import { setPathInfo } from "service/path/base";
import JSONRPC from "./jsonrpc";
import LinkWebSocket from "./link-websocket"

class CommandWs extends JSONRPC {
  ws: LinkWebSocket
  isConnected: boolean = false
  constructor() {
    super();
    this.ws = new LinkWebSocket("Command");
    this.ws.setOnOpen(this._onOpen.bind(this));
    this.ws.setOnClose(this._onClose.bind(this));
    this.ws.setOnError(this._onError.bind(this));
    this.ws.setHandleMessage(this._handleMessage.bind(this));
    // 重写jsonrpc的方法
    this._sendMessage = this.ws.sendMessage.bind(this.ws);
  }

  sendRemoteRequest(method: string, params?: any) {
    if (!this.isOpen()) return Promise.reject("websocket not connected");
    return super.sendRemoteRequest(method, params)
  }

  sendRemoteNotification(method: string, params?: any) {
    if (!this.isOpen()) return
    super.sendRemoteNotification(method, params)
  }

  // 连接command ws
  open() {
    if (this.isOpen()) return;
    this.ws.open();
    this.once('open', () => {
      // 获取路径
      super.sendRemoteRequest("getPathInfo", undefined)
        .then((result) => {
          setPathInfo(result);
          this.isConnected = true;
        })

      // todo: 获取link版本号
    })
  }

  close() {
    this.ws.close();
  }

  isOpen() {
    return this.ws.isOpen() && this.isConnected;
  }

  _onOpen() {
    this.emit("open")
  }

  _onClose() {
    this.emit("close")
  }

  _onError() {
    this.emit("error")
  }

  // 重写jsonrpc的方法
  // 这里是处理 下位机-->上位机 的request, 不是response
  didReceiveCall(method, params) {
    // 子进程的消息上报
    switch (method) {
      case "":

    }
  }
}

// command 只有一个
export const commandWs = new CommandWs();

// 不管在线版还是离线版, 启动时, 先连接一次
commandWs.open();
