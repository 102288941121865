import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

// Define a type for the slice state
interface LinkModalState {
  linkModal: boolean; // 弹窗关闭/打开
  type: "error"|"disconnect"|"start"|"connect"
}

const initialState: LinkModalState = {
  linkModal: false,
  type: "start",
};

export const linkModalSlice = createSlice({
  name: "linkModal",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // 连接失败提示框
    openLinkError: (state) => {
      state.linkModal = true
      state.type = "error"
    },
    // 连接框
    openLinkConnect: (state) => {
      state.linkModal = true
      state.type = "connect"
    },
    // 打开启动框
    openLinkStart: (state) => {
      state.linkModal = true
      state.type = "start"
    },
    // 打开断开连接框
    openLinkDisconnect: (state) => {
      state.linkModal = true
      state.type = "disconnect"
    },
    closeLinkModal: (state) => {
      state.linkModal = false
    }
  },
});

export const selectLinkModal = (state: RootState) => state.linkModal;

export const {
  openLinkError,
  openLinkConnect,
  openLinkStart,
  openLinkDisconnect,
  closeLinkModal,
} = linkModalSlice.actions;

export default linkModalSlice.reducer;
