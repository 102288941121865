import { useEffect, useState } from "react";
import ScratchBlocks from "lib/scratch-blocks";
import { Modal } from "component/modal/index";
import { FormattedMessage, defineMessages } from "react-intl";
import styles from "./VariablePrompt.module.scss";
import Box from "component/box/box";
import { vm } from "lib/scratch-vm";
import classNames from "classnames";
import message from "component/df-message/Message";
const messages = defineMessages({
  forAllSpritesMessage: {
    defaultMessage: "For all sprites",
    description:
      "Option message when creating a variable for making it available to all sprites",
    id: "gui.gui.variableScopeOptionAllSprites",
  },
  forThisSpriteMessage: {
    defaultMessage: "For this sprite only",
    description:
      "Option message when creating a varaible for making it only available to the current sprite",
    id: "gui.gui.variableScopeOptionSpriteOnly",
  },
  cloudVarOptionMessage: {
    defaultMessage: "Cloud variable (stored on server)",
    description:
      "Option message when creating a variable for making it a cloud variable, a variable that is stored on the server" /* eslint-disable-line max-len */,
    id: "gui.gui.cloudVariableOption",
  },
  availableToAllSpritesMessage: {
    defaultMessage: "This variable will be available to all sprites.",
    description:
      "A message that displays in a variable modal when the stage is selected indicating " +
      "that the variable being created will available to all sprites.",
    id: "gui.gui.variablePromptAllSpritesMessage",
  },
});
const VariablePrompt = () => {
  const [prompt, setPrompt] = useState<any>(null);
  const [globalSelected, setGlobalSelected] = useState<boolean>(true);
  const [newText, setNewText] = useState("");
  useEffect(() => {
    // 替换新建变量时的对话框
    ScratchBlocks.prompt = (
      message: string,
      defaultValue: string,
      callback: any,
      optTitle?: string,
      optVarType?: string
    ) => {
      const prompt: any = { callback, message, defaultValue };
      prompt.title = optTitle
        ? optTitle
        : ScratchBlocks.Msg.VARIABLE_MODAL_TITLE;
      prompt.varType =
        typeof optVarType === "string"
          ? optVarType
          : ScratchBlocks.SCALAR_VARIABLE_TYPE;
      prompt.showVariableOptions = // This flag means that we should show variable/list options about scope
        optVarType !== ScratchBlocks.BROADCAST_MESSAGE_VARIABLE_TYPE &&
        prompt.title !== ScratchBlocks.Msg.RENAME_VARIABLE_MODAL_TITLE &&
        prompt.title !== ScratchBlocks.Msg.RENAME_LIST_MODAL_TITLE;
      prompt.showCloudOption = false;
      // p.prompt.showCloudOption = optVarType === this.ScratchBlocks.SCALAR_VARIABLE_TYPE &&this.props.canUseCloud;
      setPrompt(prompt);
    };
    return () => {
      ScratchBlocks.prompt = null;
    };
  }, []);
  const handleCloudVariableOptionChange = (e) => { };
  const handleOk = () => {
    prompt.callback(newText, vm.runtime.getAllVarNamesOfType(prompt.varType), {
      scope: globalSelected ? "global" : "local",
      isCloud: false,
    });
    if (!newText) {
      //TODO: 加翻译
      message.error(
        `${prompt.varType === "list" ? "列表" : "变量"}名不能为空!`
      );
    }
    // 恢复默认值
    setPrompt(null);
    setNewText("");
    setGlobalSelected(true);
  };
  const handleKeyPress = (e: any) => {
    if (e.key === "Enter") {
      handleOk();
    }
  };
  return (
    <>
      {prompt && (
        <PromptComponent
          title={prompt?.title}
          canAddCloudVariable={false}
          cloudSelected={false}
          defaultValue={prompt?.defaultValue}
          globalSelected={globalSelected}
          isStage={vm.runtime.getEditingTarget().isStage}
          label={prompt?.message}
          showCloudOption={false}
          onScopeOptionSelection={(e) =>
            setGlobalSelected(e.target.value === "global")
          }
          showVariableOptions={prompt?.showVariableOptions}
          onCancel={() => setPrompt(null)}
          onChange={(e) => setNewText(e.target.value)}
          onCloudVarOptionChange={handleCloudVariableOptionChange}
          onFocus={(e) => e.target.select()}
          onKeyPress={handleKeyPress}
          onOk={handleOk}
        />
      )}
    </>
  );
};
const PromptComponent = (props) => (
  <Modal
    className={styles.modalContent}
    contentLabel={props.title}
    onRequestClose={props.onCancel}
  >
    <Box className={styles.body}>
      <Box className={styles.label}>{props.label}</Box>
      <Box>
        <input
          autoFocus
          className={styles.variableNameTextInput}
          defaultValue={props.defaultValue}
          name={props.label}
          onChange={props.onChange}
          onFocus={props.onFocus}
          onKeyPress={props.onKeyPress}
        />
      </Box>
      {props.showVariableOptions ? (
        <div>
          {props.isStage ? (
            <div className={styles.infoMessage}>
              <FormattedMessage {...messages.availableToAllSpritesMessage} />
            </div>
          ) : (
            <Box className={styles.optionsRow}>
              <label>
                <input
                  checked={props.globalSelected}
                  name="variableScopeOption"
                  type="radio"
                  value="global"
                  onChange={props.onScopeOptionSelection}
                />
                <FormattedMessage {...messages.forAllSpritesMessage} />
              </label>
              <label
                className={classNames({
                  [styles.disabledLabel]: props.cloudSelected,
                })}
              >
                <input
                  checked={!props.globalSelected}
                  disabled={props.cloudSelected}
                  name="variableScopeOption"
                  type="radio"
                  value="local"
                  onChange={props.onScopeOptionSelection}
                />
                <FormattedMessage {...messages.forThisSpriteMessage} />
              </label>
            </Box>
          )}
          {props.showCloudOption ? (
            <Box className={classNames(styles.cloudOption)}>
              <label
                className={classNames({
                  [styles.disabledLabel]: !props.canAddCloudVariable,
                })}
              >
                <input
                  checked={props.cloudSelected && props.canAddCloudVariable}
                  disabled={!props.canAddCloudVariable}
                  type="checkbox"
                  onChange={props.onCloudVarOptionChange}
                />
                <FormattedMessage {...messages.cloudVarOptionMessage} />
              </label>
            </Box>
          ) : null}
        </div>
      ) : null}

      <Box className={styles.buttonRow}>
        <button className={styles.cancelButton} onClick={props.onCancel}>
          <FormattedMessage
            defaultMessage="Cancel"
            description="Button in prompt for cancelling the dialog"
            id="gui.prompt.cancel"
          />
        </button>
        <button className={styles.okButton} onClick={props.onOk}>
          <FormattedMessage
            defaultMessage="OK"
            description="Button in prompt for confirming the dialog"
            id="gui.prompt.ok"
          />
        </button>
      </Box>
    </Box>
  </Modal>
);
export default VariablePrompt;
