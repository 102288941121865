import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { selectIntl } from "redux/intl/intlSlice";
import { ExtensionDataType } from "service/ext-asset-manager/extension/type";
import { selectAssetLibStatus } from "../../../redux/asset-lib/assetLibStatusSlice";
import { vm } from "../../../lib/scratch-vm";
import { compareVersions } from "service/ext-asset-manager/device/util";
import { compareVersion } from "util/compareVersion";

export type LibExtensionData = ExtensionDataType & {
  // 小模块是否支持当前设备
  isSupportCurrentDevice: boolean;
  // 小模块是否已加载
  isLoaded: boolean;
  // sku
  sku?: string;
};

export const useExtensionData = (): LibExtensionData[][] => {
  // 当主板切换时, 此状态刷新
  const { extensionLibVisible } = useSelector(selectAssetLibStatus);
  const [allExtensionData, setAllExtensionData] = useState<{
    [id: string]: ExtensionDataType[];
  }>({});
  useEffect(() => {
    if (!extensionLibVisible) return;
    const refreshList = (allExtensionData_: {
      [key: string]: ExtensionDataType;
    }) => {
      let ret = {};
      Object.keys(allExtensionData_).forEach((deviceId) => {
        ret[deviceId] = Object.values(allExtensionData_[deviceId]).sort((a, b) => compareVersion(a.version, b.version))
      });
      setAllExtensionData(ret);
    };
    // 
    const refreshExtensionList_ = (immediate = false) => {
      vm.runtime.extAssetManager.refreshExtensionList(refreshList,immediate)
    }
    // 每次扩展库打开时, 重新获取扩展列表
    refreshExtensionList_()
    // 当扩展加载/更新/下载时, 刷新扩展列表
    vm.on("refreshExtensionLib", refreshExtensionList_);
    return () => {
      vm.removeAllListeners("refreshExtensionLib");
    };
  }, [extensionLibVisible]);

  const allExtensionData_ = useMemo(() => {
    // 当前有无加载设备
    const currentDeviceId = vm.runtime.deviceManager.getCurrentDeviceId();
    const currentVersion = vm.runtime.deviceManager.getCurrentDeviceVersion();
    let ret = {};
    for (let extensionId in allExtensionData) {
      ret[extensionId] = allExtensionData[extensionId].map((item) => {
        let isSupportCurrentDevice = true;
        // 扩展config中配置了 支持的设备列表
        if (item.supportDevices && Object.keys(item.supportDevices).length) {
          isSupportCurrentDevice = false;
          // 判断设备名
          if (
            item.supportDevices[currentDeviceId] &&
            item.supportDevices[currentDeviceId]
          ) {
            if (item.supportDevices[currentDeviceId] === currentVersion)
              return true;
            // 判断版本号, 判断 ~ ^ * 等通配符
            if (item.supportDevices[currentDeviceId] === "*")
              isSupportCurrentDevice = true;
            if (item.supportDevices[currentDeviceId].indexOf("^") === 0) {
              let temp = item.supportDevices[currentDeviceId].slice(1);
              if (
                compareVersions(
                  currentVersion.slice(0, currentVersion.lastIndexOf(".")),
                  temp.slice(0, currentVersion.lastIndexOf("."))
                ) >= 0
              )
                isSupportCurrentDevice = true;
            }
            if (item.supportDevices[currentDeviceId].indexOf("~") === 0) {
              let temp = item.supportDevices[currentDeviceId].slice(1);
              if (
                compareVersions(
                  currentVersion.slice(0, currentVersion.indexOf(".")),
                  temp.slice(0, currentVersion.indexOf("."))
                ) >= 0
              )
                isSupportCurrentDevice = true;
            }
          }
        }
        return {
          ...item,
          // 小模块是否支持该设备
          isSupportCurrentDevice: isSupportCurrentDevice,
          // 小模块是否已加载
          isLoaded: vm.extensionManager.isExtensionLoaded(
            (item as any).extensionIdWithVersion || item.extensionId
          )||vm.extensionManager.isExtensionLoaded(item.extensionId), // 内置扩展在extensionManager没有保存版本号
        };
      });
    }
    return ret;
  }, [allExtensionData, extensionLibVisible]);
  return Object.values(allExtensionData_);
};
