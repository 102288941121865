import fs from 'service/link-adapter/fs';
import {getBuiltinDevJsonPath, getLocalDevJsonPath} from "../../path/assetPath";
import {getDeviceID, getDeviceIDWithVersion} from "./util";
import {
    DeviceConfig,
    DeviceDataType,
} from "./type";
import { DeviceMaps } from '../extAssetManager';
import { version } from 'process';
import { compareVersion } from 'util/compareVersion';
import { EXTENSION_ASSET_SERVER, IS_WEB_PLATFORM, MINDPLUS_MODE } from 'config/config';

// 获取离线版内置的device列表
const getBuiltinDeviceData = (): Promise<DeviceDataType[]> => {
    return fs.readFile(getBuiltinDevJsonPath()).then((data: string | ArrayBuffer) => {
        // 将字符串解析成对象
        let extensionData: DeviceConfig[] = [];
        try {
            extensionData = JSON.parse(data.toString());
        } catch {}
        // 解析数据
        const extensionInnerData: DeviceDataType[] = extensionData.map(item => {
            // 1.生成唯一的id,  "author-id"
            const _item: DeviceDataType = {
                ...item,
                deviceId: getDeviceID(item),
                deviceIdWithVersion: getDeviceIDWithVersion(item),
                hasUpdate: false,
                isDownloaded: true,
                isBuiltin: true, // 标为内置
            }
            // 2.cover 转换成绝对路径
            // _item.cover = getCoverAbsolutePath(_item);
            // // 3.main 转换成绝对路径
            // _item.main = getMainAbsolutePath(_item);
            return _item;
        })
        return extensionInnerData
    })
}

// 获取本地的设备列表
const getLocalDeviceData = (): Promise<DeviceDataType[]> => {
    // todo: 在线版兼容(在线版没有local)
    return fs.readFile(getLocalDevJsonPath()).then((data: string | ArrayBuffer) => {
        // 将字符串解析成对象
        let extensionData: DeviceConfig[] = [];
        try {
            extensionData = JSON.parse(data.toString());
        } catch {}
        // 解析数据
        const extensionInnerData: DeviceDataType[] = extensionData.map(item => {
            // 1.生成唯一的id,  "author-id"
            const _item: DeviceDataType = {
                ...item,
                deviceId: getDeviceID(item),
                deviceIdWithVersion: getDeviceIDWithVersion(item),
                isBuiltin: false,
                hasUpdate: false,
                isDownloaded: true,
            }
            // 2.cover 转换成绝对路径
            // _item.cover = getCoverAbsolutePath(_item);
            // // 3.main 转换成绝对路径
            // _item.main = getMainAbsolutePath(_item);
            return _item;
        })
        return extensionInnerData
    })
}


// 获取服务器的extension列表
export const getServerDeviceData = async (): Promise<DeviceDataType[]> => {
    // 将字符串解析成对象
    let extensionData: DeviceDataType[] = [];
    try {
        const response = await fetch(`${EXTENSION_ASSET_SERVER}/${MINDPLUS_MODE}/devices/dev.json`, {
            method: 'GET',
            headers: {
                'Cache-Control': 'no-cache', // 防止缓存
                'Pragma': 'no-cache'         // 兼容旧版HTTP
            }
        })
        extensionData = await response.json()
        // 解析数据
        return extensionData.map(item => {
            // 1.生成唯一的id,  "author-id"
            const _item: DeviceDataType = {
                ...item,
                deviceId: getDeviceID(item),
                deviceIdWithVersion: getDeviceIDWithVersion(item),
                isBuiltin: false,
                hasUpdate: false,
                isDownloaded: false,
            }
            // 2.cover 转换成绝对路径
            // _item.cover = getCoverAbsolutePath(_item);
            // // 3.main 转换成绝对路径
            // _item.main = getMainAbsolutePath(_item);
            return _item;
        })
    } catch (e) {
        console.log("e=====", e)
        return extensionData
    }
}

// 获取设备数据
const getDeviceData = (callback?: (data: DeviceMaps) => void): Promise<DeviceMaps> => {
    // 1.获取内置(安装目录/extensions)
    const promise1 = getBuiltinDeviceData();

    // 2.获取本地
    const promise2 = getLocalDeviceData();

    // 3.获取服务器
    const promise3 = getServerDeviceData();

    // 4.每次数据获取成功都调用一次回调
    let deviceData: [DeviceDataType[], DeviceDataType[], DeviceDataType[]] = [[], [], []];
    const parseData = () => {
        let deviceDataMap: DeviceMaps = {};
        // 合并数据
        const builtinData = deviceData[0];
        const localData = deviceData[1];
        const serverData = deviceData[2];

        builtinData.forEach(item => {
            if (!deviceDataMap[item.deviceId]) {
                deviceDataMap[item.deviceId] = {}
            }
            deviceDataMap[item.deviceId][item.version] = item;
        });

        localData.forEach(item => {
            if (!deviceDataMap[item.deviceId]) {
                deviceDataMap[item.deviceId] = {}
            }
            // 本地外部扩展可以覆盖内置扩展
            deviceDataMap[item.deviceId][item.version] = item;
        })

        serverData.forEach(item => {
            if (!deviceDataMap[item.deviceId]) {
                deviceDataMap[item.deviceId] = {}
            }
            // 该版本, 本地已下载
            if (deviceDataMap[item.deviceId][item.version]) return;
            deviceDataMap[item.deviceId][item.version] = {
                ...item,
                isBuiltin: false,
                isDownloaded: false,
            };
        })

        // hasUpdate
        Object.values(deviceDataMap).forEach(item => {
            const versions = Object.keys(item);
            versions.forEach((version) => {
                // 比较版本号
                const ret = versions.some(v => compareVersion(v, version) > 0)
                // 有版本号更高的
                if (ret) {
                    item[version].hasUpdate = true;
                }
            })
        })

        return deviceDataMap
    }
    const promiseList = [promise1, promise2, promise3].map((promise, index) => {
        return promise.then((data) => {
            // 缓存数据
            deviceData[index] = data;
            const data_ = parseData();
            // 刷新数据
            if (callback) callback(data_);
            return data_;
        }).catch(e => console.error('获取设备列表失败', index, e))
    })
    return Promise.all(promiseList).then(()=>parseData());
}

export {
    getDeviceData, // 获取设备列表
}
