import ScratchBlocks from "lib/scratch-blocks";
import styles from "./WorkspaceControl.module.scss";
import { MutableRefObject } from "react";
import ScreenShots from "lib/screenshots";
import { useSelector } from "react-redux";
import { selectProject } from "redux/project/projectSlice";

// workspace上的控制按钮（重做、撤销、截图）
export const WorkspaceControl = (props: { workspaceRef: MutableRefObject<any> }) => {
  const { projectTitle } = useSelector(selectProject)
  const { workspaceRef } = props;
  const handleUndo = () => {
    workspaceRef.current.undo(false);
  };
  const handleRedo = () => {
    workspaceRef.current.undo(true);
  };
  
  const whenKeyScreenshots = () => {
    ScreenShots.screenShots(workspaceRef.current, projectTitle)
  };

  return (
    <div className={styles.container} title={ScratchBlocks.Msg.UNDO}>
      <span onClick={handleUndo}>
        <svg id="组_332" data-name="组 332" xmlns="http://www.w3.org/2000/svg" width="17.457" height="14.649" viewBox="0 0 17.457 14.649">
          <path id="路径_355" data-name="路径 355" d="M17.507,7.325a7.294,7.294,0,0,1-7.325,7.325,1.3,1.3,0,1,1,0-2.6A4.72,4.72,0,1,0,5.462,7l.814-.814a1.378,1.378,0,0,1,1.872,0,1.378,1.378,0,0,1,0,1.872l-2.93,2.93a1.307,1.307,0,0,1-.9.407,1.307,1.307,0,0,1-.9-.407L.416,7.976a1.378,1.378,0,0,1,0-1.872,1.378,1.378,0,0,1,1.872,0l.651.651A7.171,7.171,0,0,1,10.182,0,7.294,7.294,0,0,1,17.507,7.325Z" transform="translate(-0.05)" fill="#f8a431" />
        </svg>
      </span>
      <span onClick={handleRedo} title={ScratchBlocks.Msg.REDO}>
        <svg id="组_329" data-name="组 329" xmlns="http://www.w3.org/2000/svg" width="16.985" height="14.187" viewBox="0 0 16.985 14.187">
          <path id="路径_354" data-name="路径 354" d="M7.094,0a7.185,7.185,0,0,1,7.094,6.542l.631-.631a1.335,1.335,0,0,1,1.813,0,1.335,1.335,0,0,1,0,1.813l-2.837,2.837a1.151,1.151,0,0,1-1.734,0L9.222,7.724a1.335,1.335,0,0,1,0-1.813,1.335,1.335,0,0,1,1.813,0l.788.788A4.627,4.627,0,0,0,7.251,2.443a4.727,4.727,0,0,0-4.729,4.65,4.575,4.575,0,0,0,4.571,4.571,1.261,1.261,0,1,1,0,2.522A7.094,7.094,0,0,1,7.094,0Z" fill="#f8a431" />
        </svg>
      </span>
      <span onClick={whenKeyScreenshots} title={ScratchBlocks.Msg.SNAPSHOT}>
        <svg xmlns="http://www.w3.org/2000/svg" width="18.132" height="18.135" viewBox="0 0 18.132 18.135">
          <g id="组_331" data-name="组 331" transform="translate(0.75 0.75)">
            <path id="路径_606" data-name="路径 606" d="M440.672,166.358h7.738V174.1a.594.594,0,1,0,1.188,0v-8.33a.6.6,0,0,0-.6-.6h-8.33a.594.594,0,1,0,0,1.188Z" transform="translate(-435.343 -162.792)" fill="#f8a431" stroke="#f8a431" stroke-width="1.5" />
            <path id="路径_607" data-name="路径 607" d="M252.039,75.752H239.56V63.284a.594.594,0,1,0-1.188,0v1.784h-1.778a.594.594,0,1,0,0,1.188h1.778V76.345h0a.593.593,0,0,0,.594.594h10.1v1.791a.594.594,0,0,0,1.188,0V76.94h1.784a.594.594,0,0,0,0-1.188Z" transform="translate(-236 -62.69)" fill="#f8a431" stroke="#f8a431" stroke-width="1.5" />
            <path id="路径_608" data-name="路径 608" d="M434.032,265.118l5.047-5.048a.476.476,0,1,0-.673-.672l-5.047,5.047a.476.476,0,1,0,.673.673Z" transform="translate(-428.113 -253.735)" fill="#f8a431" stroke="#f8a431" stroke-width="1.5" />
          </g>
        </svg>
      </span>
    </div>
  );
};
