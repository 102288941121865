import {useEffect, useState} from "react";
import {DeviceDataType} from "service/ext-asset-manager/device/type";
import {vm} from "lib/scratch-vm";
import { DeviceMaps } from "service/ext-asset-manager/extAssetManager";
import { useSelector } from "react-redux";
import { selectAssetLibStatus } from "redux/asset-lib/assetLibStatusSlice";
import { compareVersion } from "util/compareVersion";

export type LibDeviceData = DeviceDataType & {
    // 是否已加载
  isLoaded: boolean
  sku?:string
}

export const useDeviceData = () => {
  const { extensionLibVisible } = useSelector(selectAssetLibStatus);
  const [deviceData, setDeviceData] = useState<{ [id: string]: LibDeviceData[] }>({});
  useEffect(() => {
    if (!extensionLibVisible) return;
    const refreshList = (allDeviceData: DeviceMaps) => {
      let ret = {};
      Object.keys(allDeviceData).forEach(deviceId => {
        ret[deviceId] = Object.values(allDeviceData[deviceId]).map((item: DeviceDataType) => {
          return {
            ...item,
            isLoaded: vm.extensionManager.isExtensionLoaded(item.deviceIdWithVersion || item.deviceId)
          }
        }).sort((a, b) => compareVersion(a.version, b.version))
      })
      setDeviceData(ret);
    }
    const refreshDeviceList_ = (immediate = false) => {
      vm.runtime.extAssetManager.refreshDeviceList(refreshList, immediate)
    }
    // 每次扩展库页面打开一次, 重新获取扩展列表
    refreshDeviceList_()
    // 当扩展下载/加载/更新时, 刷新扩展列表
    vm.on('refreshExtensionLib', refreshDeviceList_)
    return () => {
      vm.removeAllListeners("refreshExtensionLib")
    }
  }, [extensionLibVisible])

    return Object.values(deviceData)
}
