import { configureStore } from '@reduxjs/toolkit'
import {intlSlice} from "./intl/intlSlice";
import {targetsSlice} from "./tagets/targetsSlice";
import {modeSlice} from "./mode/modeSlice";
import {modalsSlice} from "./modals/modalsSlice";
import {monitorsSlice} from "./monitors/monitors";
import {blockDragSlice} from "./block-drag/blockDragSlice";
import {vmStatusSlice} from "./vm-status/vmStatusSlice";
import {projectSlice} from "./project/projectSlice";
import {alertsSlice} from "./alerts/alertsSlice";
import {micIndicatorSlice} from "./mic-indicator/micIndicatorSlice";
import {workspaceMetricsSlice} from "./workspace-metrics/workspaceMetricsSlice";
import {assetLibStatusSlice} from "./asset-lib/assetLibStatusSlice";
import {tabSlice} from "./tab/tabSlice";
import {colorPickingSlice} from "./color-picking/colorPickingSlice";
import {monitorLayoutSlice} from "./monitor-layout/monitorLayoutSlice";
import {stageSizeSlice} from "./stage-size/stageSizeSlice";
import {hoverTargetSlice} from "./hover-target/hoverTargetSlice";
import {assetDragSlice} from "./asset-drag/assetDragSlice";
import {themeSlice} from "./theme/themeSlice";
import {serialListSlice} from "./serial-list/serialListSlice";
import {restoreDeletionSlice} from 'redux/restore-deletion/restoreDeletionSlice';
import {arduinoCodeSlice} from './arduino-code/arduinoCodeSlice';
import {codeEditorSizeSlice} from './code-editor-size/codeEditorSizeSlice';
import {serialWriteSettingSlice} from './serial-write-setting/serialWriteSettingSlice';
import {connectionModalExtensionIdSlice} from "./connection-modal-extensionId/connectionModalExtensionIdSlice";
import {modalStateSlice} from './modal-state/modalStateSlice';
import {userSlice} from './user/userSlice';
import {linkModalSlice} from './link-modal/linkModalSlice';
import {ScratchPaintReducer} from 'lib/scratch-paint';
import {cardSlice} from './card/cardSlice';

export const store = configureStore({
  reducer: {
    scratchPaint: ScratchPaintReducer,
    // vm: vmSlice.reducer,
    user: userSlice.reducer,
    intl: intlSlice.reducer,
    targets: targetsSlice.reducer,
    mode: modeSlice.reducer,
    modals: modalsSlice.reducer,
    monitors: monitorsSlice.reducer,
    blockDrag: blockDragSlice.reducer,
    vmStatus: vmStatusSlice.reducer,
    project: projectSlice.reducer,
    alerts: alertsSlice.reducer,
    micIndicator: micIndicatorSlice.reducer,
    workspaceMetrics: workspaceMetricsSlice.reducer,
    assetLibStatus: assetLibStatusSlice.reducer,
    tab: tabSlice.reducer,
    colorPicking: colorPickingSlice.reducer,
    monitorLayout: monitorLayoutSlice.reducer,
    stageSize: stageSizeSlice.reducer,
    hoverTarget: hoverTargetSlice.reducer,
    assetDrag: assetDragSlice.reducer,
    theme: themeSlice.reducer,
    arduinoCode: arduinoCodeSlice.reducer,
    codeEditorSize: codeEditorSizeSlice.reducer,
    serialList: serialListSlice.reducer,
    serialWriteSetting: serialWriteSettingSlice.reducer,
    restoreDeletion: restoreDeletionSlice.reducer,
    connectionModalExtensionId: connectionModalExtensionIdSlice.reducer,
    modalState: modalStateSlice.reducer,
    linkModal: linkModalSlice.reducer,
    card:cardSlice.reducer
  },
  //关闭序列化检测
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
