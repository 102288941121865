const genTime = function (date) {
    const time = date.getTime().toString();
    const length = 6;
    const timeLength = time.length;
    const id:any = [];
    for (let i = 0; i < length; i++) {
        id[i] = time.charAt(Math.random() * timeLength);
    }
    return id.join('');
};

export const getFileName = function (lang, fileInputFilename?:string){
    let projectName = 'unKownProject';
    if (lang === 'zh' || lang === 'zh-cn'){
        projectName = '新建项目';
    } else {
        projectName = 'new-project'
    }
    // const date = new Date();
    // const timestamp = genTime(date);
    // const filename = `${projectName}-${timestamp}`;
    if (!fileInputFilename) return projectName;
    // only parse title with valid scratch project extensions
    // (.sb, .sb2, and .sb3)
    const matches = fileInputFilename.match(/^(.*)\.sb[23]?$/);
    if (!matches) return projectName;
    return matches[1].substring(0, 100); // truncate project title to max 100 chars
};
