import settingIcon from 'asset/image/icon-setting.png'
import styles from './Settings.module.scss'
import { FC, useState } from "react";
import DFHorizontalTabs, { Tab } from "../../../component/df-horizontal-tabs/DFHorizontalTabs";
import DFModal from "../../../component/df-modal/DFModal";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { changeLocale, selectIntl, updateLocalMessages } from "../../../redux/intl/intlSlice";
import { changeTheme, selectTheme } from "../../../redux/theme/themeSlice";
import logoIcon from 'asset/image/navlogo.svg'
import qrCodeIcon from 'asset/image/qr_code.png'
import { Radio } from "antd";
import ScratchBlocks from "../../../lib/scratch-blocks";
import * as XLSX from 'xlsx'
import { showBlockTab } from "../../../redux/tab/tabSlice";
import { DFRadio, DFRadioGroup } from 'component/df-radio/DFRadio';

type Props = {};
export const Settings = (props: Props) => {
  const [showModal, setShowModal] = useState(false)
  const { locale, messagesByLocale } = useSelector(selectIntl)
  const theme = useSelector(selectTheme)
  const [themeVal, setThemeVal] = useState(theme)
  const [localeVal, setLocaleVal] = useState(locale)
  const [tabKey, setTabKey] = useState('language')
  const dispatch = useDispatch()
  const lang = locale === 'zh-cn' || locale === 'zh'
  const intl = useIntl()
  const handleShowModal = () => {
    setShowModal(true)
  }

  const handleChangeLocale = (val) => {
    const newLocale = val;
    setLocaleVal(newLocale)
    if (messagesByLocale[newLocale]) {
      dispatch(showBlockTab())
      dispatch(changeLocale(newLocale))
      document.documentElement.lang = newLocale;
      setShowModal(false)
    }

  }
  const handleChangeTheme = (val) => {
    setThemeVal(val)
    dispatch(changeTheme(val))
    setShowModal(false)
  }

  const handleTabChange = (tab) => {
    setTabKey(tab.key)
  }
  const hasId = (item, messages) => {
    return Object.keys(messages).indexOf(item.idx) !== -1
  }
  const onAnalysisXlsx = (e) => {
    const self = this;
    let tempFileList = Array.from(e.target.files)
    if (tempFileList && tempFileList[0]) {

      const locale = localeVal === 'zh' ? 'zh-cn' : localeVal
      // 新建一个FileReader
      const reader = new FileReader()
      // 读取文件
      reader.readAsBinaryString(e.target.files[0]);

      reader.onload = function (e: any) {
        const inpdata = e.target.result;
        const workbook = XLSX.read(inpdata, { type: 'binary' });
        const sheetNames = workbook.SheetNames;
        const messagesByLocales = Object.assign({}, messagesByLocale);
        sheetNames.forEach(name => {
          const ws = workbook.Sheets[name];
          const data = XLSX.utils.sheet_to_json(ws);
          data.forEach((item: any) => {
            if (item[locale]) {
              if (hasId(item, messagesByLocales[locale])) {
                messagesByLocales[locale][item.idx] = item[locale];
              }
              //TODO:l10n 待修改
              // if (hasId(item, Translate.locales[locale])) {
              //     Translate.locales[locale][item.idx] = item[locale];
              // }
              if (hasId(item, ScratchBlocks.ScratchMsgs.locales[locale])) {
                ScratchBlocks.ScratchMsgs.locales[locale][item.idx] = item[locale];
              }
            }
          })
        });
        dispatch(updateLocalMessages(messagesByLocales))
        setTimeout(() => {
          setShowModal(false)
        }, 10);
      }
    }
  }
  const items: Tab[] = [
    {
      key: 'language',
      label: intl.formatMessage(messages.languageSetting),
      content: <div className={styles.languageBox}>
        <DFRadioGroup onChange={handleChangeLocale} value={localeVal} defaultValue={locale}>
          <DFRadio wrapperClassName={styles.localeItem} value={'zh-cn'}>简体中文</DFRadio>
          <DFRadio wrapperClassName={styles.localeItem} value={'en'}>English</DFRadio>
          <DFRadio wrapperClassName={styles.localeItem} value={'el'}>Ελληνικά</DFRadio>
          <DFRadio wrapperClassName={styles.localeItem} value={'es-419'}>Español Latinoamericano</DFRadio>
          <DFRadio wrapperClassName={styles.localeItem} value={'ko'}>한국어</DFRadio>
          <DFRadio wrapperClassName={styles.localeItem} value={'fr'}>Français</DFRadio>
          <DFRadio wrapperClassName={styles.localeItem} value={'th'}>ไทย</DFRadio>
          <DFRadio wrapperClassName={styles.localeItem} value={'tr'}>Türkçe</DFRadio>
          <DFRadio wrapperClassName={styles.localeItem} value={'mn'}>ᠮᠣᠩᠭᠣᠯ ᠬᠡᠯᠡ</DFRadio>
          <DFRadio wrapperClassName={styles.localeItem} value={'zh-tw'}>繁體中文</DFRadio>
          <DFRadio wrapperClassName={styles.localeItem} value={'khm'}>ភាសាខ្មែរ</DFRadio>
          <DFRadio wrapperClassName={styles.localeItem} value={'ja'}>日本語</DFRadio>
          <DFRadio wrapperClassName={styles.localeItem} value={'pt'}>Português</DFRadio>
          <DFRadio wrapperClassName={styles.localeItem} value={'pt-br'}>Português Brasileiro</DFRadio>
          <DFRadio wrapperClassName={styles.localeItem} value={'hu'}>Magyar</DFRadio>
          <DFRadio wrapperClassName={styles.localeItem} value={'de'}>Deutsch</DFRadio>
          <DFRadio wrapperClassName={styles.localeItem} value={'cr'}>Croatian</DFRadio>
          <DFRadio wrapperClassName={styles.localeItem} value={'ca'}>Català</DFRadio>
        </DFRadioGroup>
        {/* <div>
                    <div className={styles.attachment}>
                        <span><FormattedMessage {...messages.uploadTranslateFile} />:</span>
                        <label htmlFor="attachmentcsv" className={styles.inputCsv}>
                        </label>
                        <p>
                            <input
                                accept=".xlsx"
                                name="attachmentcsv"
                                type="file"
                                id="attachmentcsv"
                                onChange={onAnalysisXlsx}
                            />
                        </p>
                    </div>
                    <a className={styles.helpUs} target={`_blank`} href={`https://mindplus.dfrobot.com/Translation`}>
                        <FormattedMessage {...messages.helpUsTranslate} />
                    </a>
                </div> */}
      </div>
    },
    {
      key: 'theme',
      label: intl.formatMessage(messages.themeSetting),
      content: <div className={styles.contentBox}>
        <DFRadioGroup onChange={handleChangeTheme} value={themeVal} defaultValue={theme} >
          <DFRadio wrapperClassName={styles.themeItem} value={'base'}>base</DFRadio>
          <DFRadio wrapperClassName={styles.themeItem} value={'eye-protect'}>eye-protect</DFRadio>
          <DFRadio wrapperClassName={styles.themeItem} value={'blue'}>blue</DFRadio>
          <DFRadio wrapperClassName={styles.themeItem} value={'red'}>red</DFRadio>
          <DFRadio wrapperClassName={styles.themeItem} value={'green'}>green</DFRadio>
          <DFRadio wrapperClassName={styles.themeItem} value={'purple'}>purple</DFRadio>
          <DFRadio wrapperClassName={styles.themeItem} value={'orange'}>orange</DFRadio>
          <DFRadio wrapperClassName={styles.themeItem} value={'violet'}>violet</DFRadio>
        </DFRadioGroup>
      </div>
    },
    {
      key: 'connectUs',
      label: intl.formatMessage(messages.connectUsTitle),
      content: <div className={styles.contentBox}>
        <div className={styles.logo}>
          <img draggable="false" src={logoIcon} alt="company logo" />
        </div>
        <div className={styles.contact}>
          <p style={{ width: "250px" }}>
            <span><FormattedMessage {...messages.officialWebsit} />&nbsp;:</span>
            <a
              href={`http://www.mindplus.cc`}
              className={styles.websiteName}
              target={`_blank`}
            >http://mindplus.cc</a>
          </p>
          {lang ? (<p style={{ width: "250px" }}>
            <span><FormattedMessage {...messages.officialQQGroup} />：</span>
            <span>671877416</span>
          </p>) :
            (
              <p>
                <span>E-mail：</span>
                <span>mindplus@dfrobot.com</span>
              </p>
            )
          }
          {lang ? (<p style={{ width: "250px" }}>
            <span><FormattedMessage {...messages.wechatOfficialAccounts} />&nbsp;:</span>
            <span>蘑菇云创造</span>
          </p>) : null}
        </div>
        {lang ? (<div className={styles.qrcode}>
          <img src={qrCodeIcon} alt="qr_code" draggable="false" />
          <span><FormattedMessage {...messages.officialQQGroup} /></span>
        </div>) : null}
      </div>
    }
  ]
  return (
    <>
      <div className={styles.container} onClick={handleShowModal}>
        <img
          alt="Setting-Icon"
          className={styles.settingIcon}
          src={settingIcon}
        />
      </div>
      <DFModal open={showModal} onClose={() => setShowModal(false)} width={740} footer={null} centered={true}
        contentStyle={{ padding: 0 }}>
        <DFHorizontalTabs items={items} onChangeTab={handleTabChange} defaultActiveKey={'language'}
          activeTabKey={tabKey} />
      </DFModal>
    </>
  );
};


const messages = defineMessages({
  officialWebsit: {
    "id": 'gui.setting.officialWebsit',
    "defaultMessage": "Official Website",
    "description": "official website from setting page"
  },
  remindWhenUpdate: {
    "id": 'gui.setting.remindWhenUpdate',
    "defaultMessage": "Remind me when updated",
    "description": "remind me when updated from setting page"
  },
  officialQQGroup: {
    "id": 'gui.setting.officialQQGroup',
    "defaultMessage": "Official QQ Group",
    "description": "official qq group from setting page"
  },
  wechatOfficialAccounts: {
    "id": 'gui.setting.wechatOfficialAccounts',
    "defaultMessage": "WeChat Official Accounts",
    "description": "wechat official accounts from setting page"
  },
  currentVersion: {
    "id": 'gui.setting.currentVersion',
    "defaultMessage": "Current Version",
    "description": "Current Version from setting page"
  },
  checkForUpdate: {
    "id": 'gui.setting.checkForUpdate',
    "defaultMessage": "Check for Updates",
    "description": "Check for Updates from setting page"
  },
  language: {
    "id": 'gui.setting.language',
    "defaultMessage": "Language",
    "description": "Language from setting page"
  },
  feedback: {
    "id": 'gui.setting.feedback',
    "defaultMessage": "Feedback",
    "description": "Feedback from setting page"
  },
  email: {
    "id": 'gui.setting.email',
    "defaultMessage": "E-mail",
    "description": "E-mail from setting page"
  },
  opinionFeedback: {
    "id": 'gui.setting.opinionFeedback',
    "defaultMessage": "Opinion/Feedback",
    "description": "Opinion/Feedback from setting page"
  },
  uploadAttachment: {
    "id": 'gui.setting.uploadAttachment',
    "defaultMessage": "Upload Attachment",
    "description": "Upload Attachment from setting page"
  },
  uploadTranslateFile: {
    "id": 'gui.setting.uploadTranslateFile',
    "defaultMessage": "Upload translation file",
    "description": "Upload translation file from setting page"
  },
  checkUpdate: {
    "id": 'gui.setting.checkUpdate',
    "defaultMessage": "Check Update",
    "description": "Check Update from setting page"
  },
  helpUsTranslate: {
    "id": 'gui.setting.helpUsTranslate',
    "defaultMessage": "Help Us Translate",
    "description": "Help Us Translate from setting page"
  },
  ok: {
    "id": 'gui.setting.ok',
    "defaultMessage": "Ok",
    "description": "Ok from setting page"
  },
  theme: {
    "id": 'gui.setting.theme',
    "defaultMessage": "Theme",
    "description": "Theme from setting page"
  },
  currentIsLatest: {
    "id": 'gui.setting.currentIsLatest',
    "defaultMessage": "Current version is the latest one",
    "description": "Current version is the latest one from setting page"
  },
  latestVersion: {
    "id": 'gui.setting.latestVersion',
    "defaultMessage": "Latest version",
    "description": "Latest version from setting page"
  },
  download: {
    "id": 'gui.setting.download',
    "defaultMessage": "Click to download",
    "description": "Download from setting page"
  },
  languageSetting: {
    "id": 'gui.setting.languageSetting',
    "defaultMessage": "Language",
    "description": "Language settings from setting page"
  },
  themeSetting: {
    "id": 'gui.setting.themeSetting',
    "defaultMessage": "Theme",
    "description": "Theme setting from setting page"
  },
  versionUpdate: {
    "id": 'gui.setting.versionUpdate',
    "defaultMessage": "Version update",
    "description": "Version update from setting page"
  },
  connectUsTitle: {
    "id": 'gui.setting.connectUsTitle',
    "defaultMessage": "Contact us",
    "description": "Contact us title from setting page"
  },
  displayTitle: {
    "id": 'gui.setting.displayTitle',
    "defaultMessage": "Display Setting",
    "description": "display setting title from setting page"
  },
  restartEffect: {
    "id": 'gui.setting.restartEffect',
    "defaultMessage": "font-size (restart effect):",
    "description": "restart effect title"
  },
  fontSizeLarge: {
    "id": 'gui.setting.fontSizeLarge',
    "defaultMessage": "Large",
    "description": "fontsize large"
  },
  fontSizeMiddle: {
    "id": 'gui.setting.fontSizeMiddle',
    "defaultMessage": "Middle",
    "description": "fontsize middle"
  },
  fontSizeSmall: {
    "id": 'gui.setting.fontSizeSmall',
    "defaultMessage": "Small",
    "description": "fontsize small"
  },
  onlineRepair: {
    "id": 'gui.setting.onlineRepair',
    "defaultMessage": "online repair tool, click open >",
    "description": "onlineRepair"
  }
});

