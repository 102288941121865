import React, { useEffect } from "react";
import Block from "./block/Block";
import styles from './BlockWrapper.module.scss'
import ExtensionButton from "component/extension-button/ExtensionButton";
import {useSelector} from "react-redux";
import {selectTab} from "redux/tab/tabSlice";
import {CostumeTab} from "./costume-tab/index";
import {selectStageSize} from "redux/stage-size/stageSizeSlice";
import {vm} from "lib/scratch-vm";
import {selectTargets} from "redux/tagets/targetsSlice";
// import {OnlineSubHeader} from "./online-header/OnlineHeader";
import { Tabs } from './tabs/Tabs';
import {SoundTab} from "./sound-tab";
import {Watermark} from 'component/watermark/index';

const BlockWrapper = () => {
    const {currentTab} = useSelector(selectTab);
    const {stageSize} = useSelector(selectStageSize)
    //监听editingTarget变化,判断是否显示二级header(属于模式的功能区)
    const {targetsUpdateCount} = useSelector(selectTargets)

  useEffect(() => {
    // scratch-blocks中监听了resize事件, 当舞台大小改变, 触发事件, 重新渲染blocks
    window.dispatchEvent(new Event("resize"));
  }, [stageSize, vm.editingTarget?.isDevice]); //如果vm.editingTarget是否是设备切换，触发舞台大小改变事件
  return (
    <div className={styles.container}>
      <div className={styles.flexWrapper}>
        <div className={styles.tabsWrapper}>
          <Tabs />
        </div>
        <div className={styles.blockWrapper}>
          <Block visibility={currentTab === "block"} />
          <ExtensionButton visibility={currentTab === "block"} />
          {currentTab === "backdrop" || currentTab === "costume" ? (
            <CostumeTab />
          ) : null}
          {currentTab === "sound" && <SoundTab />}
          {currentTab ==="block" ? (
            <div className={styles.watermark}>
              <Watermark />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default BlockWrapper;
