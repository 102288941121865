import DFDropdown, { DFDropdownItem } from "component/df-dropdown/DFDropdown";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "redux/user/userSlice";
import lostLoginIcon from "./lost-login-icon.svg";
import loginIcon from "./login-icon.svg";
import styles from "./Account.module.scss";
import { openLoginModal } from "redux/modals/modalsSlice";
import { LoginModal } from "./login-modal/LoginModal";
import DefaultUserIcon from "./lost-login-icon.svg"
import DownArrowIcon from "asset/image/down_arrow_icon.svg"
import { useLogout } from "service/user/useUser";

export const Account = () => {
    const { token, thumbnailUrl, username } = useSelector(selectUser);
    const [state, setState] = useState<"close" | "signIn" | "signUp">("close");
    const dispatch = useDispatch();
    const logout = useLogout()
    const handleShowLoginModal = () => {
        dispatch(openLoginModal());
    };
    const items = [
        {
            label: "退出登录",
            key: "logout",
        },
        // {
        //     label: "云端项目",
        //     key: "cloudProject",
        // },
    ];

    const onDropdownClick = (item: DFDropdownItem) => {
        console.log("item===", item);
        if (item.key === "logout") {
            logout();
        }
    };

    const onClose = () => {
        setState("close")
    }

    // 已登录
    if (token) {
        return (
            <div className={styles.userInfo}>
                <DFDropdown
                    trigger={"click"}
                    items={items}
                    position={"right"}
                    onItemClick={onDropdownClick}
                    width={"100"}
                >
                    <div className={styles.dropdownContent}>
                        <img className={styles.avatar} src={thumbnailUrl||DefaultUserIcon}/>
                        <span >{username}</span>
                        <img src={DownArrowIcon} />
                    </div>
                </DFDropdown>
            </div>
        )
    }
    return (
        <div className={styles.loginWrapper}>
            <div className={styles.button} onClick={() => setState("signUp")}>注册</div>
            <div className={styles.button} onClick={() => setState("signIn")}>登录</div>
            {state === "signIn" && <LoginModal onClose={onClose} />}
            {state === "signUp" && <LoginModal onClose={onClose} />}
        </div>
    )
};
