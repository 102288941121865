type LibType = "extension" | "device" | "module";
export const useTags = (
  libType: LibType
): { tag: string; intlLabel: any }[] => {
  switch (libType) {
    case "extension":
      return [
        {
          tag: "functionalModule",
          intlLabel: { id: "gui.extension.function", defaultMessage: "功能模块" },
        },
        {
          tag: "internetService",
          intlLabel: { id: "gui.extension.internetService", defaultMessage: "网络服务" },
        },
      ];
    case "device":
      return [
        {
          tag: "board",
          intlLabel: { id: "gui.extension.board", defaultMessage: "主控板" },
        },
        {
          tag: "kit",
          intlLabel: { id: "gui.extension.kit", defaultMessage: "套件" }
        }
      ];
    case "module":
      return [
        {
          tag: "sensor",
          intlLabel: { id: "gui.extension.sensor", defaultMessage: "传感器" },
        },
        {
          tag: "actuator",
          intlLabel: { id: "gui.extension.actuator", defaultMessage: "执行器" },
        },
        // TODO:上传模式才会有通信模块
        // {
        //   tag: "communication",
        //   intlLabel: { id: "communication", defaultMessage: "通信模块" },
        // },
        {
          tag: "display",
          intlLabel: { id: "gui.extension.display", defaultMessage: "显示模块" },
        },
        {
          tag: "spread",
          intlLabel: { id: "gui.extension.spreadBoard", defaultMessage: "扩展板" },
        },
      ];
  }
};
