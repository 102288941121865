import React, {FC, useCallback, useEffect, useRef} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {vm} from "lib/scratch-vm";
import {selectVmStatus} from "redux/vm-status/vmStatusSlice";
import styles from './Stage.module.scss'
import { useMouseEvent } from "./util/useMouseEvent";
import { MonitorList } from "component/monitor-list";
import { Question } from "component/question";
import { TargetHighlight } from "component/target-highlight";
import { selectStageSize } from "redux/stage-size/stageSizeSlice";
import { selectMode } from "redux/mode/modeSlice";
import { getStageDimensions } from "lib/screen-utils";
import { deactivateColorPicker, selectColorPicking } from 'redux/color-picking/colorPickingSlice';
import Loupe from 'component/loupe/loupe';
import { getEventXY } from 'lib/touch-utils';
const Stage: FC = (props) => {
  const dispatch = useDispatch();
  const rectRef = useRef({ width: 0, height: 0, left: 0, top: 0 });
  const { started } = useSelector(selectVmStatus);
  const { stageSize } = useSelector(selectStageSize);
  const { isFullScreen } = useSelector(selectMode);
  const colorPicking = useSelector(selectColorPicking)
  const updateRect = useCallback(
    () => {
      rectRef.current = vm.renderer.canvas.getBoundingClientRect();
    },
    [],
  );
  const { dragCanvasRef, colorInfo } = useMouseEvent(rectRef, updateRect);

  // 监听浏览器窗口变化
  useEffect(() => {
    window.addEventListener('resize', updateRect);
    window.addEventListener('scroll', updateRect);
    return () => {
      window.removeEventListener('resize', updateRect);
      window.removeEventListener('scroll', updateRect);
    }
  }, [])

  useEffect(() => {
    const stageDimensions = getStageDimensions(stageSize, isFullScreen);
    vm.renderer.canvas.style.width = `${stageDimensions.width}px`;
    vm.renderer.canvas.style.height = `${stageDimensions.height}px`;
  }, [stageSize, isFullScreen]);


  // 舞台重新绘制
  useEffect(() => {
    updateRect();
    vm.renderer.resize(rectRef.current.width, rectRef.current.height);
  }, [started, stageSize, isFullScreen, updateRect]); //增加依赖项updateRect


  // 页面渲染出来之后, 将canvas挂载到dom上
  const onStageRef = (ref: HTMLDivElement) => {
    if (!ref) return;
    if (vm.renderer.canvas) {
      ref.appendChild(vm.renderer.canvas);
    } else {
      throw Error('renderer 未初始化')
    }
  }

  const onDoubleClick = (e) => {
    const { x, y } = getEventXY(e);
    // Set editing target from cursor position, if clicking on a sprite.
    const mousePosition = [x - rectRef.current.left, y - rectRef.current.top];
    const drawableId = vm.renderer.pick(mousePosition[0], mousePosition[1]);
    if (drawableId === null) return;
    const targetId = vm.getTargetIdForDrawableId(drawableId);
    if (targetId === null) return;
    vm.setEditingTarget(targetId);
  }
  const handleDeActivateColorPicker = () => {
    dispatch(deactivateColorPicker({ color: null }))
  }
  return (
    <>
      <div
        ref={onStageRef}
        className={`${isFullScreen ? styles.stageFullScreen : styles.stage}  ${colorPicking.active ? styles.withColorPicker : ''}`}
        onDoubleClick={onDoubleClick}
      ></div>
      <div className={`${styles.stageOverlays} ${colorPicking.active ? styles.withColorPicker : ''}`}>
        <canvas
          className={styles.draggingSprite}
          height={0}
          ref={dragCanvasRef}
          width={0}
        />
        <div className={styles.monitorWrapper}>
          <MonitorList />
        </div>
        <Question />
        <TargetHighlight />
        {colorPicking.active && colorInfo && (
          <div className={styles.colorPickerWrapper}>
            <Loupe colorInfo={colorInfo} />
          </div>
        )}
      </div>
      {colorPicking.active && (
        <div className={styles.colorPickerBackground} onClick={handleDeActivateColorPicker}></div>
      )}
    </>
  );
};

export default Stage;
