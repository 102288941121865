import { exec, spawn } from "./child_process";
import path from "path";
import { getBasePathConfig } from "../path/base";
import { commandWs } from "./websocket/commandWs";

// 打开设备管理器
export function openDM() {
  spawn("devmgmt.msc", { stdio: "inherit", shell: true });
}

// 安装驱动
export function installSerialPortDriver() {
  //TODO 兼容 mac
  const { LINKAPPROOT } = getBasePathConfig();
  let cmdStr = `"${path.join(
    LINKAPPROOT,
    "platform_asset/win/drivers/driverInstall.bat"
  )}"`;
  exec(cmdStr);
}

export function openSelectDirectoryDialog(option) {
  return commandWs.sendRemoteRequest("openSelectDirectoryDialog", option);
}
