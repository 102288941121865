// GENERATED FILE:
export default {
  "ab": {
    "CONTROL_FOREVER": "инагӡалатәуп",
    "CONTROL_REPEAT": "инагӡалатәуп %1 - нтә",
    "CONTROL_IF": "%1 акәзар",
    "CONTROL_ELSE": "акәымзар",
    "CONTROL_STOP": "иаанкылатәуп",
    "CONTROL_STOP_ALL": "зегьы",
    "CONTROL_STOP_THIS": "ари апрограмма",
    "CONTROL_STOP_OTHER": "анагӡаҩ егьырҭ ипрограммақәа",
    "CONTROL_WAIT": "иԥштәуп %1 секундк",
    "CONTROL_WAITUNTIL": "иԥштәуп %1 -нӡа",
    "CONTROL_REPEATUNTIL": "инагӡалатәуп %1 акәымзар",
    "CONTROL_WHILE": "%1 иашанаҵ",
    "CONTROL_FOREACH": "рыцԥхьаӡа %1 рзы %2 аҟны",
    "CONTROL_STARTASCLONE": "сара аклон ҳасабла саналаго",
    "CONTROL_CREATECLONEOF": "иаԥҵатәуп аклон %1",
    "CONTROL_CREATECLONEOF_MYSELF": "сара исеиԥшу",
    "CONTROL_DELETETHISCLONE": "ианыхтәуп ари аклон",
    "CONTROL_COUNTER": "аԥхьаӡага",
    "CONTROL_INCRCOUNTER": "аԥхьаӡага иазырҳатәуп",
    "CONTROL_CLEARCOUNTER": "ирыцқьатәуп аԥхьаӡага",
    "CONTROL_ALLATONCE": "зегьы иаразнак",
    "DATA_SETVARIABLETO": "%1 иаҭатәуп %2 ",
    "DATA_CHANGEVARIABLEBY": "иԥсахтәуп %1 %2 ала",
    "DATA_SHOWVARIABLE": "иаарԥштәуп аҽеиҭак %1",
    "DATA_HIDEVARIABLE": "иҵәахтәуп аҽеиҭак %1",
    "DATA_ADDTOLIST": "иацҵатәуп %1 %2 ахь  ",
    "DATA_DELETEOFLIST": "ианыхтәуп %1 %2 аҟынтәи ",
    "DATA_DELETEALLOFLIST": "ианыхтәуп зегьы %1 ",
    "DATA_INSERTATLIST": "%3 аҟны ибжьаргылатәуп %1 %2 аҭыԥан  ",
    "DATA_REPLACEITEMOFLIST": "%2 аҟны иԥсахтәуп аелемент %1 %3 ала  ",
    "DATA_ITEMOFLIST": "аелемент %1 %2 аҟны",
    "DATA_ITEMNUMOFLIST": "апункт # %1 аҟынтәи %2 аҟны",
    "DATA_LENGTHOFLIST": "%1 аура",
    "DATA_LISTCONTAINSITEM": "%1 иаҵанакуама %2?",
    "DATA_SHOWLIST": "иаарԥштәуп ахьӡынҵа %1",
    "DATA_HIDELIST": "иҵәахтәуп ахьӡынҵа %1",
    "DATA_INDEX_ALL": "зегьы",
    "DATA_INDEX_LAST": "аҵыхәтәантәи",
    "DATA_INDEX_RANDOM": "иарбанзаалакь",
    "EVENT_WHENFLAGCLICKED": "%1 ҳанақәыӷәӷәалакь",
    "EVENT_WHENTHISSPRITECLICKED": "анагӡаҩ ҳаниқәыӷәӷәалакь",
    "EVENT_WHENSTAGECLICKED": "асцена ҳанақәыӷәӷәалакь",
    "EVENT_WHENTOUCHINGOBJECT": "ари анагӡаҩ %1 данадкьысло",
    "EVENT_WHENBROADCASTRECEIVED": " %1 аус арура",
    "EVENT_WHENBACKDROPSWITCHESTO": "аҿаԥшыра %1 ала аҽанаԥсахуа ",
    "EVENT_WHENGREATERTHAN": "%1 > %2 акәзар",
    "EVENT_WHENGREATERTHAN_TIMER": "аамҭарбага",
    "EVENT_WHENGREATERTHAN_LOUDNESS": "абжьы адура",
    "EVENT_BROADCAST": "идәықәҵатәуп %1  ",
    "EVENT_BROADCASTANDWAIT": "идәықәҵатәуп %1 нас иԥштәуп",
    "EVENT_WHENKEYPRESSED": "аклавиша %1 ҳанақәыӷәӷәалакь",
    "EVENT_WHENKEYPRESSED_SPACE": "абжьажьга",
    "EVENT_WHENKEYPRESSED_LEFT": "ахыц арӷьарахь",
    "EVENT_WHENKEYPRESSED_RIGHT": "ахыц армарахь",
    "EVENT_WHENKEYPRESSED_DOWN": "ахыц алада",
    "EVENT_WHENKEYPRESSED_UP": "ахыц аҩада",
    "EVENT_WHENKEYPRESSED_ANY": "иарбанзаалакь",
    "LOOKS_SAYFORSECS": "иҳәалатәуп %1 %2 секундк  ",
    "LOOKS_SAY": "иҳәатәуп %1 ",
    "LOOKS_HELLO": "Салам!",
    "LOOKS_THINKFORSECS": "ахәыцра %1 %2  секундк ",
    "LOOKS_THINK": "ахәыцра %1 ",
    "LOOKS_HMM": "М-м-м...",
    "LOOKS_SHOW": "ацәырҵра",
    "LOOKS_HIDE": "аҽыҵәахра",
    "LOOKS_HIDEALLSPRITES": "иҵәахтәуп анагӡаҩцәа зегьы",
    "LOOKS_EFFECT_COLOR": "аԥштәы",
    "LOOKS_EFFECT_FISHEYE": "аԥсыӡ аблақәа",
    "LOOKS_EFFECT_WHIRL": "акьаҳәхара",
    "LOOKS_EFFECT_PIXELATE": "апиксельқәа рыԥсахра",
    "LOOKS_EFFECT_MOSAIC": "амозаика",
    "LOOKS_EFFECT_BRIGHTNESS": "ажжара",
    "LOOKS_EFFECT_GHOST": "алаԥшҵашәара",
    "LOOKS_CHANGEEFFECTBY": "иԥсахтәуп аеффект %1  %2 ала  ",
    "LOOKS_SETEFFECTTO": "иқәыргылатәуп аеффект %1 %2",
    "LOOKS_CLEARGRAPHICEFFECTS": "иқәгатәуп аграфикатә еффектқәа",
    "LOOKS_CHANGESIZEBY": "иԥсахтәуп ашәагаа %1 ала",
    "LOOKS_SETSIZETO": "иқәыргылатәуп ашәагаа  %1 % ",
    "LOOKS_SIZE": "ашәагаа",
    "LOOKS_CHANGESTRETCHBY": "иԥсахтәуп арххара %1 ала ",
    "LOOKS_SETSTRETCHTO": "иқыргылатәуп арххара %1",
    "LOOKS_SWITCHCOSTUMETO": "иԥсахтәуп акостиум %1 ала",
    "LOOKS_NEXTCOSTUME": "анаҩстәи акостиум",
    "LOOKS_SWITCHBACKDROPTO": "иԥсахтәуп аҿаԥшыра %1 ала",
    "LOOKS_GOTOFRONTBACK": "ииастәуп %1 аҿыгҳара ахь ",
    "LOOKS_GOTOFRONTBACK_FRONT": "аԥхьаҟатәи",
    "LOOKS_GOTOFRONTBACK_BACK": "ашьҭахьҟатәи",
    "LOOKS_GOFORWARDBACKWARDLAYERS": "ииастәуп %1 %2 ҿыгҳарак ",
    "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "ԥхьаҟа",
    "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "шьҭахьҟа",
    "LOOKS_BACKDROPNUMBERNAME": "аҿаԥшыра %1 ",
    "LOOKS_COSTUMENUMBERNAME": "акостиум %1",
    "LOOKS_NUMBERNAME_NUMBER": "аномер",
    "LOOKS_NUMBERNAME_NAME": "ахьӡ",
    "LOOKS_SWITCHBACKDROPTOANDWAIT": "иԥсахтәуп аҿаԥшыра %1 ала  нас иԥштәуп ",
    "LOOKS_NEXTBACKDROP_BLOCK": "анаҩстәи аҿаԥшыра",
    "LOOKS_NEXTBACKDROP": "анаҩстәи аҿаԥшыра",
    "LOOKS_PREVIOUSBACKDROP": "аԥхьатәи аҿаԥшыра",
    "LOOKS_RANDOMBACKDROP": "иарбанзаалакь аҿаԥшыра",
    "MOTION_MOVESTEPS": "ицатәуп %1 шьаҿак",
    "MOTION_TURNLEFT": "иргьежьтәуп  %1 %2 градус рыла ",
    "MOTION_TURNRIGHT": "иргьежьтәуп %1 %2 градус рыла ",
    "MOTION_POINTINDIRECTION": "инарҳәтәуп %1 ахь",
    "MOTION_POINTTOWARDS": "%1 ахь инаҳәтәуп",
    "MOTION_POINTTOWARDS_POINTER": "аҳәынаԥ арбага",
    "MOTION_POINTTOWARDS_RANDOM": "машәыршақәтәи ахырхарҭа",
    "MOTION_GOTO": "ииастәуп %1 ахь",
    "MOTION_GOTO_POINTER": "аҳәынаԥ арбага",
    "MOTION_GOTO_RANDOM": "иарбанзаалакь апозициа",
    "MOTION_GOTOXY": "ииастәуп x: %1 y: %2 ахь ",
    "MOTION_GLIDESECSTOXY": "иқәҵәраатәуп x: %2 y: %3 ахь %1 секундк ",
    "MOTION_GLIDETO": "иқәҵәраатәуп %2 ахь %1 секундк  ",
    "MOTION_GLIDETO_POINTER": "аҳәынаԥ арбага",
    "MOTION_GLIDETO_RANDOM": "иарбанзаалакь апозициа",
    "MOTION_CHANGEXBY": "иԥсахтәуп x %1 ала   ",
    "MOTION_SETX": "иқәыргылатәуп x %1 аҟны  ",
    "MOTION_CHANGEYBY": "иԥсахтәуп y %1 ала ",
    "MOTION_SETY": "иқәыргылатәуп y %1 аҟны ",
    "MOTION_IFONEDGEBOUNCE": "акьыԥшь акәзар, иадԥатәуп",
    "MOTION_SETROTATIONSTYLE": "иқәыргылатәуп аргьежьра астиль %1 ",
    "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "армарахь-арӷьарахь",
    "MOTION_SETROTATIONSTYLE_DONTROTATE": "иргьежьтәӡам",
    "MOTION_SETROTATIONSTYLE_ALLAROUND": "иргьежьтәуп",
    "MOTION_XPOSITION": "x аҭыԥ",
    "MOTION_YPOSITION": "y аҭыԥ",
    "MOTION_DIRECTION": "ахырхарҭа",
    "MOTION_SCROLLRIGHT": "иҭарҵәитәуп арӷьарахь %1",
    "MOTION_SCROLLUP": "иҭарҵәитәуп хыхь %1",
    "MOTION_ALIGNSCENE": "еиҟаратәтәуп асцена %1",
    "MOTION_ALIGNSCENE_BOTTOMLEFT": "ҵаҟа-армарахьтәи",
    "MOTION_ALIGNSCENE_BOTTOMRIGHT": "ҵаҟа-арӷьарахьтәи",
    "MOTION_ALIGNSCENE_MIDDLE": "агәҭа",
    "MOTION_ALIGNSCENE_TOPLEFT": "хыхьтәи-армарахьтәи",
    "MOTION_ALIGNSCENE_TOPRIGHT": "хыхьтәи-арӷьарахьтәи",
    "MOTION_XSCROLL": "горизонталла аҭаргьежьра",
    "MOTION_YSCROLL": "вертикалла аҭаргьежьра",
    "MOTION_STAGE_SELECTED": "Асцена алхуп: иҟам аиҭаҵратә блокқәа",
    "OPERATORS_ADD": "%1 + %2",
    "OPERATORS_SUBTRACT": "%1 - %2",
    "OPERATORS_MULTIPLY": "%1 * %2",
    "OPERATORS_DIVIDE": "%1 / %2",
    "OPERATORS_RANDOM": "иарбанзаалакь %1 -и %2 -и рыбжьара  ",
    "OPERATORS_GT": "%1 > %2",
    "OPERATORS_GT_EQUALS": "",
    "OPERATORS_LT": "%1 < %2",
    "OPERATORS_LT_EQUALS": "",
    "OPERATORS_EQUALS": "%1 = %2",
    "OPERATORS_AND": "%1 - еи  %2 - еи",
    "OPERATORS_OR": "%1 ма %2",
    "OPERATORS_NOT": "%1 акәӡам",
    "OPERATORS_JOIN": "еиԥшьтәуп %1 %2 ",
    "OPERATORS_JOIN_APPLE": "аҵәа",
    "OPERATORS_JOIN_BANANA": "абанан",
    "OPERATORS_LETTEROF": "асимвол %1 %2 аҟны",
    "OPERATORS_LETTEROF_APPLE": "a",
    "OPERATORS_LENGTH": "%1 аура",
    "OPERATORS_CONTAINS": "%1 иаҵанакуама %2?",
    "OPERATORS_MOD": "%1 mod %2",
    "OPERATORS_ROUND": "ихаргьежьтәуп %1 ",
    "OPERATORS_MATHOP": "%1 %2",
    "OPERATORS_MATHOP_ABS": "abs",
    "OPERATORS_MATHOP_FLOOR": "еиҵоу еибгоу",
    "OPERATORS_MATHOP_CEILING": "еиҳау еибгоу",
    "OPERATORS_MATHOP_SQRT": "sqrt",
    "OPERATORS_MATHOP_SIN": "sin",
    "OPERATORS_MATHOP_COS": "cos",
    "OPERATORS_MATHOP_TAN": "tan",
    "OPERATORS_MATHOP_ASIN": "asin",
    "OPERATORS_MATHOP_ACOS": "acos",
    "OPERATORS_MATHOP_ATAN": "atan",
    "OPERATORS_MATHOP_LN": "ln",
    "OPERATORS_MATHOP_LOG": "log",
    "OPERATORS_MATHOP_EEXP": "e ^",
    "OPERATORS_MATHOP_10EXP": "10 ^",
    "PROCEDURES_DEFINITION": "еиҿкаатәуп %1",
    "SENSING_TOUCHINGOBJECT": "иадкьыслома %1?  ",
    "SENSING_TOUCHINGOBJECT_POINTER": "аҳәынаԥ арбага",
    "SENSING_TOUCHINGOBJECT_EDGE": "аҵкарқәа",
    "SENSING_TOUCHINGCOLOR": "иадкьыслома аԥштәы %1? ",
    "SENSING_COLORISTOUCHINGCOLOR": "аԥштәы %1 иадкьыслома %2?  ",
    "SENSING_DISTANCETO": "%1 аҟынӡа ибжьоу",
    "SENSING_DISTANCETO_POINTER": "аҳәынаԥ арбага",
    "SENSING_ASKANDWAIT": "иазҵаатәуп %1 нас иԥштәуп ",
    "SENSING_ASK_TEXT": "Иухьӡуи уара?",
    "SENSING_ANSWER": "аҭак",
    "SENSING_KEYPRESSED": "аклавиша %1  иақәыӷәӷәома? ",
    "SENSING_MOUSEDOWN": "аҳәынаԥ иақәыӷәӷәома?",
    "SENSING_MOUSEX": "аҳәынаԥ x ала",
    "SENSING_MOUSEY": "аҳәынаԥ y ала",
    "SENSING_SETDRAGMODE": "анаскьагара ауама %1 ",
    "SENSING_SETDRAGMODE_DRAGGABLE": "ааи",
    "SENSING_SETDRAGMODE_NOTDRAGGABLE": "мап",
    "SENSING_LOUDNESS": "абжьы адура",
    "SENSING_LOUD": "абжьы дуума?",
    "SENSING_TIMER": "аамҭарбага",
    "SENSING_RESETTIMER": "аамҭарбага ҿыц адәықәҵара",
    "SENSING_OF": "%1  %2 аҟынтәи",
    "SENSING_OF_XPOSITION": "x аҭыԥ",
    "SENSING_OF_YPOSITION": "y аҭыԥ",
    "SENSING_OF_DIRECTION": "ахырхарҭа",
    "SENSING_OF_COSTUMENUMBER": "акостиум №",
    "SENSING_OF_COSTUMENAME": "акостиум ахьӡ",
    "SENSING_OF_SIZE": "ашәагаа",
    "SENSING_OF_VOLUME": "абжьы адура",
    "SENSING_OF_BACKDROPNUMBER": "аҿаԥшыра №",
    "SENSING_OF_BACKDROPNAME": "аҿаԥшыра ахьӡ",
    "SENSING_OF_STAGE": "Асцена",
    "SENSING_CURRENT": "уажәтәи %1 ",
    "SENSING_CURRENT_YEAR": "ашықәс",
    "SENSING_CURRENT_MONTH": "амза",
    "SENSING_CURRENT_DATE": "арыцхә",
    "SENSING_CURRENT_DAYOFWEEK": "амчыбжь амш",
    "SENSING_CURRENT_HOUR": "асааҭ",
    "SENSING_CURRENT_MINUTE": "аминуҭ",
    "SENSING_CURRENT_SECOND": "асекунд",
    "SENSING_DAYSSINCE2000": "амшқәа 01.01.2000 инаркны ",
    "SENSING_USERNAME": "алахәылаҩ ихьӡ",
    "SENSING_USERID": "Алахәылаҩ ID",
    "SOUND_PLAY": "иргатәуп абжьы %1 ",
    "SOUND_PLAYUNTILDONE": "иргатәуп абжьы %1 инҵәаанӡа ",
    "SOUND_STOPALLSOUNDS": "иаанкылатәуп абжьқәа зегьы",
    "SOUND_SETEFFECTO": "иқәыргылатәуп аеффект %1  %2 аҟны  ",
    "SOUND_CHANGEEFFECTBY": "иԥсахтәуп  %1 аеффект %2 ала  ",
    "SOUND_CLEAREFFECTS": "иқәгатәуп абжьы аеффектқәа",
    "SOUND_EFFECTS_PITCH": "атемп",
    "SOUND_EFFECTS_PAN": "апанорама армарахь/арӷьарахь",
    "SOUND_CHANGEVOLUMEBY": "иԥсахтәуп абжьы %1 ала ",
    "SOUND_SETVOLUMETO": "иқәыргылатәуп абжьы адура %1%",
    "SOUND_VOLUME": "абжьы адура",
    "SOUND_RECORD": "аҭаҩра...",
    "CATEGORY_MOTION": "Аиҭаҵра",
    "CATEGORY_LOOKS": "Аԥшра-асахьа",
    "CATEGORY_SOUND": "Абжьы",
    "CATEGORY_EVENTS": "Ахҭысқәа",
    "CATEGORY_CONTROL": "Аконтроль",
    "CATEGORY_SENSING": "Асенсорқәа",
    "CATEGORY_OPERATORS": "Аоператорқәа",
    "CATEGORY_VARIABLES": "Аҽеиҭакқәа",
    "CATEGORY_MYBLOCKS": "Сара сблокқәа",
    "CATEGORY_LISTS": "",
    "CATEGORY_TUPLE": "",
    "CATEGORY_DICTIONARYS": "",
    "CATEGORY_NUMBERS": "",
    "CATEGORY_TEXT": "",
    "DUPLICATE": "Адубликат",
    "DELETE": "Ианыхтәуп",
    "ADD_COMMENT": "Иацҵатәуп акомментари",
    "REMOVE_COMMENT": "Ианыхтәуп акомментари",
    "DELETE_BLOCK": "Ианыхтәуп аблок",
    "DELETE_X_BLOCKS": "Ианыхтәуп %1 блокк ",
    "DELETE_ALL_BLOCKS": "Ианыхтәума аблокқәа %1 зегьы?",
    "CLEAN_UP": "Ирыцқьатәуп аблокқәа",
    "HELP": "Ацхыраара",
    "UNDO": "Иаҟәыхтәуп",
    "REDO": "Ирхынҳәтәуп",
    "EDIT_PROCEDURE": "Ариашара",
    "SHOW_PROCEDURE_DEFINITION": "Ииастәуп аиқәыршәарахь",
    "WORKSPACE_COMMENT_DEFAULT_TEXT": "Иҳәатәуп џьара акы...",
    "COLOUR_HUE_LABEL": "Аԥштәы",
    "COLOUR_SATURATION_LABEL": "Аԥштәылгазаара",
    "COLOUR_BRIGHTNESS_LABEL": "Ажжара",
    "CHANGE_VALUE_TITLE": "Иԥсахтәуп аҵакы:",
    "RENAME_VARIABLE": "Аҽеиҭак ахьӡ ԥсахтәуп",
    "RENAME_VARIABLE_STRING": "",
    "RENAME_VARIABLE_NUMBER": "",
    "RENAME_VARIABLE_LIST": "",
    "RENAME_VARIABLE_TITLE": "Аҽеиҭакқәа \"%1\" зегь рыхьӡ ԥсахтәуп аҟны:",
    "RENAME_VARIABLE_MODAL_TITLE": "Аҽеиҭак ахьӡ ԥсахтәуп",
    "NEW_VARIABLE": "Иаԥҵатәуп аҽеиҭак",
    "NEW_VARIABLE_TITLE": "Аҽеиҭак ахьӡ ҿыц:",
    "VARIABLE_MODAL_TITLE": "Аҽеиҭак ҿыц",
    "VARIABLE_ALREADY_EXISTS": "Аҽеиҭак  ахьӡ \"%1\" змоу ыҟоуп.",
    "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "Аҽеиҭак ахьӡ \"%1\" змоу ыҟоуп аҽеиҭакқәа ртип \"%2\" аҟны.  ",
    "DELETE_VARIABLE_CONFIRMATION": "Ианыхтәума алахәрақәа %1  аҽеиҭак \"%2\" азы?",
    "CANNOT_DELETE_VARIABLE_PROCEDURE": "Иауам аҽеиҭак \"%1\" аныхра, избанзар иара афункциа \"%2\" ашьқәыргылара иахәҭакуп ",
    "DELETE_VARIABLE": "Ианыхтәуп аҽеиҭак \"%1\"",
    "DELETE_VARIABLE_STRING": "",
    "DELETE_VARIABLE_NUMBER": "",
    "DELETE_VARIABLE_LIST": "",
    "NEW_PROCEDURE": "Иаԥҵатәуп аблок",
    "PROCEDURE_ALREADY_EXISTS": "Апроцедура ахьӡ \"%1\" змоу ыҟоуп.",
    "PROCEDURE_DEFAULT_NAME": "аблок ахьӡ",
    "NEW_LIST": "Ахьӡынҵа аԥҵара",
    "NEW_LIST_TITLE": "Ахьӡынҵа ахьӡ ҿыц:",
    "LIST_MODAL_TITLE": "Ахьӡынҵа ҿыц",
    "LIST_ALREADY_EXISTS": "Ахьӡынҵа ахьӡ \"%1\" змоу ыҟоуп.",
    "RENAME_LIST_TITLE": "Ахьӡынҵақәа \"%1\" зегьы рыхьӡ ԥсахтәуп аҟны:",
    "RENAME_LIST_MODAL_TITLE": "Ахьӡынҵа ахьӡ ԥсахтәуп",
    "DEFAULT_LIST_ITEM": "џьара акы",
    "DELETE_LIST": "Ианыхтәуп ахьӡынҵа \"%1\"",
    "RENAME_LIST": "Ахьӡынҵа ахьӡ ԥсахтәуп",
    "NEW_BROADCAST_MESSAGE": "Ацҳамҭа ҿыц",
    "NEW_BROADCAST_MESSAGE_TITLE": "Ацҳамҭа ҿыц ахьӡ:",
    "BROADCAST_MODAL_TITLE": "Ацҳамҭа ҿыц",
    "DEFAULT_BROADCAST_MESSAGE_NAME": "ацҳамҭа1",
    "DELETE_PROCEDURE": "",
    "OK": "",
    "PROMPT": "",
    "STOP": "",
    "NEW_STRING": "",
    "NEW_NUMBER_VARIABLE": "",
    "WIFI": "",
    "USERNAME": "",
    "PWD": "",
    "IOT_SERVICE": "",
    "IOT_ID": "",
    "IOT_PWD": "",
    "IOT_TOPIC": "",
    "MORE_TOPIC": "",
    "OBLOQ_HTTP": "",
    "OBLOQ_IP": "",
    "OBLOQ_PORT": "",
    "ADD_TOPIC": "",
    "DELETE_TOPIC": "",
    "IOT_SERVER": "",
    "CHINA": "",
    "GLOBAL": "",
    "LOW_C": "",
    "LOW_C$": "",
    "LOW_D": "",
    "LOW_D$": "",
    "LOW_E": "",
    "LOW_F": "",
    "LOW_F$": "",
    "LOW_G": "",
    "LOW_G$": "",
    "LOW_A": "",
    "LOW_A$": "",
    "LOW_B": "",
    "MIDDLE_C": "",
    "MIDDLE_C$": "",
    "MIDDLE_D": "",
    "MIDDLE_D$": "",
    "MIDDLE_E": "",
    "MIDDLE_F": "",
    "MIDDLE_F$": "",
    "MIDDLE_G": "",
    "MIDDLE_G$": "",
    "MIDDLE_A": "",
    "MIDDLE_A$": "",
    "MIDDLE_B": "",
    "HIGH_C": "",
    "HIGH_C$": "",
    "HIGH_D": "",
    "HIGH_D$": "",
    "HIGH_E": "",
    "HIGH_F": "",
    "HIGH_F$": "",
    "HIGH_G": "",
    "HIGH_G$": "",
    "HIGH_A": "",
    "HIGH_A$": "",
    "HIGH_B": "",
    "OPERATORS_STRINGTONUMBER": "",
    "INTEGER": "",
    "DECIMAL": "",
    "OPERATORS_NUMTOASCII": "",
    "OPERATORS_STRINGTOASCII": "",
    "OPERATORS_NUMTOSTRING": "",
    "OPERATORS_MAP": "",
    "OPERATORS_CONSTRAIN": "",
    "STEPS_PER_TURN": "",
    "ROTATE_SPEED": "",
    "NEW_AI": "",
    "ASK_AND_PAINT": "",
    "GET_IDENTIFIED_NUM": "",
    "READ_NUM_AND_SAY": "",
    "IMAGE_ADDR": "",
    "IMAGE_PREVIEW": "",
    "IMAGE_OPEN": "",
    "IMAGE_SIZE": "",
    "IMAGE_WIDTH": "",
    "IMAGE_HEIGHT": "",
    "VARIABLE": "",
    "VARIABLE_LIST": "",
    "SET_STRING_VARIABLE": "",
    "STRING_START_WITH": "",
    "DICTIONARY_HEIGHT_FIRST": "",
    "DICTIONARY_HEIGHT_TWO": "",
    "DICTIONARY_HEIGHT_THREE": "",
    "DICTIONARY_HEIGHT_FOUR": "",
    "DATA_MPITEMOFLIST": "",
    "DATA_MPINTERCEPTITEM": "",
    "DATA_MPINSERTATLIST": "",
    "DATA_MPSETVALUEATLIST": "",
    "DATA_MPINSERTATLIST_INSERT": "",
    "DATA_MPINSERTATLIST_CHANGE": "",
    "DATA_MPINSERTATLIST_FOUR": "",
    "DATA_MPINSERTATLIST_FIVE": "",
    "DATA_MPINSERTATLIST_SIX": "",
    "DATA_MPINSERTATLIST_SEVEN": "",
    "DATA_MPINSERTATLIST_EIGHT": "",
    "DATA_MPINSERTATLIST_EIGHT_FALSE": "",
    "DATA_MPINSERTATLIST_EIGHT_TRUE": "",
    "DATA_MPINSERTATLIST_EIGHT_REVERSE": "",
    "OPERATORS_RANDOMA": "",
    "OPERATORS_MODA": "",
    "OPERATORS_ROUNDA": "",
    "OPERATORS_MATHOPA": "",
    "OPERATORS_MATHOP_ABSA": "",
    "OPERATORS_MATHOP_FLOORA": "",
    "OPERATORS_MATHOP_CEILINGA": "",
    "OPERATORS_MATHOP_SQRTA": "",
    "OPERATORS_MATHOP_FIVE": "",
    "OPERATORS_MATHOP_SIX": "",
    "OPERATORS_MATHOP_SEVEN": "",
    "OPERATORS_MATHOP_LENGHT": "",
    "OPERATORS_MATHOP_MAXMIN": "",
    "OPERATORS_MATHOP_MAXMIN_MAX": "",
    "OPERATORS_MATHOP_MAXMIN_MIN": "",
    "OPERATORS_TEXTS_ONE": "",
    "OPERATORS_TEXTS_TWO": "",
    "OPERATORS_TEXTS_THREE": "",
    "OPERATORS_TEXTS_FOUR": "",
    "OPERATORS_TEXTS_FOUR_NEW": "",
    "OPERATORS_TEXTS_NEW": "",
    "OPERATORS_TEXTS_NEW_OP": "",
    "OPERATORS_TEXTS_FOUR_ONE": "",
    "OPERATORS_TEXTS_FOUR_TWO": "",
    "OPERATORS_TEXTS_FOUR_THREE": "",
    "OPERATORS_TEXTS_FOUR_FOUR": "",
    "OPERATORS_TEXTS_FOUR_F": "",
    "OPERATORS_TEXTS_FOUR_L": "",
    "OPERATORS_STRINGTONUMBERA": "",
    "OPERATORS_NUMTOASCIIA": "",
    "OPERATORS_STRINGTOASCIIA": "",
    "OPERATORS_NUMTOSTRINGA": "",
    "NEW_MC": "",
    "RENAME_MCNUMBER_TITLE": "",
    "RENAME_MCNUMBER_MODAL_TITLE": "",
    "RENAME_VARIABLE_MCNUMBER": "",
    "DELETE_VARIABLE_MCNUMBER": "",
    "SET_MC_VARIABLE": "",
    "TUPLE_ONE": "",
    "TUPLE_TWO": "",
    "ALIYUN": "",
    "ONENET": "",
    "EASYIOT": "",
    "SHANGHAI": "",
    "QINGDAO": "",
    "BEIJING": "",
    "ZHANGJIAKOU": "",
    "HUHEHAOTE": "",
    "HANGZHOU": "",
    "SHENZHEN": "",
    "HONGKONG": "",
    "SINGAPORE": "",
    "SYDNEY": "",
    "KUALALUMPUR": "",
    "JAKARTA": "",
    "MUMBAI": "",
    "TOKYO": "",
    "SILICONVALLEY": "",
    "VIRGINIA": "",
    "FRANKFURT": "",
    "LONDON": "",
    "DUBAI": "",
    "IOT_PLATFORM": "",
    "PARAMS": "",
    "SERVER_ATTR": "",
    "PRODUCTID": "",
    "DEVICEID": "",
    "TINYDB_SECRET": "",
    "TINYDB_USER": "",
    "APIADDR": "",
    "SOFTWARE_SERIAL": "",
    "HARDWARE_SERIAL": "",
    "HARDWARE_SERIAL1": "",
    "HARDWARE_SERIAL2": "",
    "HARDWARE_SERIAL3": ""
  },
  "ar": {
    "CONTROL_FOREVER": "كرِّر باستمرار",
    "CONTROL_REPEAT": "كرِّر %1 مرة",
    "CONTROL_IF": "إذا %1",
    "CONTROL_ELSE": "وإلا",
    "CONTROL_STOP": "أوقف",
    "CONTROL_STOP_ALL": "الكل",
    "CONTROL_STOP_THIS": "المقطع البرمجي الحالي",
    "CONTROL_STOP_OTHER": "المقاطع الأخرى في الكائن",
    "CONTROL_WAIT": "انتظر %1 ثانية",
    "CONTROL_WAITUNTIL": "انتظر حتى %1",
    "CONTROL_REPEATUNTIL": "كرِّر حتى %1",
    "CONTROL_WHILE": "طالما %1",
    "CONTROL_FOREACH": "من أجل %1 من %2",
    "CONTROL_STARTASCLONE": "عندما تبدأ نسخة مني",
    "CONTROL_CREATECLONEOF": "أنشئ نسخة من %1",
    "CONTROL_CREATECLONEOF_MYSELF": "نفسي",
    "CONTROL_DELETETHISCLONE": "احذف هذه النسخة",
    "CONTROL_COUNTER": "عداد",
    "CONTROL_INCRCOUNTER": "زد العداد",
    "CONTROL_CLEARCOUNTER": "صفِّر العداد",
    "CONTROL_ALLATONCE": "الكل مرة واحدة",
    "DATA_SETVARIABLETO": "اجعل %1 مساويًا %2",
    "DATA_CHANGEVARIABLEBY": "غيِّر %1 بمقدار %2",
    "DATA_SHOWVARIABLE": "أظهر المتغير %1",
    "DATA_HIDEVARIABLE": "أخفِ المتغير %1",
    "DATA_ADDTOLIST": "أضف %1 إلى %2",
    "DATA_DELETEOFLIST": "احذف %1 من %2",
    "DATA_DELETEALLOFLIST": "احذف كل العناصر في %1",
    "DATA_INSERTATLIST": "أدرج %1 في الموقع %2 من %3",
    "DATA_REPLACEITEMOFLIST": "استبدل %3 بالعنصر %1 من %2",
    "DATA_ITEMOFLIST": "العنصر %1 من %2",
    "DATA_ITEMNUMOFLIST": "رقم العنصر ذي القيمة %1 في %2",
    "DATA_LENGTHOFLIST": "طول %1",
    "DATA_LISTCONTAINSITEM": "%1 تحتوي %2؟",
    "DATA_SHOWLIST": "أظهر اللائحة %1",
    "DATA_HIDELIST": "أخفِ اللائحة %1",
    "DATA_INDEX_ALL": "الكل",
    "DATA_INDEX_LAST": "الأخير",
    "DATA_INDEX_RANDOM": "عشوائي",
    "EVENT_WHENFLAGCLICKED": "عند نقر %1",
    "EVENT_WHENTHISSPRITECLICKED": "عند نقر هذا الكائن",
    "EVENT_WHENSTAGECLICKED": "عند نقر المنصة",
    "EVENT_WHENTOUCHINGOBJECT": "عندما يلامس هذا الكائن %1",
    "EVENT_WHENBROADCASTRECEIVED": "عندما أتلقى %1",
    "EVENT_WHENBACKDROPSWITCHESTO": "عندما تتبدل الخلفية إلى %1",
    "EVENT_WHENGREATERTHAN": "عندما %1 > %2",
    "EVENT_WHENGREATERTHAN_TIMER": "المؤقت",
    "EVENT_WHENGREATERTHAN_LOUDNESS": "الضجيج",
    "EVENT_BROADCAST": "بث %1",
    "EVENT_BROADCASTANDWAIT": "بث %1 وانتظر",
    "EVENT_WHENKEYPRESSED": "عند ضغط مفتاح %1",
    "EVENT_WHENKEYPRESSED_SPACE": "المسافة",
    "EVENT_WHENKEYPRESSED_LEFT": "السهم الأيسر",
    "EVENT_WHENKEYPRESSED_RIGHT": "السهم الأيمن",
    "EVENT_WHENKEYPRESSED_DOWN": "السهم السفلي",
    "EVENT_WHENKEYPRESSED_UP": "السهم العلوي",
    "EVENT_WHENKEYPRESSED_ANY": "أي مفتاح",
    "LOOKS_SAYFORSECS": "قل %1 لمدة %2 ثانية",
    "LOOKS_SAY": "قل %1",
    "LOOKS_HELLO": "السلام عليكم!",
    "LOOKS_THINKFORSECS": "فكِّر %1 لمدة %2 ثانية",
    "LOOKS_THINK": "فكِّر %1",
    "LOOKS_HMM": "هممم...",
    "LOOKS_SHOW": "اظهر",
    "LOOKS_HIDE": "اختفِ",
    "LOOKS_HIDEALLSPRITES": "أخفِ كل الكائنات",
    "LOOKS_EFFECT_COLOR": "اللون",
    "LOOKS_EFFECT_FISHEYE": "عين السمكة",
    "LOOKS_EFFECT_WHIRL": "الدوامة",
    "LOOKS_EFFECT_PIXELATE": "البكسلة",
    "LOOKS_EFFECT_MOSAIC": "الموزاييك",
    "LOOKS_EFFECT_BRIGHTNESS": "شدة الإضاءة",
    "LOOKS_EFFECT_GHOST": "الشبح",
    "LOOKS_CHANGEEFFECTBY": "غيِّر مؤثر %1 بمقدار %2",
    "LOOKS_SETEFFECTTO": "اجعل مؤثر %1 مساويًا %2",
    "LOOKS_CLEARGRAPHICEFFECTS": "أزل المؤثرات الرسومية",
    "LOOKS_CHANGESIZEBY": "غيِّر الحجم بمقدار %1",
    "LOOKS_SETSIZETO": "اجعل الحجم مساويًا %1 ٪",
    "LOOKS_SIZE": "الحجم",
    "LOOKS_CHANGESTRETCHBY": "غيِّر المط بمقدار %1",
    "LOOKS_SETSTRETCHTO": "اجعل المط مساويًا %1 ٪",
    "LOOKS_SWITCHCOSTUMETO": "غيِّر المظهر إلى %1",
    "LOOKS_NEXTCOSTUME": "المظهر التالي",
    "LOOKS_SWITCHBACKDROPTO": "غيِّر الخلفية إلى %1",
    "LOOKS_GOTOFRONTBACK": "انتقل إلى الطبقة %1",
    "LOOKS_GOTOFRONTBACK_FRONT": "الأولى",
    "LOOKS_GOTOFRONTBACK_BACK": "الأخيرة",
    "LOOKS_GOFORWARDBACKWARDLAYERS": "انتقل إلى %1 %2 طبقة",
    "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "الأمام",
    "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "الخلف",
    "LOOKS_BACKDROPNUMBERNAME": "%1 الخلفية",
    "LOOKS_COSTUMENUMBERNAME": "%1 المظهر",
    "LOOKS_NUMBERNAME_NUMBER": "رقم",
    "LOOKS_NUMBERNAME_NAME": "اسم",
    "LOOKS_SWITCHBACKDROPTOANDWAIT": "غيِّر الخلفية إلى %1 وانتظر",
    "LOOKS_NEXTBACKDROP_BLOCK": "الخلفية التالية",
    "LOOKS_NEXTBACKDROP": "الخلفية التالية",
    "LOOKS_PREVIOUSBACKDROP": "الخلفية السابقة",
    "LOOKS_RANDOMBACKDROP": "خلفية عشوائية",
    "MOTION_MOVESTEPS": "تحرك %1 خطوة",
    "MOTION_TURNLEFT": "استدر %1 %2 درجة",
    "MOTION_TURNRIGHT": "استدر %1 %2 درجة",
    "MOTION_POINTINDIRECTION": "اتجه نحو الاتجاه %1",
    "MOTION_POINTTOWARDS": "اتجه نحو %1",
    "MOTION_POINTTOWARDS_POINTER": "مؤشر الفأرة",
    "MOTION_POINTTOWARDS_RANDOM": "اتجاه عشوائي",
    "MOTION_GOTO": "اذهب إلى %1",
    "MOTION_GOTO_POINTER": "مؤشر الفأرة",
    "MOTION_GOTO_RANDOM": "موضع عشوائي",
    "MOTION_GOTOXY": "اذهب إلى الموضع س: %1 ص: %2",
    "MOTION_GLIDESECSTOXY": "انزلق خلال %1 ثانية إلى الموضع س: %2 ص: %3",
    "MOTION_GLIDETO": "انزلق خلال %1 ثانية إلى %2",
    "MOTION_GLIDETO_POINTER": "مؤشر الفأرة",
    "MOTION_GLIDETO_RANDOM": "موضع عشوائي",
    "MOTION_CHANGEXBY": "غيِّر الموضع س بمقدار %1",
    "MOTION_SETX": "اجعل الموضع س مساويًا %1",
    "MOTION_CHANGEYBY": "غيِّر الموضع ص بمقدار %1",
    "MOTION_SETY": "اجعل الموضع ص مساويًا %1",
    "MOTION_IFONEDGEBOUNCE": "ارتد إذا كنت عند الحافة",
    "MOTION_SETROTATIONSTYLE": "اجعل نمط الدوران %1",
    "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "يمين - يسار",
    "MOTION_SETROTATIONSTYLE_DONTROTATE": "لا دوران",
    "MOTION_SETROTATIONSTYLE_ALLAROUND": "في جميع الاتجاهات",
    "MOTION_XPOSITION": "الموضع س",
    "MOTION_YPOSITION": "الموضع ص",
    "MOTION_DIRECTION": "الاتجاه",
    "MOTION_SCROLLRIGHT": "انزلق يمينًا %1",
    "MOTION_SCROLLUP": "انزلق للأعلى %1",
    "MOTION_ALIGNSCENE": "حاذِ المشهد %1",
    "MOTION_ALIGNSCENE_BOTTOMLEFT": "أسفل اليسار",
    "MOTION_ALIGNSCENE_BOTTOMRIGHT": "أسفل اليمين",
    "MOTION_ALIGNSCENE_MIDDLE": "المنتصف",
    "MOTION_ALIGNSCENE_TOPLEFT": "أعلى اليسار",
    "MOTION_ALIGNSCENE_TOPRIGHT": "أعلى اليمين",
    "MOTION_XSCROLL": "تمرير  على محور س",
    "MOTION_YSCROLL": "تمرير على محور ص",
    "MOTION_STAGE_SELECTED": "المنصة محددة: لا توجد لبنات للحركة",
    "OPERATORS_ADD": "%1 + %2",
    "OPERATORS_SUBTRACT": "%1 - %2",
    "OPERATORS_MULTIPLY": "%1 × %2",
    "OPERATORS_DIVIDE": "%1 ÷ %2",
    "OPERATORS_RANDOM": "عدد عشوائي بين %1 و %2",
    "OPERATORS_GT": "%1 > %2",
    "OPERATORS_GT_EQUALS": "",
    "OPERATORS_LT": "%1 < %2",
    "OPERATORS_LT_EQUALS": "",
    "OPERATORS_EQUALS": "%1 = %2",
    "OPERATORS_AND": "%1 و %2",
    "OPERATORS_OR": "%1 أو %2",
    "OPERATORS_NOT": "ليس %1",
    "OPERATORS_JOIN": "اربط %1 %2",
    "OPERATORS_JOIN_APPLE": "تفاحة",
    "OPERATORS_JOIN_BANANA": "موزة",
    "OPERATORS_LETTEROF": "الحرف %1 من %2",
    "OPERATORS_LETTEROF_APPLE": "ت",
    "OPERATORS_LENGTH": "طول %1",
    "OPERATORS_CONTAINS": "%1 تحتوي %2؟",
    "OPERATORS_MOD": " باقي قسمة %1 على %2",
    "OPERATORS_ROUND": "تقريب %1",
    "OPERATORS_MATHOP": "%1 للقيمة %2",
    "OPERATORS_MATHOP_ABS": "القيمة المطلقة",
    "OPERATORS_MATHOP_FLOOR": "الجزء الصحيح ",
    "OPERATORS_MATHOP_CEILING": "السقف",
    "OPERATORS_MATHOP_SQRT": "الجذر التربيعي",
    "OPERATORS_MATHOP_SIN": "الجيب (sin)",
    "OPERATORS_MATHOP_COS": "جيب التمام (cos)",
    "OPERATORS_MATHOP_TAN": "الظل (tan)",
    "OPERATORS_MATHOP_ASIN": "الجيب العكسي (asin)",
    "OPERATORS_MATHOP_ACOS": "جيب التمام العكسي (acos)",
    "OPERATORS_MATHOP_ATAN": "الظل العكسي (atan)",
    "OPERATORS_MATHOP_LN": "اللوغارتم الطبيعي",
    "OPERATORS_MATHOP_LOG": "اللوغارتم العشري",
    "OPERATORS_MATHOP_EEXP": "هـ^",
    "OPERATORS_MATHOP_10EXP": "10^",
    "PROCEDURES_DEFINITION": "عرِّف %1",
    "SENSING_TOUCHINGOBJECT": "ملامس لـ %1؟",
    "SENSING_TOUCHINGOBJECT_POINTER": "مؤشر الفأرة",
    "SENSING_TOUCHINGOBJECT_EDGE": "الحافة",
    "SENSING_TOUCHINGCOLOR": "ملامس للون %1؟",
    "SENSING_COLORISTOUCHINGCOLOR": "اللون %1 ملامس للون %2؟",
    "SENSING_DISTANCETO": "المسافة إلى %1",
    "SENSING_DISTANCETO_POINTER": "مؤشر الفأرة",
    "SENSING_ASKANDWAIT": "اسأل %1 وانتظر",
    "SENSING_ASK_TEXT": "ما اسمك؟",
    "SENSING_ANSWER": "الإجابة",
    "SENSING_KEYPRESSED": "مفتاح %1 مضغوط؟",
    "SENSING_MOUSEDOWN": "زر الفأرة مضغوط؟",
    "SENSING_MOUSEX": "الموضع س للفأرة",
    "SENSING_MOUSEY": "الموضع ص للفأرة",
    "SENSING_SETDRAGMODE": "اجعل وضع السحب %1",
    "SENSING_SETDRAGMODE_DRAGGABLE": "قابلًا للسحب",
    "SENSING_SETDRAGMODE_NOTDRAGGABLE": "غير قابل للسحب",
    "SENSING_LOUDNESS": "الضجيج",
    "SENSING_LOUD": "الصوت مرتفع؟",
    "SENSING_TIMER": "المؤقت",
    "SENSING_RESETTIMER": "صفِّر المؤقت",
    "SENSING_OF": "%1 الخاص بـ %2",
    "SENSING_OF_XPOSITION": "الموضع س",
    "SENSING_OF_YPOSITION": "الموضع ص",
    "SENSING_OF_DIRECTION": "الاتجاه",
    "SENSING_OF_COSTUMENUMBER": "رقم المظهر",
    "SENSING_OF_COSTUMENAME": "اسم المظهر",
    "SENSING_OF_SIZE": "الحجم",
    "SENSING_OF_VOLUME": "شدة الصوت",
    "SENSING_OF_BACKDROPNUMBER": "رقم الخلفية",
    "SENSING_OF_BACKDROPNAME": "اسم الخلفية",
    "SENSING_OF_STAGE": "المنصة",
    "SENSING_CURRENT": "%1 الآن",
    "SENSING_CURRENT_YEAR": "السنة",
    "SENSING_CURRENT_MONTH": "الشهر",
    "SENSING_CURRENT_DATE": "تاريخ اليوم",
    "SENSING_CURRENT_DAYOFWEEK": "يوم الأسبوع",
    "SENSING_CURRENT_HOUR": "الساعة",
    "SENSING_CURRENT_MINUTE": "الدقيقة",
    "SENSING_CURRENT_SECOND": "الثانية",
    "SENSING_DAYSSINCE2000": "الأيام منذ عام 2000",
    "SENSING_USERNAME": "اسم المستخدم",
    "SENSING_USERID": "رقم المستخدم",
    "SOUND_PLAY": "ابدأ الصوت %1",
    "SOUND_PLAYUNTILDONE": "شغِّل الصوت %1 وانتظر انتهاءه",
    "SOUND_STOPALLSOUNDS": "أوقف كل الأصوات",
    "SOUND_SETEFFECTO": "اجعل مؤثر %1 مساويًا %2",
    "SOUND_CHANGEEFFECTBY": "غيِّر مؤثر %1 بمقدار %2",
    "SOUND_CLEAREFFECTS": "أزل المؤثرات الصوتية",
    "SOUND_EFFECTS_PITCH": "طبقة الصوت",
    "SOUND_EFFECTS_PAN": "توزيع الصوت يسار/يمين",
    "SOUND_CHANGEVOLUMEBY": "غيِّر شدة الصوت بمقدار %1",
    "SOUND_SETVOLUMETO": "اجعل شدّة الصوت مساويةً %1%",
    "SOUND_VOLUME": "شدة الصوت",
    "SOUND_RECORD": "سجِّل...",
    "CATEGORY_MOTION": "الحركة",
    "CATEGORY_LOOKS": "المظاهر",
    "CATEGORY_SOUND": "الصوت",
    "CATEGORY_EVENTS": "الأحداث",
    "CATEGORY_CONTROL": "التحكم",
    "CATEGORY_SENSING": "التحسس",
    "CATEGORY_OPERATORS": "العمليات",
    "CATEGORY_VARIABLES": "المتغيرات",
    "CATEGORY_MYBLOCKS": "لبناتي",
    "CATEGORY_LISTS": "",
    "CATEGORY_TUPLE": "",
    "CATEGORY_DICTIONARYS": "",
    "CATEGORY_NUMBERS": "",
    "CATEGORY_TEXT": "",
    "DUPLICATE": "مضاعفة",
    "DELETE": "حذف",
    "ADD_COMMENT": "إضافة تعليق",
    "REMOVE_COMMENT": "حذف التعليق",
    "DELETE_BLOCK": "حذف اللبنة",
    "DELETE_X_BLOCKS": "حذف اللبنات الـ%1",
    "DELETE_ALL_BLOCKS": "حذف اللبنات الـ%1 كلها؟",
    "CLEAN_UP": "ترتيب اللبنات",
    "HELP": "مساعدة",
    "UNDO": "تراجع",
    "REDO": "إعادة",
    "EDIT_PROCEDURE": "تعديل",
    "SHOW_PROCEDURE_DEFINITION": "اذهب إلى التعريف",
    "WORKSPACE_COMMENT_DEFAULT_TEXT": "قل شيئًا...",
    "COLOUR_HUE_LABEL": "اللون",
    "COLOUR_SATURATION_LABEL": "الإشباع",
    "COLOUR_BRIGHTNESS_LABEL": "السطوع",
    "CHANGE_VALUE_TITLE": "غيِّر القيمة:",
    "RENAME_VARIABLE": "إعادة تسمية المتغير",
    "RENAME_VARIABLE_STRING": "",
    "RENAME_VARIABLE_NUMBER": "",
    "RENAME_VARIABLE_LIST": "",
    "RENAME_VARIABLE_TITLE": "إعادة تسمية جميع المتغيرات \"%1\" إلى:",
    "RENAME_VARIABLE_MODAL_TITLE": "إعادة تسمية المتغير",
    "NEW_VARIABLE": "إنشاء متغير",
    "NEW_VARIABLE_TITLE": "اسم المتغير الجديد:",
    "VARIABLE_MODAL_TITLE": "متغير جديد",
    "VARIABLE_ALREADY_EXISTS": "يوجد مسبقًا متغير بالاسم \"%1\".",
    "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "اسم المتغير \"%1\" مستخدم مسبقًا في متغير آخر من النوع \"%2\".",
    "DELETE_VARIABLE_CONFIRMATION": "احذف الاستخدامات الـ%1 للمتغير \"%2\"؟",
    "CANNOT_DELETE_VARIABLE_PROCEDURE": "لا يمكن حذف المتغير \"%1\" لأنه جزء من تعريف الدالة \"%2\"",
    "DELETE_VARIABLE": "حذف المتغير \"%1\"",
    "DELETE_VARIABLE_STRING": "",
    "DELETE_VARIABLE_NUMBER": "",
    "DELETE_VARIABLE_LIST": "",
    "NEW_PROCEDURE": "إنشاء لبنة",
    "PROCEDURE_ALREADY_EXISTS": "يوجد مسبقًا إجراء بالاسم \"%1\".",
    "PROCEDURE_DEFAULT_NAME": "اسم اللبنة",
    "NEW_LIST": "إنشاء لائحة",
    "NEW_LIST_TITLE": "اسم اللائحة الجديدة:",
    "LIST_MODAL_TITLE": "لائحة جديدة",
    "LIST_ALREADY_EXISTS": "توجد مسبقًا لائحة بالاسم \"%1\".",
    "RENAME_LIST_TITLE": "إعادة تسمية جميع اللوائح \"%1\" إلى:",
    "RENAME_LIST_MODAL_TITLE": "إعادة تسمية اللائحة",
    "DEFAULT_LIST_ITEM": "غرض",
    "DELETE_LIST": "حذف اللائحة \"%1\"",
    "RENAME_LIST": "إعادة تسمية اللائحة",
    "NEW_BROADCAST_MESSAGE": "رسالة جديدة",
    "NEW_BROADCAST_MESSAGE_TITLE": "اسم الرسالة الجديدة:",
    "BROADCAST_MODAL_TITLE": "رسالة جديدة",
    "DEFAULT_BROADCAST_MESSAGE_NAME": "الرسالة ١",
    "DELETE_PROCEDURE": "",
    "OK": "",
    "PROMPT": "",
    "STOP": "",
    "NEW_STRING": "",
    "NEW_NUMBER_VARIABLE": "",
    "WIFI": "",
    "USERNAME": "",
    "PWD": "",
    "IOT_SERVICE": "",
    "IOT_ID": "",
    "IOT_PWD": "",
    "IOT_TOPIC": "",
    "MORE_TOPIC": "",
    "OBLOQ_HTTP": "",
    "OBLOQ_IP": "",
    "OBLOQ_PORT": "",
    "ADD_TOPIC": "",
    "DELETE_TOPIC": "",
    "IOT_SERVER": "",
    "CHINA": "",
    "GLOBAL": "",
    "LOW_C": "",
    "LOW_C$": "",
    "LOW_D": "",
    "LOW_D$": "",
    "LOW_E": "",
    "LOW_F": "",
    "LOW_F$": "",
    "LOW_G": "",
    "LOW_G$": "",
    "LOW_A": "",
    "LOW_A$": "",
    "LOW_B": "",
    "MIDDLE_C": "",
    "MIDDLE_C$": "",
    "MIDDLE_D": "",
    "MIDDLE_D$": "",
    "MIDDLE_E": "",
    "MIDDLE_F": "",
    "MIDDLE_F$": "",
    "MIDDLE_G": "",
    "MIDDLE_G$": "",
    "MIDDLE_A": "",
    "MIDDLE_A$": "",
    "MIDDLE_B": "",
    "HIGH_C": "",
    "HIGH_C$": "",
    "HIGH_D": "",
    "HIGH_D$": "",
    "HIGH_E": "",
    "HIGH_F": "",
    "HIGH_F$": "",
    "HIGH_G": "",
    "HIGH_G$": "",
    "HIGH_A": "",
    "HIGH_A$": "",
    "HIGH_B": "",
    "OPERATORS_STRINGTONUMBER": "",
    "INTEGER": "",
    "DECIMAL": "",
    "OPERATORS_NUMTOASCII": "",
    "OPERATORS_STRINGTOASCII": "",
    "OPERATORS_NUMTOSTRING": "",
    "OPERATORS_MAP": "",
    "OPERATORS_CONSTRAIN": "",
    "STEPS_PER_TURN": "",
    "ROTATE_SPEED": "",
    "NEW_AI": "",
    "ASK_AND_PAINT": "",
    "GET_IDENTIFIED_NUM": "",
    "READ_NUM_AND_SAY": "",
    "IMAGE_ADDR": "",
    "IMAGE_PREVIEW": "",
    "IMAGE_OPEN": "",
    "IMAGE_SIZE": "",
    "IMAGE_WIDTH": "",
    "IMAGE_HEIGHT": "",
    "VARIABLE": "",
    "VARIABLE_LIST": "",
    "SET_STRING_VARIABLE": "",
    "STRING_START_WITH": "",
    "DICTIONARY_HEIGHT_FIRST": "",
    "DICTIONARY_HEIGHT_TWO": "",
    "DICTIONARY_HEIGHT_THREE": "",
    "DICTIONARY_HEIGHT_FOUR": "",
    "DATA_MPITEMOFLIST": "",
    "DATA_MPINTERCEPTITEM": "",
    "DATA_MPINSERTATLIST": "",
    "DATA_MPSETVALUEATLIST": "",
    "DATA_MPINSERTATLIST_INSERT": "",
    "DATA_MPINSERTATLIST_CHANGE": "",
    "DATA_MPINSERTATLIST_FOUR": "",
    "DATA_MPINSERTATLIST_FIVE": "",
    "DATA_MPINSERTATLIST_SIX": "",
    "DATA_MPINSERTATLIST_SEVEN": "",
    "DATA_MPINSERTATLIST_EIGHT": "",
    "DATA_MPINSERTATLIST_EIGHT_FALSE": "",
    "DATA_MPINSERTATLIST_EIGHT_TRUE": "",
    "DATA_MPINSERTATLIST_EIGHT_REVERSE": "",
    "OPERATORS_RANDOMA": "",
    "OPERATORS_MODA": "",
    "OPERATORS_ROUNDA": "",
    "OPERATORS_MATHOPA": "",
    "OPERATORS_MATHOP_ABSA": "",
    "OPERATORS_MATHOP_FLOORA": "",
    "OPERATORS_MATHOP_CEILINGA": "",
    "OPERATORS_MATHOP_SQRTA": "",
    "OPERATORS_MATHOP_FIVE": "",
    "OPERATORS_MATHOP_SIX": "",
    "OPERATORS_MATHOP_SEVEN": "",
    "OPERATORS_MATHOP_LENGHT": "",
    "OPERATORS_MATHOP_MAXMIN": "",
    "OPERATORS_MATHOP_MAXMIN_MAX": "",
    "OPERATORS_MATHOP_MAXMIN_MIN": "",
    "OPERATORS_TEXTS_ONE": "",
    "OPERATORS_TEXTS_TWO": "",
    "OPERATORS_TEXTS_THREE": "",
    "OPERATORS_TEXTS_FOUR": "",
    "OPERATORS_TEXTS_FOUR_NEW": "",
    "OPERATORS_TEXTS_NEW": "",
    "OPERATORS_TEXTS_NEW_OP": "",
    "OPERATORS_TEXTS_FOUR_ONE": "",
    "OPERATORS_TEXTS_FOUR_TWO": "",
    "OPERATORS_TEXTS_FOUR_THREE": "",
    "OPERATORS_TEXTS_FOUR_FOUR": "",
    "OPERATORS_TEXTS_FOUR_F": "",
    "OPERATORS_TEXTS_FOUR_L": "",
    "OPERATORS_STRINGTONUMBERA": "",
    "OPERATORS_NUMTOASCIIA": "",
    "OPERATORS_STRINGTOASCIIA": "",
    "OPERATORS_NUMTOSTRINGA": "",
    "NEW_MC": "",
    "RENAME_MCNUMBER_TITLE": "",
    "RENAME_MCNUMBER_MODAL_TITLE": "",
    "RENAME_VARIABLE_MCNUMBER": "",
    "DELETE_VARIABLE_MCNUMBER": "",
    "SET_MC_VARIABLE": "",
    "TUPLE_ONE": "",
    "TUPLE_TWO": "",
    "ALIYUN": "",
    "ONENET": "",
    "EASYIOT": "",
    "SHANGHAI": "",
    "QINGDAO": "",
    "BEIJING": "",
    "ZHANGJIAKOU": "",
    "HUHEHAOTE": "",
    "HANGZHOU": "",
    "SHENZHEN": "",
    "HONGKONG": "",
    "SINGAPORE": "",
    "SYDNEY": "",
    "KUALALUMPUR": "",
    "JAKARTA": "",
    "MUMBAI": "",
    "TOKYO": "",
    "SILICONVALLEY": "",
    "VIRGINIA": "",
    "FRANKFURT": "",
    "LONDON": "",
    "DUBAI": "",
    "IOT_PLATFORM": "",
    "PARAMS": "",
    "SERVER_ATTR": "",
    "PRODUCTID": "",
    "DEVICEID": "",
    "TINYDB_SECRET": "",
    "TINYDB_USER": "",
    "APIADDR": "",
    "SOFTWARE_SERIAL": "",
    "HARDWARE_SERIAL": "",
    "HARDWARE_SERIAL1": "",
    "HARDWARE_SERIAL2": "",
    "HARDWARE_SERIAL3": ""
  },
  "am": {
    "CONTROL_FOREVER": "ለዘላለም",
    "CONTROL_REPEAT": "%1ን ድገም",
    "CONTROL_IF": "%1 ከሆነ",
    "CONTROL_ELSE": "ካልሆነ",
    "CONTROL_STOP": "አቁም",
    "CONTROL_STOP_ALL": "ሁሉም",
    "CONTROL_STOP_THIS": "ይሄ ስክሪፕት",
    "CONTROL_STOP_OTHER": "ሌሎች በስፕራይት ላይ ያሉ ስክሪፕቶች",
    "CONTROL_WAIT": "ጠብቅ ለ%1 ሰከንድ",
    "CONTROL_WAITUNTIL": "ጠብቅ እስከ %1",
    "CONTROL_REPEATUNTIL": "ድገም እስከ %1",
    "CONTROL_WHILE": "%1 ያለ",
    "CONTROL_FOREACH": "ለያንዳንድ %1 በ%2 ውስጥ",
    "CONTROL_STARTASCLONE": "እንደ አምሳያ ስጀምር",
    "CONTROL_CREATECLONEOF": "የ%1 አምሳያ ፍጠር",
    "CONTROL_CREATECLONEOF_MYSELF": "ራሴ",
    "CONTROL_DELETETHISCLONE": "ይህንን አምሳያ አጥፋ",
    "CONTROL_COUNTER": "ቆጣሪ",
    "CONTROL_INCRCOUNTER": "ቆጣሪውን ጨምር",
    "CONTROL_CLEARCOUNTER": "ቆጣሪውን አጽዳ",
    "CONTROL_ALLATONCE": "ሁሉም በአንድ ግዜ",
    "DATA_SETVARIABLETO": "%1 ወደ %2 ለውጥ",
    "DATA_CHANGEVARIABLEBY": "%1 በ%2 ቀይር",
    "DATA_SHOWVARIABLE": "%1ን ተለዋዋጭ አሳይ",
    "DATA_HIDEVARIABLE": "%1ን ተለዋዋጭ ደብቅ",
    "DATA_ADDTOLIST": "%1 ጨምር በ%2",
    "DATA_DELETEOFLIST": "%1 ሰርዝ ከ%2",
    "DATA_DELETEALLOFLIST": "%1 ሁሉ ሰርዝ",
    "DATA_INSERTATLIST": "%1 አስገባ በ%2 ላይ %3 ውስጥ",
    "DATA_REPLACEITEMOFLIST": "%1 ከ%2 ቀይር ወደ %3",
    "DATA_ITEMOFLIST": "%1 ከ%2",
    "DATA_ITEMNUMOFLIST": "item # of %1 in %2",
    "DATA_LENGTHOFLIST": "%1 ርዝመት",
    "DATA_LISTCONTAINSITEM": "%2 %1 አለበት?",
    "DATA_SHOWLIST": "%1ን ዝርዝር አሳይ",
    "DATA_HIDELIST": "%1ን ዝርዝር ደብቅ",
    "DATA_INDEX_ALL": "ሁሉም",
    "DATA_INDEX_LAST": "መጨረሻ",
    "DATA_INDEX_RANDOM": "የማይታወቅ",
    "EVENT_WHENFLAGCLICKED": "%1 ጠቅ ሲደረግ",
    "EVENT_WHENTHISSPRITECLICKED": "ይህ ስፕራይት ሲነካ",
    "EVENT_WHENSTAGECLICKED": "መድረኩ ጠቅ ሲደረግ",
    "EVENT_WHENTOUCHINGOBJECT": "ይህ ስፕራይት %1ን ሲነካ",
    "EVENT_WHENBROADCASTRECEIVED": "%1ን ስቀበል",
    "EVENT_WHENBACKDROPSWITCHESTO": "የጀርባ ምስል ወደ %1 ሲቀየር",
    "EVENT_WHENGREATERTHAN": "%1 > %2 ሲሆን",
    "EVENT_WHENGREATERTHAN_TIMER": "የጊዜ ቆጣሪ",
    "EVENT_WHENGREATERTHAN_LOUDNESS": "ጮክታ",
    "EVENT_BROADCAST": "%1ን አሰራጭ",
    "EVENT_BROADCASTANDWAIT": "%1ን አሰራጭና ጠብቅ",
    "EVENT_WHENKEYPRESSED": "%1ን ቁልፍ ጫን ሲደረግ",
    "EVENT_WHENKEYPRESSED_SPACE": "ቦታ",
    "EVENT_WHENKEYPRESSED_LEFT": "ወደ ግራ ጠቋሚ ቀስት",
    "EVENT_WHENKEYPRESSED_RIGHT": "የቀኝ አቅጣጫ ጠቋሚ ቀስት",
    "EVENT_WHENKEYPRESSED_DOWN": "ወደታች ጠቋሚ ቀስት",
    "EVENT_WHENKEYPRESSED_UP": "ወደ ላይ አቅጣጫ ጠቋሚ ቀስት",
    "EVENT_WHENKEYPRESSED_ANY": "የቱም",
    "LOOKS_SAYFORSECS": "%1ን በል ለ%2 ሰከንድ",
    "LOOKS_SAY": "%1ን በል",
    "LOOKS_HELLO": "ሰላም!",
    "LOOKS_THINKFORSECS": "%1ን አስብ ለ%2 ሰከንድ",
    "LOOKS_THINK": "%1ን አስብ",
    "LOOKS_HMM": "እ...",
    "LOOKS_SHOW": "አሳይ",
    "LOOKS_HIDE": "ደብቅ",
    "LOOKS_HIDEALLSPRITES": "ሁሉም ስፕራይቶች ደብቅ",
    "LOOKS_EFFECT_COLOR": "ቀለም",
    "LOOKS_EFFECT_FISHEYE": "fisheye",
    "LOOKS_EFFECT_WHIRL": "መሽከርከር",
    "LOOKS_EFFECT_PIXELATE": "ፒክሰሌት",
    "LOOKS_EFFECT_MOSAIC": "ሞዚያክ",
    "LOOKS_EFFECT_BRIGHTNESS": "የብርሀን መጠን",
    "LOOKS_EFFECT_GHOST": "መንፈስ",
    "LOOKS_CHANGEEFFECTBY": "%1ን ተጽኖ በ%2 ለውጥ",
    "LOOKS_SETEFFECTTO": "%1ን ተጽኖ ወደ %2 ለውጥ",
    "LOOKS_CLEARGRAPHICEFFECTS": "የምስል ማስዋብያዎቹን አጥፋ",
    "LOOKS_CHANGESIZEBY": "ልክን ቀይር በ%1",
    "LOOKS_SETSIZETO": "ልክን ወደ %1 ቀይር",
    "LOOKS_SIZE": "መጠን",
    "LOOKS_CHANGESTRETCHBY": "ዝርጋት በ%1 ቀይር",
    "LOOKS_SETSTRETCHTO": "ዝርጋት ወደ %1 ቀይር",
    "LOOKS_SWITCHCOSTUMETO": "ልብስ ወደ %1 ለውጥ",
    "LOOKS_NEXTCOSTUME": "ቀጣይ አልባስ",
    "LOOKS_SWITCHBACKDROPTO": "የጀርባ ምስል ወደ %1 ለውጥ",
    "LOOKS_GOTOFRONTBACK": "ወደ %1 ተደራቢ ሂድ",
    "LOOKS_GOTOFRONTBACK_FRONT": "ፊት",
    "LOOKS_GOTOFRONTBACK_BACK": "ኋላ",
    "LOOKS_GOFORWARDBACKWARDLAYERS": "ወደ %1ና %2 ተደራቢዎች ሂድ",
    "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "ወደፊት",
    "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "ወደኋላ",
    "LOOKS_BACKDROPNUMBERNAME": "የጀርባ ምስል %1",
    "LOOKS_COSTUMENUMBERNAME": "ልብስ %1",
    "LOOKS_NUMBERNAME_NUMBER": "ቁጥር",
    "LOOKS_NUMBERNAME_NAME": "ስም",
    "LOOKS_SWITCHBACKDROPTOANDWAIT": "የጀርባ ምስል ወደ %1 ለውጥና ጠብቅ",
    "LOOKS_NEXTBACKDROP_BLOCK": "ቀጣይ የጀርባ ምስል",
    "LOOKS_NEXTBACKDROP": "ቀጣይ የጀርባ ምስል",
    "LOOKS_PREVIOUSBACKDROP": "ቀድሞ የነበረው የጀርባ ምስል",
    "LOOKS_RANDOMBACKDROP": "ራንደም የጀርባ ምስል",
    "MOTION_MOVESTEPS": "%1 እርምጃዎች ተንቀሳቀስ",
    "MOTION_TURNLEFT": "ዙር %1 %2 ድግሪዎች",
    "MOTION_TURNRIGHT": "ዙር %1 %2 ድግሪዎች",
    "MOTION_POINTINDIRECTION": "ወደ %1 አቅጣጫ ደግን",
    "MOTION_POINTTOWARDS": "ወደ %1 ደግን",
    "MOTION_POINTTOWARDS_POINTER": "የማውሱ መጠቆሚያ",
    "MOTION_POINTTOWARDS_RANDOM": "random direction",
    "MOTION_GOTO": "ወደ %1 ሂድ",
    "MOTION_GOTO_POINTER": "የማውሱ መጠቆሚያ",
    "MOTION_GOTO_RANDOM": "የማይታወቅ ቦታ",
    "MOTION_GOTOXY": "ወደ x: %1 y: %2 ሂድ",
    "MOTION_GLIDESECSTOXY": "ተንሸራተት %1 ሰከንድ ወደ x: %2 y: %3",
    "MOTION_GLIDETO": "ተንሸራተት %1 ሰከንድ ወደ %2",
    "MOTION_GLIDETO_POINTER": "የማውሱ መጠቆሚያ",
    "MOTION_GLIDETO_RANDOM": "የማይታወቅ ቦታ",
    "MOTION_CHANGEXBY": "xን ቀይር በ%1",
    "MOTION_SETX": "xን ወደ %1 ለውጥ",
    "MOTION_CHANGEYBY": "yን ቀይር በ%1",
    "MOTION_SETY": "yን ወደ %1 ለውጥ",
    "MOTION_IFONEDGEBOUNCE": "ጠርዝ ላይ ነጥረህ ተመለስ",
    "MOTION_SETROTATIONSTYLE": "የዙር ዘዴ ወደ %1 ለውጥ",
    "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "ግራ-ቀኝ",
    "MOTION_SETROTATIONSTYLE_DONTROTATE": "አትሽከርከር",
    "MOTION_SETROTATIONSTYLE_ALLAROUND": "በአካባቢው በሙሉ",
    "MOTION_XPOSITION": "x ቦታ",
    "MOTION_YPOSITION": "y ቦታ",
    "MOTION_DIRECTION": "አቅጣጫ",
    "MOTION_SCROLLRIGHT": "ሸብልል %1 ወደ ቀኝ",
    "MOTION_SCROLLUP": "ሸብልል %1 ወደ ላይ",
    "MOTION_ALIGNSCENE": "%1ን ሥፍራ ገባበር ",
    "MOTION_ALIGNSCENE_BOTTOMLEFT": "ታች-ግራ",
    "MOTION_ALIGNSCENE_BOTTOMRIGHT": "ታች-ቀኝ",
    "MOTION_ALIGNSCENE_MIDDLE": "መሃከል",
    "MOTION_ALIGNSCENE_TOPLEFT": "ላይ-ግራ",
    "MOTION_ALIGNSCENE_TOPRIGHT": "ላይ-ቀኝ",
    "MOTION_XSCROLL": "xን ሸብልል",
    "MOTION_YSCROLL": "yን ሸብልል",
    "MOTION_STAGE_SELECTED": "መድረክ ተመርጧል፡ ተንቀሳቃሽ ጥምሮች የሉም",
    "OPERATORS_ADD": "%1 + %2",
    "OPERATORS_SUBTRACT": "%1 - %2",
    "OPERATORS_MULTIPLY": "%1 * %2",
    "OPERATORS_DIVIDE": "%1 / %2",
    "OPERATORS_RANDOM": "%1 እስከ %2 በራንደም ምረጥ",
    "OPERATORS_GT": "%1 > %2",
    "OPERATORS_GT_EQUALS": "",
    "OPERATORS_LT": "%1 < %2",
    "OPERATORS_LT_EQUALS": "",
    "OPERATORS_EQUALS": "%1 = %2",
    "OPERATORS_AND": "%1ና %2",
    "OPERATORS_OR": "%1 ወይስ %2",
    "OPERATORS_NOT": "%1 አይደለም",
    "OPERATORS_JOIN": "%1 %2 አገናኝ",
    "OPERATORS_JOIN_APPLE": "ፖም",
    "OPERATORS_JOIN_BANANA": "ሙዝ",
    "OPERATORS_LETTEROF": "ፊደል %1 ከ%2",
    "OPERATORS_LETTEROF_APPLE": "አንድ",
    "OPERATORS_LENGTH": "%1 ርዝመት",
    "OPERATORS_CONTAINS": "%2 %1 አለበት?",
    "OPERATORS_MOD": "%1 ሞዱሎ %2",
    "OPERATORS_ROUND": "%1ን አጠጋጋ",
    "OPERATORS_MATHOP": "%1 ከ%2",
    "OPERATORS_MATHOP_ABS": "abs",
    "OPERATORS_MATHOP_FLOOR": "መሬት",
    "OPERATORS_MATHOP_CEILING": "ጣራ",
    "OPERATORS_MATHOP_SQRT": "sqrt",
    "OPERATORS_MATHOP_SIN": "sin",
    "OPERATORS_MATHOP_COS": "cos",
    "OPERATORS_MATHOP_TAN": "tan",
    "OPERATORS_MATHOP_ASIN": "asin",
    "OPERATORS_MATHOP_ACOS": "acos",
    "OPERATORS_MATHOP_ATAN": "atan",
    "OPERATORS_MATHOP_LN": "ውስጥ",
    "OPERATORS_MATHOP_LOG": "ግባ",
    "OPERATORS_MATHOP_EEXP": "e^",
    "OPERATORS_MATHOP_10EXP": "10 ^",
    "PROCEDURES_DEFINITION": "%1ን ደንግግ",
    "SENSING_TOUCHINGOBJECT": "%1ን የነካ ነው?",
    "SENSING_TOUCHINGOBJECT_POINTER": "የማውሱ መጠቆሚያ",
    "SENSING_TOUCHINGOBJECT_EDGE": "ዳር",
    "SENSING_TOUCHINGCOLOR": "%1ን ቀለም የነካ ነው?",
    "SENSING_COLORISTOUCHINGCOLOR": "%1 ቀለም %2ን የነካ ነው?",
    "SENSING_DISTANCETO": "ርቀት እስከ %1",
    "SENSING_DISTANCETO_POINTER": "የማውሱ መጠቆሚያ",
    "SENSING_ASKANDWAIT": "%1 ጠይቅና ጠብቅ",
    "SENSING_ASK_TEXT": "ስምህ ማን ነው?",
    "SENSING_ANSWER": "መልስ",
    "SENSING_KEYPRESSED": "ቁልፍ %1 ጫን ተብሏል?",
    "SENSING_MOUSEDOWN": "መዳፊት ወደታች?",
    "SENSING_MOUSEX": "መዳፊት x",
    "SENSING_MOUSEY": "መዳፊት y",
    "SENSING_SETDRAGMODE": "የጉትት ሞድ ወደ %1 ለውጥ",
    "SENSING_SETDRAGMODE_DRAGGABLE": "ይጎተታል",
    "SENSING_SETDRAGMODE_NOTDRAGGABLE": "አይጎተትም",
    "SENSING_LOUDNESS": "ጮክታ",
    "SENSING_LOUD": "ጮክ ነው?",
    "SENSING_TIMER": "የጊዜ ቆጣሪ",
    "SENSING_RESETTIMER": "ጊዜ ቆጣሪውን ከዜሮ ጀምር",
    "SENSING_OF": "%1 ከ%2",
    "SENSING_OF_XPOSITION": "x ቦታ",
    "SENSING_OF_YPOSITION": "y ቦታ",
    "SENSING_OF_DIRECTION": "አቅጣጫ",
    "SENSING_OF_COSTUMENUMBER": "ልብስ ቁጥር",
    "SENSING_OF_COSTUMENAME": "የአልባሳት ስም",
    "SENSING_OF_SIZE": "መጠን",
    "SENSING_OF_VOLUME": "የድምጽ መጠን",
    "SENSING_OF_BACKDROPNUMBER": "የጀርባ ምስል #",
    "SENSING_OF_BACKDROPNAME": "የጀርባ ምስል ስም",
    "SENSING_OF_STAGE": "መድረክ",
    "SENSING_CURRENT": "ያሁኑ %1",
    "SENSING_CURRENT_YEAR": "አመት",
    "SENSING_CURRENT_MONTH": "ወር",
    "SENSING_CURRENT_DATE": "ቀን",
    "SENSING_CURRENT_DAYOFWEEK": "የሳምንቱ ቀን",
    "SENSING_CURRENT_HOUR": "ሰዓት",
    "SENSING_CURRENT_MINUTE": "ደቂቃ",
    "SENSING_CURRENT_SECOND": "ሁለተኛ",
    "SENSING_DAYSSINCE2000": "ከ2000 ጀምሮ ያሉ ቀናት",
    "SENSING_USERNAME": "የተጠቃሚ ስም",
    "SENSING_USERID": "የተጠቃሚ መታወቂያ",
    "SOUND_PLAY": "%1ን ድምጽ ጀምር",
    "SOUND_PLAYUNTILDONE": "%1ን ድምጽ ተጫወት እስከሚያልቅ",
    "SOUND_STOPALLSOUNDS": "ሁሉንም ድምጾች አቁም",
    "SOUND_SETEFFECTO": "%1ን ተጽኖ ወደ %2 ለውጥ",
    "SOUND_CHANGEEFFECTBY": "%1ን ተጽኖ በ%2 ለውጥ",
    "SOUND_CLEAREFFECTS": "የድምጽ ተጽኖዎች አጽዳ",
    "SOUND_EFFECTS_PITCH": "ፒች",
    "SOUND_EFFECTS_PAN": "ሸብልል ወደ ግራ/ቀኝ",
    "SOUND_CHANGEVOLUMEBY": "ድምጽ ቀይር በ%1",
    "SOUND_SETVOLUMETO": "ድምጽ ወደ %1% ለውጥ",
    "SOUND_VOLUME": "የድምጽ መጠን",
    "SOUND_RECORD": "record...",
    "CATEGORY_MOTION": "እንቅስቃሴ",
    "CATEGORY_LOOKS": "ገጽታ",
    "CATEGORY_SOUND": "ድምጽ",
    "CATEGORY_EVENTS": "ክስተት",
    "CATEGORY_CONTROL": "ቁጥጥር",
    "CATEGORY_SENSING": "ስሜት",
    "CATEGORY_OPERATORS": "ስሌቶች",
    "CATEGORY_VARIABLES": "ተለዋዋጮች",
    "CATEGORY_MYBLOCKS": "የኔ ጥምሮች",
    "CATEGORY_LISTS": "",
    "CATEGORY_TUPLE": "",
    "CATEGORY_DICTIONARYS": "",
    "CATEGORY_NUMBERS": "",
    "CATEGORY_TEXT": "",
    "DUPLICATE": "ቅጂ አድርገህ ገልብጥ",
    "DELETE": "አጥፋ",
    "ADD_COMMENT": "አስተያየት ጨምር",
    "REMOVE_COMMENT": "አስተያየት አውጣ",
    "DELETE_BLOCK": "ጥምር ሰርዝ",
    "DELETE_X_BLOCKS": "%1 ጥምሮች ሰርዝ",
    "DELETE_ALL_BLOCKS": "ሁሉም %1 ጥምሮች ሰርዝ?",
    "CLEAN_UP": "ጥምሮች አጽዳ",
    "HELP": "እርዳታ",
    "UNDO": "መልስ",
    "REDO": "ድጋሜ አድርግ",
    "EDIT_PROCEDURE": "ቀይር",
    "SHOW_PROCEDURE_DEFINITION": "ወደ ትርጓሜ ሂድ",
    "WORKSPACE_COMMENT_DEFAULT_TEXT": "አንድ ነገር በል...",
    "COLOUR_HUE_LABEL": "ቀለም",
    "COLOUR_SATURATION_LABEL": "ርኬት",
    "COLOUR_BRIGHTNESS_LABEL": "ብሩህነት",
    "CHANGE_VALUE_TITLE": "እሴት ቀይር",
    "RENAME_VARIABLE": "ተለዋዋጭ ደግመህ ሰይም",
    "RENAME_VARIABLE_STRING": "",
    "RENAME_VARIABLE_NUMBER": "",
    "RENAME_VARIABLE_LIST": "",
    "RENAME_VARIABLE_TITLE": "ሁሉም \"%1\" ተለዋዋጮች ደግመህ ሰይም ወደ፡",
    "RENAME_VARIABLE_MODAL_TITLE": "ተለዋዋጭ ደግመህ ሰይም",
    "NEW_VARIABLE": "ተለዋጭ ፍጠር",
    "NEW_VARIABLE_TITLE": "አዲስ ተለዋዋጭ ስም",
    "VARIABLE_MODAL_TITLE": "አዲስ ተለዋዋጭ",
    "VARIABLE_ALREADY_EXISTS": "የተሰየመ ተለዋዋጭ \"%1\" አስቀድሞ አለ",
    "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "አንድ ተለዋዋጭ \"%1\" የተሰየመ አስቀድሞ ይኖራል ለሌ ተለዋዋጭ በ\"%2\" ዓይነት.",
    "DELETE_VARIABLE_CONFIRMATION": "የ%1 ጥቅሞች በ%2 ተለዋዋጭ ይሰረዙ?",
    "CANNOT_DELETE_VARIABLE_PROCEDURE": "%1ን ተለዋዋጭ መሰረዝ አይቻልም የ%2ን ተግባር አባል ስለሆነ",
    "DELETE_VARIABLE": "%1ን ተለዋዋጭ ይሰረዝ?",
    "DELETE_VARIABLE_STRING": "",
    "DELETE_VARIABLE_NUMBER": "",
    "DELETE_VARIABLE_LIST": "",
    "NEW_PROCEDURE": "ጡብ ፍጠር",
    "PROCEDURE_ALREADY_EXISTS": "አንድ \"%1\" የተሰየመ ተግባር አስቀድሞ አለ",
    "PROCEDURE_DEFAULT_NAME": "የጥምር ስም",
    "NEW_LIST": "ዝርዝር ፍጠር",
    "NEW_LIST_TITLE": "አዲስ ዝርዝር ስም",
    "LIST_MODAL_TITLE": "አዲስ ዝርዝር",
    "LIST_ALREADY_EXISTS": "አንድ \"%1\" የተሰየመ ዝርዝር አስቀድሞ አለ",
    "RENAME_LIST_TITLE": "ሁሉም \"%1\" ዝርዝሮች ደግሞ ይሰየሙ ወደ፡ ",
    "RENAME_LIST_MODAL_TITLE": "ዝርዝር ደግመህ ሰይም",
    "DEFAULT_LIST_ITEM": "ነገር",
    "DELETE_LIST": "Delete the \"%1\" list",
    "RENAME_LIST": "Rename list",
    "NEW_BROADCAST_MESSAGE": "አዲስ መልዕክት",
    "NEW_BROADCAST_MESSAGE_TITLE": "አዲስ የመልዕክት ስም፡",
    "BROADCAST_MODAL_TITLE": "አዲስ መልእክት",
    "DEFAULT_BROADCAST_MESSAGE_NAME": "መልእክት1",
    "DELETE_PROCEDURE": "",
    "OK": "",
    "PROMPT": "",
    "STOP": "",
    "NEW_STRING": "",
    "NEW_NUMBER_VARIABLE": "",
    "WIFI": "",
    "USERNAME": "",
    "PWD": "",
    "IOT_SERVICE": "",
    "IOT_ID": "",
    "IOT_PWD": "",
    "IOT_TOPIC": "",
    "MORE_TOPIC": "",
    "OBLOQ_HTTP": "",
    "OBLOQ_IP": "",
    "OBLOQ_PORT": "",
    "ADD_TOPIC": "",
    "DELETE_TOPIC": "",
    "IOT_SERVER": "",
    "CHINA": "",
    "GLOBAL": "",
    "LOW_C": "",
    "LOW_C$": "",
    "LOW_D": "",
    "LOW_D$": "",
    "LOW_E": "",
    "LOW_F": "",
    "LOW_F$": "",
    "LOW_G": "",
    "LOW_G$": "",
    "LOW_A": "",
    "LOW_A$": "",
    "LOW_B": "",
    "MIDDLE_C": "",
    "MIDDLE_C$": "",
    "MIDDLE_D": "",
    "MIDDLE_D$": "",
    "MIDDLE_E": "",
    "MIDDLE_F": "",
    "MIDDLE_F$": "",
    "MIDDLE_G": "",
    "MIDDLE_G$": "",
    "MIDDLE_A": "",
    "MIDDLE_A$": "",
    "MIDDLE_B": "",
    "HIGH_C": "",
    "HIGH_C$": "",
    "HIGH_D": "",
    "HIGH_D$": "",
    "HIGH_E": "",
    "HIGH_F": "",
    "HIGH_F$": "",
    "HIGH_G": "",
    "HIGH_G$": "",
    "HIGH_A": "",
    "HIGH_A$": "",
    "HIGH_B": "",
    "OPERATORS_STRINGTONUMBER": "",
    "INTEGER": "",
    "DECIMAL": "",
    "OPERATORS_NUMTOASCII": "",
    "OPERATORS_STRINGTOASCII": "",
    "OPERATORS_NUMTOSTRING": "",
    "OPERATORS_MAP": "",
    "OPERATORS_CONSTRAIN": "",
    "STEPS_PER_TURN": "",
    "ROTATE_SPEED": "",
    "NEW_AI": "",
    "ASK_AND_PAINT": "",
    "GET_IDENTIFIED_NUM": "",
    "READ_NUM_AND_SAY": "",
    "IMAGE_ADDR": "",
    "IMAGE_PREVIEW": "",
    "IMAGE_OPEN": "",
    "IMAGE_SIZE": "",
    "IMAGE_WIDTH": "",
    "IMAGE_HEIGHT": "",
    "VARIABLE": "",
    "VARIABLE_LIST": "",
    "SET_STRING_VARIABLE": "",
    "STRING_START_WITH": "",
    "DICTIONARY_HEIGHT_FIRST": "",
    "DICTIONARY_HEIGHT_TWO": "",
    "DICTIONARY_HEIGHT_THREE": "",
    "DICTIONARY_HEIGHT_FOUR": "",
    "DATA_MPITEMOFLIST": "",
    "DATA_MPINTERCEPTITEM": "",
    "DATA_MPINSERTATLIST": "",
    "DATA_MPSETVALUEATLIST": "",
    "DATA_MPINSERTATLIST_INSERT": "",
    "DATA_MPINSERTATLIST_CHANGE": "",
    "DATA_MPINSERTATLIST_FOUR": "",
    "DATA_MPINSERTATLIST_FIVE": "",
    "DATA_MPINSERTATLIST_SIX": "",
    "DATA_MPINSERTATLIST_SEVEN": "",
    "DATA_MPINSERTATLIST_EIGHT": "",
    "DATA_MPINSERTATLIST_EIGHT_FALSE": "",
    "DATA_MPINSERTATLIST_EIGHT_TRUE": "",
    "DATA_MPINSERTATLIST_EIGHT_REVERSE": "",
    "OPERATORS_RANDOMA": "",
    "OPERATORS_MODA": "",
    "OPERATORS_ROUNDA": "",
    "OPERATORS_MATHOPA": "",
    "OPERATORS_MATHOP_ABSA": "",
    "OPERATORS_MATHOP_FLOORA": "",
    "OPERATORS_MATHOP_CEILINGA": "",
    "OPERATORS_MATHOP_SQRTA": "",
    "OPERATORS_MATHOP_FIVE": "",
    "OPERATORS_MATHOP_SIX": "",
    "OPERATORS_MATHOP_SEVEN": "",
    "OPERATORS_MATHOP_LENGHT": "",
    "OPERATORS_MATHOP_MAXMIN": "",
    "OPERATORS_MATHOP_MAXMIN_MAX": "",
    "OPERATORS_MATHOP_MAXMIN_MIN": "",
    "OPERATORS_TEXTS_ONE": "",
    "OPERATORS_TEXTS_TWO": "",
    "OPERATORS_TEXTS_THREE": "",
    "OPERATORS_TEXTS_FOUR": "",
    "OPERATORS_TEXTS_FOUR_NEW": "",
    "OPERATORS_TEXTS_NEW": "",
    "OPERATORS_TEXTS_NEW_OP": "",
    "OPERATORS_TEXTS_FOUR_ONE": "",
    "OPERATORS_TEXTS_FOUR_TWO": "",
    "OPERATORS_TEXTS_FOUR_THREE": "",
    "OPERATORS_TEXTS_FOUR_FOUR": "",
    "OPERATORS_TEXTS_FOUR_F": "",
    "OPERATORS_TEXTS_FOUR_L": "",
    "OPERATORS_STRINGTONUMBERA": "",
    "OPERATORS_NUMTOASCIIA": "",
    "OPERATORS_STRINGTOASCIIA": "",
    "OPERATORS_NUMTOSTRINGA": "",
    "NEW_MC": "",
    "RENAME_MCNUMBER_TITLE": "",
    "RENAME_MCNUMBER_MODAL_TITLE": "",
    "RENAME_VARIABLE_MCNUMBER": "",
    "DELETE_VARIABLE_MCNUMBER": "",
    "SET_MC_VARIABLE": "",
    "TUPLE_ONE": "",
    "TUPLE_TWO": "",
    "ALIYUN": "",
    "ONENET": "",
    "EASYIOT": "",
    "SHANGHAI": "",
    "QINGDAO": "",
    "BEIJING": "",
    "ZHANGJIAKOU": "",
    "HUHEHAOTE": "",
    "HANGZHOU": "",
    "SHENZHEN": "",
    "HONGKONG": "",
    "SINGAPORE": "",
    "SYDNEY": "",
    "KUALALUMPUR": "",
    "JAKARTA": "",
    "MUMBAI": "",
    "TOKYO": "",
    "SILICONVALLEY": "",
    "VIRGINIA": "",
    "FRANKFURT": "",
    "LONDON": "",
    "DUBAI": "",
    "IOT_PLATFORM": "",
    "PARAMS": "",
    "SERVER_ATTR": "",
    "PRODUCTID": "",
    "DEVICEID": "",
    "TINYDB_SECRET": "",
    "TINYDB_USER": "",
    "APIADDR": "",
    "SOFTWARE_SERIAL": "",
    "HARDWARE_SERIAL": "",
    "HARDWARE_SERIAL1": "",
    "HARDWARE_SERIAL2": "",
    "HARDWARE_SERIAL3": ""
  },
  "az": {
    "CONTROL_FOREVER": "həmişə",
    "CONTROL_REPEAT": "təkrarla %1 dəfə",
    "CONTROL_IF": "əgər %1, onda",
    "CONTROL_ELSE": "əks halda",
    "CONTROL_STOP": "dayandır",
    "CONTROL_STOP_ALL": "hamısı",
    "CONTROL_STOP_THIS": "bu skript",
    "CONTROL_STOP_OTHER": "spraytın digər skriptləri",
    "CONTROL_WAIT": "%1 saniyə gözlə",
    "CONTROL_WAITUNTIL": "%1 qədər gözlə",
    "CONTROL_REPEATUNTIL": "təkrarla nə qədər ki, %1 deyil",
    "CONTROL_WHILE": "təkrarla nə qədər ki, %1 ",
    "CONTROL_FOREACH": "hər bir %1 üçün %2 -də",
    "CONTROL_STARTASCLONE": "mən klon kimi başlayanda",
    "CONTROL_CREATECLONEOF": "%1 klonunu yarat",
    "CONTROL_CREATECLONEOF_MYSELF": "özümün",
    "CONTROL_DELETETHISCLONE": "bu klonu sil",
    "CONTROL_COUNTER": "sayğac",
    "CONTROL_INCRCOUNTER": "sayğacın göstəricisini artır",
    "CONTROL_CLEARCOUNTER": "sayğacı təmizlə",
    "CONTROL_ALLATONCE": "hamısı birlikdə",
    "DATA_SETVARIABLETO": "%1 təyin et: %2",
    "DATA_CHANGEVARIABLEBY": "%1 -i %2 qədər dəyiş",
    "DATA_SHOWVARIABLE": "%1 dəyişənini göstər",
    "DATA_HIDEVARIABLE": "%1 dəyişənini gizlət",
    "DATA_ADDTOLIST": "%1 -i %2 siyahısına əlavə et",
    "DATA_DELETEOFLIST": "%1 elementini %2 siyahısından sil",
    "DATA_DELETEALLOFLIST": "%1 siyahısının bütün elementlərini sil",
    "DATA_INSERTATLIST": "%1 elementini %2 mövqeyində %3 siyahısına daxil et",
    "DATA_REPLACEITEMOFLIST": "%1 elementini %2 siyahısında %3 ilə əvəz et",
    "DATA_ITEMOFLIST": "%1 elementi %2 siyahısından",
    "DATA_ITEMNUMOFLIST": "item # of %1 in %2",
    "DATA_LENGTHOFLIST": "%1 siyahısının uzunluğu",
    "DATA_LISTCONTAINSITEM": "%1 tərkibində %2 var?",
    "DATA_SHOWLIST": "%1 siyahısını göstər",
    "DATA_HIDELIST": "%1 siyahısını gizlət",
    "DATA_INDEX_ALL": "bütün",
    "DATA_INDEX_LAST": "son",
    "DATA_INDEX_RANDOM": "təsadüfi",
    "EVENT_WHENFLAGCLICKED": "%1 kliklənəndə",
    "EVENT_WHENTHISSPRITECLICKED": "sprayt kliklənəndə",
    "EVENT_WHENSTAGECLICKED": "səhnə kliklənəndə",
    "EVENT_WHENTOUCHINGOBJECT": "sprayt %1 toxunanda",
    "EVENT_WHENBROADCASTRECEIVED": "mən %1 qəbul edəndə",
    "EVENT_WHENBACKDROPSWITCHESTO": "fon %1 fonuna dəyişdiriləndə",
    "EVENT_WHENGREATERTHAN": "%1 > %2 olduqda",
    "EVENT_WHENGREATERTHAN_TIMER": "taymer",
    "EVENT_WHENGREATERTHAN_LOUDNESS": "səs ucalığı",
    "EVENT_BROADCAST": "%1 ötür",
    "EVENT_BROADCASTANDWAIT": "%1 ötür və gözlə",
    "EVENT_WHENKEYPRESSED": "%1 düyməsinə basdıqda",
    "EVENT_WHENKEYPRESSED_SPACE": "boşluq",
    "EVENT_WHENKEYPRESSED_LEFT": "sol ox",
    "EVENT_WHENKEYPRESSED_RIGHT": "sağ ox",
    "EVENT_WHENKEYPRESSED_DOWN": "aşağı ox",
    "EVENT_WHENKEYPRESSED_UP": "yuxarı ox",
    "EVENT_WHENKEYPRESSED_ANY": "hər hansı",
    "LOOKS_SAYFORSECS": "%1 de %2 saniyə",
    "LOOKS_SAY": "%1 de",
    "LOOKS_HELLO": "Salam!",
    "LOOKS_THINKFORSECS": "%1 düşün %2 saniyə",
    "LOOKS_THINK": "%1 düşün",
    "LOOKS_HMM": "Hmm...",
    "LOOKS_SHOW": "görün",
    "LOOKS_HIDE": "gizlən",
    "LOOKS_HIDEALLSPRITES": "bütün spraytları gizlət",
    "LOOKS_EFFECT_COLOR": "rəng",
    "LOOKS_EFFECT_FISHEYE": "qabarma",
    "LOOKS_EFFECT_WHIRL": "burulma",
    "LOOKS_EFFECT_PIXELATE": "piksel",
    "LOOKS_EFFECT_MOSAIC": "mozaika",
    "LOOKS_EFFECT_BRIGHTNESS": "parlaqlıq",
    "LOOKS_EFFECT_GHOST": "ruh",
    "LOOKS_CHANGEEFFECTBY": "%1 effektini %2 qədər dəyiş",
    "LOOKS_SETEFFECTTO": "%1 effektini %2 təyin et",
    "LOOKS_CLEARGRAPHICEFFECTS": "qrafik effektləri sil",
    "LOOKS_CHANGESIZEBY": "ölçünü %1 qədər dəyiş",
    "LOOKS_SETSIZETO": "ölçünü %1 % təyin et",
    "LOOKS_SIZE": "ölçü",
    "LOOKS_CHANGESTRETCHBY": "uzunluğu %1 qədər dəyiş",
    "LOOKS_SETSTRETCHTO": "uzunluğu %1 % təyin et",
    "LOOKS_SWITCHCOSTUMETO": "%1 libasına dəyiş",
    "LOOKS_NEXTCOSTUME": "növbəti libas",
    "LOOKS_SWITCHBACKDROPTO": "%1 fonuna dəyiş",
    "LOOKS_GOTOFRONTBACK": "%1 fonuna keç",
    "LOOKS_GOTOFRONTBACK_FRONT": "ön",
    "LOOKS_GOTOFRONTBACK_BACK": "arxa",
    "LOOKS_GOFORWARDBACKWARDLAYERS": "%1 %2 fonuna keç",
    "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "irəli",
    "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "geri",
    "LOOKS_BACKDROPNUMBERNAME": "%1 fonu",
    "LOOKS_COSTUMENUMBERNAME": "%1 libası",
    "LOOKS_NUMBERNAME_NUMBER": "rəqəm",
    "LOOKS_NUMBERNAME_NAME": "ad",
    "LOOKS_SWITCHBACKDROPTOANDWAIT": "fonu %1 fonuna dəyiş və gözlə",
    "LOOKS_NEXTBACKDROP_BLOCK": "növbəti fon",
    "LOOKS_NEXTBACKDROP": "növbəti fon",
    "LOOKS_PREVIOUSBACKDROP": "əvvəlki fon",
    "LOOKS_RANDOMBACKDROP": "təsadüfi fon",
    "MOTION_MOVESTEPS": "%1 addım at",
    "MOTION_TURNLEFT": "dön %1 %2 dərəcə",
    "MOTION_TURNRIGHT": "dön %1 %2 dərəcə",
    "MOTION_POINTINDIRECTION": "%1 istiqamətində dön",
    "MOTION_POINTTOWARDS": "%1 tərəfinə dön",
    "MOTION_POINTTOWARDS_POINTER": "siçanın göstəricisi",
    "MOTION_POINTTOWARDS_RANDOM": "random direction",
    "MOTION_GOTO": "keç: %1",
    "MOTION_GOTO_POINTER": "siçanın göstəricisi",
    "MOTION_GOTO_RANDOM": "təsadüfi mövqe",
    "MOTION_GOTOXY": "keç: x: %1 y: %2",
    "MOTION_GLIDESECSTOXY": "%1 saniyə x: %2 y: %3 nöqtəsinə üz",
    "MOTION_GLIDETO": "%1 saniyə %2 tərəfə üz",
    "MOTION_GLIDETO_POINTER": "siçanın göstəricisi",
    "MOTION_GLIDETO_RANDOM": "təsadüfi mövqe",
    "MOTION_CHANGEXBY": "x-i %1 qədər dəyiş",
    "MOTION_SETX": "x-i %1 təyin et",
    "MOTION_CHANGEYBY": "y-i %1 qədər dəyiş",
    "MOTION_SETY": "y-i %1 təyin et",
    "MOTION_IFONEDGEBOUNCE": "kənara çatdınsa, geri çəkil",
    "MOTION_SETROTATIONSTYLE": "dönmə istiqamətini %1 təyin et",
    "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "sol-sağ",
    "MOTION_SETROTATIONSTYLE_DONTROTATE": "dönməmək",
    "MOTION_SETROTATIONSTYLE_ALLAROUND": "hər tərəfə",
    "MOTION_XPOSITION": "x mövqeyi",
    "MOTION_YPOSITION": "y mövqeyi",
    "MOTION_DIRECTION": "istiqamət",
    "MOTION_SCROLLRIGHT": "sağa doğru %1 qədər sürüş",
    "MOTION_SCROLLUP": "yuxarı doğru %1 qədər sürüş",
    "MOTION_ALIGNSCENE": "%1 istiqamətdən səhnəni düzləndir",
    "MOTION_ALIGNSCENE_BOTTOMLEFT": "aşağı-sol",
    "MOTION_ALIGNSCENE_BOTTOMRIGHT": "aşağı-sağ",
    "MOTION_ALIGNSCENE_MIDDLE": "orta",
    "MOTION_ALIGNSCENE_TOPLEFT": "yuxarı-sol",
    "MOTION_ALIGNSCENE_TOPRIGHT": "yuxarı-sağ",
    "MOTION_XSCROLL": "x oxu üzrə sürüşdür",
    "MOTION_YSCROLL": "y oxu üzrə sürüşdür",
    "MOTION_STAGE_SELECTED": "Səhnə seçilmişdir: Hərəkət blokları yoxdur",
    "OPERATORS_ADD": "%1 + %2",
    "OPERATORS_SUBTRACT": "%1 - %2",
    "OPERATORS_MULTIPLY": "%1 * %2",
    "OPERATORS_DIVIDE": "%1 / %2",
    "OPERATORS_RANDOM": "%1 və %2 arasında təsadüfi seç",
    "OPERATORS_GT": "%1 > %2",
    "OPERATORS_GT_EQUALS": "",
    "OPERATORS_LT": "%1 < %2",
    "OPERATORS_LT_EQUALS": "",
    "OPERATORS_EQUALS": "%1 = %2",
    "OPERATORS_AND": "%1 və %2",
    "OPERATORS_OR": "%1 və ya %2",
    "OPERATORS_NOT": "%1 deyil ",
    "OPERATORS_JOIN": "birləşdir: %1 və %2",
    "OPERATORS_JOIN_APPLE": "alma",
    "OPERATORS_JOIN_BANANA": "banan",
    "OPERATORS_LETTEROF": "hərf %1 %2-də",
    "OPERATORS_LETTEROF_APPLE": "a",
    "OPERATORS_LENGTH": "%1 sözünün uzunluğu",
    "OPERATORS_CONTAINS": "%1 sözünün tərkibində %2 hərfi var?",
    "OPERATORS_MOD": "%1 ədədinin %2 ədədinə bölünməsindən qalıq",
    "OPERATORS_ROUND": "%1 yuvarlaqlaşdır",
    "OPERATORS_MATHOP": "%1 %2",
    "OPERATORS_MATHOP_ABS": "modul",
    "OPERATORS_MATHOP_FLOOR": "aşağı yuvarlaqlaşdır",
    "OPERATORS_MATHOP_CEILING": "yuxarı yuvarlaqlaşdır",
    "OPERATORS_MATHOP_SQRT": "kvadrat kök",
    "OPERATORS_MATHOP_SIN": "sin",
    "OPERATORS_MATHOP_COS": "cos",
    "OPERATORS_MATHOP_TAN": "tg",
    "OPERATORS_MATHOP_ASIN": "arcsin",
    "OPERATORS_MATHOP_ACOS": "arccos",
    "OPERATORS_MATHOP_ATAN": "arctg",
    "OPERATORS_MATHOP_LN": "ln",
    "OPERATORS_MATHOP_LOG": "log",
    "OPERATORS_MATHOP_EEXP": "e ^",
    "OPERATORS_MATHOP_10EXP": "10 ^",
    "PROCEDURES_DEFINITION": "%1 təyin et",
    "SENSING_TOUCHINGOBJECT": "%1 toxunur?",
    "SENSING_TOUCHINGOBJECT_POINTER": "siçanın göstəricisi",
    "SENSING_TOUCHINGOBJECT_EDGE": "kənar",
    "SENSING_TOUCHINGCOLOR": "%1 rənginə toxunur?",
    "SENSING_COLORISTOUCHINGCOLOR": "%1 rəngi %2 rənginə toxunur?",
    "SENSING_DISTANCETO": "%1 qədər məsafə",
    "SENSING_DISTANCETO_POINTER": "siçanın göstəricisi",
    "SENSING_ASKANDWAIT": "%1 soruş və gözlə",
    "SENSING_ASK_TEXT": "Sənin adın nədir?",
    "SENSING_ANSWER": "cavab",
    "SENSING_KEYPRESSED": "%1 düyməsi basılıb?",
    "SENSING_MOUSEDOWN": "siçanın düyməsi basılıb?",
    "SENSING_MOUSEX": "siçanın göstəricisinin x koordinatı",
    "SENSING_MOUSEY": "siçanın göstəricisinin y koordinatı",
    "SENSING_SETDRAGMODE": "%1 daşıma rejimi təyin et",
    "SENSING_SETDRAGMODE_DRAGGABLE": "daşınabilən",
    "SENSING_SETDRAGMODE_NOTDRAGGABLE": "daşınabilməyən",
    "SENSING_LOUDNESS": "səs ucalığı",
    "SENSING_LOUD": "ucadır?",
    "SENSING_TIMER": "taymer",
    "SENSING_RESETTIMER": "taymeri yenidən başlat",
    "SENSING_OF": "%1 %2-in",
    "SENSING_OF_XPOSITION": "x mövqeyi",
    "SENSING_OF_YPOSITION": "y mövqeyi",
    "SENSING_OF_DIRECTION": "istiqamət",
    "SENSING_OF_COSTUMENUMBER": "libas #",
    "SENSING_OF_COSTUMENAME": "libasın adı",
    "SENSING_OF_SIZE": "ölçü",
    "SENSING_OF_VOLUME": "səsin yüksəkliyi",
    "SENSING_OF_BACKDROPNUMBER": "fon #",
    "SENSING_OF_BACKDROPNAME": "fonun adı",
    "SENSING_OF_STAGE": "Səhnə",
    "SENSING_CURRENT": "cari %1",
    "SENSING_CURRENT_YEAR": "il",
    "SENSING_CURRENT_MONTH": "ay",
    "SENSING_CURRENT_DATE": "tarix",
    "SENSING_CURRENT_DAYOFWEEK": "həftənin günü",
    "SENSING_CURRENT_HOUR": "saat",
    "SENSING_CURRENT_MINUTE": "dəqiqə",
    "SENSING_CURRENT_SECOND": "saniyə",
    "SENSING_DAYSSINCE2000": "01.01.2000 tarixindən ötən günlərin sayı",
    "SENSING_USERNAME": "istifadəçinin adı",
    "SENSING_USERID": "istifadəçi adı",
    "SOUND_PLAY": "%1 səsini ifa et",
    "SOUND_PLAYUNTILDONE": "%1 səsini sona qədər ifa et",
    "SOUND_STOPALLSOUNDS": "bütün səsləri dayandır",
    "SOUND_SETEFFECTO": "%1 effektini %2 təyin et",
    "SOUND_CHANGEEFFECTBY": "%1 effektini %2 qədər dəyiş",
    "SOUND_CLEAREFFECTS": "səs effektlərini təmizlə",
    "SOUND_EFFECTS_PITCH": "kökləmək",
    "SOUND_EFFECTS_PAN": "tas sol/sağ",
    "SOUND_CHANGEVOLUMEBY": "səs ucalığını %1 qədər dəyiş",
    "SOUND_SETVOLUMETO": "səs ucalığını %1% təyin et",
    "SOUND_VOLUME": "səsin yüksəkliyi",
    "SOUND_RECORD": "record...",
    "CATEGORY_MOTION": "Hərəkət",
    "CATEGORY_LOOKS": "Görünüş",
    "CATEGORY_SOUND": "Səs",
    "CATEGORY_EVENTS": "Hadisələr",
    "CATEGORY_CONTROL": "İdarəetmə",
    "CATEGORY_SENSING": "Sensorlar",
    "CATEGORY_OPERATORS": "Operatorlar",
    "CATEGORY_VARIABLES": "Dəyişənlər",
    "CATEGORY_MYBLOCKS": "Mənim Bloklarım",
    "CATEGORY_LISTS": "",
    "CATEGORY_TUPLE": "",
    "CATEGORY_DICTIONARYS": "",
    "CATEGORY_NUMBERS": "",
    "CATEGORY_TEXT": "",
    "DUPLICATE": "Dublikatın yarat",
    "DELETE": "Sil",
    "ADD_COMMENT": "Şərh əlavə et",
    "REMOVE_COMMENT": "Şərhi sil",
    "DELETE_BLOCK": "Bloku sil",
    "DELETE_X_BLOCKS": "%1 bloklarını sil",
    "DELETE_ALL_BLOCKS": "bütün %1 bloklarını sil",
    "CLEAN_UP": "Blokları sil",
    "HELP": "Yardım",
    "UNDO": "Ləğv et",
    "REDO": "Yenidən",
    "EDIT_PROCEDURE": "Düzəliş et",
    "SHOW_PROCEDURE_DEFINITION": "Tərifə get",
    "WORKSPACE_COMMENT_DEFAULT_TEXT": "Nəsə de ...",
    "COLOUR_HUE_LABEL": "Rəng",
    "COLOUR_SATURATION_LABEL": "Dolğunluq",
    "COLOUR_BRIGHTNESS_LABEL": "Parlaqlıq",
    "CHANGE_VALUE_TITLE": "Qiyməti dəyiş",
    "RENAME_VARIABLE": "Dəyişənin adını dəyiş",
    "RENAME_VARIABLE_STRING": "",
    "RENAME_VARIABLE_NUMBER": "",
    "RENAME_VARIABLE_LIST": "",
    "RENAME_VARIABLE_TITLE": "Bütün \"%1\" dəyişənlərinin adını dəyiş:",
    "RENAME_VARIABLE_MODAL_TITLE": "Dəyişənin adını dəyiş",
    "NEW_VARIABLE": "Dəyişən yarat",
    "NEW_VARIABLE_TITLE": "Yeni dəyişənin adı:",
    "VARIABLE_MODAL_TITLE": "Yeni Dəyişən",
    "VARIABLE_ALREADY_EXISTS": "\"%1\" adlı dəyişən artıq mövcuddur.",
    "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "\"%1\" adlı dəyişən digər \"%2\" növ dəyişən üçün artıq mövcuddur.",
    "DELETE_VARIABLE_CONFIRMATION": "%1 silinsin \"%2\" dəyişənini istifadə edən?",
    "CANNOT_DELETE_VARIABLE_PROCEDURE": "\"%1\" dəyişəni silinə bilmir, çünki \"%2\" funksiyasındakı tərifin bir hissəsidir",
    "DELETE_VARIABLE": "\"%1\" dəyişənini sil",
    "DELETE_VARIABLE_STRING": "",
    "DELETE_VARIABLE_NUMBER": "",
    "DELETE_VARIABLE_LIST": "",
    "NEW_PROCEDURE": "Blok yarat",
    "PROCEDURE_ALREADY_EXISTS": "\"%1\" adlı prosedur artıq mövcuddur.",
    "PROCEDURE_DEFAULT_NAME": "blokun adı",
    "NEW_LIST": "Siyahı yarat",
    "NEW_LIST_TITLE": "Yeni siyahının adı:",
    "LIST_MODAL_TITLE": "Yeni siyahı",
    "LIST_ALREADY_EXISTS": "\"%1\" adlı siyahı artıq mövcuddur.",
    "RENAME_LIST_TITLE": "Bütün \"%1\" siyahılarının adını dəyiş:",
    "RENAME_LIST_MODAL_TITLE": "Siyahının adını dəyiş",
    "DEFAULT_LIST_ITEM": "əşya",
    "DELETE_LIST": "Delete the \"%1\" list",
    "RENAME_LIST": "Rename list",
    "NEW_BROADCAST_MESSAGE": "Yeni ismarıc",
    "NEW_BROADCAST_MESSAGE_TITLE": "Yeni ismarıcın adı:",
    "BROADCAST_MODAL_TITLE": "Yeni ismarıc",
    "DEFAULT_BROADCAST_MESSAGE_NAME": "ismarıc 1",
    "DELETE_PROCEDURE": "",
    "OK": "",
    "PROMPT": "",
    "STOP": "",
    "NEW_STRING": "",
    "NEW_NUMBER_VARIABLE": "",
    "WIFI": "",
    "USERNAME": "",
    "PWD": "",
    "IOT_SERVICE": "",
    "IOT_ID": "",
    "IOT_PWD": "",
    "IOT_TOPIC": "",
    "MORE_TOPIC": "",
    "OBLOQ_HTTP": "",
    "OBLOQ_IP": "",
    "OBLOQ_PORT": "",
    "ADD_TOPIC": "",
    "DELETE_TOPIC": "",
    "IOT_SERVER": "",
    "CHINA": "",
    "GLOBAL": "",
    "LOW_C": "",
    "LOW_C$": "",
    "LOW_D": "",
    "LOW_D$": "",
    "LOW_E": "",
    "LOW_F": "",
    "LOW_F$": "",
    "LOW_G": "",
    "LOW_G$": "",
    "LOW_A": "",
    "LOW_A$": "",
    "LOW_B": "",
    "MIDDLE_C": "",
    "MIDDLE_C$": "",
    "MIDDLE_D": "",
    "MIDDLE_D$": "",
    "MIDDLE_E": "",
    "MIDDLE_F": "",
    "MIDDLE_F$": "",
    "MIDDLE_G": "",
    "MIDDLE_G$": "",
    "MIDDLE_A": "",
    "MIDDLE_A$": "",
    "MIDDLE_B": "",
    "HIGH_C": "",
    "HIGH_C$": "",
    "HIGH_D": "",
    "HIGH_D$": "",
    "HIGH_E": "",
    "HIGH_F": "",
    "HIGH_F$": "",
    "HIGH_G": "",
    "HIGH_G$": "",
    "HIGH_A": "",
    "HIGH_A$": "",
    "HIGH_B": "",
    "OPERATORS_STRINGTONUMBER": "",
    "INTEGER": "",
    "DECIMAL": "",
    "OPERATORS_NUMTOASCII": "",
    "OPERATORS_STRINGTOASCII": "",
    "OPERATORS_NUMTOSTRING": "",
    "OPERATORS_MAP": "",
    "OPERATORS_CONSTRAIN": "",
    "STEPS_PER_TURN": "",
    "ROTATE_SPEED": "",
    "NEW_AI": "",
    "ASK_AND_PAINT": "",
    "GET_IDENTIFIED_NUM": "",
    "READ_NUM_AND_SAY": "",
    "IMAGE_ADDR": "",
    "IMAGE_PREVIEW": "",
    "IMAGE_OPEN": "",
    "IMAGE_SIZE": "",
    "IMAGE_WIDTH": "",
    "IMAGE_HEIGHT": "",
    "VARIABLE": "",
    "VARIABLE_LIST": "",
    "SET_STRING_VARIABLE": "",
    "STRING_START_WITH": "",
    "DICTIONARY_HEIGHT_FIRST": "",
    "DICTIONARY_HEIGHT_TWO": "",
    "DICTIONARY_HEIGHT_THREE": "",
    "DICTIONARY_HEIGHT_FOUR": "",
    "DATA_MPITEMOFLIST": "",
    "DATA_MPINTERCEPTITEM": "",
    "DATA_MPINSERTATLIST": "",
    "DATA_MPSETVALUEATLIST": "",
    "DATA_MPINSERTATLIST_INSERT": "",
    "DATA_MPINSERTATLIST_CHANGE": "",
    "DATA_MPINSERTATLIST_FOUR": "",
    "DATA_MPINSERTATLIST_FIVE": "",
    "DATA_MPINSERTATLIST_SIX": "",
    "DATA_MPINSERTATLIST_SEVEN": "",
    "DATA_MPINSERTATLIST_EIGHT": "",
    "DATA_MPINSERTATLIST_EIGHT_FALSE": "",
    "DATA_MPINSERTATLIST_EIGHT_TRUE": "",
    "DATA_MPINSERTATLIST_EIGHT_REVERSE": "",
    "OPERATORS_RANDOMA": "",
    "OPERATORS_MODA": "",
    "OPERATORS_ROUNDA": "",
    "OPERATORS_MATHOPA": "",
    "OPERATORS_MATHOP_ABSA": "",
    "OPERATORS_MATHOP_FLOORA": "",
    "OPERATORS_MATHOP_CEILINGA": "",
    "OPERATORS_MATHOP_SQRTA": "",
    "OPERATORS_MATHOP_FIVE": "",
    "OPERATORS_MATHOP_SIX": "",
    "OPERATORS_MATHOP_SEVEN": "",
    "OPERATORS_MATHOP_LENGHT": "",
    "OPERATORS_MATHOP_MAXMIN": "",
    "OPERATORS_MATHOP_MAXMIN_MAX": "",
    "OPERATORS_MATHOP_MAXMIN_MIN": "",
    "OPERATORS_TEXTS_ONE": "",
    "OPERATORS_TEXTS_TWO": "",
    "OPERATORS_TEXTS_THREE": "",
    "OPERATORS_TEXTS_FOUR": "",
    "OPERATORS_TEXTS_FOUR_NEW": "",
    "OPERATORS_TEXTS_NEW": "",
    "OPERATORS_TEXTS_NEW_OP": "",
    "OPERATORS_TEXTS_FOUR_ONE": "",
    "OPERATORS_TEXTS_FOUR_TWO": "",
    "OPERATORS_TEXTS_FOUR_THREE": "",
    "OPERATORS_TEXTS_FOUR_FOUR": "",
    "OPERATORS_TEXTS_FOUR_F": "",
    "OPERATORS_TEXTS_FOUR_L": "",
    "OPERATORS_STRINGTONUMBERA": "",
    "OPERATORS_NUMTOASCIIA": "",
    "OPERATORS_STRINGTOASCIIA": "",
    "OPERATORS_NUMTOSTRINGA": "",
    "NEW_MC": "",
    "RENAME_MCNUMBER_TITLE": "",
    "RENAME_MCNUMBER_MODAL_TITLE": "",
    "RENAME_VARIABLE_MCNUMBER": "",
    "DELETE_VARIABLE_MCNUMBER": "",
    "SET_MC_VARIABLE": "",
    "TUPLE_ONE": "",
    "TUPLE_TWO": "",
    "ALIYUN": "",
    "ONENET": "",
    "EASYIOT": "",
    "SHANGHAI": "",
    "QINGDAO": "",
    "BEIJING": "",
    "ZHANGJIAKOU": "",
    "HUHEHAOTE": "",
    "HANGZHOU": "",
    "SHENZHEN": "",
    "HONGKONG": "",
    "SINGAPORE": "",
    "SYDNEY": "",
    "KUALALUMPUR": "",
    "JAKARTA": "",
    "MUMBAI": "",
    "TOKYO": "",
    "SILICONVALLEY": "",
    "VIRGINIA": "",
    "FRANKFURT": "",
    "LONDON": "",
    "DUBAI": "",
    "IOT_PLATFORM": "",
    "PARAMS": "",
    "SERVER_ATTR": "",
    "PRODUCTID": "",
    "DEVICEID": "",
    "TINYDB_SECRET": "",
    "TINYDB_USER": "",
    "APIADDR": "",
    "SOFTWARE_SERIAL": "",
    "HARDWARE_SERIAL": "",
    "HARDWARE_SERIAL1": "",
    "HARDWARE_SERIAL2": "",
    "HARDWARE_SERIAL3": ""
  },
  "id": {
    "CONTROL_FOREVER": "selamanya",
    "CONTROL_REPEAT": "ulangi %1 kali",
    "CONTROL_IF": "jika %1 maka",
    "CONTROL_ELSE": "jika tidak",
    "CONTROL_STOP": "berhenti",
    "CONTROL_STOP_ALL": "semua",
    "CONTROL_STOP_THIS": "skrip ini",
    "CONTROL_STOP_OTHER": "skrip lain di sprite ini",
    "CONTROL_WAIT": "tunggu %1 detik",
    "CONTROL_WAITUNTIL": "tunggu sampai %1",
    "CONTROL_REPEATUNTIL": "ulangi sampai %1",
    "CONTROL_WHILE": "selama %1",
    "CONTROL_FOREACH": "untuk setiap %1 dalam %2",
    "CONTROL_STARTASCLONE": "ketika aku mulai sebagai clone",
    "CONTROL_CREATECLONEOF": "buat clone dari %1",
    "CONTROL_CREATECLONEOF_MYSELF": "diriku",
    "CONTROL_DELETETHISCLONE": "hapus clone ini",
    "CONTROL_COUNTER": "hitungan",
    "CONTROL_INCRCOUNTER": "tingkatkan hitungan",
    "CONTROL_CLEARCOUNTER": "bersihkan hitungan",
    "CONTROL_ALLATONCE": "semuanya sekaligus",
    "DATA_SETVARIABLETO": "atur %1 ke %2",
    "DATA_CHANGEVARIABLEBY": "ubah %1 sebesar %2",
    "DATA_SHOWVARIABLE": "tampilkan variabel %1",
    "DATA_HIDEVARIABLE": "sembunyikan variabel %1",
    "DATA_ADDTOLIST": "tambahkan %1 ke %2",
    "DATA_DELETEOFLIST": "hapus %1 dari %2",
    "DATA_DELETEALLOFLIST": "hapus semua dari %1",
    "DATA_INSERTATLIST": "sisipkan %1 pada %2 dari %3",
    "DATA_REPLACEITEMOFLIST": "ganti benda %1 dari %2 dengan %3",
    "DATA_ITEMOFLIST": "benda %1 dari %2",
    "DATA_ITEMNUMOFLIST": "benda # dari %1 di %2",
    "DATA_LENGTHOFLIST": "panjang dari %1",
    "DATA_LISTCONTAINSITEM": "%1 berisi %2?",
    "DATA_SHOWLIST": "tampilkan daftar %1",
    "DATA_HIDELIST": "sembunyikan daftar %1",
    "DATA_INDEX_ALL": "semua",
    "DATA_INDEX_LAST": "terakhir",
    "DATA_INDEX_RANDOM": "sembarang",
    "EVENT_WHENFLAGCLICKED": "ketika %1 diklik",
    "EVENT_WHENTHISSPRITECLICKED": "ketika sprite ini diklik",
    "EVENT_WHENSTAGECLICKED": "ketika panggung diklik",
    "EVENT_WHENTOUCHINGOBJECT": "ketika sprite ini menyentuh %1",
    "EVENT_WHENBROADCASTRECEIVED": "ketika aku menerima %1",
    "EVENT_WHENBACKDROPSWITCHESTO": "ketika latar menjadi %1",
    "EVENT_WHENGREATERTHAN": "ketika %1 > %2",
    "EVENT_WHENGREATERTHAN_TIMER": "pengatur waktu",
    "EVENT_WHENGREATERTHAN_LOUDNESS": "kenyaringan",
    "EVENT_BROADCAST": "siarkan %1",
    "EVENT_BROADCASTANDWAIT": "siarkan %1 dan tunggu",
    "EVENT_WHENKEYPRESSED": "ketika tombol %1 ditekan",
    "EVENT_WHENKEYPRESSED_SPACE": "spasi",
    "EVENT_WHENKEYPRESSED_LEFT": "panah kiri",
    "EVENT_WHENKEYPRESSED_RIGHT": "panah kanan",
    "EVENT_WHENKEYPRESSED_DOWN": "panah bawah",
    "EVENT_WHENKEYPRESSED_UP": "panah atas",
    "EVENT_WHENKEYPRESSED_ANY": "manapun",
    "LOOKS_SAYFORSECS": "katakan %1 selama %2 detik",
    "LOOKS_SAY": "katakan %1",
    "LOOKS_HELLO": "Halo!",
    "LOOKS_THINKFORSECS": "pikirkan %1 selama %2 detik",
    "LOOKS_THINK": "pikirkan %1",
    "LOOKS_HMM": "Hmm...",
    "LOOKS_SHOW": "tampilkan",
    "LOOKS_HIDE": "sembunyikan",
    "LOOKS_HIDEALLSPRITES": "sembunyikan semua sprite",
    "LOOKS_EFFECT_COLOR": "warna",
    "LOOKS_EFFECT_FISHEYE": "mata ikan",
    "LOOKS_EFFECT_WHIRL": "pusaran",
    "LOOKS_EFFECT_PIXELATE": "pixelasi",
    "LOOKS_EFFECT_MOSAIC": "mosaik",
    "LOOKS_EFFECT_BRIGHTNESS": "kecerahan",
    "LOOKS_EFFECT_GHOST": "bayangan",
    "LOOKS_CHANGEEFFECTBY": "ubah efek %1 sebesar %2",
    "LOOKS_SETEFFECTTO": "atur efek %1 ke %2",
    "LOOKS_CLEARGRAPHICEFFECTS": "hapus semua efek grafis",
    "LOOKS_CHANGESIZEBY": "ubah ukuran sebesar %1",
    "LOOKS_SETSIZETO": "atur ukuran ke %1 %",
    "LOOKS_SIZE": "ukuran",
    "LOOKS_CHANGESTRETCHBY": "ubah rentangan sebesar %1",
    "LOOKS_SETSTRETCHTO": "atur rentangan ke %1 %",
    "LOOKS_SWITCHCOSTUMETO": "ganti kostum ke %1",
    "LOOKS_NEXTCOSTUME": "kostum berikutnya",
    "LOOKS_SWITCHBACKDROPTO": "ganti latar ke %1",
    "LOOKS_GOTOFRONTBACK": "pergi ke lapisan %1",
    "LOOKS_GOTOFRONTBACK_FRONT": "depan",
    "LOOKS_GOTOFRONTBACK_BACK": "belakang",
    "LOOKS_GOFORWARDBACKWARDLAYERS": "pergi %1 %2 lapisan",
    "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "maju",
    "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "mundur",
    "LOOKS_BACKDROPNUMBERNAME": "latar %1",
    "LOOKS_COSTUMENUMBERNAME": "kostum %1",
    "LOOKS_NUMBERNAME_NUMBER": "angka",
    "LOOKS_NUMBERNAME_NAME": "nama",
    "LOOKS_SWITCHBACKDROPTOANDWAIT": "ganti latar ke %1 dan tunggu",
    "LOOKS_NEXTBACKDROP_BLOCK": "latar berikutnya",
    "LOOKS_NEXTBACKDROP": "latar berikutnya",
    "LOOKS_PREVIOUSBACKDROP": "latar sebelumnya",
    "LOOKS_RANDOMBACKDROP": "latar sembarang",
    "MOTION_MOVESTEPS": "gerak %1 langkah",
    "MOTION_TURNLEFT": "putar %1 %2 derajat",
    "MOTION_TURNRIGHT": "putar %1 %2 derajat",
    "MOTION_POINTINDIRECTION": "mengarah ke arah %1",
    "MOTION_POINTTOWARDS": "mengarah ke %1",
    "MOTION_POINTTOWARDS_POINTER": "penunjuk tetikus",
    "MOTION_POINTTOWARDS_RANDOM": "arah sembarang",
    "MOTION_GOTO": "pergi ke %1",
    "MOTION_GOTO_POINTER": "penunjuk tetikus",
    "MOTION_GOTO_RANDOM": "posisi sembarang",
    "MOTION_GOTOXY": "pergi ke x: %1 y: %2",
    "MOTION_GLIDESECSTOXY": "meluncur %1 detik ke x: %2 y: %3",
    "MOTION_GLIDETO": "meluncur %1 detik ke %2",
    "MOTION_GLIDETO_POINTER": "penunjuk tetikus",
    "MOTION_GLIDETO_RANDOM": "posisi sembarang",
    "MOTION_CHANGEXBY": "ubah x sebesar %1",
    "MOTION_SETX": "atur x ke %1",
    "MOTION_CHANGEYBY": "ubah y sebesar %1",
    "MOTION_SETY": "atur y ke %1",
    "MOTION_IFONEDGEBOUNCE": "jika di pinggir, pantulkan",
    "MOTION_SETROTATIONSTYLE": "atur gaya rotasi %1",
    "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "kiri-kanan",
    "MOTION_SETROTATIONSTYLE_DONTROTATE": "jangan berputar",
    "MOTION_SETROTATIONSTYLE_ALLAROUND": "seluruh",
    "MOTION_XPOSITION": "posisi x",
    "MOTION_YPOSITION": "posisi y",
    "MOTION_DIRECTION": "arah",
    "MOTION_SCROLLRIGHT": "gulir ke kanan %1",
    "MOTION_SCROLLUP": "gulir ke atas %1",
    "MOTION_ALIGNSCENE": "luruskan pemandangan %1",
    "MOTION_ALIGNSCENE_BOTTOMLEFT": "kiri-bawah",
    "MOTION_ALIGNSCENE_BOTTOMRIGHT": "kanan-bawah",
    "MOTION_ALIGNSCENE_MIDDLE": "tengah",
    "MOTION_ALIGNSCENE_TOPLEFT": "kiri-atas",
    "MOTION_ALIGNSCENE_TOPRIGHT": "kanan-atas",
    "MOTION_XSCROLL": "guliran x",
    "MOTION_YSCROLL": "guliran y",
    "MOTION_STAGE_SELECTED": "Panggung terpilih: tidak ada balok gerakan",
    "OPERATORS_ADD": "%1 + %2",
    "OPERATORS_SUBTRACT": "%1 - %2",
    "OPERATORS_MULTIPLY": "%1 * %2",
    "OPERATORS_DIVIDE": "%1 / %2",
    "OPERATORS_RANDOM": "pilih acak dari %1 hingga %2",
    "OPERATORS_GT": "%1 > %2",
    "OPERATORS_GT_EQUALS": "",
    "OPERATORS_LT": "%1 < %2",
    "OPERATORS_LT_EQUALS": "",
    "OPERATORS_EQUALS": "%1 = %2",
    "OPERATORS_AND": "%1 dan %2",
    "OPERATORS_OR": "%1 atau %2",
    "OPERATORS_NOT": "tidak %1",
    "OPERATORS_JOIN": "gabungkan %1 %2",
    "OPERATORS_JOIN_APPLE": "apel",
    "OPERATORS_JOIN_BANANA": "pisang",
    "OPERATORS_LETTEROF": "huruf %1 dari %2",
    "OPERATORS_LETTEROF_APPLE": "a",
    "OPERATORS_LENGTH": "panjang dari %1",
    "OPERATORS_CONTAINS": "%1 berisi %2?",
    "OPERATORS_MOD": "%1 mod %2",
    "OPERATORS_ROUND": "bulatkan %1",
    "OPERATORS_MATHOP": "%1 dari %2",
    "OPERATORS_MATHOP_ABS": "abs",
    "OPERATORS_MATHOP_FLOOR": "floor",
    "OPERATORS_MATHOP_CEILING": "ceil",
    "OPERATORS_MATHOP_SQRT": "akar kuadrat",
    "OPERATORS_MATHOP_SIN": "sin",
    "OPERATORS_MATHOP_COS": "cos",
    "OPERATORS_MATHOP_TAN": "tan",
    "OPERATORS_MATHOP_ASIN": "asin",
    "OPERATORS_MATHOP_ACOS": "acos",
    "OPERATORS_MATHOP_ATAN": "atan",
    "OPERATORS_MATHOP_LN": "ln",
    "OPERATORS_MATHOP_LOG": "log",
    "OPERATORS_MATHOP_EEXP": "e ^",
    "OPERATORS_MATHOP_10EXP": "10 ^",
    "PROCEDURES_DEFINITION": "artikan %1",
    "SENSING_TOUCHINGOBJECT": "menyentuh %1?",
    "SENSING_TOUCHINGOBJECT_POINTER": "penunjuk tetikus",
    "SENSING_TOUCHINGOBJECT_EDGE": "pinggir",
    "SENSING_TOUCHINGCOLOR": "menyentuh warna %1?",
    "SENSING_COLORISTOUCHINGCOLOR": "warna %1 menyentuh %2?",
    "SENSING_DISTANCETO": "jarak dari %1",
    "SENSING_DISTANCETO_POINTER": "penunujuk tetikus",
    "SENSING_ASKANDWAIT": "tanya %1 dan tunggu",
    "SENSING_ASK_TEXT": "Apa namamu?",
    "SENSING_ANSWER": "jawaban",
    "SENSING_KEYPRESSED": "tombol %1 ditekan?",
    "SENSING_MOUSEDOWN": "tetikus ditekan?",
    "SENSING_MOUSEX": "x tetikus",
    "SENSING_MOUSEY": "y tetikus",
    "SENSING_SETDRAGMODE": "atur mode seret %1",
    "SENSING_SETDRAGMODE_DRAGGABLE": "dapat diseret",
    "SENSING_SETDRAGMODE_NOTDRAGGABLE": "tidak dapat diseret",
    "SENSING_LOUDNESS": "kenyaringan",
    "SENSING_LOUD": "nyaring?",
    "SENSING_TIMER": "pengatur waktu",
    "SENSING_RESETTIMER": "atur ulang pengatur waktu",
    "SENSING_OF": "%1 dari %2",
    "SENSING_OF_XPOSITION": "posisi x",
    "SENSING_OF_YPOSITION": "posisi y",
    "SENSING_OF_DIRECTION": "arah",
    "SENSING_OF_COSTUMENUMBER": "kostum #",
    "SENSING_OF_COSTUMENAME": "nama kostum",
    "SENSING_OF_SIZE": "ukuran",
    "SENSING_OF_VOLUME": "volume",
    "SENSING_OF_BACKDROPNUMBER": "latar #",
    "SENSING_OF_BACKDROPNAME": "nama latar",
    "SENSING_OF_STAGE": "Panggung",
    "SENSING_CURRENT": "%1 saat ini",
    "SENSING_CURRENT_YEAR": "tahun",
    "SENSING_CURRENT_MONTH": "bulan",
    "SENSING_CURRENT_DATE": "tanggal",
    "SENSING_CURRENT_DAYOFWEEK": "nama hari",
    "SENSING_CURRENT_HOUR": "jam",
    "SENSING_CURRENT_MINUTE": "menit",
    "SENSING_CURRENT_SECOND": "detik",
    "SENSING_DAYSSINCE2000": "hari-hari sejak 2000",
    "SENSING_USERNAME": "nama pengguna",
    "SENSING_USERID": "id pengguna",
    "SOUND_PLAY": "mulai suara %1",
    "SOUND_PLAYUNTILDONE": "mainkan suara %1 sampai selesai",
    "SOUND_STOPALLSOUNDS": "hentikan semua suara",
    "SOUND_SETEFFECTO": "atur efek %1 ke %2",
    "SOUND_CHANGEEFFECTBY": "ubah efek %1 sebesar %2",
    "SOUND_CLEAREFFECTS": "hapus semua efek suara",
    "SOUND_EFFECTS_PITCH": "nada",
    "SOUND_EFFECTS_PAN": "pan kiri/kanan",
    "SOUND_CHANGEVOLUMEBY": "ubah volume sebesar %1",
    "SOUND_SETVOLUMETO": "atur volume ke %1%",
    "SOUND_VOLUME": "volume",
    "SOUND_RECORD": "rekam...",
    "CATEGORY_MOTION": "Gerakan",
    "CATEGORY_LOOKS": "Tampilan",
    "CATEGORY_SOUND": "Suara",
    "CATEGORY_EVENTS": "Kejadian",
    "CATEGORY_CONTROL": "Kontrol",
    "CATEGORY_SENSING": "Sensor",
    "CATEGORY_OPERATORS": "Operator",
    "CATEGORY_VARIABLES": "Variabel",
    "CATEGORY_MYBLOCKS": "Balok Saya",
    "CATEGORY_LISTS": "",
    "CATEGORY_TUPLE": "",
    "CATEGORY_DICTIONARYS": "",
    "CATEGORY_NUMBERS": "",
    "CATEGORY_TEXT": "",
    "DUPLICATE": "Gandakan",
    "DELETE": "Hapus",
    "ADD_COMMENT": "Tambahkan Komentar",
    "REMOVE_COMMENT": "Hapus Komentar",
    "DELETE_BLOCK": "Hapus Balok",
    "DELETE_X_BLOCKS": "Hapus %1 Balok",
    "DELETE_ALL_BLOCKS": "Hapus semua %1 balok?",
    "CLEAN_UP": "Bersihkan Balok-Balok",
    "HELP": "Bantuan",
    "UNDO": "Batalkan",
    "REDO": "Ulangi",
    "EDIT_PROCEDURE": "Sunting",
    "SHOW_PROCEDURE_DEFINITION": "Pergi ke pengartian",
    "WORKSPACE_COMMENT_DEFAULT_TEXT": "Katakan sesuatu...",
    "COLOUR_HUE_LABEL": "Warna",
    "COLOUR_SATURATION_LABEL": "Saturasi",
    "COLOUR_BRIGHTNESS_LABEL": "Keterangan",
    "CHANGE_VALUE_TITLE": "Ubah nilai:",
    "RENAME_VARIABLE": "Namakan ulang variabel",
    "RENAME_VARIABLE_STRING": "",
    "RENAME_VARIABLE_NUMBER": "",
    "RENAME_VARIABLE_LIST": "",
    "RENAME_VARIABLE_TITLE": "Namakan ulang semua variabel \"%1\" ke:",
    "RENAME_VARIABLE_MODAL_TITLE": "Namakan Ulang Variabel",
    "NEW_VARIABLE": "Buat sebuah Variabel",
    "NEW_VARIABLE_TITLE": "Nama variabel baru:",
    "VARIABLE_MODAL_TITLE": "Variabel Baru",
    "VARIABLE_ALREADY_EXISTS": "Sebuah variabel bernama \"%1\" sudah ada.",
    "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "Sebuah variabel bernama \"%1\" sudah ada untuk variabel lain berjenis \"%2\".",
    "DELETE_VARIABLE_CONFIRMATION": "Hapus penggunaan %1 dari variabel \"%2\"?",
    "CANNOT_DELETE_VARIABLE_PROCEDURE": "Tidak bisa menghapus variabel \"%1\" karena bagian dari pengartian fungsi \"%2\"",
    "DELETE_VARIABLE": "Hapus variabel \"%1\"",
    "DELETE_VARIABLE_STRING": "",
    "DELETE_VARIABLE_NUMBER": "",
    "DELETE_VARIABLE_LIST": "",
    "NEW_PROCEDURE": "Buat sebuah Balok",
    "PROCEDURE_ALREADY_EXISTS": "Sebuah prosedur bernama \"%1\" sudah ada",
    "PROCEDURE_DEFAULT_NAME": "nama balok",
    "NEW_LIST": "Buat sebuah Daftar",
    "NEW_LIST_TITLE": "Nama daftar baru:",
    "LIST_MODAL_TITLE": "Daftar Baru",
    "LIST_ALREADY_EXISTS": "Sebuah daftar bernama \"%1\" sudah ada.",
    "RENAME_LIST_TITLE": "Namakan ulang semua daftar \"%1\" ke:",
    "RENAME_LIST_MODAL_TITLE": "Namakan Ulang Daftar:",
    "DEFAULT_LIST_ITEM": "benda",
    "DELETE_LIST": "Hapus daftar \"%1\"",
    "RENAME_LIST": "Namakan ulang daftar",
    "NEW_BROADCAST_MESSAGE": "Pesan baru",
    "NEW_BROADCAST_MESSAGE_TITLE": "Nama pesan baru:",
    "BROADCAST_MODAL_TITLE": "Pesan Baru",
    "DEFAULT_BROADCAST_MESSAGE_NAME": "pesan1",
    "DELETE_PROCEDURE": "",
    "OK": "",
    "PROMPT": "",
    "STOP": "",
    "NEW_STRING": "",
    "NEW_NUMBER_VARIABLE": "",
    "WIFI": "",
    "USERNAME": "",
    "PWD": "",
    "IOT_SERVICE": "",
    "IOT_ID": "",
    "IOT_PWD": "",
    "IOT_TOPIC": "",
    "MORE_TOPIC": "",
    "OBLOQ_HTTP": "",
    "OBLOQ_IP": "",
    "OBLOQ_PORT": "",
    "ADD_TOPIC": "",
    "DELETE_TOPIC": "",
    "IOT_SERVER": "",
    "CHINA": "",
    "GLOBAL": "",
    "LOW_C": "",
    "LOW_C$": "",
    "LOW_D": "",
    "LOW_D$": "",
    "LOW_E": "",
    "LOW_F": "",
    "LOW_F$": "",
    "LOW_G": "",
    "LOW_G$": "",
    "LOW_A": "",
    "LOW_A$": "",
    "LOW_B": "",
    "MIDDLE_C": "",
    "MIDDLE_C$": "",
    "MIDDLE_D": "",
    "MIDDLE_D$": "",
    "MIDDLE_E": "",
    "MIDDLE_F": "",
    "MIDDLE_F$": "",
    "MIDDLE_G": "",
    "MIDDLE_G$": "",
    "MIDDLE_A": "",
    "MIDDLE_A$": "",
    "MIDDLE_B": "",
    "HIGH_C": "",
    "HIGH_C$": "",
    "HIGH_D": "",
    "HIGH_D$": "",
    "HIGH_E": "",
    "HIGH_F": "",
    "HIGH_F$": "",
    "HIGH_G": "",
    "HIGH_G$": "",
    "HIGH_A": "",
    "HIGH_A$": "",
    "HIGH_B": "",
    "OPERATORS_STRINGTONUMBER": "",
    "INTEGER": "",
    "DECIMAL": "",
    "OPERATORS_NUMTOASCII": "",
    "OPERATORS_STRINGTOASCII": "",
    "OPERATORS_NUMTOSTRING": "",
    "OPERATORS_MAP": "",
    "OPERATORS_CONSTRAIN": "",
    "STEPS_PER_TURN": "",
    "ROTATE_SPEED": "",
    "NEW_AI": "",
    "ASK_AND_PAINT": "",
    "GET_IDENTIFIED_NUM": "",
    "READ_NUM_AND_SAY": "",
    "IMAGE_ADDR": "",
    "IMAGE_PREVIEW": "",
    "IMAGE_OPEN": "",
    "IMAGE_SIZE": "",
    "IMAGE_WIDTH": "",
    "IMAGE_HEIGHT": "",
    "VARIABLE": "",
    "VARIABLE_LIST": "",
    "SET_STRING_VARIABLE": "",
    "STRING_START_WITH": "",
    "DICTIONARY_HEIGHT_FIRST": "",
    "DICTIONARY_HEIGHT_TWO": "",
    "DICTIONARY_HEIGHT_THREE": "",
    "DICTIONARY_HEIGHT_FOUR": "",
    "DATA_MPITEMOFLIST": "",
    "DATA_MPINTERCEPTITEM": "",
    "DATA_MPINSERTATLIST": "",
    "DATA_MPSETVALUEATLIST": "",
    "DATA_MPINSERTATLIST_INSERT": "",
    "DATA_MPINSERTATLIST_CHANGE": "",
    "DATA_MPINSERTATLIST_FOUR": "",
    "DATA_MPINSERTATLIST_FIVE": "",
    "DATA_MPINSERTATLIST_SIX": "",
    "DATA_MPINSERTATLIST_SEVEN": "",
    "DATA_MPINSERTATLIST_EIGHT": "",
    "DATA_MPINSERTATLIST_EIGHT_FALSE": "",
    "DATA_MPINSERTATLIST_EIGHT_TRUE": "",
    "DATA_MPINSERTATLIST_EIGHT_REVERSE": "",
    "OPERATORS_RANDOMA": "",
    "OPERATORS_MODA": "",
    "OPERATORS_ROUNDA": "",
    "OPERATORS_MATHOPA": "",
    "OPERATORS_MATHOP_ABSA": "",
    "OPERATORS_MATHOP_FLOORA": "",
    "OPERATORS_MATHOP_CEILINGA": "",
    "OPERATORS_MATHOP_SQRTA": "",
    "OPERATORS_MATHOP_FIVE": "",
    "OPERATORS_MATHOP_SIX": "",
    "OPERATORS_MATHOP_SEVEN": "",
    "OPERATORS_MATHOP_LENGHT": "",
    "OPERATORS_MATHOP_MAXMIN": "",
    "OPERATORS_MATHOP_MAXMIN_MAX": "",
    "OPERATORS_MATHOP_MAXMIN_MIN": "",
    "OPERATORS_TEXTS_ONE": "",
    "OPERATORS_TEXTS_TWO": "",
    "OPERATORS_TEXTS_THREE": "",
    "OPERATORS_TEXTS_FOUR": "",
    "OPERATORS_TEXTS_FOUR_NEW": "",
    "OPERATORS_TEXTS_NEW": "",
    "OPERATORS_TEXTS_NEW_OP": "",
    "OPERATORS_TEXTS_FOUR_ONE": "",
    "OPERATORS_TEXTS_FOUR_TWO": "",
    "OPERATORS_TEXTS_FOUR_THREE": "",
    "OPERATORS_TEXTS_FOUR_FOUR": "",
    "OPERATORS_TEXTS_FOUR_F": "",
    "OPERATORS_TEXTS_FOUR_L": "",
    "OPERATORS_STRINGTONUMBERA": "",
    "OPERATORS_NUMTOASCIIA": "",
    "OPERATORS_STRINGTOASCIIA": "",
    "OPERATORS_NUMTOSTRINGA": "",
    "NEW_MC": "",
    "RENAME_MCNUMBER_TITLE": "",
    "RENAME_MCNUMBER_MODAL_TITLE": "",
    "RENAME_VARIABLE_MCNUMBER": "",
    "DELETE_VARIABLE_MCNUMBER": "",
    "SET_MC_VARIABLE": "",
    "TUPLE_ONE": "",
    "TUPLE_TWO": "",
    "ALIYUN": "",
    "ONENET": "",
    "EASYIOT": "",
    "SHANGHAI": "",
    "QINGDAO": "",
    "BEIJING": "",
    "ZHANGJIAKOU": "",
    "HUHEHAOTE": "",
    "HANGZHOU": "",
    "SHENZHEN": "",
    "HONGKONG": "",
    "SINGAPORE": "",
    "SYDNEY": "",
    "KUALALUMPUR": "",
    "JAKARTA": "",
    "MUMBAI": "",
    "TOKYO": "",
    "SILICONVALLEY": "",
    "VIRGINIA": "",
    "FRANKFURT": "",
    "LONDON": "",
    "DUBAI": "",
    "IOT_PLATFORM": "",
    "PARAMS": "",
    "SERVER_ATTR": "",
    "PRODUCTID": "",
    "DEVICEID": "",
    "TINYDB_SECRET": "",
    "TINYDB_USER": "",
    "APIADDR": "",
    "SOFTWARE_SERIAL": "",
    "HARDWARE_SERIAL": "",
    "HARDWARE_SERIAL1": "",
    "HARDWARE_SERIAL2": "",
    "HARDWARE_SERIAL3": ""
  },
  "bg": {
    "CONTROL_FOREVER": "forever",
    "CONTROL_REPEAT": "повтори %1",
    "CONTROL_IF": "ако %1 тогава",
    "CONTROL_ELSE": "иначе",
    "CONTROL_STOP": "stop",
    "CONTROL_STOP_ALL": "всички",
    "CONTROL_STOP_THIS": "този скрипт",
    "CONTROL_STOP_OTHER": "другите скриптове на спрайта",
    "CONTROL_WAIT": "изчакай %1 сек",
    "CONTROL_WAITUNTIL": "чакай докато %1",
    "CONTROL_REPEATUNTIL": "repeat until %1",
    "CONTROL_WHILE": "while %1",
    "CONTROL_FOREACH": "for each %1 in %2",
    "CONTROL_STARTASCLONE": "when I start as a clone",
    "CONTROL_CREATECLONEOF": "create clone of %1",
    "CONTROL_CREATECLONEOF_MYSELF": "себе си",
    "CONTROL_DELETETHISCLONE": "delete this clone",
    "CONTROL_COUNTER": "брояч",
    "CONTROL_INCRCOUNTER": "нарастващ брояч",
    "CONTROL_CLEARCOUNTER": "clear counter",
    "CONTROL_ALLATONCE": "all at once",
    "DATA_SETVARIABLETO": "set %1 to %2",
    "DATA_CHANGEVARIABLEBY": "change %1 by %2",
    "DATA_SHOWVARIABLE": "show variable %1",
    "DATA_HIDEVARIABLE": "hide variable %1",
    "DATA_ADDTOLIST": "add %1 to %2",
    "DATA_DELETEOFLIST": "изтрий %1 от %2",
    "DATA_DELETEALLOFLIST": "изтрий всичко от %1",
    "DATA_INSERTATLIST": "insert %1 at %2 of %3",
    "DATA_REPLACEITEMOFLIST": "replace item %1 of %2 with %3",
    "DATA_ITEMOFLIST": "елемент %1 от %2",
    "DATA_ITEMNUMOFLIST": "item # of %1 in %2",
    "DATA_LENGTHOFLIST": "големина на %1",
    "DATA_LISTCONTAINSITEM": "%1 съдържа %2?",
    "DATA_SHOWLIST": "покажи списък %1",
    "DATA_HIDELIST": "скрий списък %1",
    "DATA_INDEX_ALL": "всички",
    "DATA_INDEX_LAST": "последен",
    "DATA_INDEX_RANDOM": "random",
    "EVENT_WHENFLAGCLICKED": "when %1 clicked",
    "EVENT_WHENTHISSPRITECLICKED": "when this sprite clicked",
    "EVENT_WHENSTAGECLICKED": "when stage clicked",
    "EVENT_WHENTOUCHINGOBJECT": "when this sprite touches %1",
    "EVENT_WHENBROADCASTRECEIVED": "when I receive %1",
    "EVENT_WHENBACKDROPSWITCHESTO": "when backdrop switches to %1",
    "EVENT_WHENGREATERTHAN": "когато %1 > %2",
    "EVENT_WHENGREATERTHAN_TIMER": "таймер",
    "EVENT_WHENGREATERTHAN_LOUDNESS": "ниво на звука",
    "EVENT_BROADCAST": "разпространи %1",
    "EVENT_BROADCASTANDWAIT": "разпространи %1 и чакай",
    "EVENT_WHENKEYPRESSED": "когато е натиснат клавиш %1",
    "EVENT_WHENKEYPRESSED_SPACE": "интервал",
    "EVENT_WHENKEYPRESSED_LEFT": "лява стрелка",
    "EVENT_WHENKEYPRESSED_RIGHT": "дясна стрелка",
    "EVENT_WHENKEYPRESSED_DOWN": "стрелка надолу",
    "EVENT_WHENKEYPRESSED_UP": "стрелка нагоре",
    "EVENT_WHENKEYPRESSED_ANY": "който и да е",
    "LOOKS_SAYFORSECS": "кажи %1 за %2 сек",
    "LOOKS_SAY": "кажи %1",
    "LOOKS_HELLO": "Здравей!",
    "LOOKS_THINKFORSECS": "мисли %1 за %2 сек",
    "LOOKS_THINK": "мисли %1",
    "LOOKS_HMM": "Хъмм...",
    "LOOKS_SHOW": "покажи се",
    "LOOKS_HIDE": "скрий се",
    "LOOKS_HIDEALLSPRITES": "скрий всички спрайтове",
    "LOOKS_EFFECT_COLOR": "цвят",
    "LOOKS_EFFECT_FISHEYE": "fisheye",
    "LOOKS_EFFECT_WHIRL": "whirl",
    "LOOKS_EFFECT_PIXELATE": "pixelate",
    "LOOKS_EFFECT_MOSAIC": "мозайка",
    "LOOKS_EFFECT_BRIGHTNESS": "яркост",
    "LOOKS_EFFECT_GHOST": "ghost",
    "LOOKS_CHANGEEFFECTBY": "промени ефект %1  с %2",
    "LOOKS_SETEFFECTTO": "направи %1 ефект на %2",
    "LOOKS_CLEARGRAPHICEFFECTS": "премахни графичните ефекти",
    "LOOKS_CHANGESIZEBY": "промени размера с %1",
    "LOOKS_SETSIZETO": "set size to %1 %",
    "LOOKS_SIZE": "размер",
    "LOOKS_CHANGESTRETCHBY": "change stretch by %1",
    "LOOKS_SETSTRETCHTO": "set stretch to %1 %",
    "LOOKS_SWITCHCOSTUMETO": "switch costume to %1",
    "LOOKS_NEXTCOSTUME": "следващ костюм",
    "LOOKS_SWITCHBACKDROPTO": "смени декора с %1",
    "LOOKS_GOTOFRONTBACK": "go to %1 layer",
    "LOOKS_GOTOFRONTBACK_FRONT": "front",
    "LOOKS_GOTOFRONTBACK_BACK": "back",
    "LOOKS_GOFORWARDBACKWARDLAYERS": "премини %1 с %2 слой/я",
    "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "forward",
    "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "backward",
    "LOOKS_BACKDROPNUMBERNAME": "декор %1",
    "LOOKS_COSTUMENUMBERNAME": "костюм %1",
    "LOOKS_NUMBERNAME_NUMBER": "number",
    "LOOKS_NUMBERNAME_NAME": "name",
    "LOOKS_SWITCHBACKDROPTOANDWAIT": "смени декора с %1 и чакай",
    "LOOKS_NEXTBACKDROP_BLOCK": "следващ декор",
    "LOOKS_NEXTBACKDROP": "следващ декор",
    "LOOKS_PREVIOUSBACKDROP": "предишен декор",
    "LOOKS_RANDOMBACKDROP": "случаен декор",
    "MOTION_MOVESTEPS": "премести се с %1 стъпки",
    "MOTION_TURNLEFT": "завърти се с %1 %2 градуса",
    "MOTION_TURNRIGHT": "ззавърти се с %1 %2 градуса",
    "MOTION_POINTINDIRECTION": "обърни се в посока %1",
    "MOTION_POINTTOWARDS": "обърни се към %1",
    "MOTION_POINTTOWARDS_POINTER": "показалец на мишката",
    "MOTION_POINTTOWARDS_RANDOM": "случайна посока",
    "MOTION_GOTO": "отиди до %1",
    "MOTION_GOTO_POINTER": "показалец на мишката",
    "MOTION_GOTO_RANDOM": "случайна позиция",
    "MOTION_GOTOXY": "отиди до x: %1 y: %2",
    "MOTION_GLIDESECSTOXY": "пропълзи за %1 сек до x: %2 y: %3",
    "MOTION_GLIDETO": "пропълзи за %1 сек до %2",
    "MOTION_GLIDETO_POINTER": "показалец на мишката",
    "MOTION_GLIDETO_RANDOM": "случайна позиция",
    "MOTION_CHANGEXBY": "промени х с %1",
    "MOTION_SETX": "направи x равно на %1",
    "MOTION_CHANGEYBY": "промени y с %1",
    "MOTION_SETY": "направи y равно на %1",
    "MOTION_IFONEDGEBOUNCE": "ако докосваш ръба, отблъсни се",
    "MOTION_SETROTATIONSTYLE": "избери начин на въртене %1",
    "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "наляво-надясно",
    "MOTION_SETROTATIONSTYLE_DONTROTATE": "без въртене",
    "MOTION_SETROTATIONSTYLE_ALLAROUND": "във всички посоки",
    "MOTION_XPOSITION": "x позиция",
    "MOTION_YPOSITION": "y позиция",
    "MOTION_DIRECTION": "посока",
    "MOTION_SCROLLRIGHT": "превърти надясно %1",
    "MOTION_SCROLLUP": "превърти нагоре %1",
    "MOTION_ALIGNSCENE": "подравни %1",
    "MOTION_ALIGNSCENE_BOTTOMLEFT": "долу-ляво",
    "MOTION_ALIGNSCENE_BOTTOMRIGHT": "долу-дясно",
    "MOTION_ALIGNSCENE_MIDDLE": "по средата",
    "MOTION_ALIGNSCENE_TOPLEFT": "горе-ляво",
    "MOTION_ALIGNSCENE_TOPRIGHT": "горе-дясно",
    "MOTION_XSCROLL": "хоризонтално превъртане",
    "MOTION_YSCROLL": "вертикално превъртане",
    "MOTION_STAGE_SELECTED": "Избрана е сцената: няма блокове за движение",
    "OPERATORS_ADD": "%1 + %2",
    "OPERATORS_SUBTRACT": "%1 - %2",
    "OPERATORS_MULTIPLY": "%1 * %2",
    "OPERATORS_DIVIDE": "%1 / %2",
    "OPERATORS_RANDOM": "избери случайно от %1 до %2",
    "OPERATORS_GT": "%1 > %2",
    "OPERATORS_GT_EQUALS": "",
    "OPERATORS_LT": "%1 < %2",
    "OPERATORS_LT_EQUALS": "",
    "OPERATORS_EQUALS": "%1 = %2",
    "OPERATORS_AND": "%1 и %2",
    "OPERATORS_OR": "%1 или %2",
    "OPERATORS_NOT": "не %1",
    "OPERATORS_JOIN": "съедини %1 %2",
    "OPERATORS_JOIN_APPLE": "ябълка",
    "OPERATORS_JOIN_BANANA": "банан",
    "OPERATORS_LETTEROF": "буква %1 от %2",
    "OPERATORS_LETTEROF_APPLE": "а",
    "OPERATORS_LENGTH": "дължина на %1",
    "OPERATORS_CONTAINS": "%1 съдържа %2?",
    "OPERATORS_MOD": "%1 mod %2",
    "OPERATORS_ROUND": "закръгли %1",
    "OPERATORS_MATHOP": "%1 от %2",
    "OPERATORS_MATHOP_ABS": "абсолютна стойност",
    "OPERATORS_MATHOP_FLOOR": "floor",
    "OPERATORS_MATHOP_CEILING": "ceiling",
    "OPERATORS_MATHOP_SQRT": "квадратен корен",
    "OPERATORS_MATHOP_SIN": "sin",
    "OPERATORS_MATHOP_COS": "cos",
    "OPERATORS_MATHOP_TAN": "tan",
    "OPERATORS_MATHOP_ASIN": "asin",
    "OPERATORS_MATHOP_ACOS": "acos",
    "OPERATORS_MATHOP_ATAN": "atan",
    "OPERATORS_MATHOP_LN": "ln",
    "OPERATORS_MATHOP_LOG": "log",
    "OPERATORS_MATHOP_EEXP": "e^",
    "OPERATORS_MATHOP_10EXP": "10 ^",
    "PROCEDURES_DEFINITION": "дефинирай %1",
    "SENSING_TOUCHINGOBJECT": "допира ли %1?",
    "SENSING_TOUCHINGOBJECT_POINTER": "показалец на мишката",
    "SENSING_TOUCHINGOBJECT_EDGE": "ръб",
    "SENSING_TOUCHINGCOLOR": "допира ли цвят %1?",
    "SENSING_COLORISTOUCHINGCOLOR": "цвят %1 допира ли %2?",
    "SENSING_DISTANCETO": "разстояние до %1",
    "SENSING_DISTANCETO_POINTER": "показалец на мишката",
    "SENSING_ASKANDWAIT": "питай %1 и чакай",
    "SENSING_ASK_TEXT": "Какво е твоето име?",
    "SENSING_ANSWER": "отговор",
    "SENSING_KEYPRESSED": "клавиш %1 натиснат?",
    "SENSING_MOUSEDOWN": "mouse down?",
    "SENSING_MOUSEX": "мишка x",
    "SENSING_MOUSEY": "мишка y",
    "SENSING_SETDRAGMODE": "режим на теглене %1",
    "SENSING_SETDRAGMODE_DRAGGABLE": "теглене разрешено",
    "SENSING_SETDRAGMODE_NOTDRAGGABLE": "теглене забранено",
    "SENSING_LOUDNESS": "сила на звука",
    "SENSING_LOUD": "шумно ли е?",
    "SENSING_TIMER": "таймер",
    "SENSING_RESETTIMER": "нулирай таймера",
    "SENSING_OF": "%1 от %2",
    "SENSING_OF_XPOSITION": "x позиция",
    "SENSING_OF_YPOSITION": "y позиция",
    "SENSING_OF_DIRECTION": "посока",
    "SENSING_OF_COSTUMENUMBER": "костюм #",
    "SENSING_OF_COSTUMENAME": "име на костюм",
    "SENSING_OF_SIZE": "размер",
    "SENSING_OF_VOLUME": "сила на звука",
    "SENSING_OF_BACKDROPNUMBER": "декор #",
    "SENSING_OF_BACKDROPNAME": "име на декор",
    "SENSING_OF_STAGE": "Сцена",
    "SENSING_CURRENT": "текущ/а %1",
    "SENSING_CURRENT_YEAR": "година",
    "SENSING_CURRENT_MONTH": "месец",
    "SENSING_CURRENT_DATE": "дата",
    "SENSING_CURRENT_DAYOFWEEK": "ден от седмицата",
    "SENSING_CURRENT_HOUR": "час",
    "SENSING_CURRENT_MINUTE": "минута",
    "SENSING_CURRENT_SECOND": "секунда",
    "SENSING_DAYSSINCE2000": "дни след 2000 година",
    "SENSING_USERNAME": "потребителско име",
    "SENSING_USERID": "user id",
    "SOUND_PLAY": "пусни звук %1",
    "SOUND_PLAYUNTILDONE": "пусни звук %1 докато свърши",
    "SOUND_STOPALLSOUNDS": "stop all sounds",
    "SOUND_SETEFFECTO": "set %1 effect to %2",
    "SOUND_CHANGEEFFECTBY": "change %1 effect by %2",
    "SOUND_CLEAREFFECTS": "clear sound effects",
    "SOUND_EFFECTS_PITCH": "промяна на тона",
    "SOUND_EFFECTS_PAN": "ляво/дясно",
    "SOUND_CHANGEVOLUMEBY": "промени силата на звука с %1",
    "SOUND_SETVOLUMETO": "set volume to %1%",
    "SOUND_VOLUME": "сила на звука",
    "SOUND_RECORD": "запис...",
    "CATEGORY_MOTION": "Движение",
    "CATEGORY_LOOKS": "Външност",
    "CATEGORY_SOUND": "Звук",
    "CATEGORY_EVENTS": "Събития",
    "CATEGORY_CONTROL": "Контрол",
    "CATEGORY_SENSING": "Сетива",
    "CATEGORY_OPERATORS": "Оператори",
    "CATEGORY_VARIABLES": "Променливи",
    "CATEGORY_MYBLOCKS": "Моите Блокове",
    "CATEGORY_LISTS": "",
    "CATEGORY_TUPLE": "",
    "CATEGORY_DICTIONARYS": "",
    "CATEGORY_NUMBERS": "",
    "CATEGORY_TEXT": "",
    "DUPLICATE": "Дублиране",
    "DELETE": "Изтриване",
    "ADD_COMMENT": "Добави Коментар",
    "REMOVE_COMMENT": "Премахни Коментар",
    "DELETE_BLOCK": "Изтрий Блок",
    "DELETE_X_BLOCKS": "Изтрий %1 блока",
    "DELETE_ALL_BLOCKS": "Изтрий всички %1 блокове",
    "CLEAN_UP": "Изтрий блоковете",
    "HELP": "Помощ",
    "UNDO": "Отмяна",
    "REDO": "Повтаряне",
    "EDIT_PROCEDURE": "Редактиране",
    "SHOW_PROCEDURE_DEFINITION": "Покажи дефиницията",
    "WORKSPACE_COMMENT_DEFAULT_TEXT": "Кажи нещо...",
    "COLOUR_HUE_LABEL": "Цвят",
    "COLOUR_SATURATION_LABEL": "Наситеност",
    "COLOUR_BRIGHTNESS_LABEL": "Яркост",
    "CHANGE_VALUE_TITLE": "Промени стойността:",
    "RENAME_VARIABLE": "Преименувай променлива",
    "RENAME_VARIABLE_STRING": "",
    "RENAME_VARIABLE_NUMBER": "",
    "RENAME_VARIABLE_LIST": "",
    "RENAME_VARIABLE_TITLE": "Преименувай всички \"%1\" променливи на:",
    "RENAME_VARIABLE_MODAL_TITLE": "Преименувай Променлива",
    "NEW_VARIABLE": "Създаване на Променлива",
    "NEW_VARIABLE_TITLE": "Име на новата променлива:",
    "VARIABLE_MODAL_TITLE": "Нова променлива",
    "VARIABLE_ALREADY_EXISTS": "Променлива \"%1\" вече съществува.",
    "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "Променлива \"%1\" вече съществува за друга променлива от тип \"%2\".",
    "DELETE_VARIABLE_CONFIRMATION": "Delete %1 uses of the \"%2\" variable?",
    "CANNOT_DELETE_VARIABLE_PROCEDURE": "Не може да се изтрие променливата \"%1\", защото участва в дефиницията на функцията \"%2\".",
    "DELETE_VARIABLE": "Изтрий променливата \"%1\"",
    "DELETE_VARIABLE_STRING": "",
    "DELETE_VARIABLE_NUMBER": "",
    "DELETE_VARIABLE_LIST": "",
    "NEW_PROCEDURE": "Създаване на блок",
    "PROCEDURE_ALREADY_EXISTS": "Процедура \"%1\" вече съществува.",
    "PROCEDURE_DEFAULT_NAME": "име на блок",
    "NEW_LIST": "Създаване на списък",
    "NEW_LIST_TITLE": "Име на новия списък:",
    "LIST_MODAL_TITLE": "Нов списък",
    "LIST_ALREADY_EXISTS": "Списък \"%1\" вече съществува.",
    "RENAME_LIST_TITLE": "Преименувай всички \"%1\" списъци на:",
    "RENAME_LIST_MODAL_TITLE": "Преименувай списък",
    "DEFAULT_LIST_ITEM": "thing",
    "DELETE_LIST": "Изтрий списъкът \"%1\"",
    "RENAME_LIST": "Преименувай списък",
    "NEW_BROADCAST_MESSAGE": "Ново съобщение",
    "NEW_BROADCAST_MESSAGE_TITLE": "Име на новото съобщение:",
    "BROADCAST_MODAL_TITLE": "Ново съобщение",
    "DEFAULT_BROADCAST_MESSAGE_NAME": "съобщение1",
    "DELETE_PROCEDURE": "",
    "OK": "",
    "PROMPT": "",
    "STOP": "",
    "NEW_STRING": "",
    "NEW_NUMBER_VARIABLE": "",
    "WIFI": "",
    "USERNAME": "",
    "PWD": "",
    "IOT_SERVICE": "",
    "IOT_ID": "",
    "IOT_PWD": "",
    "IOT_TOPIC": "",
    "MORE_TOPIC": "",
    "OBLOQ_HTTP": "",
    "OBLOQ_IP": "",
    "OBLOQ_PORT": "",
    "ADD_TOPIC": "",
    "DELETE_TOPIC": "",
    "IOT_SERVER": "",
    "CHINA": "",
    "GLOBAL": "",
    "LOW_C": "",
    "LOW_C$": "",
    "LOW_D": "",
    "LOW_D$": "",
    "LOW_E": "",
    "LOW_F": "",
    "LOW_F$": "",
    "LOW_G": "",
    "LOW_G$": "",
    "LOW_A": "",
    "LOW_A$": "",
    "LOW_B": "",
    "MIDDLE_C": "",
    "MIDDLE_C$": "",
    "MIDDLE_D": "",
    "MIDDLE_D$": "",
    "MIDDLE_E": "",
    "MIDDLE_F": "",
    "MIDDLE_F$": "",
    "MIDDLE_G": "",
    "MIDDLE_G$": "",
    "MIDDLE_A": "",
    "MIDDLE_A$": "",
    "MIDDLE_B": "",
    "HIGH_C": "",
    "HIGH_C$": "",
    "HIGH_D": "",
    "HIGH_D$": "",
    "HIGH_E": "",
    "HIGH_F": "",
    "HIGH_F$": "",
    "HIGH_G": "",
    "HIGH_G$": "",
    "HIGH_A": "",
    "HIGH_A$": "",
    "HIGH_B": "",
    "OPERATORS_STRINGTONUMBER": "",
    "INTEGER": "",
    "DECIMAL": "",
    "OPERATORS_NUMTOASCII": "",
    "OPERATORS_STRINGTOASCII": "",
    "OPERATORS_NUMTOSTRING": "",
    "OPERATORS_MAP": "",
    "OPERATORS_CONSTRAIN": "",
    "STEPS_PER_TURN": "",
    "ROTATE_SPEED": "",
    "NEW_AI": "",
    "ASK_AND_PAINT": "",
    "GET_IDENTIFIED_NUM": "",
    "READ_NUM_AND_SAY": "",
    "IMAGE_ADDR": "",
    "IMAGE_PREVIEW": "",
    "IMAGE_OPEN": "",
    "IMAGE_SIZE": "",
    "IMAGE_WIDTH": "",
    "IMAGE_HEIGHT": "",
    "VARIABLE": "",
    "VARIABLE_LIST": "",
    "SET_STRING_VARIABLE": "",
    "STRING_START_WITH": "",
    "DICTIONARY_HEIGHT_FIRST": "",
    "DICTIONARY_HEIGHT_TWO": "",
    "DICTIONARY_HEIGHT_THREE": "",
    "DICTIONARY_HEIGHT_FOUR": "",
    "DATA_MPITEMOFLIST": "",
    "DATA_MPINTERCEPTITEM": "",
    "DATA_MPINSERTATLIST": "",
    "DATA_MPSETVALUEATLIST": "",
    "DATA_MPINSERTATLIST_INSERT": "",
    "DATA_MPINSERTATLIST_CHANGE": "",
    "DATA_MPINSERTATLIST_FOUR": "",
    "DATA_MPINSERTATLIST_FIVE": "",
    "DATA_MPINSERTATLIST_SIX": "",
    "DATA_MPINSERTATLIST_SEVEN": "",
    "DATA_MPINSERTATLIST_EIGHT": "",
    "DATA_MPINSERTATLIST_EIGHT_FALSE": "",
    "DATA_MPINSERTATLIST_EIGHT_TRUE": "",
    "DATA_MPINSERTATLIST_EIGHT_REVERSE": "",
    "OPERATORS_RANDOMA": "",
    "OPERATORS_MODA": "",
    "OPERATORS_ROUNDA": "",
    "OPERATORS_MATHOPA": "",
    "OPERATORS_MATHOP_ABSA": "",
    "OPERATORS_MATHOP_FLOORA": "",
    "OPERATORS_MATHOP_CEILINGA": "",
    "OPERATORS_MATHOP_SQRTA": "",
    "OPERATORS_MATHOP_FIVE": "",
    "OPERATORS_MATHOP_SIX": "",
    "OPERATORS_MATHOP_SEVEN": "",
    "OPERATORS_MATHOP_LENGHT": "",
    "OPERATORS_MATHOP_MAXMIN": "",
    "OPERATORS_MATHOP_MAXMIN_MAX": "",
    "OPERATORS_MATHOP_MAXMIN_MIN": "",
    "OPERATORS_TEXTS_ONE": "",
    "OPERATORS_TEXTS_TWO": "",
    "OPERATORS_TEXTS_THREE": "",
    "OPERATORS_TEXTS_FOUR": "",
    "OPERATORS_TEXTS_FOUR_NEW": "",
    "OPERATORS_TEXTS_NEW": "",
    "OPERATORS_TEXTS_NEW_OP": "",
    "OPERATORS_TEXTS_FOUR_ONE": "",
    "OPERATORS_TEXTS_FOUR_TWO": "",
    "OPERATORS_TEXTS_FOUR_THREE": "",
    "OPERATORS_TEXTS_FOUR_FOUR": "",
    "OPERATORS_TEXTS_FOUR_F": "",
    "OPERATORS_TEXTS_FOUR_L": "",
    "OPERATORS_STRINGTONUMBERA": "",
    "OPERATORS_NUMTOASCIIA": "",
    "OPERATORS_STRINGTOASCIIA": "",
    "OPERATORS_NUMTOSTRINGA": "",
    "NEW_MC": "",
    "RENAME_MCNUMBER_TITLE": "",
    "RENAME_MCNUMBER_MODAL_TITLE": "",
    "RENAME_VARIABLE_MCNUMBER": "",
    "DELETE_VARIABLE_MCNUMBER": "",
    "SET_MC_VARIABLE": "",
    "TUPLE_ONE": "",
    "TUPLE_TWO": "",
    "ALIYUN": "",
    "ONENET": "",
    "EASYIOT": "",
    "SHANGHAI": "",
    "QINGDAO": "",
    "BEIJING": "",
    "ZHANGJIAKOU": "",
    "HUHEHAOTE": "",
    "HANGZHOU": "",
    "SHENZHEN": "",
    "HONGKONG": "",
    "SINGAPORE": "",
    "SYDNEY": "",
    "KUALALUMPUR": "",
    "JAKARTA": "",
    "MUMBAI": "",
    "TOKYO": "",
    "SILICONVALLEY": "",
    "VIRGINIA": "",
    "FRANKFURT": "",
    "LONDON": "",
    "DUBAI": "",
    "IOT_PLATFORM": "",
    "PARAMS": "",
    "SERVER_ATTR": "",
    "PRODUCTID": "",
    "DEVICEID": "",
    "TINYDB_SECRET": "",
    "TINYDB_USER": "",
    "APIADDR": "",
    "SOFTWARE_SERIAL": "",
    "HARDWARE_SERIAL": "",
    "HARDWARE_SERIAL1": "",
    "HARDWARE_SERIAL2": "",
    "HARDWARE_SERIAL3": ""
  },
  "ca": {
    "CONTROL_FOREVER": "per sempre",
    "CONTROL_LOOP_BREAKCONTINUE": "%1",
    "CONTROL_LOOP_BREAKCONTINUE_BREAK": "break",
    "CONTROL_LOOP_BREAKCONTINUE_CONTINUE": "continue",
    "CONTROL_REPEAT": "repetir %1",
    "CONTROL_IF": "si %1 aleshores",
    "CONTROL_ELSE": "si no",
    "CONTROL_STOP": "aturar",
    "CONTROL_STOP_ALL": "tots",
    "CONTROL_STOP_THIS": "aquest programa",
    "CONTROL_STOP_OTHER": "altres programes en l'objecte",
    "CONTROL_WAIT": "esperar %1 segons",
    "CONTROL_WAIT_ESP32_MPY": "espera %1 %2",
    "CONTROL_FOR_FROM_SEQUENCE": "use %1 from sequence %2",
    "CONTROL_FOR_RANGE_SPACE": "Use %1 from range %2 to %3 every %4",
    "CONTROL_WAIT_ESP32_MPY_S": "second(s)",
    "CONTROL_WAIT_ESP32_MPY_MS": "millisecond(ms)",
    "CONTROL_WAIT_ESP32_MPY_US": "microsecond(us)",
    "CONTROL_WAITUNTIL": "esperar fins que %1",
    "CONTROL_REPEATUNTIL": "repetir fins que %1",
    "CONTROL_WHILE": "mentre %1",
    "CONTROL_FOREACH": "per cada %1 en %2",
    "CONTROL_STARTASCLONE": "al començar com a clon",
    "CONTROL_CREATECLONEOF": "crear clon de %1",
    "CONTROL_CREATECLONEOF_MYSELF": "jo mateix",
    "CONTROL_DELETETHISCLONE": "eliminar aquest clon",
    "CONTROL_COUNTER": "comptador",
    "CONTROL_INCRCOUNTER": "incrementar comptador",
    "CONTROL_CLEARCOUNTER": "esborrar comptador",
    "CONTROL_ALLATONCE": "tots a la vegada",
    "DATA_SETVARIABLETO": "establir %1 a %2",
    "DATA_CHANGEVARIABLEBY": "canviar %1 per %2",
    "DATA_SHOWVARIABLE": "mostra variable %1",
    "DATA_HIDEVARIABLE": "amaga variable %1",
    "DATA_ADDTOLIST": "afegir %1 a %2",
    "DATA_DELETEOFLIST": "esborrar %1 de %2",
    "DATA_DELETEALLOFLIST": "eliminar tots de %1",
    "DATA_INSERTATLIST": "inserir %1 en %2 de %3",
    "DATA_REPLACEITEMOFLIST": "reemplaçar element %1 de %2 amb %3",
    "DATA_ITEMOFLIST": "element %1 de %2",
    "DATA_ITEMNUMOFLIST": "item # of %1 in %2",
    "DATA_LENGTHOFLIST": "length of %1",
    "DATA_LISTCONTAINSITEM": "%1 contains %2?",
    "DATA_SHOWLIST": "mostra llista %1",
    "DATA_HIDELIST": "amagar llista %1",
    "DATA_INDEX_ALL": "tots",
    "DATA_INDEX_LAST": "últim",
    "DATA_INDEX_RANDOM": "random",
    "EVENT_WHENFLAGCLICKED": "quan la %1 es cliqui",
    "EVENT_WHENTHISSPRITECLICKED": "al fer clic a aquest objecte",
    "EVENT_WHENSTAGECLICKED": "al fer clic a aquest escenari",
    "EVENT_WHENTOUCHINGOBJECT": "quan aquest objecte toqui %1",
    "EVENT_WHENBROADCASTRECEIVED": "en rebre %1",
    "EVENT_WHENBACKDROPSWITCHESTO": "quan el fons canviar a %1",
    "EVENT_WHENGREATERTHAN": "quan %1 > %2",
    "EVENT_WHENGREATERTHAN_TIMER": "Cronòmetre",
    "EVENT_WHENGREATERTHAN_LOUDNESS": "volum del so",
    "EVENT_BROADCAST": "enviar %1",
    "EVENT_BROADCASTANDWAIT": "enviar %1 i esperar",
    "EVENT_WHENKEYPRESSED": "en prémer la tecla %1",
    "EVENT_WHENKEYPRESSED_SPACE": "espai",
    "EVENT_WHENKEYPRESSED_LEFT": "fletxa esquerra",
    "EVENT_WHENKEYPRESSED_RIGHT": "fletxa dreta",
    "EVENT_WHENKEYPRESSED_DOWN": "fletxa avall",
    "EVENT_WHENKEYPRESSED_UP": "fletxa amunt",
    "EVENT_WHENKEYPRESSED_ANY": "qualsevol",
    "LOOKS_SAYFORSECS": "dir %1 durant %2 segons",
    "LOOKS_SAY": "dir %1",
    "LOOKS_HELLO": "Hola!",
    "LOOKS_THINKFORSECS": "pensar %1 durant %2 segons",
    "LOOKS_THINK": "pensar %1",
    "LOOKS_HMM": "Hmm...",
    "LOOKS_SHOW": "Mostrar",
    "LOOKS_HIDE": "amagar llista",
    "LOOKS_HIDEALLSPRITES": "amagar tots els sprites",
    "LOOKS_EFFECT_COLOR": "color",
    "LOOKS_EFFECT_FISHEYE": "Ull de peiz",
    "LOOKS_EFFECT_WHIRL": "remolí",
    "LOOKS_EFFECT_PIXELATE": "pixalar",
    "LOOKS_EFFECT_MOSAIC": "mosaic",
    "LOOKS_EFFECT_BRIGHTNESS": "brillantor",
    "LOOKS_EFFECT_GHOST": "esvair",
    "LOOKS_CHANGEEFFECTBY": "canviar efecte %1 per %2",
    "LOOKS_SETEFFECTTO": "establir efecte %1 a %2",
    "LOOKS_CLEARGRAPHICEFFECTS": "treure tots els efectes",
    "LOOKS_CHANGESIZEBY": "canviar la mida en %1",
    "LOOKS_SETSIZETO": "fixa mida a %1 %",
    "LOOKS_SIZE": "mida",
    "LOOKS_CHANGESTRETCHBY": "change stretch by %1",
    "LOOKS_SETSTRETCHTO": "set stretch to %1 %",
    "LOOKS_SWITCHCOSTUMETO": "canviar disfressa per %1",
    "LOOKS_NEXTCOSTUME": "següent disfressa",
    "LOOKS_SWITCHBACKDROPTO": "canviar fons a %1",
    "LOOKS_GOTOFRONTBACK": "anar a la capa %1",
    "LOOKS_GOTOFRONTBACK_FRONT": "front",
    "LOOKS_GOTOFRONTBACK_BACK": "darrera",
    "LOOKS_GOFORWARDBACKWARDLAYERS": "anar %2 capes cap a %1",
    "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "Endavant",
    "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "Enrera",
    "LOOKS_BACKDROPNUMBERNAME": "fons %1",
    "LOOKS_COSTUMENUMBERNAME": "disfressa %1",
    "LOOKS_NUMBERNAME_NUMBER": "nombre",
    "LOOKS_NUMBERNAME_NAME": "nom",
    "LOOKS_SWITCHBACKDROPTOANDWAIT": "canviar fons a %1 i esperar",
    "LOOKS_NEXTBACKDROP_BLOCK": "següent fons",
    "LOOKS_NEXTBACKDROP": "següent fons",
    "LOOKS_PREVIOUSBACKDROP": "fons previ",
    "LOOKS_RANDOMBACKDROP": "fons aleatori",
    "MOTION_MOVESTEPS": "moure %1 passos",
    "MOTION_TURNLEFT": "girar %1 %2 graus",
    "MOTION_TURNRIGHT": "girar %1 %2 graus",
    "MOTION_POINTINDIRECTION": "apuntar cap a direcció %1",
    "MOTION_POINTTOWARDS": "apuntar cap a %1",
    "MOTION_POINTTOWARDS_POINTER": "Punter del ratolí",
    "MOTION_POINTTOWARDS_RANDOM": "direcció aleatòria",
    "MOTION_GOTO": "anar a %1",
    "MOTION_GOTO_POINTER": "punter ratolí",
    "MOTION_GOTO_RANDOM": "posició aleatòria",
    "MOTION_GOTOXY": "anar a x: %1 y: %2",
    "MOTION_GLIDESECSTOXY": "llisca %1 segons fins x: %2 y: %3",
    "MOTION_GLIDETO": "llisca %1 segons fins %2",
    "MOTION_GLIDETO_POINTER": "Punter del ratolí",
    "MOTION_GLIDETO_RANDOM": "Posició aleatòria",
    "MOTION_CHANGEXBY": "canviar x a %1",
    "MOTION_SETX": "establir x a %1",
    "MOTION_CHANGEYBY": "canviar y a %1",
    "MOTION_SETY": "establir y a %1",
    "MOTION_IFONEDGEBOUNCE": "Si està al límit, rebota",
    "MOTION_SETROTATIONSTYLE": "establir estil de rotació %1",
    "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "esquerra-dreta",
    "MOTION_SETROTATIONSTYLE_DONTROTATE": "no rotar",
    "MOTION_SETROTATIONSTYLE_ALLAROUND": "en totes les direccions",
    "MOTION_XPOSITION": "posició en x",
    "MOTION_YPOSITION": "posició en y",
    "MOTION_DIRECTION": "direcció",
    "MOTION_SCROLLRIGHT": "llisca a la dreta %1",
    "MOTION_SCROLLUP": "llisca a l'esquerra %1",
    "MOTION_ALIGNSCENE": "alinear amb l'escena %1",
    "MOTION_ALIGNSCENE_BOTTOMLEFT": "baix a l'esquerra",
    "MOTION_ALIGNSCENE_BOTTOMRIGHT": "baix a la dreta",
    "MOTION_ALIGNSCENE_MIDDLE": "mig",
    "MOTION_ALIGNSCENE_TOPLEFT": "dalt a l'esquerra",
    "MOTION_ALIGNSCENE_TOPRIGHT": "dalt a la dreta",
    "MOTION_XSCROLL": "lliscar x",
    "MOTION_YSCROLL": "lliscar y",
    "MOTION_STAGE_SELECTED": "Escenari seleccionat: no hi ha blocs de moviment",
    "OPERATORS_ADD": "%1 + %2",
    "OPERATORS_SUBTRACT": "%1 - %2",
    "OPERATORS_MULTIPLY": "%1 * %2",
    "OPERATORS_DIVIDE": "%1 / %2",
    "OPERATORS_RANDOM": "nombre a l'atzar entre %1 i %2",
    "OPERATORS_GT": "%1 > %2",
    "OPERATORS_GT_EQUALS": "%1 >= %2",
    "OPERATORS_LT": "%1 < %2",
    "OPERATORS_LT_EQUALS": "%1 <= %2",
    "OPERATORS_EQUALS": "%1 = %2",
    "OPERATORS_AND": "%1 i %2",
    "OPERATORS_OR": "%1 o %2",
    "OPERATORS_NOT": "no %1",
    "CONTROL_TRUE_FALSE": "%1",
    "CONTROL_TRUE_FALSE_TRUE": "cert",
    "CONTROL_TRUE_FALSE_FALSE": "fals",
    "CONTROL_NONE": "cap",
    "CONTROL_RETURN": "retorna %1",
    "OPERATORS_VALUE_IS_TRUE": "valor %1 és cert?",
    "OPERATORS_VALUE_TYPE": "valor %1 tipus",
    "CONTROL_TRY": "try",
    "CONTROL_EXCEPT": "except",
    "CONTROL_FINALLY": "finally",
    "OPERATORS_VALUE_TYPE_IS": "%1 type is %2?",
    "OPERATORS_VALUE_TYPE_INT": "int",
    "OPERATORS_VALUE_TYPE_FLOAT": "float",
    "OPERATORS_VALUE_TYPE_BOOL": "bool",
    "OPERATORS_VALUE_TYPE_STR": "str",
    "OPERATORS_VALUE_TYPE_LIST": "list",
    "OPERATORS_VALUE_TYPE_TUPLE": "tuple",
    "OPERATORS_VALUE_TYPE_SET": "set",
    "OPERATORS_VALUE_TYPE_DICT": "dict",
    "OPERATORS_VALUE_TYPE_BYTES": "bytes",
    "OPERATORS_VALUE_TYPE_BYTEARRAY": "bytearray",
    "OPERATORS_VALUE_TYPE_COMPLEX": "complex",
    "OPERATORS_CACULATE_RESULT": "eval %1",
    "OPERATORS_JOIN": "unir %1 %2",
    "OPERATORS_JOIN_APPLE": "Poma",
    "OPERATORS_JOIN_BANANA": "banana",
    "OPERATORS_LETTEROF": "lletra %1 de %2",
    "OPERATORS_LETTEROF_APPLE": "a",
    "OPERATORS_LENGTH": "longitud de %1",
    "OPERATORS_CONTAINS": "%1 conté %2?",
    "OPERATORS_MOD": "%1 mod %2",
    "OPERATORS_ROUND": "round %1",
    "OPERATORS_MATHOP": "%1 of %2",
    "OPERATORS_MATHOP_ABS": "abs",
    "OPERATORS_MATHOP_FLOOR": "floor",
    "OPERATORS_MATHOP_CEILING": "ceiling",
    "OPERATORS_MATHOP_SQRT": "sqrt",
    "OPERATORS_MATHOP_SIN": "sin",
    "OPERATORS_MATHOP_COS": "cos",
    "OPERATORS_MATHOP_TAN": "tan",
    "OPERATORS_MATHOP_ASIN": "asin",
    "OPERATORS_MATHOP_ACOS": "acos",
    "OPERATORS_MATHOP_ATAN": "atan",
    "OPERATORS_MATHOP_LN": "ln",
    "OPERATORS_MATHOP_LOG": "log",
    "OPERATORS_MATHOP_EEXP": "e ^",
    "OPERATORS_MATHOP_10EXP": "10 ^",
    "PROCEDURES_DEFINITION": "define %1",
    "SENSING_TOUCHINGOBJECT": "tocant %1?",
    "SENSING_TOUCHINGOBJECT_POINTER": "el punter del ratolí",
    "SENSING_TOUCHINGOBJECT_EDGE": "edge",
    "SENSING_TOUCHINGCOLOR": "tocant color %1?",
    "SENSING_COLORISTOUCHINGCOLOR": "color %1 està tocant %2?",
    "SENSING_DISTANCETO": "distància a %1",
    "SENSING_DISTANCETO_POINTER": "mouse-pointer",
    "SENSING_ASKANDWAIT": "preguntar %1 i esperar",
    "SENSING_ASK_TEXT": "Com et dius?",
    "SENSING_ANSWER": "resposta",
    "SENSING_KEYPRESSED": "tecla %1 pressionada?",
    "SENSING_MOUSEDOWN": "ratolí pressionat?",
    "SENSING_MOUSEX": "posició x del ratolí",
    "SENSING_MOUSEY": "posició y del ratolí",
    "SENSING_SETDRAGMODE": "fixa mode d'arrossegament %1",
    "SENSING_SETDRAGMODE_DRAGGABLE": "arrossegament",
    "SENSING_SETDRAGMODE_NOTDRAGGABLE": "no arrossegament",
    "SENSING_LOUDNESS": "volum del so",
    "SENSING_LOUD": "fort?",
    "SENSING_TIMER": "cronòmetre",
    "SENSING_RESETTIMER": "reiniciar cronòmetre",
    "SENSING_OF": "%1 de %2",
    "SENSING_OF_XPOSITION": "x posició",
    "SENSING_OF_YPOSITION": "y posició",
    "SENSING_OF_DIRECTION": "direcció",
    "SENSING_OF_COSTUMENUMBER": "disfressa #",
    "SENSING_OF_COSTUMENAME": "nom de la disfressa",
    "SENSING_OF_SIZE": "mida",
    "SENSING_OF_VOLUME": "volume",
    "SENSING_OF_BACKDROPNUMBER": "# de fons",
    "SENSING_OF_BACKDROPNAME": "nom del fons",
    "SENSING_OF_STAGE": "Escenari",
    "SENSING_CURRENT": "%1 actual",
    "SENSING_CURRENT_YEAR": "any",
    "SENSING_CURRENT_MONTH": "mes",
    "SENSING_CURRENT_DATE": "dia",
    "SENSING_CURRENT_DAYOFWEEK": "dia de la setmana",
    "SENSING_CURRENT_HOUR": "hora",
    "SENSING_CURRENT_MINUTE": "minut",
    "SENSING_CURRENT_SECOND": "segon",
    "SENSING_DAYSSINCE2000": "dies des del 2000",
    "SENSING_USERNAME": "Nom d'usuari",
    "SENSING_USERID": "Identificació d'usuari",
    "SOUND_PLAY": "tocar so %1",
    "SOUND_PLAYUNTILDONE": "tocar so %1 fins acabar",
    "SOUND_STOPALLSOUNDS": "aturar tots els sons",
    "SOUND_SETEFFECTO": "establir efecte %1 a %2",
    "SOUND_CHANGEEFFECTBY": "canviar efecte %1 en %2",
    "SOUND_CLEAREFFECTS": "esborrar efectes de so",
    "SOUND_EFFECTS_PITCH": "alçada",
    "SOUND_EFFECTS_PAN": "paneig esquerra/dreta",
    "SOUND_CHANGEVOLUMEBY": "canviar volume a  %1",
    "SOUND_SETVOLUMETO": "establir volum a%1%",
    "SOUND_VOLUME": "volum",
    "SOUND_RECORD": "gravar...",
    "CATEGORY_MOTION": "Moviment",
    "CATEGORY_LOOKS": "Aparença",
    "CATEGORY_SOUND": "So",
    "CATEGORY_EVENTS": "Esdeveniment",
    "CATEGORY_CONTROL": "Control",
    "CATEGORY_SENSING": "Sensors",
    "CATEGORY_OPERATORS": "Operadors",
    "CATEGORY_VARIABLES": "Variables",
    "CATEGORY_MYBLOCKS": "Blocs meus",
    "CATEGORY_NUMBERS": "Nombre",
    "CATEGORY_TEXT": "Text",
    "DUPLICATE": "Duplicar",
    "DELETE": "Esborrrar",
    "ADD_COMMENT": "Afegir comentari",
    "REMOVE_COMMENT": "Eliminar comentari",
    "DELETE_BLOCK": "Eliminar blocs",
    "DELETE_X_BLOCKS": "eliminar %1 Blocks",
    "DELETE_ALL_BLOCKS": "eliminar tots %1 blocks?",
    "CLEAN_UP": "Netejar blocs",
    "HELP": "Ajuda",
    "UNDO": "Desfer",
    "REDO": "Refer",
    "EDIT_PROCEDURE": "Editar",
    "SHOW_PROCEDURE_DEFINITION": "Anar a definició",
    "WORKSPACE_COMMENT_DEFAULT_TEXT": "Dir alguna cosa...",
    "COLOUR_HUE_LABEL": "Color",
    "COLOUR_SATURATION_LABEL": "Saturació",
    "COLOUR_BRIGHTNESS_LABEL": "Brillantor",
    "CHANGE_VALUE_TITLE": "canviar valor:",
    "RENAME_VARIABLE": "Rename variable",
    "RENAME_VARIABLE_STRING": "Rename string variable",
    "RENAME_VARIABLE_NUMBER": "Rename numeric variable",
    "RENAME_VARIABLE_LIST": "Rename list variable",
    "RENAME_VARIABLE_TITLE": "Rename all [%1] variables to:",
    "RENAME_VARIABLE_MODAL_TITLE": "Rename Variable",
    "NEW_VARIABLE": "Crear una variable",
    "NEW_VARIABLE_TITLE": "Nou nom de variable",
    "VARIABLE_MODAL_TITLE": "Nova variable",
    "VARIABLE_ALREADY_EXISTS": "ja existeix una variable [%1].",
    "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "A variable named [%1] already exists for another variable of type [%2].",
    "DELETE_VARIABLE_CONFIRMATION": "Delete %1 uses of the [%2] variable?",
    "CANNOT_DELETE_VARIABLE_PROCEDURE": "Can't delete the variable [%1] because it's part of the definition of the function [%2]",
    "DELETE_VARIABLE": "Delete the「%1」variable ",
    "DELETE_VARIABLE_STRING": "Delete the string variable「%1」",
    "DELETE_VARIABLE_NUMBER": "Delete the numeric variable 「%1」",
    "DELETE_VARIABLE_LIST": "Delete the「%1」 list variable",
    "NEW_PROCEDURE": "Crear bloc",
    "PROCEDURE_ALREADY_EXISTS": "Ja existeix un procediment anomenat [%1].",
    "PROCEDURE_DEFAULT_NAME": "nom del bloc",
    "NEW_LIST": "Crear llista",
    "NEW_LIST_TITLE": "Nom de la nova llista:",
    "LIST_MODAL_TITLE": "Nova llista",
    "LIST_ALREADY_EXISTS": "Ja existeix una llista amb el nom [%1].",
    "RENAME_LIST_TITLE": "Reanomena totes les llistes [%1] a:",
    "RENAME_LIST_MODAL_TITLE": "Reanomena llista",
    "DEFAULT_LIST_ITEM": "cosa",
    "DELETE_LIST": "Eliminar la llista [%1]",
    "RENAME_LIST": "Reanomenar llista",
    "NEW_BROADCAST_MESSAGE": "Nou missatge",
    "NEW_BROADCAST_MESSAGE_TITLE": "Nom per al nou missatge:",
    "BROADCAST_MODAL_TITLE": "Nou missatge",
    "DEFAULT_BROADCAST_MESSAGE_NAME": "missatge1",
    "DELETE_PROCEDURE": "Per esborrar el bloc, eliminar tots els usos relacionats amb ell",
    "OK": "OK",
    "CANCEL": "Cancel·lar",
    "PROMPT": "Prompt",
    "STOP": "Aturar",
    "NEW_STRING": "Crear una variable de cadena",
    "NEW_NUMBER_VARIABLE": "Crear una variable numèrica ",
    "WIFI": "Wi-Fi",
    "USERNAME": "user",
    "PWD": "password",
    "IP": "IP Address",
    "IOT_SERVICE": "IOT Service",
    "IOT_ID": "iot id",
    "IOT_PWD": "iot pwd",
    "IOT_TOPIC": "Topic_0",
    "MORE_TOPIC": "Topic",
    "OBLOQ_HTTP": "HTTP",
    "OBLOQ_IP": "IP Address",
    "OBLOQ_PORT": "Port",
    "WIFI_VALIDATE": "Wi-Fi password must be 8-20 letters and numbers",
    "ADD_TOPIC": "Add Topic, can only add up to 4",
    "DELETE_TOPIC": "Delete Topic",
    "IOT_SERVER": "Server",
    "CHINA": "China",
    "GLOBAL": "Global",
    "LOW_C": "Low C/C3",
    "LOW_C$": "Low C#/C#3",
    "LOW_D": "Low D/D3",
    "LOW_D$": "Low D#/D#3",
    "LOW_E": "Low E/E3",
    "LOW_F": "Low F/F3",
    "LOW_F$": "Low F#/F#3",
    "LOW_G": "Low G/G3",
    "LOW_G$": "Low G#/G#3",
    "LOW_A": "Low A/A3",
    "LOW_A$": "Low A#/A#3",
    "LOW_B": "Low B/B3",
    "MIDDLE_C": "Middle C/C4",
    "MIDDLE_C$": "Middle C#/C#4",
    "MIDDLE_D": "Middle D/D4",
    "MIDDLE_D$": "Middle D#/D#4",
    "MIDDLE_E": "Middle E/E4",
    "MIDDLE_F": "Middle F/F4",
    "MIDDLE_F$": "Middle F#/F#4",
    "MIDDLE_G": "Middle G/G4",
    "MIDDLE_G$": "Middle G#/G#4",
    "MIDDLE_A": "Middle A/A4",
    "MIDDLE_A$": "Middle A#/A#4",
    "MIDDLE_B": "Middle B/B4",
    "HIGH_C": "High C/C5",
    "HIGH_C$": "High C#/C#5",
    "HIGH_D": "High D/D5",
    "HIGH_D$": "High D#/D#5",
    "HIGH_E": "High E/E5",
    "HIGH_F": "High F/F5",
    "HIGH_F$": "High F#/F#5",
    "HIGH_G": "High G/G5",
    "HIGH_G$": "High G#/G#5",
    "HIGH_A": "High A/A5",
    "HIGH_A$": "High A#/A#5",
    "HIGH_B": "High B/B5",
    "OPERATORS_STRINGTONUMBER": "convert string %1 to %2",
    "INTEGER": "Integer",
    "DECIMAL": "Decimal",
    "OPERATORS_NUMTOASCII": "convert number %1 to ASCII",
    "OPERATORS_STRINGTOASCII": "convert string %1 to ASCII",
    "OPERATORS_NUMTOSTRING": "convert number %1 to string",
    "OPERATORS_MAP": "mapejar %1 des de [ %2 , %3 ] fins [ %4 , %5 ]",
    "OPERATORS_CONSTRAIN": "limitar %1 entre(min) %2 i (max) %3",
    "STEPS_PER_TURN": "steps per turn",
    "ROTATE_SPEED": "rotate speed(r/s)",
    "NEW_AI": "Make an AI Block",
    "ASK_AND_PAINT": "please draw a number",
    "GET_IDENTIFIED_NUM": "number recognition",
    "READ_NUM_AND_SAY": "speak out the recognized number",
    "IMAGE_ADDR": "Image Addr",
    "IMAGE_PREVIEW": "Image Preview",
    "IMAGE_OPEN": "open",
    "IMAGE_SIZE": "Mida de la imatge",
    "IMAGE_WIDTH": "W",
    "IMAGE_HEIGHT": "H",
    "VARIABLE": "%1",
    "VARIABLE_LIST": "%1",
    "SET_STRING_VARIABLE": "set %1 to %2",
    "STRING_START_WITH": "%1 start with %2",
    "OPERATORS_RANDOMA": "%1 %2 pick random %3 to %4 %5",
    "GLOBAL_MPY_VARIABLE": "global %1",
    "OPERATORS_MODA": "%1 %2 %3 mod %4",
    "OPERATORS_ROUNDA": "%1 %2 round %3",
    "OPERATORS_MATHOPA": "%1 %2 %3 %4",
    "OPERATORS_MATHOP_ABSA": "abs",
    "OPERATORS_MATHOP_FLOORA": "ceiling",
    "OPERATORS_MATHOP_CEILINGA": "floor",
    "OPERATORS_MATHOP_SQRTA": "sqrt",
    "OPERATORS_MATHOP_FIVE": "%1 %2 change %3 by %4",
    "OPERATORS_MATHOP_SIX": "%1 %2 mapejar %3 de [ %4 , %5 ] a [ %6 , %7 ]",
    "OPERATORS_MATHOP_SEVEN": "%1 %2 limita %3 entre(min) %4 and(max) %5",
    "OPERATORS_NUM_INPUT": "%1 %2 number %3",
    "OPERATORS_NUMS_CACULATE": "%1 %2 %3",
    "OPERATORS_MATHOP_SQUARE": "**",
    "OPERATORS_MATHOP_LOGIC_OR": "|",
    "OPERATORS_MATHOP_LOGIC_AND": "&",
    "OPERATORS_MATHOP_LOGIC_POWER": "^",
    "OPERATORS_MATHOP_LOGIC_LEFT": "<<",
    "OPERATORS_MATHOP_LOGIC_RIGHT": ">>",
    "OPERATORS_SPECIAL_NUM": "%1 %2 %3",
    "OPERATORS_MATHOP_PI": "π",
    "OPERATORS_MATHOP_LOGARITHM": "e",
    "OPERATORS_MATHOP_FAI": "φ",
    "OPERATORS_MATHOP_SQRT2": "sqrt(2)",
    "OPERATORS_MATHOP_SQRTHALF": "sqrt(½)",
    "OPERATORS_MATHOP_UNLIMITED": "∞",
    "OPERATORS_NUMS_TYPE": "%1 %2 %3 %4?",
    "OPERATORS_MATHOP_ISEVEN": "is even",
    "OPERATORS_MATHOP_ISODD": "is odd",
    "OPERATORS_MATHOP_ISINT": "is int",
    "OPERATORS_MATHOP_ISPOSITIVE": "is positive",
    "OPERATORS_MATHOP_ISNEGATIVE": "is negative",
    "OPERATORS_SAVE2DECIMAL": "%1 %2 %3 save %4 decimal places",
    "OPERATORS_LIST_CACULATE": "%1 %2 %3 %4",
    "OPERATORS_MATHOP_LIST_SUM": "sum of the numbers in the list",
    "OPERATORS_MATHOP_LIST_MIN": "minimum value in list",
    "OPERATORS_MATHOP_LIST_MAX": "maximum value in the list",
    "OPERATORS_MATHOP_LIST_AVERAGE": "average in list",
    "OPERATORS_MATHOP_LIST_NUM": "number of digits in the list",
    "OPERATORS_MATHOP_LIST_MODE": "list mode",
    "OPERATORS_MATHOP_LIST_STDDEVIATION": "standard deviation in the list",
    "OPERATORS_MATHOP_LIST_RANDOM": "random item of list",
    "OPERATORS_QUOTIENT_INT": "%1 %2 %3 ÷ %4 integer part of quotient",
    "OPERATORS_RANDOM_DECIMAL": "%1 %2 random decimal",
    "OPERATORS_BIN_NEGATION": "%1 %2 binary negation %3",
    "OPERATORS_INT_TO_STR": "%1 %2 decimal integer %3 transform into %4 string",
    "OPERATORS_MATHOP_INTTOSTR_BIN": "binary",
    "OPERATORS_MATHOP_INTTOSTR_OCT": "octal",
    "OPERATORS_MATHOP_INTTOSTR_HEX": "hex",
    "OPERATORS_BINSTR_TO_INT": "%1 %2 %3 string %4 transform into decimal integer",
    "OPERATORS_INT_TO_BYTE": "%1 %2 decimal integer %3 transform into %4 byte",
    "OPERATORS_MATHOP_INTTOBYTE_TWO": "2",
    "OPERATORS_MATHOP_INTTOBYTE_FOUR": "4",
    "OPERATORS_INT_TO_ASCII": "%1 %2 int %3 transform into ASCII character",
    "OPERATORS_ASCII_TO_INT": "%1 %2 ASCII character %3 to int",
    "OPERATORS_STR_TO_INT": "%1 %2 string %3 to hex string",
    "OPERATORS_HEXSTR_TO_BIN": "%1 %2 hex string %3 to bin byte",
    "OPERATORS_STR_TO_BASE64": "%1 %2 %3 encodes in base64 format returns a byte object",
    "OPERATORS_BASE64_TO_BYTE": "%1 %2 decode base64 data %3 returns a byte object",
    "OPERATORS_MATHOP_LENGHT": "length %1",
    "OPERATORS_MATHOP_MAXMIN": "%1 %2",
    "OPERATORS_MATHOP_MAXMIN_MAX": "max",
    "OPERATORS_MATHOP_MAXMIN_MIN": "min",
    "OPERATORS_TEXTS_ONE": "%1 %2 join %3 %4",
    "OPERATORS_TEXTS_TWO": "%1 %2 letter %4 of %3",
    "OPERATORS_TEXTS_THREE": "%1 %2 %3 contains %4?",
    "OPERATORS_TEXTS_FOUR": "%1 %2 letter  %5 %4 to %7 %6 of %3",
    "OPERATORS_TEXTS_FOUR_NEW": "%1 obté %2 %3 caràcters a %4 %5 caràcters",
    "OPERATORS_TEXTS_NEW": "%1 %2 find %3 in %4  %5 Emerging position",
    "OPERATORS_TEXTS_NEW_OP": "Trobar on apareix %1 a %2 %3",
    "OPERATORS_TEXTS_FOUR_ONE": "from left",
    "OPERATORS_TEXTS_FOUR_TWO": "from right",
    "OPERATORS_TEXTS_FOUR_THREE": "from left",
    "OPERATORS_TEXTS_FOUR_FOUR": "from right",
    "OPERATORS_TEXTS_FOUR_F": "primer",
    "OPERATORS_TEXTS_FOUR_L": "últim",
    "OPERATORS_INPUTSTRING": "%1 %2 string %3",
    "OPERATORS_TRANSINTOTEXT": "%1 %2 transform into %3",
    "OPERATORS_TRANSINTOTEXT_ADD": "add",
    "OPERATORS_TRANSINTOTEXT_ADD_ITEM": "item",
    "OPERATORS_FORMATSTRING2F": "%1 %2 format string %3 % %4",
    "OPERATORS_FORMATSTRING3": "%1 %2 format string %3 format %4",
    "OPERATORS_TEXT_ESCAPE": "%1 %2 text escape character %3",
    "BAR_N": "\\n",
    "BAR_NN": "\\n\\r",
    "BAR_R": "\\r",
    "BAR_RN": "\\r\\n",
    "BAR_B": "\\b",
    "BAR_T": "\\t",
    "BAR_BAR": "\\\\",
    "OPERATORS_TEXT_NUMORLETTER": "%1 %2 %3 %4?",
    "OPERATORS_TEXT_NUMORLETTER_NUM": "is number",
    "OPERATORS_TEXT_NUMORLETTER_LETTER": "is letter",
    "OPERATORS_TEXT_LENGTH": "%1 %2 %3 length",
    "OPERATORS_TEXT_ISNONE": "%1 %2 %3 is none?",
    "OPERATORS_FROMTEXT_GETCHAR": "%1 %2 in text %3 %4",
    "OPERATORS_FROMTEXT_GETCHAR_FIRST": "first letter",
    "OPERATORS_FROMTEXT_GETCHAR_LAST": "last letter",
    "OPERATORS_FROMTEXT_GETCHAR_RANDOM": "random letter",
    "OPERATORS_TEXT_UPPLOW": "%1 %2 %3 %4",
    "OPERATORS_TEXT_UPPLOW_UPP": "uppercase",
    "OPERATORS_TEXT_UPPLOW_LOW": "lowercase",
    "OPERATORS_TEXT_SIDES_SPACE": "%1 %2 %3 %4",
    "OPERATORS_TEXT_SIDES_SPACE_BOTH": "clear spaces on both sides",
    "OPERATORS_TEXT_SIDES_SPACE_LEFT": "clear left space",
    "OPERATORS_TEXT_SIDES_SPACE_RIGHT": "clear right space",
    "OPERATORS_TEXT_STRTOBYTE": "%1 %2 turn byte %3",
    "OPERATORS_TEXT_BYTETOSTR": "%1 %2 byte %3 convert to string",
    "OPERATORS_TEXT_DICTTOJSONSTR": "%1 %2 dict %3 transform into json string",
    "OPERATORS_TEXT_PARSEJSONSTR": "%1 %2 parse json string %3 and return object",
    "OPERATORS_STRINGTONUMBERA": "%1 %2 convert string %3 to %4",
    "OPERATORS_NUMTOASCIIA": "%1 %2 convert number %3 into ASCII characters",
    "OPERATORS_STRINGTOASCIIA": "%1 %2 convert character %3 into an ASCII value",
    "OPERATORS_NUMTOSTRINGA": "%1 %2 convert number %3 into a string",
    "NEW_MC": "make variable",
    "RENAME_MCNUMBER_TITLE": "rename all variables whose name is [%1] to:",
    "RENAME_MCNUMBER_MODAL_TITLE": "anomenar variable",
    "RENAME_VARIABLE_MCNUMBER": "\nanomenar variable",
    "DELETE_VARIABLE_MCNUMBER": "esborrar variable [%1]",
    "SET_MC_VARIABLE": "establir  %1 com %2",
    "ALIYUN": "Aliyun",
    "ONENET": "OneNet",
    "ONENETNEW": "OneNet(New)",
    "EASYIOT": "Easy IoT",
    "SHANGHAI": "Shanghai",
    "QINGDAO": "Qingdao",
    "BEIJING": "Beijing",
    "ZHANGJIAKOU": "Zhangjiakou",
    "HUHEHAOTE": "Huhehaote",
    "HANGZHOU": "Hangzhou",
    "SHENZHEN": "Shenzhen",
    "HONGKONG": "HongKong",
    "SINGAPORE": "Singapore",
    "SYDNEY": "Sydney",
    "KUALALUMPUR": "Luala Lumpur",
    "JAKARTA": "Jakarta",
    "MUMBAI": "Mumbai",
    "TOKYO": "Tokyo",
    "SILICONVALLEY": "Silicon Valley",
    "VIRGINIA": "Virginia",
    "FRANKFURT": "Frankfurt",
    "LONDON": "London",
    "DUBAI": "Dubai",
    "IOT_PLATFORM": "Iot Platform",
    "PARAMS": "Params",
    "SERVER_ATTR": "Server Addr",
    "PRODUCTID": "ProductId",
    "DEVICEID": "DeviceId",
    "DEVICENAME": "DeviceName",
    "TINYDB_SECRET": "secret (secret)",
    "TINYDB_USER": "user (user)",
    "APIADDR": "API Addr",
    "SOFTWARE_SERIAL": "Software Serial",
    "HARDWARE_SERIAL": "Hardware Serial",
    "HARDWARE_SERIAL1": "Hardware Serial1",
    "HARDWARE_SERIAL2": "Hardware Serial2",
    "HARDWARE_SERIAL3": "Hardware Serial3",
    "CHECKTYPE_TIPS": "The data type received by the input box does not match the output type of the building block",
    "HIGHLIGHT_BLOCK": "hight light block",
    "HIGHLIGHT_ALL_CONFLICT_BLOCKS": "highlight all blocks with warnings",
    "SNAPSHOT": "Snapshot (CTRL+G)",
    "pictureaiPath": "please enter the picture path or URL",
    "pictureaiType": "account type",
    "pictureaiAccountDefault": "default account",
    "pictureaiAccountBaidu": "baidu account",
    "pictureaiwebImgAddr": "web image address",
    "MITRIXICON_EDIT": "light up",
    "MITRIXICON_ERASER": "eraser",
    "MITRIXICON_DUSTBIN": "clear",
    "MITRIXICON_REVERSE": "reverse",
    "MITRIXICON_COLOR": "select color",
    "RED": "red",
    "GREEN": "green",
    "BLUE": "blue",
    "SEARCH_BLOCKS": "search blocks",
    "NO_SEARCH_BLOCKS": "with no result",
    "SEARCH_DEFAULT_LABEL_THINK": "think",
    "SEARCH_DEFAULT_LABEL_IF": "if",
    "SEARCH_DEFAULT_LABEL_WAIT": "wait",
    "SEARCH_DEFAULT_LABEL_CONVERT": "convert",
    "SEARCH_DEFAULT_LABEL_BROADCAST": "broadcast",
    "MITRIXICON_SAVE": "guardar",
    "MITRIXICON_EXPORT": "exportar",
    "MITRIXICON_UPLOAD": "pujar",
    "ROLL_MOUSE": "Rolling mouse",
    "MOUSE_ZOOM_IN_OUT": "Scroll the mouse to zoom in and out",
    "UPLOAD_CORRECT_JSON_FILE": "Please upload the json file in the correct format",
    "EXPORT_PROMPT": "There are no custom patterns to export. Please click the save button in the lower right corner to save the custom pattern before attempting to export.",
    "HEART": "Heart",
    "HEART_SMALL": "Small heart",
    "HAPPY": "Happy",
    "SMILE": "Smile",
    "SAD": "Sad",
    "CONFUSED": "Confused",
    "ANGRY": "Angry",
    "SLEEP": "Sleep",
    "SURPRISED": "Surprised",
    "SILLY": "Silly",
    "WONDERFUL": "Wonderful",
    "BORED": "Bored",
    "ROCK": "Rock",
    "ROCK_SMALL": "Small rock",
    "PAPER": "Paper",
    "PAPER_SMALL": "Small paper",
    "SCISSORS": "scissors",
    "SCISSORS_SMALL": "Small scissors",
    "DATA_SOLITLIST": "%1 %2 make %3 %4 with delimiter %5",
    "DATA_SOLITLIST_TYPE_LT": "list from text5",
    "DATA_SOLITLIST_TYPE_TL": "text from list",
    "DATA_LENLIST": "%1 %2 %3 is empty?",
    "DATA_LENGTHLIST": "%1 %2 length of %3",
    "DATA_CREATELIST": "%1 %2 create list %3",
    "DATA_CLEARLIST": "%1 %2 clear list %3",
    "ML5MODALBUTTON": "Open AI Visual Interface",
    "COLLAPSE_BLOCK": "collapse",
    "EXPAND_BLOCK": "expand",
    "COLLAPSE_ALL": "collapse all blocks",
    "EXPAND_ALL": "expand all blocks",
    "pyTimer": "Timer",
    "pyList": "List",
    "pyDictionary": "Dictionary",
    "pySet": "Set",
    "mpyTuple": "Tuple",
    "pythonBase_printAndInput": "Input and Output",
    "pythonBase_mainSetUp": "%1 %2 Python main program start",
    "pythonBase_print": "%1 %2 print %3",
    "pythonBase_input": "%1 %2 gets the input, the prompt is %3",
    "pythonBase_file": "file",
    "pythonBase_file_open": "%1 %2 open file %5 in format %3 mode %4 and returned %6",
    "pythonBase_file_open_read": "read",
    "pythonBase_file_open_write": "write",
    "pythonBase_file_close": "%1 %2 %3 close",
    "pythonBase_file_read": "%1 %2 %3 read %4",
    "pythonBase_file_read_all": "entire file ",
    "pythonBase_file_read_line": "a line",
    "pythonBase_file_read_lines": "all lines",
    "pythonBase_file_write": "%1 %2 %3 write %4",
    "pyTimer_setEvent": "%1 %2 set timer %3 %4 cycle %5 milliseconds",
    "pyTimer_repeated": "Repeated execution",
    "pyTimer_delayed": "Delayed execution",
    "pyTimer_conditionEvent": "%1 %2 set timer %3: when %4",
    "pyTimer_getCount": "%1 %2 timer %3 count value",
    "pyTimer_clearEvent": "%1 %2 clear timer/event %3",
    "pyDictionary_init": "%1 %2 initialize dictionary %3",
    "pyDictionary_getValue": "%1 %2 dictionary %3 key %4 value",
    "pyDictionary_setValue": "The value of %1 %2 dictionary %3 key %4 is set to %5",
    "pyDictionary_deleteKey": "%1 %2 dictionary %3 delete key %4",
    "pyDictionary_clear": "%1 %2 clear dictionary %3",
    "pyDictionary_isInclude": "%1 %2 dictionary %3 contains the key %4?",
    "pyDictionary_getLength": "%1 %2 dictionary %3 length",
    "pyDictionary_list": "%1 %2 dictionary %3 %4 list",
    "pyDictionary_TypeIndex_A": "Key",
    "pyDictionary_TypeIndex_B": "Value",
    "pyList_join": "%1 %2 list %3 uses separator %4 to combine text",
    "pyList_split": "%1 %2 text %3 uses separator %4 to make a list",
    "pyList_init": "%1 %2 initialization list %3",
    "pyList_clear": "%1 %2 clear list %3",
    "pyList_getLength": "%1 %2 length of list %3",
    "pyList_isEmpty": "%1 %2 list %3 is empty?",
    "pyList_getValue": "%1 %2 list %3 index %4 value",
    "pyList_getValueRange": "%1 %2 returns list %3 and takes %4 %5 items to %6 %7 items",
    "pyList_insert": "%1 %2 list %3 insert %5 at index %4",
    "pyList_setValue": "%1 %2 list %3 set index %4 to value %5",
    "pyList_delete": "%1 %2 list %3 delete index %4 value",
    "pyList_append": "%1 %2 list %3 adds %4 to the end",
    "pyList_extend": "%1 %2 list %3 append list %4",
    "pyList_findIndex": "%1 %2 list %3 find index of %4",
    "pyList_sort": "%1 %2 list %3 sorted by %4 as %5",
    "pyList_SortModeIndex_A": "Ascending order",
    "pyList_SortModeIndex_B": "Descending Order",
    "pyList_SortTypeIndex_A": "Number",
    "pyList_SortTypeIndex_B": "Letter",
    "pyList_SortTypeIndex_C": "Letters, ignore case",
    "pySet_init": "%1 %2 initialize set %3 ",
    "pySet_update": "%1 %2 set %3 updated to be %5 with set %4",
    "pySet_TypeIndex_A": "Intersection",
    "pySet_TypeIndex_B": "Union Set",
    "pySet_TypeIndex_C": "Difference Set",
    "pySet_addValue": "%1 %2 set %3 add %4",
    "pySet_isSubsetSuperset": "%1 %2 set %3 is %5 of set %4?",
    "pySet_SubsetSupersetType_A": "Subset",
    "pySet_SubsetSupersetType_B": "Superset",
    "pySet_intersectionUnionSetDifference": "%1 %2 takes %3 set %4 set %5",
    "pySet_length": "%1 %2 set %3 length",
    "pySet_pop": "%1 %2 returned random items and removed from the set %3",
    "pyTuple_init": "%1 %2 initialized tuple %3",
    "pyTuple_minMaxLength": "%1 %2 tuple %3 %4",
    "pyTuple_TypeIndex_A": "Minimum Value",
    "pyTuple_TypeIndex_B": "Maximum Value",
    "pyTuple_TypeIndex_C": "Length",
    "pyTuple_isInclude": "%1 %2 tuple %3 contains %4?",
    "pyTuple_getValueRange": "%1 %2 returns the tuple %3 taking %4 %5 items to %6 %7 items",
    "pyTuple_IndexType_A": "#",
    "pyTuple_IndexType_B": "countdown #",
    "pyTuple_listToTulpe": "%1 %2 list %3 to tuple",
    "pyTuple_getValue": "%1 %2 tuple %3 index %4 value",
    "pyTuple_tupleToList": "%1 %2 tuple %3 turned into a list"
  },
  "cr": {
    "CONTROL_FOREVER": "ponavljaj",
    "CONTROL_LOOP_BREAKCONTINUE": "%1",
    "CONTROL_LOOP_BREAKCONTINUE_BREAK": "pauza",
    "CONTROL_LOOP_BREAKCONTINUE_CONTINUE": "nastaviti",
    "CONTROL_REPEAT": "ponovi %1",
    "CONTROL_IF": "ako %1 onda",
    "CONTROL_ELSE": "inače",
    "CONTROL_STOP": "zaustavi",
    "CONTROL_STOP_ALL": "sve",
    "CONTROL_STOP_THIS": "ovu skriptu",
    "CONTROL_STOP_OTHER": "druge skripte u liku",
    "CONTROL_WAIT": "čekaj %1 sekundi",
    "CONTROL_WAIT_ESP32_MPY": "čekaj %1 %2",
    "CONTROL_FOR_FROM_SEQUENCE": "koristi %1 iz niza %2",
    "CONTROL_FOR_RANGE_SPACE": "Koristi %1 iz raspona %2 do %3 svaki %4",
    "CONTROL_WAIT_ESP32_MPY_S": "sekunda(e)",
    "CONTROL_WAIT_ESP32_MPY_MS": "milisekunda (ms)",
    "CONTROL_WAIT_ESP32_MPY_US": "mikrosekunda(us)",
    "CONTROL_WAITUNTIL": "čekaj dok nije %1",
    "CONTROL_REPEATUNTIL": "ponavljaj dok nije %1",
    "CONTROL_WHILE": "sve dok %1",
    "CONTROL_FOREACH": "za svaki %1 u %2",
    "CONTROL_STARTASCLONE": "kada se pokrenem kao klon",
    "CONTROL_CREATECLONEOF": "kloniraj %1",
    "CONTROL_CREATECLONEOF_MYSELF": "sebe",
    "CONTROL_DELETETHISCLONE": "izbriši ovaj klon",
    "CONTROL_COUNTER": "brojač",
    "CONTROL_INCRCOUNTER": "brojač inkrementa",
    "CONTROL_CLEARCOUNTER": "resetirati brojač",
    "CONTROL_ALLATONCE": "sve odjednom",
    "DATA_SETVARIABLETO": "postavi %1 na %2",
    "DATA_CHANGEVARIABLEBY": "promijeni %1 za %2",
    "DATA_SHOWVARIABLE": "prikaži varijablu %1",
    "DATA_HIDEVARIABLE": "sakrij varijablu %1",
    "DATA_ADDTOLIST": "dodaj %1 u %2",
    "DATA_DELETEOFLIST": "izbriši %1 iz %2",
    "DATA_DELETEALLOFLIST": "izbriši sve elemente u %1",
    "DATA_INSERTATLIST": "umetni %1 na poziciju %2 u %3",
    "DATA_REPLACEITEMOFLIST": "zamijeni %1 element u %2 s %3",
    "DATA_ITEMOFLIST": "%1 element u %2",
    "DATA_ITEMNUMOFLIST": "indeks od %1 u %2",
    "DATA_LENGTHOFLIST": "dužina %1",
    "DATA_LISTCONTAINSITEM": "lista %1 sadrži %2?",
    "DATA_SHOWLIST": "prikaži listu %1",
    "DATA_HIDELIST": "sakrij listu %1",
    "DATA_INDEX_ALL": "svi",
    "DATA_INDEX_LAST": "posljednji",
    "DATA_INDEX_RANDOM": "slučajan",
    "EVENT_WHENFLAGCLICKED": "kada je kliknuta %1",
    "EVENT_WHENTHISSPRITECLICKED": "kada je ovaj lik kliknut",
    "EVENT_WHENSTAGECLICKED": "kada je pozornica kliknuta",
    "EVENT_WHENTOUCHINGOBJECT": "kada ovaj lik dotakne %1",
    "EVENT_WHENBROADCASTRECEIVED": "kada primim %1",
    "EVENT_WHENBACKDROPSWITCHESTO": "kada se pozadina promijeni u %1",
    "EVENT_WHENGREATERTHAN": "kada je %1 > %2",
    "EVENT_WHENGREATERTHAN_TIMER": "mjerač vremena",
    "EVENT_WHENGREATERTHAN_LOUDNESS": "glasnoća",
    "EVENT_BROADCAST": "pošalji %1",
    "EVENT_BROADCASTANDWAIT": "pošalji %1 i čekaj",
    "EVENT_WHENKEYPRESSED": "kada se pritisne tipkalo %1",
    "EVENT_WHENKEYPRESSED_SPACE": "space",
    "EVENT_WHENKEYPRESSED_LEFT": "strelica lijevo",
    "EVENT_WHENKEYPRESSED_RIGHT": "strelica desno",
    "EVENT_WHENKEYPRESSED_DOWN": "strelica dolje",
    "EVENT_WHENKEYPRESSED_UP": "strelica gore",
    "EVENT_WHENKEYPRESSED_ANY": "bilo koja",
    "LOOKS_SAYFORSECS": "reci %1 %2 sekunde",
    "LOOKS_SAY": "reci %1",
    "LOOKS_HELLO": "Zdravo!",
    "LOOKS_THINKFORSECS": "razmišljaj %1 %2 sekunde",
    "LOOKS_THINK": "razmišljaj %1",
    "LOOKS_HMM": "Hmm...",
    "LOOKS_SHOW": "pokaži",
    "LOOKS_HIDE": "sakrij",
    "LOOKS_HIDEALLSPRITES": "sakrij sve likove",
    "LOOKS_EFFECT_COLOR": "boja",
    "LOOKS_EFFECT_FISHEYE": "riblje oko",
    "LOOKS_EFFECT_WHIRL": "vrtlog",
    "LOOKS_EFFECT_PIXELATE": "pikselirati",
    "LOOKS_EFFECT_MOSAIC": "mozaik",
    "LOOKS_EFFECT_BRIGHTNESS": "svjetlina",
    "LOOKS_EFFECT_GHOST": "duh",
    "LOOKS_CHANGEEFFECTBY": "promijeni efekt %1 za %2",
    "LOOKS_SETEFFECTTO": "postavi efekt %1 na %2",
    "LOOKS_CLEARGRAPHICEFFECTS": "ukloni grafičke efekte",
    "LOOKS_CHANGESIZEBY": "promijeni veličinu za %1",
    "LOOKS_SETSIZETO": "postavi veličinu na %1 %",
    "LOOKS_SIZE": "veličina",
    "LOOKS_CHANGESTRETCHBY": "promijeni rastezanje za %1",
    "LOOKS_SETSTRETCHTO": "postavi rastezanje na %1 %",
    "LOOKS_SWITCHCOSTUMETO": "promijeni kostim na %1",
    "LOOKS_NEXTCOSTUME": "sljedeći kostim",
    "LOOKS_SWITCHBACKDROPTO": "promijeni pozadinu u %1",
    "LOOKS_GOTOFRONTBACK": "idi na sloj %1",
    "LOOKS_GOTOFRONTBACK_FRONT": "prednji",
    "LOOKS_GOTOFRONTBACK_BACK": "stražnji",
    "LOOKS_GOFORWARDBACKWARDLAYERS": "idi %1 %2 sloj",
    "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "naprijed",
    "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "natrag",
    "LOOKS_BACKDROPNUMBERNAME": "pozadina %1",
    "LOOKS_COSTUMENUMBERNAME": "kostim %1",
    "LOOKS_NUMBERNAME_NUMBER": "broj",
    "LOOKS_NUMBERNAME_NAME": "ime",
    "LOOKS_SWITCHBACKDROPTOANDWAIT": "promijeni pozadinu na %1 i pričekaj",
    "LOOKS_NEXTBACKDROP_BLOCK": "sljedeća pozadina",
    "LOOKS_NEXTBACKDROP": "sljedeću pozadinu",
    "LOOKS_PREVIOUSBACKDROP": "prethodnu pozadinu",
    "LOOKS_RANDOMBACKDROP": "nasumičnu pozadinu",
    "MOTION_MOVESTEPS": "idi %1 koraka",
    "MOTION_TURNLEFT": "skreni %1 %2 stupnjeva",
    "MOTION_TURNRIGHT": "skreni %1 %2 stupnjeva",
    "MOTION_POINTINDIRECTION": "okreni se u smjeru %1",
    "MOTION_POINTTOWARDS": "okreni se prema %1",
    "MOTION_POINTTOWARDS_POINTER": "pokazivaču miša",
    "MOTION_POINTTOWARDS_RANDOM": "nasumičnom smjeru",
    "MOTION_GOTO": "idi do %1",
    "MOTION_GOTO_POINTER": "pokazivača miša",
    "MOTION_GOTO_RANDOM": "nasumične pozicije",
    "MOTION_GOTOXY": "idi na x: %1 y: %2",
    "MOTION_GLIDESECSTOXY": "klizi %1 sekundi do x: %2 y: %3",
    "MOTION_GLIDETO": "klizi %1 sekundi do %2",
    "MOTION_GLIDETO_POINTER": "pokazivača miša",
    "MOTION_GLIDETO_RANDOM": "nasumične pozicije",
    "MOTION_CHANGEXBY": "promijeni x za %1",
    "MOTION_SETX": "postavi x na %1",
    "MOTION_CHANGEYBY": "promijeni y za %1",
    "MOTION_SETY": "postavi y na %1",
    "MOTION_IFONEDGEBOUNCE": "ako si na rubu, okreni se",
    "MOTION_SETROTATIONSTYLE": "postavi stil rotacije %1",
    "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "lijevo-desno",
    "MOTION_SETROTATIONSTYLE_DONTROTATE": "ne rotiraj",
    "MOTION_SETROTATIONSTYLE_ALLAROUND": "sve unaokolo",
    "MOTION_XPOSITION": "x položaj",
    "MOTION_YPOSITION": "y položaj",
    "MOTION_DIRECTION": "smjer",
    "MOTION_SCROLLRIGHT": "pomakni se desno %1",
    "MOTION_SCROLLUP": "pomakni se gore %1",
    "MOTION_ALIGNSCENE": "poravnaj sa scenom %1",
    "MOTION_ALIGNSCENE_BOTTOMLEFT": "dolje lijevo",
    "MOTION_ALIGNSCENE_BOTTOMRIGHT": "dolje desno",
    "MOTION_ALIGNSCENE_MIDDLE": "sredini",
    "MOTION_ALIGNSCENE_TOPLEFT": "gore lijevo",
    "MOTION_ALIGNSCENE_TOPRIGHT": "gore desno",
    "MOTION_XSCROLL": "x svitak",
    "MOTION_YSCROLL": "y svitak",
    "MOTION_STAGE_SELECTED": "Odabrana faza: nema blokada pokreta",
    "OPERATORS_ADD": "%1 + %2",
    "OPERATORS_SUBTRACT": "%1 - %2",
    "OPERATORS_MULTIPLY": "%1 * %2",
    "OPERATORS_DIVIDE": "%1 / %2",
    "OPERATORS_RANDOM": "slučajan broj od %1 do %2",
    "OPERATORS_GT": "%1 > %2",
    "OPERATORS_GT_EQUALS": "%1 >= %2",
    "OPERATORS_LT": "%1 < %2",
    "OPERATORS_LT_EQUALS": "%1 <= %2",
    "OPERATORS_EQUALS": "%1 = %2",
    "OPERATORS_AND": "%1 i %2",
    "OPERATORS_OR": "%1 ili %2",
    "OPERATORS_NOT": "ne %1",
    "CONTROL_TRUE_FALSE": "%1",
    "CONTROL_TRUE_FALSE_TRUE": "istina",
    "CONTROL_TRUE_FALSE_FALSE": "laž",
    "CONTROL_NONE": "Ništa",
    "CONTROL_RETURN": "povratak %1",
    "OPERATORS_VALUE_IS_TRUE": "%1 je istina?",
    "OPERATORS_VALUE_TYPE": "%1 vrsta",
    "CONTROL_TRY": "probati",
    "CONTROL_EXCEPT": "osim",
    "CONTROL_FINALLY": "konačno",
    "OPERATORS_VALUE_TYPE_IS": "%1 tip je %2?",
    "OPERATORS_VALUE_TYPE_INT": "int",
    "OPERATORS_VALUE_TYPE_FLOAT": "float",
    "OPERATORS_VALUE_TYPE_BOOL": "bool",
    "OPERATORS_VALUE_TYPE_STR": "str",
    "OPERATORS_VALUE_TYPE_LIST": "list",
    "OPERATORS_VALUE_TYPE_TUPLE": "tuple",
    "OPERATORS_VALUE_TYPE_SET": "set",
    "OPERATORS_VALUE_TYPE_DICT": "dict",
    "OPERATORS_VALUE_TYPE_BYTES": "bajtova",
    "OPERATORS_VALUE_TYPE_BYTEARRAY": "niz bajtova",
    "OPERATORS_VALUE_TYPE_COMPLEX": "kompleks",
    "OPERATORS_CACULATE_RESULT": "eval %1",
    "OPERATORS_JOIN": "spoji %1 i %2",
    "OPERATORS_JOIN_APPLE": "jabuka",
    "OPERATORS_JOIN_BANANA": "banana",
    "OPERATORS_LETTEROF": "slovo %1 od %2",
    "OPERATORS_LETTEROF_APPLE": "a",
    "OPERATORS_LENGTH": "dužina %1",
    "OPERATORS_CONTAINS": "%1 sadrži %2?",
    "OPERATORS_MOD": "%1 mod %2",
    "OPERATORS_ROUND": "zaokruži %1",
    "OPERATORS_MATHOP": "%1 od %2",
    "OPERATORS_MATHOP_ABS": "apsolutno",
    "OPERATORS_MATHOP_FLOOR": "donja vrijednost",
    "OPERATORS_MATHOP_CEILING": "gornja vrijednost",
    "OPERATORS_MATHOP_SQRT": "drugi korijen",
    "OPERATORS_MATHOP_SIN": "sin",
    "OPERATORS_MATHOP_COS": "cos",
    "OPERATORS_MATHOP_TAN": "tan",
    "OPERATORS_MATHOP_ASIN": "asin",
    "OPERATORS_MATHOP_ACOS": "acos",
    "OPERATORS_MATHOP_ATAN": "atan",
    "OPERATORS_MATHOP_LN": "ln",
    "OPERATORS_MATHOP_LOG": "log",
    "OPERATORS_MATHOP_EEXP": "e ^",
    "OPERATORS_MATHOP_10EXP": "10 ^",
    "PROCEDURES_DEFINITION": "definiraj %1",
    "SENSING_TOUCHINGOBJECT": "dodiruje %1?",
    "SENSING_TOUCHINGOBJECT_POINTER": "pokazivač miša",
    "SENSING_TOUCHINGOBJECT_EDGE": "rub",
    "SENSING_TOUCHINGCOLOR": "dodiruje boju %1?",
    "SENSING_COLORISTOUCHINGCOLOR": "boja %1 dodiruje %2?",
    "SENSING_DISTANCETO": "udaljenost do %1",
    "SENSING_DISTANCETO_POINTER": "pokazivača miša",
    "SENSING_ASKANDWAIT": "pitaj %1 i pričekaj",
    "SENSING_ASK_TEXT": "Kako se zoveš?",
    "SENSING_ANSWER": "odgovor",
    "SENSING_KEYPRESSED": "tipkalo %1 pritisnuto?",
    "SENSING_MOUSEDOWN": "miš pritisnut?",
    "SENSING_MOUSEX": "miš x",
    "SENSING_MOUSEY": "miš y",
    "SENSING_SETDRAGMODE": "postavi način povlačenja %1",
    "SENSING_SETDRAGMODE_DRAGGABLE": "može se povlačiti",
    "SENSING_SETDRAGMODE_NOTDRAGGABLE": "ne može se povlačiti",
    "SENSING_LOUDNESS": "glasnoća",
    "SENSING_LOUD": "glasno?",
    "SENSING_TIMER": "štoperica",
    "SENSING_RESETTIMER": "poništi štopericu",
    "SENSING_OF": "%1 od %2",
    "SENSING_OF_XPOSITION": "x položaj",
    "SENSING_OF_YPOSITION": "y položaj",
    "SENSING_OF_DIRECTION": "smjer",
    "SENSING_OF_COSTUMENUMBER": "kostim #",
    "SENSING_OF_COSTUMENAME": "naziv kostima",
    "SENSING_OF_SIZE": "veličina",
    "SENSING_OF_VOLUME": "volumen",
    "SENSING_OF_BACKDROPNUMBER": "pozadina #",
    "SENSING_OF_BACKDROPNAME": "naziv pozadine",
    "SENSING_OF_STAGE": "Pozornica",
    "SENSING_CURRENT": "trenutna %1",
    "SENSING_CURRENT_YEAR": "godina",
    "SENSING_CURRENT_MONTH": "mjesec",
    "SENSING_CURRENT_DATE": "datum",
    "SENSING_CURRENT_DAYOFWEEK": "dan u tjednu",
    "SENSING_CURRENT_HOUR": "sat",
    "SENSING_CURRENT_MINUTE": "minuta",
    "SENSING_CURRENT_SECOND": "sekunda",
    "SENSING_DAYSSINCE2000": "dana od 2000",
    "SENSING_USERNAME": "Korisničko ime",
    "SENSING_USERID": "korisnički ID",
    "SOUND_PLAY": "sviraj zvuk %1",
    "SOUND_PLAYUNTILDONE": "sviraj zvuk %1 do kraja",
    "SOUND_STOPALLSOUNDS": "zaustavi sve zvukove",
    "SOUND_SETEFFECTO": "postavi efekt %1 na %2",
    "SOUND_CHANGEEFFECTBY": "promijeni efekt %1 na %2",
    "SOUND_CLEAREFFECTS": "ukloni sve zvučne efekte",
    "SOUND_EFFECTS_PITCH": "visina tona",
    "SOUND_EFFECTS_PAN": "kretanje lijevo/desno",
    "SOUND_CHANGEVOLUMEBY": "promijeni glasnoću za %1",
    "SOUND_SETVOLUMETO": "postavi glasnoću na %1%",
    "SOUND_VOLUME": "glasnoća",
    "SOUND_RECORD": "snimiti...",
    "CATEGORY_MOTION": "Kretanje",
    "CATEGORY_LOOKS": "Izgled",
    "CATEGORY_SOUND": "Zvuk",
    "CATEGORY_EVENTS": "Događaji",
    "CATEGORY_CONTROL": "Upravljanje",
    "CATEGORY_SENSING": "Očitanja",
    "CATEGORY_OPERATORS": "Operacije",
    "CATEGORY_VARIABLES": "Varijable",
    "CATEGORY_MYBLOCKS": "Moji blokovi",
    "CATEGORY_NUMBERS": "Broj",
    "CATEGORY_TEXT": "Tekst",
    "DUPLICATE": "Dupliciraj",
    "DELETE": "Izbriši",
    "ADD_COMMENT": "Dodaj komentar",
    "REMOVE_COMMENT": "Ukloni komentar",
    "DELETE_BLOCK": "Izbriši blok",
    "DELETE_X_BLOCKS": "Izbriši %1 blokova",
    "DELETE_ALL_BLOCKS": "Izbrisati sve %1 blokove?",
    "CLEAN_UP": "Očisti blokove",
    "HELP": "Pomozite",
    "UNDO": "Poništi",
    "REDO": "Ponovi",
    "EDIT_PROCEDURE": "Uredi",
    "SHOW_PROCEDURE_DEFINITION": "Idi na definiciju",
    "WORKSPACE_COMMENT_DEFAULT_TEXT": "Reci nešto...",
    "COLOUR_HUE_LABEL": "Boja",
    "COLOUR_SATURATION_LABEL": "Zasićenost",
    "COLOUR_BRIGHTNESS_LABEL": "Svjetlina",
    "CHANGE_VALUE_TITLE": "Promjena vrijednosti:",
    "RENAME_VARIABLE": "Preimenuj varijablu",
    "RENAME_VARIABLE_STRING": "Preimenuj znakovni niz",
    "RENAME_VARIABLE_NUMBER": "Preimenuj brojčanu varijablu",
    "RENAME_VARIABLE_LIST": "Preimenuj listu",
    "RENAME_VARIABLE_TITLE": "Preimenuj varijablu [%1] u:",
    "RENAME_VARIABLE_MODAL_TITLE": "Preimenuj varijablu",
    "NEW_VARIABLE": "Napravi varijablu",
    "NEW_VARIABLE_TITLE": "Ime nove varijable:",
    "VARIABLE_MODAL_TITLE": "Nova varijabla",
    "VARIABLE_ALREADY_EXISTS": "Varijabla pod nazivom [%1] već postoji.",
    "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "Varijabla pod nazivom [%1] već postoji za drugu varijablu tipa [%2].",
    "DELETE_VARIABLE_CONFIRMATION": "Brišem varijablu [%2] koja se upotrebljava %1 puta?",
    "CANNOT_DELETE_VARIABLE_PROCEDURE": "Nije moguće izbrisati varijablu [%1] jer je dio definicije funkcije [%2]",
    "DELETE_VARIABLE": "Izbriši varijablu「%1」",
    "DELETE_VARIABLE_STRING": "Izbriši znakovni niz「%1」",
    "DELETE_VARIABLE_NUMBER": "Izbriši brojčanu varijablu 「%1」",
    "DELETE_VARIABLE_LIST": "Izbriši listu「%1」",
    "NEW_PROCEDURE": "Napravi blok",
    "PROCEDURE_ALREADY_EXISTS": "Procedura pod nazivom [%1] već postoji.",
    "PROCEDURE_DEFAULT_NAME": "naziv bloka",
    "NEW_LIST": "Napravi listu",
    "NEW_LIST_TITLE": "Naziv nove liste:",
    "LIST_MODAL_TITLE": "Nova lista",
    "LIST_ALREADY_EXISTS": "Lista pod nazivom [%1] već postoji.",
    "RENAME_LIST_TITLE": "Preimenuj listu [%1] u:",
    "RENAME_LIST_MODAL_TITLE": "Preimenuj listu",
    "DEFAULT_LIST_ITEM": "stvar",
    "DELETE_LIST": "Izbriši listu [%1].",
    "RENAME_LIST": "Preimenuj listu",
    "NEW_BROADCAST_MESSAGE": "Nova poruka",
    "NEW_BROADCAST_MESSAGE_TITLE": "Ime nove poruke:",
    "BROADCAST_MODAL_TITLE": "Nova poruka",
    "DEFAULT_BROADCAST_MESSAGE_NAME": "poruka1",
    "DELETE_PROCEDURE": "Za brisanje definicije bloka prvo ukloni sve upotrebe bloka",
    "OK": "OK",
    "CANCEL": "Poništi",
    "PROMPT": "Upitaj",
    "STOP": "Stop",
    "NEW_STRING": "Napravi znakovni niz",
    "NEW_NUMBER_VARIABLE": "Napravi brojčanu varijablu",
    "WIFI": "Wi-Fi",
    "USERNAME": "korisnik",
    "PWD": "zaporka",
    "IP": "IP adresa",
    "IOT_SERVICE": "IOT usluga",
    "IOT_ID": "jot id",
    "IOT_PWD": "iot pwd",
    "IOT_TOPIC": "Tema_0",
    "MORE_TOPIC": "Tema",
    "OBLOQ_HTTP": "HTTP",
    "OBLOQ_IP": "IP adresa",
    "OBLOQ_PORT": "Port",
    "WIFI_VALIDATE": "Wi-Fi lozinka mora sadržavati 8-20 slova i brojeva",
    "ADD_TOPIC": "Dodaj temu, možeš dodati najviše 4",
    "DELETE_TOPIC": "Izbriši temu",
    "IOT_SERVER": "poslužitelj",
    "CHINA": "Kina",
    "GLOBAL": "Globalno",
    "LOW_C": "Niski C/C3",
    "LOW_C$": "Niski C#/C#3",
    "LOW_D": "Niski D/D3",
    "LOW_D$": "Niski D#/D#3",
    "LOW_E": "Niski E/E3",
    "LOW_F": "Niski F/F3",
    "LOW_F$": "Niski F#/F#3",
    "LOW_G": "Niski G/G3",
    "LOW_G$": "Niski G#/G#3",
    "LOW_A": "Niski A/A3",
    "LOW_A$": "Niski A#/A#3",
    "LOW_B": "Niski B/B3",
    "MIDDLE_C": "Srednji C/C4",
    "MIDDLE_C$": "Srednji C#/C#4",
    "MIDDLE_D": "Srednji D/D4",
    "MIDDLE_D$": "Srednji D#/D#4",
    "MIDDLE_E": "Srednji E/E4",
    "MIDDLE_F": "Srednji F/F4",
    "MIDDLE_F$": "Srednji F#/F#4",
    "MIDDLE_G": "Srednji G/G4",
    "MIDDLE_G$": "Srednji G#/G#4",
    "MIDDLE_A": "Srednji A/A4",
    "MIDDLE_A$": "Srednji A#/A#4",
    "MIDDLE_B": "Srednji B/B4",
    "HIGH_C": "Visoki C/C5",
    "HIGH_C$": "Visoki C#/C#5",
    "HIGH_D": "Visoki D/D5",
    "HIGH_D$": "Visoki D#/D#5",
    "HIGH_E": "Visoki E/E5",
    "HIGH_F": "Visoki F/F5",
    "HIGH_F$": "Visoki F#/F#5",
    "HIGH_G": "Visoki G/G5",
    "HIGH_G$": "Visoki G#/G#5",
    "HIGH_A": "Visoki A/A5",
    "HIGH_A$": "Visoki A#/A#5",
    "HIGH_B": "Visoko B/B5",
    "OPERATORS_STRINGTONUMBER": "pretvori niz %1 u %2",
    "INTEGER": "cijeli broj",
    "DECIMAL": "decimalni broj",
    "OPERATORS_NUMTOASCII": "pretvori broj %1 u ASCII",
    "OPERATORS_STRINGTOASCII": "pretvori niz %1 u ASCII",
    "OPERATORS_NUMTOSTRING": "pretvori broj %1 u niz",
    "OPERATORS_MAP": "preslikaj %1 iz [ %2 , %3 ] u [ %4 , %5 ]",
    "OPERATORS_CONSTRAIN": "ograniči %1 između (min) %2 i (max) %3",
    "STEPS_PER_TURN": "koraka po okretaju",
    "ROTATE_SPEED": "brzina rotacije (r/s)",
    "NEW_AI": "Napravi AI blok",
    "ASK_AND_PAINT": "molim nacrtaj broj",
    "GET_IDENTIFIED_NUM": "prepoznavanje brojeva",
    "READ_NUM_AND_SAY": "izgovori prepoznati broj",
    "IMAGE_ADDR": "Adresa slike",
    "IMAGE_PREVIEW": "Pregled slike",
    "IMAGE_OPEN": "otvorena",
    "IMAGE_SIZE": "Veličina slike",
    "IMAGE_WIDTH": "W",
    "IMAGE_HEIGHT": "H",
    "VARIABLE": "%1",
    "VARIABLE_LIST": "%1",
    "SET_STRING_VARIABLE": "postavi %1 na %2",
    "STRING_START_WITH": "%1 počinje s %2",
    "OPERATORS_RANDOMA": "%1 %2 odaberi nasumično %3 do %4 %5",
    "GLOBAL_MPY_VARIABLE": "globalno %1",
    "OPERATORS_MODA": "%1 %2 %3 mod %4",
    "OPERATORS_ROUNDA": "%1 %2 krug %3",
    "OPERATORS_MATHOPA": "%1 %2 %3 %4",
    "OPERATORS_MATHOP_ABSA": "abs",
    "OPERATORS_MATHOP_FLOORA": "ceiling",
    "OPERATORS_MATHOP_CEILINGA": "floor",
    "OPERATORS_MATHOP_SQRTA": "sqrt",
    "OPERATORS_MATHOP_FIVE": "%1 %2 promijeni %3 za %4",
    "OPERATORS_MATHOP_SIX": "%1 %2 preslika %3 od [ %4 , %5 ] do [ %6 , %7 ]",
    "OPERATORS_MATHOP_SEVEN": "%1 %2 ograniči %3 između (min) %4 i (max) %5",
    "OPERATORS_NUM_INPUT": "%1 %2 broj %3",
    "OPERATORS_NUMS_CACULATE": "%1 %2 %3",
    "OPERATORS_MATHOP_SQUARE": "**",
    "OPERATORS_MATHOP_LOGIC_OR": "|",
    "OPERATORS_MATHOP_LOGIC_AND": "i",
    "OPERATORS_MATHOP_LOGIC_POWER": "^",
    "OPERATORS_MATHOP_LOGIC_LEFT": "<<",
    "OPERATORS_MATHOP_LOGIC_RIGHT": ">>",
    "OPERATORS_SPECIAL_NUM": "%1 %2 %3",
    "OPERATORS_MATHOP_PI": "π",
    "OPERATORS_MATHOP_LOGARITHM": "e",
    "OPERATORS_MATHOP_FAI": "φ",
    "OPERATORS_MATHOP_SQRT2": "sqrt(2)",
    "OPERATORS_MATHOP_SQRTHALF": "sqrt(½)",
    "OPERATORS_MATHOP_UNLIMITED": "∞",
    "OPERATORS_NUMS_TYPE": "%1 %2 %3 %4?",
    "OPERATORS_MATHOP_ISEVEN": "je čak",
    "OPERATORS_MATHOP_ISODD": "je čudno",
    "OPERATORS_MATHOP_ISINT": "je int",
    "OPERATORS_MATHOP_ISPOSITIVE": "je pozitivan",
    "OPERATORS_MATHOP_ISNEGATIVE": "je negativan",
    "OPERATORS_SAVE2DECIMAL": "%1 %2 %3 spremi %4 decimalna mjesta",
    "OPERATORS_LIST_CACULATE": "%1 %2 %3 %4",
    "OPERATORS_MATHOP_LIST_SUM": "zbroj brojeva na listi",
    "OPERATORS_MATHOP_LIST_MIN": "minimalna vrijednost na listi",
    "OPERATORS_MATHOP_LIST_MAX": "najveća vrijednost na listi",
    "OPERATORS_MATHOP_LIST_AVERAGE": "prosjek na listi",
    "OPERATORS_MATHOP_LIST_NUM": "broj znamenki na listi",
    "OPERATORS_MATHOP_LIST_MODE": "način liste",
    "OPERATORS_MATHOP_LIST_STDDEVIATION": "standardna devijacija na listi",
    "OPERATORS_MATHOP_LIST_RANDOM": "nasumična stavka liste",
    "OPERATORS_QUOTIENT_INT": "%1 %2 %3 ÷ %4 cijeli dio kvocijenta",
    "OPERATORS_RANDOM_DECIMAL": "%1 %2 slučajna decimala",
    "OPERATORS_BIN_NEGATION": "%1 %2 binarna negacija %3",
    "OPERATORS_INT_TO_STR": "%1 %2 decimalni cijeli broj %3 pretvoriti u %4 niz",
    "OPERATORS_MATHOP_INTTOSTR_BIN": "binarni",
    "OPERATORS_MATHOP_INTTOSTR_OCT": "oktalni",
    "OPERATORS_MATHOP_INTTOSTR_HEX": "šesterokutni",
    "OPERATORS_BINSTR_TO_INT": "%1 %2 %3 niz %4 pretvoriti u decimalni cijeli broj",
    "OPERATORS_INT_TO_BYTE": "%1 %2 decimalni cijeli broj %3 pretvara u %4 bajt",
    "OPERATORS_MATHOP_INTTOBYTE_TWO": "2",
    "OPERATORS_MATHOP_INTTOBYTE_FOUR": "4",
    "OPERATORS_INT_TO_ASCII": "%1 %2 int %3 pretvoriti u ASCII znak",
    "OPERATORS_ASCII_TO_INT": "%1 %2 ASCII znak %3 u int",
    "OPERATORS_STR_TO_INT": "%1 %2 niz %3 u hex niz",
    "OPERATORS_HEXSTR_TO_BIN": "%1 %2 heksadecimalni niz %3 u bin bajt",
    "OPERATORS_STR_TO_BASE64": "%1 %2 %3 kodira u formatu base64 vraća bajt objekt",
    "OPERATORS_BASE64_TO_BYTE": "%1 %2 dekodiranje base64 podataka %3 vraća bajt objekt",
    "OPERATORS_MATHOP_LENGHT": "dužina %1",
    "OPERATORS_MATHOP_MAXMIN": "%1 %2",
    "OPERATORS_MATHOP_MAXMIN_MAX": "max",
    "OPERATORS_MATHOP_MAXMIN_MIN": "min",
    "OPERATORS_TEXTS_ONE": "%1 %2 pridruži %3 %4",
    "OPERATORS_TEXTS_TWO": "%1 %2 slovo %4 od %3",
    "OPERATORS_TEXTS_THREE": "%1 %2 %3 sadrži %4?",
    "OPERATORS_TEXTS_FOUR": "%1 %2 slovo %5 %4 do %7 %6 od %3",
    "OPERATORS_TEXTS_FOUR_NEW": "%1 dohvati od %3 znaka %2 do %5 znaka %4",
    "OPERATORS_TEXTS_NEW": "%1 %2 pronađi %3 u %4 %5 Položaj u nastajanju",
    "OPERATORS_TEXTS_NEW_OP": "pronađi gdje se %1 pojavljuje u %2 %3",
    "OPERATORS_TEXTS_FOUR_ONE": "s lijeva",
    "OPERATORS_TEXTS_FOUR_TWO": "s desna",
    "OPERATORS_TEXTS_FOUR_THREE": "s lijeva",
    "OPERATORS_TEXTS_FOUR_FOUR": "s desna",
    "OPERATORS_TEXTS_FOUR_F": "prvi",
    "OPERATORS_TEXTS_FOUR_L": "posljednji",
    "OPERATORS_INPUTSTRING": "%1 %2 niz %3",
    "OPERATORS_TRANSINTOTEXT": "%1 %2 pretvoriti u %3",
    "OPERATORS_TRANSINTOTEXT_ADD": "dodati",
    "OPERATORS_TRANSINTOTEXT_ADD_ITEM": "artikal",
    "OPERATORS_FORMATSTRING2F": "%1 %2 formatirani niz %3 % %4",
    "OPERATORS_FORMATSTRING3": "%1 %2 niz formata %3 format %4",
    "OPERATORS_TEXT_ESCAPE": "%1 %2 znak izlaznog teksta %3",
    "BAR_N": "\\n",
    "BAR_NN": "\\n\\r",
    "BAR_R": "\\r",
    "BAR_RN": "\\r\\n",
    "BAR_B": "\\b",
    "BAR_T": "\\t",
    "BAR_BAR": "\\\\",
    "OPERATORS_TEXT_NUMORLETTER": "%1 %2 %3 %4?",
    "OPERATORS_TEXT_NUMORLETTER_NUM": "je broj",
    "OPERATORS_TEXT_NUMORLETTER_LETTER": "je pismo",
    "OPERATORS_TEXT_LENGTH": "%1 %2 %3 dužina",
    "OPERATORS_TEXT_ISNONE": "%1 %2 %3 nije ništa?",
    "OPERATORS_FROMTEXT_GETCHAR": "%1 %2 u tekstu %3 %4",
    "OPERATORS_FROMTEXT_GETCHAR_FIRST": "prvo slovo",
    "OPERATORS_FROMTEXT_GETCHAR_LAST": "zadnje slovo",
    "OPERATORS_FROMTEXT_GETCHAR_RANDOM": "nasumično slovo",
    "OPERATORS_TEXT_UPPLOW": "%1 %2 %3 %4",
    "OPERATORS_TEXT_UPPLOW_UPP": "velika slova",
    "OPERATORS_TEXT_UPPLOW_LOW": "mala slova",
    "OPERATORS_TEXT_SIDES_SPACE": "%1 %2 %3 %4",
    "OPERATORS_TEXT_SIDES_SPACE_BOTH": "čisti prostori s obje strane",
    "OPERATORS_TEXT_SIDES_SPACE_LEFT": "oslobodi lijevi prostor",
    "OPERATORS_TEXT_SIDES_SPACE_RIGHT": "oslobodi desni prostor",
    "OPERATORS_TEXT_STRTOBYTE": "%1 %2 okrenuti bajt %3",
    "OPERATORS_TEXT_BYTETOSTR": "%1 %2 bajt %3 pretvoriti u niz",
    "OPERATORS_TEXT_DICTTOJSONSTR": "%1 %2 dict %3 pretvoriti u json niz",
    "OPERATORS_TEXT_PARSEJSONSTR": "%1 %2 raščlani json niz %3 i vrati objekt",
    "OPERATORS_STRINGTONUMBERA": "%1 %2 pretvori niz %3 u %4",
    "OPERATORS_NUMTOASCIIA": "%1 %2 pretvara broj %3 u ASCII znakove",
    "OPERATORS_STRINGTOASCIIA": "%1 %2 pretvoriti znak %3 u ASCII vrijednost",
    "OPERATORS_NUMTOSTRINGA": "%1 %2 pretvori broj %3 u niz",
    "NEW_MC": "napraviti micropython varijablu",
    "RENAME_MCNUMBER_TITLE": "preimenuj sve varijable čije je ime [%1] u:",
    "RENAME_MCNUMBER_MODAL_TITLE": "preimenuj varijablu",
    "RENAME_VARIABLE_MCNUMBER": "preimenuj varijablu",
    "DELETE_VARIABLE_MCNUMBER": "brisanje varijable [%1]",
    "SET_MC_VARIABLE": "postavi %1 na %2",
    "ALIYUN": "Aliyun",
    "ONENET": "OneNet",
    "ONENETNEW": "OneNet (novo)",
    "EASYIOT": "Easy IoT",
    "SHANGHAI": "Šangaj",
    "QINGDAO": "Qingdao",
    "BEIJING": "Peking",
    "ZHANGJIAKOU": "Zhangjiakou",
    "HUHEHAOTE": "Huhehaote",
    "HANGZHOU": "Hangzhou",
    "SHENZHEN": "Shenzhen",
    "HONGKONG": "Hong Kong",
    "SINGAPORE": "Singapur",
    "SYDNEY": "Sydney",
    "KUALALUMPUR": "Luala Lumpur",
    "JAKARTA": "Jakarta",
    "MUMBAI": "Mumbai",
    "TOKYO": "Tokio",
    "SILICONVALLEY": "Silicijska dolina",
    "VIRGINIA": "Virginia",
    "FRANKFURT": "Frankfurt",
    "LONDON": "London",
    "DUBAI": "Dubai",
    "IOT_PLATFORM": "Iot platforma",
    "PARAMS": "Parametri",
    "SERVER_ATTR": "Adresa poslužitelja",
    "PRODUCTID": "Identifikacijski broj proizvoda",
    "DEVICEID": "DeviceId",
    "DEVICENAME": "Naziv uređaja",
    "TINYDB_SECRET": "tajna (tajna)",
    "TINYDB_USER": "korisnik (korisnik)",
    "APIADDR": "API adresa",
    "SOFTWARE_SERIAL": "Serijski softver",
    "HARDWARE_SERIAL": "Hardverski serijski",
    "HARDWARE_SERIAL1": "Serijski hardver 1",
    "HARDWARE_SERIAL2": "Serijski hardver 2",
    "HARDWARE_SERIAL3": "Serijski hardver 3",
    "CHECKTYPE_TIPS": "Vrsta podataka koju prima okvir za unos ne odgovara vrsti izlaza građevnog bloka",
    "HIGHLIGHT_BLOCK": "Istakni blok",
    "HIGHLIGHT_ALL_CONFLICT_BLOCKS": "Označi sve blokove s upozorenjima",
    "SNAPSHOT": "Snimak (CTRL+G)",
    "pictureaiPath": "unesi put slike ili URL",
    "pictureaiType": "Vrsta računa",
    "pictureaiAccountDefault": "zadani račun",
    "pictureaiAccountBaidu": "baidu račun",
    "pictureaiwebImgAddr": "adresa web slike",
    "MITRIXICON_EDIT": "osvijetli",
    "MITRIXICON_ERASER": "gumica",
    "MITRIXICON_DUSTBIN": "očisti",
    "MITRIXICON_REVERSE": "invertiraj",
    "MITRIXICON_COLOR": "odaberi boju",
    "RED": "crvena",
    "GREEN": "zelena",
    "BLUE": "plava",
    "SEARCH_BLOCKS": "traži blokove",
    "NO_SEARCH_BLOCKS": "bez rezultata",
    "SEARCH_DEFAULT_LABEL_THINK": "razmišljaj",
    "SEARCH_DEFAULT_LABEL_IF": "ako",
    "SEARCH_DEFAULT_LABEL_WAIT": "čekaj",
    "SEARCH_DEFAULT_LABEL_CONVERT": "pretvori",
    "SEARCH_DEFAULT_LABEL_BROADCAST": "emitiraj",
    "MITRIXICON_SAVE": "spremi",
    "MITRIXICON_EXPORT": "izvezi",
    "MITRIXICON_UPLOAD": "učitaj",
    "ROLL_MOUSE": "Kotrljajući miš",
    "MOUSE_ZOOM_IN_OUT": "Pomiči se mišem za povećavanje i smanjivanje",
    "UPLOAD_CORRECT_JSON_FILE": "Molimo prenesi json datoteku u ispravnom formatu",
    "EXPORT_PROMPT": "Nema prilagođenih uzoraka za izvoz. Klikni gumb za spremanje u donjem desnom kutu kako bi spremio prilagođeni uzorak prije pokušaja izvoza.",
    "HEART": "Srce",
    "HEART_SMALL": "Malo srce",
    "HAPPY": "Sretan",
    "SMILE": "Osmijeh",
    "SAD": "tužno",
    "CONFUSED": "Zbunjen",
    "ANGRY": "Ljut",
    "SLEEP": "Spavati",
    "SURPRISED": "Iznenađen",
    "SILLY": "Budalica",
    "WONDERFUL": "Predivno",
    "BORED": "dosadno",
    "ROCK": "Rock",
    "ROCK_SMALL": "Mali kamen",
    "PAPER": "Papir",
    "PAPER_SMALL": "Mali papir",
    "SCISSORS": "škare",
    "SCISSORS_SMALL": "Male škare",
    "DATA_SOLITLIST": "%1 %2 napravi %3 %4 s razdjelnikom %5",
    "DATA_SOLITLIST_TYPE_LT": "lista iz teksta5",
    "DATA_SOLITLIST_TYPE_TL": "tekst sa liste",
    "DATA_LENLIST": "%1 %2 %3 je prazan?",
    "DATA_LENGTHLIST": "%1 %2 dužina %3",
    "DATA_CREATELIST": "%1 %2 napravi listu %3",
    "DATA_CLEARLIST": "%1 %2 očisti listu %3",
    "ML5MODALBUTTON": "Otvoreno AI vizualno sučelje",
    "COLLAPSE_BLOCK": "Sažmi",
    "EXPAND_BLOCK": "Proširi",
    "COLLAPSE_ALL": "Sažmi sve blokove",
    "EXPAND_ALL": "Proširi sve blokove",
    "pyTimer": "Timer",
    "pyList": "Lista",
    "pyDictionary": "Rječnik",
    "pySet": "Set",
    "mpyTuple": "Tuple",
    "pythonBase_printAndInput": "Ulaz i izlaz",
    "pythonBase_mainSetUp": "%1 %2 Pokretanje glavnog programa Pythona",
    "pythonBase_print": "%1 %2 ispis %3",
    "pythonBase_input": "%1 %2 dohvaća unos, upit je %3",
    "pythonBase_file": "datoteka",
    "pythonBase_file_open": "%1 %2 otvori datoteku %5 u formatu %3 način rada %4 i vrati %6",
    "pythonBase_file_open_read": "čitati",
    "pythonBase_file_open_write": "pisati",
    "pythonBase_file_close": "%1 %2 %3 zatvori",
    "pythonBase_file_read": "%1 %2 %3 čita %4",
    "pythonBase_file_read_all": "cijela datoteka",
    "pythonBase_file_read_line": "linija",
    "pythonBase_file_read_lines": "sve linije",
    "pythonBase_file_write": "%1 %2 %3 napiši %4",
    "pyTimer_setEvent": "%1 %2 postavi tajmer %3 %4 ciklus %5 milisekundi",
    "pyTimer_repeated": "Ponovljeno izvršenje",
    "pyTimer_delayed": "Odgođeno izvršenje",
    "pyTimer_conditionEvent": "%1 %2 postavi tajmer %3: kada %4",
    "pyTimer_getCount": "%1 %2 tajmer %3 vrijednost brojanja",
    "pyTimer_clearEvent": "%1 %2 brisanje mjerača vremena/događaja %3",
    "pyDictionary_init": "%1 %2 inicijalizirati rječnik %3",
    "pyDictionary_getValue": "%1 %2 rječnik %3 ključ %4 vrijednost",
    "pyDictionary_setValue": "Vrijednost %1 %2 rječnika %3 ključa %4 postavljena je na %5",
    "pyDictionary_deleteKey": "%1 %2 rječnik %3 tipka za brisanje %4",
    "pyDictionary_clear": "%1 %2 očisti rječnik %3",
    "pyDictionary_isInclude": "%1 %2 rječnik %3 sadrži ključ %4?",
    "pyDictionary_getLength": "%1 %2 rječnik %3 dužina",
    "pyDictionary_list": "%1 %2 rječnik %3 %4 listu",
    "pyDictionary_TypeIndex_A": "Ključ",
    "pyDictionary_TypeIndex_B": "Vrijednost",
    "pyList_join": "%1 %2 listu %3 koristi separator %4 za kombiniranje teksta",
    "pyList_split": "%1 %2 tekst %3 koristi separator %4 za izradu liste",
    "pyList_init": "%1 %2 listu inicijalizacije %3",
    "pyList_clear": "%1 %2 očisti listu %3",
    "pyList_getLength": "%1 %2 dužina liste %3",
    "pyList_isEmpty": "%1 %2 listu %3 je prazan?",
    "pyList_getValue": "%1 %2 listu %3 indeks %4 vrijednost",
    "pyList_getValueRange": "%1 %2 vraća listu %3 i uzima %4 %5 stavki u %6 %7 stavki",
    "pyList_insert": "%1 %2 listu %3 umetni %5 u indeks %4",
    "pyList_setValue": "%1 %2 listu %3 postavi indeks %4 na vrijednost %5",
    "pyList_delete": "%1 %2 listu %3 brisanje indeksa %4 vrijednost",
    "pyList_append": "%1 %2 listu %3 dodaje %4 na kraj",
    "pyList_extend": "%1 %2 listu %3 dodaj listu %4",
    "pyList_findIndex": "%1 %2 lista %3 pronađi indeks od %4",
    "pyList_sort": "%1 %2 listu %3 poredan prema %4 kao %5",
    "pyList_SortModeIndex_A": "Uzlazno",
    "pyList_SortModeIndex_B": "Silazni poredak",
    "pyList_SortTypeIndex_A": "Broj",
    "pyList_SortTypeIndex_B": "Pismo",
    "pyList_SortTypeIndex_C": "Slova, zanemari velika i mala slova",
    "pySet_init": "%1 %2 inicijalizirati skup %3",
    "pySet_update": "%1 %2 set %3 ažuriran je na %5 sa skupom %4",
    "pySet_TypeIndex_A": "Križanje",
    "pySet_TypeIndex_B": "Union Set",
    "pySet_TypeIndex_C": "Set razlika",
    "pySet_addValue": "%1 %2 postavi %3 dodaj %4",
    "pySet_isSubsetSuperset": "%1 %2 skup %3 je %5 skupa %4?",
    "pySet_SubsetSupersetType_A": "Podskup",
    "pySet_SubsetSupersetType_B": "Superset",
    "pySet_intersectionUnionSetDifference": "%1 %2 uzima %3 set %4 set %5",
    "pySet_length": "%1 %2 postavi %3 duljinu",
    "pySet_pop": "%1 %2 vratio je nasumične stavke i uklonio ih iz skupa %3",
    "pyTuple_init": "%1 %2 inicijalizirana korpa %3",
    "pyTuple_minMaxLength": "%1 %2 tuple %3 %4",
    "pyTuple_TypeIndex_A": "Minimalna vrijednost",
    "pyTuple_TypeIndex_B": "Maksimalna vrijednost",
    "pyTuple_TypeIndex_C": "Dužina",
    "pyTuple_isInclude": "%1 %2 tuple %3 sadrži %4?",
    "pyTuple_getValueRange": "%1 %2 vraća tuple %3 uzimajući %4 %5 stavki u %6 %7 stavki",
    "pyTuple_IndexType_A": "#",
    "pyTuple_IndexType_B": "odbrojavanje #",
    "pyTuple_listToTulpe": "%1 %2 listu %3 za nadogradnju",
    "pyTuple_getValue": "%1 %2 tuple %3 indeks %4 vrijednost",
    "pyTuple_tupleToList": "%1 %2 tuple %3 pretvoren u listu"
  },
  "cs": {
    "CONTROL_FOREVER": "opakuj stále",
    "CONTROL_REPEAT": "opakuj %1 krát",
    "CONTROL_IF": "když %1 tak",
    "CONTROL_ELSE": "jinak",
    "CONTROL_STOP": "zastav",
    "CONTROL_STOP_ALL": "všechno",
    "CONTROL_STOP_THIS": "tento scénář",
    "CONTROL_STOP_OTHER": "jiné scénáře postavy",
    "CONTROL_WAIT": "čekej %1 sekund",
    "CONTROL_WAITUNTIL": "čekej dokud nenastane %1",
    "CONTROL_REPEATUNTIL": "opakuj dokud nenastane %1",
    "CONTROL_WHILE": "opakuj dokud platí %1",
    "CONTROL_FOREACH": "pro každé %1 z %2",
    "CONTROL_STARTASCLONE": "když startuje můj klon",
    "CONTROL_CREATECLONEOF": "klonuj %1",
    "CONTROL_CREATECLONEOF_MYSELF": "sebe",
    "CONTROL_DELETETHISCLONE": "zruš tento klon",
    "CONTROL_COUNTER": "čítač",
    "CONTROL_INCRCOUNTER": "zvyš čítač",
    "CONTROL_CLEARCOUNTER": "vynuluj čítač",
    "CONTROL_ALLATONCE": "vše najednou",
    "DATA_SETVARIABLETO": "nastav %1 na %2",
    "DATA_CHANGEVARIABLEBY": "změň %1 o %2",
    "DATA_SHOWVARIABLE": "ukaž proměnnou %1",
    "DATA_HIDEVARIABLE": "skryj proměnnou %1",
    "DATA_ADDTOLIST": "přidej %1 k %2",
    "DATA_DELETEOFLIST": "smaž %1 z %2",
    "DATA_DELETEALLOFLIST": "smaž všechno z %1",
    "DATA_INSERTATLIST": "vlož %1 na %2 v %3",
    "DATA_REPLACEITEMOFLIST": "nahraď %1 v %2 hodnotou %3",
    "DATA_ITEMOFLIST": "prvek %1 z %2",
    "DATA_ITEMNUMOFLIST": "pořadí %1 ve %2",
    "DATA_LENGTHOFLIST": "délka %1",
    "DATA_LISTCONTAINSITEM": "%1 obsahuje %2",
    "DATA_SHOWLIST": "ukaž seznam %1",
    "DATA_HIDELIST": "skryj seznam %1",
    "DATA_INDEX_ALL": "všechno",
    "DATA_INDEX_LAST": "poslední",
    "DATA_INDEX_RANDOM": "náhodně",
    "EVENT_WHENFLAGCLICKED": "po kliknutí na %1",
    "EVENT_WHENTHISSPRITECLICKED": "po kliknutí na mě",
    "EVENT_WHENSTAGECLICKED": "po kliknutí na scénu",
    "EVENT_WHENTOUCHINGOBJECT": "po doteku %1",
    "EVENT_WHENBROADCASTRECEIVED": "po obdržení zprávy %1",
    "EVENT_WHENBACKDROPSWITCHESTO": "po změně pozadí na %1",
    "EVENT_WHENGREATERTHAN": "když %1 > %2",
    "EVENT_WHENGREATERTHAN_TIMER": "stopky",
    "EVENT_WHENGREATERTHAN_LOUDNESS": "hlasitost",
    "EVENT_BROADCAST": "vyšli zprávu %1",
    "EVENT_BROADCASTANDWAIT": "vyšli zprávu %1 a čekej",
    "EVENT_WHENKEYPRESSED": "po stisku klávesy %1",
    "EVENT_WHENKEYPRESSED_SPACE": "mezerník",
    "EVENT_WHENKEYPRESSED_LEFT": "šipka vlevo",
    "EVENT_WHENKEYPRESSED_RIGHT": "šipka vpravo",
    "EVENT_WHENKEYPRESSED_DOWN": "šipka dolů",
    "EVENT_WHENKEYPRESSED_UP": "šipka nahoru",
    "EVENT_WHENKEYPRESSED_ANY": "libovolná",
    "LOOKS_SAYFORSECS": "bublina %1 %2 sekund",
    "LOOKS_SAY": "bublina %1",
    "LOOKS_HELLO": "Ahoj!",
    "LOOKS_THINKFORSECS": "myšlenka %1 %2 sekund",
    "LOOKS_THINK": "myšlenka %1",
    "LOOKS_HMM": "Hmm...",
    "LOOKS_SHOW": "ukaž se",
    "LOOKS_HIDE": "skryj se",
    "LOOKS_HIDEALLSPRITES": "skryj všechny postavy",
    "LOOKS_EFFECT_COLOR": "barva",
    "LOOKS_EFFECT_FISHEYE": "rybí oko",
    "LOOKS_EFFECT_WHIRL": "víření",
    "LOOKS_EFFECT_PIXELATE": "kostičkování",
    "LOOKS_EFFECT_MOSAIC": "mozaika",
    "LOOKS_EFFECT_BRIGHTNESS": "jas",
    "LOOKS_EFFECT_GHOST": "průhlednost",
    "LOOKS_CHANGEEFFECTBY": "změň efekt %1 o %2",
    "LOOKS_SETEFFECTTO": "nastav efekt %1 na %2",
    "LOOKS_CLEARGRAPHICEFFECTS": "zruš grafické efekty",
    "LOOKS_CHANGESIZEBY": "změň velikost o %1",
    "LOOKS_SETSIZETO": "nastav velikost na %1 %",
    "LOOKS_SIZE": "velikost",
    "LOOKS_CHANGESTRETCHBY": "změň roztažení o %1",
    "LOOKS_SETSTRETCHTO": "nastav roztažení na %1 %",
    "LOOKS_SWITCHCOSTUMETO": "změň kostým na %1",
    "LOOKS_NEXTCOSTUME": "další kostým",
    "LOOKS_SWITCHBACKDROPTO": "přepni pozadí na %1",
    "LOOKS_GOTOFRONTBACK": "přejdi na vrstvu %1",
    "LOOKS_GOTOFRONTBACK_FRONT": "popředí",
    "LOOKS_GOTOFRONTBACK_BACK": "pozadí",
    "LOOKS_GOFORWARDBACKWARDLAYERS": "jdi %1 o %2",
    "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "dopředu",
    "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "dozadu",
    "LOOKS_BACKDROPNUMBERNAME": "pozadí %1",
    "LOOKS_COSTUMENUMBERNAME": "kostým %1",
    "LOOKS_NUMBERNAME_NUMBER": "číslo",
    "LOOKS_NUMBERNAME_NAME": "název",
    "LOOKS_SWITCHBACKDROPTOANDWAIT": "změň pozadí na %1",
    "LOOKS_NEXTBACKDROP_BLOCK": "další pozadí",
    "LOOKS_NEXTBACKDROP": "další pozadí",
    "LOOKS_PREVIOUSBACKDROP": "předchozí pozadí",
    "LOOKS_RANDOMBACKDROP": "náhodné pozadí",
    "MOTION_MOVESTEPS": "dopředu o %1 kroků",
    "MOTION_TURNLEFT": "otoč se %1 o %2 stupňů",
    "MOTION_TURNRIGHT": "otoč se %1 o %2 stupňů",
    "MOTION_POINTINDIRECTION": "nastav směr %1",
    "MOTION_POINTTOWARDS": "nastav směr k %1",
    "MOTION_POINTTOWARDS_POINTER": "ukazateli myši",
    "MOTION_POINTTOWARDS_RANDOM": "náhodný směr",
    "MOTION_GOTO": "skoč na %1",
    "MOTION_GOTO_POINTER": "ukazatel myši",
    "MOTION_GOTO_RANDOM": "náhodná pozice",
    "MOTION_GOTOXY": "skoč na x: %1 y: %2",
    "MOTION_GLIDESECSTOXY": "klouzej %1 sekund na x: %2 y: %3",
    "MOTION_GLIDETO": "klouzej %1 sekund na %2",
    "MOTION_GLIDETO_POINTER": "ukazatel myši",
    "MOTION_GLIDETO_RANDOM": "náhodná pozice",
    "MOTION_CHANGEXBY": "změň x o %1",
    "MOTION_SETX": "nastav x na %1",
    "MOTION_CHANGEYBY": "změň y o %1",
    "MOTION_SETY": "nastav y na %1",
    "MOTION_IFONEDGEBOUNCE": "když narazíš na okraj, odraz se",
    "MOTION_SETROTATIONSTYLE": "nastav otáčení %1",
    "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "vlevo-vpravo",
    "MOTION_SETROTATIONSTYLE_DONTROTATE": "neotáčet",
    "MOTION_SETROTATIONSTYLE_ALLAROUND": "dokola",
    "MOTION_XPOSITION": "x",
    "MOTION_YPOSITION": "y",
    "MOTION_DIRECTION": "směr",
    "MOTION_SCROLLRIGHT": "posuň se doprava %1",
    "MOTION_SCROLLUP": "posuň se nahoru %1",
    "MOTION_ALIGNSCENE": "zarovnej scénu %1",
    "MOTION_ALIGNSCENE_BOTTOMLEFT": "vlevo dole",
    "MOTION_ALIGNSCENE_BOTTOMRIGHT": "vpravo dole",
    "MOTION_ALIGNSCENE_MIDDLE": "prostřední",
    "MOTION_ALIGNSCENE_TOPLEFT": "vlevo nahoře",
    "MOTION_ALIGNSCENE_TOPRIGHT": "vpravo nahoře",
    "MOTION_XSCROLL": "x přesouvání",
    "MOTION_YSCROLL": "y přesouvání",
    "MOTION_STAGE_SELECTED": "Scéna je zvolena: nemá bloky pohybu",
    "OPERATORS_ADD": "%1 + %2",
    "OPERATORS_SUBTRACT": "%1 - %2",
    "OPERATORS_MULTIPLY": "%1 * %2",
    "OPERATORS_DIVIDE": "%1 / %2",
    "OPERATORS_RANDOM": "náhodné číslo od %1 do %2",
    "OPERATORS_GT": "%1 > %2",
    "OPERATORS_GT_EQUALS": "",
    "OPERATORS_LT": "%1 < %2",
    "OPERATORS_LT_EQUALS": "",
    "OPERATORS_EQUALS": "%1 = %2",
    "OPERATORS_AND": "%1 a %2",
    "OPERATORS_OR": "%1 nebo %2",
    "OPERATORS_NOT": "ne %1",
    "OPERATORS_JOIN": "spoj %1 %2",
    "OPERATORS_JOIN_APPLE": "jablko",
    "OPERATORS_JOIN_BANANA": "banán",
    "OPERATORS_LETTEROF": "písmeno %1 z %2",
    "OPERATORS_LETTEROF_APPLE": "a",
    "OPERATORS_LENGTH": "délka %1",
    "OPERATORS_CONTAINS": "%1 obsahuje %2?",
    "OPERATORS_MOD": "zbytek %1 děleno %2",
    "OPERATORS_ROUND": "zaokrouhli %1",
    "OPERATORS_MATHOP": "%1 %2",
    "OPERATORS_MATHOP_ABS": "abs",
    "OPERATORS_MATHOP_FLOOR": "zaokr. dolů",
    "OPERATORS_MATHOP_CEILING": "zaokr. nahoru",
    "OPERATORS_MATHOP_SQRT": "odmocnina",
    "OPERATORS_MATHOP_SIN": "sin",
    "OPERATORS_MATHOP_COS": "cos",
    "OPERATORS_MATHOP_TAN": "tg",
    "OPERATORS_MATHOP_ASIN": "arcsin",
    "OPERATORS_MATHOP_ACOS": "arccos",
    "OPERATORS_MATHOP_ATAN": "arctg",
    "OPERATORS_MATHOP_LN": "ln",
    "OPERATORS_MATHOP_LOG": "log",
    "OPERATORS_MATHOP_EEXP": "e ^",
    "OPERATORS_MATHOP_10EXP": "10 ^",
    "PROCEDURES_DEFINITION": "scénář pro %1",
    "SENSING_TOUCHINGOBJECT": "dotýkáš se %1?",
    "SENSING_TOUCHINGOBJECT_POINTER": "ukazatele myši",
    "SENSING_TOUCHINGOBJECT_EDGE": "okraje",
    "SENSING_TOUCHINGCOLOR": "dotýkáš se barvy %1?",
    "SENSING_COLORISTOUCHINGCOLOR": "barvou %1 se dotýkáš %2?",
    "SENSING_DISTANCETO": "vzdálenost k %1",
    "SENSING_DISTANCETO_POINTER": "ukazateli myši",
    "SENSING_ASKANDWAIT": "otázka %1",
    "SENSING_ASK_TEXT": "Jak se jmenuješ?",
    "SENSING_ANSWER": "odpověď",
    "SENSING_KEYPRESSED": "klávesa %1 stisknuta?",
    "SENSING_MOUSEDOWN": "myš stisknuta?",
    "SENSING_MOUSEX": "x myši",
    "SENSING_MOUSEY": "y myši",
    "SENSING_SETDRAGMODE": "povoleno přesouvat %1",
    "SENSING_SETDRAGMODE_DRAGGABLE": "ano",
    "SENSING_SETDRAGMODE_NOTDRAGGABLE": "ne",
    "SENSING_LOUDNESS": "hlasitost",
    "SENSING_LOUD": "hlasité?",
    "SENSING_TIMER": "stopky",
    "SENSING_RESETTIMER": "vynuluj stopky",
    "SENSING_OF": "%1 z %2",
    "SENSING_OF_XPOSITION": "x",
    "SENSING_OF_YPOSITION": "y",
    "SENSING_OF_DIRECTION": "směr",
    "SENSING_OF_COSTUMENUMBER": "číslo kostýmu",
    "SENSING_OF_COSTUMENAME": "název kostýmu",
    "SENSING_OF_SIZE": "velikost",
    "SENSING_OF_VOLUME": "hlasitost",
    "SENSING_OF_BACKDROPNUMBER": "číslo pozadí",
    "SENSING_OF_BACKDROPNAME": "název pozadí",
    "SENSING_OF_STAGE": "Scéna",
    "SENSING_CURRENT": "aktuální %1",
    "SENSING_CURRENT_YEAR": "rok",
    "SENSING_CURRENT_MONTH": "měsíc",
    "SENSING_CURRENT_DATE": "den v měsíci",
    "SENSING_CURRENT_DAYOFWEEK": "den týdne",
    "SENSING_CURRENT_HOUR": "hodina",
    "SENSING_CURRENT_MINUTE": "minuta",
    "SENSING_CURRENT_SECOND": "sekunda",
    "SENSING_DAYSSINCE2000": "dnů od r. 2000",
    "SENSING_USERNAME": "jméno uživatele",
    "SENSING_USERID": "ID uživatele",
    "SOUND_PLAY": "začni hrát zvuk %1",
    "SOUND_PLAYUNTILDONE": "přehraj zvuk %1 až do konce",
    "SOUND_STOPALLSOUNDS": "zastav všechny zvuky",
    "SOUND_SETEFFECTO": "nastav efekt %1 na %2",
    "SOUND_CHANGEEFFECTBY": "změň efekt %1 o %2",
    "SOUND_CLEAREFFECTS": "zruš zvukové efekty",
    "SOUND_EFFECTS_PITCH": "poloha",
    "SOUND_EFFECTS_PAN": "stereo vlevo/vpravo",
    "SOUND_CHANGEVOLUMEBY": "změň hlasitost o %1",
    "SOUND_SETVOLUMETO": "nastav hlasitost na %1%",
    "SOUND_VOLUME": "hlasitost",
    "SOUND_RECORD": "záznam ...",
    "CATEGORY_MOTION": "Pohyb",
    "CATEGORY_LOOKS": "Vzhled",
    "CATEGORY_SOUND": "Zvuk",
    "CATEGORY_EVENTS": "Události",
    "CATEGORY_CONTROL": "Ovládání",
    "CATEGORY_SENSING": "Vnímání",
    "CATEGORY_OPERATORS": "Operátory",
    "CATEGORY_VARIABLES": "Proměnné",
    "CATEGORY_MYBLOCKS": "Moje bloky",
    "CATEGORY_LISTS": "",
    "CATEGORY_TUPLE": "",
    "CATEGORY_DICTIONARYS": "",
    "CATEGORY_NUMBERS": "",
    "CATEGORY_TEXT": "",
    "DUPLICATE": "Kopírovat",
    "DELETE": "Odstranit",
    "ADD_COMMENT": "Přidat poznámku",
    "REMOVE_COMMENT": "Odstranit poznámku",
    "DELETE_BLOCK": "Smaž blok",
    "DELETE_X_BLOCKS": "Smazat %1 bloků",
    "DELETE_ALL_BLOCKS": "Smazat všech %1 bloků?",
    "CLEAN_UP": "Ukliď bloky",
    "HELP": "Pomoc",
    "UNDO": "Vrátit",
    "REDO": "Znovu provést",
    "EDIT_PROCEDURE": "Upravit",
    "SHOW_PROCEDURE_DEFINITION": "Jdi ke scénáři",
    "WORKSPACE_COMMENT_DEFAULT_TEXT": "Řekni něco ...",
    "COLOUR_HUE_LABEL": "Barva",
    "COLOUR_SATURATION_LABEL": "Sytost",
    "COLOUR_BRIGHTNESS_LABEL": "Jas",
    "CHANGE_VALUE_TITLE": "Změň hodnotu:",
    "RENAME_VARIABLE": "Přejmenovat proměnnou",
    "RENAME_VARIABLE_STRING": "",
    "RENAME_VARIABLE_NUMBER": "",
    "RENAME_VARIABLE_LIST": "",
    "RENAME_VARIABLE_TITLE": "Přejmenuj všech \"%1\" proměnných na:",
    "RENAME_VARIABLE_MODAL_TITLE": "Přejmenovat proměnnou",
    "NEW_VARIABLE": "Vytvoř proměnnou",
    "NEW_VARIABLE_TITLE": "Nové jméno proměnné:",
    "VARIABLE_MODAL_TITLE": "Nová proměnná",
    "VARIABLE_ALREADY_EXISTS": "Proměnná pojmenovaná \"%1\" již existuje.",
    "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "Proměnná téhož jména \"%1\" již existuje jako jiná proměnná typu \"%2\".",
    "DELETE_VARIABLE_CONFIRMATION": "Smazat %1 výskytů proměnné \"%2\"?",
    "CANNOT_DELETE_VARIABLE_PROCEDURE": "Nelze smazat proměnnou \"%1\", protože je součástí scénáře funkce \"%2\"",
    "DELETE_VARIABLE": "Smaž proměnnou \"%1\"",
    "DELETE_VARIABLE_STRING": "",
    "DELETE_VARIABLE_NUMBER": "",
    "DELETE_VARIABLE_LIST": "",
    "NEW_PROCEDURE": "Vytvořit blok",
    "PROCEDURE_ALREADY_EXISTS": "Scénář se jménem \"%1\" již existuje.",
    "PROCEDURE_DEFAULT_NAME": "název bloku",
    "NEW_LIST": "Vytvoř seznam",
    "NEW_LIST_TITLE": "Nový název seznamu:",
    "LIST_MODAL_TITLE": "Nový seznam",
    "LIST_ALREADY_EXISTS": "Seznam \"%1\" již existuje.",
    "RENAME_LIST_TITLE": "Přejmenuj všech \"%1\" seznamů na:",
    "RENAME_LIST_MODAL_TITLE": "Přejmenuj seznam",
    "DEFAULT_LIST_ITEM": "věc",
    "DELETE_LIST": "Smaž seznam \"%1\"",
    "RENAME_LIST": "Přejmenuj seznam",
    "NEW_BROADCAST_MESSAGE": "Nová zpráva",
    "NEW_BROADCAST_MESSAGE_TITLE": "Nový název zprávy:",
    "BROADCAST_MODAL_TITLE": "Nová zpráva",
    "DEFAULT_BROADCAST_MESSAGE_NAME": "zpráva1",
    "DELETE_PROCEDURE": "",
    "OK": "",
    "PROMPT": "",
    "STOP": "",
    "NEW_STRING": "",
    "NEW_NUMBER_VARIABLE": "",
    "WIFI": "",
    "USERNAME": "",
    "PWD": "",
    "IOT_SERVICE": "",
    "IOT_ID": "",
    "IOT_PWD": "",
    "IOT_TOPIC": "",
    "MORE_TOPIC": "",
    "OBLOQ_HTTP": "",
    "OBLOQ_IP": "",
    "OBLOQ_PORT": "",
    "ADD_TOPIC": "",
    "DELETE_TOPIC": "",
    "IOT_SERVER": "",
    "CHINA": "",
    "GLOBAL": "",
    "LOW_C": "",
    "LOW_C$": "",
    "LOW_D": "",
    "LOW_D$": "",
    "LOW_E": "",
    "LOW_F": "",
    "LOW_F$": "",
    "LOW_G": "",
    "LOW_G$": "",
    "LOW_A": "",
    "LOW_A$": "",
    "LOW_B": "",
    "MIDDLE_C": "",
    "MIDDLE_C$": "",
    "MIDDLE_D": "",
    "MIDDLE_D$": "",
    "MIDDLE_E": "",
    "MIDDLE_F": "",
    "MIDDLE_F$": "",
    "MIDDLE_G": "",
    "MIDDLE_G$": "",
    "MIDDLE_A": "",
    "MIDDLE_A$": "",
    "MIDDLE_B": "",
    "HIGH_C": "",
    "HIGH_C$": "",
    "HIGH_D": "",
    "HIGH_D$": "",
    "HIGH_E": "",
    "HIGH_F": "",
    "HIGH_F$": "",
    "HIGH_G": "",
    "HIGH_G$": "",
    "HIGH_A": "",
    "HIGH_A$": "",
    "HIGH_B": "",
    "OPERATORS_STRINGTONUMBER": "",
    "INTEGER": "",
    "DECIMAL": "",
    "OPERATORS_NUMTOASCII": "",
    "OPERATORS_STRINGTOASCII": "",
    "OPERATORS_NUMTOSTRING": "",
    "OPERATORS_MAP": "",
    "OPERATORS_CONSTRAIN": "",
    "STEPS_PER_TURN": "",
    "ROTATE_SPEED": "",
    "NEW_AI": "",
    "ASK_AND_PAINT": "",
    "GET_IDENTIFIED_NUM": "",
    "READ_NUM_AND_SAY": "",
    "IMAGE_ADDR": "",
    "IMAGE_PREVIEW": "",
    "IMAGE_OPEN": "",
    "IMAGE_SIZE": "",
    "IMAGE_WIDTH": "",
    "IMAGE_HEIGHT": "",
    "VARIABLE": "",
    "VARIABLE_LIST": "",
    "SET_STRING_VARIABLE": "",
    "STRING_START_WITH": "",
    "DICTIONARY_HEIGHT_FIRST": "",
    "DICTIONARY_HEIGHT_TWO": "",
    "DICTIONARY_HEIGHT_THREE": "",
    "DICTIONARY_HEIGHT_FOUR": "",
    "DATA_MPITEMOFLIST": "",
    "DATA_MPINTERCEPTITEM": "",
    "DATA_MPINSERTATLIST": "",
    "DATA_MPSETVALUEATLIST": "",
    "DATA_MPINSERTATLIST_INSERT": "",
    "DATA_MPINSERTATLIST_CHANGE": "",
    "DATA_MPINSERTATLIST_FOUR": "",
    "DATA_MPINSERTATLIST_FIVE": "",
    "DATA_MPINSERTATLIST_SIX": "",
    "DATA_MPINSERTATLIST_SEVEN": "",
    "DATA_MPINSERTATLIST_EIGHT": "",
    "DATA_MPINSERTATLIST_EIGHT_FALSE": "",
    "DATA_MPINSERTATLIST_EIGHT_TRUE": "",
    "DATA_MPINSERTATLIST_EIGHT_REVERSE": "",
    "OPERATORS_RANDOMA": "",
    "OPERATORS_MODA": "",
    "OPERATORS_ROUNDA": "",
    "OPERATORS_MATHOPA": "",
    "OPERATORS_MATHOP_ABSA": "",
    "OPERATORS_MATHOP_FLOORA": "",
    "OPERATORS_MATHOP_CEILINGA": "",
    "OPERATORS_MATHOP_SQRTA": "",
    "OPERATORS_MATHOP_FIVE": "",
    "OPERATORS_MATHOP_SIX": "",
    "OPERATORS_MATHOP_SEVEN": "",
    "OPERATORS_MATHOP_LENGHT": "",
    "OPERATORS_MATHOP_MAXMIN": "",
    "OPERATORS_MATHOP_MAXMIN_MAX": "",
    "OPERATORS_MATHOP_MAXMIN_MIN": "",
    "OPERATORS_TEXTS_ONE": "",
    "OPERATORS_TEXTS_TWO": "",
    "OPERATORS_TEXTS_THREE": "",
    "OPERATORS_TEXTS_FOUR": "",
    "OPERATORS_TEXTS_FOUR_NEW": "",
    "OPERATORS_TEXTS_NEW": "",
    "OPERATORS_TEXTS_NEW_OP": "",
    "OPERATORS_TEXTS_FOUR_ONE": "",
    "OPERATORS_TEXTS_FOUR_TWO": "",
    "OPERATORS_TEXTS_FOUR_THREE": "",
    "OPERATORS_TEXTS_FOUR_FOUR": "",
    "OPERATORS_TEXTS_FOUR_F": "",
    "OPERATORS_TEXTS_FOUR_L": "",
    "OPERATORS_STRINGTONUMBERA": "",
    "OPERATORS_NUMTOASCIIA": "",
    "OPERATORS_STRINGTOASCIIA": "",
    "OPERATORS_NUMTOSTRINGA": "",
    "NEW_MC": "",
    "RENAME_MCNUMBER_TITLE": "",
    "RENAME_MCNUMBER_MODAL_TITLE": "",
    "RENAME_VARIABLE_MCNUMBER": "",
    "DELETE_VARIABLE_MCNUMBER": "",
    "SET_MC_VARIABLE": "",
    "TUPLE_ONE": "",
    "TUPLE_TWO": "",
    "ALIYUN": "",
    "ONENET": "",
    "EASYIOT": "",
    "SHANGHAI": "",
    "QINGDAO": "",
    "BEIJING": "",
    "ZHANGJIAKOU": "",
    "HUHEHAOTE": "",
    "HANGZHOU": "",
    "SHENZHEN": "",
    "HONGKONG": "",
    "SINGAPORE": "",
    "SYDNEY": "",
    "KUALALUMPUR": "",
    "JAKARTA": "",
    "MUMBAI": "",
    "TOKYO": "",
    "SILICONVALLEY": "",
    "VIRGINIA": "",
    "FRANKFURT": "",
    "LONDON": "",
    "DUBAI": "",
    "IOT_PLATFORM": "",
    "PARAMS": "",
    "SERVER_ATTR": "",
    "PRODUCTID": "",
    "DEVICEID": "",
    "TINYDB_SECRET": "",
    "TINYDB_USER": "",
    "APIADDR": "",
    "SOFTWARE_SERIAL": "",
    "HARDWARE_SERIAL": "",
    "HARDWARE_SERIAL1": "",
    "HARDWARE_SERIAL2": "",
    "HARDWARE_SERIAL3": ""
  },
  "cy": {
    "CONTROL_FOREVER": "am byth",
    "CONTROL_REPEAT": "ailadrodd %1",
    "CONTROL_IF": "os %1 yna",
    "CONTROL_ELSE": "fel arall",
    "CONTROL_STOP": "aros",
    "CONTROL_STOP_ALL": "y cyfan",
    "CONTROL_STOP_THIS": "y sgript hon",
    "CONTROL_STOP_OTHER": "sgriptiau eraill yn y ciplun",
    "CONTROL_WAIT": "aros %1 eiliad",
    "CONTROL_WAITUNTIL": "aros hyd at %1",
    "CONTROL_REPEATUNTIL": "ailadrodd hyd at %1",
    "CONTROL_WHILE": "tra bo %1",
    "CONTROL_FOREACH": "am bob %1 yn %2",
    "CONTROL_STARTASCLONE": "pan rwy'n dechrau fel clôn",
    "CONTROL_CREATECLONEOF": "creu clôn o %1",
    "CONTROL_CREATECLONEOF_MYSELF": "fi fy hun",
    "CONTROL_DELETETHISCLONE": "dileu y clôn hwn",
    "CONTROL_COUNTER": "rhifydd",
    "CONTROL_INCRCOUNTER": "rhifydd cynyddiad",
    "CONTROL_CLEARCOUNTER": "clirio'r rhifydd",
    "CONTROL_ALLATONCE": "pob un ar unwaith",
    "DATA_SETVARIABLETO": "gosod %1 i %2",
    "DATA_CHANGEVARIABLEBY": "newid %1 gan %2",
    "DATA_SHOWVARIABLE": "dangos newidyn %1",
    "DATA_HIDEVARIABLE": "cuddio newidyn %1",
    "DATA_ADDTOLIST": "ychwanegu %1 i %2",
    "DATA_DELETEOFLIST": "dileu %1 o %2",
    "DATA_DELETEALLOFLIST": "Dileu pob un o'r %1",
    "DATA_INSERTATLIST": "mewnosod %1 yn %2 o %3",
    "DATA_REPLACEITEMOFLIST": "amnewid eitem %1 o %2 gyda %3",
    "DATA_ITEMOFLIST": "eitem %1 o %2",
    "DATA_ITEMNUMOFLIST": "eitem # %1 yn %2",
    "DATA_LENGTHOFLIST": "hyd %1",
    "DATA_LISTCONTAINSITEM": "%1 yn cynnwys %2?",
    "DATA_SHOWLIST": "dangos rhestr %1",
    "DATA_HIDELIST": "cuddio rhestr %1",
    "DATA_INDEX_ALL": "y cyfan",
    "DATA_INDEX_LAST": "olaf",
    "DATA_INDEX_RANDOM": "ar hap",
    "EVENT_WHENFLAGCLICKED": "pan fydd %1 wedi ei glicio",
    "EVENT_WHENTHISSPRITECLICKED": "pan gaiff y ciplun yma ei glicio",
    "EVENT_WHENSTAGECLICKED": "pan fydd llwyfan wedi ei glicio",
    "EVENT_WHENTOUCHINGOBJECT": "pan fydd y corlun yn cyffwrdd %1",
    "EVENT_WHENBROADCASTRECEIVED": "pan rwy'n derbyn %1",
    "EVENT_WHENBACKDROPSWITCHESTO": "pan fydd cefndir yn newid i %1",
    "EVENT_WHENGREATERTHAN": "pan %1 > %2",
    "EVENT_WHENGREATERTHAN_TIMER": "amserydd",
    "EVENT_WHENGREATERTHAN_LOUDNESS": "cryfder sain",
    "EVENT_BROADCAST": "darlledu  %1",
    "EVENT_BROADCASTANDWAIT": "darlledu %1 ac aros",
    "EVENT_WHENKEYPRESSED": "pan fo bysell %1 wedi ei wasgu",
    "EVENT_WHENKEYPRESSED_SPACE": "bwlch",
    "EVENT_WHENKEYPRESSED_LEFT": "saeth chwith",
    "EVENT_WHENKEYPRESSED_RIGHT": "saeth de",
    "EVENT_WHENKEYPRESSED_DOWN": "saeth i lawr",
    "EVENT_WHENKEYPRESSED_UP": "saeth i fyny",
    "EVENT_WHENKEYPRESSED_ANY": "unrhyw",
    "LOOKS_SAYFORSECS": "dweud %1 am %2 eiliad",
    "LOOKS_SAY": "dweud %1",
    "LOOKS_HELLO": "Helo!",
    "LOOKS_THINKFORSECS": "meddwl %1 am %2 eiliad",
    "LOOKS_THINK": "meddwl %1",
    "LOOKS_HMM": "Hmm...",
    "LOOKS_SHOW": "dangos",
    "LOOKS_HIDE": "cuddio",
    "LOOKS_HIDEALLSPRITES": "cuddio pob ciplun",
    "LOOKS_EFFECT_COLOR": "lliw",
    "LOOKS_EFFECT_FISHEYE": "llygad pysgodyn",
    "LOOKS_EFFECT_WHIRL": "chwyrliad",
    "LOOKS_EFFECT_PIXELATE": "picseleiddio",
    "LOOKS_EFFECT_MOSAIC": "mosaig",
    "LOOKS_EFFECT_BRIGHTNESS": "disgleirdeb",
    "LOOKS_EFFECT_GHOST": "ysbryd",
    "LOOKS_CHANGEEFFECTBY": "newid effaith %1 gan %2",
    "LOOKS_SETEFFECTTO": "gosod effaith %1 effaith i %2",
    "LOOKS_CLEARGRAPHICEFFECTS": "clirio effeithiau graffeg",
    "LOOKS_CHANGESIZEBY": "newid maint gan %1",
    "LOOKS_SETSIZETO": "gosod maint i %1 %",
    "LOOKS_SIZE": "maint",
    "LOOKS_CHANGESTRETCHBY": "newid yr ymestyn gan %1",
    "LOOKS_SETSTRETCHTO": "gosod ymestyniad gan %1 %",
    "LOOKS_SWITCHCOSTUMETO": "newid gwisg i %1",
    "LOOKS_NEXTCOSTUME": "gwisg nesaf",
    "LOOKS_SWITCHBACKDROPTO": "newid cefndir i %1",
    "LOOKS_GOTOFRONTBACK": "mynd i haen %1",
    "LOOKS_GOTOFRONTBACK_FRONT": "blaen",
    "LOOKS_GOTOFRONTBACK_BACK": "cefn",
    "LOOKS_GOFORWARDBACKWARDLAYERS": "mynd i haenau %1 %2",
    "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "ymlaen",
    "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "nôl",
    "LOOKS_BACKDROPNUMBERNAME": "cefnlen %1",
    "LOOKS_COSTUMENUMBERNAME": "gwisg %1",
    "LOOKS_NUMBERNAME_NUMBER": "rhif",
    "LOOKS_NUMBERNAME_NAME": "enw",
    "LOOKS_SWITCHBACKDROPTOANDWAIT": "newid cefnlen i %1 ac aros",
    "LOOKS_NEXTBACKDROP_BLOCK": "cefnlen nesaf",
    "LOOKS_NEXTBACKDROP": "cefnlen nesaf",
    "LOOKS_PREVIOUSBACKDROP": "cefnlen flaenorol",
    "LOOKS_RANDOMBACKDROP": "cefnlen ar hap",
    "MOTION_MOVESTEPS": "symud %1 cam",
    "MOTION_TURNLEFT": "troi %1 %2 gradd",
    "MOTION_TURNRIGHT": "troi %1 %2 gradd",
    "MOTION_POINTINDIRECTION": "pwyntio i gyfeiriad %1",
    "MOTION_POINTTOWARDS": "pwyntio tuag at %1",
    "MOTION_POINTTOWARDS_POINTER": "pwyntydd llygoden",
    "MOTION_POINTTOWARDS_RANDOM": "cyfeiriad ar hap",
    "MOTION_GOTO": "mynd i %1",
    "MOTION_GOTO_POINTER": "pwyntydd llygoden",
    "MOTION_GOTO_RANDOM": "safle ar hap",
    "MOTION_GOTOXY": "mynd i x:%1 y: %2",
    "MOTION_GLIDESECSTOXY": "llithro %1 eiliad i x: %2 y: %3",
    "MOTION_GLIDETO": "llithro %1 eiliad i %2",
    "MOTION_GLIDETO_POINTER": "pwyntydd llygoden",
    "MOTION_GLIDETO_RANDOM": "safle ar hap",
    "MOTION_CHANGEXBY": "newid x gan %1",
    "MOTION_SETX": "gosod x i %1",
    "MOTION_CHANGEYBY": "newid y gan %1",
    "MOTION_SETY": "gosod y i %1",
    "MOTION_IFONEDGEBOUNCE": "os ar ymyl, bowndio",
    "MOTION_SETROTATIONSTYLE": "gosod steil cylchdroi %1",
    "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "chwith-dde",
    "MOTION_SETROTATIONSTYLE_DONTROTATE": "peidio troi",
    "MOTION_SETROTATIONSTYLE_ALLAROUND": "o amgylch",
    "MOTION_XPOSITION": "safle x",
    "MOTION_YPOSITION": "safle y",
    "MOTION_DIRECTION": "cyfeiriad",
    "MOTION_SCROLLRIGHT": "sgrolio i'r dde %1",
    "MOTION_SCROLLUP": "sgrolio i fyny %1",
    "MOTION_ALIGNSCENE": "alinio golygfa %1",
    "MOTION_ALIGNSCENE_BOTTOMLEFT": "gwaelod chwith",
    "MOTION_ALIGNSCENE_BOTTOMRIGHT": "gwaelod de",
    "MOTION_ALIGNSCENE_MIDDLE": "canol",
    "MOTION_ALIGNSCENE_TOPLEFT": "brig chwith",
    "MOTION_ALIGNSCENE_TOPRIGHT": "brig de",
    "MOTION_XSCROLL": "sgrolio x",
    "MOTION_YSCROLL": "sgrolio y",
    "MOTION_STAGE_SELECTED": "Llwyfan wedi ei ddewis: dim blociau symud",
    "OPERATORS_ADD": "%1 + %2",
    "OPERATORS_SUBTRACT": "%1 - %2",
    "OPERATORS_MULTIPLY": "%1 * %2",
    "OPERATORS_DIVIDE": "%1 / %2",
    "OPERATORS_RANDOM": "dewis ar hap %1 i %2",
    "OPERATORS_GT": "%1 > %2",
    "OPERATORS_GT_EQUALS": "",
    "OPERATORS_LT": "%1 < %2",
    "OPERATORS_LT_EQUALS": "",
    "OPERATORS_EQUALS": "%1 = %2",
    "OPERATORS_AND": "%1 a %2",
    "OPERATORS_OR": "%1 neu %2",
    "OPERATORS_NOT": "nid %1",
    "OPERATORS_JOIN": "uno %1 %2",
    "OPERATORS_JOIN_APPLE": "afal",
    "OPERATORS_JOIN_BANANA": "banana",
    "OPERATORS_LETTEROF": "llythyren %1 o %2",
    "OPERATORS_LETTEROF_APPLE": "a",
    "OPERATORS_LENGTH": "hyd %1",
    "OPERATORS_CONTAINS": "%1 yn cynnwys %2?",
    "OPERATORS_MOD": "%1 mod %2",
    "OPERATORS_ROUND": "talgrynnu %1",
    "OPERATORS_MATHOP": "%1 o %2",
    "OPERATORS_MATHOP_ABS": "abs",
    "OPERATORS_MATHOP_FLOOR": "llawr",
    "OPERATORS_MATHOP_CEILING": "nenfwd",
    "OPERATORS_MATHOP_SQRT": "ail isradd",
    "OPERATORS_MATHOP_SIN": "sin",
    "OPERATORS_MATHOP_COS": "cos",
    "OPERATORS_MATHOP_TAN": "tan",
    "OPERATORS_MATHOP_ASIN": "asin",
    "OPERATORS_MATHOP_ACOS": "acos",
    "OPERATORS_MATHOP_ATAN": "atan",
    "OPERATORS_MATHOP_LN": "Yn",
    "OPERATORS_MATHOP_LOG": "log",
    "OPERATORS_MATHOP_EEXP": "e ^",
    "OPERATORS_MATHOP_10EXP": "10 ^",
    "PROCEDURES_DEFINITION": "diffinio %1",
    "SENSING_TOUCHINGOBJECT": "cyffwrdd %1?",
    "SENSING_TOUCHINGOBJECT_POINTER": "pwyntydd llygoden",
    "SENSING_TOUCHINGOBJECT_EDGE": "ymyl",
    "SENSING_TOUCHINGCOLOR": "cyffwrdd lliw %1?",
    "SENSING_COLORISTOUCHINGCOLOR": "lliw %1 yn cyffwrdd %2?",
    "SENSING_DISTANCETO": "pellter i %1",
    "SENSING_DISTANCETO_POINTER": "pwyntydd llygoden",
    "SENSING_ASKANDWAIT": "gofyn %1 ac aros",
    "SENSING_ASK_TEXT": "Beth yw eich enw?",
    "SENSING_ANSWER": "ateb",
    "SENSING_KEYPRESSED": "bysell %1 wedi ei phwyso?",
    "SENSING_MOUSEDOWN": "llygoden i lawr?",
    "SENSING_MOUSEX": "llygoden x",
    "SENSING_MOUSEY": "llygoden y",
    "SENSING_SETDRAGMODE": "gosod y modd llusgo %1",
    "SENSING_SETDRAGMODE_DRAGGABLE": "llusgadwy",
    "SENSING_SETDRAGMODE_NOTDRAGGABLE": "anllusgadwy",
    "SENSING_LOUDNESS": "cryfder sain",
    "SENSING_LOUD": "sain gref?",
    "SENSING_TIMER": "amserydd",
    "SENSING_RESETTIMER": "ailosod amserydd",
    "SENSING_OF": "%1 o %2",
    "SENSING_OF_XPOSITION": "safle x",
    "SENSING_OF_YPOSITION": "safle y",
    "SENSING_OF_DIRECTION": "cyfeiriad",
    "SENSING_OF_COSTUMENUMBER": "gwisg #",
    "SENSING_OF_COSTUMENAME": "enw'r wisg",
    "SENSING_OF_SIZE": "maint",
    "SENSING_OF_VOLUME": "lefel sain",
    "SENSING_OF_BACKDROPNUMBER": "cefnlen #",
    "SENSING_OF_BACKDROPNAME": "enw cefnlen",
    "SENSING_OF_STAGE": "Llwyfan",
    "SENSING_CURRENT": "cyfredol %1",
    "SENSING_CURRENT_YEAR": "blwyddyn",
    "SENSING_CURRENT_MONTH": "mis",
    "SENSING_CURRENT_DATE": "dyddiad",
    "SENSING_CURRENT_DAYOFWEEK": "diwrnod o'r wythnos",
    "SENSING_CURRENT_HOUR": "awr",
    "SENSING_CURRENT_MINUTE": "munud",
    "SENSING_CURRENT_SECOND": "eiliad",
    "SENSING_DAYSSINCE2000": "dyddiau ers 2000",
    "SENSING_USERNAME": "enw defnyddiwr",
    "SENSING_USERID": "id defnyddiwr",
    "SOUND_PLAY": "cychwyn sain %1",
    "SOUND_PLAYUNTILDONE": "chwarae sain %1 tan y diwedd",
    "SOUND_STOPALLSOUNDS": "atal pob sain",
    "SOUND_SETEFFECTO": "gosod effaith %1 effaith i %2",
    "SOUND_CHANGEEFFECTBY": "newid effaith %1 gan %2",
    "SOUND_CLEAREFFECTS": "clirio effeithiau sain",
    "SOUND_EFFECTS_PITCH": "traw",
    "SOUND_EFFECTS_PAN": "troi chwith/de",
    "SOUND_CHANGEVOLUMEBY": "newid uchder sain gan %1",
    "SOUND_SETVOLUMETO": "gosod lefel sain i %1%",
    "SOUND_VOLUME": "lefel sain",
    "SOUND_RECORD": "recordio...",
    "CATEGORY_MOTION": "Symudiad",
    "CATEGORY_LOOKS": "Edrychiad",
    "CATEGORY_SOUND": "Sain",
    "CATEGORY_EVENTS": "Digwyddiadau",
    "CATEGORY_CONTROL": "Rheoli",
    "CATEGORY_SENSING": "Synhwyro",
    "CATEGORY_OPERATORS": "Gweithredwyr",
    "CATEGORY_VARIABLES": "Newidynnau",
    "CATEGORY_MYBLOCKS": "Fy Mlociau",
    "CATEGORY_LISTS": "",
    "CATEGORY_TUPLE": "",
    "CATEGORY_DICTIONARYS": "",
    "CATEGORY_NUMBERS": "",
    "CATEGORY_TEXT": "",
    "DUPLICATE": "Dyblygu",
    "DELETE": "Dileu",
    "ADD_COMMENT": "Ychwanegu Sylw",
    "REMOVE_COMMENT": "Tynnu Sylw",
    "DELETE_BLOCK": "Dileu Bloc",
    "DELETE_X_BLOCKS": "Dileu %1 Bloc",
    "DELETE_ALL_BLOCKS": "Dileu pob un o'r %1 bloc?",
    "CLEAN_UP": "Glanhau'r Blociau",
    "HELP": "Cymorth",
    "UNDO": "Dadwneud",
    "REDO": "Ailwneud",
    "EDIT_PROCEDURE": "Golygu",
    "SHOW_PROCEDURE_DEFINITION": "Mynd i'r diffiniad",
    "WORKSPACE_COMMENT_DEFAULT_TEXT": "Dwedwch rywbeth...",
    "COLOUR_HUE_LABEL": "Lliw",
    "COLOUR_SATURATION_LABEL": "Dirlawnder",
    "COLOUR_BRIGHTNESS_LABEL": "Disgleirdeb",
    "CHANGE_VALUE_TITLE": "Newid gwerth:",
    "RENAME_VARIABLE": "Ailenwi newidyn",
    "RENAME_VARIABLE_STRING": "",
    "RENAME_VARIABLE_NUMBER": "",
    "RENAME_VARIABLE_LIST": "",
    "RENAME_VARIABLE_TITLE": "Ailenwi pob newidyn \"%1\" i:",
    "RENAME_VARIABLE_MODAL_TITLE": "Ailenwi Newidyn",
    "NEW_VARIABLE": "Creu Newidyn",
    "NEW_VARIABLE_TITLE": "Enw newidyn newydd:",
    "VARIABLE_MODAL_TITLE": "Newidyn Newydd",
    "VARIABLE_ALREADY_EXISTS": "Mae newidyn o'r enw \"%1\" yn bodoli eisoes.",
    "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "Mae newidyn o'r enw \"%1\" yn bodoli eisoes ar gyfer math arall o newidyn \"%2\".",
    "DELETE_VARIABLE_CONFIRMATION": "Dileu'r defnydd %1 o'r newidyn \"%2\"?",
    "CANNOT_DELETE_VARIABLE_PROCEDURE": "Methu dileu newidyn \"%1\" gan ei fod yn rhan o ddiffiniad swyddogaeth \"%2\"",
    "DELETE_VARIABLE": "Dileu newidyn \"%1\"",
    "DELETE_VARIABLE_STRING": "",
    "DELETE_VARIABLE_NUMBER": "",
    "DELETE_VARIABLE_LIST": "",
    "NEW_PROCEDURE": "Creu Bloc",
    "PROCEDURE_ALREADY_EXISTS": "Mae trefn o'r enw \"%1\" yn bodoli eisoes.",
    "PROCEDURE_DEFAULT_NAME": "enw bloc",
    "NEW_LIST": "Gwneud Rhestr",
    "NEW_LIST_TITLE": "Enw rhestr newydd:",
    "LIST_MODAL_TITLE": "Rhestr Newydd",
    "LIST_ALREADY_EXISTS": "Mae rhestr o'r enw \"%1\" yn bodoli eisoes.",
    "RENAME_LIST_TITLE": "Ailenwi pob rhestr \"%1\" i:",
    "RENAME_LIST_MODAL_TITLE": "Ailenwi Rhestr",
    "DEFAULT_LIST_ITEM": "peth",
    "DELETE_LIST": "Dileu'r rhestr \"%1\" ",
    "RENAME_LIST": "Ailenwi rhestr",
    "NEW_BROADCAST_MESSAGE": "Neges newydd",
    "NEW_BROADCAST_MESSAGE_TITLE": "Enw neges newydd:",
    "BROADCAST_MODAL_TITLE": "Neges Newydd",
    "DEFAULT_BROADCAST_MESSAGE_NAME": "neges1",
    "DELETE_PROCEDURE": "",
    "OK": "",
    "PROMPT": "",
    "STOP": "",
    "NEW_STRING": "",
    "NEW_NUMBER_VARIABLE": "",
    "WIFI": "",
    "USERNAME": "",
    "PWD": "",
    "IOT_SERVICE": "",
    "IOT_ID": "",
    "IOT_PWD": "",
    "IOT_TOPIC": "",
    "MORE_TOPIC": "",
    "OBLOQ_HTTP": "",
    "OBLOQ_IP": "",
    "OBLOQ_PORT": "",
    "ADD_TOPIC": "",
    "DELETE_TOPIC": "",
    "IOT_SERVER": "",
    "CHINA": "",
    "GLOBAL": "",
    "LOW_C": "",
    "LOW_C$": "",
    "LOW_D": "",
    "LOW_D$": "",
    "LOW_E": "",
    "LOW_F": "",
    "LOW_F$": "",
    "LOW_G": "",
    "LOW_G$": "",
    "LOW_A": "",
    "LOW_A$": "",
    "LOW_B": "",
    "MIDDLE_C": "",
    "MIDDLE_C$": "",
    "MIDDLE_D": "",
    "MIDDLE_D$": "",
    "MIDDLE_E": "",
    "MIDDLE_F": "",
    "MIDDLE_F$": "",
    "MIDDLE_G": "",
    "MIDDLE_G$": "",
    "MIDDLE_A": "",
    "MIDDLE_A$": "",
    "MIDDLE_B": "",
    "HIGH_C": "",
    "HIGH_C$": "",
    "HIGH_D": "",
    "HIGH_D$": "",
    "HIGH_E": "",
    "HIGH_F": "",
    "HIGH_F$": "",
    "HIGH_G": "",
    "HIGH_G$": "",
    "HIGH_A": "",
    "HIGH_A$": "",
    "HIGH_B": "",
    "OPERATORS_STRINGTONUMBER": "",
    "INTEGER": "",
    "DECIMAL": "",
    "OPERATORS_NUMTOASCII": "",
    "OPERATORS_STRINGTOASCII": "",
    "OPERATORS_NUMTOSTRING": "",
    "OPERATORS_MAP": "",
    "OPERATORS_CONSTRAIN": "",
    "STEPS_PER_TURN": "",
    "ROTATE_SPEED": "",
    "NEW_AI": "",
    "ASK_AND_PAINT": "",
    "GET_IDENTIFIED_NUM": "",
    "READ_NUM_AND_SAY": "",
    "IMAGE_ADDR": "",
    "IMAGE_PREVIEW": "",
    "IMAGE_OPEN": "",
    "IMAGE_SIZE": "",
    "IMAGE_WIDTH": "",
    "IMAGE_HEIGHT": "",
    "VARIABLE": "",
    "VARIABLE_LIST": "",
    "SET_STRING_VARIABLE": "",
    "STRING_START_WITH": "",
    "DICTIONARY_HEIGHT_FIRST": "",
    "DICTIONARY_HEIGHT_TWO": "",
    "DICTIONARY_HEIGHT_THREE": "",
    "DICTIONARY_HEIGHT_FOUR": "",
    "DATA_MPITEMOFLIST": "",
    "DATA_MPINTERCEPTITEM": "",
    "DATA_MPINSERTATLIST": "",
    "DATA_MPSETVALUEATLIST": "",
    "DATA_MPINSERTATLIST_INSERT": "",
    "DATA_MPINSERTATLIST_CHANGE": "",
    "DATA_MPINSERTATLIST_FOUR": "",
    "DATA_MPINSERTATLIST_FIVE": "",
    "DATA_MPINSERTATLIST_SIX": "",
    "DATA_MPINSERTATLIST_SEVEN": "",
    "DATA_MPINSERTATLIST_EIGHT": "",
    "DATA_MPINSERTATLIST_EIGHT_FALSE": "",
    "DATA_MPINSERTATLIST_EIGHT_TRUE": "",
    "DATA_MPINSERTATLIST_EIGHT_REVERSE": "",
    "OPERATORS_RANDOMA": "",
    "OPERATORS_MODA": "",
    "OPERATORS_ROUNDA": "",
    "OPERATORS_MATHOPA": "",
    "OPERATORS_MATHOP_ABSA": "",
    "OPERATORS_MATHOP_FLOORA": "",
    "OPERATORS_MATHOP_CEILINGA": "",
    "OPERATORS_MATHOP_SQRTA": "",
    "OPERATORS_MATHOP_FIVE": "",
    "OPERATORS_MATHOP_SIX": "",
    "OPERATORS_MATHOP_SEVEN": "",
    "OPERATORS_MATHOP_LENGHT": "",
    "OPERATORS_MATHOP_MAXMIN": "",
    "OPERATORS_MATHOP_MAXMIN_MAX": "",
    "OPERATORS_MATHOP_MAXMIN_MIN": "",
    "OPERATORS_TEXTS_ONE": "",
    "OPERATORS_TEXTS_TWO": "",
    "OPERATORS_TEXTS_THREE": "",
    "OPERATORS_TEXTS_FOUR": "",
    "OPERATORS_TEXTS_FOUR_NEW": "",
    "OPERATORS_TEXTS_NEW": "",
    "OPERATORS_TEXTS_NEW_OP": "",
    "OPERATORS_TEXTS_FOUR_ONE": "",
    "OPERATORS_TEXTS_FOUR_TWO": "",
    "OPERATORS_TEXTS_FOUR_THREE": "",
    "OPERATORS_TEXTS_FOUR_FOUR": "",
    "OPERATORS_TEXTS_FOUR_F": "",
    "OPERATORS_TEXTS_FOUR_L": "",
    "OPERATORS_STRINGTONUMBERA": "",
    "OPERATORS_NUMTOASCIIA": "",
    "OPERATORS_STRINGTOASCIIA": "",
    "OPERATORS_NUMTOSTRINGA": "",
    "NEW_MC": "",
    "RENAME_MCNUMBER_TITLE": "",
    "RENAME_MCNUMBER_MODAL_TITLE": "",
    "RENAME_VARIABLE_MCNUMBER": "",
    "DELETE_VARIABLE_MCNUMBER": "",
    "SET_MC_VARIABLE": "",
    "TUPLE_ONE": "",
    "TUPLE_TWO": "",
    "ALIYUN": "",
    "ONENET": "",
    "EASYIOT": "",
    "SHANGHAI": "",
    "QINGDAO": "",
    "BEIJING": "",
    "ZHANGJIAKOU": "",
    "HUHEHAOTE": "",
    "HANGZHOU": "",
    "SHENZHEN": "",
    "HONGKONG": "",
    "SINGAPORE": "",
    "SYDNEY": "",
    "KUALALUMPUR": "",
    "JAKARTA": "",
    "MUMBAI": "",
    "TOKYO": "",
    "SILICONVALLEY": "",
    "VIRGINIA": "",
    "FRANKFURT": "",
    "LONDON": "",
    "DUBAI": "",
    "IOT_PLATFORM": "",
    "PARAMS": "",
    "SERVER_ATTR": "",
    "PRODUCTID": "",
    "DEVICEID": "",
    "TINYDB_SECRET": "",
    "TINYDB_USER": "",
    "APIADDR": "",
    "SOFTWARE_SERIAL": "",
    "HARDWARE_SERIAL": "",
    "HARDWARE_SERIAL1": "",
    "HARDWARE_SERIAL2": "",
    "HARDWARE_SERIAL3": ""
  },
  "da": {
    "CONTROL_FOREVER": "for evigt",
    "CONTROL_REPEAT": "gentag %1 gange",
    "CONTROL_IF": "hvis %1 så",
    "CONTROL_ELSE": "ellers",
    "CONTROL_STOP": "stop",
    "CONTROL_STOP_ALL": "alle",
    "CONTROL_STOP_THIS": "dette script",
    "CONTROL_STOP_OTHER": "andre scripts i sprite",
    "CONTROL_WAIT": "vent %1 sekunder",
    "CONTROL_WAITUNTIL": "vent indtil %1",
    "CONTROL_REPEATUNTIL": "gentag indtil %1",
    "CONTROL_WHILE": "mens %1",
    "CONTROL_FOREACH": "for hver %1 i %2",
    "CONTROL_STARTASCLONE": "når jeg starter som klon",
    "CONTROL_CREATECLONEOF": "opret en klon af %1",
    "CONTROL_CREATECLONEOF_MYSELF": "mig selv",
    "CONTROL_DELETETHISCLONE": "slet denne klon",
    "CONTROL_COUNTER": "tæller",
    "CONTROL_INCRCOUNTER": "tæl tæller op",
    "CONTROL_CLEARCOUNTER": "nulstil tæller",
    "CONTROL_ALLATONCE": "alle på en gang",
    "DATA_SETVARIABLETO": "sæt %1 til %2",
    "DATA_CHANGEVARIABLEBY": "ændre %1 med %2",
    "DATA_SHOWVARIABLE": "vis variabel %1",
    "DATA_HIDEVARIABLE": "skjul variabel %1",
    "DATA_ADDTOLIST": "tilføj %1 til %2",
    "DATA_DELETEOFLIST": "slet %1 af %2",
    "DATA_DELETEALLOFLIST": "slet alle %1",
    "DATA_INSERTATLIST": "indsæt %1 ved %2 af %3",
    "DATA_REPLACEITEMOFLIST": "erstat nummer %1 af %2 med %3",
    "DATA_ITEMOFLIST": "nummer %1 af %2",
    "DATA_ITEMNUMOFLIST": "nummer # af %1 i %2",
    "DATA_LENGTHOFLIST": "længden af %1",
    "DATA_LISTCONTAINSITEM": "%1 indeholder %2?",
    "DATA_SHOWLIST": "vis liste %1",
    "DATA_HIDELIST": "skjul liste %1",
    "DATA_INDEX_ALL": "alle",
    "DATA_INDEX_LAST": "sidste",
    "DATA_INDEX_RANDOM": "tilfældig",
    "EVENT_WHENFLAGCLICKED": "når der klikkes på %1 ",
    "EVENT_WHENTHISSPRITECLICKED": "når denne sprite klikkes",
    "EVENT_WHENSTAGECLICKED": "når scene er valgt",
    "EVENT_WHENTOUCHINGOBJECT": "når denne sprite berører %1",
    "EVENT_WHENBROADCASTRECEIVED": "når jeg modtager %1",
    "EVENT_WHENBACKDROPSWITCHESTO": "når baggrund skifter til %1",
    "EVENT_WHENGREATERTHAN": "når %1 > %2",
    "EVENT_WHENGREATERTHAN_TIMER": "stopur",
    "EVENT_WHENGREATERTHAN_LOUDNESS": "lydstyrke",
    "EVENT_BROADCAST": "send %1",
    "EVENT_BROADCASTANDWAIT": "send %1 og vent",
    "EVENT_WHENKEYPRESSED": "når du trykker på %1",
    "EVENT_WHENKEYPRESSED_SPACE": "mellemrum",
    "EVENT_WHENKEYPRESSED_LEFT": "venstre pil",
    "EVENT_WHENKEYPRESSED_RIGHT": "højre pil",
    "EVENT_WHENKEYPRESSED_DOWN": "pil nedad",
    "EVENT_WHENKEYPRESSED_UP": "pil opad",
    "EVENT_WHENKEYPRESSED_ANY": "vilkårlig",
    "LOOKS_SAYFORSECS": "sig %1 i %2 sekunder",
    "LOOKS_SAY": "sig %1",
    "LOOKS_HELLO": "Hej!",
    "LOOKS_THINKFORSECS": "tænk %1 i %2 sekunder",
    "LOOKS_THINK": "tænk %1",
    "LOOKS_HMM": "Hmm...",
    "LOOKS_SHOW": "vis",
    "LOOKS_HIDE": "skjul",
    "LOOKS_HIDEALLSPRITES": "skjul alle sprites",
    "LOOKS_EFFECT_COLOR": "farve",
    "LOOKS_EFFECT_FISHEYE": "fiskeøje",
    "LOOKS_EFFECT_WHIRL": "vrid",
    "LOOKS_EFFECT_PIXELATE": "pixeler",
    "LOOKS_EFFECT_MOSAIC": "mosaik",
    "LOOKS_EFFECT_BRIGHTNESS": "lysstyrke",
    "LOOKS_EFFECT_GHOST": "spøgelse",
    "LOOKS_CHANGEEFFECTBY": "ændre effekt %1 med %2",
    "LOOKS_SETEFFECTTO": "sæt effekt %1 til %2",
    "LOOKS_CLEARGRAPHICEFFECTS": "fjern grafiske effekter",
    "LOOKS_CHANGESIZEBY": "ændre størrelse med %1",
    "LOOKS_SETSIZETO": "sæt størrelse til %1 %",
    "LOOKS_SIZE": "størrelse",
    "LOOKS_CHANGESTRETCHBY": "ændre stræk med %1",
    "LOOKS_SETSTRETCHTO": "sæt stræk til %1 %",
    "LOOKS_SWITCHCOSTUMETO": "skift kostume til %1",
    "LOOKS_NEXTCOSTUME": "næste kostume",
    "LOOKS_SWITCHBACKDROPTO": "skift baggrund til %1",
    "LOOKS_GOTOFRONTBACK": "gå til %1 lag",
    "LOOKS_GOTOFRONTBACK_FRONT": "forreste",
    "LOOKS_GOTOFRONTBACK_BACK": "bagerste",
    "LOOKS_GOFORWARDBACKWARDLAYERS": "gå %2 lag %1",
    "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "frem",
    "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "tilbage",
    "LOOKS_BACKDROPNUMBERNAME": "baggrund %1",
    "LOOKS_COSTUMENUMBERNAME": "kostume %1",
    "LOOKS_NUMBERNAME_NUMBER": "nummer",
    "LOOKS_NUMBERNAME_NAME": "navn",
    "LOOKS_SWITCHBACKDROPTOANDWAIT": "skift baggrund til %1 og vent",
    "LOOKS_NEXTBACKDROP_BLOCK": "næste baggrund",
    "LOOKS_NEXTBACKDROP": "næste baggrund",
    "LOOKS_PREVIOUSBACKDROP": "forrige baggrund",
    "LOOKS_RANDOMBACKDROP": "tilfældig baggrund",
    "MOTION_MOVESTEPS": "gå %1 trin",
    "MOTION_TURNLEFT": "drej %1 %2 grader",
    "MOTION_TURNRIGHT": "drej %1 %2 grader",
    "MOTION_POINTINDIRECTION": "peg i retning %1",
    "MOTION_POINTTOWARDS": "peg mod %1",
    "MOTION_POINTTOWARDS_POINTER": "musepil",
    "MOTION_POINTTOWARDS_RANDOM": "tilfældig retning",
    "MOTION_GOTO": "gå til %1",
    "MOTION_GOTO_POINTER": "musepil",
    "MOTION_GOTO_RANDOM": "tilfældig position",
    "MOTION_GOTOXY": "gå til x: %1 y: %2",
    "MOTION_GLIDESECSTOXY": "glid %1 sekunder til x: %2 y: %3",
    "MOTION_GLIDETO": "glid %1 sekunder til %2",
    "MOTION_GLIDETO_POINTER": "musepil",
    "MOTION_GLIDETO_RANDOM": "tilfældig position",
    "MOTION_CHANGEXBY": "ændre x med %1",
    "MOTION_SETX": "sæt x til %1",
    "MOTION_CHANGEYBY": "ændre y med %1",
    "MOTION_SETY": "sæt y til %1",
    "MOTION_IFONEDGEBOUNCE": "hop tilbage ved kanten",
    "MOTION_SETROTATIONSTYLE": "sæt rotationsstil til %1",
    "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "venstre-højre",
    "MOTION_SETROTATIONSTYLE_DONTROTATE": "drej ikke",
    "MOTION_SETROTATIONSTYLE_ALLAROUND": "alle retninger",
    "MOTION_XPOSITION": "x position",
    "MOTION_YPOSITION": "y position",
    "MOTION_DIRECTION": "retning",
    "MOTION_SCROLLRIGHT": "rul mod højre %1",
    "MOTION_SCROLLUP": "rul op %1",
    "MOTION_ALIGNSCENE": "tilpas scene %1",
    "MOTION_ALIGNSCENE_BOTTOMLEFT": "nederst-til-venstre",
    "MOTION_ALIGNSCENE_BOTTOMRIGHT": "nederst-til-højre",
    "MOTION_ALIGNSCENE_MIDDLE": "midten",
    "MOTION_ALIGNSCENE_TOPLEFT": "øverst-til-venstre",
    "MOTION_ALIGNSCENE_TOPRIGHT": "øverst-til-højre",
    "MOTION_XSCROLL": "x rul",
    "MOTION_YSCROLL": "y rul",
    "MOTION_STAGE_SELECTED": "scene valgt: ingen bevægelses brikker",
    "OPERATORS_ADD": "%1 + %2",
    "OPERATORS_SUBTRACT": "%1 - %2",
    "OPERATORS_MULTIPLY": "%1 * %2",
    "OPERATORS_DIVIDE": "%1 / %2",
    "OPERATORS_RANDOM": "vælg tilfældigt mellem %1 og %2",
    "OPERATORS_GT": "%1 > %2",
    "OPERATORS_GT_EQUALS": "",
    "OPERATORS_LT": "%1 < %2",
    "OPERATORS_LT_EQUALS": "",
    "OPERATORS_EQUALS": "%1 = %2",
    "OPERATORS_AND": "%1 og %2",
    "OPERATORS_OR": "%1 eller %2",
    "OPERATORS_NOT": "ikke %1",
    "OPERATORS_JOIN": "%1 tilkobles %2",
    "OPERATORS_JOIN_APPLE": "æble",
    "OPERATORS_JOIN_BANANA": "banan",
    "OPERATORS_LETTEROF": "bogstav %1 af %2",
    "OPERATORS_LETTEROF_APPLE": "a",
    "OPERATORS_LENGTH": "længden af %1",
    "OPERATORS_CONTAINS": "%1 indeholder %2?",
    "OPERATORS_MOD": "%1 modulo %2",
    "OPERATORS_ROUND": "afrund %1",
    "OPERATORS_MATHOP": "%1 af %2",
    "OPERATORS_MATHOP_ABS": "abs",
    "OPERATORS_MATHOP_FLOOR": "rund ned",
    "OPERATORS_MATHOP_CEILING": "rund op",
    "OPERATORS_MATHOP_SQRT": "kvrod",
    "OPERATORS_MATHOP_SIN": "sin",
    "OPERATORS_MATHOP_COS": "cos",
    "OPERATORS_MATHOP_TAN": "tan",
    "OPERATORS_MATHOP_ASIN": "asin",
    "OPERATORS_MATHOP_ACOS": "acos",
    "OPERATORS_MATHOP_ATAN": "atan",
    "OPERATORS_MATHOP_LN": "ln",
    "OPERATORS_MATHOP_LOG": "log",
    "OPERATORS_MATHOP_EEXP": "e ^",
    "OPERATORS_MATHOP_10EXP": "10 ^",
    "PROCEDURES_DEFINITION": "definer %1",
    "SENSING_TOUCHINGOBJECT": "berører %1?",
    "SENSING_TOUCHINGOBJECT_POINTER": "musepil",
    "SENSING_TOUCHINGOBJECT_EDGE": "kant",
    "SENSING_TOUCHINGCOLOR": "berører farven %1?",
    "SENSING_COLORISTOUCHINGCOLOR": "berører %1 farven %2?",
    "SENSING_DISTANCETO": "afstand til %1",
    "SENSING_DISTANCETO_POINTER": "musepil",
    "SENSING_ASKANDWAIT": "bed om %1 og vent",
    "SENSING_ASK_TEXT": "Hvad er dit navn?",
    "SENSING_ANSWER": "svar",
    "SENSING_KEYPRESSED": "trykket på tasten %1?",
    "SENSING_MOUSEDOWN": "trykket på musen?",
    "SENSING_MOUSEX": "musens x-position",
    "SENSING_MOUSEY": "musens y-position",
    "SENSING_SETDRAGMODE": "sæt flytbarhed til %1",
    "SENSING_SETDRAGMODE_DRAGGABLE": "kan flyttes",
    "SENSING_SETDRAGMODE_NOTDRAGGABLE": "kan ikke flyttes",
    "SENSING_LOUDNESS": "lydstyrke",
    "SENSING_LOUD": "højt?",
    "SENSING_TIMER": "stopur",
    "SENSING_RESETTIMER": "nulstil stopur",
    "SENSING_OF": "%1 af %2",
    "SENSING_OF_XPOSITION": "x position",
    "SENSING_OF_YPOSITION": "y position",
    "SENSING_OF_DIRECTION": "retning",
    "SENSING_OF_COSTUMENUMBER": "kostume #",
    "SENSING_OF_COSTUMENAME": "navn på kostume",
    "SENSING_OF_SIZE": "størrelse",
    "SENSING_OF_VOLUME": "lydstyrke",
    "SENSING_OF_BACKDROPNUMBER": "baggrund #",
    "SENSING_OF_BACKDROPNAME": "navn på baggrund",
    "SENSING_OF_STAGE": "Scene",
    "SENSING_CURRENT": "nuværende %1",
    "SENSING_CURRENT_YEAR": "år",
    "SENSING_CURRENT_MONTH": "måned",
    "SENSING_CURRENT_DATE": "dato",
    "SENSING_CURRENT_DAYOFWEEK": "ugedag",
    "SENSING_CURRENT_HOUR": "time",
    "SENSING_CURRENT_MINUTE": "minut",
    "SENSING_CURRENT_SECOND": "sekund",
    "SENSING_DAYSSINCE2000": "dage siden 2000",
    "SENSING_USERNAME": "brugernavn",
    "SENSING_USERID": "bruger id",
    "SOUND_PLAY": "spil lyden %1",
    "SOUND_PLAYUNTILDONE": "spil lyden %1 indtil færdig",
    "SOUND_STOPALLSOUNDS": "stop al lyd",
    "SOUND_SETEFFECTO": "sæt effekt %1 til %2",
    "SOUND_CHANGEEFFECTBY": "ændre effekt %1 med %2",
    "SOUND_CLEAREFFECTS": "fjern lydeffekter",
    "SOUND_EFFECTS_PITCH": "tonehøjde",
    "SOUND_EFFECTS_PAN": "balance venstre/højre",
    "SOUND_CHANGEVOLUMEBY": "ændre lydstyrke med %1",
    "SOUND_SETVOLUMETO": "sæt lydstyrke til %1%",
    "SOUND_VOLUME": "lydstyrke",
    "SOUND_RECORD": "optag...",
    "CATEGORY_MOTION": "Bevægelse",
    "CATEGORY_LOOKS": "Udseende",
    "CATEGORY_SOUND": "Lyd",
    "CATEGORY_EVENTS": "Hændelser",
    "CATEGORY_CONTROL": "Kontrol",
    "CATEGORY_SENSING": "Registrering",
    "CATEGORY_OPERATORS": "Operatorer",
    "CATEGORY_VARIABLES": "Variabler",
    "CATEGORY_MYBLOCKS": "Mine brikker",
    "CATEGORY_LISTS": "",
    "CATEGORY_TUPLE": "",
    "CATEGORY_DICTIONARYS": "",
    "CATEGORY_NUMBERS": "",
    "CATEGORY_TEXT": "",
    "DUPLICATE": "Kopiér",
    "DELETE": "Slet",
    "ADD_COMMENT": "Tilføj kommentar",
    "REMOVE_COMMENT": "Slet kommentar",
    "DELETE_BLOCK": "Slet brik",
    "DELETE_X_BLOCKS": "Slet %1 brikker",
    "DELETE_ALL_BLOCKS": "Slet alle %1 brikker?",
    "CLEAN_UP": "Ryd brikkerne op",
    "HELP": "Hjælp",
    "UNDO": "Fortryd",
    "REDO": "Gør om",
    "EDIT_PROCEDURE": "Redigér",
    "SHOW_PROCEDURE_DEFINITION": "Gå til forklaring",
    "WORKSPACE_COMMENT_DEFAULT_TEXT": "Sig noget...",
    "COLOUR_HUE_LABEL": "Farve",
    "COLOUR_SATURATION_LABEL": "Farvemætning",
    "COLOUR_BRIGHTNESS_LABEL": "Lysstyrke",
    "CHANGE_VALUE_TITLE": "Ændre værdien:",
    "RENAME_VARIABLE": "Omdøb variabel",
    "RENAME_VARIABLE_STRING": "",
    "RENAME_VARIABLE_NUMBER": "",
    "RENAME_VARIABLE_LIST": "",
    "RENAME_VARIABLE_TITLE": "Omdøb alle \"%1\" variabler til:",
    "RENAME_VARIABLE_MODAL_TITLE": "Omdøb variabel",
    "NEW_VARIABLE": "Lav en variabel",
    "NEW_VARIABLE_TITLE": "Navn på ny variabel:",
    "VARIABLE_MODAL_TITLE": "Ny variabel",
    "VARIABLE_ALREADY_EXISTS": "Der eksisterer allerede en variabel med navnet '%1'.",
    "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "Der eksisterer allerede en variabel med navnet \"%1\"  for en anden variabel af typen \"%2\".",
    "DELETE_VARIABLE_CONFIRMATION": "Slet %1 brugen af denne \"%2\" variabel?",
    "CANNOT_DELETE_VARIABLE_PROCEDURE": "Kan ikke slette variablen \"%1\" fordi at den er en del af forklaringen til funktionen \"%2\"",
    "DELETE_VARIABLE": "Slet \"%1\" variablen",
    "DELETE_VARIABLE_STRING": "",
    "DELETE_VARIABLE_NUMBER": "",
    "DELETE_VARIABLE_LIST": "",
    "NEW_PROCEDURE": "Lav en brik",
    "PROCEDURE_ALREADY_EXISTS": "Der eksisterer allerede en procedure med navnet \"%1\".",
    "PROCEDURE_DEFAULT_NAME": "brikkens navn",
    "NEW_LIST": "Lav en liste",
    "NEW_LIST_TITLE": "Navn på ny liste:",
    "LIST_MODAL_TITLE": "Ny liste",
    "LIST_ALREADY_EXISTS": "Der eksisterer allerede en liste med navnet \"%1\".",
    "RENAME_LIST_TITLE": "Omdøb alle \"%1\" lister til:",
    "RENAME_LIST_MODAL_TITLE": "Omdøb liste",
    "DEFAULT_LIST_ITEM": "ting",
    "DELETE_LIST": "Slet \"%1\" listen",
    "RENAME_LIST": "Omdøb liste",
    "NEW_BROADCAST_MESSAGE": "Ny besked",
    "NEW_BROADCAST_MESSAGE_TITLE": "Navn på ny besked:",
    "BROADCAST_MODAL_TITLE": "Ny besked",
    "DEFAULT_BROADCAST_MESSAGE_NAME": "besked1",
    "DELETE_PROCEDURE": "",
    "OK": "",
    "PROMPT": "",
    "STOP": "",
    "NEW_STRING": "",
    "NEW_NUMBER_VARIABLE": "",
    "WIFI": "",
    "USERNAME": "",
    "PWD": "",
    "IOT_SERVICE": "",
    "IOT_ID": "",
    "IOT_PWD": "",
    "IOT_TOPIC": "",
    "MORE_TOPIC": "",
    "OBLOQ_HTTP": "",
    "OBLOQ_IP": "",
    "OBLOQ_PORT": "",
    "ADD_TOPIC": "",
    "DELETE_TOPIC": "",
    "IOT_SERVER": "",
    "CHINA": "",
    "GLOBAL": "",
    "LOW_C": "",
    "LOW_C$": "",
    "LOW_D": "",
    "LOW_D$": "",
    "LOW_E": "",
    "LOW_F": "",
    "LOW_F$": "",
    "LOW_G": "",
    "LOW_G$": "",
    "LOW_A": "",
    "LOW_A$": "",
    "LOW_B": "",
    "MIDDLE_C": "",
    "MIDDLE_C$": "",
    "MIDDLE_D": "",
    "MIDDLE_D$": "",
    "MIDDLE_E": "",
    "MIDDLE_F": "",
    "MIDDLE_F$": "",
    "MIDDLE_G": "",
    "MIDDLE_G$": "",
    "MIDDLE_A": "",
    "MIDDLE_A$": "",
    "MIDDLE_B": "",
    "HIGH_C": "",
    "HIGH_C$": "",
    "HIGH_D": "",
    "HIGH_D$": "",
    "HIGH_E": "",
    "HIGH_F": "",
    "HIGH_F$": "",
    "HIGH_G": "",
    "HIGH_G$": "",
    "HIGH_A": "",
    "HIGH_A$": "",
    "HIGH_B": "",
    "OPERATORS_STRINGTONUMBER": "",
    "INTEGER": "",
    "DECIMAL": "",
    "OPERATORS_NUMTOASCII": "",
    "OPERATORS_STRINGTOASCII": "",
    "OPERATORS_NUMTOSTRING": "",
    "OPERATORS_MAP": "",
    "OPERATORS_CONSTRAIN": "",
    "STEPS_PER_TURN": "",
    "ROTATE_SPEED": "",
    "NEW_AI": "",
    "ASK_AND_PAINT": "",
    "GET_IDENTIFIED_NUM": "",
    "READ_NUM_AND_SAY": "",
    "IMAGE_ADDR": "",
    "IMAGE_PREVIEW": "",
    "IMAGE_OPEN": "",
    "IMAGE_SIZE": "",
    "IMAGE_WIDTH": "",
    "IMAGE_HEIGHT": "",
    "VARIABLE": "",
    "VARIABLE_LIST": "",
    "SET_STRING_VARIABLE": "",
    "STRING_START_WITH": "",
    "DICTIONARY_HEIGHT_FIRST": "",
    "DICTIONARY_HEIGHT_TWO": "",
    "DICTIONARY_HEIGHT_THREE": "",
    "DICTIONARY_HEIGHT_FOUR": "",
    "DATA_MPITEMOFLIST": "",
    "DATA_MPINTERCEPTITEM": "",
    "DATA_MPINSERTATLIST": "",
    "DATA_MPSETVALUEATLIST": "",
    "DATA_MPINSERTATLIST_INSERT": "",
    "DATA_MPINSERTATLIST_CHANGE": "",
    "DATA_MPINSERTATLIST_FOUR": "",
    "DATA_MPINSERTATLIST_FIVE": "",
    "DATA_MPINSERTATLIST_SIX": "",
    "DATA_MPINSERTATLIST_SEVEN": "",
    "DATA_MPINSERTATLIST_EIGHT": "",
    "DATA_MPINSERTATLIST_EIGHT_FALSE": "",
    "DATA_MPINSERTATLIST_EIGHT_TRUE": "",
    "DATA_MPINSERTATLIST_EIGHT_REVERSE": "",
    "OPERATORS_RANDOMA": "",
    "OPERATORS_MODA": "",
    "OPERATORS_ROUNDA": "",
    "OPERATORS_MATHOPA": "",
    "OPERATORS_MATHOP_ABSA": "",
    "OPERATORS_MATHOP_FLOORA": "",
    "OPERATORS_MATHOP_CEILINGA": "",
    "OPERATORS_MATHOP_SQRTA": "",
    "OPERATORS_MATHOP_FIVE": "",
    "OPERATORS_MATHOP_SIX": "",
    "OPERATORS_MATHOP_SEVEN": "",
    "OPERATORS_MATHOP_LENGHT": "",
    "OPERATORS_MATHOP_MAXMIN": "",
    "OPERATORS_MATHOP_MAXMIN_MAX": "",
    "OPERATORS_MATHOP_MAXMIN_MIN": "",
    "OPERATORS_TEXTS_ONE": "",
    "OPERATORS_TEXTS_TWO": "",
    "OPERATORS_TEXTS_THREE": "",
    "OPERATORS_TEXTS_FOUR": "",
    "OPERATORS_TEXTS_FOUR_NEW": "",
    "OPERATORS_TEXTS_NEW": "",
    "OPERATORS_TEXTS_NEW_OP": "",
    "OPERATORS_TEXTS_FOUR_ONE": "",
    "OPERATORS_TEXTS_FOUR_TWO": "",
    "OPERATORS_TEXTS_FOUR_THREE": "",
    "OPERATORS_TEXTS_FOUR_FOUR": "",
    "OPERATORS_TEXTS_FOUR_F": "",
    "OPERATORS_TEXTS_FOUR_L": "",
    "OPERATORS_STRINGTONUMBERA": "",
    "OPERATORS_NUMTOASCIIA": "",
    "OPERATORS_STRINGTOASCIIA": "",
    "OPERATORS_NUMTOSTRINGA": "",
    "NEW_MC": "",
    "RENAME_MCNUMBER_TITLE": "",
    "RENAME_MCNUMBER_MODAL_TITLE": "",
    "RENAME_VARIABLE_MCNUMBER": "",
    "DELETE_VARIABLE_MCNUMBER": "",
    "SET_MC_VARIABLE": "",
    "TUPLE_ONE": "",
    "TUPLE_TWO": "",
    "ALIYUN": "",
    "ONENET": "",
    "EASYIOT": "",
    "SHANGHAI": "",
    "QINGDAO": "",
    "BEIJING": "",
    "ZHANGJIAKOU": "",
    "HUHEHAOTE": "",
    "HANGZHOU": "",
    "SHENZHEN": "",
    "HONGKONG": "",
    "SINGAPORE": "",
    "SYDNEY": "",
    "KUALALUMPUR": "",
    "JAKARTA": "",
    "MUMBAI": "",
    "TOKYO": "",
    "SILICONVALLEY": "",
    "VIRGINIA": "",
    "FRANKFURT": "",
    "LONDON": "",
    "DUBAI": "",
    "IOT_PLATFORM": "",
    "PARAMS": "",
    "SERVER_ATTR": "",
    "PRODUCTID": "",
    "DEVICEID": "",
    "TINYDB_SECRET": "",
    "TINYDB_USER": "",
    "APIADDR": "",
    "SOFTWARE_SERIAL": "",
    "HARDWARE_SERIAL": "",
    "HARDWARE_SERIAL1": "",
    "HARDWARE_SERIAL2": "",
    "HARDWARE_SERIAL3": ""
  },
  "de": {
    "CONTROL_FOREVER": "wiederhole fortlaufend",
    "CONTROL_REPEAT": "wiederhole %1 mal",
    "CONTROL_IF": "falls %1, dann",
    "CONTROL_ELSE": "sonst",
    "CONTROL_STOP": "stoppe",
    "CONTROL_STOP_ALL": "alles",
    "CONTROL_STOP_THIS": "dieses Skript",
    "CONTROL_STOP_OTHER": "andere Skripte der Figur",
    "CONTROL_WAIT": "warte %1 Sekunden",
    "CONTROL_WAITUNTIL": "warte bis %1",
    "CONTROL_REPEATUNTIL": "wiederhole bis %1",
    "CONTROL_WHILE": "wiederhole solange %1",
    "CONTROL_FOREACH": "wiederhole für jedes %1 in %2",
    "CONTROL_STARTASCLONE": "Wenn ich als Klon entstehe",
    "CONTROL_CREATECLONEOF": "erzeuge Klon von %1",
    "CONTROL_CREATECLONEOF_MYSELF": "mir selbst",
    "CONTROL_DELETETHISCLONE": "lösche diesen Klon",
    "CONTROL_COUNTER": "Zähler",
    "CONTROL_INCRCOUNTER": "erhöhe Zähler",
    "CONTROL_CLEARCOUNTER": "lösche Zähler",
    "CONTROL_ALLATONCE": "Alle gleichzeitig",
    "DATA_SETVARIABLETO": "setze %1 auf %2",
    "DATA_CHANGEVARIABLEBY": "ändere %1 um %2",
    "DATA_SHOWVARIABLE": "zeige Variable %1",
    "DATA_HIDEVARIABLE": "verstecke Variable %1",
    "DATA_ADDTOLIST": "füge %1 zu %2 hinzu",
    "DATA_DELETEOFLIST": "lösche %1 aus %2",
    "DATA_DELETEALLOFLIST": "lösche alles aus %1",
    "DATA_INSERTATLIST": "füge %1 bei %2 in %3 ein",
    "DATA_REPLACEITEMOFLIST": "ersetze Element %1 von %2 durch %3",
    "DATA_ITEMOFLIST": "Element %1 von %2",
    "DATA_ITEMNUMOFLIST": "Nummer von %1 in %2",
    "DATA_LENGTHOFLIST": "Länge von %1",
    "DATA_LISTCONTAINSITEM": "%1 enthält %2?",
    "DATA_SHOWLIST": "zeige Liste %1",
    "DATA_HIDELIST": "verstecke Liste %1",
    "DATA_INDEX_ALL": "alles",
    "DATA_INDEX_LAST": "letztes",
    "DATA_INDEX_RANDOM": "zufälliges",
    "EVENT_WHENFLAGCLICKED": "Wenn %1 angeklickt wird",
    "EVENT_WHENTHISSPRITECLICKED": "Wenn diese Figur angeklickt wird",
    "EVENT_WHENSTAGECLICKED": "Wenn die Bühne angeklickt wird",
    "EVENT_WHENTOUCHINGOBJECT": "Wenn diese Figur %1 berührt",
    "EVENT_WHENBROADCASTRECEIVED": "Wenn ich %1 empfange",
    "EVENT_WHENBACKDROPSWITCHESTO": "Wenn das Bühnenbild zu %1 wechselt",
    "EVENT_WHENGREATERTHAN": "Wenn %1 > %2",
    "EVENT_WHENGREATERTHAN_TIMER": "Stoppuhr",
    "EVENT_WHENGREATERTHAN_LOUDNESS": "Lautstärke",
    "EVENT_BROADCAST": "sende %1 an alle",
    "EVENT_BROADCASTANDWAIT": "sende %1 an alle und warte",
    "EVENT_WHENKEYPRESSED": "Wenn Taste %1 gedrückt wird",
    "EVENT_WHENKEYPRESSED_SPACE": "Leertaste",
    "EVENT_WHENKEYPRESSED_LEFT": "Pfeil nach links",
    "EVENT_WHENKEYPRESSED_RIGHT": "Pfeil nach rechts",
    "EVENT_WHENKEYPRESSED_DOWN": "Pfeil nach unten",
    "EVENT_WHENKEYPRESSED_UP": "Pfeil nach oben",
    "EVENT_WHENKEYPRESSED_ANY": "beliebiges",
    "LOOKS_SAYFORSECS": "sage %1 für %2 Sekunden",
    "LOOKS_SAY": "sage %1",
    "LOOKS_HELLO": "Hallo!",
    "LOOKS_THINKFORSECS": "denke %1 für %2 Sekunden",
    "LOOKS_THINK": "denke %1",
    "LOOKS_HMM": "Hmm...",
    "LOOKS_SHOW": "zeige dich",
    "LOOKS_HIDE": "verstecke dich",
    "LOOKS_HIDEALLSPRITES": "Alle Figuren verbergen",
    "LOOKS_EFFECT_COLOR": "Farbe",
    "LOOKS_EFFECT_FISHEYE": "Fischauge",
    "LOOKS_EFFECT_WHIRL": "Wirbel",
    "LOOKS_EFFECT_PIXELATE": "Pixel",
    "LOOKS_EFFECT_MOSAIC": "Mosaik",
    "LOOKS_EFFECT_BRIGHTNESS": "Helligkeit",
    "LOOKS_EFFECT_GHOST": "Durchsichtigkeit",
    "LOOKS_CHANGEEFFECTBY": "ändere Effekt %1 um %2",
    "LOOKS_SETEFFECTTO": "setze Effekt %1 auf %2",
    "LOOKS_CLEARGRAPHICEFFECTS": "schalte Grafikeffekte aus",
    "LOOKS_CHANGESIZEBY": "ändere Größe um %1",
    "LOOKS_SETSIZETO": "setze Größe auf %1",
    "LOOKS_SIZE": "Größe",
    "LOOKS_CHANGESTRETCHBY": "ändere Dehnung um %1",
    "LOOKS_SETSTRETCHTO": "setze Dehnung auf %1 %",
    "LOOKS_SWITCHCOSTUMETO": "wechsle zu Kostüm %1",
    "LOOKS_NEXTCOSTUME": "wechsle zum nächsten Kostüm",
    "LOOKS_SWITCHBACKDROPTO": "wechsle zu Bühnenbild %1",
    "LOOKS_GOTOFRONTBACK": "gehe zu %1 Ebene",
    "LOOKS_GOTOFRONTBACK_FRONT": "vorderster",
    "LOOKS_GOTOFRONTBACK_BACK": "hinterster",
    "LOOKS_GOFORWARDBACKWARDLAYERS": "gehe %2 Ebenen %1",
    "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "nach vorne",
    "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "nach hinten",
    "LOOKS_BACKDROPNUMBERNAME": "Bühnenbild %1",
    "LOOKS_COSTUMENUMBERNAME": "Kostüm %1",
    "LOOKS_NUMBERNAME_NUMBER": "Nummer",
    "LOOKS_NUMBERNAME_NAME": "Name",
    "LOOKS_SWITCHBACKDROPTOANDWAIT": "wechsle zu Bühnenbild %1 und warte",
    "LOOKS_NEXTBACKDROP_BLOCK": "wechsle zum nächsten Bühnenbild",
    "LOOKS_NEXTBACKDROP": "nächstes Bühnenbild",
    "LOOKS_PREVIOUSBACKDROP": "vorheriges Bühnenbild",
    "LOOKS_RANDOMBACKDROP": "zufälliges Bühnenbild",
    "MOTION_MOVESTEPS": "gehe %1 er Schritt",
    "MOTION_TURNLEFT": "drehe dich %1 um %2 Grad",
    "MOTION_TURNRIGHT": "drehe dich %1 um %2 Grad",
    "MOTION_POINTINDIRECTION": "setze Richtung auf %1 Grad",
    "MOTION_POINTTOWARDS": "drehe dich zu %1",
    "MOTION_POINTTOWARDS_POINTER": "Mauszeiger",
    "MOTION_POINTTOWARDS_RANDOM": "Zufallsrichtung",
    "MOTION_GOTO": "gehe zu %1",
    "MOTION_GOTO_POINTER": "Mauszeiger",
    "MOTION_GOTO_RANDOM": "Zufallsposition",
    "MOTION_GOTOXY": "gehe zu x: %1 y: %2",
    "MOTION_GLIDESECSTOXY": "gleite in %1 Sek. zu x:%2  y:%3",
    "MOTION_GLIDETO": "gleite in %1 Sek. zu %2",
    "MOTION_GLIDETO_POINTER": "Mauszeiger",
    "MOTION_GLIDETO_RANDOM": "Zufallsposition",
    "MOTION_CHANGEXBY": "ändere x um %1",
    "MOTION_SETX": "setze x auf %1",
    "MOTION_CHANGEYBY": "ändere y um %1",
    "MOTION_SETY": "setze y auf %1",
    "MOTION_IFONEDGEBOUNCE": "pralle vom Rand ab",
    "MOTION_SETROTATIONSTYLE": "setze Drehtyp auf %1",
    "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "links-rechts",
    "MOTION_SETROTATIONSTYLE_DONTROTATE": "nicht drehen",
    "MOTION_SETROTATIONSTYLE_ALLAROUND": "rundherum",
    "MOTION_XPOSITION": "x-Position",
    "MOTION_YPOSITION": "y-Position",
    "MOTION_DIRECTION": "Richtung",
    "MOTION_SCROLLRIGHT": "%1 nach rechts bewegen",
    "MOTION_SCROLLUP": "%1 nach oben bewegen",
    "MOTION_ALIGNSCENE": "Szene ausrichten %1",
    "MOTION_ALIGNSCENE_BOTTOMLEFT": "unten links",
    "MOTION_ALIGNSCENE_BOTTOMRIGHT": "unten rechts",
    "MOTION_ALIGNSCENE_MIDDLE": "Mitte",
    "MOTION_ALIGNSCENE_TOPLEFT": "oben links",
    "MOTION_ALIGNSCENE_TOPRIGHT": "oben rechts",
    "MOTION_XSCROLL": "x-Bewegung",
    "MOTION_YSCROLL": "y-Bewegung",
    "MOTION_STAGE_SELECTED": "Bühne ausgewählt: keine Bewegungsblöcke",
    "OPERATORS_ADD": "%1 + %2",
    "OPERATORS_SUBTRACT": "%1 - %2",
    "OPERATORS_MULTIPLY": "%1 * %2",
    "OPERATORS_DIVIDE": "%1 / %2",
    "OPERATORS_RANDOM": "Zufallszahl von %1 bis %2",
    "OPERATORS_GT": "%1 > %2",
    "OPERATORS_GT_EQUALS": "%1 >= %2",
    "OPERATORS_LT": "%1 < %2",
    "OPERATORS_LT_EQUALS": "%1 <= %2",
    "OPERATORS_EQUALS": "%1 = %2",
    "OPERATORS_AND": "%1 und %2",
    "OPERATORS_OR": "%1 oder %2",
    "OPERATORS_NOT": "nicht %1",
    "OPERATORS_JOIN": "verbinde %1 und %2",
    "OPERATORS_JOIN_APPLE": "Apfel",
    "OPERATORS_JOIN_BANANA": "Banane",
    "OPERATORS_LETTEROF": "Zeichen %1 von %2",
    "OPERATORS_LETTEROF_APPLE": "a",
    "OPERATORS_LENGTH": "Länge von %1",
    "OPERATORS_CONTAINS": "%1 enthält %2?",
    "OPERATORS_MOD": "%1 mod %2",
    "OPERATORS_ROUND": "%1 gerundet",
    "OPERATORS_MATHOP": "%1 von %2",
    "OPERATORS_MATHOP_ABS": "Betrag",
    "OPERATORS_MATHOP_FLOOR": "abrunden",
    "OPERATORS_MATHOP_CEILING": "aufrunden",
    "OPERATORS_MATHOP_SQRT": "Wurzel",
    "OPERATORS_MATHOP_SIN": "sin",
    "OPERATORS_MATHOP_COS": "cos",
    "OPERATORS_MATHOP_TAN": "tan",
    "OPERATORS_MATHOP_ASIN": "asin",
    "OPERATORS_MATHOP_ACOS": "acos",
    "OPERATORS_MATHOP_ATAN": "atan",
    "OPERATORS_MATHOP_LN": "ln",
    "OPERATORS_MATHOP_LOG": "log",
    "OPERATORS_MATHOP_EEXP": "e^",
    "OPERATORS_MATHOP_10EXP": "10^",
    "PROCEDURES_DEFINITION": "Definiere %1",
    "SENSING_TOUCHINGOBJECT": "wird %1 berührt?",
    "SENSING_TOUCHINGOBJECT_POINTER": "Mauszeiger",
    "SENSING_TOUCHINGOBJECT_EDGE": "Rand",
    "SENSING_TOUCHINGCOLOR": "wird Farbe %1 berührt?",
    "SENSING_COLORISTOUCHINGCOLOR": "Farbe %1 berührt %2?",
    "SENSING_DISTANCETO": "Entfernung von %1",
    "SENSING_DISTANCETO_POINTER": "Mauszeiger",
    "SENSING_ASKANDWAIT": "frage %1 und warte",
    "SENSING_ASK_TEXT": "Wie heißt du?",
    "SENSING_ANSWER": "Antwort",
    "SENSING_KEYPRESSED": "Taste %1 gedrückt?",
    "SENSING_MOUSEDOWN": "Maustaste gedrückt?",
    "SENSING_MOUSEX": "Maus x-Position",
    "SENSING_MOUSEY": "Maus y-Position",
    "SENSING_SETDRAGMODE": "setze Ziehbarkeit auf %1",
    "SENSING_SETDRAGMODE_DRAGGABLE": "ziehbar",
    "SENSING_SETDRAGMODE_NOTDRAGGABLE": "nicht ziehbar",
    "SENSING_LOUDNESS": "Lautstärke",
    "SENSING_LOUD": "laut?",
    "SENSING_TIMER": "Stoppuhr",
    "SENSING_RESETTIMER": "setze Stoppuhr zurück",
    "SENSING_OF": "%1 von %2",
    "SENSING_OF_XPOSITION": "x-Position",
    "SENSING_OF_YPOSITION": "y-Position",
    "SENSING_OF_DIRECTION": "Richtung",
    "SENSING_OF_COSTUMENUMBER": "Kostümnummer",
    "SENSING_OF_COSTUMENAME": "Kostümname",
    "SENSING_OF_SIZE": "Größe",
    "SENSING_OF_VOLUME": "Lautstärke",
    "SENSING_OF_BACKDROPNUMBER": "Bühnenbildnummer",
    "SENSING_OF_BACKDROPNAME": "Bühnenbildname",
    "SENSING_OF_STAGE": "Bühne",
    "SENSING_CURRENT": "%1 im Moment",
    "SENSING_CURRENT_YEAR": "Jahr",
    "SENSING_CURRENT_MONTH": "Monat",
    "SENSING_CURRENT_DATE": "Datum",
    "SENSING_CURRENT_DAYOFWEEK": "Wochentag",
    "SENSING_CURRENT_HOUR": "Stunde",
    "SENSING_CURRENT_MINUTE": "Minute",
    "SENSING_CURRENT_SECOND": "Sekunde",
    "SENSING_DAYSSINCE2000": "Tage seit 2000",
    "SENSING_USERNAME": "Benutzername",
    "SENSING_USERID": "Benutzer-ID",
    "SOUND_PLAY": "spiele Klang %1",
    "SOUND_PLAYUNTILDONE": "spiele Klang %1 ganz",
    "SOUND_STOPALLSOUNDS": "stoppe alle Klänge",
    "SOUND_SETEFFECTO": "setze Effekt %1 auf %2",
    "SOUND_CHANGEEFFECTBY": "ändere Effekt %1 um %2",
    "SOUND_CLEAREFFECTS": "schalte Klangeffekte aus",
    "SOUND_EFFECTS_PITCH": "Höhe",
    "SOUND_EFFECTS_PAN": "Aussteuern links/rechts",
    "SOUND_CHANGEVOLUMEBY": "ändere Lautstärke um %1",
    "SOUND_SETVOLUMETO": "setze Lautstärke auf %1%",
    "SOUND_VOLUME": "Lautstärke",
    "SOUND_RECORD": "zeichne auf...",
    "CATEGORY_MOTION": "Bewegung",
    "CATEGORY_LOOKS": "Aussehen",
    "CATEGORY_SOUND": "Klang",
    "CATEGORY_EVENTS": "Ereignisse",
    "CATEGORY_CONTROL": "Steuerung",
    "CATEGORY_SENSING": "Fühlen",
    "CATEGORY_OPERATORS": "Operatoren",
    "CATEGORY_VARIABLES": "Variablen",
    "CATEGORY_MYBLOCKS": "Meine Blöcke",
    "CATEGORY_LISTS": "",
    "CATEGORY_TUPLE": "",
    "CATEGORY_DICTIONARYS": "",
    "CATEGORY_NUMBERS": "Zahl",
    "CATEGORY_TEXT": "Text",
    "DUPLICATE": "Duplizieren",
    "DELETE": "Löschen",
    "ADD_COMMENT": "Kommentar hinzufügen",
    "REMOVE_COMMENT": "Kommentar entfernen",
    "DELETE_BLOCK": "Lösche Block",
    "DELETE_X_BLOCKS": "Lösche %1 Blöcke",
    "DELETE_ALL_BLOCKS": "Alle %1 Blöcke löschen?",
    "CLEAN_UP": "Blöcke aufräumen",
    "HELP": "Hilfe",
    "UNDO": "Rückgängig",
    "REDO": "Wiederherstellen",
    "EDIT_PROCEDURE": "Bearbeiten",
    "SHOW_PROCEDURE_DEFINITION": "Gehe zur Definition",
    "WORKSPACE_COMMENT_DEFAULT_TEXT": "Sag etwas...",
    "COLOUR_HUE_LABEL": "Farbe",
    "COLOUR_SATURATION_LABEL": "Sättigung",
    "COLOUR_BRIGHTNESS_LABEL": "Helligkeit",
    "CHANGE_VALUE_TITLE": "Ändere Wert",
    "RENAME_VARIABLE": "Benenne die Variable um",
    "RENAME_VARIABLE_STRING": "String-Variable umbenennen",
    "RENAME_VARIABLE_NUMBER": "Numerische Variable umbenennen",
    "RENAME_VARIABLE_LIST": "Listenvariable umbenennen",
    "RENAME_VARIABLE_TITLE": "Benenne alle Variablen mit Namen \"%1\" um in:",
    "RENAME_VARIABLE_MODAL_TITLE": "Variable umbenennen",
    "NEW_VARIABLE": "Neue Variable",
    "NEW_VARIABLE_TITLE": "Neue Variable Name:",
    "VARIABLE_MODAL_TITLE": "Neue Variable",
    "VARIABLE_ALREADY_EXISTS": "Eine Variable \"%1\" existiert bereits.",
    "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "Eine Variable mit dem Namen \"%1\" existiert bereits als Variable des Typs \"%2\".",
    "DELETE_VARIABLE_CONFIRMATION": "Entferne alle %1 Nutzungen der Variable \"%2\"?",
    "CANNOT_DELETE_VARIABLE_PROCEDURE": "Die Variable \"%1\" kann nicht gelöscht werden, weil sie Teil der Definition der Funktion \"%2\" ist.",
    "DELETE_VARIABLE": "Lösche die Variable \"%1\"",
    "DELETE_VARIABLE_STRING": "Lösche die String-Variable「%1」",
    "DELETE_VARIABLE_NUMBER": "Lösche die numerische Variable 「%1」",
    "DELETE_VARIABLE_LIST": "Lösche die 「%1」  Listenvariable",
    "NEW_PROCEDURE": "Neuer Block",
    "PROCEDURE_ALREADY_EXISTS": "Eine Prozedur mit dem Namen \"%1\" existiert bereits.",
    "PROCEDURE_DEFAULT_NAME": "Blockname",
    "NEW_LIST": "Neue Liste",
    "NEW_LIST_TITLE": "Neuer Listen Name:",
    "LIST_MODAL_TITLE": "Neue Liste",
    "LIST_ALREADY_EXISTS": "Eine Liste mit dem Namen \"%1\" existiert bereits",
    "RENAME_LIST_TITLE": "Benenne alle Listen mit Namen \"%1\" um in:",
    "RENAME_LIST_MODAL_TITLE": "Liste umbenennen",
    "DEFAULT_LIST_ITEM": "Ding",
    "DELETE_LIST": "Lösche die Liste \"%1\"",
    "RENAME_LIST": "Benenne die Liste um",
    "NEW_BROADCAST_MESSAGE": "Neue Nachricht",
    "NEW_BROADCAST_MESSAGE_TITLE": "Neue Nachricht name:",
    "BROADCAST_MODAL_TITLE": "Neue Nachricht",
    "DEFAULT_BROADCAST_MESSAGE_NAME": "Nachricht1",
    "DELETE_PROCEDURE": "Um eine Blockdefinition zu löschen, entferne zunächst alle Verwendungen des Blocks",
    "OK": "OK",
    "PROMPT": "Aufforderung",
    "STOP": "stoppen",
    "NEW_STRING": "Eine String-Variable erstellen",
    "NEW_NUMBER_VARIABLE": "Numerische Variable erstellen",
    "WIFI": "Wi-Fi",
    "USERNAME": "Benutzer",
    "PWD": "Passwort",
    "IOT_SERVICE": "IOT-Dienst",
    "IOT_ID": "iot id",
    "IOT_PWD": "iot pwd",
    "IOT_TOPIC": "Thema_0",
    "MORE_TOPIC": "Thema",
    "OBLOQ_HTTP": "HTTP",
    "OBLOQ_IP": "IP-Adresse",
    "OBLOQ_PORT": "Anschluss",
    "ADD_TOPIC": "Thema hinzufügen, kann nur bis zu 4 hinzufügen",
    "DELETE_TOPIC": "Thema löschen",
    "IOT_SERVER": "Server",
    "CHINA": "China",
    "GLOBAL": "Global",
    "LOW_C": "Tiefes C/C3",
    "LOW_C$": "Tiefes C#/C#3",
    "LOW_D": "Tiefes D/D3",
    "LOW_D$": "Tiefes D#/D#3",
    "LOW_E": "Tiefes E/E3",
    "LOW_F": "Tiefes F/F3",
    "LOW_F$": "Tiefes F#/F#3",
    "LOW_G": "Tiefes G/G3",
    "LOW_G$": "Tiefes G#/G#3",
    "LOW_A": "Tiefes A/A3",
    "LOW_A$": "Tiefes A#/A#3",
    "LOW_B": "Tiefes B/B3",
    "MIDDLE_C": "Mittleres C/C4",
    "MIDDLE_C$": "Mittleres C#/C#4",
    "MIDDLE_D": "Mittleres D/D4",
    "MIDDLE_D$": "Mittleres D#/D#4",
    "MIDDLE_E": "Mittleres E/E4",
    "MIDDLE_F": "Mittleres F/F4",
    "MIDDLE_F$": "Mittleres F#/F#4",
    "MIDDLE_G": "Mittleres G/G4",
    "MIDDLE_G$": "Mittleres G#/G#4",
    "MIDDLE_A": "Mittleres A/A4",
    "MIDDLE_A$": "Mittleres A#/A#4",
    "MIDDLE_B": "Mittleres B/B4",
    "HIGH_C": "Hohes C/C5",
    "HIGH_C$": "Hohes C#/C#5",
    "HIGH_D": "Hohes D/D5",
    "HIGH_D$": "Hohes D#/D#5",
    "HIGH_E": "Hohes E/E5",
    "HIGH_F": "Hohes F/F5",
    "HIGH_F$": "Hohes F#/F#5",
    "HIGH_G": "Hohes G/G5",
    "HIGH_G$": "Hohes G#/G#5",
    "HIGH_A": "Hohes A/A5",
    "HIGH_A$": "Hohes A#/A#5",
    "HIGH_B": "Hohes B/B5",
    "OPERATORS_STRINGTONUMBER": "String %1 nach %2 konvertieren",
    "INTEGER": "Ganzzahl",
    "DECIMAL": "Dezimal",
    "OPERATORS_NUMTOASCII": "Zahl %1 nach ASCII konvertieren",
    "OPERATORS_STRINGTOASCII": "konvertiert Zeichenkette %1 nach ASCII",
    "OPERATORS_NUMTOSTRING": "konvertiere Zahl %1 nach String",
    "OPERATORS_MAP": "Verteile %1 von[ %2 , %3 ] bis[ %4 , %5 ]",
    "OPERATORS_CONSTRAIN": "begrenze %1 zwischen(min) %2 und(max) %3",
    "STEPS_PER_TURN": "Schritte pro Umdrehung",
    "ROTATE_SPEED": "Rotationsgeschwindigkeit(r/s)",
    "NEW_AI": "Einen AI-Block erstellen",
    "ASK_AND_PAINT": "bitte eine Zahl zeichnen",
    "GET_IDENTIFIED_NUM": "Zahlenerkennung",
    "READ_NUM_AND_SAY": "spreche die erkannte Zahl aus",
    "IMAGE_ADDR": "Bild Addr",
    "IMAGE_PREVIEW": "Bildvorschau",
    "IMAGE_OPEN": "öffnen",
    "IMAGE_SIZE": "Bildgröße",
    "IMAGE_WIDTH": "W",
    "IMAGE_HEIGHT": "H",
    "VARIABLE": "%1",
    "VARIABLE_LIST": "%1",
    "SET_STRING_VARIABLE": "setze %1 auf %2",
    "STRING_START_WITH": "%1 beginne mit %2",
    "DICTIONARY_HEIGHT_FIRST": "",
    "DICTIONARY_HEIGHT_TWO": "",
    "DICTIONARY_HEIGHT_THREE": "",
    "DICTIONARY_HEIGHT_FOUR": "",
    "DATA_MPITEMOFLIST": "",
    "DATA_MPINTERCEPTITEM": "",
    "DATA_MPINSERTATLIST": "",
    "DATA_MPSETVALUEATLIST": "",
    "DATA_MPINSERTATLIST_INSERT": "",
    "DATA_MPINSERTATLIST_CHANGE": "",
    "DATA_MPINSERTATLIST_FOUR": "",
    "DATA_MPINSERTATLIST_FIVE": "",
    "DATA_MPINSERTATLIST_SIX": "",
    "DATA_MPINSERTATLIST_SEVEN": "",
    "DATA_MPINSERTATLIST_EIGHT": "",
    "DATA_MPINSERTATLIST_EIGHT_FALSE": "",
    "DATA_MPINSERTATLIST_EIGHT_TRUE": "",
    "DATA_MPINSERTATLIST_EIGHT_REVERSE": "",
    "OPERATORS_RANDOMA": "%1 %2 wähle zufällig %3 bis %4 %5",
    "OPERATORS_MODA": "%1 %2 %3 mod %4",
    "OPERATORS_ROUNDA": "%1 %2 runden %3",
    "OPERATORS_MATHOPA": "%1 %2 %3 %4",
    "OPERATORS_MATHOP_ABSA": "abs",
    "OPERATORS_MATHOP_FLOORA": "abrunden",
    "OPERATORS_MATHOP_CEILINGA": "aufrunden",
    "OPERATORS_MATHOP_SQRTA": "sqrt",
    "OPERATORS_MATHOP_FIVE": "%1 %2 ändern %3 um %4",
    "OPERATORS_MATHOP_SIX": "%1 %2 verteile %3 von[ %4 , %5 ] bis[ %6 , %7 ]",
    "OPERATORS_MATHOP_SEVEN": "%1 %2 begrenze %3 zwischen(min) %4 und(max) %5",
    "OPERATORS_MATHOP_LENGHT": "Länge %1",
    "OPERATORS_MATHOP_MAXMIN": "%1 %2",
    "OPERATORS_MATHOP_MAXMIN_MAX": "max",
    "OPERATORS_MATHOP_MAXMIN_MIN": "min",
    "OPERATORS_TEXTS_ONE": "%1 %2 verbinde %3 %4",
    "OPERATORS_TEXTS_TWO": "%1 %2 Buchstabe %4 von %3",
    "OPERATORS_TEXTS_THREE": "%1 %2 %3 enthält %4?",
    "OPERATORS_TEXTS_FOUR": "%1 %2 Buchstabe %5 %4 bis %7 %6 von %3",
    "OPERATORS_TEXTS_FOUR_NEW": "%1 wähle %2 %3 Zeichen bis %4 %5Zeichen",
    "OPERATORS_TEXTS_NEW": "%1 %2 findet %3 in %4 %5 auftauchende Position",
    "OPERATORS_TEXTS_NEW_OP": "finde, wie oft %1 in %2 %3 erscheint",
    "OPERATORS_TEXTS_FOUR_ONE": "von links",
    "OPERATORS_TEXTS_FOUR_TWO": "von rechts",
    "OPERATORS_TEXTS_FOUR_THREE": "von links",
    "OPERATORS_TEXTS_FOUR_FOUR": "von rechts",
    "OPERATORS_TEXTS_FOUR_F": "erste",
    "OPERATORS_TEXTS_FOUR_L": "letzte",
    "OPERATORS_STRINGTONUMBERA": "%1 %2 konvertiert string %3 in %4",
    "OPERATORS_NUMTOASCIIA": "%1 %2 konvertiert Zahl %3 in ASCII-Zeichen",
    "OPERATORS_STRINGTOASCIIA": "%1 %2 konvertiert Zeichen %3 in einen ASCII-Wert",
    "OPERATORS_NUMTOSTRINGA": "%1 %2 konvertiere Zahl %3 in eine Zeichenkette",
    "NEW_MC": "Micropython-Variable erzeugen",
    "RENAME_MCNUMBER_TITLE": "alle Variablen, deren Name [%1] ist, umbenennen in:",
    "RENAME_MCNUMBER_MODAL_TITLE": "Variable umbenennen",
    "RENAME_VARIABLE_MCNUMBER": "Variable umbenennen",
    "DELETE_VARIABLE_MCNUMBER": "Variable [%1] löschen",
    "SET_MC_VARIABLE": "setze %1 auf %2",
    "TUPLE_ONE": "",
    "TUPLE_TWO": "",
    "ALIYUN": "Aliyun",
    "ONENET": "OneNet",
    "EASYIOT": "Einfaches IoT",
    "SHANGHAI": "Schanghai",
    "QINGDAO": "Qingdao",
    "BEIJING": "Peking",
    "ZHANGJIAKOU": "Zhangjiakou",
    "HUHEHAOTE": "Huhehaote",
    "HANGZHOU": "Hangzhou",
    "SHENZHEN": "Shenzhen",
    "HONGKONG": "HongKong",
    "SINGAPORE": "Singapur",
    "SYDNEY": "Sydney",
    "KUALALUMPUR": "Luala Lumpur",
    "JAKARTA": "Jakarta",
    "MUMBAI": "Mumbai",
    "TOKYO": "Tokio",
    "SILICONVALLEY": "Silicon Valley",
    "VIRGINIA": "Virginia",
    "FRANKFURT": "Frankfurt",
    "LONDON": "London",
    "DUBAI": "Dubai",
    "IOT_PLATFORM": "Iot-Plattform",
    "PARAMS": "Parameter",
    "SERVER_ATTR": "Server Adresse",
    "PRODUCTID": "Produkt-ID",
    "DEVICEID": "Geräte-ID",
    "TINYDB_SECRET": "Geheimnis (Geheimnis)",
    "TINYDB_USER": "Benutzer (Benutzer)",
    "APIADDR": "API-Adresse",
    "SOFTWARE_SERIAL": "Software Seriell",
    "HARDWARE_SERIAL": "Hardware Seriell",
    "HARDWARE_SERIAL1": "Hardware Seriell1",
    "HARDWARE_SERIAL2": "Hardware Seriell2",
    "HARDWARE_SERIAL3": "Hardware Seriell3",
    "CONTROL_LOOP_BREAKCONTINUE": "%1",
    "CONTROL_LOOP_BREAKCONTINUE_BREAK": "Pause",
    "CONTROL_LOOP_BREAKCONTINUE_CONTINUE": "weiter",
    "CONTROL_WAIT_ESP32_MPY": "warte %1 %2",
    "CONTROL_FOR_FROM_SEQUENCE": "verwende %1 aus Sequenz %2",
    "CONTROL_WAIT_ESP32_MPY_S": "Sekunde(s)",
    "CONTROL_WAIT_ESP32_MPY_MS": "Millisekunde(ms)",
    "CONTROL_WAIT_ESP32_MPY_US": "Mikrosekunde(us)",
    "CONTROL_TRUE_FALSE": "%1",
    "CONTROL_TRUE_FALSE_TRUE": "true",
    "CONTROL_NONE": "keine",
    "CONTROL_RETURN": "Rückgabe %1",
    "OPERATORS_VALUE_IS_TRUE": "Wert %1 ist wahr?",
    "OPERATORS_VALUE_TYPE": "Wert %1 Typ",
    "CONTROL_TRY": "versuchen",
    "CONTROL_EXCEPT": "außer",
    "CONTROL_FINALLY": "endgültig",
    "OPERATORS_VALUE_TYPE_IS": "Wert %1 Typ ist %2?",
    "OPERATORS_VALUE_TYPE_INT": "int",
    "OPERATORS_VALUE_TYPE_FLOAT": "float",
    "OPERATORS_VALUE_TYPE_BOOL": "bool",
    "OPERATORS_VALUE_TYPE_STR": "str",
    "OPERATORS_VALUE_TYPE_LIST": "Liste",
    "OPERATORS_VALUE_TYPE_TUPLE": "Tupel",
    "OPERATORS_VALUE_TYPE_SET": "set",
    "OPERATORS_VALUE_TYPE_DICT": "dict",
    "OPERATORS_VALUE_TYPE_BYTES": "bytes",
    "OPERATORS_VALUE_TYPE_BYTEARRAY": "bytearray",
    "OPERATORS_VALUE_TYPE_COMPLEX": "komplex",
    "OPERATORS_CACULATE_RESULT": "berechne %1",
    "CANCEL": "Abbrechen",
    "WIFI_VALIDATE": "Wi-Fi-Passwort muss aus 8-20 Buchstaben und Zahlen bestehen",
    "GLOBAL_MPY_VARIABLE": "global %1",
    "OPERATORS_NUM_INPUT": "%1 %2 Anzahl %3",
    "OPERATORS_NUMS_CACULATE": "%1 %2 %3",
    "OPERATORS_MATHOP_SQUARE": "**",
    "OPERATORS_MATHOP_LOGIC_OR": "|",
    "OPERATORS_MATHOP_LOGIC_AND": "&",
    "OPERATORS_MATHOP_LOGIC_POWER": "^",
    "OPERATORS_MATHOP_LOGIC_LEFT": "<<",
    "OPERATORS_MATHOP_LOGIC_RIGHT": ">>",
    "OPERATORS_SPECIAL_NUM": "%1 %2 %3",
    "OPERATORS_MATHOP_PI": "π",
    "OPERATORS_MATHOP_LOGARITHM": "e",
    "OPERATORS_MATHOP_FAI": "φ",
    "OPERATORS_MATHOP_SQRT2": "sqrt(2)",
    "OPERATORS_MATHOP_SQRTHALF": "sqrt(½)",
    "OPERATORS_MATHOP_UNLIMITED": "∞",
    "OPERATORS_NUMS_TYPE": "%1 %2 %3 %4?",
    "OPERATORS_MATHOP_ISEVEN": "ist gerade",
    "OPERATORS_MATHOP_ISODD": "ist ungerade",
    "OPERATORS_MATHOP_ISINT": "ist Ganzzahl",
    "OPERATORS_MATHOP_ISPOSITIVE": "ist positiv",
    "OPERATORS_MATHOP_ISNEGATIVE": "ist negativ",
    "OPERATORS_SAVE2DECIMAL": "%1 %2 %3 speichert %4 Nachkommastellen",
    "OPERATORS_LIST_CACULATE": "%1 %2 %3 %4",
    "OPERATORS_MATHOP_LIST_SUM": "Summe der Zahlen in der Liste",
    "OPERATORS_MATHOP_LIST_MIN": "minimaler Wert in der Liste",
    "OPERATORS_MATHOP_LIST_MAX": "maximaler Wert in der Liste",
    "OPERATORS_MATHOP_LIST_AVERAGE": "Durchschnitt in der Liste",
    "OPERATORS_MATHOP_LIST_NUM": "Anzahl der Ziffern in der Liste",
    "OPERATORS_MATHOP_LIST_MODE": "Listenmodus",
    "OPERATORS_MATHOP_LIST_STDDEVIATION": "Standardabweichung in der Liste",
    "OPERATORS_MATHOP_LIST_RANDOM": "zufälliges Element der Liste",
    "OPERATORS_QUOTIENT_INT": "%1 %2 %3 ÷ %4 ganzzahliger Teil des Quotienten",
    "OPERATORS_RANDOM_DECIMAL": "%1 %2 zufällige Dezimalzahl",
    "OPERATORS_BIN_NEGATION": "%1 %2 binäre Negation %3",
    "OPERATORS_INT_TO_STR": "%1 %2 dezimale Ganzzahl %3 in %4 String umwandeln",
    "OPERATORS_MATHOP_INTTOSTR_BIN": "binär",
    "OPERATORS_MATHOP_INTTOSTR_OCT": "oktal",
    "OPERATORS_MATHOP_INTTOSTR_HEX": "hex",
    "OPERATORS_BINSTR_TO_INT": "%1 %2 %3 String %4 umwandeln in dezimale Ganzzahl",
    "OPERATORS_INT_TO_BYTE": "%1 %2 dezimale Ganzzahl %3 umwandeln in %4 Byte",
    "OPERATORS_MATHOP_INTTOBYTE_TWO": "2",
    "OPERATORS_MATHOP_INTTOBYTE_FOUR": "4",
    "OPERATORS_INT_TO_ASCII": "%1 %2 int %3 umwandeln in ASCII-Zeichen",
    "OPERATORS_ASCII_TO_INT": "%1 %2 ASCII-Zeichen %3 nach int",
    "OPERATORS_STR_TO_INT": "%1 %2 String %3 nach Hex-String",
    "OPERATORS_HEXSTR_TO_BIN": "%1 %2 hex string %3 nach bin byte",
    "OPERATORS_STR_TO_BASE64": "%1 %2 %3 kodieren in base64-Format liefert ein Byte-Objekt",
    "OPERATORS_BASE64_TO_BYTE": "%1 %2 dekodiert base64-Daten %3 gibt ein Byte-Objekt zurück",
    "OPERATORS_INPUTSTRING": "%1 %2 Zeichenkette %3",
    "OPERATORS_TRANSINTOTEXT": "%1 %2 umwandeln in %3",
    "OPERATORS_TRANSINTOTEXT_ADD": "hinzufügen",
    "OPERATORS_TRANSINTOTEXT_ADD_ITEM": "Element",
    "OPERATORS_FORMATSTRING2F": "%1 %2 Format string %3 % %4",
    "OPERATORS_FORMATSTRING3": "%1 %2 Format string %3 Format %4",
    "OPERATORS_TEXT_ESCAPE": "%1 %2 Text escape Zeichen %3",
    "BAR_N": "\\n",
    "BAR_NN": "\\n\\r",
    "BAR_R": "\\r",
    "BAR_RN": "\\r\\n",
    "BAR_B": "\\b",
    "BAR_T": "\\t",
    "BAR_BAR": "\\\\",
    "OPERATORS_TEXT_NUMORLETTER": "%1 %2 %3 %4?",
    "OPERATORS_TEXT_NUMORLETTER_NUM": "ist Zahl",
    "OPERATORS_TEXT_NUMORLETTER_LETTER": "ist Buchstabe",
    "OPERATORS_TEXT_LENGTH": "%1 %2 %3 Länge",
    "OPERATORS_TEXT_ISNONE": "%1 %2 %3 ist keine?",
    "OPERATORS_FROMTEXT_GETCHAR": "%1 %2 im Text %3 %4",
    "OPERATORS_FROMTEXT_GETCHAR_FIRST": "erster Buchstabe",
    "OPERATORS_FROMTEXT_GETCHAR_LAST": "letzter Buchstabe",
    "OPERATORS_FROMTEXT_GETCHAR_RANDOM": "zufälliger Buchstabe",
    "OPERATORS_TEXT_UPPLOW": "%1 %2 %3 %4",
    "OPERATORS_TEXT_UPPLOW_UPP": "Großbuchstaben",
    "OPERATORS_TEXT_UPPLOW_LOW": "Kleinbuchstaben",
    "OPERATORS_TEXT_SIDES_SPACE": "%1 %2 %3 %4",
    "OPERATORS_TEXT_SIDES_SPACE_BOTH": "beidseitig Leerzeichen löschen",
    "OPERATORS_TEXT_SIDES_SPACE_LEFT": "linkes Leerzeichen löschen",
    "OPERATORS_TEXT_SIDES_SPACE_RIGHT": "Leerzeichen rechts löschen",
    "OPERATORS_TEXT_STRTOBYTE": "%1 %2 Byte umwandeln %3",
    "OPERATORS_TEXT_BYTETOSTR": "%1 %2 Byte %3 umwandeln in string",
    "OPERATORS_TEXT_DICTTOJSONSTR": "%1 %2 dict %3 umwandeln in json string",
    "OPERATORS_TEXT_PARSEJSONSTR": "%1 %2 json string %3 parsen und Objekt zurückgeben",
    "ONENETNEW": "OneNet(Neu)",
    "DEVICENAME": "Gerätename",
    "CHECKTYPE_TIPS": "Der von der Eingangsbox empfangene Datentyp stimmt nicht mit dem Ausgangstyp des Bausteins überein",
    "HIGHLIGHT_BLOCK": "Baustein hervorheben",
    "HIGHLIGHT_ALL_CONFLICT_BLOCKS": "alle Bausteine mit Warnungen hervorheben",
    "SNAPSHOT": "Schnappschuss (CTRL+G)",
    "pictureaiPath": "Bitte gebe den Bildpfad oder die URL ein",
    "pictureaiType": "Accounttyp",
    "pictureaiAccountDefault": "Standard-Account",
    "pictureaiAccountBaidu": "Baidu-Account",
    "pictureaiwebImgAddr": "Web-Bild-Adresse",
    "MITRIXICON_EDIT": "aufleuchten",
    "MITRIXICON_ERASER": "Radiergummi",
    "MITRIXICON_DUSTBIN": "löschen",
    "MITRIXICON_REVERSE": "umkehren",
    "MITRIXICON_COLOR": "Farbe wählen",
    "RED": "rot",
    "GREEN": "grün",
    "BLUE": "blau",
    "MITRIXICON_SAVE": "speichern",
    "MITRIXICON_EXPORT": "exportieren",
    "MITRIXICON_UPLOAD": "hochladen",
    "ROLL_MOUSE": "drehende Maus",
    "MOUSE_ZOOM_IN_OUT": "Bewege die Maus zum Vergrößern und Verkleinern",
    "UPLOAD_CORRECT_JSON_FILE": "Bitte lade die json-Datei im richtigen Format hoch",
    "EXPORT_PROMPT": "Es sind keine benutzerdefinierten Muster zum Exportieren vorhanden. Bitte klicke auf die Schaltfläche \"Speichern\" in der unteren rechten Ecke, um das benutzerdefinierte Muster zu speichern, bevor versucht wird, es zu exportieren.",
    "HEART": "Herz",
    "HEART_SMALL": "Kleines Herz",
    "HAPPY": "Glücklich",
    "SMILE": "Lächeln",
    "SAD": "Traurig",
    "CONFUSED": "Verwirrt",
    "ANGRY": "Wütend",
    "SLEEP": "Schlaf",
    "SURPRISED": "Überrascht",
    "SILLY": "Dumm",
    "WONDERFUL": "Wunderbar",
    "BORED": "Gelangweilt",
    "ROCK": "Felsen",
    "ROCK_SMALL": "Kleiner Felsen",
    "PAPER": "Papier",
    "PAPER_SMALL": "Kleines Papier",
    "SCISSORS": "Schere",
    "SCISSORS_SMALL": "Kleine Schere",
    "DATA_SOLITLIST": "%1 %2 mache %3 %4 mit Trennzeichen %5",
    "DATA_SOLITLIST_TYPE_LT": "Liste aus Text5",
    "DATA_SOLITLIST_TYPE_TL": "Text aus Liste",
    "DATA_LENLIST": "%1 %2 %3 ist leer?",
    "DATA_LENGTHLIST": "%1 %2 Länge von %3",
    "DATA_CREATELIST": "%1 %2 Liste erstellen %3",
    "DATA_CLEARLIST": "%1 %2 Liste %3 löschen",
    "ML5MODALBUTTON": "AI Visual Interface öffnen",
    "COLLAPSE_BLOCK": "einklappen",
    "EXPAND_BLOCK": "expandieren",
    "COLLAPSE_ALL": "alle Blöcke einklappen",
    "EXPAND_ALL": "alle Blöcke aufklappen",
    "CONTROL_TRUE_FALSE_FALSE": "false",
    "SEARCH_BLOCKS": "search blocks",
    "NO_SEARCH_BLOCKS": "with no result",
    "SEARCH_DEFAULT_LABEL_THINK": "think",
    "SEARCH_DEFAULT_LABEL_IF": "if",
    "SEARCH_DEFAULT_LABEL_WAIT": "wait",
    "SEARCH_DEFAULT_LABEL_CONVERT": "convert",
    "SEARCH_DEFAULT_LABEL_BROADCAST": "broadcast",
    "pyTimer": "Timer",
    "pyList": "List",
    "pyDictionary": "Dictionary",
    "pySet": "Set",
    "mpyTuple": "Tuple",
    "pythonBase_printAndInput": "Input and Output",
    "pythonBase_mainSetUp": "%1 %2 Python main program start",
    "pythonBase_print": "%1 %2 print %3",
    "pythonBase_input": "%1 %2 gets the input, the prompt is %3",
    "pyTimer_setEvent": "%1 %2 set timer %3 %4 cycle %5 milliseconds",
    "pyTimer_repeated": "Repeated execution",
    "pyTimer_delayed": "Delayed execution",
    "pyTimer_conditionEvent": "%1 %2 set timer %3: when %4",
    "pyTimer_getCount": "%1 %2 timer %3 count value",
    "pyTimer_clearEvent": "%1 %2 clear timer/event %3",
    "pyDictionary_init": "%1 %2 initialize dictionary %3",
    "pyDictionary_getValue": "%1 %2 dictionary %3 key %4 value",
    "pyDictionary_setValue": "The value of %1 %2 dictionary %3 key %4 is set to %5",
    "pyDictionary_deleteKey": "%1 %2 dictionary %3 delete key %4",
    "pyDictionary_clear": "%1 %2 clear dictionary %3",
    "pyDictionary_isInclude": "%1 %2 dictionary %3 contains the key %4?",
    "pyDictionary_getLength": "%1 %2 dictionary %3 length",
    "pyDictionary_list": "%1 %2 dictionary %3 %4 list",
    "pyDictionary_TypeIndex_A": "Key",
    "pyDictionary_TypeIndex_B": "Value",
    "pyList_join": "%1 %2 list %3 uses separator %4 to combine text",
    "pyList_split": "%1 %2 text %3 uses separator %4 to make a list",
    "pyList_init": "%1 %2 initialization list %3",
    "pyList_clear": "%1 %2 clear list %3",
    "pyList_getLength": "%1 %2 length of list %3",
    "pyList_isEmpty": "%1 %2 list %3 is empty?",
    "pyList_getValue": "%1 %2 list %3 index %4 value",
    "pyList_getValueRange": "%1 %2 returns list %3 and takes %4 %5 items to %6 %7 items",
    "pyList_insert": "%1 %2 list %3 insert %5 at index %4",
    "pyList_setValue": "%1 %2 list %3 set index %4 to value %5",
    "pyList_delete": "%1 %2 list %3 delete index %4 value",
    "pyList_append": "%1 %2 list %3 adds %4 to the end",
    "pyList_extend": "%1 %2 list %3 append list %4",
    "pyList_findIndex": "%1 %2 list %3 find index of %4",
    "pyList_sort": "%1 %2 list %3 sorted by %4 as %5",
    "pyList_SortModeIndex_A": "Ascending order",
    "pyList_SortModeIndex_B": "Descending Order",
    "pyList_SortTypeIndex_A": "Number",
    "pyList_SortTypeIndex_B": "Letter",
    "pyList_SortTypeIndex_C": "Letters, ignore case",
    "pySet_init": "%1 %2 initialize set %3 ",
    "pySet_initEmpty": "%1 %2 initialize empty set",
    "pySet_update": "%1 %2 set %3 updated to be %5 with set %4",
    "pySet_TypeIndex_A": "Intersection",
    "pySet_TypeIndex_B": "Union Set",
    "pySet_TypeIndex_C": "Difference Set",
    "pySet_remove": "%1 %2 set %3 remove %4",
    "pySet_clear": "%1 %2 clear set %3",
    "pySet_copy": "%1 %2 copy set %3",
    "pySet_has": "%1 %2 set %3 has %4",
    "pySet_addValue": "%1 %2 set %3 add %4",
    "pySet_isSubsetSuperset": "%1 %2 set %3 is %5 of set %4?",
    "pySet_SubsetSupersetType_A": "Subset",
    "pySet_SubsetSupersetType_B": "Superset",
    "pySet_intersectionUnionSetDifference": "%1 %2 takes %3 set %4 set %5",
    "pySet_length": "%1 %2 set %3 length",
    "pySet_pop": "%1 %2 returned random items and removed from the set %3",
    "pyTuple_init": "%1 %2 initialized tuple %3",
    "pyTuple_minMaxLength": "%1 %2 tuple %3 %4",
    "pyTuple_TypeIndex_A": "Minimum Value",
    "pyTuple_TypeIndex_B": "Maximum Value",
    "pyTuple_TypeIndex_C": "Length",
    "pyTuple_isInclude": "%1 %2 tuple %3 contains %4?",
    "pyTuple_getValueRange": "%1 %2 returns the tuple %3 taking %4 %5 items to %6 %7 items",
    "pyTuple_IndexType_A": "#",
    "pyTuple_IndexType_B": "countdown #",
    "pyTuple_listToTulpe": "%1 %2 list %3 to tuple",
    "pyTuple_getValue": "%1 %2 tuple %3 index %4 value",
    "pyTuple_tupleToList": "%1 %2 tuple %3 turned into a list",
    "CONTROL_FOR_RANGE_SPACE": "verwende %1 im Bereich %2 bis %3 jedes %4",
    "OPERATORS_MATHOP_NEGATIVE": "-",
    "OPERATORS_MATHOP_INT": "int",
    "OPERATORS_MATHOP_FLOAT": "float",
    "DUPLICATE_CURRENT": "Einzelnes Element duplizieren",
    "IP": "IP-Adresse",
    "pythonBase_file": "Datei",
    "pythonBase_file_open": "%1 %2 öffnet Datei %5 im Format %3 Modus %4 und gibt %6 zurück",
    "pythonBase_file_open_b": "%1 %2 öffnete Datei %4 im Modus %3 und gab %5 zurück",
    "pythonBase_file_open_read": "lesen",
    "pythonBase_file_open_write": "schreiben",
    "pythonBase_file_open_add": "hinzufügen",
    "pythonBase_file_open_read_bit": "binäres lesen",
    "pythonBase_file_open_write_bit": "binäres schreiben",
    "pythonBase_file_open_add_bit": "binäres hinzufügen",
    "pythonBase_file_close": "%1 %2 %3 schließen",
    "pythonBase_file_read": "%1 %2 %3 lesen %4",
    "pythonBase_file_read_all": "gesamte Datei",
    "pythonBase_file_read_line": "eine Zeile",
    "pythonBase_file_read_lines": "alle Zeilen",
    "pythonBase_file_write": "%1 %2 %3 schreiben %4",
    "pythonBase_time": "Systemzeit",
    "pythonBase_get_time": "%1 %2 Systemzeit abrufen %3",
    "pythonBase_get_time_by_year": "Jahr",
    "pythonBase_get_time_by_month": "Monat",
    "pythonBase_get_time_by_day": "Tag",
    "pythonBase_get_time_by_hour": "Stunde",
    "pythonBase_get_time_by_minute": "Minute",
    "pythonBase_get_time_by_second": "Sekunde",
    "pythonBase_get_time_by_day_of_week": "Tag der Woche",
    "pythonBase_get_time_by_day_of_year": "Tag des Jahres",
    "pythonBase_get_time_format": "%1 %2 liefert das Systemzeitformat %3",
    "pythonBase_get_time_format_by_hour_minute_second": "Stunde:Minute:Sekunde",
    "pythonBase_get_time_format_by_hour_minute": "Stunde:Minute",
    "pythonBase_get_time_format_by_year_month_day": "Jahr/Monat/Tag",
    "pythonBase_get_time_format_by_year_month": "Jahr/Monat",
    "pythonBase_get_time_format_by_year_month_day_": "Jahr-Monat-Tag",
    "pythonBase_get_time_format_by_year_month_day_hour_minute_second": "Jahr/Monat/Tag Stunde:Minute:Sekunde",
    "pythonBase_get_timestamp": "%1 %2 Systemzeitstempel abrufen",
    "pythonBase_get_time_format_custom": "%1 %2 Systemzeitformat erhalten (%3)",
    "pyList_has": "%1 %2 Liste %3 hat %4 ?",
    "pySet_updateValue": "%1 %2 Menge %3 aufzählbares Objekt hinzufügen %4",
    "text_decode_encode": "dekodieren oder kodieren",
    "coder": "%1 %2 %3 Text %4 %5",
    "decode": "dekodieren",
    "encode": "encodieren",
    "mathop_specially": "%1 %2 konvertiert %3 in %4"
  },
  "et": {
    "CONTROL_FOREVER": "korda lõputult",
    "CONTROL_REPEAT": "korda %1 korda",
    "CONTROL_IF": "kui %1 siis",
    "CONTROL_ELSE": "muidu",
    "CONTROL_STOP": "stopp",
    "CONTROL_STOP_ALL": "kõik",
    "CONTROL_STOP_THIS": "see skript",
    "CONTROL_STOP_OTHER": "teised spraidi skriptid",
    "CONTROL_WAIT": "oota %1 sek",
    "CONTROL_WAITUNTIL": "oota kuni %1",
    "CONTROL_REPEATUNTIL": "korda kuni %1",
    "CONTROL_WHILE": "kui %1",
    "CONTROL_FOREACH": "iga %1 jaoks %2-s",
    "CONTROL_STARTASCLONE": "kui alustan kloonina",
    "CONTROL_CREATECLONEOF": "klooni %1",
    "CONTROL_CREATECLONEOF_MYSELF": "ennast",
    "CONTROL_DELETETHISCLONE": "eemalda see kloon",
    "CONTROL_COUNTER": "loendur",
    "CONTROL_INCRCOUNTER": "suurenda loendurit",
    "CONTROL_CLEARCOUNTER": "nulli loendur",
    "CONTROL_ALLATONCE": "kõik korraga",
    "DATA_SETVARIABLETO": "võta %1 = %2",
    "DATA_CHANGEVARIABLEBY": "muuda %1 %2 võrra",
    "DATA_SHOWVARIABLE": "näita muutujat %1",
    "DATA_HIDEVARIABLE": "peida muutuja %1",
    "DATA_ADDTOLIST": "lisa %1 loendisse %2",
    "DATA_DELETEOFLIST": "eemalda %1 loendist %2",
    "DATA_DELETEALLOFLIST": "eemalda kõik loendist %1",
    "DATA_INSERTATLIST": "sisesta %1 kohale %2 loendis %3",
    "DATA_REPLACEITEMOFLIST": "asenda väärtus %1 loendis %2 %3 -ga",
    "DATA_ITEMOFLIST": "%1 loendis %2",
    "DATA_ITEMNUMOFLIST": "%1 jrk nr loendis %2",
    "DATA_LENGTHOFLIST": "%1 pikkus",
    "DATA_LISTCONTAINSITEM": "%1 sisaldab %2?",
    "DATA_SHOWLIST": "näita loendit %1",
    "DATA_HIDELIST": "peida loend %1",
    "DATA_INDEX_ALL": "kõik",
    "DATA_INDEX_LAST": "viimane",
    "DATA_INDEX_RANDOM": "juhuslik",
    "EVENT_WHENFLAGCLICKED": "kui klõpsata %1",
    "EVENT_WHENTHISSPRITECLICKED": "kui spraiti klõpsata",
    "EVENT_WHENSTAGECLICKED": "kui lava klõpsata",
    "EVENT_WHENTOUCHINGOBJECT": "kui sprait puudutab %1",
    "EVENT_WHENBROADCASTRECEIVED": "kui saan teate %1",
    "EVENT_WHENBACKDROPSWITCHESTO": "kui taustaks saab %1",
    "EVENT_WHENGREATERTHAN": "kui %1 > %2",
    "EVENT_WHENGREATERTHAN_TIMER": "taimer",
    "EVENT_WHENGREATERTHAN_LOUDNESS": "valjus",
    "EVENT_BROADCAST": "teata %1",
    "EVENT_BROADCASTANDWAIT": "teata %1 ja oota",
    "EVENT_WHENKEYPRESSED": "kui klõpsata klahvi %1",
    "EVENT_WHENKEYPRESSED_SPACE": "tühik",
    "EVENT_WHENKEYPRESSED_LEFT": "nool vasakule",
    "EVENT_WHENKEYPRESSED_RIGHT": "nool paremale",
    "EVENT_WHENKEYPRESSED_DOWN": "nool alla",
    "EVENT_WHENKEYPRESSED_UP": "nool üles",
    "EVENT_WHENKEYPRESSED_ANY": "mõni",
    "LOOKS_SAYFORSECS": "ütle %1 %2 sek",
    "LOOKS_SAY": "ütle %1",
    "LOOKS_HELLO": "Tere!",
    "LOOKS_THINKFORSECS": "mõtle %1 %2 sek",
    "LOOKS_THINK": "mõtle %1",
    "LOOKS_HMM": "Hmm...",
    "LOOKS_SHOW": "näita",
    "LOOKS_HIDE": "peida",
    "LOOKS_HIDEALLSPRITES": "peida kõik spraidid",
    "LOOKS_EFFECT_COLOR": "värv",
    "LOOKS_EFFECT_FISHEYE": "kalasilm",
    "LOOKS_EFFECT_WHIRL": "pööris",
    "LOOKS_EFFECT_PIXELATE": "pikseldus",
    "LOOKS_EFFECT_MOSAIC": "mosaiik",
    "LOOKS_EFFECT_BRIGHTNESS": "heledus",
    "LOOKS_EFFECT_GHOST": "kummitus",
    "LOOKS_CHANGEEFFECTBY": "muuda efekti %1 %2 võrra",
    "LOOKS_SETEFFECTTO": "pane %1 efekt %2",
    "LOOKS_CLEARGRAPHICEFFECTS": "tühista graafikaefektid",
    "LOOKS_CHANGESIZEBY": "muuda suurust %1 võrra",
    "LOOKS_SETSIZETO": "võta suuruseks %1 %",
    "LOOKS_SIZE": "suurus",
    "LOOKS_CHANGESTRETCHBY": "muuda ulatust %1 võrra",
    "LOOKS_SETSTRETCHTO": "võta ulatuseks %1 %",
    "LOOKS_SWITCHCOSTUMETO": "võta kostüüm %1",
    "LOOKS_NEXTCOSTUME": "järgmine kostüüm",
    "LOOKS_SWITCHBACKDROPTO": "võta taust %1",
    "LOOKS_GOTOFRONTBACK": "mine kihile %1",
    "LOOKS_GOTOFRONTBACK_FRONT": "esiplaan",
    "LOOKS_GOTOFRONTBACK_BACK": "tagaplaan",
    "LOOKS_GOFORWARDBACKWARDLAYERS": "mine %1 %2 kihti",
    "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "ettepoole",
    "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "tahapoole",
    "LOOKS_BACKDROPNUMBERNAME": "tausta %1",
    "LOOKS_COSTUMENUMBERNAME": "kostüümi %1",
    "LOOKS_NUMBERNAME_NUMBER": "number",
    "LOOKS_NUMBERNAME_NAME": "nimi",
    "LOOKS_SWITCHBACKDROPTOANDWAIT": "võta taust %1 ja oota",
    "LOOKS_NEXTBACKDROP_BLOCK": "järgmine taust",
    "LOOKS_NEXTBACKDROP": "järgmine taust",
    "LOOKS_PREVIOUSBACKDROP": "eelmine taust",
    "LOOKS_RANDOMBACKDROP": "juhuslik taust",
    "MOTION_MOVESTEPS": "liigu %1 punkti",
    "MOTION_TURNLEFT": "pööra %1 %2 kraadi",
    "MOTION_TURNRIGHT": "pööra %1 %2 kraadi",
    "MOTION_POINTINDIRECTION": "osuta suunas %1",
    "MOTION_POINTTOWARDS": "osuta %1-le",
    "MOTION_POINTTOWARDS_POINTER": "hiirekursor",
    "MOTION_POINTTOWARDS_RANDOM": "juhusuund",
    "MOTION_GOTO": "mine %1",
    "MOTION_GOTO_POINTER": "hiirekursor",
    "MOTION_GOTO_RANDOM": "juhuslik asukoht",
    "MOTION_GOTOXY": "mine x: %1 y: %2",
    "MOTION_GLIDESECSTOXY": "liugle %1 sekundiga x: %2 y: %3",
    "MOTION_GLIDETO": "liugle %1 sekundiga %2",
    "MOTION_GLIDETO_POINTER": "hiirekursor",
    "MOTION_GLIDETO_RANDOM": "juhuslik asukoht",
    "MOTION_CHANGEXBY": "muuda x %1 võrra",
    "MOTION_SETX": "võta x %1",
    "MOTION_CHANGEYBY": "muuda y %1 võrra",
    "MOTION_SETY": "võta y %1",
    "MOTION_IFONEDGEBOUNCE": "kui äärel, põrka",
    "MOTION_SETROTATIONSTYLE": "määra pööramisstiil %1",
    "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "vasak-parem",
    "MOTION_SETROTATIONSTYLE_DONTROTATE": "ei pöördu",
    "MOTION_SETROTATIONSTYLE_ALLAROUND": "suvaline",
    "MOTION_XPOSITION": "x",
    "MOTION_YPOSITION": "y",
    "MOTION_DIRECTION": "suund",
    "MOTION_SCROLLRIGHT": "keri paremale %1",
    "MOTION_SCROLLUP": "keri üles %1",
    "MOTION_ALIGNSCENE": "joonda stseen %1",
    "MOTION_ALIGNSCENE_BOTTOMLEFT": "alla vasakule",
    "MOTION_ALIGNSCENE_BOTTOMRIGHT": "alla paremale",
    "MOTION_ALIGNSCENE_MIDDLE": "keskele",
    "MOTION_ALIGNSCENE_TOPLEFT": "üles vasakule",
    "MOTION_ALIGNSCENE_TOPRIGHT": "üles paremale",
    "MOTION_XSCROLL": "x kerimine",
    "MOTION_YSCROLL": "y kerimine",
    "MOTION_STAGE_SELECTED": "Valitud on Lava: liikumiskäsud puuduvad",
    "OPERATORS_ADD": "%1 + %2",
    "OPERATORS_SUBTRACT": "%1 - %2",
    "OPERATORS_MULTIPLY": "%1 * %2",
    "OPERATORS_DIVIDE": "%1 / %2",
    "OPERATORS_RANDOM": "juhuarv %1 kuni %2",
    "OPERATORS_GT": "%1 > %2",
    "OPERATORS_GT_EQUALS": "",
    "OPERATORS_LT": "%1 < %2",
    "OPERATORS_LT_EQUALS": "",
    "OPERATORS_EQUALS": "%1 = %2",
    "OPERATORS_AND": "%1 ja %2",
    "OPERATORS_OR": "%1 või %2",
    "OPERATORS_NOT": "mitte %1",
    "OPERATORS_JOIN": "%1 & %2",
    "OPERATORS_JOIN_APPLE": "õun",
    "OPERATORS_JOIN_BANANA": "banaan",
    "OPERATORS_LETTEROF": "märk %1 sõnas %2",
    "OPERATORS_LETTEROF_APPLE": "õ",
    "OPERATORS_LENGTH": "%1 pikkus",
    "OPERATORS_CONTAINS": "%1 sisaldab %2?",
    "OPERATORS_MOD": "%1 mod %2",
    "OPERATORS_ROUND": "ümarda %1",
    "OPERATORS_MATHOP": "%2 %1",
    "OPERATORS_MATHOP_ABS": "abs",
    "OPERATORS_MATHOP_FLOOR": "floor",
    "OPERATORS_MATHOP_CEILING": "ceiling",
    "OPERATORS_MATHOP_SQRT": "sqrt",
    "OPERATORS_MATHOP_SIN": "sin",
    "OPERATORS_MATHOP_COS": "cos",
    "OPERATORS_MATHOP_TAN": "tan",
    "OPERATORS_MATHOP_ASIN": "asin",
    "OPERATORS_MATHOP_ACOS": "acos",
    "OPERATORS_MATHOP_ATAN": "atan",
    "OPERATORS_MATHOP_LN": "ln",
    "OPERATORS_MATHOP_LOG": "log",
    "OPERATORS_MATHOP_EEXP": "e ^",
    "OPERATORS_MATHOP_10EXP": "10 ^",
    "PROCEDURES_DEFINITION": "defineeri %1",
    "SENSING_TOUCHINGOBJECT": "puudutab %1?",
    "SENSING_TOUCHINGOBJECT_POINTER": "hiirekursor",
    "SENSING_TOUCHINGOBJECT_EDGE": "serv",
    "SENSING_TOUCHINGCOLOR": "puudutab värvi %1?",
    "SENSING_COLORISTOUCHINGCOLOR": "värv %1 puudutab värvi %2?",
    "SENSING_DISTANCETO": "kaugus %1-st",
    "SENSING_DISTANCETO_POINTER": "hiirekursor",
    "SENSING_ASKANDWAIT": "küsi %1 ja oota",
    "SENSING_ASK_TEXT": "Mis Su nimi on?",
    "SENSING_ANSWER": "vastus",
    "SENSING_KEYPRESSED": "klahv %1 all?",
    "SENSING_MOUSEDOWN": "hiir all?",
    "SENSING_MOUSEX": "hiire x",
    "SENSING_MOUSEY": "hiire y",
    "SENSING_SETDRAGMODE": "võta lohistatavus = %1",
    "SENSING_SETDRAGMODE_DRAGGABLE": "lohistatav",
    "SENSING_SETDRAGMODE_NOTDRAGGABLE": "pole lohistatav",
    "SENSING_LOUDNESS": "valjus",
    "SENSING_LOUD": "väga vali?",
    "SENSING_TIMER": "taimer",
    "SENSING_RESETTIMER": "nulli taimer",
    "SENSING_OF": "%2 %1",
    "SENSING_OF_XPOSITION": "x",
    "SENSING_OF_YPOSITION": "y",
    "SENSING_OF_DIRECTION": "suund",
    "SENSING_OF_COSTUMENUMBER": "kostüümi nr",
    "SENSING_OF_COSTUMENAME": "kostüümi nimi",
    "SENSING_OF_SIZE": "suurus",
    "SENSING_OF_VOLUME": "helitugevus",
    "SENSING_OF_BACKDROPNUMBER": "tausta nr",
    "SENSING_OF_BACKDROPNAME": "tausta nimi",
    "SENSING_OF_STAGE": "Lava",
    "SENSING_CURRENT": "hetke %1",
    "SENSING_CURRENT_YEAR": "aasta",
    "SENSING_CURRENT_MONTH": "kuu",
    "SENSING_CURRENT_DATE": "kuupäev",
    "SENSING_CURRENT_DAYOFWEEK": "nädalapäev",
    "SENSING_CURRENT_HOUR": "tunnid",
    "SENSING_CURRENT_MINUTE": "minutid",
    "SENSING_CURRENT_SECOND": "sekundid",
    "SENSING_DAYSSINCE2000": "päevi alates 2000",
    "SENSING_USERNAME": "kasutajanimi",
    "SENSING_USERID": "kasutaja ID",
    "SOUND_PLAY": "alusta heli %1",
    "SOUND_PLAYUNTILDONE": "mängi heli %1 kuni lõpuni",
    "SOUND_STOPALLSOUNDS": "peata kõik helid",
    "SOUND_SETEFFECTO": "pane %1 efekt %2",
    "SOUND_CHANGEEFFECTBY": "muuda efekti %1 %2 võrra",
    "SOUND_CLEAREFFECTS": "vaigista heliefektid",
    "SOUND_EFFECTS_PITCH": "helikõrgus",
    "SOUND_EFFECTS_PAN": "heli vasakul/paremal",
    "SOUND_CHANGEVOLUMEBY": "muuda helitugevust %1 võrra",
    "SOUND_SETVOLUMETO": "võta helitugevuseks %1%",
    "SOUND_VOLUME": "helitugevus",
    "SOUND_RECORD": "salvesta...",
    "CATEGORY_MOTION": "Liikumine",
    "CATEGORY_LOOKS": "Välimus",
    "CATEGORY_SOUND": "Helid",
    "CATEGORY_EVENTS": "Sündmused",
    "CATEGORY_CONTROL": "Juhtimine",
    "CATEGORY_SENSING": "Andurid",
    "CATEGORY_OPERATORS": "Tehted",
    "CATEGORY_VARIABLES": "Muutujad",
    "CATEGORY_MYBLOCKS": "Minu Plokid",
    "CATEGORY_LISTS": "",
    "CATEGORY_TUPLE": "",
    "CATEGORY_DICTIONARYS": "",
    "CATEGORY_NUMBERS": "",
    "CATEGORY_TEXT": "",
    "DUPLICATE": "Paljunda",
    "DELETE": "Kustuta",
    "ADD_COMMENT": "Lisa kommentaar",
    "REMOVE_COMMENT": "Eemalda kommentaar",
    "DELETE_BLOCK": "Eemalda plokk",
    "DELETE_X_BLOCKS": "Eemalda %1 plokki",
    "DELETE_ALL_BLOCKS": "Eemalda kõik %1 plokki?",
    "CLEAN_UP": "Korrasta plokid",
    "HELP": "Abi",
    "UNDO": "Võta tagasi",
    "REDO": "Tee uuesti",
    "EDIT_PROCEDURE": "Redigeeri",
    "SHOW_PROCEDURE_DEFINITION": "Mine definitsiooni juurde",
    "WORKSPACE_COMMENT_DEFAULT_TEXT": "Ütle midagi",
    "COLOUR_HUE_LABEL": "Värv",
    "COLOUR_SATURATION_LABEL": "Küllastus",
    "COLOUR_BRIGHTNESS_LABEL": "Heledus",
    "CHANGE_VALUE_TITLE": "Muuda väärtust:",
    "RENAME_VARIABLE": "Muuda muutuja nime",
    "RENAME_VARIABLE_STRING": "",
    "RENAME_VARIABLE_NUMBER": "",
    "RENAME_VARIABLE_LIST": "",
    "RENAME_VARIABLE_TITLE": "Nimeta kõik \"%1\" muutujad nimega:",
    "RENAME_VARIABLE_MODAL_TITLE": "Muuda muutuja nime",
    "NEW_VARIABLE": "Loo muutuja",
    "NEW_VARIABLE_TITLE": "Uue muutuja nimi:",
    "VARIABLE_MODAL_TITLE": "Uus muutuja",
    "VARIABLE_ALREADY_EXISTS": "Muutuja nimega \"%1\" on juba olemas.",
    "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "Muutuja nimi \"%1\" on juba kasutusel \"%2\" tüüpi muutujal.",
    "DELETE_VARIABLE_CONFIRMATION": "Eemaldada %1 muutuja \"%2\" kasutamist?",
    "CANNOT_DELETE_VARIABLE_PROCEDURE": "Muutujat \"%1\" ei saa eemaldada, sest see on osa funktsiooni \"%2\" definitsioonist.",
    "DELETE_VARIABLE": "Eemalda muutuja \"%1\"",
    "DELETE_VARIABLE_STRING": "",
    "DELETE_VARIABLE_NUMBER": "",
    "DELETE_VARIABLE_LIST": "",
    "NEW_PROCEDURE": "Loo Plokk",
    "PROCEDURE_ALREADY_EXISTS": "Protseduur nimega \"%1\" on juba olemas.",
    "PROCEDURE_DEFAULT_NAME": "ploki nimi",
    "NEW_LIST": "Loo Loend",
    "NEW_LIST_TITLE": "Uue loendi nimi:",
    "LIST_MODAL_TITLE": "Uus loend",
    "LIST_ALREADY_EXISTS": "Loend nimega \"%1\" on juba olemas.",
    "RENAME_LIST_TITLE": "Nimeta kõik \"%1\" loendid nimega:",
    "RENAME_LIST_MODAL_TITLE": "Muuda loendi nime",
    "DEFAULT_LIST_ITEM": "asi",
    "DELETE_LIST": "Eemalda loend \"%1\"",
    "RENAME_LIST": "Muuda loendi nime",
    "NEW_BROADCAST_MESSAGE": "Uus teade",
    "NEW_BROADCAST_MESSAGE_TITLE": "Uue teate nimi:",
    "BROADCAST_MODAL_TITLE": "Uus teade",
    "DEFAULT_BROADCAST_MESSAGE_NAME": "teade1",
    "DELETE_PROCEDURE": "",
    "OK": "",
    "PROMPT": "",
    "STOP": "",
    "NEW_STRING": "",
    "NEW_NUMBER_VARIABLE": "",
    "WIFI": "",
    "USERNAME": "",
    "PWD": "",
    "IOT_SERVICE": "",
    "IOT_ID": "",
    "IOT_PWD": "",
    "IOT_TOPIC": "",
    "MORE_TOPIC": "",
    "OBLOQ_HTTP": "",
    "OBLOQ_IP": "",
    "OBLOQ_PORT": "",
    "ADD_TOPIC": "",
    "DELETE_TOPIC": "",
    "IOT_SERVER": "",
    "CHINA": "",
    "GLOBAL": "",
    "LOW_C": "",
    "LOW_C$": "",
    "LOW_D": "",
    "LOW_D$": "",
    "LOW_E": "",
    "LOW_F": "",
    "LOW_F$": "",
    "LOW_G": "",
    "LOW_G$": "",
    "LOW_A": "",
    "LOW_A$": "",
    "LOW_B": "",
    "MIDDLE_C": "",
    "MIDDLE_C$": "",
    "MIDDLE_D": "",
    "MIDDLE_D$": "",
    "MIDDLE_E": "",
    "MIDDLE_F": "",
    "MIDDLE_F$": "",
    "MIDDLE_G": "",
    "MIDDLE_G$": "",
    "MIDDLE_A": "",
    "MIDDLE_A$": "",
    "MIDDLE_B": "",
    "HIGH_C": "",
    "HIGH_C$": "",
    "HIGH_D": "",
    "HIGH_D$": "",
    "HIGH_E": "",
    "HIGH_F": "",
    "HIGH_F$": "",
    "HIGH_G": "",
    "HIGH_G$": "",
    "HIGH_A": "",
    "HIGH_A$": "",
    "HIGH_B": "",
    "OPERATORS_STRINGTONUMBER": "",
    "INTEGER": "",
    "DECIMAL": "",
    "OPERATORS_NUMTOASCII": "",
    "OPERATORS_STRINGTOASCII": "",
    "OPERATORS_NUMTOSTRING": "",
    "OPERATORS_MAP": "",
    "OPERATORS_CONSTRAIN": "",
    "STEPS_PER_TURN": "",
    "ROTATE_SPEED": "",
    "NEW_AI": "",
    "ASK_AND_PAINT": "",
    "GET_IDENTIFIED_NUM": "",
    "READ_NUM_AND_SAY": "",
    "IMAGE_ADDR": "",
    "IMAGE_PREVIEW": "",
    "IMAGE_OPEN": "",
    "IMAGE_SIZE": "",
    "IMAGE_WIDTH": "",
    "IMAGE_HEIGHT": "",
    "VARIABLE": "",
    "VARIABLE_LIST": "",
    "SET_STRING_VARIABLE": "",
    "STRING_START_WITH": "",
    "DICTIONARY_HEIGHT_FIRST": "",
    "DICTIONARY_HEIGHT_TWO": "",
    "DICTIONARY_HEIGHT_THREE": "",
    "DICTIONARY_HEIGHT_FOUR": "",
    "DATA_MPITEMOFLIST": "",
    "DATA_MPINTERCEPTITEM": "",
    "DATA_MPINSERTATLIST": "",
    "DATA_MPSETVALUEATLIST": "",
    "DATA_MPINSERTATLIST_INSERT": "",
    "DATA_MPINSERTATLIST_CHANGE": "",
    "DATA_MPINSERTATLIST_FOUR": "",
    "DATA_MPINSERTATLIST_FIVE": "",
    "DATA_MPINSERTATLIST_SIX": "",
    "DATA_MPINSERTATLIST_SEVEN": "",
    "DATA_MPINSERTATLIST_EIGHT": "",
    "DATA_MPINSERTATLIST_EIGHT_FALSE": "",
    "DATA_MPINSERTATLIST_EIGHT_TRUE": "",
    "DATA_MPINSERTATLIST_EIGHT_REVERSE": "",
    "OPERATORS_RANDOMA": "",
    "OPERATORS_MODA": "",
    "OPERATORS_ROUNDA": "",
    "OPERATORS_MATHOPA": "",
    "OPERATORS_MATHOP_ABSA": "",
    "OPERATORS_MATHOP_FLOORA": "",
    "OPERATORS_MATHOP_CEILINGA": "",
    "OPERATORS_MATHOP_SQRTA": "",
    "OPERATORS_MATHOP_FIVE": "",
    "OPERATORS_MATHOP_SIX": "",
    "OPERATORS_MATHOP_SEVEN": "",
    "OPERATORS_MATHOP_LENGHT": "",
    "OPERATORS_MATHOP_MAXMIN": "",
    "OPERATORS_MATHOP_MAXMIN_MAX": "",
    "OPERATORS_MATHOP_MAXMIN_MIN": "",
    "OPERATORS_TEXTS_ONE": "",
    "OPERATORS_TEXTS_TWO": "",
    "OPERATORS_TEXTS_THREE": "",
    "OPERATORS_TEXTS_FOUR": "",
    "OPERATORS_TEXTS_FOUR_NEW": "",
    "OPERATORS_TEXTS_NEW": "",
    "OPERATORS_TEXTS_NEW_OP": "",
    "OPERATORS_TEXTS_FOUR_ONE": "",
    "OPERATORS_TEXTS_FOUR_TWO": "",
    "OPERATORS_TEXTS_FOUR_THREE": "",
    "OPERATORS_TEXTS_FOUR_FOUR": "",
    "OPERATORS_TEXTS_FOUR_F": "",
    "OPERATORS_TEXTS_FOUR_L": "",
    "OPERATORS_STRINGTONUMBERA": "",
    "OPERATORS_NUMTOASCIIA": "",
    "OPERATORS_STRINGTOASCIIA": "",
    "OPERATORS_NUMTOSTRINGA": "",
    "NEW_MC": "",
    "RENAME_MCNUMBER_TITLE": "",
    "RENAME_MCNUMBER_MODAL_TITLE": "",
    "RENAME_VARIABLE_MCNUMBER": "",
    "DELETE_VARIABLE_MCNUMBER": "",
    "SET_MC_VARIABLE": "",
    "TUPLE_ONE": "",
    "TUPLE_TWO": "",
    "ALIYUN": "",
    "ONENET": "",
    "EASYIOT": "",
    "SHANGHAI": "",
    "QINGDAO": "",
    "BEIJING": "",
    "ZHANGJIAKOU": "",
    "HUHEHAOTE": "",
    "HANGZHOU": "",
    "SHENZHEN": "",
    "HONGKONG": "",
    "SINGAPORE": "",
    "SYDNEY": "",
    "KUALALUMPUR": "",
    "JAKARTA": "",
    "MUMBAI": "",
    "TOKYO": "",
    "SILICONVALLEY": "",
    "VIRGINIA": "",
    "FRANKFURT": "",
    "LONDON": "",
    "DUBAI": "",
    "IOT_PLATFORM": "",
    "PARAMS": "",
    "SERVER_ATTR": "",
    "PRODUCTID": "",
    "DEVICEID": "",
    "TINYDB_SECRET": "",
    "TINYDB_USER": "",
    "APIADDR": "",
    "SOFTWARE_SERIAL": "",
    "HARDWARE_SERIAL": "",
    "HARDWARE_SERIAL1": "",
    "HARDWARE_SERIAL2": "",
    "HARDWARE_SERIAL3": ""
  },
  "el": {
    "CONTROL_FOREVER": "για πάντα",
    "CONTROL_LOOP_BREAKCONTINUE": "%1",
    "CONTROL_LOOP_BREAKCONTINUE_BREAK": "break",
    "CONTROL_LOOP_BREAKCONTINUE_CONTINUE": "continue",
    "CONTROL_REPEAT": "επανάλαβε %1",
    "CONTROL_IF": "εάν %1 τότε",
    "CONTROL_ELSE": "αλλιώς",
    "CONTROL_STOP": "σταμάτησε",
    "CONTROL_STOP_ALL": "όλα",
    "CONTROL_STOP_THIS": "αυτό το σενάριο",
    "CONTROL_STOP_OTHER": "άλλα σενάρια σε αυτό το αντικείμενο",
    "CONTROL_WAIT": "περίμενε %1 δευτερόλεπτα",
    "CONTROL_WAIT_ESP32_MPY": "wait %1 %2",
    "CONTROL_FOR_FROM_SEQUENCE": "use %1 from sequence %2",
    "CONTROL_WAIT_ESP32_MPY_S": "second(s)",
    "CONTROL_WAIT_ESP32_MPY_MS": "millisecond(ms)",
    "CONTROL_WAIT_ESP32_MPY_US": "microsecond(us)",
    "CONTROL_WAITUNTIL": "περίμενε ώσπου %1",
    "CONTROL_REPEATUNTIL": "επανάλαβε ώσπου %1",
    "CONTROL_WHILE": "όσο %1",
    "CONTROL_FOREACH": "για κάθε %1 στο %2",
    "CONTROL_STARTASCLONE": "όταν ξεκινήσω ως κλώνος",
    "CONTROL_CREATECLONEOF": "δημιούργησε κλώνο του %1",
    "CONTROL_CREATECLONEOF_MYSELF": "εαυτού μου",
    "CONTROL_DELETETHISCLONE": "διάγραψε αυτόν τον κλώνο",
    "CONTROL_COUNTER": "μετρητής",
    "CONTROL_INCRCOUNTER": "αύξηση μετρητή",
    "CONTROL_CLEARCOUNTER": "καθαρισμός μετρητή",
    "CONTROL_ALLATONCE": "μονομιάς",
    "DATA_SETVARIABLETO": "όρισε %1 σε %2",
    "DATA_CHANGEVARIABLEBY": "άλλαξε %1 κατά %2",
    "DATA_SHOWVARIABLE": "εμφάνισε μεταβλητή %1",
    "DATA_HIDEVARIABLE": "απόκρυψε μεταβλητή %1",
    "DATA_ADDTOLIST": "πρόσθεσε %1 στη λίστα %2",
    "DATA_DELETEOFLIST": "διάγραψε %1 από λίστα %2",
    "DATA_DELETEALLOFLIST": "διαγραφή όλων από λίστα %1",
    "DATA_INSERTATLIST": "βάλε %1 στη θέση %2 λίστας %3",
    "DATA_REPLACEITEMOFLIST": "αντικατάστησε στοιχείο %1 λίστας %2 με %3",
    "DATA_ITEMOFLIST": "στοιχείο %1 λίστας %2",
    "DATA_ITEMNUMOFLIST": "# στοιχείου %1 σε %2",
    "DATA_LENGTHOFLIST": "μήκος λίστας %1",
    "DATA_LISTCONTAINSITEM": "%1 περιέχει %2;",
    "DATA_SHOWLIST": "εμφάνισε λίστα %1",
    "DATA_HIDELIST": "απόκρυψε λίστα %1",
    "DATA_INDEX_ALL": "όλα",
    "DATA_INDEX_LAST": "τελευταίο",
    "DATA_INDEX_RANDOM": "τυχαίο",
    "EVENT_WHENFLAGCLICKED": "όταν γίνει κλικ σε %1",
    "EVENT_WHENTHISSPRITECLICKED": "όταν γίνει κλικ σε αυτό το αντικείμενο",
    "EVENT_WHENSTAGECLICKED": "όταν γίνει κλικ στη σκηνή",
    "EVENT_WHENTOUCHINGOBJECT": "όταν αυτό το αντικείμενο αγγίξει το %1",
    "EVENT_WHENBROADCASTRECEIVED": "όταν λάβω %1",
    "EVENT_WHENBACKDROPSWITCHESTO": "όταν το υπόβαθρο αλλάξει σε %1",
    "EVENT_WHENGREATERTHAN": "όταν %1 > %2",
    "EVENT_WHENGREATERTHAN_TIMER": "χρονομέτρο",
    "EVENT_WHENGREATERTHAN_LOUDNESS": "ένταση",
    "EVENT_BROADCAST": "μετάδωσε %1",
    "EVENT_BROADCASTANDWAIT": "μετάδωσε %1 και περίμενε",
    "EVENT_WHENKEYPRESSED": "όταν πατηθεί πλήκτρο %1",
    "EVENT_WHENKEYPRESSED_SPACE": "διάστημα",
    "EVENT_WHENKEYPRESSED_LEFT": "αριστερό βέλος",
    "EVENT_WHENKEYPRESSED_RIGHT": "δεξί βέλος",
    "EVENT_WHENKEYPRESSED_DOWN": "κάτω βέλος",
    "EVENT_WHENKEYPRESSED_UP": "πάνω βέλος",
    "EVENT_WHENKEYPRESSED_ANY": "οποιοδήποτε",
    "LOOKS_SAYFORSECS": "πες %1 για %2 δευτερόλεπτα",
    "LOOKS_SAY": "πες %1",
    "LOOKS_HELLO": "Γεια!",
    "LOOKS_THINKFORSECS": "σκέψου %1 για %2 δευτερόλεπτα",
    "LOOKS_THINK": "σκέψου %1",
    "LOOKS_HMM": "Χμμ...",
    "LOOKS_SHOW": "εμφανίσου",
    "LOOKS_HIDE": "εξαφανίσου",
    "LOOKS_HIDEALLSPRITES": "απόκρυψη όλων των αντικειμένων",
    "LOOKS_EFFECT_COLOR": "χρώματος",
    "LOOKS_EFFECT_FISHEYE": "κυρτότητας",
    "LOOKS_EFFECT_WHIRL": "δίνης",
    "LOOKS_EFFECT_PIXELATE": "εικονοστοιχειοποίησης",
    "LOOKS_EFFECT_MOSAIC": "ψηφιδωτού",
    "LOOKS_EFFECT_BRIGHTNESS": "φωτεινότητας",
    "LOOKS_EFFECT_GHOST": "φαντάσματος",
    "LOOKS_CHANGEEFFECTBY": "άλλαξε εφέ %1 κατά %2",
    "LOOKS_SETEFFECTTO": "όρισε εφέ %1 σε %2",
    "LOOKS_CLEARGRAPHICEFFECTS": "επανάφερε εφέ γραφικών",
    "LOOKS_CHANGESIZEBY": "άλλαξε μέγεθος κατά %1",
    "LOOKS_SETSIZETO": "όρισε μέγεθος σε %1 %",
    "LOOKS_SIZE": "μέγεθος",
    "LOOKS_CHANGESTRETCHBY": "άλλαξε τέντωμα κατά %1",
    "LOOKS_SETSTRETCHTO": "όρισε τέντωμα σε %1 %",
    "LOOKS_SWITCHCOSTUMETO": "άλλαξε ενδυμασία σε %1",
    "LOOKS_NEXTCOSTUME": "επόμενη ενδυμασία",
    "LOOKS_SWITCHBACKDROPTO": "άλλαξε υπόβαθρο σε %1",
    "LOOKS_GOTOFRONTBACK": "πήγαινε σε επίπεδο %1",
    "LOOKS_GOTOFRONTBACK_FRONT": "προσκήνιο",
    "LOOKS_GOTOFRONTBACK_BACK": "υπόβαθρο",
    "LOOKS_GOFORWARDBACKWARDLAYERS": "πήγαινε %1 %2 επίπεδα",
    "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "μπροστά",
    "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "πίσω",
    "LOOKS_BACKDROPNUMBERNAME": "υπόβαθρο %1",
    "LOOKS_COSTUMENUMBERNAME": "ενδυμασία %1",
    "LOOKS_NUMBERNAME_NUMBER": "νούμερο",
    "LOOKS_NUMBERNAME_NAME": "όνομα",
    "LOOKS_SWITCHBACKDROPTOANDWAIT": "άλλαξε υπόβαθρο σε %1 και περίμενε",
    "LOOKS_NEXTBACKDROP_BLOCK": "επόμενο υπόβαθρο",
    "LOOKS_NEXTBACKDROP": "επόμενο υπόβαθρο",
    "LOOKS_PREVIOUSBACKDROP": "προηγούμενο υπόβαθρο",
    "LOOKS_RANDOMBACKDROP": "τυχαίο υπόβαθρο",
    "MOTION_MOVESTEPS": "κινήσου %1 βήματα",
    "MOTION_TURNLEFT": "στρίψε %1 %2 μοίρες",
    "MOTION_TURNRIGHT": "στρίψε %1 %2 μοίρες",
    "MOTION_POINTINDIRECTION": "δείξε προς κατεύθυνση %1",
    "MOTION_POINTTOWARDS": "δείξε προς %1",
    "MOTION_POINTTOWARDS_POINTER": "δείκτη ποντικιού",
    "MOTION_POINTTOWARDS_RANDOM": "τυχαία κατεύθυνση",
    "MOTION_GOTO": "πήγαινε σε %1",
    "MOTION_GOTO_POINTER": "δείκτη ποντικιού",
    "MOTION_GOTO_RANDOM": "τυχαία θέση",
    "MOTION_GOTOXY": "πήγαινε σε θέση x: %1 y: %2",
    "MOTION_GLIDESECSTOXY": "ολίσθησε για %1 δευτ. στη θέση x: %2 y: %3",
    "MOTION_GLIDETO": "ολίσθησε για %1 δευτ. στη θέση %2",
    "MOTION_GLIDETO_POINTER": "δείκτη ποντικιού",
    "MOTION_GLIDETO_RANDOM": "τυχαία θέση",
    "MOTION_CHANGEXBY": "άλλαξε x κατά %1",
    "MOTION_SETX": "όρισε x σε %1",
    "MOTION_CHANGEYBY": "άλλαξε y κατά %1",
    "MOTION_SETY": "όρισε y σε %1",
    "MOTION_IFONEDGEBOUNCE": "εάν σε όριο, αναπήδησε",
    "MOTION_SETROTATIONSTYLE": "όρισε τρόπο περιστροφής %1",
    "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "αριστερά-δεξιά",
    "MOTION_SETROTATIONSTYLE_DONTROTATE": "μην περιστρέψεις",
    "MOTION_SETROTATIONSTYLE_ALLAROUND": "τριγύρω",
    "MOTION_XPOSITION": "θέση x",
    "MOTION_YPOSITION": "θέση y",
    "MOTION_DIRECTION": "κατεύθυνση",
    "MOTION_SCROLLRIGHT": "κύλιση δεξιά %1",
    "MOTION_SCROLLUP": "κύλιση πάνω %1",
    "MOTION_ALIGNSCENE": "ευθυγράμμιση σκηνής %1",
    "MOTION_ALIGNSCENE_BOTTOMLEFT": "κάτω-αριστερά",
    "MOTION_ALIGNSCENE_BOTTOMRIGHT": "κάτω-δεξιά",
    "MOTION_ALIGNSCENE_MIDDLE": "μέση",
    "MOTION_ALIGNSCENE_TOPLEFT": "πάνω-αριστερά",
    "MOTION_ALIGNSCENE_TOPRIGHT": "πάνω-δεξιά",
    "MOTION_XSCROLL": "κύλιση x",
    "MOTION_YSCROLL": "κύλιση y",
    "MOTION_STAGE_SELECTED": "Σκηνή επιλεγμένη: χωρίς εντολές κίνησης",
    "OPERATORS_ADD": "%1 + %2",
    "OPERATORS_SUBTRACT": "%1 - %2",
    "OPERATORS_MULTIPLY": "%1 * %2",
    "OPERATORS_DIVIDE": "%1 / %2",
    "OPERATORS_RANDOM": "επίλεξε τυχαίο %1 εώς %2",
    "OPERATORS_GT": "%1 > %2",
    "OPERATORS_GT_EQUALS": "%1 >= %2",
    "OPERATORS_LT": "%1 < %2",
    "OPERATORS_LT_EQUALS": "%1 <= %2",
    "OPERATORS_EQUALS": "%1 = %2",
    "OPERATORS_AND": "%1 και %2",
    "OPERATORS_OR": "%1 ή %2",
    "OPERATORS_NOT": "όχι %1",
    "CONTROL_TRUE_FALSE": "%1",
    "CONTROL_TRUE_FALSE_TRUE": "true",
    "CONTROL_TRUE_FALSE_FALSE": "false",
    "CONTROL_NONE": "None",
    "CONTROL_RETURN": "return %1",
    "OPERATORS_VALUE_IS_TRUE": "value %1 is true?",
    "OPERATORS_VALUE_TYPE": "value %1 type",
    "CONTROL_TRY": "try",
    "CONTROL_EXCEPT": "except",
    "CONTROL_FINALLY": "finally",
    "OPERATORS_VALUE_TYPE_IS": "value %1 type is %2?",
    "OPERATORS_VALUE_TYPE_INT": "int",
    "OPERATORS_VALUE_TYPE_FLOAT": "float",
    "OPERATORS_VALUE_TYPE_BOOL": "bool",
    "OPERATORS_VALUE_TYPE_STR": "str",
    "OPERATORS_VALUE_TYPE_LIST": "list",
    "OPERATORS_VALUE_TYPE_TUPLE": "tuple",
    "OPERATORS_VALUE_TYPE_SET": "set",
    "OPERATORS_VALUE_TYPE_DICT": "dict",
    "OPERATORS_VALUE_TYPE_BYTES": "bytes",
    "OPERATORS_VALUE_TYPE_BYTEARRAY": "bytearray",
    "OPERATORS_VALUE_TYPE_COMPLEX": "complex",
    "OPERATORS_CACULATE_RESULT": "eval %1",
    "OPERATORS_JOIN": "ένωσε %1 %2",
    "OPERATORS_JOIN_APPLE": "μήλο",
    "OPERATORS_JOIN_BANANA": "μπανάνα",
    "OPERATORS_LETTEROF": "γράμμα %1 του %2",
    "OPERATORS_LETTEROF_APPLE": "ο",
    "OPERATORS_LENGTH": "μήκος του %1",
    "OPERATORS_CONTAINS": "%1 περιλαμβάνει %2;",
    "OPERATORS_MOD": "ακέρ. υπόλ. %1 δια %2",
    "OPERATORS_ROUND": "στρογγυλ. %1",
    "OPERATORS_MATHOP": "%1 του %2",
    "OPERATORS_MATHOP_ABS": "απόλυτη τιμή",
    "OPERATORS_MATHOP_FLOOR": "στρογγυλ. κάτω",
    "OPERATORS_MATHOP_CEILING": "στρογγυλ. άνω",
    "OPERATORS_MATHOP_SQRT": "τετρ. ρίζα",
    "OPERATORS_MATHOP_SIN": "ημ",
    "OPERATORS_MATHOP_COS": "συν",
    "OPERATORS_MATHOP_TAN": "εφ",
    "OPERATORS_MATHOP_ASIN": "τοξημ",
    "OPERATORS_MATHOP_ACOS": "τοξσυν",
    "OPERATORS_MATHOP_ATAN": "τοξεφ",
    "OPERATORS_MATHOP_LN": "ln",
    "OPERATORS_MATHOP_LOG": "log",
    "OPERATORS_MATHOP_EEXP": "e ^",
    "OPERATORS_MATHOP_10EXP": "10 ^",
    "PROCEDURES_DEFINITION": "ορισμός %1",
    "SENSING_TOUCHINGOBJECT": "αγγίζει %1;",
    "SENSING_TOUCHINGOBJECT_POINTER": "δείκτη ποντικιού",
    "SENSING_TOUCHINGOBJECT_EDGE": "όριο",
    "SENSING_TOUCHINGCOLOR": "αγγίζει χρώμα %1;",
    "SENSING_COLORISTOUCHINGCOLOR": "χρώμα %1 αγγίζει χρώμα %2;",
    "SENSING_DISTANCETO": "απόσταση έως %1",
    "SENSING_DISTANCETO_POINTER": "δείκτη ποντικιού",
    "SENSING_ASKANDWAIT": "ρώτησε %1 και περίμενε",
    "SENSING_ASK_TEXT": "Πώς σε λένε;",
    "SENSING_ANSWER": "απάντηση",
    "SENSING_KEYPRESSED": "πατήθηκε πλήκτρο %1;",
    "SENSING_MOUSEDOWN": "πατήθηκε πλήκτρο ποντικιού;",
    "SENSING_MOUSEX": "x ποντικιού",
    "SENSING_MOUSEY": "y ποντικιού",
    "SENSING_SETDRAGMODE": "όρισε τρόπο συρσίματος %1",
    "SENSING_SETDRAGMODE_DRAGGABLE": "συρόμενο",
    "SENSING_SETDRAGMODE_NOTDRAGGABLE": "μη συρόμενο",
    "SENSING_LOUDNESS": "ένταση",
    "SENSING_LOUD": "δυνατά;",
    "SENSING_TIMER": "χρονόμετρο",
    "SENSING_RESETTIMER": "μηδένισε χρονόμετρο",
    "SENSING_OF": "%1 από %2",
    "SENSING_OF_XPOSITION": "θέση x",
    "SENSING_OF_YPOSITION": "θέση y",
    "SENSING_OF_DIRECTION": "κατεύθυνση",
    "SENSING_OF_COSTUMENUMBER": "# ενδυμασίας",
    "SENSING_OF_COSTUMENAME": "όνομα ενδυμασίας",
    "SENSING_OF_SIZE": "μέγεθος",
    "SENSING_OF_VOLUME": "ένταση",
    "SENSING_OF_BACKDROPNUMBER": "# υποβάθρου",
    "SENSING_OF_BACKDROPNAME": "όνομα υποβάθρου",
    "SENSING_OF_STAGE": "Σκηνή",
    "SENSING_CURRENT": "τρέχων %1",
    "SENSING_CURRENT_YEAR": "έτος",
    "SENSING_CURRENT_MONTH": "μήνας",
    "SENSING_CURRENT_DATE": "ημερομηνία",
    "SENSING_CURRENT_DAYOFWEEK": "μέρα της εβδομάδας",
    "SENSING_CURRENT_HOUR": "ώρα",
    "SENSING_CURRENT_MINUTE": "λεπτό",
    "SENSING_CURRENT_SECOND": "δευτερόλεπτο",
    "SENSING_DAYSSINCE2000": "ημέρες από το 2000",
    "SENSING_USERNAME": "όνομα χρήστη",
    "SENSING_USERID": "αναγνωριστικό χρήστη",
    "SOUND_PLAY": "παίξε τον ήχο %1",
    "SOUND_PLAYUNTILDONE": "παίξε ήχο %1 μέχρι τέλους",
    "SOUND_STOPALLSOUNDS": "σταμάτησε όλους τους ήχους",
    "SOUND_SETEFFECTO": "όρισε εφέ %1 σε %2",
    "SOUND_CHANGEEFFECTBY": "άλλαξε εφέ %1 κατά %2",
    "SOUND_CLEAREFFECTS": "καθάρισε ηχητικά εφέ",
    "SOUND_EFFECTS_PITCH": "τόνος",
    "SOUND_EFFECTS_PAN": "μετατόπιση αριστερά/δεξιά",
    "SOUND_CHANGEVOLUMEBY": "άλλαξε ένταση κατά %1",
    "SOUND_SETVOLUMETO": "όρισε ένταση σε %1%",
    "SOUND_VOLUME": "ένταση",
    "SOUND_RECORD": "εγγραφή...",
    "CATEGORY_MOTION": "Κίνηση",
    "CATEGORY_LOOKS": "Όψεις",
    "CATEGORY_SOUND": "Ήχος",
    "CATEGORY_EVENTS": "Συμβάντα",
    "CATEGORY_CONTROL": "Έλεγχος",
    "CATEGORY_SENSING": "Αισθητήρες",
    "CATEGORY_OPERATORS": "Τελεστές",
    "CATEGORY_VARIABLES": "Μεταβλητές",
    "CATEGORY_MYBLOCKS": "Οι Εντολές μου",
    "CATEGORY_NUMBERS": "Αριθμός",
    "CATEGORY_TEXT": "Κείμενο",
    "DUPLICATE": "Διπλασιασμός",
    "DELETE": "Διαγραφή",
    "ADD_COMMENT": "Προσθήκη σχολίου",
    "REMOVE_COMMENT": "Αφαίρεση σχολίου",
    "DELETE_BLOCK": "Διαγραφή Εντολής",
    "DELETE_X_BLOCKS": "Διαγραφή %1 Εντολών",
    "DELETE_ALL_BLOCKS": "Διαγραφή όλων των %1 εντολών;",
    "CLEAN_UP": "Τακτοποίησε Εντολές",
    "HELP": "Βοήθεια",
    "UNDO": "Αναίρεση",
    "REDO": "Επανάληψη",
    "EDIT_PROCEDURE": "Επεξεργασία",
    "SHOW_PROCEDURE_DEFINITION": "Μετάβαση σε ορισμό",
    "WORKSPACE_COMMENT_DEFAULT_TEXT": "Πες κάτι...",
    "COLOUR_HUE_LABEL": "Χρώμα",
    "COLOUR_SATURATION_LABEL": "Κορεσμός",
    "COLOUR_BRIGHTNESS_LABEL": "Φωτεινότητα",
    "CHANGE_VALUE_TITLE": "Αλλαγή τιμής:",
    "RENAME_VARIABLE": "Μετονομασία μεταβλητής",
    "RENAME_VARIABLE_STRING": "Μετονομασία μεταβλητής κειμένου",
    "RENAME_VARIABLE_NUMBER": "Μετονομασία αριθμητικής μεταβλητής",
    "RENAME_VARIABLE_LIST": "Μετανομασία λίστας",
    "RENAME_VARIABLE_TITLE": "Μετονομασία όλων των \"%1\" μεταβλητών σε:",
    "RENAME_VARIABLE_MODAL_TITLE": "Μετονομασία Μεταβλητής",
    "NEW_VARIABLE": "Δημιουργία Μεταβλητής",
    "NEW_VARIABLE_TITLE": "Όνομα νέας μεταβλητής:",
    "VARIABLE_MODAL_TITLE": "Νέα Μεταβλητή",
    "VARIABLE_ALREADY_EXISTS": "Υπάρχει ήδη μια μεταβλητή με όνομα \"%1\".",
    "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "Υπάρχει ήδη μια μεταβλητή με όνομα \"%1\" για μια άλλη μεταβλητή τύπου \"%2\".",
    "DELETE_VARIABLE_CONFIRMATION": "Διαγραφή %1 χρήσεων της μεταβλητής \"%2\";",
    "CANNOT_DELETE_VARIABLE_PROCEDURE": "Η μεταβλητή \"%1\" δεν μπορεί να διαγραφεί γιατί είναι μέρος του ορισμού της λειτουργίας \"%2\"",
    "DELETE_VARIABLE": "Διαγραφή της μεταβλητής \"%1\"",
    "DELETE_VARIABLE_STRING": "Σβήσε τη μεταβλητή κειμένου「%1」",
    "DELETE_VARIABLE_NUMBER": "Σβήσε την αριθμητική μεταβλητή 「%1」",
    "DELETE_VARIABLE_LIST": "Delete the「%1」 list variable",
    "NEW_PROCEDURE": "Δημιουργία Εντολής",
    "PROCEDURE_ALREADY_EXISTS": "Υπάρχει ήδη μια διαδικασία με όνομα \"%1\".",
    "PROCEDURE_DEFAULT_NAME": "όνομα εντολής",
    "NEW_LIST": "Δημιουργία Λίστας",
    "NEW_LIST_TITLE": "Όνομα νέας λίστας:",
    "LIST_MODAL_TITLE": "Νέα Λίστα",
    "LIST_ALREADY_EXISTS": "Υπάρχει ήδη μια λίστα με όνομα \"%1\".",
    "RENAME_LIST_TITLE": "Μετονόμασε όλες τις λίστες \"%1\" σε:",
    "RENAME_LIST_MODAL_TITLE": "Μετονομασία Λίστας",
    "DEFAULT_LIST_ITEM": "πράγμα",
    "DELETE_LIST": "Διάγραψε τη λίστα \"%1\"",
    "RENAME_LIST": "Μετονομασία λίστας",
    "NEW_BROADCAST_MESSAGE": "Νέο μήνυμα",
    "NEW_BROADCAST_MESSAGE_TITLE": "Όνομα νέου μηνύματος:",
    "BROADCAST_MODAL_TITLE": "Νέο μήνυμα",
    "DEFAULT_BROADCAST_MESSAGE_NAME": "μήνυμα1",
    "DELETE_PROCEDURE": "To delete a block definition, first remove all uses of the block",
    "OK": "Εντάξει",
    "CANCEL": "Ακύρωση",
    "PROMPT": "Prompt",
    "STOP": "σταμάτα",
    "NEW_STRING": "Δημιουργησε μεταβλητή κειμένου (string)",
    "NEW_NUMBER_VARIABLE": "Δημιούργησε αριθμητική μεταβλητή",
    "WIFI": "WIFI",
    "USERNAME": "χρήστης",
    "PWD": "κωδικός",
    "IOT_SERVICE": "IOT Service",
    "IOT_ID": "iot id",
    "IOT_PWD": "iot pwd",
    "IOT_TOPIC": "Topic_0",
    "MORE_TOPIC": "Topic",
    "OBLOQ_HTTP": "HTTP",
    "OBLOQ_IP": "IP Address",
    "OBLOQ_PORT": "Port",
    "ADD_TOPIC": "Add Topic, can only add up to 4",
    "DELETE_TOPIC": "Delete Topic",
    "IOT_SERVER": "Server",
    "CHINA": "China",
    "GLOBAL": "Global",
    "LOW_C": "Low C/C3",
    "LOW_C$": "Low C#/C#3",
    "LOW_D": "Low D/D3",
    "LOW_D$": "Low D#/D#3",
    "LOW_E": "Low E/E3",
    "LOW_F": "Low F/F3",
    "LOW_F$": "Low F#/F#3",
    "LOW_G": "Low G/G3",
    "LOW_G$": "Low G#/G#3",
    "LOW_A": "Low A/A3",
    "LOW_A$": "Low A#/A#3",
    "LOW_B": "Low B/B3",
    "MIDDLE_C": "Middle C/C4",
    "MIDDLE_C$": "Middle C#/C#4",
    "MIDDLE_D": "Middle D/D4",
    "MIDDLE_D$": "Middle D#/D#4",
    "MIDDLE_E": "Middle E/E4",
    "MIDDLE_F": "Middle F/F4",
    "MIDDLE_F$": "Middle F#/F#4",
    "MIDDLE_G": "Middle G/G4",
    "MIDDLE_G$": "Middle G#/G#4",
    "MIDDLE_A": "Middle A/A4",
    "MIDDLE_A$": "Middle A#/A#4",
    "MIDDLE_B": "Middle B/B4",
    "HIGH_C": "High C/C5",
    "HIGH_C$": "High C#/C#5",
    "HIGH_D": "High D/D5",
    "HIGH_D$": "High D#/D#5",
    "HIGH_E": "High E/E5",
    "HIGH_F": "High F/F5",
    "HIGH_F$": "High F#/F#5",
    "HIGH_G": "High G/G5",
    "HIGH_G$": "High G#/G#5",
    "HIGH_A": "High A/A5",
    "HIGH_A$": "High A#/A#5",
    "HIGH_B": "High B/B5",
    "OPERATORS_STRINGTONUMBER": "μετέτρεψε το κείμενο %1 σε %2",
    "INTEGER": "Ακέραιος",
    "DECIMAL": "Δεκαδικός",
    "OPERATORS_NUMTOASCII": "μετέτρεψε το αριθμό %1 σε ASCII",
    "OPERATORS_STRINGTOASCII": "μετέτρεψε το κείμενο %1 σε ASCII",
    "OPERATORS_NUMTOSTRING": "μετέτρεψε τον αριθμό %1 σε κείμενο",
    "OPERATORS_MAP": "αντιστοίχησε %1 από[ %2 , %3 ] σε[ %4 , %5 ]",
    "OPERATORS_CONSTRAIN": "διατήρησε %1 μεταξύ (min) %2 και(max) %3",
    "STEPS_PER_TURN": "steps per turn",
    "ROTATE_SPEED": "ταχύτητα περιστροφής(r/s)",
    "NEW_AI": "Make an AI Block",
    "ASK_AND_PAINT": "παρακαλώ γράψτε ένα αριθμό",
    "GET_IDENTIFIED_NUM": "number recognition",
    "READ_NUM_AND_SAY": "speak out the recognized number",
    "IMAGE_ADDR": "Image Addr",
    "IMAGE_PREVIEW": "Image Preview",
    "IMAGE_OPEN": "άνοιξε",
    "IMAGE_SIZE": "Image Size",
    "IMAGE_WIDTH": "W",
    "IMAGE_HEIGHT": "H",
    "VARIABLE": "%1",
    "VARIABLE_LIST": "%1",
    "SET_STRING_VARIABLE": "set %1 to %2",
    "STRING_START_WITH": "%1 start with %2",
    "OPERATORS_RANDOMA": "%1 %2 τυχαίος αριθμός %3 σε %4 %5",
    "OPERATORS_MODA": "%1 %2 %3 mod %4",
    "OPERATORS_ROUNDA": "%1 %2 στρογγυλοποίησε %3",
    "OPERATORS_MATHOPA": "%1 %2 %3 %4",
    "OPERATORS_MATHOP_ABSA": "abs",
    "OPERATORS_MATHOP_FLOORA": "ceiling",
    "OPERATORS_MATHOP_CEILINGA": "floor",
    "OPERATORS_MATHOP_SQRTA": "sqrt",
    "OPERATORS_MATHOP_FIVE": "%1 %2 change %3 by %4",
    "OPERATORS_MATHOP_SIX": "%1 %2 map %3 from[ %4 , %5 ] to[ %6 , %7 ]",
    "OPERATORS_MATHOP_SEVEN": "%1 %2 constrain %3 between(min) %4 and(max) %5",
    "OPERATORS_NUM_INPUT": "%1 %2 number %3",
    "OPERATORS_NUMS_CACULATE": "%1 %2 %3",
    "OPERATORS_MATHOP_SQUARE": "**",
    "OPERATORS_MATHOP_LOGIC_OR": "|",
    "OPERATORS_MATHOP_LOGIC_AND": "&",
    "OPERATORS_MATHOP_LOGIC_POWER": "^",
    "OPERATORS_MATHOP_LOGIC_LEFT": "<<",
    "OPERATORS_MATHOP_LOGIC_RIGHT": ">>",
    "OPERATORS_SPECIAL_NUM": "%1 %2 %3",
    "OPERATORS_MATHOP_PI": "π",
    "OPERATORS_MATHOP_LOGARITHM": "e",
    "OPERATORS_MATHOP_FAI": "φ",
    "OPERATORS_MATHOP_SQRT2": "sqrt(2)",
    "OPERATORS_MATHOP_SQRTHALF": "sqrt(½)",
    "OPERATORS_MATHOP_UNLIMITED": "∞",
    "OPERATORS_NUMS_TYPE": "%1 %2 %3 %4?",
    "OPERATORS_MATHOP_ISEVEN": "is even",
    "OPERATORS_MATHOP_ISODD": "is odd",
    "OPERATORS_MATHOP_ISINT": "is int",
    "OPERATORS_MATHOP_ISPOSITIVE": "is positive",
    "OPERATORS_MATHOP_ISNEGATIVE": "is negative",
    "OPERATORS_SAVE2DECIMAL": "%1 %2 %3 save %4 decimal places",
    "OPERATORS_LIST_CACULATE": "%1 %2 %3 %4",
    "OPERATORS_MATHOP_LIST_SUM": "sum of the numbers in the list",
    "OPERATORS_MATHOP_LIST_MIN": "minimum value in list",
    "OPERATORS_MATHOP_LIST_MAX": "maximum value in the list",
    "OPERATORS_MATHOP_LIST_AVERAGE": "average in list",
    "OPERATORS_MATHOP_LIST_NUM": "number of digits in the list",
    "OPERATORS_MATHOP_LIST_MODE": "list mode",
    "OPERATORS_MATHOP_LIST_STDDEVIATION": "standard deviation in the list",
    "OPERATORS_MATHOP_LIST_RANDOM": "random item of list",
    "OPERATORS_QUOTIENT_INT": "%1 %2 %3 ÷ %4 integer part of quotient",
    "OPERATORS_RANDOM_DECIMAL": "%1 %2 random decimal",
    "OPERATORS_BIN_NEGATION": "%1 %2 binary negation %3",
    "OPERATORS_INT_TO_STR": "%1 %2 decimal integer %3 transform into %4 string",
    "OPERATORS_MATHOP_INTTOSTR_BIN": "binary",
    "OPERATORS_MATHOP_INTTOSTR_OCT": "octal",
    "OPERATORS_MATHOP_INTTOSTR_HEX": "hex",
    "OPERATORS_BINSTR_TO_INT": "%1 %2 %3 string %4 transform into decimal integer",
    "OPERATORS_INT_TO_BYTE": "%1 %2 decimal integer %3 transform into %4 byte",
    "OPERATORS_MATHOP_INTTOBYTE_TWO": "2",
    "OPERATORS_MATHOP_INTTOBYTE_FOUR": "4",
    "OPERATORS_INT_TO_ASCII": "%1 %2 int %3 transform into ASCII character",
    "OPERATORS_ASCII_TO_INT": "%1 %2 ASCII character %3 to int",
    "OPERATORS_STR_TO_INT": "%1 %2 string %3 to hex string",
    "OPERATORS_HEXSTR_TO_BIN": "%1 %2 hex string %3 to bin byte",
    "OPERATORS_STR_TO_BASE64": "%1 %2 %3 encodes in base64 format returns a byte object",
    "OPERATORS_BASE64_TO_BYTE": "%1 %2 decode base64 data %3 returns a byte object",
    "OPERATORS_MATHOP_LENGHT": "μήκος του %1",
    "OPERATORS_MATHOP_MAXMIN": "%1 %2",
    "OPERATORS_MATHOP_MAXMIN_MAX": "μέγιστο",
    "OPERATORS_MATHOP_MAXMIN_MIN": "ελάχιστο",
    "OPERATORS_TEXTS_ONE": "%1 %2 συνένωσε %3 %4",
    "OPERATORS_TEXTS_TWO": "%1 %2 γράμμα %4 από %3",
    "OPERATORS_TEXTS_THREE": "%1 %2 %3 περιέχει %4 ?",
    "OPERATORS_TEXTS_FOUR": "%1 %2 γράμμα  %5 %4 στο %7 %6 από %3",
    "OPERATORS_TEXTS_FOUR_NEW": "%1 gets %2 %3 characters to %4 %5characters",
    "OPERATORS_TEXTS_NEW": "%1 %2 find %3 στο %4  %5 αρχική θέση",
    "OPERATORS_TEXTS_NEW_OP": "βρες που το κείμενο %1 εμφανίζεται μέσα στο %2 %3",
    "OPERATORS_TEXTS_FOUR_ONE": "από αριστερά",
    "OPERATORS_TEXTS_FOUR_TWO": "από δεξιά",
    "OPERATORS_TEXTS_FOUR_THREE": "από αριστερά",
    "OPERATORS_TEXTS_FOUR_FOUR": "από δεξιά",
    "OPERATORS_TEXTS_FOUR_F": "πρώτο",
    "OPERATORS_TEXTS_FOUR_L": "τελευταίο",
    "OPERATORS_INPUTSTRING": "%1 %2 string %3",
    "OPERATORS_TRANSINTOTEXT": "%1 %2 transform into %3",
    "OPERATORS_TRANSINTOTEXT_ADD": "add",
    "OPERATORS_TRANSINTOTEXT_ADD_ITEM": "item",
    "OPERATORS_FORMATSTRING2F": "%1 %2 format string %3 % %4",
    "OPERATORS_FORMATSTRING3": "%1 %2 format string %3 format %4",
    "OPERATORS_TEXT_ESCAPE": "%1 %2 text escape character %3",
    "BAR_N": "\\n",
    "BAR_NN": "\\n\\r",
    "BAR_R": "\\r",
    "BAR_RN": "\\r\\n",
    "BAR_B": "\\b",
    "BAR_T": "\\t",
    "BAR_BAR": "\\\\",
    "OPERATORS_TEXT_NUMORLETTER": "%1 %2 %3 %4?",
    "OPERATORS_TEXT_NUMORLETTER_NUM": "is number",
    "OPERATORS_TEXT_NUMORLETTER_LETTER": "is letter",
    "OPERATORS_TEXT_LENGTH": "%1 %2 %3 length",
    "OPERATORS_TEXT_ISNONE": "%1 %2 %3 is none?",
    "OPERATORS_FROMTEXT_GETCHAR": "%1 %2 in text %3 %4",
    "OPERATORS_FROMTEXT_GETCHAR_FIRST": "first letter",
    "OPERATORS_FROMTEXT_GETCHAR_LAST": "last letter",
    "OPERATORS_FROMTEXT_GETCHAR_RANDOM": "random letter",
    "OPERATORS_TEXT_UPPLOW": "%1 %2 %3 %4",
    "OPERATORS_TEXT_UPPLOW_UPP": "uppercase",
    "OPERATORS_TEXT_UPPLOW_LOW": "lowercase",
    "OPERATORS_TEXT_SIDES_SPACE": "%1 %2 %3 %4",
    "OPERATORS_TEXT_SIDES_SPACE_BOTH": "clear spaces on both sides",
    "OPERATORS_TEXT_SIDES_SPACE_LEFT": "clear left space",
    "OPERATORS_TEXT_SIDES_SPACE_RIGHT": "clear right space",
    "OPERATORS_TEXT_STRTOBYTE": "%1 %2 turn byte %3",
    "OPERATORS_TEXT_BYTETOSTR": "%1 %2 byte %3 convert to string",
    "OPERATORS_TEXT_DICTTOJSONSTR": "%1 %2 dict %3 transform into json string",
    "OPERATORS_TEXT_PARSEJSONSTR": "%1 %2 parse json string %3 and return object",
    "OPERATORS_STRINGTONUMBERA": "%1 %2  μετέτρεψε το κείμενο %3 σε %4",
    "OPERATORS_NUMTOASCIIA": "%1 %2 μετέτρεψε τον αριθμό %3 σε ASCII χαρακτήρες",
    "OPERATORS_STRINGTOASCIIA": "%1 %2 μετέτρεψε τον χαρακτήρα %3 σε αξία ASCII",
    "OPERATORS_NUMTOSTRINGA": "%1 %2 μετέτρεψε τον αριθμό %3 σε κείμενο",
    "NEW_MC": "make variable",
    "RENAME_MCNUMBER_TITLE": "μετενόμασε όλες τις μεταβλητές που το όνομα τους είναι [%1] σε:",
    "RENAME_MCNUMBER_MODAL_TITLE": "μετονομασία της μεταβλητής",
    "RENAME_VARIABLE_MCNUMBER": "μετονομασία της μεταβλητής",
    "DELETE_VARIABLE_MCNUMBER": "διαγραφή μεταβλητής [%1]",
    "SET_MC_VARIABLE": "θέσε %1 σε %2",
    "ALIYUN": "Aliyun",
    "ONENET": "OneNet",
    "ONENETNEW": "OneNet(New)",
    "EASYIOT": "Easy IoT",
    "SHANGHAI": "Shanghai",
    "QINGDAO": "Qingdao",
    "BEIJING": "Beijing",
    "ZHANGJIAKOU": "Zhangjiakou",
    "HUHEHAOTE": "Huhehaote",
    "HANGZHOU": "Hangzhou",
    "SHENZHEN": "Shenzhen",
    "HONGKONG": "HongKong",
    "SINGAPORE": "Singapore",
    "SYDNEY": "Sydney",
    "KUALALUMPUR": "Luala Lumpur",
    "JAKARTA": "Jakarta",
    "MUMBAI": "Mumbai",
    "TOKYO": "Tokyo",
    "SILICONVALLEY": "Silicon Valley",
    "VIRGINIA": "Virginia",
    "FRANKFURT": "Frankfurt",
    "LONDON": "London",
    "DUBAI": "Dubai",
    "IOT_PLATFORM": "Iot Platform",
    "PARAMS": "Params",
    "SERVER_ATTR": "Server Addr",
    "PRODUCTID": "ProductId",
    "DEVICEID": "DeviceId",
    "DEVICENAME": "DeviceName",
    "TINYDB_SECRET": "secret (secret)",
    "TINYDB_USER": "user (user)",
    "APIADDR": "API Addr",
    "SOFTWARE_SERIAL": "Software Serial",
    "HARDWARE_SERIAL": "Hardware Serial",
    "HARDWARE_SERIAL1": "Hardware Serial1",
    "HARDWARE_SERIAL2": "Hardware Serial2",
    "HARDWARE_SERIAL3": "Hardware Serial3",
    "CHECKTYPE_TIPS": "The data type received by the input box does not match the output type of the building block",
    "HIGHLIGHT_BLOCK": "hight light block",
    "HIGHLIGHT_ALL_CONFLICT_BLOCKS": "highlight all blocks with warnings",
    "SNAPSHOT": "Snapshot (CTRL+G)",
    "pictureaiPath": "please enter the picture path or URL",
    "pictureaiType": "account type",
    "pictureaiAccountDefault": "default account",
    "pictureaiAccountBaidu": "baidu account",
    "pictureaiwebImgAddr": "web image address",
    "MITRIXICON_EDIT": "light up",
    "MITRIXICON_ERASER": "eraser",
    "MITRIXICON_DUSTBIN": "clear",
    "MITRIXICON_REVERSE": "reverse",
    "MITRIXICON_SAVE": "save",
    "MITRIXICON_EXPORT": "export",
    "MITRIXICON_UPLOAD": "upload",
    "ROLL_MOUSE": "Rolling mouse",
    "MOUSE_ZOOM_IN_OUT": "Scroll the mouse to zoom in and out",
    "UPLOAD_CORRECT_JSON_FILE": "Please upload the json file in the correct format",
    "EXPORT_PROMPT": "There are no custom patterns to export. Please click the save button in the lower right corner to save the custom pattern before attempting to export.",
    "HEART": "Heart",
    "HEART_SMALL": "Small heart",
    "HAPPY": "Happy",
    "SMILE": "Smile",
    "SAD": "Sad",
    "CONFUSED": "Confused",
    "ANGRY": "Angry",
    "SLEEP": "Sleep",
    "SURPRISED": "Surprised",
    "SILLY": "Silly",
    "WONDERFUL": "Wonderful",
    "BORED": "Bored",
    "ROCK": "Rock",
    "ROCK_SMALL": "Small rock",
    "PAPER": "Paper",
    "PAPER_SMALL": "Small paper",
    "SCISSORS": "scissors",
    "SCISSORS_SMALL": "Small scissors",
    "DATA_SOLITLIST": "%1 %2 make %3 %4 with delimiter %5",
    "DATA_SOLITLIST_TYPE_LT": "list from text5",
    "DATA_SOLITLIST_TYPE_TL": "text from list",
    "DATA_LENLIST": "%1 %2 %3 is empty?",
    "DATA_LENGTHLIST": "%1 %2 length of %3",
    "DATA_CREATELIST": "%1 %2 create list %3",
    "DATA_CLEARLIST": "%1 %2 clear list %3"
  },
  "en": {
    "CONTROL_FOREVER": "forever",
    "CONTROL_LOOP_BREAKCONTINUE": "%1",
    "CONTROL_LOOP_BREAKCONTINUE_BREAK": "break",
    "CONTROL_LOOP_BREAKCONTINUE_CONTINUE": "continue",
    "CONTROL_REPEAT": "repeat %1",
    "CONTROL_IF": "if %1 then",
    "CONTROL_ELSE": "else",
    "CONTROL_STOP": "stop",
    "CONTROL_STOP_ALL": "all",
    "CONTROL_STOP_THIS": "this script",
    "CONTROL_STOP_OTHER": "other scripts in sprite",
    "CONTROL_WAIT": "wait %1 seconds",
    "CONTROL_WAIT_ESP32_MPY": "wait %1 %2",
    "CONTROL_FOR_FROM_SEQUENCE": "use %1 from sequence %2",
    "CONTROL_FOR_RANGE_SPACE": "Use %1 from range %2 to %3 every %4",
    "CONTROL_WAIT_ESP32_MPY_S": "second(s)",
    "CONTROL_WAIT_ESP32_MPY_MS": "millisecond(ms)",
    "CONTROL_WAIT_ESP32_MPY_US": "microsecond(us)",
    "CONTROL_WAITUNTIL": "wait until %1",
    "CONTROL_REPEATUNTIL": "repeat until %1",
    "CONTROL_WHILE": "while %1",
    "CONTROL_FOREACH": "for each %1 in %2",
    "CONTROL_STARTASCLONE": "when I start as a clone",
    "CONTROL_CREATECLONEOF": "create clone of %1",
    "CONTROL_CREATECLONEOF_MYSELF": "myself",
    "CONTROL_DELETETHISCLONE": "delete this clone",
    "CONTROL_COUNTER": "counter",
    "CONTROL_INCRCOUNTER": "increment counter ",
    "CONTROL_CLEARCOUNTER": "reset counter",
    "CONTROL_ALLATONCE": "all at once",
    "DATA_SETVARIABLETO": "set %1 to %2",
    "DATA_CHANGEVARIABLEBY": "change %1 by %2",
    "DATA_SHOWVARIABLE": "show variable %1",
    "DATA_HIDEVARIABLE": "hide variable %1",
    "DATA_ADDTOLIST": "add %1 to %2",
    "DATA_DELETEOFLIST": "delete %1 of %2",
    "DATA_DELETEALLOFLIST": "delete all of %1",
    "DATA_INSERTATLIST": "insert %1 at %2 of %3",
    "DATA_REPLACEITEMOFLIST": "replace item %1 of %2 with %3",
    "DATA_ITEMOFLIST": "item %1 of %2",
    "DATA_ITEMNUMOFLIST": "item # of %1 in %2",
    "DATA_LENGTHOFLIST": "length of %1",
    "DATA_LISTCONTAINSITEM": "%1 contains %2?",
    "DATA_SHOWLIST": "show list %1",
    "DATA_HIDELIST": "hide list %1",
    "DATA_INDEX_ALL": "all",
    "DATA_INDEX_LAST": "last",
    "DATA_INDEX_RANDOM": "random",
    "EVENT_WHENFLAGCLICKED": "when %1 clicked",
    "EVENT_WHENTHISSPRITECLICKED": "when this sprite clicked",
    "EVENT_WHENSTAGECLICKED": "when stage clicked",
    "EVENT_WHENTOUCHINGOBJECT": "when this sprite touches %1",
    "EVENT_WHENBROADCASTRECEIVED": "when I receive %1",
    "EVENT_WHENBACKDROPSWITCHESTO": "when backdrop switches to %1",
    "EVENT_WHENGREATERTHAN": "when %1 > %2",
    "EVENT_WHENGREATERTHAN_TIMER": "timer",
    "EVENT_WHENGREATERTHAN_LOUDNESS": "loudness",
    "EVENT_BROADCAST": "broadcast %1",
    "EVENT_BROADCASTANDWAIT": "broadcast %1 and wait",
    "EVENT_WHENKEYPRESSED": "when %1 key pressed",
    "EVENT_WHENKEYPRESSED_SPACE": "space",
    "EVENT_WHENKEYPRESSED_LEFT": "left arrow",
    "EVENT_WHENKEYPRESSED_RIGHT": "right arrow",
    "EVENT_WHENKEYPRESSED_DOWN": "down arrow",
    "EVENT_WHENKEYPRESSED_UP": "up arrow",
    "EVENT_WHENKEYPRESSED_ANY": "any",
    "LOOKS_SAYFORSECS": "say %1 for %2 seconds",
    "LOOKS_SAY": "say %1",
    "LOOKS_HELLO": "Hello!",
    "LOOKS_THINKFORSECS": "think %1 for %2 seconds",
    "LOOKS_THINK": "think %1",
    "LOOKS_HMM": "Hmm...",
    "LOOKS_SHOW": "show",
    "LOOKS_HIDE": "hide",
    "LOOKS_HIDEALLSPRITES": "hide all sprites",
    "LOOKS_EFFECT_COLOR": "color",
    "LOOKS_EFFECT_FISHEYE": "fisheye",
    "LOOKS_EFFECT_WHIRL": "whirl",
    "LOOKS_EFFECT_PIXELATE": "pixelate",
    "LOOKS_EFFECT_MOSAIC": "mosaic",
    "LOOKS_EFFECT_BRIGHTNESS": "brightness",
    "LOOKS_EFFECT_GHOST": "ghost",
    "LOOKS_CHANGEEFFECTBY": "change %1 effect by %2",
    "LOOKS_SETEFFECTTO": "set %1 effect to %2",
    "LOOKS_CLEARGRAPHICEFFECTS": "clear graphic effects",
    "LOOKS_CHANGESIZEBY": "change size by %1",
    "LOOKS_SETSIZETO": "set size to %1 %",
    "LOOKS_SIZE": "size",
    "LOOKS_CHANGESTRETCHBY": "change stretch by %1",
    "LOOKS_SETSTRETCHTO": "set stretch to %1 %",
    "LOOKS_SWITCHCOSTUMETO": "switch costume to %1",
    "LOOKS_NEXTCOSTUME": "next costume",
    "LOOKS_SWITCHBACKDROPTO": "switch backdrop to %1",
    "LOOKS_GOTOFRONTBACK": "go to %1 layer",
    "LOOKS_GOTOFRONTBACK_FRONT": "front",
    "LOOKS_GOTOFRONTBACK_BACK": "back",
    "LOOKS_GOFORWARDBACKWARDLAYERS": "go %1 %2 layers",
    "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "forward",
    "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "backward",
    "LOOKS_BACKDROPNUMBERNAME": "backdrop %1",
    "LOOKS_COSTUMENUMBERNAME": "costume %1",
    "LOOKS_NUMBERNAME_NUMBER": "number",
    "LOOKS_NUMBERNAME_NAME": "name",
    "LOOKS_SWITCHBACKDROPTOANDWAIT": "switch backdrop to %1 and wait",
    "LOOKS_NEXTBACKDROP_BLOCK": "next backdrop",
    "LOOKS_NEXTBACKDROP": "next backdrop",
    "LOOKS_PREVIOUSBACKDROP": "previous backdrop",
    "LOOKS_RANDOMBACKDROP": "random backdrop",
    "MOTION_MOVESTEPS": "move %1 steps",
    "MOTION_TURNLEFT": "turn %1 %2 degrees",
    "MOTION_TURNRIGHT": "turn %1 %2 degrees",
    "MOTION_POINTINDIRECTION": "point in direction %1",
    "MOTION_POINTTOWARDS": "point towards %1",
    "MOTION_POINTTOWARDS_POINTER": "mouse-pointer",
    "MOTION_POINTTOWARDS_RANDOM": "random direction",
    "MOTION_GOTO": "go to %1",
    "MOTION_GOTO_POINTER": "mouse-pointer",
    "MOTION_GOTO_RANDOM": "random position",
    "MOTION_GOTOXY": "go to x: %1 y: %2",
    "MOTION_GLIDESECSTOXY": "glide %1 secs to x: %2 y: %3",
    "MOTION_GLIDETO": "glide %1 secs to %2",
    "MOTION_GLIDETO_POINTER": "mouse-pointer",
    "MOTION_GLIDETO_RANDOM": "random position",
    "MOTION_CHANGEXBY": "change x by %1",
    "MOTION_SETX": "set x to %1",
    "MOTION_CHANGEYBY": "change y by %1",
    "MOTION_SETY": "set y to %1",
    "MOTION_IFONEDGEBOUNCE": "if on edge, bounce",
    "MOTION_SETROTATIONSTYLE": "set rotation style %1",
    "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "left-right",
    "MOTION_SETROTATIONSTYLE_DONTROTATE": "don't rotate",
    "MOTION_SETROTATIONSTYLE_ALLAROUND": "all around",
    "MOTION_XPOSITION": "x position",
    "MOTION_YPOSITION": "y position",
    "MOTION_DIRECTION": "direction",
    "MOTION_SCROLLRIGHT": "scroll right %1",
    "MOTION_SCROLLUP": "scroll up %1",
    "MOTION_ALIGNSCENE": "align with scene %1",
    "MOTION_ALIGNSCENE_BOTTOMLEFT": "bottom-left",
    "MOTION_ALIGNSCENE_BOTTOMRIGHT": "bottom-right",
    "MOTION_ALIGNSCENE_MIDDLE": "middle",
    "MOTION_ALIGNSCENE_TOPLEFT": "top-left",
    "MOTION_ALIGNSCENE_TOPRIGHT": "top-right",
    "MOTION_XSCROLL": "x scroll",
    "MOTION_YSCROLL": "y scroll",
    "MOTION_STAGE_SELECTED": "Stage selected: no motion blocks",
    "OPERATORS_ADD": "%1 + %2",
    "OPERATORS_SUBTRACT": "%1 - %2",
    "OPERATORS_MULTIPLY": "%1 * %2",
    "OPERATORS_DIVIDE": "%1 / %2",
    "OPERATORS_RANDOM": "pick random %1 to %2",
    "OPERATORS_GT": "%1 > %2",
    "OPERATORS_GT_EQUALS": "%1 >= %2",
    "OPERATORS_LT": "%1 < %2",
    "OPERATORS_LT_EQUALS": "%1 <= %2",
    "OPERATORS_EQUALS": "%1 = %2",
    "OPERATORS_AND": "%1 and %2",
    "OPERATORS_OR": "%1 or %2",
    "OPERATORS_NOT": "not %1",
    "CONTROL_TRUE_FALSE": "%1",
    "CONTROL_TRUE_FALSE_TRUE": "true",
    "CONTROL_TRUE_FALSE_FALSE": "false",
    "CONTROL_NONE": "None",
    "CONTROL_RETURN": "return %1",
    "OPERATORS_VALUE_IS_TRUE": "%1 is true?",
    "OPERATORS_VALUE_TYPE": "%1 type",
    "CONTROL_TRY": "try",
    "CONTROL_EXCEPT": "except",
    "CONTROL_FINALLY": "finally",
    "OPERATORS_VALUE_TYPE_IS": "%1 type is %2?",
    "OPERATORS_VALUE_TYPE_INT": "int",
    "OPERATORS_VALUE_TYPE_FLOAT": "float",
    "OPERATORS_VALUE_TYPE_BOOL": "bool",
    "OPERATORS_VALUE_TYPE_STR": "str",
    "OPERATORS_VALUE_TYPE_LIST": "list",
    "OPERATORS_VALUE_TYPE_TUPLE": "tuple",
    "OPERATORS_VALUE_TYPE_SET": "set",
    "OPERATORS_VALUE_TYPE_DICT": "dict",
    "OPERATORS_VALUE_TYPE_BYTES": "bytes",
    "OPERATORS_VALUE_TYPE_BYTEARRAY": "bytearray",
    "OPERATORS_VALUE_TYPE_COMPLEX": "complex",
    "OPERATORS_CACULATE_RESULT": "eval %1",
    "OPERATORS_JOIN": "join %1 %2",
    "OPERATORS_JOIN_APPLE": "apple",
    "OPERATORS_JOIN_BANANA": "banana",
    "OPERATORS_LETTEROF": "letter %1 of %2",
    "OPERATORS_LETTEROF_APPLE": "a",
    "OPERATORS_LENGTH": "length of %1",
    "OPERATORS_CONTAINS": "%1 contains %2?",
    "OPERATORS_MOD": "%1 mod %2",
    "OPERATORS_ROUND": "round %1",
    "OPERATORS_MATHOP": "%1 of %2",
    "OPERATORS_MATHOP_ABS": "abs",
    "OPERATORS_MATHOP_FLOOR": "floor",
    "OPERATORS_MATHOP_CEILING": "ceiling",
    "OPERATORS_MATHOP_SQRT": "sqrt",
    "OPERATORS_MATHOP_SIN": "sin",
    "OPERATORS_MATHOP_COS": "cos",
    "OPERATORS_MATHOP_TAN": "tan",
    "OPERATORS_MATHOP_ASIN": "asin",
    "OPERATORS_MATHOP_ACOS": "acos",
    "OPERATORS_MATHOP_ATAN": "atan",
    "OPERATORS_MATHOP_LN": "ln",
    "OPERATORS_MATHOP_LOG": "log",
    "OPERATORS_MATHOP_EEXP": "e ^",
    "OPERATORS_MATHOP_10EXP": "10 ^",
    "OPERATORS_MATHOP_NEGATIVE": "-",
    "OPERATORS_MATHOP_INT": "int",
    "OPERATORS_MATHOP_FLOAT": "float",
    "PROCEDURES_DEFINITION": "define %1",
    "SENSING_TOUCHINGOBJECT": "touching %1?",
    "SENSING_TOUCHINGOBJECT_POINTER": "mouse-pointer",
    "SENSING_TOUCHINGOBJECT_EDGE": "edge",
    "SENSING_TOUCHINGCOLOR": "touching color %1?",
    "SENSING_COLORISTOUCHINGCOLOR": "color %1 is touching %2?",
    "SENSING_DISTANCETO": "distance to %1",
    "SENSING_DISTANCETO_POINTER": "mouse-pointer",
    "SENSING_ASKANDWAIT": "ask %1 and wait",
    "SENSING_ASK_TEXT": "What's your name?",
    "SENSING_ANSWER": "answer",
    "SENSING_KEYPRESSED": "key %1 pressed?",
    "SENSING_MOUSEDOWN": "mouse down?",
    "SENSING_MOUSEX": "mouse x",
    "SENSING_MOUSEY": "mouse y",
    "SENSING_SETDRAGMODE": "set drag mode %1",
    "SENSING_SETDRAGMODE_DRAGGABLE": "draggable",
    "SENSING_SETDRAGMODE_NOTDRAGGABLE": "not draggable",
    "SENSING_LOUDNESS": "loudness",
    "SENSING_LOUD": "loud?",
    "SENSING_TIMER": "timer",
    "SENSING_RESETTIMER": "reset timer",
    "SENSING_OF": "%1 of %2",
    "SENSING_OF_XPOSITION": "x position",
    "SENSING_OF_YPOSITION": "y position",
    "SENSING_OF_DIRECTION": "direction",
    "SENSING_OF_COSTUMENUMBER": "costume #",
    "SENSING_OF_COSTUMENAME": "costume name",
    "SENSING_OF_SIZE": "size",
    "SENSING_OF_VOLUME": "volume",
    "SENSING_OF_BACKDROPNUMBER": "backdrop #",
    "SENSING_OF_BACKDROPNAME": "backdrop name",
    "SENSING_OF_STAGE": "Stage",
    "SENSING_CURRENT": "current %1",
    "SENSING_CURRENT_YEAR": "year",
    "SENSING_CURRENT_MONTH": "month",
    "SENSING_CURRENT_DATE": "date",
    "SENSING_CURRENT_DAYOFWEEK": "day of week",
    "SENSING_CURRENT_HOUR": "hour",
    "SENSING_CURRENT_MINUTE": "minute",
    "SENSING_CURRENT_SECOND": "second",
    "SENSING_DAYSSINCE2000": "days since 2000",
    "SENSING_USERNAME": "username",
    "SENSING_USERID": "user id",
    "SOUND_PLAY": "start sound %1",
    "SOUND_PLAYUNTILDONE": "play sound %1 until done",
    "SOUND_STOPALLSOUNDS": "stop all sounds",
    "SOUND_SETEFFECTO": "set %1 effect to %2",
    "SOUND_CHANGEEFFECTBY": "change %1 effect by %2",
    "SOUND_CLEAREFFECTS": "clear sound effects",
    "SOUND_EFFECTS_PITCH": "pitch",
    "SOUND_EFFECTS_PAN": "pan left/right",
    "SOUND_CHANGEVOLUMEBY": "change volume by %1",
    "SOUND_SETVOLUMETO": "set volume to %1%",
    "SOUND_VOLUME": "volume",
    "SOUND_RECORD": "record...",
    "CATEGORY_MOTION": "Motion",
    "CATEGORY_LOOKS": "Looks",
    "CATEGORY_SOUND": "Sound",
    "CATEGORY_EVENTS": "Events",
    "CATEGORY_CONTROL": "Control",
    "CATEGORY_SENSING": "Sensing",
    "CATEGORY_OPERATORS": "Operators",
    "CATEGORY_VARIABLES": "Variables",
    "CATEGORY_MYBLOCKS": "My Blocks",
    "CATEGORY_NUMBERS": "Number",
    "CATEGORY_TEXT": "Text",
    "DUPLICATE": "Duplicate",
    "DUPLICATE_CURRENT": "Duplicate a single",
    "DELETE": "Delete",
    "ADD_COMMENT": "Add Comment",
    "REMOVE_COMMENT": "Remove Comment",
    "DELETE_BLOCK": "Delete Block",
    "DELETE_X_BLOCKS": "Delete %1 Blocks",
    "DELETE_ALL_BLOCKS": "Delete all %1 blocks?",
    "CLEAN_UP": "Clean up Blocks",
    "HELP": "Help",
    "UNDO": "Undo",
    "REDO": "Redo",
    "EDIT_PROCEDURE": "Edit",
    "SHOW_PROCEDURE_DEFINITION": "Go to definition",
    "WORKSPACE_COMMENT_DEFAULT_TEXT": "Say something...",
    "COLOUR_HUE_LABEL": "Color",
    "COLOUR_SATURATION_LABEL": "Saturation",
    "COLOUR_BRIGHTNESS_LABEL": "Brightness",
    "CHANGE_VALUE_TITLE": "Change value:",
    "RENAME_VARIABLE": "Rename variable",
    "RENAME_VARIABLE_STRING": "Rename string variable",
    "RENAME_VARIABLE_NUMBER": "Rename numeric variable",
    "RENAME_VARIABLE_LIST": "Rename list variable",
    "RENAME_VARIABLE_TITLE": "Rename all [%1] variables to:",
    "RENAME_VARIABLE_MODAL_TITLE": "Rename Variable",
    "NEW_VARIABLE": "Make a Variable",
    "NEW_VARIABLE_TITLE": "New variable name:",
    "VARIABLE_MODAL_TITLE": "New Variable",
    "VARIABLE_ALREADY_EXISTS": "A variable named [%1] already exists.",
    "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "A variable named [%1] already exists for another variable of type [%2].",
    "DELETE_VARIABLE_CONFIRMATION": "Delete %1 uses of the [%2] variable?",
    "CANNOT_DELETE_VARIABLE_PROCEDURE": "Can't delete the variable [%1] because it's part of the definition of the function [%2]",
    "DELETE_VARIABLE": "Delete the「%1」variable ",
    "DELETE_VARIABLE_STRING": "Delete the string variable「%1」",
    "DELETE_VARIABLE_NUMBER": "Delete the numeric variable 「%1」",
    "DELETE_VARIABLE_LIST": "Delete the「%1」 list variable",
    "NEW_PROCEDURE": "Make a Block",
    "PROCEDURE_ALREADY_EXISTS": "A procedure named [%1] already exists.",
    "PROCEDURE_DEFAULT_NAME": "block name",
    "NEW_LIST": "Make a List",
    "NEW_LIST_TITLE": "New list name:",
    "LIST_MODAL_TITLE": "New List",
    "LIST_ALREADY_EXISTS": "A list named [%1] already exists.",
    "RENAME_LIST_TITLE": "Rename all [%1] lists to:",
    "RENAME_LIST_MODAL_TITLE": "Rename List",
    "DEFAULT_LIST_ITEM": "thing",
    "DELETE_LIST": "Delete the [%1] list",
    "RENAME_LIST": "Rename list",
    "NEW_BROADCAST_MESSAGE": "New message",
    "NEW_BROADCAST_MESSAGE_TITLE": "New message name:",
    "BROADCAST_MODAL_TITLE": "New Message",
    "DEFAULT_BROADCAST_MESSAGE_NAME": "message1",
    "PROCEDURE_USED": "To delete a block definition, first remove all uses of the block",
    "OK": "OK",
    "CANCEL": "Cancel",
    "PROMPT": "Prompt",
    "STOP": "stop",
    "NEW_STRING": "Make a String Variable",
    "NEW_NUMBER_VARIABLE": "Make a Numeric Variable",
    "WIFI": "Wi-Fi",
    "USERNAME": "user",
    "PWD": "password",
    "IP": "IP Address",
    "IOT_SERVICE": "IOT Service",
    "IOT_ID": "iot id",
    "IOT_PWD": "iot pwd",
    "IOT_TOPIC": "Topic_0",
    "MORE_TOPIC": "Topic",
    "OBLOQ_HTTP": "HTTP",
    "OBLOQ_IP": "IP Address",
    "OBLOQ_PORT": "Port",
    "WIFI_VALIDATE": "Wi-Fi password must be 8-20 letters and numbers",
    "ADD_TOPIC": "Add Topic, can only add up to 4",
    "DELETE_TOPIC": "Delete Topic",
    "IOT_SERVER": "Server",
    "CHINA": "China",
    "GLOBAL": "Global",
    "LOW_C": "Low C/C3",
    "LOW_C$": "Low C#/C#3",
    "LOW_D": "Low D/D3",
    "LOW_D$": "Low D#/D#3",
    "LOW_E": "Low E/E3",
    "LOW_F": "Low F/F3",
    "LOW_F$": "Low F#/F#3",
    "LOW_G": "Low G/G3",
    "LOW_G$": "Low G#/G#3",
    "LOW_A": "Low A/A3",
    "LOW_A$": "Low A#/A#3",
    "LOW_B": "Low B/B3",
    "MIDDLE_C": "Middle C/C4",
    "MIDDLE_C$": "Middle C#/C#4",
    "MIDDLE_D": "Middle D/D4",
    "MIDDLE_D$": "Middle D#/D#4",
    "MIDDLE_E": "Middle E/E4",
    "MIDDLE_F": "Middle F/F4",
    "MIDDLE_F$": "Middle F#/F#4",
    "MIDDLE_G": "Middle G/G4",
    "MIDDLE_G$": "Middle G#/G#4",
    "MIDDLE_A": "Middle A/A4",
    "MIDDLE_A$": "Middle A#/A#4",
    "MIDDLE_B": "Middle B/B4",
    "HIGH_C": "High C/C5",
    "HIGH_C$": "High C#/C#5",
    "HIGH_D": "High D/D5",
    "HIGH_D$": "High D#/D#5",
    "HIGH_E": "High E/E5",
    "HIGH_F": "High F/F5",
    "HIGH_F$": "High F#/F#5",
    "HIGH_G": "High G/G5",
    "HIGH_G$": "High G#/G#5",
    "HIGH_A": "High A/A5",
    "HIGH_A$": "High A#/A#5",
    "HIGH_B": "High B/B5",
    "OPERATORS_STRINGTONUMBER": "convert string %1 to %2",
    "INTEGER": "Integer",
    "DECIMAL": "Decimal",
    "OPERATORS_NUMTOASCII": "convert number %1 to ASCII",
    "OPERATORS_STRINGTOASCII": "convert string %1 to ASCII",
    "OPERATORS_NUMTOSTRING": "convert number %1 to string",
    "OPERATORS_MAP": "map %1 from[ %2 , %3 ] to[ %4 , %5 ]",
    "OPERATORS_CONSTRAIN": "constrain %1 between(min) %2 and(max) %3",
    "STEPS_PER_TURN": "steps per turn",
    "ROTATE_SPEED": "rotate speed(r/s)",
    "NEW_AI": "Make an AI Block",
    "ASK_AND_PAINT": "please draw a number",
    "GET_IDENTIFIED_NUM": "number recognition",
    "READ_NUM_AND_SAY": "speak out the recognized number",
    "IMAGE_ADDR": "Image Addr",
    "IMAGE_PREVIEW": "Image Preview",
    "IMAGE_OPEN": "open",
    "IMAGE_SIZE": "Image Size",
    "IMAGE_WIDTH": "W",
    "IMAGE_HEIGHT": "H",
    "VARIABLE": "%1",
    "VARIABLE_LIST": "%1",
    "SET_STRING_VARIABLE": "set %1 to %2",
    "STRING_START_WITH": "%1 start with %2",
    "OPERATORS_RANDOMA": "%1 %2 pick random %3 to %4 %5",
    "GLOBAL_MPY_VARIABLE": "global %1",
    "OPERATORS_MODA": "%1 %2 %3 mod %4",
    "OPERATORS_ROUNDA": "%1 %2 round %3",
    "OPERATORS_MATHOPA": "%1 %2 %3 %4",
    "OPERATORS_MATHOP_ABSA": "abs",
    "OPERATORS_MATHOP_FLOORA": "ceiling",
    "OPERATORS_MATHOP_CEILINGA": "floor",
    "OPERATORS_MATHOP_SQRTA": "sqrt",
    "OPERATORS_MATHOP_FIVE": "%1 %2 change %3 by %4",
    "OPERATORS_MATHOP_SIX": "%1 %2 map %3 from[ %4 , %5 ] to[ %6 , %7 ]",
    "OPERATORS_MATHOP_SEVEN": "%1 %2 constrain %3 between(min) %4 and(max) %5",
    "OPERATORS_NUM_INPUT": "%1 %2 number %3",
    "OPERATORS_NUMS_CACULATE": "%1 %2 %3",
    "OPERATORS_MATHOP_SQUARE": "**",
    "OPERATORS_MATHOP_LOGIC_OR": "|",
    "OPERATORS_MATHOP_LOGIC_AND": "&",
    "OPERATORS_MATHOP_LOGIC_POWER": "^",
    "OPERATORS_MATHOP_LOGIC_LEFT": "<<",
    "OPERATORS_MATHOP_LOGIC_RIGHT": ">>",
    "OPERATORS_SPECIAL_NUM": "%1 %2 %3",
    "OPERATORS_MATHOP_PI": "π",
    "OPERATORS_MATHOP_LOGARITHM": "e",
    "OPERATORS_MATHOP_FAI": "φ",
    "OPERATORS_MATHOP_SQRT2": "sqrt(2)",
    "OPERATORS_MATHOP_SQRTHALF": "sqrt(½)",
    "OPERATORS_MATHOP_UNLIMITED": "∞",
    "OPERATORS_NUMS_TYPE": "%1 %2 %3 %4?",
    "OPERATORS_MATHOP_ISEVEN": "is even",
    "OPERATORS_MATHOP_ISODD": "is odd",
    "OPERATORS_MATHOP_ISINT": "is int",
    "OPERATORS_MATHOP_ISPOSITIVE": "is positive",
    "OPERATORS_MATHOP_ISNEGATIVE": "is negative",
    "OPERATORS_SAVE2DECIMAL": "%1 %2 %3 save %4 decimal places",
    "OPERATORS_LIST_CACULATE": "%1 %2 %3 %4",
    "OPERATORS_MATHOP_LIST_SUM": "sum of the numbers in the list",
    "OPERATORS_MATHOP_LIST_MIN": "minimum value in list",
    "OPERATORS_MATHOP_LIST_MAX": "maximum value in the list",
    "OPERATORS_MATHOP_LIST_AVERAGE": "average in list",
    "OPERATORS_MATHOP_LIST_NUM": "number of digits in the list",
    "OPERATORS_MATHOP_LIST_MODE": "list mode",
    "OPERATORS_MATHOP_LIST_STDDEVIATION": "standard deviation in the list",
    "OPERATORS_MATHOP_LIST_RANDOM": "random item of list",
    "OPERATORS_QUOTIENT_INT": "%1 %2 %3 ÷ %4 integer part of quotient",
    "OPERATORS_RANDOM_DECIMAL": "%1 %2 random decimal",
    "OPERATORS_BIN_NEGATION": "%1 %2 binary negation %3",
    "OPERATORS_INT_TO_STR": "%1 %2 decimal integer %3 transform into %4 string",
    "OPERATORS_MATHOP_INTTOSTR_BIN": "binary",
    "OPERATORS_MATHOP_INTTOSTR_OCT": "octal",
    "OPERATORS_MATHOP_INTTOSTR_HEX": "hex",
    "OPERATORS_BINSTR_TO_INT": "%1 %2 %3 string %4 transform into decimal integer",
    "OPERATORS_INT_TO_BYTE": "%1 %2 decimal integer %3 transform into %4 byte",
    "OPERATORS_MATHOP_INTTOBYTE_TWO": "2",
    "OPERATORS_MATHOP_INTTOBYTE_FOUR": "4",
    "OPERATORS_INT_TO_ASCII": "%1 %2 int %3 transform into ASCII character",
    "OPERATORS_ASCII_TO_INT": "%1 %2 ASCII character %3 to int",
    "OPERATORS_STR_TO_INT": "%1 %2 string %3 to hex string",
    "OPERATORS_HEXSTR_TO_BIN": "%1 %2 hex string %3 to bin byte",
    "OPERATORS_STR_TO_BASE64": "%1 %2 %3 encodes in base64 format returns a byte object",
    "OPERATORS_BASE64_TO_BYTE": "%1 %2 decode base64 data %3 returns a byte object",
    "OPERATORS_MATHOP_LENGHT": "length %1",
    "OPERATORS_MATHOP_MAXMIN": "%1 %2",
    "OPERATORS_MATHOP_MAXMIN_MAX": "max",
    "OPERATORS_MATHOP_MAXMIN_MIN": "min",
    "OPERATORS_TEXTS_ONE": "%1 %2 join %3 %4",
    "OPERATORS_TEXTS_TWO": "%1 %2 letter %4 of %3",
    "OPERATORS_TEXTS_THREE": "%1 %2 %3 contains %4?",
    "OPERATORS_TEXTS_FOUR": "%1 %2 letter  %5 %4 to %7 %6 of %3",
    "OPERATORS_TEXTS_FOUR_NEW": "%1 gets %2 %3 characters to %4 %5characters",
    "OPERATORS_TEXTS_NEW": "%1 %2 find %3 in %4  %5 Emerging position",
    "OPERATORS_TEXTS_NEW_OP": "find where %1 appears in %2 %3",
    "OPERATORS_TEXTS_FOUR_ONE": "from left",
    "OPERATORS_TEXTS_FOUR_TWO": "from right",
    "OPERATORS_TEXTS_FOUR_THREE": "from left",
    "OPERATORS_TEXTS_FOUR_FOUR": "from right",
    "OPERATORS_TEXTS_FOUR_F": "first",
    "OPERATORS_TEXTS_FOUR_L": "last",
    "OPERATORS_INPUTSTRING": "%1 %2 string %3",
    "OPERATORS_TRANSINTOTEXT": "%1 %2 transform into %3",
    "OPERATORS_TRANSINTOTEXT_ADD": "add",
    "OPERATORS_TRANSINTOTEXT_ADD_ITEM": "item",
    "OPERATORS_FORMATSTRING2F": "%1 %2 format string %3 % %4",
    "OPERATORS_FORMATSTRING3": "%1 %2 format string %3 format %4",
    "OPERATORS_TEXT_ESCAPE": "%1 %2 text escape character %3",
    "BAR_N": "\\n",
    "BAR_NN": "\\n\\r",
    "BAR_R": "\\r",
    "BAR_RN": "\\r\\n",
    "BAR_B": "\\b",
    "BAR_T": "\\t",
    "BAR_BAR": "\\\\",
    "OPERATORS_TEXT_NUMORLETTER": "%1 %2 %3 %4?",
    "OPERATORS_TEXT_NUMORLETTER_NUM": "is number",
    "OPERATORS_TEXT_NUMORLETTER_LETTER": "is letter",
    "OPERATORS_TEXT_LENGTH": "%1 %2 %3 length",
    "OPERATORS_TEXT_ISNONE": "%1 %2 %3 is none?",
    "OPERATORS_FROMTEXT_GETCHAR": "%1 %2 in text %3 %4",
    "OPERATORS_FROMTEXT_GETCHAR_FIRST": "first letter",
    "OPERATORS_FROMTEXT_GETCHAR_LAST": "last letter",
    "OPERATORS_FROMTEXT_GETCHAR_RANDOM": "random letter",
    "OPERATORS_TEXT_UPPLOW": "%1 %2 %3 %4",
    "OPERATORS_TEXT_UPPLOW_UPP": "uppercase",
    "OPERATORS_TEXT_UPPLOW_LOW": "lowercase",
    "OPERATORS_TEXT_SIDES_SPACE": "%1 %2 %3 %4",
    "OPERATORS_TEXT_SIDES_SPACE_BOTH": "clear spaces on both sides",
    "OPERATORS_TEXT_SIDES_SPACE_LEFT": "clear left space",
    "OPERATORS_TEXT_SIDES_SPACE_RIGHT": "clear right space",
    "OPERATORS_TEXT_STRTOBYTE": "%1 %2 turn byte %3",
    "OPERATORS_TEXT_BYTETOSTR": "%1 %2 byte %3 convert to string",
    "OPERATORS_TEXT_DICTTOJSONSTR": "%1 %2 dict %3 transform into json string",
    "OPERATORS_TEXT_PARSEJSONSTR": "%1 %2 parse json string %3 and return object",
    "OPERATORS_STRINGTONUMBERA": "%1 %2 convert string %3 to %4",
    "OPERATORS_NUMTOASCIIA": "%1 %2 convert number %3 into ASCII characters",
    "OPERATORS_STRINGTOASCIIA": "%1 %2 convert character %3 into an ASCII value",
    "OPERATORS_NUMTOSTRINGA": "%1 %2 convert number %3 into a string",
    "NEW_MC": "make variable",
    "RENAME_MCNUMBER_TITLE": "rename all variables whose name is [%1] to:",
    "RENAME_MCNUMBER_MODAL_TITLE": "rename variable",
    "RENAME_VARIABLE_MCNUMBER": "rename variable",
    "DELETE_VARIABLE_MCNUMBER": "delete variable [%1]",
    "SET_MC_VARIABLE": "set %1 to %2",
    "ALIYUN": "Aliyun",
    "ONENET": "OneNet",
    "ONENETNEW": "OneNet(New)",
    "EASYIOT": "Easy IoT",
    "SIOT": "SIoT",
    "SHANGHAI": "Shanghai",
    "QINGDAO": "Qingdao",
    "BEIJING": "Beijing",
    "ZHANGJIAKOU": "Zhangjiakou",
    "HUHEHAOTE": "Huhehaote",
    "HANGZHOU": "Hangzhou",
    "SHENZHEN": "Shenzhen",
    "HONGKONG": "HongKong",
    "SINGAPORE": "Singapore",
    "SYDNEY": "Sydney",
    "KUALALUMPUR": "Luala Lumpur",
    "JAKARTA": "Jakarta",
    "MUMBAI": "Mumbai",
    "TOKYO": "Tokyo",
    "SILICONVALLEY": "Silicon Valley",
    "VIRGINIA": "Virginia",
    "FRANKFURT": "Frankfurt",
    "LONDON": "London",
    "DUBAI": "Dubai",
    "IOT_PLATFORM": "Iot Platform",
    "PARAMS": "Params",
    "SERVER_ATTR": "Server Addr",
    "PRODUCTID": "ProductId",
    "DEVICEID": "DeviceId",
    "DEVICENAME": "DeviceName",
    "TINYDB_SECRET": "secret (secret)",
    "TINYDB_USER": "user (user)",
    "APIADDR": "API Addr",
    "SOFTWARE_SERIAL": "Software Serial",
    "HARDWARE_SERIAL": "Hardware Serial",
    "HARDWARE_SERIAL1": "Hardware Serial1",
    "HARDWARE_SERIAL2": "Hardware Serial2",
    "HARDWARE_SERIAL3": "Hardware Serial3",
    "CHECKTYPE_TIPS": "The data type received by the input box does not match the output type of the building block",
    "HIGHLIGHT_BLOCK": "hight light block",
    "HIGHLIGHT_ALL_CONFLICT_BLOCKS": "highlight all blocks with warnings",
    "SNAPSHOT": "Snapshot (CTRL+G)",
    "pictureaiPath": "please enter the picture path or URL",
    "pictureaiType": "account type",
    "pictureaiAccountDefault": "default account",
    "pictureaiAccountBaidu": "baidu account",
    "pictureaiwebImgAddr": "web image address",
    "MITRIXICON_EDIT": "light up",
    "MITRIXICON_ERASER": "eraser",
    "MITRIXICON_DUSTBIN": "clear",
    "MITRIXICON_REVERSE": "reverse",
    "MITRIXICON_COLOR": "select color",
    "RED": "red",
    "GREEN": "green",
    "BLUE": "blue",
    "SEARCH_BLOCKS": "search blocks",
    "NO_SEARCH_BLOCKS": "with no result",
    "SEARCH_DEFAULT_LABEL_THINK": "think",
    "SEARCH_DEFAULT_LABEL_IF": "if",
    "SEARCH_DEFAULT_LABEL_WAIT": "wait",
    "SEARCH_DEFAULT_LABEL_CONVERT": "convert",
    "SEARCH_DEFAULT_LABEL_BROADCAST": "broadcast",
    "MITRIXICON_SAVE": "save",
    "MITRIXICON_EXPORT": "export",
    "MITRIXICON_UPLOAD": "upload",
    "ROLL_MOUSE": "Rolling mouse",
    "MOUSE_ZOOM_IN_OUT": "Scroll the mouse to zoom in and out",
    "UPLOAD_CORRECT_JSON_FILE": "Please upload the json file in the correct format",
    "EXPORT_PROMPT": "There are no custom patterns to export. Please click the save button in the lower right corner to save the custom pattern before attempting to export.",
    "HEART": "Heart",
    "HEART_SMALL": "Small heart",
    "HAPPY": "Happy",
    "SMILE": "Smile",
    "SAD": "Sad",
    "CONFUSED": "Confused",
    "ANGRY": "Angry",
    "SLEEP": "Sleep",
    "SURPRISED": "Surprised",
    "SILLY": "Silly",
    "WONDERFUL": "Wonderful",
    "BORED": "Bored",
    "ROCK": "Rock",
    "ROCK_SMALL": "Small rock",
    "PAPER": "Paper",
    "PAPER_SMALL": "Small paper",
    "SCISSORS": "scissors",
    "SCISSORS_SMALL": "Small scissors",
    "DATA_SOLITLIST": "%1 %2 make %3 %4 with delimiter %5",
    "DATA_SOLITLIST_TYPE_LT": "list from text5",
    "DATA_SOLITLIST_TYPE_TL": "text from list",
    "DATA_LENLIST": "%1 %2 %3 is empty?",
    "DATA_LENGTHLIST": "%1 %2 length of %3",
    "DATA_CREATELIST": "%1 %2 create list %3",
    "DATA_CLEARLIST": "%1 %2 clear list %3",
    "ML5MODALBUTTON": "Open AI Visual Interface",
    "COLLAPSE_BLOCK": "collapse",
    "EXPAND_BLOCK": "expand",
    "COLLAPSE_ALL": "collapse all blocks",
    "EXPAND_ALL": "expand all blocks",
    "pyTimer": "Timer",
    "pyList": "List",
    "pyDictionary": "Dictionary",
    "pySet": "Set",
    "mpyTuple": "Tuple",
    "pythonBase_printAndInput": "Input and Output",
    "pythonBase_mainSetUp": "%1 %2 Python main program start",
    "pythonBase_print": "%1 %2 print %3",
    "pythonBase_input": "%1 %2 gets the input, the prompt is %3",
    "pythonBase_file": "file",
    "pythonBase_file_open": "%1 %2 open file %5 in format %3 mode %4 and returned %6",
    "pythonBase_file_open_b": "%1 %2 open file %4 in mode %3 and returned %5",
    "pythonBase_file_open_read": "read",
    "pythonBase_file_open_write": "write",
    "pythonBase_file_open_add": "add",
    "pythonBase_file_open_read_bit": "binary read",
    "pythonBase_file_open_write_bit": "binary write",
    "pythonBase_file_open_add_bit": "binary add",
    "pythonBase_file_close": "%1 %2 %3 close",
    "pythonBase_file_read": "%1 %2 %3 read %4",
    "pythonBase_file_read_all": "entire file ",
    "pythonBase_file_read_line": "a line",
    "pythonBase_file_read_lines": "all lines",
    "pythonBase_file_write": "%1 %2 %3 write %4",
    "pythonBase_time": "system time",
    "pythonBase_get_time": "%1 %2 get system time %3",
    "pythonBase_get_time_by_year": "year",
    "pythonBase_get_time_by_month": "month",
    "pythonBase_get_time_by_day": "day",
    "pythonBase_get_time_by_hour": "hour",
    "pythonBase_get_time_by_minute": "minute",
    "pythonBase_get_time_by_second": "second",
    "pythonBase_get_time_by_day_of_week": "day of week",
    "pythonBase_get_time_by_day_of_year": "day of year",
    "pythonBase_get_time_format": "%1 %2 get system time format %3",
    "pythonBase_get_time_format_by_hour_minute_second": "hour:minute:second",
    "pythonBase_get_time_format_by_hour_minute": "hour:minute",
    "pythonBase_get_time_format_by_year_month_day": "year/month/day",
    "pythonBase_get_time_format_by_year_month": "year/month",
    "pythonBase_get_time_format_by_year_month_day_": "year-month-day",
    "pythonBase_get_time_format_by_year_month_day_hour_minute_second": "year/month/day hour:minute:second",
    "pythonBase_get_timestamp": "%1 %2 get system timestamp",
    "pythonBase_get_time_format_custom": "%1 %2 get system time format (%3)",
    "pyTimer_setEvent": "%1 %2 set timer %3 %4 cycle %5 milliseconds",
    "pyTimer_repeated": "Repeated execution",
    "pyTimer_delayed": "Delayed execution",
    "pyTimer_conditionEvent": "%1 %2 set timer %3: when %4",
    "pyTimer_getCount": "%1 %2 timer %3 count value",
    "pyTimer_clearEvent": "%1 %2 clear timer/event %3",
    "pyDictionary_init": "%1 %2 initialize dictionary %3",
    "pyDictionary_getValue": "%1 %2 dictionary %3 key %4 value",
    "pyDictionary_setValue": "The value of %1 %2 dictionary %3 key %4 is set to %5",
    "pyDictionary_deleteKey": "%1 %2 dictionary %3 delete key %4",
    "pyDictionary_clear": "%1 %2 clear dictionary %3",
    "pyDictionary_isInclude": "%1 %2 dictionary %3 contains the key %4?",
    "pyDictionary_getLength": "%1 %2 dictionary %3 length",
    "pyDictionary_list": "%1 %2 dictionary %3 %4 list",
    "pyDictionary_TypeIndex_A": "Key",
    "pyDictionary_TypeIndex_B": "Value",
    "pyList_join": "%1 %2 list %3 uses separator %4 to combine text",
    "pyList_split": "%1 %2 text %3 uses separator %4 to make a list",
    "pyList_init": "%1 %2 initialization list %3",
    "pyList_clear": "%1 %2 clear list %3",
    "pyList_getLength": "%1 %2 length of list %3",
    "pyList_isEmpty": "%1 %2 list %3 is empty?",
    "pyList_getValue": "%1 %2 list %3 index %4 value",
    "pyList_getValueRange": "%1 %2 returns list %3 and takes %4 %5 items to %6 %7 items",
    "pyList_insert": "%1 %2 list %3 insert %5 at index %4",
    "pyList_setValue": "%1 %2 list %3 set index %4 to value %5",
    "pyList_delete": "%1 %2 list %3 delete index %4 value",
    "pyList_append": "%1 %2 list %3 adds %4 to the end",
    "pyList_extend": "%1 %2 list %3 append list %4",
    "pyList_findIndex": "%1 %2 list %3 find index of %4",
    "pyList_sort": "%1 %2 list %3 sorted by %4 as %5",
    "pyList_SortModeIndex_A": "Ascending order",
    "pyList_SortModeIndex_B": "Descending Order",
    "pyList_SortTypeIndex_A": "Number",
    "pyList_SortTypeIndex_B": "Letter",
    "pyList_SortTypeIndex_C": "Letters, ignore case",
    "pyList_has": "%1 %2 list %3 has %4 ?",
    "pySet_init": "%1 %2 initialize set %3 ",
    "pySet_initEmpty": "%1 %2 initialize empty set",
    "pySet_update": "%1 %2 set %3 updated to be %5 with set %4",
    "pySet_TypeIndex_A": "Intersection",
    "pySet_TypeIndex_B": "Union Set",
    "pySet_TypeIndex_C": "Difference Set",
    "pySet_remove": "%1 %2 set %3 remove %4",
    "pySet_clear": "%1 %2 clear set %3",
    "pySet_copy": "%1 %2 copy set %3",
    "pySet_has": "%1 %2 set %3 has %4",
    "pySet_addValue": "%1 %2 set %3 add single element %4",
    "pySet_updateValue": "%1 %2 set %3 add an iterable object %4",
    "pySet_isSubsetSuperset": "%1 %2 set %3 is %5 of set %4?",
    "pySet_SubsetSupersetType_A": "Subset",
    "pySet_SubsetSupersetType_B": "Superset",
    "pySet_intersectionUnionSetDifference": "%1 %2 takes %3 set %4 set %5",
    "pySet_length": "%1 %2 set %3 length",
    "pySet_pop": "%1 %2 returned random items and removed from the set %3",
    "pyTuple_init": "%1 %2 initialized tuple %3",
    "pyTuple_minMaxLength": "%1 %2 tuple %3 %4",
    "pyTuple_TypeIndex_A": "Minimum Value",
    "pyTuple_TypeIndex_B": "Maximum Value",
    "pyTuple_TypeIndex_C": "Length",
    "pyTuple_isInclude": "%1 %2 tuple %3 contains %4?",
    "pyTuple_getValueRange": "%1 %2 returns the tuple %3 taking %4 %5 items to %6 %7 items",
    "pyTuple_IndexType_A": "#",
    "pyTuple_IndexType_B": "countdown #",
    "pyTuple_listToTulpe": "%1 %2 list %3 to tuple",
    "pyTuple_getValue": "%1 %2 tuple %3 index %4 value",
    "pyTuple_tupleToList": "%1 %2 tuple %3 turned into a list",
    "text_decode_encode": "decode or encode",
    "coder": "%1 %2 %3 text %4 %5",
    "decode": "decode",
    "encode": "encode",
    "mathop_specially": "%1 %2 convert %3 into %4",
    "CONTROLS_IF_MSG_IF": "if",
    "CONTROLS_IF_MSG_ELSEIF": "else if",
    "CONTROLS_IF_MSG_ELSE": "else",
    "CONTROLS_IF_MSG_THEN": "then"
  },
  "es": {
    "CONTROL_FOREVER": "por siempre",
    "CONTROL_REPEAT": "repetir %1",
    "CONTROL_IF": "si %1 entonces",
    "CONTROL_ELSE": "si no",
    "CONTROL_STOP": "detener",
    "CONTROL_STOP_ALL": "todos",
    "CONTROL_STOP_THIS": "este programa",
    "CONTROL_STOP_OTHER": "otros programas en el objeto",
    "CONTROL_WAIT": "esperar %1 segundos",
    "CONTROL_WAITUNTIL": "esperar hasta que %1",
    "CONTROL_REPEATUNTIL": "repetir hasta que %1",
    "CONTROL_WHILE": "mientras %1",
    "CONTROL_FOREACH": "para cada %1 de %2",
    "CONTROL_STARTASCLONE": "al comenzar como clon",
    "CONTROL_CREATECLONEOF": "crear clon de %1",
    "CONTROL_CREATECLONEOF_MYSELF": "mí mismo",
    "CONTROL_DELETETHISCLONE": "eliminar este clon",
    "CONTROL_COUNTER": "contador",
    "CONTROL_INCRCOUNTER": "incrementar contador",
    "CONTROL_CLEARCOUNTER": "borrar contador",
    "CONTROL_ALLATONCE": "todo de una vez",
    "DATA_SETVARIABLETO": "dar a %1 el valor %2",
    "DATA_CHANGEVARIABLEBY": "sumar a %1 %2",
    "DATA_SHOWVARIABLE": "mostrar variable %1",
    "DATA_HIDEVARIABLE": "esconder variable %1",
    "DATA_ADDTOLIST": "añadir %1 a %2",
    "DATA_DELETEOFLIST": "eliminar %1 de %2",
    "DATA_DELETEALLOFLIST": "eliminar todos de %1",
    "DATA_INSERTATLIST": "insertar %1 en %2 de %3",
    "DATA_REPLACEITEMOFLIST": "reemplazar elemento %1 de %2 con %3",
    "DATA_ITEMOFLIST": "elemento %1 de %2",
    "DATA_ITEMNUMOFLIST": "# de elemento de %1 en %2",
    "DATA_LENGTHOFLIST": "longitud de %1",
    "DATA_LISTCONTAINSITEM": "¿%2 está en %1?",
    "DATA_SHOWLIST": "mostrar lista %1",
    "DATA_HIDELIST": "esconder lista %1",
    "DATA_INDEX_ALL": "todos",
    "DATA_INDEX_LAST": "último",
    "DATA_INDEX_RANDOM": "aleatorio",
    "EVENT_WHENFLAGCLICKED": "al hacer clic en %1",
    "EVENT_WHENTHISSPRITECLICKED": "al hacer clic en este objeto",
    "EVENT_WHENSTAGECLICKED": "al hacer clic en el escenario",
    "EVENT_WHENTOUCHINGOBJECT": "cuando el objeto toque %1",
    "EVENT_WHENBROADCASTRECEIVED": "al recibir %1",
    "EVENT_WHENBACKDROPSWITCHESTO": "cuando el fondo cambie a %1",
    "EVENT_WHENGREATERTHAN": "cuando %1 > %2",
    "EVENT_WHENGREATERTHAN_TIMER": "cronómetro",
    "EVENT_WHENGREATERTHAN_LOUDNESS": "volumen del sonido",
    "EVENT_BROADCAST": "enviar %1",
    "EVENT_BROADCASTANDWAIT": "enviar %1 y esperar",
    "EVENT_WHENKEYPRESSED": "al presionar tecla %1",
    "EVENT_WHENKEYPRESSED_SPACE": "espacio",
    "EVENT_WHENKEYPRESSED_LEFT": "flecha izquierda",
    "EVENT_WHENKEYPRESSED_RIGHT": "flecha derecha",
    "EVENT_WHENKEYPRESSED_DOWN": "flecha abajo",
    "EVENT_WHENKEYPRESSED_UP": "flecha arriba",
    "EVENT_WHENKEYPRESSED_ANY": "cualquiera",
    "LOOKS_SAYFORSECS": "decir %1 durante %2 segundos",
    "LOOKS_SAY": "decir %1",
    "LOOKS_HELLO": "¡Hola!",
    "LOOKS_THINKFORSECS": "pensar %1 durante %2 segundos",
    "LOOKS_THINK": "pensar %1",
    "LOOKS_HMM": "Umm...",
    "LOOKS_SHOW": "mostrar",
    "LOOKS_HIDE": "esconder",
    "LOOKS_HIDEALLSPRITES": "esconder todos los objetos",
    "LOOKS_EFFECT_COLOR": "color",
    "LOOKS_EFFECT_FISHEYE": "ojo de pez",
    "LOOKS_EFFECT_WHIRL": "remolino",
    "LOOKS_EFFECT_PIXELATE": "pixelar",
    "LOOKS_EFFECT_MOSAIC": "mosaico",
    "LOOKS_EFFECT_BRIGHTNESS": "brillo",
    "LOOKS_EFFECT_GHOST": "desvanecer",
    "LOOKS_CHANGEEFFECTBY": "sumar al efecto %1 %2",
    "LOOKS_SETEFFECTTO": "dar al efecto %1 el valor %2",
    "LOOKS_CLEARGRAPHICEFFECTS": "quitar efectos gráficos",
    "LOOKS_CHANGESIZEBY": "cambiar tamaño por %1",
    "LOOKS_SETSIZETO": "fijar tamaño al %1 %",
    "LOOKS_SIZE": "tamaño",
    "LOOKS_CHANGESTRETCHBY": "sumar al estiramiento %1",
    "LOOKS_SETSTRETCHTO": "fijar estiramiento al %1 %",
    "LOOKS_SWITCHCOSTUMETO": "cambiar disfraz a %1",
    "LOOKS_NEXTCOSTUME": "siguiente disfraz",
    "LOOKS_SWITCHBACKDROPTO": "cambiar fondo a %1",
    "LOOKS_GOTOFRONTBACK": "ir a capa %1",
    "LOOKS_GOTOFRONTBACK_FRONT": "delantera",
    "LOOKS_GOTOFRONTBACK_BACK": "trasera",
    "LOOKS_GOFORWARDBACKWARDLAYERS": "ir %2 capas hacia %1",
    "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "delante",
    "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "atrás",
    "LOOKS_BACKDROPNUMBERNAME": "%1 de fondo",
    "LOOKS_COSTUMENUMBERNAME": "%1 de disfraz",
    "LOOKS_NUMBERNAME_NUMBER": "número",
    "LOOKS_NUMBERNAME_NAME": "nombre",
    "LOOKS_SWITCHBACKDROPTOANDWAIT": "cambiar fondo a %1 y esperar",
    "LOOKS_NEXTBACKDROP_BLOCK": "siguiente fondo",
    "LOOKS_NEXTBACKDROP": "siguiente fondo",
    "LOOKS_PREVIOUSBACKDROP": "fondo anterior",
    "LOOKS_RANDOMBACKDROP": "fondo aleatorio",
    "MOTION_MOVESTEPS": "mover %1 pasos",
    "MOTION_TURNLEFT": "girar %1 %2 grados",
    "MOTION_TURNRIGHT": "girar %1 %2 grados",
    "MOTION_POINTINDIRECTION": "apuntar en dirección %1",
    "MOTION_POINTTOWARDS": "apuntar hacia %1",
    "MOTION_POINTTOWARDS_POINTER": "puntero del ratón",
    "MOTION_POINTTOWARDS_RANDOM": "dirección aleatoria",
    "MOTION_GOTO": "ir a %1",
    "MOTION_GOTO_POINTER": "puntero del ratón",
    "MOTION_GOTO_RANDOM": "posición aleatoria",
    "MOTION_GOTOXY": "ir a x: %1 y: %2",
    "MOTION_GLIDESECSTOXY": "deslizar en %1 segs a x: %2 y: %3",
    "MOTION_GLIDETO": "deslizar en %1 segs a %2",
    "MOTION_GLIDETO_POINTER": "puntero del ratón",
    "MOTION_GLIDETO_RANDOM": "posición aleatoria",
    "MOTION_CHANGEXBY": "sumar a x %1",
    "MOTION_SETX": "dar a x el valor %1",
    "MOTION_CHANGEYBY": "sumar a y %1",
    "MOTION_SETY": "dar a y el valor %1",
    "MOTION_IFONEDGEBOUNCE": "si toca un borde, rebotar",
    "MOTION_SETROTATIONSTYLE": "fijar estilo de rotación a %1",
    "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "izquierda-derecha",
    "MOTION_SETROTATIONSTYLE_DONTROTATE": "no rotar",
    "MOTION_SETROTATIONSTYLE_ALLAROUND": "en todas direcciones",
    "MOTION_XPOSITION": "posición en x",
    "MOTION_YPOSITION": "posición en y",
    "MOTION_DIRECTION": "dirección",
    "MOTION_SCROLLRIGHT": "desplazar a la derecha %1",
    "MOTION_SCROLLUP": "desplazar arriba %1",
    "MOTION_ALIGNSCENE": "alinear escena %1",
    "MOTION_ALIGNSCENE_BOTTOMLEFT": "abajo a la izquierda",
    "MOTION_ALIGNSCENE_BOTTOMRIGHT": "abajo a la derecha",
    "MOTION_ALIGNSCENE_MIDDLE": "al centro",
    "MOTION_ALIGNSCENE_TOPLEFT": "arriba a la izquierda",
    "MOTION_ALIGNSCENE_TOPRIGHT": "arriba a la derecha",
    "MOTION_XSCROLL": "desplazamiento en x",
    "MOTION_YSCROLL": "desplazamiento en y",
    "MOTION_STAGE_SELECTED": "Escenario seleccionado: no hay bloques de movimiento",
    "OPERATORS_ADD": "%1 + %2",
    "OPERATORS_SUBTRACT": "%1 - %2",
    "OPERATORS_MULTIPLY": "%1 * %2",
    "OPERATORS_DIVIDE": "%1 / %2",
    "OPERATORS_RANDOM": "número aleatorio entre %1 y %2",
    "OPERATORS_GT": "%1 > %2",
    "OPERATORS_GT_EQUALS": "",
    "OPERATORS_LT": "%1 < %2",
    "OPERATORS_LT_EQUALS": "",
    "OPERATORS_EQUALS": "%1 = %2",
    "OPERATORS_AND": "%1 y %2",
    "OPERATORS_OR": "%1 o %2",
    "OPERATORS_NOT": "no %1",
    "OPERATORS_JOIN": "unir %1 %2",
    "OPERATORS_JOIN_APPLE": "manzana",
    "OPERATORS_JOIN_BANANA": "plátano",
    "OPERATORS_LETTEROF": "letra %1 de %2",
    "OPERATORS_LETTEROF_APPLE": "a",
    "OPERATORS_LENGTH": "longitud de %1",
    "OPERATORS_CONTAINS": "¿%2 está en %1?",
    "OPERATORS_MOD": "%1 módulo %2",
    "OPERATORS_ROUND": "redondear %1",
    "OPERATORS_MATHOP": "%1 de %2",
    "OPERATORS_MATHOP_ABS": "valor absoluto",
    "OPERATORS_MATHOP_FLOOR": "suelo",
    "OPERATORS_MATHOP_CEILING": "techo",
    "OPERATORS_MATHOP_SQRT": "raíz cuadrada",
    "OPERATORS_MATHOP_SIN": "sen",
    "OPERATORS_MATHOP_COS": "cos",
    "OPERATORS_MATHOP_TAN": "tan",
    "OPERATORS_MATHOP_ASIN": "arcsen",
    "OPERATORS_MATHOP_ACOS": "arccos",
    "OPERATORS_MATHOP_ATAN": "arctan",
    "OPERATORS_MATHOP_LN": "ln",
    "OPERATORS_MATHOP_LOG": "log",
    "OPERATORS_MATHOP_EEXP": "e ^",
    "OPERATORS_MATHOP_10EXP": "10 ^",
    "PROCEDURES_DEFINITION": "definir %1",
    "SENSING_TOUCHINGOBJECT": "¿tocando %1?",
    "SENSING_TOUCHINGOBJECT_POINTER": "puntero del ratón",
    "SENSING_TOUCHINGOBJECT_EDGE": "borde",
    "SENSING_TOUCHINGCOLOR": "¿tocando el color %1?",
    "SENSING_COLORISTOUCHINGCOLOR": "¿color %1 tocando %2?",
    "SENSING_DISTANCETO": "distancia a %1",
    "SENSING_DISTANCETO_POINTER": "puntero del ratón",
    "SENSING_ASKANDWAIT": "preguntar %1 y esperar",
    "SENSING_ASK_TEXT": "¿Cómo te llamas?",
    "SENSING_ANSWER": "respuesta",
    "SENSING_KEYPRESSED": "¿tecla %1 presionada?",
    "SENSING_MOUSEDOWN": "¿ratón presionado?",
    "SENSING_MOUSEX": "posición x del ratón",
    "SENSING_MOUSEY": "posición y del ratón",
    "SENSING_SETDRAGMODE": "fijar modo de arrastre a %1",
    "SENSING_SETDRAGMODE_DRAGGABLE": "arrastrable",
    "SENSING_SETDRAGMODE_NOTDRAGGABLE": "no arrastrable",
    "SENSING_LOUDNESS": "volumen del sonido",
    "SENSING_LOUD": "¿ruidoso?",
    "SENSING_TIMER": "cronómetro",
    "SENSING_RESETTIMER": "reiniciar cronómetro",
    "SENSING_OF": "%1 de %2",
    "SENSING_OF_XPOSITION": "posición en x",
    "SENSING_OF_YPOSITION": "posición en y",
    "SENSING_OF_DIRECTION": "dirección",
    "SENSING_OF_COSTUMENUMBER": "# de disfraz",
    "SENSING_OF_COSTUMENAME": "nombre de disfraz",
    "SENSING_OF_SIZE": "tamaño",
    "SENSING_OF_VOLUME": "volumen",
    "SENSING_OF_BACKDROPNUMBER": "# de fondo",
    "SENSING_OF_BACKDROPNAME": "nombre de fondo",
    "SENSING_OF_STAGE": "Escenario",
    "SENSING_CURRENT": "%1 actual",
    "SENSING_CURRENT_YEAR": "año",
    "SENSING_CURRENT_MONTH": "mes",
    "SENSING_CURRENT_DATE": "día",
    "SENSING_CURRENT_DAYOFWEEK": "día de la semana",
    "SENSING_CURRENT_HOUR": "hora",
    "SENSING_CURRENT_MINUTE": "minuto",
    "SENSING_CURRENT_SECOND": "segundo",
    "SENSING_DAYSSINCE2000": "días desde el 2000",
    "SENSING_USERNAME": "nombre de usuario",
    "SENSING_USERID": "id de usuario",
    "SOUND_PLAY": "iniciar sonido %1",
    "SOUND_PLAYUNTILDONE": "tocar sonido %1 hasta que termine",
    "SOUND_STOPALLSOUNDS": "detener todos los sonidos",
    "SOUND_SETEFFECTO": "dar al efecto %1 el valor %2",
    "SOUND_CHANGEEFFECTBY": "sumar al efecto %1 %2",
    "SOUND_CLEAREFFECTS": "quitar efectos de sonido",
    "SOUND_EFFECTS_PITCH": "altura",
    "SOUND_EFFECTS_PAN": "balance izquierda/derecha",
    "SOUND_CHANGEVOLUMEBY": "cambiar volumen por %1",
    "SOUND_SETVOLUMETO": "fijar volumen al %1%",
    "SOUND_VOLUME": "volumen",
    "SOUND_RECORD": "grabar...",
    "CATEGORY_MOTION": "Movimiento",
    "CATEGORY_LOOKS": "Apariencia",
    "CATEGORY_SOUND": "Sonido",
    "CATEGORY_EVENTS": "Eventos",
    "CATEGORY_CONTROL": "Control",
    "CATEGORY_SENSING": "Sensores",
    "CATEGORY_OPERATORS": "Operadores",
    "CATEGORY_VARIABLES": "Variables",
    "CATEGORY_MYBLOCKS": "Mis bloques",
    "CATEGORY_LISTS": "",
    "CATEGORY_TUPLE": "",
    "CATEGORY_DICTIONARYS": "",
    "CATEGORY_NUMBERS": "",
    "CATEGORY_TEXT": "",
    "DUPLICATE": "Duplicar",
    "DELETE": "Eliminar",
    "ADD_COMMENT": "Añadir comentario",
    "REMOVE_COMMENT": "Eliminar comentario",
    "DELETE_BLOCK": "Eliminar bloque",
    "DELETE_X_BLOCKS": "Eliminar %1 bloques",
    "DELETE_ALL_BLOCKS": "¿Eliminar los %1 bloques?",
    "CLEAN_UP": "Ordenar bloques",
    "HELP": "Ayuda",
    "UNDO": "Deshacer",
    "REDO": "Rehacer",
    "EDIT_PROCEDURE": "Editar",
    "SHOW_PROCEDURE_DEFINITION": "Ir a definición",
    "WORKSPACE_COMMENT_DEFAULT_TEXT": "Di algo...",
    "COLOUR_HUE_LABEL": "Color",
    "COLOUR_SATURATION_LABEL": "Saturación",
    "COLOUR_BRIGHTNESS_LABEL": "Brillo",
    "CHANGE_VALUE_TITLE": "Cambiar valor:",
    "RENAME_VARIABLE": "Renombrar variable",
    "RENAME_VARIABLE_STRING": "",
    "RENAME_VARIABLE_NUMBER": "",
    "RENAME_VARIABLE_LIST": "",
    "RENAME_VARIABLE_TITLE": "Renombrar todas las variables \"%1\" a:",
    "RENAME_VARIABLE_MODAL_TITLE": "Renombrar variable",
    "NEW_VARIABLE": "Crear una variable",
    "NEW_VARIABLE_TITLE": "Nombre de la variable:",
    "VARIABLE_MODAL_TITLE": "Nueva variable",
    "VARIABLE_ALREADY_EXISTS": "Ya existe una variable llamada \"%1\".",
    "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "Ya existe una variable llamada \"%1\" para otra variable de tipo \"%2\".",
    "DELETE_VARIABLE_CONFIRMATION": "¿Eliminar %1 usos de la variable \"%2\"?",
    "CANNOT_DELETE_VARIABLE_PROCEDURE": "No se puede eliminar la variable \"%1\" porque es parte de la definición de la función \"%2\"",
    "DELETE_VARIABLE": "Eliminar la variable \"%1\"",
    "DELETE_VARIABLE_STRING": "",
    "DELETE_VARIABLE_NUMBER": "",
    "DELETE_VARIABLE_LIST": "",
    "NEW_PROCEDURE": "Crear un bloque",
    "PROCEDURE_ALREADY_EXISTS": "Ya existe un procedimiento llamado \"%1\".",
    "PROCEDURE_DEFAULT_NAME": "nombre del bloque",
    "NEW_LIST": "Crear una lista",
    "NEW_LIST_TITLE": "Nombre de la lista:",
    "LIST_MODAL_TITLE": "Nueva lista",
    "LIST_ALREADY_EXISTS": "Ya existe una lista llamada \"%1\".",
    "RENAME_LIST_TITLE": "Renombrar todas las listas \"%1\" a:",
    "RENAME_LIST_MODAL_TITLE": "Renombrar lista",
    "DEFAULT_LIST_ITEM": "cosa",
    "DELETE_LIST": "Eliminar la lista \"%1\"",
    "RENAME_LIST": "Renombrar lista",
    "NEW_BROADCAST_MESSAGE": "Nuevo mensaje",
    "NEW_BROADCAST_MESSAGE_TITLE": "Nombre del mensaje:",
    "BROADCAST_MODAL_TITLE": "Nuevo Mensaje",
    "DEFAULT_BROADCAST_MESSAGE_NAME": "mensaje1",
    "DELETE_PROCEDURE": "",
    "OK": "",
    "PROMPT": "",
    "STOP": "",
    "NEW_STRING": "",
    "NEW_NUMBER_VARIABLE": "",
    "WIFI": "",
    "USERNAME": "",
    "PWD": "",
    "IOT_SERVICE": "",
    "IOT_ID": "",
    "IOT_PWD": "",
    "IOT_TOPIC": "",
    "MORE_TOPIC": "",
    "OBLOQ_HTTP": "",
    "OBLOQ_IP": "",
    "OBLOQ_PORT": "",
    "ADD_TOPIC": "",
    "DELETE_TOPIC": "",
    "IOT_SERVER": "",
    "CHINA": "",
    "GLOBAL": "",
    "LOW_C": "",
    "LOW_C$": "",
    "LOW_D": "",
    "LOW_D$": "",
    "LOW_E": "",
    "LOW_F": "",
    "LOW_F$": "",
    "LOW_G": "",
    "LOW_G$": "",
    "LOW_A": "",
    "LOW_A$": "",
    "LOW_B": "",
    "MIDDLE_C": "",
    "MIDDLE_C$": "",
    "MIDDLE_D": "",
    "MIDDLE_D$": "",
    "MIDDLE_E": "",
    "MIDDLE_F": "",
    "MIDDLE_F$": "",
    "MIDDLE_G": "",
    "MIDDLE_G$": "",
    "MIDDLE_A": "",
    "MIDDLE_A$": "",
    "MIDDLE_B": "",
    "HIGH_C": "",
    "HIGH_C$": "",
    "HIGH_D": "",
    "HIGH_D$": "",
    "HIGH_E": "",
    "HIGH_F": "",
    "HIGH_F$": "",
    "HIGH_G": "",
    "HIGH_G$": "",
    "HIGH_A": "",
    "HIGH_A$": "",
    "HIGH_B": "",
    "OPERATORS_STRINGTONUMBER": "",
    "INTEGER": "",
    "DECIMAL": "",
    "OPERATORS_NUMTOASCII": "",
    "OPERATORS_STRINGTOASCII": "",
    "OPERATORS_NUMTOSTRING": "",
    "OPERATORS_MAP": "",
    "OPERATORS_CONSTRAIN": "",
    "STEPS_PER_TURN": "",
    "ROTATE_SPEED": "",
    "NEW_AI": "",
    "ASK_AND_PAINT": "",
    "GET_IDENTIFIED_NUM": "",
    "READ_NUM_AND_SAY": "",
    "IMAGE_ADDR": "",
    "IMAGE_PREVIEW": "",
    "IMAGE_OPEN": "",
    "IMAGE_SIZE": "",
    "IMAGE_WIDTH": "",
    "IMAGE_HEIGHT": "",
    "VARIABLE": "",
    "VARIABLE_LIST": "",
    "SET_STRING_VARIABLE": "",
    "STRING_START_WITH": "",
    "DICTIONARY_HEIGHT_FIRST": "",
    "DICTIONARY_HEIGHT_TWO": "",
    "DICTIONARY_HEIGHT_THREE": "",
    "DICTIONARY_HEIGHT_FOUR": "",
    "DATA_MPITEMOFLIST": "",
    "DATA_MPINTERCEPTITEM": "",
    "DATA_MPINSERTATLIST": "",
    "DATA_MPSETVALUEATLIST": "",
    "DATA_MPINSERTATLIST_INSERT": "",
    "DATA_MPINSERTATLIST_CHANGE": "",
    "DATA_MPINSERTATLIST_FOUR": "",
    "DATA_MPINSERTATLIST_FIVE": "",
    "DATA_MPINSERTATLIST_SIX": "",
    "DATA_MPINSERTATLIST_SEVEN": "",
    "DATA_MPINSERTATLIST_EIGHT": "",
    "DATA_MPINSERTATLIST_EIGHT_FALSE": "",
    "DATA_MPINSERTATLIST_EIGHT_TRUE": "",
    "DATA_MPINSERTATLIST_EIGHT_REVERSE": "",
    "OPERATORS_RANDOMA": "",
    "OPERATORS_MODA": "",
    "OPERATORS_ROUNDA": "",
    "OPERATORS_MATHOPA": "",
    "OPERATORS_MATHOP_ABSA": "",
    "OPERATORS_MATHOP_FLOORA": "",
    "OPERATORS_MATHOP_CEILINGA": "",
    "OPERATORS_MATHOP_SQRTA": "",
    "OPERATORS_MATHOP_FIVE": "",
    "OPERATORS_MATHOP_SIX": "",
    "OPERATORS_MATHOP_SEVEN": "",
    "OPERATORS_MATHOP_LENGHT": "",
    "OPERATORS_MATHOP_MAXMIN": "",
    "OPERATORS_MATHOP_MAXMIN_MAX": "",
    "OPERATORS_MATHOP_MAXMIN_MIN": "",
    "OPERATORS_TEXTS_ONE": "",
    "OPERATORS_TEXTS_TWO": "",
    "OPERATORS_TEXTS_THREE": "",
    "OPERATORS_TEXTS_FOUR": "",
    "OPERATORS_TEXTS_FOUR_NEW": "",
    "OPERATORS_TEXTS_NEW": "",
    "OPERATORS_TEXTS_NEW_OP": "",
    "OPERATORS_TEXTS_FOUR_ONE": "",
    "OPERATORS_TEXTS_FOUR_TWO": "",
    "OPERATORS_TEXTS_FOUR_THREE": "",
    "OPERATORS_TEXTS_FOUR_FOUR": "",
    "OPERATORS_TEXTS_FOUR_F": "",
    "OPERATORS_TEXTS_FOUR_L": "",
    "OPERATORS_STRINGTONUMBERA": "",
    "OPERATORS_NUMTOASCIIA": "",
    "OPERATORS_STRINGTOASCIIA": "",
    "OPERATORS_NUMTOSTRINGA": "",
    "NEW_MC": "",
    "RENAME_MCNUMBER_TITLE": "",
    "RENAME_MCNUMBER_MODAL_TITLE": "",
    "RENAME_VARIABLE_MCNUMBER": "",
    "DELETE_VARIABLE_MCNUMBER": "",
    "SET_MC_VARIABLE": "",
    "TUPLE_ONE": "",
    "TUPLE_TWO": "",
    "ALIYUN": "",
    "ONENET": "",
    "EASYIOT": "",
    "SHANGHAI": "",
    "QINGDAO": "",
    "BEIJING": "",
    "ZHANGJIAKOU": "",
    "HUHEHAOTE": "",
    "HANGZHOU": "",
    "SHENZHEN": "",
    "HONGKONG": "",
    "SINGAPORE": "",
    "SYDNEY": "",
    "KUALALUMPUR": "",
    "JAKARTA": "",
    "MUMBAI": "",
    "TOKYO": "",
    "SILICONVALLEY": "",
    "VIRGINIA": "",
    "FRANKFURT": "",
    "LONDON": "",
    "DUBAI": "",
    "IOT_PLATFORM": "",
    "PARAMS": "",
    "SERVER_ATTR": "",
    "PRODUCTID": "",
    "DEVICEID": "",
    "TINYDB_SECRET": "",
    "TINYDB_USER": "",
    "APIADDR": "",
    "SOFTWARE_SERIAL": "",
    "HARDWARE_SERIAL": "",
    "HARDWARE_SERIAL1": "",
    "HARDWARE_SERIAL2": "",
    "HARDWARE_SERIAL3": ""
  },
  "es-419": {
    "CONTROL_FOREVER": "por siempre",
    "CONTROL_LOOP_BREAKCONTINUE": "%1",
    "CONTROL_LOOP_BREAKCONTINUE_BREAK": "break",
    "CONTROL_LOOP_BREAKCONTINUE_CONTINUE": "continue",
    "CONTROL_REPEAT": "repetir %1",
    "CONTROL_IF": "si %1 entonces",
    "CONTROL_ELSE": "si no",
    "CONTROL_STOP": "detener",
    "CONTROL_STOP_ALL": "todos",
    "CONTROL_STOP_THIS": "este programa",
    "CONTROL_STOP_OTHER": "otros programas en el objeto",
    "CONTROL_WAIT": "esperar %1 segundos",
    "CONTROL_WAIT_ESP32_MPY": "wait %1 %2",
    "CONTROL_FOR_FROM_SEQUENCE": "use %1 from sequence %2",
    "CONTROL_WAIT_ESP32_MPY_S": "second(s)",
    "CONTROL_WAIT_ESP32_MPY_MS": "millisecond(ms)",
    "CONTROL_WAIT_ESP32_MPY_US": "microsecond(us)",
    "CONTROL_WAITUNTIL": "esperar hasta que %1",
    "CONTROL_REPEATUNTIL": "repetir hasta que %1",
    "CONTROL_WHILE": "mientras %1",
    "CONTROL_FOREACH": "para cada %1 en %2",
    "CONTROL_STARTASCLONE": "al comenzar como clon",
    "CONTROL_CREATECLONEOF": "crear clon de %1",
    "CONTROL_CREATECLONEOF_MYSELF": "mí mismo",
    "CONTROL_DELETETHISCLONE": "eliminar este clon",
    "CONTROL_COUNTER": "contador",
    "CONTROL_INCRCOUNTER": "incrementar contador",
    "CONTROL_CLEARCOUNTER": "borrar contador",
    "CONTROL_ALLATONCE": "todos a la vez",
    "DATA_SETVARIABLETO": "establecer %1 a %2",
    "DATA_CHANGEVARIABLEBY": "cambiar %1 en %2",
    "DATA_SHOWVARIABLE": "mostrar variable %1",
    "DATA_HIDEVARIABLE": "ocultar variable %1",
    "DATA_ADDTOLIST": "añadir %1 a %2",
    "DATA_DELETEOFLIST": "eliminar %1 de %2",
    "DATA_DELETEALLOFLIST": "Eliminar todos de %1",
    "DATA_INSERTATLIST": "insertar %1 en %2 de %3",
    "DATA_REPLACEITEMOFLIST": "reemplazar elemento %1 de %2 con %3",
    "DATA_ITEMOFLIST": "elemento %1 de %2",
    "DATA_ITEMNUMOFLIST": "elemento # %1 en %2 ",
    "DATA_LENGTHOFLIST": "longitud de %1",
    "DATA_LISTCONTAINSITEM": "¿%1 contiene %2?",
    "DATA_SHOWLIST": "mostrar lista %1",
    "DATA_HIDELIST": "esconder lista %1",
    "DATA_INDEX_ALL": "todos",
    "DATA_INDEX_LAST": "último",
    "DATA_INDEX_RANDOM": "al azar",
    "EVENT_WHENFLAGCLICKED": "al presionar %1",
    "EVENT_WHENTHISSPRITECLICKED": "al hacer clic en este objeto",
    "EVENT_WHENSTAGECLICKED": "al hacer clic en el escenario",
    "EVENT_WHENTOUCHINGOBJECT": "cuando este objeto toque %1",
    "EVENT_WHENBROADCASTRECEIVED": "al recibir %1",
    "EVENT_WHENBACKDROPSWITCHESTO": "cuando el fondo cambie a %1",
    "EVENT_WHENGREATERTHAN": "cuando %1 > %2",
    "EVENT_WHENGREATERTHAN_TIMER": "cronómetro",
    "EVENT_WHENGREATERTHAN_LOUDNESS": "volumen del sonido",
    "EVENT_BROADCAST": "enviar %1",
    "EVENT_BROADCASTANDWAIT": "enviar %1 y esperar",
    "EVENT_WHENKEYPRESSED": "al presionar la tecla %1",
    "EVENT_WHENKEYPRESSED_SPACE": "espacio",
    "EVENT_WHENKEYPRESSED_LEFT": "flecha izquierda",
    "EVENT_WHENKEYPRESSED_RIGHT": "flecha derecha",
    "EVENT_WHENKEYPRESSED_DOWN": "flecha abajo",
    "EVENT_WHENKEYPRESSED_UP": "flecha arriba",
    "EVENT_WHENKEYPRESSED_ANY": "cualquiera",
    "LOOKS_SAYFORSECS": "decir %1 por %2 segundos",
    "LOOKS_SAY": "decir %1",
    "LOOKS_HELLO": "¡Hola!",
    "LOOKS_THINKFORSECS": "pensar %1 por %2 segundos",
    "LOOKS_THINK": "pensar %1",
    "LOOKS_HMM": "Hmm...",
    "LOOKS_SHOW": "mostrar",
    "LOOKS_HIDE": "ocultar",
    "LOOKS_HIDEALLSPRITES": "esconder todos los objetos",
    "LOOKS_EFFECT_COLOR": "color",
    "LOOKS_EFFECT_FISHEYE": "ojo de pez",
    "LOOKS_EFFECT_WHIRL": "remolino",
    "LOOKS_EFFECT_PIXELATE": "pixelar",
    "LOOKS_EFFECT_MOSAIC": "mosaico",
    "LOOKS_EFFECT_BRIGHTNESS": "brillo",
    "LOOKS_EFFECT_GHOST": "desvanecer",
    "LOOKS_CHANGEEFFECTBY": "cambiar el efecto %1 en %2",
    "LOOKS_SETEFFECTTO": "fijar efecto %1 a %2",
    "LOOKS_CLEARGRAPHICEFFECTS": "quitar efectos gráficos",
    "LOOKS_CHANGESIZEBY": "cambiar tamaño en %1",
    "LOOKS_SETSIZETO": "fijar tamaño a %1 %",
    "LOOKS_SIZE": "tamaño",
    "LOOKS_CHANGESTRETCHBY": "cambiar estiramiento en %1",
    "LOOKS_SETSTRETCHTO": "fijar estiramiento a %1 %",
    "LOOKS_SWITCHCOSTUMETO": "cambiar disfraz a %1",
    "LOOKS_NEXTCOSTUME": "siguiente disfraz",
    "LOOKS_SWITCHBACKDROPTO": "cambiar fondo a %1",
    "LOOKS_GOTOFRONTBACK": "ir a la capa %1 ",
    "LOOKS_GOTOFRONTBACK_FRONT": "del frente",
    "LOOKS_GOTOFRONTBACK_BACK": "de atrás",
    "LOOKS_GOFORWARDBACKWARDLAYERS": "ir %2 capas hacia %1",
    "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "adelante",
    "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "atrás",
    "LOOKS_BACKDROPNUMBERNAME": "fondo %1",
    "LOOKS_COSTUMENUMBERNAME": "disfraz %1",
    "LOOKS_NUMBERNAME_NUMBER": "número",
    "LOOKS_NUMBERNAME_NAME": "nombre",
    "LOOKS_SWITCHBACKDROPTOANDWAIT": "cambiar fondo a %1 y esperar",
    "LOOKS_NEXTBACKDROP_BLOCK": "fondo siguiente",
    "LOOKS_NEXTBACKDROP": "fondo siguiente",
    "LOOKS_PREVIOUSBACKDROP": "fondo anterior",
    "LOOKS_RANDOMBACKDROP": "fondo aleatorio",
    "MOTION_MOVESTEPS": "mover %1 pasos",
    "MOTION_TURNLEFT": "girar %1 %2 grados",
    "MOTION_TURNRIGHT": "girar %1 %2 grados",
    "MOTION_POINTINDIRECTION": "apuntar en dirección %1",
    "MOTION_POINTTOWARDS": "apuntar hacia %1",
    "MOTION_POINTTOWARDS_POINTER": "puntero del ratón",
    "MOTION_POINTTOWARDS_RANDOM": "dirección aleatoria",
    "MOTION_GOTO": "ir a %1",
    "MOTION_GOTO_POINTER": "puntero del ratón",
    "MOTION_GOTO_RANDOM": "posición aleatoria",
    "MOTION_GOTOXY": "ir a x: %1 y: %2",
    "MOTION_GLIDESECSTOXY": "deslizar en %1 segs a x: %2 y: %3",
    "MOTION_GLIDETO": "deslizar en %1 segs a %2",
    "MOTION_GLIDETO_POINTER": "puntero del ratón",
    "MOTION_GLIDETO_RANDOM": "posición aleatoria",
    "MOTION_CHANGEXBY": "cambiar x en %1",
    "MOTION_SETX": "fijar x a %1",
    "MOTION_CHANGEYBY": "cambiar y en %1",
    "MOTION_SETY": "fijar y a %1",
    "MOTION_IFONEDGEBOUNCE": "rebotar si toca un borde",
    "MOTION_SETROTATIONSTYLE": "fijar estilo de rotación: %1",
    "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "izquierda-derecha",
    "MOTION_SETROTATIONSTYLE_DONTROTATE": "no rotar",
    "MOTION_SETROTATIONSTYLE_ALLAROUND": "en todas las direcciones",
    "MOTION_XPOSITION": "posición en x",
    "MOTION_YPOSITION": "posición en y",
    "MOTION_DIRECTION": "dirección",
    "MOTION_SCROLLRIGHT": "deslizar a la derecha %1",
    "MOTION_SCROLLUP": "deslizar hacia arriba %1",
    "MOTION_ALIGNSCENE": "alinear escena %1",
    "MOTION_ALIGNSCENE_BOTTOMLEFT": "abajo a la izquierda",
    "MOTION_ALIGNSCENE_BOTTOMRIGHT": "abajo a la derecha",
    "MOTION_ALIGNSCENE_MIDDLE": "mitad",
    "MOTION_ALIGNSCENE_TOPLEFT": "arriba a la izquierda",
    "MOTION_ALIGNSCENE_TOPRIGHT": "arriba a la derecha",
    "MOTION_XSCROLL": "deslizar x",
    "MOTION_YSCROLL": "deslizar y",
    "MOTION_STAGE_SELECTED": "Escenario seleccionado: no existen bloques de movimiento",
    "OPERATORS_ADD": "%1 + %2",
    "OPERATORS_SUBTRACT": "%1 - %2",
    "OPERATORS_MULTIPLY": "%1 * %2",
    "OPERATORS_DIVIDE": "%1 / %2",
    "OPERATORS_RANDOM": "número al azar entre %1 y %2",
    "OPERATORS_GT": "%1 > %2",
    "OPERATORS_GT_EQUALS": "%1 >= %2",
    "OPERATORS_LT": "%1 < %2",
    "OPERATORS_LT_EQUALS": "%1 <= %2",
    "OPERATORS_EQUALS": "%1 = %2",
    "OPERATORS_AND": "%1 y %2",
    "OPERATORS_OR": "%1 o %2",
    "OPERATORS_NOT": "no %1",
    "CONTROL_TRUE_FALSE": "%1",
    "CONTROL_TRUE_FALSE_TRUE": "true",
    "CONTROL_TRUE_FALSE_FALSE": "false",
    "CONTROL_NONE": "None",
    "CONTROL_RETURN": "return %1",
    "OPERATORS_VALUE_IS_TRUE": "value %1 is true?",
    "OPERATORS_VALUE_TYPE": "value %1 type",
    "CONTROL_TRY": "try",
    "CONTROL_EXCEPT": "except",
    "CONTROL_FINALLY": "finally",
    "OPERATORS_VALUE_TYPE_IS": "value %1 type is %2?",
    "OPERATORS_VALUE_TYPE_INT": "int",
    "OPERATORS_VALUE_TYPE_FLOAT": "float",
    "OPERATORS_VALUE_TYPE_BOOL": "bool",
    "OPERATORS_VALUE_TYPE_STR": "str",
    "OPERATORS_VALUE_TYPE_LIST": "list",
    "OPERATORS_VALUE_TYPE_TUPLE": "tuple",
    "OPERATORS_VALUE_TYPE_SET": "set",
    "OPERATORS_VALUE_TYPE_DICT": "dict",
    "OPERATORS_VALUE_TYPE_BYTES": "bytes",
    "OPERATORS_VALUE_TYPE_BYTEARRAY": "bytearray",
    "OPERATORS_VALUE_TYPE_COMPLEX": "complex",
    "OPERATORS_CACULATE_RESULT": "eval %1",
    "OPERATORS_JOIN": "unir %1 %2",
    "OPERATORS_JOIN_APPLE": "manzana",
    "OPERATORS_JOIN_BANANA": "banana",
    "OPERATORS_LETTEROF": "letra %1 de %2",
    "OPERATORS_LETTEROF_APPLE": "a",
    "OPERATORS_LENGTH": "longitud de %1",
    "OPERATORS_CONTAINS": "%1 contiene %2?",
    "OPERATORS_MOD": "%1 mód %2",
    "OPERATORS_ROUND": "redondear %1",
    "OPERATORS_MATHOP": "%1 de %2",
    "OPERATORS_MATHOP_ABS": "abs",
    "OPERATORS_MATHOP_FLOOR": "piso",
    "OPERATORS_MATHOP_CEILING": "techo",
    "OPERATORS_MATHOP_SQRT": "raíz cuadrada",
    "OPERATORS_MATHOP_SIN": "seno",
    "OPERATORS_MATHOP_COS": "coseno",
    "OPERATORS_MATHOP_TAN": "tangente",
    "OPERATORS_MATHOP_ASIN": "arcoseno",
    "OPERATORS_MATHOP_ACOS": "arcocoseno",
    "OPERATORS_MATHOP_ATAN": "arcotangente",
    "OPERATORS_MATHOP_LN": "logaritmo natural",
    "OPERATORS_MATHOP_LOG": "logaritmo en base 10",
    "OPERATORS_MATHOP_EEXP": "e ^",
    "OPERATORS_MATHOP_10EXP": "10 ^",
    "PROCEDURES_DEFINITION": "definir %1",
    "SENSING_TOUCHINGOBJECT": "¿tocando %1?",
    "SENSING_TOUCHINGOBJECT_POINTER": "el puntero del ratón",
    "SENSING_TOUCHINGOBJECT_EDGE": "el borde",
    "SENSING_TOUCHINGCOLOR": "¿tocando el color %1?",
    "SENSING_COLORISTOUCHINGCOLOR": "¿color %1 está tocando %2?",
    "SENSING_DISTANCETO": "distancia a %1",
    "SENSING_DISTANCETO_POINTER": "el puntero del ratón",
    "SENSING_ASKANDWAIT": "preguntar %1 y esperar",
    "SENSING_ASK_TEXT": "¿Cómo te llamas?",
    "SENSING_ANSWER": "respuesta",
    "SENSING_KEYPRESSED": "¿tecla %1 presionada?",
    "SENSING_MOUSEDOWN": "¿ratón presionado?",
    "SENSING_MOUSEX": "posición x del ratón",
    "SENSING_MOUSEY": "posición y del ratón",
    "SENSING_SETDRAGMODE": "fijar modo de arrastre a %1",
    "SENSING_SETDRAGMODE_DRAGGABLE": "arrastrable",
    "SENSING_SETDRAGMODE_NOTDRAGGABLE": "no arrastrable",
    "SENSING_LOUDNESS": "volumen del sonido",
    "SENSING_LOUD": "¿fuerte?",
    "SENSING_TIMER": "cronómetro",
    "SENSING_RESETTIMER": "reiniciar cronómetro",
    "SENSING_OF": "%1 de %2",
    "SENSING_OF_XPOSITION": "posición en x",
    "SENSING_OF_YPOSITION": "posición en y",
    "SENSING_OF_DIRECTION": "dirección",
    "SENSING_OF_COSTUMENUMBER": "# de disfraz",
    "SENSING_OF_COSTUMENAME": "nombre del disfraz",
    "SENSING_OF_SIZE": "tamaño",
    "SENSING_OF_VOLUME": "volumen",
    "SENSING_OF_BACKDROPNUMBER": "# de fondo",
    "SENSING_OF_BACKDROPNAME": "nombre de fondo",
    "SENSING_OF_STAGE": "Escenario",
    "SENSING_CURRENT": "%1 actual",
    "SENSING_CURRENT_YEAR": "año",
    "SENSING_CURRENT_MONTH": "mes",
    "SENSING_CURRENT_DATE": "fecha",
    "SENSING_CURRENT_DAYOFWEEK": "día de la semana",
    "SENSING_CURRENT_HOUR": "hora",
    "SENSING_CURRENT_MINUTE": "minuto",
    "SENSING_CURRENT_SECOND": "segundo",
    "SENSING_DAYSSINCE2000": "días desde el 2000",
    "SENSING_USERNAME": "nombre de usuario",
    "SENSING_USERID": "identificación de usuario",
    "SOUND_PLAY": "tocar sonido %1",
    "SOUND_PLAYUNTILDONE": "tocar sonido %1 hasta terminar",
    "SOUND_STOPALLSOUNDS": "detener todos los sonidos",
    "SOUND_SETEFFECTO": "fijar efecto %1 a %2",
    "SOUND_CHANGEEFFECTBY": "cambiar efecto %1 en %2",
    "SOUND_CLEAREFFECTS": "borrar efectos de sonido",
    "SOUND_EFFECTS_PITCH": "altura",
    "SOUND_EFFECTS_PAN": "paneo izquierda/derecha",
    "SOUND_CHANGEVOLUMEBY": "cambiar volumen en %1",
    "SOUND_SETVOLUMETO": "fijar volumen a %1%",
    "SOUND_VOLUME": "volumen",
    "SOUND_RECORD": "grabar...",
    "CATEGORY_MOTION": "Movimiento",
    "CATEGORY_LOOKS": "Apariencia",
    "CATEGORY_SOUND": "Sonido",
    "CATEGORY_EVENTS": "Eventos",
    "CATEGORY_CONTROL": "Control",
    "CATEGORY_SENSING": "Sensores",
    "CATEGORY_OPERATORS": "Operadores",
    "CATEGORY_VARIABLES": "Variables",
    "CATEGORY_MYBLOCKS": "Mis Bloques",
    "CATEGORY_NUMBERS": "Número",
    "CATEGORY_TEXT": "Text",
    "DUPLICATE": "Duplicar",
    "DELETE": "Eliminar",
    "ADD_COMMENT": "Agregar comentario",
    "REMOVE_COMMENT": "Eliminar comentario",
    "DELETE_BLOCK": "Eliminar bloque",
    "DELETE_X_BLOCKS": "Eliminar %1 bloques",
    "DELETE_ALL_BLOCKS": "¿Eliminar todos los bloques %1?",
    "CLEAN_UP": "Limpiar bloques",
    "HELP": "Ayuda",
    "UNDO": "Deshacer",
    "REDO": "Rehacer",
    "EDIT_PROCEDURE": "Editar",
    "SHOW_PROCEDURE_DEFINITION": "Ir a la definición",
    "WORKSPACE_COMMENT_DEFAULT_TEXT": "Decir algo...",
    "COLOUR_HUE_LABEL": "Color",
    "COLOUR_SATURATION_LABEL": "Saturación",
    "COLOUR_BRIGHTNESS_LABEL": "Brillo",
    "CHANGE_VALUE_TITLE": "Cambiar valor:",
    "RENAME_VARIABLE": "Renombrar variable",
    "RENAME_VARIABLE_STRING": "Renombrar Variable de Cadena",
    "RENAME_VARIABLE_NUMBER": "Renombrar Variable de Número",
    "RENAME_VARIABLE_LIST": "Renombrar Variable de Lista",
    "RENAME_VARIABLE_TITLE": "Renombrar todas las variables [%1] a:",
    "RENAME_VARIABLE_MODAL_TITLE": "Renombrar variable",
    "NEW_VARIABLE": "Crear una variable",
    "NEW_VARIABLE_TITLE": "Nuevo nombre de variable:",
    "VARIABLE_MODAL_TITLE": "Variable nueva",
    "VARIABLE_ALREADY_EXISTS": "Ya existe una variable llamada [%1].",
    "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "Ya existe una variable llamada [%1] para otra variable de tipo [%2].",
    "DELETE_VARIABLE_CONFIRMATION": "¿Eliminar %1 usos de la variable [%2]?",
    "CANNOT_DELETE_VARIABLE_PROCEDURE": "No se puede eliminar la variable [%1] porque es parte de la definición de la función [%2]",
    "DELETE_VARIABLE": "Eliminar la variable [%1]",
    "DELETE_VARIABLE_STRING": "Borrar Variable de Cadena [%1]",
    "DELETE_VARIABLE_NUMBER": "Borrar Variable de Número [%1]",
    "DELETE_VARIABLE_LIST": "Borrar Variable de Lista [%1]",
    "NEW_PROCEDURE": "Crear un bloque",
    "PROCEDURE_ALREADY_EXISTS": "Ya existe un procedimiento llamado [%1].",
    "PROCEDURE_DEFAULT_NAME": "nombre del bloque",
    "NEW_LIST": "Crear una lista",
    "NEW_LIST_TITLE": "Nombre para nueva lista:",
    "LIST_MODAL_TITLE": "Lista nueva",
    "LIST_ALREADY_EXISTS": "Ya existe una lista llamada [%1].",
    "RENAME_LIST_TITLE": "Renombrar todas las listas [%1] a:",
    "RENAME_LIST_MODAL_TITLE": "Renombrar lista",
    "DEFAULT_LIST_ITEM": "cosa",
    "DELETE_LIST": "Eliminar la lista [%1]",
    "RENAME_LIST": "Renombrar lista",
    "NEW_BROADCAST_MESSAGE": "Nuevo mensaje",
    "NEW_BROADCAST_MESSAGE_TITLE": "Nombre para el nuevo mensaje:",
    "BROADCAST_MODAL_TITLE": "Nuevo Mensaje",
    "DEFAULT_BROADCAST_MESSAGE_NAME": "mensaje1",
    "DELETE_PROCEDURE": "Para borrar el bloque, elimina todos los usos relacionados con él",
    "OK": "Ok",
    "CANCEL": "Cancel",
    "PROMPT": "Prompt",
    "STOP": "Parar",
    "NEW_STRING": "Crear Variable de Cadena",
    "NEW_NUMBER_VARIABLE": "Crear Variable de Número",
    "WIFI": "Wi-Fi",
    "USERNAME": "user",
    "PWD": "password",
    "IOT_SERVICE": "IOT Service",
    "IOT_ID": "iot id",
    "IOT_PWD": "iot pwd",
    "IOT_TOPIC": "Topic_0",
    "MORE_TOPIC": "Topic",
    "OBLOQ_HTTP": "HTTP",
    "OBLOQ_IP": "IP Address",
    "OBLOQ_PORT": "Port",
    "ADD_TOPIC": "Añadir tema, 4 como máximo",
    "DELETE_TOPIC": "Borrar Topoc",
    "IOT_SERVER": "Servidor",
    "CHINA": "China",
    "GLOBAL": "Global",
    "LOW_C": "Low C/C3",
    "LOW_C$": "Low C#/C#3",
    "LOW_D": "Low D/D3",
    "LOW_D$": "Low D#/D#3",
    "LOW_E": "Low E/E3",
    "LOW_F": "Low F/F3",
    "LOW_F$": "Low F#/F#3",
    "LOW_G": "Low G/G3",
    "LOW_G$": "Low G#/G#3",
    "LOW_A": "Low A/A3",
    "LOW_A$": "Low A#/A#3",
    "LOW_B": "Low B/B3",
    "MIDDLE_C": "Middle C/C4",
    "MIDDLE_C$": "Middle C#/C#4",
    "MIDDLE_D": "Middle D/D4",
    "MIDDLE_D$": "Middle D#/D#4",
    "MIDDLE_E": "Middle E/E4",
    "MIDDLE_F": "Middle F/F4",
    "MIDDLE_F$": "Middle F#/F#4",
    "MIDDLE_G": "Middle G/G4",
    "MIDDLE_G$": "Middle G#/G#4",
    "MIDDLE_A": "Middle A/A4",
    "MIDDLE_A$": "Middle A#/A#4",
    "MIDDLE_B": "Middle B/B4",
    "HIGH_C": "High C/C5",
    "HIGH_C$": "High C#/C#5",
    "HIGH_D": "High D/D5",
    "HIGH_D$": "High D#/D#5",
    "HIGH_E": "High E/E5",
    "HIGH_F": "High F/F5",
    "HIGH_F$": "High F#/F#5",
    "HIGH_G": "High G/G5",
    "HIGH_G$": "High G#/G#5",
    "HIGH_A": "High A/A5",
    "HIGH_A$": "High A#/A#5",
    "HIGH_B": "High B/B5",
    "OPERATORS_STRINGTONUMBER": "Convertir Cadena %1 a %2",
    "INTEGER": "Integer",
    "DECIMAL": "Decimal",
    "OPERATORS_NUMTOASCII": "Convertir Número %1 a ASCII",
    "OPERATORS_STRINGTOASCII": "Convertir Cadena %1 a ASCII",
    "OPERATORS_NUMTOSTRING": "Convertir Número %1 a Cadena",
    "OPERATORS_MAP": "Mapear %1 de [ %2 , %3 ] a [ %4 , %5 ]",
    "OPERATORS_CONSTRAIN": "Limitar %1 entre %2 (mínimo) y %3 (máximo)",
    "STEPS_PER_TURN": "Paso por Turno",
    "ROTATE_SPEED": "Velocidad de Rotación",
    "NEW_AI": "Crear un Bloque AI",
    "ASK_AND_PAINT": "Escribir un Número",
    "GET_IDENTIFIED_NUM": "Número Reconocido",
    "READ_NUM_AND_SAY": "Decir el Número Reconocido",
    "IMAGE_ADDR": "Dirección de Imagen",
    "IMAGE_PREVIEW": "Vista Previa de Imagen",
    "IMAGE_OPEN": "open",
    "IMAGE_SIZE": "Tamaño de Imagen",
    "IMAGE_WIDTH": "Ancho",
    "IMAGE_HEIGHT": "Alto",
    "VARIABLE": "%1",
    "VARIABLE_LIST": "%1",
    "SET_STRING_VARIABLE": "Establecer %1 como %2",
    "STRING_START_WITH": "%1 empieza con %2",
    "OPERATORS_RANDOMA": "%1 %2 coge un número al alzar entre %3 y %4 %5",
    "OPERATORS_MODA": "El residuo de %1 %2 %3 dividido por %4",
    "OPERATORS_ROUNDA": "Redondear %1 %2 a %3",
    "OPERATORS_MATHOPA": "%1 %2 %3 %4",
    "OPERATORS_MATHOP_ABSA": "Valor Absoluto",
    "OPERATORS_MATHOP_FLOORA": "Calcula el valor integral más pequeño que no sea menor de x",
    "OPERATORS_MATHOP_CEILINGA": "Calcula el valor integral más grande que no sea mayor de x.",
    "OPERATORS_MATHOP_SQRTA": "Cuadrado",
    "OPERATORS_MATHOP_FIVE": "%1 %2 aumenta el variable %3 por %4",
    "OPERATORS_MATHOP_SIX": "%1 %2 mapea %3 de [ %4 , %5 ] a [ %6 , %7 ]",
    "OPERATORS_MATHOP_SEVEN": "%1 %2 limita %3 entre %4 (mínimo) y %5 (máximo)",
    "OPERATORS_NUM_INPUT": "%1 %2 number %3",
    "OPERATORS_NUMS_CACULATE": "%1 %2 %3",
    "OPERATORS_MATHOP_SQUARE": "**",
    "OPERATORS_MATHOP_LOGIC_OR": "|",
    "OPERATORS_MATHOP_LOGIC_AND": "&",
    "OPERATORS_MATHOP_LOGIC_POWER": "^",
    "OPERATORS_MATHOP_LOGIC_LEFT": "<<",
    "OPERATORS_MATHOP_LOGIC_RIGHT": ">>",
    "OPERATORS_SPECIAL_NUM": "%1 %2 %3",
    "OPERATORS_MATHOP_PI": "π",
    "OPERATORS_MATHOP_LOGARITHM": "e",
    "OPERATORS_MATHOP_FAI": "φ",
    "OPERATORS_MATHOP_SQRT2": "sqrt(2)",
    "OPERATORS_MATHOP_SQRTHALF": "sqrt(½)",
    "OPERATORS_MATHOP_UNLIMITED": "∞",
    "OPERATORS_NUMS_TYPE": "%1 %2 %3 %4?",
    "OPERATORS_MATHOP_ISEVEN": "is even",
    "OPERATORS_MATHOP_ISODD": "is odd",
    "OPERATORS_MATHOP_ISINT": "is int",
    "OPERATORS_MATHOP_ISPOSITIVE": "is positive",
    "OPERATORS_MATHOP_ISNEGATIVE": "is negative",
    "OPERATORS_SAVE2DECIMAL": "%1 %2 %3 save %4 decimal places",
    "OPERATORS_LIST_CACULATE": "%1 %2 %3 %4",
    "OPERATORS_MATHOP_LIST_SUM": "sum of the numbers in the list",
    "OPERATORS_MATHOP_LIST_MIN": "minimum value in list",
    "OPERATORS_MATHOP_LIST_MAX": "maximum value in the list",
    "OPERATORS_MATHOP_LIST_AVERAGE": "average in list",
    "OPERATORS_MATHOP_LIST_NUM": "number of digits in the list",
    "OPERATORS_MATHOP_LIST_MODE": "list mode",
    "OPERATORS_MATHOP_LIST_STDDEVIATION": "standard deviation in the list",
    "OPERATORS_MATHOP_LIST_RANDOM": "random item of list",
    "OPERATORS_QUOTIENT_INT": "%1 %2 %3 ÷ %4 integer part of quotient",
    "OPERATORS_RANDOM_DECIMAL": "%1 %2 random decimal",
    "OPERATORS_BIN_NEGATION": "%1 %2 binary negation %3",
    "OPERATORS_INT_TO_STR": "%1 %2 decimal integer %3 transform into %4 string",
    "OPERATORS_MATHOP_INTTOSTR_BIN": "binary",
    "OPERATORS_MATHOP_INTTOSTR_OCT": "octal",
    "OPERATORS_MATHOP_INTTOSTR_HEX": "hex",
    "OPERATORS_BINSTR_TO_INT": "%1 %2 %3 string %4 transform into decimal integer",
    "OPERATORS_INT_TO_BYTE": "%1 %2 decimal integer %3 transform into %4 byte",
    "OPERATORS_MATHOP_INTTOBYTE_TWO": "2",
    "OPERATORS_MATHOP_INTTOBYTE_FOUR": "4",
    "OPERATORS_INT_TO_ASCII": "%1 %2 int %3 transform into ASCII character",
    "OPERATORS_ASCII_TO_INT": "%1 %2 ASCII character %3 to int",
    "OPERATORS_STR_TO_INT": "%1 %2 string %3 to hex string",
    "OPERATORS_HEXSTR_TO_BIN": "%1 %2 hex string %3 to bin byte",
    "OPERATORS_STR_TO_BASE64": "%1 %2 %3 encodes in base64 format returns a byte object",
    "OPERATORS_BASE64_TO_BYTE": "%1 %2 decode base64 data %3 returns a byte object",
    "OPERATORS_MATHOP_LENGHT": "Longitud %1",
    "OPERATORS_MATHOP_MAXMIN": "%1 %2",
    "OPERATORS_MATHOP_MAXMIN_MAX": "Valor Máximo",
    "OPERATORS_MATHOP_MAXMIN_MIN": "Valor Mínimo",
    "OPERATORS_TEXTS_ONE": "%1 %2 fusionar %3 %4",
    "OPERATORS_TEXTS_TWO": "%1 %2 coge el número %4 del carácter %3",
    "OPERATORS_TEXTS_THREE": "¿%1 %2 %3 contiene %4?",
    "OPERATORS_TEXTS_FOUR": "%1 %2 %3 coge substring de la letra número %4 %5 a %6 %7",
    "OPERATORS_TEXTS_FOUR_NEW": "%1 gets %2 %3 characters to %4 %5characters",
    "OPERATORS_TEXTS_NEW": "%1 %2 find %3 in %4  %5 Emerging position",
    "OPERATORS_TEXTS_NEW_OP": "find where %1 appears in %2 %3",
    "OPERATORS_TEXTS_FOUR_ONE": "Primero",
    "OPERATORS_TEXTS_FOUR_TWO": "Último",
    "OPERATORS_TEXTS_FOUR_THREE": "Primero",
    "OPERATORS_TEXTS_FOUR_FOUR": "Último",
    "OPERATORS_TEXTS_FOUR_F": "first",
    "OPERATORS_TEXTS_FOUR_L": "last",
    "OPERATORS_INPUTSTRING": "%1 %2 string %3",
    "OPERATORS_TRANSINTOTEXT": "%1 %2 transform into %3",
    "OPERATORS_TRANSINTOTEXT_ADD": "add",
    "OPERATORS_TRANSINTOTEXT_ADD_ITEM": "item",
    "OPERATORS_FORMATSTRING2F": "%1 %2 format string %3 % %4",
    "OPERATORS_FORMATSTRING3": "%1 %2 format string %3 format %4",
    "OPERATORS_TEXT_ESCAPE": "%1 %2 text escape character %3",
    "BAR_N": "\\n",
    "BAR_NN": "\\n\\r",
    "BAR_R": "\\r",
    "BAR_RN": "\\r\\n",
    "BAR_B": "\\b",
    "BAR_T": "\\t",
    "BAR_BAR": "\\\\",
    "OPERATORS_TEXT_NUMORLETTER": "%1 %2 %3 %4?",
    "OPERATORS_TEXT_NUMORLETTER_NUM": "is number",
    "OPERATORS_TEXT_NUMORLETTER_LETTER": "is letter",
    "OPERATORS_TEXT_LENGTH": "%1 %2 %3 length",
    "OPERATORS_TEXT_ISNONE": "%1 %2 %3 is none?",
    "OPERATORS_FROMTEXT_GETCHAR": "%1 %2 in text %3 %4",
    "OPERATORS_FROMTEXT_GETCHAR_FIRST": "first letter",
    "OPERATORS_FROMTEXT_GETCHAR_LAST": "last letter",
    "OPERATORS_FROMTEXT_GETCHAR_RANDOM": "random letter",
    "OPERATORS_TEXT_UPPLOW": "%1 %2 %3 %4",
    "OPERATORS_TEXT_UPPLOW_UPP": "uppercase",
    "OPERATORS_TEXT_UPPLOW_LOW": "lowercase",
    "OPERATORS_TEXT_SIDES_SPACE": "%1 %2 %3 %4",
    "OPERATORS_TEXT_SIDES_SPACE_BOTH": "clear spaces on both sides",
    "OPERATORS_TEXT_SIDES_SPACE_LEFT": "clear left space",
    "OPERATORS_TEXT_SIDES_SPACE_RIGHT": "clear right space",
    "OPERATORS_TEXT_STRTOBYTE": "%1 %2 turn byte %3",
    "OPERATORS_TEXT_BYTETOSTR": "%1 %2 byte %3 convert to string",
    "OPERATORS_TEXT_DICTTOJSONSTR": "%1 %2 dict %3 transform into json string",
    "OPERATORS_TEXT_PARSEJSONSTR": "%1 %2 parse json string %3 and return object",
    "OPERATORS_STRINGTONUMBERA": "%1 %2 Convertir Cadena %3 a %4",
    "OPERATORS_NUMTOASCIIA": "%1 %2 Convertir Número %3 a ASCII",
    "OPERATORS_STRINGTOASCIIA": "%1 %2 Convertir Carácter %3 a ASCII",
    "OPERATORS_NUMTOSTRINGA": "%1 %2 Convertir Número %3 a Cadena",
    "NEW_MC": "Crear Variable Micropyton",
    "RENAME_MCNUMBER_TITLE": "Renombrar todos los variables con el nombre [%1] a",
    "RENAME_MCNUMBER_MODAL_TITLE": "Renombrar Variable",
    "RENAME_VARIABLE_MCNUMBER": "Renombrar Variable",
    "DELETE_VARIABLE_MCNUMBER": "Borrar Variable [%1]",
    "SET_MC_VARIABLE": "Establecer %1 como %2",
    "ALIYUN": "Aliyun",
    "ONENET": "OneNet",
    "ONENETNEW": "OneNet(New)",
    "EASYIOT": "Easy IoT",
    "SHANGHAI": "shanghai",
    "QINGDAO": "qingdao",
    "BEIJING": "beijing",
    "ZHANGJIAKOU": "zhangjiakou",
    "HUHEHAOTE": "huhehaote",
    "HANGZHOU": "hangzhou",
    "SHENZHEN": "shenzhen",
    "HONGKONG": "HongKong",
    "SINGAPORE": "singapore",
    "SYDNEY": "Sydney",
    "KUALALUMPUR": "Luala Lumpur",
    "JAKARTA": "Jakarta",
    "MUMBAI": "Mumbai",
    "TOKYO": "Tokyo",
    "SILICONVALLEY": "Silicon Valley",
    "VIRGINIA": "Virginia",
    "FRANKFURT": "Frankfurt",
    "LONDON": "London",
    "DUBAI": "Dubai",
    "IOT_PLATFORM": "Iot Platform",
    "PARAMS": "Params",
    "SERVER_ATTR": "Server Addr",
    "PRODUCTID": "ProductId",
    "DEVICEID": "DeviceId",
    "DEVICENAME": "DeviceName",
    "TINYDB_SECRET": "secret",
    "TINYDB_USER": "user",
    "APIADDR": "API Addr",
    "SOFTWARE_SERIAL": "Software Serial",
    "HARDWARE_SERIAL": "Hardware Serial",
    "HARDWARE_SERIAL1": "Hardware Serial1",
    "HARDWARE_SERIAL2": "Hardware Serial2",
    "HARDWARE_SERIAL3": "Hardware Serial3",
    "CHECKTYPE_TIPS": "The data type received by the input box does not match the output type of the building block",
    "HIGHLIGHT_BLOCK": "hight light block",
    "HIGHLIGHT_ALL_CONFLICT_BLOCKS": "highlight all blocks with warnings",
    "SNAPSHOT": "Snapshot (CTRL+G)",
    "pictureaiPath": "please enter the picture path or URL",
    "pictureaiType": "account type",
    "pictureaiAccountDefault": "default account",
    "pictureaiAccountBaidu": "baidu account",
    "pictureaiwebImgAddr": "web image address",
    "MITRIXICON_EDIT": "light up",
    "MITRIXICON_ERASER": "eraser",
    "MITRIXICON_DUSTBIN": "clear",
    "MITRIXICON_REVERSE": "reverse",
    "MITRIXICON_SAVE": "save",
    "MITRIXICON_EXPORT": "export",
    "MITRIXICON_UPLOAD": "upload",
    "ROLL_MOUSE": "Rolling mouse",
    "MOUSE_ZOOM_IN_OUT": "Scroll the mouse to zoom in and out",
    "UPLOAD_CORRECT_JSON_FILE": "Please upload the json file in the correct format",
    "EXPORT_PROMPT": "There are no custom patterns to export. Please click the save button in the lower right corner to save the custom pattern before attempting to export.",
    "HEART": "Heart",
    "HEART_SMALL": "Small heart",
    "HAPPY": "Happy",
    "SMILE": "Smile",
    "SAD": "Sad",
    "CONFUSED": "Confused",
    "ANGRY": "Angry",
    "SLEEP": "Sleep",
    "SURPRISED": "Surprised",
    "SILLY": "Silly",
    "WONDERFUL": "Wonderful",
    "BORED": "Bored",
    "ROCK": "Rock",
    "ROCK_SMALL": "Small rock",
    "PAPER": "Paper",
    "PAPER_SMALL": "Small paper",
    "SCISSORS": "scissors",
    "SCISSORS_SMALL": "Small scissors",
    "DATA_SOLITLIST": "%1 %2 make %3 %4 with delimiter %5",
    "DATA_SOLITLIST_TYPE_LT": "list from text5",
    "DATA_SOLITLIST_TYPE_TL": "text from list",
    "DATA_LENLIST": "%1 %2 %3 is empty?",
    "DATA_LENGTHLIST": "%1 %2 length of %3",
    "DATA_CREATELIST": "%1 %2 create list %3",
    "DATA_CLEARLIST": "%1 %2 clear list %3"
  },
  "eu": {
    "CONTROL_FOREVER": "etengabe",
    "CONTROL_REPEAT": "errepikatu %1 aldiz",
    "CONTROL_IF": "baldin %1 orduan",
    "CONTROL_ELSE": "bestela",
    "CONTROL_STOP": "gelditu",
    "CONTROL_STOP_ALL": "dena",
    "CONTROL_STOP_THIS": "script hau",
    "CONTROL_STOP_OTHER": "pertsonaiaren beste script batzuk",
    "CONTROL_WAIT": "itxaron %1 segundo",
    "CONTROL_WAITUNTIL": "itxaron %1 arte ",
    "CONTROL_REPEATUNTIL": "errepikatu %1 arte",
    "CONTROL_WHILE": "%1 den bitartean",
    "CONTROL_FOREACH": "%2 ko %1 bakoitzeko ",
    "CONTROL_STARTASCLONE": "klon moduan hasten naizenean",
    "CONTROL_CREATECLONEOF": "sortu %1 ren klona",
    "CONTROL_CREATECLONEOF_MYSELF": "nire burua",
    "CONTROL_DELETETHISCLONE": "ezabatu klon hau ",
    "CONTROL_COUNTER": "kontagailua",
    "CONTROL_INCRCOUNTER": "gehitu kontagailuan",
    "CONTROL_CLEARCOUNTER": "berrabiarazi kontagailua ",
    "CONTROL_ALLATONCE": "denak batera",
    "DATA_SETVARIABLETO": "ezarri %1: %2 ",
    "DATA_CHANGEVARIABLEBY": "aldatu %1 %2 unitate",
    "DATA_SHOWVARIABLE": "erakutsi %1 aldagaia ",
    "DATA_HIDEVARIABLE": "ezkutatu %1 aldagaia",
    "DATA_ADDTOLIST": "batu %1 %2 ri",
    "DATA_DELETEOFLIST": "ezabatu %1 %2 tik",
    "DATA_DELETEALLOFLIST": "ezabatu %1-ko guztia",
    "DATA_INSERTATLIST": "txertatu %1  %3 -ren %2 -an",
    "DATA_REPLACEITEMOFLIST": "aldatu %2 -ko %1 elementua %3 -rekin ",
    "DATA_ITEMOFLIST": "%2 tik %1. elementua",
    "DATA_ITEMNUMOFLIST": "%1 tik #. elementua %2 n",
    "DATA_LENGTHOFLIST": "%1 ren luzera",
    "DATA_LISTCONTAINSITEM": "%1 k bere baitan al du %2?",
    "DATA_SHOWLIST": "erakutsi %1 zerrenda",
    "DATA_HIDELIST": "ezkutatu %1 zerrenda",
    "DATA_INDEX_ALL": "dena",
    "DATA_INDEX_LAST": "azkena",
    "DATA_INDEX_RANDOM": "ausazkoa",
    "EVENT_WHENFLAGCLICKED": "%1 klik egitean",
    "EVENT_WHENTHISSPRITECLICKED": "pertsonai honetan klik egitean",
    "EVENT_WHENSTAGECLICKED": "Eszenan klik egitean",
    "EVENT_WHENTOUCHINGOBJECT": "pertsonai honek %1 ukitzen duenean",
    "EVENT_WHENBROADCASTRECEIVED": "%1 jasotzean",
    "EVENT_WHENBACKDROPSWITCHESTO": "atzeko oihala %1 ra aldatzen denean",
    "EVENT_WHENGREATERTHAN": "%1  > %2 bada",
    "EVENT_WHENGREATERTHAN_TIMER": "kronometroa",
    "EVENT_WHENGREATERTHAN_LOUDNESS": "ozentasuna",
    "EVENT_BROADCAST": "bidali %1 guztiei",
    "EVENT_BROADCASTANDWAIT": "bidali %1 guztiei eta itxaron",
    "EVENT_WHENKEYPRESSED": "%1 tekla sakatzean",
    "EVENT_WHENKEYPRESSED_SPACE": "zuriunea",
    "EVENT_WHENKEYPRESSED_LEFT": "ezkerrera gezia",
    "EVENT_WHENKEYPRESSED_RIGHT": "eskuinera gezia",
    "EVENT_WHENKEYPRESSED_DOWN": "behera gezia",
    "EVENT_WHENKEYPRESSED_UP": "gora gezia",
    "EVENT_WHENKEYPRESSED_ANY": "edozein",
    "LOOKS_SAYFORSECS": "esan %1 %2 segundoz",
    "LOOKS_SAY": "esan %1",
    "LOOKS_HELLO": "Kaixo!",
    "LOOKS_THINKFORSECS": "pentsatu %1  %2 segundoz",
    "LOOKS_THINK": "pentsatu %1",
    "LOOKS_HMM": "Mmm...",
    "LOOKS_SHOW": "erakutsi",
    "LOOKS_HIDE": "ezkutatu",
    "LOOKS_HIDEALLSPRITES": "ezkutatu pertsonai guztiak ",
    "LOOKS_EFFECT_COLOR": "kolorea",
    "LOOKS_EFFECT_FISHEYE": "arrain-begia",
    "LOOKS_EFFECT_WHIRL": "zurrunbiloa",
    "LOOKS_EFFECT_PIXELATE": "pixelatu",
    "LOOKS_EFFECT_MOSAIC": "mosaikoa",
    "LOOKS_EFFECT_BRIGHTNESS": "distira",
    "LOOKS_EFFECT_GHOST": "mamua",
    "LOOKS_CHANGEEFFECTBY": "aldatu %1 efektua %2 unitate",
    "LOOKS_SETEFFECTTO": "ezarri %1 efektua %2 ri ",
    "LOOKS_CLEARGRAPHICEFFECTS": "kendu efektu grafikoak ",
    "LOOKS_CHANGESIZEBY": "aldatu tamaina %1 unitate",
    "LOOKS_SETSIZETO": "ezarri tamaina: % %1",
    "LOOKS_SIZE": "tamaina",
    "LOOKS_CHANGESTRETCHBY": "aldatu luzaketa %1 unitate",
    "LOOKS_SETSTRETCHTO": "ezarri luzera: % %1",
    "LOOKS_SWITCHCOSTUMETO": "aldatu tankera %1 ra ",
    "LOOKS_NEXTCOSTUME": "hurrengo tankera",
    "LOOKS_SWITCHBACKDROPTO": "aldatu atzeko oihala %1 ra ",
    "LOOKS_GOTOFRONTBACK": "joan %1 geruzara ",
    "LOOKS_GOTOFRONTBACK_FRONT": "aurrealdeko",
    "LOOKS_GOTOFRONTBACK_BACK": "atzealdeko",
    "LOOKS_GOFORWARDBACKWARDLAYERS": "joan %1 geruza %2",
    "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "aurrealdera",
    "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "atzealdera",
    "LOOKS_BACKDROPNUMBERNAME": "%1 atzeko oihala",
    "LOOKS_COSTUMENUMBERNAME": "%1 tankera",
    "LOOKS_NUMBERNAME_NUMBER": "zenbakia",
    "LOOKS_NUMBERNAME_NAME": "izena",
    "LOOKS_SWITCHBACKDROPTOANDWAIT": "aldatu atzeko oihala %1 ra eta itxaron",
    "LOOKS_NEXTBACKDROP_BLOCK": "hurrengo atzeko oihala",
    "LOOKS_NEXTBACKDROP": "hurrengo atzeko oihala",
    "LOOKS_PREVIOUSBACKDROP": "aurreko atzeko oihala",
    "LOOKS_RANDOMBACKDROP": "ausazko atzeko oihala",
    "MOTION_MOVESTEPS": "mugitu %1 pausu ",
    "MOTION_TURNLEFT": "biratu %1 %2 gradu",
    "MOTION_TURNRIGHT": "biratu %1 %2 gradu",
    "MOTION_POINTINDIRECTION": "apuntatu norabidea: %1",
    "MOTION_POINTTOWARDS": "jarri hona begira: %1",
    "MOTION_POINTTOWARDS_POINTER": "saguaren erakuslea",
    "MOTION_POINTTOWARDS_RANDOM": "ausazko norabidea",
    "MOTION_GOTO": "joan hona %1",
    "MOTION_GOTO_POINTER": "saguaren erakuslea",
    "MOTION_GOTO_RANDOM": "ausazko kokapena",
    "MOTION_GOTOXY": "joan x: %1 y: %2 kokapenera ",
    "MOTION_GLIDESECSTOXY": "Irristatu %1 segundotan hona x: %2 y: %3",
    "MOTION_GLIDETO": "Irristatu %1 segundotan hona: %2",
    "MOTION_GLIDETO_POINTER": "saguaren erakuslea",
    "MOTION_GLIDETO_RANDOM": "ausazko kokapena",
    "MOTION_CHANGEXBY": "aldatu x %1 unitate",
    "MOTION_SETX": "ezarri x: %1",
    "MOTION_CHANGEYBY": "aldatu y %1 unitate",
    "MOTION_SETY": "ezarri y: %1",
    "MOTION_IFONEDGEBOUNCE": "errebotatu ertza ukitzean ",
    "MOTION_SETROTATIONSTYLE": "ezarri biraketa modua: %1 ",
    "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "ezker-eskuin",
    "MOTION_SETROTATIONSTYLE_DONTROTATE": "ez biratu",
    "MOTION_SETROTATIONSTYLE_ALLAROUND": "norabide guztietara",
    "MOTION_XPOSITION": "x kokapena",
    "MOTION_YPOSITION": "y kokapena",
    "MOTION_DIRECTION": "norabidea",
    "MOTION_SCROLLRIGHT": "korritu eskuinera %1",
    "MOTION_SCROLLUP": "korritu gora %1",
    "MOTION_ALIGNSCENE": "lerrokatu %1 eszena",
    "MOTION_ALIGNSCENE_BOTTOMLEFT": "behean ezkerrean",
    "MOTION_ALIGNSCENE_BOTTOMRIGHT": "behean eskuinean",
    "MOTION_ALIGNSCENE_MIDDLE": "erdian",
    "MOTION_ALIGNSCENE_TOPLEFT": "goian ezkerrean",
    "MOTION_ALIGNSCENE_TOPRIGHT": "goian eskuinean",
    "MOTION_XSCROLL": "korritu x",
    "MOTION_YSCROLL": "korritu y",
    "MOTION_STAGE_SELECTED": "Hautatutako eszena: ez dago mugimendurako blokerik",
    "OPERATORS_ADD": "%1 + %2",
    "OPERATORS_SUBTRACT": "%1 - %2",
    "OPERATORS_MULTIPLY": "%1 * %2",
    "OPERATORS_DIVIDE": "%1 / %2",
    "OPERATORS_RANDOM": "%1 eta %2 arteko ausazko balioa",
    "OPERATORS_GT": "%1 > %2",
    "OPERATORS_GT_EQUALS": "",
    "OPERATORS_LT": "%1 < %2",
    "OPERATORS_LT_EQUALS": "",
    "OPERATORS_EQUALS": "%1 = %2",
    "OPERATORS_AND": "%1 eta %2",
    "OPERATORS_OR": "%1 edo %2",
    "OPERATORS_NOT": "%1 ez",
    "OPERATORS_JOIN": "elkartu %1 %2 rekin",
    "OPERATORS_JOIN_APPLE": "sagarra",
    "OPERATORS_JOIN_BANANA": "banana",
    "OPERATORS_LETTEROF": "%1. letra %2 tik",
    "OPERATORS_LETTEROF_APPLE": "a",
    "OPERATORS_LENGTH": "%1 ren luzera",
    "OPERATORS_CONTAINS": "%1(e)k bere baitan al du %2?",
    "OPERATORS_MOD": "%1 modulu %2",
    "OPERATORS_ROUND": "biribildu %1",
    "OPERATORS_MATHOP": " %2 tik %1",
    "OPERATORS_MATHOP_ABS": "balio absolutu",
    "OPERATORS_MATHOP_FLOOR": "zoru-funtzioa",
    "OPERATORS_MATHOP_CEILING": "sabai-funtzioa",
    "OPERATORS_MATHOP_SQRT": "erro karratua",
    "OPERATORS_MATHOP_SIN": "sin",
    "OPERATORS_MATHOP_COS": "cos",
    "OPERATORS_MATHOP_TAN": "tan",
    "OPERATORS_MATHOP_ASIN": "asin",
    "OPERATORS_MATHOP_ACOS": "acos",
    "OPERATORS_MATHOP_ATAN": "atan",
    "OPERATORS_MATHOP_LN": "ln",
    "OPERATORS_MATHOP_LOG": "log",
    "OPERATORS_MATHOP_EEXP": "e ^",
    "OPERATORS_MATHOP_10EXP": "10 ^",
    "PROCEDURES_DEFINITION": "definitu %1",
    "SENSING_TOUCHINGOBJECT": "%1 ukitzen?",
    "SENSING_TOUCHINGOBJECT_POINTER": "saguaren erakuslea",
    "SENSING_TOUCHINGOBJECT_EDGE": "ertza",
    "SENSING_TOUCHINGCOLOR": "%1 kolorea ukitzen?",
    "SENSING_COLORISTOUCHINGCOLOR": "%1 koloreak %2 ukitzen du?",
    "SENSING_DISTANCETO": "%1 rako distantzia",
    "SENSING_DISTANCETO_POINTER": "saguaren erakuslea",
    "SENSING_ASKANDWAIT": "galdetu %1 eta itxaron",
    "SENSING_ASK_TEXT": "Nola duzu izena?",
    "SENSING_ANSWER": "erantzuna",
    "SENSING_KEYPRESSED": "%1 tekla sakatuta?",
    "SENSING_MOUSEDOWN": "sagua sakatuta?",
    "SENSING_MOUSEX": "saguaren x",
    "SENSING_MOUSEY": "saguaren y",
    "SENSING_SETDRAGMODE": "ezarri arrastea %1 moduan",
    "SENSING_SETDRAGMODE_DRAGGABLE": "arrastagarria",
    "SENSING_SETDRAGMODE_NOTDRAGGABLE": "arrastaezina",
    "SENSING_LOUDNESS": "ozentasuna",
    "SENSING_LOUD": "ozen?",
    "SENSING_TIMER": "kronometroa",
    "SENSING_RESETTIMER": "berrabiarazi kronometroa",
    "SENSING_OF": "%2 tik %1",
    "SENSING_OF_XPOSITION": "x kokapena",
    "SENSING_OF_YPOSITION": "y kokapena",
    "SENSING_OF_DIRECTION": "norabidea",
    "SENSING_OF_COSTUMENUMBER": "# tankera",
    "SENSING_OF_COSTUMENAME": "tankeraren izena",
    "SENSING_OF_SIZE": "tamaina",
    "SENSING_OF_VOLUME": "bolumena",
    "SENSING_OF_BACKDROPNUMBER": "# atzeko oihala",
    "SENSING_OF_BACKDROPNAME": "atzeko oihalaren izena",
    "SENSING_OF_STAGE": "Eszena",
    "SENSING_CURRENT": "oraingo %1",
    "SENSING_CURRENT_YEAR": "urtea",
    "SENSING_CURRENT_MONTH": "hilabetea",
    "SENSING_CURRENT_DATE": "data",
    "SENSING_CURRENT_DAYOFWEEK": "asteko eguna",
    "SENSING_CURRENT_HOUR": "ordua",
    "SENSING_CURRENT_MINUTE": "minutua",
    "SENSING_CURRENT_SECOND": "segundoa",
    "SENSING_DAYSSINCE2000": "2000tik geroztiko egunak",
    "SENSING_USERNAME": "erabiltzaile-izena",
    "SENSING_USERID": "erabiltzaile-id",
    "SOUND_PLAY": "hasi %1 soinua",
    "SOUND_PLAYUNTILDONE": "jo %1 soinua amaitu arte",
    "SOUND_STOPALLSOUNDS": "gelditu soinu guztiak",
    "SOUND_SETEFFECTO": "ezarri %1 efektua: %2",
    "SOUND_CHANGEEFFECTBY": "aldatu %1 efektua %2 unitate",
    "SOUND_CLEAREFFECTS": "kendu soinu-efektuak",
    "SOUND_EFFECTS_PITCH": "tonua",
    "SOUND_EFFECTS_PAN": "ezker/eskuin balantzea",
    "SOUND_CHANGEVOLUMEBY": "aldatu bolumena %1 unitate",
    "SOUND_SETVOLUMETO": "ezarri bolumena: %1%",
    "SOUND_VOLUME": "bolumena",
    "SOUND_RECORD": "grabatu...",
    "CATEGORY_MOTION": "Mugimendua",
    "CATEGORY_LOOKS": "Itxura",
    "CATEGORY_SOUND": "Soinua",
    "CATEGORY_EVENTS": "Gertaerak",
    "CATEGORY_CONTROL": "Kontrola",
    "CATEGORY_SENSING": "Sentsoreak",
    "CATEGORY_OPERATORS": "Eragileak",
    "CATEGORY_VARIABLES": "Aldagaiak",
    "CATEGORY_MYBLOCKS": "Nire blokeak",
    "CATEGORY_LISTS": "",
    "CATEGORY_TUPLE": "",
    "CATEGORY_DICTIONARYS": "",
    "CATEGORY_NUMBERS": "",
    "CATEGORY_TEXT": "",
    "DUPLICATE": "Bikoiztu",
    "DELETE": "Ezabatu",
    "ADD_COMMENT": "Gehitu iruzkina",
    "REMOVE_COMMENT": "Kendu iruzkina",
    "DELETE_BLOCK": "Ezabatu blokea",
    "DELETE_X_BLOCKS": "Ezabatu %1 bloke",
    "DELETE_ALL_BLOCKS": "Ezabatu %1 blokeak?",
    "CLEAN_UP": "Kendu blokeak",
    "HELP": "Laguntza",
    "UNDO": "Desegin",
    "REDO": "Berregin",
    "EDIT_PROCEDURE": "Editatu",
    "SHOW_PROCEDURE_DEFINITION": "Joan definiziora",
    "WORKSPACE_COMMENT_DEFAULT_TEXT": "Esan zerbait...",
    "COLOUR_HUE_LABEL": "Kolorea",
    "COLOUR_SATURATION_LABEL": "Asetasuna",
    "COLOUR_BRIGHTNESS_LABEL": "Distira",
    "CHANGE_VALUE_TITLE": "Aldatu balioa:",
    "RENAME_VARIABLE": "Aldatu izena aldagaiari",
    "RENAME_VARIABLE_STRING": "",
    "RENAME_VARIABLE_NUMBER": "",
    "RENAME_VARIABLE_LIST": "",
    "RENAME_VARIABLE_TITLE": "Aldatu izenak \"%1\" aldagaiei honela:",
    "RENAME_VARIABLE_MODAL_TITLE": "Aldatu izena aldagaiari",
    "NEW_VARIABLE": "Sortu aldagai bat",
    "NEW_VARIABLE_TITLE": "Aldagai berriaren izena:",
    "VARIABLE_MODAL_TITLE": "Aldagai berria",
    "VARIABLE_ALREADY_EXISTS": "\"%1\" izeneko aldagaia badago lehendik.",
    "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "\"%2\" motako beste aldagai batek badu lehendik \"%1\" izena.",
    "DELETE_VARIABLE_CONFIRMATION": "Ezabatu \"%2\" aldagaiaren %1 erabilerak?",
    "CANNOT_DELETE_VARIABLE_PROCEDURE": "Ezin da \"%1\" aldagaia ezabatu \"%2\" funtzioaren definizioan parte hartzen duelako.",
    "DELETE_VARIABLE": "Ezabatu \"%1\" aldagaia",
    "DELETE_VARIABLE_STRING": "",
    "DELETE_VARIABLE_NUMBER": "",
    "DELETE_VARIABLE_LIST": "",
    "NEW_PROCEDURE": "Sortu bloke bat",
    "PROCEDURE_ALREADY_EXISTS": "\"%1\" izeneko prozedura badago lehendik.",
    "PROCEDURE_DEFAULT_NAME": "blokearen izena",
    "NEW_LIST": "Sortu zerrenda bat",
    "NEW_LIST_TITLE": "Zerrenda berriaren izena:",
    "LIST_MODAL_TITLE": "Zerrenda berria",
    "LIST_ALREADY_EXISTS": "\"%1\" izeneko zerrenda badago lehendik.",
    "RENAME_LIST_TITLE": "Aldatu izenak \"%1\" zerrendei honela:",
    "RENAME_LIST_MODAL_TITLE": "Aldatu izena zerrendari",
    "DEFAULT_LIST_ITEM": "gauza",
    "DELETE_LIST": "Ezabatu \"%1\" zerrenda",
    "RENAME_LIST": "Aldatu izena zerrendari",
    "NEW_BROADCAST_MESSAGE": "Mezu berria",
    "NEW_BROADCAST_MESSAGE_TITLE": "Mezu berriaren izena:",
    "BROADCAST_MODAL_TITLE": "Mezu berria",
    "DEFAULT_BROADCAST_MESSAGE_NAME": "mezua1",
    "DELETE_PROCEDURE": "",
    "OK": "",
    "PROMPT": "",
    "STOP": "",
    "NEW_STRING": "",
    "NEW_NUMBER_VARIABLE": "",
    "WIFI": "",
    "USERNAME": "",
    "PWD": "",
    "IOT_SERVICE": "",
    "IOT_ID": "",
    "IOT_PWD": "",
    "IOT_TOPIC": "",
    "MORE_TOPIC": "",
    "OBLOQ_HTTP": "",
    "OBLOQ_IP": "",
    "OBLOQ_PORT": "",
    "ADD_TOPIC": "",
    "DELETE_TOPIC": "",
    "IOT_SERVER": "",
    "CHINA": "",
    "GLOBAL": "",
    "LOW_C": "",
    "LOW_C$": "",
    "LOW_D": "",
    "LOW_D$": "",
    "LOW_E": "",
    "LOW_F": "",
    "LOW_F$": "",
    "LOW_G": "",
    "LOW_G$": "",
    "LOW_A": "",
    "LOW_A$": "",
    "LOW_B": "",
    "MIDDLE_C": "",
    "MIDDLE_C$": "",
    "MIDDLE_D": "",
    "MIDDLE_D$": "",
    "MIDDLE_E": "",
    "MIDDLE_F": "",
    "MIDDLE_F$": "",
    "MIDDLE_G": "",
    "MIDDLE_G$": "",
    "MIDDLE_A": "",
    "MIDDLE_A$": "",
    "MIDDLE_B": "",
    "HIGH_C": "",
    "HIGH_C$": "",
    "HIGH_D": "",
    "HIGH_D$": "",
    "HIGH_E": "",
    "HIGH_F": "",
    "HIGH_F$": "",
    "HIGH_G": "",
    "HIGH_G$": "",
    "HIGH_A": "",
    "HIGH_A$": "",
    "HIGH_B": "",
    "OPERATORS_STRINGTONUMBER": "",
    "INTEGER": "",
    "DECIMAL": "",
    "OPERATORS_NUMTOASCII": "",
    "OPERATORS_STRINGTOASCII": "",
    "OPERATORS_NUMTOSTRING": "",
    "OPERATORS_MAP": "",
    "OPERATORS_CONSTRAIN": "",
    "STEPS_PER_TURN": "",
    "ROTATE_SPEED": "",
    "NEW_AI": "",
    "ASK_AND_PAINT": "",
    "GET_IDENTIFIED_NUM": "",
    "READ_NUM_AND_SAY": "",
    "IMAGE_ADDR": "",
    "IMAGE_PREVIEW": "",
    "IMAGE_OPEN": "",
    "IMAGE_SIZE": "",
    "IMAGE_WIDTH": "",
    "IMAGE_HEIGHT": "",
    "VARIABLE": "",
    "VARIABLE_LIST": "",
    "SET_STRING_VARIABLE": "",
    "STRING_START_WITH": "",
    "DICTIONARY_HEIGHT_FIRST": "",
    "DICTIONARY_HEIGHT_TWO": "",
    "DICTIONARY_HEIGHT_THREE": "",
    "DICTIONARY_HEIGHT_FOUR": "",
    "DATA_MPITEMOFLIST": "",
    "DATA_MPINTERCEPTITEM": "",
    "DATA_MPINSERTATLIST": "",
    "DATA_MPSETVALUEATLIST": "",
    "DATA_MPINSERTATLIST_INSERT": "",
    "DATA_MPINSERTATLIST_CHANGE": "",
    "DATA_MPINSERTATLIST_FOUR": "",
    "DATA_MPINSERTATLIST_FIVE": "",
    "DATA_MPINSERTATLIST_SIX": "",
    "DATA_MPINSERTATLIST_SEVEN": "",
    "DATA_MPINSERTATLIST_EIGHT": "",
    "DATA_MPINSERTATLIST_EIGHT_FALSE": "",
    "DATA_MPINSERTATLIST_EIGHT_TRUE": "",
    "DATA_MPINSERTATLIST_EIGHT_REVERSE": "",
    "OPERATORS_RANDOMA": "",
    "OPERATORS_MODA": "",
    "OPERATORS_ROUNDA": "",
    "OPERATORS_MATHOPA": "",
    "OPERATORS_MATHOP_ABSA": "",
    "OPERATORS_MATHOP_FLOORA": "",
    "OPERATORS_MATHOP_CEILINGA": "",
    "OPERATORS_MATHOP_SQRTA": "",
    "OPERATORS_MATHOP_FIVE": "",
    "OPERATORS_MATHOP_SIX": "",
    "OPERATORS_MATHOP_SEVEN": "",
    "OPERATORS_MATHOP_LENGHT": "",
    "OPERATORS_MATHOP_MAXMIN": "",
    "OPERATORS_MATHOP_MAXMIN_MAX": "",
    "OPERATORS_MATHOP_MAXMIN_MIN": "",
    "OPERATORS_TEXTS_ONE": "",
    "OPERATORS_TEXTS_TWO": "",
    "OPERATORS_TEXTS_THREE": "",
    "OPERATORS_TEXTS_FOUR": "",
    "OPERATORS_TEXTS_FOUR_NEW": "",
    "OPERATORS_TEXTS_NEW": "",
    "OPERATORS_TEXTS_NEW_OP": "",
    "OPERATORS_TEXTS_FOUR_ONE": "",
    "OPERATORS_TEXTS_FOUR_TWO": "",
    "OPERATORS_TEXTS_FOUR_THREE": "",
    "OPERATORS_TEXTS_FOUR_FOUR": "",
    "OPERATORS_TEXTS_FOUR_F": "",
    "OPERATORS_TEXTS_FOUR_L": "",
    "OPERATORS_STRINGTONUMBERA": "",
    "OPERATORS_NUMTOASCIIA": "",
    "OPERATORS_STRINGTOASCIIA": "",
    "OPERATORS_NUMTOSTRINGA": "",
    "NEW_MC": "",
    "RENAME_MCNUMBER_TITLE": "",
    "RENAME_MCNUMBER_MODAL_TITLE": "",
    "RENAME_VARIABLE_MCNUMBER": "",
    "DELETE_VARIABLE_MCNUMBER": "",
    "SET_MC_VARIABLE": "",
    "TUPLE_ONE": "",
    "TUPLE_TWO": "",
    "ALIYUN": "",
    "ONENET": "",
    "EASYIOT": "",
    "SHANGHAI": "",
    "QINGDAO": "",
    "BEIJING": "",
    "ZHANGJIAKOU": "",
    "HUHEHAOTE": "",
    "HANGZHOU": "",
    "SHENZHEN": "",
    "HONGKONG": "",
    "SINGAPORE": "",
    "SYDNEY": "",
    "KUALALUMPUR": "",
    "JAKARTA": "",
    "MUMBAI": "",
    "TOKYO": "",
    "SILICONVALLEY": "",
    "VIRGINIA": "",
    "FRANKFURT": "",
    "LONDON": "",
    "DUBAI": "",
    "IOT_PLATFORM": "",
    "PARAMS": "",
    "SERVER_ATTR": "",
    "PRODUCTID": "",
    "DEVICEID": "",
    "TINYDB_SECRET": "",
    "TINYDB_USER": "",
    "APIADDR": "",
    "SOFTWARE_SERIAL": "",
    "HARDWARE_SERIAL": "",
    "HARDWARE_SERIAL1": "",
    "HARDWARE_SERIAL2": "",
    "HARDWARE_SERIAL3": ""
  },
  "fa": {
    "CONTROL_FOREVER": "برای همیشه",
    "CONTROL_REPEAT": " %1 بار تکرار کن",
    "CONTROL_IF": "اگر %1 آن گاه",
    "CONTROL_ELSE": "وگر نه",
    "CONTROL_STOP": "توقف",
    "CONTROL_STOP_ALL": "همه",
    "CONTROL_STOP_THIS": "این دستور",
    "CONTROL_STOP_OTHER": "سایر دستور های این شکلک",
    "CONTROL_WAIT": "%1 ثانیه صبر کن",
    "CONTROL_WAITUNTIL": "تا %1 صبر کن",
    "CONTROL_REPEATUNTIL": "تا وقتی که %1 تکرار کن",
    "CONTROL_WHILE": "هنگامی که %1 ",
    "CONTROL_FOREACH": "برای هر %1 در %2",
    "CONTROL_STARTASCLONE": "وقتی که به عنوان یک مشابه شروع کردم",
    "CONTROL_CREATECLONEOF": "از %1 مشابه بساز",
    "CONTROL_CREATECLONEOF_MYSELF": "خودم",
    "CONTROL_DELETETHISCLONE": "این مشابه را حذف کن",
    "CONTROL_COUNTER": "شمارنده",
    "CONTROL_INCRCOUNTER": "افزایش شمارنده",
    "CONTROL_CLEARCOUNTER": "شمارنده را پاک کن",
    "CONTROL_ALLATONCE": "همه در یک بار ",
    "DATA_SETVARIABLETO": "%1 را به %2 تنظیم کن",
    "DATA_CHANGEVARIABLEBY": "مقدار %1 را %2 تا تغییر بده",
    "DATA_SHOWVARIABLE": "متغیر %1 را نشان بده",
    "DATA_HIDEVARIABLE": "متغیر %1 را پنهان کن",
    "DATA_ADDTOLIST": " %1 را به %2 اضافه کن",
    "DATA_DELETEOFLIST": " %1 را از %2 حذف کن ",
    "DATA_DELETEALLOFLIST": "همه %1 ها را حذف کن",
    "DATA_INSERTATLIST": "%1 را در %2 از %3 درج کن",
    "DATA_REPLACEITEMOFLIST": "مورد %1 از %2 را با %3 جایگزین کن",
    "DATA_ITEMOFLIST": "عنصر %1 از %2",
    "DATA_ITEMNUMOFLIST": "مورد # از %1 در %2",
    "DATA_LENGTHOFLIST": "طول %1",
    "DATA_LISTCONTAINSITEM": "آیا %1 شامل %2 است؟",
    "DATA_SHOWLIST": "فهرست %1 را نمایش بده",
    "DATA_HIDELIST": "فهرست %1 را پنهان کن",
    "DATA_INDEX_ALL": "همه",
    "DATA_INDEX_LAST": "آخرین",
    "DATA_INDEX_RANDOM": "تصادفی",
    "EVENT_WHENFLAGCLICKED": "وقتی که %1 کلیک شد",
    "EVENT_WHENTHISSPRITECLICKED": "وقتی که این شکلک کلیک شد",
    "EVENT_WHENSTAGECLICKED": "وقتی که صحنه کلیک شد",
    "EVENT_WHENTOUCHINGOBJECT": "وقتی که این شکلک %1 را لمس کرد",
    "EVENT_WHENBROADCASTRECEIVED": "وقتی که %1 را دریافت کردم",
    "EVENT_WHENBACKDROPSWITCHESTO": "وقتی که پس زمینه به %1 تغییر کرد",
    "EVENT_WHENGREATERTHAN": "وقتی که %1 > %2",
    "EVENT_WHENGREATERTHAN_TIMER": "زمان سنج",
    "EVENT_WHENGREATERTHAN_LOUDNESS": "بلندی صدا",
    "EVENT_BROADCAST": "%1 را منتشر کن",
    "EVENT_BROADCASTANDWAIT": "%1 را منتشر کن و صبر کن",
    "EVENT_WHENKEYPRESSED": "وقتی که کلید %1 فشرده شد",
    "EVENT_WHENKEYPRESSED_SPACE": "فضا",
    "EVENT_WHENKEYPRESSED_LEFT": "جهت چپ",
    "EVENT_WHENKEYPRESSED_RIGHT": "جهت راست",
    "EVENT_WHENKEYPRESSED_DOWN": "جهت پایین",
    "EVENT_WHENKEYPRESSED_UP": "جهت بالا",
    "EVENT_WHENKEYPRESSED_ANY": "هر",
    "LOOKS_SAYFORSECS": "%1 را به مدت %2 ثانیه بگو",
    "LOOKS_SAY": "بگو %1",
    "LOOKS_HELLO": "سلام!",
    "LOOKS_THINKFORSECS": "به %1 به مدت %2 ثانیه فکر کن",
    "LOOKS_THINK": "به %1 فکر کن",
    "LOOKS_HMM": "اوهومم...",
    "LOOKS_SHOW": "نمایش بده",
    "LOOKS_HIDE": "پنهان کن",
    "LOOKS_HIDEALLSPRITES": "همه ی شکلک ها را مخفی کن",
    "LOOKS_EFFECT_COLOR": "رنگ",
    "LOOKS_EFFECT_FISHEYE": "چشم ماهی",
    "LOOKS_EFFECT_WHIRL": "چرخش گردابی",
    "LOOKS_EFFECT_PIXELATE": "پیکسل بندی",
    "LOOKS_EFFECT_MOSAIC": "موزاييک بندی",
    "LOOKS_EFFECT_BRIGHTNESS": "روشنایی",
    "LOOKS_EFFECT_GHOST": "روح",
    "LOOKS_CHANGEEFFECTBY": "جلوه ی %1 را به اندازه ی %2 تغییر بده",
    "LOOKS_SETEFFECTTO": "جلوه ی %1 را به %2 مقدار دهی کن",
    "LOOKS_CLEARGRAPHICEFFECTS": "جلوه های ترسیمی را پاک کن",
    "LOOKS_CHANGESIZEBY": "اندازه را به میزان %1 تغییر بده",
    "LOOKS_SETSIZETO": "اندازه را به %1 % مقدار دهی کن",
    "LOOKS_SIZE": "اندازه",
    "LOOKS_CHANGESTRETCHBY": "میزان کش آمدن را به اندازه ی %1 تغییر بده",
    "LOOKS_SETSTRETCHTO": "میزان کش آمدن را به %1  % تنظیم کن",
    "LOOKS_SWITCHCOSTUMETO": "تعویض حالت به %1",
    "LOOKS_NEXTCOSTUME": "حالت بعدی",
    "LOOKS_SWITCHBACKDROPTO": "تغییر پس زمینه به %1",
    "LOOKS_GOTOFRONTBACK": "به لایه ی %1 برو",
    "LOOKS_GOTOFRONTBACK_FRONT": "جلو",
    "LOOKS_GOTOFRONTBACK_BACK": "عقب",
    "LOOKS_GOFORWARDBACKWARDLAYERS": "%2 لایه به %1 برو",
    "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "به جلو",
    "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "رو به عقب",
    "LOOKS_BACKDROPNUMBERNAME": "پس زمینه ی %1",
    "LOOKS_COSTUMENUMBERNAME": "حالت %1",
    "LOOKS_NUMBERNAME_NUMBER": "شماره",
    "LOOKS_NUMBERNAME_NAME": "نام",
    "LOOKS_SWITCHBACKDROPTOANDWAIT": "پس زمینه را به %1 تغییر بده و صبر کن",
    "LOOKS_NEXTBACKDROP_BLOCK": "پس زمینه ی بعدی",
    "LOOKS_NEXTBACKDROP": "پس زمینه ی بعدی",
    "LOOKS_PREVIOUSBACKDROP": "پس زمینه ی قبلی",
    "LOOKS_RANDOMBACKDROP": "پس زمینه ی اتفاقی",
    "MOTION_MOVESTEPS": "%1 گام حرکت کن",
    "MOTION_TURNLEFT": "%2 درجه %1 بچرخ",
    "MOTION_TURNRIGHT": "%2 درجه %1 بچرخ",
    "MOTION_POINTINDIRECTION": "در جهت %1 قرار بگیر",
    "MOTION_POINTTOWARDS": "به سمت %1 قرار بگیر",
    "MOTION_POINTTOWARDS_POINTER": "اشاره گر ماوس",
    "MOTION_POINTTOWARDS_RANDOM": "جهت تصافی",
    "MOTION_GOTO": "به %1 برو",
    "MOTION_GOTO_POINTER": "اشاره گر ماوس",
    "MOTION_GOTO_RANDOM": "مکان تصادفی",
    "MOTION_GOTOXY": "به x: %1 و y: %2 برو",
    "MOTION_GLIDESECSTOXY": "به x: %2 y: %3 در مدت %1 ثانیه سر بخور",
    "MOTION_GLIDETO": "به %2 در مدت %1 ثانیه سر بخور",
    "MOTION_GLIDETO_POINTER": "اشاره گر ماوس",
    "MOTION_GLIDETO_RANDOM": "مکان تصادفی",
    "MOTION_CHANGEXBY": "x را به اندازه ی %1 تغییر بده",
    "MOTION_SETX": "x را %1 قرار بده",
    "MOTION_CHANGEYBY": "y را به اندازه ی %1 تغییر بده",
    "MOTION_SETY": "y را %1 قرار بده",
    "MOTION_IFONEDGEBOUNCE": "اگر روی لبه قرار گرفت، برگردد",
    "MOTION_SETROTATIONSTYLE": "شیوه ی چرخش را به %1 تنظیم کن",
    "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "چپ - راست",
    "MOTION_SETROTATIONSTYLE_DONTROTATE": "چرخش نکردن",
    "MOTION_SETROTATIONSTYLE_ALLAROUND": "همه طرف",
    "MOTION_XPOSITION": "موقعیت x",
    "MOTION_YPOSITION": "موقعیت y",
    "MOTION_DIRECTION": "جهت",
    "MOTION_SCROLLRIGHT": "%1 به راست پیمایش کن",
    "MOTION_SCROLLUP": "%1 به بالا پیمایش کن",
    "MOTION_ALIGNSCENE": "تراز کردن صحنه %1",
    "MOTION_ALIGNSCENE_BOTTOMLEFT": "پایین - چپ",
    "MOTION_ALIGNSCENE_BOTTOMRIGHT": "پایین - راست",
    "MOTION_ALIGNSCENE_MIDDLE": "وسط",
    "MOTION_ALIGNSCENE_TOPLEFT": "بالا - چپ",
    "MOTION_ALIGNSCENE_TOPRIGHT": "بالا - راست",
    "MOTION_XSCROLL": "پیمایش x",
    "MOTION_YSCROLL": "پیمایش y",
    "MOTION_STAGE_SELECTED": "صحنه ی انتخاب شده: بدون قطعه ی حرکتی",
    "OPERATORS_ADD": "%2 + %1",
    "OPERATORS_SUBTRACT": "%2 - %1",
    "OPERATORS_MULTIPLY": "%2 * %1",
    "OPERATORS_DIVIDE": "%2 / %1",
    "OPERATORS_RANDOM": "انتخاب تصادفی از %1 تا %2",
    "OPERATORS_GT": "%1 > %2",
    "OPERATORS_GT_EQUALS": "",
    "OPERATORS_LT": "%1 < %2",
    "OPERATORS_LT_EQUALS": "",
    "OPERATORS_EQUALS": "%1 = %2",
    "OPERATORS_AND": "%1 و %2",
    "OPERATORS_OR": "%1 یا %2",
    "OPERATORS_NOT": "%1 نباشد",
    "OPERATORS_JOIN": "اتصال %1 و %2",
    "OPERATORS_JOIN_APPLE": "سیب",
    "OPERATORS_JOIN_BANANA": "موز",
    "OPERATORS_LETTEROF": "حرف %1 ام از %2",
    "OPERATORS_LETTEROF_APPLE": "یک",
    "OPERATORS_LENGTH": "طول %1",
    "OPERATORS_CONTAINS": "آیا %1 شامل %2 است؟",
    "OPERATORS_MOD": "باقیمانده ی تقسیم %1 به %2",
    "OPERATORS_ROUND": "%1 را گرد کن",
    "OPERATORS_MATHOP": "%1 از %2",
    "OPERATORS_MATHOP_ABS": "قدر مطلق",
    "OPERATORS_MATHOP_FLOOR": "کف",
    "OPERATORS_MATHOP_CEILING": "سقف",
    "OPERATORS_MATHOP_SQRT": "جذر",
    "OPERATORS_MATHOP_SIN": "sin",
    "OPERATORS_MATHOP_COS": "cos",
    "OPERATORS_MATHOP_TAN": "tan",
    "OPERATORS_MATHOP_ASIN": "asin",
    "OPERATORS_MATHOP_ACOS": "acos",
    "OPERATORS_MATHOP_ATAN": "atan",
    "OPERATORS_MATHOP_LN": "ln",
    "OPERATORS_MATHOP_LOG": "log",
    "OPERATORS_MATHOP_EEXP": "e ^",
    "OPERATORS_MATHOP_10EXP": "10 ^",
    "PROCEDURES_DEFINITION": "تعریف %1",
    "SENSING_TOUCHINGOBJECT": "آیا %1 را لمس می کند؟",
    "SENSING_TOUCHINGOBJECT_POINTER": "اشاره گر ماوس",
    "SENSING_TOUCHINGOBJECT_EDGE": "لبه",
    "SENSING_TOUCHINGCOLOR": "آیا رنگ %1 را لمس می کند؟",
    "SENSING_COLORISTOUCHINGCOLOR": "آیا رنگ %1 ٬ %2 را لمس می کند؟",
    "SENSING_DISTANCETO": "فاصله تا %1",
    "SENSING_DISTANCETO_POINTER": "اشاره گر ماوس",
    "SENSING_ASKANDWAIT": "%1 را بپرس و صبر کن",
    "SENSING_ASK_TEXT": "اسم شما چیه؟",
    "SENSING_ANSWER": "پاسخ",
    "SENSING_KEYPRESSED": "کلید %1 فشرده شده؟",
    "SENSING_MOUSEDOWN": "آیا دکمه ی ماوس پایین فشرده شده؟",
    "SENSING_MOUSEX": "مکان x ماوس",
    "SENSING_MOUSEY": "مکان y ماوس",
    "SENSING_SETDRAGMODE": "تنظیم حالت کشیدن به %1",
    "SENSING_SETDRAGMODE_DRAGGABLE": "قابل کشیدن",
    "SENSING_SETDRAGMODE_NOTDRAGGABLE": "غیر قابل کشیدن",
    "SENSING_LOUDNESS": "بلندی صدا",
    "SENSING_LOUD": "صدا بلند است؟",
    "SENSING_TIMER": "زمان سنج",
    "SENSING_RESETTIMER": "تنظیم دوباره ی زمان سنج",
    "SENSING_OF": "%1 از %2",
    "SENSING_OF_XPOSITION": "موقعیت x",
    "SENSING_OF_YPOSITION": "موقعیت y",
    "SENSING_OF_DIRECTION": "جهت",
    "SENSING_OF_COSTUMENUMBER": "حالت #",
    "SENSING_OF_COSTUMENAME": "نام حالت",
    "SENSING_OF_SIZE": "اندازه",
    "SENSING_OF_VOLUME": "حجم",
    "SENSING_OF_BACKDROPNUMBER": "پس زمینه #",
    "SENSING_OF_BACKDROPNAME": "نام پس زمینه",
    "SENSING_OF_STAGE": "صحنه",
    "SENSING_CURRENT": "%1 فعلی",
    "SENSING_CURRENT_YEAR": "سال",
    "SENSING_CURRENT_MONTH": "ماه",
    "SENSING_CURRENT_DATE": "تاریخ",
    "SENSING_CURRENT_DAYOFWEEK": "روز هفته",
    "SENSING_CURRENT_HOUR": "ساعت",
    "SENSING_CURRENT_MINUTE": "دقیقه",
    "SENSING_CURRENT_SECOND": "ثانیه",
    "SENSING_DAYSSINCE2000": "تعداد روز ها از سال 2000",
    "SENSING_USERNAME": "نام کاربری",
    "SENSING_USERID": "شناسه ی کاربری",
    "SOUND_PLAY": "شروع صدا %1",
    "SOUND_PLAYUNTILDONE": "پخش صدای %1 وقتی که تمام شود",
    "SOUND_STOPALLSOUNDS": "همه ی صدا ها را متوقف کن",
    "SOUND_SETEFFECTO": "جلوه ی %1 را به %2 مقداردهی کن",
    "SOUND_CHANGEEFFECTBY": "تغییر جلوه ی %1 به اندازه ی %2",
    "SOUND_CLEAREFFECTS": "جلوه های صدا را پاک کن",
    "SOUND_EFFECTS_PITCH": "گام ",
    "SOUND_EFFECTS_PAN": "قاب چپ/راست",
    "SOUND_CHANGEVOLUMEBY": "بلندی صدا را به اندازه ی %1 تغییر بده",
    "SOUND_SETVOLUMETO": "تنظیم بلندی صدا به %1%",
    "SOUND_VOLUME": "بلندی صدا",
    "SOUND_RECORD": "ضبط کردن...",
    "CATEGORY_MOTION": "حرکت",
    "CATEGORY_LOOKS": "ظاهر ها",
    "CATEGORY_SOUND": "صدا",
    "CATEGORY_EVENTS": "رویدادها",
    "CATEGORY_CONTROL": "کنترل کردن",
    "CATEGORY_SENSING": "حس کردن",
    "CATEGORY_OPERATORS": "عملگرها",
    "CATEGORY_VARIABLES": "متغیرها",
    "CATEGORY_MYBLOCKS": "قطعه های من",
    "CATEGORY_LISTS": "",
    "CATEGORY_TUPLE": "",
    "CATEGORY_DICTIONARYS": "",
    "CATEGORY_NUMBERS": "",
    "CATEGORY_TEXT": "",
    "DUPLICATE": "تکثیر کردن",
    "DELETE": "حذف",
    "ADD_COMMENT": "افزودن توضیح",
    "REMOVE_COMMENT": "حذف توضیح",
    "DELETE_BLOCK": "حذف قطعه",
    "DELETE_X_BLOCKS": "حذف قطعه های %1",
    "DELETE_ALL_BLOCKS": "همه ی قطعه های %1 حذف شوند؟",
    "CLEAN_UP": "پاک کردن قطعه ها",
    "HELP": "راهنما",
    "UNDO": "باطل کردن",
    "REDO": "دوباره انجام دادن",
    "EDIT_PROCEDURE": "ویرایش",
    "SHOW_PROCEDURE_DEFINITION": "به محل تعریف شده برو",
    "WORKSPACE_COMMENT_DEFAULT_TEXT": "یک چیزی بگو...",
    "COLOUR_HUE_LABEL": "رنگ",
    "COLOUR_SATURATION_LABEL": "خلوص رنگ",
    "COLOUR_BRIGHTNESS_LABEL": "روشنایی",
    "CHANGE_VALUE_TITLE": "تغییر مقدار:",
    "RENAME_VARIABLE": "تغییر نام متغیر",
    "RENAME_VARIABLE_STRING": "",
    "RENAME_VARIABLE_NUMBER": "",
    "RENAME_VARIABLE_LIST": "",
    "RENAME_VARIABLE_TITLE": "تغییر نام همه متغیر های \"%1\" به:",
    "RENAME_VARIABLE_MODAL_TITLE": "تغییر نام متغیر",
    "NEW_VARIABLE": "ایجاد یک متغیر",
    "NEW_VARIABLE_TITLE": "نام متغیر جدید:",
    "VARIABLE_MODAL_TITLE": "متغیر جدید",
    "VARIABLE_ALREADY_EXISTS": "متغیری با نام \"%1\" وجود دارد",
    "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "هم اکنون نام \"%1\" برای متغیر دیگری از نوع %2 وجود دارد",
    "DELETE_VARIABLE_CONFIRMATION": "ِآیا %1 مورد استفاده شده از \"%2\" متغییر حذف شوند؟",
    "CANNOT_DELETE_VARIABLE_PROCEDURE": "امکان حذف متغیر \"%1\" نیست. زیرا جزئی از تعریف تابع \"%2\" است",
    "DELETE_VARIABLE": "حذف متغیر \"%1\"",
    "DELETE_VARIABLE_STRING": "",
    "DELETE_VARIABLE_NUMBER": "",
    "DELETE_VARIABLE_LIST": "",
    "NEW_PROCEDURE": "ایجاد یک قطعه",
    "PROCEDURE_ALREADY_EXISTS": "هم اکنون روالی به اسم \"%1\" وجود دارد.",
    "PROCEDURE_DEFAULT_NAME": "اسم قطعه",
    "NEW_LIST": "ایجاد فهرست",
    "NEW_LIST_TITLE": "اسم فهرست جدید:",
    "LIST_MODAL_TITLE": "فهرست جدید",
    "LIST_ALREADY_EXISTS": "هم اکنون فهرستی به اسم \"%1\" وجود دارد.",
    "RENAME_LIST_TITLE": "تغییرنام همه ی فهرست های \"%1\" به:",
    "RENAME_LIST_MODAL_TITLE": "تغییر نام فهرست",
    "DEFAULT_LIST_ITEM": "چیز",
    "DELETE_LIST": "حذف فهرست \"%1\"",
    "RENAME_LIST": "تغییر نام فهرست",
    "NEW_BROADCAST_MESSAGE": "پیام جدید",
    "NEW_BROADCAST_MESSAGE_TITLE": "نام پیام جدید:",
    "BROADCAST_MODAL_TITLE": "پیام جدید",
    "DEFAULT_BROADCAST_MESSAGE_NAME": "پیام 1",
    "DELETE_PROCEDURE": "",
    "OK": "",
    "PROMPT": "",
    "STOP": "",
    "NEW_STRING": "",
    "NEW_NUMBER_VARIABLE": "",
    "WIFI": "",
    "USERNAME": "",
    "PWD": "",
    "IOT_SERVICE": "",
    "IOT_ID": "",
    "IOT_PWD": "",
    "IOT_TOPIC": "",
    "MORE_TOPIC": "",
    "OBLOQ_HTTP": "",
    "OBLOQ_IP": "",
    "OBLOQ_PORT": "",
    "ADD_TOPIC": "",
    "DELETE_TOPIC": "",
    "IOT_SERVER": "",
    "CHINA": "",
    "GLOBAL": "",
    "LOW_C": "",
    "LOW_C$": "",
    "LOW_D": "",
    "LOW_D$": "",
    "LOW_E": "",
    "LOW_F": "",
    "LOW_F$": "",
    "LOW_G": "",
    "LOW_G$": "",
    "LOW_A": "",
    "LOW_A$": "",
    "LOW_B": "",
    "MIDDLE_C": "",
    "MIDDLE_C$": "",
    "MIDDLE_D": "",
    "MIDDLE_D$": "",
    "MIDDLE_E": "",
    "MIDDLE_F": "",
    "MIDDLE_F$": "",
    "MIDDLE_G": "",
    "MIDDLE_G$": "",
    "MIDDLE_A": "",
    "MIDDLE_A$": "",
    "MIDDLE_B": "",
    "HIGH_C": "",
    "HIGH_C$": "",
    "HIGH_D": "",
    "HIGH_D$": "",
    "HIGH_E": "",
    "HIGH_F": "",
    "HIGH_F$": "",
    "HIGH_G": "",
    "HIGH_G$": "",
    "HIGH_A": "",
    "HIGH_A$": "",
    "HIGH_B": "",
    "OPERATORS_STRINGTONUMBER": "",
    "INTEGER": "",
    "DECIMAL": "",
    "OPERATORS_NUMTOASCII": "",
    "OPERATORS_STRINGTOASCII": "",
    "OPERATORS_NUMTOSTRING": "",
    "OPERATORS_MAP": "",
    "OPERATORS_CONSTRAIN": "",
    "STEPS_PER_TURN": "",
    "ROTATE_SPEED": "",
    "NEW_AI": "",
    "ASK_AND_PAINT": "",
    "GET_IDENTIFIED_NUM": "",
    "READ_NUM_AND_SAY": "",
    "IMAGE_ADDR": "",
    "IMAGE_PREVIEW": "",
    "IMAGE_OPEN": "",
    "IMAGE_SIZE": "",
    "IMAGE_WIDTH": "",
    "IMAGE_HEIGHT": "",
    "VARIABLE": "",
    "VARIABLE_LIST": "",
    "SET_STRING_VARIABLE": "",
    "STRING_START_WITH": "",
    "DICTIONARY_HEIGHT_FIRST": "",
    "DICTIONARY_HEIGHT_TWO": "",
    "DICTIONARY_HEIGHT_THREE": "",
    "DICTIONARY_HEIGHT_FOUR": "",
    "DATA_MPITEMOFLIST": "",
    "DATA_MPINTERCEPTITEM": "",
    "DATA_MPINSERTATLIST": "",
    "DATA_MPSETVALUEATLIST": "",
    "DATA_MPINSERTATLIST_INSERT": "",
    "DATA_MPINSERTATLIST_CHANGE": "",
    "DATA_MPINSERTATLIST_FOUR": "",
    "DATA_MPINSERTATLIST_FIVE": "",
    "DATA_MPINSERTATLIST_SIX": "",
    "DATA_MPINSERTATLIST_SEVEN": "",
    "DATA_MPINSERTATLIST_EIGHT": "",
    "DATA_MPINSERTATLIST_EIGHT_FALSE": "",
    "DATA_MPINSERTATLIST_EIGHT_TRUE": "",
    "DATA_MPINSERTATLIST_EIGHT_REVERSE": "",
    "OPERATORS_RANDOMA": "",
    "OPERATORS_MODA": "",
    "OPERATORS_ROUNDA": "",
    "OPERATORS_MATHOPA": "",
    "OPERATORS_MATHOP_ABSA": "",
    "OPERATORS_MATHOP_FLOORA": "",
    "OPERATORS_MATHOP_CEILINGA": "",
    "OPERATORS_MATHOP_SQRTA": "",
    "OPERATORS_MATHOP_FIVE": "",
    "OPERATORS_MATHOP_SIX": "",
    "OPERATORS_MATHOP_SEVEN": "",
    "OPERATORS_MATHOP_LENGHT": "",
    "OPERATORS_MATHOP_MAXMIN": "",
    "OPERATORS_MATHOP_MAXMIN_MAX": "",
    "OPERATORS_MATHOP_MAXMIN_MIN": "",
    "OPERATORS_TEXTS_ONE": "",
    "OPERATORS_TEXTS_TWO": "",
    "OPERATORS_TEXTS_THREE": "",
    "OPERATORS_TEXTS_FOUR": "",
    "OPERATORS_TEXTS_FOUR_NEW": "",
    "OPERATORS_TEXTS_NEW": "",
    "OPERATORS_TEXTS_NEW_OP": "",
    "OPERATORS_TEXTS_FOUR_ONE": "",
    "OPERATORS_TEXTS_FOUR_TWO": "",
    "OPERATORS_TEXTS_FOUR_THREE": "",
    "OPERATORS_TEXTS_FOUR_FOUR": "",
    "OPERATORS_TEXTS_FOUR_F": "",
    "OPERATORS_TEXTS_FOUR_L": "",
    "OPERATORS_STRINGTONUMBERA": "",
    "OPERATORS_NUMTOASCIIA": "",
    "OPERATORS_STRINGTOASCIIA": "",
    "OPERATORS_NUMTOSTRINGA": "",
    "NEW_MC": "",
    "RENAME_MCNUMBER_TITLE": "",
    "RENAME_MCNUMBER_MODAL_TITLE": "",
    "RENAME_VARIABLE_MCNUMBER": "",
    "DELETE_VARIABLE_MCNUMBER": "",
    "SET_MC_VARIABLE": "",
    "TUPLE_ONE": "",
    "TUPLE_TWO": "",
    "ALIYUN": "",
    "ONENET": "",
    "EASYIOT": "",
    "SHANGHAI": "",
    "QINGDAO": "",
    "BEIJING": "",
    "ZHANGJIAKOU": "",
    "HUHEHAOTE": "",
    "HANGZHOU": "",
    "SHENZHEN": "",
    "HONGKONG": "",
    "SINGAPORE": "",
    "SYDNEY": "",
    "KUALALUMPUR": "",
    "JAKARTA": "",
    "MUMBAI": "",
    "TOKYO": "",
    "SILICONVALLEY": "",
    "VIRGINIA": "",
    "FRANKFURT": "",
    "LONDON": "",
    "DUBAI": "",
    "IOT_PLATFORM": "",
    "PARAMS": "",
    "SERVER_ATTR": "",
    "PRODUCTID": "",
    "DEVICEID": "",
    "TINYDB_SECRET": "",
    "TINYDB_USER": "",
    "APIADDR": "",
    "SOFTWARE_SERIAL": "",
    "HARDWARE_SERIAL": "",
    "HARDWARE_SERIAL1": "",
    "HARDWARE_SERIAL2": "",
    "HARDWARE_SERIAL3": ""
  },
  "fr": {
    "CONTROL_FOREVER": "pour toujours",
    "CONTROL_LOOP_BREAKCONTINUE": "%1",
    "CONTROL_LOOP_BREAKCONTINUE_BREAK": "break",
    "CONTROL_LOOP_BREAKCONTINUE_CONTINUE": "continue",
    "CONTROL_REPEAT": "répéter %1",
    "CONTROL_IF": "si %1 alors",
    "CONTROL_ELSE": "sinon",
    "CONTROL_STOP": "Arrêtez",
    "CONTROL_STOP_ALL": "tout",
    "CONTROL_STOP_THIS": "ce script",
    "CONTROL_STOP_OTHER": "autres scripts pour le Lutin",
    "CONTROL_WAIT": "attendre %1 secondes",
    "CONTROL_WAIT_ESP32_MPY": "wait %1 %2",
    "CONTROL_FOR_FROM_SEQUENCE": "use %1 from sequence %2",
    "CONTROL_WAIT_ESP32_MPY_S": "second(s)",
    "CONTROL_WAIT_ESP32_MPY_MS": "millisecond(ms)",
    "CONTROL_WAIT_ESP32_MPY_US": "microsecond(us)",
    "CONTROL_WAITUNTIL": "attendre jusqu'à %1",
    "CONTROL_REPEATUNTIL": "répéter jusqu'à %1",
    "CONTROL_WHILE": "tandis que %1",
    "CONTROL_FOREACH": "pour chaque %1 sur %2",
    "CONTROL_STARTASCLONE": "quand je commence comme un clone",
    "CONTROL_CREATECLONEOF": "créer un clone de %1",
    "CONTROL_CREATECLONEOF_MYSELF": "moi même",
    "CONTROL_DELETETHISCLONE": "supprimer ce clone",
    "CONTROL_COUNTER": "compteur",
    "CONTROL_INCRCOUNTER": "compteur d'incrément",
    "CONTROL_CLEARCOUNTER": "effacer compteur",
    "CONTROL_ALLATONCE": "tout en une fois",
    "DATA_SETVARIABLETO": "fixer %1 à %2",
    "DATA_CHANGEVARIABLEBY": "changer %1 de %2",
    "DATA_SHOWVARIABLE": "affiche la variable %1",
    "DATA_HIDEVARIABLE": "masquer la variable %1",
    "DATA_ADDTOLIST": "ajouter %1 à %2",
    "DATA_DELETEOFLIST": "supprimer %1 sur %2",
    "DATA_DELETEALLOFLIST": "supprimer tout de %1",
    "DATA_INSERTATLIST": "insérer %1 en %2 de %3",
    "DATA_REPLACEITEMOFLIST": "remplace l'élément %1 de %2 par %3",
    "DATA_ITEMOFLIST": "élément %1 de %2",
    "DATA_ITEMNUMOFLIST": "Numéro d'article de %1 à %2",
    "DATA_LENGTHOFLIST": "longueur de %1",
    "DATA_LISTCONTAINSITEM": "%1 contient %2?",
    "DATA_SHOWLIST": "afficher la liste %1",
    "DATA_HIDELIST": "masquer la liste %1",
    "DATA_INDEX_ALL": "tout",
    "DATA_INDEX_LAST": "dernier",
    "DATA_INDEX_RANDOM": "au hasard",
    "EVENT_WHENFLAGCLICKED": "quand %1 a cliqué",
    "EVENT_WHENTHISSPRITECLICKED": "quand on clique ce Lutin",
    "EVENT_WHENSTAGECLICKED": "quand on clique cette scène",
    "EVENT_WHENTOUCHINGOBJECT": "quand ce lutin touche %1",
    "EVENT_WHENBROADCASTRECEIVED": "quand je reçois %1",
    "EVENT_WHENBACKDROPSWITCHESTO": "lorsque le Fond bascule sur %1",
    "EVENT_WHENGREATERTHAN": "quand %1 > %2",
    "EVENT_WHENGREATERTHAN_TIMER": "Chronomètre",
    "EVENT_WHENGREATERTHAN_LOUDNESS": "intensité",
    "EVENT_BROADCAST": "diffuser %1",
    "EVENT_BROADCASTANDWAIT": "diffuser %1 et attendre",
    "EVENT_WHENKEYPRESSED": "touche %1 enfoncée",
    "EVENT_WHENKEYPRESSED_SPACE": "espace",
    "EVENT_WHENKEYPRESSED_LEFT": "←",
    "EVENT_WHENKEYPRESSED_RIGHT": "→",
    "EVENT_WHENKEYPRESSED_DOWN": "↓",
    "EVENT_WHENKEYPRESSED_UP": "↑",
    "EVENT_WHENKEYPRESSED_ANY": "tout",
    "LOOKS_SAYFORSECS": "dire %1 pendant %2 secondes",
    "LOOKS_SAY": "dire %1",
    "LOOKS_HELLO": "salut!",
    "LOOKS_THINKFORSECS": "pense %1 pendant %2 secondes",
    "LOOKS_THINK": "pense %1",
    "LOOKS_HMM": "Hmm ...",
    "LOOKS_SHOW": "montrer",
    "LOOKS_HIDE": "cacher",
    "LOOKS_HIDEALLSPRITES": "cacher tous les lutins",
    "LOOKS_EFFECT_COLOR": "Couleur",
    "LOOKS_EFFECT_FISHEYE": "fisheye",
    "LOOKS_EFFECT_WHIRL": "tourbillon",
    "LOOKS_EFFECT_PIXELATE": "pixelate",
    "LOOKS_EFFECT_MOSAIC": "mosaïque",
    "LOOKS_EFFECT_BRIGHTNESS": "luminosité",
    "LOOKS_EFFECT_GHOST": "fantôme",
    "LOOKS_CHANGEEFFECTBY": "change effet %1 de %2",
    "LOOKS_SETEFFECTTO": "défini effet %1 à %2",
    "LOOKS_CLEARGRAPHICEFFECTS": "annuler les effets graphiques",
    "LOOKS_CHANGESIZEBY": "changer la taille de %1",
    "LOOKS_SETSIZETO": "fixer la taille sur %1%",
    "LOOKS_SIZE": "Taille",
    "LOOKS_CHANGESTRETCHBY": "change l'étirement de %1",
    "LOOKS_SETSTRETCHTO": "fixer l'étirement à  %1 %",
    "LOOKS_SWITCHCOSTUMETO": "passer au costume %1",
    "LOOKS_NEXTCOSTUME": "prochain costume",
    "LOOKS_SWITCHBACKDROPTO": "basculer le Fond sur %1",
    "LOOKS_GOTOFRONTBACK": "placer la couche vers %1 ",
    "LOOKS_GOTOFRONTBACK_FRONT": "devant",
    "LOOKS_GOTOFRONTBACK_BACK": "derriere",
    "LOOKS_GOFORWARDBACKWARDLAYERS": "placer la couche %2 en %1 ",
    "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "l'avant",
    "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "l'arrière",
    "LOOKS_BACKDROPNUMBERNAME": "%1 de le Fond",
    "LOOKS_COSTUMENUMBERNAME": "%1 du costume",
    "LOOKS_NUMBERNAME_NUMBER": "numéro",
    "LOOKS_NUMBERNAME_NAME": "nom",
    "LOOKS_SWITCHBACKDROPTOANDWAIT": "basculer le Fond sur %1 et attendre",
    "LOOKS_NEXTBACKDROP_BLOCK": "Fond suivant",
    "LOOKS_NEXTBACKDROP": "Fond suivant",
    "LOOKS_PREVIOUSBACKDROP": "Fond précédente",
    "LOOKS_RANDOMBACKDROP": "Fond aléatoire",
    "MOTION_MOVESTEPS": "déplace %1 étapes",
    "MOTION_TURNLEFT": "tourne %1 %2 degrés",
    "MOTION_TURNRIGHT": "tourne %1 %2 degrés",
    "MOTION_POINTINDIRECTION": "pointe dans la direction %1",
    "MOTION_POINTTOWARDS": "pointe vers %1",
    "MOTION_POINTTOWARDS_POINTER": "pointeur de la souris",
    "MOTION_POINTTOWARDS_RANDOM": "direction aléatoire",
    "MOTION_GOTO": "aller à %1",
    "MOTION_GOTO_POINTER": "pointeur de la souris",
    "MOTION_GOTO_RANDOM": "position aléatoire",
    "MOTION_GOTOXY": "aller à x: %1 y: %2",
    "MOTION_GLIDESECSTOXY": "glisse en %1 s vers x: %2 y: %3",
    "MOTION_GLIDETO": "glisse %1 secondes à %2",
    "MOTION_GLIDETO_POINTER": "pointeur de la souris",
    "MOTION_GLIDETO_RANDOM": "position aléatoire",
    "MOTION_CHANGEXBY": "change x de %1",
    "MOTION_SETX": "fixer x à %1",
    "MOTION_CHANGEYBY": "change y de %1",
    "MOTION_SETY": "fixe y à %1",
    "MOTION_IFONEDGEBOUNCE": "si sur bord rebondir",
    "MOTION_SETROTATIONSTYLE": "fixe le style de rotation %1",
    "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "gauche droite",
    "MOTION_SETROTATIONSTYLE_DONTROTATE": "ne tourne pas",
    "MOTION_SETROTATIONSTYLE_ALLAROUND": "tout autour",
    "MOTION_XPOSITION": "position en x",
    "MOTION_YPOSITION": "position en y",
    "MOTION_DIRECTION": "direction",
    "MOTION_SCROLLRIGHT": "faire défiler vers la droite %1",
    "MOTION_SCROLLUP": "faire défiler %1",
    "MOTION_ALIGNSCENE": "aligner la scène %1",
    "MOTION_ALIGNSCENE_BOTTOMLEFT": "en bas à gauche",
    "MOTION_ALIGNSCENE_BOTTOMRIGHT": "en bas à droite",
    "MOTION_ALIGNSCENE_MIDDLE": "milieu",
    "MOTION_ALIGNSCENE_TOPLEFT": "en haut à gauche",
    "MOTION_ALIGNSCENE_TOPRIGHT": "en haut à droite",
    "MOTION_XSCROLL": "défilement sur x",
    "MOTION_YSCROLL": "défilement sur y",
    "MOTION_STAGE_SELECTED": "Scénario sélectionné: pas de bloc de mouvement",
    "OPERATORS_ADD": "%1 + %2",
    "OPERATORS_SUBTRACT": "%1 - %2",
    "OPERATORS_MULTIPLY": "%1 * %2",
    "OPERATORS_DIVIDE": "%1 / %2",
    "OPERATORS_RANDOM": "nombre au hasard entre %1 à %2",
    "OPERATORS_GT": "%1 > %2",
    "OPERATORS_GT_EQUALS": "%1 >= %2",
    "OPERATORS_LT": "%1 < %2",
    "OPERATORS_LT_EQUALS": "%1 <= %2",
    "OPERATORS_EQUALS": "%1 = %2",
    "OPERATORS_AND": "%1 et %2",
    "OPERATORS_OR": "%1 ou %2",
    "OPERATORS_NOT": "non %1",
    "CONTROL_TRUE_FALSE": "%1",
    "CONTROL_TRUE_FALSE_TRUE": "true",
    "CONTROL_TRUE_FALSE_FALSE": "false",
    "CONTROL_NONE": "None",
    "CONTROL_RETURN": "return %1",
    "OPERATORS_VALUE_IS_TRUE": "value %1 is true?",
    "OPERATORS_VALUE_TYPE": "value %1 type",
    "CONTROL_TRY": "try",
    "CONTROL_EXCEPT": "except",
    "CONTROL_FINALLY": "finally",
    "OPERATORS_VALUE_TYPE_IS": "value %1 type is %2?",
    "OPERATORS_VALUE_TYPE_INT": "int",
    "OPERATORS_VALUE_TYPE_FLOAT": "float",
    "OPERATORS_VALUE_TYPE_BOOL": "bool",
    "OPERATORS_VALUE_TYPE_STR": "str",
    "OPERATORS_VALUE_TYPE_LIST": "list",
    "OPERATORS_VALUE_TYPE_TUPLE": "tuple",
    "OPERATORS_VALUE_TYPE_SET": "set",
    "OPERATORS_VALUE_TYPE_DICT": "dict",
    "OPERATORS_VALUE_TYPE_BYTES": "bytes",
    "OPERATORS_VALUE_TYPE_BYTEARRAY": "bytearray",
    "OPERATORS_VALUE_TYPE_COMPLEX": "complex",
    "OPERATORS_CACULATE_RESULT": "eval %1",
    "OPERATORS_JOIN": "unir %1 et %2",
    "OPERATORS_JOIN_APPLE": "pomme",
    "OPERATORS_JOIN_BANANA": "banane",
    "OPERATORS_LETTEROF": "lettre %1 de %2",
    "OPERATORS_LETTEROF_APPLE": "a",
    "OPERATORS_LENGTH": "longueur de %1",
    "OPERATORS_CONTAINS": "%1 contient %2?",
    "OPERATORS_MOD": "%1 mod %2",
    "OPERATORS_ROUND": "arrondi %1",
    "OPERATORS_MATHOP": "%1 de %2",
    "OPERATORS_MATHOP_ABS": "abs",
    "OPERATORS_MATHOP_FLOOR": "arrondiINF",
    "OPERATORS_MATHOP_CEILING": "arrondiSUP",
    "OPERATORS_MATHOP_SQRT": "sqrt",
    "OPERATORS_MATHOP_SIN": "sin",
    "OPERATORS_MATHOP_COS": "cos",
    "OPERATORS_MATHOP_TAN": "tan",
    "OPERATORS_MATHOP_ASIN": "arcsin",
    "OPERATORS_MATHOP_ACOS": "arccos",
    "OPERATORS_MATHOP_ATAN": "arctan",
    "OPERATORS_MATHOP_LN": "ln",
    "OPERATORS_MATHOP_LOG": "log",
    "OPERATORS_MATHOP_EEXP": "e ^",
    "OPERATORS_MATHOP_10EXP": "10 ^",
    "PROCEDURES_DEFINITION": "fixer %1",
    "SENSING_TOUCHINGOBJECT": "%1 touché?",
    "SENSING_TOUCHINGOBJECT_POINTER": "pointeur de la souris",
    "SENSING_TOUCHINGOBJECT_EDGE": "bord",
    "SENSING_TOUCHINGCOLOR": "couleur %1 touché?",
    "SENSING_COLORISTOUCHINGCOLOR": "couleur %1 touche %2?",
    "SENSING_DISTANCETO": "distance au %1",
    "SENSING_DISTANCETO_POINTER": "pointeur de la souris",
    "SENSING_ASKANDWAIT": "demander %1 et attendre",
    "SENSING_ASK_TEXT": "Quel est ton nom?",
    "SENSING_ANSWER": "réponse",
    "SENSING_KEYPRESSED": "touche %1 enfoncée?",
    "SENSING_MOUSEDOWN": "souris vers le bas?",
    "SENSING_MOUSEX": "souris x",
    "SENSING_MOUSEY": "souris y",
    "SENSING_SETDRAGMODE": "fixer le mode glisser %1",
    "SENSING_SETDRAGMODE_DRAGGABLE": "déplaçable",
    "SENSING_SETDRAGMODE_NOTDRAGGABLE": "non déplaçable",
    "SENSING_LOUDNESS": "intensité",
    "SENSING_LOUD": "bruyant?",
    "SENSING_TIMER": "Chronomètre",
    "SENSING_RESETTIMER": "réinitialiser la minuterie",
    "SENSING_OF": "%1 sur %2",
    "SENSING_OF_XPOSITION": "position en x",
    "SENSING_OF_YPOSITION": "position en y",
    "SENSING_OF_DIRECTION": "direction",
    "SENSING_OF_COSTUMENUMBER": "costume #",
    "SENSING_OF_COSTUMENAME": "nom du costume",
    "SENSING_OF_SIZE": "Taille",
    "SENSING_OF_VOLUME": "volume",
    "SENSING_OF_BACKDROPNUMBER": "fond #",
    "SENSING_OF_BACKDROPNAME": "nom du Fond",
    "SENSING_OF_STAGE": "Scène",
    "SENSING_CURRENT": "%1 courante",
    "SENSING_CURRENT_YEAR": "année",
    "SENSING_CURRENT_MONTH": "mois",
    "SENSING_CURRENT_DATE": "date",
    "SENSING_CURRENT_DAYOFWEEK": "jour de la semaine",
    "SENSING_CURRENT_HOUR": "heure",
    "SENSING_CURRENT_MINUTE": "minute",
    "SENSING_CURRENT_SECOND": "seconde",
    "SENSING_DAYSSINCE2000": "jours depuis 2000",
    "SENSING_USERNAME": "Nom d'utilisateur",
    "SENSING_USERID": "identifiant d'utilisateur",
    "SOUND_PLAY": "jouer le son %1",
    "SOUND_PLAYUNTILDONE": "jouer le son %1 jusqu'à la fin",
    "SOUND_STOPALLSOUNDS": "arrêter tous les sons",
    "SOUND_SETEFFECTO": "fixer la %1 sur %2",
    "SOUND_CHANGEEFFECTBY": "changer la %1 de %2",
    "SOUND_CLEAREFFECTS": "annuler les effets sonores",
    "SOUND_EFFECTS_PITCH": "hauteur",
    "SOUND_EFFECTS_PAN": "balance gauche/droite",
    "SOUND_CHANGEVOLUMEBY": "changer le volume de %1",
    "SOUND_SETVOLUMETO": "fixer le volume sur %1%",
    "SOUND_VOLUME": "le volume",
    "SOUND_RECORD": "enregistre…",
    "CATEGORY_MOTION": "Mouvement",
    "CATEGORY_LOOKS": "Affichage",
    "CATEGORY_SOUND": "Son",
    "CATEGORY_EVENTS": "Événements",
    "CATEGORY_CONTROL": "Contrôle",
    "CATEGORY_SENSING": "Détection",
    "CATEGORY_OPERATORS": "Opérateurs",
    "CATEGORY_VARIABLES": "Variables",
    "CATEGORY_MYBLOCKS": "Mes blocs",
    "CATEGORY_NUMBERS": "Nombre",
    "CATEGORY_TEXT": "Texte",
    "DUPLICATE": "Dupliquer",
    "DELETE": "Effacer",
    "ADD_COMMENT": "Ajouter un commentaire",
    "REMOVE_COMMENT": "Supprimer le commentaire",
    "DELETE_BLOCK": "Supprimer le bloc",
    "DELETE_X_BLOCKS": "Supprimer les blocs %1",
    "DELETE_ALL_BLOCKS": "Supprimer tous les blocs %1?",
    "CLEAN_UP": "Nettoyer les blocs",
    "HELP": "Aidez-moi",
    "UNDO": "annuler",
    "REDO": "Refaire",
    "EDIT_PROCEDURE": "modifier",
    "SHOW_PROCEDURE_DEFINITION": "Aller à la définition",
    "WORKSPACE_COMMENT_DEFAULT_TEXT": "Dis quelque-chose..",
    "COLOUR_HUE_LABEL": "Couleur",
    "COLOUR_SATURATION_LABEL": "Saturation",
    "COLOUR_BRIGHTNESS_LABEL": "Luminosité",
    "CHANGE_VALUE_TITLE": "Change la valeur:",
    "RENAME_VARIABLE": "Renomme la variable",
    "RENAME_VARIABLE_STRING": "Renomme la chaîne",
    "RENAME_VARIABLE_NUMBER": "Renomme la variable numérique",
    "RENAME_VARIABLE_LIST": "Renomme la liste",
    "RENAME_VARIABLE_TITLE": "Renomme toutes les variables \"%1\" en:",
    "RENAME_VARIABLE_MODAL_TITLE": "Rename Variable",
    "NEW_VARIABLE": "Créer une Variable",
    "NEW_VARIABLE_TITLE": "nom de la Nouvelle variable:",
    "VARIABLE_MODAL_TITLE": "Nouvelle variable",
    "VARIABLE_ALREADY_EXISTS": "Une variable nommé \"%1\" existe déjà.",
    "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "Une variable nommé \"%1\" existe pour une autre variable de type \"%2\"",
    "DELETE_VARIABLE_CONFIRMATION": "Efface %1 usages de la variable \"%2\" ?",
    "CANNOT_DELETE_VARIABLE_PROCEDURE": "Can't delete the variable \"%1\" because it's part of the definition of the function \"%2\"",
    "DELETE_VARIABLE": "Efface la variable \"%1\" ",
    "DELETE_VARIABLE_STRING": "Efface la variable chaîne \"%1\"",
    "DELETE_VARIABLE_NUMBER": "Efface la variable nombre \"%1\"",
    "DELETE_VARIABLE_LIST": "Efface la variable liste \"%1\"",
    "NEW_PROCEDURE": "créer un Bloc",
    "PROCEDURE_ALREADY_EXISTS": "Une procédure nommé \"%1\" existe déjà.",
    "PROCEDURE_DEFAULT_NAME": "nom du bloc",
    "NEW_LIST": "créer une Liste",
    "NEW_LIST_TITLE": " nom de la Nouvelle liste:",
    "LIST_MODAL_TITLE": "Nouvelle Liste",
    "LIST_ALREADY_EXISTS": "Une list nommé \"%1\" existe déjà.",
    "RENAME_LIST_TITLE": "Renomme toute les listes \"%1\" en:",
    "RENAME_LIST_MODAL_TITLE": "Renomme la Liste",
    "DEFAULT_LIST_ITEM": "chose",
    "DELETE_LIST": "Delete the \"%1\" list",
    "RENAME_LIST": "Rename list",
    "NEW_BROADCAST_MESSAGE": "New message",
    "NEW_BROADCAST_MESSAGE_TITLE": "New message name:",
    "BROADCAST_MODAL_TITLE": "New Message",
    "DEFAULT_BROADCAST_MESSAGE_NAME": "message1",
    "DELETE_PROCEDURE": "To delete a block definition first remove all uses of the block",
    "OK": "OK",
    "CANCEL": "Cancel",
    "PROMPT": "Prompt",
    "STOP": "stop",
    "NEW_STRING": "Créer une chaîne",
    "NEW_NUMBER_VARIABLE": "nouvelle variable numérique",
    "WIFI": "Wi-Fi",
    "USERNAME": "user",
    "PWD": "password",
    "IOT_SERVICE": "IOT Service",
    "IOT_ID": "iot id",
    "IOT_PWD": "iot pwd",
    "IOT_TOPIC": "Topic_0",
    "MORE_TOPIC": "Topic",
    "OBLOQ_HTTP": "HTTP",
    "OBLOQ_IP": "IP Address",
    "OBLOQ_PORT": "Port",
    "ADD_TOPIC": "Add Topic - only add up to 4",
    "DELETE_TOPIC": "Delete Topic",
    "IOT_SERVER": "Server",
    "CHINA": "China",
    "GLOBAL": "Global",
    "LOW_C": "Low C/C3",
    "LOW_C$": "Low C#/C#3",
    "LOW_D": "Low D/D3",
    "LOW_D$": "Low D#/D#3",
    "LOW_E": "Low E/E3",
    "LOW_F": "Low F/F3",
    "LOW_F$": "Low F#/F#3",
    "LOW_G": "Low G/G3",
    "LOW_G$": "Low G#/G#3",
    "LOW_A": "Low A/A3",
    "LOW_A$": "Low A#/A#3",
    "LOW_B": "Low B/B3",
    "MIDDLE_C": "Middle C/C4",
    "MIDDLE_C$": "Middle C#/C#4",
    "MIDDLE_D": "Middle D/D4",
    "MIDDLE_D$": "Middle D#/D#4",
    "MIDDLE_E": "Middle E/E4",
    "MIDDLE_F": "Middle F/F4",
    "MIDDLE_F$": "Middle F#/F#4",
    "MIDDLE_G": "Middle G/G4",
    "MIDDLE_G$": "Middle G#/G#4",
    "MIDDLE_A": "Middle A/A4",
    "MIDDLE_A$": "Middle A#/A#4",
    "MIDDLE_B": "Middle B/B4",
    "HIGH_C": "High C/C5",
    "HIGH_C$": "High C#/C#5",
    "HIGH_D": "High D/D5",
    "HIGH_D$": "High D#/D#5",
    "HIGH_E": "High E/E5",
    "HIGH_F": "High F/F5",
    "HIGH_F$": "High F#/F#5",
    "HIGH_G": "High G/G5",
    "HIGH_G$": "High G#/G#5",
    "HIGH_A": "High A/A5",
    "HIGH_A$": "High A#/A#5",
    "HIGH_B": "High B/B5",
    "OPERATORS_STRINGTONUMBER": "convertis chaîne %1 en %2",
    "INTEGER": "Entier",
    "DECIMAL": "Decimal",
    "OPERATORS_NUMTOASCII": "convertis nombre %1 en ASCII",
    "OPERATORS_STRINGTOASCII": "convertis chaîne %1 en ASCII",
    "OPERATORS_NUMTOSTRING": "convertis nombre %1 en chaîne",
    "OPERATORS_MAP": "mappe %1 de [ %2  %3 ] à [ %4  %5 ]",
    "OPERATORS_CONSTRAIN": "contrains %1 entre(min) %2 et(max) %3",
    "STEPS_PER_TURN": "pas par tour",
    "ROTATE_SPEED": "tourne à la vitesse (t/s)",
    "NEW_AI": "Make an AI Block",
    "ASK_AND_PAINT": "please draw a number",
    "GET_IDENTIFIED_NUM": "number recognition",
    "READ_NUM_AND_SAY": "speak out the recognized number",
    "IMAGE_ADDR": "Image Addr",
    "IMAGE_PREVIEW": "Image Preview",
    "IMAGE_OPEN": "open",
    "IMAGE_SIZE": "Image Size",
    "IMAGE_WIDTH": "W",
    "IMAGE_HEIGHT": "H",
    "VARIABLE": "%1",
    "VARIABLE_LIST": "%1",
    "SET_STRING_VARIABLE": "met %1 à %2",
    "STRING_START_WITH": "%1 débute avec %2",
    "OPERATORS_RANDOMA": "%1 %2 prend un nombre aléatoire compris entre %3 et %4 %5",
    "OPERATORS_MODA": "%1 %2 %3 divisé par le reste de %4",
    "OPERATORS_ROUNDA": "%1 %2 arrondi à %3",
    "OPERATORS_MATHOPA": "%1 %2 %3 %4",
    "OPERATORS_MATHOP_ABSA": "abs",
    "OPERATORS_MATHOP_FLOORA": "arrondiSUP",
    "OPERATORS_MATHOP_CEILINGA": "arrondiINF",
    "OPERATORS_MATHOP_SQRTA": "carré",
    "OPERATORS_MATHOP_FIVE": "%1 %2 augmente la variable %3 de %4",
    "OPERATORS_MATHOP_SIX": "%1 %2 mappe %3 de [ %4  %5 ] à [ %6  %7 ]",
    "OPERATORS_MATHOP_SEVEN": "%1 %2 contrains %3 entre (min) %4 et (max) %5",
    "OPERATORS_NUM_INPUT": "%1 %2 number %3",
    "OPERATORS_NUMS_CACULATE": "%1 %2 %3",
    "OPERATORS_MATHOP_SQUARE": "**",
    "OPERATORS_MATHOP_LOGIC_OR": "|",
    "OPERATORS_MATHOP_LOGIC_AND": "&",
    "OPERATORS_MATHOP_LOGIC_POWER": "^",
    "OPERATORS_MATHOP_LOGIC_LEFT": "<<",
    "OPERATORS_MATHOP_LOGIC_RIGHT": ">>",
    "OPERATORS_SPECIAL_NUM": "%1 %2 %3",
    "OPERATORS_MATHOP_PI": "π",
    "OPERATORS_MATHOP_LOGARITHM": "e",
    "OPERATORS_MATHOP_FAI": "φ",
    "OPERATORS_MATHOP_SQRT2": "sqrt(2)",
    "OPERATORS_MATHOP_SQRTHALF": "sqrt(½)",
    "OPERATORS_MATHOP_UNLIMITED": "∞",
    "OPERATORS_NUMS_TYPE": "%1 %2 %3 %4?",
    "OPERATORS_MATHOP_ISEVEN": "is even",
    "OPERATORS_MATHOP_ISODD": "is odd",
    "OPERATORS_MATHOP_ISINT": "is int",
    "OPERATORS_MATHOP_ISPOSITIVE": "is positive",
    "OPERATORS_MATHOP_ISNEGATIVE": "is negative",
    "OPERATORS_SAVE2DECIMAL": "%1 %2 %3 save %4 decimal places",
    "OPERATORS_LIST_CACULATE": "%1 %2 %3 %4",
    "OPERATORS_MATHOP_LIST_SUM": "sum of the numbers in the list",
    "OPERATORS_MATHOP_LIST_MIN": "minimum value in list",
    "OPERATORS_MATHOP_LIST_MAX": "maximum value in the list",
    "OPERATORS_MATHOP_LIST_AVERAGE": "average in list",
    "OPERATORS_MATHOP_LIST_NUM": "number of digits in the list",
    "OPERATORS_MATHOP_LIST_MODE": "list mode",
    "OPERATORS_MATHOP_LIST_STDDEVIATION": "standard deviation in the list",
    "OPERATORS_MATHOP_LIST_RANDOM": "random item of list",
    "OPERATORS_QUOTIENT_INT": "%1 %2 %3 ÷ %4 integer part of quotient",
    "OPERATORS_RANDOM_DECIMAL": "%1 %2 random decimal",
    "OPERATORS_BIN_NEGATION": "%1 %2 binary negation %3",
    "OPERATORS_INT_TO_STR": "%1 %2 decimal integer %3 transform into %4 string",
    "OPERATORS_MATHOP_INTTOSTR_BIN": "binary",
    "OPERATORS_MATHOP_INTTOSTR_OCT": "octal",
    "OPERATORS_MATHOP_INTTOSTR_HEX": "hex",
    "OPERATORS_BINSTR_TO_INT": "%1 %2 %3 string %4 transform into decimal integer",
    "OPERATORS_INT_TO_BYTE": "%1 %2 decimal integer %3 transform into %4 byte",
    "OPERATORS_MATHOP_INTTOBYTE_TWO": "2",
    "OPERATORS_MATHOP_INTTOBYTE_FOUR": "4",
    "OPERATORS_INT_TO_ASCII": "%1 %2 int %3 transform into ASCII character",
    "OPERATORS_ASCII_TO_INT": "%1 %2 ASCII character %3 to int",
    "OPERATORS_STR_TO_INT": "%1 %2 string %3 to hex string",
    "OPERATORS_HEXSTR_TO_BIN": "%1 %2 hex string %3 to bin byte",
    "OPERATORS_STR_TO_BASE64": "%1 %2 %3 encodes in base64 format returns a byte object",
    "OPERATORS_BASE64_TO_BYTE": "%1 %2 decode base64 data %3 returns a byte object",
    "OPERATORS_MATHOP_LENGHT": "longueur %1",
    "OPERATORS_MATHOP_MAXMIN": "%1 %2",
    "OPERATORS_MATHOP_MAXMIN_MAX": "max",
    "OPERATORS_MATHOP_MAXMIN_MIN": "min",
    "OPERATORS_TEXTS_ONE": "%1 %2 unir %3 et %4",
    "OPERATORS_TEXTS_TWO": "%1 %2 extrait le caractère n°%4 de %3",
    "OPERATORS_TEXTS_THREE": "%1 %2 %3 contient %4?",
    "OPERATORS_TEXTS_FOUR": "%1 %2 de %3 extrait la sous-chaîne des  %5 %4 caratères au %7 %6 caratères",
    "OPERATORS_TEXTS_FOUR_NEW": " extrait de %1 du %3ième caractère %2 au %5ième caractère %4",
    "OPERATORS_TEXTS_NEW": "%1 %2 trouve la position du %5 %3 dans %4",
    "OPERATORS_TEXTS_NEW_OP": "trouve où %1 apparaît dans %2 en %3",
    "OPERATORS_TEXTS_FOUR_ONE": "depuis la gauche",
    "OPERATORS_TEXTS_FOUR_TWO": "depuis la droite",
    "OPERATORS_TEXTS_FOUR_THREE": "depuis la gauche",
    "OPERATORS_TEXTS_FOUR_FOUR": "depuis la droite",
    "OPERATORS_TEXTS_FOUR_F": "premier",
    "OPERATORS_TEXTS_FOUR_L": "dernier",
    "OPERATORS_INPUTSTRING": "%1 %2 string %3",
    "OPERATORS_TRANSINTOTEXT": "%1 %2 transform into %3",
    "OPERATORS_TRANSINTOTEXT_ADD": "add",
    "OPERATORS_TRANSINTOTEXT_ADD_ITEM": "item",
    "OPERATORS_FORMATSTRING2F": "%1 %2 format string %3 % %4",
    "OPERATORS_FORMATSTRING3": "%1 %2 format string %3 format %4",
    "OPERATORS_TEXT_ESCAPE": "%1 %2 text escape character %3",
    "BAR_N": "\\n",
    "BAR_NN": "\\n\\r",
    "BAR_R": "\\r",
    "BAR_RN": "\\r\\n",
    "BAR_B": "\\b",
    "BAR_T": "\\t",
    "BAR_BAR": "\\\\",
    "OPERATORS_TEXT_NUMORLETTER": "%1 %2 %3 %4?",
    "OPERATORS_TEXT_NUMORLETTER_NUM": "is number",
    "OPERATORS_TEXT_NUMORLETTER_LETTER": "is letter",
    "OPERATORS_TEXT_LENGTH": "%1 %2 %3 length",
    "OPERATORS_TEXT_ISNONE": "%1 %2 %3 is none?",
    "OPERATORS_FROMTEXT_GETCHAR": "%1 %2 in text %3 %4",
    "OPERATORS_FROMTEXT_GETCHAR_FIRST": "first letter",
    "OPERATORS_FROMTEXT_GETCHAR_LAST": "last letter",
    "OPERATORS_FROMTEXT_GETCHAR_RANDOM": "random letter",
    "OPERATORS_TEXT_UPPLOW": "%1 %2 %3 %4",
    "OPERATORS_TEXT_UPPLOW_UPP": "uppercase",
    "OPERATORS_TEXT_UPPLOW_LOW": "lowercase",
    "OPERATORS_TEXT_SIDES_SPACE": "%1 %2 %3 %4",
    "OPERATORS_TEXT_SIDES_SPACE_BOTH": "clear spaces on both sides",
    "OPERATORS_TEXT_SIDES_SPACE_LEFT": "clear left space",
    "OPERATORS_TEXT_SIDES_SPACE_RIGHT": "clear right space",
    "OPERATORS_TEXT_STRTOBYTE": "%1 %2 turn byte %3",
    "OPERATORS_TEXT_BYTETOSTR": "%1 %2 byte %3 convert to string",
    "OPERATORS_TEXT_DICTTOJSONSTR": "%1 %2 dict %3 transform into json string",
    "OPERATORS_TEXT_PARSEJSONSTR": "%1 %2 parse json string %3 and return object",
    "OPERATORS_STRINGTONUMBERA": "%1 %2 convertis la chaîne %3 en %4",
    "OPERATORS_NUMTOASCIIA": "%1 %2 convertis le nombre %3 en caractères ASCII",
    "OPERATORS_STRINGTOASCIIA": "%1 %2 convertis le caractère %3 en une valeur ASCII",
    "OPERATORS_NUMTOSTRINGA": "%1 %2 convertis le nombre %3 en chaîne",
    "NEW_MC": "créer une variable micropython",
    "RENAME_MCNUMBER_TITLE": "renommez toutes les variables dont le nom de variable est \"%1\" en:",
    "RENAME_MCNUMBER_MODAL_TITLE": "renommer la variable",
    "RENAME_VARIABLE_MCNUMBER": "renommer la variable",
    "DELETE_VARIABLE_MCNUMBER": "supprimer la variable \"%1\"",
    "SET_MC_VARIABLE": "fixer %1 à %2",
    "ALIYUN": "Aliyun",
    "ONENET": "OneNet",
    "ONENETNEW": "OneNet(New)",
    "EASYIOT": "Easy IoT",
    "SHANGHAI": "shanghai",
    "QINGDAO": "qingdao",
    "BEIJING": "beijing",
    "ZHANGJIAKOU": "zhangjiakou",
    "HUHEHAOTE": "huhehaote",
    "HANGZHOU": "hangzhou",
    "SHENZHEN": "shenzhen",
    "HONGKONG": "HongKong",
    "SINGAPORE": "singapore",
    "SYDNEY": "Sydney",
    "KUALALUMPUR": "Luala Lumpur",
    "JAKARTA": "Jakarta",
    "MUMBAI": "Mumbai",
    "TOKYO": "Tokyo",
    "SILICONVALLEY": "Silicon Valley",
    "VIRGINIA": "Virginia",
    "FRANKFURT": "Frankfurt",
    "LONDON": "London",
    "DUBAI": "Dubai",
    "IOT_PLATFORM": "Iot Platform",
    "PARAMS": "Params",
    "SERVER_ATTR": "Server Addr",
    "PRODUCTID": "ProductId",
    "DEVICEID": "DeviceId",
    "DEVICENAME": "DeviceName",
    "TINYDB_SECRET": "secret",
    "TINYDB_USER": "user",
    "APIADDR": "API Addr",
    "SOFTWARE_SERIAL": "Software Serial",
    "HARDWARE_SERIAL": "Hardware Serial",
    "HARDWARE_SERIAL1": "Hardware Serial1",
    "HARDWARE_SERIAL2": "Hardware Serial2",
    "HARDWARE_SERIAL3": "Hardware Serial3",
    "CHECKTYPE_TIPS": "The data type received by the input box does not match the output type of the building block",
    "HIGHLIGHT_BLOCK": "hight light block",
    "HIGHLIGHT_ALL_CONFLICT_BLOCKS": "highlight all blocks with warnings",
    "SNAPSHOT": "Snapshot (CTRL+G)",
    "pictureaiPath": "please enter the picture path or URL",
    "pictureaiType": "account type",
    "pictureaiAccountDefault": "default account",
    "pictureaiAccountBaidu": "baidu account",
    "pictureaiwebImgAddr": "web image address",
    "MITRIXICON_EDIT": "light up",
    "MITRIXICON_ERASER": "eraser",
    "MITRIXICON_DUSTBIN": "clear",
    "MITRIXICON_REVERSE": "reverse",
    "MITRIXICON_SAVE": "save",
    "MITRIXICON_EXPORT": "export",
    "MITRIXICON_UPLOAD": "upload",
    "ROLL_MOUSE": "Rolling mouse",
    "MOUSE_ZOOM_IN_OUT": "Scroll the mouse to zoom in and out",
    "UPLOAD_CORRECT_JSON_FILE": "Please upload the json file in the correct format",
    "EXPORT_PROMPT": "There are no custom patterns to export. Please click the save button in the lower right corner to save the custom pattern before attempting to export.",
    "HEART": "Heart",
    "HEART_SMALL": "Small heart",
    "HAPPY": "Happy",
    "SMILE": "Smile",
    "SAD": "Sad",
    "CONFUSED": "Confused",
    "ANGRY": "Angry",
    "SLEEP": "Sleep",
    "SURPRISED": "Surprised",
    "SILLY": "Silly",
    "WONDERFUL": "Wonderful",
    "BORED": "Bored",
    "ROCK": "Rock",
    "ROCK_SMALL": "Small rock",
    "PAPER": "Paper",
    "PAPER_SMALL": "Small paper",
    "SCISSORS": "scissors",
    "SCISSORS_SMALL": "Small scissors",
    "DATA_SOLITLIST": "%1 %2 make %3 %4 with delimiter %5",
    "DATA_SOLITLIST_TYPE_LT": "list from text5",
    "DATA_SOLITLIST_TYPE_TL": "text from list",
    "DATA_LENLIST": "%1 %2 %3 is empty?",
    "DATA_LENGTHLIST": "%1 %2 length of %3",
    "DATA_CREATELIST": "%1 %2 create list %3",
    "DATA_CLEARLIST": "%1 %2 clear list %3"
  },
  "ga": {
    "CONTROL_FOREVER": "go deo",
    "CONTROL_REPEAT": "déan %1 uair",
    "CONTROL_IF": "más %1 ansin",
    "CONTROL_ELSE": "seachas sin",
    "CONTROL_STOP": "stop",
    "CONTROL_STOP_ALL": "uile",
    "CONTROL_STOP_THIS": "an script seo",
    "CONTROL_STOP_OTHER": "scripteanna eile sa sprid",
    "CONTROL_WAIT": "fan %1 soicind",
    "CONTROL_WAITUNTIL": "fan go %1",
    "CONTROL_REPEATUNTIL": "athdhéan go %1",
    "CONTROL_WHILE": "chomh fada is %1",
    "CONTROL_FOREACH": "do gach %1 i %2",
    "CONTROL_STARTASCLONE": "nuair a thosaím mar chóip",
    "CONTROL_CREATECLONEOF": "cruthaigh cóip de %1",
    "CONTROL_CREATECLONEOF_MYSELF": "mise féin",
    "CONTROL_DELETETHISCLONE": "scrios an chóip seo",
    "CONTROL_COUNTER": "áiritheoir",
    "CONTROL_INCRCOUNTER": "incrimintigh an t-áiritheoir",
    "CONTROL_CLEARCOUNTER": "glan an t-áiritheoir",
    "CONTROL_ALLATONCE": "d'aon iarraidh",
    "DATA_SETVARIABLETO": "socraigh %1: %2",
    "DATA_CHANGEVARIABLEBY": "athraigh %1 de %2",
    "DATA_SHOWVARIABLE": "taispeáin athróg %1",
    "DATA_HIDEVARIABLE": "folaigh athróg %1",
    "DATA_ADDTOLIST": "cuir %1 le %2",
    "DATA_DELETEOFLIST": "scrios %1 as %2",
    "DATA_DELETEALLOFLIST": "scrios na %1 rud go léir",
    "DATA_INSERTATLIST": "ionsáigh %1 ag %2 i %3",
    "DATA_REPLACEITEMOFLIST": "cuir %3 in áit mír %1 i %2",
    "DATA_ITEMOFLIST": "mír %1 as %2",
    "DATA_ITEMNUMOFLIST": "mír # as %1 in %2",
    "DATA_LENGTHOFLIST": "fad %1",
    "DATA_LISTCONTAINSITEM": "%1 agus %2 ann?",
    "DATA_SHOWLIST": "taispeáin liosta %1",
    "DATA_HIDELIST": "folaigh liosta %1",
    "DATA_INDEX_ALL": "uile",
    "DATA_INDEX_LAST": "deireanach",
    "DATA_INDEX_RANDOM": "randamach",
    "EVENT_WHENFLAGCLICKED": "nuair a chliceáiltear %1",
    "EVENT_WHENTHISSPRITECLICKED": "nuair a chliceáiltear an sprid seo",
    "EVENT_WHENSTAGECLICKED": "nuair a chliceáiltear an stáitse",
    "EVENT_WHENTOUCHINGOBJECT": "nuair a theagmhaíonn an sprid seo le %1",
    "EVENT_WHENBROADCASTRECEIVED": "nuair a fhaighim %1",
    "EVENT_WHENBACKDROPSWITCHESTO": "nuair a chuirtear %1 suas",
    "EVENT_WHENGREATERTHAN": "nuair atá %1 > %2",
    "EVENT_WHENGREATERTHAN_TIMER": "amadóir",
    "EVENT_WHENGREATERTHAN_LOUDNESS": "treise",
    "EVENT_BROADCAST": "craol %1",
    "EVENT_BROADCASTANDWAIT": "craol %1 agus fan",
    "EVENT_WHENKEYPRESSED": "nuair a bhrúitear eochair %1",
    "EVENT_WHENKEYPRESSED_SPACE": "spás",
    "EVENT_WHENKEYPRESSED_LEFT": "saighead chlé",
    "EVENT_WHENKEYPRESSED_RIGHT": "saighead dheas",
    "EVENT_WHENKEYPRESSED_DOWN": "saighead síos",
    "EVENT_WHENKEYPRESSED_UP": "saighead suas",
    "EVENT_WHENKEYPRESSED_ANY": "ceann ar bith",
    "LOOKS_SAYFORSECS": "abair %1 ar feadh %2 soicind",
    "LOOKS_SAY": "abair %1",
    "LOOKS_HELLO": "Dia dhuit!",
    "LOOKS_THINKFORSECS": "smaoinigh %1 ar feadh %2 soicind",
    "LOOKS_THINK": "smaoinigh %1",
    "LOOKS_HMM": "Hmm...",
    "LOOKS_SHOW": "taispeáin",
    "LOOKS_HIDE": "folaigh",
    "LOOKS_HIDEALLSPRITES": "folaigh gach sprid",
    "LOOKS_EFFECT_COLOR": "dath",
    "LOOKS_EFFECT_FISHEYE": "súil an éisc",
    "LOOKS_EFFECT_WHIRL": "rothlú",
    "LOOKS_EFFECT_PIXELATE": "picteilíniú",
    "LOOKS_EFFECT_MOSAIC": "mósáic",
    "LOOKS_EFFECT_BRIGHTNESS": "gile",
    "LOOKS_EFFECT_GHOST": "taibhse",
    "LOOKS_CHANGEEFFECTBY": "athraigh maisíocht %1 de %2",
    "LOOKS_SETEFFECTTO": "socraigh maisíocht %1: %2",
    "LOOKS_CLEARGRAPHICEFFECTS": "glan na maisíochtaí grafaice",
    "LOOKS_CHANGESIZEBY": "athraigh an mhéid de %1",
    "LOOKS_SETSIZETO": "socraigh an mhéid: %1 %",
    "LOOKS_SIZE": "méid",
    "LOOKS_CHANGESTRETCHBY": "athraigh an síneadh de %1",
    "LOOKS_SETSTRETCHTO": "socraigh an síneadh: %1 %",
    "LOOKS_SWITCHCOSTUMETO": "athraigh an chulaith go %1",
    "LOOKS_NEXTCOSTUME": "an chéad chulaith eile",
    "LOOKS_SWITCHBACKDROPTO": "athraigh an cúlra go %1",
    "LOOKS_GOTOFRONTBACK": "téigh go dtí an tsraith %1",
    "LOOKS_GOTOFRONTBACK_FRONT": "thosaigh",
    "LOOKS_GOTOFRONTBACK_BACK": "dheiridh",
    "LOOKS_GOFORWARDBACKWARDLAYERS": "téigh %1 %2 sraith",
    "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "ar aghaidh",
    "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "ar gcúl",
    "LOOKS_BACKDROPNUMBERNAME": "cúlra %1",
    "LOOKS_COSTUMENUMBERNAME": "culaith %1",
    "LOOKS_NUMBERNAME_NUMBER": "uimhir",
    "LOOKS_NUMBERNAME_NAME": "ainm",
    "LOOKS_SWITCHBACKDROPTOANDWAIT": "athraigh an cúlra go %1 agus fan",
    "LOOKS_NEXTBACKDROP_BLOCK": "an chéad chúlra eile",
    "LOOKS_NEXTBACKDROP": "an chéad chúlra eile",
    "LOOKS_PREVIOUSBACKDROP": "cúlra roimhe seo",
    "LOOKS_RANDOMBACKDROP": "cúlra randamach",
    "MOTION_MOVESTEPS": "bog %1 coiscéim",
    "MOTION_TURNLEFT": "rothlaigh %1 %2 céim",
    "MOTION_TURNRIGHT": "rothlaigh %1 %2 céim",
    "MOTION_POINTINDIRECTION": "tabhair aghaidh ar %1",
    "MOTION_POINTTOWARDS": "tabhair aghaidh i dtreo %1",
    "MOTION_POINTTOWARDS_POINTER": "pointeoir-luiche",
    "MOTION_POINTTOWARDS_RANDOM": "treo randamach",
    "MOTION_GOTO": "téigh go %1",
    "MOTION_GOTO_POINTER": "pointeoir-luiche",
    "MOTION_GOTO_RANDOM": "ionad randamach",
    "MOTION_GOTOXY": "téigh go x: %1 y: %2",
    "MOTION_GLIDESECSTOXY": "sleamhnaigh %1 soicind go x: %2 y: %3",
    "MOTION_GLIDETO": "sleamhnaigh %1 soicind go %2",
    "MOTION_GLIDETO_POINTER": "pointeoir-luiche",
    "MOTION_GLIDETO_RANDOM": "ionad randamach",
    "MOTION_CHANGEXBY": "athraigh x de %1",
    "MOTION_SETX": "socraigh x: %1",
    "MOTION_CHANGEYBY": "athraigh y de %1",
    "MOTION_SETY": "socraigh y: %1",
    "MOTION_IFONEDGEBOUNCE": "más ar an imeall, preab",
    "MOTION_SETROTATIONSTYLE": "socraigh an stíl rothlaithe: %1",
    "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "clé-deas",
    "MOTION_SETROTATIONSTYLE_DONTROTATE": "ná rothlaigh",
    "MOTION_SETROTATIONSTYLE_ALLAROUND": "timpeall ar fad",
    "MOTION_XPOSITION": "ionad x",
    "MOTION_YPOSITION": "ionad y",
    "MOTION_DIRECTION": "treo",
    "MOTION_SCROLLRIGHT": "scrollaigh ar dheis %1",
    "MOTION_SCROLLUP": "scrollaigh suas %1",
    "MOTION_ALIGNSCENE": "ailínigh radharc %1",
    "MOTION_ALIGNSCENE_BOTTOMLEFT": "bun ar chlé",
    "MOTION_ALIGNSCENE_BOTTOMRIGHT": "bun ar dheis",
    "MOTION_ALIGNSCENE_MIDDLE": "lár",
    "MOTION_ALIGNSCENE_TOPLEFT": "barr ar chlé",
    "MOTION_ALIGNSCENE_TOPRIGHT": "barr ar dheis",
    "MOTION_XSCROLL": "scrollaigh x",
    "MOTION_YSCROLL": "scrollaigh y",
    "MOTION_STAGE_SELECTED": "Stáitse roghnaithe: gan bloic gluaiseachta",
    "OPERATORS_ADD": "%1 + %2",
    "OPERATORS_SUBTRACT": "%1 - %2",
    "OPERATORS_MULTIPLY": "%1 * %2",
    "OPERATORS_DIVIDE": "%1 / %2",
    "OPERATORS_RANDOM": "uimhir randamach idir %1 agus %2",
    "OPERATORS_GT": "%1 > %2",
    "OPERATORS_GT_EQUALS": "",
    "OPERATORS_LT": "%1 < %2",
    "OPERATORS_LT_EQUALS": "",
    "OPERATORS_EQUALS": "%1 = %2",
    "OPERATORS_AND": "%1 agus %2",
    "OPERATORS_OR": "%1 nó %2",
    "OPERATORS_NOT": "séanadh %1",
    "OPERATORS_JOIN": "ceangail %1 %2",
    "OPERATORS_JOIN_APPLE": "úll",
    "OPERATORS_JOIN_BANANA": "banana",
    "OPERATORS_LETTEROF": "litir %1 de %2",
    "OPERATORS_LETTEROF_APPLE": "ú",
    "OPERATORS_LENGTH": "fad %1",
    "OPERATORS_CONTAINS": "%1 agus %2 ann?",
    "OPERATORS_MOD": "%1 mod %2",
    "OPERATORS_ROUND": "slánaigh %1",
    "OPERATORS_MATHOP": "%1 as %2",
    "OPERATORS_MATHOP_ABS": "abs",
    "OPERATORS_MATHOP_FLOOR": "urlár",
    "OPERATORS_MATHOP_CEILING": "síleáil",
    "OPERATORS_MATHOP_SQRT": "sqrt",
    "OPERATORS_MATHOP_SIN": "sin",
    "OPERATORS_MATHOP_COS": "cos",
    "OPERATORS_MATHOP_TAN": "tan",
    "OPERATORS_MATHOP_ASIN": "asin",
    "OPERATORS_MATHOP_ACOS": "acos",
    "OPERATORS_MATHOP_ATAN": "atan",
    "OPERATORS_MATHOP_LN": "ln",
    "OPERATORS_MATHOP_LOG": "log",
    "OPERATORS_MATHOP_EEXP": "e ^",
    "OPERATORS_MATHOP_10EXP": "10 ^",
    "PROCEDURES_DEFINITION": "sainmhínigh %1",
    "SENSING_TOUCHINGOBJECT": "i dteagmháil le %1?",
    "SENSING_TOUCHINGOBJECT_POINTER": "pointeoir-luiche",
    "SENSING_TOUCHINGOBJECT_EDGE": "ciumhais",
    "SENSING_TOUCHINGCOLOR": "i dteagmháil le dath %1?",
    "SENSING_COLORISTOUCHINGCOLOR": "dath %1 i dteagmháil le %2?",
    "SENSING_DISTANCETO": "fad go dtí %1",
    "SENSING_DISTANCETO_POINTER": "pointeoir-luiche",
    "SENSING_ASKANDWAIT": "fiafraigh %1 agus fan",
    "SENSING_ASK_TEXT": "Cén t-ainm atá ort?",
    "SENSING_ANSWER": "freagra",
    "SENSING_KEYPRESSED": "eochair %1 brúite?",
    "SENSING_MOUSEDOWN": "luchóg síos?",
    "SENSING_MOUSEX": "luchóg x",
    "SENSING_MOUSEY": "luchóg y",
    "SENSING_SETDRAGMODE": "socraigh mód tarraingthe: %1",
    "SENSING_SETDRAGMODE_DRAGGABLE": "intarraingthe",
    "SENSING_SETDRAGMODE_NOTDRAGGABLE": "dotharraingthe",
    "SENSING_LOUDNESS": "treise",
    "SENSING_LOUD": "os ard?",
    "SENSING_TIMER": "amadóir",
    "SENSING_RESETTIMER": "athshocraigh an t-amadóir",
    "SENSING_OF": "%1 as %2",
    "SENSING_OF_XPOSITION": "ionad x",
    "SENSING_OF_YPOSITION": "ionad y",
    "SENSING_OF_DIRECTION": "treo",
    "SENSING_OF_COSTUMENUMBER": "culaith #",
    "SENSING_OF_COSTUMENAME": "ainm na culaithe",
    "SENSING_OF_SIZE": "méid",
    "SENSING_OF_VOLUME": "airde",
    "SENSING_OF_BACKDROPNUMBER": "cúlra #",
    "SENSING_OF_BACKDROPNAME": "ainm an chúlra",
    "SENSING_OF_STAGE": "Stáitse",
    "SENSING_CURRENT": "%1 reatha",
    "SENSING_CURRENT_YEAR": "bliain",
    "SENSING_CURRENT_MONTH": "mí",
    "SENSING_CURRENT_DATE": "dáta",
    "SENSING_CURRENT_DAYOFWEEK": "lá den tseachtain",
    "SENSING_CURRENT_HOUR": "uair",
    "SENSING_CURRENT_MINUTE": "nóiméad",
    "SENSING_CURRENT_SECOND": "soicind",
    "SENSING_DAYSSINCE2000": "laethanta ó 2000",
    "SENSING_USERNAME": "ainm úsáideora",
    "SENSING_USERID": "aitheantas úsáideora",
    "SOUND_PLAY": "seinn fuaim %1",
    "SOUND_PLAYUNTILDONE": "seinn fuaim %1 go dtí a deireadh",
    "SOUND_STOPALLSOUNDS": "stop gach fuaim",
    "SOUND_SETEFFECTO": "socraigh maisíocht %1: %2",
    "SOUND_CHANGEEFFECTBY": "athraigh maisíocht %1 de %2",
    "SOUND_CLEAREFFECTS": "glan na maisíochtaí fuaime",
    "SOUND_EFFECTS_PITCH": "tuinairde",
    "SOUND_EFFECTS_PAN": "peanáil ar chlé/ar dheis",
    "SOUND_CHANGEVOLUMEBY": "athraigh airde na fuaime de %1",
    "SOUND_SETVOLUMETO": "socraigh airde na fuaime: %1%",
    "SOUND_VOLUME": "airde",
    "SOUND_RECORD": "taifead...",
    "CATEGORY_MOTION": "Gluaiseacht",
    "CATEGORY_LOOKS": "Cuma",
    "CATEGORY_SOUND": "Fuaim",
    "CATEGORY_EVENTS": "Teagmhais",
    "CATEGORY_CONTROL": "Rialú",
    "CATEGORY_SENSING": "Brath",
    "CATEGORY_OPERATORS": "Oibreoirí",
    "CATEGORY_VARIABLES": "Athróga",
    "CATEGORY_MYBLOCKS": "Mo Chuid Blocanna",
    "CATEGORY_LISTS": "",
    "CATEGORY_TUPLE": "",
    "CATEGORY_DICTIONARYS": "",
    "CATEGORY_NUMBERS": "",
    "CATEGORY_TEXT": "",
    "DUPLICATE": "Cóipeáil",
    "DELETE": "Scrios",
    "ADD_COMMENT": "Cuir Nóta Tráchta Leis",
    "REMOVE_COMMENT": "Bain an Nóta Tráchta",
    "DELETE_BLOCK": "Scrios an Bloc",
    "DELETE_X_BLOCKS": "Scrios %1 Bloc",
    "DELETE_ALL_BLOCKS": "Scrios na %1 bloc go léir?",
    "CLEAN_UP": "Glan na Blocanna",
    "HELP": "Cabhair",
    "UNDO": "Cealaigh",
    "REDO": "Athdhéan",
    "EDIT_PROCEDURE": "Eagar",
    "SHOW_PROCEDURE_DEFINITION": "Taispeáin an sainmhíniú",
    "WORKSPACE_COMMENT_DEFAULT_TEXT": "Abair rud éigin...",
    "COLOUR_HUE_LABEL": "Dath",
    "COLOUR_SATURATION_LABEL": "Sáithiú",
    "COLOUR_BRIGHTNESS_LABEL": "Gile",
    "CHANGE_VALUE_TITLE": "Athraigh an luach:",
    "RENAME_VARIABLE": "Cuir ainm nua ar an athróg",
    "RENAME_VARIABLE_STRING": "",
    "RENAME_VARIABLE_NUMBER": "",
    "RENAME_VARIABLE_LIST": "",
    "RENAME_VARIABLE_TITLE": "Cuir ainm nua ar gach athróg \"%1\":",
    "RENAME_VARIABLE_MODAL_TITLE": "Cuir Ainm Nua ar an Athróg",
    "NEW_VARIABLE": "Cruthaigh Athróg",
    "NEW_VARIABLE_TITLE": "Ainm na hathróige nua:",
    "VARIABLE_MODAL_TITLE": "Athróg Nua",
    "VARIABLE_ALREADY_EXISTS": "Tá athróg darb ainm \"%1\" ann cheana.",
    "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "Tá athróg darb ainm \"%1\" ann cheana, athróg eile de chineál \"%2\".",
    "DELETE_VARIABLE_CONFIRMATION": "Scrios %1 cóip den athróg \"%2\"?",
    "CANNOT_DELETE_VARIABLE_PROCEDURE": "Ní féidir athróg \"%1\" a scriosadh toisc gur cuid den sainmhíniú ar fheidhm \"%2\" í.",
    "DELETE_VARIABLE": "Scrios an athróg \"%1\"",
    "DELETE_VARIABLE_STRING": "",
    "DELETE_VARIABLE_NUMBER": "",
    "DELETE_VARIABLE_LIST": "",
    "NEW_PROCEDURE": "Cruthaigh Bloc",
    "PROCEDURE_ALREADY_EXISTS": "Tá feidhm darb ainm \"%1\" ann cheana.",
    "PROCEDURE_DEFAULT_NAME": "ainm an bhloic",
    "NEW_LIST": "Cruthaigh Liosta",
    "NEW_LIST_TITLE": "Ainm an liosta nua:",
    "LIST_MODAL_TITLE": "Liosta Nua",
    "LIST_ALREADY_EXISTS": "Tá liosta darb ainm \"%1\" ann cheana.",
    "RENAME_LIST_TITLE": "Cuir ainm nua ar gach liosta \"%1\":",
    "RENAME_LIST_MODAL_TITLE": "Cuir ainm nua ar an liosta",
    "DEFAULT_LIST_ITEM": "rud",
    "DELETE_LIST": "Scrios liosta \"%1\"",
    "RENAME_LIST": "Cuir ainm nua ar an liosta",
    "NEW_BROADCAST_MESSAGE": "Teachtaireacht nua",
    "NEW_BROADCAST_MESSAGE_TITLE": "Ainm na teachtaireachta nua:",
    "BROADCAST_MODAL_TITLE": "Teachtaireacht Nua",
    "DEFAULT_BROADCAST_MESSAGE_NAME": "teachtaireacht1",
    "DELETE_PROCEDURE": "",
    "OK": "",
    "PROMPT": "",
    "STOP": "",
    "NEW_STRING": "",
    "NEW_NUMBER_VARIABLE": "",
    "WIFI": "",
    "USERNAME": "",
    "PWD": "",
    "IOT_SERVICE": "",
    "IOT_ID": "",
    "IOT_PWD": "",
    "IOT_TOPIC": "",
    "MORE_TOPIC": "",
    "OBLOQ_HTTP": "",
    "OBLOQ_IP": "",
    "OBLOQ_PORT": "",
    "ADD_TOPIC": "",
    "DELETE_TOPIC": "",
    "IOT_SERVER": "",
    "CHINA": "",
    "GLOBAL": "",
    "LOW_C": "",
    "LOW_C$": "",
    "LOW_D": "",
    "LOW_D$": "",
    "LOW_E": "",
    "LOW_F": "",
    "LOW_F$": "",
    "LOW_G": "",
    "LOW_G$": "",
    "LOW_A": "",
    "LOW_A$": "",
    "LOW_B": "",
    "MIDDLE_C": "",
    "MIDDLE_C$": "",
    "MIDDLE_D": "",
    "MIDDLE_D$": "",
    "MIDDLE_E": "",
    "MIDDLE_F": "",
    "MIDDLE_F$": "",
    "MIDDLE_G": "",
    "MIDDLE_G$": "",
    "MIDDLE_A": "",
    "MIDDLE_A$": "",
    "MIDDLE_B": "",
    "HIGH_C": "",
    "HIGH_C$": "",
    "HIGH_D": "",
    "HIGH_D$": "",
    "HIGH_E": "",
    "HIGH_F": "",
    "HIGH_F$": "",
    "HIGH_G": "",
    "HIGH_G$": "",
    "HIGH_A": "",
    "HIGH_A$": "",
    "HIGH_B": "",
    "OPERATORS_STRINGTONUMBER": "",
    "INTEGER": "",
    "DECIMAL": "",
    "OPERATORS_NUMTOASCII": "",
    "OPERATORS_STRINGTOASCII": "",
    "OPERATORS_NUMTOSTRING": "",
    "OPERATORS_MAP": "",
    "OPERATORS_CONSTRAIN": "",
    "STEPS_PER_TURN": "",
    "ROTATE_SPEED": "",
    "NEW_AI": "",
    "ASK_AND_PAINT": "",
    "GET_IDENTIFIED_NUM": "",
    "READ_NUM_AND_SAY": "",
    "IMAGE_ADDR": "",
    "IMAGE_PREVIEW": "",
    "IMAGE_OPEN": "",
    "IMAGE_SIZE": "",
    "IMAGE_WIDTH": "",
    "IMAGE_HEIGHT": "",
    "VARIABLE": "",
    "VARIABLE_LIST": "",
    "SET_STRING_VARIABLE": "",
    "STRING_START_WITH": "",
    "DICTIONARY_HEIGHT_FIRST": "",
    "DICTIONARY_HEIGHT_TWO": "",
    "DICTIONARY_HEIGHT_THREE": "",
    "DICTIONARY_HEIGHT_FOUR": "",
    "DATA_MPITEMOFLIST": "",
    "DATA_MPINTERCEPTITEM": "",
    "DATA_MPINSERTATLIST": "",
    "DATA_MPSETVALUEATLIST": "",
    "DATA_MPINSERTATLIST_INSERT": "",
    "DATA_MPINSERTATLIST_CHANGE": "",
    "DATA_MPINSERTATLIST_FOUR": "",
    "DATA_MPINSERTATLIST_FIVE": "",
    "DATA_MPINSERTATLIST_SIX": "",
    "DATA_MPINSERTATLIST_SEVEN": "",
    "DATA_MPINSERTATLIST_EIGHT": "",
    "DATA_MPINSERTATLIST_EIGHT_FALSE": "",
    "DATA_MPINSERTATLIST_EIGHT_TRUE": "",
    "DATA_MPINSERTATLIST_EIGHT_REVERSE": "",
    "OPERATORS_RANDOMA": "",
    "OPERATORS_MODA": "",
    "OPERATORS_ROUNDA": "",
    "OPERATORS_MATHOPA": "",
    "OPERATORS_MATHOP_ABSA": "",
    "OPERATORS_MATHOP_FLOORA": "",
    "OPERATORS_MATHOP_CEILINGA": "",
    "OPERATORS_MATHOP_SQRTA": "",
    "OPERATORS_MATHOP_FIVE": "",
    "OPERATORS_MATHOP_SIX": "",
    "OPERATORS_MATHOP_SEVEN": "",
    "OPERATORS_MATHOP_LENGHT": "",
    "OPERATORS_MATHOP_MAXMIN": "",
    "OPERATORS_MATHOP_MAXMIN_MAX": "",
    "OPERATORS_MATHOP_MAXMIN_MIN": "",
    "OPERATORS_TEXTS_ONE": "",
    "OPERATORS_TEXTS_TWO": "",
    "OPERATORS_TEXTS_THREE": "",
    "OPERATORS_TEXTS_FOUR": "",
    "OPERATORS_TEXTS_FOUR_NEW": "",
    "OPERATORS_TEXTS_NEW": "",
    "OPERATORS_TEXTS_NEW_OP": "",
    "OPERATORS_TEXTS_FOUR_ONE": "",
    "OPERATORS_TEXTS_FOUR_TWO": "",
    "OPERATORS_TEXTS_FOUR_THREE": "",
    "OPERATORS_TEXTS_FOUR_FOUR": "",
    "OPERATORS_TEXTS_FOUR_F": "",
    "OPERATORS_TEXTS_FOUR_L": "",
    "OPERATORS_STRINGTONUMBERA": "",
    "OPERATORS_NUMTOASCIIA": "",
    "OPERATORS_STRINGTOASCIIA": "",
    "OPERATORS_NUMTOSTRINGA": "",
    "NEW_MC": "",
    "RENAME_MCNUMBER_TITLE": "",
    "RENAME_MCNUMBER_MODAL_TITLE": "",
    "RENAME_VARIABLE_MCNUMBER": "",
    "DELETE_VARIABLE_MCNUMBER": "",
    "SET_MC_VARIABLE": "",
    "TUPLE_ONE": "",
    "TUPLE_TWO": "",
    "ALIYUN": "",
    "ONENET": "",
    "EASYIOT": "",
    "SHANGHAI": "",
    "QINGDAO": "",
    "BEIJING": "",
    "ZHANGJIAKOU": "",
    "HUHEHAOTE": "",
    "HANGZHOU": "",
    "SHENZHEN": "",
    "HONGKONG": "",
    "SINGAPORE": "",
    "SYDNEY": "",
    "KUALALUMPUR": "",
    "JAKARTA": "",
    "MUMBAI": "",
    "TOKYO": "",
    "SILICONVALLEY": "",
    "VIRGINIA": "",
    "FRANKFURT": "",
    "LONDON": "",
    "DUBAI": "",
    "IOT_PLATFORM": "",
    "PARAMS": "",
    "SERVER_ATTR": "",
    "PRODUCTID": "",
    "DEVICEID": "",
    "TINYDB_SECRET": "",
    "TINYDB_USER": "",
    "APIADDR": "",
    "SOFTWARE_SERIAL": "",
    "HARDWARE_SERIAL": "",
    "HARDWARE_SERIAL1": "",
    "HARDWARE_SERIAL2": "",
    "HARDWARE_SERIAL3": ""
  },
  "gd": {
    "CONTROL_FOREVER": "gu buan",
    "CONTROL_REPEAT": "dèan seo %1 turas",
    "CONTROL_IF": "ma tha %1 a-rèiste",
    "CONTROL_ELSE": "air neo",
    "CONTROL_STOP": "cuir stad air",
    "CONTROL_STOP_ALL": "na h-uile",
    "CONTROL_STOP_THIS": "an sgriobt seo",
    "CONTROL_STOP_OTHER": "sgriobtaichean eile na sprìde",
    "CONTROL_WAIT": "fan fad %1 diog",
    "CONTROL_WAITUNTIL": "fan gus am bi %1",
    "CONTROL_REPEATUNTIL": "dèan a-rithist e gus am bi %1",
    "CONTROL_WHILE": "fhad ’s a tha %1",
    "CONTROL_FOREACH": "airson gach %1 air %2",
    "CONTROL_STARTASCLONE": "nuair a thèid thu nad chlòn",
    "CONTROL_CREATECLONEOF": "cruthaich clòn %1",
    "CONTROL_CREATECLONEOF_MYSELF": "dhut fhèin",
    "CONTROL_DELETETHISCLONE": "sguab às an clòn seo",
    "CONTROL_COUNTER": "cunntair",
    "CONTROL_INCRCOUNTER": "cunntair ioncramaide",
    "CONTROL_CLEARCOUNTER": "falamhaich an cunntair",
    "CONTROL_ALLATONCE": "a h-uile gin aig an aon àm",
    "DATA_SETVARIABLETO": "suidhich %1 air %2",
    "DATA_CHANGEVARIABLEBY": "atharraich %1 le %2",
    "DATA_SHOWVARIABLE": "seall an caochladair %1",
    "DATA_HIDEVARIABLE": "falaich an caochladair %1",
    "DATA_ADDTOLIST": "cuir %1 ri %2",
    "DATA_DELETEOFLIST": "sguab às %1 de %2",
    "DATA_DELETEALLOFLIST": "sguab às na h-uile gin de %1",
    "DATA_INSERTATLIST": "cuir %1 ris aig %2 de %3",
    "DATA_REPLACEITEMOFLIST": "cuir %3 an àite nì %1 de %2",
    "DATA_ITEMOFLIST": "nì %1 de %2",
    "DATA_ITEMNUMOFLIST": "àireamh an nì %1 air %2",
    "DATA_LENGTHOFLIST": "an fhaide aig %1",
    "DATA_LISTCONTAINSITEM": "%2 am broinn %1",
    "DATA_SHOWLIST": "seall an liosta %1",
    "DATA_HIDELIST": "falaich an liosta %1",
    "DATA_INDEX_ALL": "na h-uile",
    "DATA_INDEX_LAST": "am fear mu dheireadh",
    "DATA_INDEX_RANDOM": "tuaireamach",
    "EVENT_WHENFLAGCLICKED": "le briogadh air %1",
    "EVENT_WHENTHISSPRITECLICKED": "le briogadh air an sprìd seo",
    "EVENT_WHENSTAGECLICKED": "le briogadh air an àrd-ùrlar",
    "EVENT_WHENTOUCHINGOBJECT": "nuair a bhios an sprìd a’ beantainn ri %1",
    "EVENT_WHENBROADCASTRECEIVED": "nuair a gheibh thu %1",
    "EVENT_WHENBACKDROPSWITCHESTO": "nuair a thèid an cùlaibh na %1",
    "EVENT_WHENGREATERTHAN": "nuair a thèid %1 > %2",
    "EVENT_WHENGREATERTHAN_TIMER": "an tìmear",
    "EVENT_WHENGREATERTHAN_LOUDNESS": "àirde na fuaime",
    "EVENT_BROADCAST": "craol %1",
    "EVENT_BROADCASTANDWAIT": "craol %1 is fan",
    "EVENT_WHENKEYPRESSED": "le brùthadh air iuchair %1",
    "EVENT_WHENKEYPRESSED_SPACE": "spàis",
    "EVENT_WHENKEYPRESSED_LEFT": "saighde gu clì",
    "EVENT_WHENKEYPRESSED_RIGHT": "saighde gu deas",
    "EVENT_WHENKEYPRESSED_DOWN": "saighde sìos",
    "EVENT_WHENKEYPRESSED_UP": "saighde suas",
    "EVENT_WHENKEYPRESSED_ANY": "sam bith",
    "LOOKS_SAYFORSECS": "can %1 fad %2 diog",
    "LOOKS_SAY": "can %1",
    "LOOKS_HELLO": "Shin thu!",
    "LOOKS_THINKFORSECS": "smaoinich %1 fad %2 diog",
    "LOOKS_THINK": "smaoinich %1",
    "LOOKS_HMM": "An-dà…",
    "LOOKS_SHOW": "seall",
    "LOOKS_HIDE": "falaich",
    "LOOKS_HIDEALLSPRITES": "cuir gach sprìd am falach",
    "LOOKS_EFFECT_COLOR": "datha",
    "LOOKS_EFFECT_FISHEYE": "sùil èisg",
    "LOOKS_EFFECT_WHIRL": "cuairteige",
    "LOOKS_EFFECT_PIXELATE": "phiogsailean",
    "LOOKS_EFFECT_MOSAIC": "mosàig",
    "LOOKS_EFFECT_BRIGHTNESS": "soilleireachd",
    "LOOKS_EFFECT_GHOST": "taibhse",
    "LOOKS_CHANGEEFFECTBY": "atharraich èifeachd %1 le %2",
    "LOOKS_SETEFFECTTO": "cuir èifeachd %1 de %2 ort",
    "LOOKS_CLEARGRAPHICEFFECTS": "falamhaich na h-èifeachdan",
    "LOOKS_CHANGESIZEBY": "atharraich do mheud le %1",
    "LOOKS_SETSIZETO": "suidhich do mheud air %1 %",
    "LOOKS_SIZE": "meud",
    "LOOKS_CHANGESTRETCHBY": "atharraich an sìneadh le %1",
    "LOOKS_SETSTRETCHTO": "suidhich an sìneadh air %1 %",
    "LOOKS_SWITCHCOSTUMETO": "cuir dreach %1 ort",
    "LOOKS_NEXTCOSTUME": "an t-ath-dhreach",
    "LOOKS_SWITCHBACKDROPTO": "cuir %1 air a’ chùlaibh",
    "LOOKS_GOTOFRONTBACK": "rach gu breath %1",
    "LOOKS_GOTOFRONTBACK_FRONT": "an fhìor-bheulaibh",
    "LOOKS_GOTOFRONTBACK_BACK": "an fhìor-chùlaibh",
    "LOOKS_GOFORWARDBACKWARDLAYERS": "rach %2 breath %1",
    "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "an comhair a’ bheòil",
    "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "an comhair a’ chùil",
    "LOOKS_BACKDROPNUMBERNAME": "%1 a’ chùlaibh",
    "LOOKS_COSTUMENUMBERNAME": "%1 an dreacha",
    "LOOKS_NUMBERNAME_NUMBER": "àireamh",
    "LOOKS_NUMBERNAME_NAME": "ainm",
    "LOOKS_SWITCHBACKDROPTOANDWAIT": "cuir %1 air a’ chùlaibh is fan",
    "LOOKS_NEXTBACKDROP_BLOCK": "an t-ath-chùlaibh",
    "LOOKS_NEXTBACKDROP": "an t-ath-chùlaibh",
    "LOOKS_PREVIOUSBACKDROP": "a’ chùlaibh roimhe",
    "LOOKS_RANDOMBACKDROP": "cùlaibh air thuaiream",
    "MOTION_MOVESTEPS": "gluais le %1 ceum",
    "MOTION_TURNLEFT": "cuairtich %1 le %2 ceum",
    "MOTION_TURNRIGHT": "cuairtich %1 le %2 ceum",
    "MOTION_POINTINDIRECTION": "cuir d’ aghaidh ri %1",
    "MOTION_POINTTOWARDS": "cuir d’ aghaidh an comhair %1",
    "MOTION_POINTTOWARDS_POINTER": "tomhaire na luchaige",
    "MOTION_POINTTOWARDS_RANDOM": "tuaireamach",
    "MOTION_GOTO": "rach gu %1",
    "MOTION_GOTO_POINTER": "tomhaire na luchaige",
    "MOTION_GOTO_RANDOM": "ionad air thuaiream",
    "MOTION_GOTOXY": "rach gu x: %1 y: %2",
    "MOTION_GLIDESECSTOXY": "gluais fad %1 diog gu x: %2 y: %3",
    "MOTION_GLIDETO": "gluais fad %1 diog gu %2",
    "MOTION_GLIDETO_POINTER": "tomhaire na luchaige",
    "MOTION_GLIDETO_RANDOM": "ionad air thuaiream",
    "MOTION_CHANGEXBY": "atharraich x le %1",
    "MOTION_SETX": "suidhich x air %1",
    "MOTION_CHANGEYBY": "atharraich y le %1",
    "MOTION_SETY": "suidhich y air %1",
    "MOTION_IFONEDGEBOUNCE": "ma tha thu ris an oir, boc",
    "MOTION_SETROTATIONSTYLE": "suidhich stoidhle na cuairte air %1",
    "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "clì is deas",
    "MOTION_SETROTATIONSTYLE_DONTROTATE": "gun chuairteachadh",
    "MOTION_SETROTATIONSTYLE_ALLAROUND": "cuairt shlàn",
    "MOTION_XPOSITION": "ionad x",
    "MOTION_YPOSITION": "ionad y",
    "MOTION_DIRECTION": "comhair",
    "MOTION_SCROLLRIGHT": "sgrolaich gu deas le %1",
    "MOTION_SCROLLUP": "sgrolaich suas le %1",
    "MOTION_ALIGNSCENE": "co-thaobhaich an sealladh dhan %1",
    "MOTION_ALIGNSCENE_BOTTOMLEFT": "taobh clì aig a’ bhonn",
    "MOTION_ALIGNSCENE_BOTTOMRIGHT": "taobh deas aig a’ bhonn",
    "MOTION_ALIGNSCENE_MIDDLE": "mheadhan",
    "MOTION_ALIGNSCENE_TOPLEFT": "taobh clì aig a’ bhàrr",
    "MOTION_ALIGNSCENE_TOPRIGHT": "taobh deas aig a’ bhàrr",
    "MOTION_XSCROLL": "sgroladh x",
    "MOTION_YSCROLL": "sgroladh y",
    "MOTION_STAGE_SELECTED": "Chan eil bloca gluasaid an-seo on a thagh thu àrd-ùrlar",
    "OPERATORS_ADD": "%1 + %2",
    "OPERATORS_SUBTRACT": "%1 - %2",
    "OPERATORS_MULTIPLY": "%1 × %2",
    "OPERATORS_DIVIDE": "%1 / %2",
    "OPERATORS_RANDOM": "luach tuaireamach eadar %1 is %2",
    "OPERATORS_GT": "%1 > %2",
    "OPERATORS_GT_EQUALS": "",
    "OPERATORS_LT": "%1 < %2",
    "OPERATORS_LT_EQUALS": "",
    "OPERATORS_EQUALS": "%1 = %2",
    "OPERATORS_AND": "%1 agus %2",
    "OPERATORS_OR": "%1 no %2",
    "OPERATORS_NOT": "e fallsa gu bheil %1",
    "OPERATORS_JOIN": "ceangail %1 ri %2",
    "OPERATORS_JOIN_APPLE": "eòrna",
    "OPERATORS_JOIN_BANANA": "seagal",
    "OPERATORS_LETTEROF": "litir %1 de %2",
    "OPERATORS_LETTEROF_APPLE": "a",
    "OPERATORS_LENGTH": "an fhaide aig %1",
    "OPERATORS_CONTAINS": "%2 am broinn %1",
    "OPERATORS_MOD": "%1 mòidealas %2",
    "OPERATORS_ROUND": "cuairtich %1",
    "OPERATORS_MATHOP": "%1 aig %2",
    "OPERATORS_MATHOP_ABS": "an luach absaloideach",
    "OPERATORS_MATHOP_FLOOR": "an cuairteachadh sìos",
    "OPERATORS_MATHOP_CEILING": "an cuairteachadh suas",
    "OPERATORS_MATHOP_SQRT": "am freumh ceàrnagach",
    "OPERATORS_MATHOP_SIN": "an sìneas",
    "OPERATORS_MATHOP_COS": "an co-shìneas",
    "OPERATORS_MATHOP_TAN": "am beantan",
    "OPERATORS_MATHOP_ASIN": "an t-àrc-shìneas",
    "OPERATORS_MATHOP_ACOS": "an t-àrc-cho-shìneas",
    "OPERATORS_MATHOP_ATAN": "an t-àrc-bheantan",
    "OPERATORS_MATHOP_LN": "an log-àireamh nàdarra",
    "OPERATORS_MATHOP_LOG": "an log-àireamh",
    "OPERATORS_MATHOP_EEXP": "e ^",
    "OPERATORS_MATHOP_10EXP": "10 ^",
    "PROCEDURES_DEFINITION": "mìnich %1",
    "SENSING_TOUCHINGOBJECT": "%1 a’ beantainn riut",
    "SENSING_TOUCHINGOBJECT_POINTER": "tomhaire na luchaige",
    "SENSING_TOUCHINGOBJECT_EDGE": "an oir",
    "SENSING_TOUCHINGCOLOR": "an dath %1 a’ beantainn riut",
    "SENSING_COLORISTOUCHINGCOLOR": "an dath %1 a’ beantainn ri %2",
    "SENSING_DISTANCETO": "an t-astar gu %1",
    "SENSING_DISTANCETO_POINTER": "tomhaire na luchaige",
    "SENSING_ASKANDWAIT": "faighnich %1 is fan",
    "SENSING_ASK_TEXT": "Dè an t-ainm a th’ort?",
    "SENSING_ANSWER": "freagairt",
    "SENSING_KEYPRESSED": "iuchair %1 ga brùthadh",
    "SENSING_MOUSEDOWN": "an luchag ga briogadh",
    "SENSING_MOUSEX": "x na luchaige",
    "SENSING_MOUSEY": "y na luchaige",
    "SENSING_SETDRAGMODE": "suidhich %1 a shlaodadh",
    "SENSING_SETDRAGMODE_DRAGGABLE": "gun gabh",
    "SENSING_SETDRAGMODE_NOTDRAGGABLE": "nach gabh",
    "SENSING_LOUDNESS": "àirde na fuaime",
    "SENSING_LOUD": "an fhuaim air",
    "SENSING_TIMER": "an tìmear",
    "SENSING_RESETTIMER": "ath-shuidhich an tìmear",
    "SENSING_OF": "%1 aig %2",
    "SENSING_OF_XPOSITION": "ionad x",
    "SENSING_OF_YPOSITION": "ionad y",
    "SENSING_OF_DIRECTION": "comhair",
    "SENSING_OF_COSTUMENUMBER": "àireamh an dreacha",
    "SENSING_OF_COSTUMENAME": "ainm an dreacha",
    "SENSING_OF_SIZE": "meud",
    "SENSING_OF_VOLUME": "àirde na fuaime",
    "SENSING_OF_BACKDROPNUMBER": "àireamh a’ chùlaibh",
    "SENSING_OF_BACKDROPNAME": "ainm a’ chùlaibh",
    "SENSING_OF_STAGE": "Àrd-ùrlar",
    "SENSING_CURRENT": "%1 làithreach",
    "SENSING_CURRENT_YEAR": "am bliadhna",
    "SENSING_CURRENT_MONTH": "am mìos",
    "SENSING_CURRENT_DATE": "an ceann-là",
    "SENSING_CURRENT_DAYOFWEEK": "latha na seachdaine",
    "SENSING_CURRENT_HOUR": "an uair",
    "SENSING_CURRENT_MINUTE": "a’ mhionaid",
    "SENSING_CURRENT_SECOND": "an diog",
    "SENSING_DAYSSINCE2000": "làithean o 2000 a-mach",
    "SENSING_USERNAME": "ainm-cleachdaiche",
    "SENSING_USERID": "ID a’ chleachdaiche",
    "SOUND_PLAY": "tòisich air fuaim %1 a chluich",
    "SOUND_PLAYUNTILDONE": "cluich fuaim %1 gu lèir",
    "SOUND_STOPALLSOUNDS": "cuir stad air a h-uile fuaim",
    "SOUND_SETEFFECTO": "cuir èifeachd %1 de %2 ort",
    "SOUND_CHANGEEFFECTBY": "atharraich èifeachd %1 le %2",
    "SOUND_CLEAREFFECTS": "falamhaich èifeachdan na fuaime",
    "SOUND_EFFECTS_PITCH": "gleusa",
    "SOUND_EFFECTS_PAN": "panachaidh gu clì/deas",
    "SOUND_CHANGEVOLUMEBY": "atharraich àirde na fuaime le %1",
    "SOUND_SETVOLUMETO": "suidhich àirde na fuaime air %1%",
    "SOUND_VOLUME": "àirde na fuaime",
    "SOUND_RECORD": "clàraich...",
    "CATEGORY_MOTION": "Gluasad",
    "CATEGORY_LOOKS": "Coltas",
    "CATEGORY_SOUND": "Fuaim",
    "CATEGORY_EVENTS": "Tachartas",
    "CATEGORY_CONTROL": "Smachd",
    "CATEGORY_SENSING": "Mothachadh",
    "CATEGORY_OPERATORS": "Gnìomharaiche",
    "CATEGORY_VARIABLES": "Caochladairean",
    "CATEGORY_MYBLOCKS": "Bloca agamsa",
    "CATEGORY_LISTS": "",
    "CATEGORY_TUPLE": "",
    "CATEGORY_DICTIONARYS": "",
    "CATEGORY_NUMBERS": "",
    "CATEGORY_TEXT": "",
    "DUPLICATE": "Dùblaich",
    "DELETE": "Sguab às",
    "ADD_COMMENT": "Cuir beachd ris",
    "REMOVE_COMMENT": "Thoir am beachd air falbh",
    "DELETE_BLOCK": "Sguab às am bloca",
    "DELETE_X_BLOCKS": "Sguab às %1 bloca(ichean)",
    "DELETE_ALL_BLOCKS": "A bheil thu airson na %1 bloca(ichean) uile a sguabadh às?",
    "CLEAN_UP": "Sgioblaich na blocaichean",
    "HELP": "Cobhair",
    "UNDO": "Neo-dhèan",
    "REDO": "Ath-dhèan",
    "EDIT_PROCEDURE": "Deasaich",
    "SHOW_PROCEDURE_DEFINITION": "Rach dhan mhìneachadh",
    "WORKSPACE_COMMENT_DEFAULT_TEXT": "Can rudeigin…",
    "COLOUR_HUE_LABEL": "Dath",
    "COLOUR_SATURATION_LABEL": "Sàthachd",
    "COLOUR_BRIGHTNESS_LABEL": "Soilleireachd",
    "CHANGE_VALUE_TITLE": "Atharraich an luach:",
    "RENAME_VARIABLE": "Thoir ainm ùr air a’ chaochladair",
    "RENAME_VARIABLE_STRING": "",
    "RENAME_VARIABLE_NUMBER": "",
    "RENAME_VARIABLE_LIST": "",
    "RENAME_VARIABLE_TITLE": "Thoir ainm ùr air gach caochladair “%1”:",
    "RENAME_VARIABLE_MODAL_TITLE": "Thoir ainm ùr air a’ chaochladair",
    "NEW_VARIABLE": "Cruthaich caochladair",
    "NEW_VARIABLE_TITLE": "Ainm ùr a’ chaochladair:",
    "VARIABLE_MODAL_TITLE": "Caochladair ùr",
    "VARIABLE_ALREADY_EXISTS": "Tha caochladair air a bheil “%1” ann mu thràth.",
    "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "Tha caochladair air a bheil “%1” ann mu thràth airson caochladair eile dhen t-seòrsa “%2”.",
    "DELETE_VARIABLE_CONFIRMATION": "A bheil thu airson a sguabadh às dha %1 chleachdadh/cleachdaidhean dhen chaochladair “%2”?",
    "CANNOT_DELETE_VARIABLE_PROCEDURE": "Chan urrainn dhuinn an caochladair “%1” a sguabadh às on a e am broinn mìneachadh an fhoincsein “%2”",
    "DELETE_VARIABLE": "Sguab às dha “%1”",
    "DELETE_VARIABLE_STRING": "",
    "DELETE_VARIABLE_NUMBER": "",
    "DELETE_VARIABLE_LIST": "",
    "NEW_PROCEDURE": "Cruthaich bloca",
    "PROCEDURE_ALREADY_EXISTS": "Tha modh air a bheil “%1” ann mu thràth.",
    "PROCEDURE_DEFAULT_NAME": "ainm a’ bhloca",
    "NEW_LIST": "Cruthaich liosta",
    "NEW_LIST_TITLE": "Ainm na liosta ùire:",
    "LIST_MODAL_TITLE": "Liosta ùr",
    "LIST_ALREADY_EXISTS": "Tha liosta air a bheil “%1” ann mu thràth.",
    "RENAME_LIST_TITLE": "Thoir ainm ùr air gach liosta “%1”:",
    "RENAME_LIST_MODAL_TITLE": "Thoir ainm ùr air an liosta",
    "DEFAULT_LIST_ITEM": "nì",
    "DELETE_LIST": "Sguab às dhan liosta “%1”",
    "RENAME_LIST": "Thoir ainm ùr air an liosta",
    "NEW_BROADCAST_MESSAGE": "Teachdaireachd ùr",
    "NEW_BROADCAST_MESSAGE_TITLE": "Ainm na teachdaireachd ùire:",
    "BROADCAST_MODAL_TITLE": "teachdaireachd ùr",
    "DEFAULT_BROADCAST_MESSAGE_NAME": "teachdaireachd1",
    "DELETE_PROCEDURE": "",
    "OK": "",
    "PROMPT": "",
    "STOP": "",
    "NEW_STRING": "",
    "NEW_NUMBER_VARIABLE": "",
    "WIFI": "",
    "USERNAME": "",
    "PWD": "",
    "IOT_SERVICE": "",
    "IOT_ID": "",
    "IOT_PWD": "",
    "IOT_TOPIC": "",
    "MORE_TOPIC": "",
    "OBLOQ_HTTP": "",
    "OBLOQ_IP": "",
    "OBLOQ_PORT": "",
    "ADD_TOPIC": "",
    "DELETE_TOPIC": "",
    "IOT_SERVER": "",
    "CHINA": "",
    "GLOBAL": "",
    "LOW_C": "",
    "LOW_C$": "",
    "LOW_D": "",
    "LOW_D$": "",
    "LOW_E": "",
    "LOW_F": "",
    "LOW_F$": "",
    "LOW_G": "",
    "LOW_G$": "",
    "LOW_A": "",
    "LOW_A$": "",
    "LOW_B": "",
    "MIDDLE_C": "",
    "MIDDLE_C$": "",
    "MIDDLE_D": "",
    "MIDDLE_D$": "",
    "MIDDLE_E": "",
    "MIDDLE_F": "",
    "MIDDLE_F$": "",
    "MIDDLE_G": "",
    "MIDDLE_G$": "",
    "MIDDLE_A": "",
    "MIDDLE_A$": "",
    "MIDDLE_B": "",
    "HIGH_C": "",
    "HIGH_C$": "",
    "HIGH_D": "",
    "HIGH_D$": "",
    "HIGH_E": "",
    "HIGH_F": "",
    "HIGH_F$": "",
    "HIGH_G": "",
    "HIGH_G$": "",
    "HIGH_A": "",
    "HIGH_A$": "",
    "HIGH_B": "",
    "OPERATORS_STRINGTONUMBER": "",
    "INTEGER": "",
    "DECIMAL": "",
    "OPERATORS_NUMTOASCII": "",
    "OPERATORS_STRINGTOASCII": "",
    "OPERATORS_NUMTOSTRING": "",
    "OPERATORS_MAP": "",
    "OPERATORS_CONSTRAIN": "",
    "STEPS_PER_TURN": "",
    "ROTATE_SPEED": "",
    "NEW_AI": "",
    "ASK_AND_PAINT": "",
    "GET_IDENTIFIED_NUM": "",
    "READ_NUM_AND_SAY": "",
    "IMAGE_ADDR": "",
    "IMAGE_PREVIEW": "",
    "IMAGE_OPEN": "",
    "IMAGE_SIZE": "",
    "IMAGE_WIDTH": "",
    "IMAGE_HEIGHT": "",
    "VARIABLE": "",
    "VARIABLE_LIST": "",
    "SET_STRING_VARIABLE": "",
    "STRING_START_WITH": "",
    "DICTIONARY_HEIGHT_FIRST": "",
    "DICTIONARY_HEIGHT_TWO": "",
    "DICTIONARY_HEIGHT_THREE": "",
    "DICTIONARY_HEIGHT_FOUR": "",
    "DATA_MPITEMOFLIST": "",
    "DATA_MPINTERCEPTITEM": "",
    "DATA_MPINSERTATLIST": "",
    "DATA_MPSETVALUEATLIST": "",
    "DATA_MPINSERTATLIST_INSERT": "",
    "DATA_MPINSERTATLIST_CHANGE": "",
    "DATA_MPINSERTATLIST_FOUR": "",
    "DATA_MPINSERTATLIST_FIVE": "",
    "DATA_MPINSERTATLIST_SIX": "",
    "DATA_MPINSERTATLIST_SEVEN": "",
    "DATA_MPINSERTATLIST_EIGHT": "",
    "DATA_MPINSERTATLIST_EIGHT_FALSE": "",
    "DATA_MPINSERTATLIST_EIGHT_TRUE": "",
    "DATA_MPINSERTATLIST_EIGHT_REVERSE": "",
    "OPERATORS_RANDOMA": "",
    "OPERATORS_MODA": "",
    "OPERATORS_ROUNDA": "",
    "OPERATORS_MATHOPA": "",
    "OPERATORS_MATHOP_ABSA": "",
    "OPERATORS_MATHOP_FLOORA": "",
    "OPERATORS_MATHOP_CEILINGA": "",
    "OPERATORS_MATHOP_SQRTA": "",
    "OPERATORS_MATHOP_FIVE": "",
    "OPERATORS_MATHOP_SIX": "",
    "OPERATORS_MATHOP_SEVEN": "",
    "OPERATORS_MATHOP_LENGHT": "",
    "OPERATORS_MATHOP_MAXMIN": "",
    "OPERATORS_MATHOP_MAXMIN_MAX": "",
    "OPERATORS_MATHOP_MAXMIN_MIN": "",
    "OPERATORS_TEXTS_ONE": "",
    "OPERATORS_TEXTS_TWO": "",
    "OPERATORS_TEXTS_THREE": "",
    "OPERATORS_TEXTS_FOUR": "",
    "OPERATORS_TEXTS_FOUR_NEW": "",
    "OPERATORS_TEXTS_NEW": "",
    "OPERATORS_TEXTS_NEW_OP": "",
    "OPERATORS_TEXTS_FOUR_ONE": "",
    "OPERATORS_TEXTS_FOUR_TWO": "",
    "OPERATORS_TEXTS_FOUR_THREE": "",
    "OPERATORS_TEXTS_FOUR_FOUR": "",
    "OPERATORS_TEXTS_FOUR_F": "",
    "OPERATORS_TEXTS_FOUR_L": "",
    "OPERATORS_STRINGTONUMBERA": "",
    "OPERATORS_NUMTOASCIIA": "",
    "OPERATORS_STRINGTOASCIIA": "",
    "OPERATORS_NUMTOSTRINGA": "",
    "NEW_MC": "",
    "RENAME_MCNUMBER_TITLE": "",
    "RENAME_MCNUMBER_MODAL_TITLE": "",
    "RENAME_VARIABLE_MCNUMBER": "",
    "DELETE_VARIABLE_MCNUMBER": "",
    "SET_MC_VARIABLE": "",
    "TUPLE_ONE": "",
    "TUPLE_TWO": "",
    "ALIYUN": "",
    "ONENET": "",
    "EASYIOT": "",
    "SHANGHAI": "",
    "QINGDAO": "",
    "BEIJING": "",
    "ZHANGJIAKOU": "",
    "HUHEHAOTE": "",
    "HANGZHOU": "",
    "SHENZHEN": "",
    "HONGKONG": "",
    "SINGAPORE": "",
    "SYDNEY": "",
    "KUALALUMPUR": "",
    "JAKARTA": "",
    "MUMBAI": "",
    "TOKYO": "",
    "SILICONVALLEY": "",
    "VIRGINIA": "",
    "FRANKFURT": "",
    "LONDON": "",
    "DUBAI": "",
    "IOT_PLATFORM": "",
    "PARAMS": "",
    "SERVER_ATTR": "",
    "PRODUCTID": "",
    "DEVICEID": "",
    "TINYDB_SECRET": "",
    "TINYDB_USER": "",
    "APIADDR": "",
    "SOFTWARE_SERIAL": "",
    "HARDWARE_SERIAL": "",
    "HARDWARE_SERIAL1": "",
    "HARDWARE_SERIAL2": "",
    "HARDWARE_SERIAL3": ""
  },
  "gl": {
    "CONTROL_FOREVER": "para sempre",
    "CONTROL_REPEAT": "repetir %1",
    "CONTROL_IF": "se %1 entón",
    "CONTROL_ELSE": "se non",
    "CONTROL_STOP": "parar.",
    "CONTROL_STOP_ALL": "todos",
    "CONTROL_STOP_THIS": "este programa",
    "CONTROL_STOP_OTHER": "outros programas na figura",
    "CONTROL_WAIT": "agardar %1 segundos",
    "CONTROL_WAITUNTIL": "agardar até %1",
    "CONTROL_REPEATUNTIL": "repetir até %1",
    "CONTROL_WHILE": "mentres %1",
    "CONTROL_FOREACH": "para cada %1 en %2",
    "CONTROL_STARTASCLONE": "Ao comezar como clon",
    "CONTROL_CREATECLONEOF": "crear un clon de %1",
    "CONTROL_CREATECLONEOF_MYSELF": "min mesmo",
    "CONTROL_DELETETHISCLONE": "eliminar este clon.",
    "CONTROL_COUNTER": "contador",
    "CONTROL_INCRCOUNTER": "incrementar o contador",
    "CONTROL_CLEARCOUNTER": "limpar o contador",
    "CONTROL_ALLATONCE": "todo de vez",
    "DATA_SETVARIABLETO": "asignar %2 a %1",
    "DATA_CHANGEVARIABLEBY": "sumarlle %2 a %1",
    "DATA_SHOWVARIABLE": "amosar a variábel %1",
    "DATA_HIDEVARIABLE": "agochar a variábel %1",
    "DATA_ADDTOLIST": "engadir %1 a %2",
    "DATA_DELETEOFLIST": "eliminar %1 de %2",
    "DATA_DELETEALLOFLIST": "eliminar todo o contido de %1",
    "DATA_INSERTATLIST": "inserir %1 na posición %2 de %3",
    "DATA_REPLACEITEMOFLIST": "substituír o elemento %1 de %2 por %3",
    "DATA_ITEMOFLIST": "elemento %1 de %2",
    "DATA_ITEMNUMOFLIST": "posición de %2 que contén %1",
    "DATA_LENGTHOFLIST": "lonxitude de %1",
    "DATA_LISTCONTAINSITEM": "%1 contén %2",
    "DATA_SHOWLIST": "amosar a lista %1",
    "DATA_HIDELIST": "agochar a lista %1",
    "DATA_INDEX_ALL": "todos",
    "DATA_INDEX_LAST": "último",
    "DATA_INDEX_RANDOM": "ao chou",
    "EVENT_WHENFLAGCLICKED": "Ao premer en %1",
    "EVENT_WHENTHISSPRITECLICKED": "Ao premer nesta figura",
    "EVENT_WHENSTAGECLICKED": "Ao premer no escenario",
    "EVENT_WHENTOUCHINGOBJECT": "Cando esta figura toque %1",
    "EVENT_WHENBROADCASTRECEIVED": "Ao recibir %1",
    "EVENT_WHENBACKDROPSWITCHESTO": "Ao mudar o fondo a %1",
    "EVENT_WHENGREATERTHAN": "Cando %1 > %2",
    "EVENT_WHENGREATERTHAN_TIMER": "cronómetro",
    "EVENT_WHENGREATERTHAN_LOUDNESS": "volume do micrófono",
    "EVENT_BROADCAST": "emitir %1",
    "EVENT_BROADCASTANDWAIT": "emitir %1 e agardar",
    "EVENT_WHENKEYPRESSED": "Ao premer a tecla %1",
    "EVENT_WHENKEYPRESSED_SPACE": "espazo",
    "EVENT_WHENKEYPRESSED_LEFT": "frecha esquerda",
    "EVENT_WHENKEYPRESSED_RIGHT": "frecha dereita",
    "EVENT_WHENKEYPRESSED_DOWN": "frecha abaixo",
    "EVENT_WHENKEYPRESSED_UP": "frecha arriba",
    "EVENT_WHENKEYPRESSED_ANY": "calquera",
    "LOOKS_SAYFORSECS": "dicir %1 durante %2 segundos",
    "LOOKS_SAY": "dicir %1",
    "LOOKS_HELLO": "Ola!",
    "LOOKS_THINKFORSECS": "pensar %1 durante %2 segundos",
    "LOOKS_THINK": "pensar %1",
    "LOOKS_HMM": "Mmm...",
    "LOOKS_SHOW": "amosarse",
    "LOOKS_HIDE": "agocharse",
    "LOOKS_HIDEALLSPRITES": "agochar todas as figuras",
    "LOOKS_EFFECT_COLOR": "cor",
    "LOOKS_EFFECT_FISHEYE": "ollo de peixe",
    "LOOKS_EFFECT_WHIRL": "remuiño",
    "LOOKS_EFFECT_PIXELATE": "pixelar",
    "LOOKS_EFFECT_MOSAIC": "mosaico",
    "LOOKS_EFFECT_BRIGHTNESS": "luminosidade",
    "LOOKS_EFFECT_GHOST": "desaparecer",
    "LOOKS_CHANGEEFFECTBY": "sumar %2 ao efecto %1",
    "LOOKS_SETEFFECTTO": "aplicar o efecto %1 co valor %2",
    "LOOKS_CLEARGRAPHICEFFECTS": "limpar os efectos gráficos",
    "LOOKS_CHANGESIZEBY": "sumar %1 ao tamaño",
    "LOOKS_SETSIZETO": "pór o tamaño ao %1 %",
    "LOOKS_SIZE": "tamaño",
    "LOOKS_CHANGESTRETCHBY": "sumar %1 ao estiramento",
    "LOOKS_SETSTRETCHTO": "pór o estiramento no %1 %",
    "LOOKS_SWITCHCOSTUMETO": "mudar vestimenta a %1",
    "LOOKS_NEXTCOSTUME": "vestimenta seguinte",
    "LOOKS_SWITCHBACKDROPTO": "mudar fondo a %1",
    "LOOKS_GOTOFRONTBACK": "ir para a capa %1",
    "LOOKS_GOTOFRONTBACK_FRONT": "por diante",
    "LOOKS_GOTOFRONTBACK_BACK": "por detrás",
    "LOOKS_GOFORWARDBACKWARDLAYERS": "ir %1 %2 capas",
    "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "adiante",
    "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "atrás",
    "LOOKS_BACKDROPNUMBERNAME": "fondo %1",
    "LOOKS_COSTUMENUMBERNAME": "vestimenta %1",
    "LOOKS_NUMBERNAME_NUMBER": "número",
    "LOOKS_NUMBERNAME_NAME": "nome",
    "LOOKS_SWITCHBACKDROPTOANDWAIT": "mudar fondo a %1 e agardar",
    "LOOKS_NEXTBACKDROP_BLOCK": "fondo seguinte",
    "LOOKS_NEXTBACKDROP": "fondo seguinte",
    "LOOKS_PREVIOUSBACKDROP": "fondo anterior",
    "LOOKS_RANDOMBACKDROP": "fondo de pantalla aleatorio",
    "MOTION_MOVESTEPS": "mover %1 pasos",
    "MOTION_TURNLEFT": "xirar %1 %2 graos",
    "MOTION_TURNRIGHT": "xirar %1 %2 graos",
    "MOTION_POINTINDIRECTION": "apuntar na dirección %1",
    "MOTION_POINTTOWARDS": "apuntar cara a %1",
    "MOTION_POINTTOWARDS_POINTER": "punteiro do rato",
    "MOTION_POINTTOWARDS_RANDOM": "dirección aleatoria",
    "MOTION_GOTO": "ir para %1",
    "MOTION_GOTO_POINTER": "punteiro do rato",
    "MOTION_GOTO_RANDOM": "posición aleatoria",
    "MOTION_GOTOXY": "ir para X: %1 Y: %2",
    "MOTION_GLIDESECSTOXY": "esvarar durante %1 seg a X: %2 Y: %3",
    "MOTION_GLIDETO": "esvarar durante %1 seg a %2",
    "MOTION_GLIDETO_POINTER": "punteiro do rato",
    "MOTION_GLIDETO_RANDOM": "posición aleatoria",
    "MOTION_CHANGEXBY": "sumar %1 a X",
    "MOTION_SETX": "asignar a X o valor %1",
    "MOTION_CHANGEYBY": "sumar %1 a Y",
    "MOTION_SETY": "asignar a Y o valor %1",
    "MOTION_IFONEDGEBOUNCE": "se toca un bordo, rebotar",
    "MOTION_SETROTATIONSTYLE": "usar o estilo de rotación %1",
    "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "esquerda-dereita",
    "MOTION_SETROTATIONSTYLE_DONTROTATE": "non rotar",
    "MOTION_SETROTATIONSTYLE_ALLAROUND": "en todas as direccións",
    "MOTION_XPOSITION": "posición en X",
    "MOTION_YPOSITION": "posición en Y",
    "MOTION_DIRECTION": "dirección",
    "MOTION_SCROLLRIGHT": "desprazamento dereita %1",
    "MOTION_SCROLLUP": "desprazamento arriba %1",
    "MOTION_ALIGNSCENE": "aliñar a escena %1",
    "MOTION_ALIGNSCENE_BOTTOMLEFT": "abaixo esquerda",
    "MOTION_ALIGNSCENE_BOTTOMRIGHT": "abaixo dereita",
    "MOTION_ALIGNSCENE_MIDDLE": "medio",
    "MOTION_ALIGNSCENE_TOPLEFT": "arriba esquerda",
    "MOTION_ALIGNSCENE_TOPRIGHT": "arriba dereita",
    "MOTION_XSCROLL": "desprazamento x",
    "MOTION_YSCROLL": "desprazamento Y",
    "MOTION_STAGE_SELECTED": "Escenario seleccionado: sen bloques de movemento",
    "OPERATORS_ADD": "%1 + %2",
    "OPERATORS_SUBTRACT": "%1 – %2",
    "OPERATORS_MULTIPLY": "%1 × %2",
    "OPERATORS_DIVIDE": "%1 / %2",
    "OPERATORS_RANDOM": "número ao chou entre %1 e %2",
    "OPERATORS_GT": "%1 > %2",
    "OPERATORS_GT_EQUALS": "",
    "OPERATORS_LT": "%1 < %2",
    "OPERATORS_LT_EQUALS": "",
    "OPERATORS_EQUALS": "%1 = %2",
    "OPERATORS_AND": "%1 e %2",
    "OPERATORS_OR": "%1 ou %2",
    "OPERATORS_NOT": "non %1",
    "OPERATORS_JOIN": "concatenar %1 %2",
    "OPERATORS_JOIN_APPLE": "mazá",
    "OPERATORS_JOIN_BANANA": "banana",
    "OPERATORS_LETTEROF": "letra %1 de %2",
    "OPERATORS_LETTEROF_APPLE": "a",
    "OPERATORS_LENGTH": "lonxitude de %1",
    "OPERATORS_CONTAINS": "%1 contén %2",
    "OPERATORS_MOD": "%1 módulo %2",
    "OPERATORS_ROUND": "arredondar %1",
    "OPERATORS_MATHOP": "%1 de %2",
    "OPERATORS_MATHOP_ABS": "valor absoluto",
    "OPERATORS_MATHOP_FLOOR": "chan",
    "OPERATORS_MATHOP_CEILING": "teito",
    "OPERATORS_MATHOP_SQRT": "raíz cadrada",
    "OPERATORS_MATHOP_SIN": "seno",
    "OPERATORS_MATHOP_COS": "coseno",
    "OPERATORS_MATHOP_TAN": "tanxente",
    "OPERATORS_MATHOP_ASIN": "arco seno",
    "OPERATORS_MATHOP_ACOS": "arco coseno",
    "OPERATORS_MATHOP_ATAN": "arco tanxente",
    "OPERATORS_MATHOP_LN": "ln",
    "OPERATORS_MATHOP_LOG": "log",
    "OPERATORS_MATHOP_EEXP": "e ^",
    "OPERATORS_MATHOP_10EXP": "10 ^",
    "PROCEDURES_DEFINITION": "definir %1",
    "SENSING_TOUCHINGOBJECT": "toca %1",
    "SENSING_TOUCHINGOBJECT_POINTER": "punteiro do rato",
    "SENSING_TOUCHINGOBJECT_EDGE": "bordo",
    "SENSING_TOUCHINGCOLOR": "toca na cor %1",
    "SENSING_COLORISTOUCHINGCOLOR": "a cor %1 toca %2",
    "SENSING_DISTANCETO": "distancia a %1",
    "SENSING_DISTANCETO_POINTER": "punteiro do rato",
    "SENSING_ASKANDWAIT": "preguntar %1 e agardar",
    "SENSING_ASK_TEXT": "Como te chamas?",
    "SENSING_ANSWER": "resposta",
    "SENSING_KEYPRESSED": "a tecla %1 está premida",
    "SENSING_MOUSEDOWN": "o rato está premido",
    "SENSING_MOUSEX": "posición en X do rato",
    "SENSING_MOUSEY": "posición en Y do rato",
    "SENSING_SETDRAGMODE": "usar o modo de arrastre %1",
    "SENSING_SETDRAGMODE_DRAGGABLE": "arrastrábel",
    "SENSING_SETDRAGMODE_NOTDRAGGABLE": "non arrastrábel",
    "SENSING_LOUDNESS": "volume no micrófono",
    "SENSING_LOUD": "forte?",
    "SENSING_TIMER": "cronómetro",
    "SENSING_RESETTIMER": "reiniciar o cronómetro",
    "SENSING_OF": "%1 de %2",
    "SENSING_OF_XPOSITION": "posición en X",
    "SENSING_OF_YPOSITION": "posición en Y",
    "SENSING_OF_DIRECTION": "dirección",
    "SENSING_OF_COSTUMENUMBER": "vestimenta n.º",
    "SENSING_OF_COSTUMENAME": "nome da vestimenta",
    "SENSING_OF_SIZE": "tamaño",
    "SENSING_OF_VOLUME": "volume",
    "SENSING_OF_BACKDROPNUMBER": "fondo n.º",
    "SENSING_OF_BACKDROPNAME": "nome do fondo",
    "SENSING_OF_STAGE": "Escenario",
    "SENSING_CURRENT": "%1 actual",
    "SENSING_CURRENT_YEAR": "ano",
    "SENSING_CURRENT_MONTH": "mes",
    "SENSING_CURRENT_DATE": "data",
    "SENSING_CURRENT_DAYOFWEEK": "día da semana",
    "SENSING_CURRENT_HOUR": "hora",
    "SENSING_CURRENT_MINUTE": "minuto",
    "SENSING_CURRENT_SECOND": "segundo",
    "SENSING_DAYSSINCE2000": "días desde o 2000",
    "SENSING_USERNAME": "nome de usuario",
    "SENSING_USERID": "identificador de usuario",
    "SOUND_PLAY": "iniciar son %1",
    "SOUND_PLAYUNTILDONE": "reproducir %1 até rematar",
    "SOUND_STOPALLSOUNDS": "deter todos os sons",
    "SOUND_SETEFFECTO": "aplicar o efecto %1 como %2",
    "SOUND_CHANGEEFFECTBY": "sumar ao efecto %1 o valor %2",
    "SOUND_CLEAREFFECTS": "limpar os efectos de son",
    "SOUND_EFFECTS_PITCH": "ton",
    "SOUND_EFFECTS_PAN": "canle esquerda/dereita",
    "SOUND_CHANGEVOLUMEBY": "subir o volume en %1",
    "SOUND_SETVOLUMETO": "pór o volume no %1%",
    "SOUND_VOLUME": "volume",
    "SOUND_RECORD": "gravar...",
    "CATEGORY_MOTION": "Movemento",
    "CATEGORY_LOOKS": "Aparencia",
    "CATEGORY_SOUND": "Son",
    "CATEGORY_EVENTS": "Eventos",
    "CATEGORY_CONTROL": "Control",
    "CATEGORY_SENSING": "Sensores",
    "CATEGORY_OPERATORS": "Operadores",
    "CATEGORY_VARIABLES": "Variábeis",
    "CATEGORY_MYBLOCKS": "Os meus bloques",
    "CATEGORY_LISTS": "",
    "CATEGORY_TUPLE": "",
    "CATEGORY_DICTIONARYS": "",
    "CATEGORY_NUMBERS": "",
    "CATEGORY_TEXT": "",
    "DUPLICATE": "Duplicar",
    "DELETE": "Eliminar",
    "ADD_COMMENT": "Engadir comentario",
    "REMOVE_COMMENT": "Retirar comentario",
    "DELETE_BLOCK": "Eliminar bloque",
    "DELETE_X_BLOCKS": "Eliminar %1 bloques",
    "DELETE_ALL_BLOCKS": "Eliminar os %1 bloques?",
    "CLEAN_UP": "Limpar bloques",
    "HELP": "Axuda",
    "UNDO": "Desfacer",
    "REDO": "Refacer",
    "EDIT_PROCEDURE": "Editar",
    "SHOW_PROCEDURE_DEFINITION": "Ir á definición",
    "WORKSPACE_COMMENT_DEFAULT_TEXT": "Di algo...",
    "COLOUR_HUE_LABEL": "Cor",
    "COLOUR_SATURATION_LABEL": "Saturación",
    "COLOUR_BRIGHTNESS_LABEL": "Luminosidade",
    "CHANGE_VALUE_TITLE": "Cambiar valor:",
    "RENAME_VARIABLE": "Renomear variábel",
    "RENAME_VARIABLE_STRING": "",
    "RENAME_VARIABLE_NUMBER": "",
    "RENAME_VARIABLE_LIST": "",
    "RENAME_VARIABLE_TITLE": "Renomear as variábeis «%1» como:",
    "RENAME_VARIABLE_MODAL_TITLE": "Renomear variábel",
    "NEW_VARIABLE": "Crear unha variábel",
    "NEW_VARIABLE_TITLE": "Nome da nova variábel:",
    "VARIABLE_MODAL_TITLE": "Nova variábel",
    "VARIABLE_ALREADY_EXISTS": "Xa existe unha variábel chamada «%1».",
    "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "Xa existe unha variábel chamada «%1» para outra variábel de tipo «%2».",
    "DELETE_VARIABLE_CONFIRMATION": "Eliminar %1 usos da variábel «%2»?",
    "CANNOT_DELETE_VARIABLE_PROCEDURE": "Non é posíbel eliminar a variábel «%1» porque é parte da definición da función «%2».",
    "DELETE_VARIABLE": "Eliminar a variábel «%1»",
    "DELETE_VARIABLE_STRING": "",
    "DELETE_VARIABLE_NUMBER": "",
    "DELETE_VARIABLE_LIST": "",
    "NEW_PROCEDURE": "Crear un bloque",
    "PROCEDURE_ALREADY_EXISTS": "Xa existe un procedemento chamado «%1».",
    "PROCEDURE_DEFAULT_NAME": "nome do bloque",
    "NEW_LIST": "Crear unha lista",
    "NEW_LIST_TITLE": "Nome da nova lista:",
    "LIST_MODAL_TITLE": "Nova lista",
    "LIST_ALREADY_EXISTS": "Xa existe unha lista chamada «%1».",
    "RENAME_LIST_TITLE": "Renomear as listas «%1» como:",
    "RENAME_LIST_MODAL_TITLE": "Renomear lista",
    "DEFAULT_LIST_ITEM": "cousa",
    "DELETE_LIST": "Eliminar a lista «%1»",
    "RENAME_LIST": "Renomear lista",
    "NEW_BROADCAST_MESSAGE": "Nova mensaxe",
    "NEW_BROADCAST_MESSAGE_TITLE": "Nome da nova mensaxe:",
    "BROADCAST_MODAL_TITLE": "Mensaxe nova",
    "DEFAULT_BROADCAST_MESSAGE_NAME": "mensaxe1",
    "DELETE_PROCEDURE": "",
    "OK": "",
    "PROMPT": "",
    "STOP": "",
    "NEW_STRING": "",
    "NEW_NUMBER_VARIABLE": "",
    "WIFI": "",
    "USERNAME": "",
    "PWD": "",
    "IOT_SERVICE": "",
    "IOT_ID": "",
    "IOT_PWD": "",
    "IOT_TOPIC": "",
    "MORE_TOPIC": "",
    "OBLOQ_HTTP": "",
    "OBLOQ_IP": "",
    "OBLOQ_PORT": "",
    "ADD_TOPIC": "",
    "DELETE_TOPIC": "",
    "IOT_SERVER": "",
    "CHINA": "",
    "GLOBAL": "",
    "LOW_C": "",
    "LOW_C$": "",
    "LOW_D": "",
    "LOW_D$": "",
    "LOW_E": "",
    "LOW_F": "",
    "LOW_F$": "",
    "LOW_G": "",
    "LOW_G$": "",
    "LOW_A": "",
    "LOW_A$": "",
    "LOW_B": "",
    "MIDDLE_C": "",
    "MIDDLE_C$": "",
    "MIDDLE_D": "",
    "MIDDLE_D$": "",
    "MIDDLE_E": "",
    "MIDDLE_F": "",
    "MIDDLE_F$": "",
    "MIDDLE_G": "",
    "MIDDLE_G$": "",
    "MIDDLE_A": "",
    "MIDDLE_A$": "",
    "MIDDLE_B": "",
    "HIGH_C": "",
    "HIGH_C$": "",
    "HIGH_D": "",
    "HIGH_D$": "",
    "HIGH_E": "",
    "HIGH_F": "",
    "HIGH_F$": "",
    "HIGH_G": "",
    "HIGH_G$": "",
    "HIGH_A": "",
    "HIGH_A$": "",
    "HIGH_B": "",
    "OPERATORS_STRINGTONUMBER": "",
    "INTEGER": "",
    "DECIMAL": "",
    "OPERATORS_NUMTOASCII": "",
    "OPERATORS_STRINGTOASCII": "",
    "OPERATORS_NUMTOSTRING": "",
    "OPERATORS_MAP": "",
    "OPERATORS_CONSTRAIN": "",
    "STEPS_PER_TURN": "",
    "ROTATE_SPEED": "",
    "NEW_AI": "",
    "ASK_AND_PAINT": "",
    "GET_IDENTIFIED_NUM": "",
    "READ_NUM_AND_SAY": "",
    "IMAGE_ADDR": "",
    "IMAGE_PREVIEW": "",
    "IMAGE_OPEN": "",
    "IMAGE_SIZE": "",
    "IMAGE_WIDTH": "",
    "IMAGE_HEIGHT": "",
    "VARIABLE": "",
    "VARIABLE_LIST": "",
    "SET_STRING_VARIABLE": "",
    "STRING_START_WITH": "",
    "DICTIONARY_HEIGHT_FIRST": "",
    "DICTIONARY_HEIGHT_TWO": "",
    "DICTIONARY_HEIGHT_THREE": "",
    "DICTIONARY_HEIGHT_FOUR": "",
    "DATA_MPITEMOFLIST": "",
    "DATA_MPINTERCEPTITEM": "",
    "DATA_MPINSERTATLIST": "",
    "DATA_MPSETVALUEATLIST": "",
    "DATA_MPINSERTATLIST_INSERT": "",
    "DATA_MPINSERTATLIST_CHANGE": "",
    "DATA_MPINSERTATLIST_FOUR": "",
    "DATA_MPINSERTATLIST_FIVE": "",
    "DATA_MPINSERTATLIST_SIX": "",
    "DATA_MPINSERTATLIST_SEVEN": "",
    "DATA_MPINSERTATLIST_EIGHT": "",
    "DATA_MPINSERTATLIST_EIGHT_FALSE": "",
    "DATA_MPINSERTATLIST_EIGHT_TRUE": "",
    "DATA_MPINSERTATLIST_EIGHT_REVERSE": "",
    "OPERATORS_RANDOMA": "",
    "OPERATORS_MODA": "",
    "OPERATORS_ROUNDA": "",
    "OPERATORS_MATHOPA": "",
    "OPERATORS_MATHOP_ABSA": "",
    "OPERATORS_MATHOP_FLOORA": "",
    "OPERATORS_MATHOP_CEILINGA": "",
    "OPERATORS_MATHOP_SQRTA": "",
    "OPERATORS_MATHOP_FIVE": "",
    "OPERATORS_MATHOP_SIX": "",
    "OPERATORS_MATHOP_SEVEN": "",
    "OPERATORS_MATHOP_LENGHT": "",
    "OPERATORS_MATHOP_MAXMIN": "",
    "OPERATORS_MATHOP_MAXMIN_MAX": "",
    "OPERATORS_MATHOP_MAXMIN_MIN": "",
    "OPERATORS_TEXTS_ONE": "",
    "OPERATORS_TEXTS_TWO": "",
    "OPERATORS_TEXTS_THREE": "",
    "OPERATORS_TEXTS_FOUR": "",
    "OPERATORS_TEXTS_FOUR_NEW": "",
    "OPERATORS_TEXTS_NEW": "",
    "OPERATORS_TEXTS_NEW_OP": "",
    "OPERATORS_TEXTS_FOUR_ONE": "",
    "OPERATORS_TEXTS_FOUR_TWO": "",
    "OPERATORS_TEXTS_FOUR_THREE": "",
    "OPERATORS_TEXTS_FOUR_FOUR": "",
    "OPERATORS_TEXTS_FOUR_F": "",
    "OPERATORS_TEXTS_FOUR_L": "",
    "OPERATORS_STRINGTONUMBERA": "",
    "OPERATORS_NUMTOASCIIA": "",
    "OPERATORS_STRINGTOASCIIA": "",
    "OPERATORS_NUMTOSTRINGA": "",
    "NEW_MC": "",
    "RENAME_MCNUMBER_TITLE": "",
    "RENAME_MCNUMBER_MODAL_TITLE": "",
    "RENAME_VARIABLE_MCNUMBER": "",
    "DELETE_VARIABLE_MCNUMBER": "",
    "SET_MC_VARIABLE": "",
    "TUPLE_ONE": "",
    "TUPLE_TWO": "",
    "ALIYUN": "",
    "ONENET": "",
    "EASYIOT": "",
    "SHANGHAI": "",
    "QINGDAO": "",
    "BEIJING": "",
    "ZHANGJIAKOU": "",
    "HUHEHAOTE": "",
    "HANGZHOU": "",
    "SHENZHEN": "",
    "HONGKONG": "",
    "SINGAPORE": "",
    "SYDNEY": "",
    "KUALALUMPUR": "",
    "JAKARTA": "",
    "MUMBAI": "",
    "TOKYO": "",
    "SILICONVALLEY": "",
    "VIRGINIA": "",
    "FRANKFURT": "",
    "LONDON": "",
    "DUBAI": "",
    "IOT_PLATFORM": "",
    "PARAMS": "",
    "SERVER_ATTR": "",
    "PRODUCTID": "",
    "DEVICEID": "",
    "TINYDB_SECRET": "",
    "TINYDB_USER": "",
    "APIADDR": "",
    "SOFTWARE_SERIAL": "",
    "HARDWARE_SERIAL": "",
    "HARDWARE_SERIAL1": "",
    "HARDWARE_SERIAL2": "",
    "HARDWARE_SERIAL3": ""
  },
  "ko": {
    "CONTROL_FOREVER": "무한 반복하기",
    "CONTROL_LOOP_BREAKCONTINUE": "%1",
    "CONTROL_LOOP_BREAKCONTINUE_BREAK": "break",
    "CONTROL_LOOP_BREAKCONTINUE_CONTINUE": "continue",
    "CONTROL_REPEAT": "%1 번 중복 실행하기",
    "CONTROL_IF": "만약 %1 (이)라면 실행하기",
    "CONTROL_ELSE": "아니면",
    "CONTROL_STOP": "멈추기",
    "CONTROL_STOP_ALL": "모든 스크립트",
    "CONTROL_STOP_THIS": "이 스크립트",
    "CONTROL_STOP_OTHER": "이 스프라이트에 있는 다른 스크립트",
    "CONTROL_WAIT": "%1 초 기다리기",
    "CONTROL_WAIT_ESP32_MPY": "wait %1 %2",
    "CONTROL_FOR_FROM_SEQUENCE": "use %1 from sequence %2",
    "CONTROL_WAIT_ESP32_MPY_S": "second(s)",
    "CONTROL_WAIT_ESP32_MPY_MS": "millisecond(ms)",
    "CONTROL_WAIT_ESP32_MPY_US": "microsecond(us)",
    "CONTROL_WAITUNTIL": "%1 까지 기다리기",
    "CONTROL_REPEATUNTIL": "%1 까지 중복 실행하기",
    "CONTROL_WHILE": "%1 중복 실행하기",
    "CONTROL_FOREACH": "%1 변수로 %2 번 반복하기",
    "CONTROL_STARTASCLONE": "복사본을 실행할 때",
    "CONTROL_CREATECLONEOF": "%1 복사하기",
    "CONTROL_CREATECLONEOF_MYSELF": "나 자신",
    "CONTROL_DELETETHISCLONE": "이 복사본 삭제하기",
    "CONTROL_COUNTER": "카운터",
    "CONTROL_INCRCOUNTER": "카운터 증가하기",
    "CONTROL_CLEARCOUNTER": "카운터 초기화",
    "CONTROL_ALLATONCE": "모든 스크립트",
    "DATA_SETVARIABLETO": "%1 을(를) %2 로 설정하기",
    "DATA_CHANGEVARIABLEBY": "%1 을(를) %2 로 변경하기",
    "DATA_SHOWVARIABLE": "변수 %1  보이기",
    "DATA_HIDEVARIABLE": "변수 %1  숨기기",
    "DATA_ADDTOLIST": "%1 을(를) %2 에 추가하기",
    "DATA_DELETEOFLIST": "%2 의 %1 항목 삭제하기",
    "DATA_DELETEALLOFLIST": "%1 의 모든 항목 삭제하기",
    "DATA_INSERTATLIST": "%3 의 %2 번째 항목 앞에 %1 삽입하기",
    "DATA_REPLACEITEMOFLIST": "%2 의 %1 번쨰 항목을 %3 으로 교체하기",
    "DATA_ITEMOFLIST": "%2 의 %1 번째 항목",
    "DATA_ITEMNUMOFLIST": "%2 중 첫 번째 %1 의 번호",
    "DATA_LENGTHOFLIST": "%1 의 항목수",
    "DATA_LISTCONTAINSITEM": "%1 이(가) %2 을(를) 포함하고 있습니까?",
    "DATA_SHOWLIST": "%1 리스트 보이기",
    "DATA_HIDELIST": "%1 리스트 숨기기",
    "DATA_INDEX_ALL": "모두",
    "DATA_INDEX_LAST": "마지막",
    "DATA_INDEX_RANDOM": "랜덤",
    "EVENT_WHENFLAGCLICKED": "%1 클릭했을 때",
    "EVENT_WHENTHISSPRITECLICKED": "이 스프라이트를 클릭했을 때",
    "EVENT_WHENSTAGECLICKED": "스테이지를 클릭했을 때",
    "EVENT_WHENTOUCHINGOBJECT": "이 스프라이트가 %1 에 닿을 때",
    "EVENT_WHENBROADCASTRECEIVED": "%1 를 받았을 때",
    "EVENT_WHENBACKDROPSWITCHESTO": "배경이 %1 (으)로 바뀌었을 때",
    "EVENT_WHENGREATERTHAN": "%1 > %2 일 때",
    "EVENT_WHENGREATERTHAN_TIMER": "타이머",
    "EVENT_WHENGREATERTHAN_LOUDNESS": "음량",
    "EVENT_BROADCAST": "%1 신호 보내기",
    "EVENT_BROADCASTANDWAIT": "%1 신호 보내고 기다리기",
    "EVENT_WHENKEYPRESSED": "%1 키를 눌렀을 때",
    "EVENT_WHENKEYPRESSED_SPACE": "스페이스",
    "EVENT_WHENKEYPRESSED_LEFT": "←",
    "EVENT_WHENKEYPRESSED_RIGHT": "→",
    "EVENT_WHENKEYPRESSED_DOWN": "↓",
    "EVENT_WHENKEYPRESSED_UP": "↑",
    "EVENT_WHENKEYPRESSED_ANY": "랜덤",
    "LOOKS_SAYFORSECS": "%1 을(를) %2 초 동안 말하기",
    "LOOKS_SAY": "%1 말하기",
    "LOOKS_HELLO": "안녕!",
    "LOOKS_THINKFORSECS": "%1 을(를) %2 초 동안 생각하기",
    "LOOKS_THINK": "%1 생각하기",
    "LOOKS_HMM": "음...",
    "LOOKS_SHOW": "보이기",
    "LOOKS_HIDE": "숨기기",
    "LOOKS_HIDEALLSPRITES": "모든 스프라이트 숨기기",
    "LOOKS_EFFECT_COLOR": "색깔",
    "LOOKS_EFFECT_FISHEYE": "어안 렌즈",
    "LOOKS_EFFECT_WHIRL": "소용돌이",
    "LOOKS_EFFECT_PIXELATE": "픽셀화",
    "LOOKS_EFFECT_MOSAIC": "모자이크",
    "LOOKS_EFFECT_BRIGHTNESS": "밝기",
    "LOOKS_EFFECT_GHOST": "가상",
    "LOOKS_CHANGEEFFECTBY": "%1 효과를 %2 만큼 바꾸기",
    "LOOKS_SETEFFECTTO": "%1 효과를 %2 (으)로 정하기",
    "LOOKS_CLEARGRAPHICEFFECTS": "그래픽 효과 지우기",
    "LOOKS_CHANGESIZEBY": "크기를 %1 만큼 변경하기",
    "LOOKS_SETSIZETO": "크기를 %1 % 로 설정하기",
    "LOOKS_SIZE": "크기",
    "LOOKS_CHANGESTRETCHBY": "%1 만큼 늘이기",
    "LOOKS_SETSTRETCHTO": "늘이기를 %1 % 로 설정하기",
    "LOOKS_SWITCHCOSTUMETO": "%1 모양(으)로 바꾸기",
    "LOOKS_NEXTCOSTUME": "다음 모양으로 바꾸기",
    "LOOKS_SWITCHBACKDROPTO": "%1 배경 (으)로 바꾸기",
    "LOOKS_GOTOFRONTBACK": "%1 단계로 이동하기",
    "LOOKS_GOTOFRONTBACK_FRONT": "앞면",
    "LOOKS_GOTOFRONTBACK_BACK": "뒷면",
    "LOOKS_GOFORWARDBACKWARDLAYERS": "%1 %2 층",
    "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "앞으로",
    "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "뒤로",
    "LOOKS_BACKDROPNUMBERNAME": "배경 %1",
    "LOOKS_COSTUMENUMBERNAME": "모양 %1",
    "LOOKS_NUMBERNAME_NUMBER": "번호",
    "LOOKS_NUMBERNAME_NAME": "이름",
    "LOOKS_SWITCHBACKDROPTOANDWAIT": "배경을 %1 로 바꾸고 기다리기",
    "LOOKS_NEXTBACKDROP_BLOCK": "다음 배경",
    "LOOKS_NEXTBACKDROP": "다음 배경",
    "LOOKS_PREVIOUSBACKDROP": "이전 배경",
    "LOOKS_RANDOMBACKDROP": "랜덤 배경",
    "MOTION_MOVESTEPS": "%1 보 움직이기",
    "MOTION_TURNLEFT": "%1 %2 만큼 좌회전",
    "MOTION_TURNRIGHT": "%1 %2 만큼 우회전",
    "MOTION_POINTINDIRECTION": "%1 방향으로 가리키기",
    "MOTION_POINTTOWARDS": "%1 로 가리키기",
    "MOTION_POINTTOWARDS_POINTER": "마우스 포인터",
    "MOTION_POINTTOWARDS_RANDOM": "랜덤 방향",
    "MOTION_GOTO": "%1 (으)로 이동하기",
    "MOTION_GOTO_POINTER": "마우스 포인터",
    "MOTION_GOTO_RANDOM": "랜덤 위치",
    "MOTION_GOTOXY": "x: %1 y: %2 (으)로 이동하기",
    "MOTION_GLIDESECSTOXY": "%1 초 안에 x: %2 y: %3 (으)로 이동하기",
    "MOTION_GLIDETO": "%1 초 안에 %2 (으)로 이동하기",
    "MOTION_GLIDETO_POINTER": "마우스 포인터",
    "MOTION_GLIDETO_RANDOM": "랜덤 위치",
    "MOTION_CHANGEXBY": "x 좌표를 %1 만큼 변경하기",
    "MOTION_SETX": "x 좌표를 %1 (으)로 정하기",
    "MOTION_CHANGEYBY": "y 좌표를 %1 만큼 변경하기",
    "MOTION_SETY": "y 좌표를 %1 (으)로 정하기",
    "MOTION_IFONEDGEBOUNCE": "벽에 닿으면 튕기기",
    "MOTION_SETROTATIONSTYLE": "회전 방식을 %1 (으)로 정하기",
    "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "왼쪽-오른쪽으로 번갈아가며 회전하기",
    "MOTION_SETROTATIONSTYLE_DONTROTATE": "회전할 수 없습니다.",
    "MOTION_SETROTATIONSTYLE_ALLAROUND": "무작위로 회전하기",
    "MOTION_XPOSITION": "x 좌표",
    "MOTION_YPOSITION": "y 좌표",
    "MOTION_DIRECTION": "방향",
    "MOTION_SCROLLRIGHT": "오른쪽으로 %1 만큼 스크롤하기",
    "MOTION_SCROLLUP": "위쪽으로 %1 만큼 스크롤하기",
    "MOTION_ALIGNSCENE": "화면을 %1 에 정렬하기",
    "MOTION_ALIGNSCENE_BOTTOMLEFT": "왼쪽 아래",
    "MOTION_ALIGNSCENE_BOTTOMRIGHT": "오른쪽 아래",
    "MOTION_ALIGNSCENE_MIDDLE": "가운데",
    "MOTION_ALIGNSCENE_TOPLEFT": "왼쪽 위",
    "MOTION_ALIGNSCENE_TOPRIGHT": "오른쪽 위",
    "MOTION_XSCROLL": "x 스크롤",
    "MOTION_YSCROLL": "y 스크롤",
    "MOTION_STAGE_SELECTED": "스테이지가 선택되었습니다: 동작 블록 없음",
    "OPERATORS_ADD": "%1 + %2",
    "OPERATORS_SUBTRACT": "%1 - %2",
    "OPERATORS_MULTIPLY": "%1 × %2",
    "OPERATORS_DIVIDE": "%1 ÷ %2",
    "OPERATORS_RANDOM": "%1 과 %2 사이 임의의 난수를 취합니다.",
    "OPERATORS_GT": "%1 > %2",
    "OPERATORS_GT_EQUALS": "%1 >= %2",
    "OPERATORS_LT": "%1 < %2",
    "OPERATORS_LT_EQUALS": "%1 <= %2",
    "OPERATORS_EQUALS": "%1 = %2",
    "OPERATORS_AND": "%1 그리고 %2",
    "OPERATORS_OR": "%1 또는 %2",
    "OPERATORS_NOT": "%1 이(가) 아닙니다",
    "CONTROL_TRUE_FALSE": "%1",
    "CONTROL_TRUE_FALSE_TRUE": "true",
    "CONTROL_TRUE_FALSE_FALSE": "false",
    "CONTROL_NONE": "None",
    "CONTROL_RETURN": "return %1",
    "OPERATORS_VALUE_IS_TRUE": "value %1 is true?",
    "OPERATORS_VALUE_TYPE": "value %1 type",
    "CONTROL_TRY": "try",
    "CONTROL_EXCEPT": "except",
    "CONTROL_FINALLY": "finally",
    "OPERATORS_VALUE_TYPE_IS": "value %1 type is %2?",
    "OPERATORS_VALUE_TYPE_INT": "int",
    "OPERATORS_VALUE_TYPE_FLOAT": "float",
    "OPERATORS_VALUE_TYPE_BOOL": "bool",
    "OPERATORS_VALUE_TYPE_STR": "str",
    "OPERATORS_VALUE_TYPE_LIST": "list",
    "OPERATORS_VALUE_TYPE_TUPLE": "tuple",
    "OPERATORS_VALUE_TYPE_SET": "set",
    "OPERATORS_VALUE_TYPE_DICT": "dict",
    "OPERATORS_VALUE_TYPE_BYTES": "bytes",
    "OPERATORS_VALUE_TYPE_BYTEARRAY": "bytearray",
    "OPERATORS_VALUE_TYPE_COMPLEX": "complex",
    "OPERATORS_CACULATE_RESULT": "eval %1",
    "OPERATORS_JOIN": "%1 와(과) %2 결합하기",
    "OPERATORS_JOIN_APPLE": "사과",
    "OPERATORS_JOIN_BANANA": "바나나",
    "OPERATORS_LETTEROF": "%2 의 %1 번째 글자",
    "OPERATORS_LETTEROF_APPLE": "과일",
    "OPERATORS_LENGTH": "%1 의 글자수",
    "OPERATORS_CONTAINS": "%1 이(가) %2 을(를) 포함하는가?",
    "OPERATORS_MOD": "%1 나누기 %2 의 나머지",
    "OPERATORS_ROUND": "%1 의 반올림",
    "OPERATORS_MATHOP": "%1 %2 ",
    "OPERATORS_MATHOP_ABS": "절대값",
    "OPERATORS_MATHOP_FLOOR": "버림",
    "OPERATORS_MATHOP_CEILING": "올림",
    "OPERATORS_MATHOP_SQRT": "제곱근",
    "OPERATORS_MATHOP_SIN": "sin",
    "OPERATORS_MATHOP_COS": "cos",
    "OPERATORS_MATHOP_TAN": "tan",
    "OPERATORS_MATHOP_ASIN": "asin",
    "OPERATORS_MATHOP_ACOS": "acos",
    "OPERATORS_MATHOP_ATAN": "atan",
    "OPERATORS_MATHOP_LN": "ln",
    "OPERATORS_MATHOP_LOG": "log",
    "OPERATORS_MATHOP_EEXP": "e ^",
    "OPERATORS_MATHOP_10EXP": "10 ^",
    "PROCEDURES_DEFINITION": "%1 정의하기",
    "SENSING_TOUCHINGOBJECT": "%1 에 닿았습니까?",
    "SENSING_TOUCHINGOBJECT_POINTER": "마우스 포인터",
    "SENSING_TOUCHINGOBJECT_EDGE": "무대 가장자리",
    "SENSING_TOUCHINGCOLOR": "색깔 %1 에 닿았습니까?",
    "SENSING_COLORISTOUCHINGCOLOR": "색깔%1 이 %2 에 닿았습니까?",
    "SENSING_DISTANCETO": "%1 까지의 거리",
    "SENSING_DISTANCETO_POINTER": "마우스 포인터",
    "SENSING_ASKANDWAIT": "%1 라고 묻고 기다리기",
    "SENSING_ASK_TEXT": "당신의 이름은 무엇입니까?",
    "SENSING_ANSWER": "대답",
    "SENSING_KEYPRESSED": "%1 키를 눌렀습니까?",
    "SENSING_MOUSEDOWN": "마우스를 클릭하셨습니까?",
    "SENSING_MOUSEX": "마우스의 x좌표",
    "SENSING_MOUSEY": "마우스의 y좌표",
    "SENSING_SETDRAGMODE": "드래그 모드를 %1로 설정하기",
    "SENSING_SETDRAGMODE_DRAGGABLE": "드래그 가능합니다.",
    "SENSING_SETDRAGMODE_NOTDRAGGABLE": "드래그 할 수 없습니다.",
    "SENSING_LOUDNESS": "음량",
    "SENSING_LOUD": "소리가 너무 큽니까?",
    "SENSING_TIMER": "타이머",
    "SENSING_RESETTIMER": "타이머 초기화",
    "SENSING_OF": "%2 의 %1",
    "SENSING_OF_XPOSITION": "x좌표",
    "SENSING_OF_YPOSITION": "y좌표",
    "SENSING_OF_DIRECTION": "방향",
    "SENSING_OF_COSTUMENUMBER": "모양 번호",
    "SENSING_OF_COSTUMENAME": "모양 이름",
    "SENSING_OF_SIZE": "크기",
    "SENSING_OF_VOLUME": "음량",
    "SENSING_OF_BACKDROPNUMBER": "배경 번호",
    "SENSING_OF_BACKDROPNAME": "배경 이름",
    "SENSING_OF_STAGE": "스테이지",
    "SENSING_CURRENT": "현재 시간은 %1 입니다.",
    "SENSING_CURRENT_YEAR": "년",
    "SENSING_CURRENT_MONTH": "월",
    "SENSING_CURRENT_DATE": "일",
    "SENSING_CURRENT_DAYOFWEEK": "요일",
    "SENSING_CURRENT_HOUR": "시",
    "SENSING_CURRENT_MINUTE": "분",
    "SENSING_CURRENT_SECOND": "초",
    "SENSING_DAYSSINCE2000": "2000년 이후 현재까지 날짜 수",
    "SENSING_USERNAME": "사용자 이름",
    "SENSING_USERID": "사용자 ID",
    "SOUND_PLAY": "소리 %1 재생하기",
    "SOUND_PLAYUNTILDONE": "소리 %1 을 끝까지 재생하기",
    "SOUND_STOPALLSOUNDS": "모든 소리 중지하기",
    "SOUND_SETEFFECTO": "%1 효과를 %2 로 정하기",
    "SOUND_CHANGEEFFECTBY": "%1 효과를 %2 만큼 변경하기",
    "SOUND_CLEAREFFECTS": "소리효과 지우기",
    "SOUND_EFFECTS_PITCH": "피치",
    "SOUND_EFFECTS_PAN": "좌우평행",
    "SOUND_CHANGEVOLUMEBY": "음량을 %1 로 변경하기",
    "SOUND_SETVOLUMETO": "음량을 %1% 로 정하기",
    "SOUND_VOLUME": "음량",
    "SOUND_RECORD": "녹음",
    "CATEGORY_MOTION": "동작",
    "CATEGORY_LOOKS": "외관",
    "CATEGORY_SOUND": "소리",
    "CATEGORY_EVENTS": "이벤트",
    "CATEGORY_CONTROL": "제어",
    "CATEGORY_SENSING": "감지",
    "CATEGORY_OPERATORS": "연산",
    "CATEGORY_VARIABLES": "변수",
    "CATEGORY_MYBLOCKS": "함수",
    "CATEGORY_NUMBERS": "수치",
    "CATEGORY_TEXT": "텍스트",
    "DUPLICATE": "복사하기",
    "DELETE": "삭제하기",
    "ADD_COMMENT": "주석 넣기",
    "REMOVE_COMMENT": "주석 지우기",
    "DELETE_BLOCK": "삭제하기",
    "DELETE_X_BLOCKS": "블록 %1 삭제하기",
    "DELETE_ALL_BLOCKS": " %1의 블록을 모두 삭제할까요?",
    "CLEAN_UP": "블록 정리하기",
    "HELP": "도움말",
    "UNDO": "되돌리기",
    "REDO": "재시도",
    "EDIT_PROCEDURE": "편집",
    "SHOW_PROCEDURE_DEFINITION": "정의하기로 이동하기",
    "WORKSPACE_COMMENT_DEFAULT_TEXT": "말해보세요.",
    "COLOUR_HUE_LABEL": "색상",
    "COLOUR_SATURATION_LABEL": "채도",
    "COLOUR_BRIGHTNESS_LABEL": "명도",
    "CHANGE_VALUE_TITLE": "변수 수정하기",
    "RENAME_VARIABLE": "변수 이름 수정하기",
    "RENAME_VARIABLE_STRING": "문자형 변수 이름 수정하기",
    "RENAME_VARIABLE_NUMBER": "숫자형 변수 이름 수정하기",
    "RENAME_VARIABLE_LIST": "리스트 변수 이름 수정하기",
    "RENAME_VARIABLE_TITLE": "「%1」 변수 이름을 모두 다음으로 수정하기:",
    "RENAME_VARIABLE_MODAL_TITLE": "변수 이름 수정하기",
    "NEW_VARIABLE": "변수 새로 만들기",
    "NEW_VARIABLE_TITLE": "새 변수 이름:",
    "VARIABLE_MODAL_TITLE": "새 변수",
    "VARIABLE_ALREADY_EXISTS": "「%1」변수 이름은 이미 존재합니다.",
    "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "유형 \"%2\" 변수에 「%1」 변수 이름이 이미 존재합니다.",
    "DELETE_VARIABLE_CONFIRMATION": "「%2」변수의 %1사용을 삭제하시겠습니까?",
    "CANNOT_DELETE_VARIABLE_PROCEDURE": "「%2」 함수를 정의하는 데 사용하였기 때문에 변수 「%1」를 삭제할 수 없습니다.",
    "DELETE_VARIABLE": "「%1」변수 삭제하기",
    "DELETE_VARIABLE_STRING": "문자 변수「%1」  삭제하기",
    "DELETE_VARIABLE_NUMBER": "숫자변수「%1」  삭제하기",
    "DELETE_VARIABLE_LIST": "리스트 변수「%1」  삭제하기",
    "NEW_PROCEDURE": "블록 만들기",
    "PROCEDURE_ALREADY_EXISTS": "\"%1\" 프로그램명은 이미 존재합니다.",
    "PROCEDURE_DEFAULT_NAME": "블록 이름",
    "NEW_LIST": "리스트 만들기",
    "NEW_LIST_TITLE": "새 리스트 이름",
    "LIST_MODAL_TITLE": "새 리스트",
    "LIST_ALREADY_EXISTS": "\"%1\" 리스트 이름은 이미 존재합니다.",
    "RENAME_LIST_TITLE": "\"%1\" 리스트 이름을 모두 다음으로 수정하기: ",
    "RENAME_LIST_MODAL_TITLE": "리스트 이름 수정하기",
    "DEFAULT_LIST_ITEM": "것",
    "DELETE_LIST": "\"%1\" 리스트 삭제하기",
    "RENAME_LIST": "리스트 이름 바꾸기",
    "NEW_BROADCAST_MESSAGE": "새 데이터",
    "NEW_BROADCAST_MESSAGE_TITLE": "새 데이터 이름:",
    "BROADCAST_MODAL_TITLE": "새 데이터",
    "DEFAULT_BROADCAST_MESSAGE_NAME": "데이터 1",
    "DELETE_PROCEDURE": "사용자 정의 빌딩 블록을 삭제하려면 먼저 해당 빌딩 블록에 대한 모든 참조를 제거하십시오.\r\n",
    "OK": "확인",
    "CANCEL": "Cancel",
    "PROMPT": "프롬프트",
    "STOP": "정지",
    "NEW_STRING": "새 문자형 변수 만들기",
    "NEW_NUMBER_VARIABLE": "새 숫자형 변수 만들기",
    "WIFI": "Wi-Fi",
    "USERNAME": "Wi-Fi이름",
    "PWD": "Wi-Fi비밀번호",
    "IOT_SERVICE": "IoT 플랫폼 변수",
    "IOT_ID": "Iot_id",
    "IOT_PWD": "Iot_pwd",
    "IOT_TOPIC": "Topic_0",
    "MORE_TOPIC": "Topic",
    "OBLOQ_HTTP": "HTTP ",
    "OBLOQ_IP": "IP주소",
    "OBLOQ_PORT": "포트",
    "ADD_TOPIC": "주제 추가하기, 최대 4개까지 선택 가능",
    "DELETE_TOPIC": "주제 삭제",
    "IOT_SERVER": "서버",
    "CHINA": "중국",
    "GLOBAL": "글로벌",
    "LOW_C": "Low C/C3",
    "LOW_C$": "Low C#/C#3",
    "LOW_D": "Low D/D3",
    "LOW_D$": "Low D#/D#3",
    "LOW_E": "Low E/E3",
    "LOW_F": "Low F/F3",
    "LOW_F$": "Low F#/F#3",
    "LOW_G": "Low G/G3",
    "LOW_G$": "Low G#/G#3",
    "LOW_A": "Low A/A3",
    "LOW_A$": "Low A#/A#3",
    "LOW_B": "Low B/B3",
    "MIDDLE_C": "Middle C/C4",
    "MIDDLE_C$": "Middle C#/C#4",
    "MIDDLE_D": "Middle D/D4",
    "MIDDLE_D$": "Middle D#/D#4",
    "MIDDLE_E": "Middle E/E4",
    "MIDDLE_F": "Middle F/F4",
    "MIDDLE_F$": "Middle F#/F#4",
    "MIDDLE_G": "Middle G/G4",
    "MIDDLE_G$": "Middle G#/G#4",
    "MIDDLE_A": "Middle A/A4",
    "MIDDLE_A$": "Middle A#/A#4",
    "MIDDLE_B": "Middle B/B4",
    "HIGH_C": "High C/C5",
    "HIGH_C$": "High C#/C#5",
    "HIGH_D": "High D/D5",
    "HIGH_D$": "High D#/D#5",
    "HIGH_E": "High E/E5",
    "HIGH_F": "High F/F5",
    "HIGH_F$": "High F#/F#5",
    "HIGH_G": "High G/G5",
    "HIGH_G$": "High G#/G#5",
    "HIGH_A": "High A/A5",
    "HIGH_A$": "High A#/A#5",
    "HIGH_B": "High B/B5",
    "OPERATORS_STRINGTONUMBER": "문자열 %1을 %2로 전환하기",
    "INTEGER": "정수",
    "DECIMAL": "소수",
    "OPERATORS_NUMTOASCII": "숫자%1을 ASCII 문자로 바꾸기",
    "OPERATORS_STRINGTOASCII": "문자%1을 ASCII 수식으로 바꾸기",
    "OPERATORS_NUMTOSTRING": "숫자%1을 문자열로 바꾸기",
    "OPERATORS_MAP": "%1 [ %2 , %3 ]에서[ %4 , %5 ]까지 매핑합니다.",
    "OPERATORS_CONSTRAIN": "%1을 %2(최소)와 %3(최대) 사이로 제한합니다.",
    "STEPS_PER_TURN": "턴당 걸음 수",
    "ROTATE_SPEED": "속도 전환하기(r/min)",
    "NEW_AI": "AI모형 만들기",
    "ASK_AND_PAINT": "숫자를 기입하세요.",
    "GET_IDENTIFIED_NUM": "숫자를 식별 중입니다.",
    "READ_NUM_AND_SAY": "식별한 숫자를 읽어내는 중입니다.",
    "IMAGE_ADDR": "이미지 주소",
    "IMAGE_PREVIEW": "이미지 열기",
    "IMAGE_OPEN": "열기",
    "IMAGE_SIZE": "이미지 사이즈",
    "IMAGE_WIDTH": "너비",
    "IMAGE_HEIGHT": "높이",
    "VARIABLE": "변수 %1",
    "VARIABLE_LIST": "리스트 %1",
    "SET_STRING_VARIABLE": "%1의 값을 %2로 설정하기",
    "STRING_START_WITH": "%2 문자열로 %1을 시작합니다. ",
    "OPERATORS_RANDOMA": "%1 %2는 %3에서%4사이 임의로 난수를 취합니다 %5.",
    "OPERATORS_MODA": "%1 %2 %3을 %4로 나눈 나머지",
    "OPERATORS_ROUNDA": "%1 %2 반올림 %3",
    "OPERATORS_MATHOPA": "%1 %2 %3 %4",
    "OPERATORS_MATHOP_ABSA": "절대값",
    "OPERATORS_MATHOP_FLOORA": "올림",
    "OPERATORS_MATHOP_CEILINGA": "내림",
    "OPERATORS_MATHOP_SQRTA": "제곱근",
    "OPERATORS_MATHOP_FIVE": "%1 %2 변수%3을 %4로 변경합니다.",
    "OPERATORS_MATHOP_SIX": "%1 %2가 %3을 [ %4 , %5 ] 에서[ %6 , %7 ]까지 매핑합니다.",
    "OPERATORS_MATHOP_SEVEN": "%1 %2 는 %3을 %4(최소값)과 %5(최대값) 사이로 제한합니다.",
    "OPERATORS_NUM_INPUT": "%1 %2 number %3",
    "OPERATORS_NUMS_CACULATE": "%1 %2 %3",
    "OPERATORS_MATHOP_SQUARE": "**",
    "OPERATORS_MATHOP_LOGIC_OR": "|",
    "OPERATORS_MATHOP_LOGIC_AND": "&",
    "OPERATORS_MATHOP_LOGIC_POWER": "^",
    "OPERATORS_MATHOP_LOGIC_LEFT": "<<",
    "OPERATORS_MATHOP_LOGIC_RIGHT": ">>",
    "OPERATORS_SPECIAL_NUM": "%1 %2 %3",
    "OPERATORS_MATHOP_PI": "π",
    "OPERATORS_MATHOP_LOGARITHM": "e",
    "OPERATORS_MATHOP_FAI": "φ",
    "OPERATORS_MATHOP_SQRT2": "sqrt(2)",
    "OPERATORS_MATHOP_SQRTHALF": "sqrt(½)",
    "OPERATORS_MATHOP_UNLIMITED": "∞",
    "OPERATORS_NUMS_TYPE": "%1 %2 %3 %4?",
    "OPERATORS_MATHOP_ISEVEN": "is even",
    "OPERATORS_MATHOP_ISODD": "is odd",
    "OPERATORS_MATHOP_ISINT": "is int",
    "OPERATORS_MATHOP_ISPOSITIVE": "is positive",
    "OPERATORS_MATHOP_ISNEGATIVE": "is negative",
    "OPERATORS_SAVE2DECIMAL": "%1 %2 %3 save %4 decimal places",
    "OPERATORS_LIST_CACULATE": "%1 %2 %3 %4",
    "OPERATORS_MATHOP_LIST_SUM": "sum of the numbers in the list",
    "OPERATORS_MATHOP_LIST_MIN": "minimum value in list",
    "OPERATORS_MATHOP_LIST_MAX": "maximum value in the list",
    "OPERATORS_MATHOP_LIST_AVERAGE": "average in list",
    "OPERATORS_MATHOP_LIST_NUM": "number of digits in the list",
    "OPERATORS_MATHOP_LIST_MODE": "list mode",
    "OPERATORS_MATHOP_LIST_STDDEVIATION": "standard deviation in the list",
    "OPERATORS_MATHOP_LIST_RANDOM": "random item of list",
    "OPERATORS_QUOTIENT_INT": "%1 %2 %3 ÷ %4 integer part of quotient",
    "OPERATORS_RANDOM_DECIMAL": "%1 %2 random decimal",
    "OPERATORS_BIN_NEGATION": "%1 %2 binary negation %3",
    "OPERATORS_INT_TO_STR": "%1 %2 decimal integer %3 transform into %4 string",
    "OPERATORS_MATHOP_INTTOSTR_BIN": "binary",
    "OPERATORS_MATHOP_INTTOSTR_OCT": "octal",
    "OPERATORS_MATHOP_INTTOSTR_HEX": "hex",
    "OPERATORS_BINSTR_TO_INT": "%1 %2 %3 string %4 transform into decimal integer",
    "OPERATORS_INT_TO_BYTE": "%1 %2 decimal integer %3 transform into %4 byte",
    "OPERATORS_MATHOP_INTTOBYTE_TWO": "2",
    "OPERATORS_MATHOP_INTTOBYTE_FOUR": "4",
    "OPERATORS_INT_TO_ASCII": "%1 %2 int %3 transform into ASCII character",
    "OPERATORS_ASCII_TO_INT": "%1 %2 ASCII character %3 to int",
    "OPERATORS_STR_TO_INT": "%1 %2 string %3 to hex string",
    "OPERATORS_HEXSTR_TO_BIN": "%1 %2 hex string %3 to bin byte",
    "OPERATORS_STR_TO_BASE64": "%1 %2 %3 encodes in base64 format returns a byte object",
    "OPERATORS_BASE64_TO_BYTE": "%1 %2 decode base64 data %3 returns a byte object",
    "OPERATORS_MATHOP_LENGHT": "길이 %1",
    "OPERATORS_MATHOP_MAXMIN": "%1 %2",
    "OPERATORS_MATHOP_MAXMIN_MAX": "최대값",
    "OPERATORS_MATHOP_MAXMIN_MIN": "최소값",
    "OPERATORS_TEXTS_ONE": "%1 %2를 %3 %4와 합치기",
    "OPERATORS_TEXTS_TWO": "%1 %2는 %3의 %4번째 문자를 가져옵니다. ",
    "OPERATORS_TEXTS_THREE": "%1 %2 %3은 %4를 포함합니까? ",
    "OPERATORS_TEXTS_FOUR": "%1 %2 %3은 %4 %5문자부터 %6%7 문자를 가져옵니다.",
    "OPERATORS_TEXTS_FOUR_NEW": "%1은 %2 %3문자부터 %4 %5문자를 가져옵니다. ",
    "OPERATORS_TEXTS_NEW": "%1 %2는 %3이 %4 중의 %5에 나타나는 위치를 찾습니다.",
    "OPERATORS_TEXTS_NEW_OP": "%2중의 %3에 %1이 나타나는 위치를 찾습니다.",
    "OPERATORS_TEXTS_FOUR_ONE": "제",
    "OPERATORS_TEXTS_FOUR_TWO": "아래에서 부터",
    "OPERATORS_TEXTS_FOUR_THREE": "제",
    "OPERATORS_TEXTS_FOUR_FOUR": "아래에서 부터",
    "OPERATORS_TEXTS_FOUR_F": "처음으로",
    "OPERATORS_TEXTS_FOUR_L": "마지막으로",
    "OPERATORS_INPUTSTRING": "%1 %2 string %3",
    "OPERATORS_TRANSINTOTEXT": "%1 %2 transform into %3",
    "OPERATORS_TRANSINTOTEXT_ADD": "add",
    "OPERATORS_TRANSINTOTEXT_ADD_ITEM": "item",
    "OPERATORS_FORMATSTRING2F": "%1 %2 format string %3 % %4",
    "OPERATORS_FORMATSTRING3": "%1 %2 format string %3 format %4",
    "OPERATORS_TEXT_ESCAPE": "%1 %2 text escape character %3",
    "BAR_N": "\\n",
    "BAR_NN": "\\n\\r",
    "BAR_R": "\\r",
    "BAR_RN": "\\r\\n",
    "BAR_B": "\\b",
    "BAR_T": "\\t",
    "BAR_BAR": "\\\\",
    "OPERATORS_TEXT_NUMORLETTER": "%1 %2 %3 %4?",
    "OPERATORS_TEXT_NUMORLETTER_NUM": "is number",
    "OPERATORS_TEXT_NUMORLETTER_LETTER": "is letter",
    "OPERATORS_TEXT_LENGTH": "%1 %2 %3 length",
    "OPERATORS_TEXT_ISNONE": "%1 %2 %3 is none?",
    "OPERATORS_FROMTEXT_GETCHAR": "%1 %2 in text %3 %4",
    "OPERATORS_FROMTEXT_GETCHAR_FIRST": "first letter",
    "OPERATORS_FROMTEXT_GETCHAR_LAST": "last letter",
    "OPERATORS_FROMTEXT_GETCHAR_RANDOM": "random letter",
    "OPERATORS_TEXT_UPPLOW": "%1 %2 %3 %4",
    "OPERATORS_TEXT_UPPLOW_UPP": "uppercase",
    "OPERATORS_TEXT_UPPLOW_LOW": "lowercase",
    "OPERATORS_TEXT_SIDES_SPACE": "%1 %2 %3 %4",
    "OPERATORS_TEXT_SIDES_SPACE_BOTH": "clear spaces on both sides",
    "OPERATORS_TEXT_SIDES_SPACE_LEFT": "clear left space",
    "OPERATORS_TEXT_SIDES_SPACE_RIGHT": "clear right space",
    "OPERATORS_TEXT_STRTOBYTE": "%1 %2 turn byte %3",
    "OPERATORS_TEXT_BYTETOSTR": "%1 %2 byte %3 convert to string",
    "OPERATORS_TEXT_DICTTOJSONSTR": "%1 %2 dict %3 transform into json string",
    "OPERATORS_TEXT_PARSEJSONSTR": "%1 %2 parse json string %3 and return object",
    "OPERATORS_STRINGTONUMBERA": "%1 %2는  문자열의 %3을 %4로 바꿉니다.",
    "OPERATORS_NUMTOASCIIA": "%1 %2는 숫자 %3을 ASCII문자로 바꿉니다.",
    "OPERATORS_STRINGTOASCIIA": "%1 %2는 문자 %3을 ASCII수치로 바꿉니다. ",
    "OPERATORS_NUMTOSTRINGA": "%1 %2는 숫자 %3을 문자열로 바꿉니다.",
    "NEW_MC": "새로 변수 만들기",
    "RENAME_MCNUMBER_TITLE": "이름이  \"%1\"인 모든 변수 이름을 다음과 같이 바꿉니다:",
    "RENAME_MCNUMBER_MODAL_TITLE": "다시 변수이름 지정하기",
    "RENAME_VARIABLE_MCNUMBER": "다시 변수이름 지정하기",
    "DELETE_VARIABLE_MCNUMBER": "변수 %1 삭제하기",
    "SET_MC_VARIABLE": "변수 %1 을 %2에 할당하기 ",
    "ALIYUN": "Aliyun",
    "ONENET": "OneNet",
    "ONENETNEW": "OneNet(New)",
    "EASYIOT": "Easy IoT",
    "SHANGHAI": "상해",
    "QINGDAO": "청도",
    "BEIJING": "북경",
    "ZHANGJIAKOU": "장가구",
    "HUHEHAOTE": "호화호특",
    "HANGZHOU": "항주",
    "SHENZHEN": "심천",
    "HONGKONG": "홍콩",
    "SINGAPORE": "싱가포르",
    "SYDNEY": "시드니",
    "KUALALUMPUR": "쿠알라룸푸르",
    "JAKARTA": "자카르타",
    "MUMBAI": "뭄바이",
    "TOKYO": "도쿄",
    "SILICONVALLEY": "실리콘밸리",
    "VIRGINIA": "버지니아",
    "FRANKFURT": "프랑크프루트",
    "LONDON": "런던",
    "DUBAI": "두바이",
    "IOT_PLATFORM": "IoT플랫폼",
    "PARAMS": "변수",
    "SERVER_ATTR": "서버주소",
    "PRODUCTID": "상품ID",
    "DEVICEID": "장치ID",
    "DEVICENAME": "DeviceName",
    "TINYDB_SECRET": "암호",
    "TINYDB_USER": "아이디",
    "APIADDR": "API주소",
    "SOFTWARE_SERIAL": "Software Serial",
    "HARDWARE_SERIAL": "Hardware Serial",
    "HARDWARE_SERIAL1": "Hardware Serial1",
    "HARDWARE_SERIAL2": "Hardware Serial2",
    "HARDWARE_SERIAL3": "Hardware Serial3",
    "CHECKTYPE_TIPS": "The data type received by the input box does not match the output type of the building block",
    "HIGHLIGHT_BLOCK": "hight light block",
    "HIGHLIGHT_ALL_CONFLICT_BLOCKS": "highlight all blocks with warnings",
    "SNAPSHOT": "Snapshot (CTRL+G)",
    "pictureaiPath": "please enter the picture path or URL",
    "pictureaiType": "account type",
    "pictureaiAccountDefault": "default account",
    "pictureaiAccountBaidu": "baidu account",
    "pictureaiwebImgAddr": "web image address",
    "MITRIXICON_EDIT": "light up",
    "MITRIXICON_ERASER": "eraser",
    "MITRIXICON_DUSTBIN": "clear",
    "MITRIXICON_REVERSE": "reverse",
    "MITRIXICON_SAVE": "save",
    "MITRIXICON_EXPORT": "export",
    "MITRIXICON_UPLOAD": "upload",
    "ROLL_MOUSE": "Rolling mouse",
    "MOUSE_ZOOM_IN_OUT": "Scroll the mouse to zoom in and out",
    "UPLOAD_CORRECT_JSON_FILE": "Please upload the json file in the correct format",
    "EXPORT_PROMPT": "There are no custom patterns to export. Please click the save button in the lower right corner to save the custom pattern before attempting to export.",
    "HEART": "Heart",
    "HEART_SMALL": "Small heart",
    "HAPPY": "Happy",
    "SMILE": "Smile",
    "SAD": "Sad",
    "CONFUSED": "Confused",
    "ANGRY": "Angry",
    "SLEEP": "Sleep",
    "SURPRISED": "Surprised",
    "SILLY": "Silly",
    "WONDERFUL": "Wonderful",
    "BORED": "Bored",
    "ROCK": "Rock",
    "ROCK_SMALL": "Small rock",
    "PAPER": "Paper",
    "PAPER_SMALL": "Small paper",
    "SCISSORS": "scissors",
    "SCISSORS_SMALL": "Small scissors",
    "DATA_SOLITLIST": "%1 %2 make %3 %4 with delimiter %5",
    "DATA_SOLITLIST_TYPE_LT": "list from text5",
    "DATA_SOLITLIST_TYPE_TL": "text from list",
    "DATA_LENLIST": "%1 %2 %3 is empty?",
    "DATA_LENGTHLIST": "%1 %2 length of %3",
    "DATA_CREATELIST": "%1 %2 create list %3",
    "DATA_CLEARLIST": "%1 %2 clear list %3"
  },
  "he": {
    "CONTROL_FOREVER": "לעולמים",
    "CONTROL_REPEAT": "חזור  %1 פעמים",
    "CONTROL_IF": "אם %1 אז",
    "CONTROL_ELSE": "אחרת",
    "CONTROL_STOP": "עצור",
    "CONTROL_STOP_ALL": "הכל",
    "CONTROL_STOP_THIS": "תסריט זה",
    "CONTROL_STOP_OTHER": "תסריטים אחרים בדמות",
    "CONTROL_WAIT": "חכה %1 שניות",
    "CONTROL_WAITUNTIL": "חכה עד ש %1",
    "CONTROL_REPEATUNTIL": "חזור עד ש %1",
    "CONTROL_WHILE": "כל עוד %1",
    "CONTROL_FOREACH": "עבור כל %1 ב %2",
    "CONTROL_STARTASCLONE": "כאשר מופעל ככפיל",
    "CONTROL_CREATECLONEOF": "צור כפיל של %1",
    "CONTROL_CREATECLONEOF_MYSELF": "עצמי",
    "CONTROL_DELETETHISCLONE": "מחק כפיל זה",
    "CONTROL_COUNTER": "מונה",
    "CONTROL_INCRCOUNTER": "הגדל מונה",
    "CONTROL_CLEARCOUNTER": "אפס מונה",
    "CONTROL_ALLATONCE": "הכל בבת אחת",
    "DATA_SETVARIABLETO": "קבע %1 ל %2",
    "DATA_CHANGEVARIABLEBY": "שנה %1 ב %2",
    "DATA_SHOWVARIABLE": "הצג משתנה %1",
    "DATA_HIDEVARIABLE": "הסתר משתנה %1",
    "DATA_ADDTOLIST": "הוסף %1 ל %2",
    "DATA_DELETEOFLIST": "מחק פריט %1 מתוך %2",
    "DATA_DELETEALLOFLIST": "מחק הכל מ%1",
    "DATA_INSERTATLIST": "הכנס %1 במקום %2 של %3",
    "DATA_REPLACEITEMOFLIST": "קבע פריט %1 של %2 ל %3",
    "DATA_ITEMOFLIST": "פריט %1 של %2",
    "DATA_ITEMNUMOFLIST": "# פריט של %1 ב- %2",
    "DATA_LENGTHOFLIST": "האורך של %1",
    "DATA_LISTCONTAINSITEM": "%1 מכיל %2 ?",
    "DATA_SHOWLIST": "הצג רשימה %1",
    "DATA_HIDELIST": "הסתר רשימה %1",
    "DATA_INDEX_ALL": "הכל",
    "DATA_INDEX_LAST": "אחרון",
    "DATA_INDEX_RANDOM": "אקראי",
    "EVENT_WHENFLAGCLICKED": "כאשר לוחצים על %1",
    "EVENT_WHENTHISSPRITECLICKED": "כאשר לוחצים על דמות זו",
    "EVENT_WHENSTAGECLICKED": "כאשר לוחצים על הבמה",
    "EVENT_WHENTOUCHINGOBJECT": "כאשר דמות זו נוגעת ב %1",
    "EVENT_WHENBROADCASTRECEIVED": "כאשר מתקבל מסר %1",
    "EVENT_WHENBACKDROPSWITCHESTO": "כאשר הרקע משתנה ל %1",
    "EVENT_WHENGREATERTHAN": "כאשר %1 > %2",
    "EVENT_WHENGREATERTHAN_TIMER": "שעון עצר",
    "EVENT_WHENGREATERTHAN_LOUDNESS": "עוצמת קול",
    "EVENT_BROADCAST": "שדר מסר %1",
    "EVENT_BROADCASTANDWAIT": "שדר מסר %1 וחכה",
    "EVENT_WHENKEYPRESSED": "כאשר נלחץ מקש %1",
    "EVENT_WHENKEYPRESSED_SPACE": "רווח",
    "EVENT_WHENKEYPRESSED_LEFT": "חץ שמאלי",
    "EVENT_WHENKEYPRESSED_RIGHT": "חץ ימני",
    "EVENT_WHENKEYPRESSED_DOWN": "חץ מטה",
    "EVENT_WHENKEYPRESSED_UP": "חץ מעלה",
    "EVENT_WHENKEYPRESSED_ANY": "כל",
    "LOOKS_SAYFORSECS": "אמור %1 למשך %2 שניות",
    "LOOKS_SAY": "אמור %1",
    "LOOKS_HELLO": "שלום!",
    "LOOKS_THINKFORSECS": "חשוב %1 למשך %2 שניות",
    "LOOKS_THINK": "חשוב %1",
    "LOOKS_HMM": "הממ...",
    "LOOKS_SHOW": "הצג",
    "LOOKS_HIDE": "הסתר",
    "LOOKS_HIDEALLSPRITES": "הסתר את כל הדמויות",
    "LOOKS_EFFECT_COLOR": "צבע",
    "LOOKS_EFFECT_FISHEYE": "עין דג",
    "LOOKS_EFFECT_WHIRL": "סחרור",
    "LOOKS_EFFECT_PIXELATE": "פיקסלים",
    "LOOKS_EFFECT_MOSAIC": "פסיפס",
    "LOOKS_EFFECT_BRIGHTNESS": "בהירות",
    "LOOKS_EFFECT_GHOST": "רוח רפאים",
    "LOOKS_CHANGEEFFECTBY": "שנה אפקט %1 ב %2",
    "LOOKS_SETEFFECTTO": "קבע אפקט %1 ל %2",
    "LOOKS_CLEARGRAPHICEFFECTS": "נקה אפקטים",
    "LOOKS_CHANGESIZEBY": "שנה גודל ב %1",
    "LOOKS_SETSIZETO": "קבע גודל ל %1 %",
    "LOOKS_SIZE": "גודל",
    "LOOKS_CHANGESTRETCHBY": "שנה מתיחה ב %1",
    "LOOKS_SETSTRETCHTO": "קבע מתיחה ל %1",
    "LOOKS_SWITCHCOSTUMETO": "קבע תלבושת ל %1",
    "LOOKS_NEXTCOSTUME": "התלבושת הבאה",
    "LOOKS_SWITCHBACKDROPTO": "קבע רקע ל %1",
    "LOOKS_GOTOFRONTBACK": "העבר לשכבה ה %1",
    "LOOKS_GOTOFRONTBACK_FRONT": "קדמית",
    "LOOKS_GOTOFRONTBACK_BACK": "אחורית",
    "LOOKS_GOFORWARDBACKWARDLAYERS": "העבר %2 שכבות %1",
    "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "קדימה",
    "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "אחורה",
    "LOOKS_BACKDROPNUMBERNAME": "%1 רקע",
    "LOOKS_COSTUMENUMBERNAME": "%1 תלבושת",
    "LOOKS_NUMBERNAME_NUMBER": "מספר",
    "LOOKS_NUMBERNAME_NAME": "שם",
    "LOOKS_SWITCHBACKDROPTOANDWAIT": "החלף רקע ל %1 וחכה",
    "LOOKS_NEXTBACKDROP_BLOCK": "הרקע הבא",
    "LOOKS_NEXTBACKDROP": "הרקע הבא",
    "LOOKS_PREVIOUSBACKDROP": "הרקע הקודם",
    "LOOKS_RANDOMBACKDROP": "רקע אקראי",
    "MOTION_MOVESTEPS": "זוז %1 צעדים",
    "MOTION_TURNLEFT": "הסתובב %1 %2 מעלות",
    "MOTION_TURNRIGHT": "הסתובב %1 %2 מעלות",
    "MOTION_POINTINDIRECTION": "פנה לכיוון %1",
    "MOTION_POINTTOWARDS": "פנה לכיוון של %1",
    "MOTION_POINTTOWARDS_POINTER": "מצביע העכבר",
    "MOTION_POINTTOWARDS_RANDOM": "כיוון אקראי",
    "MOTION_GOTO": "קפוץ אל %1",
    "MOTION_GOTO_POINTER": "מצביע העכבר",
    "MOTION_GOTO_RANDOM": "מיקום אקראי",
    "MOTION_GOTOXY": "קפוץ אל x: %1 y: %2",
    "MOTION_GLIDESECSTOXY": "גלוש %1 שניות ל x: %2 y: %3",
    "MOTION_GLIDETO": "גלוש %1 שניות ל %2",
    "MOTION_GLIDETO_POINTER": "סמן העכבר",
    "MOTION_GLIDETO_RANDOM": "מיקום אקראי",
    "MOTION_CHANGEXBY": "שנה את ערך x ב %1",
    "MOTION_SETX": "קבע את ערך x ל %1",
    "MOTION_CHANGEYBY": "שנה את ערך y ב %1",
    "MOTION_SETY": "קבע את ערך y ל %1",
    "MOTION_IFONEDGEBOUNCE": "בנגיעה במסגרת שנה כיוון",
    "MOTION_SETROTATIONSTYLE": "קבע אופן סיבוב %1",
    "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "שמאל-ימין",
    "MOTION_SETROTATIONSTYLE_DONTROTATE": "בטל סיבוב",
    "MOTION_SETROTATIONSTYLE_ALLAROUND": "מסביב",
    "MOTION_XPOSITION": "מיקום על ציר x",
    "MOTION_YPOSITION": "מיקום על ציר Y",
    "MOTION_DIRECTION": "כיוון",
    "MOTION_SCROLLRIGHT": "גלול ימינה %1",
    "MOTION_SCROLLUP": "גלול למעלה %1",
    "MOTION_ALIGNSCENE": "ישר סצנה %1",
    "MOTION_ALIGNSCENE_BOTTOMLEFT": "למטה־משמאל",
    "MOTION_ALIGNSCENE_BOTTOMRIGHT": "למטה־מימין",
    "MOTION_ALIGNSCENE_MIDDLE": "אמצעי",
    "MOTION_ALIGNSCENE_TOPLEFT": "למעלה־משמאל",
    "MOTION_ALIGNSCENE_TOPRIGHT": "למעלה־מימין",
    "MOTION_XSCROLL": "גלילה בציר x",
    "MOTION_YSCROLL": "גלילה בציר y",
    "MOTION_STAGE_SELECTED": "נבחרה במה: אין לבנים לתנועה",
    "OPERATORS_ADD": "%1 + %2",
    "OPERATORS_SUBTRACT": "%2 - %1",
    "OPERATORS_MULTIPLY": "%1 * %2",
    "OPERATORS_DIVIDE": "%2 / %1",
    "OPERATORS_RANDOM": "בחר מספר אקראי בין %1 ל %2",
    "OPERATORS_GT": "%1 > %2",
    "OPERATORS_GT_EQUALS": "",
    "OPERATORS_LT": "%1 < %2",
    "OPERATORS_LT_EQUALS": "",
    "OPERATORS_EQUALS": "%1 = %2",
    "OPERATORS_AND": "%1 וגם %2",
    "OPERATORS_OR": "%1 או %2",
    "OPERATORS_NOT": "לא %1",
    "OPERATORS_JOIN": "חבר %1 %2",
    "OPERATORS_JOIN_APPLE": "תפוח",
    "OPERATORS_JOIN_BANANA": "בננה",
    "OPERATORS_LETTEROF": "אות %1 של %2",
    "OPERATORS_LETTEROF_APPLE": "ת",
    "OPERATORS_LENGTH": "האורך של %1",
    "OPERATORS_CONTAINS": "%1 מכיל %2 ?",
    "OPERATORS_MOD": "%1 מודולו %2",
    "OPERATORS_ROUND": "עגל %1",
    "OPERATORS_MATHOP": "%1 של %2",
    "OPERATORS_MATHOP_ABS": "ערך מוחלט",
    "OPERATORS_MATHOP_FLOOR": "עיגול למטה",
    "OPERATORS_MATHOP_CEILING": "עיגול למעלה",
    "OPERATORS_MATHOP_SQRT": "שורש ריבועי",
    "OPERATORS_MATHOP_SIN": "sin",
    "OPERATORS_MATHOP_COS": "cos",
    "OPERATORS_MATHOP_TAN": "tan",
    "OPERATORS_MATHOP_ASIN": "asin",
    "OPERATORS_MATHOP_ACOS": "acos",
    "OPERATORS_MATHOP_ATAN": "atan",
    "OPERATORS_MATHOP_LN": "ln",
    "OPERATORS_MATHOP_LOG": "log",
    "OPERATORS_MATHOP_EEXP": "‏e בחזקה",
    "OPERATORS_MATHOP_10EXP": "‏10 בחזקה",
    "PROCEDURES_DEFINITION": "הגדר %1",
    "SENSING_TOUCHINGOBJECT": "נוגע ב %1?",
    "SENSING_TOUCHINGOBJECT_POINTER": "סמן העכבר",
    "SENSING_TOUCHINGOBJECT_EDGE": "קצה",
    "SENSING_TOUCHINGCOLOR": "נוגע בצבע  %1?",
    "SENSING_COLORISTOUCHINGCOLOR": "צבע %1 נוגע בצבע %2?",
    "SENSING_DISTANCETO": "מרחק אל %1",
    "SENSING_DISTANCETO_POINTER": "סמן העכבר",
    "SENSING_ASKANDWAIT": "שאל %1 והמתן",
    "SENSING_ASK_TEXT": "מה השם שלך?",
    "SENSING_ANSWER": "תשובה",
    "SENSING_KEYPRESSED": "מקש %1 נלחץ?",
    "SENSING_MOUSEDOWN": "עכבר נלחץ?",
    "SENSING_MOUSEX": "מיקום עכבר בציר x",
    "SENSING_MOUSEY": "מיקום עכבר בציר y",
    "SENSING_SETDRAGMODE": "קבע מצב גרירה ל %1",
    "SENSING_SETDRAGMODE_DRAGGABLE": "ניתן לגרירה",
    "SENSING_SETDRAGMODE_NOTDRAGGABLE": "לא ניתן לגרירה",
    "SENSING_LOUDNESS": "עוצמת קול",
    "SENSING_LOUD": "עוצמת קול?",
    "SENSING_TIMER": "שעון עצר",
    "SENSING_RESETTIMER": "אפס מונה",
    "SENSING_OF": "%1 של %2",
    "SENSING_OF_XPOSITION": "מיקום על ציר x",
    "SENSING_OF_YPOSITION": "מיקום על ציר y",
    "SENSING_OF_DIRECTION": "כיוון",
    "SENSING_OF_COSTUMENUMBER": "# תלבושת",
    "SENSING_OF_COSTUMENAME": "שם תלבושת",
    "SENSING_OF_SIZE": "גודל",
    "SENSING_OF_VOLUME": "עוצמת קול",
    "SENSING_OF_BACKDROPNUMBER": "# רקע",
    "SENSING_OF_BACKDROPNAME": "שם רקע",
    "SENSING_OF_STAGE": "במה",
    "SENSING_CURRENT": "%1 נוכחית",
    "SENSING_CURRENT_YEAR": "שנה",
    "SENSING_CURRENT_MONTH": "חודש",
    "SENSING_CURRENT_DATE": "תאריך",
    "SENSING_CURRENT_DAYOFWEEK": "יום בשבוע",
    "SENSING_CURRENT_HOUR": "שעה",
    "SENSING_CURRENT_MINUTE": "דקה",
    "SENSING_CURRENT_SECOND": "שניה",
    "SENSING_DAYSSINCE2000": "ימים מאז 2000",
    "SENSING_USERNAME": "שם משתמש",
    "SENSING_USERID": "זהות המשתמש",
    "SOUND_PLAY": "נגן צליל %1",
    "SOUND_PLAYUNTILDONE": "נגן צליל %1 עד לסיום",
    "SOUND_STOPALLSOUNDS": "הפסק את כל הצלילים",
    "SOUND_SETEFFECTO": "קבע אפקט %1 ל %2",
    "SOUND_CHANGEEFFECTBY": "שנה אפקט %1 ב %2",
    "SOUND_CLEAREFFECTS": "נקה אפקטים קוליים",
    "SOUND_EFFECTS_PITCH": "גובה צליל",
    "SOUND_EFFECTS_PAN": "ערוץ שמע שמאלי/ימני",
    "SOUND_CHANGEVOLUMEBY": "שנה עוצמת קול ב %1",
    "SOUND_SETVOLUMETO": "קבע עוצמת קול ל %1%",
    "SOUND_VOLUME": "עוצמת קול",
    "SOUND_RECORD": "הקלט...",
    "CATEGORY_MOTION": "תנועה",
    "CATEGORY_LOOKS": "מראה",
    "CATEGORY_SOUND": "צלילים",
    "CATEGORY_EVENTS": "אירועים",
    "CATEGORY_CONTROL": "בקרה",
    "CATEGORY_SENSING": "חיישנים",
    "CATEGORY_OPERATORS": "מפעילים",
    "CATEGORY_VARIABLES": "משתנים",
    "CATEGORY_MYBLOCKS": "הלבנים שלי",
    "CATEGORY_LISTS": "",
    "CATEGORY_TUPLE": "",
    "CATEGORY_DICTIONARYS": "",
    "CATEGORY_NUMBERS": "",
    "CATEGORY_TEXT": "",
    "DUPLICATE": "שכפל",
    "DELETE": "מחק",
    "ADD_COMMENT": "הוספת תגובה",
    "REMOVE_COMMENT": "מחק תגובה",
    "DELETE_BLOCK": "מחק לבנה",
    "DELETE_X_BLOCKS": "מחק %1 לבנים ",
    "DELETE_ALL_BLOCKS": "למחוק את כל הלבנים של %1?",
    "CLEAN_UP": "נקה לבנים",
    "HELP": "עזרה",
    "UNDO": "בטל",
    "REDO": "בצע שוב",
    "EDIT_PROCEDURE": "ערוך",
    "SHOW_PROCEDURE_DEFINITION": "עבור להגדרה",
    "WORKSPACE_COMMENT_DEFAULT_TEXT": "כתבו משהו...",
    "COLOUR_HUE_LABEL": "צבע",
    "COLOUR_SATURATION_LABEL": "רווי צבע",
    "COLOUR_BRIGHTNESS_LABEL": "בהירות",
    "CHANGE_VALUE_TITLE": "שנה ערך:",
    "RENAME_VARIABLE": "שנה שם משתנה",
    "RENAME_VARIABLE_STRING": "",
    "RENAME_VARIABLE_NUMBER": "",
    "RENAME_VARIABLE_LIST": "",
    "RENAME_VARIABLE_TITLE": "שנה שמם של כל המשתנים \"%1\" ל:",
    "RENAME_VARIABLE_MODAL_TITLE": "שנה שם משתנה",
    "NEW_VARIABLE": "צור משתנה",
    "NEW_VARIABLE_TITLE": "שם המשתנה החדש",
    "VARIABLE_MODAL_TITLE": "משתנה חדש",
    "VARIABLE_ALREADY_EXISTS": "משתנה בשם \"%1\" כבר קיים",
    "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "משתנה בשם  \"%1\" כבר קיים למשתנה אחר %2\"",
    "DELETE_VARIABLE_CONFIRMATION": "האם  למחוק את %1 השימושים של המשתנה %2 ?",
    "CANNOT_DELETE_VARIABLE_PROCEDURE": "לא ניתן למחוק את המשתנה \"%1\" מפני שזה חלק מהגדרת הפונקציה \"%2\"",
    "DELETE_VARIABLE": "מחק את המשתנה \"%1\"",
    "DELETE_VARIABLE_STRING": "",
    "DELETE_VARIABLE_NUMBER": "",
    "DELETE_VARIABLE_LIST": "",
    "NEW_PROCEDURE": "צור לבנה",
    "PROCEDURE_ALREADY_EXISTS": "פרוצדורה בשם  \"%1\" כבר קיימת",
    "PROCEDURE_DEFAULT_NAME": "שם הלבנה",
    "NEW_LIST": "צור רשימה",
    "NEW_LIST_TITLE": "שם הרשימה",
    "LIST_MODAL_TITLE": "רשימה חדשה",
    "LIST_ALREADY_EXISTS": "רשימה בשם \"%1\" כבר קיימת",
    "RENAME_LIST_TITLE": "שנה את שם כל  רשימות ־\"%1\"  ל:",
    "RENAME_LIST_MODAL_TITLE": "שנה שם רשימה",
    "DEFAULT_LIST_ITEM": "דבר",
    "DELETE_LIST": "מחק את הרשימה %1",
    "RENAME_LIST": "תן שם חדש  לרשימה",
    "NEW_BROADCAST_MESSAGE": "מסר חדש",
    "NEW_BROADCAST_MESSAGE_TITLE": "צור מסר חדש",
    "BROADCAST_MODAL_TITLE": "מסר חדש",
    "DEFAULT_BROADCAST_MESSAGE_NAME": "מסר 1",
    "DELETE_PROCEDURE": "",
    "OK": "",
    "PROMPT": "",
    "STOP": "",
    "NEW_STRING": "",
    "NEW_NUMBER_VARIABLE": "",
    "WIFI": "",
    "USERNAME": "",
    "PWD": "",
    "IOT_SERVICE": "",
    "IOT_ID": "",
    "IOT_PWD": "",
    "IOT_TOPIC": "",
    "MORE_TOPIC": "",
    "OBLOQ_HTTP": "",
    "OBLOQ_IP": "",
    "OBLOQ_PORT": "",
    "ADD_TOPIC": "",
    "DELETE_TOPIC": "",
    "IOT_SERVER": "",
    "CHINA": "",
    "GLOBAL": "",
    "LOW_C": "",
    "LOW_C$": "",
    "LOW_D": "",
    "LOW_D$": "",
    "LOW_E": "",
    "LOW_F": "",
    "LOW_F$": "",
    "LOW_G": "",
    "LOW_G$": "",
    "LOW_A": "",
    "LOW_A$": "",
    "LOW_B": "",
    "MIDDLE_C": "",
    "MIDDLE_C$": "",
    "MIDDLE_D": "",
    "MIDDLE_D$": "",
    "MIDDLE_E": "",
    "MIDDLE_F": "",
    "MIDDLE_F$": "",
    "MIDDLE_G": "",
    "MIDDLE_G$": "",
    "MIDDLE_A": "",
    "MIDDLE_A$": "",
    "MIDDLE_B": "",
    "HIGH_C": "",
    "HIGH_C$": "",
    "HIGH_D": "",
    "HIGH_D$": "",
    "HIGH_E": "",
    "HIGH_F": "",
    "HIGH_F$": "",
    "HIGH_G": "",
    "HIGH_G$": "",
    "HIGH_A": "",
    "HIGH_A$": "",
    "HIGH_B": "",
    "OPERATORS_STRINGTONUMBER": "",
    "INTEGER": "",
    "DECIMAL": "",
    "OPERATORS_NUMTOASCII": "",
    "OPERATORS_STRINGTOASCII": "",
    "OPERATORS_NUMTOSTRING": "",
    "OPERATORS_MAP": "",
    "OPERATORS_CONSTRAIN": "",
    "STEPS_PER_TURN": "",
    "ROTATE_SPEED": "",
    "NEW_AI": "",
    "ASK_AND_PAINT": "",
    "GET_IDENTIFIED_NUM": "",
    "READ_NUM_AND_SAY": "",
    "IMAGE_ADDR": "",
    "IMAGE_PREVIEW": "",
    "IMAGE_OPEN": "",
    "IMAGE_SIZE": "",
    "IMAGE_WIDTH": "",
    "IMAGE_HEIGHT": "",
    "VARIABLE": "",
    "VARIABLE_LIST": "",
    "SET_STRING_VARIABLE": "",
    "STRING_START_WITH": "",
    "DICTIONARY_HEIGHT_FIRST": "",
    "DICTIONARY_HEIGHT_TWO": "",
    "DICTIONARY_HEIGHT_THREE": "",
    "DICTIONARY_HEIGHT_FOUR": "",
    "DATA_MPITEMOFLIST": "",
    "DATA_MPINTERCEPTITEM": "",
    "DATA_MPINSERTATLIST": "",
    "DATA_MPSETVALUEATLIST": "",
    "DATA_MPINSERTATLIST_INSERT": "",
    "DATA_MPINSERTATLIST_CHANGE": "",
    "DATA_MPINSERTATLIST_FOUR": "",
    "DATA_MPINSERTATLIST_FIVE": "",
    "DATA_MPINSERTATLIST_SIX": "",
    "DATA_MPINSERTATLIST_SEVEN": "",
    "DATA_MPINSERTATLIST_EIGHT": "",
    "DATA_MPINSERTATLIST_EIGHT_FALSE": "",
    "DATA_MPINSERTATLIST_EIGHT_TRUE": "",
    "DATA_MPINSERTATLIST_EIGHT_REVERSE": "",
    "OPERATORS_RANDOMA": "",
    "OPERATORS_MODA": "",
    "OPERATORS_ROUNDA": "",
    "OPERATORS_MATHOPA": "",
    "OPERATORS_MATHOP_ABSA": "",
    "OPERATORS_MATHOP_FLOORA": "",
    "OPERATORS_MATHOP_CEILINGA": "",
    "OPERATORS_MATHOP_SQRTA": "",
    "OPERATORS_MATHOP_FIVE": "",
    "OPERATORS_MATHOP_SIX": "",
    "OPERATORS_MATHOP_SEVEN": "",
    "OPERATORS_MATHOP_LENGHT": "",
    "OPERATORS_MATHOP_MAXMIN": "",
    "OPERATORS_MATHOP_MAXMIN_MAX": "",
    "OPERATORS_MATHOP_MAXMIN_MIN": "",
    "OPERATORS_TEXTS_ONE": "",
    "OPERATORS_TEXTS_TWO": "",
    "OPERATORS_TEXTS_THREE": "",
    "OPERATORS_TEXTS_FOUR": "",
    "OPERATORS_TEXTS_FOUR_NEW": "",
    "OPERATORS_TEXTS_NEW": "",
    "OPERATORS_TEXTS_NEW_OP": "",
    "OPERATORS_TEXTS_FOUR_ONE": "",
    "OPERATORS_TEXTS_FOUR_TWO": "",
    "OPERATORS_TEXTS_FOUR_THREE": "",
    "OPERATORS_TEXTS_FOUR_FOUR": "",
    "OPERATORS_TEXTS_FOUR_F": "",
    "OPERATORS_TEXTS_FOUR_L": "",
    "OPERATORS_STRINGTONUMBERA": "",
    "OPERATORS_NUMTOASCIIA": "",
    "OPERATORS_STRINGTOASCIIA": "",
    "OPERATORS_NUMTOSTRINGA": "",
    "NEW_MC": "",
    "RENAME_MCNUMBER_TITLE": "",
    "RENAME_MCNUMBER_MODAL_TITLE": "",
    "RENAME_VARIABLE_MCNUMBER": "",
    "DELETE_VARIABLE_MCNUMBER": "",
    "SET_MC_VARIABLE": "",
    "TUPLE_ONE": "",
    "TUPLE_TWO": "",
    "ALIYUN": "",
    "ONENET": "",
    "EASYIOT": "",
    "SHANGHAI": "",
    "QINGDAO": "",
    "BEIJING": "",
    "ZHANGJIAKOU": "",
    "HUHEHAOTE": "",
    "HANGZHOU": "",
    "SHENZHEN": "",
    "HONGKONG": "",
    "SINGAPORE": "",
    "SYDNEY": "",
    "KUALALUMPUR": "",
    "JAKARTA": "",
    "MUMBAI": "",
    "TOKYO": "",
    "SILICONVALLEY": "",
    "VIRGINIA": "",
    "FRANKFURT": "",
    "LONDON": "",
    "DUBAI": "",
    "IOT_PLATFORM": "",
    "PARAMS": "",
    "SERVER_ATTR": "",
    "PRODUCTID": "",
    "DEVICEID": "",
    "TINYDB_SECRET": "",
    "TINYDB_USER": "",
    "APIADDR": "",
    "SOFTWARE_SERIAL": "",
    "HARDWARE_SERIAL": "",
    "HARDWARE_SERIAL1": "",
    "HARDWARE_SERIAL2": "",
    "HARDWARE_SERIAL3": ""
  },
  "hr": {
    "CONTROL_FOREVER": "ponavljaj",
    "CONTROL_REPEAT": "ponovi %1",
    "CONTROL_IF": "ako %1 onda",
    "CONTROL_ELSE": "inače",
    "CONTROL_STOP": "zaustavi",
    "CONTROL_STOP_ALL": "sve",
    "CONTROL_STOP_THIS": "ovaj program",
    "CONTROL_STOP_OTHER": "druge programe lika",
    "CONTROL_WAIT": "čekaj %1 sekundi",
    "CONTROL_WAITUNTIL": "čekaj do %1",
    "CONTROL_REPEATUNTIL": "ponavljaj dok nije %1",
    "CONTROL_WHILE": "dok je %1",
    "CONTROL_FOREACH": "za svaki %1 u %2",
    "CONTROL_STARTASCLONE": "kada krećem kao klon",
    "CONTROL_CREATECLONEOF": "kloniraj %1",
    "CONTROL_CREATECLONEOF_MYSELF": "ja",
    "CONTROL_DELETETHISCLONE": "izbriši klona",
    "CONTROL_COUNTER": "brojač",
    "CONTROL_INCRCOUNTER": "uvečaj brojač",
    "CONTROL_CLEARCOUNTER": "obriši brojač",
    "CONTROL_ALLATONCE": "sve odjednom",
    "DATA_SETVARIABLETO": "postavi %1 na %2",
    "DATA_CHANGEVARIABLEBY": "promijeni %1 za %2",
    "DATA_SHOWVARIABLE": "prikaži varijablu %1",
    "DATA_HIDEVARIABLE": "sakrij varijablu %1",
    "DATA_ADDTOLIST": "dodaj %1 u %2",
    "DATA_DELETEOFLIST": "izbriši %1 iz %2",
    "DATA_DELETEALLOFLIST": "izbriši sve %1",
    "DATA_INSERTATLIST": "umetni %1 na %2 u %3",
    "DATA_REPLACEITEMOFLIST": "zamijeni %1 na %2 s %3",
    "DATA_ITEMOFLIST": "element %1 od %2",
    "DATA_ITEMNUMOFLIST": "element # od %1 u %2",
    "DATA_LENGTHOFLIST": "dužina %1",
    "DATA_LISTCONTAINSITEM": "Sadrži li %1 %2?",
    "DATA_SHOWLIST": "prikaži listu %1",
    "DATA_HIDELIST": "sakrij listu %1",
    "DATA_INDEX_ALL": "sve",
    "DATA_INDEX_LAST": "posljednje",
    "DATA_INDEX_RANDOM": "nasumično",
    "EVENT_WHENFLAGCLICKED": "Kada je kliknuta %1 ",
    "EVENT_WHENTHISSPRITECLICKED": "Kada je lik kliknut",
    "EVENT_WHENSTAGECLICKED": "kada je kliknuto na pozadinu",
    "EVENT_WHENTOUCHINGOBJECT": "kada lik dodiruje %1",
    "EVENT_WHENBROADCASTRECEIVED": "kada primim %1",
    "EVENT_WHENBACKDROPSWITCHESTO": "kada se pozadina promijeni u %1",
    "EVENT_WHENGREATERTHAN": "kada je %1 > %2",
    "EVENT_WHENGREATERTHAN_TIMER": "štoperica",
    "EVENT_WHENGREATERTHAN_LOUDNESS": "glasnoća",
    "EVENT_BROADCAST": "pošalji %1",
    "EVENT_BROADCASTANDWAIT": "pošalji %1 i čekaj",
    "EVENT_WHENKEYPRESSED": "kada je tipka %1 pritisnuta",
    "EVENT_WHENKEYPRESSED_SPACE": "razmaknica",
    "EVENT_WHENKEYPRESSED_LEFT": "strelica lijevo",
    "EVENT_WHENKEYPRESSED_RIGHT": "strelica desno",
    "EVENT_WHENKEYPRESSED_DOWN": "strelica dolje",
    "EVENT_WHENKEYPRESSED_UP": "strelica gore",
    "EVENT_WHENKEYPRESSED_ANY": "bilo koja",
    "LOOKS_SAYFORSECS": "govori %1 %2 sekundi",
    "LOOKS_SAY": "reci %1",
    "LOOKS_HELLO": "Bok!",
    "LOOKS_THINKFORSECS": "razmišljaj %1 %2 sekundi",
    "LOOKS_THINK": "misli %1",
    "LOOKS_HMM": "Hmm...",
    "LOOKS_SHOW": "prikaži",
    "LOOKS_HIDE": "sakrij",
    "LOOKS_HIDEALLSPRITES": "sakrij sve likove",
    "LOOKS_EFFECT_COLOR": "boja",
    "LOOKS_EFFECT_FISHEYE": "riblje oko",
    "LOOKS_EFFECT_WHIRL": "vrtlog",
    "LOOKS_EFFECT_PIXELATE": "pozrnati",
    "LOOKS_EFFECT_MOSAIC": "mozaik",
    "LOOKS_EFFECT_BRIGHTNESS": "osvjetljenje",
    "LOOKS_EFFECT_GHOST": "duh",
    "LOOKS_CHANGEEFFECTBY": "promijeni efekt %1 za %2",
    "LOOKS_SETEFFECTTO": "promijeni efekt %1 na %2",
    "LOOKS_CLEARGRAPHICEFFECTS": "ukloni grafičke efekte",
    "LOOKS_CHANGESIZEBY": "promijeni veličinu za %1",
    "LOOKS_SETSIZETO": "postavi veličinu na %1 %",
    "LOOKS_SIZE": "veličina",
    "LOOKS_CHANGESTRETCHBY": "promijeni uvećanje za %1",
    "LOOKS_SETSTRETCHTO": "postavi uvećanje na %1",
    "LOOKS_SWITCHCOSTUMETO": "promijeni kostim u %1",
    "LOOKS_NEXTCOSTUME": "sljedeći kostim",
    "LOOKS_SWITCHBACKDROPTO": "promijeni pozadinu na %1",
    "LOOKS_GOTOFRONTBACK": "idi na sloj %1",
    "LOOKS_GOTOFRONTBACK_FRONT": "prednji",
    "LOOKS_GOTOFRONTBACK_BACK": "stražnji",
    "LOOKS_GOFORWARDBACKWARDLAYERS": "idi %1 %2 sloja",
    "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "naprijed",
    "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "natrag",
    "LOOKS_BACKDROPNUMBERNAME": "pozadina %1",
    "LOOKS_COSTUMENUMBERNAME": "kostim %1",
    "LOOKS_NUMBERNAME_NUMBER": "broj",
    "LOOKS_NUMBERNAME_NAME": "ime",
    "LOOKS_SWITCHBACKDROPTOANDWAIT": "promijeni pozadinu u %1 i čekaj",
    "LOOKS_NEXTBACKDROP_BLOCK": "sljedeća pozadina",
    "LOOKS_NEXTBACKDROP": "sljedeća pozadina",
    "LOOKS_PREVIOUSBACKDROP": "prethodna pozadina",
    "LOOKS_RANDOMBACKDROP": "nasumična pozadina",
    "MOTION_MOVESTEPS": "idi %1 koraka",
    "MOTION_TURNLEFT": "skreni %1 %2 stupnjeva",
    "MOTION_TURNRIGHT": "skreni %1 %2 stupnjeva",
    "MOTION_POINTINDIRECTION": "okreni se u smjeru %1",
    "MOTION_POINTTOWARDS": "okreni se prema %1",
    "MOTION_POINTTOWARDS_POINTER": "pokazivač miša",
    "MOTION_POINTTOWARDS_RANDOM": "nasumičan smjer",
    "MOTION_GOTO": "idi do %1",
    "MOTION_GOTO_POINTER": "pokazivač miša",
    "MOTION_GOTO_RANDOM": "nasumična pozicija",
    "MOTION_GOTOXY": "idi na x: %1 y: %2",
    "MOTION_GLIDESECSTOXY": "klizi %1 sekundi do x: %2 y: %3",
    "MOTION_GLIDETO": "klizi %1 sekundi do %2",
    "MOTION_GLIDETO_POINTER": "pokazivač miša",
    "MOTION_GLIDETO_RANDOM": "nasumična pozicija",
    "MOTION_CHANGEXBY": "promijeni x za %1",
    "MOTION_SETX": "postavi x na %1",
    "MOTION_CHANGEYBY": "promijeni y za %1",
    "MOTION_SETY": "postavi y na %1",
    "MOTION_IFONEDGEBOUNCE": "ako si na rubu, okreni se",
    "MOTION_SETROTATIONSTYLE": "postavi stil rotacije %1",
    "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "lijevo-desno",
    "MOTION_SETROTATIONSTYLE_DONTROTATE": "ne rotiraj",
    "MOTION_SETROTATIONSTYLE_ALLAROUND": "sve unaokolo",
    "MOTION_XPOSITION": "x položaj",
    "MOTION_YPOSITION": "y položaj",
    "MOTION_DIRECTION": "smjer",
    "MOTION_SCROLLRIGHT": "zrcali desno %1",
    "MOTION_SCROLLUP": "zrcali gore %1",
    "MOTION_ALIGNSCENE": "poravnanje scene %1",
    "MOTION_ALIGNSCENE_BOTTOMLEFT": "dolje-lijevo",
    "MOTION_ALIGNSCENE_BOTTOMRIGHT": "dolje-desno",
    "MOTION_ALIGNSCENE_MIDDLE": "sredina",
    "MOTION_ALIGNSCENE_TOPLEFT": "gore-lijevo",
    "MOTION_ALIGNSCENE_TOPRIGHT": "gore-desno",
    "MOTION_XSCROLL": "x klizanje",
    "MOTION_YSCROLL": "y klizanje",
    "MOTION_STAGE_SELECTED": "Pozornica je izabrana: nema blokova za kretanje",
    "OPERATORS_ADD": "%1 + %2",
    "OPERATORS_SUBTRACT": "%1 - %2",
    "OPERATORS_MULTIPLY": "%1 * %2",
    "OPERATORS_DIVIDE": "%1 / %2",
    "OPERATORS_RANDOM": "slučajan broj od %1 do %2",
    "OPERATORS_GT": "%1 > %2",
    "OPERATORS_GT_EQUALS": "",
    "OPERATORS_LT": "%1 < %2",
    "OPERATORS_LT_EQUALS": "",
    "OPERATORS_EQUALS": "%1 = %2",
    "OPERATORS_AND": "%1 i %2",
    "OPERATORS_OR": "%1 ili %2",
    "OPERATORS_NOT": "nije %1",
    "OPERATORS_JOIN": "spoji %1 %2",
    "OPERATORS_JOIN_APPLE": "jabuka",
    "OPERATORS_JOIN_BANANA": "banana",
    "OPERATORS_LETTEROF": "slovo %1 od %2",
    "OPERATORS_LETTEROF_APPLE": "a",
    "OPERATORS_LENGTH": "dužina %1",
    "OPERATORS_CONTAINS": "%1 sadrži %2",
    "OPERATORS_MOD": "%1 mod %2",
    "OPERATORS_ROUND": "zaokruži %1",
    "OPERATORS_MATHOP": "%1 od %2",
    "OPERATORS_MATHOP_ABS": "apsolutno",
    "OPERATORS_MATHOP_FLOOR": "donja vrijednost",
    "OPERATORS_MATHOP_CEILING": "gornja vrijednost",
    "OPERATORS_MATHOP_SQRT": "korijen",
    "OPERATORS_MATHOP_SIN": "sin",
    "OPERATORS_MATHOP_COS": "cos",
    "OPERATORS_MATHOP_TAN": "tan",
    "OPERATORS_MATHOP_ASIN": "asin",
    "OPERATORS_MATHOP_ACOS": "acos",
    "OPERATORS_MATHOP_ATAN": "atan",
    "OPERATORS_MATHOP_LN": "ln",
    "OPERATORS_MATHOP_LOG": "log",
    "OPERATORS_MATHOP_EEXP": "e ^",
    "OPERATORS_MATHOP_10EXP": "10 ^",
    "PROCEDURES_DEFINITION": "definiraj %1",
    "SENSING_TOUCHINGOBJECT": "dodiruje %1?",
    "SENSING_TOUCHINGOBJECT_POINTER": "pokazivač miša",
    "SENSING_TOUCHINGOBJECT_EDGE": "rub",
    "SENSING_TOUCHINGCOLOR": "dodiruje boju %1?",
    "SENSING_COLORISTOUCHINGCOLOR": "boja %1 dodiruje %2?",
    "SENSING_DISTANCETO": "udaljenost do %1",
    "SENSING_DISTANCETO_POINTER": "pokazivač miša",
    "SENSING_ASKANDWAIT": "pitaj %1 i čekaj",
    "SENSING_ASK_TEXT": "Kako se zoveš?",
    "SENSING_ANSWER": "odgovor",
    "SENSING_KEYPRESSED": "tipka %1 pritisnuta?",
    "SENSING_MOUSEDOWN": "miš pritisnut?",
    "SENSING_MOUSEX": "miš x",
    "SENSING_MOUSEY": "miš y",
    "SENSING_SETDRAGMODE": "postavi način povlačenja na %1",
    "SENSING_SETDRAGMODE_DRAGGABLE": "moguće povlačenje",
    "SENSING_SETDRAGMODE_NOTDRAGGABLE": "bez povlačenja",
    "SENSING_LOUDNESS": "glasnoća",
    "SENSING_LOUD": "glasno?",
    "SENSING_TIMER": "štoperica",
    "SENSING_RESETTIMER": "poništi štopericu",
    "SENSING_OF": "%1 od %2",
    "SENSING_OF_XPOSITION": "x položaj",
    "SENSING_OF_YPOSITION": "y položaj",
    "SENSING_OF_DIRECTION": "smjer",
    "SENSING_OF_COSTUMENUMBER": "kostim #",
    "SENSING_OF_COSTUMENAME": "naziv kostima",
    "SENSING_OF_SIZE": "veličina",
    "SENSING_OF_VOLUME": "jačina zvuka",
    "SENSING_OF_BACKDROPNUMBER": "pozadina #",
    "SENSING_OF_BACKDROPNAME": "naziv pozadine",
    "SENSING_OF_STAGE": "Pozornica",
    "SENSING_CURRENT": "trenutna %1",
    "SENSING_CURRENT_YEAR": "godina",
    "SENSING_CURRENT_MONTH": "mjesec",
    "SENSING_CURRENT_DATE": "dan",
    "SENSING_CURRENT_DAYOFWEEK": "dan u tjednu",
    "SENSING_CURRENT_HOUR": "sat",
    "SENSING_CURRENT_MINUTE": "minuta",
    "SENSING_CURRENT_SECOND": "sekunda",
    "SENSING_DAYSSINCE2000": "dana od 2000",
    "SENSING_USERNAME": "nadimak",
    "SENSING_USERID": "korisnički id",
    "SOUND_PLAY": "sviraj zvuk %1",
    "SOUND_PLAYUNTILDONE": "sviraj zvuk %1 do kraja",
    "SOUND_STOPALLSOUNDS": "zaustavi sve zvukove",
    "SOUND_SETEFFECTO": "promijeni efekt %1 na %2",
    "SOUND_CHANGEEFFECTBY": "promijeni efekt %1 za %2",
    "SOUND_CLEAREFFECTS": "očisti sve efekte zvuka",
    "SOUND_EFFECTS_PITCH": "visina tona",
    "SOUND_EFFECTS_PAN": "kretanje lijevo/desno",
    "SOUND_CHANGEVOLUMEBY": "promijeni glasnoću za %1",
    "SOUND_SETVOLUMETO": "postavi glasnoću na %1%",
    "SOUND_VOLUME": "glasnoća",
    "SOUND_RECORD": "snimaj...",
    "CATEGORY_MOTION": "Kretanje",
    "CATEGORY_LOOKS": "Izgled",
    "CATEGORY_SOUND": "Zvuk",
    "CATEGORY_EVENTS": "Događaji",
    "CATEGORY_CONTROL": "Upravljanje",
    "CATEGORY_SENSING": "Očitanja",
    "CATEGORY_OPERATORS": "Operacije",
    "CATEGORY_VARIABLES": "Varijable",
    "CATEGORY_MYBLOCKS": "Moji Blokovi",
    "CATEGORY_LISTS": "",
    "CATEGORY_TUPLE": "",
    "CATEGORY_DICTIONARYS": "",
    "CATEGORY_NUMBERS": "",
    "CATEGORY_TEXT": "",
    "DUPLICATE": "Dupliciraj",
    "DELETE": "Izbriši",
    "ADD_COMMENT": "Dodaj komentar",
    "REMOVE_COMMENT": "Ukloni komentar",
    "DELETE_BLOCK": "Izbriši blok",
    "DELETE_X_BLOCKS": "Izbriši %1 blokove",
    "DELETE_ALL_BLOCKS": "Izbriši sve %1 blokove?",
    "CLEAN_UP": "Očisti blokove",
    "HELP": "Pomoć",
    "UNDO": "Poništi",
    "REDO": "Ponovi",
    "EDIT_PROCEDURE": "Uredi",
    "SHOW_PROCEDURE_DEFINITION": "Idi do definicije",
    "WORKSPACE_COMMENT_DEFAULT_TEXT": "Reci nešto...",
    "COLOUR_HUE_LABEL": "Boja",
    "COLOUR_SATURATION_LABEL": "Saturacija",
    "COLOUR_BRIGHTNESS_LABEL": "Osvjetljenje",
    "CHANGE_VALUE_TITLE": "Promijeni vrijednost:",
    "RENAME_VARIABLE": "Preimenuj varijablu",
    "RENAME_VARIABLE_STRING": "",
    "RENAME_VARIABLE_NUMBER": "",
    "RENAME_VARIABLE_LIST": "",
    "RENAME_VARIABLE_TITLE": "Preimenuj sve \"%1\" varijable u:",
    "RENAME_VARIABLE_MODAL_TITLE": "Preimenuj varijablu",
    "NEW_VARIABLE": "Napravi varijablu",
    "NEW_VARIABLE_TITLE": "Ime varijable:",
    "VARIABLE_MODAL_TITLE": "Nova varijabla",
    "VARIABLE_ALREADY_EXISTS": "Varijabla \"%1\" već postoji.",
    "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "Varijabla \"%1\" već postoji kod druge varijable tipa \"%2\".",
    "DELETE_VARIABLE_CONFIRMATION": "Izbriši \"%1\" upotrebu varijable \"%2\"?",
    "CANNOT_DELETE_VARIABLE_PROCEDURE": "Ne mogu izbrisati varijablu \"%1\" jer je dio definicije funkcije \"%2\"",
    "DELETE_VARIABLE": "Izbriši varijablu \"%1\"",
    "DELETE_VARIABLE_STRING": "",
    "DELETE_VARIABLE_NUMBER": "",
    "DELETE_VARIABLE_LIST": "",
    "NEW_PROCEDURE": "Napravi blok",
    "PROCEDURE_ALREADY_EXISTS": "Procedura \"%1\" već postoji.",
    "PROCEDURE_DEFAULT_NAME": "ime bloka",
    "NEW_LIST": "Napravi listu",
    "NEW_LIST_TITLE": "Novo ime lliste:",
    "LIST_MODAL_TITLE": "Nova lista",
    "LIST_ALREADY_EXISTS": "Lista imena \"%1\" već postoji.",
    "RENAME_LIST_TITLE": "Preimenuj sve \"%1\" liste u:",
    "RENAME_LIST_MODAL_TITLE": "Preimenuj listu",
    "DEFAULT_LIST_ITEM": "stvar",
    "DELETE_LIST": "Izbriši listu \"%1\"",
    "RENAME_LIST": "Preimenuj listu",
    "NEW_BROADCAST_MESSAGE": "Nova poruka",
    "NEW_BROADCAST_MESSAGE_TITLE": "Naziv nove poruke:",
    "BROADCAST_MODAL_TITLE": "Nova poruka",
    "DEFAULT_BROADCAST_MESSAGE_NAME": "poruka1",
    "DELETE_PROCEDURE": "",
    "OK": "",
    "PROMPT": "",
    "STOP": "",
    "NEW_STRING": "",
    "NEW_NUMBER_VARIABLE": "",
    "WIFI": "",
    "USERNAME": "",
    "PWD": "",
    "IOT_SERVICE": "",
    "IOT_ID": "",
    "IOT_PWD": "",
    "IOT_TOPIC": "",
    "MORE_TOPIC": "",
    "OBLOQ_HTTP": "",
    "OBLOQ_IP": "",
    "OBLOQ_PORT": "",
    "ADD_TOPIC": "",
    "DELETE_TOPIC": "",
    "IOT_SERVER": "",
    "CHINA": "",
    "GLOBAL": "",
    "LOW_C": "",
    "LOW_C$": "",
    "LOW_D": "",
    "LOW_D$": "",
    "LOW_E": "",
    "LOW_F": "",
    "LOW_F$": "",
    "LOW_G": "",
    "LOW_G$": "",
    "LOW_A": "",
    "LOW_A$": "",
    "LOW_B": "",
    "MIDDLE_C": "",
    "MIDDLE_C$": "",
    "MIDDLE_D": "",
    "MIDDLE_D$": "",
    "MIDDLE_E": "",
    "MIDDLE_F": "",
    "MIDDLE_F$": "",
    "MIDDLE_G": "",
    "MIDDLE_G$": "",
    "MIDDLE_A": "",
    "MIDDLE_A$": "",
    "MIDDLE_B": "",
    "HIGH_C": "",
    "HIGH_C$": "",
    "HIGH_D": "",
    "HIGH_D$": "",
    "HIGH_E": "",
    "HIGH_F": "",
    "HIGH_F$": "",
    "HIGH_G": "",
    "HIGH_G$": "",
    "HIGH_A": "",
    "HIGH_A$": "",
    "HIGH_B": "",
    "OPERATORS_STRINGTONUMBER": "",
    "INTEGER": "",
    "DECIMAL": "",
    "OPERATORS_NUMTOASCII": "",
    "OPERATORS_STRINGTOASCII": "",
    "OPERATORS_NUMTOSTRING": "",
    "OPERATORS_MAP": "",
    "OPERATORS_CONSTRAIN": "",
    "STEPS_PER_TURN": "",
    "ROTATE_SPEED": "",
    "NEW_AI": "",
    "ASK_AND_PAINT": "",
    "GET_IDENTIFIED_NUM": "",
    "READ_NUM_AND_SAY": "",
    "IMAGE_ADDR": "",
    "IMAGE_PREVIEW": "",
    "IMAGE_OPEN": "",
    "IMAGE_SIZE": "",
    "IMAGE_WIDTH": "",
    "IMAGE_HEIGHT": "",
    "VARIABLE": "",
    "VARIABLE_LIST": "",
    "SET_STRING_VARIABLE": "",
    "STRING_START_WITH": "",
    "DICTIONARY_HEIGHT_FIRST": "",
    "DICTIONARY_HEIGHT_TWO": "",
    "DICTIONARY_HEIGHT_THREE": "",
    "DICTIONARY_HEIGHT_FOUR": "",
    "DATA_MPITEMOFLIST": "",
    "DATA_MPINTERCEPTITEM": "",
    "DATA_MPINSERTATLIST": "",
    "DATA_MPSETVALUEATLIST": "",
    "DATA_MPINSERTATLIST_INSERT": "",
    "DATA_MPINSERTATLIST_CHANGE": "",
    "DATA_MPINSERTATLIST_FOUR": "",
    "DATA_MPINSERTATLIST_FIVE": "",
    "DATA_MPINSERTATLIST_SIX": "",
    "DATA_MPINSERTATLIST_SEVEN": "",
    "DATA_MPINSERTATLIST_EIGHT": "",
    "DATA_MPINSERTATLIST_EIGHT_FALSE": "",
    "DATA_MPINSERTATLIST_EIGHT_TRUE": "",
    "DATA_MPINSERTATLIST_EIGHT_REVERSE": "",
    "OPERATORS_RANDOMA": "",
    "OPERATORS_MODA": "",
    "OPERATORS_ROUNDA": "",
    "OPERATORS_MATHOPA": "",
    "OPERATORS_MATHOP_ABSA": "",
    "OPERATORS_MATHOP_FLOORA": "",
    "OPERATORS_MATHOP_CEILINGA": "",
    "OPERATORS_MATHOP_SQRTA": "",
    "OPERATORS_MATHOP_FIVE": "",
    "OPERATORS_MATHOP_SIX": "",
    "OPERATORS_MATHOP_SEVEN": "",
    "OPERATORS_MATHOP_LENGHT": "",
    "OPERATORS_MATHOP_MAXMIN": "",
    "OPERATORS_MATHOP_MAXMIN_MAX": "",
    "OPERATORS_MATHOP_MAXMIN_MIN": "",
    "OPERATORS_TEXTS_ONE": "",
    "OPERATORS_TEXTS_TWO": "",
    "OPERATORS_TEXTS_THREE": "",
    "OPERATORS_TEXTS_FOUR": "",
    "OPERATORS_TEXTS_FOUR_NEW": "",
    "OPERATORS_TEXTS_NEW": "",
    "OPERATORS_TEXTS_NEW_OP": "",
    "OPERATORS_TEXTS_FOUR_ONE": "",
    "OPERATORS_TEXTS_FOUR_TWO": "",
    "OPERATORS_TEXTS_FOUR_THREE": "",
    "OPERATORS_TEXTS_FOUR_FOUR": "",
    "OPERATORS_TEXTS_FOUR_F": "",
    "OPERATORS_TEXTS_FOUR_L": "",
    "OPERATORS_STRINGTONUMBERA": "",
    "OPERATORS_NUMTOASCIIA": "",
    "OPERATORS_STRINGTOASCIIA": "",
    "OPERATORS_NUMTOSTRINGA": "",
    "NEW_MC": "",
    "RENAME_MCNUMBER_TITLE": "",
    "RENAME_MCNUMBER_MODAL_TITLE": "",
    "RENAME_VARIABLE_MCNUMBER": "",
    "DELETE_VARIABLE_MCNUMBER": "",
    "SET_MC_VARIABLE": "",
    "TUPLE_ONE": "",
    "TUPLE_TWO": "",
    "ALIYUN": "",
    "ONENET": "",
    "EASYIOT": "",
    "SHANGHAI": "",
    "QINGDAO": "",
    "BEIJING": "",
    "ZHANGJIAKOU": "",
    "HUHEHAOTE": "",
    "HANGZHOU": "",
    "SHENZHEN": "",
    "HONGKONG": "",
    "SINGAPORE": "",
    "SYDNEY": "",
    "KUALALUMPUR": "",
    "JAKARTA": "",
    "MUMBAI": "",
    "TOKYO": "",
    "SILICONVALLEY": "",
    "VIRGINIA": "",
    "FRANKFURT": "",
    "LONDON": "",
    "DUBAI": "",
    "IOT_PLATFORM": "",
    "PARAMS": "",
    "SERVER_ATTR": "",
    "PRODUCTID": "",
    "DEVICEID": "",
    "TINYDB_SECRET": "",
    "TINYDB_USER": "",
    "APIADDR": "",
    "SOFTWARE_SERIAL": "",
    "HARDWARE_SERIAL": "",
    "HARDWARE_SERIAL1": "",
    "HARDWARE_SERIAL2": "",
    "HARDWARE_SERIAL3": ""
  },
  "zu": {
    "CONTROL_FOREVER": "ingunaphakade",
    "CONTROL_REPEAT": "phinda %1",
    "CONTROL_IF": "uma %1 bese",
    "CONTROL_ELSE": "okunye",
    "CONTROL_STOP": "ima",
    "CONTROL_STOP_ALL": "yonke",
    "CONTROL_STOP_THIS": "lo mbhalo",
    "CONTROL_STOP_OTHER": "omunye umdlalo womlingisi",
    "CONTROL_WAIT": "linda %1 umzuzuwana",
    "CONTROL_WAITUNTIL": "linda kuze %1",
    "CONTROL_REPEATUNTIL": "phinda kuze %1",
    "CONTROL_WHILE": "ngenkathi %1",
    "CONTROL_FOREACH": "ngokunye %1 ngokunye %2",
    "CONTROL_STARTASCLONE": "uma sengiqala ncamashi",
    "CONTROL_CREATECLONEOF": "akha umfanisi ye %1",
    "CONTROL_CREATECLONEOF_MYSELF": "mina",
    "CONTROL_DELETETHISCLONE": "khipa umfanisi",
    "CONTROL_COUNTER": "isibali",
    "CONTROL_INCRCOUNTER": "khulisa isibali",
    "CONTROL_CLEARCOUNTER": "susa isibali",
    "CONTROL_ALLATONCE": "konke ngesikhathi esisodwa",
    "DATA_SETVARIABLETO": "lungisa %1 ukuze %2",
    "DATA_CHANGEVARIABLEBY": "shintsha %1 ngokuthi %2",
    "DATA_SHOWVARIABLE": "tshengisa i-variable %1",
    "DATA_HIDEVARIABLE": "fihla i-variable %1",
    "DATA_ADDTOLIST": "engeza %1 kuya %2",
    "DATA_DELETEOFLIST": "inhlamvu %1 ye %2",
    "DATA_DELETEALLOFLIST": "susa konke %1",
    "DATA_INSERTATLIST": "faka %1 ku %2 ye %3",
    "DATA_REPLACEITEMOFLIST": "buyisela into %1 ye %2 nge %3",
    "DATA_ITEMOFLIST": "into %1 ye %2",
    "DATA_ITEMNUMOFLIST": "into # yaku %1 ku %2",
    "DATA_LENGTHOFLIST": "ubude be %1",
    "DATA_LISTCONTAINSITEM": "%1 kukuphatheleni %2?",
    "DATA_SHOWLIST": "khombisa uhlu %1",
    "DATA_HIDELIST": "fihla uhla %1",
    "DATA_INDEX_ALL": "yonke",
    "DATA_INDEX_LAST": "eyokugcina",
    "DATA_INDEX_RANDOM": "okungajwayelekile",
    "EVENT_WHENFLAGCLICKED": "uma %1 liciphiziwe ",
    "EVENT_WHENTHISSPRITECLICKED": "Uma lomlingisi eciphiziwe ",
    "EVENT_WHENSTAGECLICKED": "uma Indawo iciphiziwe",
    "EVENT_WHENTOUCHINGOBJECT": "uma lomlingisi ethinta %1",
    "EVENT_WHENBROADCASTRECEIVED": "uma ngikuthole %1",
    "EVENT_WHENBACKDROPSWITCHESTO": "uma indawo ishintshela kwi %1",
    "EVENT_WHENGREATERTHAN": "uma %1 > %2",
    "EVENT_WHENGREATERTHAN_TIMER": "iwashi elicushiwe",
    "EVENT_WHENGREATERTHAN_LOUDNESS": "ubukhulu bomsindo",
    "EVENT_BROADCAST": "sakaza %1",
    "EVENT_BROADCASTANDWAIT": "sakaza %1 futhi linda",
    "EVENT_WHENKEYPRESSED": "uma sekucindezelwe ukhiye %1",
    "EVENT_WHENKEYPRESSED_SPACE": "isikhala",
    "EVENT_WHENKEYPRESSED_LEFT": "umcibisholo kwesobunxele",
    "EVENT_WHENKEYPRESSED_RIGHT": "umcibilisholo kwesokudla",
    "EVENT_WHENKEYPRESSED_DOWN": "umcibisholo ophansi",
    "EVENT_WHENKEYPRESSED_UP": "umcibilisholo phezulu",
    "EVENT_WHENKEYPRESSED_ANY": "noma ikuphi",
    "LOOKS_SAYFORSECS": "isho %1 ye %2 umzuzwana",
    "LOOKS_SAY": "isho %1",
    "LOOKS_HELLO": "Sawubona!",
    "LOOKS_THINKFORSECS": "cabangela %1 ye %2 umzuzuwana",
    "LOOKS_THINK": "cabanga %1",
    "LOOKS_HMM": "Hmm...",
    "LOOKS_SHOW": "khombisa",
    "LOOKS_HIDE": "casha",
    "LOOKS_HIDEALLSPRITES": "cashisa bonke abalingisi",
    "LOOKS_EFFECT_COLOR": "umbala",
    "LOOKS_EFFECT_FISHEYE": "ihlo likafishi",
    "LOOKS_EFFECT_WHIRL": "phenduka",
    "LOOKS_EFFECT_PIXELATE": "pixelate",
    "LOOKS_EFFECT_MOSAIC": "mosaic",
    "LOOKS_EFFECT_BRIGHTNESS": "ukukhanya",
    "LOOKS_EFFECT_GHOST": "isipoki",
    "LOOKS_CHANGEEFFECTBY": "shintsha %1 umthelela ngokuthi %2",
    "LOOKS_SETEFFECTTO": "lungisa %1 umthelela uwuyise %2",
    "LOOKS_CLEARGRAPHICEFFECTS": "susa amagrafikh athintekayo",
    "LOOKS_CHANGESIZEBY": "shintsha usayizi ngokuthi %1",
    "LOOKS_SETSIZETO": "lungisa isisindo ukuze %1 %",
    "LOOKS_SIZE": "isisindo",
    "LOOKS_CHANGESTRETCHBY": "shinthsa ukunwebeka ngo %1",
    "LOOKS_SETSTRETCHTO": "lungisa ukunwebeka ku %1",
    "LOOKS_SWITCHCOSTUMETO": "shintsha impahla ukuze %1",
    "LOOKS_NEXTCOSTUME": "impahla elandelayo",
    "LOOKS_SWITCHBACKDROPTO": "shintsha indawo ukuze %1",
    "LOOKS_GOTOFRONTBACK": "hamba ku %1 ngokwahlukana",
    "LOOKS_GOTOFRONTBACK_FRONT": "phambili",
    "LOOKS_GOTOFRONTBACK_BACK": "emuva",
    "LOOKS_GOFORWARDBACKWARDLAYERS": "buyela %1 %2 ngokwahlukana",
    "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "phambili",
    "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "emuva",
    "LOOKS_BACKDROPNUMBERNAME": "indawo1 %1",
    "LOOKS_COSTUMENUMBERNAME": "impahla %1",
    "LOOKS_NUMBERNAME_NUMBER": "inombolo",
    "LOOKS_NUMBERNAME_NAME": "igama",
    "LOOKS_SWITCHBACKDROPTOANDWAIT": "shintsha indawo mase %1 futhi uyalinda",
    "LOOKS_NEXTBACKDROP_BLOCK": "indawo elandelayo",
    "LOOKS_NEXTBACKDROP": "indawo elandelayo",
    "LOOKS_PREVIOUSBACKDROP": "indawo edlule",
    "LOOKS_RANDOMBACKDROP": "iya noma ikephi indawo",
    "MOTION_MOVESTEPS": "hlehlisa %1 izitebhisi",
    "MOTION_TURNLEFT": "phendula %1 %2 wehlise",
    "MOTION_TURNRIGHT": "phendula %1 %2 wehlise",
    "MOTION_POINTINDIRECTION": "khomba ngasendleleni %1",
    "MOTION_POINTTOWARDS": "khomba ubhekise %1",
    "MOTION_POINTTOWARDS_POINTER": "imows-poynta",
    "MOTION_POINTTOWARDS_RANDOM": "inkomba engalindelekile",
    "MOTION_GOTO": "hamba uye %1",
    "MOTION_GOTO_POINTER": "imows-poynta",
    "MOTION_GOTO_RANDOM": "indawo engajwayelekile",
    "MOTION_GOTOXY": "hamba uye x: %1 y: %2",
    "MOTION_GLIDESECSTOXY": "ukushibilika %1 umzuzuwana kwi x: %2 y: %3",
    "MOTION_GLIDETO": "ukushibilika %1 umzuzuwana kwi %2",
    "MOTION_GLIDETO_POINTER": "Igundane phoyinta",
    "MOTION_GLIDETO_RANDOM": "indawo engajwayelekile",
    "MOTION_CHANGEXBY": "shintsha u-x ngokuthi %1",
    "MOTION_SETX": "lungisa u-x ukuze %1",
    "MOTION_CHANGEYBY": "shintsha u-y ngokuthi %1",
    "MOTION_SETY": "lungisa u-y ukuze %1",
    "MOTION_IFONEDGEBOUNCE": "uma isenigcupheni, gxuma",
    "MOTION_SETROTATIONSTYLE": "lungisa isitayela ejikeleza ngaso %1",
    "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "isinxele-esokudla",
    "MOTION_SETROTATIONSTYLE_DONTROTATE": "ungajikelezi",
    "MOTION_SETROTATIONSTYLE_ALLAROUND": "yonke indawo",
    "MOTION_XPOSITION": "x indlela yokuma",
    "MOTION_YPOSITION": "y indlela yokuma",
    "MOTION_DIRECTION": "indlela",
    "MOTION_SCROLLRIGHT": "gingqa kwesokudla %1",
    "MOTION_SCROLLUP": "gingqa phezulu %1",
    "MOTION_ALIGNSCENE": "hlela inkundla %1",
    "MOTION_ALIGNSCENE_BOTTOMLEFT": "phansi kwesokunxele",
    "MOTION_ALIGNSCENE_BOTTOMRIGHT": "phansi kwesokudla",
    "MOTION_ALIGNSCENE_MIDDLE": "phakathi nendawo",
    "MOTION_ALIGNSCENE_TOPLEFT": "phezulu kwesokunxele",
    "MOTION_ALIGNSCENE_TOPRIGHT": "phezulu kwesokudla",
    "MOTION_XSCROLL": "x gingqa",
    "MOTION_YSCROLL": "y gingqa",
    "MOTION_STAGE_SELECTED": "Khetha indawo: awekho amabhulokisi okunyakaza",
    "OPERATORS_ADD": "%1 + %2",
    "OPERATORS_SUBTRACT": "%1 - %2",
    "OPERATORS_MULTIPLY": "%1 * %2",
    "OPERATORS_DIVIDE": "%1 / %2",
    "OPERATORS_RANDOM": "khetha unganaki %1 uku %2",
    "OPERATORS_GT": "%1 > %2",
    "OPERATORS_GT_EQUALS": "",
    "OPERATORS_LT": "%1 < %2",
    "OPERATORS_LT_EQUALS": "",
    "OPERATORS_EQUALS": "%1 = %2",
    "OPERATORS_AND": "%1 futhi %2",
    "OPERATORS_OR": "%1 noma %2",
    "OPERATORS_NOT": "akunjalo %1",
    "OPERATORS_JOIN": "hlanganisa %1 %2",
    "OPERATORS_JOIN_APPLE": "i-apula",
    "OPERATORS_JOIN_BANANA": "ibhanana",
    "OPERATORS_LETTEROF": "inhlamvu %1 ye %2",
    "OPERATORS_LETTEROF_APPLE": "a",
    "OPERATORS_LENGTH": "ubude be %1",
    "OPERATORS_CONTAINS": "%1 okuphethwe %2?",
    "OPERATORS_MOD": "%1 mod %2",
    "OPERATORS_ROUND": "rawunda %1",
    "OPERATORS_MATHOP": "%1 ya %2",
    "OPERATORS_MATHOP_ABS": "abs",
    "OPERATORS_MATHOP_FLOOR": "phansi",
    "OPERATORS_MATHOP_CEILING": "iphezulu",
    "OPERATORS_MATHOP_SQRT": "sqrt",
    "OPERATORS_MATHOP_SIN": "sin",
    "OPERATORS_MATHOP_COS": "cos",
    "OPERATORS_MATHOP_TAN": "tan",
    "OPERATORS_MATHOP_ASIN": "asin",
    "OPERATORS_MATHOP_ACOS": "acos",
    "OPERATORS_MATHOP_ATAN": "atan",
    "OPERATORS_MATHOP_LN": "ln",
    "OPERATORS_MATHOP_LOG": "log",
    "OPERATORS_MATHOP_EEXP": "e ^",
    "OPERATORS_MATHOP_10EXP": "10 ^",
    "PROCEDURES_DEFINITION": "chaza %1",
    "SENSING_TOUCHINGOBJECT": "thinta %1?",
    "SENSING_TOUCHINGOBJECT_POINTER": "imows-poynta",
    "SENSING_TOUCHINGOBJECT_EDGE": "ikhona",
    "SENSING_TOUCHINGCOLOR": "thinta umbala %1?",
    "SENSING_COLORISTOUCHINGCOLOR": "umbala %1 uyathinta %2?",
    "SENSING_DISTANCETO": "ibanga eliya %1",
    "SENSING_DISTANCETO_POINTER": "imows-poynta",
    "SENSING_ASKANDWAIT": "buza %1 futhi linda",
    "SENSING_ASK_TEXT": "Ubani igama lakho?",
    "SENSING_ANSWER": "phendula",
    "SENSING_KEYPRESSED": "ukhiye %1 ucindezelwe?",
    "SENSING_MOUSEDOWN": "igundane phansi?",
    "SENSING_MOUSEX": "igundane x",
    "SENSING_MOUSEY": "igundane y",
    "SENSING_SETDRAGMODE": "lungisa isimo sokuhudula %1",
    "SENSING_SETDRAGMODE_DRAGGABLE": "kuyahuduleka",
    "SENSING_SETDRAGMODE_NOTDRAGGABLE": "akuhuduleki",
    "SENSING_LOUDNESS": "ubukhulu bomsindo",
    "SENSING_LOUD": "kukhulu umsindo?",
    "SENSING_TIMER": "iwashi elicushiwe",
    "SENSING_RESETTIMER": "ngifaka iwashi elicushiwe",
    "SENSING_OF": "%1 ya %2",
    "SENSING_OF_XPOSITION": "x indlela yokuma",
    "SENSING_OF_YPOSITION": "y indlela yokuma",
    "SENSING_OF_DIRECTION": "indlela",
    "SENSING_OF_COSTUMENUMBER": "impahla #",
    "SENSING_OF_COSTUMENAME": "igama lempahla",
    "SENSING_OF_SIZE": "isisindo",
    "SENSING_OF_VOLUME": "umsindo",
    "SENSING_OF_BACKDROPNUMBER": "indawo #",
    "SENSING_OF_BACKDROPNAME": "igama lendawo",
    "SENSING_OF_STAGE": "Isigaba",
    "SENSING_CURRENT": "mange %1",
    "SENSING_CURRENT_YEAR": "unyaka",
    "SENSING_CURRENT_MONTH": "inyanga",
    "SENSING_CURRENT_DATE": "usuku",
    "SENSING_CURRENT_DAYOFWEEK": "usuku lwesonto",
    "SENSING_CURRENT_HOUR": "ihora",
    "SENSING_CURRENT_MINUTE": "umzuzu",
    "SENSING_CURRENT_SECOND": "umzuzwana",
    "SENSING_DAYSSINCE2000": "izinsuku kusukela 2000",
    "SENSING_USERNAME": "igama elisebenzile",
    "SENSING_USERID": "umsebenzisi ID",
    "SOUND_PLAY": "qala umsindo %1",
    "SOUND_PLAYUNTILDONE": "dlala umsindo %1 uze uqede",
    "SOUND_STOPALLSOUNDS": "misa yonke imisindo",
    "SOUND_SETEFFECTO": "Lungisa %1 umthelela ngo %2",
    "SOUND_CHANGEEFFECTBY": "Shintsha %1 womthelela ngo %2",
    "SOUND_CLEAREFFECTS": "susa umthelela womsindo",
    "SOUND_EFFECTS_PITCH": "izinga elidlulele",
    "SOUND_EFFECTS_PAN": "jikisa isinxele-nangakwesokudla",
    "SOUND_CHANGEVOLUMEBY": "shintsha umsindo ngokuthi %1",
    "SOUND_SETVOLUMETO": "lungisa inani lo msindo ukuze %1%",
    "SOUND_VOLUME": "umsindo",
    "SOUND_RECORD": "qopha...",
    "CATEGORY_MOTION": "Ukunyakaza",
    "CATEGORY_LOOKS": "Ukubukeka",
    "CATEGORY_SOUND": "Umsindo",
    "CATEGORY_EVENTS": "Izehlakalo",
    "CATEGORY_CONTROL": "Lawula",
    "CATEGORY_SENSING": "Ukuzwa",
    "CATEGORY_OPERATORS": "Abalawuli",
    "CATEGORY_VARIABLES": "I-variables",
    "CATEGORY_MYBLOCKS": "Amabhulokisi ami",
    "CATEGORY_LISTS": "",
    "CATEGORY_TUPLE": "",
    "CATEGORY_DICTIONARYS": "",
    "CATEGORY_NUMBERS": "",
    "CATEGORY_TEXT": "",
    "DUPLICATE": "Fanisa",
    "DELETE": "Cima",
    "ADD_COMMENT": "Faka Phawula",
    "REMOVE_COMMENT": "Susa Izikulumo",
    "DELETE_BLOCK": "Susa Amabhulokisi",
    "DELETE_X_BLOCKS": "Susa %1 Amabhulokisi",
    "DELETE_ALL_BLOCKS": "Susa wonke %1 amabhulokisi?",
    "CLEAN_UP": "Qoqa amabhulokisi",
    "HELP": "Usizo",
    "UNDO": "Yenza kabusha",
    "REDO": "Ukwenzakabusha",
    "EDIT_PROCEDURE": "Lungisa",
    "SHOW_PROCEDURE_DEFINITION": "Iyokokukhethiwe",
    "WORKSPACE_COMMENT_DEFAULT_TEXT": "Isho okuthile...",
    "COLOUR_HUE_LABEL": "Umbala",
    "COLOUR_SATURATION_LABEL": "Ukukhanya kombala",
    "COLOUR_BRIGHTNESS_LABEL": "Ukukhanya",
    "CHANGE_VALUE_TITLE": "Shintsha inani:",
    "RENAME_VARIABLE": "Shintsha igama i-variable",
    "RENAME_VARIABLE_STRING": "",
    "RENAME_VARIABLE_NUMBER": "",
    "RENAME_VARIABLE_LIST": "",
    "RENAME_VARIABLE_TITLE": "Shinthsha amagama akho konke %1 i-variable ku:",
    "RENAME_VARIABLE_MODAL_TITLE": "Shintsha Igama i-variable",
    "NEW_VARIABLE": "Yenza i-variable",
    "NEW_VARIABLE_TITLE": "Igama elisha i-variable",
    "VARIABLE_MODAL_TITLE": "I-variable Entsha",
    "VARIABLE_ALREADY_EXISTS": "Leligama levaebuli \"%1\" selivele likhona ",
    "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "Leligama levaebuli \"%1\" selivele likhona kwenya i-variable yolunye uhlobo \"%2\".",
    "DELETE_VARIABLE_CONFIRMATION": "Cisha %1 ukusetshenziswa kwe \"%2\" i-variable?",
    "CANNOT_DELETE_VARIABLE_PROCEDURE": "Ngeke uyicishe i-variable \"%1\" ngoba iyingxenye yencazelo yalomsebenzi \"%2\"",
    "DELETE_VARIABLE": "Cisha u %1 i-variable",
    "DELETE_VARIABLE_STRING": "",
    "DELETE_VARIABLE_NUMBER": "",
    "DELETE_VARIABLE_LIST": "",
    "NEW_PROCEDURE": "Enza ibhulokhi",
    "PROCEDURE_ALREADY_EXISTS": "Inqubomgomo %1 isivele ikhona",
    "PROCEDURE_DEFAULT_NAME": "igama lamabhulokisi",
    "NEW_LIST": "Yenza Uhlu",
    "NEW_LIST_TITLE": "Igama lohlu elisha:",
    "LIST_MODAL_TITLE": "Uhlu Olusha",
    "LIST_ALREADY_EXISTS": "Uhlu oluyileligama %1 seluvele lukhona",
    "RENAME_LIST_TITLE": "Qaba loke \"%1\" igama lohlu ku",
    "RENAME_LIST_MODAL_TITLE": "Qamba igama lohlu",
    "DEFAULT_LIST_ITEM": "into",
    "DELETE_LIST": "Cisha i\"%1\" uhlo",
    "RENAME_LIST": "Shintsha igama lohlu",
    "NEW_BROADCAST_MESSAGE": "Umyalezo omusha",
    "NEW_BROADCAST_MESSAGE_TITLE": "Igama lomyalezo elisha",
    "BROADCAST_MODAL_TITLE": "Umyalezo Omusha",
    "DEFAULT_BROADCAST_MESSAGE_NAME": "umyalezo wokuqala",
    "DELETE_PROCEDURE": "",
    "OK": "",
    "PROMPT": "",
    "STOP": "",
    "NEW_STRING": "",
    "NEW_NUMBER_VARIABLE": "",
    "WIFI": "",
    "USERNAME": "",
    "PWD": "",
    "IOT_SERVICE": "",
    "IOT_ID": "",
    "IOT_PWD": "",
    "IOT_TOPIC": "",
    "MORE_TOPIC": "",
    "OBLOQ_HTTP": "",
    "OBLOQ_IP": "",
    "OBLOQ_PORT": "",
    "ADD_TOPIC": "",
    "DELETE_TOPIC": "",
    "IOT_SERVER": "",
    "CHINA": "",
    "GLOBAL": "",
    "LOW_C": "",
    "LOW_C$": "",
    "LOW_D": "",
    "LOW_D$": "",
    "LOW_E": "",
    "LOW_F": "",
    "LOW_F$": "",
    "LOW_G": "",
    "LOW_G$": "",
    "LOW_A": "",
    "LOW_A$": "",
    "LOW_B": "",
    "MIDDLE_C": "",
    "MIDDLE_C$": "",
    "MIDDLE_D": "",
    "MIDDLE_D$": "",
    "MIDDLE_E": "",
    "MIDDLE_F": "",
    "MIDDLE_F$": "",
    "MIDDLE_G": "",
    "MIDDLE_G$": "",
    "MIDDLE_A": "",
    "MIDDLE_A$": "",
    "MIDDLE_B": "",
    "HIGH_C": "",
    "HIGH_C$": "",
    "HIGH_D": "",
    "HIGH_D$": "",
    "HIGH_E": "",
    "HIGH_F": "",
    "HIGH_F$": "",
    "HIGH_G": "",
    "HIGH_G$": "",
    "HIGH_A": "",
    "HIGH_A$": "",
    "HIGH_B": "",
    "OPERATORS_STRINGTONUMBER": "",
    "INTEGER": "",
    "DECIMAL": "",
    "OPERATORS_NUMTOASCII": "",
    "OPERATORS_STRINGTOASCII": "",
    "OPERATORS_NUMTOSTRING": "",
    "OPERATORS_MAP": "",
    "OPERATORS_CONSTRAIN": "",
    "STEPS_PER_TURN": "",
    "ROTATE_SPEED": "",
    "NEW_AI": "",
    "ASK_AND_PAINT": "",
    "GET_IDENTIFIED_NUM": "",
    "READ_NUM_AND_SAY": "",
    "IMAGE_ADDR": "",
    "IMAGE_PREVIEW": "",
    "IMAGE_OPEN": "",
    "IMAGE_SIZE": "",
    "IMAGE_WIDTH": "",
    "IMAGE_HEIGHT": "",
    "VARIABLE": "",
    "VARIABLE_LIST": "",
    "SET_STRING_VARIABLE": "",
    "STRING_START_WITH": "",
    "DICTIONARY_HEIGHT_FIRST": "",
    "DICTIONARY_HEIGHT_TWO": "",
    "DICTIONARY_HEIGHT_THREE": "",
    "DICTIONARY_HEIGHT_FOUR": "",
    "DATA_MPITEMOFLIST": "",
    "DATA_MPINTERCEPTITEM": "",
    "DATA_MPINSERTATLIST": "",
    "DATA_MPSETVALUEATLIST": "",
    "DATA_MPINSERTATLIST_INSERT": "",
    "DATA_MPINSERTATLIST_CHANGE": "",
    "DATA_MPINSERTATLIST_FOUR": "",
    "DATA_MPINSERTATLIST_FIVE": "",
    "DATA_MPINSERTATLIST_SIX": "",
    "DATA_MPINSERTATLIST_SEVEN": "",
    "DATA_MPINSERTATLIST_EIGHT": "",
    "DATA_MPINSERTATLIST_EIGHT_FALSE": "",
    "DATA_MPINSERTATLIST_EIGHT_TRUE": "",
    "DATA_MPINSERTATLIST_EIGHT_REVERSE": "",
    "OPERATORS_RANDOMA": "",
    "OPERATORS_MODA": "",
    "OPERATORS_ROUNDA": "",
    "OPERATORS_MATHOPA": "",
    "OPERATORS_MATHOP_ABSA": "",
    "OPERATORS_MATHOP_FLOORA": "",
    "OPERATORS_MATHOP_CEILINGA": "",
    "OPERATORS_MATHOP_SQRTA": "",
    "OPERATORS_MATHOP_FIVE": "",
    "OPERATORS_MATHOP_SIX": "",
    "OPERATORS_MATHOP_SEVEN": "",
    "OPERATORS_MATHOP_LENGHT": "",
    "OPERATORS_MATHOP_MAXMIN": "",
    "OPERATORS_MATHOP_MAXMIN_MAX": "",
    "OPERATORS_MATHOP_MAXMIN_MIN": "",
    "OPERATORS_TEXTS_ONE": "",
    "OPERATORS_TEXTS_TWO": "",
    "OPERATORS_TEXTS_THREE": "",
    "OPERATORS_TEXTS_FOUR": "",
    "OPERATORS_TEXTS_FOUR_NEW": "",
    "OPERATORS_TEXTS_NEW": "",
    "OPERATORS_TEXTS_NEW_OP": "",
    "OPERATORS_TEXTS_FOUR_ONE": "",
    "OPERATORS_TEXTS_FOUR_TWO": "",
    "OPERATORS_TEXTS_FOUR_THREE": "",
    "OPERATORS_TEXTS_FOUR_FOUR": "",
    "OPERATORS_TEXTS_FOUR_F": "",
    "OPERATORS_TEXTS_FOUR_L": "",
    "OPERATORS_STRINGTONUMBERA": "",
    "OPERATORS_NUMTOASCIIA": "",
    "OPERATORS_STRINGTOASCIIA": "",
    "OPERATORS_NUMTOSTRINGA": "",
    "NEW_MC": "",
    "RENAME_MCNUMBER_TITLE": "",
    "RENAME_MCNUMBER_MODAL_TITLE": "",
    "RENAME_VARIABLE_MCNUMBER": "",
    "DELETE_VARIABLE_MCNUMBER": "",
    "SET_MC_VARIABLE": "",
    "TUPLE_ONE": "",
    "TUPLE_TWO": "",
    "ALIYUN": "",
    "ONENET": "",
    "EASYIOT": "",
    "SHANGHAI": "",
    "QINGDAO": "",
    "BEIJING": "",
    "ZHANGJIAKOU": "",
    "HUHEHAOTE": "",
    "HANGZHOU": "",
    "SHENZHEN": "",
    "HONGKONG": "",
    "SINGAPORE": "",
    "SYDNEY": "",
    "KUALALUMPUR": "",
    "JAKARTA": "",
    "MUMBAI": "",
    "TOKYO": "",
    "SILICONVALLEY": "",
    "VIRGINIA": "",
    "FRANKFURT": "",
    "LONDON": "",
    "DUBAI": "",
    "IOT_PLATFORM": "",
    "PARAMS": "",
    "SERVER_ATTR": "",
    "PRODUCTID": "",
    "DEVICEID": "",
    "TINYDB_SECRET": "",
    "TINYDB_USER": "",
    "APIADDR": "",
    "SOFTWARE_SERIAL": "",
    "HARDWARE_SERIAL": "",
    "HARDWARE_SERIAL1": "",
    "HARDWARE_SERIAL2": "",
    "HARDWARE_SERIAL3": ""
  },
  "is": {
    "CONTROL_FOREVER": "endalaust",
    "CONTROL_REPEAT": "endurtaka %1 sinnum",
    "CONTROL_IF": "ef %1 þá",
    "CONTROL_ELSE": "annars",
    "CONTROL_STOP": "stöðva",
    "CONTROL_STOP_ALL": "allt",
    "CONTROL_STOP_THIS": "þetta forrit",
    "CONTROL_STOP_OTHER": "önnur forrit á karakter",
    "CONTROL_WAIT": "bíða í %1 sekúndur",
    "CONTROL_WAITUNTIL": "bíða þangað til %1",
    "CONTROL_REPEATUNTIL": "endurtaka þar til %1",
    "CONTROL_WHILE": "á meðan %1",
    "CONTROL_FOREACH": "fyrir hvert %1 í %2",
    "CONTROL_STARTASCLONE": "þegar ég byrja sem klónn",
    "CONTROL_CREATECLONEOF": "búa til klón af %1",
    "CONTROL_CREATECLONEOF_MYSELF": "sjálfum mér",
    "CONTROL_DELETETHISCLONE": "eyða þessum klón",
    "CONTROL_COUNTER": "teljari",
    "CONTROL_INCRCOUNTER": "vaxandi teljari",
    "CONTROL_CLEARCOUNTER": "tæma teljara",
    "CONTROL_ALLATONCE": "alla í einu",
    "DATA_SETVARIABLETO": "láttu %1 verða %2",
    "DATA_CHANGEVARIABLEBY": "breyttu %1 um %2",
    "DATA_SHOWVARIABLE": "sýndu mér breytu %1",
    "DATA_HIDEVARIABLE": "feldu breytu %1",
    "DATA_ADDTOLIST": "bættu %1 við %2",
    "DATA_DELETEOFLIST": "fjarlægðu %1 af %2",
    "DATA_DELETEALLOFLIST": "eyddu öllu af %1",
    "DATA_INSERTATLIST": "settu %1 við %2 af %3",
    "DATA_REPLACEITEMOFLIST": "skiptu út hlut %1 af %2 fyrir %3",
    "DATA_ITEMOFLIST": "hlutur %1 af %2",
    "DATA_ITEMNUMOFLIST": "item # of %1 in %2",
    "DATA_LENGTHOFLIST": "lengd %1",
    "DATA_LISTCONTAINSITEM": "inniheldur %1, %2?",
    "DATA_SHOWLIST": "sýndu lista %1",
    "DATA_HIDELIST": "feldu lista %1",
    "DATA_INDEX_ALL": "allt",
    "DATA_INDEX_LAST": "seinasti",
    "DATA_INDEX_RANDOM": "handahófskenndur",
    "EVENT_WHENFLAGCLICKED": "þegar smellt er á %1",
    "EVENT_WHENTHISSPRITECLICKED": "þegar smellt er á þennan karakter",
    "EVENT_WHENSTAGECLICKED": "þegar smellt er a sviðið",
    "EVENT_WHENTOUCHINGOBJECT": "þegar karakterinn snertir %1",
    "EVENT_WHENBROADCASTRECEIVED": "þegar ég fæ sent %1",
    "EVENT_WHENBACKDROPSWITCHESTO": "þegar bakgrunnurinn verður %1",
    "EVENT_WHENGREATERTHAN": "þegar %1 er meira en %2",
    "EVENT_WHENGREATERTHAN_TIMER": "skeiðklukka",
    "EVENT_WHENGREATERTHAN_LOUDNESS": "hljóðstyrkur",
    "EVENT_BROADCAST": "senda %1",
    "EVENT_BROADCASTANDWAIT": "senda %1 og bíða",
    "EVENT_WHENKEYPRESSED": "þegar ýtt er á %1",
    "EVENT_WHENKEYPRESSED_SPACE": "bilslá",
    "EVENT_WHENKEYPRESSED_LEFT": "vinstri ör",
    "EVENT_WHENKEYPRESSED_RIGHT": "hægri ör",
    "EVENT_WHENKEYPRESSED_DOWN": "niður ör",
    "EVENT_WHENKEYPRESSED_UP": "upp ör",
    "EVENT_WHENKEYPRESSED_ANY": "eitthvert",
    "LOOKS_SAYFORSECS": "segðu %1 í %2 sekúndur",
    "LOOKS_SAY": "segðu %1",
    "LOOKS_HELLO": "Hæ!",
    "LOOKS_THINKFORSECS": "hugsaðu %1 í %2 sekúndur",
    "LOOKS_THINK": "hugsaðu %1",
    "LOOKS_HMM": "Hmmmm....",
    "LOOKS_SHOW": "birta",
    "LOOKS_HIDE": "fela",
    "LOOKS_HIDEALLSPRITES": "fela allar teikningar",
    "LOOKS_EFFECT_COLOR": "litur",
    "LOOKS_EFFECT_FISHEYE": "fiskauga",
    "LOOKS_EFFECT_WHIRL": "þyrla",
    "LOOKS_EFFECT_PIXELATE": "pixla",
    "LOOKS_EFFECT_MOSAIC": "mósaík",
    "LOOKS_EFFECT_BRIGHTNESS": "birtustig",
    "LOOKS_EFFECT_GHOST": "draugur",
    "LOOKS_CHANGEEFFECTBY": "breyta %1 áhrifunum um %2",
    "LOOKS_SETEFFECTTO": "settu %1 áhrifin á %2",
    "LOOKS_CLEARGRAPHICEFFECTS": "hreinsa myndbrellur",
    "LOOKS_CHANGESIZEBY": "breyta stærð um %1",
    "LOOKS_SETSIZETO": "stærð verður %1 %",
    "LOOKS_SIZE": "stærð",
    "LOOKS_CHANGESTRETCHBY": "breyta teygju um %1",
    "LOOKS_SETSTRETCHTO": "teygja verður %1 %",
    "LOOKS_SWITCHCOSTUMETO": "breyttu í búning %1",
    "LOOKS_NEXTCOSTUME": "næsti búningur",
    "LOOKS_SWITCHBACKDROPTO": "bakgrunnur verður %1",
    "LOOKS_GOTOFRONTBACK": "farðu á lag %1",
    "LOOKS_GOTOFRONTBACK_FRONT": "fremst",
    "LOOKS_GOTOFRONTBACK_BACK": "aftast",
    "LOOKS_GOFORWARDBACKWARDLAYERS": "farðu %1 %2 lög",
    "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "áfram",
    "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "aftur á bak",
    "LOOKS_BACKDROPNUMBERNAME": "bakgrunnur %1",
    "LOOKS_COSTUMENUMBERNAME": "búningur %1",
    "LOOKS_NUMBERNAME_NUMBER": "númer",
    "LOOKS_NUMBERNAME_NAME": "nafn",
    "LOOKS_SWITCHBACKDROPTOANDWAIT": "nota bakgrunn %1 og bíða",
    "LOOKS_NEXTBACKDROP_BLOCK": "næsti bakgrunnur",
    "LOOKS_NEXTBACKDROP": "næsti bakgrunnur",
    "LOOKS_PREVIOUSBACKDROP": "síðasti bakgrunnur",
    "LOOKS_RANDOMBACKDROP": "handahófskenndur bakgrunnur",
    "MOTION_MOVESTEPS": "fara %1 skref",
    "MOTION_TURNLEFT": "snúa til %1 um %2 gráður",
    "MOTION_TURNRIGHT": "snúa til %1 um %2 gráður",
    "MOTION_POINTINDIRECTION": "stefna í %1 gráður",
    "MOTION_POINTTOWARDS": "stefna í átt að %1",
    "MOTION_POINTTOWARDS_POINTER": "músarbendill",
    "MOTION_POINTTOWARDS_RANDOM": "random direction",
    "MOTION_GOTO": "fara til %1",
    "MOTION_GOTO_POINTER": "músarbendill",
    "MOTION_GOTO_RANDOM": "handahófskennd staðsetning",
    "MOTION_GOTOXY": "fara til x: %1 og y: %2",
    "MOTION_GLIDESECSTOXY": "svífa í %1 sekúndur til x: %2 og y: %3",
    "MOTION_GLIDETO": "svífa í %1 sekúndur til %2",
    "MOTION_GLIDETO_POINTER": "músarbendill",
    "MOTION_GLIDETO_RANDOM": "handahófskend staðsetning",
    "MOTION_CHANGEXBY": "breyta x um %1",
    "MOTION_SETX": "láttu x verða %1",
    "MOTION_CHANGEYBY": "breyttu y um %1",
    "MOTION_SETY": "láttu y verða %1",
    "MOTION_IFONEDGEBOUNCE": "ef á kanti, snúðu við",
    "MOTION_SETROTATIONSTYLE": "snúningsgerð verður %1",
    "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "vinstri-hægri",
    "MOTION_SETROTATIONSTYLE_DONTROTATE": "ekki snúast",
    "MOTION_SETROTATIONSTYLE_ALLAROUND": "allan hringinn",
    "MOTION_XPOSITION": "x hnit",
    "MOTION_YPOSITION": "y hnit",
    "MOTION_DIRECTION": "stefna",
    "MOTION_SCROLLRIGHT": "skrolla hægri um %1",
    "MOTION_SCROLLUP": "skrolla up %1",
    "MOTION_ALIGNSCENE": "stilla til svið %1",
    "MOTION_ALIGNSCENE_BOTTOMLEFT": "neðra vinstra megin",
    "MOTION_ALIGNSCENE_BOTTOMRIGHT": "neðra hægra megin",
    "MOTION_ALIGNSCENE_MIDDLE": "miðjunni",
    "MOTION_ALIGNSCENE_TOPLEFT": "efra vinstra megin",
    "MOTION_ALIGNSCENE_TOPRIGHT": "efra hægra megin",
    "MOTION_XSCROLL": "x skroll",
    "MOTION_YSCROLL": "y skroll",
    "MOTION_STAGE_SELECTED": "Sviðið hefur enga hreyfikubba",
    "OPERATORS_ADD": "%1 + %2",
    "OPERATORS_SUBTRACT": "%1 - %2",
    "OPERATORS_MULTIPLY": "%1 * %2",
    "OPERATORS_DIVIDE": "%1 / %2",
    "OPERATORS_RANDOM": "velja tölu á milli %1 og %2 af handahófi",
    "OPERATORS_GT": "%1 > %2",
    "OPERATORS_GT_EQUALS": "",
    "OPERATORS_LT": "%1 < %2",
    "OPERATORS_LT_EQUALS": "",
    "OPERATORS_EQUALS": "%1 = %2",
    "OPERATORS_AND": "%1 og %2",
    "OPERATORS_OR": "%1 eða %2",
    "OPERATORS_NOT": "ekki %1",
    "OPERATORS_JOIN": "sameina %1 og %2",
    "OPERATORS_JOIN_APPLE": "epli",
    "OPERATORS_JOIN_BANANA": "banani",
    "OPERATORS_LETTEROF": "stafur númer %1 úr %2",
    "OPERATORS_LETTEROF_APPLE": "e",
    "OPERATORS_LENGTH": "lengd %1",
    "OPERATORS_CONTAINS": "inniheldur %1 %2?",
    "OPERATORS_MOD": "%1 modulus %2",
    "OPERATORS_ROUND": "rúna %1",
    "OPERATORS_MATHOP": "%1 af %2",
    "OPERATORS_MATHOP_ABS": "abs",
    "OPERATORS_MATHOP_FLOOR": "rúna niður",
    "OPERATORS_MATHOP_CEILING": "rúna upp",
    "OPERATORS_MATHOP_SQRT": "kvaðratrót",
    "OPERATORS_MATHOP_SIN": "sínus",
    "OPERATORS_MATHOP_COS": "kósínus",
    "OPERATORS_MATHOP_TAN": "tangens",
    "OPERATORS_MATHOP_ASIN": "arksínus",
    "OPERATORS_MATHOP_ACOS": "arkcósínus",
    "OPERATORS_MATHOP_ATAN": "arktangens",
    "OPERATORS_MATHOP_LN": "ln",
    "OPERATORS_MATHOP_LOG": "log",
    "OPERATORS_MATHOP_EEXP": "e ^",
    "OPERATORS_MATHOP_10EXP": "10 ^",
    "PROCEDURES_DEFINITION": "skilgreina %1",
    "SENSING_TOUCHINGOBJECT": "snertir %1",
    "SENSING_TOUCHINGOBJECT_POINTER": "músarbendill",
    "SENSING_TOUCHINGOBJECT_EDGE": "brún",
    "SENSING_TOUCHINGCOLOR": "snertir litinn %1",
    "SENSING_COLORISTOUCHINGCOLOR": "liturinn %1 snertir litinn %2",
    "SENSING_DISTANCETO": "fjarlægð til %1",
    "SENSING_DISTANCETO_POINTER": "músarbendill",
    "SENSING_ASKANDWAIT": "spurðu %1 og bíddu",
    "SENSING_ASK_TEXT": "Hvað heitir þú?",
    "SENSING_ANSWER": "svar",
    "SENSING_KEYPRESSED": "hnappur %1 sleginn",
    "SENSING_MOUSEDOWN": "músarhnappur niðri?",
    "SENSING_MOUSEX": "x hnit músar",
    "SENSING_MOUSEY": "y hnit músar",
    "SENSING_SETDRAGMODE": "breyttu dragstillingu %1",
    "SENSING_SETDRAGMODE_DRAGGABLE": "draganlegur",
    "SENSING_SETDRAGMODE_NOTDRAGGABLE": "ekki draganlegur",
    "SENSING_LOUDNESS": "hljóðstyrkur",
    "SENSING_LOUD": "hávær?",
    "SENSING_TIMER": "skeiðklukka",
    "SENSING_RESETTIMER": "endurstilla skeiðklukku",
    "SENSING_OF": "%1 af %2",
    "SENSING_OF_XPOSITION": "x hnit",
    "SENSING_OF_YPOSITION": "y hnit",
    "SENSING_OF_DIRECTION": "stefna",
    "SENSING_OF_COSTUMENUMBER": "búnungur númer",
    "SENSING_OF_COSTUMENAME": "nafn búnings",
    "SENSING_OF_SIZE": "stærð",
    "SENSING_OF_VOLUME": "hljóðstyrkur",
    "SENSING_OF_BACKDROPNUMBER": "bakgrunnur númer",
    "SENSING_OF_BACKDROPNAME": "nafn bakgrunns",
    "SENSING_OF_STAGE": "Svið",
    "SENSING_CURRENT": "núverandi %1",
    "SENSING_CURRENT_YEAR": "ár",
    "SENSING_CURRENT_MONTH": "mánuður",
    "SENSING_CURRENT_DATE": "dagsetning",
    "SENSING_CURRENT_DAYOFWEEK": "vikudagur",
    "SENSING_CURRENT_HOUR": "klukkustund",
    "SENSING_CURRENT_MINUTE": "mínúta",
    "SENSING_CURRENT_SECOND": "sekúnda",
    "SENSING_DAYSSINCE2000": "dagar síðan árið 2000",
    "SENSING_USERNAME": "notendanafn",
    "SENSING_USERID": "einkenni notenda",
    "SOUND_PLAY": "spila hljóð %1",
    "SOUND_PLAYUNTILDONE": "spila hljóð %1 þar til það klárast",
    "SOUND_STOPALLSOUNDS": "stöðva öll hljóð",
    "SOUND_SETEFFECTO": "settu %1 áhrifin á %2",
    "SOUND_CHANGEEFFECTBY": "breyta %1 áhrifunum um %2",
    "SOUND_CLEAREFFECTS": "fjarlægja hljóðbreytingar",
    "SOUND_EFFECTS_PITCH": "tónhæð",
    "SOUND_EFFECTS_PAN": "skima til vinstri/hægri",
    "SOUND_CHANGEVOLUMEBY": "breyta hljóðstyrk um %1",
    "SOUND_SETVOLUMETO": "setja hljóðstyrk í  %1%",
    "SOUND_VOLUME": "hljóðstyrkur",
    "SOUND_RECORD": "record...",
    "CATEGORY_MOTION": "Hreyfing",
    "CATEGORY_LOOKS": "Útlit",
    "CATEGORY_SOUND": "Hljóð",
    "CATEGORY_EVENTS": "Atburðir",
    "CATEGORY_CONTROL": "Stjórn",
    "CATEGORY_SENSING": "Mæling",
    "CATEGORY_OPERATORS": "Virkjar",
    "CATEGORY_VARIABLES": "Breytur",
    "CATEGORY_MYBLOCKS": "Mínir kubbar",
    "CATEGORY_LISTS": "",
    "CATEGORY_TUPLE": "",
    "CATEGORY_DICTIONARYS": "",
    "CATEGORY_NUMBERS": "",
    "CATEGORY_TEXT": "",
    "DUPLICATE": "Tvöfalda",
    "DELETE": "Eyða",
    "ADD_COMMENT": "Bæta við athugasemd",
    "REMOVE_COMMENT": "Fjarlægja athugasemd",
    "DELETE_BLOCK": "Eyða kubb",
    "DELETE_X_BLOCKS": "Eyða %1 kubbum",
    "DELETE_ALL_BLOCKS": "Eyða öllum %1 kubbum",
    "CLEAN_UP": "Hreinsa til kubbana",
    "HELP": "Hjálp",
    "UNDO": "Afturkalla",
    "REDO": "Framkalla",
    "EDIT_PROCEDURE": "Breyta",
    "SHOW_PROCEDURE_DEFINITION": "Fara að skilgreiningu",
    "WORKSPACE_COMMENT_DEFAULT_TEXT": "Segðu eitthvað...",
    "COLOUR_HUE_LABEL": "Litur",
    "COLOUR_SATURATION_LABEL": "mettun",
    "COLOUR_BRIGHTNESS_LABEL": "Birtustig",
    "CHANGE_VALUE_TITLE": "Breyta gildi:",
    "RENAME_VARIABLE": "Endurskýra breytu",
    "RENAME_VARIABLE_STRING": "",
    "RENAME_VARIABLE_NUMBER": "",
    "RENAME_VARIABLE_LIST": "",
    "RENAME_VARIABLE_TITLE": "Endurskýra allar %1 breytur sem: ",
    "RENAME_VARIABLE_MODAL_TITLE": "Endurskýra breytu",
    "NEW_VARIABLE": "Smíða breytu",
    "NEW_VARIABLE_TITLE": "Nafn nýrrar breytu:",
    "VARIABLE_MODAL_TITLE": "Ný breyta",
    "VARIABLE_ALREADY_EXISTS": "Það er nú þegar til breyta sem heitir \"%1\".",
    "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "Það er nú þegar til breyta sem heitir \"%1\" fyrir aðra breytu af týpunni \"%2\".",
    "DELETE_VARIABLE_CONFIRMATION": "Þú ert að nota %2 á %1 stöðum. Viltu samt eyða breytunni?",
    "CANNOT_DELETE_VARIABLE_PROCEDURE": "Þú getur ekki eytt breytunni %1 vegna þess að þú ert að nota hana í kubb af eigin hönnun. Hann heitir: %2.",
    "DELETE_VARIABLE": "Eyða \"%1\" breytunni",
    "DELETE_VARIABLE_STRING": "",
    "DELETE_VARIABLE_NUMBER": "",
    "DELETE_VARIABLE_LIST": "",
    "NEW_PROCEDURE": "Búa til kubb",
    "PROCEDURE_ALREADY_EXISTS": "Fall með nafninu \"%1\" er nú þegar til.",
    "PROCEDURE_DEFAULT_NAME": "nafn kubbs",
    "NEW_LIST": "Búa til lista",
    "NEW_LIST_TITLE": "Nafn nýs lista:",
    "LIST_MODAL_TITLE": "Nýr listi",
    "LIST_ALREADY_EXISTS": "Það er nú þegar til listi sem heitir \"%1\".",
    "RENAME_LIST_TITLE": "Endurskýra alla %1 lista sem:",
    "RENAME_LIST_MODAL_TITLE": "Endurskýra lista",
    "DEFAULT_LIST_ITEM": "hlutur",
    "DELETE_LIST": "Delete the \"%1\" list",
    "RENAME_LIST": "Rename list",
    "NEW_BROADCAST_MESSAGE": "Ný skilaboð",
    "NEW_BROADCAST_MESSAGE_TITLE": "Nafn nýrra skilaboða",
    "BROADCAST_MODAL_TITLE": "Ný skilaboð",
    "DEFAULT_BROADCAST_MESSAGE_NAME": "dæmiUmNafnÁSkilaboðum",
    "DELETE_PROCEDURE": "",
    "OK": "",
    "PROMPT": "",
    "STOP": "",
    "NEW_STRING": "",
    "NEW_NUMBER_VARIABLE": "",
    "WIFI": "",
    "USERNAME": "",
    "PWD": "",
    "IOT_SERVICE": "",
    "IOT_ID": "",
    "IOT_PWD": "",
    "IOT_TOPIC": "",
    "MORE_TOPIC": "",
    "OBLOQ_HTTP": "",
    "OBLOQ_IP": "",
    "OBLOQ_PORT": "",
    "ADD_TOPIC": "",
    "DELETE_TOPIC": "",
    "IOT_SERVER": "",
    "CHINA": "",
    "GLOBAL": "",
    "LOW_C": "",
    "LOW_C$": "",
    "LOW_D": "",
    "LOW_D$": "",
    "LOW_E": "",
    "LOW_F": "",
    "LOW_F$": "",
    "LOW_G": "",
    "LOW_G$": "",
    "LOW_A": "",
    "LOW_A$": "",
    "LOW_B": "",
    "MIDDLE_C": "",
    "MIDDLE_C$": "",
    "MIDDLE_D": "",
    "MIDDLE_D$": "",
    "MIDDLE_E": "",
    "MIDDLE_F": "",
    "MIDDLE_F$": "",
    "MIDDLE_G": "",
    "MIDDLE_G$": "",
    "MIDDLE_A": "",
    "MIDDLE_A$": "",
    "MIDDLE_B": "",
    "HIGH_C": "",
    "HIGH_C$": "",
    "HIGH_D": "",
    "HIGH_D$": "",
    "HIGH_E": "",
    "HIGH_F": "",
    "HIGH_F$": "",
    "HIGH_G": "",
    "HIGH_G$": "",
    "HIGH_A": "",
    "HIGH_A$": "",
    "HIGH_B": "",
    "OPERATORS_STRINGTONUMBER": "",
    "INTEGER": "",
    "DECIMAL": "",
    "OPERATORS_NUMTOASCII": "",
    "OPERATORS_STRINGTOASCII": "",
    "OPERATORS_NUMTOSTRING": "",
    "OPERATORS_MAP": "",
    "OPERATORS_CONSTRAIN": "",
    "STEPS_PER_TURN": "",
    "ROTATE_SPEED": "",
    "NEW_AI": "",
    "ASK_AND_PAINT": "",
    "GET_IDENTIFIED_NUM": "",
    "READ_NUM_AND_SAY": "",
    "IMAGE_ADDR": "",
    "IMAGE_PREVIEW": "",
    "IMAGE_OPEN": "",
    "IMAGE_SIZE": "",
    "IMAGE_WIDTH": "",
    "IMAGE_HEIGHT": "",
    "VARIABLE": "",
    "VARIABLE_LIST": "",
    "SET_STRING_VARIABLE": "",
    "STRING_START_WITH": "",
    "DICTIONARY_HEIGHT_FIRST": "",
    "DICTIONARY_HEIGHT_TWO": "",
    "DICTIONARY_HEIGHT_THREE": "",
    "DICTIONARY_HEIGHT_FOUR": "",
    "DATA_MPITEMOFLIST": "",
    "DATA_MPINTERCEPTITEM": "",
    "DATA_MPINSERTATLIST": "",
    "DATA_MPSETVALUEATLIST": "",
    "DATA_MPINSERTATLIST_INSERT": "",
    "DATA_MPINSERTATLIST_CHANGE": "",
    "DATA_MPINSERTATLIST_FOUR": "",
    "DATA_MPINSERTATLIST_FIVE": "",
    "DATA_MPINSERTATLIST_SIX": "",
    "DATA_MPINSERTATLIST_SEVEN": "",
    "DATA_MPINSERTATLIST_EIGHT": "",
    "DATA_MPINSERTATLIST_EIGHT_FALSE": "",
    "DATA_MPINSERTATLIST_EIGHT_TRUE": "",
    "DATA_MPINSERTATLIST_EIGHT_REVERSE": "",
    "OPERATORS_RANDOMA": "",
    "OPERATORS_MODA": "",
    "OPERATORS_ROUNDA": "",
    "OPERATORS_MATHOPA": "",
    "OPERATORS_MATHOP_ABSA": "",
    "OPERATORS_MATHOP_FLOORA": "",
    "OPERATORS_MATHOP_CEILINGA": "",
    "OPERATORS_MATHOP_SQRTA": "",
    "OPERATORS_MATHOP_FIVE": "",
    "OPERATORS_MATHOP_SIX": "",
    "OPERATORS_MATHOP_SEVEN": "",
    "OPERATORS_MATHOP_LENGHT": "",
    "OPERATORS_MATHOP_MAXMIN": "",
    "OPERATORS_MATHOP_MAXMIN_MAX": "",
    "OPERATORS_MATHOP_MAXMIN_MIN": "",
    "OPERATORS_TEXTS_ONE": "",
    "OPERATORS_TEXTS_TWO": "",
    "OPERATORS_TEXTS_THREE": "",
    "OPERATORS_TEXTS_FOUR": "",
    "OPERATORS_TEXTS_FOUR_NEW": "",
    "OPERATORS_TEXTS_NEW": "",
    "OPERATORS_TEXTS_NEW_OP": "",
    "OPERATORS_TEXTS_FOUR_ONE": "",
    "OPERATORS_TEXTS_FOUR_TWO": "",
    "OPERATORS_TEXTS_FOUR_THREE": "",
    "OPERATORS_TEXTS_FOUR_FOUR": "",
    "OPERATORS_TEXTS_FOUR_F": "",
    "OPERATORS_TEXTS_FOUR_L": "",
    "OPERATORS_STRINGTONUMBERA": "",
    "OPERATORS_NUMTOASCIIA": "",
    "OPERATORS_STRINGTOASCIIA": "",
    "OPERATORS_NUMTOSTRINGA": "",
    "NEW_MC": "",
    "RENAME_MCNUMBER_TITLE": "",
    "RENAME_MCNUMBER_MODAL_TITLE": "",
    "RENAME_VARIABLE_MCNUMBER": "",
    "DELETE_VARIABLE_MCNUMBER": "",
    "SET_MC_VARIABLE": "",
    "TUPLE_ONE": "",
    "TUPLE_TWO": "",
    "ALIYUN": "",
    "ONENET": "",
    "EASYIOT": "",
    "SHANGHAI": "",
    "QINGDAO": "",
    "BEIJING": "",
    "ZHANGJIAKOU": "",
    "HUHEHAOTE": "",
    "HANGZHOU": "",
    "SHENZHEN": "",
    "HONGKONG": "",
    "SINGAPORE": "",
    "SYDNEY": "",
    "KUALALUMPUR": "",
    "JAKARTA": "",
    "MUMBAI": "",
    "TOKYO": "",
    "SILICONVALLEY": "",
    "VIRGINIA": "",
    "FRANKFURT": "",
    "LONDON": "",
    "DUBAI": "",
    "IOT_PLATFORM": "",
    "PARAMS": "",
    "SERVER_ATTR": "",
    "PRODUCTID": "",
    "DEVICEID": "",
    "TINYDB_SECRET": "",
    "TINYDB_USER": "",
    "APIADDR": "",
    "SOFTWARE_SERIAL": "",
    "HARDWARE_SERIAL": "",
    "HARDWARE_SERIAL1": "",
    "HARDWARE_SERIAL2": "",
    "HARDWARE_SERIAL3": ""
  },
  "it": {
    "CONTROL_FOREVER": "per sempre",
    "CONTROL_REPEAT": "ripeti %1  volte",
    "CONTROL_IF": "se %1 allora",
    "CONTROL_ELSE": "altrimenti",
    "CONTROL_STOP": "ferma",
    "CONTROL_STOP_ALL": "tutto",
    "CONTROL_STOP_THIS": "questo script",
    "CONTROL_STOP_OTHER": "tutti gli altri script dello sprite",
    "CONTROL_WAIT": "attendi %1 secondi",
    "CONTROL_WAITUNTIL": "attendi fino a quando %1",
    "CONTROL_REPEATUNTIL": "ripeti fino a quando %1",
    "CONTROL_WHILE": "mentre %1",
    "CONTROL_FOREACH": "per ogni %1 in %2",
    "CONTROL_STARTASCLONE": "quando vengo clonato",
    "CONTROL_CREATECLONEOF": "crea clone di %1",
    "CONTROL_CREATECLONEOF_MYSELF": "me stesso",
    "CONTROL_DELETETHISCLONE": "elimina questo clone",
    "CONTROL_COUNTER": "contatore",
    "CONTROL_INCRCOUNTER": "incrementa contatore",
    "CONTROL_CLEARCOUNTER": "azzera contatore",
    "CONTROL_ALLATONCE": "esegui tutto insieme",
    "DATA_SETVARIABLETO": "porta %1 a %2",
    "DATA_CHANGEVARIABLEBY": "cambia %1 di %2",
    "DATA_SHOWVARIABLE": "mostra la variabile %1",
    "DATA_HIDEVARIABLE": "nascondi la variabile %1",
    "DATA_ADDTOLIST": "aggiungi %1 a %2",
    "DATA_DELETEOFLIST": "cancella %1 da %2",
    "DATA_DELETEALLOFLIST": "cancella tutto da %1",
    "DATA_INSERTATLIST": "inserisci %1 alla posizione %2 di %3",
    "DATA_REPLACEITEMOFLIST": "sostituisci elemento %1 di %2 con %3",
    "DATA_ITEMOFLIST": "elemento %1 di %2",
    "DATA_ITEMNUMOFLIST": "posizione di %1 in %2",
    "DATA_LENGTHOFLIST": "lunghezza di %1",
    "DATA_LISTCONTAINSITEM": "%1 contiene %2",
    "DATA_SHOWLIST": "mostra la lista %1",
    "DATA_HIDELIST": "nascondi la lista %1",
    "DATA_INDEX_ALL": "tutto",
    "DATA_INDEX_LAST": "ultimo",
    "DATA_INDEX_RANDOM": "a caso",
    "EVENT_WHENFLAGCLICKED": "quando si clicca su %1",
    "EVENT_WHENTHISSPRITECLICKED": "quando si clicca questo sprite",
    "EVENT_WHENSTAGECLICKED": "quando si clicca sullo Stage",
    "EVENT_WHENTOUCHINGOBJECT": "quando questo sprite tocca %1",
    "EVENT_WHENBROADCASTRECEIVED": "quando ricevo %1",
    "EVENT_WHENBACKDROPSWITCHESTO": "quando lo sfondo passa a %1",
    "EVENT_WHENGREATERTHAN": "quando %1 > %2",
    "EVENT_WHENGREATERTHAN_TIMER": "cronometro",
    "EVENT_WHENGREATERTHAN_LOUDNESS": "volume microfono",
    "EVENT_BROADCAST": "invia a tutti %1",
    "EVENT_BROADCASTANDWAIT": "invia a tutti %1 e attendi",
    "EVENT_WHENKEYPRESSED": "quando si preme il tasto %1",
    "EVENT_WHENKEYPRESSED_SPACE": "spazio",
    "EVENT_WHENKEYPRESSED_LEFT": "freccia sinistra",
    "EVENT_WHENKEYPRESSED_RIGHT": "freccia destra",
    "EVENT_WHENKEYPRESSED_DOWN": "freccia giù",
    "EVENT_WHENKEYPRESSED_UP": "freccia su",
    "EVENT_WHENKEYPRESSED_ANY": "qualunque",
    "LOOKS_SAYFORSECS": "dire %1 per %2 secondi",
    "LOOKS_SAY": "dire %1",
    "LOOKS_HELLO": "Ciao!",
    "LOOKS_THINKFORSECS": "pensa %1 per %2 secondi",
    "LOOKS_THINK": "pensa %1",
    "LOOKS_HMM": "Uhm...",
    "LOOKS_SHOW": "mostra",
    "LOOKS_HIDE": "nascondi",
    "LOOKS_HIDEALLSPRITES": "nascondi tutti gli sprite",
    "LOOKS_EFFECT_COLOR": "colore",
    "LOOKS_EFFECT_FISHEYE": "fish-eye",
    "LOOKS_EFFECT_WHIRL": "mulinello",
    "LOOKS_EFFECT_PIXELATE": "effetto pixel",
    "LOOKS_EFFECT_MOSAIC": "mosaico",
    "LOOKS_EFFECT_BRIGHTNESS": "luminosità",
    "LOOKS_EFFECT_GHOST": "fantasma",
    "LOOKS_CHANGEEFFECTBY": "cambia effetto %1 di %2",
    "LOOKS_SETEFFECTTO": "porta effetto %1 a %2",
    "LOOKS_CLEARGRAPHICEFFECTS": "rimuovi effetti grafici",
    "LOOKS_CHANGESIZEBY": "cambia dimensione di %1",
    "LOOKS_SETSIZETO": "porta dimensione a %1 %",
    "LOOKS_SIZE": "dimensione",
    "LOOKS_CHANGESTRETCHBY": "cambia allungamento di %1",
    "LOOKS_SETSTRETCHTO": "porta allungamento a %1 %",
    "LOOKS_SWITCHCOSTUMETO": "passa al costume %1",
    "LOOKS_NEXTCOSTUME": "passa al costume seguente",
    "LOOKS_SWITCHBACKDROPTO": "passa allo sfondo %1",
    "LOOKS_GOTOFRONTBACK": "vai in %1 piano",
    "LOOKS_GOTOFRONTBACK_FRONT": "primo",
    "LOOKS_GOTOFRONTBACK_BACK": "secondo",
    "LOOKS_GOFORWARDBACKWARDLAYERS": "vai %1 di %2 livelli",
    "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "avanti",
    "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "indietro",
    "LOOKS_BACKDROPNUMBERNAME": "%1 sfondo",
    "LOOKS_COSTUMENUMBERNAME": "%1 costume",
    "LOOKS_NUMBERNAME_NUMBER": "numero",
    "LOOKS_NUMBERNAME_NAME": "nome",
    "LOOKS_SWITCHBACKDROPTOANDWAIT": "passa allo sfondo %1 e attendi",
    "LOOKS_NEXTBACKDROP_BLOCK": "passa allo sfondo seguente",
    "LOOKS_NEXTBACKDROP": "seguente",
    "LOOKS_PREVIOUSBACKDROP": "precedente",
    "LOOKS_RANDOMBACKDROP": "scelto a caso",
    "MOTION_MOVESTEPS": "fai %1 passi",
    "MOTION_TURNLEFT": "ruota %1 di %2 gradi",
    "MOTION_TURNRIGHT": "ruota %1 di %2 gradi",
    "MOTION_POINTINDIRECTION": "punta in direzione %1",
    "MOTION_POINTTOWARDS": "punta verso %1",
    "MOTION_POINTTOWARDS_POINTER": "puntatore del mouse",
    "MOTION_POINTTOWARDS_RANDOM": "direzione a caso",
    "MOTION_GOTO": "raggiungi %1",
    "MOTION_GOTO_POINTER": "puntatore del mouse",
    "MOTION_GOTO_RANDOM": "posizione a caso",
    "MOTION_GOTOXY": "vai a x: %1 y: %2",
    "MOTION_GLIDESECSTOXY": "scivola in %1 secondi a x: %2 y: %3",
    "MOTION_GLIDETO": "scivola in %1 secondi a %2",
    "MOTION_GLIDETO_POINTER": "puntatore del mouse",
    "MOTION_GLIDETO_RANDOM": "posizione a caso",
    "MOTION_CHANGEXBY": "cambia x di %1",
    "MOTION_SETX": "vai dove x è %1",
    "MOTION_CHANGEYBY": "cambia y di %1",
    "MOTION_SETY": "vai dove y è %1",
    "MOTION_IFONEDGEBOUNCE": "rimbalza quando tocchi il bordo",
    "MOTION_SETROTATIONSTYLE": "usa stile rotazione %1",
    "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "sinistra-destra",
    "MOTION_SETROTATIONSTYLE_DONTROTATE": "non ruotare",
    "MOTION_SETROTATIONSTYLE_ALLAROUND": "può ruotare",
    "MOTION_XPOSITION": "posizione x",
    "MOTION_YPOSITION": "posizione y",
    "MOTION_DIRECTION": "direzione",
    "MOTION_SCROLLRIGHT": "scrolla a destra di %1",
    "MOTION_SCROLLUP": "scrolla in alto di %1",
    "MOTION_ALIGNSCENE": "allinea la scena %1",
    "MOTION_ALIGNSCENE_BOTTOMLEFT": "in basso a sinistra",
    "MOTION_ALIGNSCENE_BOTTOMRIGHT": "in basso a destra",
    "MOTION_ALIGNSCENE_MIDDLE": "in mezzo",
    "MOTION_ALIGNSCENE_TOPLEFT": "in alto a sinistra",
    "MOTION_ALIGNSCENE_TOPRIGHT": "in alto a destra",
    "MOTION_XSCROLL": "posizione x dello scroll",
    "MOTION_YSCROLL": "posizione y dello scroll",
    "MOTION_STAGE_SELECTED": "Stage selezionato: nessun blocco di movimento",
    "OPERATORS_ADD": "%1 + %2",
    "OPERATORS_SUBTRACT": "%1 - %2",
    "OPERATORS_MULTIPLY": "%1 * %2",
    "OPERATORS_DIVIDE": "%1 / %2",
    "OPERATORS_RANDOM": "numero a caso tra %1 e %2",
    "OPERATORS_GT": "%1 > %2",
    "OPERATORS_GT_EQUALS": "",
    "OPERATORS_LT": "%1 < %2",
    "OPERATORS_LT_EQUALS": "",
    "OPERATORS_EQUALS": "%1 = %2",
    "OPERATORS_AND": "%1 e %2",
    "OPERATORS_OR": "%1 o %2",
    "OPERATORS_NOT": "non %1",
    "OPERATORS_JOIN": "unione di %1 e %2",
    "OPERATORS_JOIN_APPLE": "mela",
    "OPERATORS_JOIN_BANANA": "banana",
    "OPERATORS_LETTEROF": "lettera %1 di %2",
    "OPERATORS_LETTEROF_APPLE": "a",
    "OPERATORS_LENGTH": "lunghezza di %1",
    "OPERATORS_CONTAINS": "%1 contiene %2",
    "OPERATORS_MOD": "resto della divisione di %1 diviso %2",
    "OPERATORS_ROUND": "arrotonda %1",
    "OPERATORS_MATHOP": "%1 di %2",
    "OPERATORS_MATHOP_ABS": "valore assoluto",
    "OPERATORS_MATHOP_FLOOR": "intero inferiore",
    "OPERATORS_MATHOP_CEILING": "intero superiore",
    "OPERATORS_MATHOP_SQRT": "radice quadrata",
    "OPERATORS_MATHOP_SIN": "sen",
    "OPERATORS_MATHOP_COS": "cos",
    "OPERATORS_MATHOP_TAN": "tan",
    "OPERATORS_MATHOP_ASIN": "arcsen",
    "OPERATORS_MATHOP_ACOS": "arccos",
    "OPERATORS_MATHOP_ATAN": "arctan",
    "OPERATORS_MATHOP_LN": "ln",
    "OPERATORS_MATHOP_LOG": "log",
    "OPERATORS_MATHOP_EEXP": "e ^",
    "OPERATORS_MATHOP_10EXP": "10 ^",
    "PROCEDURES_DEFINITION": "definisci %1",
    "SENSING_TOUCHINGOBJECT": "sta toccando %1",
    "SENSING_TOUCHINGOBJECT_POINTER": "puntatore del mouse",
    "SENSING_TOUCHINGOBJECT_EDGE": "bordo",
    "SENSING_TOUCHINGCOLOR": "sta toccando il colore %1",
    "SENSING_COLORISTOUCHINGCOLOR": "il colore %1 sta toccando il colore %2",
    "SENSING_DISTANCETO": "distanza da %1",
    "SENSING_DISTANCETO_POINTER": "puntatore del mouse",
    "SENSING_ASKANDWAIT": "chiedi %1 e attendi",
    "SENSING_ASK_TEXT": "Come ti chiami?",
    "SENSING_ANSWER": "risposta",
    "SENSING_KEYPRESSED": "tasto %1 premuto",
    "SENSING_MOUSEDOWN": "pulsante del mouse premuto",
    "SENSING_MOUSEX": "x del mouse",
    "SENSING_MOUSEY": "y del mouse",
    "SENSING_SETDRAGMODE": "rendi lo sprite %1",
    "SENSING_SETDRAGMODE_DRAGGABLE": "trascinabile",
    "SENSING_SETDRAGMODE_NOTDRAGGABLE": "non trascinabile",
    "SENSING_LOUDNESS": "volume microfono",
    "SENSING_LOUD": "volume alto?",
    "SENSING_TIMER": "cronometro",
    "SENSING_RESETTIMER": "azzera cronometro",
    "SENSING_OF": "%1 di %2",
    "SENSING_OF_XPOSITION": "posizione x",
    "SENSING_OF_YPOSITION": "posizione y",
    "SENSING_OF_DIRECTION": "direzione",
    "SENSING_OF_COSTUMENUMBER": "numero del costume",
    "SENSING_OF_COSTUMENAME": "nome del costume",
    "SENSING_OF_SIZE": "dimensione",
    "SENSING_OF_VOLUME": "volume",
    "SENSING_OF_BACKDROPNUMBER": "numero dello sfondo",
    "SENSING_OF_BACKDROPNAME": "nome dello sfondo",
    "SENSING_OF_STAGE": "Stage",
    "SENSING_CURRENT": "%1 attuale",
    "SENSING_CURRENT_YEAR": "anno",
    "SENSING_CURRENT_MONTH": "mese",
    "SENSING_CURRENT_DATE": "data",
    "SENSING_CURRENT_DAYOFWEEK": "giorno della settimana",
    "SENSING_CURRENT_HOUR": "ora",
    "SENSING_CURRENT_MINUTE": "minuto",
    "SENSING_CURRENT_SECOND": "secondo",
    "SENSING_DAYSSINCE2000": "giorni trascorsi dal 2000",
    "SENSING_USERNAME": "username",
    "SENSING_USERID": "user id",
    "SOUND_PLAY": "avvia riproduzione suono %1",
    "SOUND_PLAYUNTILDONE": "riproduci suono %1 e attendi la fine",
    "SOUND_STOPALLSOUNDS": "ferma tutti i suoni",
    "SOUND_SETEFFECTO": "porta effetto %1 a %2",
    "SOUND_CHANGEEFFECTBY": "cambia effetto %1 di %2",
    "SOUND_CLEAREFFECTS": "rimuovi effetti audio",
    "SOUND_EFFECTS_PITCH": "frequenza",
    "SOUND_EFFECTS_PAN": "panning sinistra/destra",
    "SOUND_CHANGEVOLUMEBY": "cambia volume di %1",
    "SOUND_SETVOLUMETO": "porta volume a %1%",
    "SOUND_VOLUME": "volume",
    "SOUND_RECORD": "registrazione...",
    "CATEGORY_MOTION": "Movimento",
    "CATEGORY_LOOKS": "Aspetto",
    "CATEGORY_SOUND": "Suono",
    "CATEGORY_EVENTS": "Situazioni",
    "CATEGORY_CONTROL": "Controllo",
    "CATEGORY_SENSING": "Sensori",
    "CATEGORY_OPERATORS": "Operatori",
    "CATEGORY_VARIABLES": "Variabili",
    "CATEGORY_MYBLOCKS": "I Miei Blocchi",
    "CATEGORY_LISTS": "",
    "CATEGORY_TUPLE": "",
    "CATEGORY_DICTIONARYS": "",
    "CATEGORY_NUMBERS": "",
    "CATEGORY_TEXT": "",
    "DUPLICATE": "Duplica",
    "DELETE": "Cancella",
    "ADD_COMMENT": "Aggiungi commento",
    "REMOVE_COMMENT": "Rimuovi commento",
    "DELETE_BLOCK": "Cancella questo Blocco",
    "DELETE_X_BLOCKS": "Cancella %1 Blocchi",
    "DELETE_ALL_BLOCKS": "Cancellare i %1 blocchi?",
    "CLEAN_UP": "Riordina i Blocchi",
    "HELP": "Aiuto",
    "UNDO": "Annulla",
    "REDO": "Ripristina",
    "EDIT_PROCEDURE": "Modifica",
    "SHOW_PROCEDURE_DEFINITION": "Vai alla definizione",
    "WORKSPACE_COMMENT_DEFAULT_TEXT": "Dì qualcosa...",
    "COLOUR_HUE_LABEL": "Colore",
    "COLOUR_SATURATION_LABEL": "Saturazione",
    "COLOUR_BRIGHTNESS_LABEL": "Luminosità",
    "CHANGE_VALUE_TITLE": "Cambia il valore:",
    "RENAME_VARIABLE": "Rinomina la variabile",
    "RENAME_VARIABLE_STRING": "",
    "RENAME_VARIABLE_NUMBER": "",
    "RENAME_VARIABLE_LIST": "",
    "RENAME_VARIABLE_TITLE": "Rinomina tutti i riferimenti alla variabile \"%1\" come:",
    "RENAME_VARIABLE_MODAL_TITLE": "Rinomina la Variabile",
    "NEW_VARIABLE": "Crea una Variabile",
    "NEW_VARIABLE_TITLE": "Nome della nuova variabile:",
    "VARIABLE_MODAL_TITLE": "Nuova Variabile",
    "VARIABLE_ALREADY_EXISTS": "Una variabile chiamata \"%1\" esiste già.",
    "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "Una variabile chiamata \"%1\" già esiste per un'altra variabile di tipo '%2'.",
    "DELETE_VARIABLE_CONFIRMATION": "Eliminare tutti i %1 riferimenti alla variabile \"%2\"?",
    "CANNOT_DELETE_VARIABLE_PROCEDURE": "Non è possibile eliminare la variabile \"%1\" perchè fa parte della definizione della funzione \"%2\"",
    "DELETE_VARIABLE": "Elimina la variabile \"%1\"",
    "DELETE_VARIABLE_STRING": "",
    "DELETE_VARIABLE_NUMBER": "",
    "DELETE_VARIABLE_LIST": "",
    "NEW_PROCEDURE": "Crea un Blocco",
    "PROCEDURE_ALREADY_EXISTS": "Una procedura chiamata \"%1\" esiste già.",
    "PROCEDURE_DEFAULT_NAME": "nome del blocco",
    "NEW_LIST": "Crea una Lista",
    "NEW_LIST_TITLE": "Nome della nuova lista:",
    "LIST_MODAL_TITLE": "Nuova Lista",
    "LIST_ALREADY_EXISTS": "Una lista chiamata \"%1\" esiste già.",
    "RENAME_LIST_TITLE": "Rinomina tutti i riferimenti alla lista \"%1\" come:",
    "RENAME_LIST_MODAL_TITLE": "Rinomina la Lista",
    "DEFAULT_LIST_ITEM": "cosa",
    "DELETE_LIST": "Cancella la lista \"%1\"",
    "RENAME_LIST": "Rinomina lista",
    "NEW_BROADCAST_MESSAGE": "Nuovo messaggio",
    "NEW_BROADCAST_MESSAGE_TITLE": "Nome del nuovo messaggio:",
    "BROADCAST_MODAL_TITLE": "Nuovo Messaggio",
    "DEFAULT_BROADCAST_MESSAGE_NAME": "messaggio1",
    "DELETE_PROCEDURE": "",
    "OK": "",
    "PROMPT": "",
    "STOP": "",
    "NEW_STRING": "",
    "NEW_NUMBER_VARIABLE": "",
    "WIFI": "",
    "USERNAME": "",
    "PWD": "",
    "IOT_SERVICE": "",
    "IOT_ID": "",
    "IOT_PWD": "",
    "IOT_TOPIC": "",
    "MORE_TOPIC": "",
    "OBLOQ_HTTP": "",
    "OBLOQ_IP": "",
    "OBLOQ_PORT": "",
    "ADD_TOPIC": "",
    "DELETE_TOPIC": "",
    "IOT_SERVER": "",
    "CHINA": "",
    "GLOBAL": "",
    "LOW_C": "",
    "LOW_C$": "",
    "LOW_D": "",
    "LOW_D$": "",
    "LOW_E": "",
    "LOW_F": "",
    "LOW_F$": "",
    "LOW_G": "",
    "LOW_G$": "",
    "LOW_A": "",
    "LOW_A$": "",
    "LOW_B": "",
    "MIDDLE_C": "",
    "MIDDLE_C$": "",
    "MIDDLE_D": "",
    "MIDDLE_D$": "",
    "MIDDLE_E": "",
    "MIDDLE_F": "",
    "MIDDLE_F$": "",
    "MIDDLE_G": "",
    "MIDDLE_G$": "",
    "MIDDLE_A": "",
    "MIDDLE_A$": "",
    "MIDDLE_B": "",
    "HIGH_C": "",
    "HIGH_C$": "",
    "HIGH_D": "",
    "HIGH_D$": "",
    "HIGH_E": "",
    "HIGH_F": "",
    "HIGH_F$": "",
    "HIGH_G": "",
    "HIGH_G$": "",
    "HIGH_A": "",
    "HIGH_A$": "",
    "HIGH_B": "",
    "OPERATORS_STRINGTONUMBER": "",
    "INTEGER": "",
    "DECIMAL": "",
    "OPERATORS_NUMTOASCII": "",
    "OPERATORS_STRINGTOASCII": "",
    "OPERATORS_NUMTOSTRING": "",
    "OPERATORS_MAP": "",
    "OPERATORS_CONSTRAIN": "",
    "STEPS_PER_TURN": "",
    "ROTATE_SPEED": "",
    "NEW_AI": "",
    "ASK_AND_PAINT": "",
    "GET_IDENTIFIED_NUM": "",
    "READ_NUM_AND_SAY": "",
    "IMAGE_ADDR": "",
    "IMAGE_PREVIEW": "",
    "IMAGE_OPEN": "",
    "IMAGE_SIZE": "",
    "IMAGE_WIDTH": "",
    "IMAGE_HEIGHT": "",
    "VARIABLE": "",
    "VARIABLE_LIST": "",
    "SET_STRING_VARIABLE": "",
    "STRING_START_WITH": "",
    "DICTIONARY_HEIGHT_FIRST": "",
    "DICTIONARY_HEIGHT_TWO": "",
    "DICTIONARY_HEIGHT_THREE": "",
    "DICTIONARY_HEIGHT_FOUR": "",
    "DATA_MPITEMOFLIST": "",
    "DATA_MPINTERCEPTITEM": "",
    "DATA_MPINSERTATLIST": "",
    "DATA_MPSETVALUEATLIST": "",
    "DATA_MPINSERTATLIST_INSERT": "",
    "DATA_MPINSERTATLIST_CHANGE": "",
    "DATA_MPINSERTATLIST_FOUR": "",
    "DATA_MPINSERTATLIST_FIVE": "",
    "DATA_MPINSERTATLIST_SIX": "",
    "DATA_MPINSERTATLIST_SEVEN": "",
    "DATA_MPINSERTATLIST_EIGHT": "",
    "DATA_MPINSERTATLIST_EIGHT_FALSE": "",
    "DATA_MPINSERTATLIST_EIGHT_TRUE": "",
    "DATA_MPINSERTATLIST_EIGHT_REVERSE": "",
    "OPERATORS_RANDOMA": "",
    "OPERATORS_MODA": "",
    "OPERATORS_ROUNDA": "",
    "OPERATORS_MATHOPA": "",
    "OPERATORS_MATHOP_ABSA": "",
    "OPERATORS_MATHOP_FLOORA": "",
    "OPERATORS_MATHOP_CEILINGA": "",
    "OPERATORS_MATHOP_SQRTA": "",
    "OPERATORS_MATHOP_FIVE": "",
    "OPERATORS_MATHOP_SIX": "",
    "OPERATORS_MATHOP_SEVEN": "",
    "OPERATORS_MATHOP_LENGHT": "",
    "OPERATORS_MATHOP_MAXMIN": "",
    "OPERATORS_MATHOP_MAXMIN_MAX": "",
    "OPERATORS_MATHOP_MAXMIN_MIN": "",
    "OPERATORS_TEXTS_ONE": "",
    "OPERATORS_TEXTS_TWO": "",
    "OPERATORS_TEXTS_THREE": "",
    "OPERATORS_TEXTS_FOUR": "",
    "OPERATORS_TEXTS_FOUR_NEW": "",
    "OPERATORS_TEXTS_NEW": "",
    "OPERATORS_TEXTS_NEW_OP": "",
    "OPERATORS_TEXTS_FOUR_ONE": "",
    "OPERATORS_TEXTS_FOUR_TWO": "",
    "OPERATORS_TEXTS_FOUR_THREE": "",
    "OPERATORS_TEXTS_FOUR_FOUR": "",
    "OPERATORS_TEXTS_FOUR_F": "",
    "OPERATORS_TEXTS_FOUR_L": "",
    "OPERATORS_STRINGTONUMBERA": "",
    "OPERATORS_NUMTOASCIIA": "",
    "OPERATORS_STRINGTOASCIIA": "",
    "OPERATORS_NUMTOSTRINGA": "",
    "NEW_MC": "",
    "RENAME_MCNUMBER_TITLE": "",
    "RENAME_MCNUMBER_MODAL_TITLE": "",
    "RENAME_VARIABLE_MCNUMBER": "",
    "DELETE_VARIABLE_MCNUMBER": "",
    "SET_MC_VARIABLE": "",
    "TUPLE_ONE": "",
    "TUPLE_TWO": "",
    "ALIYUN": "",
    "ONENET": "",
    "EASYIOT": "",
    "SHANGHAI": "",
    "QINGDAO": "",
    "BEIJING": "",
    "ZHANGJIAKOU": "",
    "HUHEHAOTE": "",
    "HANGZHOU": "",
    "SHENZHEN": "",
    "HONGKONG": "",
    "SINGAPORE": "",
    "SYDNEY": "",
    "KUALALUMPUR": "",
    "JAKARTA": "",
    "MUMBAI": "",
    "TOKYO": "",
    "SILICONVALLEY": "",
    "VIRGINIA": "",
    "FRANKFURT": "",
    "LONDON": "",
    "DUBAI": "",
    "IOT_PLATFORM": "",
    "PARAMS": "",
    "SERVER_ATTR": "",
    "PRODUCTID": "",
    "DEVICEID": "",
    "TINYDB_SECRET": "",
    "TINYDB_USER": "",
    "APIADDR": "",
    "SOFTWARE_SERIAL": "",
    "HARDWARE_SERIAL": "",
    "HARDWARE_SERIAL1": "",
    "HARDWARE_SERIAL2": "",
    "HARDWARE_SERIAL3": ""
  },
  "ckb": {
    "CONTROL_FOREVER": "بۆهەتایە",
    "CONTROL_REPEAT": "دوبارەکردنەوە %1",
    "CONTROL_IF": "ئەگەر %1 ئینجا",
    "CONTROL_ELSE": "ئەگەرنا",
    "CONTROL_STOP": "وەستان",
    "CONTROL_STOP_ALL": "هەموو",
    "CONTROL_STOP_THIS": "ئەم سکریپتە",
    "CONTROL_STOP_OTHER": "سکریپتەکانی تر لە تەن",
    "CONTROL_WAIT": "بوەستە %1 چرکە",
    "CONTROL_WAITUNTIL": "چاوەڕێ بکە تا %1",
    "CONTROL_REPEATUNTIL": "دوبارەکردنەوە تا %1",
    "CONTROL_WHILE": "کاتێک %1",
    "CONTROL_FOREACH": "بۆ هەر %1 لە %2",
    "CONTROL_STARTASCLONE": "کاتێک دەستپێدەکەم وەک لێکچووێک",
    "CONTROL_CREATECLONEOF": "دروستکردنی لێکچوو لە %1",
    "CONTROL_CREATECLONEOF_MYSELF": "خۆم",
    "CONTROL_DELETETHISCLONE": "سڕینەوەی ئەم لێکچووە",
    "CONTROL_COUNTER": "ژمێرەر",
    "CONTROL_INCRCOUNTER": "زیادکردنی ژمێرەر",
    "CONTROL_CLEARCOUNTER": "بەتاڵکردنەوەی ژمێرەر",
    "CONTROL_ALLATONCE": "هەموو بەیەکەوە",
    "DATA_SETVARIABLETO": "دانانی %1 بۆ %2",
    "DATA_CHANGEVARIABLEBY": "گۆڕین %1 بە %2",
    "DATA_SHOWVARIABLE": "پیشاندانی گۆڕاو %1",
    "DATA_HIDEVARIABLE": "شاردنەوەی گۆڕاوی %1",
    "DATA_ADDTOLIST": "زیادکردنی %1 بۆ %2",
    "DATA_DELETEOFLIST": "سڕینەوە %1 لە %2",
    "DATA_DELETEALLOFLIST": "سڕینەوەی هەموو لە %1",
    "DATA_INSERTATLIST": "خستنەناو %1 بۆ %2 لە %3",
    "DATA_REPLACEITEMOFLIST": "گۆڕینەوەی دانە %1 لە %2 لەگەڵ %3",
    "DATA_ITEMOFLIST": "دانە %1 لە %2",
    "DATA_ITEMNUMOFLIST": "دانەی # %1 لە %2",
    "DATA_LENGTHOFLIST": "درێژی لە %1",
    "DATA_LISTCONTAINSITEM": "%1 تێیدایە %2؟",
    "DATA_SHOWLIST": "پیشاندانی لیست %1",
    "DATA_HIDELIST": "شاردنەوەی لیست %1",
    "DATA_INDEX_ALL": "هەموو",
    "DATA_INDEX_LAST": "دوایەمین",
    "DATA_INDEX_RANDOM": "هەڕەمەکی",
    "EVENT_WHENFLAGCLICKED": "کاتێک %1 کرتەکرا",
    "EVENT_WHENTHISSPRITECLICKED": "کاتێک ئەم تەنە کرتەکرا",
    "EVENT_WHENSTAGECLICKED": "کاتێک سەکۆ کرتەکرا",
    "EVENT_WHENTOUCHINGOBJECT": "کاتێک ئەم تەنە بەرکەوت %1",
    "EVENT_WHENBROADCASTRECEIVED": "کاتێک بەدەستم گەیشت %1",
    "EVENT_WHENBACKDROPSWITCHESTO": "کاتێک پاشبنەما دەگۆڕێت بۆ %1",
    "EVENT_WHENGREATERTHAN": "کاتێک %1 > %2",
    "EVENT_WHENGREATERTHAN_TIMER": "کاتێنەر",
    "EVENT_WHENGREATERTHAN_LOUDNESS": "دەنگەدەنگ",
    "EVENT_BROADCAST": "پەخشکردن %1",
    "EVENT_BROADCASTANDWAIT": "پەخشکردنی %1 و چاوەڕێکردن",
    "EVENT_WHENKEYPRESSED": "کاتێک دوگمەی %1 کرتەکرا",
    "EVENT_WHENKEYPRESSED_SPACE": "بۆشایی",
    "EVENT_WHENKEYPRESSED_LEFT": "تیری چەپ",
    "EVENT_WHENKEYPRESSED_RIGHT": "تیری ڕاست",
    "EVENT_WHENKEYPRESSED_DOWN": "تیری خواروو",
    "EVENT_WHENKEYPRESSED_UP": "تیری سەروو",
    "EVENT_WHENKEYPRESSED_ANY": "هەر کام",
    "LOOKS_SAYFORSECS": "بڵێ %1 بۆ %2 چرکە",
    "LOOKS_SAY": "بڵێ %1",
    "LOOKS_HELLO": "سڵاو!",
    "LOOKS_THINKFORSECS": "بیرکردنەوە %1 بۆ %2 چرکە",
    "LOOKS_THINK": "بیرکردنەوەی %1",
    "LOOKS_HMM": "ئمم...",
    "LOOKS_SHOW": "پیشاندان",
    "LOOKS_HIDE": "شاردنەوە",
    "LOOKS_HIDEALLSPRITES": "شاردنەوەی هەموو تەنەکان",
    "LOOKS_EFFECT_COLOR": "ڕەنگ",
    "LOOKS_EFFECT_FISHEYE": "چاوماسی",
    "LOOKS_EFFECT_WHIRL": "خولانەوە",
    "LOOKS_EFFECT_PIXELATE": "وێنەخاڵکردن",
    "LOOKS_EFFECT_MOSAIC": "موزایک",
    "LOOKS_EFFECT_BRIGHTNESS": "ڕووناکی",
    "LOOKS_EFFECT_GHOST": "خێو",
    "LOOKS_CHANGEEFFECTBY": "گۆڕینی %1 کاریگەری بە %2",
    "LOOKS_SETEFFECTTO": "دانانی %1 کاریگەری بۆ %2",
    "LOOKS_CLEARGRAPHICEFFECTS": "سڕینەوەی کاریگەرییەکانی هێلکاری",
    "LOOKS_CHANGESIZEBY": "گۆڕینی قەبارە بە %1",
    "LOOKS_SETSIZETO": "دانانی قەبارە بە %1 ٪",
    "LOOKS_SIZE": "قەبارە",
    "LOOKS_CHANGESTRETCHBY": "گۆڕینی درێژبوونەوە بە %1",
    "LOOKS_SETSTRETCHTO": "دانانی درێژبوونەوە بە %1 ٪",
    "LOOKS_SWITCHCOSTUMETO": "گۆڕینی بەرگ بۆ %1",
    "LOOKS_NEXTCOSTUME": "بەرگی دواتر",
    "LOOKS_SWITCHBACKDROPTO": "گۆڕینی پاشبنەما بۆ %1",
    "LOOKS_GOTOFRONTBACK": "بڕۆ بۆ %1 چین",
    "LOOKS_GOTOFRONTBACK_FRONT": "پێشەوە",
    "LOOKS_GOTOFRONTBACK_BACK": "دواوە",
    "LOOKS_GOFORWARDBACKWARDLAYERS": "بڕۆ بۆ %1 %2 چین",
    "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "بۆ پێشەوە",
    "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "بۆ دواوە",
    "LOOKS_BACKDROPNUMBERNAME": "پاشبنه‌ما %1",
    "LOOKS_COSTUMENUMBERNAME": "بەرگ %1",
    "LOOKS_NUMBERNAME_NUMBER": "ژمارە",
    "LOOKS_NUMBERNAME_NAME": "ناو",
    "LOOKS_SWITCHBACKDROPTOANDWAIT": "گۆڕینی پاشبنەما بۆ %1 و چاوەڕێ بکە",
    "LOOKS_NEXTBACKDROP_BLOCK": "پاشبنەمای دواتر",
    "LOOKS_NEXTBACKDROP": "پاشبنەمای دواتر",
    "LOOKS_PREVIOUSBACKDROP": "پاشبنەمای پێشتر",
    "LOOKS_RANDOMBACKDROP": "پاشبنەمای هەڕەمەکی",
    "MOTION_MOVESTEPS": "جوڵان %1 هەنگاو",
    "MOTION_TURNLEFT": "بیسوڕێنەوە %1 %2 پلە",
    "MOTION_TURNRIGHT": "بیسوڕێنەوە %1 %2 پلە",
    "MOTION_POINTINDIRECTION": "خاڵ بە ئاڕاستەی %1",
    "MOTION_POINTTOWARDS": "خاڵ بەرەو %1",
    "MOTION_POINTTOWARDS_POINTER": "نیشاندەری ماوس",
    "MOTION_POINTTOWARDS_RANDOM": "ئاڕاستەی هەڕەمەکی",
    "MOTION_GOTO": "بڕۆ بۆ %1",
    "MOTION_GOTO_POINTER": "نیشاندەری ماوس",
    "MOTION_GOTO_RANDOM": "شوێنی هەڕەمەکی",
    "MOTION_GOTOXY": "بڕۆ بۆ x: %1   y: %2",
    "MOTION_GLIDESECSTOXY": "خلیسکان %1 چرکە بۆ x %2  y %3",
    "MOTION_GLIDETO": "خلیسکان %1 چرکە بۆ %2",
    "MOTION_GLIDETO_POINTER": "نیشاندەری ماوس",
    "MOTION_GLIDETO_RANDOM": "شوێنی هەڕەمەکی",
    "MOTION_CHANGEXBY": "گۆڕینی x بە %1",
    "MOTION_SETX": "دانانی x بە %1",
    "MOTION_CHANGEYBY": "گۆڕینی y بە %1",
    "MOTION_SETY": "دانانی y بە %1",
    "MOTION_IFONEDGEBOUNCE": "ئەگەر لە لێواربوو، بگەڕێوە",
    "MOTION_SETROTATIONSTYLE": "دانانی شێوازی سوڕانەوە %1",
    "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "چەپ-ڕاست",
    "MOTION_SETROTATIONSTYLE_DONTROTATE": "مەیسوڕێنە",
    "MOTION_SETROTATIONSTYLE_ALLAROUND": "بەدەوری",
    "MOTION_XPOSITION": "شوێنی x",
    "MOTION_YPOSITION": "شوێنی y",
    "MOTION_DIRECTION": "ئاڕاستە",
    "MOTION_SCROLLRIGHT": "پێچۆکە بۆ ڕاست %1",
    "MOTION_SCROLLUP": "پێچۆکه‌ هه‌ڵکێشان %1",
    "MOTION_ALIGNSCENE": "لاگرتنی دیمەن %1",
    "MOTION_ALIGNSCENE_BOTTOMLEFT": "ژێرەوە-چەپ",
    "MOTION_ALIGNSCENE_BOTTOMRIGHT": "ژێرەوە-ڕاست",
    "MOTION_ALIGNSCENE_MIDDLE": "ناوەند",
    "MOTION_ALIGNSCENE_TOPLEFT": "سەرەوە-چەپ",
    "MOTION_ALIGNSCENE_TOPRIGHT": "سەرەوە-ڕاست",
    "MOTION_XSCROLL": "پێچۆکەی x",
    "MOTION_YSCROLL": "پێچۆکەی y",
    "MOTION_STAGE_SELECTED": "سەکۆی دیاریکراو: هیچ بلۆکێکی جوڵاندن",
    "OPERATORS_ADD": "%1 + %2",
    "OPERATORS_SUBTRACT": "%1 - %2",
    "OPERATORS_MULTIPLY": "%1 * %2",
    "OPERATORS_DIVIDE": "%1 / %2",
    "OPERATORS_RANDOM": "هەڵبژاردنی هەڕەمەکی %1 بۆ %2",
    "OPERATORS_GT": "%1 > %2",
    "OPERATORS_GT_EQUALS": "",
    "OPERATORS_LT": "%1 < %2",
    "OPERATORS_LT_EQUALS": "",
    "OPERATORS_EQUALS": "%1 = %2",
    "OPERATORS_AND": "%1 and %2",
    "OPERATORS_OR": "%1 or %2",
    "OPERATORS_NOT": "نا %1",
    "OPERATORS_JOIN": "پەیوەندی %1 %2",
    "OPERATORS_JOIN_APPLE": "سێو",
    "OPERATORS_JOIN_BANANA": "مۆز",
    "OPERATORS_LETTEROF": "پیت %1 لە %2",
    "OPERATORS_LETTEROF_APPLE": "ێ",
    "OPERATORS_LENGTH": "درێژی لە %1",
    "OPERATORS_CONTAINS": "%1 تێیدایە %2؟",
    "OPERATORS_MOD": "%1 ماوە %2",
    "OPERATORS_ROUND": "نزیککردنەوە %1",
    "OPERATORS_MATHOP": "%1 لە %2",
    "OPERATORS_MATHOP_ABS": "ڕەگی دووجا",
    "OPERATORS_MATHOP_FLOOR": "زەوی",
    "OPERATORS_MATHOP_CEILING": "بنمیچ",
    "OPERATORS_MATHOP_SQRT": "ڕەگی دووجا",
    "OPERATORS_MATHOP_SIN": "تەژێ",
    "OPERATORS_MATHOP_COS": "تەژێ تەواو",
    "OPERATORS_MATHOP_TAN": "تانجێنت",
    "OPERATORS_MATHOP_ASIN": "asin",
    "OPERATORS_MATHOP_ACOS": "acos",
    "OPERATORS_MATHOP_ATAN": "atan",
    "OPERATORS_MATHOP_LN": "لن",
    "OPERATORS_MATHOP_LOG": "لۆگاریتم",
    "OPERATORS_MATHOP_EEXP": "e ^",
    "OPERATORS_MATHOP_10EXP": "10 ^",
    "PROCEDURES_DEFINITION": "پێناسەی %1",
    "SENSING_TOUCHINGOBJECT": "بەرکەوتنی %1؟",
    "SENSING_TOUCHINGOBJECT_POINTER": "نیشاندەری ماوس",
    "SENSING_TOUCHINGOBJECT_EDGE": "لێوار",
    "SENSING_TOUCHINGCOLOR": "بەرکەوتنی ڕەنگ %1؟",
    "SENSING_COLORISTOUCHINGCOLOR": "ڕەنگ %1 بەردەکەوێت %2؟",
    "SENSING_DISTANCETO": "دووری بۆ %1",
    "SENSING_DISTANCETO_POINTER": "نیشاندەری ماوس",
    "SENSING_ASKANDWAIT": "پرسیار بکە %1 و چاوەڕێ بکە",
    "SENSING_ASK_TEXT": "ناوت چییە؟",
    "SENSING_ANSWER": "وەڵام",
    "SENSING_KEYPRESSED": "دوگمەی %1 داگیرا؟",
    "SENSING_MOUSEDOWN": "ماوس بۆ خوارەوە؟",
    "SENSING_MOUSEX": "ماوس x",
    "SENSING_MOUSEY": "ماوس y",
    "SENSING_SETDRAGMODE": "دانانی شێوازی  ڕاکێشان %1",
    "SENSING_SETDRAGMODE_DRAGGABLE": "ڕادەکێشترێت",
    "SENSING_SETDRAGMODE_NOTDRAGGABLE": "ڕاناکێشرێت",
    "SENSING_LOUDNESS": "دەنگەدەنگ",
    "SENSING_LOUD": "بەدەنگی بەرز؟",
    "SENSING_TIMER": "کاتێنەر",
    "SENSING_RESETTIMER": "ڕێکخستنەوەی کاتێنەر",
    "SENSING_OF": "%1 لە %2",
    "SENSING_OF_XPOSITION": "شوێنی x",
    "SENSING_OF_YPOSITION": "شوێنی y",
    "SENSING_OF_DIRECTION": "ئاڕاستە",
    "SENSING_OF_COSTUMENUMBER": "بەرگ #",
    "SENSING_OF_COSTUMENAME": "ناوی بەرگ",
    "SENSING_OF_SIZE": "قەبارە",
    "SENSING_OF_VOLUME": "قەبارە",
    "SENSING_OF_BACKDROPNUMBER": "پاشبنه‌ما #",
    "SENSING_OF_BACKDROPNAME": "ناوی پاشبنەما",
    "SENSING_OF_STAGE": "سەکۆ",
    "SENSING_CURRENT": "ئێستا %1",
    "SENSING_CURRENT_YEAR": "ساڵ",
    "SENSING_CURRENT_MONTH": "مانگ",
    "SENSING_CURRENT_DATE": "بەروار",
    "SENSING_CURRENT_DAYOFWEEK": "ڕۆژی هەفتە",
    "SENSING_CURRENT_HOUR": "کاتژمێر",
    "SENSING_CURRENT_MINUTE": "خولەک",
    "SENSING_CURRENT_SECOND": "چرکە",
    "SENSING_DAYSSINCE2000": "ڕۆژ لەوەتەی 2000",
    "SENSING_USERNAME": "ناوی بەکارهێنەر",
    "SENSING_USERID": "ژمارەی بەکارهێنەر",
    "SOUND_PLAY": "دەستپێکردنی دەنگ %1",
    "SOUND_PLAYUNTILDONE": "لێدانی دەنگی %1 تا تەواوبوون",
    "SOUND_STOPALLSOUNDS": "هەموو دەنگەکان ڕاگرە",
    "SOUND_SETEFFECTO": "دانانی %1 کاریگەری بۆ %2",
    "SOUND_CHANGEEFFECTBY": "گۆڕینی %1 کاریگەری بە %2",
    "SOUND_CLEAREFFECTS": "سڕینەوەی کاریگەرییەکانی دەنگ",
    "SOUND_EFFECTS_PITCH": "بەرزی دەنگ",
    "SOUND_EFFECTS_PAN": "سوڕانەوە چەپ/ڕاست",
    "SOUND_CHANGEVOLUMEBY": "گۆڕینی دەنگ بە %1",
    "SOUND_SETVOLUMETO": "دانانی دانگ بە %1%",
    "SOUND_VOLUME": "قەبارە",
    "SOUND_RECORD": "تۆمارکردن...",
    "CATEGORY_MOTION": "جوڵان",
    "CATEGORY_LOOKS": "ڕوانین",
    "CATEGORY_SOUND": "دەنگ",
    "CATEGORY_EVENTS": "چالاکییەکان",
    "CATEGORY_CONTROL": "کۆنتڕۆڵ",
    "CATEGORY_SENSING": "هەستکردن",
    "CATEGORY_OPERATORS": "كرده‌هێماکان",
    "CATEGORY_VARIABLES": "گۆڕاوەکان",
    "CATEGORY_MYBLOCKS": "بلۆکەکانم",
    "CATEGORY_LISTS": "",
    "CATEGORY_TUPLE": "",
    "CATEGORY_DICTIONARYS": "",
    "CATEGORY_NUMBERS": "",
    "CATEGORY_TEXT": "",
    "DUPLICATE": "هاوشێوەکردنەوە",
    "DELETE": "سڕینەوە",
    "ADD_COMMENT": "زیادکردنی لێدوان",
    "REMOVE_COMMENT": "لابردنی لێدوان",
    "DELETE_BLOCK": "سڕینەوەی بلۆک",
    "DELETE_X_BLOCKS": "سڕینەوەی %1 بلۆک",
    "DELETE_ALL_BLOCKS": "سڕینەوەی هەموو %1 بلۆکەکان؟",
    "CLEAN_UP": "بەتاڵکردنەوەی بلۆکەکان",
    "HELP": "یارمەتی",
    "UNDO": "هه‌ڵوه‌شاندنه‌وه‌",
    "REDO": "کردنەوە",
    "EDIT_PROCEDURE": "دەستکاری",
    "SHOW_PROCEDURE_DEFINITION": "بڕۆ بۆ پێناسە",
    "WORKSPACE_COMMENT_DEFAULT_TEXT": "شتێک بڵێ",
    "COLOUR_HUE_LABEL": "ڕەنگ",
    "COLOUR_SATURATION_LABEL": "تێری",
    "COLOUR_BRIGHTNESS_LABEL": "ڕووناکی",
    "CHANGE_VALUE_TITLE": "گۆڕینی نرخ:",
    "RENAME_VARIABLE": "ناونانەوەی گۆڕاو",
    "RENAME_VARIABLE_STRING": "",
    "RENAME_VARIABLE_NUMBER": "",
    "RENAME_VARIABLE_LIST": "",
    "RENAME_VARIABLE_TITLE": "ناونانەوەی هەموو \"%1\" گۆڕاوەکان بۆ:",
    "RENAME_VARIABLE_MODAL_TITLE": "ناونانەوەی گۆڕاو",
    "NEW_VARIABLE": "دروستکردنی گۆڕاوێک",
    "NEW_VARIABLE_TITLE": "ناوی نوێی گۆڕاو",
    "VARIABLE_MODAL_TITLE": "گۆڕاوی نوێ",
    "VARIABLE_ALREADY_EXISTS": "گۆڕاوی ناونراو \"%1\" هەیە.",
    "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "گۆڕاوی ناونراو \"%1\" هەیە بۆ گۆڕاوێکی تر لە جۆری \"%2\".",
    "DELETE_VARIABLE_CONFIRMATION": "سڕینەوەی %1 بەکارهێنانی \"%2\" گۆڕاو؟",
    "CANNOT_DELETE_VARIABLE_PROCEDURE": "گۆڕاوەی \"%1\" ناسڕێتەوە چونکە بەشێکە لە پێناسەی فرمانی \"%2\"",
    "DELETE_VARIABLE": "سڕینەوەی \"%1\" گۆڕاو",
    "DELETE_VARIABLE_STRING": "",
    "DELETE_VARIABLE_NUMBER": "",
    "DELETE_VARIABLE_LIST": "",
    "NEW_PROCEDURE": "دروستکردنی بلۆکێک",
    "PROCEDURE_ALREADY_EXISTS": "کارایی ناونراو \"%1\" هەیە.",
    "PROCEDURE_DEFAULT_NAME": "ناوی بلۆک",
    "NEW_LIST": "دروستکردنی لیستێک",
    "NEW_LIST_TITLE": "ناوی نوێی لیست",
    "LIST_MODAL_TITLE": "لیستی نوێ",
    "LIST_ALREADY_EXISTS": "لیستی ناونراو \"%1\" هەیە.",
    "RENAME_LIST_TITLE": "ناونانەوەی هەموو \"%1\" لیستەکان بە:",
    "RENAME_LIST_MODAL_TITLE": "ناونانەوەی لیست",
    "DEFAULT_LIST_ITEM": "شت",
    "DELETE_LIST": "سڕینەوەی \"%1\" لیست",
    "RENAME_LIST": "ناونانەوەی لیست",
    "NEW_BROADCAST_MESSAGE": "پەیامی نوێ",
    "NEW_BROADCAST_MESSAGE_TITLE": "ناوی نامەی نوێی",
    "BROADCAST_MODAL_TITLE": "پەیامی نوێ",
    "DEFAULT_BROADCAST_MESSAGE_NAME": "نامەی1",
    "DELETE_PROCEDURE": "",
    "OK": "",
    "PROMPT": "",
    "STOP": "",
    "NEW_STRING": "",
    "NEW_NUMBER_VARIABLE": "",
    "WIFI": "",
    "USERNAME": "",
    "PWD": "",
    "IOT_SERVICE": "",
    "IOT_ID": "",
    "IOT_PWD": "",
    "IOT_TOPIC": "",
    "MORE_TOPIC": "",
    "OBLOQ_HTTP": "",
    "OBLOQ_IP": "",
    "OBLOQ_PORT": "",
    "ADD_TOPIC": "",
    "DELETE_TOPIC": "",
    "IOT_SERVER": "",
    "CHINA": "",
    "GLOBAL": "",
    "LOW_C": "",
    "LOW_C$": "",
    "LOW_D": "",
    "LOW_D$": "",
    "LOW_E": "",
    "LOW_F": "",
    "LOW_F$": "",
    "LOW_G": "",
    "LOW_G$": "",
    "LOW_A": "",
    "LOW_A$": "",
    "LOW_B": "",
    "MIDDLE_C": "",
    "MIDDLE_C$": "",
    "MIDDLE_D": "",
    "MIDDLE_D$": "",
    "MIDDLE_E": "",
    "MIDDLE_F": "",
    "MIDDLE_F$": "",
    "MIDDLE_G": "",
    "MIDDLE_G$": "",
    "MIDDLE_A": "",
    "MIDDLE_A$": "",
    "MIDDLE_B": "",
    "HIGH_C": "",
    "HIGH_C$": "",
    "HIGH_D": "",
    "HIGH_D$": "",
    "HIGH_E": "",
    "HIGH_F": "",
    "HIGH_F$": "",
    "HIGH_G": "",
    "HIGH_G$": "",
    "HIGH_A": "",
    "HIGH_A$": "",
    "HIGH_B": "",
    "OPERATORS_STRINGTONUMBER": "",
    "INTEGER": "",
    "DECIMAL": "",
    "OPERATORS_NUMTOASCII": "",
    "OPERATORS_STRINGTOASCII": "",
    "OPERATORS_NUMTOSTRING": "",
    "OPERATORS_MAP": "",
    "OPERATORS_CONSTRAIN": "",
    "STEPS_PER_TURN": "",
    "ROTATE_SPEED": "",
    "NEW_AI": "",
    "ASK_AND_PAINT": "",
    "GET_IDENTIFIED_NUM": "",
    "READ_NUM_AND_SAY": "",
    "IMAGE_ADDR": "",
    "IMAGE_PREVIEW": "",
    "IMAGE_OPEN": "",
    "IMAGE_SIZE": "",
    "IMAGE_WIDTH": "",
    "IMAGE_HEIGHT": "",
    "VARIABLE": "",
    "VARIABLE_LIST": "",
    "SET_STRING_VARIABLE": "",
    "STRING_START_WITH": "",
    "DICTIONARY_HEIGHT_FIRST": "",
    "DICTIONARY_HEIGHT_TWO": "",
    "DICTIONARY_HEIGHT_THREE": "",
    "DICTIONARY_HEIGHT_FOUR": "",
    "DATA_MPITEMOFLIST": "",
    "DATA_MPINTERCEPTITEM": "",
    "DATA_MPINSERTATLIST": "",
    "DATA_MPSETVALUEATLIST": "",
    "DATA_MPINSERTATLIST_INSERT": "",
    "DATA_MPINSERTATLIST_CHANGE": "",
    "DATA_MPINSERTATLIST_FOUR": "",
    "DATA_MPINSERTATLIST_FIVE": "",
    "DATA_MPINSERTATLIST_SIX": "",
    "DATA_MPINSERTATLIST_SEVEN": "",
    "DATA_MPINSERTATLIST_EIGHT": "",
    "DATA_MPINSERTATLIST_EIGHT_FALSE": "",
    "DATA_MPINSERTATLIST_EIGHT_TRUE": "",
    "DATA_MPINSERTATLIST_EIGHT_REVERSE": "",
    "OPERATORS_RANDOMA": "",
    "OPERATORS_MODA": "",
    "OPERATORS_ROUNDA": "",
    "OPERATORS_MATHOPA": "",
    "OPERATORS_MATHOP_ABSA": "",
    "OPERATORS_MATHOP_FLOORA": "",
    "OPERATORS_MATHOP_CEILINGA": "",
    "OPERATORS_MATHOP_SQRTA": "",
    "OPERATORS_MATHOP_FIVE": "",
    "OPERATORS_MATHOP_SIX": "",
    "OPERATORS_MATHOP_SEVEN": "",
    "OPERATORS_MATHOP_LENGHT": "",
    "OPERATORS_MATHOP_MAXMIN": "",
    "OPERATORS_MATHOP_MAXMIN_MAX": "",
    "OPERATORS_MATHOP_MAXMIN_MIN": "",
    "OPERATORS_TEXTS_ONE": "",
    "OPERATORS_TEXTS_TWO": "",
    "OPERATORS_TEXTS_THREE": "",
    "OPERATORS_TEXTS_FOUR": "",
    "OPERATORS_TEXTS_FOUR_NEW": "",
    "OPERATORS_TEXTS_NEW": "",
    "OPERATORS_TEXTS_NEW_OP": "",
    "OPERATORS_TEXTS_FOUR_ONE": "",
    "OPERATORS_TEXTS_FOUR_TWO": "",
    "OPERATORS_TEXTS_FOUR_THREE": "",
    "OPERATORS_TEXTS_FOUR_FOUR": "",
    "OPERATORS_TEXTS_FOUR_F": "",
    "OPERATORS_TEXTS_FOUR_L": "",
    "OPERATORS_STRINGTONUMBERA": "",
    "OPERATORS_NUMTOASCIIA": "",
    "OPERATORS_STRINGTOASCIIA": "",
    "OPERATORS_NUMTOSTRINGA": "",
    "NEW_MC": "",
    "RENAME_MCNUMBER_TITLE": "",
    "RENAME_MCNUMBER_MODAL_TITLE": "",
    "RENAME_VARIABLE_MCNUMBER": "",
    "DELETE_VARIABLE_MCNUMBER": "",
    "SET_MC_VARIABLE": "",
    "TUPLE_ONE": "",
    "TUPLE_TWO": "",
    "ALIYUN": "",
    "ONENET": "",
    "EASYIOT": "",
    "SHANGHAI": "",
    "QINGDAO": "",
    "BEIJING": "",
    "ZHANGJIAKOU": "",
    "HUHEHAOTE": "",
    "HANGZHOU": "",
    "SHENZHEN": "",
    "HONGKONG": "",
    "SINGAPORE": "",
    "SYDNEY": "",
    "KUALALUMPUR": "",
    "JAKARTA": "",
    "MUMBAI": "",
    "TOKYO": "",
    "SILICONVALLEY": "",
    "VIRGINIA": "",
    "FRANKFURT": "",
    "LONDON": "",
    "DUBAI": "",
    "IOT_PLATFORM": "",
    "PARAMS": "",
    "SERVER_ATTR": "",
    "PRODUCTID": "",
    "DEVICEID": "",
    "TINYDB_SECRET": "",
    "TINYDB_USER": "",
    "APIADDR": "",
    "SOFTWARE_SERIAL": "",
    "HARDWARE_SERIAL": "",
    "HARDWARE_SERIAL1": "",
    "HARDWARE_SERIAL2": "",
    "HARDWARE_SERIAL3": ""
  },
  "lv": {
    "CONTROL_FOREVER": "nepārtraukti",
    "CONTROL_REPEAT": "atkārtot %1",
    "CONTROL_IF": "ja %1 tad",
    "CONTROL_ELSE": "citādi",
    "CONTROL_STOP": "apturēt",
    "CONTROL_STOP_ALL": "viss",
    "CONTROL_STOP_THIS": "šis skripts",
    "CONTROL_STOP_OTHER": "citi skripti gariņā",
    "CONTROL_WAIT": "gaidīt %1 sekundes",
    "CONTROL_WAITUNTIL": "gaidīt līdz %1",
    "CONTROL_REPEATUNTIL": "atkārtot līdz %1",
    "CONTROL_WHILE": "kamēr %1",
    "CONTROL_FOREACH": "katram %1 %2",
    "CONTROL_STARTASCLONE": "kad es parādos kā dublikāts",
    "CONTROL_CREATECLONEOF": "izveidot %1 dublikātu",
    "CONTROL_CREATECLONEOF_MYSELF": "savu",
    "CONTROL_DELETETHISCLONE": "dzēst šo dublikātu",
    "CONTROL_COUNTER": "skaitītājs",
    "CONTROL_INCRCOUNTER": "pieauguma skaitītājs",
    "CONTROL_CLEARCOUNTER": "nodzēst skaitītāju",
    "CONTROL_ALLATONCE": "viss vienlaikus",
    "DATA_SETVARIABLETO": "iestatīt %1 uz %2",
    "DATA_CHANGEVARIABLEBY": "mainīt %1 par %2",
    "DATA_SHOWVARIABLE": "rādīt mainīgo %1",
    "DATA_HIDEVARIABLE": "slēpt mainīgo %1",
    "DATA_ADDTOLIST": "pievienot %1 pie %2",
    "DATA_DELETEOFLIST": "dzēst %1 no %2",
    "DATA_DELETEALLOFLIST": "dzēst visu no %1",
    "DATA_INSERTATLIST": "ievietot %1 kā %2 sarakstā %3",
    "DATA_REPLACEITEMOFLIST": "aizvietot %1 vienumu sarakstā %2 ar %3",
    "DATA_ITEMOFLIST": "vienums %1 sarakstā %2",
    "DATA_ITEMNUMOFLIST": "vienuma %1 # sarakstā %2",
    "DATA_LENGTHOFLIST": "%1 garums",
    "DATA_LISTCONTAINSITEM": "%1 satur %2?",
    "DATA_SHOWLIST": "rādīt sarakstu %1",
    "DATA_HIDELIST": "slēpt sarakstu %1",
    "DATA_INDEX_ALL": "viss",
    "DATA_INDEX_LAST": "pēdējais",
    "DATA_INDEX_RANDOM": "nejaušs",
    "EVENT_WHENFLAGCLICKED": "kad %1 nospiests",
    "EVENT_WHENTHISSPRITECLICKED": "kad noklikšķināts šis gariņš",
    "EVENT_WHENSTAGECLICKED": "kad noklikšķināta skatuve",
    "EVENT_WHENTOUCHINGOBJECT": "kad šis gariņš pieskaras %1",
    "EVENT_WHENBROADCASTRECEIVED": "kad es saņemu %1",
    "EVENT_WHENBACKDROPSWITCHESTO": "kad fons nomainās uz %1",
    "EVENT_WHENGREATERTHAN": "kad %1 > %2",
    "EVENT_WHENGREATERTHAN_TIMER": "taimeris",
    "EVENT_WHENGREATERTHAN_LOUDNESS": "skaļums",
    "EVENT_BROADCAST": "pārraidīt %1",
    "EVENT_BROADCASTANDWAIT": "pārraidīt %1 un gaidīt",
    "EVENT_WHENKEYPRESSED": "kad nospiests taustiņš %1",
    "EVENT_WHENKEYPRESSED_SPACE": "atstarpe",
    "EVENT_WHENKEYPRESSED_LEFT": "kreisā bulta",
    "EVENT_WHENKEYPRESSED_RIGHT": "labā bulta",
    "EVENT_WHENKEYPRESSED_DOWN": "apakšējā bulta",
    "EVENT_WHENKEYPRESSED_UP": "augšējā bulta",
    "EVENT_WHENKEYPRESSED_ANY": "jebkāds",
    "LOOKS_SAYFORSECS": "teikt %1 %2 sekundes",
    "LOOKS_SAY": "teikt %1",
    "LOOKS_HELLO": "Sveiki!",
    "LOOKS_THINKFORSECS": "domāt %1 %2 sekundes",
    "LOOKS_THINK": "domāt %1",
    "LOOKS_HMM": "Hmm...",
    "LOOKS_SHOW": "rādīt",
    "LOOKS_HIDE": "slēpt",
    "LOOKS_HIDEALLSPRITES": "slēpt visus gariņus",
    "LOOKS_EFFECT_COLOR": "krāsa",
    "LOOKS_EFFECT_FISHEYE": "zivsacs",
    "LOOKS_EFFECT_WHIRL": "virpuļi",
    "LOOKS_EFFECT_PIXELATE": "pikseļi",
    "LOOKS_EFFECT_MOSAIC": "mozaīka",
    "LOOKS_EFFECT_BRIGHTNESS": "gaišums",
    "LOOKS_EFFECT_GHOST": "spoks",
    "LOOKS_CHANGEEFFECTBY": "mainīt efektu %1 par %2",
    "LOOKS_SETEFFECTTO": "iestatīt efektu %1 par %2",
    "LOOKS_CLEARGRAPHICEFFECTS": "notīrīt grafiskos efektus",
    "LOOKS_CHANGESIZEBY": "mainīt izmēru par %1",
    "LOOKS_SETSIZETO": "iestatīt izmēru uz %1 %",
    "LOOKS_SIZE": "izmērs",
    "LOOKS_CHANGESTRETCHBY": "manīt saspīlējumu par %1",
    "LOOKS_SETSTRETCHTO": "iestatīt saspīlējumu uz %1",
    "LOOKS_SWITCHCOSTUMETO": "mainīt tērpu uz %1",
    "LOOKS_NEXTCOSTUME": "nākamais tērps",
    "LOOKS_SWITCHBACKDROPTO": "mainīt fonu uz %1",
    "LOOKS_GOTOFRONTBACK": "iet uz %1 slāni",
    "LOOKS_GOTOFRONTBACK_FRONT": "priekšējo",
    "LOOKS_GOTOFRONTBACK_BACK": "aizmugurējo",
    "LOOKS_GOFORWARDBACKWARDLAYERS": "iet %1 %2 slāņus",
    "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "uz priekšu",
    "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "atpakaļ",
    "LOOKS_BACKDROPNUMBERNAME": "fona %1",
    "LOOKS_COSTUMENUMBERNAME": "tērpa %1",
    "LOOKS_NUMBERNAME_NUMBER": "numurs",
    "LOOKS_NUMBERNAME_NAME": "vārds",
    "LOOKS_SWITCHBACKDROPTOANDWAIT": "mainīt fonu uz %1 un gaidīt",
    "LOOKS_NEXTBACKDROP_BLOCK": "nākamais fons",
    "LOOKS_NEXTBACKDROP": "nākamais fons",
    "LOOKS_PREVIOUSBACKDROP": "iepriekšējais fons",
    "LOOKS_RANDOMBACKDROP": "nejaušs fons",
    "MOTION_MOVESTEPS": "pārvietoties %1 soļus",
    "MOTION_TURNLEFT": "pagriezties %1 par %2 grādiem",
    "MOTION_TURNRIGHT": "pagriezties %1 par %2 grādiem",
    "MOTION_POINTINDIRECTION": "pagriezt virzienā %1",
    "MOTION_POINTTOWARDS": "pagriezt uz %1",
    "MOTION_POINTTOWARDS_POINTER": "peles kursors",
    "MOTION_POINTTOWARDS_RANDOM": "nejaušs virziens",
    "MOTION_GOTO": "iet uz %1",
    "MOTION_GOTO_POINTER": "peles kursors",
    "MOTION_GOTO_RANDOM": "nejauša pozīcija",
    "MOTION_GOTOXY": "iet uz x: %1 y: %2",
    "MOTION_GLIDESECSTOXY": "slīdēt %1 sekundes uz x: %2 y: %3",
    "MOTION_GLIDETO": "slīdēt %1 sekundes uz %2",
    "MOTION_GLIDETO_POINTER": "peles kursors",
    "MOTION_GLIDETO_RANDOM": "nejauša pozīcija",
    "MOTION_CHANGEXBY": "mainīt x par %1",
    "MOTION_SETX": "iestatīt x uz %1",
    "MOTION_CHANGEYBY": "mainīt y par %1",
    "MOTION_SETY": "iestatīt y uz %1",
    "MOTION_IFONEDGEBOUNCE": "ja uz malas, atlēkt",
    "MOTION_SETROTATIONSTYLE": "iestatīt griešanās stilu uz %1",
    "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "pa kreisi - pa labi",
    "MOTION_SETROTATIONSTYLE_DONTROTATE": "negriezties",
    "MOTION_SETROTATIONSTYLE_ALLAROUND": "jebkurā virzienā",
    "MOTION_XPOSITION": "x pozīcija",
    "MOTION_YPOSITION": "y pozīcija",
    "MOTION_DIRECTION": "virziens",
    "MOTION_SCROLLRIGHT": "ritināt pa labi %1",
    "MOTION_SCROLLUP": "ritināt uz augšu %1",
    "MOTION_ALIGNSCENE": "izlīdzināt ainu %1",
    "MOTION_ALIGNSCENE_BOTTOMLEFT": "apakšā-pa kreisi",
    "MOTION_ALIGNSCENE_BOTTOMRIGHT": "apakšā-pa labi",
    "MOTION_ALIGNSCENE_MIDDLE": " vidū",
    "MOTION_ALIGNSCENE_TOPLEFT": "augšā-pa kreisi",
    "MOTION_ALIGNSCENE_TOPRIGHT": "augšā-pa labi",
    "MOTION_XSCROLL": "x ritināšana",
    "MOTION_YSCROLL": "y ritināšana",
    "MOTION_STAGE_SELECTED": "Izvēlēta skatuve: nav kustības bloku",
    "OPERATORS_ADD": "%1 + %2",
    "OPERATORS_SUBTRACT": "%1 - %2",
    "OPERATORS_MULTIPLY": "%1 * %2",
    "OPERATORS_DIVIDE": "%1 / %2",
    "OPERATORS_RANDOM": "izvēlēties nejauši no %1 līdz %2",
    "OPERATORS_GT": "%1 > %2",
    "OPERATORS_GT_EQUALS": "",
    "OPERATORS_LT": "%1 < %2",
    "OPERATORS_LT_EQUALS": "",
    "OPERATORS_EQUALS": "%1 = %2",
    "OPERATORS_AND": "%1 un %2",
    "OPERATORS_OR": "%1 vai %2",
    "OPERATORS_NOT": "nav  %1",
    "OPERATORS_JOIN": "apvienot %1 %2",
    "OPERATORS_JOIN_APPLE": "ābols",
    "OPERATORS_JOIN_BANANA": "banāns",
    "OPERATORS_LETTEROF": "burts %1 no %2",
    "OPERATORS_LETTEROF_APPLE": "ā",
    "OPERATORS_LENGTH": "%1 garums",
    "OPERATORS_CONTAINS": "%1 satur %2?",
    "OPERATORS_MOD": "%1 atlikums dalot ar %2",
    "OPERATORS_ROUND": "noapaļot %1",
    "OPERATORS_MATHOP": "%1 no %2",
    "OPERATORS_MATHOP_ABS": "modulis (absolūtā vērtība)",
    "OPERATORS_MATHOP_FLOOR": "apaļot uz leju",
    "OPERATORS_MATHOP_CEILING": "apaļot uz augšu",
    "OPERATORS_MATHOP_SQRT": "kvadrātsakne",
    "OPERATORS_MATHOP_SIN": "sin",
    "OPERATORS_MATHOP_COS": "cos",
    "OPERATORS_MATHOP_TAN": "tan",
    "OPERATORS_MATHOP_ASIN": "asin",
    "OPERATORS_MATHOP_ACOS": "acos",
    "OPERATORS_MATHOP_ATAN": "atan",
    "OPERATORS_MATHOP_LN": "In",
    "OPERATORS_MATHOP_LOG": "log",
    "OPERATORS_MATHOP_EEXP": "e ^",
    "OPERATORS_MATHOP_10EXP": "10 ^",
    "PROCEDURES_DEFINITION": "definēt %1",
    "SENSING_TOUCHINGOBJECT": "pieskaras %1?",
    "SENSING_TOUCHINGOBJECT_POINTER": "peles kursors",
    "SENSING_TOUCHINGOBJECT_EDGE": "mala",
    "SENSING_TOUCHINGCOLOR": "pieskaras krāsai %1?",
    "SENSING_COLORISTOUCHINGCOLOR": "krāsa %1 pieskaras %2?",
    "SENSING_DISTANCETO": "attālums līdz %1",
    "SENSING_DISTANCETO_POINTER": "peles kursors",
    "SENSING_ASKANDWAIT": "jautāt %1 un gaidīt ",
    "SENSING_ASK_TEXT": "Kā tevi sauc?",
    "SENSING_ANSWER": "atbilde",
    "SENSING_KEYPRESSED": "nospiests taustiņš %1?",
    "SENSING_MOUSEDOWN": "peles taustiņš nospiests?",
    "SENSING_MOUSEX": "peles x",
    "SENSING_MOUSEY": "peles y",
    "SENSING_SETDRAGMODE": "iestatīt vilkšanas režīmu uz %1",
    "SENSING_SETDRAGMODE_DRAGGABLE": "velkams",
    "SENSING_SETDRAGMODE_NOTDRAGGABLE": "nav velkams",
    "SENSING_LOUDNESS": "skaļums",
    "SENSING_LOUD": "skaļš?",
    "SENSING_TIMER": "taimeris",
    "SENSING_RESETTIMER": "atiestatīt taimeri",
    "SENSING_OF": "%1 no %2",
    "SENSING_OF_XPOSITION": "x pozīcija",
    "SENSING_OF_YPOSITION": "y pozīcija",
    "SENSING_OF_DIRECTION": "virziens",
    "SENSING_OF_COSTUMENUMBER": "tērpa #",
    "SENSING_OF_COSTUMENAME": "tērpa nosaukums",
    "SENSING_OF_SIZE": "izmērs",
    "SENSING_OF_VOLUME": "skaļums",
    "SENSING_OF_BACKDROPNUMBER": "fona #",
    "SENSING_OF_BACKDROPNAME": "fona nosaukums",
    "SENSING_OF_STAGE": "Skatuve",
    "SENSING_CURRENT": "pašreizējais %1",
    "SENSING_CURRENT_YEAR": "gads",
    "SENSING_CURRENT_MONTH": "mēnesis",
    "SENSING_CURRENT_DATE": "datums",
    "SENSING_CURRENT_DAYOFWEEK": "nedēļas diena",
    "SENSING_CURRENT_HOUR": "stunda",
    "SENSING_CURRENT_MINUTE": "minūte",
    "SENSING_CURRENT_SECOND": "sekunde",
    "SENSING_DAYSSINCE2000": "dienas kopš 2000",
    "SENSING_USERNAME": "lietotājvārds",
    "SENSING_USERID": "lietotāja id",
    "SOUND_PLAY": "atskaņot skaņu %1",
    "SOUND_PLAYUNTILDONE": "atskaņot skaņu %1 līdz galam",
    "SOUND_STOPALLSOUNDS": "apturēt visas skaņas",
    "SOUND_SETEFFECTO": "iestatīt efektu %1 uz %2",
    "SOUND_CHANGEEFFECTBY": "mainīt efektu %1 par %2",
    "SOUND_CLEAREFFECTS": "notīrīt skaņas efektus",
    "SOUND_EFFECTS_PITCH": "augstums",
    "SOUND_EFFECTS_PAN": "bīdīt pa labi/pa kreisi",
    "SOUND_CHANGEVOLUMEBY": "mainīt skaļumu par %1",
    "SOUND_SETVOLUMETO": "iestatīt skaļumu uz %1%",
    "SOUND_VOLUME": "skaļums",
    "SOUND_RECORD": "ierakstīt...",
    "CATEGORY_MOTION": "Kustība",
    "CATEGORY_LOOKS": "Izskats",
    "CATEGORY_SOUND": "Skaņa",
    "CATEGORY_EVENTS": "Notikumi",
    "CATEGORY_CONTROL": "Vadība",
    "CATEGORY_SENSING": "Uztveršana",
    "CATEGORY_OPERATORS": "Operatori",
    "CATEGORY_VARIABLES": "Mainīgie",
    "CATEGORY_MYBLOCKS": "Mani bloki",
    "CATEGORY_LISTS": "",
    "CATEGORY_TUPLE": "",
    "CATEGORY_DICTIONARYS": "",
    "CATEGORY_NUMBERS": "",
    "CATEGORY_TEXT": "",
    "DUPLICATE": "Dublēt",
    "DELETE": "Dzēst",
    "ADD_COMMENT": "Pievienot komentāru",
    "REMOVE_COMMENT": "Noņemt komentāru",
    "DELETE_BLOCK": "Dzēst bloku",
    "DELETE_X_BLOCKS": "Dzēst %1 blokus",
    "DELETE_ALL_BLOCKS": "Dzēst visus %1 blokus?",
    "CLEAN_UP": "Satīrīt blokus",
    "HELP": "Palīdzība",
    "UNDO": "Atsaukt",
    "REDO": "Atcelt atsaukšanu",
    "EDIT_PROCEDURE": "Rediģēt",
    "SHOW_PROCEDURE_DEFINITION": "Iet uz definīciju",
    "WORKSPACE_COMMENT_DEFAULT_TEXT": "Saki kaut ko...",
    "COLOUR_HUE_LABEL": "Krāsa",
    "COLOUR_SATURATION_LABEL": "Piesātinājums",
    "COLOUR_BRIGHTNESS_LABEL": "Gaišums",
    "CHANGE_VALUE_TITLE": "Mainīt vērtību:",
    "RENAME_VARIABLE": "Pārdēvēt mainīgo",
    "RENAME_VARIABLE_STRING": "",
    "RENAME_VARIABLE_NUMBER": "",
    "RENAME_VARIABLE_LIST": "",
    "RENAME_VARIABLE_TITLE": "Pārdēvēt visus \"%1\" mainīgos uz:",
    "RENAME_VARIABLE_MODAL_TITLE": "Pārdēvēt Mainīgo",
    "NEW_VARIABLE": "Izveidot Mainīgo",
    "NEW_VARIABLE_TITLE": "Jauns mainīgā nosaukums:",
    "VARIABLE_MODAL_TITLE": "Jauns Mainīgais",
    "VARIABLE_ALREADY_EXISTS": "Mainīgais \"%1\" jau pastāv.",
    "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "Mainīgais \"%1\" jau pastāv citam mainīgajam ar tipu ''%2''.",
    "DELETE_VARIABLE_CONFIRMATION": "Dzēst %1 mainīgā %2 lietojumus?",
    "CANNOT_DELETE_VARIABLE_PROCEDURE": "Nevar dzēst mainīgo \"%1\", jo tas ir daļa no funkcijas \"%2\" definīcijas",
    "DELETE_VARIABLE": "Dzēst ''%1'' mainīgo",
    "DELETE_VARIABLE_STRING": "",
    "DELETE_VARIABLE_NUMBER": "",
    "DELETE_VARIABLE_LIST": "",
    "NEW_PROCEDURE": "Izveidot bloku",
    "PROCEDURE_ALREADY_EXISTS": "Procedūra ar nosaukumu \"%1\" jau pastāv.",
    "PROCEDURE_DEFAULT_NAME": "bloka nosaukums",
    "NEW_LIST": "Izveidot sarakstu",
    "NEW_LIST_TITLE": "Jauns saraksta nosaukums:",
    "LIST_MODAL_TITLE": "Jauns saraksts",
    "LIST_ALREADY_EXISTS": "Saraksts ar nosaukumu \"%1\" jau pastāv.",
    "RENAME_LIST_TITLE": "Pārdēvēt visus \"%1\" sarakstus uz:",
    "RENAME_LIST_MODAL_TITLE": "Pārdēvēt sarakstu",
    "DEFAULT_LIST_ITEM": "lieta",
    "DELETE_LIST": "Dzēst \"%1\" sarakstu",
    "RENAME_LIST": "Pārdēvēt sarakstu",
    "NEW_BROADCAST_MESSAGE": "Jauna ziņa",
    "NEW_BROADCAST_MESSAGE_TITLE": "Jauns ziņas nosaukums:",
    "BROADCAST_MODAL_TITLE": "Jauna ziņa",
    "DEFAULT_BROADCAST_MESSAGE_NAME": "ziņa1",
    "DELETE_PROCEDURE": "",
    "OK": "",
    "PROMPT": "",
    "STOP": "",
    "NEW_STRING": "",
    "NEW_NUMBER_VARIABLE": "",
    "WIFI": "",
    "USERNAME": "",
    "PWD": "",
    "IOT_SERVICE": "",
    "IOT_ID": "",
    "IOT_PWD": "",
    "IOT_TOPIC": "",
    "MORE_TOPIC": "",
    "OBLOQ_HTTP": "",
    "OBLOQ_IP": "",
    "OBLOQ_PORT": "",
    "ADD_TOPIC": "",
    "DELETE_TOPIC": "",
    "IOT_SERVER": "",
    "CHINA": "",
    "GLOBAL": "",
    "LOW_C": "",
    "LOW_C$": "",
    "LOW_D": "",
    "LOW_D$": "",
    "LOW_E": "",
    "LOW_F": "",
    "LOW_F$": "",
    "LOW_G": "",
    "LOW_G$": "",
    "LOW_A": "",
    "LOW_A$": "",
    "LOW_B": "",
    "MIDDLE_C": "",
    "MIDDLE_C$": "",
    "MIDDLE_D": "",
    "MIDDLE_D$": "",
    "MIDDLE_E": "",
    "MIDDLE_F": "",
    "MIDDLE_F$": "",
    "MIDDLE_G": "",
    "MIDDLE_G$": "",
    "MIDDLE_A": "",
    "MIDDLE_A$": "",
    "MIDDLE_B": "",
    "HIGH_C": "",
    "HIGH_C$": "",
    "HIGH_D": "",
    "HIGH_D$": "",
    "HIGH_E": "",
    "HIGH_F": "",
    "HIGH_F$": "",
    "HIGH_G": "",
    "HIGH_G$": "",
    "HIGH_A": "",
    "HIGH_A$": "",
    "HIGH_B": "",
    "OPERATORS_STRINGTONUMBER": "",
    "INTEGER": "",
    "DECIMAL": "",
    "OPERATORS_NUMTOASCII": "",
    "OPERATORS_STRINGTOASCII": "",
    "OPERATORS_NUMTOSTRING": "",
    "OPERATORS_MAP": "",
    "OPERATORS_CONSTRAIN": "",
    "STEPS_PER_TURN": "",
    "ROTATE_SPEED": "",
    "NEW_AI": "",
    "ASK_AND_PAINT": "",
    "GET_IDENTIFIED_NUM": "",
    "READ_NUM_AND_SAY": "",
    "IMAGE_ADDR": "",
    "IMAGE_PREVIEW": "",
    "IMAGE_OPEN": "",
    "IMAGE_SIZE": "",
    "IMAGE_WIDTH": "",
    "IMAGE_HEIGHT": "",
    "VARIABLE": "",
    "VARIABLE_LIST": "",
    "SET_STRING_VARIABLE": "",
    "STRING_START_WITH": "",
    "DICTIONARY_HEIGHT_FIRST": "",
    "DICTIONARY_HEIGHT_TWO": "",
    "DICTIONARY_HEIGHT_THREE": "",
    "DICTIONARY_HEIGHT_FOUR": "",
    "DATA_MPITEMOFLIST": "",
    "DATA_MPINTERCEPTITEM": "",
    "DATA_MPINSERTATLIST": "",
    "DATA_MPSETVALUEATLIST": "",
    "DATA_MPINSERTATLIST_INSERT": "",
    "DATA_MPINSERTATLIST_CHANGE": "",
    "DATA_MPINSERTATLIST_FOUR": "",
    "DATA_MPINSERTATLIST_FIVE": "",
    "DATA_MPINSERTATLIST_SIX": "",
    "DATA_MPINSERTATLIST_SEVEN": "",
    "DATA_MPINSERTATLIST_EIGHT": "",
    "DATA_MPINSERTATLIST_EIGHT_FALSE": "",
    "DATA_MPINSERTATLIST_EIGHT_TRUE": "",
    "DATA_MPINSERTATLIST_EIGHT_REVERSE": "",
    "OPERATORS_RANDOMA": "",
    "OPERATORS_MODA": "",
    "OPERATORS_ROUNDA": "",
    "OPERATORS_MATHOPA": "",
    "OPERATORS_MATHOP_ABSA": "",
    "OPERATORS_MATHOP_FLOORA": "",
    "OPERATORS_MATHOP_CEILINGA": "",
    "OPERATORS_MATHOP_SQRTA": "",
    "OPERATORS_MATHOP_FIVE": "",
    "OPERATORS_MATHOP_SIX": "",
    "OPERATORS_MATHOP_SEVEN": "",
    "OPERATORS_MATHOP_LENGHT": "",
    "OPERATORS_MATHOP_MAXMIN": "",
    "OPERATORS_MATHOP_MAXMIN_MAX": "",
    "OPERATORS_MATHOP_MAXMIN_MIN": "",
    "OPERATORS_TEXTS_ONE": "",
    "OPERATORS_TEXTS_TWO": "",
    "OPERATORS_TEXTS_THREE": "",
    "OPERATORS_TEXTS_FOUR": "",
    "OPERATORS_TEXTS_FOUR_NEW": "",
    "OPERATORS_TEXTS_NEW": "",
    "OPERATORS_TEXTS_NEW_OP": "",
    "OPERATORS_TEXTS_FOUR_ONE": "",
    "OPERATORS_TEXTS_FOUR_TWO": "",
    "OPERATORS_TEXTS_FOUR_THREE": "",
    "OPERATORS_TEXTS_FOUR_FOUR": "",
    "OPERATORS_TEXTS_FOUR_F": "",
    "OPERATORS_TEXTS_FOUR_L": "",
    "OPERATORS_STRINGTONUMBERA": "",
    "OPERATORS_NUMTOASCIIA": "",
    "OPERATORS_STRINGTOASCIIA": "",
    "OPERATORS_NUMTOSTRINGA": "",
    "NEW_MC": "",
    "RENAME_MCNUMBER_TITLE": "",
    "RENAME_MCNUMBER_MODAL_TITLE": "",
    "RENAME_VARIABLE_MCNUMBER": "",
    "DELETE_VARIABLE_MCNUMBER": "",
    "SET_MC_VARIABLE": "",
    "TUPLE_ONE": "",
    "TUPLE_TWO": "",
    "ALIYUN": "",
    "ONENET": "",
    "EASYIOT": "",
    "SHANGHAI": "",
    "QINGDAO": "",
    "BEIJING": "",
    "ZHANGJIAKOU": "",
    "HUHEHAOTE": "",
    "HANGZHOU": "",
    "SHENZHEN": "",
    "HONGKONG": "",
    "SINGAPORE": "",
    "SYDNEY": "",
    "KUALALUMPUR": "",
    "JAKARTA": "",
    "MUMBAI": "",
    "TOKYO": "",
    "SILICONVALLEY": "",
    "VIRGINIA": "",
    "FRANKFURT": "",
    "LONDON": "",
    "DUBAI": "",
    "IOT_PLATFORM": "",
    "PARAMS": "",
    "SERVER_ATTR": "",
    "PRODUCTID": "",
    "DEVICEID": "",
    "TINYDB_SECRET": "",
    "TINYDB_USER": "",
    "APIADDR": "",
    "SOFTWARE_SERIAL": "",
    "HARDWARE_SERIAL": "",
    "HARDWARE_SERIAL1": "",
    "HARDWARE_SERIAL2": "",
    "HARDWARE_SERIAL3": ""
  },
  "lt": {
    "CONTROL_FOREVER": "kartoti be galo",
    "CONTROL_REPEAT": "kartoti %1 kartus",
    "CONTROL_IF": "jeigu %1, tuomet",
    "CONTROL_ELSE": "kitaip",
    "CONTROL_STOP": "sustabdyti",
    "CONTROL_STOP_ALL": "visiškai",
    "CONTROL_STOP_THIS": "šį scenarijų",
    "CONTROL_STOP_OTHER": "kitus veikėjo scenarijus",
    "CONTROL_WAIT": "laukti %1 sekundes",
    "CONTROL_WAITUNTIL": "laukti kol %1",
    "CONTROL_REPEATUNTIL": "kartoti kol %1",
    "CONTROL_WHILE": "kol %1",
    "CONTROL_FOREACH": "už kiekvieną %1 iš %2",
    "CONTROL_STARTASCLONE": "kai pradedu veikti kaip klonas",
    "CONTROL_CREATECLONEOF": "sukurti kloną iš %1",
    "CONTROL_CREATECLONEOF_MYSELF": "savęs",
    "CONTROL_DELETETHISCLONE": "ištrinti šį kloną",
    "CONTROL_COUNTER": " skaitiklis",
    "CONTROL_INCRCOUNTER": "pridėti vieną prie skaitiklio",
    "CONTROL_CLEARCOUNTER": "nunulinti skaitiklį",
    "CONTROL_ALLATONCE": "viskas vienu metu",
    "DATA_SETVARIABLETO": "nustatyti %1 reikšmę į %2",
    "DATA_CHANGEVARIABLEBY": "keisti %1 per %2",
    "DATA_SHOWVARIABLE": "rodyti kintamąjį %1",
    "DATA_HIDEVARIABLE": "slėpti kintamąjį %1",
    "DATA_ADDTOLIST": "pridėti %1 į %2",
    "DATA_DELETEOFLIST": "ištrinti %1 iš %2",
    "DATA_DELETEALLOFLIST": "ištrinti visus iš %1",
    "DATA_INSERTATLIST": "įterpti %1 į vietą %2 sąraše %3",
    "DATA_REPLACEITEMOFLIST": "pakeisti elementą %1 iš sąrašo %2 į %3",
    "DATA_ITEMOFLIST": "elementas %1 iš %2",
    "DATA_ITEMNUMOFLIST": "elementas # iš %1 sąraše %2",
    "DATA_LENGTHOFLIST": "%1 sąrašo ilgis",
    "DATA_LISTCONTAINSITEM": "ar %1 turi %2?",
    "DATA_SHOWLIST": "rodyti sąrašą %1",
    "DATA_HIDELIST": "slėpti sąrašą %1",
    "DATA_INDEX_ALL": "viskas",
    "DATA_INDEX_LAST": "paskutinis",
    "DATA_INDEX_RANDOM": "atsitiktinis",
    "EVENT_WHENFLAGCLICKED": "kai spusteli %1",
    "EVENT_WHENTHISSPRITECLICKED": "kai spusteli ant šio veikėjo",
    "EVENT_WHENSTAGECLICKED": "kai paspaudi scenoje",
    "EVENT_WHENTOUCHINGOBJECT": "kai šis veikėjas palies %1",
    "EVENT_WHENBROADCASTRECEIVED": "kai gaunu žinutę %1",
    "EVENT_WHENBACKDROPSWITCHESTO": "kai fonas perjungiamas į %1",
    "EVENT_WHENGREATERTHAN": "kai %1 > %2",
    "EVENT_WHENGREATERTHAN_TIMER": "laikmatis",
    "EVENT_WHENGREATERTHAN_LOUDNESS": "garsumas",
    "EVENT_BROADCAST": "transliuoti %1",
    "EVENT_BROADCASTANDWAIT": "transliuoti %1 ir palaukti",
    "EVENT_WHENKEYPRESSED": "kai paspaudi %1 klavišas",
    "EVENT_WHENKEYPRESSED_SPACE": "tarpas",
    "EVENT_WHENKEYPRESSED_LEFT": "rodyklė kairėn",
    "EVENT_WHENKEYPRESSED_RIGHT": "rodyklė dešinėn",
    "EVENT_WHENKEYPRESSED_DOWN": "rodyklė žemyn",
    "EVENT_WHENKEYPRESSED_UP": "rodyklė aukštyn",
    "EVENT_WHENKEYPRESSED_ANY": "bet kuris",
    "LOOKS_SAYFORSECS": "sakyk %1 %2 sekundes",
    "LOOKS_SAY": "sakyk %1",
    "LOOKS_HELLO": "Sveiki!",
    "LOOKS_THINKFORSECS": "mąstyti %1 tiek sekundžių: %2",
    "LOOKS_THINK": "mąstyti %1",
    "LOOKS_HMM": "Hmm...",
    "LOOKS_SHOW": "rodyk",
    "LOOKS_HIDE": "slėpk",
    "LOOKS_HIDEALLSPRITES": "paslėpti visus veikėjus",
    "LOOKS_EFFECT_COLOR": "spalva",
    "LOOKS_EFFECT_FISHEYE": "lęšis",
    "LOOKS_EFFECT_WHIRL": "susukimas",
    "LOOKS_EFFECT_PIXELATE": "stambinti taškus",
    "LOOKS_EFFECT_MOSAIC": "mozaika",
    "LOOKS_EFFECT_BRIGHTNESS": "šviesumas",
    "LOOKS_EFFECT_GHOST": "permatomumas",
    "LOOKS_CHANGEEFFECTBY": "change %1 effect by %2",
    "LOOKS_SETEFFECTTO": "set %1 effect to %2",
    "LOOKS_CLEARGRAPHICEFFECTS": "clear graphic effects",
    "LOOKS_CHANGESIZEBY": "keisti dydį per %1 ",
    "LOOKS_SETSIZETO": "nustatyti dydį į %1 %",
    "LOOKS_SIZE": "dydis",
    "LOOKS_CHANGESTRETCHBY": "change stretch by %1",
    "LOOKS_SETSTRETCHTO": "set stretch to %1 %",
    "LOOKS_SWITCHCOSTUMETO": "keisk kaukę į %1",
    "LOOKS_NEXTCOSTUME": "kita kaukė",
    "LOOKS_SWITCHBACKDROPTO": "keisk foną į %1",
    "LOOKS_GOTOFRONTBACK": "perkelti į %1 sluoksnį",
    "LOOKS_GOTOFRONTBACK_FRONT": "žemiausią",
    "LOOKS_GOTOFRONTBACK_BACK": "žemiausią",
    "LOOKS_GOFORWARDBACKWARDLAYERS": "perkelti per %2 sluoksnius %1",
    "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "aukštyn",
    "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "žemyn",
    "LOOKS_BACKDROPNUMBERNAME": "fonas %1",
    "LOOKS_COSTUMENUMBERNAME": "kaukė %1",
    "LOOKS_NUMBERNAME_NUMBER": "numeris",
    "LOOKS_NUMBERNAME_NAME": "pavadinimas",
    "LOOKS_SWITCHBACKDROPTOANDWAIT": "switch backdrop to %1 and wait",
    "LOOKS_NEXTBACKDROP_BLOCK": "kitas  fonas",
    "LOOKS_NEXTBACKDROP": "kitas  fonas",
    "LOOKS_PREVIOUSBACKDROP": "ankstesnis fonas",
    "LOOKS_RANDOMBACKDROP": "atsitiktinis fonas",
    "MOTION_MOVESTEPS": "eik %1 žingsnius",
    "MOTION_TURNLEFT": "pasisuk %1 %2 laipsnių",
    "MOTION_TURNRIGHT": "pasisuk %1 %2 laipsnių",
    "MOTION_POINTINDIRECTION": "žiūrėk %1 laipsnių kryptimi",
    "MOTION_POINTTOWARDS": "žiūrėk į %1",
    "MOTION_POINTTOWARDS_POINTER": "pelės žymeklį",
    "MOTION_POINTTOWARDS_RANDOM": "atsitiktinė kryptis",
    "MOTION_GOTO": "eik į %1",
    "MOTION_GOTO_POINTER": "prie pelės žymeklio",
    "MOTION_GOTO_RANDOM": "į atsitiktinę poziciją",
    "MOTION_GOTOXY": "eik į tašką x: %1 y: %2",
    "MOTION_GLIDESECSTOXY": "slink link taško x: %2 y: %3 per %1 sekundes",
    "MOTION_GLIDETO": "slink link %2 per %1 sekundes",
    "MOTION_GLIDETO_POINTER": "pelės žymeklio",
    "MOTION_GLIDETO_RANDOM": "atsitiktinės pozicijos",
    "MOTION_CHANGEXBY": "prie x pridėk %1",
    "MOTION_SETX": "nustatyk x į %1",
    "MOTION_CHANGEYBY": "prie y pridėk %1",
    "MOTION_SETY": "nustatyk y į %1",
    "MOTION_IFONEDGEBOUNCE": "jei prie krašto, atšok",
    "MOTION_SETROTATIONSTYLE": "set rotation style %1",
    "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "kairėn-dešinėn",
    "MOTION_SETROTATIONSTYLE_DONTROTATE": "nesisukti",
    "MOTION_SETROTATIONSTYLE_ALLAROUND": "aplinkui",
    "MOTION_XPOSITION": "x koordinatė",
    "MOTION_YPOSITION": "y koordinatė",
    "MOTION_DIRECTION": "kryptis",
    "MOTION_SCROLLRIGHT": "slinkti dešinėn %1",
    "MOTION_SCROLLUP": "slinkti aukštyn %1",
    "MOTION_ALIGNSCENE": "lygiuoti sceną %1",
    "MOTION_ALIGNSCENE_BOTTOMLEFT": "apačioj kairėj",
    "MOTION_ALIGNSCENE_BOTTOMRIGHT": "apačioj dešinėj",
    "MOTION_ALIGNSCENE_MIDDLE": "viduryje",
    "MOTION_ALIGNSCENE_TOPLEFT": "kairėj viršuj",
    "MOTION_ALIGNSCENE_TOPRIGHT": "dešinėj viršuj",
    "MOTION_XSCROLL": "x slinktis",
    "MOTION_YSCROLL": "y slinktis",
    "MOTION_STAGE_SELECTED": "Stage selected: no motion blocks",
    "OPERATORS_ADD": "%1 + %2",
    "OPERATORS_SUBTRACT": "%1 - %2",
    "OPERATORS_MULTIPLY": "%1 * %2",
    "OPERATORS_DIVIDE": "%1 / %2",
    "OPERATORS_RANDOM": "parinkti atsitiktinį skaičių tarp %1 ir %2",
    "OPERATORS_GT": "%1 > %2",
    "OPERATORS_GT_EQUALS": "",
    "OPERATORS_LT": "%1 < %2",
    "OPERATORS_LT_EQUALS": "",
    "OPERATORS_EQUALS": "%1 = %2",
    "OPERATORS_AND": "%1 ir %2",
    "OPERATORS_OR": "%1 arba %2",
    "OPERATORS_NOT": "ne %1",
    "OPERATORS_JOIN": "prijungti %1 prie %2",
    "OPERATORS_JOIN_APPLE": "obuolys",
    "OPERATORS_JOIN_BANANA": "bananas",
    "OPERATORS_LETTEROF": "%1-oji raidė žodyje %2",
    "OPERATORS_LETTEROF_APPLE": "o",
    "OPERATORS_LENGTH": "%1 žodžio ilgis",
    "OPERATORS_CONTAINS": "ar %1 turi %2?",
    "OPERATORS_MOD": "liekana dalinant %1 iš %2",
    "OPERATORS_ROUND": "suapvalinti %1",
    "OPERATORS_MATHOP": "%1 iš %2",
    "OPERATORS_MATHOP_ABS": "modulis",
    "OPERATORS_MATHOP_FLOOR": "apvalinimas žemyn",
    "OPERATORS_MATHOP_CEILING": "apvalinimas aukštyn",
    "OPERATORS_MATHOP_SQRT": "kvadratinė šaknis",
    "OPERATORS_MATHOP_SIN": "sin",
    "OPERATORS_MATHOP_COS": "cos",
    "OPERATORS_MATHOP_TAN": "tg",
    "OPERATORS_MATHOP_ASIN": "arcsin",
    "OPERATORS_MATHOP_ACOS": "arccos",
    "OPERATORS_MATHOP_ATAN": "arctg",
    "OPERATORS_MATHOP_LN": "ln",
    "OPERATORS_MATHOP_LOG": "log",
    "OPERATORS_MATHOP_EEXP": "e ^",
    "OPERATORS_MATHOP_10EXP": "10 ^",
    "PROCEDURES_DEFINITION": "apibrėžti komandą %1",
    "SENSING_TOUCHINGOBJECT": "ar liečia %1?",
    "SENSING_TOUCHINGOBJECT_POINTER": "pelės žymeklį",
    "SENSING_TOUCHINGOBJECT_EDGE": "kraštą",
    "SENSING_TOUCHINGCOLOR": "liečia spalvą %1?",
    "SENSING_COLORISTOUCHINGCOLOR": "ar spalva %1 liečia %2?",
    "SENSING_DISTANCETO": "atstumas iki %1",
    "SENSING_DISTANCETO_POINTER": "pelės žymeklio",
    "SENSING_ASKANDWAIT": "paklausk %1 ir lauk",
    "SENSING_ASK_TEXT": "Koks tavo vardas?",
    "SENSING_ANSWER": "atsakas",
    "SENSING_KEYPRESSED": "ar klavišas %1 nuspaustas?",
    "SENSING_MOUSEDOWN": "ar pelė nuspausta?",
    "SENSING_MOUSEX": "pelės x",
    "SENSING_MOUSEY": "pelės y",
    "SENSING_SETDRAGMODE": "%1 vilkimą pele",
    "SENSING_SETDRAGMODE_DRAGGABLE": "velkamas",
    "SENSING_SETDRAGMODE_NOTDRAGGABLE": "nevelkamas",
    "SENSING_LOUDNESS": "garsumas",
    "SENSING_LOUD": "garsiai?",
    "SENSING_TIMER": "laikmatis",
    "SENSING_RESETTIMER": "reset timer",
    "SENSING_OF": "%1 iš %2",
    "SENSING_OF_XPOSITION": "x koordinatė",
    "SENSING_OF_YPOSITION": "y koordinatė",
    "SENSING_OF_DIRECTION": "kryptis",
    "SENSING_OF_COSTUMENUMBER": "kaukės numeris",
    "SENSING_OF_COSTUMENAME": "kaukės pavadinimas",
    "SENSING_OF_SIZE": "dydis",
    "SENSING_OF_VOLUME": "garsas",
    "SENSING_OF_BACKDROPNUMBER": "fono nr.",
    "SENSING_OF_BACKDROPNAME": "fono pavadinimas",
    "SENSING_OF_STAGE": "Scena",
    "SENSING_CURRENT": "dabartinis %1",
    "SENSING_CURRENT_YEAR": "metai",
    "SENSING_CURRENT_MONTH": "mėnuo",
    "SENSING_CURRENT_DATE": "data",
    "SENSING_CURRENT_DAYOFWEEK": "savaitės diena",
    "SENSING_CURRENT_HOUR": "valanda",
    "SENSING_CURRENT_MINUTE": "minutė",
    "SENSING_CURRENT_SECOND": "sekundė",
    "SENSING_DAYSSINCE2000": "dienų skaičius nuo 2000-ųjų",
    "SENSING_USERNAME": "vartotojo vardas",
    "SENSING_USERID": "vartotojo id",
    "SOUND_PLAY": "groti garsą %1",
    "SOUND_PLAYUNTILDONE": "groti garsą %1 kol pasibaigs",
    "SOUND_STOPALLSOUNDS": "nutildyti visus garsus",
    "SOUND_SETEFFECTO": "set %1 effect to %2",
    "SOUND_CHANGEEFFECTBY": "change %1 effect by %2",
    "SOUND_CLEAREFFECTS": "nuimti garso efektus",
    "SOUND_EFFECTS_PITCH": "garso aukštis",
    "SOUND_EFFECTS_PAN": "pan left/right",
    "SOUND_CHANGEVOLUMEBY": "pakeisti garsumą per %1",
    "SOUND_SETVOLUMETO": "nustatyti garsumą į %1%",
    "SOUND_VOLUME": "garsumas",
    "SOUND_RECORD": "įrašyti...",
    "CATEGORY_MOTION": "Judėjimas",
    "CATEGORY_LOOKS": "Išvaizda",
    "CATEGORY_SOUND": "Garsas",
    "CATEGORY_EVENTS": "Įvykiai",
    "CATEGORY_CONTROL": "Valdymas",
    "CATEGORY_SENSING": "Jutimas",
    "CATEGORY_OPERATORS": "Matematiniai simboliai",
    "CATEGORY_VARIABLES": "Kintamieji",
    "CATEGORY_MYBLOCKS": "Mano Blokai",
    "CATEGORY_LISTS": "",
    "CATEGORY_TUPLE": "",
    "CATEGORY_DICTIONARYS": "",
    "CATEGORY_NUMBERS": "",
    "CATEGORY_TEXT": "",
    "DUPLICATE": "Kurti kopiją",
    "DELETE": "Ištrinti",
    "ADD_COMMENT": "Pridėti komentarą",
    "REMOVE_COMMENT": "Pašalinti komentarą",
    "DELETE_BLOCK": "Ištrinti bloką",
    "DELETE_X_BLOCKS": "Ištrinti %1 blokus",
    "DELETE_ALL_BLOCKS": "Ištrinti visus %1 blokus?",
    "CLEAN_UP": "Valyti blokus",
    "HELP": "Pagalba",
    "UNDO": "Atšaukti",
    "REDO": "Grąžinti",
    "EDIT_PROCEDURE": "Taisyti",
    "SHOW_PROCEDURE_DEFINITION": "Eiti į apibrėžimą",
    "WORKSPACE_COMMENT_DEFAULT_TEXT": "Pasakyk ką nors...",
    "COLOUR_HUE_LABEL": "Spalva",
    "COLOUR_SATURATION_LABEL": "Sodrumas",
    "COLOUR_BRIGHTNESS_LABEL": "Šviesumas",
    "CHANGE_VALUE_TITLE": "Change value:",
    "RENAME_VARIABLE": "Pervadinti kintamąjį",
    "RENAME_VARIABLE_STRING": "",
    "RENAME_VARIABLE_NUMBER": "",
    "RENAME_VARIABLE_LIST": "",
    "RENAME_VARIABLE_TITLE": "Pervadinti visus \"%1\" kintamuosius į:",
    "RENAME_VARIABLE_MODAL_TITLE": "Pervadinti kintamąjį",
    "NEW_VARIABLE": "Sukurk kintamąjį",
    "NEW_VARIABLE_TITLE": "Naujo kintamojo pavadinimas:",
    "VARIABLE_MODAL_TITLE": "Naujas kintamasis",
    "VARIABLE_ALREADY_EXISTS": "A variable named \"%1\" already exists.",
    "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "A variable named \"%1\" already exists for another variable of type \"%2\".",
    "DELETE_VARIABLE_CONFIRMATION": "Ištrinti %1 atvejus, kuriuose panaudotas kintamasis \"%2\"?",
    "CANNOT_DELETE_VARIABLE_PROCEDURE": "Can't delete the variable \"%1\" because it's part of the definition of the function \"%2\"",
    "DELETE_VARIABLE": "Ištrinti kintamąjį \"%1\"",
    "DELETE_VARIABLE_STRING": "",
    "DELETE_VARIABLE_NUMBER": "",
    "DELETE_VARIABLE_LIST": "",
    "NEW_PROCEDURE": "Sukurti bloką",
    "PROCEDURE_ALREADY_EXISTS": "A procedure named \"%1\" already exists.",
    "PROCEDURE_DEFAULT_NAME": "bloko pavadinimas",
    "NEW_LIST": "Sukurti sąrašą",
    "NEW_LIST_TITLE": "Naujo sąrašo pavadinimas:",
    "LIST_MODAL_TITLE": "Naujas sąrašas",
    "LIST_ALREADY_EXISTS": "Jau egzistuoja sąrašas pavadintas \"%1\".",
    "RENAME_LIST_TITLE": "Pervadinti visus \"%1\" sąrašus į:",
    "RENAME_LIST_MODAL_TITLE": "Pervadinti sąrašą",
    "DEFAULT_LIST_ITEM": "daiktas",
    "DELETE_LIST": "Ištrinti \"%1\" sąrašą",
    "RENAME_LIST": "Pervadinti sąrašą",
    "NEW_BROADCAST_MESSAGE": "Nauja žinutė",
    "NEW_BROADCAST_MESSAGE_TITLE": "Nauja žinutė pavadinimu:",
    "BROADCAST_MODAL_TITLE": "Nauja žinutė",
    "DEFAULT_BROADCAST_MESSAGE_NAME": "žinutė1",
    "DELETE_PROCEDURE": "",
    "OK": "",
    "PROMPT": "",
    "STOP": "",
    "NEW_STRING": "",
    "NEW_NUMBER_VARIABLE": "",
    "WIFI": "",
    "USERNAME": "",
    "PWD": "",
    "IOT_SERVICE": "",
    "IOT_ID": "",
    "IOT_PWD": "",
    "IOT_TOPIC": "",
    "MORE_TOPIC": "",
    "OBLOQ_HTTP": "",
    "OBLOQ_IP": "",
    "OBLOQ_PORT": "",
    "ADD_TOPIC": "",
    "DELETE_TOPIC": "",
    "IOT_SERVER": "",
    "CHINA": "",
    "GLOBAL": "",
    "LOW_C": "",
    "LOW_C$": "",
    "LOW_D": "",
    "LOW_D$": "",
    "LOW_E": "",
    "LOW_F": "",
    "LOW_F$": "",
    "LOW_G": "",
    "LOW_G$": "",
    "LOW_A": "",
    "LOW_A$": "",
    "LOW_B": "",
    "MIDDLE_C": "",
    "MIDDLE_C$": "",
    "MIDDLE_D": "",
    "MIDDLE_D$": "",
    "MIDDLE_E": "",
    "MIDDLE_F": "",
    "MIDDLE_F$": "",
    "MIDDLE_G": "",
    "MIDDLE_G$": "",
    "MIDDLE_A": "",
    "MIDDLE_A$": "",
    "MIDDLE_B": "",
    "HIGH_C": "",
    "HIGH_C$": "",
    "HIGH_D": "",
    "HIGH_D$": "",
    "HIGH_E": "",
    "HIGH_F": "",
    "HIGH_F$": "",
    "HIGH_G": "",
    "HIGH_G$": "",
    "HIGH_A": "",
    "HIGH_A$": "",
    "HIGH_B": "",
    "OPERATORS_STRINGTONUMBER": "",
    "INTEGER": "",
    "DECIMAL": "",
    "OPERATORS_NUMTOASCII": "",
    "OPERATORS_STRINGTOASCII": "",
    "OPERATORS_NUMTOSTRING": "",
    "OPERATORS_MAP": "",
    "OPERATORS_CONSTRAIN": "",
    "STEPS_PER_TURN": "",
    "ROTATE_SPEED": "",
    "NEW_AI": "",
    "ASK_AND_PAINT": "",
    "GET_IDENTIFIED_NUM": "",
    "READ_NUM_AND_SAY": "",
    "IMAGE_ADDR": "",
    "IMAGE_PREVIEW": "",
    "IMAGE_OPEN": "",
    "IMAGE_SIZE": "",
    "IMAGE_WIDTH": "",
    "IMAGE_HEIGHT": "",
    "VARIABLE": "",
    "VARIABLE_LIST": "",
    "SET_STRING_VARIABLE": "",
    "STRING_START_WITH": "",
    "DICTIONARY_HEIGHT_FIRST": "",
    "DICTIONARY_HEIGHT_TWO": "",
    "DICTIONARY_HEIGHT_THREE": "",
    "DICTIONARY_HEIGHT_FOUR": "",
    "DATA_MPITEMOFLIST": "",
    "DATA_MPINTERCEPTITEM": "",
    "DATA_MPINSERTATLIST": "",
    "DATA_MPSETVALUEATLIST": "",
    "DATA_MPINSERTATLIST_INSERT": "",
    "DATA_MPINSERTATLIST_CHANGE": "",
    "DATA_MPINSERTATLIST_FOUR": "",
    "DATA_MPINSERTATLIST_FIVE": "",
    "DATA_MPINSERTATLIST_SIX": "",
    "DATA_MPINSERTATLIST_SEVEN": "",
    "DATA_MPINSERTATLIST_EIGHT": "",
    "DATA_MPINSERTATLIST_EIGHT_FALSE": "",
    "DATA_MPINSERTATLIST_EIGHT_TRUE": "",
    "DATA_MPINSERTATLIST_EIGHT_REVERSE": "",
    "OPERATORS_RANDOMA": "",
    "OPERATORS_MODA": "",
    "OPERATORS_ROUNDA": "",
    "OPERATORS_MATHOPA": "",
    "OPERATORS_MATHOP_ABSA": "",
    "OPERATORS_MATHOP_FLOORA": "",
    "OPERATORS_MATHOP_CEILINGA": "",
    "OPERATORS_MATHOP_SQRTA": "",
    "OPERATORS_MATHOP_FIVE": "",
    "OPERATORS_MATHOP_SIX": "",
    "OPERATORS_MATHOP_SEVEN": "",
    "OPERATORS_MATHOP_LENGHT": "",
    "OPERATORS_MATHOP_MAXMIN": "",
    "OPERATORS_MATHOP_MAXMIN_MAX": "",
    "OPERATORS_MATHOP_MAXMIN_MIN": "",
    "OPERATORS_TEXTS_ONE": "",
    "OPERATORS_TEXTS_TWO": "",
    "OPERATORS_TEXTS_THREE": "",
    "OPERATORS_TEXTS_FOUR": "",
    "OPERATORS_TEXTS_FOUR_NEW": "",
    "OPERATORS_TEXTS_NEW": "",
    "OPERATORS_TEXTS_NEW_OP": "",
    "OPERATORS_TEXTS_FOUR_ONE": "",
    "OPERATORS_TEXTS_FOUR_TWO": "",
    "OPERATORS_TEXTS_FOUR_THREE": "",
    "OPERATORS_TEXTS_FOUR_FOUR": "",
    "OPERATORS_TEXTS_FOUR_F": "",
    "OPERATORS_TEXTS_FOUR_L": "",
    "OPERATORS_STRINGTONUMBERA": "",
    "OPERATORS_NUMTOASCIIA": "",
    "OPERATORS_STRINGTOASCIIA": "",
    "OPERATORS_NUMTOSTRINGA": "",
    "NEW_MC": "",
    "RENAME_MCNUMBER_TITLE": "",
    "RENAME_MCNUMBER_MODAL_TITLE": "",
    "RENAME_VARIABLE_MCNUMBER": "",
    "DELETE_VARIABLE_MCNUMBER": "",
    "SET_MC_VARIABLE": "",
    "TUPLE_ONE": "",
    "TUPLE_TWO": "",
    "ALIYUN": "",
    "ONENET": "",
    "EASYIOT": "",
    "SHANGHAI": "",
    "QINGDAO": "",
    "BEIJING": "",
    "ZHANGJIAKOU": "",
    "HUHEHAOTE": "",
    "HANGZHOU": "",
    "SHENZHEN": "",
    "HONGKONG": "",
    "SINGAPORE": "",
    "SYDNEY": "",
    "KUALALUMPUR": "",
    "JAKARTA": "",
    "MUMBAI": "",
    "TOKYO": "",
    "SILICONVALLEY": "",
    "VIRGINIA": "",
    "FRANKFURT": "",
    "LONDON": "",
    "DUBAI": "",
    "IOT_PLATFORM": "",
    "PARAMS": "",
    "SERVER_ATTR": "",
    "PRODUCTID": "",
    "DEVICEID": "",
    "TINYDB_SECRET": "",
    "TINYDB_USER": "",
    "APIADDR": "",
    "SOFTWARE_SERIAL": "",
    "HARDWARE_SERIAL": "",
    "HARDWARE_SERIAL1": "",
    "HARDWARE_SERIAL2": "",
    "HARDWARE_SERIAL3": ""
  },
  "hu": {
    "CONTROL_FOREVER": "mindig",
    "CONTROL_REPEAT": "ismételd %1",
    "CONTROL_IF": "ha %1 akkor",
    "CONTROL_ELSE": "különben",
    "CONTROL_STOP": "álljon le",
    "CONTROL_STOP_ALL": "minden feladat",
    "CONTROL_STOP_THIS": "ez a feladat",
    "CONTROL_STOP_OTHER": "szereplő többi feladata",
    "CONTROL_WAIT": "várj %1 mp-et",
    "CONTROL_WAITUNTIL": "várj eddig: %1",
    "CONTROL_REPEATUNTIL": "ismételd eddig: %1",
    "CONTROL_WHILE": "amíg %1",
    "CONTROL_FOREACH": "minden %1 elemet a %2 listában",
    "CONTROL_STARTASCLONE": "másolatként kezdéskor",
    "CONTROL_CREATECLONEOF": "készíts másolatot: %1",
    "CONTROL_CREATECLONEOF_MYSELF": "magadról",
    "CONTROL_DELETETHISCLONE": "töröld ezt a másolatot",
    "CONTROL_COUNTER": "számláló",
    "CONTROL_INCRCOUNTER": "növeld a számlálót",
    "CONTROL_CLEARCOUNTER": "nullázd a számlálót",
    "CONTROL_ALLATONCE": "mindent egyszerre",
    "DATA_SETVARIABLETO": "%1 legyen %2",
    "DATA_CHANGEVARIABLEBY": "%1 változzon %2",
    "DATA_SHOWVARIABLE": "%1 változó jelenjen meg",
    "DATA_HIDEVARIABLE": "%1 változó tűnjön el",
    "DATA_ADDTOLIST": "add %1 elemet %2 listához",
    "DATA_DELETEOFLIST": "töröld %1 elemet %2 listából",
    "DATA_DELETEALLOFLIST": "töröld %1 lista minden elemét",
    "DATA_INSERTATLIST": "szúrd be %1 elemet %2 helyre %3 listában",
    "DATA_REPLACEITEMOFLIST": "cseréld le %1 elemet %2 listában %3 elemre",
    "DATA_ITEMOFLIST": "%2 %1 eleme",
    "DATA_ITEMNUMOFLIST": "%1 sorszáma %2 listában",
    "DATA_LENGTHOFLIST": "%1 hossza",
    "DATA_LISTCONTAINSITEM": "%1 tartalmazza %2?",
    "DATA_SHOWLIST": "%1 lista jelenjen meg",
    "DATA_HIDELIST": "%1 lista tűnjön el",
    "DATA_INDEX_ALL": "minden",
    "DATA_INDEX_LAST": "utolsó",
    "DATA_INDEX_RANDOM": "véletlen",
    "EVENT_WHENFLAGCLICKED": "%1 -ra kattintáskor",
    "EVENT_WHENTHISSPRITECLICKED": "ezen szereplőre kattintáskor",
    "EVENT_WHENSTAGECLICKED": "színpadra kattintáskor",
    "EVENT_WHENTOUCHINGOBJECT": "%1 érintésekor",
    "EVENT_WHENBROADCASTRECEIVED": "%1 üzenet érkezésekor",
    "EVENT_WHENBACKDROPSWITCHESTO": "%1 háttér beállításakor",
    "EVENT_WHENGREATERTHAN": "amikor %1 > %2",
    "EVENT_WHENGREATERTHAN_TIMER": "időmérő",
    "EVENT_WHENGREATERTHAN_LOUDNESS": "hangerő",
    "EVENT_BROADCAST": "küldj üzenetet: %1",
    "EVENT_BROADCASTANDWAIT": "küldj üzenetet: %1 és várj",
    "EVENT_WHENKEYPRESSED": "amikor %1 gomb lenyomva",
    "EVENT_WHENKEYPRESSED_SPACE": "szóköz",
    "EVENT_WHENKEYPRESSED_LEFT": "balra nyíl",
    "EVENT_WHENKEYPRESSED_RIGHT": "jobbra nyíl",
    "EVENT_WHENKEYPRESSED_DOWN": "lefele nyíl",
    "EVENT_WHENKEYPRESSED_UP": "felfele nyíl",
    "EVENT_WHENKEYPRESSED_ANY": "bármelyik",
    "LOOKS_SAYFORSECS": "mondd: %1 %2 másodpercig",
    "LOOKS_SAY": "mondd: %1",
    "LOOKS_HELLO": "Üdv!",
    "LOOKS_THINKFORSECS": "gondold: %1 %2 másodpercig",
    "LOOKS_THINK": "gondold: %1",
    "LOOKS_HMM": "Hmm...",
    "LOOKS_SHOW": "jelenj meg",
    "LOOKS_HIDE": "tűnj el",
    "LOOKS_HIDEALLSPRITES": "rejtsd el az összes szereplőt",
    "LOOKS_EFFECT_COLOR": "szín",
    "LOOKS_EFFECT_FISHEYE": "halszem",
    "LOOKS_EFFECT_WHIRL": "örvény",
    "LOOKS_EFFECT_PIXELATE": "pixeles",
    "LOOKS_EFFECT_MOSAIC": "mozaik",
    "LOOKS_EFFECT_BRIGHTNESS": "fényerő",
    "LOOKS_EFFECT_GHOST": "szellem",
    "LOOKS_CHANGEEFFECTBY": "%1 hatás változzon %2",
    "LOOKS_SETEFFECTTO": "%1 hatás legyen %2",
    "LOOKS_CLEARGRAPHICEFFECTS": "töröld a grafikus hatásokat",
    "LOOKS_CHANGESIZEBY": "méret változzon %1",
    "LOOKS_SETSIZETO": "méret legyen %1 %",
    "LOOKS_SIZE": "méret",
    "LOOKS_CHANGESTRETCHBY": "nyújtás változzon %1",
    "LOOKS_SETSTRETCHTO": "nyújtás legyen %1",
    "LOOKS_SWITCHCOSTUMETO": "jelmez legyen %1",
    "LOOKS_NEXTCOSTUME": "következő jelmez",
    "LOOKS_SWITCHBACKDROPTO": "háttér legyen %1",
    "LOOKS_GOTOFRONTBACK": "menj %1",
    "LOOKS_GOTOFRONTBACK_FRONT": "legelőre",
    "LOOKS_GOTOFRONTBACK_BACK": "leghátra",
    "LOOKS_GOFORWARDBACKWARDLAYERS": "menj %1 %2 szintet",
    "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "előre",
    "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "hátra",
    "LOOKS_BACKDROPNUMBERNAME": "%1 háttér",
    "LOOKS_COSTUMENUMBERNAME": "%1 jelmez",
    "LOOKS_NUMBERNAME_NUMBER": "szám",
    "LOOKS_NUMBERNAME_NAME": "név",
    "LOOKS_SWITCHBACKDROPTOANDWAIT": "háttér legyen %1 és várj",
    "LOOKS_NEXTBACKDROP_BLOCK": "következő háttér",
    "LOOKS_NEXTBACKDROP": "következő háttér",
    "LOOKS_PREVIOUSBACKDROP": "előző háttér",
    "LOOKS_RANDOMBACKDROP": "véletlen háttér",
    "MOTION_MOVESTEPS": "menj %1 lépést",
    "MOTION_TURNLEFT": "fordulj %1 %2 fokot",
    "MOTION_TURNRIGHT": "fordulj %1 %2 fokot",
    "MOTION_POINTINDIRECTION": "nézz %1 fokos irányba",
    "MOTION_POINTTOWARDS": "nézz %1 felé",
    "MOTION_POINTTOWARDS_POINTER": "egérmutató",
    "MOTION_POINTTOWARDS_RANDOM": "véletlen irány",
    "MOTION_GOTO": "ugorj %1 helyére",
    "MOTION_GOTO_POINTER": "egérmutató",
    "MOTION_GOTO_RANDOM": "véletlen hely",
    "MOTION_GOTOXY": "ugorj ide: x: %1 y: %2",
    "MOTION_GLIDESECSTOXY": "csússz %1 mp-ig ide: x: %2 y: %3",
    "MOTION_GLIDETO": "csússz %1 mp-ig ide: %2",
    "MOTION_GLIDETO_POINTER": "egérmutató",
    "MOTION_GLIDETO_RANDOM": "véletlen hely",
    "MOTION_CHANGEXBY": "x változzon %1",
    "MOTION_SETX": "x legyen %1",
    "MOTION_CHANGEYBY": "y változzon %1",
    "MOTION_SETY": "y legyen %1",
    "MOTION_IFONEDGEBOUNCE": "ha szélen vagy, pattanj vissza",
    "MOTION_SETROTATIONSTYLE": "jelmez %1",
    "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "balra-jobbra nézhet",
    "MOTION_SETROTATIONSTYLE_DONTROTATE": "nem foroghat",
    "MOTION_SETROTATIONSTYLE_ALLAROUND": "foroghat",
    "MOTION_XPOSITION": "x hely",
    "MOTION_YPOSITION": "y hely",
    "MOTION_DIRECTION": "irány",
    "MOTION_SCROLLRIGHT": "görgess jobbra: %1",
    "MOTION_SCROLLUP": "görgess föl: %1",
    "MOTION_ALIGNSCENE": "%1 jelenet igazítása",
    "MOTION_ALIGNSCENE_BOTTOMLEFT": "lent balra",
    "MOTION_ALIGNSCENE_BOTTOMRIGHT": "lent jobbra",
    "MOTION_ALIGNSCENE_MIDDLE": "középen",
    "MOTION_ALIGNSCENE_TOPLEFT": "fent balra",
    "MOTION_ALIGNSCENE_TOPRIGHT": "fent jobbra",
    "MOTION_XSCROLL": "x görgetés",
    "MOTION_YSCROLL": "y görgetés",
    "MOTION_STAGE_SELECTED": "Színpad kiválasztva: nincsenek mozgás blokkok",
    "OPERATORS_ADD": "%1 + %2",
    "OPERATORS_SUBTRACT": "%1 - %2",
    "OPERATORS_MULTIPLY": "%1 * %2",
    "OPERATORS_DIVIDE": "%1 / %2",
    "OPERATORS_RANDOM": "véletlen %1 és %2 között",
    "OPERATORS_GT": "%1 > %2",
    "OPERATORS_GT_EQUALS": "%1 >= %2",
    "OPERATORS_LT": "%1 < %2",
    "OPERATORS_LT_EQUALS": "%1 <= %2",
    "OPERATORS_EQUALS": "%1 = %2",
    "OPERATORS_AND": "%1 és %2",
    "OPERATORS_OR": "%1 vagy %2",
    "OPERATORS_NOT": "nem %1",
    "OPERATORS_JOIN": "%1 és %2 összefűzve",
    "OPERATORS_JOIN_APPLE": "alma",
    "OPERATORS_JOIN_BANANA": "banán",
    "OPERATORS_LETTEROF": "%2 %1 betűje",
    "OPERATORS_LETTEROF_APPLE": "a",
    "OPERATORS_LENGTH": "%1 hossza",
    "OPERATORS_CONTAINS": "%1 tartalmazza %2?",
    "OPERATORS_MOD": "%1 / %2 maradéka",
    "OPERATORS_ROUND": "%1 kerekítve",
    "OPERATORS_MATHOP": "%2 %1",
    "OPERATORS_MATHOP_ABS": "abszolut értéke",
    "OPERATORS_MATHOP_FLOOR": "lefelé kerekítve",
    "OPERATORS_MATHOP_CEILING": "felfelé kerekítve",
    "OPERATORS_MATHOP_SQRT": "gyöke",
    "OPERATORS_MATHOP_SIN": "szinusza",
    "OPERATORS_MATHOP_COS": "koszinusza",
    "OPERATORS_MATHOP_TAN": "tangense",
    "OPERATORS_MATHOP_ASIN": "arcsin",
    "OPERATORS_MATHOP_ACOS": "arccos",
    "OPERATORS_MATHOP_ATAN": "arctan",
    "OPERATORS_MATHOP_LN": "ln",
    "OPERATORS_MATHOP_LOG": "logaritmusa",
    "OPERATORS_MATHOP_EEXP": "e^",
    "OPERATORS_MATHOP_10EXP": "10^",
    "PROCEDURES_DEFINITION": "%1 meghatározása",
    "SENSING_TOUCHINGOBJECT": "érinted: %1?",
    "SENSING_TOUCHINGOBJECT_POINTER": "egérmutató",
    "SENSING_TOUCHINGOBJECT_EDGE": "játéktér széle",
    "SENSING_TOUCHINGCOLOR": "érintesz %1 színt?",
    "SENSING_COLORISTOUCHINGCOLOR": "%1 szín érinti %2 színt?",
    "SENSING_DISTANCETO": "%1 távolsága",
    "SENSING_DISTANCETO_POINTER": "egérmutató",
    "SENSING_ASKANDWAIT": "kérdezd %1 és várj",
    "SENSING_ASK_TEXT": "Hogy hívnak?",
    "SENSING_ANSWER": "válasz",
    "SENSING_KEYPRESSED": "%1 lenyomva?",
    "SENSING_MOUSEDOWN": "egér lenyomva?",
    "SENSING_MOUSEX": "egér x",
    "SENSING_MOUSEY": "egér y",
    "SENSING_SETDRAGMODE": "húzás módja legyen %1",
    "SENSING_SETDRAGMODE_DRAGGABLE": "húzható",
    "SENSING_SETDRAGMODE_NOTDRAGGABLE": "nem húzható",
    "SENSING_LOUDNESS": "hangerő",
    "SENSING_LOUD": "hangos?",
    "SENSING_TIMER": "időmérő",
    "SENSING_RESETTIMER": "időmérő visszaállítása",
    "SENSING_OF": "%2 %1",
    "SENSING_OF_XPOSITION": "x hely",
    "SENSING_OF_YPOSITION": "y hely",
    "SENSING_OF_DIRECTION": "irány",
    "SENSING_OF_COSTUMENUMBER": "jelmez sorszáma",
    "SENSING_OF_COSTUMENAME": "jelmez neve",
    "SENSING_OF_SIZE": "mérete",
    "SENSING_OF_VOLUME": "hangereje",
    "SENSING_OF_BACKDROPNUMBER": "háttér sorszáma",
    "SENSING_OF_BACKDROPNAME": "háttér neve",
    "SENSING_OF_STAGE": "Színpad",
    "SENSING_CURRENT": "jelenlegi %1",
    "SENSING_CURRENT_YEAR": "év",
    "SENSING_CURRENT_MONTH": "hónap",
    "SENSING_CURRENT_DATE": "nap",
    "SENSING_CURRENT_DAYOFWEEK": "hét napja",
    "SENSING_CURRENT_HOUR": "óra",
    "SENSING_CURRENT_MINUTE": "perc",
    "SENSING_CURRENT_SECOND": "másodperc",
    "SENSING_DAYSSINCE2000": "napok 2000 óta",
    "SENSING_USERNAME": "felhasználó",
    "SENSING_USERID": "felhasználóazonosító",
    "SOUND_PLAY": "játszd %1 hangot",
    "SOUND_PLAYUNTILDONE": "játszd végig %1 hangot",
    "SOUND_STOPALLSOUNDS": "minden hang álljon le",
    "SOUND_SETEFFECTO": "%1 hatás legyen %2",
    "SOUND_CHANGEEFFECTBY": "%1 hatás változzon %2",
    "SOUND_CLEAREFFECTS": "hanghatások törlése",
    "SOUND_EFFECTS_PITCH": "hangmagasság",
    "SOUND_EFFECTS_PAN": "pásztázás balra/jobbra",
    "SOUND_CHANGEVOLUMEBY": "hangerő változzon %1",
    "SOUND_SETVOLUMETO": "hangerő legyen %1%",
    "SOUND_VOLUME": "hangerő",
    "SOUND_RECORD": "felvétel...",
    "CATEGORY_MOTION": "Mozgás",
    "CATEGORY_LOOKS": "Kinézet",
    "CATEGORY_SOUND": "Hang",
    "CATEGORY_EVENTS": "Események",
    "CATEGORY_CONTROL": "Vezérlés",
    "CATEGORY_SENSING": "Érzékelés",
    "CATEGORY_OPERATORS": "Műveletek",
    "CATEGORY_VARIABLES": "Változók",
    "CATEGORY_MYBLOCKS": "Blokkjaim",
    "CATEGORY_LISTS": "",
    "CATEGORY_TUPLE": "",
    "CATEGORY_DICTIONARYS": "",
    "CATEGORY_NUMBERS": "Szám",
    "CATEGORY_TEXT": "Szöveg",
    "DUPLICATE": "Duplikálás",
    "DELETE": "Törlés",
    "ADD_COMMENT": "Megjegyzés",
    "REMOVE_COMMENT": "Megjegyzés eltávolítása",
    "DELETE_BLOCK": "Blokk törlése",
    "DELETE_X_BLOCKS": "Törölj %1 blokkot",
    "DELETE_ALL_BLOCKS": "Mind a %1 blokk törlése?",
    "CLEAN_UP": "Rendrakás",
    "HELP": "Súgó",
    "UNDO": "Visszavonás",
    "REDO": "Mégis",
    "EDIT_PROCEDURE": "Szerkesztés",
    "SHOW_PROCEDURE_DEFINITION": "Ugorj a meghatározáshoz",
    "WORKSPACE_COMMENT_DEFAULT_TEXT": "Mondj valamit...",
    "COLOUR_HUE_LABEL": "Szín",
    "COLOUR_SATURATION_LABEL": "Telítettség",
    "COLOUR_BRIGHTNESS_LABEL": "Fényerő",
    "CHANGE_VALUE_TITLE": "Érték változtatása:",
    "RENAME_VARIABLE": "Változó átnevezése",
    "RENAME_VARIABLE_STRING": "Karakterlánc változó átnevezése",
    "RENAME_VARIABLE_NUMBER": "Numerikus változó átnevezése",
    "RENAME_VARIABLE_LIST": "Listaváltozók átnevezése",
    "RENAME_VARIABLE_TITLE": "Minden „%1” változó átnevezése erre:",
    "RENAME_VARIABLE_MODAL_TITLE": "Változó átnevezése",
    "NEW_VARIABLE": "Változó létrehozása",
    "NEW_VARIABLE_TITLE": "Új változó neve:",
    "VARIABLE_MODAL_TITLE": "Új változó",
    "VARIABLE_ALREADY_EXISTS": "„%1” nevű változó már létezik.",
    "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "„%1” nevű változó már létezik egy másik „%2” típusú változónál",
    "DELETE_VARIABLE_CONFIRMATION": "Törlöd „%2” változót (%1 használat)?",
    "CANNOT_DELETE_VARIABLE_PROCEDURE": "„%1” változó nem törölhető, mert „%2” függvény leírásának része",
    "DELETE_VARIABLE": "Töröld „%1” változót",
    "DELETE_VARIABLE_STRING": "「%1」karakterlánc változó törlése",
    "DELETE_VARIABLE_NUMBER": "「%1」numerikus változó törlése",
    "DELETE_VARIABLE_LIST": "「%1」listaváltozó törlése",
    "NEW_PROCEDURE": "Blokk létrehozása",
    "PROCEDURE_ALREADY_EXISTS": "„%1” nevű blokk már létezik.",
    "PROCEDURE_DEFAULT_NAME": "blokknév",
    "NEW_LIST": "Lista létrehozása",
    "NEW_LIST_TITLE": "Új lista neve:",
    "LIST_MODAL_TITLE": "Új lista",
    "LIST_ALREADY_EXISTS": "„%1” nevű lista már létezik.",
    "RENAME_LIST_TITLE": "Nevezd át az összes „%1” listát erre:",
    "RENAME_LIST_MODAL_TITLE": "Lista átnevezése",
    "DEFAULT_LIST_ITEM": "valami",
    "DELETE_LIST": "Töröld „%1” listát",
    "RENAME_LIST": "Lista átnevezése",
    "NEW_BROADCAST_MESSAGE": "Új üzenet",
    "NEW_BROADCAST_MESSAGE_TITLE": "Új üzenet neve:",
    "BROADCAST_MODAL_TITLE": "Új üzenet",
    "DEFAULT_BROADCAST_MESSAGE_NAME": "üzenet1",
    "DELETE_PROCEDURE": "A blokk meghatározásának törléséhez először töröld az összes ilyen blokkhasználatot",
    "OK": "Rendben",
    "PROMPT": "Azonnal",
    "STOP": "Megállítás",
    "NEW_STRING": "Karakterlánc változó létrehozása",
    "NEW_NUMBER_VARIABLE": "Numerikus változó létrehozása",
    "WIFI": "Wi-Fi",
    "USERNAME": "Felhasználó",
    "PWD": "Jelszó",
    "IOT_SERVICE": "IoT szolgáltatás",
    "IOT_ID": "iot azonosító",
    "IOT_PWD": "iot jelszó",
    "IOT_TOPIC": "Téma_0",
    "MORE_TOPIC": "Téma",
    "OBLOQ_HTTP": "HTTP",
    "OBLOQ_IP": "IP cím",
    "OBLOQ_PORT": "Port",
    "ADD_TOPIC": "Téma hozzáadása, maximum 4 adható hozzá",
    "DELETE_TOPIC": "Téma törlése",
    "IOT_SERVER": "Szerver",
    "CHINA": "Kína",
    "GLOBAL": "Nemzetközi",
    "LOW_C": "Alsó C/C3",
    "LOW_C$": "Alsó C#/C#3",
    "LOW_D": "Alsó D/D3",
    "LOW_D$": "Alsó D#/D#3",
    "LOW_E": "Alsó E/E3",
    "LOW_F": "Alsó F/F3",
    "LOW_F$": "Alsó F#/F#3",
    "LOW_G": "Alsó G/G3",
    "LOW_G$": "Alsó G#/G#3",
    "LOW_A": "Alsó A/A3",
    "LOW_A$": "Alsó A#/A#3",
    "LOW_B": "Alsó B/B3",
    "MIDDLE_C": "Középső C/C4",
    "MIDDLE_C$": "Középső C#/C#4",
    "MIDDLE_D": "Középső D/D4",
    "MIDDLE_D$": "Középső D#/D#4",
    "MIDDLE_E": "Középső E/E4",
    "MIDDLE_F": "Középső F/F4",
    "MIDDLE_F$": "Középső F#/F#4",
    "MIDDLE_G": "Középső G/G4",
    "MIDDLE_G$": "Középső G#/G#4",
    "MIDDLE_A": "Középső A/A4",
    "MIDDLE_A$": "Középső A#/A#4",
    "MIDDLE_B": "Középső B/B4",
    "HIGH_C": "Felső C/C5",
    "HIGH_C$": "Felső C#/C#5",
    "HIGH_D": "Felső D/D5",
    "HIGH_D$": "Felső D#/D#5",
    "HIGH_E": "Felső E/E5",
    "HIGH_F": "Felső F/F5",
    "HIGH_F$": "Felső F#/F#5",
    "HIGH_G": "Felső G/G5",
    "HIGH_G$": "Felső G#/G#5",
    "HIGH_A": "Felső A/A5",
    "HIGH_A$": "Felső A#/A#5",
    "HIGH_B": "Felső B/B5",
    "OPERATORS_STRINGTONUMBER": "%1 karakterlánc konvertálása %2",
    "INTEGER": "Egész ",
    "DECIMAL": "Decimális",
    "OPERATORS_NUMTOASCII": "%1 szám ASCII-vé konvertálása",
    "OPERATORS_STRINGTOASCII": "%1 karakterlánc ASCII-vé konvertálása",
    "OPERATORS_NUMTOSTRING": "%1 szám karakterlánccá konvertálása",
    "OPERATORS_MAP": "függvény %1 ettől [ %2, %3 ] eddig [ %4, %5 ]",
    "OPERATORS_CONSTRAIN": "korlátozd %1 (min) %2 és (max) %3 közé",
    "STEPS_PER_TURN": "lépés fordulatonként",
    "ROTATE_SPEED": "forgási sebesség (r/s)",
    "NEW_AI": "MI blokk létrehozása",
    "ASK_AND_PAINT": "kérlek rajzolj egy számot",
    "GET_IDENTIFIED_NUM": "számfelismerés",
    "READ_NUM_AND_SAY": "mondd ki a felismert számot",
    "IMAGE_ADDR": "Kép címe",
    "IMAGE_PREVIEW": "Kép előnézete",
    "IMAGE_OPEN": "Megnyit",
    "IMAGE_SIZE": "Képméret",
    "IMAGE_WIDTH": "SZ",
    "IMAGE_HEIGHT": "M",
    "VARIABLE": "%1",
    "VARIABLE_LIST": "%1",
    "SET_STRING_VARIABLE": "%1 beállítása erre: %2",
    "STRING_START_WITH": "%1 kezdése ezzel: %2",
    "DICTIONARY_HEIGHT_FIRST": "",
    "DICTIONARY_HEIGHT_TWO": "",
    "DICTIONARY_HEIGHT_THREE": "",
    "DICTIONARY_HEIGHT_FOUR": "",
    "DATA_MPITEMOFLIST": "",
    "DATA_MPINTERCEPTITEM": "",
    "DATA_MPINSERTATLIST": "",
    "DATA_MPSETVALUEATLIST": "",
    "DATA_MPINSERTATLIST_INSERT": "",
    "DATA_MPINSERTATLIST_CHANGE": "",
    "DATA_MPINSERTATLIST_FOUR": "",
    "DATA_MPINSERTATLIST_FIVE": "",
    "DATA_MPINSERTATLIST_SIX": "",
    "DATA_MPINSERTATLIST_SEVEN": "",
    "DATA_MPINSERTATLIST_EIGHT": "",
    "DATA_MPINSERTATLIST_EIGHT_FALSE": "",
    "DATA_MPINSERTATLIST_EIGHT_TRUE": "",
    "DATA_MPINSERTATLIST_EIGHT_REVERSE": "",
    "OPERATORS_RANDOMA": "%1 %2 véletlenszerű szám kiválasztása %3 és %4 között %5",
    "OPERATORS_MODA": "%1 %2 %3 mód %4",
    "OPERATORS_ROUNDA": "%1 %2 kerekítés %3",
    "OPERATORS_MATHOPA": "%1 %2 %3 %4",
    "OPERATORS_MATHOP_ABSA": "abszolútértéke",
    "OPERATORS_MATHOP_FLOORA": "felfele kerekítés",
    "OPERATORS_MATHOP_CEILINGA": "lefelé kerekítés",
    "OPERATORS_MATHOP_SQRTA": "gyök",
    "OPERATORS_MATHOP_FIVE": "%1 %2 %3 megváltoztatása ennyivel: %4",
    "OPERATORS_MATHOP_SIX": "%1 %2 függvény %3 ettől [ %4, %5 ] eddig [ %6, %7 ]",
    "OPERATORS_MATHOP_SEVEN": "%1 %2 korlátozása %3(min) %4 és (max) %5 közé",
    "OPERATORS_MATHOP_LENGHT": "hosszúság %1",
    "OPERATORS_MATHOP_MAXMIN": "%1 %2",
    "OPERATORS_MATHOP_MAXMIN_MAX": "maximum",
    "OPERATORS_MATHOP_MAXMIN_MIN": "minimum",
    "OPERATORS_TEXTS_ONE": "%1 %2 összekapcsolás %3 %4",
    "OPERATORS_TEXTS_TWO": "%1 %2 betű %3 %4",
    "OPERATORS_TEXTS_THREE": "%1 %2 %3 tartalmazza %4?",
    "OPERATORS_TEXTS_FOUR": "%1 %2 betű %5 %4 ezzé %7 %3 %6",
    "OPERATORS_TEXTS_FOUR_NEW": "%1 megkapja %2 %3 karakterek, hogy %4 %5 karakterek",
    "OPERATORS_TEXTS_NEW": "%1 %2 találja meg %3 ebben: %4 %5 kialakuló helyzet",
    "OPERATORS_TEXTS_NEW_OP": "keresd meg hol tűnik fel %1 itt: %2 %3",
    "OPERATORS_TEXTS_FOUR_ONE": "balról",
    "OPERATORS_TEXTS_FOUR_TWO": "jobbról",
    "OPERATORS_TEXTS_FOUR_THREE": "balról",
    "OPERATORS_TEXTS_FOUR_FOUR": "jobbról",
    "OPERATORS_TEXTS_FOUR_F": "első",
    "OPERATORS_TEXTS_FOUR_L": "utolsó",
    "OPERATORS_STRINGTONUMBERA": "%1 %2 %3 karakterlánc %4 konvertálása",
    "OPERATORS_NUMTOASCIIA": "%1 %2 %3 szám ASCII karakterekké konvertálása",
    "OPERATORS_STRINGTOASCIIA": "%1 %2 %3 karakter ASCII értékké konvertálása",
    "OPERATORS_NUMTOSTRINGA": "%1 %2 %3 szám karakterlánccá konvertálása",
    "NEW_MC": "MicroPython változó létrehozása",
    "RENAME_MCNUMBER_TITLE": "minden [%1] nevű változó átnevezése erre:",
    "RENAME_MCNUMBER_MODAL_TITLE": "változó átnevezése",
    "RENAME_VARIABLE_MCNUMBER": "változó átnevezése",
    "DELETE_VARIABLE_MCNUMBER": "[%1] változó törlése",
    "SET_MC_VARIABLE": "%1 legyen %2",
    "TUPLE_ONE": "",
    "TUPLE_TWO": "",
    "ALIYUN": "Aliyun",
    "ONENET": "OneNet",
    "EASYIOT": "Easy IoT",
    "SHANGHAI": "Shanghai",
    "QINGDAO": "Qingdao",
    "BEIJING": "Beijing",
    "ZHANGJIAKOU": "Zhangjiakou",
    "HUHEHAOTE": "Huhehaote",
    "HANGZHOU": "Hangzhou",
    "SHENZHEN": "Shenzhen",
    "HONGKONG": "HongKong",
    "SINGAPORE": "Singapore",
    "SYDNEY": "Sydney",
    "KUALALUMPUR": "Luala Lumpur",
    "JAKARTA": "Jakarta",
    "MUMBAI": "Mumbai",
    "TOKYO": "Tokyo",
    "SILICONVALLEY": "Silicon Valley",
    "VIRGINIA": "Virginia",
    "FRANKFURT": "Frankfurt",
    "LONDON": "London",
    "DUBAI": "Dubai",
    "IOT_PLATFORM": "Iot Platform",
    "PARAMS": "Params",
    "SERVER_ATTR": "Szerver cím",
    "PRODUCTID": "Termékazonosító",
    "DEVICEID": "Eszközazonosító",
    "TINYDB_SECRET": "titkos (titkos)",
    "TINYDB_USER": "felhasználó (felhasználó)",
    "APIADDR": "API cím",
    "SOFTWARE_SERIAL": "Program sorozatszám",
    "HARDWARE_SERIAL": "Hardware sorozatszám",
    "HARDWARE_SERIAL1": "Hardware sorozatszám1",
    "HARDWARE_SERIAL2": "Hardware sorozatszám2",
    "HARDWARE_SERIAL3": "Hardware sorozatszám3",
    "CONTROL_LOOP_BREAKCONTINUE": "%1",
    "CONTROL_LOOP_BREAKCONTINUE_BREAK": "megszakítás",
    "CONTROL_LOOP_BREAKCONTINUE_CONTINUE": "folytatás",
    "CONTROL_WAIT_ESP32_MPY": "várj %1 %2",
    "CONTROL_WAIT_ESP32_MPY_S": "másodpercet",
    "CONTROL_WAIT_ESP32_MPY_MS": "ezredmásodperc (ms)",
    "CONTROL_WAIT_ESP32_MPY_US": "mikroszekundumot (us)",
    "CONTROL_TRUE_FALSE": "%1",
    "CONTROL_TRUE_FALSE_TRUE": "igaz",
    "CONTROL_TRUE_FALSE_FALSE": "hamis",
    "CONTROL_NONE": "egyik sem",
    "CONTROL_RETURN": "visszatérés %1",
    "OPERATORS_VALUE_IS_TRUE": "%1 érték igaz?",
    "OPERATORS_VALUE_TYPE": "%1 érték típusa",
    "CONTROL_TRY": "kipróbálás",
    "CONTROL_EXCEPT": "kivéve",
    "CONTROL_FINALLY": "végül",
    "OPERATORS_VALUE_TYPE_IS": "%1 típus értéke %2?",
    "OPERATORS_VALUE_TYPE_INT": "int",
    "OPERATORS_VALUE_TYPE_FLOAT": "float",
    "OPERATORS_VALUE_TYPE_BOOL": "bool",
    "OPERATORS_VALUE_TYPE_STR": "str",
    "OPERATORS_VALUE_TYPE_LIST": "list",
    "OPERATORS_VALUE_TYPE_TUPLE": "tuple",
    "OPERATORS_VALUE_TYPE_SET": "set",
    "OPERATORS_VALUE_TYPE_DICT": "dict",
    "OPERATORS_VALUE_TYPE_BYTES": "bytes",
    "OPERATORS_VALUE_TYPE_BYTEARRAY": "bytearray",
    "OPERATORS_VALUE_TYPE_COMPLEX": "complex",
    "OPERATORS_CACULATE_RESULT": "eval %1",
    "CANCEL": "Mégsem",
    "WIFI_VALIDATE": "A Wifi jelszónak 8 és 20 közötti karakterszámú betűkből és számokból kell állnia",
    "OPERATORS_NUM_INPUT": "%1 %2 szám %3",
    "OPERATORS_NUMS_CACULATE": "%1 %2 %3",
    "OPERATORS_MATHOP_SQUARE": "**",
    "OPERATORS_MATHOP_LOGIC_OR": "|",
    "OPERATORS_MATHOP_LOGIC_AND": "&",
    "OPERATORS_MATHOP_LOGIC_POWER": "^",
    "OPERATORS_MATHOP_LOGIC_LEFT": "<<",
    "OPERATORS_MATHOP_LOGIC_RIGHT": ">>",
    "OPERATORS_SPECIAL_NUM": "%1 %2 %3",
    "OPERATORS_MATHOP_PI": "π",
    "OPERATORS_MATHOP_LOGARITHM": "e",
    "OPERATORS_MATHOP_FAI": "φ",
    "OPERATORS_MATHOP_SQRT2": "sqrt(2)",
    "OPERATORS_MATHOP_SQRTHALF": "sqrt(½)",
    "OPERATORS_MATHOP_UNLIMITED": "∞",
    "OPERATORS_NUMS_TYPE": "%1 %2 %3 %4?",
    "OPERATORS_MATHOP_ISEVEN": "páros",
    "OPERATORS_MATHOP_ISODD": "páratlan",
    "OPERATORS_MATHOP_ISINT": "egész",
    "OPERATORS_MATHOP_ISPOSITIVE": "pozitív",
    "OPERATORS_MATHOP_ISNEGATIVE": "negatív",
    "OPERATORS_SAVE2DECIMAL": "%1 %2 %3 %4 tizedes jel mentése",
    "OPERATORS_LIST_CACULATE": "%1 %2 %3 %4",
    "OPERATORS_MATHOP_LIST_SUM": "számok összege a listában",
    "OPERATORS_MATHOP_LIST_MIN": "minimális érték a listában",
    "OPERATORS_MATHOP_LIST_MAX": "maximális érték a listában",
    "OPERATORS_MATHOP_LIST_AVERAGE": "átlagos érték a listában",
    "OPERATORS_MATHOP_LIST_NUM": "számjegyek száma a listában",
    "OPERATORS_MATHOP_LIST_MODE": "lista mód",
    "OPERATORS_MATHOP_LIST_STDDEVIATION": "szórás a listában",
    "OPERATORS_MATHOP_LIST_RANDOM": "véletlenszerű tárgylista",
    "OPERATORS_QUOTIENT_INT": "%1 %2 %3 ÷ %4 hányados egész része",
    "OPERATORS_RANDOM_DECIMAL": "%1 %2 véletlenszerű decimális",
    "OPERATORS_BIN_NEGATION": "%1 %2 bináris tagadás %3",
    "OPERATORS_INT_TO_STR": "%1 %2 %3 decimális egész %4 karakterlánccá konvertálása",
    "OPERATORS_MATHOP_INTTOSTR_BIN": "bináris",
    "OPERATORS_MATHOP_INTTOSTR_OCT": "octal",
    "OPERATORS_MATHOP_INTTOSTR_HEX": "hex",
    "OPERATORS_BINSTR_TO_INT": "%1 %2 %3 karakterlánc %4 decimális egésszé konvertálása",
    "OPERATORS_INT_TO_BYTE": "%1 %2 %3 decimális egész %4 bájttá konvertálása",
    "OPERATORS_MATHOP_INTTOBYTE_TWO": "2",
    "OPERATORS_MATHOP_INTTOBYTE_FOUR": "4",
    "OPERATORS_INT_TO_ASCII": "%1 %2  egész %3 ASCII karakterré konvertálása",
    "OPERATORS_ASCII_TO_INT": "%1 %2 ASCII karakter %3 egésszé konvertálása",
    "OPERATORS_STR_TO_INT": "%1 %2 karakterlánc %3  hex karakterlánccá konvertálása",
    "OPERATORS_HEXSTR_TO_BIN": "%1 %2 hex karakterlánc %3 bináris bájttá konvertálása",
    "OPERATORS_STR_TO_BASE64": "%1 %2 %3 base64 formátumba kódolása bájtobjektum visszaadásával",
    "OPERATORS_BASE64_TO_BYTE": "%1 %2 %3 base64 adat dekodolása bájtobjektum visszaadásával ",
    "OPERATORS_INPUTSTRING": "%1 %2 karakterlánc %3",
    "OPERATORS_TRANSINTOTEXT": "%1 %2 %3 való átalakítása",
    "OPERATORS_TRANSINTOTEXT_ADD": "hozzáad",
    "OPERATORS_TRANSINTOTEXT_ADD_ITEM": "tétel",
    "OPERATORS_FORMATSTRING2F": "%1 %2 %3 % karakterlánc formátum %4",
    "OPERATORS_FORMATSTRING3": "%1 %2 %3 karakterlánc formátum %4 formátum",
    "OPERATORS_TEXT_ESCAPE": "%1 %2 szövegfeloldójel %3",
    "BAR_N": "\\n",
    "BAR_NN": "\\n\\r",
    "BAR_R": "\\r",
    "BAR_RN": "\\r\\n",
    "BAR_B": "\\b",
    "BAR_T": "\\t",
    "BAR_BAR": "\\\\",
    "OPERATORS_TEXT_NUMORLETTER": "%1 %2 %3 %4?",
    "OPERATORS_TEXT_NUMORLETTER_NUM": "szám",
    "OPERATORS_TEXT_NUMORLETTER_LETTER": "betű",
    "OPERATORS_TEXT_LENGTH": "%1 %2 %3 hosszú",
    "OPERATORS_TEXT_ISNONE": "%1 %2 %3 semmi?",
    "OPERATORS_FROMTEXT_GETCHAR": "%1 %2 %3 %4 szövegben",
    "OPERATORS_FROMTEXT_GETCHAR_FIRST": "első betű",
    "OPERATORS_FROMTEXT_GETCHAR_LAST": "utolsó betű",
    "OPERATORS_FROMTEXT_GETCHAR_RANDOM": "véletlenszerű betű",
    "OPERATORS_TEXT_UPPLOW": "%1 %2 %3 %4",
    "OPERATORS_TEXT_UPPLOW_UPP": "nagybetű",
    "OPERATORS_TEXT_UPPLOW_LOW": "kisbetű",
    "OPERATORS_TEXT_SIDES_SPACE": "%1 %2 %3 %4",
    "OPERATORS_TEXT_SIDES_SPACE_BOTH": "szóköz törlése mind két oldalon",
    "OPERATORS_TEXT_SIDES_SPACE_LEFT": "szóköz törlése balra",
    "OPERATORS_TEXT_SIDES_SPACE_RIGHT": "szóköz törlése jobbra",
    "OPERATORS_TEXT_STRTOBYTE": "%1 %2 bájttá alakítja %3",
    "OPERATORS_TEXT_BYTETOSTR": "%1 %2 bájt %3 karakterlánccá konvertálása",
    "OPERATORS_TEXT_DICTTOJSONSTR": "%1 %2 dict %3 json karakterlánccá konvertlása",
    "OPERATORS_TEXT_PARSEJSONSTR": "%1 %2  json karakterlánc %3 elemzése és objektum visszaadása",
    "ONENETNEW": "OneNet(New)",
    "DEVICENAME": "Eszköznév",
    "CHECKTYPE_TIPS": "A beviteli doboztól érkezett adattípus nem egyezik a kimenet építőblokk típusával",
    "HIGHLIGHT_BLOCK": "blokkok kiemelése",
    "HIGHLIGHT_ALL_CONFLICT_BLOCKS": "minden figyelmeztetéssel ellátott blokk kiemelése",
    "SNAPSHOT": "Pillanatkép (Ctrl+G)",
    "pictureaiPath": "kérlek írd be a kép elérési útvonalát vagy URL címét",
    "pictureaiType": "felhasználó fajta",
    "pictureaiAccountDefault": "alap felhasználó",
    "pictureaiAccountBaidu": "baidu felhasználó",
    "pictureaiwebImgAddr": "webkép cím",
    "MITRIXICON_EDIT": "világít",
    "MITRIXICON_ERASER": "radír",
    "MITRIXICON_DUSTBIN": "tisztítás",
    "MITRIXICON_REVERSE": "megfordítás",
    "MITRIXICON_COLOR": "válassz színt",
    "RED": "piros",
    "GREEN": "zöld",
    "BLUE": "kék",
    "MITRIXICON_SAVE": "mentés",
    "MITRIXICON_EXPORT": "exportálás",
    "MITRIXICON_UPLOAD": "feltöltés",
    "ROLL_MOUSE": "Görgős egér",
    "MOUSE_ZOOM_IN_OUT": "Görgesd az egeret a nagyításhoz és kicsinyítéshez",
    "UPLOAD_CORRECT_JSON_FILE": "Kérjük töltsd fel a json fájlt a jelenlegi formátumban",
    "EXPORT_PROMPT": "Nem található exportálható saját minta. Kérjük kattints a mentés gombra a jobb alsó sarokban a saját minta mentéséhez, mielőtt megpróbálod exportálni.",
    "HEART": "Szív",
    "HEART_SMALL": "Kicsi szív",
    "HAPPY": "Boldog",
    "SMILE": "Mosolygós",
    "SAD": "Szomorú",
    "CONFUSED": "Összezavarodott",
    "ANGRY": "Mérges",
    "SLEEP": "Álmos",
    "SURPRISED": "Meglepődött",
    "SILLY": "Bolondos",
    "WONDERFUL": "Csodálatos",
    "BORED": "Unott",
    "ROCK": "Kő",
    "ROCK_SMALL": "Kicsi kő",
    "PAPER": "Lap",
    "PAPER_SMALL": "Kicsi lap",
    "SCISSORS": "Olló",
    "SCISSORS_SMALL": "Kicsi olló",
    "DATA_SOLITLIST": "%1 %2 make %3 %4 with delimiter %5",
    "DATA_SOLITLIST_TYPE_LT": "Alsó from/text5",
    "DATA_SOLITLIST_TYPE_TL": "Alsó from/list",
    "DATA_LENLIST": "%1 %2 %3 Alsó is/empty?",
    "DATA_LENGTHLIST": "%1 %2 length of %3",
    "DATA_CREATELIST": "%1 %2 create list %3",
    "DATA_CLEARLIST": "%1 %2 clear list %3",
    "ML5MODALBUTTON": "Alsó Visual/Interface",
    "COLLAPSE_BLOCK": "összecsukás",
    "EXPAND_BLOCK": "kinyitás",
    "COLLAPSE_ALL": "minden blokk összecsukása",
    "EXPAND_ALL": "minden blokk kinyitása"
  },
  "mi": {
    "CONTROL_FOREVER": "mō ake, ake mahia",
    "CONTROL_REPEAT": "tōaitia %1",
    "CONTROL_IF": "mēnā %1 mahia",
    "CONTROL_ELSE": "kē atu",
    "CONTROL_STOP": "katia",
    "CONTROL_STOP_ALL": "katoa",
    "CONTROL_STOP_THIS": "tēnei hōtuhi",
    "CONTROL_STOP_OTHER": "hōtuhi kē i roto i te parehe",
    "CONTROL_WAIT": "tatari kia %1 hēkona",
    "CONTROL_WAITUNTIL": "tatari kia %1",
    "CONTROL_REPEATUNTIL": "tōaitia tonutia kia %1",
    "CONTROL_WHILE": "tōaitia tonutia mēnā %1",
    "CONTROL_FOREACH": "mō ia %1 o roto i te %2",
    "CONTROL_STARTASCLONE": "ina ka tīmata mai ahau hei tārua",
    "CONTROL_CREATECLONEOF": "whakapūruatia %1",
    "CONTROL_CREATECLONEOF_MYSELF": "ahau anō",
    "CONTROL_DELETETHISCLONE": "mukua tēnei tāruarua",
    "CONTROL_COUNTER": "pūtatau",
    "CONTROL_INCRCOUNTER": "tāpiri tahi ki te pūtatau",
    "CONTROL_CLEARCOUNTER": "ūkui pūtatau",
    "CONTROL_ALLATONCE": "te katoa i te wā kotahi",
    "DATA_SETVARIABLETO": "tautuhia %1 ki te %2",
    "DATA_CHANGEVARIABLEBY": "panonitia %1 mā te %2",
    "DATA_SHOWVARIABLE": "whakaaturia te taurangi %1",
    "DATA_HIDEVARIABLE": "hunaia te taurangi %1",
    "DATA_ADDTOLIST": "tāpiritia te %1 ki te %2",
    "DATA_DELETEOFLIST": "mukua %1 i a %2",
    "DATA_DELETEALLOFLIST": "mukua te katoa o %1",
    "DATA_INSERTATLIST": "whakaurua te %1 hei %2 o %3",
    "DATA_REPLACEITEMOFLIST": "whakakapia te tūemi %1 o %2, ki %3",
    "DATA_ITEMOFLIST": "te tūemi %1 o %2",
    "DATA_ITEMNUMOFLIST": "te tūemi # %1 o %2",
    "DATA_LENGTHOFLIST": "te roanga o te %1",
    "DATA_LISTCONTAINSITEM": "kei roto i %1 te %2?",
    "DATA_SHOWLIST": "whakaaturia te rārangi %1",
    "DATA_HIDELIST": "hunāia te rārangi %1",
    "DATA_INDEX_ALL": "katoa",
    "DATA_INDEX_LAST": "whakamutunga",
    "DATA_INDEX_RANDOM": "matapōkere",
    "EVENT_WHENFLAGCLICKED": "ina pāwhiria te %1",
    "EVENT_WHENTHISSPRITECLICKED": "ina pāwhiria tēnei parehe",
    "EVENT_WHENSTAGECLICKED": "ina pāwhiria te Atamira",
    "EVENT_WHENTOUCHINGOBJECT": "ka pā tēnei parehe i te %1",
    "EVENT_WHENBROADCASTRECEIVED": "ina ka riro mai te %1",
    "EVENT_WHENBACKDROPSWITCHESTO": "ina hurihia te ārai tuarongo kia %1",
    "EVENT_WHENGREATERTHAN": "mēnā %1 > %2",
    "EVENT_WHENGREATERTHAN_TIMER": "taima",
    "EVENT_WHENGREATERTHAN_LOUDNESS": "hoihoi",
    "EVENT_BROADCAST": "whakapaoho %1",
    "EVENT_BROADCASTANDWAIT": "whakapaoho %1, kātahi tatari",
    "EVENT_WHENKEYPRESSED": "ina pēhia te pātuhi %1",
    "EVENT_WHENKEYPRESSED_SPACE": "mokowā",
    "EVENT_WHENKEYPRESSED_LEFT": "pere mauī",
    "EVENT_WHENKEYPRESSED_RIGHT": "pere katau",
    "EVENT_WHENKEYPRESSED_DOWN": "pere whakararo",
    "EVENT_WHENKEYPRESSED_UP": "pere whakarunga",
    "EVENT_WHENKEYPRESSED_ANY": "noa",
    "LOOKS_SAYFORSECS": "kīia %1 mō ngā hēkona %2",
    "LOOKS_SAY": "kīia %1",
    "LOOKS_HELLO": "Kia ora!",
    "LOOKS_THINKFORSECS": "whakaarohia te %1 mō ngā hēkona %2",
    "LOOKS_THINK": "whakaarohia te %1",
    "LOOKS_HMM": "Āa...",
    "LOOKS_SHOW": "whakaatu",
    "LOOKS_HIDE": "huna",
    "LOOKS_HIDEALLSPRITES": "hunaia ngā parehe katoa",
    "LOOKS_EFFECT_COLOR": "tae",
    "LOOKS_EFFECT_FISHEYE": "karu ika",
    "LOOKS_EFFECT_WHIRL": "kōriporipo",
    "LOOKS_EFFECT_PIXELATE": "whakatongitongi",
    "LOOKS_EFFECT_MOSAIC": "toi rōpinepine",
    "LOOKS_EFFECT_BRIGHTNESS": "pīataata",
    "LOOKS_EFFECT_GHOST": "kēhua",
    "LOOKS_CHANGEEFFECTBY": "panonitia te rākeitanga %1 mā te %2",
    "LOOKS_SETEFFECTTO": "panonitia te rākeitanga %1 ki te %2",
    "LOOKS_CLEARGRAPHICEFFECTS": "ūkui rākeitanga whakairoiro",
    "LOOKS_CHANGESIZEBY": "panonitia te rahi mā te %1",
    "LOOKS_SETSIZETO": "tautuhia te rahi kia %1 %",
    "LOOKS_SIZE": "rahi",
    "LOOKS_CHANGESTRETCHBY": "panonitia te toronga mā te %1",
    "LOOKS_SETSTRETCHTO": "tautuhia te toronga kia %1 %",
    "LOOKS_SWITCHCOSTUMETO": "panonitia te kākahu kia %1",
    "LOOKS_NEXTCOSTUME": "kākahu panuku",
    "LOOKS_SWITCHBACKDROPTO": "panonitia te ārai tuarongo kia %1",
    "LOOKS_GOTOFRONTBACK": "haere ki te apa %1",
    "LOOKS_GOTOFRONTBACK_FRONT": "mua",
    "LOOKS_GOTOFRONTBACK_BACK": "muri",
    "LOOKS_GOFORWARDBACKWARDLAYERS": "nekehia %1, kia %2 ngā apaapa",
    "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "ki mua",
    "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "ki muri",
    "LOOKS_BACKDROPNUMBERNAME": "ārai tuarongo %1",
    "LOOKS_COSTUMENUMBERNAME": "kākahu %1",
    "LOOKS_NUMBERNAME_NUMBER": "nama",
    "LOOKS_NUMBERNAME_NAME": "ingoa",
    "LOOKS_SWITCHBACKDROPTOANDWAIT": "panonitia te ārai tuarongo kia %1, kātahi, tatari",
    "LOOKS_NEXTBACKDROP_BLOCK": "ārai tuarongo panuku",
    "LOOKS_NEXTBACKDROP": "ārai tuarongo panuku",
    "LOOKS_PREVIOUSBACKDROP": "ārai tuarongo o mua",
    "LOOKS_RANDOMBACKDROP": "ārai tuarongo matapōkere",
    "MOTION_MOVESTEPS": "nekehia kia %1 ngā takahanga",
    "MOTION_TURNLEFT": "hurihia %1, kia %2 putu",
    "MOTION_TURNRIGHT": "hurihia %1, kia %2 putu",
    "MOTION_POINTINDIRECTION": "atatohua ki %1",
    "MOTION_POINTTOWARDS": "atatohua ki %1",
    "MOTION_POINTTOWARDS_POINTER": "atatohu-kiore",
    "MOTION_POINTTOWARDS_RANDOM": "ahunga matapōkere",
    "MOTION_GOTO": "neke ki %1",
    "MOTION_GOTO_POINTER": "atatohu-kiore",
    "MOTION_GOTO_RANDOM": "tūnga matapōkere",
    "MOTION_GOTOXY": "neke ki x: %1 y: %2",
    "MOTION_GLIDESECSTOXY": "wania kia %1 hēkona ki x: %2 y: %3",
    "MOTION_GLIDETO": "wania kia %1 hēkona ki %2",
    "MOTION_GLIDETO_POINTER": "atatohu-kiore",
    "MOTION_GLIDETO_RANDOM": "tūnga matapōkere",
    "MOTION_CHANGEXBY": "panonitia x mā te %1",
    "MOTION_SETX": "tautuhia te x kia %1",
    "MOTION_CHANGEYBY": "panonitia y mā te %1",
    "MOTION_SETY": "tautuhia te y kia %1",
    "MOTION_IFONEDGEBOUNCE": "mēnā kei te taitapa, tupanatia",
    "MOTION_SETROTATIONSTYLE": "tautuhia te momo huringa kia %1",
    "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "mauī-ki-katau",
    "MOTION_SETROTATIONSTYLE_DONTROTATE": "kaua e takahuri",
    "MOTION_SETROTATIONSTYLE_ALLAROUND": "huri noa",
    "MOTION_XPOSITION": "tūnga x",
    "MOTION_YPOSITION": "tūnga y",
    "MOTION_DIRECTION": "ahunga",
    "MOTION_SCROLLRIGHT": "panuku whakatekatau %1",
    "MOTION_SCROLLUP": "panuku whakarunga %1",
    "MOTION_ALIGNSCENE": "tīaro kāpeka %1",
    "MOTION_ALIGNSCENE_BOTTOMLEFT": "taha mauī o raro",
    "MOTION_ALIGNSCENE_BOTTOMRIGHT": "taha katau o raro",
    "MOTION_ALIGNSCENE_MIDDLE": "waenga",
    "MOTION_ALIGNSCENE_TOPLEFT": "taha mauī o runga",
    "MOTION_ALIGNSCENE_TOPRIGHT": "taha katau o runga",
    "MOTION_XSCROLL": "panuku x",
    "MOTION_YSCROLL": "panuku y",
    "MOTION_STAGE_SELECTED": "Kua tīpakongia te atamira: kāore he paraka nekeneke",
    "OPERATORS_ADD": "%1 + %2",
    "OPERATORS_SUBTRACT": "%1 - %2",
    "OPERATORS_MULTIPLY": "%1 * %2",
    "OPERATORS_DIVIDE": "%1 / %2",
    "OPERATORS_RANDOM": "kōwhiria matapōkere %1 ki te %2",
    "OPERATORS_GT": "%1 > %2",
    "OPERATORS_GT_EQUALS": "",
    "OPERATORS_LT": "%1 < %2",
    "OPERATORS_LT_EQUALS": "",
    "OPERATORS_EQUALS": "%1 = %2",
    "OPERATORS_AND": "%1 me %2",
    "OPERATORS_OR": "%1, %2 rānei",
    "OPERATORS_NOT": "ehara ko te %1",
    "OPERATORS_JOIN": "tāpiritia te %1, %2",
    "OPERATORS_JOIN_APPLE": "āporo",
    "OPERATORS_JOIN_BANANA": "panana",
    "OPERATORS_LETTEROF": "te pū %1 o te %2",
    "OPERATORS_LETTEROF_APPLE": "he",
    "OPERATORS_LENGTH": "te roanga o te %1",
    "OPERATORS_CONTAINS": "kei roto i a %1 : %2?",
    "OPERATORS_MOD": "%1 % %2",
    "OPERATORS_ROUND": "whakaawhiwhi %1",
    "OPERATORS_MATHOP": "%1 o te %2",
    "OPERATORS_MATHOP_ABS": "abs",
    "OPERATORS_MATHOP_FLOOR": "papa",
    "OPERATORS_MATHOP_CEILING": "tuanui",
    "OPERATORS_MATHOP_SQRT": "sqrt",
    "OPERATORS_MATHOP_SIN": "sin",
    "OPERATORS_MATHOP_COS": "cos",
    "OPERATORS_MATHOP_TAN": "tan",
    "OPERATORS_MATHOP_ASIN": "asin",
    "OPERATORS_MATHOP_ACOS": "acos",
    "OPERATORS_MATHOP_ATAN": "atan",
    "OPERATORS_MATHOP_LN": "ln",
    "OPERATORS_MATHOP_LOG": "log",
    "OPERATORS_MATHOP_EEXP": "e ^",
    "OPERATORS_MATHOP_10EXP": "10 ^",
    "PROCEDURES_DEFINITION": "tautuhia %1",
    "SENSING_TOUCHINGOBJECT": "kei te pā ki te %1?",
    "SENSING_TOUCHINGOBJECT_POINTER": "atatohu-kiore",
    "SENSING_TOUCHINGOBJECT_EDGE": "taitapa",
    "SENSING_TOUCHINGCOLOR": "kei te pā ki te tae %1?",
    "SENSING_COLORISTOUCHINGCOLOR": "ka pā te tae %1 i te %2?",
    "SENSING_DISTANCETO": "tawhiti ki %1",
    "SENSING_DISTANCETO_POINTER": "atatohu-kiore",
    "SENSING_ASKANDWAIT": "pātai %1, kātahi, tatari",
    "SENSING_ASK_TEXT": "Ko wai tō ingoa?",
    "SENSING_ANSWER": "whakautu",
    "SENSING_KEYPRESSED": "kua pēhia te pātuhi %1?",
    "SENSING_MOUSEDOWN": "pāwhiri kiore?",
    "SENSING_MOUSEX": "x kiore",
    "SENSING_MOUSEY": "y kiore",
    "SENSING_SETDRAGMODE": "tautuhia te aratau tō kia %1",
    "SENSING_SETDRAGMODE_DRAGGABLE": "ka taea te tō",
    "SENSING_SETDRAGMODE_NOTDRAGGABLE": "kāore e taea te tō",
    "SENSING_LOUDNESS": "hoihoi",
    "SENSING_LOUD": "hoihoi?",
    "SENSING_TIMER": "taima",
    "SENSING_RESETTIMER": "tautuhia anōtia te taima",
    "SENSING_OF": "%1 o %2",
    "SENSING_OF_XPOSITION": "tūnga x",
    "SENSING_OF_YPOSITION": "tūnga y",
    "SENSING_OF_DIRECTION": "ahunga",
    "SENSING_OF_COSTUMENUMBER": "kākahu #",
    "SENSING_OF_COSTUMENAME": "ingoa kākahu",
    "SENSING_OF_SIZE": "rahi",
    "SENSING_OF_VOLUME": "kahaoro",
    "SENSING_OF_BACKDROPNUMBER": "ārai tuarongo #",
    "SENSING_OF_BACKDROPNAME": "ingoa ārai tuarongo",
    "SENSING_OF_STAGE": "Atamira",
    "SENSING_CURRENT": "%1 o nāianei",
    "SENSING_CURRENT_YEAR": "tau",
    "SENSING_CURRENT_MONTH": "marama",
    "SENSING_CURRENT_DATE": "te rā",
    "SENSING_CURRENT_DAYOFWEEK": "te rangi o te wiki",
    "SENSING_CURRENT_HOUR": "haora",
    "SENSING_CURRENT_MINUTE": "miniti",
    "SENSING_CURRENT_SECOND": "hēkona",
    "SENSING_DAYSSINCE2000": "ngā rā mai i 2000",
    "SENSING_USERNAME": "ingoa kaiwhakamahi",
    "SENSING_USERID": "tohu tuakiri",
    "SOUND_PLAY": "tīmatahia te whakatangi i te oro %1",
    "SOUND_PLAYUNTILDONE": "whakatangihia te oro %1 kia mutu rawa",
    "SOUND_STOPALLSOUNDS": "katia ngā oro katoa",
    "SOUND_SETEFFECTO": "tautuhia te rākeitanga %1 kia %2",
    "SOUND_CHANGEEFFECTBY": "panonitia te rākeitanga %1 mā te %2",
    "SOUND_CLEAREFFECTS": "ūkui rākeitanga orotaunaki",
    "SOUND_EFFECTS_PITCH": "hauoro",
    "SOUND_EFFECTS_PAN": "huri whakatemauī/whakatekatau",
    "SOUND_CHANGEVOLUMEBY": "panonitia te kahaoro mā te %1",
    "SOUND_SETVOLUMETO": "tautuhia te kahaoro kia %1%",
    "SOUND_VOLUME": "kahaoro",
    "SOUND_RECORD": "hopukina...",
    "CATEGORY_MOTION": "Nekehanga",
    "CATEGORY_LOOKS": "Āhua",
    "CATEGORY_SOUND": "Oro",
    "CATEGORY_EVENTS": "Takahanga",
    "CATEGORY_CONTROL": "Whakatina",
    "CATEGORY_SENSING": "Paerongo",
    "CATEGORY_OPERATORS": "Tohutūmahi",
    "CATEGORY_VARIABLES": "Ngā Taurangi",
    "CATEGORY_MYBLOCKS": "Aku Paraka",
    "CATEGORY_LISTS": "",
    "CATEGORY_TUPLE": "",
    "CATEGORY_DICTIONARYS": "",
    "CATEGORY_NUMBERS": "",
    "CATEGORY_TEXT": "",
    "DUPLICATE": "Tāruatia",
    "DELETE": "Mukua",
    "ADD_COMMENT": "Tāpiri Tākupu",
    "REMOVE_COMMENT": "Mukua te Tākupu",
    "DELETE_BLOCK": "Mukua te Paraka",
    "DELETE_X_BLOCKS": "Mukua %1 Paraka",
    "DELETE_ALL_BLOCKS": "Mukua te katoa o ngā paraka e %1 ?",
    "CLEAN_UP": "Whakatikatika i ngā Paraka",
    "HELP": "Āwhina",
    "UNDO": "Wetekia",
    "REDO": "Mahia anōtia",
    "EDIT_PROCEDURE": "Whakatikaina",
    "SHOW_PROCEDURE_DEFINITION": "Haere ki te tautuhinga",
    "WORKSPACE_COMMENT_DEFAULT_TEXT": "Kīia tētahi mea...",
    "COLOUR_HUE_LABEL": "Tae",
    "COLOUR_SATURATION_LABEL": "Waiwai",
    "COLOUR_BRIGHTNESS_LABEL": "Pīataata",
    "CHANGE_VALUE_TITLE": "Panonitia te uara:",
    "RENAME_VARIABLE": "Tapaina anōtia te taurangi",
    "RENAME_VARIABLE_STRING": "",
    "RENAME_VARIABLE_NUMBER": "",
    "RENAME_VARIABLE_LIST": "",
    "RENAME_VARIABLE_TITLE": "Tapaina anōtia te katoa o ngā taurangi \"%1\" ki:",
    "RENAME_VARIABLE_MODAL_TITLE": "Tapaina anōtia te Taurangi",
    "NEW_VARIABLE": "Hangaia tētahi taurangi",
    "NEW_VARIABLE_TITLE": "Ingoa taurangi hou:",
    "VARIABLE_MODAL_TITLE": "Taurangi Hou",
    "VARIABLE_ALREADY_EXISTS": "Kua hangaia kētia tētahi taurangi, ko \"%1\" te ingoa.",
    "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "Kua hangaia kētia tētahi taurangi, ko \"%1\"  te ingoa, engari he momo \"%2\" tērā.",
    "DELETE_VARIABLE_CONFIRMATION": "Mukua hoki %1 ngā mahinga o te taurangi \"%2\"?",
    "CANNOT_DELETE_VARIABLE_PROCEDURE": "Kāore e taea te muku i te taurangi \"%1\", nā te mea he wāhanga taua taurangi o te tautuhinga mō te hātepe \"%2\"",
    "DELETE_VARIABLE": "Mukua te taurangi \"%1\"",
    "DELETE_VARIABLE_STRING": "",
    "DELETE_VARIABLE_NUMBER": "",
    "DELETE_VARIABLE_LIST": "",
    "NEW_PROCEDURE": "Hangaia tētahi Paraka",
    "PROCEDURE_ALREADY_EXISTS": "Kua hangaia kētia tētahi hātepe ko \"%1\" te ingoa.",
    "PROCEDURE_DEFAULT_NAME": "ingoa paraka",
    "NEW_LIST": "Hangaia tētahi Rārangi",
    "NEW_LIST_TITLE": "Ingoa rārangi hou:",
    "LIST_MODAL_TITLE": "Rārangi Hou",
    "LIST_ALREADY_EXISTS": "Kua hangaia kētia tētahi rārangi ko \"%1\" te ingoa.",
    "RENAME_LIST_TITLE": "Tapaina anōtia te katoa o ngā rārangi \"%1\" ki:",
    "RENAME_LIST_MODAL_TITLE": "Tapaina te Rārangi",
    "DEFAULT_LIST_ITEM": "mea",
    "DELETE_LIST": "Mukua te rārangi \"%1\"",
    "RENAME_LIST": "Tapaina anōtia te Rārangi",
    "NEW_BROADCAST_MESSAGE": "Karere hou",
    "NEW_BROADCAST_MESSAGE_TITLE": "Ingoa karere hou:",
    "BROADCAST_MODAL_TITLE": "Karere Hou",
    "DEFAULT_BROADCAST_MESSAGE_NAME": "karere1",
    "DELETE_PROCEDURE": "",
    "OK": "",
    "PROMPT": "",
    "STOP": "",
    "NEW_STRING": "",
    "NEW_NUMBER_VARIABLE": "",
    "WIFI": "",
    "USERNAME": "",
    "PWD": "",
    "IOT_SERVICE": "",
    "IOT_ID": "",
    "IOT_PWD": "",
    "IOT_TOPIC": "",
    "MORE_TOPIC": "",
    "OBLOQ_HTTP": "",
    "OBLOQ_IP": "",
    "OBLOQ_PORT": "",
    "ADD_TOPIC": "",
    "DELETE_TOPIC": "",
    "IOT_SERVER": "",
    "CHINA": "",
    "GLOBAL": "",
    "LOW_C": "",
    "LOW_C$": "",
    "LOW_D": "",
    "LOW_D$": "",
    "LOW_E": "",
    "LOW_F": "",
    "LOW_F$": "",
    "LOW_G": "",
    "LOW_G$": "",
    "LOW_A": "",
    "LOW_A$": "",
    "LOW_B": "",
    "MIDDLE_C": "",
    "MIDDLE_C$": "",
    "MIDDLE_D": "",
    "MIDDLE_D$": "",
    "MIDDLE_E": "",
    "MIDDLE_F": "",
    "MIDDLE_F$": "",
    "MIDDLE_G": "",
    "MIDDLE_G$": "",
    "MIDDLE_A": "",
    "MIDDLE_A$": "",
    "MIDDLE_B": "",
    "HIGH_C": "",
    "HIGH_C$": "",
    "HIGH_D": "",
    "HIGH_D$": "",
    "HIGH_E": "",
    "HIGH_F": "",
    "HIGH_F$": "",
    "HIGH_G": "",
    "HIGH_G$": "",
    "HIGH_A": "",
    "HIGH_A$": "",
    "HIGH_B": "",
    "OPERATORS_STRINGTONUMBER": "",
    "INTEGER": "",
    "DECIMAL": "",
    "OPERATORS_NUMTOASCII": "",
    "OPERATORS_STRINGTOASCII": "",
    "OPERATORS_NUMTOSTRING": "",
    "OPERATORS_MAP": "",
    "OPERATORS_CONSTRAIN": "",
    "STEPS_PER_TURN": "",
    "ROTATE_SPEED": "",
    "NEW_AI": "",
    "ASK_AND_PAINT": "",
    "GET_IDENTIFIED_NUM": "",
    "READ_NUM_AND_SAY": "",
    "IMAGE_ADDR": "",
    "IMAGE_PREVIEW": "",
    "IMAGE_OPEN": "",
    "IMAGE_SIZE": "",
    "IMAGE_WIDTH": "",
    "IMAGE_HEIGHT": "",
    "VARIABLE": "",
    "VARIABLE_LIST": "",
    "SET_STRING_VARIABLE": "",
    "STRING_START_WITH": "",
    "DICTIONARY_HEIGHT_FIRST": "",
    "DICTIONARY_HEIGHT_TWO": "",
    "DICTIONARY_HEIGHT_THREE": "",
    "DICTIONARY_HEIGHT_FOUR": "",
    "DATA_MPITEMOFLIST": "",
    "DATA_MPINTERCEPTITEM": "",
    "DATA_MPINSERTATLIST": "",
    "DATA_MPSETVALUEATLIST": "",
    "DATA_MPINSERTATLIST_INSERT": "",
    "DATA_MPINSERTATLIST_CHANGE": "",
    "DATA_MPINSERTATLIST_FOUR": "",
    "DATA_MPINSERTATLIST_FIVE": "",
    "DATA_MPINSERTATLIST_SIX": "",
    "DATA_MPINSERTATLIST_SEVEN": "",
    "DATA_MPINSERTATLIST_EIGHT": "",
    "DATA_MPINSERTATLIST_EIGHT_FALSE": "",
    "DATA_MPINSERTATLIST_EIGHT_TRUE": "",
    "DATA_MPINSERTATLIST_EIGHT_REVERSE": "",
    "OPERATORS_RANDOMA": "",
    "OPERATORS_MODA": "",
    "OPERATORS_ROUNDA": "",
    "OPERATORS_MATHOPA": "",
    "OPERATORS_MATHOP_ABSA": "",
    "OPERATORS_MATHOP_FLOORA": "",
    "OPERATORS_MATHOP_CEILINGA": "",
    "OPERATORS_MATHOP_SQRTA": "",
    "OPERATORS_MATHOP_FIVE": "",
    "OPERATORS_MATHOP_SIX": "",
    "OPERATORS_MATHOP_SEVEN": "",
    "OPERATORS_MATHOP_LENGHT": "",
    "OPERATORS_MATHOP_MAXMIN": "",
    "OPERATORS_MATHOP_MAXMIN_MAX": "",
    "OPERATORS_MATHOP_MAXMIN_MIN": "",
    "OPERATORS_TEXTS_ONE": "",
    "OPERATORS_TEXTS_TWO": "",
    "OPERATORS_TEXTS_THREE": "",
    "OPERATORS_TEXTS_FOUR": "",
    "OPERATORS_TEXTS_FOUR_NEW": "",
    "OPERATORS_TEXTS_NEW": "",
    "OPERATORS_TEXTS_NEW_OP": "",
    "OPERATORS_TEXTS_FOUR_ONE": "",
    "OPERATORS_TEXTS_FOUR_TWO": "",
    "OPERATORS_TEXTS_FOUR_THREE": "",
    "OPERATORS_TEXTS_FOUR_FOUR": "",
    "OPERATORS_TEXTS_FOUR_F": "",
    "OPERATORS_TEXTS_FOUR_L": "",
    "OPERATORS_STRINGTONUMBERA": "",
    "OPERATORS_NUMTOASCIIA": "",
    "OPERATORS_STRINGTOASCIIA": "",
    "OPERATORS_NUMTOSTRINGA": "",
    "NEW_MC": "",
    "RENAME_MCNUMBER_TITLE": "",
    "RENAME_MCNUMBER_MODAL_TITLE": "",
    "RENAME_VARIABLE_MCNUMBER": "",
    "DELETE_VARIABLE_MCNUMBER": "",
    "SET_MC_VARIABLE": "",
    "TUPLE_ONE": "",
    "TUPLE_TWO": "",
    "ALIYUN": "",
    "ONENET": "",
    "EASYIOT": "",
    "SHANGHAI": "",
    "QINGDAO": "",
    "BEIJING": "",
    "ZHANGJIAKOU": "",
    "HUHEHAOTE": "",
    "HANGZHOU": "",
    "SHENZHEN": "",
    "HONGKONG": "",
    "SINGAPORE": "",
    "SYDNEY": "",
    "KUALALUMPUR": "",
    "JAKARTA": "",
    "MUMBAI": "",
    "TOKYO": "",
    "SILICONVALLEY": "",
    "VIRGINIA": "",
    "FRANKFURT": "",
    "LONDON": "",
    "DUBAI": "",
    "IOT_PLATFORM": "",
    "PARAMS": "",
    "SERVER_ATTR": "",
    "PRODUCTID": "",
    "DEVICEID": "",
    "TINYDB_SECRET": "",
    "TINYDB_USER": "",
    "APIADDR": "",
    "SOFTWARE_SERIAL": "",
    "HARDWARE_SERIAL": "",
    "HARDWARE_SERIAL1": "",
    "HARDWARE_SERIAL2": "",
    "HARDWARE_SERIAL3": ""
  },
  "nl": {
    "CONTROL_FOREVER": "herhaal",
    "CONTROL_REPEAT": "herhaal %1",
    "CONTROL_IF": "als %1 dan",
    "CONTROL_ELSE": "anders",
    "CONTROL_STOP": "stop",
    "CONTROL_STOP_ALL": "alle",
    "CONTROL_STOP_THIS": "dit script",
    "CONTROL_STOP_OTHER": "andere scripts in sprite",
    "CONTROL_WAIT": "wacht %1 sec.",
    "CONTROL_WAITUNTIL": "wacht tot %1",
    "CONTROL_REPEATUNTIL": "herhaal tot %1",
    "CONTROL_WHILE": "zolang %1",
    "CONTROL_FOREACH": "voor elke %1 in %2",
    "CONTROL_STARTASCLONE": "wanneer ik als kloon start",
    "CONTROL_CREATECLONEOF": "maak een kloon van %1",
    "CONTROL_CREATECLONEOF_MYSELF": "mijzelf",
    "CONTROL_DELETETHISCLONE": "verwijder deze kloon",
    "CONTROL_COUNTER": "teller",
    "CONTROL_INCRCOUNTER": "verhoog teller",
    "CONTROL_CLEARCOUNTER": "zet teller op 0",
    "CONTROL_ALLATONCE": "alles in een keer",
    "DATA_SETVARIABLETO": "maak %1 %2",
    "DATA_CHANGEVARIABLEBY": "verander %1 met %2",
    "DATA_SHOWVARIABLE": "toon variabele %1",
    "DATA_HIDEVARIABLE": "verberg variabele %1",
    "DATA_ADDTOLIST": "voeg %1 toe aan %2",
    "DATA_DELETEOFLIST": "verwijder %1 van %2",
    "DATA_DELETEALLOFLIST": "verwijder alle van %1",
    "DATA_INSERTATLIST": "voeg %1 toe op %2 van %3",
    "DATA_REPLACEITEMOFLIST": "vervang item %1 van %2 door %3",
    "DATA_ITEMOFLIST": "item %1 van %2",
    "DATA_ITEMNUMOFLIST": "item # van %1 in %2",
    "DATA_LENGTHOFLIST": "lengte van %1",
    "DATA_LISTCONTAINSITEM": "%1 bevat %2?",
    "DATA_SHOWLIST": "toon lijst %1",
    "DATA_HIDELIST": "verberg lijst %1",
    "DATA_INDEX_ALL": "alle",
    "DATA_INDEX_LAST": "laatste",
    "DATA_INDEX_RANDOM": "willekeurig",
    "EVENT_WHENFLAGCLICKED": "wanneer op %1 wordt geklikt",
    "EVENT_WHENTHISSPRITECLICKED": "wanneer op deze sprite wordt geklikt",
    "EVENT_WHENSTAGECLICKED": "wanneer op het speelveld wordt geklikt",
    "EVENT_WHENTOUCHINGOBJECT": "wanneer deze sprite %1 raakt",
    "EVENT_WHENBROADCASTRECEIVED": "wanneer ik signaal %1 ontvang",
    "EVENT_WHENBACKDROPSWITCHESTO": "wanneer achtergrond verandert naar %1",
    "EVENT_WHENGREATERTHAN": "wanneer %1 > %2",
    "EVENT_WHENGREATERTHAN_TIMER": "klok",
    "EVENT_WHENGREATERTHAN_LOUDNESS": "volume",
    "EVENT_BROADCAST": "zend signaal %1",
    "EVENT_BROADCASTANDWAIT": "zend signaal %1 en wacht",
    "EVENT_WHENKEYPRESSED": "wanneer %1 is ingedrukt",
    "EVENT_WHENKEYPRESSED_SPACE": "spatiebalk",
    "EVENT_WHENKEYPRESSED_LEFT": "pijltje links",
    "EVENT_WHENKEYPRESSED_RIGHT": "pijltje rechts",
    "EVENT_WHENKEYPRESSED_DOWN": "pijltje omlaag",
    "EVENT_WHENKEYPRESSED_UP": "pijltje omhoog",
    "EVENT_WHENKEYPRESSED_ANY": "willekeurig",
    "LOOKS_SAYFORSECS": "zeg %1 %2 sec.",
    "LOOKS_SAY": "zeg %1",
    "LOOKS_HELLO": "Hallo!",
    "LOOKS_THINKFORSECS": "denk %1 %2 sec.",
    "LOOKS_THINK": "denk %1",
    "LOOKS_HMM": "Hmm...",
    "LOOKS_SHOW": "verschijn",
    "LOOKS_HIDE": "verdwijn",
    "LOOKS_HIDEALLSPRITES": "verberg alle sprites",
    "LOOKS_EFFECT_COLOR": "kleur",
    "LOOKS_EFFECT_FISHEYE": "vissenoog",
    "LOOKS_EFFECT_WHIRL": "draaikolk",
    "LOOKS_EFFECT_PIXELATE": "pixeleren",
    "LOOKS_EFFECT_MOSAIC": "mozaïek",
    "LOOKS_EFFECT_BRIGHTNESS": "helderheid",
    "LOOKS_EFFECT_GHOST": "transparant",
    "LOOKS_CHANGEEFFECTBY": "verander %1 effect met %2",
    "LOOKS_SETEFFECTTO": "zet %1 effect op %2",
    "LOOKS_CLEARGRAPHICEFFECTS": "zet alle effecten uit",
    "LOOKS_CHANGESIZEBY": "verander grootte met %1",
    "LOOKS_SETSIZETO": "maak grootte %1 %",
    "LOOKS_SIZE": "grootte",
    "LOOKS_CHANGESTRETCHBY": "verander stretch met %1",
    "LOOKS_SETSTRETCHTO": "maak stretch %1 %",
    "LOOKS_SWITCHCOSTUMETO": "verander uiterlijk naar %1",
    "LOOKS_NEXTCOSTUME": "volgend uiterlijk",
    "LOOKS_SWITCHBACKDROPTO": "verander achtergrond naar %1",
    "LOOKS_GOTOFRONTBACK": "ga naar laag %1",
    "LOOKS_GOTOFRONTBACK_FRONT": "voorgrond",
    "LOOKS_GOTOFRONTBACK_BACK": "achtergrond",
    "LOOKS_GOFORWARDBACKWARDLAYERS": "ga %1 %2 lagen",
    "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "naar voren",
    "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "naar achteren",
    "LOOKS_BACKDROPNUMBERNAME": "achtergrond %1",
    "LOOKS_COSTUMENUMBERNAME": "uiterlijk %1",
    "LOOKS_NUMBERNAME_NUMBER": "nummer",
    "LOOKS_NUMBERNAME_NAME": "naam",
    "LOOKS_SWITCHBACKDROPTOANDWAIT": "verander achtergrond naar %1 en wacht",
    "LOOKS_NEXTBACKDROP_BLOCK": "volgende achtergrond",
    "LOOKS_NEXTBACKDROP": "volgende achtergrond",
    "LOOKS_PREVIOUSBACKDROP": "vorige achtergrond",
    "LOOKS_RANDOMBACKDROP": "willekeurige achtergrond",
    "MOTION_MOVESTEPS": "neem %1 stappen",
    "MOTION_TURNLEFT": "draai %1 %2 graden",
    "MOTION_TURNRIGHT": "draai %1 %2 graden",
    "MOTION_POINTINDIRECTION": "richt naar %1 graden",
    "MOTION_POINTTOWARDS": "richt naar %1",
    "MOTION_POINTTOWARDS_POINTER": "muisaanwijzer",
    "MOTION_POINTTOWARDS_RANDOM": "willekeurige richting",
    "MOTION_GOTO": "ga naar %1",
    "MOTION_GOTO_POINTER": "muisaanwijzer",
    "MOTION_GOTO_RANDOM": "willekeurige positie",
    "MOTION_GOTOXY": "ga naar x: %1 y: %2",
    "MOTION_GLIDESECSTOXY": "schuif in %1 sec. naar x: %2 y: %3",
    "MOTION_GLIDETO": "schuif in %1 sec. naar %2",
    "MOTION_GLIDETO_POINTER": "muisaanwijzer",
    "MOTION_GLIDETO_RANDOM": "willekeurige positie",
    "MOTION_CHANGEXBY": "verander x met %1",
    "MOTION_SETX": "maak x %1",
    "MOTION_CHANGEYBY": "verander y met %1",
    "MOTION_SETY": "maak y %1",
    "MOTION_IFONEDGEBOUNCE": "keer om aan de rand",
    "MOTION_SETROTATIONSTYLE": "maak draaistijl %1",
    "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "links-rechts",
    "MOTION_SETROTATIONSTYLE_DONTROTATE": "niet draaien",
    "MOTION_SETROTATIONSTYLE_ALLAROUND": "helemaal rond",
    "MOTION_XPOSITION": "x-positie",
    "MOTION_YPOSITION": "y-positie",
    "MOTION_DIRECTION": "richting",
    "MOTION_SCROLLRIGHT": "rechts scrollen %1",
    "MOTION_SCROLLUP": "omhoog scrollen %1",
    "MOTION_ALIGNSCENE": "scène uitlijnen %1",
    "MOTION_ALIGNSCENE_BOTTOMLEFT": "linksonder",
    "MOTION_ALIGNSCENE_BOTTOMRIGHT": "rechtsonder",
    "MOTION_ALIGNSCENE_MIDDLE": "midden",
    "MOTION_ALIGNSCENE_TOPLEFT": "linksboven",
    "MOTION_ALIGNSCENE_TOPRIGHT": "rechtsboven",
    "MOTION_XSCROLL": "x scroll",
    "MOTION_YSCROLL": "y scroll",
    "MOTION_STAGE_SELECTED": "Speelveld geselecteerd: geen bewegingsblokken",
    "OPERATORS_ADD": "%1 + %2",
    "OPERATORS_SUBTRACT": "%1 - %2",
    "OPERATORS_MULTIPLY": "%1 * %2",
    "OPERATORS_DIVIDE": "%1 / %2",
    "OPERATORS_RANDOM": "willekeurig getal tussen %1 en %2",
    "OPERATORS_GT": "%1 > %2",
    "OPERATORS_GT_EQUALS": "",
    "OPERATORS_LT": "%1 < %2",
    "OPERATORS_LT_EQUALS": "",
    "OPERATORS_EQUALS": "%1 = %2",
    "OPERATORS_AND": "%1 en %2",
    "OPERATORS_OR": "%1 of %2",
    "OPERATORS_NOT": "niet %1",
    "OPERATORS_JOIN": "voeg %1 en %2 samen",
    "OPERATORS_JOIN_APPLE": "appel",
    "OPERATORS_JOIN_BANANA": "banaan",
    "OPERATORS_LETTEROF": "letter %1 van %2",
    "OPERATORS_LETTEROF_APPLE": "a",
    "OPERATORS_LENGTH": "lengte van %1",
    "OPERATORS_CONTAINS": "%1 bevat %2?",
    "OPERATORS_MOD": "%1 modulo %2",
    "OPERATORS_ROUND": "afgerond %1",
    "OPERATORS_MATHOP": "%1 van %2",
    "OPERATORS_MATHOP_ABS": "absoluut",
    "OPERATORS_MATHOP_FLOOR": "beneden",
    "OPERATORS_MATHOP_CEILING": "boven",
    "OPERATORS_MATHOP_SQRT": "wortel",
    "OPERATORS_MATHOP_SIN": "sin",
    "OPERATORS_MATHOP_COS": "cos",
    "OPERATORS_MATHOP_TAN": "tan",
    "OPERATORS_MATHOP_ASIN": "arcsin",
    "OPERATORS_MATHOP_ACOS": "arccos",
    "OPERATORS_MATHOP_ATAN": "arctan",
    "OPERATORS_MATHOP_LN": "ln",
    "OPERATORS_MATHOP_LOG": "log",
    "OPERATORS_MATHOP_EEXP": "e ^",
    "OPERATORS_MATHOP_10EXP": "10 ^",
    "PROCEDURES_DEFINITION": "definieer %1",
    "SENSING_TOUCHINGOBJECT": "raak ik %1?",
    "SENSING_TOUCHINGOBJECT_POINTER": "muisaanwijzer",
    "SENSING_TOUCHINGOBJECT_EDGE": "rand",
    "SENSING_TOUCHINGCOLOR": "raak ik kleur %1?",
    "SENSING_COLORISTOUCHINGCOLOR": "raakt kleur %1 kleur %2?",
    "SENSING_DISTANCETO": "afstand tot %1",
    "SENSING_DISTANCETO_POINTER": "muisaanwijzer",
    "SENSING_ASKANDWAIT": "vraag %1 en wacht",
    "SENSING_ASK_TEXT": "Hoe heet je?",
    "SENSING_ANSWER": "antwoord",
    "SENSING_KEYPRESSED": "toets %1 ingedrukt?",
    "SENSING_MOUSEDOWN": "muis ingedrukt?",
    "SENSING_MOUSEX": "muis x",
    "SENSING_MOUSEY": "muis y",
    "SENSING_SETDRAGMODE": "zet sleepbaar op %1",
    "SENSING_SETDRAGMODE_DRAGGABLE": "sleepbaar",
    "SENSING_SETDRAGMODE_NOTDRAGGABLE": "niet sleepbaar",
    "SENSING_LOUDNESS": "volume",
    "SENSING_LOUD": "luid?",
    "SENSING_TIMER": "klok",
    "SENSING_RESETTIMER": "zet klok op 0",
    "SENSING_OF": "%1 van %2",
    "SENSING_OF_XPOSITION": "x-positie",
    "SENSING_OF_YPOSITION": "y-positie",
    "SENSING_OF_DIRECTION": "richting",
    "SENSING_OF_COSTUMENUMBER": "uiterlijk #",
    "SENSING_OF_COSTUMENAME": "naam uiterlijk",
    "SENSING_OF_SIZE": "grootte",
    "SENSING_OF_VOLUME": "volume",
    "SENSING_OF_BACKDROPNUMBER": "achtergrond #",
    "SENSING_OF_BACKDROPNAME": "achtergrond naam",
    "SENSING_OF_STAGE": "Speelveld",
    "SENSING_CURRENT": "huidige %1",
    "SENSING_CURRENT_YEAR": "jaar",
    "SENSING_CURRENT_MONTH": "maand",
    "SENSING_CURRENT_DATE": "datum",
    "SENSING_CURRENT_DAYOFWEEK": "dag van de week",
    "SENSING_CURRENT_HOUR": "uur",
    "SENSING_CURRENT_MINUTE": "minuut",
    "SENSING_CURRENT_SECOND": "seconde",
    "SENSING_DAYSSINCE2000": "dagen sinds 2000",
    "SENSING_USERNAME": "gebruikersnaam",
    "SENSING_USERID": "gebruiker id",
    "SOUND_PLAY": "start geluid %1",
    "SOUND_PLAYUNTILDONE": "start geluid %1 en wacht",
    "SOUND_STOPALLSOUNDS": "stop alle geluiden",
    "SOUND_SETEFFECTO": "zet effect %1 op %2",
    "SOUND_CHANGEEFFECTBY": "verander %1-effect met %2",
    "SOUND_CLEAREFFECTS": "zet alle effecten uit",
    "SOUND_EFFECTS_PITCH": "toonhoogte",
    "SOUND_EFFECTS_PAN": "kanaal links/rechts",
    "SOUND_CHANGEVOLUMEBY": "verander volume met %1",
    "SOUND_SETVOLUMETO": "zet volume op %1%",
    "SOUND_VOLUME": "volume",
    "SOUND_RECORD": "opnemen...",
    "CATEGORY_MOTION": "Beweging",
    "CATEGORY_LOOKS": "Uiterlijken",
    "CATEGORY_SOUND": "Geluid",
    "CATEGORY_EVENTS": "Gebeurtenissen",
    "CATEGORY_CONTROL": "Besturen",
    "CATEGORY_SENSING": "Waarnemen",
    "CATEGORY_OPERATORS": "Functies",
    "CATEGORY_VARIABLES": "Variabelen",
    "CATEGORY_MYBLOCKS": "Mijn blokken",
    "CATEGORY_LISTS": "",
    "CATEGORY_TUPLE": "",
    "CATEGORY_DICTIONARYS": "",
    "CATEGORY_NUMBERS": "",
    "CATEGORY_TEXT": "",
    "DUPLICATE": "Kopie maken",
    "DELETE": "Verwijderen",
    "ADD_COMMENT": "Commentaar toevoegen",
    "REMOVE_COMMENT": "Commentaar verwijderen",
    "DELETE_BLOCK": "Blok verwijderen",
    "DELETE_X_BLOCKS": "Verwijder %1 blokken",
    "DELETE_ALL_BLOCKS": "Verwijder alle %1 blokken?",
    "CLEAN_UP": "Blokken opruimen",
    "HELP": "Help",
    "UNDO": "Ongedaan maken",
    "REDO": "Opnieuw",
    "EDIT_PROCEDURE": "Bewerk",
    "SHOW_PROCEDURE_DEFINITION": "Ga naar definitie",
    "WORKSPACE_COMMENT_DEFAULT_TEXT": "Zeg iets...",
    "COLOUR_HUE_LABEL": "Kleur",
    "COLOUR_SATURATION_LABEL": "Verzadiging",
    "COLOUR_BRIGHTNESS_LABEL": "Helderheid",
    "CHANGE_VALUE_TITLE": "Verander waarde:",
    "RENAME_VARIABLE": "Variabele hernoemen",
    "RENAME_VARIABLE_STRING": "",
    "RENAME_VARIABLE_NUMBER": "",
    "RENAME_VARIABLE_LIST": "",
    "RENAME_VARIABLE_TITLE": "Hernoem alle '%1' variabelen naar:",
    "RENAME_VARIABLE_MODAL_TITLE": "Variabele hernoemen",
    "NEW_VARIABLE": "Maak een variabele",
    "NEW_VARIABLE_TITLE": "Nieuwe variabelenaam:",
    "VARIABLE_MODAL_TITLE": "Nieuwe variabele",
    "VARIABLE_ALREADY_EXISTS": "Er bestaat al een variabele met de naam '%1'.",
    "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "Er bestaat al een variabele met de naam '%1' voor een andere variabele van het type '%2'",
    "DELETE_VARIABLE_CONFIRMATION": "Verwijder de %1 toepassingen van de variabele '%2'?",
    "CANNOT_DELETE_VARIABLE_PROCEDURE": "Kan de variabele '%1' niet verwijderen, omdat die deel uitmaakt van de definitie van de functie '%2'",
    "DELETE_VARIABLE": "Verwijder de variabele '%1'",
    "DELETE_VARIABLE_STRING": "",
    "DELETE_VARIABLE_NUMBER": "",
    "DELETE_VARIABLE_LIST": "",
    "NEW_PROCEDURE": "Maak een blok",
    "PROCEDURE_ALREADY_EXISTS": "Er bestaat al een procedure met de naam '%1'.",
    "PROCEDURE_DEFAULT_NAME": "bloknaam",
    "NEW_LIST": "Maak een lijst",
    "NEW_LIST_TITLE": "Nieuwe lijstnaam:",
    "LIST_MODAL_TITLE": "Nieuwe lijst",
    "LIST_ALREADY_EXISTS": "Er bestaat al een lijst met de naam '%1'.",
    "RENAME_LIST_TITLE": "Hernoem alle '%1' lijsten naar:",
    "RENAME_LIST_MODAL_TITLE": "Lijst hernoemen",
    "DEFAULT_LIST_ITEM": "ding",
    "DELETE_LIST": "Verwijder de \"%1\" lijst",
    "RENAME_LIST": "Hernoem lijst",
    "NEW_BROADCAST_MESSAGE": "Nieuw bericht",
    "NEW_BROADCAST_MESSAGE_TITLE": "Nieuwe berichtnaam:",
    "BROADCAST_MODAL_TITLE": "Nieuw bericht",
    "DEFAULT_BROADCAST_MESSAGE_NAME": "bericht1",
    "DELETE_PROCEDURE": "",
    "OK": "",
    "PROMPT": "",
    "STOP": "",
    "NEW_STRING": "",
    "NEW_NUMBER_VARIABLE": "",
    "WIFI": "",
    "USERNAME": "",
    "PWD": "",
    "IOT_SERVICE": "",
    "IOT_ID": "",
    "IOT_PWD": "",
    "IOT_TOPIC": "",
    "MORE_TOPIC": "",
    "OBLOQ_HTTP": "",
    "OBLOQ_IP": "",
    "OBLOQ_PORT": "",
    "ADD_TOPIC": "",
    "DELETE_TOPIC": "",
    "IOT_SERVER": "",
    "CHINA": "",
    "GLOBAL": "",
    "LOW_C": "",
    "LOW_C$": "",
    "LOW_D": "",
    "LOW_D$": "",
    "LOW_E": "",
    "LOW_F": "",
    "LOW_F$": "",
    "LOW_G": "",
    "LOW_G$": "",
    "LOW_A": "",
    "LOW_A$": "",
    "LOW_B": "",
    "MIDDLE_C": "",
    "MIDDLE_C$": "",
    "MIDDLE_D": "",
    "MIDDLE_D$": "",
    "MIDDLE_E": "",
    "MIDDLE_F": "",
    "MIDDLE_F$": "",
    "MIDDLE_G": "",
    "MIDDLE_G$": "",
    "MIDDLE_A": "",
    "MIDDLE_A$": "",
    "MIDDLE_B": "",
    "HIGH_C": "",
    "HIGH_C$": "",
    "HIGH_D": "",
    "HIGH_D$": "",
    "HIGH_E": "",
    "HIGH_F": "",
    "HIGH_F$": "",
    "HIGH_G": "",
    "HIGH_G$": "",
    "HIGH_A": "",
    "HIGH_A$": "",
    "HIGH_B": "",
    "OPERATORS_STRINGTONUMBER": "",
    "INTEGER": "",
    "DECIMAL": "",
    "OPERATORS_NUMTOASCII": "",
    "OPERATORS_STRINGTOASCII": "",
    "OPERATORS_NUMTOSTRING": "",
    "OPERATORS_MAP": "",
    "OPERATORS_CONSTRAIN": "",
    "STEPS_PER_TURN": "",
    "ROTATE_SPEED": "",
    "NEW_AI": "",
    "ASK_AND_PAINT": "",
    "GET_IDENTIFIED_NUM": "",
    "READ_NUM_AND_SAY": "",
    "IMAGE_ADDR": "",
    "IMAGE_PREVIEW": "",
    "IMAGE_OPEN": "",
    "IMAGE_SIZE": "",
    "IMAGE_WIDTH": "",
    "IMAGE_HEIGHT": "",
    "VARIABLE": "",
    "VARIABLE_LIST": "",
    "SET_STRING_VARIABLE": "",
    "STRING_START_WITH": "",
    "DICTIONARY_HEIGHT_FIRST": "",
    "DICTIONARY_HEIGHT_TWO": "",
    "DICTIONARY_HEIGHT_THREE": "",
    "DICTIONARY_HEIGHT_FOUR": "",
    "DATA_MPITEMOFLIST": "",
    "DATA_MPINTERCEPTITEM": "",
    "DATA_MPINSERTATLIST": "",
    "DATA_MPSETVALUEATLIST": "",
    "DATA_MPINSERTATLIST_INSERT": "",
    "DATA_MPINSERTATLIST_CHANGE": "",
    "DATA_MPINSERTATLIST_FOUR": "",
    "DATA_MPINSERTATLIST_FIVE": "",
    "DATA_MPINSERTATLIST_SIX": "",
    "DATA_MPINSERTATLIST_SEVEN": "",
    "DATA_MPINSERTATLIST_EIGHT": "",
    "DATA_MPINSERTATLIST_EIGHT_FALSE": "",
    "DATA_MPINSERTATLIST_EIGHT_TRUE": "",
    "DATA_MPINSERTATLIST_EIGHT_REVERSE": "",
    "OPERATORS_RANDOMA": "",
    "OPERATORS_MODA": "",
    "OPERATORS_ROUNDA": "",
    "OPERATORS_MATHOPA": "",
    "OPERATORS_MATHOP_ABSA": "",
    "OPERATORS_MATHOP_FLOORA": "",
    "OPERATORS_MATHOP_CEILINGA": "",
    "OPERATORS_MATHOP_SQRTA": "",
    "OPERATORS_MATHOP_FIVE": "",
    "OPERATORS_MATHOP_SIX": "",
    "OPERATORS_MATHOP_SEVEN": "",
    "OPERATORS_MATHOP_LENGHT": "",
    "OPERATORS_MATHOP_MAXMIN": "",
    "OPERATORS_MATHOP_MAXMIN_MAX": "",
    "OPERATORS_MATHOP_MAXMIN_MIN": "",
    "OPERATORS_TEXTS_ONE": "",
    "OPERATORS_TEXTS_TWO": "",
    "OPERATORS_TEXTS_THREE": "",
    "OPERATORS_TEXTS_FOUR": "",
    "OPERATORS_TEXTS_FOUR_NEW": "",
    "OPERATORS_TEXTS_NEW": "",
    "OPERATORS_TEXTS_NEW_OP": "",
    "OPERATORS_TEXTS_FOUR_ONE": "",
    "OPERATORS_TEXTS_FOUR_TWO": "",
    "OPERATORS_TEXTS_FOUR_THREE": "",
    "OPERATORS_TEXTS_FOUR_FOUR": "",
    "OPERATORS_TEXTS_FOUR_F": "",
    "OPERATORS_TEXTS_FOUR_L": "",
    "OPERATORS_STRINGTONUMBERA": "",
    "OPERATORS_NUMTOASCIIA": "",
    "OPERATORS_STRINGTOASCIIA": "",
    "OPERATORS_NUMTOSTRINGA": "",
    "NEW_MC": "",
    "RENAME_MCNUMBER_TITLE": "",
    "RENAME_MCNUMBER_MODAL_TITLE": "",
    "RENAME_VARIABLE_MCNUMBER": "",
    "DELETE_VARIABLE_MCNUMBER": "",
    "SET_MC_VARIABLE": "",
    "TUPLE_ONE": "",
    "TUPLE_TWO": "",
    "ALIYUN": "",
    "ONENET": "",
    "EASYIOT": "",
    "SHANGHAI": "",
    "QINGDAO": "",
    "BEIJING": "",
    "ZHANGJIAKOU": "",
    "HUHEHAOTE": "",
    "HANGZHOU": "",
    "SHENZHEN": "",
    "HONGKONG": "",
    "SINGAPORE": "",
    "SYDNEY": "",
    "KUALALUMPUR": "",
    "JAKARTA": "",
    "MUMBAI": "",
    "TOKYO": "",
    "SILICONVALLEY": "",
    "VIRGINIA": "",
    "FRANKFURT": "",
    "LONDON": "",
    "DUBAI": "",
    "IOT_PLATFORM": "",
    "PARAMS": "",
    "SERVER_ATTR": "",
    "PRODUCTID": "",
    "DEVICEID": "",
    "TINYDB_SECRET": "",
    "TINYDB_USER": "",
    "APIADDR": "",
    "SOFTWARE_SERIAL": "",
    "HARDWARE_SERIAL": "",
    "HARDWARE_SERIAL1": "",
    "HARDWARE_SERIAL2": "",
    "HARDWARE_SERIAL3": ""
  },
  "ja": {
    "CONTROL_FOREVER": "ずっと",
    "CONTROL_REPEAT": "%1 回繰り返す",
    "CONTROL_IF": "もし %1 なら",
    "CONTROL_ELSE": "でなければ",
    "CONTROL_STOP": "　",
    "CONTROL_STOP_ALL": "すべてを止める",
    "CONTROL_STOP_THIS": "このスクリプトを止める",
    "CONTROL_STOP_OTHER": "スプライトの他のスクリプトを止める",
    "CONTROL_WAIT": "%1 秒待つ",
    "CONTROL_WAITUNTIL": "%1 まで待つ",
    "CONTROL_REPEATUNTIL": "%1 まで繰り返す",
    "CONTROL_WHILE": "%1 の間繰り返す",
    "CONTROL_FOREACH": "%2 の要素を %1 に入れて繰り返す",
    "CONTROL_STARTASCLONE": "クローンされたとき",
    "CONTROL_CREATECLONEOF": "%1 のクローンを作る",
    "CONTROL_CREATECLONEOF_MYSELF": "自分自身",
    "CONTROL_DELETETHISCLONE": "このクローンを削除する",
    "CONTROL_COUNTER": "カウンター",
    "CONTROL_INCRCOUNTER": "カウンターに足す",
    "CONTROL_CLEARCOUNTER": "カウンターをクリアする",
    "CONTROL_ALLATONCE": "一斉に",
    "DATA_SETVARIABLETO": "%1 を %2 にする",
    "DATA_CHANGEVARIABLEBY": "%1 を %2 ずつ変える",
    "DATA_SHOWVARIABLE": "変数 %1 を表示する",
    "DATA_HIDEVARIABLE": "変数 %1 を隠す",
    "DATA_ADDTOLIST": "%1 を %2 に追加する",
    "DATA_DELETEOFLIST": "%2 の %1 番目を削除する",
    "DATA_DELETEALLOFLIST": "%1のすべてを削除する",
    "DATA_INSERTATLIST": "%3 の %2 番目に %1 を挿入する",
    "DATA_REPLACEITEMOFLIST": "%2 の %1 番目を %3 で置き換える",
    "DATA_ITEMOFLIST": "%2 の %1 番目",
    "DATA_ITEMNUMOFLIST": "%2中の%1の場所",
    "DATA_LENGTHOFLIST": "%1 の長さ",
    "DATA_LISTCONTAINSITEM": "%1 に %2 が含まれる",
    "DATA_SHOWLIST": "リスト %1 を表示する",
    "DATA_HIDELIST": "リスト %1 を隠す",
    "DATA_INDEX_ALL": "すべて",
    "DATA_INDEX_LAST": "最後",
    "DATA_INDEX_RANDOM": "乱数",
    "EVENT_WHENFLAGCLICKED": "%1 が押されたとき",
    "EVENT_WHENTHISSPRITECLICKED": "このスプライトが押されたとき",
    "EVENT_WHENSTAGECLICKED": "ステージが押されたとき",
    "EVENT_WHENTOUCHINGOBJECT": "このスプライトが %1 に触れたとき",
    "EVENT_WHENBROADCASTRECEIVED": "%1 を受け取ったとき",
    "EVENT_WHENBACKDROPSWITCHESTO": "背景が %1 になったとき",
    "EVENT_WHENGREATERTHAN": "%1 > %2 のとき",
    "EVENT_WHENGREATERTHAN_TIMER": "タイマー",
    "EVENT_WHENGREATERTHAN_LOUDNESS": "音量",
    "EVENT_BROADCAST": "%1 を送る",
    "EVENT_BROADCASTANDWAIT": "%1 を送って待つ",
    "EVENT_WHENKEYPRESSED": "%1 キーが押されたとき",
    "EVENT_WHENKEYPRESSED_SPACE": "スペース",
    "EVENT_WHENKEYPRESSED_LEFT": "左向き矢印",
    "EVENT_WHENKEYPRESSED_RIGHT": "右向き矢印",
    "EVENT_WHENKEYPRESSED_DOWN": "下向き矢印",
    "EVENT_WHENKEYPRESSED_UP": "上向き矢印",
    "EVENT_WHENKEYPRESSED_ANY": "どれかの",
    "LOOKS_SAYFORSECS": "%1 と %2 秒言う",
    "LOOKS_SAY": "%1 と言う",
    "LOOKS_HELLO": "こんにちは!",
    "LOOKS_THINKFORSECS": "%1 と %2 秒考える",
    "LOOKS_THINK": "%1 と考える",
    "LOOKS_HMM": "うーん...",
    "LOOKS_SHOW": "表示する",
    "LOOKS_HIDE": "隠す",
    "LOOKS_HIDEALLSPRITES": "すべてのスプライトを隠す",
    "LOOKS_EFFECT_COLOR": "色",
    "LOOKS_EFFECT_FISHEYE": "魚眼レンズ",
    "LOOKS_EFFECT_WHIRL": "渦巻き",
    "LOOKS_EFFECT_PIXELATE": "ピクセル化",
    "LOOKS_EFFECT_MOSAIC": "モザイク",
    "LOOKS_EFFECT_BRIGHTNESS": "明るさ",
    "LOOKS_EFFECT_GHOST": "幽霊",
    "LOOKS_CHANGEEFFECTBY": "%1 の効果を %2 ずつ変える",
    "LOOKS_SETEFFECTTO": "%1 の効果を %2 にする",
    "LOOKS_CLEARGRAPHICEFFECTS": "画像効果をなくす",
    "LOOKS_CHANGESIZEBY": "大きさを %1 ずつ変える",
    "LOOKS_SETSIZETO": "大きさを %1 %にする",
    "LOOKS_SIZE": "大きさ",
    "LOOKS_CHANGESTRETCHBY": "伸びを %1 ずつ変える",
    "LOOKS_SETSTRETCHTO": "伸びを %1 %にする",
    "LOOKS_SWITCHCOSTUMETO": "コスチュームを %1 にする",
    "LOOKS_NEXTCOSTUME": "次のコスチュームにする",
    "LOOKS_SWITCHBACKDROPTO": "背景を %1 にする",
    "LOOKS_GOTOFRONTBACK": "%1 へ移動する",
    "LOOKS_GOTOFRONTBACK_FRONT": "最前面",
    "LOOKS_GOTOFRONTBACK_BACK": "最背面",
    "LOOKS_GOFORWARDBACKWARDLAYERS": "%2 層 %1",
    "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "手前に出す",
    "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "奥に下げる",
    "LOOKS_BACKDROPNUMBERNAME": "背景の %1",
    "LOOKS_COSTUMENUMBERNAME": "コスチュームの %1",
    "LOOKS_NUMBERNAME_NUMBER": "番号",
    "LOOKS_NUMBERNAME_NAME": "名前",
    "LOOKS_SWITCHBACKDROPTOANDWAIT": "背景を %1 にして待つ",
    "LOOKS_NEXTBACKDROP_BLOCK": "次の背景にする",
    "LOOKS_NEXTBACKDROP": "次の背景",
    "LOOKS_PREVIOUSBACKDROP": "前の背景",
    "LOOKS_RANDOMBACKDROP": "どれかの背景",
    "MOTION_MOVESTEPS": "%1 歩動かす",
    "MOTION_TURNLEFT": "%1 %2 度回す",
    "MOTION_TURNRIGHT": "%1 %2 度回す",
    "MOTION_POINTINDIRECTION": "%1 度に向ける",
    "MOTION_POINTTOWARDS": "%1 へ向ける",
    "MOTION_POINTTOWARDS_POINTER": "マウスのポインター",
    "MOTION_POINTTOWARDS_RANDOM": "どれかの向き",
    "MOTION_GOTO": "%1 へ行く",
    "MOTION_GOTO_POINTER": "マウスのポインター",
    "MOTION_GOTO_RANDOM": "どこかの場所",
    "MOTION_GOTOXY": "x座標を %1 、y座標を %2 にする",
    "MOTION_GLIDESECSTOXY": "%1 秒でx座標を %2 に、y座標を %3 に変える",
    "MOTION_GLIDETO": "%1 秒で %2 へ行く",
    "MOTION_GLIDETO_POINTER": "マウスのポインター",
    "MOTION_GLIDETO_RANDOM": "どこかの場所",
    "MOTION_CHANGEXBY": "x座標を %1 ずつ変える",
    "MOTION_SETX": "x座標を %1 にする",
    "MOTION_CHANGEYBY": "y座標を %1 ずつ変える",
    "MOTION_SETY": "y座標を %1 にする",
    "MOTION_IFONEDGEBOUNCE": "もし端に着いたら、跳ね返る",
    "MOTION_SETROTATIONSTYLE": "回転方法を %1 にする",
    "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "左右のみ",
    "MOTION_SETROTATIONSTYLE_DONTROTATE": "回転しない",
    "MOTION_SETROTATIONSTYLE_ALLAROUND": "自由に回転",
    "MOTION_XPOSITION": "x座標",
    "MOTION_YPOSITION": "y座標",
    "MOTION_DIRECTION": "向き",
    "MOTION_SCROLLRIGHT": "右に %1 スクロールする",
    "MOTION_SCROLLUP": "上に %1 スクロールする",
    "MOTION_ALIGNSCENE": "スクロール位置を %1 にする",
    "MOTION_ALIGNSCENE_BOTTOMLEFT": "左下",
    "MOTION_ALIGNSCENE_BOTTOMRIGHT": "右下",
    "MOTION_ALIGNSCENE_MIDDLE": "中央",
    "MOTION_ALIGNSCENE_TOPLEFT": "左上",
    "MOTION_ALIGNSCENE_TOPRIGHT": "右上",
    "MOTION_XSCROLL": "x方向のスクロール量",
    "MOTION_YSCROLL": "y方向のスクロール方向",
    "MOTION_STAGE_SELECTED": "ステージが選択されました: 動きブロックはありません",
    "OPERATORS_ADD": "%1 + %2",
    "OPERATORS_SUBTRACT": "%1 - %2",
    "OPERATORS_MULTIPLY": "%1 * %2",
    "OPERATORS_DIVIDE": "%1 / %2",
    "OPERATORS_RANDOM": "%1 から %2 までの乱数",
    "OPERATORS_GT": "%1 > %2",
    "OPERATORS_GT_EQUALS": "%1 >= %2",
    "OPERATORS_LT": "%1 < %2",
    "OPERATORS_LT_EQUALS": "%1 <= %2",
    "OPERATORS_EQUALS": "%1 = %2",
    "OPERATORS_AND": "%1 かつ %2",
    "OPERATORS_OR": "%1 または %2",
    "OPERATORS_NOT": "%1 ではない",
    "OPERATORS_JOIN": "%1 と %2",
    "OPERATORS_JOIN_APPLE": "りんご",
    "OPERATORS_JOIN_BANANA": "バナナ",
    "OPERATORS_LETTEROF": "%2 の %1 番目の文字",
    "OPERATORS_LETTEROF_APPLE": "り",
    "OPERATORS_LENGTH": "%1 の長さ",
    "OPERATORS_CONTAINS": "%1 に %2 が含まれる",
    "OPERATORS_MOD": "%1 を %2 で割った余り",
    "OPERATORS_ROUND": "%1 を四捨五入",
    "OPERATORS_MATHOP": "%2 の %1",
    "OPERATORS_MATHOP_ABS": "絶対値",
    "OPERATORS_MATHOP_FLOOR": "切り下げ",
    "OPERATORS_MATHOP_CEILING": "切り上げ",
    "OPERATORS_MATHOP_SQRT": "平方根",
    "OPERATORS_MATHOP_SIN": "sin",
    "OPERATORS_MATHOP_COS": "cos",
    "OPERATORS_MATHOP_TAN": "tan",
    "OPERATORS_MATHOP_ASIN": "asin",
    "OPERATORS_MATHOP_ACOS": "acos",
    "OPERATORS_MATHOP_ATAN": "atan",
    "OPERATORS_MATHOP_LN": "ln",
    "OPERATORS_MATHOP_LOG": "log",
    "OPERATORS_MATHOP_EEXP": "e ^",
    "OPERATORS_MATHOP_10EXP": "10 ^",
    "PROCEDURES_DEFINITION": "定義 %1",
    "SENSING_TOUCHINGOBJECT": "%1 に触れた",
    "SENSING_TOUCHINGOBJECT_POINTER": "マウスのポインター",
    "SENSING_TOUCHINGOBJECT_EDGE": "端",
    "SENSING_TOUCHINGCOLOR": "%1 色に触れた",
    "SENSING_COLORISTOUCHINGCOLOR": "%1 色が %2 色に触れた",
    "SENSING_DISTANCETO": "%1 までの距離",
    "SENSING_DISTANCETO_POINTER": "マウスのポインター",
    "SENSING_ASKANDWAIT": "%1 と聞いて待つ",
    "SENSING_ASK_TEXT": "あなたの名前は何ですか?",
    "SENSING_ANSWER": "答え",
    "SENSING_KEYPRESSED": "%1 キーが押された",
    "SENSING_MOUSEDOWN": "マウスが押された",
    "SENSING_MOUSEX": "マウスのx座標",
    "SENSING_MOUSEY": "マウスのy座標",
    "SENSING_SETDRAGMODE": "ドラッグ %1 ようにする",
    "SENSING_SETDRAGMODE_DRAGGABLE": "できる",
    "SENSING_SETDRAGMODE_NOTDRAGGABLE": "できない",
    "SENSING_LOUDNESS": "音量",
    "SENSING_LOUD": "うるさい",
    "SENSING_TIMER": "タイマー",
    "SENSING_RESETTIMER": "タイマーをリセット",
    "SENSING_OF": "%2 の %1",
    "SENSING_OF_XPOSITION": "x座標",
    "SENSING_OF_YPOSITION": "y座標",
    "SENSING_OF_DIRECTION": "向き",
    "SENSING_OF_COSTUMENUMBER": "コスチューム #",
    "SENSING_OF_COSTUMENAME": "コスチューム名",
    "SENSING_OF_SIZE": "大きさ",
    "SENSING_OF_VOLUME": "音量",
    "SENSING_OF_BACKDROPNUMBER": "背景 #",
    "SENSING_OF_BACKDROPNAME": "背景の名前",
    "SENSING_OF_STAGE": "ステージ",
    "SENSING_CURRENT": "現在の %1",
    "SENSING_CURRENT_YEAR": "年",
    "SENSING_CURRENT_MONTH": "月",
    "SENSING_CURRENT_DATE": "日",
    "SENSING_CURRENT_DAYOFWEEK": "曜日",
    "SENSING_CURRENT_HOUR": "時",
    "SENSING_CURRENT_MINUTE": "分",
    "SENSING_CURRENT_SECOND": "秒",
    "SENSING_DAYSSINCE2000": "2000年からの日数",
    "SENSING_USERNAME": "ユーザー名",
    "SENSING_USERID": "ユーザーID",
    "SOUND_PLAY": "%1 の音を鳴らす",
    "SOUND_PLAYUNTILDONE": "終わるまで %1 の音を鳴らす",
    "SOUND_STOPALLSOUNDS": "すべての音を止める",
    "SOUND_SETEFFECTO": "%1 の効果を %2 にする",
    "SOUND_CHANGEEFFECTBY": "%1 の効果を %2 ずつ変える",
    "SOUND_CLEAREFFECTS": "音の効果をなくす",
    "SOUND_EFFECTS_PITCH": "ピッチ",
    "SOUND_EFFECTS_PAN": "左右にパン",
    "SOUND_CHANGEVOLUMEBY": "音量を %1 ずつ変える",
    "SOUND_SETVOLUMETO": "音量を %1 %にする",
    "SOUND_VOLUME": "音量",
    "SOUND_RECORD": "録音...",
    "CATEGORY_MOTION": "動き",
    "CATEGORY_LOOKS": "見た目",
    "CATEGORY_SOUND": "音",
    "CATEGORY_EVENTS": "イベント",
    "CATEGORY_CONTROL": "制御",
    "CATEGORY_SENSING": "調べる",
    "CATEGORY_OPERATORS": "演算",
    "CATEGORY_VARIABLES": "変数",
    "CATEGORY_MYBLOCKS": "ブロック定義",
    "CATEGORY_LISTS": "",
    "CATEGORY_TUPLE": "",
    "CATEGORY_DICTIONARYS": "",
    "CATEGORY_NUMBERS": "数値",
    "CATEGORY_TEXT": "文字",
    "DUPLICATE": "複製",
    "DELETE": "削除",
    "ADD_COMMENT": "コメントを追加",
    "REMOVE_COMMENT": "コメントを削除",
    "DELETE_BLOCK": "ブロックを削除",
    "DELETE_X_BLOCKS": "%1 個のブロックを削除",
    "DELETE_ALL_BLOCKS": "%1 個のブロックをすべて削除しますか?",
    "CLEAN_UP": "きれいにする",
    "HELP": "ヘルプ",
    "UNDO": "取り消し",
    "REDO": "やり直し",
    "EDIT_PROCEDURE": "編集",
    "SHOW_PROCEDURE_DEFINITION": "定義へ行く",
    "WORKSPACE_COMMENT_DEFAULT_TEXT": "コメントを書く...",
    "COLOUR_HUE_LABEL": "色",
    "COLOUR_SATURATION_LABEL": "鮮やかさ",
    "COLOUR_BRIGHTNESS_LABEL": "明るさ",
    "CHANGE_VALUE_TITLE": "値を変える:",
    "RENAME_VARIABLE": "変数名を変更",
    "RENAME_VARIABLE_STRING": "文字列変数の名前の変更",
    "RENAME_VARIABLE_NUMBER": "数値変数の名前を変更",
    "RENAME_VARIABLE_LIST": "リスト名を変更",
    "RENAME_VARIABLE_TITLE": "変数\"%1\"をすべて以下の名前に変える:",
    "RENAME_VARIABLE_MODAL_TITLE": "変数名を変更",
    "NEW_VARIABLE": "変数を作る",
    "NEW_VARIABLE_TITLE": "新しい変数名:",
    "VARIABLE_MODAL_TITLE": "新しい変数",
    "VARIABLE_ALREADY_EXISTS": "変数\"%1\"はすでに存在します。",
    "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "変数\"%1\"は\"%2\"型の変数としてすでに存在します。",
    "DELETE_VARIABLE_CONFIRMATION": "変数\"%2\"が使われている\"%1\"箇所も削除しますか?",
    "CANNOT_DELETE_VARIABLE_PROCEDURE": "変数\"%1\"は定義\"%2\"で使われているため削除できません",
    "DELETE_VARIABLE": "変数\"%1\"を削除",
    "DELETE_VARIABLE_STRING": "文字列変数 [%1] を削除",
    "DELETE_VARIABLE_NUMBER": "数値変数 [%1] を削除",
    "DELETE_VARIABLE_LIST": "リスト [%1] を削除",
    "NEW_PROCEDURE": "ブロックを作る",
    "PROCEDURE_ALREADY_EXISTS": "定義\"%1\"はすでに存在します。",
    "PROCEDURE_DEFAULT_NAME": "ブロック名",
    "NEW_LIST": "リストを作る",
    "NEW_LIST_TITLE": "新しいリスト名:",
    "LIST_MODAL_TITLE": "新しいリスト",
    "LIST_ALREADY_EXISTS": "リスト\"%1\"はすでに存在します。",
    "RENAME_LIST_TITLE": "リスト\"%1\"をすべて以下の名前に変える:",
    "RENAME_LIST_MODAL_TITLE": "リスト名を変更",
    "DEFAULT_LIST_ITEM": "なにか",
    "DELETE_LIST": "リスト「%1」を削除する",
    "RENAME_LIST": "リスト名を変更",
    "NEW_BROADCAST_MESSAGE": "新しいメッセージ",
    "NEW_BROADCAST_MESSAGE_TITLE": "新しいメッセージ名:",
    "BROADCAST_MODAL_TITLE": "新しいメッセージ",
    "DEFAULT_BROADCAST_MESSAGE_NAME": "メッセージ1",
    "DELETE_PROCEDURE": "ブロック定義を削除するには、まず使用している全てのブロックを削除します",
    "OK": "OK",
    "PROMPT": "プロンプト",
    "STOP": "停止",
    "NEW_STRING": "文字列変数を作る",
    "NEW_NUMBER_VARIABLE": "数値変数を作る",
    "WIFI": "Wi-Fi",
    "USERNAME": "Wi-Fi名",
    "PWD": "Wi-Fiパスワード",
    "IOT_SERVICE": "IOTサーバー",
    "IOT_ID": "iot id",
    "IOT_PWD": "iot pwd",
    "IOT_TOPIC": "Topic_0",
    "MORE_TOPIC": "Topic",
    "OBLOQ_HTTP": "HTTP",
    "OBLOQ_IP": "IPアドレス",
    "OBLOQ_PORT": "ポート",
    "ADD_TOPIC": "トピックを追加（最大4つまで追加できます）",
    "DELETE_TOPIC": "トピックを削除",
    "IOT_SERVER": "サーバーアドレス",
    "CHINA": "中国",
    "GLOBAL": "グローバル",
    "LOW_C": "Low C/C3",
    "LOW_C$": "Low C#/C#3",
    "LOW_D": "Low D/D3",
    "LOW_D$": "Low D#/D#3",
    "LOW_E": "Low E/E3",
    "LOW_F": "Low F/F3",
    "LOW_F$": "Low F#/F#3",
    "LOW_G": "Low G/G3",
    "LOW_G$": "Low G#/G#3",
    "LOW_A": "Low A/A3",
    "LOW_A$": "Low A#/A#3",
    "LOW_B": "Low B/B3",
    "MIDDLE_C": "Middle C/C4",
    "MIDDLE_C$": "Middle C#/C#4",
    "MIDDLE_D": "Middle D/D4",
    "MIDDLE_D$": "Middle D#/D#4",
    "MIDDLE_E": "Middle E/E4",
    "MIDDLE_F": "Middle F/F4",
    "MIDDLE_F$": "Middle F#/F#4",
    "MIDDLE_G": "Middle G/G4",
    "MIDDLE_G$": "Middle G#/G#4",
    "MIDDLE_A": "Middle A/A4",
    "MIDDLE_A$": "Middle A#/A#4",
    "MIDDLE_B": "Middle B/B4",
    "HIGH_C": "High C/C5",
    "HIGH_C$": "High C#/C#5",
    "HIGH_D": "High D/D5",
    "HIGH_D$": "High D#/D#5",
    "HIGH_E": "High E/E5",
    "HIGH_F": "High F/F5",
    "HIGH_F$": "High F#/F#5",
    "HIGH_G": "High G/G5",
    "HIGH_G$": "High G#/G#5",
    "HIGH_A": "High A/A5",
    "HIGH_A$": "High A#/A#5",
    "HIGH_B": "High B/B5",
    "OPERATORS_STRINGTONUMBER": "文字列 %1 を %2 に変換する",
    "INTEGER": "整数（Integer）",
    "DECIMAL": "小数（Decimal）",
    "OPERATORS_NUMTOASCII": "数値 %1 を ASCIIコードに変換する",
    "OPERATORS_STRINGTOASCII": "文字 %1 を ASCIIコードに変換する",
    "OPERATORS_NUMTOSTRING": "数値 %1 を文字列に変換する",
    "OPERATORS_MAP": "%1 を [ %2～%3 ] から [ %4～%5 ] にマップする ",
    "OPERATORS_CONSTRAIN": "%1 を %2 以上 %3 以下に収める",
    "STEPS_PER_TURN": "回転あたりのステップ",
    "ROTATE_SPEED": "回転速度(r/s)",
    "NEW_AI": "ＡＩブロックを作成する",
    "ASK_AND_PAINT": "数字を描いてください",
    "GET_IDENTIFIED_NUM": "デジタル認識",
    "READ_NUM_AND_SAY": "認識された番号を発言",
    "IMAGE_ADDR": "画像アドレス",
    "IMAGE_PREVIEW": "画像プレビュー",
    "IMAGE_OPEN": "開く",
    "IMAGE_SIZE": "イメージサイズ",
    "IMAGE_WIDTH": "幅",
    "IMAGE_HEIGHT": "高さ",
    "VARIABLE": "%1",
    "VARIABLE_LIST": "%1",
    "SET_STRING_VARIABLE": "%1 を %2 にする",
    "STRING_START_WITH": "%1 は %2 で始まる",
    "DICTIONARY_HEIGHT_FIRST": "",
    "DICTIONARY_HEIGHT_TWO": "",
    "DICTIONARY_HEIGHT_THREE": "",
    "DICTIONARY_HEIGHT_FOUR": "",
    "DATA_MPITEMOFLIST": "",
    "DATA_MPINTERCEPTITEM": "",
    "DATA_MPINSERTATLIST": "",
    "DATA_MPSETVALUEATLIST": "",
    "DATA_MPINSERTATLIST_INSERT": "",
    "DATA_MPINSERTATLIST_CHANGE": "",
    "DATA_MPINSERTATLIST_FOUR": "",
    "DATA_MPINSERTATLIST_FIVE": "",
    "DATA_MPINSERTATLIST_SIX": "",
    "DATA_MPINSERTATLIST_SEVEN": "",
    "DATA_MPINSERTATLIST_EIGHT": "",
    "DATA_MPINSERTATLIST_EIGHT_FALSE": "",
    "DATA_MPINSERTATLIST_EIGHT_TRUE": "",
    "DATA_MPINSERTATLIST_EIGHT_REVERSE": "",
    "OPERATORS_RANDOMA": "%1 %2 %3 から %4 までの乱数 %5",
    "OPERATORS_MODA": "%1 %2 %3 ÷ %4 の余り",
    "OPERATORS_ROUNDA": "%1 %2 round %3",
    "OPERATORS_MATHOPA": "%1 %2 %3 %4",
    "OPERATORS_MATHOP_ABSA": "絶対値",
    "OPERATORS_MATHOP_FLOORA": "切り捨て",
    "OPERATORS_MATHOP_CEILINGA": "切り上げ",
    "OPERATORS_MATHOP_SQRTA": "平方根",
    "OPERATORS_MATHOP_FIVE": "%1 %2 %3 を %4 増やす",
    "OPERATORS_MATHOP_SIX": "%1 %2 %3 を [ %4 ～ %5 ] から [ %6 ～ %7 ] へマップ",
    "OPERATORS_MATHOP_SEVEN": "%1 %2 %3 を %4 ～ %5 の間に制限する",
    "OPERATORS_MATHOP_LENGHT": "長さ %1",
    "OPERATORS_MATHOP_MAXMIN": "%1 %2",
    "OPERATORS_MATHOP_MAXMIN_MAX": "最大値",
    "OPERATORS_MATHOP_MAXMIN_MIN": "最小値",
    "OPERATORS_TEXTS_ONE": "%1 %2 %3 と %4",
    "OPERATORS_TEXTS_TWO": "%1 %2 %3 の %4 番目の文字",
    "OPERATORS_TEXTS_THREE": "%1 %2 %3 に %4 が含まれている？",
    "OPERATORS_TEXTS_FOUR": "%1 %2 文字列 %3 の %4 %5 番目から %6 %7 番目までを抜き出す",
    "OPERATORS_TEXTS_FOUR_NEW": "%1 の %2 %3 番目～ %4 %5 番目までを抜き出す",
    "OPERATORS_TEXTS_NEW": "%1 %2 文字列 %4 の %3 の位置を %5 から探して見つけた位置",
    "OPERATORS_TEXTS_NEW_OP": "%1 は %2 の %3 から何番目",
    "OPERATORS_TEXTS_FOUR_ONE": "左から",
    "OPERATORS_TEXTS_FOUR_TWO": "右から",
    "OPERATORS_TEXTS_FOUR_THREE": "左から",
    "OPERATORS_TEXTS_FOUR_FOUR": "右から",
    "OPERATORS_TEXTS_FOUR_F": "先頭",
    "OPERATORS_TEXTS_FOUR_L": "末尾",
    "OPERATORS_STRINGTONUMBERA": "%1 %2 文字列 %3 を %4 に変換",
    "OPERATORS_NUMTOASCIIA": "%1 %2 数値 %3 をASCII文字に変換",
    "OPERATORS_STRINGTOASCIIA": "%1 %2 文字 %3 をASCII値に変換",
    "OPERATORS_NUMTOSTRINGA": "%1 %2 数値 %3 を文字列に変換",
    "NEW_MC": "micropython変数を作る",
    "RENAME_MCNUMBER_TITLE": "変数名 [%1] を持つすべての変数の名前を次のように変更します：",
    "RENAME_MCNUMBER_MODAL_TITLE": "変数の名前を変更",
    "RENAME_VARIABLE_MCNUMBER": "変数の名前を変更",
    "DELETE_VARIABLE_MCNUMBER": "変数 [%1] を削除",
    "SET_MC_VARIABLE": "%1 を %2 にする",
    "ALIYUN": "Aliyun（阿里雲）",
    "ONENET": "OneNet",
    "EASYIOT": "Easy IoT",
    "SHANGHAI": "上海",
    "QINGDAO": "青島",
    "BEIJING": "北京",
    "ZHANGJIAKOU": "張家口",
    "HUHEHAOTE": "フフホト",
    "HANGZHOU": "杭州",
    "SHENZHEN": "深セン",
    "HONGKONG": "香港",
    "SINGAPORE": "シンガポール",
    "SYDNEY": "シドニー",
    "KUALALUMPUR": "クアラルンプール",
    "JAKARTA": "ジャカルタ",
    "MUMBAI": "ムンバイ",
    "TOKYO": "東京",
    "SILICONVALLEY": "シリコンバレー",
    "VIRGINIA": "バージニア",
    "FRANKFURT": "フランクフルト",
    "LONDON": "ロンドン",
    "DUBAI": "ドバイ",
    "IOT_PLATFORM": "Iotプラットフォーム",
    "PARAMS": "パラメーター",
    "SERVER_ATTR": "サーバーアドレス",
    "PRODUCTID": "プロダクトID",
    "DEVICEID": "デバイス名ID",
    "TINYDB_SECRET": "シークレット (secret)",
    "TINYDB_USER": "ユーザー名 (user)",
    "APIADDR": "API アドレス",
    "SOFTWARE_SERIAL": "ソフトウェアシリアルポート",
    "HARDWARE_SERIAL": "ハードウェアシリアルポート",
    "HARDWARE_SERIAL1": "ハードウェアシリアルポート1",
    "HARDWARE_SERIAL2": "ハードウェアシリアルポート2",
    "HARDWARE_SERIAL3": "ハードウェアシリアルポート3",
    "CONTROL_LOOP_BREAKCONTINUE": "%1",
    "CONTROL_LOOP_BREAKCONTINUE_BREAK": "繰り返しを抜ける",
    "CONTROL_LOOP_BREAKCONTINUE_CONTINUE": "繰り返しの先頭に戻る",
    "CONTROL_WAIT_ESP32_MPY": "%1 %2 待つ",
    "CONTROL_FOR_FROM_SEQUENCE": "use %1 from sequence %2",
    "CONTROL_WAIT_ESP32_MPY_S": "秒",
    "CONTROL_WAIT_ESP32_MPY_MS": "ミリ秒",
    "CONTROL_WAIT_ESP32_MPY_US": "マイクロ秒",
    "CONTROL_TRUE_FALSE": "%1",
    "CONTROL_TRUE_FALSE_TRUE": "真",
    "CONTROL_TRUE_FALSE_FALSE": "偽",
    "CONTROL_NONE": "空",
    "CONTROL_RETURN": "%1 を返す",
    "OPERATORS_VALUE_IS_TRUE": "値 %1 は真か？",
    "OPERATORS_VALUE_TYPE": "値 %1 のタイプ",
    "CONTROL_TRY": "例外が発生しうる処理",
    "CONTROL_EXCEPT": "例外",
    "CONTROL_FINALLY": "最後に必ず実行する処理",
    "OPERATORS_VALUE_TYPE_IS": "%1 の型は %2 である",
    "OPERATORS_VALUE_TYPE_INT": "int",
    "OPERATORS_VALUE_TYPE_FLOAT": "float",
    "OPERATORS_VALUE_TYPE_BOOL": "bool",
    "OPERATORS_VALUE_TYPE_STR": "str",
    "OPERATORS_VALUE_TYPE_LIST": "list",
    "OPERATORS_VALUE_TYPE_TUPLE": "tuple",
    "OPERATORS_VALUE_TYPE_SET": "set",
    "OPERATORS_VALUE_TYPE_DICT": "dict",
    "OPERATORS_VALUE_TYPE_BYTES": "bytes",
    "OPERATORS_VALUE_TYPE_BYTEARRAY": "bytearray",
    "OPERATORS_VALUE_TYPE_COMPLEX": "complex",
    "OPERATORS_CACULATE_RESULT": "コードとして実行 %1",
    "CANCEL": "キャンセル",
    "OPERATORS_NUM_INPUT": "%1 %2 数値 %3",
    "OPERATORS_NUMS_CACULATE": "%1 %2 %3",
    "OPERATORS_MATHOP_SQUARE": "**",
    "OPERATORS_MATHOP_LOGIC_OR": "|",
    "OPERATORS_MATHOP_LOGIC_AND": "&",
    "OPERATORS_MATHOP_LOGIC_POWER": "^",
    "OPERATORS_MATHOP_LOGIC_LEFT": "<<",
    "OPERATORS_MATHOP_LOGIC_RIGHT": ">>",
    "OPERATORS_SPECIAL_NUM": "%1 %2 %3",
    "OPERATORS_MATHOP_PI": "π",
    "OPERATORS_MATHOP_LOGARITHM": "e",
    "OPERATORS_MATHOP_FAI": "φ",
    "OPERATORS_MATHOP_SQRT2": "sqrt(2)",
    "OPERATORS_MATHOP_SQRTHALF": "sqrt(½)",
    "OPERATORS_MATHOP_UNLIMITED": "∞",
    "OPERATORS_NUMS_TYPE": "%1 %2 %3 %4?",
    "OPERATORS_MATHOP_ISEVEN": "は偶数",
    "OPERATORS_MATHOP_ISODD": "は奇数",
    "OPERATORS_MATHOP_ISINT": "は整数",
    "OPERATORS_MATHOP_ISPOSITIVE": "は正",
    "OPERATORS_MATHOP_ISNEGATIVE": "は負",
    "OPERATORS_SAVE2DECIMAL": "%1 %2 %3 を小数 %4 位に丸める",
    "OPERATORS_LIST_CACULATE": "%1 %2 %3 %4",
    "OPERATORS_MATHOP_LIST_SUM": "リストの数値の合計",
    "OPERATORS_MATHOP_LIST_MIN": "リストの最小値",
    "OPERATORS_MATHOP_LIST_MAX": "リストの最大値",
    "OPERATORS_MATHOP_LIST_AVERAGE": "リストの平均",
    "OPERATORS_MATHOP_LIST_NUM": "リストの桁数",
    "OPERATORS_MATHOP_LIST_MODE": "リストのモード",
    "OPERATORS_MATHOP_LIST_STDDEVIATION": "リストの標準偏差",
    "OPERATORS_MATHOP_LIST_RANDOM": "リストのランダムなアイテム",
    "OPERATORS_QUOTIENT_INT": "%1 %2 %3 ÷ %4 の整数部",
    "OPERATORS_RANDOM_DECIMAL": "%1 %2 乱数（10進数）",
    "OPERATORS_BIN_NEGATION": "%1 %2 ビット反転 %3",
    "OPERATORS_INT_TO_STR": "%1 %2 10進数 %3 を文字列 %4 に変換",
    "OPERATORS_MATHOP_INTTOSTR_BIN": "2進数",
    "OPERATORS_MATHOP_INTTOSTR_OCT": "8進数",
    "OPERATORS_MATHOP_INTTOSTR_HEX": "16進数",
    "OPERATORS_BINSTR_TO_INT": "%1 %2 文字列 %3 を10進数 %4 に変換",
    "OPERATORS_INT_TO_BYTE": "%1 %2 10進数 %3 をバイト %4 に変換",
    "OPERATORS_MATHOP_INTTOBYTE_TWO": "2",
    "OPERATORS_MATHOP_INTTOBYTE_FOUR": "4",
    "OPERATORS_INT_TO_ASCII": "%1 %2 整数 %3 を ASCII文字に変換",
    "OPERATORS_ASCII_TO_INT": "%1 %2 ASCII文字 %3 を整数に変換",
    "OPERATORS_STR_TO_INT": "%1 %2 文字列 %3 を16進数文字列に変換",
    "OPERATORS_HEXSTR_TO_BIN": "%1 %2 16進数文字列 %3 をバイナリーバイトに変換",
    "OPERATORS_STR_TO_BASE64": "%1 %2 %3 をbase64形式でエンコードし、バイトオブジェクトを返します",
    "OPERATORS_BASE64_TO_BYTE": "%1 %2 %3 をbase64形式でデコードし、バイトオブジェクトを返します",
    "OPERATORS_INPUTSTRING": "%1 %2 文字列 %3",
    "OPERATORS_TRANSINTOTEXT": "%1 %2 文字列に変換 %3",
    "OPERATORS_TRANSINTOTEXT_ADD": "追加",
    "OPERATORS_TRANSINTOTEXT_ADD_ITEM": "アイテム",
    "OPERATORS_FORMATSTRING2F": "%1 %2 書式文字列 %3 % %4",
    "OPERATORS_FORMATSTRING3": "%1 %2 書式文字列 %3 format %4",
    "OPERATORS_TEXT_ESCAPE": "%1 %2 エスケープ文字 %3",
    "BAR_N": "\\n",
    "BAR_NN": "\\n\\r",
    "BAR_R": "\\r",
    "BAR_RN": "\\r\\n",
    "BAR_B": "\\b",
    "BAR_T": "\\t",
    "BAR_BAR": "\\\\",
    "OPERATORS_TEXT_NUMORLETTER": "%1 %2 %3 %4?",
    "OPERATORS_TEXT_NUMORLETTER_NUM": "は数値",
    "OPERATORS_TEXT_NUMORLETTER_LETTER": "は文字",
    "OPERATORS_TEXT_LENGTH": "%1 %2 %3 の長さ",
    "OPERATORS_TEXT_ISNONE": "%1 %2 %3 は空？",
    "OPERATORS_FROMTEXT_GETCHAR": "%1 %2 文字列 %3 から %4",
    "OPERATORS_FROMTEXT_GETCHAR_FIRST": "最初の文字を取得",
    "OPERATORS_FROMTEXT_GETCHAR_LAST": "最後の文字を取得",
    "OPERATORS_FROMTEXT_GETCHAR_RANDOM": "ランダムな位置の文字を取得する",
    "OPERATORS_TEXT_UPPLOW": "%1 %2 %3 %4",
    "OPERATORS_TEXT_UPPLOW_UPP": "大文字にする",
    "OPERATORS_TEXT_UPPLOW_LOW": "小文字にする",
    "OPERATORS_TEXT_SIDES_SPACE": "%1 %2 %3 %4",
    "OPERATORS_TEXT_SIDES_SPACE_BOTH": "両端の空白を消す",
    "OPERATORS_TEXT_SIDES_SPACE_LEFT": "左側の空白を消す",
    "OPERATORS_TEXT_SIDES_SPACE_RIGHT": "右側の空白を消す",
    "OPERATORS_TEXT_STRTOBYTE": "%1 %2 文字列 %3 をバイトに変換",
    "OPERATORS_TEXT_BYTETOSTR": "%1 %2 バイト %3 を文字列に変換",
    "OPERATORS_TEXT_DICTTOJSONSTR": "%1 %2 辞書型 %3 をjson文字列に変換",
    "OPERATORS_TEXT_PARSEJSONSTR": "%1 %2 json文字列 %3 を解析し、オブジェクトを返します",
    "ONENETNEW": "OneNet(New)",
    "DEVICENAME": "デバイス名",
    "CHECKTYPE_TIPS": "入力ボックスが受け取ったデータ型は、ビルディングブロックの出力型と一致しません",
    "HIGHLIGHT_BLOCK": "ハイライト",
    "HIGHLIGHT_ALL_CONFLICT_BLOCKS": "警告のあるすべてのブロックをハイライト",
    "SNAPSHOT": "スクリーンショット(CTRL+G)",
    "pictureaiPath": "画像のパスまたはURLを入力してください",
    "pictureaiType": "アカウントタイプ",
    "pictureaiAccountDefault": "デフォルトアカウント",
    "pictureaiAccountBaidu": "バイドゥアカウント",
    "pictureaiwebImgAddr": "ウェブ画像アドレス",
    "MITRIXICON_EDIT": "ライトアップ",
    "MITRIXICON_ERASER": "消しゴム",
    "MITRIXICON_DUSTBIN": "削除",
    "MITRIXICON_REVERSE": "反転",
    "MITRIXICON_SAVE": "保存",
    "MITRIXICON_EXPORT": "書き出す",
    "MITRIXICON_UPLOAD": "アップロード",
    "ROLL_MOUSE": "マウスをスクロール",
    "MOUSE_ZOOM_IN_OUT": "マウスをスクロールしてズームインおよびズームアウトします",
    "UPLOAD_CORRECT_JSON_FILE": "jsonファイルを正しい形式でアップロードしてください",
    "EXPORT_PROMPT": "エクスポートするカスタムパターンはありません。エクスポートを試みる前に、右下隅の保存ボタンをクリックしてカスタムパターンを保存してください。",
    "HEART": "ハート",
    "HEART_SMALL": "ハート（小）",
    "HAPPY": "ハッピー",
    "SMILE": "スマイル",
    "SAD": "悲しい",
    "CONFUSED": "困惑",
    "ANGRY": "怒り",
    "SLEEP": "睡眠",
    "SURPRISED": "びっくり",
    "SILLY": "ばかげた",
    "WONDERFUL": "素晴らしい",
    "BORED": "退屈",
    "ROCK": "石",
    "ROCK_SMALL": "石（小）",
    "PAPER": "紙",
    "PAPER_SMALL": "紙（小）",
    "SCISSORS": "ハサミ",
    "SCISSORS_SMALL": "ハサミ（小）",
    "DATA_SOLITLIST": "%1 %2 make %3 %4 with delimiter %5",
    "DATA_SOLITLIST_TYPE_LT": "リストからテキストを作成する",
    "DATA_SOLITLIST_TYPE_TL": "テキストからリストを作成する",
    "DATA_LENLIST": "%1 %2 リスト %3 は空である",
    "DATA_LENGTHLIST": "%1 %2 %3 の長さ",
    "DATA_CREATELIST": "%1 %2 リスト %3 を作成",
    "DATA_CLEARLIST": "%1 %2 リスト %3 を消去",
    "ML5MODALBUTTON": "AIビジュアルインターフェイス"
  },
  "ja-Hira": {
    "CONTROL_FOREVER": "ずっと",
    "CONTROL_REPEAT": "%1 かいくりかえす",
    "CONTROL_IF": "もし %1 なら",
    "CONTROL_ELSE": "でなければ",
    "CONTROL_STOP": "　",
    "CONTROL_STOP_ALL": "すべてをとめる",
    "CONTROL_STOP_THIS": "このスクリプトをとめる",
    "CONTROL_STOP_OTHER": "スプライトのほかのスクリプトをとめる",
    "CONTROL_WAIT": "%1 びょうまつ",
    "CONTROL_WAITUNTIL": "%1 までまつ",
    "CONTROL_REPEATUNTIL": "%1 までくりかえす",
    "CONTROL_WHILE": "%1 のあいだくりかえす",
    "CONTROL_FOREACH": "%2 のようそを %1 にいれてくりかえす",
    "CONTROL_STARTASCLONE": "クローンされたとき",
    "CONTROL_CREATECLONEOF": "%1 のクローンをつくる",
    "CONTROL_CREATECLONEOF_MYSELF": "じぶんじしん",
    "CONTROL_DELETETHISCLONE": "このクローンをさくじょする",
    "CONTROL_COUNTER": "カウンター",
    "CONTROL_INCRCOUNTER": "カウンターにたす",
    "CONTROL_CLEARCOUNTER": "カウンターをクリアする",
    "CONTROL_ALLATONCE": "いっせいに",
    "DATA_SETVARIABLETO": "%1 を %2 にする",
    "DATA_CHANGEVARIABLEBY": "%1 を %2 ずつかえる",
    "DATA_SHOWVARIABLE": "へんすう %1 をひょうじする",
    "DATA_HIDEVARIABLE": "へんすう %1 をかくす",
    "DATA_ADDTOLIST": "%1 を %2 についかする",
    "DATA_DELETEOFLIST": "%2 の %1 ばんめをさくじょする",
    "DATA_DELETEALLOFLIST": "%1のすべてをさくじょする",
    "DATA_INSERTATLIST": "%3 の %2 ばんめに %1 をそうにゅうする",
    "DATA_REPLACEITEMOFLIST": "%2 の %1 ばんめを %3 でおきかえる",
    "DATA_ITEMOFLIST": "%2 の %1 ばんめ",
    "DATA_ITEMNUMOFLIST": "%2なかの%1のばしょ",
    "DATA_LENGTHOFLIST": "%1 のながさ",
    "DATA_LISTCONTAINSITEM": "%1 に %2 がふくまれる",
    "DATA_SHOWLIST": "リスト %1 をひょうじする",
    "DATA_HIDELIST": "リスト %1 をかくす",
    "DATA_INDEX_ALL": "すべて",
    "DATA_INDEX_LAST": "さいご",
    "DATA_INDEX_RANDOM": "らんすう",
    "EVENT_WHENFLAGCLICKED": "%1 がおされたとき",
    "EVENT_WHENTHISSPRITECLICKED": "このスプライトがおされたとき",
    "EVENT_WHENSTAGECLICKED": "ステージがおされたとき",
    "EVENT_WHENTOUCHINGOBJECT": "このスプライトが %1 にふれたとき",
    "EVENT_WHENBROADCASTRECEIVED": "%1 をうけとったとき",
    "EVENT_WHENBACKDROPSWITCHESTO": "はいけいが %1 になったとき",
    "EVENT_WHENGREATERTHAN": "%1 > %2 のとき",
    "EVENT_WHENGREATERTHAN_TIMER": "タイマー",
    "EVENT_WHENGREATERTHAN_LOUDNESS": "おんりょう",
    "EVENT_BROADCAST": "%1 をおくる",
    "EVENT_BROADCASTANDWAIT": "%1 をおくってまつ",
    "EVENT_WHENKEYPRESSED": "%1 キーがおされたとき",
    "EVENT_WHENKEYPRESSED_SPACE": "スペース",
    "EVENT_WHENKEYPRESSED_LEFT": "ひだりむきやじるし",
    "EVENT_WHENKEYPRESSED_RIGHT": "みぎむきやじるし",
    "EVENT_WHENKEYPRESSED_DOWN": "したむきやじるし",
    "EVENT_WHENKEYPRESSED_UP": "うわむきやじるし",
    "EVENT_WHENKEYPRESSED_ANY": "どれかの",
    "LOOKS_SAYFORSECS": "%1 と %2 びょういう",
    "LOOKS_SAY": "%1 という",
    "LOOKS_HELLO": "こんにちは!",
    "LOOKS_THINKFORSECS": "%1 と %2 びょうかんがえる",
    "LOOKS_THINK": "%1 とかんがえる",
    "LOOKS_HMM": "うーん...",
    "LOOKS_SHOW": "ひょうじする",
    "LOOKS_HIDE": "かくす",
    "LOOKS_HIDEALLSPRITES": "すべてのスプライトをかくす",
    "LOOKS_EFFECT_COLOR": "いろ",
    "LOOKS_EFFECT_FISHEYE": "ぎょがんレンズ",
    "LOOKS_EFFECT_WHIRL": "うずまき",
    "LOOKS_EFFECT_PIXELATE": "ピクセルか",
    "LOOKS_EFFECT_MOSAIC": "モザイク",
    "LOOKS_EFFECT_BRIGHTNESS": "あかるさ",
    "LOOKS_EFFECT_GHOST": "ゆうれい",
    "LOOKS_CHANGEEFFECTBY": "%1 のこうかを %2 ずつかえる",
    "LOOKS_SETEFFECTTO": "%1 のこうかを %2 にする",
    "LOOKS_CLEARGRAPHICEFFECTS": "がぞうこうかをなくす",
    "LOOKS_CHANGESIZEBY": "おおきさを %1 ずつかえる",
    "LOOKS_SETSIZETO": "おおきさを %1 %にする",
    "LOOKS_SIZE": "おおきさ",
    "LOOKS_CHANGESTRETCHBY": "のびを %1 ずつかえる",
    "LOOKS_SETSTRETCHTO": "のびを %1 %にする",
    "LOOKS_SWITCHCOSTUMETO": "コスチュームを %1 にする",
    "LOOKS_NEXTCOSTUME": "つぎのコスチュームにする",
    "LOOKS_SWITCHBACKDROPTO": "はいけいを %1 にする",
    "LOOKS_GOTOFRONTBACK": "%1 へいどうする",
    "LOOKS_GOTOFRONTBACK_FRONT": "さいぜんめん",
    "LOOKS_GOTOFRONTBACK_BACK": "さいはいめん",
    "LOOKS_GOFORWARDBACKWARDLAYERS": "%2 そう %1",
    "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "てまえにだす",
    "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "おくにさげる",
    "LOOKS_BACKDROPNUMBERNAME": "はいけいの %1",
    "LOOKS_COSTUMENUMBERNAME": "コスチュームの %1",
    "LOOKS_NUMBERNAME_NUMBER": "ばんごう",
    "LOOKS_NUMBERNAME_NAME": "なまえ",
    "LOOKS_SWITCHBACKDROPTOANDWAIT": "はいけいを %1 にしてまつ",
    "LOOKS_NEXTBACKDROP_BLOCK": "つぎのはいけいにする",
    "LOOKS_NEXTBACKDROP": "つぎのはいけい",
    "LOOKS_PREVIOUSBACKDROP": "まえのはいけい",
    "LOOKS_RANDOMBACKDROP": "どれかのはいけい",
    "MOTION_MOVESTEPS": "%1 ほうごかす",
    "MOTION_TURNLEFT": "%1 %2 どまわす",
    "MOTION_TURNRIGHT": "%1 %2 どまわす",
    "MOTION_POINTINDIRECTION": "%1 どにむける",
    "MOTION_POINTTOWARDS": "%1 へむける",
    "MOTION_POINTTOWARDS_POINTER": "マウスのポインター",
    "MOTION_POINTTOWARDS_RANDOM": "どれかのむき",
    "MOTION_GOTO": "%1 へいく",
    "MOTION_GOTO_POINTER": "マウスのポインター",
    "MOTION_GOTO_RANDOM": "どこかのばしょ",
    "MOTION_GOTOXY": "xざひょうを %1 、yざひょうを %2 にする",
    "MOTION_GLIDESECSTOXY": "%1 びょうでxざひょうを %2 に、yざひょうを %3 にかえる",
    "MOTION_GLIDETO": "%1 びょうで %2 へいく",
    "MOTION_GLIDETO_POINTER": "マウスのポインター",
    "MOTION_GLIDETO_RANDOM": "どこかのばしょ",
    "MOTION_CHANGEXBY": "xざひょうを %1 ずつかえる",
    "MOTION_SETX": "xざひょうを %1 にする",
    "MOTION_CHANGEYBY": "yざひょうを %1 ずつかえる",
    "MOTION_SETY": "yざひょうを %1 にする",
    "MOTION_IFONEDGEBOUNCE": "もしはしについたら、はねかえる",
    "MOTION_SETROTATIONSTYLE": "かいてんほうほうを %1 にする",
    "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "さゆうのみ",
    "MOTION_SETROTATIONSTYLE_DONTROTATE": "かいてんしない",
    "MOTION_SETROTATIONSTYLE_ALLAROUND": "じゆうにかいてん",
    "MOTION_XPOSITION": "xざひょう",
    "MOTION_YPOSITION": "yざひょう",
    "MOTION_DIRECTION": "むき",
    "MOTION_SCROLLRIGHT": "みぎに %1 スクロールする",
    "MOTION_SCROLLUP": "うえに %1 スクロールする",
    "MOTION_ALIGNSCENE": "スクロールいちを %1 にする",
    "MOTION_ALIGNSCENE_BOTTOMLEFT": "ひだりした",
    "MOTION_ALIGNSCENE_BOTTOMRIGHT": "みぎした",
    "MOTION_ALIGNSCENE_MIDDLE": "ちゅうおう",
    "MOTION_ALIGNSCENE_TOPLEFT": "ひだりうえ",
    "MOTION_ALIGNSCENE_TOPRIGHT": "みぎうえ",
    "MOTION_XSCROLL": "xほうこうのスクロールりょう",
    "MOTION_YSCROLL": "yほうこうのスクロールほうこう",
    "MOTION_STAGE_SELECTED": "ステージがせんたくされました: うごきブロックはありません",
    "OPERATORS_ADD": "%1 + %2",
    "OPERATORS_SUBTRACT": "%1 - %2",
    "OPERATORS_MULTIPLY": "%1 * %2",
    "OPERATORS_DIVIDE": "%1 / %2",
    "OPERATORS_RANDOM": "%1 から %2 までのらんすう",
    "OPERATORS_GT": "%1 > %2",
    "OPERATORS_GT_EQUALS": "",
    "OPERATORS_LT": "%1 < %2",
    "OPERATORS_LT_EQUALS": "",
    "OPERATORS_EQUALS": "%1 = %2",
    "OPERATORS_AND": "%1 かつ %2",
    "OPERATORS_OR": "%1 または %2",
    "OPERATORS_NOT": "%1 ではない",
    "OPERATORS_JOIN": "%1 と %2",
    "OPERATORS_JOIN_APPLE": "りんご",
    "OPERATORS_JOIN_BANANA": "バナナ",
    "OPERATORS_LETTEROF": "%2 の %1 ばんめのもじ",
    "OPERATORS_LETTEROF_APPLE": "り",
    "OPERATORS_LENGTH": "%1 のながさ",
    "OPERATORS_CONTAINS": "%1 に %2 がふくまれる",
    "OPERATORS_MOD": "%1 を %2 でわったあまり",
    "OPERATORS_ROUND": "%1 をししゃごにゅう",
    "OPERATORS_MATHOP": "%2 の %1",
    "OPERATORS_MATHOP_ABS": "ぜったいち",
    "OPERATORS_MATHOP_FLOOR": "きりさげ",
    "OPERATORS_MATHOP_CEILING": "きりあげ",
    "OPERATORS_MATHOP_SQRT": "へいほうこん",
    "OPERATORS_MATHOP_SIN": "sin",
    "OPERATORS_MATHOP_COS": "cos",
    "OPERATORS_MATHOP_TAN": "tan",
    "OPERATORS_MATHOP_ASIN": "asin",
    "OPERATORS_MATHOP_ACOS": "acos",
    "OPERATORS_MATHOP_ATAN": "atan",
    "OPERATORS_MATHOP_LN": "ln",
    "OPERATORS_MATHOP_LOG": "log",
    "OPERATORS_MATHOP_EEXP": "e ^",
    "OPERATORS_MATHOP_10EXP": "10 ^",
    "PROCEDURES_DEFINITION": "ていぎ %1",
    "SENSING_TOUCHINGOBJECT": "%1 にふれた",
    "SENSING_TOUCHINGOBJECT_POINTER": "マウスのポインター",
    "SENSING_TOUCHINGOBJECT_EDGE": "はし",
    "SENSING_TOUCHINGCOLOR": "%1 いろにふれた",
    "SENSING_COLORISTOUCHINGCOLOR": "%1 いろが %2 いろにふれた",
    "SENSING_DISTANCETO": "%1 までのきょり",
    "SENSING_DISTANCETO_POINTER": "マウスのポインター",
    "SENSING_ASKANDWAIT": "%1 ときいてまつ",
    "SENSING_ASK_TEXT": "あなたのなまえはなんですか?",
    "SENSING_ANSWER": "こたえ",
    "SENSING_KEYPRESSED": "%1 キーがおされた",
    "SENSING_MOUSEDOWN": "マウスがおされた",
    "SENSING_MOUSEX": "マウスのxざひょう",
    "SENSING_MOUSEY": "マウスのyざひょう",
    "SENSING_SETDRAGMODE": "ドラッグ %1 ようにする",
    "SENSING_SETDRAGMODE_DRAGGABLE": "できる",
    "SENSING_SETDRAGMODE_NOTDRAGGABLE": "できない",
    "SENSING_LOUDNESS": "おんりょう",
    "SENSING_LOUD": "うるさい",
    "SENSING_TIMER": "タイマー",
    "SENSING_RESETTIMER": "タイマーをリセット",
    "SENSING_OF": "%2 の %1",
    "SENSING_OF_XPOSITION": "xざひょう",
    "SENSING_OF_YPOSITION": "yざひょう",
    "SENSING_OF_DIRECTION": "むき",
    "SENSING_OF_COSTUMENUMBER": "コスチューム #",
    "SENSING_OF_COSTUMENAME": "コスチュームめい",
    "SENSING_OF_SIZE": "おおきさ",
    "SENSING_OF_VOLUME": "おんりょう",
    "SENSING_OF_BACKDROPNUMBER": "はいけい #",
    "SENSING_OF_BACKDROPNAME": "はいけいのなまえ",
    "SENSING_OF_STAGE": "ステージ",
    "SENSING_CURRENT": "げんざいの %1",
    "SENSING_CURRENT_YEAR": "とし",
    "SENSING_CURRENT_MONTH": "つき",
    "SENSING_CURRENT_DATE": "ひ",
    "SENSING_CURRENT_DAYOFWEEK": "ようび",
    "SENSING_CURRENT_HOUR": "とき",
    "SENSING_CURRENT_MINUTE": "ぶん",
    "SENSING_CURRENT_SECOND": "びょう",
    "SENSING_DAYSSINCE2000": "2000ねんからのにっすう",
    "SENSING_USERNAME": "ユーザーめい",
    "SENSING_USERID": "ユーザーID",
    "SOUND_PLAY": "%1 のおとをならす",
    "SOUND_PLAYUNTILDONE": "おわるまで %1 のおとをならす",
    "SOUND_STOPALLSOUNDS": "すべてのおとをとめる",
    "SOUND_SETEFFECTO": "%1 のこうかを %2 にする",
    "SOUND_CHANGEEFFECTBY": "%1 のこうかを %2 ずつかえる",
    "SOUND_CLEAREFFECTS": "おとのこうかをなくす",
    "SOUND_EFFECTS_PITCH": "ピッチ",
    "SOUND_EFFECTS_PAN": "さゆうにパン",
    "SOUND_CHANGEVOLUMEBY": "おんりょうを %1 ずつかえる",
    "SOUND_SETVOLUMETO": "おんりょうを %1 %にする",
    "SOUND_VOLUME": "おんりょう",
    "SOUND_RECORD": "ろくおん...",
    "CATEGORY_MOTION": "うごき",
    "CATEGORY_LOOKS": "みため",
    "CATEGORY_SOUND": "おと",
    "CATEGORY_EVENTS": "イベント",
    "CATEGORY_CONTROL": "せいぎょ",
    "CATEGORY_SENSING": "しらべる",
    "CATEGORY_OPERATORS": "えんざん",
    "CATEGORY_VARIABLES": "へんすう",
    "CATEGORY_MYBLOCKS": "ブロックていぎ",
    "CATEGORY_LISTS": "",
    "CATEGORY_TUPLE": "",
    "CATEGORY_DICTIONARYS": "",
    "CATEGORY_NUMBERS": "",
    "CATEGORY_TEXT": "",
    "DUPLICATE": "ふくせい",
    "DELETE": "さくじょ",
    "ADD_COMMENT": "コメントをついか",
    "REMOVE_COMMENT": "コメントをさくじょ",
    "DELETE_BLOCK": "ブロックをさくじょ",
    "DELETE_X_BLOCKS": "%1 このブロックをさくじょ",
    "DELETE_ALL_BLOCKS": "%1 このブロックをすべてさくじょしますか?",
    "CLEAN_UP": "きれいにする",
    "HELP": "ヘルプ",
    "UNDO": "とりけし",
    "REDO": "やりなおし",
    "EDIT_PROCEDURE": "へんしゅう",
    "SHOW_PROCEDURE_DEFINITION": "ていぎへいく",
    "WORKSPACE_COMMENT_DEFAULT_TEXT": "コメントをかく...",
    "COLOUR_HUE_LABEL": "いろ",
    "COLOUR_SATURATION_LABEL": "あざやかさ",
    "COLOUR_BRIGHTNESS_LABEL": "あかるさ",
    "CHANGE_VALUE_TITLE": "あたいをかえる:",
    "RENAME_VARIABLE": "へんすうめいをへんこう",
    "RENAME_VARIABLE_STRING": "",
    "RENAME_VARIABLE_NUMBER": "",
    "RENAME_VARIABLE_LIST": "",
    "RENAME_VARIABLE_TITLE": "へんすう\"%1\"をすべていかのなまえにかえる:",
    "RENAME_VARIABLE_MODAL_TITLE": "へんすうめいをへんこう",
    "NEW_VARIABLE": "へんすうをつくる",
    "NEW_VARIABLE_TITLE": "あたらしいへんすうめい:",
    "VARIABLE_MODAL_TITLE": "あたらしいへんすう",
    "VARIABLE_ALREADY_EXISTS": "へんすう\"%1\"はすでにそんざいします。",
    "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "へんすう\"%1\"は\"%2\"かたのへんすうとしてすでにそんざいします。",
    "DELETE_VARIABLE_CONFIRMATION": "へんすう\"%2\"がつかわれている\"%1\"かしょもさくじょしますか?",
    "CANNOT_DELETE_VARIABLE_PROCEDURE": "へんすう\"%1\"はていぎ\"%2\"でつかわれているためさくじょできません",
    "DELETE_VARIABLE": "へんすう\"%1\"をさくじょ",
    "DELETE_VARIABLE_STRING": "",
    "DELETE_VARIABLE_NUMBER": "",
    "DELETE_VARIABLE_LIST": "",
    "NEW_PROCEDURE": "ブロックをつくる",
    "PROCEDURE_ALREADY_EXISTS": "ていぎ\"%1\"はすでにそんざいします。",
    "PROCEDURE_DEFAULT_NAME": "ブロックめい",
    "NEW_LIST": "リストをつくる",
    "NEW_LIST_TITLE": "あたらしいリストめい:",
    "LIST_MODAL_TITLE": "あたらしいリスト",
    "LIST_ALREADY_EXISTS": "リスト\"%1\"はすでにそんざいします。",
    "RENAME_LIST_TITLE": "リスト\"%1\"をすべていかのなまえにかえる:",
    "RENAME_LIST_MODAL_TITLE": "リストめいをへんこう",
    "DEFAULT_LIST_ITEM": "なにか",
    "DELETE_LIST": "リスト「%1」をさくじょする",
    "RENAME_LIST": "リストめいをへんこう",
    "NEW_BROADCAST_MESSAGE": "あたらしいメッセージ",
    "NEW_BROADCAST_MESSAGE_TITLE": "あたらしいメッセージめい:",
    "BROADCAST_MODAL_TITLE": "あたらしいメッセージ",
    "DEFAULT_BROADCAST_MESSAGE_NAME": "メッセージ1",
    "DELETE_PROCEDURE": "",
    "OK": "",
    "PROMPT": "",
    "STOP": "",
    "NEW_STRING": "",
    "NEW_NUMBER_VARIABLE": "",
    "WIFI": "",
    "USERNAME": "",
    "PWD": "",
    "IOT_SERVICE": "",
    "IOT_ID": "",
    "IOT_PWD": "",
    "IOT_TOPIC": "",
    "MORE_TOPIC": "",
    "OBLOQ_HTTP": "",
    "OBLOQ_IP": "",
    "OBLOQ_PORT": "",
    "ADD_TOPIC": "",
    "DELETE_TOPIC": "",
    "IOT_SERVER": "",
    "CHINA": "",
    "GLOBAL": "",
    "LOW_C": "",
    "LOW_C$": "",
    "LOW_D": "",
    "LOW_D$": "",
    "LOW_E": "",
    "LOW_F": "",
    "LOW_F$": "",
    "LOW_G": "",
    "LOW_G$": "",
    "LOW_A": "",
    "LOW_A$": "",
    "LOW_B": "",
    "MIDDLE_C": "",
    "MIDDLE_C$": "",
    "MIDDLE_D": "",
    "MIDDLE_D$": "",
    "MIDDLE_E": "",
    "MIDDLE_F": "",
    "MIDDLE_F$": "",
    "MIDDLE_G": "",
    "MIDDLE_G$": "",
    "MIDDLE_A": "",
    "MIDDLE_A$": "",
    "MIDDLE_B": "",
    "HIGH_C": "",
    "HIGH_C$": "",
    "HIGH_D": "",
    "HIGH_D$": "",
    "HIGH_E": "",
    "HIGH_F": "",
    "HIGH_F$": "",
    "HIGH_G": "",
    "HIGH_G$": "",
    "HIGH_A": "",
    "HIGH_A$": "",
    "HIGH_B": "",
    "OPERATORS_STRINGTONUMBER": "",
    "INTEGER": "",
    "DECIMAL": "",
    "OPERATORS_NUMTOASCII": "",
    "OPERATORS_STRINGTOASCII": "",
    "OPERATORS_NUMTOSTRING": "",
    "OPERATORS_MAP": "",
    "OPERATORS_CONSTRAIN": "",
    "STEPS_PER_TURN": "",
    "ROTATE_SPEED": "",
    "NEW_AI": "",
    "ASK_AND_PAINT": "",
    "GET_IDENTIFIED_NUM": "",
    "READ_NUM_AND_SAY": "",
    "IMAGE_ADDR": "",
    "IMAGE_PREVIEW": "",
    "IMAGE_OPEN": "",
    "IMAGE_SIZE": "",
    "IMAGE_WIDTH": "",
    "IMAGE_HEIGHT": "",
    "VARIABLE": "",
    "VARIABLE_LIST": "",
    "SET_STRING_VARIABLE": "",
    "STRING_START_WITH": "",
    "DICTIONARY_HEIGHT_FIRST": "",
    "DICTIONARY_HEIGHT_TWO": "",
    "DICTIONARY_HEIGHT_THREE": "",
    "DICTIONARY_HEIGHT_FOUR": "",
    "DATA_MPITEMOFLIST": "",
    "DATA_MPINTERCEPTITEM": "",
    "DATA_MPINSERTATLIST": "",
    "DATA_MPSETVALUEATLIST": "",
    "DATA_MPINSERTATLIST_INSERT": "",
    "DATA_MPINSERTATLIST_CHANGE": "",
    "DATA_MPINSERTATLIST_FOUR": "",
    "DATA_MPINSERTATLIST_FIVE": "",
    "DATA_MPINSERTATLIST_SIX": "",
    "DATA_MPINSERTATLIST_SEVEN": "",
    "DATA_MPINSERTATLIST_EIGHT": "",
    "DATA_MPINSERTATLIST_EIGHT_FALSE": "",
    "DATA_MPINSERTATLIST_EIGHT_TRUE": "",
    "DATA_MPINSERTATLIST_EIGHT_REVERSE": "",
    "OPERATORS_RANDOMA": "",
    "OPERATORS_MODA": "",
    "OPERATORS_ROUNDA": "",
    "OPERATORS_MATHOPA": "",
    "OPERATORS_MATHOP_ABSA": "",
    "OPERATORS_MATHOP_FLOORA": "",
    "OPERATORS_MATHOP_CEILINGA": "",
    "OPERATORS_MATHOP_SQRTA": "",
    "OPERATORS_MATHOP_FIVE": "",
    "OPERATORS_MATHOP_SIX": "",
    "OPERATORS_MATHOP_SEVEN": "",
    "OPERATORS_MATHOP_LENGHT": "",
    "OPERATORS_MATHOP_MAXMIN": "",
    "OPERATORS_MATHOP_MAXMIN_MAX": "",
    "OPERATORS_MATHOP_MAXMIN_MIN": "",
    "OPERATORS_TEXTS_ONE": "",
    "OPERATORS_TEXTS_TWO": "",
    "OPERATORS_TEXTS_THREE": "",
    "OPERATORS_TEXTS_FOUR": "",
    "OPERATORS_TEXTS_FOUR_NEW": "",
    "OPERATORS_TEXTS_NEW": "",
    "OPERATORS_TEXTS_NEW_OP": "",
    "OPERATORS_TEXTS_FOUR_ONE": "",
    "OPERATORS_TEXTS_FOUR_TWO": "",
    "OPERATORS_TEXTS_FOUR_THREE": "",
    "OPERATORS_TEXTS_FOUR_FOUR": "",
    "OPERATORS_TEXTS_FOUR_F": "",
    "OPERATORS_TEXTS_FOUR_L": "",
    "OPERATORS_STRINGTONUMBERA": "",
    "OPERATORS_NUMTOASCIIA": "",
    "OPERATORS_STRINGTOASCIIA": "",
    "OPERATORS_NUMTOSTRINGA": "",
    "NEW_MC": "",
    "RENAME_MCNUMBER_TITLE": "",
    "RENAME_MCNUMBER_MODAL_TITLE": "",
    "RENAME_VARIABLE_MCNUMBER": "",
    "DELETE_VARIABLE_MCNUMBER": "",
    "SET_MC_VARIABLE": "",
    "TUPLE_ONE": "",
    "TUPLE_TWO": "",
    "ALIYUN": "",
    "ONENET": "",
    "EASYIOT": "",
    "SHANGHAI": "",
    "QINGDAO": "",
    "BEIJING": "",
    "ZHANGJIAKOU": "",
    "HUHEHAOTE": "",
    "HANGZHOU": "",
    "SHENZHEN": "",
    "HONGKONG": "",
    "SINGAPORE": "",
    "SYDNEY": "",
    "KUALALUMPUR": "",
    "JAKARTA": "",
    "MUMBAI": "",
    "TOKYO": "",
    "SILICONVALLEY": "",
    "VIRGINIA": "",
    "FRANKFURT": "",
    "LONDON": "",
    "DUBAI": "",
    "IOT_PLATFORM": "",
    "PARAMS": "",
    "SERVER_ATTR": "",
    "PRODUCTID": "",
    "DEVICEID": "",
    "TINYDB_SECRET": "",
    "TINYDB_USER": "",
    "APIADDR": "",
    "SOFTWARE_SERIAL": "",
    "HARDWARE_SERIAL": "",
    "HARDWARE_SERIAL1": "",
    "HARDWARE_SERIAL2": "",
    "HARDWARE_SERIAL3": ""
  },
  "nb": {
    "CONTROL_FOREVER": "gjenta for alltid",
    "CONTROL_REPEAT": "gjenta %1 ganger",
    "CONTROL_IF": "hvis %1",
    "CONTROL_ELSE": "ellers",
    "CONTROL_STOP": "stopp",
    "CONTROL_STOP_ALL": "alle",
    "CONTROL_STOP_THIS": "dette skriptet",
    "CONTROL_STOP_OTHER": "andre skript i figuren",
    "CONTROL_WAIT": "vent %1 sekunder",
    "CONTROL_WAITUNTIL": "vent til %1",
    "CONTROL_REPEATUNTIL": "gjenta til %1",
    "CONTROL_WHILE": "gjenta hvis %1",
    "CONTROL_FOREACH": "for hver %1 i %2",
    "CONTROL_STARTASCLONE": "når jeg starter som klon",
    "CONTROL_CREATECLONEOF": "lag klon av %1",
    "CONTROL_CREATECLONEOF_MYSELF": "meg",
    "CONTROL_DELETETHISCLONE": "slett denne klonen",
    "CONTROL_COUNTER": "teller",
    "CONTROL_INCRCOUNTER": "tell opp med 1",
    "CONTROL_CLEARCOUNTER": "nullstill telleren",
    "CONTROL_ALLATONCE": "kjør fort",
    "DATA_SETVARIABLETO": "sett %1 til %2",
    "DATA_CHANGEVARIABLEBY": "endre %1 med %2",
    "DATA_SHOWVARIABLE": "vis variabel %1",
    "DATA_HIDEVARIABLE": "skjul variabel %1",
    "DATA_ADDTOLIST": "legg til %1 i %2",
    "DATA_DELETEOFLIST": "slett element %1 i %2",
    "DATA_DELETEALLOFLIST": "slett alt i %1",
    "DATA_INSERTATLIST": "sett inn %1 på plass %2 i %3",
    "DATA_REPLACEITEMOFLIST": "erstatt element %1 i %2 med %3",
    "DATA_ITEMOFLIST": "element %1 i %2",
    "DATA_ITEMNUMOFLIST": "posisjon av %1 i %2",
    "DATA_LENGTHOFLIST": "lengden av %1",
    "DATA_LISTCONTAINSITEM": "%1 inneholder %2?",
    "DATA_SHOWLIST": "vis liste %1",
    "DATA_HIDELIST": "skjul liste %1",
    "DATA_INDEX_ALL": "alle",
    "DATA_INDEX_LAST": "siste",
    "DATA_INDEX_RANDOM": "tilfeldig",
    "EVENT_WHENFLAGCLICKED": "når %1 klikkes",
    "EVENT_WHENTHISSPRITECLICKED": "når denne figuren klikkes",
    "EVENT_WHENSTAGECLICKED": "når scenen klikkes",
    "EVENT_WHENTOUCHINGOBJECT": "når denne figuren berører %1",
    "EVENT_WHENBROADCASTRECEIVED": "når jeg mottar %1",
    "EVENT_WHENBACKDROPSWITCHESTO": "når bakgrunn bytter til %1",
    "EVENT_WHENGREATERTHAN": "når %1 > %2",
    "EVENT_WHENGREATERTHAN_TIMER": "sekundklokke",
    "EVENT_WHENGREATERTHAN_LOUDNESS": "lydnivå",
    "EVENT_BROADCAST": "send melding %1",
    "EVENT_BROADCASTANDWAIT": "send melding %1 og vent",
    "EVENT_WHENKEYPRESSED": "når %1 trykkes",
    "EVENT_WHENKEYPRESSED_SPACE": "mellomrom",
    "EVENT_WHENKEYPRESSED_LEFT": "pil venstre",
    "EVENT_WHENKEYPRESSED_RIGHT": "pil høyre",
    "EVENT_WHENKEYPRESSED_DOWN": "pil ned",
    "EVENT_WHENKEYPRESSED_UP": "pil opp",
    "EVENT_WHENKEYPRESSED_ANY": "hvilken som helst",
    "LOOKS_SAYFORSECS": "si %1 i %2 sekunder",
    "LOOKS_SAY": "si %1",
    "LOOKS_HELLO": "Hei!",
    "LOOKS_THINKFORSECS": "tenk %1 i %2 sekunder",
    "LOOKS_THINK": "tenk %1",
    "LOOKS_HMM": "Hmm...",
    "LOOKS_SHOW": "vis",
    "LOOKS_HIDE": "skjul",
    "LOOKS_HIDEALLSPRITES": "skjul alle figurer",
    "LOOKS_EFFECT_COLOR": "farge",
    "LOOKS_EFFECT_FISHEYE": "fiskeøye",
    "LOOKS_EFFECT_WHIRL": "virvel",
    "LOOKS_EFFECT_PIXELATE": "piksel",
    "LOOKS_EFFECT_MOSAIC": "mosaikk",
    "LOOKS_EFFECT_BRIGHTNESS": "lysstyrke",
    "LOOKS_EFFECT_GHOST": "gjennomsiktig",
    "LOOKS_CHANGEEFFECTBY": "endre %1 effekt med %2",
    "LOOKS_SETEFFECTTO": "sett %1 effekt til %2",
    "LOOKS_CLEARGRAPHICEFFECTS": "fjern grafiske effekter",
    "LOOKS_CHANGESIZEBY": "endre størrelse med %1",
    "LOOKS_SETSIZETO": "sett størrelse til %1 %",
    "LOOKS_SIZE": "størrelse",
    "LOOKS_CHANGESTRETCHBY": "endre strekk med %1",
    "LOOKS_SETSTRETCHTO": "sett strekk til %1 %",
    "LOOKS_SWITCHCOSTUMETO": "bytt drakt til %1",
    "LOOKS_NEXTCOSTUME": "neste drakt",
    "LOOKS_SWITCHBACKDROPTO": "bytt bakgrunn til %1",
    "LOOKS_GOTOFRONTBACK": "legg %1",
    "LOOKS_GOTOFRONTBACK_FRONT": "foran alt",
    "LOOKS_GOTOFRONTBACK_BACK": "bakerst",
    "LOOKS_GOFORWARDBACKWARDLAYERS": "gå %1 %2 lag",
    "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "forover",
    "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "bakover",
    "LOOKS_BACKDROPNUMBERNAME": "bakgrunn %1",
    "LOOKS_COSTUMENUMBERNAME": "drakt %1",
    "LOOKS_NUMBERNAME_NUMBER": "nummer",
    "LOOKS_NUMBERNAME_NAME": "navn",
    "LOOKS_SWITCHBACKDROPTOANDWAIT": "bytt bakgrunn til %1 og vent",
    "LOOKS_NEXTBACKDROP_BLOCK": "neste bakgrunn",
    "LOOKS_NEXTBACKDROP": "neste bakgrunn",
    "LOOKS_PREVIOUSBACKDROP": "forrige bakgrunn",
    "LOOKS_RANDOMBACKDROP": "tilfeldig bakgrunn",
    "MOTION_MOVESTEPS": "gå %1 steg",
    "MOTION_TURNLEFT": "snu %1 %2 grader",
    "MOTION_TURNRIGHT": "snu %1 %2 grader",
    "MOTION_POINTINDIRECTION": "pek i retning %1",
    "MOTION_POINTTOWARDS": "pek mot %1",
    "MOTION_POINTTOWARDS_POINTER": "musepeker",
    "MOTION_POINTTOWARDS_RANDOM": "tilfeldig retning",
    "MOTION_GOTO": "gå til %1",
    "MOTION_GOTO_POINTER": "musepeker",
    "MOTION_GOTO_RANDOM": "tilfeldig sted",
    "MOTION_GOTOXY": "gå til x: %1 y: %2",
    "MOTION_GLIDESECSTOXY": "gli %1 sekunder til x: %2 y: %3",
    "MOTION_GLIDETO": "gli %1 sekunder til %2",
    "MOTION_GLIDETO_POINTER": "musepeker",
    "MOTION_GLIDETO_RANDOM": "tilfeldig sted",
    "MOTION_CHANGEXBY": "endre x med %1",
    "MOTION_SETX": "sett x til %1",
    "MOTION_CHANGEYBY": "endre y med %1",
    "MOTION_SETY": "sett y til %1",
    "MOTION_IFONEDGEBOUNCE": "sprett tilbake ved kanten",
    "MOTION_SETROTATIONSTYLE": "begrens rotasjon %1",
    "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "vend sideveis",
    "MOTION_SETROTATIONSTYLE_DONTROTATE": "ikke roter",
    "MOTION_SETROTATIONSTYLE_ALLAROUND": "fri rotasjon",
    "MOTION_XPOSITION": "x-posisjon",
    "MOTION_YPOSITION": "y-posisjon",
    "MOTION_DIRECTION": "retning",
    "MOTION_SCROLLRIGHT": "rull høyre %1",
    "MOTION_SCROLLUP": "rull opp %1",
    "MOTION_ALIGNSCENE": "flytt bakgrunn %1",
    "MOTION_ALIGNSCENE_BOTTOMLEFT": "nederst til venstre",
    "MOTION_ALIGNSCENE_BOTTOMRIGHT": "nederst til høyre",
    "MOTION_ALIGNSCENE_MIDDLE": "i midten",
    "MOTION_ALIGNSCENE_TOPLEFT": "øverst til venstre",
    "MOTION_ALIGNSCENE_TOPRIGHT": "øverst til høyre",
    "MOTION_XSCROLL": "rull x",
    "MOTION_YSCROLL": "rull y",
    "MOTION_STAGE_SELECTED": "Scenen er valgt: den har ingen bevegelsesklosser",
    "OPERATORS_ADD": "%1 + %2",
    "OPERATORS_SUBTRACT": "%1 - %2",
    "OPERATORS_MULTIPLY": "%1 * %2",
    "OPERATORS_DIVIDE": "%1 / %2",
    "OPERATORS_RANDOM": "tilfeldig tall fra %1 til %2",
    "OPERATORS_GT": "%1 > %2",
    "OPERATORS_GT_EQUALS": "",
    "OPERATORS_LT": "%1 < %2",
    "OPERATORS_LT_EQUALS": "",
    "OPERATORS_EQUALS": "%1 = %2",
    "OPERATORS_AND": "%1 og %2",
    "OPERATORS_OR": "%1 eller %2",
    "OPERATORS_NOT": "ikke %1",
    "OPERATORS_JOIN": "sett sammen %1 %2",
    "OPERATORS_JOIN_APPLE": "eple",
    "OPERATORS_JOIN_BANANA": "banan",
    "OPERATORS_LETTEROF": "bokstav %1 i %2",
    "OPERATORS_LETTEROF_APPLE": "p",
    "OPERATORS_LENGTH": "lengden av %1",
    "OPERATORS_CONTAINS": "%1 inneholder %2?",
    "OPERATORS_MOD": "%1 mod %2",
    "OPERATORS_ROUND": "avrund %1",
    "OPERATORS_MATHOP": "%1 av %2",
    "OPERATORS_MATHOP_ABS": "absoluttverdi",
    "OPERATORS_MATHOP_FLOOR": "avrund ned",
    "OPERATORS_MATHOP_CEILING": "avrund opp",
    "OPERATORS_MATHOP_SQRT": "kvadratrot",
    "OPERATORS_MATHOP_SIN": "sin",
    "OPERATORS_MATHOP_COS": "cos",
    "OPERATORS_MATHOP_TAN": "tan",
    "OPERATORS_MATHOP_ASIN": "arcsin",
    "OPERATORS_MATHOP_ACOS": "arccos",
    "OPERATORS_MATHOP_ATAN": "arctan",
    "OPERATORS_MATHOP_LN": "ln",
    "OPERATORS_MATHOP_LOG": "log",
    "OPERATORS_MATHOP_EEXP": "e ^",
    "OPERATORS_MATHOP_10EXP": "10 ^",
    "PROCEDURES_DEFINITION": "definer %1",
    "SENSING_TOUCHINGOBJECT": "berører %1?",
    "SENSING_TOUCHINGOBJECT_POINTER": "musepeker",
    "SENSING_TOUCHINGOBJECT_EDGE": "kant",
    "SENSING_TOUCHINGCOLOR": "berører fargen %1?",
    "SENSING_COLORISTOUCHINGCOLOR": "farge %1 berører %2?",
    "SENSING_DISTANCETO": "avstand til %1",
    "SENSING_DISTANCETO_POINTER": "musepeker",
    "SENSING_ASKANDWAIT": "spør %1 og vent",
    "SENSING_ASK_TEXT": "Hva heter du?",
    "SENSING_ANSWER": "svar",
    "SENSING_KEYPRESSED": "tast %1 trykket?",
    "SENSING_MOUSEDOWN": "museknappen er trykket?",
    "SENSING_MOUSEX": "mus x",
    "SENSING_MOUSEY": "mus y",
    "SENSING_SETDRAGMODE": "%1 å dra med musen",
    "SENSING_SETDRAGMODE_DRAGGABLE": "tillat",
    "SENSING_SETDRAGMODE_NOTDRAGGABLE": "ikke tillat",
    "SENSING_LOUDNESS": "lydnivå",
    "SENSING_LOUD": "høy lyd?",
    "SENSING_TIMER": "sekundklokke",
    "SENSING_RESETTIMER": "nullstill klokken",
    "SENSING_OF": "%1 av %2",
    "SENSING_OF_XPOSITION": "x-posisjon",
    "SENSING_OF_YPOSITION": "y-posisjon",
    "SENSING_OF_DIRECTION": "retning",
    "SENSING_OF_COSTUMENUMBER": "drakt nr.",
    "SENSING_OF_COSTUMENAME": "draktnavn",
    "SENSING_OF_SIZE": "størrelse",
    "SENSING_OF_VOLUME": "volum",
    "SENSING_OF_BACKDROPNUMBER": "bakgrunn nr.",
    "SENSING_OF_BACKDROPNAME": "navn på bakgrunn",
    "SENSING_OF_STAGE": "Scene",
    "SENSING_CURRENT": "%1 nå",
    "SENSING_CURRENT_YEAR": "år",
    "SENSING_CURRENT_MONTH": "måned",
    "SENSING_CURRENT_DATE": "dato",
    "SENSING_CURRENT_DAYOFWEEK": "ukedag",
    "SENSING_CURRENT_HOUR": "time",
    "SENSING_CURRENT_MINUTE": "minutt",
    "SENSING_CURRENT_SECOND": "sekund",
    "SENSING_DAYSSINCE2000": "dager siden 2000",
    "SENSING_USERNAME": "brukernavn",
    "SENSING_USERID": "brukernavn",
    "SOUND_PLAY": "start lyden %1",
    "SOUND_PLAYUNTILDONE": "spill lyden %1 til den er ferdig",
    "SOUND_STOPALLSOUNDS": "stopp alle lyder",
    "SOUND_SETEFFECTO": "sett %1 effekt til %2",
    "SOUND_CHANGEEFFECTBY": "endre %1 effekt med %2",
    "SOUND_CLEAREFFECTS": "fjern lydeffekter",
    "SOUND_EFFECTS_PITCH": "tonehøyde",
    "SOUND_EFFECTS_PAN": "retning",
    "SOUND_CHANGEVOLUMEBY": "endre volum med %1",
    "SOUND_SETVOLUMETO": "sett volum %1%",
    "SOUND_VOLUME": "volum",
    "SOUND_RECORD": "spill inn...",
    "CATEGORY_MOTION": "Bevegelse",
    "CATEGORY_LOOKS": "Utseende",
    "CATEGORY_SOUND": "Lyd",
    "CATEGORY_EVENTS": "Hendelser",
    "CATEGORY_CONTROL": "Styring",
    "CATEGORY_SENSING": "Sansing",
    "CATEGORY_OPERATORS": "Operatorer",
    "CATEGORY_VARIABLES": "Variabler",
    "CATEGORY_MYBLOCKS": "Mine klosser",
    "CATEGORY_LISTS": "",
    "CATEGORY_TUPLE": "",
    "CATEGORY_DICTIONARYS": "",
    "CATEGORY_NUMBERS": "",
    "CATEGORY_TEXT": "",
    "DUPLICATE": "Lag en kopi",
    "DELETE": "Slett",
    "ADD_COMMENT": "Skriv en kommentar",
    "REMOVE_COMMENT": "Fjern kommentar",
    "DELETE_BLOCK": "Slett kloss",
    "DELETE_X_BLOCKS": "Slett %1 klosser",
    "DELETE_ALL_BLOCKS": "Slette disse %1  klossene?",
    "CLEAN_UP": "Rydd opp klossene",
    "HELP": "Hjelp",
    "UNDO": "Angre",
    "REDO": "Gjør igjen",
    "EDIT_PROCEDURE": "Rediger",
    "SHOW_PROCEDURE_DEFINITION": "Gå til definisjonen",
    "WORKSPACE_COMMENT_DEFAULT_TEXT": "Si noe...",
    "COLOUR_HUE_LABEL": "Farge",
    "COLOUR_SATURATION_LABEL": "Fargemetning",
    "COLOUR_BRIGHTNESS_LABEL": "Lysstyrke",
    "CHANGE_VALUE_TITLE": "Endre verdi:",
    "RENAME_VARIABLE": "Endre navn på variabel",
    "RENAME_VARIABLE_STRING": "",
    "RENAME_VARIABLE_NUMBER": "",
    "RENAME_VARIABLE_LIST": "",
    "RENAME_VARIABLE_TITLE": "Endre variabelnavn \"%1\" til:",
    "RENAME_VARIABLE_MODAL_TITLE": "Endre navn på variabel",
    "NEW_VARIABLE": "Lag en variabel",
    "NEW_VARIABLE_TITLE": "Nytt variabelnavn:",
    "VARIABLE_MODAL_TITLE": "Ny variabel",
    "VARIABLE_ALREADY_EXISTS": "Det finnes allerede en variabel som heter \"%1\".",
    "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "Det finnes allerede en %2-variabel som heter \"%1\"",
    "DELETE_VARIABLE_CONFIRMATION": "Slette variabelen \"%2\" som er brukt %1 ganger?",
    "CANNOT_DELETE_VARIABLE_PROCEDURE": "Kan ikke slette variabelen \"%1\" fordi den brukes i definisjonen av funksjonen \"%2\"",
    "DELETE_VARIABLE": "Slett variabelen \"%1\"",
    "DELETE_VARIABLE_STRING": "",
    "DELETE_VARIABLE_NUMBER": "",
    "DELETE_VARIABLE_LIST": "",
    "NEW_PROCEDURE": "Lag en kloss",
    "PROCEDURE_ALREADY_EXISTS": "Det finnes allerede en prosedyre som heter \"%1\".",
    "PROCEDURE_DEFAULT_NAME": "klossnavn",
    "NEW_LIST": "Lag en Liste",
    "NEW_LIST_TITLE": "Nytt navn på listen:",
    "LIST_MODAL_TITLE": "Ny liste",
    "LIST_ALREADY_EXISTS": "Det finnes allerede en liste som heter \"%1\".",
    "RENAME_LIST_TITLE": "Endre listenavnet \"%1\" til:",
    "RENAME_LIST_MODAL_TITLE": "Gi listen et annet navn",
    "DEFAULT_LIST_ITEM": "ting",
    "DELETE_LIST": "Slett listen \"%1\"",
    "RENAME_LIST": "Endre listenavn",
    "NEW_BROADCAST_MESSAGE": "Ny melding",
    "NEW_BROADCAST_MESSAGE_TITLE": "Skriv ny  melding:",
    "BROADCAST_MODAL_TITLE": "Ny melding",
    "DEFAULT_BROADCAST_MESSAGE_NAME": "melding1",
    "DELETE_PROCEDURE": "",
    "OK": "",
    "PROMPT": "",
    "STOP": "",
    "NEW_STRING": "",
    "NEW_NUMBER_VARIABLE": "",
    "WIFI": "",
    "USERNAME": "",
    "PWD": "",
    "IOT_SERVICE": "",
    "IOT_ID": "",
    "IOT_PWD": "",
    "IOT_TOPIC": "",
    "MORE_TOPIC": "",
    "OBLOQ_HTTP": "",
    "OBLOQ_IP": "",
    "OBLOQ_PORT": "",
    "ADD_TOPIC": "",
    "DELETE_TOPIC": "",
    "IOT_SERVER": "",
    "CHINA": "",
    "GLOBAL": "",
    "LOW_C": "",
    "LOW_C$": "",
    "LOW_D": "",
    "LOW_D$": "",
    "LOW_E": "",
    "LOW_F": "",
    "LOW_F$": "",
    "LOW_G": "",
    "LOW_G$": "",
    "LOW_A": "",
    "LOW_A$": "",
    "LOW_B": "",
    "MIDDLE_C": "",
    "MIDDLE_C$": "",
    "MIDDLE_D": "",
    "MIDDLE_D$": "",
    "MIDDLE_E": "",
    "MIDDLE_F": "",
    "MIDDLE_F$": "",
    "MIDDLE_G": "",
    "MIDDLE_G$": "",
    "MIDDLE_A": "",
    "MIDDLE_A$": "",
    "MIDDLE_B": "",
    "HIGH_C": "",
    "HIGH_C$": "",
    "HIGH_D": "",
    "HIGH_D$": "",
    "HIGH_E": "",
    "HIGH_F": "",
    "HIGH_F$": "",
    "HIGH_G": "",
    "HIGH_G$": "",
    "HIGH_A": "",
    "HIGH_A$": "",
    "HIGH_B": "",
    "OPERATORS_STRINGTONUMBER": "",
    "INTEGER": "",
    "DECIMAL": "",
    "OPERATORS_NUMTOASCII": "",
    "OPERATORS_STRINGTOASCII": "",
    "OPERATORS_NUMTOSTRING": "",
    "OPERATORS_MAP": "",
    "OPERATORS_CONSTRAIN": "",
    "STEPS_PER_TURN": "",
    "ROTATE_SPEED": "",
    "NEW_AI": "",
    "ASK_AND_PAINT": "",
    "GET_IDENTIFIED_NUM": "",
    "READ_NUM_AND_SAY": "",
    "IMAGE_ADDR": "",
    "IMAGE_PREVIEW": "",
    "IMAGE_OPEN": "",
    "IMAGE_SIZE": "",
    "IMAGE_WIDTH": "",
    "IMAGE_HEIGHT": "",
    "VARIABLE": "",
    "VARIABLE_LIST": "",
    "SET_STRING_VARIABLE": "",
    "STRING_START_WITH": "",
    "DICTIONARY_HEIGHT_FIRST": "",
    "DICTIONARY_HEIGHT_TWO": "",
    "DICTIONARY_HEIGHT_THREE": "",
    "DICTIONARY_HEIGHT_FOUR": "",
    "DATA_MPITEMOFLIST": "",
    "DATA_MPINTERCEPTITEM": "",
    "DATA_MPINSERTATLIST": "",
    "DATA_MPSETVALUEATLIST": "",
    "DATA_MPINSERTATLIST_INSERT": "",
    "DATA_MPINSERTATLIST_CHANGE": "",
    "DATA_MPINSERTATLIST_FOUR": "",
    "DATA_MPINSERTATLIST_FIVE": "",
    "DATA_MPINSERTATLIST_SIX": "",
    "DATA_MPINSERTATLIST_SEVEN": "",
    "DATA_MPINSERTATLIST_EIGHT": "",
    "DATA_MPINSERTATLIST_EIGHT_FALSE": "",
    "DATA_MPINSERTATLIST_EIGHT_TRUE": "",
    "DATA_MPINSERTATLIST_EIGHT_REVERSE": "",
    "OPERATORS_RANDOMA": "",
    "OPERATORS_MODA": "",
    "OPERATORS_ROUNDA": "",
    "OPERATORS_MATHOPA": "",
    "OPERATORS_MATHOP_ABSA": "",
    "OPERATORS_MATHOP_FLOORA": "",
    "OPERATORS_MATHOP_CEILINGA": "",
    "OPERATORS_MATHOP_SQRTA": "",
    "OPERATORS_MATHOP_FIVE": "",
    "OPERATORS_MATHOP_SIX": "",
    "OPERATORS_MATHOP_SEVEN": "",
    "OPERATORS_MATHOP_LENGHT": "",
    "OPERATORS_MATHOP_MAXMIN": "",
    "OPERATORS_MATHOP_MAXMIN_MAX": "",
    "OPERATORS_MATHOP_MAXMIN_MIN": "",
    "OPERATORS_TEXTS_ONE": "",
    "OPERATORS_TEXTS_TWO": "",
    "OPERATORS_TEXTS_THREE": "",
    "OPERATORS_TEXTS_FOUR": "",
    "OPERATORS_TEXTS_FOUR_NEW": "",
    "OPERATORS_TEXTS_NEW": "",
    "OPERATORS_TEXTS_NEW_OP": "",
    "OPERATORS_TEXTS_FOUR_ONE": "",
    "OPERATORS_TEXTS_FOUR_TWO": "",
    "OPERATORS_TEXTS_FOUR_THREE": "",
    "OPERATORS_TEXTS_FOUR_FOUR": "",
    "OPERATORS_TEXTS_FOUR_F": "",
    "OPERATORS_TEXTS_FOUR_L": "",
    "OPERATORS_STRINGTONUMBERA": "",
    "OPERATORS_NUMTOASCIIA": "",
    "OPERATORS_STRINGTOASCIIA": "",
    "OPERATORS_NUMTOSTRINGA": "",
    "NEW_MC": "",
    "RENAME_MCNUMBER_TITLE": "",
    "RENAME_MCNUMBER_MODAL_TITLE": "",
    "RENAME_VARIABLE_MCNUMBER": "",
    "DELETE_VARIABLE_MCNUMBER": "",
    "SET_MC_VARIABLE": "",
    "TUPLE_ONE": "",
    "TUPLE_TWO": "",
    "ALIYUN": "",
    "ONENET": "",
    "EASYIOT": "",
    "SHANGHAI": "",
    "QINGDAO": "",
    "BEIJING": "",
    "ZHANGJIAKOU": "",
    "HUHEHAOTE": "",
    "HANGZHOU": "",
    "SHENZHEN": "",
    "HONGKONG": "",
    "SINGAPORE": "",
    "SYDNEY": "",
    "KUALALUMPUR": "",
    "JAKARTA": "",
    "MUMBAI": "",
    "TOKYO": "",
    "SILICONVALLEY": "",
    "VIRGINIA": "",
    "FRANKFURT": "",
    "LONDON": "",
    "DUBAI": "",
    "IOT_PLATFORM": "",
    "PARAMS": "",
    "SERVER_ATTR": "",
    "PRODUCTID": "",
    "DEVICEID": "",
    "TINYDB_SECRET": "",
    "TINYDB_USER": "",
    "APIADDR": "",
    "SOFTWARE_SERIAL": "",
    "HARDWARE_SERIAL": "",
    "HARDWARE_SERIAL1": "",
    "HARDWARE_SERIAL2": "",
    "HARDWARE_SERIAL3": ""
  },
  "nn": {
    "CONTROL_FOREVER": "for alltid",
    "CONTROL_REPEAT": "gjenta %1 gongar",
    "CONTROL_IF": "viss %1",
    "CONTROL_ELSE": "elles",
    "CONTROL_STOP": "stopp",
    "CONTROL_STOP_ALL": "alle",
    "CONTROL_STOP_THIS": "dette skriptet",
    "CONTROL_STOP_OTHER": "andre skript i figuren",
    "CONTROL_WAIT": "vent %1 sekund",
    "CONTROL_WAITUNTIL": "vent til %1",
    "CONTROL_REPEATUNTIL": "gjenta til %1",
    "CONTROL_WHILE": "gjenta viss %1",
    "CONTROL_FOREACH": "for kvar %1 i %2",
    "CONTROL_STARTASCLONE": "når eg startar som klon",
    "CONTROL_CREATECLONEOF": "lag klon av %1",
    "CONTROL_CREATECLONEOF_MYSELF": "meg",
    "CONTROL_DELETETHISCLONE": "slett denne klonen",
    "CONTROL_COUNTER": "teljar",
    "CONTROL_INCRCOUNTER": "auk teljar",
    "CONTROL_CLEARCOUNTER": "tøm teljar",
    "CONTROL_ALLATONCE": "alle på éin gong",
    "DATA_SETVARIABLETO": "set %1 til %2",
    "DATA_CHANGEVARIABLEBY": "endra %1 med %2",
    "DATA_SHOWVARIABLE": "vis variabelen %1",
    "DATA_HIDEVARIABLE": "gøym variabelen %1",
    "DATA_ADDTOLIST": "legg %1 til %2",
    "DATA_DELETEOFLIST": "slett element %1 i %2",
    "DATA_DELETEALLOFLIST": "slett alt i %1",
    "DATA_INSERTATLIST": "set inn %1 på plass %2 i %3",
    "DATA_REPLACEITEMOFLIST": "byt ut element %1 i %2 med %3",
    "DATA_ITEMOFLIST": "element %1 i %2",
    "DATA_ITEMNUMOFLIST": "posisjon til %1 i %2",
    "DATA_LENGTHOFLIST": "lengda til %1",
    "DATA_LISTCONTAINSITEM": "%1 inneheld %2?",
    "DATA_SHOWLIST": "vis lista %1",
    "DATA_HIDELIST": "gøym lista %1",
    "DATA_INDEX_ALL": "alle",
    "DATA_INDEX_LAST": "siste",
    "DATA_INDEX_RANDOM": "tilfeldig",
    "EVENT_WHENFLAGCLICKED": "når %1 vert trykt på",
    "EVENT_WHENTHISSPRITECLICKED": "når denne figuren vert trykt på",
    "EVENT_WHENSTAGECLICKED": "når scena vert trykt på",
    "EVENT_WHENTOUCHINGOBJECT": "når figuren rører %1",
    "EVENT_WHENBROADCASTRECEIVED": "når eg får meldinga %1",
    "EVENT_WHENBACKDROPSWITCHESTO": "når bakgrunnen byter til %1",
    "EVENT_WHENGREATERTHAN": "når %1 > %2",
    "EVENT_WHENGREATERTHAN_TIMER": "klokke",
    "EVENT_WHENGREATERTHAN_LOUDNESS": "lydstyrke",
    "EVENT_BROADCAST": "send meldinga %1",
    "EVENT_BROADCASTANDWAIT": "send meldinga %1 og vent",
    "EVENT_WHENKEYPRESSED": "når %1 vert trykt",
    "EVENT_WHENKEYPRESSED_SPACE": "mellomrom",
    "EVENT_WHENKEYPRESSED_LEFT": "pil venstre",
    "EVENT_WHENKEYPRESSED_RIGHT": "pil høgre",
    "EVENT_WHENKEYPRESSED_DOWN": "pil ned",
    "EVENT_WHENKEYPRESSED_UP": "pil opp",
    "EVENT_WHENKEYPRESSED_ANY": "vilkårleg",
    "LOOKS_SAYFORSECS": "sei %1 i %2 sekund",
    "LOOKS_SAY": "sei %1",
    "LOOKS_HELLO": "Hei!",
    "LOOKS_THINKFORSECS": "tenk %1 i %2 sekund",
    "LOOKS_THINK": "tenk %1",
    "LOOKS_HMM": "Hm …",
    "LOOKS_SHOW": "vis",
    "LOOKS_HIDE": "gøym",
    "LOOKS_HIDEALLSPRITES": "gøym alle figurar",
    "LOOKS_EFFECT_COLOR": "farge",
    "LOOKS_EFFECT_FISHEYE": "fiskeauge",
    "LOOKS_EFFECT_WHIRL": "kvervel",
    "LOOKS_EFFECT_PIXELATE": "pikselering",
    "LOOKS_EFFECT_MOSAIC": "mosaikk",
    "LOOKS_EFFECT_BRIGHTNESS": "lysstyrke",
    "LOOKS_EFFECT_GHOST": "gjennomsiktig",
    "LOOKS_CHANGEEFFECTBY": "endra %1-effekten med %2",
    "LOOKS_SETEFFECTTO": "set %1-effekten til %2",
    "LOOKS_CLEARGRAPHICEFFECTS": "ta vekk grafiske effektar",
    "LOOKS_CHANGESIZEBY": "endra storleik med %1",
    "LOOKS_SETSIZETO": "set storleik til %1 %",
    "LOOKS_SIZE": "storleik",
    "LOOKS_CHANGESTRETCHBY": "endra strekking med %1",
    "LOOKS_SETSTRETCHTO": "set strekking til %1 %",
    "LOOKS_SWITCHCOSTUMETO": "byt drakt til %1",
    "LOOKS_NEXTCOSTUME": "neste drakt",
    "LOOKS_SWITCHBACKDROPTO": "byt bakgrunn til %1",
    "LOOKS_GOTOFRONTBACK": "legg %1",
    "LOOKS_GOTOFRONTBACK_FRONT": "framfor alt",
    "LOOKS_GOTOFRONTBACK_BACK": "bak alt",
    "LOOKS_GOFORWARDBACKWARDLAYERS": "gå %1 %2 lag",
    "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "framover",
    "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "bakover",
    "LOOKS_BACKDROPNUMBERNAME": "bakgrunn %1",
    "LOOKS_COSTUMENUMBERNAME": "drakt %1",
    "LOOKS_NUMBERNAME_NUMBER": "tal",
    "LOOKS_NUMBERNAME_NAME": "namn",
    "LOOKS_SWITCHBACKDROPTOANDWAIT": "byt bakgrunn til %1 og vent",
    "LOOKS_NEXTBACKDROP_BLOCK": "neste bakgrunn",
    "LOOKS_NEXTBACKDROP": "neste bakgrunn",
    "LOOKS_PREVIOUSBACKDROP": "førre bakgrunn",
    "LOOKS_RANDOMBACKDROP": "tilfeldig bakgrunn",
    "MOTION_MOVESTEPS": "gå %1 steg",
    "MOTION_TURNLEFT": "snu %1 %2 gradar",
    "MOTION_TURNRIGHT": "snu %1 %2 gradar",
    "MOTION_POINTINDIRECTION": "peik i retning %1",
    "MOTION_POINTTOWARDS": "peik mot %1",
    "MOTION_POINTTOWARDS_POINTER": "musepeikar",
    "MOTION_POINTTOWARDS_RANDOM": "tilfeldig retning",
    "MOTION_GOTO": "gå til %1",
    "MOTION_GOTO_POINTER": "musepeikar",
    "MOTION_GOTO_RANDOM": "tilfeldig plassering",
    "MOTION_GOTOXY": "gå til x: %1 y: %2",
    "MOTION_GLIDESECSTOXY": "gli %1 sekund til x: %2 y: %3",
    "MOTION_GLIDETO": "gli %1 sekund til %2",
    "MOTION_GLIDETO_POINTER": "musepeikar",
    "MOTION_GLIDETO_RANDOM": "tilfeldig plassering",
    "MOTION_CHANGEXBY": "endra x med %1",
    "MOTION_SETX": "set x til %1",
    "MOTION_CHANGEYBY": "endra y med %1",
    "MOTION_SETY": "set y til %1",
    "MOTION_IFONEDGEBOUNCE": "viss ved kant, sprett",
    "MOTION_SETROTATIONSTYLE": "avgrens rotering til %1",
    "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "venstre–høgre",
    "MOTION_SETROTATIONSTYLE_DONTROTATE": "ikkje roter",
    "MOTION_SETROTATIONSTYLE_ALLAROUND": "roter fritt",
    "MOTION_XPOSITION": "x-posisjon",
    "MOTION_YPOSITION": "y-posisjon",
    "MOTION_DIRECTION": "retning",
    "MOTION_SCROLLRIGHT": "rull til høgre %1",
    "MOTION_SCROLLUP": "rull opp %1",
    "MOTION_ALIGNSCENE": "flytt bakgrunn %1",
    "MOTION_ALIGNSCENE_BOTTOMLEFT": "nede til venstre",
    "MOTION_ALIGNSCENE_BOTTOMRIGHT": "nede til høgre",
    "MOTION_ALIGNSCENE_MIDDLE": "i midten",
    "MOTION_ALIGNSCENE_TOPLEFT": "oppe til venstre",
    "MOTION_ALIGNSCENE_TOPRIGHT": "oppe til høgre",
    "MOTION_XSCROLL": "rull x",
    "MOTION_YSCROLL": "rull y",
    "MOTION_STAGE_SELECTED": "Scene vald: ingen rørsleklossar",
    "OPERATORS_ADD": "%1 + %2",
    "OPERATORS_SUBTRACT": "%1 − %2",
    "OPERATORS_MULTIPLY": "%1 × %2",
    "OPERATORS_DIVIDE": "%1 : %2",
    "OPERATORS_RANDOM": "tilfeldig tal frå %1 til %2",
    "OPERATORS_GT": "%1 > %2",
    "OPERATORS_GT_EQUALS": "",
    "OPERATORS_LT": "%1 < %2",
    "OPERATORS_LT_EQUALS": "",
    "OPERATORS_EQUALS": "%1 = %2",
    "OPERATORS_AND": "%1 og %2",
    "OPERATORS_OR": "%1 eller %2",
    "OPERATORS_NOT": "ikkje %1",
    "OPERATORS_JOIN": "set saman %1 %2",
    "OPERATORS_JOIN_APPLE": "eple",
    "OPERATORS_JOIN_BANANA": "banan",
    "OPERATORS_LETTEROF": "bokstav %1 i %2",
    "OPERATORS_LETTEROF_APPLE": "e",
    "OPERATORS_LENGTH": "lengda til %1",
    "OPERATORS_CONTAINS": "%1 inneheld %2?",
    "OPERATORS_MOD": "%1 mod %2",
    "OPERATORS_ROUND": "rund av %1",
    "OPERATORS_MATHOP": "%1 av %2",
    "OPERATORS_MATHOP_ABS": "abs",
    "OPERATORS_MATHOP_FLOOR": "golv",
    "OPERATORS_MATHOP_CEILING": "tak",
    "OPERATORS_MATHOP_SQRT": "kvadratrot",
    "OPERATORS_MATHOP_SIN": "sin",
    "OPERATORS_MATHOP_COS": "cos",
    "OPERATORS_MATHOP_TAN": "tan",
    "OPERATORS_MATHOP_ASIN": "arcsin",
    "OPERATORS_MATHOP_ACOS": "arccos",
    "OPERATORS_MATHOP_ATAN": "arctan",
    "OPERATORS_MATHOP_LN": "ln",
    "OPERATORS_MATHOP_LOG": "log",
    "OPERATORS_MATHOP_EEXP": "e^",
    "OPERATORS_MATHOP_10EXP": "10^",
    "PROCEDURES_DEFINITION": "definer %1",
    "SENSING_TOUCHINGOBJECT": "rører %1",
    "SENSING_TOUCHINGOBJECT_POINTER": "musepeikar",
    "SENSING_TOUCHINGOBJECT_EDGE": "kant",
    "SENSING_TOUCHINGCOLOR": "rører fargen %1?",
    "SENSING_COLORISTOUCHINGCOLOR": "fargen %1 rører %2?",
    "SENSING_DISTANCETO": "avstand til %1",
    "SENSING_DISTANCETO_POINTER": "musepeikar",
    "SENSING_ASKANDWAIT": "spør %1 og vent",
    "SENSING_ASK_TEXT": "Kva heiter du?",
    "SENSING_ANSWER": "svar",
    "SENSING_KEYPRESSED": "tasten %1 er trykt?",
    "SENSING_MOUSEDOWN": "museknappen er trykt?",
    "SENSING_MOUSEX": "mus x",
    "SENSING_MOUSEY": "mus y",
    "SENSING_SETDRAGMODE": "%1 å dra med musa",
    "SENSING_SETDRAGMODE_DRAGGABLE": "tillate",
    "SENSING_SETDRAGMODE_NOTDRAGGABLE": "ikkje tillate",
    "SENSING_LOUDNESS": "lydstyrke",
    "SENSING_LOUD": "høg lyd?",
    "SENSING_TIMER": "sekundklokke",
    "SENSING_RESETTIMER": "nullstill klokka",
    "SENSING_OF": "%1 av %2",
    "SENSING_OF_XPOSITION": "x-posisjon",
    "SENSING_OF_YPOSITION": "y-posisjon",
    "SENSING_OF_DIRECTION": "retning",
    "SENSING_OF_COSTUMENUMBER": "drakt nr.",
    "SENSING_OF_COSTUMENAME": "draktnamn",
    "SENSING_OF_SIZE": "storleik",
    "SENSING_OF_VOLUME": "lydstyrke",
    "SENSING_OF_BACKDROPNUMBER": "bakgrunn nr.",
    "SENSING_OF_BACKDROPNAME": "bakgrunnsnamn",
    "SENSING_OF_STAGE": "Scene",
    "SENSING_CURRENT": "%1 nett no",
    "SENSING_CURRENT_YEAR": "året",
    "SENSING_CURRENT_MONTH": "månaden",
    "SENSING_CURRENT_DATE": "datoen",
    "SENSING_CURRENT_DAYOFWEEK": "vekedagen",
    "SENSING_CURRENT_HOUR": "timen",
    "SENSING_CURRENT_MINUTE": "minuttet",
    "SENSING_CURRENT_SECOND": "sekundet",
    "SENSING_DAYSSINCE2000": "dagar sidan år 2000",
    "SENSING_USERNAME": "brukarnamn",
    "SENSING_USERID": "brukar-id",
    "SOUND_PLAY": "start lyden %1",
    "SOUND_PLAYUNTILDONE": "spel lyden %1 til han er ferdig",
    "SOUND_STOPALLSOUNDS": "stopp alle lydar",
    "SOUND_SETEFFECTO": "set %1-effekt til %2",
    "SOUND_CHANGEEFFECTBY": "endra %1-effekt med %2",
    "SOUND_CLEAREFFECTS": "fjern lydeffektar",
    "SOUND_EFFECTS_PITCH": "tonehøgd",
    "SOUND_EFFECTS_PAN": "venstre–høgre-panorering",
    "SOUND_CHANGEVOLUMEBY": "endra lydstyrken med %1",
    "SOUND_SETVOLUMETO": "set lydstyrken til %1 %",
    "SOUND_VOLUME": "lydstyrke",
    "SOUND_RECORD": "spel inn …",
    "CATEGORY_MOTION": "Rørsle",
    "CATEGORY_LOOKS": "Utsjånad",
    "CATEGORY_SOUND": "Lyd",
    "CATEGORY_EVENTS": "Hendingar",
    "CATEGORY_CONTROL": "Styring",
    "CATEGORY_SENSING": "Sansing",
    "CATEGORY_OPERATORS": "Operatorar",
    "CATEGORY_VARIABLES": "Variablar",
    "CATEGORY_MYBLOCKS": "Mine klossar",
    "CATEGORY_LISTS": "",
    "CATEGORY_TUPLE": "",
    "CATEGORY_DICTIONARYS": "",
    "CATEGORY_NUMBERS": "",
    "CATEGORY_TEXT": "",
    "DUPLICATE": "Lag ein kopi",
    "DELETE": "Slett",
    "ADD_COMMENT": "Skriv kommentar",
    "REMOVE_COMMENT": "Fjern kommentar",
    "DELETE_BLOCK": "Slett kloss",
    "DELETE_X_BLOCKS": "Slett %1 klossar",
    "DELETE_ALL_BLOCKS": "Vil du sletta alle %1 klossane?",
    "CLEAN_UP": "Rydd opp i klossar",
    "HELP": "Hjelp",
    "UNDO": "Angra",
    "REDO": "Gjer om",
    "EDIT_PROCEDURE": "Rediger",
    "SHOW_PROCEDURE_DEFINITION": "Gå til definisjonen",
    "WORKSPACE_COMMENT_DEFAULT_TEXT": "Sei noko …",
    "COLOUR_HUE_LABEL": "Farge",
    "COLOUR_SATURATION_LABEL": "Fargemetting",
    "COLOUR_BRIGHTNESS_LABEL": "Lysstyrke",
    "CHANGE_VALUE_TITLE": "Endra verdi:",
    "RENAME_VARIABLE": "Gje nytt namn",
    "RENAME_VARIABLE_STRING": "",
    "RENAME_VARIABLE_NUMBER": "",
    "RENAME_VARIABLE_LIST": "",
    "RENAME_VARIABLE_TITLE": "Endra namnet på alle «%1»-variablane til:",
    "RENAME_VARIABLE_MODAL_TITLE": "Gje nytt namn",
    "NEW_VARIABLE": "Lag ein variabel",
    "NEW_VARIABLE_TITLE": "Namn på ny variabel:",
    "VARIABLE_MODAL_TITLE": "Ny variabel",
    "VARIABLE_ALREADY_EXISTS": "Det finst alt ein variabel som heiter «%1».",
    "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "Det finst alt ein variabel som heiter «%1», for ein annan variabel av type «%2».",
    "DELETE_VARIABLE_CONFIRMATION": "Vil du sletta %1 bruk av variabelen «%2»?",
    "CANNOT_DELETE_VARIABLE_PROCEDURE": "Kan ikkje sletta variabelen «%1», då han er del av definisjonen til funksjonen «%2».",
    "DELETE_VARIABLE": "Slett variabelen «%1»",
    "DELETE_VARIABLE_STRING": "",
    "DELETE_VARIABLE_NUMBER": "",
    "DELETE_VARIABLE_LIST": "",
    "NEW_PROCEDURE": "Lag ein kloss",
    "PROCEDURE_ALREADY_EXISTS": "Det finst alt ein prosedyre som heiter «%1».",
    "PROCEDURE_DEFAULT_NAME": "klossnamn",
    "NEW_LIST": "Lag ei liste",
    "NEW_LIST_TITLE": "Namn på ny liste:",
    "LIST_MODAL_TITLE": "Ny liste",
    "LIST_ALREADY_EXISTS": "Det finst alt ei liste som heiter «%1».",
    "RENAME_LIST_TITLE": "Endra namnet på alle «%1»-listene til:",
    "RENAME_LIST_MODAL_TITLE": "Gje nytt namn",
    "DEFAULT_LIST_ITEM": "ting",
    "DELETE_LIST": "Slett lista «%1»",
    "RENAME_LIST": "Gje nytt namn",
    "NEW_BROADCAST_MESSAGE": "Ny melding",
    "NEW_BROADCAST_MESSAGE_TITLE": "Namn på ny melding:",
    "BROADCAST_MODAL_TITLE": "Ny melding",
    "DEFAULT_BROADCAST_MESSAGE_NAME": "melding1",
    "DELETE_PROCEDURE": "",
    "OK": "",
    "PROMPT": "",
    "STOP": "",
    "NEW_STRING": "",
    "NEW_NUMBER_VARIABLE": "",
    "WIFI": "",
    "USERNAME": "",
    "PWD": "",
    "IOT_SERVICE": "",
    "IOT_ID": "",
    "IOT_PWD": "",
    "IOT_TOPIC": "",
    "MORE_TOPIC": "",
    "OBLOQ_HTTP": "",
    "OBLOQ_IP": "",
    "OBLOQ_PORT": "",
    "ADD_TOPIC": "",
    "DELETE_TOPIC": "",
    "IOT_SERVER": "",
    "CHINA": "",
    "GLOBAL": "",
    "LOW_C": "",
    "LOW_C$": "",
    "LOW_D": "",
    "LOW_D$": "",
    "LOW_E": "",
    "LOW_F": "",
    "LOW_F$": "",
    "LOW_G": "",
    "LOW_G$": "",
    "LOW_A": "",
    "LOW_A$": "",
    "LOW_B": "",
    "MIDDLE_C": "",
    "MIDDLE_C$": "",
    "MIDDLE_D": "",
    "MIDDLE_D$": "",
    "MIDDLE_E": "",
    "MIDDLE_F": "",
    "MIDDLE_F$": "",
    "MIDDLE_G": "",
    "MIDDLE_G$": "",
    "MIDDLE_A": "",
    "MIDDLE_A$": "",
    "MIDDLE_B": "",
    "HIGH_C": "",
    "HIGH_C$": "",
    "HIGH_D": "",
    "HIGH_D$": "",
    "HIGH_E": "",
    "HIGH_F": "",
    "HIGH_F$": "",
    "HIGH_G": "",
    "HIGH_G$": "",
    "HIGH_A": "",
    "HIGH_A$": "",
    "HIGH_B": "",
    "OPERATORS_STRINGTONUMBER": "",
    "INTEGER": "",
    "DECIMAL": "",
    "OPERATORS_NUMTOASCII": "",
    "OPERATORS_STRINGTOASCII": "",
    "OPERATORS_NUMTOSTRING": "",
    "OPERATORS_MAP": "",
    "OPERATORS_CONSTRAIN": "",
    "STEPS_PER_TURN": "",
    "ROTATE_SPEED": "",
    "NEW_AI": "",
    "ASK_AND_PAINT": "",
    "GET_IDENTIFIED_NUM": "",
    "READ_NUM_AND_SAY": "",
    "IMAGE_ADDR": "",
    "IMAGE_PREVIEW": "",
    "IMAGE_OPEN": "",
    "IMAGE_SIZE": "",
    "IMAGE_WIDTH": "",
    "IMAGE_HEIGHT": "",
    "VARIABLE": "",
    "VARIABLE_LIST": "",
    "SET_STRING_VARIABLE": "",
    "STRING_START_WITH": "",
    "DICTIONARY_HEIGHT_FIRST": "",
    "DICTIONARY_HEIGHT_TWO": "",
    "DICTIONARY_HEIGHT_THREE": "",
    "DICTIONARY_HEIGHT_FOUR": "",
    "DATA_MPITEMOFLIST": "",
    "DATA_MPINTERCEPTITEM": "",
    "DATA_MPINSERTATLIST": "",
    "DATA_MPSETVALUEATLIST": "",
    "DATA_MPINSERTATLIST_INSERT": "",
    "DATA_MPINSERTATLIST_CHANGE": "",
    "DATA_MPINSERTATLIST_FOUR": "",
    "DATA_MPINSERTATLIST_FIVE": "",
    "DATA_MPINSERTATLIST_SIX": "",
    "DATA_MPINSERTATLIST_SEVEN": "",
    "DATA_MPINSERTATLIST_EIGHT": "",
    "DATA_MPINSERTATLIST_EIGHT_FALSE": "",
    "DATA_MPINSERTATLIST_EIGHT_TRUE": "",
    "DATA_MPINSERTATLIST_EIGHT_REVERSE": "",
    "OPERATORS_RANDOMA": "",
    "OPERATORS_MODA": "",
    "OPERATORS_ROUNDA": "",
    "OPERATORS_MATHOPA": "",
    "OPERATORS_MATHOP_ABSA": "",
    "OPERATORS_MATHOP_FLOORA": "",
    "OPERATORS_MATHOP_CEILINGA": "",
    "OPERATORS_MATHOP_SQRTA": "",
    "OPERATORS_MATHOP_FIVE": "",
    "OPERATORS_MATHOP_SIX": "",
    "OPERATORS_MATHOP_SEVEN": "",
    "OPERATORS_MATHOP_LENGHT": "",
    "OPERATORS_MATHOP_MAXMIN": "",
    "OPERATORS_MATHOP_MAXMIN_MAX": "",
    "OPERATORS_MATHOP_MAXMIN_MIN": "",
    "OPERATORS_TEXTS_ONE": "",
    "OPERATORS_TEXTS_TWO": "",
    "OPERATORS_TEXTS_THREE": "",
    "OPERATORS_TEXTS_FOUR": "",
    "OPERATORS_TEXTS_FOUR_NEW": "",
    "OPERATORS_TEXTS_NEW": "",
    "OPERATORS_TEXTS_NEW_OP": "",
    "OPERATORS_TEXTS_FOUR_ONE": "",
    "OPERATORS_TEXTS_FOUR_TWO": "",
    "OPERATORS_TEXTS_FOUR_THREE": "",
    "OPERATORS_TEXTS_FOUR_FOUR": "",
    "OPERATORS_TEXTS_FOUR_F": "",
    "OPERATORS_TEXTS_FOUR_L": "",
    "OPERATORS_STRINGTONUMBERA": "",
    "OPERATORS_NUMTOASCIIA": "",
    "OPERATORS_STRINGTOASCIIA": "",
    "OPERATORS_NUMTOSTRINGA": "",
    "NEW_MC": "",
    "RENAME_MCNUMBER_TITLE": "",
    "RENAME_MCNUMBER_MODAL_TITLE": "",
    "RENAME_VARIABLE_MCNUMBER": "",
    "DELETE_VARIABLE_MCNUMBER": "",
    "SET_MC_VARIABLE": "",
    "TUPLE_ONE": "",
    "TUPLE_TWO": "",
    "ALIYUN": "",
    "ONENET": "",
    "EASYIOT": "",
    "SHANGHAI": "",
    "QINGDAO": "",
    "BEIJING": "",
    "ZHANGJIAKOU": "",
    "HUHEHAOTE": "",
    "HANGZHOU": "",
    "SHENZHEN": "",
    "HONGKONG": "",
    "SINGAPORE": "",
    "SYDNEY": "",
    "KUALALUMPUR": "",
    "JAKARTA": "",
    "MUMBAI": "",
    "TOKYO": "",
    "SILICONVALLEY": "",
    "VIRGINIA": "",
    "FRANKFURT": "",
    "LONDON": "",
    "DUBAI": "",
    "IOT_PLATFORM": "",
    "PARAMS": "",
    "SERVER_ATTR": "",
    "PRODUCTID": "",
    "DEVICEID": "",
    "TINYDB_SECRET": "",
    "TINYDB_USER": "",
    "APIADDR": "",
    "SOFTWARE_SERIAL": "",
    "HARDWARE_SERIAL": "",
    "HARDWARE_SERIAL1": "",
    "HARDWARE_SERIAL2": "",
    "HARDWARE_SERIAL3": ""
  },
  "th": {
    "CONTROL_FOREVER": "วนซ้ำตลอด",
    "CONTROL_LOOP_BREAKCONTINUE": "%1",
    "CONTROL_LOOP_BREAKCONTINUE_BREAK": "break",
    "CONTROL_LOOP_BREAKCONTINUE_CONTINUE": "continue",
    "CONTROL_REPEAT": "ทำซ้ำ %1",
    "CONTROL_IF": "ถ้า %1 แล้ว",
    "CONTROL_ELSE": "มิฉะนั้น",
    "CONTROL_STOP": "หยุด",
    "CONTROL_STOP_ALL": "ทั้งหมด",
    "CONTROL_STOP_THIS": "สคริปต์นี้",
    "CONTROL_STOP_OTHER": "สริปต์อื่นในตัวละคร",
    "CONTROL_WAIT": "รอ %1 วินาที",
    "CONTROL_WAIT_ESP32_MPY": "wait %1 %2",
    "CONTROL_FOR_FROM_SEQUENCE": "use %1 from sequence %2",
    "CONTROL_WAIT_ESP32_MPY_S": "second(s)",
    "CONTROL_WAIT_ESP32_MPY_MS": "millisecond(ms)",
    "CONTROL_WAIT_ESP32_MPY_US": "microsecond(us)",
    "CONTROL_WAITUNTIL": "รอจนกระทั่ง %1",
    "CONTROL_REPEATUNTIL": "ทำซ้ำจน %1",
    "CONTROL_WHILE": "ขณะที่ %1",
    "CONTROL_FOREACH": "สำหรับแต่ละ %1 ใน %2",
    "CONTROL_STARTASCLONE": "เมื่อฉันเริ่มในฐานะโคลน",
    "CONTROL_CREATECLONEOF": "สร้าง โคลน ของ %1",
    "CONTROL_CREATECLONEOF_MYSELF": "ตัวฉันเอง",
    "CONTROL_DELETETHISCLONE": "ลบโคลนตัวนี้",
    "CONTROL_COUNTER": "ตัวนับ",
    "CONTROL_INCRCOUNTER": "ตัวนับเพิ่ม",
    "CONTROL_CLEARCOUNTER": "ล้างตัวนับ",
    "CONTROL_ALLATONCE": "ทั้งหมดในครั้งเดียว",
    "DATA_SETVARIABLETO": "ตั้งค่า %1 เป็น %2",
    "DATA_CHANGEVARIABLEBY": "เปลี่ยน %1 ทีละ %2",
    "DATA_SHOWVARIABLE": "แสดงตัวแปร %1",
    "DATA_HIDEVARIABLE": "ซ่อนตัวแปร %1",
    "DATA_ADDTOLIST": "เพิ่ม %1 ไปยัง %2",
    "DATA_DELETEOFLIST": "ลบรายการที่ %1 ออกจาก %2",
    "DATA_DELETEALLOFLIST": "ลบทั้งหมดจาก %1",
    "DATA_INSERTATLIST": "แทรก %1 ที่ลำดับ %2 ของ %3",
    "DATA_REPLACEITEMOFLIST": "แทนที่รายการที่ %1 ของ %2 ด้วย %3",
    "DATA_ITEMOFLIST": "รายการที่ %1 ของ %2",
    "DATA_ITEMNUMOFLIST": " รายการ # ของ %1 จาก %2",
    "DATA_LENGTHOFLIST": "ความยาวของ %1",
    "DATA_LISTCONTAINSITEM": "%1รวมถึง %2?",
    "DATA_SHOWLIST": "แสดงรายการ %1",
    "DATA_HIDELIST": "ซ่อนรายการ %1",
    "DATA_INDEX_ALL": "ทั้งหมด",
    "DATA_INDEX_LAST": "สุดท้าย",
    "DATA_INDEX_RANDOM": "สุ่ม",
    "EVENT_WHENFLAGCLICKED": "เมื่อคลิก %1",
    "EVENT_WHENTHISSPRITECLICKED": "เมื่อตัวละครนี้ถูกคลิก",
    "EVENT_WHENSTAGECLICKED": "เมื่อคลิกเวที",
    "EVENT_WHENTOUCHINGOBJECT": "เมื่อสไปรต์นี้แตะ %1",
    "EVENT_WHENBROADCASTRECEIVED": "เมื่อฉันได้รับ %1",
    "EVENT_WHENBACKDROPSWITCHESTO": "เมื่อฉากหลังเปลี่ยนเป็น %1",
    "EVENT_WHENGREATERTHAN": "เมื่อ %1 > %2",
    "EVENT_WHENGREATERTHAN_TIMER": "ตัวจับเวลา",
    "EVENT_WHENGREATERTHAN_LOUDNESS": "ระดับความดัง",
    "EVENT_BROADCAST": "กระจาย %1",
    "EVENT_BROADCASTANDWAIT": "กระจาย %1 และรอ",
    "EVENT_WHENKEYPRESSED": "เมื่อกดปุ่ม %1",
    "EVENT_WHENKEYPRESSED_SPACE": "สเปซบาร์",
    "EVENT_WHENKEYPRESSED_LEFT": "ลูกศรชี้ทางซ้าย",
    "EVENT_WHENKEYPRESSED_RIGHT": "ลูกศรชี้ขวา",
    "EVENT_WHENKEYPRESSED_DOWN": "ลูกศรชี้ลง",
    "EVENT_WHENKEYPRESSED_UP": "ลูกศรขึ้น",
    "EVENT_WHENKEYPRESSED_ANY": "อะไรก็ได้",
    "LOOKS_SAYFORSECS": "พูด %1   %2 วินาที",
    "LOOKS_SAY": "พูด %1",
    "LOOKS_HELLO": "สวัสดี!",
    "LOOKS_THINKFORSECS": "คิด %1   %2 วินาที",
    "LOOKS_THINK": "คิด %1",
    "LOOKS_HMM": "อืมม...",
    "LOOKS_SHOW": "แสดง",
    "LOOKS_HIDE": "ซ่อน",
    "LOOKS_HIDEALLSPRITES": "ซ่อนสไปรต์ทั้งหมด",
    "LOOKS_EFFECT_COLOR": "สี",
    "LOOKS_EFFECT_FISHEYE": "ตาปลา",
    "LOOKS_EFFECT_WHIRL": "หมุนวน",
    "LOOKS_EFFECT_PIXELATE": "แยกพิกเซล",
    "LOOKS_EFFECT_MOSAIC": "โมเสก",
    "LOOKS_EFFECT_BRIGHTNESS": "ความสว่าง",
    "LOOKS_EFFECT_GHOST": "ล่องหน",
    "LOOKS_CHANGEEFFECTBY": "เปลี่ยนเอฟเฟกต์ %1 ทีละ %2",
    "LOOKS_SETEFFECTTO": "ตั้งค่าเอฟเฟกต์ %1 เป็น %2",
    "LOOKS_CLEARGRAPHICEFFECTS": "ล้างเอฟเฟคภาพ",
    "LOOKS_CHANGESIZEBY": "เปลี่ยนขนาดทีละ %1",
    "LOOKS_SETSIZETO": "กำหนดขนาดเป็น %1 %",
    "LOOKS_SIZE": "ขนาด",
    "LOOKS_CHANGESTRETCHBY": "เปลี่ยนความยืดทีละ %1",
    "LOOKS_SETSTRETCHTO": "ตั้งค่าความยืดเป็น %1 %",
    "LOOKS_SWITCHCOSTUMETO": "เปลี่ยนคอสตูมเป็น %1",
    "LOOKS_NEXTCOSTUME": "ชุดถัดไป",
    "LOOKS_SWITCHBACKDROPTO": "เปลี่ยนฉากหลังเป็น %1",
    "LOOKS_GOTOFRONTBACK": "ไปที่ระดับ %1 ",
    "LOOKS_GOTOFRONTBACK_FRONT": "หน้าสุด",
    "LOOKS_GOTOFRONTBACK_BACK": "หลังสุด",
    "LOOKS_GOFORWARDBACKWARDLAYERS": "ไป %1 %2 ระดับ",
    "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "ด้านหน้า",
    "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "ด้านหลัง",
    "LOOKS_BACKDROPNUMBERNAME": "ฉากหลัง %1",
    "LOOKS_COSTUMENUMBERNAME": "คอสตูม %1",
    "LOOKS_NUMBERNAME_NUMBER": "หมายเลข",
    "LOOKS_NUMBERNAME_NAME": "ชื่อ",
    "LOOKS_SWITCHBACKDROPTOANDWAIT": "เปลี่ยนฉากหลังเป็น %1 และรอ",
    "LOOKS_NEXTBACKDROP_BLOCK": "ฉากหลังต่อไป",
    "LOOKS_NEXTBACKDROP": "ฉากหลังต่อไป",
    "LOOKS_PREVIOUSBACKDROP": "ฉากหลังก่อนหน้า",
    "LOOKS_RANDOMBACKDROP": "ฉากหลังแบบสุ่ม",
    "MOTION_MOVESTEPS": "เคลื่อนที่ %1 ก้าว",
    "MOTION_TURNLEFT": "หัน %1 %2 องศา",
    "MOTION_TURNRIGHT": "หัน %1 %2 องศา",
    "MOTION_POINTINDIRECTION": "หันในทิศทาง %1",
    "MOTION_POINTTOWARDS": "หันไปทาง %1",
    "MOTION_POINTTOWARDS_POINTER": "ตัวชี้เมาส์",
    "MOTION_POINTTOWARDS_RANDOM": "สุ่มทิศทาง",
    "MOTION_GOTO": "ไปที่ %1",
    "MOTION_GOTO_POINTER": "ตัวชี้เมาส์",
    "MOTION_GOTO_RANDOM": "ตำแหน่งสุ่ม",
    "MOTION_GOTOXY": "ไปที่ x: %1 y: %2",
    "MOTION_GLIDESECSTOXY": "เหิน %1 วินาที ไปยัง x: %2 y: %3",
    "MOTION_GLIDETO": "เหิน %1 วินาที ไปยัง %2",
    "MOTION_GLIDETO_POINTER": "ตัวชี้เมาส์",
    "MOTION_GLIDETO_RANDOM": "ตำแหน่งสุ่ม",
    "MOTION_CHANGEXBY": "เปลี่ยน x ทีละ %1",
    "MOTION_SETX": "ตั้งค่า x เป็น %1",
    "MOTION_CHANGEYBY": "เปลี่ยน y ทีละ %1",
    "MOTION_SETY": "ตั้งค่า y เป็น %1",
    "MOTION_IFONEDGEBOUNCE": "ถ้าชนขอบให้สะท้อนกลับ",
    "MOTION_SETROTATIONSTYLE": "ตั้งรูปแบบการหมุนเป็น %1",
    "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "ซ้าย-ขวา",
    "MOTION_SETROTATIONSTYLE_DONTROTATE": "ห้ามหมุน",
    "MOTION_SETROTATIONSTYLE_ALLAROUND": "รอบด้าน",
    "MOTION_XPOSITION": "ตำแหน่ง x ",
    "MOTION_YPOSITION": "ตำแหน่ง y",
    "MOTION_DIRECTION": "ทิศทาง",
    "MOTION_SCROLLRIGHT": "เลื่อนขวา %1",
    "MOTION_SCROLLUP": "เลื่อนขึ้น %1",
    "MOTION_ALIGNSCENE": "แนวฉาก %1",
    "MOTION_ALIGNSCENE_BOTTOMLEFT": "ล่างซ้าย",
    "MOTION_ALIGNSCENE_BOTTOMRIGHT": "ล่างขวา",
    "MOTION_ALIGNSCENE_MIDDLE": "กึ่งกลาง",
    "MOTION_ALIGNSCENE_TOPLEFT": "บนซ้าย",
    "MOTION_ALIGNSCENE_TOPRIGHT": "บนขวา",
    "MOTION_XSCROLL": "x เลื่อน",
    "MOTION_YSCROLL": "y เลื่อน",
    "MOTION_STAGE_SELECTED": "เลือกเวที: ไม่มีบล็อกเคลื่อนไหว",
    "OPERATORS_ADD": "%1 + %2",
    "OPERATORS_SUBTRACT": "%1 - %2",
    "OPERATORS_MULTIPLY": "%1 * %2",
    "OPERATORS_DIVIDE": "%1 / %2",
    "OPERATORS_RANDOM": "เลือกสุ่มจาก %1 ถึง %2",
    "OPERATORS_GT": "%1 > %2",
    "OPERATORS_GT_EQUALS": "%1 >= %2",
    "OPERATORS_LT": "%1 < %2",
    "OPERATORS_LT_EQUALS": "%1 <= %2",
    "OPERATORS_EQUALS": "%1 = %2",
    "OPERATORS_AND": "%1 และ %2",
    "OPERATORS_OR": "%1 หรือ %2",
    "OPERATORS_NOT": "ไม่ %1",
    "CONTROL_TRUE_FALSE": "%1",
    "CONTROL_TRUE_FALSE_TRUE": "true",
    "CONTROL_TRUE_FALSE_FALSE": "false",
    "CONTROL_NONE": "None",
    "CONTROL_RETURN": "return %1",
    "OPERATORS_VALUE_IS_TRUE": "value %1 is true?",
    "OPERATORS_VALUE_TYPE": "value %1 type",
    "CONTROL_TRY": "try",
    "CONTROL_EXCEPT": "except",
    "CONTROL_FINALLY": "finally",
    "OPERATORS_VALUE_TYPE_IS": "value %1 type is %2?",
    "OPERATORS_VALUE_TYPE_INT": "int",
    "OPERATORS_VALUE_TYPE_FLOAT": "float",
    "OPERATORS_VALUE_TYPE_BOOL": "bool",
    "OPERATORS_VALUE_TYPE_STR": "str",
    "OPERATORS_VALUE_TYPE_LIST": "list",
    "OPERATORS_VALUE_TYPE_TUPLE": "tuple",
    "OPERATORS_VALUE_TYPE_SET": "set",
    "OPERATORS_VALUE_TYPE_DICT": "dict",
    "OPERATORS_VALUE_TYPE_BYTES": "bytes",
    "OPERATORS_VALUE_TYPE_BYTEARRAY": "bytearray",
    "OPERATORS_VALUE_TYPE_COMPLEX": "complex",
    "OPERATORS_CACULATE_RESULT": "eval %1",
    "OPERATORS_JOIN": "ต่อ %1 %2",
    "OPERATORS_JOIN_APPLE": "แอปเปิ้ล",
    "OPERATORS_JOIN_BANANA": "กล้วย",
    "OPERATORS_LETTEROF": "อักษรที่ %1 ของ %2",
    "OPERATORS_LETTEROF_APPLE": "อ",
    "OPERATORS_LENGTH": "ความยาวของ %1",
    "OPERATORS_CONTAINS": "%1 มี %2?",
    "OPERATORS_MOD": "%1 หารเอาเศษ %2",
    "OPERATORS_ROUND": "ปัดเศษ %1",
    "OPERATORS_MATHOP": "%1 ของ %2",
    "OPERATORS_MATHOP_ABS": "ค่าสัมบูรณ์",
    "OPERATORS_MATHOP_FLOOR": "พื้น",
    "OPERATORS_MATHOP_CEILING": "เพดาน",
    "OPERATORS_MATHOP_SQRT": "รากที่สอง",
    "OPERATORS_MATHOP_SIN": "sin",
    "OPERATORS_MATHOP_COS": "cos",
    "OPERATORS_MATHOP_TAN": "tan",
    "OPERATORS_MATHOP_ASIN": "asin",
    "OPERATORS_MATHOP_ACOS": "acos",
    "OPERATORS_MATHOP_ATAN": "atan",
    "OPERATORS_MATHOP_LN": "ln",
    "OPERATORS_MATHOP_LOG": "log",
    "OPERATORS_MATHOP_EEXP": "e ^",
    "OPERATORS_MATHOP_10EXP": "10 ^",
    "PROCEDURES_DEFINITION": "กำหนด %1",
    "SENSING_TOUCHINGOBJECT": "แตะ %1?",
    "SENSING_TOUCHINGOBJECT_POINTER": "ตัวชี้เมาส์",
    "SENSING_TOUCHINGOBJECT_EDGE": "ขอบ",
    "SENSING_TOUCHINGCOLOR": "แตะสี %1?",
    "SENSING_COLORISTOUCHINGCOLOR": "สี %1 แตะกับสี %2?",
    "SENSING_DISTANCETO": "ระยะห่างไปยัง %1",
    "SENSING_DISTANCETO_POINTER": "ตัวชี้เมาส์",
    "SENSING_ASKANDWAIT": "ถาม %1 และรอ",
    "SENSING_ASK_TEXT": "คุณชื่ออะไร?",
    "SENSING_ANSWER": "คำตอบ",
    "SENSING_KEYPRESSED": "ปุ่ม %1 ถูกกด?",
    "SENSING_MOUSEDOWN": "เมาส์ถูกกด?",
    "SENSING_MOUSEX": "ตำแหน่ง x ของเมาส์",
    "SENSING_MOUSEY": "ตำแหน่ง y ของเมาส์",
    "SENSING_SETDRAGMODE": "ตั้งค่าโหมดลาก %1",
    "SENSING_SETDRAGMODE_DRAGGABLE": "ลากได้",
    "SENSING_SETDRAGMODE_NOTDRAGGABLE": "ลากไม่ได้",
    "SENSING_LOUDNESS": "ระดับความดัง",
    "SENSING_LOUD": "เสียงดัง?",
    "SENSING_TIMER": "จับเวลา",
    "SENSING_RESETTIMER": "จับเวลาใหม่",
    "SENSING_OF": "%1 ของ %2",
    "SENSING_OF_XPOSITION": "ตำแหน่ง x ",
    "SENSING_OF_YPOSITION": "ตำแหน่ง y",
    "SENSING_OF_DIRECTION": "ทิศทาง",
    "SENSING_OF_COSTUMENUMBER": "คอสตูม #",
    "SENSING_OF_COSTUMENAME": "ชื่อคอสตูม",
    "SENSING_OF_SIZE": "ขนาด",
    "SENSING_OF_VOLUME": "ระดับเสียง",
    "SENSING_OF_BACKDROPNUMBER": "ฉากหลัง #",
    "SENSING_OF_BACKDROPNAME": "ชื่อฉากหลัง",
    "SENSING_OF_STAGE": "เวที",
    "SENSING_CURRENT": "%1 ปัจจุบัน",
    "SENSING_CURRENT_YEAR": "ปี",
    "SENSING_CURRENT_MONTH": "เดือน",
    "SENSING_CURRENT_DATE": "วันที่",
    "SENSING_CURRENT_DAYOFWEEK": "วันของสัปดาห์",
    "SENSING_CURRENT_HOUR": "ชั่วโมง",
    "SENSING_CURRENT_MINUTE": "นาที",
    "SENSING_CURRENT_SECOND": "วินาที",
    "SENSING_DAYSSINCE2000": "จำนวนวันตั้งแต่ปี ค.ศ 2000",
    "SENSING_USERNAME": "ชื่อผู้ใช้",
    "SENSING_USERID": "รหัสผู้ใช้",
    "SOUND_PLAY": "เริ่มเสียง %1",
    "SOUND_PLAYUNTILDONE": "เล่นเสียง %1 จนจบ",
    "SOUND_STOPALLSOUNDS": "หยุดเสียงทั้งหมด",
    "SOUND_SETEFFECTO": "ตั้งค่าเอฟเฟกต์ %1 เป็น %2",
    "SOUND_CHANGEEFFECTBY": "เปลี่ยนเอฟเฟกต์ %1 ทีละ %2",
    "SOUND_CLEAREFFECTS": "ล้างเอฟเฟกต์เสียง",
    "SOUND_EFFECTS_PITCH": "เสียงสูงต่ำ",
    "SOUND_EFFECTS_PAN": "แพนเสียงซ้าย/ขวา",
    "SOUND_CHANGEVOLUMEBY": "เปลี่ยนระดับเสียงทีละ %1",
    "SOUND_SETVOLUMETO": "ตั้งระดับเสียงเป็น %1%",
    "SOUND_VOLUME": "ระดับเสียง",
    "SOUND_RECORD": "อัดบันทึก...",
    "CATEGORY_MOTION": "เคลื่อนไหว",
    "CATEGORY_LOOKS": "รูปลักษณ์",
    "CATEGORY_SOUND": "เสียง",
    "CATEGORY_EVENTS": "เหตุการณ์",
    "CATEGORY_CONTROL": "ควบคุม",
    "CATEGORY_SENSING": "ตรวจจับ",
    "CATEGORY_OPERATORS": "ตัวดำเนินการ",
    "CATEGORY_VARIABLES": "ตัวแปร",
    "CATEGORY_MYBLOCKS": "บล็อกของฉัน",
    "CATEGORY_NUMBERS": "ค่าตัวเลข",
    "CATEGORY_TEXT": "ข้อความ",
    "DUPLICATE": "ทำซ้ำ",
    "DELETE": "ลบ",
    "ADD_COMMENT": "เพิ่มคำอธิบาย",
    "REMOVE_COMMENT": "ลบคำอธิบาย",
    "DELETE_BLOCK": "ลบบล็อก",
    "DELETE_X_BLOCKS": "ลบ %1 บล็อก",
    "DELETE_ALL_BLOCKS": "ลบทั้งหมด %1 บล็อก?",
    "CLEAN_UP": "จัดระเบียบบล็อก",
    "HELP": "ช่วยเหลือ",
    "UNDO": "ย้อนกลับ",
    "REDO": "ทำซ้ำ",
    "EDIT_PROCEDURE": "แก้ไข",
    "SHOW_PROCEDURE_DEFINITION": "ไปยังตัวกำหนด",
    "WORKSPACE_COMMENT_DEFAULT_TEXT": "พูดอะไรสักอย่าง...",
    "COLOUR_HUE_LABEL": "สี",
    "COLOUR_SATURATION_LABEL": "ความอิ่มตัว",
    "COLOUR_BRIGHTNESS_LABEL": "ความสว่าง",
    "CHANGE_VALUE_TITLE": "เปลี่ยนค่า:",
    "RENAME_VARIABLE": "เปลี่ยนชื่อตัวแปร",
    "RENAME_VARIABLE_STRING": "เปลี่ยนชื่อตัวแปรชนิดสตริง",
    "RENAME_VARIABLE_NUMBER": "เปลี่ยนชื่อตัวแปรชนิดตัวเลข",
    "RENAME_VARIABLE_LIST": "เปลี่ยนชื่อตัวแปรลิสต์",
    "RENAME_VARIABLE_TITLE": "เปลี่ยนชื่อตัวแปร \"%1\" ทั้งหมดเป็น:",
    "RENAME_VARIABLE_MODAL_TITLE": "เปลี่ยนชื่อตัวแปร",
    "NEW_VARIABLE": "สร้างตัวแปร",
    "NEW_VARIABLE_TITLE": "ชื่อตัวแปรใหม่:",
    "VARIABLE_MODAL_TITLE": "ตัวแปรใหม่",
    "VARIABLE_ALREADY_EXISTS": "มีตัวแปรชื่อ \"%1\" อยู่แล้ว",
    "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "มีตัวแปรชื่อ \"%1\" อยู่แล้วสำหรับตัวแปรอื่นชนิด \"%2\"",
    "DELETE_VARIABLE_CONFIRMATION": "ลบตัวแปร \"%2\" ซึ่งใช้อยู่ %1 ที่?",
    "CANNOT_DELETE_VARIABLE_PROCEDURE": "ไม่สามารถลบตัวแปร \"%1\" ได้เนื่องจากเป็นส่วนหนึ่งของคำจำกัดความของฟังก์ชัน \"%2\"",
    "DELETE_VARIABLE": "ลบตัวแปร \"%1\"",
    "DELETE_VARIABLE_STRING": "ลบตัวแปรสตริง「%1」",
    "DELETE_VARIABLE_NUMBER": "ลบตัวแปรตัวเลข「%1」",
    "DELETE_VARIABLE_LIST": "ลบตัวแปรลิสต์「%1」",
    "NEW_PROCEDURE": "สร้างบล็อกใหม่",
    "PROCEDURE_ALREADY_EXISTS": "มีขั้นตอนชื่อ \"%1\" อยู่แล้ว",
    "PROCEDURE_DEFAULT_NAME": "ชื่อบล็อก",
    "NEW_LIST": "สร้างรายการ",
    "NEW_LIST_TITLE": "ชื่อรายการ:",
    "LIST_MODAL_TITLE": "รายการใหม่",
    "LIST_ALREADY_EXISTS": "มีรายการชื่อ \"%1\" อยู่แล้ว",
    "RENAME_LIST_TITLE": "เปลี่ยนชื่อรายการ \"%1\" ทั้งหมดเป็น:",
    "RENAME_LIST_MODAL_TITLE": "เปลี่ยนชื่อรายการ",
    "DEFAULT_LIST_ITEM": "สิ่ง",
    "DELETE_LIST": "ลบรายชื่อ \"%1\"",
    "RENAME_LIST": "เปลี่ยนชื่อรายชื่อ",
    "NEW_BROADCAST_MESSAGE": "ข้อความใหม่",
    "NEW_BROADCAST_MESSAGE_TITLE": "ชื่อข้อความใหม่:",
    "BROADCAST_MODAL_TITLE": "ข้อความใหม่",
    "DEFAULT_BROADCAST_MESSAGE_NAME": "ข้อความ1",
    "DELETE_PROCEDURE": "ต้องการลบบล็อก, ก่อนอื่นต้องลบทุกอย่างที่ใช้เกี่ยวกับบล๊อกก่อน",
    "OK": "OK",
    "CANCEL": "Cancel",
    "PROMPT": "แจ้งเตือน",
    "STOP": "หยุด",
    "NEW_STRING": "สร้างตัวแปรชนิดสตริงใหม่",
    "NEW_NUMBER_VARIABLE": "ใหม่สร้างตัวแปรชนิดตัวเลข",
    "WIFI": "Wi-Fi",
    "USERNAME": "ชื่อv",
    "PWD": "รหัสWi-Fi",
    "IOT_SERVICE": "การบริการ IOT",
    "IOT_ID": "iot id",
    "IOT_PWD": "iot pwd",
    "IOT_TOPIC": "Topic_0",
    "MORE_TOPIC": "Topic",
    "OBLOQ_HTTP": "HTTP",
    "OBLOQ_IP": "ที่อยู่IP",
    "OBLOQ_PORT": "พอร์ท",
    "ADD_TOPIC": "เพิ่มTopic, สามารถเพิ่มได้ที่สุด4อัน",
    "DELETE_TOPIC": "ลบ Topic",
    "IOT_SERVER": "Server",
    "CHINA": "ประเทศจีน",
    "GLOBAL": "ทั่วโลก",
    "LOW_C": "Low C/C3",
    "LOW_C$": "Low C#/C#3",
    "LOW_D": "Low D/D3",
    "LOW_D$": "Low D#/D#3",
    "LOW_E": "Low E/E3",
    "LOW_F": "Low F/F3",
    "LOW_F$": "Low F#/F#3",
    "LOW_G": "Low G/G3",
    "LOW_G$": "Low G#/G#3",
    "LOW_A": "Low A/A3",
    "LOW_A$": "Low A#/A#3",
    "LOW_B": "Low B/B3",
    "MIDDLE_C": "Middle C/C4",
    "MIDDLE_C$": "Middle C#/C#4",
    "MIDDLE_D": "Middle D/D4",
    "MIDDLE_D$": "Middle D#/D#4",
    "MIDDLE_E": "Middle E/E4",
    "MIDDLE_F": "Middle F/F4",
    "MIDDLE_F$": "Middle F#/F#4",
    "MIDDLE_G": "Middle G/G4",
    "MIDDLE_G$": "Middle G#/G#4",
    "MIDDLE_A": "Middle A/A4",
    "MIDDLE_A$": "Middle A#/A#4",
    "MIDDLE_B": "Middle B/B4",
    "HIGH_C": "High C/C5",
    "HIGH_C$": "High C#/C#5",
    "HIGH_D": "High D/D5",
    "HIGH_D$": "High D#/D#5",
    "HIGH_E": "High E/E5",
    "HIGH_F": "High F/F5",
    "HIGH_F$": "High F#/F#5",
    "HIGH_G": "High G/G5",
    "HIGH_G$": "High G#/G#5",
    "HIGH_A": "High A/A5",
    "HIGH_A$": "High A#/A#5",
    "HIGH_B": "High B/B5",
    "OPERATORS_STRINGTONUMBER": "เปลี่ยนสตริง %1 เป็น %2",
    "INTEGER": "เลขจำนวนเต็ม",
    "DECIMAL": "เลขทศนิยม",
    "OPERATORS_NUMTOASCII": "เปลี่ยนตัวเลข %1 เป็น ASCIIสตริง",
    "OPERATORS_STRINGTOASCII": "เปลี่ยนสตริง %1 เป็น ASCIIตัวเลข",
    "OPERATORS_NUMTOSTRING": "เปลี่ยนตัวเลข %1 เป็นสตริง",
    "OPERATORS_MAP": "สะท้อน %1 จาก[ %2 , %3 ] ถึง[ %4 , %5 ]",
    "OPERATORS_CONSTRAIN": "จำกัด %1 ระหว่าง(น้องที่สุด) %2 และ(มากที่สุด) %3",
    "STEPS_PER_TURN": "การหมุนต่อรอบ",
    "ROTATE_SPEED": "ความเร็วในการหมุน(r/min)",
    "NEW_AI": "สร้างAIบล็อก",
    "ASK_AND_PAINT": "กรุณาเขียน1ตัวเลข",
    "GET_IDENTIFIED_NUM": "วิเคราะห์ตัวเลข",
    "READ_NUM_AND_SAY": "พูดการวิเคราะห์ตัวเลข",
    "IMAGE_ADDR": "ที่อยู่รูปภาพ",
    "IMAGE_PREVIEW": "ตัวอย่างรูปภาพ",
    "IMAGE_OPEN": "เปิด",
    "IMAGE_SIZE": "ขนาดรูป",
    "IMAGE_WIDTH": "กว้าง",
    "IMAGE_HEIGHT": "สูง",
    "VARIABLE": "ตัวแปร %1",
    "VARIABLE_LIST": "ลิสต์ %1",
    "SET_STRING_VARIABLE": "ตั้งค่า %1 เป็น %2",
    "STRING_START_WITH": "%1 เริ่มด้วย %2",
    "OPERATORS_RANDOMA": "%1 %2 สุ่มเลือก %3 ถึง %4 %5",
    "OPERATORS_MODA": "%1 %2 %3 นอกจาก %4",
    "OPERATORS_ROUNDA": "%1 %2 ล้อม%3",
    "OPERATORS_MATHOPA": "%1 %2 %3 %4",
    "OPERATORS_MATHOP_ABSA": "abs",
    "OPERATORS_MATHOP_FLOORA": "เพดาน",
    "OPERATORS_MATHOP_CEILINGA": "พื้น",
    "OPERATORS_MATHOP_SQRTA": "รากที่สอง",
    "OPERATORS_MATHOP_FIVE": "%1 %2 เปลี่ยนตัวแปร %3 เพิ่มเป็น %4",
    "OPERATORS_MATHOP_SIX": "%1 %2 ทำแผน %3 จาก[ %4 , %5 ] ถึง[ %6 , %7 ]",
    "OPERATORS_MATHOP_SEVEN": "%1 %2 จำกัด %3 ระหว่าง(น้อยที่สุด) %4 และ(มากที่สุด) %5",
    "OPERATORS_NUM_INPUT": "%1 %2 number %3",
    "OPERATORS_NUMS_CACULATE": "%1 %2 %3",
    "OPERATORS_MATHOP_SQUARE": "**",
    "OPERATORS_MATHOP_LOGIC_OR": "|",
    "OPERATORS_MATHOP_LOGIC_AND": "&",
    "OPERATORS_MATHOP_LOGIC_POWER": "^",
    "OPERATORS_MATHOP_LOGIC_LEFT": "<<",
    "OPERATORS_MATHOP_LOGIC_RIGHT": ">>",
    "OPERATORS_SPECIAL_NUM": "%1 %2 %3",
    "OPERATORS_MATHOP_PI": "π",
    "OPERATORS_MATHOP_LOGARITHM": "e",
    "OPERATORS_MATHOP_FAI": "φ",
    "OPERATORS_MATHOP_SQRT2": "sqrt(2)",
    "OPERATORS_MATHOP_SQRTHALF": "sqrt(½)",
    "OPERATORS_MATHOP_UNLIMITED": "∞",
    "OPERATORS_NUMS_TYPE": "%1 %2 %3 %4?",
    "OPERATORS_MATHOP_ISEVEN": "is even",
    "OPERATORS_MATHOP_ISODD": "is odd",
    "OPERATORS_MATHOP_ISINT": "is int",
    "OPERATORS_MATHOP_ISPOSITIVE": "is positive",
    "OPERATORS_MATHOP_ISNEGATIVE": "is negative",
    "OPERATORS_SAVE2DECIMAL": "%1 %2 %3 save %4 decimal places",
    "OPERATORS_LIST_CACULATE": "%1 %2 %3 %4",
    "OPERATORS_MATHOP_LIST_SUM": "sum of the numbers in the list",
    "OPERATORS_MATHOP_LIST_MIN": "minimum value in list",
    "OPERATORS_MATHOP_LIST_MAX": "maximum value in the list",
    "OPERATORS_MATHOP_LIST_AVERAGE": "average in list",
    "OPERATORS_MATHOP_LIST_NUM": "number of digits in the list",
    "OPERATORS_MATHOP_LIST_MODE": "list mode",
    "OPERATORS_MATHOP_LIST_STDDEVIATION": "standard deviation in the list",
    "OPERATORS_MATHOP_LIST_RANDOM": "random item of list",
    "OPERATORS_QUOTIENT_INT": "%1 %2 %3 ÷ %4 integer part of quotient",
    "OPERATORS_RANDOM_DECIMAL": "%1 %2 random decimal",
    "OPERATORS_BIN_NEGATION": "%1 %2 binary negation %3",
    "OPERATORS_INT_TO_STR": "%1 %2 decimal integer %3 transform into %4 string",
    "OPERATORS_MATHOP_INTTOSTR_BIN": "binary",
    "OPERATORS_MATHOP_INTTOSTR_OCT": "octal",
    "OPERATORS_MATHOP_INTTOSTR_HEX": "hex",
    "OPERATORS_BINSTR_TO_INT": "%1 %2 %3 string %4 transform into decimal integer",
    "OPERATORS_INT_TO_BYTE": "%1 %2 decimal integer %3 transform into %4 byte",
    "OPERATORS_MATHOP_INTTOBYTE_TWO": "2",
    "OPERATORS_MATHOP_INTTOBYTE_FOUR": "4",
    "OPERATORS_INT_TO_ASCII": "%1 %2 int %3 transform into ASCII character",
    "OPERATORS_ASCII_TO_INT": "%1 %2 ASCII character %3 to int",
    "OPERATORS_STR_TO_INT": "%1 %2 string %3 to hex string",
    "OPERATORS_HEXSTR_TO_BIN": "%1 %2 hex string %3 to bin byte",
    "OPERATORS_STR_TO_BASE64": "%1 %2 %3 encodes in base64 format returns a byte object",
    "OPERATORS_BASE64_TO_BYTE": "%1 %2 decode base64 data %3 returns a byte object",
    "OPERATORS_MATHOP_LENGHT": "ความยาว %1",
    "OPERATORS_MATHOP_MAXMIN": "%1 %2",
    "OPERATORS_MATHOP_MAXMIN_MAX": "มากที่สุด",
    "OPERATORS_MATHOP_MAXMIN_MIN": "น้อยที่สุด",
    "OPERATORS_TEXTS_ONE": "%1 %2 รวมเข้ากับ %3 %4",
    "OPERATORS_TEXTS_TWO": "%1 %2 ตัวอักษร %4 ของ %3",
    "OPERATORS_TEXTS_THREE": "%1 %2 %3 รวมไปถึง %4 ?",
    "OPERATORS_TEXTS_FOUR": "%1 %2 ตัวอักษร  %5 %4 ถึง %7 %6 ของ %3",
    "OPERATORS_TEXTS_FOUR_NEW": "%1 ได้รับ %2 %3 ตัวอักษร ถึง %4 %5ตัวอักษร",
    "OPERATORS_TEXTS_NEW": "%1 %2 หา %3 ใน %4  %5 ตำแหน่งค้นพบ",
    "OPERATORS_TEXTS_NEW_OP": "ค้นพบ %1 ใน %2 ตำแหน่งค้าพบ %3",
    "OPERATORS_TEXTS_FOUR_ONE": "จากทางซ้าย",
    "OPERATORS_TEXTS_FOUR_TWO": "จากทางขวา",
    "OPERATORS_TEXTS_FOUR_THREE": "จากทางซ้าย",
    "OPERATORS_TEXTS_FOUR_FOUR": "จากทางขวา",
    "OPERATORS_TEXTS_FOUR_F": "ครั้งแรก",
    "OPERATORS_TEXTS_FOUR_L": "ครั้งสุดท้าย",
    "OPERATORS_INPUTSTRING": "%1 %2 string %3",
    "OPERATORS_TRANSINTOTEXT": "%1 %2 transform into %3",
    "OPERATORS_TRANSINTOTEXT_ADD": "add",
    "OPERATORS_TRANSINTOTEXT_ADD_ITEM": "item",
    "OPERATORS_FORMATSTRING2F": "%1 %2 format string %3 % %4",
    "OPERATORS_FORMATSTRING3": "%1 %2 format string %3 format %4",
    "OPERATORS_TEXT_ESCAPE": "%1 %2 text escape character %3",
    "BAR_N": "\\n",
    "BAR_NN": "\\n\\r",
    "BAR_R": "\\r",
    "BAR_RN": "\\r\\n",
    "BAR_B": "\\b",
    "BAR_T": "\\t",
    "BAR_BAR": "\\\\",
    "OPERATORS_TEXT_NUMORLETTER": "%1 %2 %3 %4?",
    "OPERATORS_TEXT_NUMORLETTER_NUM": "is number",
    "OPERATORS_TEXT_NUMORLETTER_LETTER": "is letter",
    "OPERATORS_TEXT_LENGTH": "%1 %2 %3 length",
    "OPERATORS_TEXT_ISNONE": "%1 %2 %3 is none?",
    "OPERATORS_FROMTEXT_GETCHAR": "%1 %2 in text %3 %4",
    "OPERATORS_FROMTEXT_GETCHAR_FIRST": "first letter",
    "OPERATORS_FROMTEXT_GETCHAR_LAST": "last letter",
    "OPERATORS_FROMTEXT_GETCHAR_RANDOM": "random letter",
    "OPERATORS_TEXT_UPPLOW": "%1 %2 %3 %4",
    "OPERATORS_TEXT_UPPLOW_UPP": "uppercase",
    "OPERATORS_TEXT_UPPLOW_LOW": "lowercase",
    "OPERATORS_TEXT_SIDES_SPACE": "%1 %2 %3 %4",
    "OPERATORS_TEXT_SIDES_SPACE_BOTH": "clear spaces on both sides",
    "OPERATORS_TEXT_SIDES_SPACE_LEFT": "clear left space",
    "OPERATORS_TEXT_SIDES_SPACE_RIGHT": "clear right space",
    "OPERATORS_TEXT_STRTOBYTE": "%1 %2 turn byte %3",
    "OPERATORS_TEXT_BYTETOSTR": "%1 %2 byte %3 convert to string",
    "OPERATORS_TEXT_DICTTOJSONSTR": "%1 %2 dict %3 transform into json string",
    "OPERATORS_TEXT_PARSEJSONSTR": "%1 %2 parse json string %3 and return object",
    "OPERATORS_STRINGTONUMBERA": "%1 %2 เปลี่ยนสตริง %3 เป็น %4",
    "OPERATORS_NUMTOASCIIA": "%1 %2 เปลี่ยนตัวเลข %3 เป็น ASCIIสตริง",
    "OPERATORS_STRINGTOASCIIA": "%1 %2 เปลี่ยนสตริง %3 เป็น ASCIIตัวเลข",
    "OPERATORS_NUMTOSTRINGA": "%1 %2 เปลี่ยนตัวเข %3 เป็นสตริง",
    "NEW_MC": "สร้างตัวแปรใหม่",
    "RENAME_MCNUMBER_TITLE": "เปลี่ยนชื่อตัวแปรทั้งหมดที่ชื่อ [%1] เป็น:",
    "RENAME_MCNUMBER_MODAL_TITLE": "เปลี่ยนชื่อตัวแปร",
    "RENAME_VARIABLE_MCNUMBER": "เปลี่ยนชื่อตัวแปร",
    "DELETE_VARIABLE_MCNUMBER": "ลบตัวแปร [%1]",
    "SET_MC_VARIABLE": "ตั้งค่าตัวแปร %1 เป็น %2",
    "ALIYUN": "Aliyun",
    "ONENET": "OneNet",
    "ONENETNEW": "OneNet(New)",
    "EASYIOT": "Easy IoT",
    "SHANGHAI": "Shanghai",
    "QINGDAO": "Qingdao",
    "BEIJING": "Beijing",
    "ZHANGJIAKOU": "Zhangjiakou",
    "HUHEHAOTE": "Huhehaote",
    "HANGZHOU": "Hangzhou",
    "SHENZHEN": "Shenzhen",
    "HONGKONG": "Hongkong",
    "SINGAPORE": "Singapore",
    "SYDNEY": "Sydney",
    "KUALALUMPUR": "Luala Lumpur",
    "JAKARTA": "Jakarta",
    "MUMBAI": "Mumbai",
    "TOKYO": "Tokyo",
    "SILICONVALLEY": "Silicon Valley",
    "VIRGINIA": "Virginia",
    "FRANKFURT": "Frankfurt",
    "LONDON": "London",
    "DUBAI": "Dubai",
    "IOT_PLATFORM": "Iot Platform",
    "PARAMS": "Params",
    "SERVER_ATTR": "Server Addr",
    "PRODUCTID": "ProductId",
    "DEVICEID": "DeviceId",
    "DEVICENAME": "DeviceName",
    "TINYDB_SECRET": "secret (secret)",
    "TINYDB_USER": "user (user)",
    "APIADDR": "API Addr",
    "SOFTWARE_SERIAL": "Software Serial",
    "HARDWARE_SERIAL": "Hardware Serial",
    "HARDWARE_SERIAL1": "Hardware Serial1",
    "HARDWARE_SERIAL2": "Hardware Serial2",
    "HARDWARE_SERIAL3": "Hardware Serial3",
    "CHECKTYPE_TIPS": "The data type received by the input box does not match the output type of the building block",
    "HIGHLIGHT_BLOCK": "hight light block",
    "HIGHLIGHT_ALL_CONFLICT_BLOCKS": "highlight all blocks with warnings",
    "SNAPSHOT": "Snapshot (CTRL+G)",
    "pictureaiPath": "please enter the picture path or URL",
    "pictureaiType": "account type",
    "pictureaiAccountDefault": "default account",
    "pictureaiAccountBaidu": "baidu account",
    "pictureaiwebImgAddr": "web image address",
    "MITRIXICON_EDIT": "light up",
    "MITRIXICON_ERASER": "eraser",
    "MITRIXICON_DUSTBIN": "clear",
    "MITRIXICON_REVERSE": "reverse",
    "MITRIXICON_SAVE": "save",
    "MITRIXICON_EXPORT": "export",
    "MITRIXICON_UPLOAD": "upload",
    "ROLL_MOUSE": "Rolling mouse",
    "MOUSE_ZOOM_IN_OUT": "Scroll the mouse to zoom in and out",
    "UPLOAD_CORRECT_JSON_FILE": "Please upload the json file in the correct format",
    "EXPORT_PROMPT": "There are no custom patterns to export. Please click the save button in the lower right corner to save the custom pattern before attempting to export.",
    "HEART": "Heart",
    "HEART_SMALL": "Small heart",
    "HAPPY": "Happy",
    "SMILE": "Smile",
    "SAD": "Sad",
    "CONFUSED": "Confused",
    "ANGRY": "Angry",
    "SLEEP": "Sleep",
    "SURPRISED": "Surprised",
    "SILLY": "Silly",
    "WONDERFUL": "Wonderful",
    "BORED": "Bored",
    "ROCK": "Rock",
    "ROCK_SMALL": "Small rock",
    "PAPER": "Paper",
    "PAPER_SMALL": "Small paper",
    "SCISSORS": "scissors",
    "SCISSORS_SMALL": "Small scissors",
    "DATA_SOLITLIST": "%1 %2 make %3 %4 with delimiter %5",
    "DATA_SOLITLIST_TYPE_LT": "list from text5",
    "DATA_SOLITLIST_TYPE_TL": "text from list",
    "DATA_LENLIST": "%1 %2 %3 is empty?",
    "DATA_LENGTHLIST": "%1 %2 length of %3",
    "DATA_CREATELIST": "%1 %2 create list %3",
    "DATA_CLEARLIST": "%1 %2 clear list %3"
  },
  "pl": {
    "CONTROL_FOREVER": "zawsze",
    "CONTROL_REPEAT": "powtarzaj %1",
    "CONTROL_IF": "jeżeli %1 to",
    "CONTROL_ELSE": "w przeciwnym razie",
    "CONTROL_STOP": "zatrzymaj",
    "CONTROL_STOP_ALL": "wszystko",
    "CONTROL_STOP_THIS": "ten skrypt",
    "CONTROL_STOP_OTHER": "inne skrypty duszka",
    "CONTROL_WAIT": "czekaj %1 sekund",
    "CONTROL_WAITUNTIL": "czekaj aż %1",
    "CONTROL_REPEATUNTIL": "powtarzaj aż %1",
    "CONTROL_WHILE": "podczas gdy %1",
    "CONTROL_FOREACH": "dla każdego %1 z %2",
    "CONTROL_STARTASCLONE": "gdy zaczynam jako klon",
    "CONTROL_CREATECLONEOF": "utwórz klona z %1",
    "CONTROL_CREATECLONEOF_MYSELF": "siebie",
    "CONTROL_DELETETHISCLONE": "usuń tego klona",
    "CONTROL_COUNTER": "licznik",
    "CONTROL_INCRCOUNTER": "zwiększ licznik",
    "CONTROL_CLEARCOUNTER": "zeruj licznik",
    "CONTROL_ALLATONCE": "wszystko na raz",
    "DATA_SETVARIABLETO": "ustaw %1 na %2",
    "DATA_CHANGEVARIABLEBY": "zmień %1 o %2",
    "DATA_SHOWVARIABLE": "pokaż zmienną %1",
    "DATA_HIDEVARIABLE": "ukryj zmienną %1",
    "DATA_ADDTOLIST": "dodaj %1 do %2",
    "DATA_DELETEOFLIST": "usuń %1 z %2",
    "DATA_DELETEALLOFLIST": "usuń wszystko z %1",
    "DATA_INSERTATLIST": "wstaw %1 na %2 pozycji z %3",
    "DATA_REPLACEITEMOFLIST": "zamień %1 z %2 na %3",
    "DATA_ITEMOFLIST": "element %1 z %2",
    "DATA_ITEMNUMOFLIST": "pozycja %1 na liście %2",
    "DATA_LENGTHOFLIST": "długość %1",
    "DATA_LISTCONTAINSITEM": "%1 zawiera %2?",
    "DATA_SHOWLIST": "pokaż listę %1",
    "DATA_HIDELIST": "ukryj listę %1",
    "DATA_INDEX_ALL": "wszystko",
    "DATA_INDEX_LAST": "ostatni",
    "DATA_INDEX_RANDOM": "losowa",
    "EVENT_WHENFLAGCLICKED": "kiedy %1 kliknięty",
    "EVENT_WHENTHISSPRITECLICKED": "kiedy ten duszek kliknięty",
    "EVENT_WHENSTAGECLICKED": "kiedy scena kliknięta",
    "EVENT_WHENTOUCHINGOBJECT": "kiedy ten duszek dotknie %1",
    "EVENT_WHENBROADCASTRECEIVED": "kiedy otrzymam %1",
    "EVENT_WHENBACKDROPSWITCHESTO": "kiedy tło zmieni się na %1",
    "EVENT_WHENGREATERTHAN": "kiedy %1 > %2",
    "EVENT_WHENGREATERTHAN_TIMER": "stoper",
    "EVENT_WHENGREATERTHAN_LOUDNESS": "głośność",
    "EVENT_BROADCAST": "nadaj komunikat %1",
    "EVENT_BROADCASTANDWAIT": "nadaj komunikat %1 i czekaj",
    "EVENT_WHENKEYPRESSED": "kiedy klawisz %1 naciśnięty",
    "EVENT_WHENKEYPRESSED_SPACE": "spacja",
    "EVENT_WHENKEYPRESSED_LEFT": "strzałka w lewo",
    "EVENT_WHENKEYPRESSED_RIGHT": "strzałka w prawo",
    "EVENT_WHENKEYPRESSED_DOWN": "strzałka w dół",
    "EVENT_WHENKEYPRESSED_UP": "strzałka w górę",
    "EVENT_WHENKEYPRESSED_ANY": "dowolny",
    "LOOKS_SAYFORSECS": "powiedz %1 przez %2 sekund",
    "LOOKS_SAY": "powiedz %1",
    "LOOKS_HELLO": "Cześć!",
    "LOOKS_THINKFORSECS": "pomyśl %1 przez %2 sekund",
    "LOOKS_THINK": "pomyśl %1",
    "LOOKS_HMM": "Hmm...",
    "LOOKS_SHOW": "pokaż",
    "LOOKS_HIDE": "ukryj",
    "LOOKS_HIDEALLSPRITES": "ukryj wszystkie duszki",
    "LOOKS_EFFECT_COLOR": "kolor",
    "LOOKS_EFFECT_FISHEYE": "rybie oko",
    "LOOKS_EFFECT_WHIRL": "wir",
    "LOOKS_EFFECT_PIXELATE": "zniekształć",
    "LOOKS_EFFECT_MOSAIC": "mozaika",
    "LOOKS_EFFECT_BRIGHTNESS": "jasność",
    "LOOKS_EFFECT_GHOST": "duch",
    "LOOKS_CHANGEEFFECTBY": "zmień efekt %1 o %2",
    "LOOKS_SETEFFECTTO": "ustaw efekt %1 na %2",
    "LOOKS_CLEARGRAPHICEFFECTS": "wyczyść efekty graficzne",
    "LOOKS_CHANGESIZEBY": "zmień rozmiar o %1",
    "LOOKS_SETSIZETO": "ustaw rozmiar na %1 %",
    "LOOKS_SIZE": "rozmiar",
    "LOOKS_CHANGESTRETCHBY": "zmień rozciągnięcie o %1",
    "LOOKS_SETSTRETCHTO": "ustaw rozciąganie na %1 %",
    "LOOKS_SWITCHCOSTUMETO": "zmień kostium na %1",
    "LOOKS_NEXTCOSTUME": "następny kostium",
    "LOOKS_SWITCHBACKDROPTO": "zmień tło na %1",
    "LOOKS_GOTOFRONTBACK": "przesuń na %1",
    "LOOKS_GOTOFRONTBACK_FRONT": "na wierzch",
    "LOOKS_GOTOFRONTBACK_BACK": "na spód",
    "LOOKS_GOFORWARDBACKWARDLAYERS": "przesuń %1 o %2 warstw",
    "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "do przodu",
    "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "do tyłu",
    "LOOKS_BACKDROPNUMBERNAME": "tło %1",
    "LOOKS_COSTUMENUMBERNAME": "kostium %1",
    "LOOKS_NUMBERNAME_NUMBER": "liczba",
    "LOOKS_NUMBERNAME_NAME": "nazwa",
    "LOOKS_SWITCHBACKDROPTOANDWAIT": "zmień tło na %1 i czekaj",
    "LOOKS_NEXTBACKDROP_BLOCK": "następne tło",
    "LOOKS_NEXTBACKDROP": "następne tło",
    "LOOKS_PREVIOUSBACKDROP": "poprzednie tło",
    "LOOKS_RANDOMBACKDROP": "losowo wybrane tło",
    "MOTION_MOVESTEPS": "przesuń o %1 kroków",
    "MOTION_TURNLEFT": "obróć %1 o %2 stopni",
    "MOTION_TURNRIGHT": "obróć %1 o %2 stopni",
    "MOTION_POINTINDIRECTION": "ustaw kierunek na %1",
    "MOTION_POINTTOWARDS": "ustaw w kierunku duszka %1",
    "MOTION_POINTTOWARDS_POINTER": "wskaźnik myszy",
    "MOTION_POINTTOWARDS_RANDOM": "losowy kierunek",
    "MOTION_GOTO": "idź do %1",
    "MOTION_GOTO_POINTER": "wskaźnik myszy",
    "MOTION_GOTO_RANDOM": "losowa pozycja",
    "MOTION_GOTOXY": "Idź do x: %1 y: %2",
    "MOTION_GLIDESECSTOXY": "leć przez %1 sekund do x: %2 y: %3",
    "MOTION_GLIDETO": "leć przez %1 sekund do %2",
    "MOTION_GLIDETO_POINTER": "wskaźnik myszy",
    "MOTION_GLIDETO_RANDOM": "losowa pozycja",
    "MOTION_CHANGEXBY": "zmień x o %1",
    "MOTION_SETX": "ustaw x na %1",
    "MOTION_CHANGEYBY": "zmień y o %1",
    "MOTION_SETY": "ustaw y na %1",
    "MOTION_IFONEDGEBOUNCE": "jeżeli na brzegu, odbij się",
    "MOTION_SETROTATIONSTYLE": "ustaw styl obrotu na %1",
    "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "lewo-prawo",
    "MOTION_SETROTATIONSTYLE_DONTROTATE": "nie obracaj",
    "MOTION_SETROTATIONSTYLE_ALLAROUND": "dookoła",
    "MOTION_XPOSITION": "pozycja x",
    "MOTION_YPOSITION": "pozycja y",
    "MOTION_DIRECTION": "kierunek",
    "MOTION_SCROLLRIGHT": "przewiń w prawo %1",
    "MOTION_SCROLLUP": "przewiń w górę %1",
    "MOTION_ALIGNSCENE": "wyrównaj scenę %1",
    "MOTION_ALIGNSCENE_BOTTOMLEFT": "na dole po lewej stronie",
    "MOTION_ALIGNSCENE_BOTTOMRIGHT": "na dole z prawej stronie",
    "MOTION_ALIGNSCENE_MIDDLE": "środek",
    "MOTION_ALIGNSCENE_TOPLEFT": "na górze po lewej stronie",
    "MOTION_ALIGNSCENE_TOPRIGHT": "na górze po prawej stronie",
    "MOTION_XSCROLL": "przewijanie x",
    "MOTION_YSCROLL": "przewijanie y",
    "MOTION_STAGE_SELECTED": "wybrana scena: bez bloków ruchu",
    "OPERATORS_ADD": "%1 + %2",
    "OPERATORS_SUBTRACT": "%1 - %2",
    "OPERATORS_MULTIPLY": "%1 * %2",
    "OPERATORS_DIVIDE": "%1 / %2",
    "OPERATORS_RANDOM": "losuj liczbę od %1 do %2",
    "OPERATORS_GT": "%1 > %2",
    "OPERATORS_GT_EQUALS": "",
    "OPERATORS_LT": "%1 < %2",
    "OPERATORS_LT_EQUALS": "",
    "OPERATORS_EQUALS": "%1 = %2",
    "OPERATORS_AND": "%1 i %2",
    "OPERATORS_OR": "%1 lub %2",
    "OPERATORS_NOT": "nie %1",
    "OPERATORS_JOIN": "połącz %1 i %2",
    "OPERATORS_JOIN_APPLE": "jabłko",
    "OPERATORS_JOIN_BANANA": "banan",
    "OPERATORS_LETTEROF": "litera %1 z %2",
    "OPERATORS_LETTEROF_APPLE": "j",
    "OPERATORS_LENGTH": "długość %1",
    "OPERATORS_CONTAINS": "Czy %1 zawiera %2 ?",
    "OPERATORS_MOD": "reszta z dzielenia %1 przez %2",
    "OPERATORS_ROUND": "zaokrąglij %1",
    "OPERATORS_MATHOP": "%1 z %2",
    "OPERATORS_MATHOP_ABS": "wartość bezwzględna",
    "OPERATORS_MATHOP_FLOOR": "podłoga",
    "OPERATORS_MATHOP_CEILING": "sufit",
    "OPERATORS_MATHOP_SQRT": "pierwiastek kwadratowy",
    "OPERATORS_MATHOP_SIN": "sinus",
    "OPERATORS_MATHOP_COS": "cosinus",
    "OPERATORS_MATHOP_TAN": "tangens",
    "OPERATORS_MATHOP_ASIN": "arcus sinus",
    "OPERATORS_MATHOP_ACOS": "arcus cosinus",
    "OPERATORS_MATHOP_ATAN": "arcus tangens",
    "OPERATORS_MATHOP_LN": "logarytm naturalny",
    "OPERATORS_MATHOP_LOG": "log",
    "OPERATORS_MATHOP_EEXP": "e ^",
    "OPERATORS_MATHOP_10EXP": "10 ^",
    "PROCEDURES_DEFINITION": "definiuj %1",
    "SENSING_TOUCHINGOBJECT": "dotyka %1 ?",
    "SENSING_TOUCHINGOBJECT_POINTER": "wskaźnik myszy",
    "SENSING_TOUCHINGOBJECT_EDGE": "krawędź",
    "SENSING_TOUCHINGCOLOR": "dotyka koloru %1 ?",
    "SENSING_COLORISTOUCHINGCOLOR": "kolor %1 dotyka %2 ? ",
    "SENSING_DISTANCETO": "odległość od %1",
    "SENSING_DISTANCETO_POINTER": "wskaźnik myszy",
    "SENSING_ASKANDWAIT": "zapytaj %1 i czekaj",
    "SENSING_ASK_TEXT": "Jak się nazywasz?",
    "SENSING_ANSWER": "odpowiedź",
    "SENSING_KEYPRESSED": "klawisz %1 naciśnięty ?",
    "SENSING_MOUSEDOWN": "kliknięto myszką?",
    "SENSING_MOUSEX": "x myszy",
    "SENSING_MOUSEY": "y myszy",
    "SENSING_SETDRAGMODE": "ustaw tryb przeciągania na %1",
    "SENSING_SETDRAGMODE_DRAGGABLE": "z przeciąganiem",
    "SENSING_SETDRAGMODE_NOTDRAGGABLE": "bez przeciągania",
    "SENSING_LOUDNESS": "głośność",
    "SENSING_LOUD": "głośno?",
    "SENSING_TIMER": "stoper",
    "SENSING_RESETTIMER": "resetuj stoper",
    "SENSING_OF": "%1 z %2",
    "SENSING_OF_XPOSITION": "pozycja x",
    "SENSING_OF_YPOSITION": "pozycja y",
    "SENSING_OF_DIRECTION": "kierunek",
    "SENSING_OF_COSTUMENUMBER": "indeks kostiumu",
    "SENSING_OF_COSTUMENAME": "nazwa kostiumu",
    "SENSING_OF_SIZE": "rozmiar",
    "SENSING_OF_VOLUME": "głośność",
    "SENSING_OF_BACKDROPNUMBER": "indeks tła",
    "SENSING_OF_BACKDROPNAME": "nazwa tła",
    "SENSING_OF_STAGE": "scena",
    "SENSING_CURRENT": "obecny %1",
    "SENSING_CURRENT_YEAR": "rok",
    "SENSING_CURRENT_MONTH": "miesiąc",
    "SENSING_CURRENT_DATE": "dzień",
    "SENSING_CURRENT_DAYOFWEEK": "dzień tygodnia",
    "SENSING_CURRENT_HOUR": "godzina",
    "SENSING_CURRENT_MINUTE": "minuta",
    "SENSING_CURRENT_SECOND": "sekunda",
    "SENSING_DAYSSINCE2000": "dni od 2000",
    "SENSING_USERNAME": "nazwa użytkownika",
    "SENSING_USERID": "ID użytkownika",
    "SOUND_PLAY": "zagraj dźwięk %1",
    "SOUND_PLAYUNTILDONE": "graj dźwięk %1 aż się skończy",
    "SOUND_STOPALLSOUNDS": "zakończ wszystkie dźwięki",
    "SOUND_SETEFFECTO": "ustaw efekt %1 na %2",
    "SOUND_CHANGEEFFECTBY": "zmień efekt %1 o %2",
    "SOUND_CLEAREFFECTS": "usuń efekty dźwiękowe",
    "SOUND_EFFECTS_PITCH": "płynność dźwięku",
    "SOUND_EFFECTS_PAN": "przesuń w lewo/prawo",
    "SOUND_CHANGEVOLUMEBY": "zmień głośność o %1",
    "SOUND_SETVOLUMETO": "ustaw głośność na %1%",
    "SOUND_VOLUME": "głośność",
    "SOUND_RECORD": "nagraj...",
    "CATEGORY_MOTION": "Ruch",
    "CATEGORY_LOOKS": "Wygląd",
    "CATEGORY_SOUND": "Dźwięk",
    "CATEGORY_EVENTS": "Zdarzenia",
    "CATEGORY_CONTROL": "Kontrola",
    "CATEGORY_SENSING": "Czujniki",
    "CATEGORY_OPERATORS": "Wyrażenia",
    "CATEGORY_VARIABLES": "Zmienne",
    "CATEGORY_MYBLOCKS": "Moje bloki",
    "CATEGORY_LISTS": "",
    "CATEGORY_TUPLE": "",
    "CATEGORY_DICTIONARYS": "",
    "CATEGORY_NUMBERS": "",
    "CATEGORY_TEXT": "",
    "DUPLICATE": "Duplikuj",
    "DELETE": "Usuń",
    "ADD_COMMENT": "Dodaj komentarz",
    "REMOVE_COMMENT": "Usuń komentarz",
    "DELETE_BLOCK": "Usuń blok",
    "DELETE_X_BLOCKS": "Usuń %1 bloków",
    "DELETE_ALL_BLOCKS": "Czy usunąć wszystkie %1 bloków?",
    "CLEAN_UP": "Posprzątaj bloki",
    "HELP": "Pomoc",
    "UNDO": "Cofnij",
    "REDO": "Ponów",
    "EDIT_PROCEDURE": "Edycja",
    "SHOW_PROCEDURE_DEFINITION": "Przejdź do definicji",
    "WORKSPACE_COMMENT_DEFAULT_TEXT": "Powiedz coś...",
    "COLOUR_HUE_LABEL": "Kolor",
    "COLOUR_SATURATION_LABEL": "Nasycenie",
    "COLOUR_BRIGHTNESS_LABEL": "Jasność",
    "CHANGE_VALUE_TITLE": "Zmień wartość:",
    "RENAME_VARIABLE": "Zmień nazwę zmiennej",
    "RENAME_VARIABLE_STRING": "",
    "RENAME_VARIABLE_NUMBER": "",
    "RENAME_VARIABLE_LIST": "",
    "RENAME_VARIABLE_TITLE": "Zmień nazwę wszystkich zmiennych \"%1\" na:",
    "RENAME_VARIABLE_MODAL_TITLE": "Zmień nazwę zmiennej",
    "NEW_VARIABLE": "Utwórz zmienną",
    "NEW_VARIABLE_TITLE": "Nowa nazwa zmiennej:",
    "VARIABLE_MODAL_TITLE": "Nowa zmienna",
    "VARIABLE_ALREADY_EXISTS": "Zmienna o nazwie \"%1\" już istnieje.",
    "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "Zmienna o nazwie \"%1\" już istnieje dla innego typu zmiennej \"%2\".",
    "DELETE_VARIABLE_CONFIRMATION": "Czy usunąć %1 używając zmiennej \"%2\"?",
    "CANNOT_DELETE_VARIABLE_PROCEDURE": "Nie można usunąć zmiennej \"%1\", ponieważ stanowi część definicji funkcji \"%2\"",
    "DELETE_VARIABLE": "Usuń zmienną \"%1\"",
    "DELETE_VARIABLE_STRING": "",
    "DELETE_VARIABLE_NUMBER": "",
    "DELETE_VARIABLE_LIST": "",
    "NEW_PROCEDURE": "Utwórz blok",
    "PROCEDURE_ALREADY_EXISTS": "Procedura o nazwie \"%1\" już istnieje.",
    "PROCEDURE_DEFAULT_NAME": "nazwa bloku",
    "NEW_LIST": "Stwórz listę",
    "NEW_LIST_TITLE": "Nowa nazwa lista:",
    "LIST_MODAL_TITLE": "Nowa lista",
    "LIST_ALREADY_EXISTS": "Lista o nazwie \"%1\" już istnieje.",
    "RENAME_LIST_TITLE": "Zmień nazwy wszystkich list \"%1\" na:",
    "RENAME_LIST_MODAL_TITLE": "Zmień nazwę listy",
    "DEFAULT_LIST_ITEM": "obiekt",
    "DELETE_LIST": "Usuń listę \"%1\"",
    "RENAME_LIST": "Zmień nazwę listy",
    "NEW_BROADCAST_MESSAGE": "Nowa wiadomość",
    "NEW_BROADCAST_MESSAGE_TITLE": "Nowa nazwa wiadomości:",
    "BROADCAST_MODAL_TITLE": "Nowa wiadomość",
    "DEFAULT_BROADCAST_MESSAGE_NAME": "wiadomość1",
    "DELETE_PROCEDURE": "",
    "OK": "",
    "PROMPT": "",
    "STOP": "",
    "NEW_STRING": "",
    "NEW_NUMBER_VARIABLE": "",
    "WIFI": "",
    "USERNAME": "",
    "PWD": "",
    "IOT_SERVICE": "",
    "IOT_ID": "",
    "IOT_PWD": "",
    "IOT_TOPIC": "",
    "MORE_TOPIC": "",
    "OBLOQ_HTTP": "",
    "OBLOQ_IP": "",
    "OBLOQ_PORT": "",
    "ADD_TOPIC": "",
    "DELETE_TOPIC": "",
    "IOT_SERVER": "",
    "CHINA": "",
    "GLOBAL": "",
    "LOW_C": "",
    "LOW_C$": "",
    "LOW_D": "",
    "LOW_D$": "",
    "LOW_E": "",
    "LOW_F": "",
    "LOW_F$": "",
    "LOW_G": "",
    "LOW_G$": "",
    "LOW_A": "",
    "LOW_A$": "",
    "LOW_B": "",
    "MIDDLE_C": "",
    "MIDDLE_C$": "",
    "MIDDLE_D": "",
    "MIDDLE_D$": "",
    "MIDDLE_E": "",
    "MIDDLE_F": "",
    "MIDDLE_F$": "",
    "MIDDLE_G": "",
    "MIDDLE_G$": "",
    "MIDDLE_A": "",
    "MIDDLE_A$": "",
    "MIDDLE_B": "",
    "HIGH_C": "",
    "HIGH_C$": "",
    "HIGH_D": "",
    "HIGH_D$": "",
    "HIGH_E": "",
    "HIGH_F": "",
    "HIGH_F$": "",
    "HIGH_G": "",
    "HIGH_G$": "",
    "HIGH_A": "",
    "HIGH_A$": "",
    "HIGH_B": "",
    "OPERATORS_STRINGTONUMBER": "",
    "INTEGER": "",
    "DECIMAL": "",
    "OPERATORS_NUMTOASCII": "",
    "OPERATORS_STRINGTOASCII": "",
    "OPERATORS_NUMTOSTRING": "",
    "OPERATORS_MAP": "",
    "OPERATORS_CONSTRAIN": "",
    "STEPS_PER_TURN": "",
    "ROTATE_SPEED": "",
    "NEW_AI": "",
    "ASK_AND_PAINT": "",
    "GET_IDENTIFIED_NUM": "",
    "READ_NUM_AND_SAY": "",
    "IMAGE_ADDR": "",
    "IMAGE_PREVIEW": "",
    "IMAGE_OPEN": "",
    "IMAGE_SIZE": "",
    "IMAGE_WIDTH": "",
    "IMAGE_HEIGHT": "",
    "VARIABLE": "",
    "VARIABLE_LIST": "",
    "SET_STRING_VARIABLE": "",
    "STRING_START_WITH": "",
    "DICTIONARY_HEIGHT_FIRST": "",
    "DICTIONARY_HEIGHT_TWO": "",
    "DICTIONARY_HEIGHT_THREE": "",
    "DICTIONARY_HEIGHT_FOUR": "",
    "DATA_MPITEMOFLIST": "",
    "DATA_MPINTERCEPTITEM": "",
    "DATA_MPINSERTATLIST": "",
    "DATA_MPSETVALUEATLIST": "",
    "DATA_MPINSERTATLIST_INSERT": "",
    "DATA_MPINSERTATLIST_CHANGE": "",
    "DATA_MPINSERTATLIST_FOUR": "",
    "DATA_MPINSERTATLIST_FIVE": "",
    "DATA_MPINSERTATLIST_SIX": "",
    "DATA_MPINSERTATLIST_SEVEN": "",
    "DATA_MPINSERTATLIST_EIGHT": "",
    "DATA_MPINSERTATLIST_EIGHT_FALSE": "",
    "DATA_MPINSERTATLIST_EIGHT_TRUE": "",
    "DATA_MPINSERTATLIST_EIGHT_REVERSE": "",
    "OPERATORS_RANDOMA": "",
    "OPERATORS_MODA": "",
    "OPERATORS_ROUNDA": "",
    "OPERATORS_MATHOPA": "",
    "OPERATORS_MATHOP_ABSA": "",
    "OPERATORS_MATHOP_FLOORA": "",
    "OPERATORS_MATHOP_CEILINGA": "",
    "OPERATORS_MATHOP_SQRTA": "",
    "OPERATORS_MATHOP_FIVE": "",
    "OPERATORS_MATHOP_SIX": "",
    "OPERATORS_MATHOP_SEVEN": "",
    "OPERATORS_MATHOP_LENGHT": "",
    "OPERATORS_MATHOP_MAXMIN": "",
    "OPERATORS_MATHOP_MAXMIN_MAX": "",
    "OPERATORS_MATHOP_MAXMIN_MIN": "",
    "OPERATORS_TEXTS_ONE": "",
    "OPERATORS_TEXTS_TWO": "",
    "OPERATORS_TEXTS_THREE": "",
    "OPERATORS_TEXTS_FOUR": "",
    "OPERATORS_TEXTS_FOUR_NEW": "",
    "OPERATORS_TEXTS_NEW": "",
    "OPERATORS_TEXTS_NEW_OP": "",
    "OPERATORS_TEXTS_FOUR_ONE": "",
    "OPERATORS_TEXTS_FOUR_TWO": "",
    "OPERATORS_TEXTS_FOUR_THREE": "",
    "OPERATORS_TEXTS_FOUR_FOUR": "",
    "OPERATORS_TEXTS_FOUR_F": "",
    "OPERATORS_TEXTS_FOUR_L": "",
    "OPERATORS_STRINGTONUMBERA": "",
    "OPERATORS_NUMTOASCIIA": "",
    "OPERATORS_STRINGTOASCIIA": "",
    "OPERATORS_NUMTOSTRINGA": "",
    "NEW_MC": "",
    "RENAME_MCNUMBER_TITLE": "",
    "RENAME_MCNUMBER_MODAL_TITLE": "",
    "RENAME_VARIABLE_MCNUMBER": "",
    "DELETE_VARIABLE_MCNUMBER": "",
    "SET_MC_VARIABLE": "",
    "TUPLE_ONE": "",
    "TUPLE_TWO": "",
    "ALIYUN": "",
    "ONENET": "",
    "EASYIOT": "",
    "SHANGHAI": "",
    "QINGDAO": "",
    "BEIJING": "",
    "ZHANGJIAKOU": "",
    "HUHEHAOTE": "",
    "HANGZHOU": "",
    "SHENZHEN": "",
    "HONGKONG": "",
    "SINGAPORE": "",
    "SYDNEY": "",
    "KUALALUMPUR": "",
    "JAKARTA": "",
    "MUMBAI": "",
    "TOKYO": "",
    "SILICONVALLEY": "",
    "VIRGINIA": "",
    "FRANKFURT": "",
    "LONDON": "",
    "DUBAI": "",
    "IOT_PLATFORM": "",
    "PARAMS": "",
    "SERVER_ATTR": "",
    "PRODUCTID": "",
    "DEVICEID": "",
    "TINYDB_SECRET": "",
    "TINYDB_USER": "",
    "APIADDR": "",
    "SOFTWARE_SERIAL": "",
    "HARDWARE_SERIAL": "",
    "HARDWARE_SERIAL1": "",
    "HARDWARE_SERIAL2": "",
    "HARDWARE_SERIAL3": ""
  },
  "pt": {
    "CONTROL_FOREVER": "repete para sempre",
    "CONTROL_REPEAT": "repete %1 vezes",
    "CONTROL_IF": "se %1 , então",
    "CONTROL_ELSE": "senão,",
    "CONTROL_STOP": "pára",
    "CONTROL_STOP_ALL": "tudo",
    "CONTROL_STOP_THIS": "este guião",
    "CONTROL_STOP_OTHER": "os teus outros guiões",
    "CONTROL_WAIT": "espera %1 s",
    "CONTROL_WAITUNTIL": "espera até que %1",
    "CONTROL_REPEATUNTIL": "até que %1 , repete",
    "CONTROL_WHILE": "enquanto %1",
    "CONTROL_FOREACH": "para cada %1 em %2",
    "CONTROL_STARTASCLONE": "Quando fores criado como um clone",
    "CONTROL_CREATECLONEOF": "cria um clone de %1",
    "CONTROL_CREATECLONEOF_MYSELF": "ti mesmo",
    "CONTROL_DELETETHISCLONE": "remove-te como clone",
    "CONTROL_COUNTER": "contador",
    "CONTROL_INCRCOUNTER": "incrementa o contador",
    "CONTROL_CLEARCOUNTER": "põe o contador a zero",
    "CONTROL_ALLATONCE": "executa tudo de uma vez",
    "DATA_SETVARIABLETO": "altera %1 para %2",
    "DATA_CHANGEVARIABLEBY": "adiciona a %1 o valor %2",
    "DATA_SHOWVARIABLE": "mostra a variável %1",
    "DATA_HIDEVARIABLE": "esconde a variável %1",
    "DATA_ADDTOLIST": "acrescenta %1 a %2",
    "DATA_DELETEOFLIST": "remove %1 de %2",
    "DATA_DELETEALLOFLIST": "remove tudo de %1",
    "DATA_INSERTATLIST": "insere %1 como %2 de %3",
    "DATA_REPLACEITEMOFLIST": "substitui %1 de %2 por %3",
    "DATA_ITEMOFLIST": "%1 de %2",
    "DATA_ITEMNUMOFLIST": "o número do item %1 em %2",
    "DATA_LENGTHOFLIST": "o comprimento de %1",
    "DATA_LISTCONTAINSITEM": "%1 contém %2",
    "DATA_SHOWLIST": "mostra a lista %1",
    "DATA_HIDELIST": "esconde a lista %1",
    "DATA_INDEX_ALL": "tudo",
    "DATA_INDEX_LAST": "o último item",
    "DATA_INDEX_RANDOM": "um item ao acaso",
    "EVENT_WHENFLAGCLICKED": "Quando alguém clicar em %1",
    "EVENT_WHENTHISSPRITECLICKED": "Quando alguém clicar em ti",
    "EVENT_WHENSTAGECLICKED": "Quando alguém clicar no palco",
    "EVENT_WHENTOUCHINGOBJECT": "Quando tocares em %1",
    "EVENT_WHENBROADCASTRECEIVED": "Quando receberes a mensagem %1",
    "EVENT_WHENBACKDROPSWITCHESTO": "Quando o cenário mudar para %1",
    "EVENT_WHENGREATERTHAN": "Quando o valor do sensor %1 exceder %2",
    "EVENT_WHENGREATERTHAN_TIMER": "o valor do cronómetro",
    "EVENT_WHENGREATERTHAN_LOUDNESS": "o volume no microfone",
    "EVENT_BROADCAST": "difunde a mensagem %1",
    "EVENT_BROADCASTANDWAIT": "difunde a mensagem %1 e espera",
    "EVENT_WHENKEYPRESSED": "Quando alguém pressionar a tecla %1",
    "EVENT_WHENKEYPRESSED_SPACE": "espaço",
    "EVENT_WHENKEYPRESSED_LEFT": "seta para a esquerda",
    "EVENT_WHENKEYPRESSED_RIGHT": "seta para a direita",
    "EVENT_WHENKEYPRESSED_DOWN": "seta para baixo",
    "EVENT_WHENKEYPRESSED_UP": "seta para cima",
    "EVENT_WHENKEYPRESSED_ANY": "qualquer",
    "LOOKS_SAYFORSECS": "diz %1 durante %2 s",
    "LOOKS_SAY": "diz %1",
    "LOOKS_HELLO": "Olá!",
    "LOOKS_THINKFORSECS": "pensa %1 durante %2 s",
    "LOOKS_THINK": "pensa %1",
    "LOOKS_HMM": "Hmm…",
    "LOOKS_SHOW": "mostra-te",
    "LOOKS_HIDE": "esconde-te",
    "LOOKS_HIDEALLSPRITES": "esconde todos os actores",
    "LOOKS_EFFECT_COLOR": "cor",
    "LOOKS_EFFECT_FISHEYE": "olho de peixe",
    "LOOKS_EFFECT_WHIRL": "remoinho",
    "LOOKS_EFFECT_PIXELATE": "pixelização",
    "LOOKS_EFFECT_MOSAIC": "mosaico",
    "LOOKS_EFFECT_BRIGHTNESS": "brilho",
    "LOOKS_EFFECT_GHOST": "fantasma",
    "LOOKS_CHANGEEFFECTBY": "adiciona ao teu efeito %1 o valor %2",
    "LOOKS_SETEFFECTTO": "altera o teu efeito %1 para %2",
    "LOOKS_CLEARGRAPHICEFFECTS": "cancela os teus efeitos gráficos",
    "LOOKS_CHANGESIZEBY": "adiciona %1 ao teu tamanho",
    "LOOKS_SETSIZETO": "altera o teu tamanho para %1 %",
    "LOOKS_SIZE": "o tamanho",
    "LOOKS_CHANGESTRETCHBY": "adiciona %1 % ao esticamento",
    "LOOKS_SETSTRETCHTO": "altera o esticamento para %1 %",
    "LOOKS_SWITCHCOSTUMETO": "muda o teu traje para %1",
    "LOOKS_NEXTCOSTUME": "passa para o teu próximo traje",
    "LOOKS_SWITCHBACKDROPTO": "muda o cenário para %1",
    "LOOKS_GOTOFRONTBACK": "vai para a camada %1",
    "LOOKS_GOTOFRONTBACK_FRONT": "frontal",
    "LOOKS_GOTOFRONTBACK_BACK": "traseira",
    "LOOKS_GOFORWARDBACKWARDLAYERS": "%1 %2 camadas",
    "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "avança",
    "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "recua",
    "LOOKS_BACKDROPNUMBERNAME": "%1 do cenário",
    "LOOKS_COSTUMENUMBERNAME": "%1 do traje",
    "LOOKS_NUMBERNAME_NUMBER": "o número",
    "LOOKS_NUMBERNAME_NAME": "o nome",
    "LOOKS_SWITCHBACKDROPTOANDWAIT": "muda o cenário para %1 e espera",
    "LOOKS_NEXTBACKDROP_BLOCK": "passa para o teu próximo cenário",
    "LOOKS_NEXTBACKDROP": "passa para o teu próximo cenário",
    "LOOKS_PREVIOUSBACKDROP": "o cenário anterior",
    "LOOKS_RANDOMBACKDROP": "um cenário ao acaso",
    "MOTION_MOVESTEPS": "anda %1 passos",
    "MOTION_TURNLEFT": "gira %1 %2 °",
    "MOTION_TURNRIGHT": "gira %1 %2 °",
    "MOTION_POINTINDIRECTION": "altera a tua direcção para %1 °",
    "MOTION_POINTTOWARDS": "aponta em direcção a %1",
    "MOTION_POINTTOWARDS_POINTER": "ponteiro do rato",
    "MOTION_POINTTOWARDS_RANDOM": "uma direcção ao acaso",
    "MOTION_GOTO": "vai para %1",
    "MOTION_GOTO_POINTER": "o ponteiro do rato",
    "MOTION_GOTO_RANDOM": "uma posição ao acaso",
    "MOTION_GOTOXY": "vai para a posição x: %1 y: %2",
    "MOTION_GLIDESECSTOXY": "desliza em %1 s para a posição x: %2 y: %3",
    "MOTION_GLIDETO": "desliza em %1 s em direcção a%2",
    "MOTION_GLIDETO_POINTER": "o ponteiro do rato",
    "MOTION_GLIDETO_RANDOM": "uma posição ao acaso",
    "MOTION_CHANGEXBY": "adiciona %1 ao teu x",
    "MOTION_SETX": "altera o teu x para %1",
    "MOTION_CHANGEYBY": "adiciona %1 ao teu y",
    "MOTION_SETY": "altera o teu y para %1",
    "MOTION_IFONEDGEBOUNCE": "se estiveres a bater na borda, ressalta",
    "MOTION_SETROTATIONSTYLE": "altera o teu estilo de rotação para %1",
    "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "olha apenas para a esquerda e para a direita",
    "MOTION_SETROTATIONSTYLE_DONTROTATE": "não gires",
    "MOTION_SETROTATIONSTYLE_ALLAROUND": "gira a toda a volta",
    "MOTION_XPOSITION": "o x da tua posição",
    "MOTION_YPOSITION": "o y da tua posição",
    "MOTION_DIRECTION": "a direcção",
    "MOTION_SCROLLRIGHT": "rola %1 para a direita",
    "MOTION_SCROLLUP": "rola %1 para cima",
    "MOTION_ALIGNSCENE": "alinha a cena %1",
    "MOTION_ALIGNSCENE_BOTTOMLEFT": "ao fundo e à esquerda",
    "MOTION_ALIGNSCENE_BOTTOMRIGHT": "ao fundo e à direita",
    "MOTION_ALIGNSCENE_MIDDLE": "a meio",
    "MOTION_ALIGNSCENE_TOPLEFT": "no topo e à esquerda",
    "MOTION_ALIGNSCENE_TOPRIGHT": "no topo e à direita",
    "MOTION_XSCROLL": "o rolamento em x",
    "MOTION_YSCROLL": "o rolamento em y",
    "MOTION_STAGE_SELECTED": "Palco seleccionado: não tem blocos de movimento",
    "OPERATORS_ADD": "%1 + %2",
    "OPERATORS_SUBTRACT": "%1 − %2",
    "OPERATORS_MULTIPLY": "%1 × %2",
    "OPERATORS_DIVIDE": "%1 / %2",
    "OPERATORS_RANDOM": "um valor ao acaso entre %1 e %2",
    "OPERATORS_GT": "%1 > %2",
    "OPERATORS_GT_EQUALS": "%1 >= %2",
    "OPERATORS_LT": "%1 < %2",
    "OPERATORS_LT_EQUALS": "%1 <= %2",
    "OPERATORS_EQUALS": "%1 = %2",
    "OPERATORS_AND": "%1 e %2",
    "OPERATORS_OR": "%1 ou %2",
    "OPERATORS_NOT": "é falso que %1",
    "OPERATORS_JOIN": "a junção de %1 com %2",
    "OPERATORS_JOIN_APPLE": "maçã",
    "OPERATORS_JOIN_BANANA": "banana",
    "OPERATORS_LETTEROF": "o caractere %1 de %2",
    "OPERATORS_LETTEROF_APPLE": "a",
    "OPERATORS_LENGTH": "o comprimento de %1",
    "OPERATORS_CONTAINS": "%1 contém %2",
    "OPERATORS_MOD": "o resto de %1 a dividir por %2",
    "OPERATORS_ROUND": "o arredondamento de %1",
    "OPERATORS_MATHOP": "%1 de %2",
    "OPERATORS_MATHOP_ABS": "o valor absoluto",
    "OPERATORS_MATHOP_FLOOR": "o chão (o maior inteiro não superior)",
    "OPERATORS_MATHOP_CEILING": "o tecto (menor inteiro não inferior)",
    "OPERATORS_MATHOP_SQRT": "a raiz quadrada",
    "OPERATORS_MATHOP_SIN": "o seno",
    "OPERATORS_MATHOP_COS": "o cosseno",
    "OPERATORS_MATHOP_TAN": "a tangente",
    "OPERATORS_MATHOP_ASIN": "o arco-seno",
    "OPERATORS_MATHOP_ACOS": "o arco-cosseno",
    "OPERATORS_MATHOP_ATAN": "o arco-tangente",
    "OPERATORS_MATHOP_LN": "o logaritmo natural",
    "OPERATORS_MATHOP_LOG": "o logaritmo",
    "OPERATORS_MATHOP_EEXP": "a exponencial",
    "OPERATORS_MATHOP_10EXP": "10 ^",
    "PROCEDURES_DEFINITION": "Define %1",
    "SENSING_TOUCHINGOBJECT": "estás a tocar em %1",
    "SENSING_TOUCHINGOBJECT_POINTER": "o ponteiro do rato",
    "SENSING_TOUCHINGOBJECT_EDGE": "a borda",
    "SENSING_TOUCHINGCOLOR": "estás a tocar na cor %1",
    "SENSING_COLORISTOUCHINGCOLOR": "a cor %1 está a tocar na cor %2",
    "SENSING_DISTANCETO": "a distância até %1",
    "SENSING_DISTANCETO_POINTER": "o ponteiro do rato",
    "SENSING_ASKANDWAIT": "pergunta %1 e espera pela resposta",
    "SENSING_ASK_TEXT": "Como te chamas?",
    "SENSING_ANSWER": "a resposta",
    "SENSING_KEYPRESSED": "a tecla %1 está a ser pressionada",
    "SENSING_MOUSEDOWN": "o botão do rato está pressionado",
    "SENSING_MOUSEX": "o x da posição do rato",
    "SENSING_MOUSEY": "o y da posição do rato",
    "SENSING_SETDRAGMODE": "altera o teu modo de arrasto para %1",
    "SENSING_SETDRAGMODE_DRAGGABLE": "arrastável",
    "SENSING_SETDRAGMODE_NOTDRAGGABLE": "não arrastável",
    "SENSING_LOUDNESS": "o volume no microfone",
    "SENSING_LOUD": "o som é forte",
    "SENSING_TIMER": "o valor do cronómetro",
    "SENSING_RESETTIMER": "reinicia o cronómetro",
    "SENSING_OF": "%1 de %2",
    "SENSING_OF_XPOSITION": "o x da posição",
    "SENSING_OF_YPOSITION": "o y da posição",
    "SENSING_OF_DIRECTION": "a direcção",
    "SENSING_OF_COSTUMENUMBER": "o número do traje",
    "SENSING_OF_COSTUMENAME": "o nome do traje",
    "SENSING_OF_SIZE": "o tamanho",
    "SENSING_OF_VOLUME": "o volume",
    "SENSING_OF_BACKDROPNUMBER": "o número do cenário",
    "SENSING_OF_BACKDROPNAME": "o nome do cenário",
    "SENSING_OF_STAGE": "o palco",
    "SENSING_CURRENT": "%1 actual",
    "SENSING_CURRENT_YEAR": "o ano",
    "SENSING_CURRENT_MONTH": "o mês",
    "SENSING_CURRENT_DATE": "a data",
    "SENSING_CURRENT_DAYOFWEEK": "o dia da semana",
    "SENSING_CURRENT_HOUR": "a hora",
    "SENSING_CURRENT_MINUTE": "o minuto",
    "SENSING_CURRENT_SECOND": "o segundo",
    "SENSING_DAYSSINCE2000": "o número de dias desde 2000",
    "SENSING_USERNAME": "o nome de utilizador",
    "SENSING_USERID": "o ID de utilizador",
    "SOUND_PLAY": "toca o som %1",
    "SOUND_PLAYUNTILDONE": "toca o som %1 até terminar",
    "SOUND_STOPALLSOUNDS": "pára todos os sons",
    "SOUND_SETEFFECTO": "altera o teu efeito %1 para %2",
    "SOUND_CHANGEEFFECTBY": "adiciona ao teu efeito %1 o valor %2",
    "SOUND_CLEAREFFECTS": "cancela todos os teus efeitos sonoros",
    "SOUND_EFFECTS_PITCH": "tom",
    "SOUND_EFFECTS_PAN": "canal esquerdo/direito",
    "SOUND_CHANGEVOLUMEBY": "adiciona %1 % ao teu volume",
    "SOUND_SETVOLUMETO": "altera o teu volume para %1%",
    "SOUND_VOLUME": "o volume",
    "SOUND_RECORD": "gravar…",
    "CATEGORY_MOTION": "Movimento",
    "CATEGORY_LOOKS": "Aparência",
    "CATEGORY_SOUND": "Som",
    "CATEGORY_EVENTS": "Eventos",
    "CATEGORY_CONTROL": "Controlo",
    "CATEGORY_SENSING": "Sensores",
    "CATEGORY_OPERATORS": "Operadores",
    "CATEGORY_VARIABLES": "Variáveis",
    "CATEGORY_MYBLOCKS": "Os Meus Blocos",
    "CATEGORY_LISTS": "",
    "CATEGORY_TUPLE": "",
    "CATEGORY_DICTIONARYS": "",
    "CATEGORY_NUMBERS": "Número",
    "CATEGORY_TEXT": "Texto",
    "DUPLICATE": "Duplicar",
    "DELETE": "Remover",
    "ADD_COMMENT": "Adicionar Comentário",
    "REMOVE_COMMENT": "Remover Comentário",
    "DELETE_BLOCK": "Remover Bloco",
    "DELETE_X_BLOCKS": "Remover %1 Blocos",
    "DELETE_ALL_BLOCKS": "Remover todos os %1 blocos?",
    "CLEAN_UP": "Arrumar Blocos",
    "HELP": "Ajuda",
    "UNDO": "Desfazer",
    "REDO": "Refazer",
    "EDIT_PROCEDURE": "Editar",
    "SHOW_PROCEDURE_DEFINITION": "Ir para a definição",
    "WORKSPACE_COMMENT_DEFAULT_TEXT": "Diga qualquer coisa…",
    "COLOUR_HUE_LABEL": "Matiz",
    "COLOUR_SATURATION_LABEL": "Saturação",
    "COLOUR_BRIGHTNESS_LABEL": "Brilho",
    "CHANGE_VALUE_TITLE": "Alterar o valor:",
    "RENAME_VARIABLE": "Renomear a variável",
    "RENAME_VARIABLE_STRING": "Renomear variável String",
    "RENAME_VARIABLE_NUMBER": "Renomear variável numérica",
    "RENAME_VARIABLE_LIST": "Renomear variável Lista",
    "RENAME_VARIABLE_TITLE": "Renomear todas as variáveis «%1» para:",
    "RENAME_VARIABLE_MODAL_TITLE": "Renomear Variável",
    "NEW_VARIABLE": "Criar uma Variável",
    "NEW_VARIABLE_TITLE": "Novo nome da variável:",
    "VARIABLE_MODAL_TITLE": "Nova Variável",
    "VARIABLE_ALREADY_EXISTS": "Já existe uma variável com o nome «%1».",
    "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "Já existe uma variável com o nome «%1» do tipo «%2».",
    "DELETE_VARIABLE_CONFIRMATION": "Remover %1 utilizações da variável «%2»?",
    "CANNOT_DELETE_VARIABLE_PROCEDURE": "Não é possível remover a variável «%1» porque é parte da definição da função «%2»",
    "DELETE_VARIABLE": "Remover a variável «%1»",
    "DELETE_VARIABLE_STRING": "Delete the string variable「%1」",
    "DELETE_VARIABLE_NUMBER": "Eliminar a variável numérica「%1」",
    "DELETE_VARIABLE_LIST": "eliminar variável tipo lista「%1」",
    "NEW_PROCEDURE": "Criar um Bloco",
    "PROCEDURE_ALREADY_EXISTS": "Já existe um procedimento com o nome «%1».",
    "PROCEDURE_DEFAULT_NAME": "nome do bloco",
    "NEW_LIST": "Criar uma Lista",
    "NEW_LIST_TITLE": "Nome da nova lista:",
    "LIST_MODAL_TITLE": "Nova Lista",
    "LIST_ALREADY_EXISTS": "Já existe uma lista com o nome «%1».",
    "RENAME_LIST_TITLE": "Renomear todas as listas «%1» para:",
    "RENAME_LIST_MODAL_TITLE": "Renomear Lista",
    "DEFAULT_LIST_ITEM": "uma coisa",
    "DELETE_LIST": "Remover a lista «%1»",
    "RENAME_LIST": "Renomear a lista",
    "NEW_BROADCAST_MESSAGE": "Nova mensagem",
    "NEW_BROADCAST_MESSAGE_TITLE": "Nome da nova mensagem:",
    "BROADCAST_MODAL_TITLE": "Nova Mensagem",
    "DEFAULT_BROADCAST_MESSAGE_NAME": "Mensagem 1",
    "DELETE_PROCEDURE": "Para apagar a definição de um bloco, apagar primeiro todas as utilizações do bloco ",
    "OK": "OK",
    "PROMPT": "Aviso",
    "STOP": "Parar",
    "NEW_STRING": "Cria uma Variável String",
    "NEW_NUMBER_VARIABLE": "Cria uma Variável Numérica",
    "WIFI": "Wi-Fi",
    "USERNAME": "utilizador",
    "PWD": "palavra passe",
    "IOT_SERVICE": "IOT Service",
    "IOT_ID": "iot id",
    "IOT_PWD": "iot pwd",
    "IOT_TOPIC": "Topic_0",
    "MORE_TOPIC": "Topic",
    "OBLOQ_HTTP": "HTTP",
    "OBLOQ_IP": "IP Address",
    "OBLOQ_PORT": "Port",
    "ADD_TOPIC": "Adicionar Topic, só até 4",
    "DELETE_TOPIC": "Eliminar Topic",
    "IOT_SERVER": "Servidor",
    "CHINA": "China",
    "GLOBAL": "Global",
    "LOW_C": "Grave C/C3",
    "LOW_C$": "Grave C#/C#3",
    "LOW_D": "Grave D/D3",
    "LOW_D$": "Grave D#/D#3",
    "LOW_E": "Grave E/E3",
    "LOW_F": "Grave F/F3",
    "LOW_F$": "Grave F#/F#3",
    "LOW_G": "Grave G/G3",
    "LOW_G$": "Grave G#/G#3",
    "LOW_A": "Grave A/A3",
    "LOW_A$": "Grave A#/A#3",
    "LOW_B": "Grave B/B3",
    "MIDDLE_C": "Médio C/C4",
    "MIDDLE_C$": "Médio C#/C#4",
    "MIDDLE_D": "Médio D/D4",
    "MIDDLE_D$": "Médio D#/D#4",
    "MIDDLE_E": "Médio E/E4",
    "MIDDLE_F": "Médio F/F4",
    "MIDDLE_F$": "Médio F#/F#4",
    "MIDDLE_G": "Médio G/G4",
    "MIDDLE_G$": "Médio G#/G#4",
    "MIDDLE_A": "Médio A/A4",
    "MIDDLE_A$": "Médio A#/A#4",
    "MIDDLE_B": "Médio B/B4",
    "HIGH_C": "Agudo C/C5",
    "HIGH_C$": "Agudo C#/C#5",
    "HIGH_D": "Agudo D/D5",
    "HIGH_D$": "Agudo D#/D#5",
    "HIGH_E": "Agudo E/E5",
    "HIGH_F": "Agudo F/F5",
    "HIGH_F$": "Agudo F#/F#5",
    "HIGH_G": "Agudo G/G5",
    "HIGH_G$": "Agudo G#/G#5",
    "HIGH_A": "Agudo A/A5",
    "HIGH_A$": "Agudo A#/A#5",
    "HIGH_B": "Agudo B/B5",
    "OPERATORS_STRINGTONUMBER": "converte string %1 para %2",
    "INTEGER": "Inteiro",
    "DECIMAL": "Decimal",
    "OPERATORS_NUMTOASCII": "converter número %1 para ASCII",
    "OPERATORS_STRINGTOASCII": "converter string %1 para ASCII",
    "OPERATORS_NUMTOSTRING": "converter número %1 para string",
    "OPERATORS_MAP": "mapear %1 desde[ %2 , %3 ] até[ %4 , %5 ]",
    "OPERATORS_CONSTRAIN": "limitar %1 entre(min) %2 e(max) %3",
    "STEPS_PER_TURN": "Passos por volta",
    "ROTATE_SPEED": "Velocidade de Rotação(r/s)",
    "NEW_AI": "Cria um Bloco de AI ",
    "ASK_AND_PAINT": "escrever um número",
    "GET_IDENTIFIED_NUM": "número reconhecido",
    "READ_NUM_AND_SAY": "Diz o número reconhecido ",
    "IMAGE_ADDR": "Imagem Addr",
    "IMAGE_PREVIEW": "Previsão da imagem",
    "IMAGE_OPEN": "Abrir",
    "IMAGE_SIZE": "Tamanho da Imagem",
    "IMAGE_WIDTH": "Largura",
    "IMAGE_HEIGHT": "Altura",
    "VARIABLE": "%1",
    "VARIABLE_LIST": "%1",
    "SET_STRING_VARIABLE": "defina %1 para %2",
    "STRING_START_WITH": "%1 Começa com %2",
    "DICTIONARY_HEIGHT_FIRST": "",
    "DICTIONARY_HEIGHT_TWO": "",
    "DICTIONARY_HEIGHT_THREE": "",
    "DICTIONARY_HEIGHT_FOUR": "",
    "DATA_MPITEMOFLIST": "",
    "DATA_MPINTERCEPTITEM": "",
    "DATA_MPINSERTATLIST": "",
    "DATA_MPSETVALUEATLIST": "",
    "DATA_MPINSERTATLIST_INSERT": "",
    "DATA_MPINSERTATLIST_CHANGE": "",
    "DATA_MPINSERTATLIST_FOUR": "",
    "DATA_MPINSERTATLIST_FIVE": "",
    "DATA_MPINSERTATLIST_SIX": "",
    "DATA_MPINSERTATLIST_SEVEN": "",
    "DATA_MPINSERTATLIST_EIGHT": "",
    "DATA_MPINSERTATLIST_EIGHT_FALSE": "",
    "DATA_MPINSERTATLIST_EIGHT_TRUE": "",
    "DATA_MPINSERTATLIST_EIGHT_REVERSE": "",
    "OPERATORS_RANDOMA": "%1 %2 pick random %3 to %4 %5",
    "OPERATORS_MODA": "%1 %2 %3 mod %4",
    "OPERATORS_ROUNDA": "%1 %2 arrendondamento %3",
    "OPERATORS_MATHOPA": "%1 %2 %3 %4",
    "OPERATORS_MATHOP_ABSA": "abs",
    "OPERATORS_MATHOP_FLOORA": "ceiling",
    "OPERATORS_MATHOP_CEILINGA": "floor",
    "OPERATORS_MATHOP_SQRTA": "quadrado",
    "OPERATORS_MATHOP_FIVE": "%1 %2 muda %3 por %4",
    "OPERATORS_MATHOP_SIX": "Mapear %1 %2 %3 de [%4 , %5] a [%6 , %7]",
    "OPERATORS_MATHOP_SEVEN": "%1 %2 restringe %3 entre (min) %4 e (max) %5",
    "OPERATORS_MATHOP_LENGHT": "comprimento %1",
    "OPERATORS_MATHOP_MAXMIN": "%1 %2",
    "OPERATORS_MATHOP_MAXMIN_MAX": "max",
    "OPERATORS_MATHOP_MAXMIN_MIN": "min",
    "OPERATORS_TEXTS_ONE": "%1 %2 ingressar em %3 %4",
    "OPERATORS_TEXTS_TWO": "%1 %2 identifica o número %4 do caractere %3",
    "OPERATORS_TEXTS_THREE": "%1 %2 %3 contém %4 ?",
    "OPERATORS_TEXTS_FOUR": "%1 %2 %3 recebe %4 %5 caracteres para %6 %7 caracteres",
    "OPERATORS_TEXTS_FOUR_NEW": "%1 recebe %2 %3 caracteres para %4 %5 caracteres",
    "OPERATORS_TEXTS_NEW": "%1 %2 encontra %3 em %4 onde %5 aparece",
    "OPERATORS_TEXTS_NEW_OP": "encontre onde %1 aparece em %2 %3",
    "OPERATORS_TEXTS_FOUR_ONE": "Desde a esquerda",
    "OPERATORS_TEXTS_FOUR_TWO": "Desde a direita",
    "OPERATORS_TEXTS_FOUR_THREE": "Desde a esquerda",
    "OPERATORS_TEXTS_FOUR_FOUR": "Desde a direita",
    "OPERATORS_TEXTS_FOUR_F": "primeiro",
    "OPERATORS_TEXTS_FOUR_L": "último",
    "OPERATORS_STRINGTONUMBERA": "%1 %2 converte string %3 para %4",
    "OPERATORS_NUMTOASCIIA": "%1 %2 converte número %3 em caratere ASCII ",
    "OPERATORS_STRINGTOASCIIA": "%1 %2 converte carater %3 num valor ASCII ",
    "OPERATORS_NUMTOSTRINGA": "%1 %2 converte número %3 numa string",
    "NEW_MC": "cria variável de micropython ",
    "RENAME_MCNUMBER_TITLE": "renomeia todas as variáveis cujo nome é [%1] para:",
    "RENAME_MCNUMBER_MODAL_TITLE": "renomear variável",
    "RENAME_VARIABLE_MCNUMBER": "renomear variável",
    "DELETE_VARIABLE_MCNUMBER": "eliminar variável [%1]",
    "SET_MC_VARIABLE": "define %1 para %2",
    "TUPLE_ONE": "",
    "TUPLE_TWO": "",
    "ALIYUN": "Aliyun",
    "ONENET": "OneNet",
    "EASYIOT": "Easy IoT",
    "SHANGHAI": "Shanghai",
    "QINGDAO": "Qingdao",
    "BEIJING": "Beijing",
    "ZHANGJIAKOU": "Zhangjiakou",
    "HUHEHAOTE": "Huhehaote",
    "HANGZHOU": "Hangzhou",
    "SHENZHEN": "Shenzhen",
    "HONGKONG": "Hongkong",
    "SINGAPORE": "Singapore",
    "SYDNEY": "Sydney",
    "KUALALUMPUR": "Luala Lumpur",
    "JAKARTA": "Jakarta",
    "MUMBAI": "Mumbai",
    "TOKYO": "Tokyo",
    "SILICONVALLEY": "Silicon Valley",
    "VIRGINIA": "Virginia",
    "FRANKFURT": "Frankfurt",
    "LONDON": "London",
    "DUBAI": "Dubai",
    "IOT_PLATFORM": "Iot Platform",
    "PARAMS": "Params",
    "SERVER_ATTR": "Server Addr",
    "PRODUCTID": "ProductId",
    "DEVICEID": "DeviceId",
    "TINYDB_SECRET": "secret (secret)",
    "TINYDB_USER": "user (user)",
    "APIADDR": "API Addr",
    "SOFTWARE_SERIAL": "Software Serial",
    "HARDWARE_SERIAL": "Hardware Serial",
    "HARDWARE_SERIAL1": "Hardware Serial1",
    "HARDWARE_SERIAL2": "Hardware Serial2",
    "HARDWARE_SERIAL3": "Hardware Serial3",
    "CANCEL": "Cancelar",
    "CHECKTYPE_TIPS": "The data type received by the input box does not match the output type of the building block",
    "HIGHLIGHT_BLOCK": "hight light block",
    "HIGHLIGHT_ALL_CONFLICT_BLOCKS": "highlight all blocks with warnings",
    "SNAPSHOT": "Snapshot (CTRL+G)",
    "pictureaiPath": "please enter the picture path or URL",
    "pictureaiType": "account type",
    "pictureaiAccountDefault": "default account",
    "pictureaiAccountBaidu": "baidu account",
    "pictureaiwebImgAddr": "web image address",
    "MITRIXICON_EDIT": "light up",
    "MITRIXICON_ERASER": "eraser",
    "MITRIXICON_DUSTBIN": "clear",
    "MITRIXICON_REVERSE": "reverse",
    "MITRIXICON_SAVE": "save",
    "MITRIXICON_EXPORT": "export",
    "MITRIXICON_UPLOAD": "upload",
    "ROLL_MOUSE": "Rolling mouse",
    "MOUSE_ZOOM_IN_OUT": "Scroll the mouse to zoom in and out",
    "UPLOAD_CORRECT_JSON_FILE": "Please upload the json file in the correct format",
    "EXPORT_PROMPT": "There are no custom patterns to export. Please click the save button in the lower right corner to save the custom pattern before attempting to export."
  },
  "pt-br": {
    "CONTROL_FOREVER": "sempre",
    "CONTROL_REPEAT": "repita %1 vezes",
    "CONTROL_IF": "se %1 então",
    "CONTROL_ELSE": "senão",
    "CONTROL_STOP": "pare",
    "CONTROL_STOP_ALL": "todos",
    "CONTROL_STOP_THIS": "este script",
    "CONTROL_STOP_OTHER": "outros scripts no ator",
    "CONTROL_WAIT": "espere %1 seg",
    "CONTROL_WAITUNTIL": "espere até que %1",
    "CONTROL_REPEATUNTIL": "repita até que %1",
    "CONTROL_WHILE": "enquanto %1",
    "CONTROL_FOREACH": "para cada %1 em %2",
    "CONTROL_STARTASCLONE": "quando eu começar como um clone",
    "CONTROL_CREATECLONEOF": "crie clone de %1",
    "CONTROL_CREATECLONEOF_MYSELF": "este ator",
    "CONTROL_DELETETHISCLONE": "apague este clone",
    "CONTROL_COUNTER": "contador",
    "CONTROL_INCRCOUNTER": "desenvolver contador",
    "CONTROL_CLEARCOUNTER": "limpar contador",
    "CONTROL_ALLATONCE": "todos de uma vez",
    "DATA_SETVARIABLETO": "mude %1 para %2",
    "DATA_CHANGEVARIABLEBY": "adicione %2 a %1",
    "DATA_SHOWVARIABLE": "mostre a variável %1",
    "DATA_HIDEVARIABLE": "esconda a variável %1",
    "DATA_ADDTOLIST": "adicione %1 a %2",
    "DATA_DELETEOFLIST": "apague %1 de %2",
    "DATA_DELETEALLOFLIST": "apague todos os itens de %1",
    "DATA_INSERTATLIST": "insira %1 na posição %2 de %3",
    "DATA_REPLACEITEMOFLIST": "substitua o item %1 de %2 por %3",
    "DATA_ITEMOFLIST": "item %1 de %2",
    "DATA_ITEMNUMOFLIST": "item # de %1 em %2",
    "DATA_LENGTHOFLIST": "tamanho de %1",
    "DATA_LISTCONTAINSITEM": "%1 contém %2?",
    "DATA_SHOWLIST": "mostre a lista %1",
    "DATA_HIDELIST": "esconda a lista %1",
    "DATA_INDEX_ALL": "todos",
    "DATA_INDEX_LAST": "último",
    "DATA_INDEX_RANDOM": "aleatório",
    "EVENT_WHENFLAGCLICKED": "quando %1 for clicado",
    "EVENT_WHENTHISSPRITECLICKED": "quando este ator for clicado",
    "EVENT_WHENSTAGECLICKED": "quando o palco for clicado",
    "EVENT_WHENTOUCHINGOBJECT": "quando este ator tocar %1",
    "EVENT_WHENBROADCASTRECEIVED": "quando eu receber %1",
    "EVENT_WHENBACKDROPSWITCHESTO": "quando o cenário mudar para %1",
    "EVENT_WHENGREATERTHAN": "quando %1 > %2",
    "EVENT_WHENGREATERTHAN_TIMER": "cronômetro",
    "EVENT_WHENGREATERTHAN_LOUDNESS": "ruído",
    "EVENT_BROADCAST": "transmita %1",
    "EVENT_BROADCASTANDWAIT": "transmita %1 e espere",
    "EVENT_WHENKEYPRESSED": "quando a tecla %1 for pressionada",
    "EVENT_WHENKEYPRESSED_SPACE": "espaço",
    "EVENT_WHENKEYPRESSED_LEFT": "seta para esquerda",
    "EVENT_WHENKEYPRESSED_RIGHT": "seta para direita",
    "EVENT_WHENKEYPRESSED_DOWN": "seta para baixo",
    "EVENT_WHENKEYPRESSED_UP": "seta para cima",
    "EVENT_WHENKEYPRESSED_ANY": "qualquer",
    "LOOKS_SAYFORSECS": "diga %1 por %2 segundos",
    "LOOKS_SAY": "diga %1",
    "LOOKS_HELLO": "Olá!",
    "LOOKS_THINKFORSECS": "pense %1 por %2 segundos",
    "LOOKS_THINK": "pense %1",
    "LOOKS_HMM": "Hummm...",
    "LOOKS_SHOW": "mostre",
    "LOOKS_HIDE": "esconda",
    "LOOKS_HIDEALLSPRITES": "esconda todos os atores",
    "LOOKS_EFFECT_COLOR": "cor",
    "LOOKS_EFFECT_FISHEYE": "olho de peixe",
    "LOOKS_EFFECT_WHIRL": "turbilhão",
    "LOOKS_EFFECT_PIXELATE": "pixelado",
    "LOOKS_EFFECT_MOSAIC": "mosaico",
    "LOOKS_EFFECT_BRIGHTNESS": "brilho",
    "LOOKS_EFFECT_GHOST": "fantasma",
    "LOOKS_CHANGEEFFECTBY": "mude %2 ao efeito %1",
    "LOOKS_SETEFFECTTO": "defina o efeito %1 como %2",
    "LOOKS_CLEARGRAPHICEFFECTS": "remova os efeitos gráficos",
    "LOOKS_CHANGESIZEBY": "mude %1 no tamanho",
    "LOOKS_SETSIZETO": "defina o tamanho como %1 %",
    "LOOKS_SIZE": "tamanho",
    "LOOKS_CHANGESTRETCHBY": "mude trajeto por %1",
    "LOOKS_SETSTRETCHTO": "defina curso para %1 %",
    "LOOKS_SWITCHCOSTUMETO": "mude para a fantasia %1",
    "LOOKS_NEXTCOSTUME": "próxima fantasia",
    "LOOKS_SWITCHBACKDROPTO": "mude para o cenário %1",
    "LOOKS_GOTOFRONTBACK": "Vá para a camada %1",
    "LOOKS_GOTOFRONTBACK_FRONT": "da frente",
    "LOOKS_GOTOFRONTBACK_BACK": "de trás",
    "LOOKS_GOFORWARDBACKWARDLAYERS": "Vá para %1 %2 camadas",
    "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "frente",
    "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "trás",
    "LOOKS_BACKDROPNUMBERNAME": "cenário %1",
    "LOOKS_COSTUMENUMBERNAME": "fantasia %1",
    "LOOKS_NUMBERNAME_NUMBER": "número",
    "LOOKS_NUMBERNAME_NAME": "nome",
    "LOOKS_SWITCHBACKDROPTOANDWAIT": "mude para o cenário %1 e espere",
    "LOOKS_NEXTBACKDROP_BLOCK": "próximo cenário",
    "LOOKS_NEXTBACKDROP": "próximo cenário",
    "LOOKS_PREVIOUSBACKDROP": "cenário anterior",
    "LOOKS_RANDOMBACKDROP": "cenário aleatório",
    "MOTION_MOVESTEPS": "mova %1 passos",
    "MOTION_TURNLEFT": "gire %1 %2 graus",
    "MOTION_TURNRIGHT": "gire %1 %2 graus",
    "MOTION_POINTINDIRECTION": "aponte  para a direção %1",
    "MOTION_POINTTOWARDS": "aponte para %1",
    "MOTION_POINTTOWARDS_POINTER": "ponteiro do mouse",
    "MOTION_POINTTOWARDS_RANDOM": "direção aleatória",
    "MOTION_GOTO": "vá para %1",
    "MOTION_GOTO_POINTER": "ponteiro do mouse",
    "MOTION_GOTO_RANDOM": "posição aleatória",
    "MOTION_GOTOXY": "vá para x: %1 y: %2",
    "MOTION_GLIDESECSTOXY": "deslize por %1 segs. até x: %2 y: %3",
    "MOTION_GLIDETO": "deslize por %1 segs. até %2",
    "MOTION_GLIDETO_POINTER": "ponteiro do mouse",
    "MOTION_GLIDETO_RANDOM": "posição aleatória",
    "MOTION_CHANGEXBY": "adicione %1 a x",
    "MOTION_SETX": "mude x para %1",
    "MOTION_CHANGEYBY": "adicione %1 a y",
    "MOTION_SETY": "mude y para %1",
    "MOTION_IFONEDGEBOUNCE": "se tocar na borda, volte",
    "MOTION_SETROTATIONSTYLE": "defina o estilo de rotação para %1",
    "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "esquerda-direita",
    "MOTION_SETROTATIONSTYLE_DONTROTATE": "não rotacionar",
    "MOTION_SETROTATIONSTYLE_ALLAROUND": "rotação completa",
    "MOTION_XPOSITION": "posição x",
    "MOTION_YPOSITION": "posição y",
    "MOTION_DIRECTION": "direção",
    "MOTION_SCROLLRIGHT": "rolar para direita %1 %",
    "MOTION_SCROLLUP": "rolar para cima %1",
    "MOTION_ALIGNSCENE": "alinhar cena %1",
    "MOTION_ALIGNSCENE_BOTTOMLEFT": "inferior esquerda",
    "MOTION_ALIGNSCENE_BOTTOMRIGHT": "inferior direita",
    "MOTION_ALIGNSCENE_MIDDLE": "meio",
    "MOTION_ALIGNSCENE_TOPLEFT": "superior esquerdo",
    "MOTION_ALIGNSCENE_TOPRIGHT": "superior direita",
    "MOTION_XSCROLL": "rolagem eixo x",
    "MOTION_YSCROLL": "rolagem eixo y",
    "MOTION_STAGE_SELECTED": "Palco selecionado: sem blocos de movimento",
    "OPERATORS_ADD": "%1 + %2",
    "OPERATORS_SUBTRACT": "%1 - %2",
    "OPERATORS_MULTIPLY": "%1 * %2",
    "OPERATORS_DIVIDE": "%1 / %2",
    "OPERATORS_RANDOM": "número aleatório entre %1 e %2",
    "OPERATORS_GT": "%1 > %2",
    "OPERATORS_GT_EQUALS": "%1 >= %2",
    "OPERATORS_LT": "%1 < %2",
    "OPERATORS_LT_EQUALS": "%1 <= %2",
    "OPERATORS_EQUALS": "%1 = %2",
    "OPERATORS_AND": "%1 e %2",
    "OPERATORS_OR": "%1 ou %2",
    "OPERATORS_NOT": "não %1",
    "OPERATORS_JOIN": "junte %1 com %2",
    "OPERATORS_JOIN_APPLE": "maçã",
    "OPERATORS_JOIN_BANANA": "banana",
    "OPERATORS_LETTEROF": "letra %1 de %2",
    "OPERATORS_LETTEROF_APPLE": "m",
    "OPERATORS_LENGTH": "tamanho de %1",
    "OPERATORS_CONTAINS": "%1 contém %2?",
    "OPERATORS_MOD": "resto de %1 por %2",
    "OPERATORS_ROUND": "arredondamento de %1",
    "OPERATORS_MATHOP": "%1 de %2",
    "OPERATORS_MATHOP_ABS": "módulo",
    "OPERATORS_MATHOP_FLOOR": "arredondamento para baixo de",
    "OPERATORS_MATHOP_CEILING": "arredondamento para cima de",
    "OPERATORS_MATHOP_SQRT": "raiz quadrada",
    "OPERATORS_MATHOP_SIN": "sen",
    "OPERATORS_MATHOP_COS": "cos",
    "OPERATORS_MATHOP_TAN": "tg",
    "OPERATORS_MATHOP_ASIN": "arcsen",
    "OPERATORS_MATHOP_ACOS": "arccos",
    "OPERATORS_MATHOP_ATAN": "arctg",
    "OPERATORS_MATHOP_LN": "ln",
    "OPERATORS_MATHOP_LOG": "logaritmo",
    "OPERATORS_MATHOP_EEXP": "e elevado à ",
    "OPERATORS_MATHOP_10EXP": "10 elevado à ",
    "PROCEDURES_DEFINITION": "defina %1",
    "SENSING_TOUCHINGOBJECT": "tocando em %1?",
    "SENSING_TOUCHINGOBJECT_POINTER": "ponteiro do mouse",
    "SENSING_TOUCHINGOBJECT_EDGE": "borda",
    "SENSING_TOUCHINGCOLOR": "tocando na cor %1?",
    "SENSING_COLORISTOUCHINGCOLOR": "a cor %1 está tocando na cor %2?",
    "SENSING_DISTANCETO": "distância até %1",
    "SENSING_DISTANCETO_POINTER": "ponteiro do mouse",
    "SENSING_ASKANDWAIT": "pergunte %1 e espere",
    "SENSING_ASK_TEXT": "Qual o seu nome?",
    "SENSING_ANSWER": "resposta",
    "SENSING_KEYPRESSED": "tecla %1 pressionada?",
    "SENSING_MOUSEDOWN": "mouse pressionado?",
    "SENSING_MOUSEX": "posição x do mouse",
    "SENSING_MOUSEY": "posição y do mouse",
    "SENSING_SETDRAGMODE": "defina modo de arrasto para %1",
    "SENSING_SETDRAGMODE_DRAGGABLE": "arrastável",
    "SENSING_SETDRAGMODE_NOTDRAGGABLE": "não arrastável",
    "SENSING_LOUDNESS": "ruído",
    "SENSING_LOUD": "alto?",
    "SENSING_TIMER": "cronômetro",
    "SENSING_RESETTIMER": "zere o cronômetro",
    "SENSING_OF": "%1 de %2",
    "SENSING_OF_XPOSITION": "posição x",
    "SENSING_OF_YPOSITION": "posição y",
    "SENSING_OF_DIRECTION": "direção",
    "SENSING_OF_COSTUMENUMBER": "n° da fantasia",
    "SENSING_OF_COSTUMENAME": "nome da fantasia",
    "SENSING_OF_SIZE": "tamanho",
    "SENSING_OF_VOLUME": "volume",
    "SENSING_OF_BACKDROPNUMBER": "n° do cenário",
    "SENSING_OF_BACKDROPNAME": "nome do cenário",
    "SENSING_OF_STAGE": "Palco",
    "SENSING_CURRENT": "%1 atual",
    "SENSING_CURRENT_YEAR": "ano",
    "SENSING_CURRENT_MONTH": "mês",
    "SENSING_CURRENT_DATE": "data",
    "SENSING_CURRENT_DAYOFWEEK": "dia da semana",
    "SENSING_CURRENT_HOUR": "hora",
    "SENSING_CURRENT_MINUTE": "minuto",
    "SENSING_CURRENT_SECOND": "segundo",
    "SENSING_DAYSSINCE2000": "número de dias desde 2000",
    "SENSING_USERNAME": "nome de usuário",
    "SENSING_USERID": "id do usuário",
    "SOUND_PLAY": "toque o som %1",
    "SOUND_PLAYUNTILDONE": "toque o som %1 até o fim",
    "SOUND_STOPALLSOUNDS": "pare todos os sons",
    "SOUND_SETEFFECTO": "mude o efeito %1 para %2",
    "SOUND_CHANGEEFFECTBY": "mude  %2 no efeito %1",
    "SOUND_CLEAREFFECTS": "remova os efeitos sonoros ",
    "SOUND_EFFECTS_PITCH": "tom",
    "SOUND_EFFECTS_PAN": "balanço esquerda/direita",
    "SOUND_CHANGEVOLUMEBY": "mude volume em %1",
    "SOUND_SETVOLUMETO": "mude o volume para %1%",
    "SOUND_VOLUME": "volume",
    "SOUND_RECORD": "gravar...",
    "CATEGORY_MOTION": "Movimento",
    "CATEGORY_LOOKS": "Aparência",
    "CATEGORY_SOUND": "Som",
    "CATEGORY_EVENTS": "Eventos",
    "CATEGORY_CONTROL": "Controle",
    "CATEGORY_SENSING": "Sensores",
    "CATEGORY_OPERATORS": "Operadores",
    "CATEGORY_VARIABLES": "Variáveis",
    "CATEGORY_MYBLOCKS": "Meus Blocos",
    "CATEGORY_LISTS": "",
    "CATEGORY_TUPLE": "",
    "CATEGORY_DICTIONARYS": "",
    "CATEGORY_NUMBERS": "Número",
    "CATEGORY_TEXT": "Texto",
    "DUPLICATE": "Duplicar",
    "DELETE": "Apagar",
    "ADD_COMMENT": "Comentar",
    "REMOVE_COMMENT": "Remover Comentário",
    "DELETE_BLOCK": "Apagar Bloco",
    "DELETE_X_BLOCKS": "Apagar %1 Blocos",
    "DELETE_ALL_BLOCKS": "Apagar todos os blocos %1?",
    "CLEAN_UP": "Limpar Blocos",
    "HELP": "Ajuda",
    "UNDO": "Desfazer",
    "REDO": "Refazer",
    "EDIT_PROCEDURE": "Editar",
    "SHOW_PROCEDURE_DEFINITION": "Vá para definição",
    "WORKSPACE_COMMENT_DEFAULT_TEXT": "Diga algo...",
    "COLOUR_HUE_LABEL": "Cor",
    "COLOUR_SATURATION_LABEL": "Saturação",
    "COLOUR_BRIGHTNESS_LABEL": "Brilho",
    "CHANGE_VALUE_TITLE": "Mudar valor:",
    "RENAME_VARIABLE": "Renomear variável",
    "RENAME_VARIABLE_STRING": "Renomear variável de texto",
    "RENAME_VARIABLE_NUMBER": "Renomear variável numérica",
    "RENAME_VARIABLE_LIST": "Renomear variável de lista",
    "RENAME_VARIABLE_TITLE": "Renomear todas as variáveis \"%1\" para:",
    "RENAME_VARIABLE_MODAL_TITLE": "Renomear Variável",
    "NEW_VARIABLE": "Criar uma Variável",
    "NEW_VARIABLE_TITLE": "Nome da nova variável:",
    "VARIABLE_MODAL_TITLE": "Nova Variável",
    "VARIABLE_ALREADY_EXISTS": "Uma variável chamada \"%1\" já existe.",
    "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "Uma variável chamada \"%1\" já existe para outra variável do tipo \"%2\".",
    "DELETE_VARIABLE_CONFIRMATION": "Apagar os %1 usos da variável \"%2\"?",
    "CANNOT_DELETE_VARIABLE_PROCEDURE": "Não é possível apagar a variável '%1' pois ela faz parte da definição do bloco '%2'",
    "DELETE_VARIABLE": "Apagar a variável '%1'",
    "DELETE_VARIABLE_STRING": "Excluir variável de texto 「%1」",
    "DELETE_VARIABLE_NUMBER": "Excluir variável numérica 「%1」",
    "DELETE_VARIABLE_LIST": "Excluir variável de lista 「%1」",
    "NEW_PROCEDURE": "Criar um bloco",
    "PROCEDURE_ALREADY_EXISTS": "Já existe um bloco chamado '%1'.",
    "PROCEDURE_DEFAULT_NAME": "nome do bloco",
    "NEW_LIST": "Criar uma Lista",
    "NEW_LIST_TITLE": "Nome da nova lista:",
    "LIST_MODAL_TITLE": "Nova Lista",
    "LIST_ALREADY_EXISTS": "Já existe uma lista chamada '%1'.",
    "RENAME_LIST_TITLE": "Renomear todas as listas \"%1\" para:",
    "RENAME_LIST_MODAL_TITLE": "Renomear Lista",
    "DEFAULT_LIST_ITEM": "coisa",
    "DELETE_LIST": "Apagar a lista \"%1\"",
    "RENAME_LIST": "Renomear lista",
    "NEW_BROADCAST_MESSAGE": "Nova mensagem",
    "NEW_BROADCAST_MESSAGE_TITLE": "Novo nome de mensagem:",
    "BROADCAST_MODAL_TITLE": "Nova Mensagem",
    "DEFAULT_BROADCAST_MESSAGE_NAME": "mensagem 1",
    "DELETE_PROCEDURE": "Para excluir a definição de um bloco, remova primeiro todos os usos do bloco.",
    "OK": "OK",
    "PROMPT": "Prompt",
    "STOP": "Parar",
    "NEW_STRING": "Criar uma Variável de Texto",
    "NEW_NUMBER_VARIABLE": "Criar uma Variável Numérica",
    "WIFI": "Wi-Fi",
    "USERNAME": "usuário",
    "PWD": "senha",
    "IOT_SERVICE": "Serviço de IoT",
    "IOT_ID": "ID do IoT",
    "IOT_PWD": "Senha do IoT",
    "IOT_TOPIC": "Tópico_0",
    "MORE_TOPIC": "Tópico",
    "OBLOQ_HTTP": "HTTP",
    "OBLOQ_IP": "Endereço IP",
    "OBLOQ_PORT": "Porta",
    "ADD_TOPIC": "Adicionar Tópico, só é possível adicionar até 4",
    "DELETE_TOPIC": "Excluir Tópico",
    "IOT_SERVER": "Servidor",
    "CHINA": "China",
    "GLOBAL": "Global",
    "LOW_C": " Baixo C/C3",
    "LOW_C$": " Baixo C#/C#3",
    "LOW_D": " Baixo D/D3",
    "LOW_D$": " Baixo D#/D#3",
    "LOW_E": " Baixo E/E3",
    "LOW_F": " Baixo F/F3",
    "LOW_F$": " Baixo F#/F#3",
    "LOW_G": " Baixo G/G3",
    "LOW_G$": " Baixo G#/G#3",
    "LOW_A": " Baixo A/A3",
    "LOW_A$": " Baixo A#/A#3",
    "LOW_B": " Baixo B/B3",
    "MIDDLE_C": " Médio C/C4",
    "MIDDLE_C$": " Médio C#/C#4",
    "MIDDLE_D": " Médio D/D4",
    "MIDDLE_D$": " Médio D#/D#4",
    "MIDDLE_E": " Médio E/E4",
    "MIDDLE_F": " Médio F/F4",
    "MIDDLE_F$": " Médio F#/F#4",
    "MIDDLE_G": " Médio G/G4",
    "MIDDLE_G$": " Médio G#/G#4",
    "MIDDLE_A": " Médio A/A4",
    "MIDDLE_A$": " Médio A#/A#4",
    "MIDDLE_B": " Médio B/B4",
    "HIGH_C": " Alto C/C5",
    "HIGH_C$": " Alto C#/C#5",
    "HIGH_D": " Alto D/D5",
    "HIGH_D$": " Alto D#/D#5",
    "HIGH_E": " Alto E/E5",
    "HIGH_F": " Alto F/F5",
    "HIGH_F$": " Alto F#/F#5",
    "HIGH_G": " Alto G/G5",
    "HIGH_G$": " Alto G#/G#5",
    "HIGH_A": " Alto A/A5",
    "HIGH_A$": " Alto A#/A#5",
    "HIGH_B": " Alto B/B5",
    "OPERATORS_STRINGTONUMBER": "converter string %1 para %2",
    "INTEGER": "Inteiro",
    "DECIMAL": "Decimal",
    "OPERATORS_NUMTOASCII": "converter número %1 para ASCII",
    "OPERATORS_STRINGTOASCII": "converter string %1 para ASCII",
    "OPERATORS_NUMTOSTRING": "converter número %1 para string",
    "OPERATORS_MAP": "mapear %1 de [%2 , %3] para [%4 , %5]",
    "OPERATORS_CONSTRAIN": "limitar %1 entre(mínimo) %2 e(máximo) %3",
    "STEPS_PER_TURN": "passos por volta",
    "ROTATE_SPEED": "velocidade de rotação(r/s)",
    "NEW_AI": "Criar um Bloco de IA",
    "ASK_AND_PAINT": "por favor, desenhe um número",
    "GET_IDENTIFIED_NUM": "reconhecimento de número",
    "READ_NUM_AND_SAY": "dizer o número reconhecido",
    "IMAGE_ADDR": "Endereço da Imagem",
    "IMAGE_PREVIEW": "Visualizar Imagem",
    "IMAGE_OPEN": "abrir",
    "IMAGE_SIZE": "Tamanho da Imagem",
    "IMAGE_WIDTH": "L",
    "IMAGE_HEIGHT": "A",
    "VARIABLE": "%1",
    "VARIABLE_LIST": "%1",
    "SET_STRING_VARIABLE": "definir %1 para %2",
    "STRING_START_WITH": "%1 começa com %2",
    "DICTIONARY_HEIGHT_FIRST": "",
    "DICTIONARY_HEIGHT_TWO": "",
    "DICTIONARY_HEIGHT_THREE": "",
    "DICTIONARY_HEIGHT_FOUR": "",
    "DATA_MPITEMOFLIST": "",
    "DATA_MPINTERCEPTITEM": "",
    "DATA_MPINSERTATLIST": "",
    "DATA_MPSETVALUEATLIST": "",
    "DATA_MPINSERTATLIST_INSERT": "",
    "DATA_MPINSERTATLIST_CHANGE": "",
    "DATA_MPINSERTATLIST_FOUR": "",
    "DATA_MPINSERTATLIST_FIVE": "",
    "DATA_MPINSERTATLIST_SIX": "",
    "DATA_MPINSERTATLIST_SEVEN": "",
    "DATA_MPINSERTATLIST_EIGHT": "",
    "DATA_MPINSERTATLIST_EIGHT_FALSE": "",
    "DATA_MPINSERTATLIST_EIGHT_TRUE": "",
    "DATA_MPINSERTATLIST_EIGHT_REVERSE": "",
    "OPERATORS_RANDOMA": "%1 %2 escolher aleatoriamente de %3 a %4 %5",
    "OPERATORS_MODA": "%1 %2 %3 mod %4",
    "OPERATORS_ROUNDA": "%1 %2 arredondar %3",
    "OPERATORS_MATHOPA": "%1 %2 %3 %4",
    "OPERATORS_MATHOP_ABSA": "abs",
    "OPERATORS_MATHOP_FLOORA": "teto",
    "OPERATORS_MATHOP_CEILINGA": "chão",
    "OPERATORS_MATHOP_SQRTA": "raiz quadrada",
    "OPERATORS_MATHOP_FIVE": "%1 %2 alterar %3 por %4",
    "OPERATORS_MATHOP_SIX": "%1 %2 mapear %3 de [%4 , %5] para [%6 , %7]",
    "OPERATORS_MATHOP_SEVEN": "%1 %2 limitar %3 entre(mínimo) %4 e(máximo) %5",
    "OPERATORS_MATHOP_LENGHT": "comprimento %1",
    "OPERATORS_MATHOP_MAXMIN": "%1 %2",
    "OPERATORS_MATHOP_MAXMIN_MAX": "máximo",
    "OPERATORS_MATHOP_MAXMIN_MIN": "mínimo",
    "OPERATORS_TEXTS_ONE": "%1 %2 juntar %3 %4",
    "OPERATORS_TEXTS_TWO": "%1 %2 letra %4 de %3",
    "OPERATORS_TEXTS_THREE": "%1 %2 %3 contém %4?",
    "OPERATORS_TEXTS_FOUR": "%1 %2 letra %5 %4 a %7 %6 de %3",
    "OPERATORS_TEXTS_FOUR_NEW": "%1 pega %2 %3 caracteres até %4 %5 caracteres",
    "OPERATORS_TEXTS_NEW": "%1 %2 encontrar %3 em %4 %5 posição emergente",
    "OPERATORS_TEXTS_NEW_OP": "encontrar onde %1 aparece em %2 %3",
    "OPERATORS_TEXTS_FOUR_ONE": "da esquerda",
    "OPERATORS_TEXTS_FOUR_TWO": "da direita",
    "OPERATORS_TEXTS_FOUR_THREE": "da esquerda",
    "OPERATORS_TEXTS_FOUR_FOUR": "da direita",
    "OPERATORS_TEXTS_FOUR_F": "primeiro",
    "OPERATORS_TEXTS_FOUR_L": "último",
    "OPERATORS_STRINGTONUMBERA": "%1 %2 converter string %3 para %4",
    "OPERATORS_NUMTOASCIIA": "%1 %2 converter número %3 em caracteres ASCII",
    "OPERATORS_STRINGTOASCIIA": "%1 %2 converter caractere %3 em valor ASCII",
    "OPERATORS_NUMTOSTRINGA": "%1 %2 converter número %3 em string",
    "NEW_MC": "Criar variável",
    "RENAME_MCNUMBER_TITLE": "Renomear todas as variáveis cujo nome é [%1] para:",
    "RENAME_MCNUMBER_MODAL_TITLE": "Renomear variável",
    "RENAME_VARIABLE_MCNUMBER": "Renomear variável",
    "DELETE_VARIABLE_MCNUMBER": "Excluir variável [%1]",
    "SET_MC_VARIABLE": "Definir %1 para %2",
    "TUPLE_ONE": "",
    "TUPLE_TWO": "",
    "ALIYUN": "Aliyun",
    "ONENET": "OneNet",
    "EASYIOT": "Easy Iot",
    "SHANGHAI": "Xangai",
    "QINGDAO": "Qingdao",
    "BEIJING": "Pequim",
    "ZHANGJIAKOU": "Zhangjiakou",
    "HUHEHAOTE": "Huhehaote",
    "HANGZHOU": "Hangzhou",
    "SHENZHEN": "Shenzhen",
    "HONGKONG": "Hong Kong",
    "SINGAPORE": "Cingapura",
    "SYDNEY": "Sydney",
    "KUALALUMPUR": "Luala Lumpur",
    "JAKARTA": "Jacarta",
    "MUMBAI": "Mumbai",
    "TOKYO": "Tóquio",
    "SILICONVALLEY": "Vale do Silício",
    "VIRGINIA": "Virgínia",
    "FRANKFURT": "Frankfurt",
    "LONDON": "Londres",
    "DUBAI": "Dubai",
    "IOT_PLATFORM": "Plataforma IoT",
    "PARAMS": "Parâmetros",
    "SERVER_ATTR": "Endereço do Servidor",
    "PRODUCTID": "ID do Produto",
    "DEVICEID": "ID do Dispositivo",
    "TINYDB_SECRET": "senha (senha)",
    "TINYDB_USER": "usuário (usuário)",
    "APIADDR": "Endereço da API",
    "SOFTWARE_SERIAL": "Serial de Software",
    "HARDWARE_SERIAL": "Serial de Hardware",
    "HARDWARE_SERIAL1": "Serial de Hardware1",
    "HARDWARE_SERIAL2": "Serial de Hardware2",
    "HARDWARE_SERIAL3": "Serial de Hardware3",
    "CONTROL_LOOP_BREAKCONTINUE": "%1",
    "CONTROL_LOOP_BREAKCONTINUE_BREAK": "parar",
    "CONTROL_LOOP_BREAKCONTINUE_CONTINUE": "continuar",
    "CONTROL_WAIT_ESP32_MPY": "esperar %1 %2",
    "CONTROL_FOR_FROM_SEQUENCE": "usar %1 da sequência %2",
    "CONTROL_FOR_RANGE_SPACE": "Usar %1 do intervalo %2 a %3 a cada %4",
    "CONTROL_WAIT_ESP32_MPY_S": "segundo(s)",
    "CONTROL_WAIT_ESP32_MPY_MS": "milissegundo(ms)",
    "CONTROL_WAIT_ESP32_MPY_US": "microssegundo(us)",
    "CONTROL_TRUE_FALSE": "%1",
    "CONTROL_TRUE_FALSE_TRUE": "true",
    "CONTROL_NONE": "Nenhum",
    "CONTROL_RETURN": "retornar %1",
    "OPERATORS_VALUE_IS_TRUE": "%1 é verdadeiro?",
    "OPERATORS_VALUE_TYPE": "%1 tipo",
    "CONTROL_TRY": "tentar",
    "CONTROL_EXCEPT": "exceto",
    "CONTROL_FINALLY": "finalmente",
    "OPERATORS_VALUE_TYPE_IS": "%1 tipo é %2?",
    "OPERATORS_VALUE_TYPE_INT": "inteiro",
    "OPERATORS_VALUE_TYPE_FLOAT": "flutuante",
    "OPERATORS_VALUE_TYPE_BOOL": "booleano",
    "OPERATORS_VALUE_TYPE_STR": "string",
    "OPERATORS_VALUE_TYPE_LIST": "lista",
    "OPERATORS_VALUE_TYPE_TUPLE": "tupla",
    "OPERATORS_VALUE_TYPE_SET": "conjunto",
    "OPERATORS_VALUE_TYPE_DICT": "dicionário",
    "OPERATORS_VALUE_TYPE_BYTES": "bytes",
    "OPERATORS_VALUE_TYPE_BYTEARRAY": "bytearray",
    "OPERATORS_VALUE_TYPE_COMPLEX": "complexo",
    "OPERATORS_CACULATE_RESULT": "avaliar %1",
    "OPERATORS_MATHOP_NEGATIVE": "-",
    "OPERATORS_MATHOP_INT": "inteiro",
    "OPERATORS_MATHOP_FLOAT": "flutuante",
    "DUPLICATE_CURRENT": "Duplicar um único",
    "CANCEL": "Cancelar",
    "IP": "Endereço IP",
    "WIFI_VALIDATE": "A senha do Wi-Fi deve ter de 8 a 20 letras e números",
    "SIOT": "SIOT",
    "GLOBAL_MPY_VARIABLE": "global %1",
    "OPERATORS_NUM_INPUT": "%1 %2 número %3",
    "OPERATORS_NUMS_CACULATE": "%1 %2 %3",
    "OPERATORS_MATHOP_SQUARE": "**",
    "OPERATORS_MATHOP_LOGIC_OR": "|",
    "OPERATORS_MATHOP_LOGIC_AND": "&",
    "OPERATORS_MATHOP_LOGIC_POWER": "^",
    "OPERATORS_MATHOP_LOGIC_LEFT": "<<",
    "OPERATORS_MATHOP_LOGIC_RIGHT": ">>",
    "OPERATORS_SPECIAL_NUM": "%1 %2 %3",
    "OPERATORS_MATHOP_PI": "π",
    "OPERATORS_MATHOP_LOGARITHM": "e",
    "OPERATORS_MATHOP_FAI": "φ",
    "OPERATORS_MATHOP_SQRT2": "raiz quadrada de 2",
    "OPERATORS_MATHOP_SQRTHALF": "raiz quadrada de ½",
    "OPERATORS_MATHOP_UNLIMITED": "∞",
    "OPERATORS_NUMS_TYPE": "%1 %2 %3 %4?",
    "OPERATORS_MATHOP_ISEVEN": "é par",
    "OPERATORS_MATHOP_ISODD": "é ímpar",
    "OPERATORS_MATHOP_ISINT": "é int",
    "OPERATORS_MATHOP_ISPOSITIVE": "é positivo",
    "OPERATORS_MATHOP_ISNEGATIVE": "é negativo",
    "OPERATORS_SAVE2DECIMAL": "%1 %2 %3 salvar %4 casas decimais",
    "OPERATORS_LIST_CACULATE": "%1 %2 %3 %4",
    "OPERATORS_MATHOP_LIST_SUM": "soma dos números na lista",
    "OPERATORS_MATHOP_LIST_MIN": "valor mínimo na lista",
    "OPERATORS_MATHOP_LIST_MAX": "valor máximo na lista",
    "OPERATORS_MATHOP_LIST_AVERAGE": "média na lista",
    "OPERATORS_MATHOP_LIST_NUM": "número de dígitos na lista",
    "OPERATORS_MATHOP_LIST_MODE": "modo da lista",
    "OPERATORS_MATHOP_LIST_STDDEVIATION": "desvio padrão na lista",
    "OPERATORS_MATHOP_LIST_RANDOM": "item aleatório da lista",
    "OPERATORS_QUOTIENT_INT": "%1 %2 %3 ÷ %4 parte inteira do quociente",
    "OPERATORS_RANDOM_DECIMAL": "%1 %2 número decimal aleatório",
    "OPERATORS_BIN_NEGATION": "%1 %2 negação binária %3",
    "OPERATORS_INT_TO_STR": "%1 %2 número inteiro decimal %3 transformar em %4 string",
    "OPERATORS_MATHOP_INTTOSTR_BIN": "binário",
    "OPERATORS_MATHOP_INTTOSTR_OCT": "octal",
    "OPERATORS_MATHOP_INTTOSTR_HEX": "hexadecimal",
    "OPERATORS_BINSTR_TO_INT": "%1 %2 %3 string %4 transformar em número inteiro decimal",
    "OPERATORS_INT_TO_BYTE": "%1 %2 número inteiro decimal %3 transformar em %4 byte",
    "OPERATORS_MATHOP_INTTOBYTE_TWO": "2",
    "OPERATORS_MATHOP_INTTOBYTE_FOUR": "4",
    "OPERATORS_INT_TO_ASCII": "%1 %2 int %3 transformar em caractere ASCII",
    "OPERATORS_ASCII_TO_INT": "%1 %2 caractere ASCII %3 para int",
    "OPERATORS_STR_TO_INT": "%1 %2 string %3 para string hexadecimal",
    "OPERATORS_HEXSTR_TO_BIN": "%1 %2 string hexadecimal %3 para byte binário",
    "OPERATORS_STR_TO_BASE64": "%1 %2 %3 codifica em formato base64 retorna um objeto byte",
    "OPERATORS_BASE64_TO_BYTE": "%1 %2 decodificar dados base64 %3 retorna um objeto byte",
    "OPERATORS_INPUTSTRING": "%1 %2 string %3",
    "OPERATORS_TRANSINTOTEXT": "%1 %2 transformar em %3",
    "OPERATORS_TRANSINTOTEXT_ADD": "adicionar",
    "OPERATORS_TRANSINTOTEXT_ADD_ITEM": "item",
    "OPERATORS_FORMATSTRING2F": "%1 %2 formatar string %3 % %4",
    "OPERATORS_FORMATSTRING3": "%1 %2 formatar string %3 formato %4",
    "OPERATORS_TEXT_ESCAPE": "%1 %2 caractere de escape de texto %3",
    "BAR_N": "\\n",
    "BAR_NN": "\\n\\r",
    "BAR_R": "\\r",
    "BAR_RN": "\\r\\n",
    "BAR_B": "\\b",
    "BAR_T": "\\t",
    "BAR_BAR": "\\\\",
    "OPERATORS_TEXT_NUMORLETTER": "%1 %2 %3 %4?",
    "OPERATORS_TEXT_NUMORLETTER_NUM": "É número",
    "OPERATORS_TEXT_NUMORLETTER_LETTER": "É letra",
    "OPERATORS_TEXT_LENGTH": "%1 %2 %3 comprimento",
    "OPERATORS_TEXT_ISNONE": "%1 %2 %3 é nulo?",
    "OPERATORS_FROMTEXT_GETCHAR": "%1 %2 no texto %3 %4",
    "OPERATORS_FROMTEXT_GETCHAR_FIRST": "Primeira letra",
    "OPERATORS_FROMTEXT_GETCHAR_LAST": "Última letra",
    "OPERATORS_FROMTEXT_GETCHAR_RANDOM": "Letra aleatória",
    "OPERATORS_TEXT_UPPLOW": "%1 %2 %3 %4",
    "OPERATORS_TEXT_UPPLOW_UPP": "Maiúsculas",
    "OPERATORS_TEXT_UPPLOW_LOW": "Minúsculas",
    "OPERATORS_TEXT_SIDES_SPACE": "%1 %2 %3 %4",
    "OPERATORS_TEXT_SIDES_SPACE_BOTH": "Remover espaços dos dois lados",
    "OPERATORS_TEXT_SIDES_SPACE_LEFT": "Remover espaço à esquerda",
    "OPERATORS_TEXT_SIDES_SPACE_RIGHT": "Remover espaço à direita",
    "OPERATORS_TEXT_STRTOBYTE": "%1 %2 converter byte %3",
    "OPERATORS_TEXT_BYTETOSTR": "%1 %2 byte %3 converter para string",
    "OPERATORS_TEXT_DICTTOJSONSTR": "%1 %2 dicionário %3 transformar em string JSON",
    "OPERATORS_TEXT_PARSEJSONSTR": "%1 %2 analisar string JSON %3 e retornar objeto",
    "ONENETNEW": "OneNet(Novo)",
    "DEVICENAME": "Nome do Dispositivo",
    "CHECKTYPE_TIPS": "O tipo de dados recebido pela caixa de entrada não corresponde ao tipo de saída do bloco de construção",
    "HIGHLIGHT_BLOCK": "Destacar bloco",
    "HIGHLIGHT_ALL_CONFLICT_BLOCKS": "Destacar todos os blocos com avisos",
    "SNAPSHOT": "Instantâneo (CTRL+G)",
    "pictureaiPath": "Por favor, insira o caminho ou URL da imagem",
    "pictureaiType": "Tipo de conta",
    "pictureaiAccountDefault": "Conta padrão",
    "pictureaiAccountBaidu": "Conta Baidu",
    "pictureaiwebImgAddr": "Endereço da imagem da web",
    "MITRIXICON_EDIT": "Acender",
    "MITRIXICON_ERASER": "Borracha",
    "MITRIXICON_DUSTBIN": "Limpar",
    "MITRIXICON_REVERSE": "Inverter",
    "MITRIXICON_COLOR": "Selecionar cor",
    "RED": "Vermelho",
    "GREEN": "Verde",
    "BLUE": "Azul",
    "SEARCH_BLOCKS": "Buscar blocos",
    "NO_SEARCH_BLOCKS": "Sem resultado",
    "SEARCH_DEFAULT_LABEL_THINK": "Pensar",
    "SEARCH_DEFAULT_LABEL_IF": "Se",
    "SEARCH_DEFAULT_LABEL_WAIT": "Aguardar",
    "SEARCH_DEFAULT_LABEL_CONVERT": "Converter",
    "SEARCH_DEFAULT_LABEL_BROADCAST": "Transmitir",
    "MITRIXICON_SAVE": "Salvar",
    "MITRIXICON_EXPORT": "Exportar",
    "MITRIXICON_UPLOAD": "Carregar",
    "ROLL_MOUSE": "Rolar o mouse",
    "MOUSE_ZOOM_IN_OUT": "Rolar o mouse para dar zoom",
    "UPLOAD_CORRECT_JSON_FILE": "Por favor, faça o upload do arquivo JSON no formato correto",
    "EXPORT_PROMPT": "Não há padrões personalizados para exportar. Clique no botão de salvar no canto inferior direito para salvar o padrão personalizado antes de tentar exportar.",
    "HEART": "Coração",
    "HEART_SMALL": "Coração pequeno",
    "HAPPY": "Feliz",
    "SMILE": "Sorriso",
    "SAD": "Triste",
    "CONFUSED": "Confuso",
    "ANGRY": "Bravo",
    "SLEEP": "Dormir",
    "SURPRISED": "Surpreso",
    "SILLY": "Bobo",
    "WONDERFUL": "Maravilhoso",
    "BORED": "Entediado",
    "ROCK": "Pedra",
    "ROCK_SMALL": "Pedra pequena",
    "PAPER": "Papel",
    "PAPER_SMALL": "Papel pequeno",
    "SCISSORS": "Tesoura",
    "SCISSORS_SMALL": "Tesoura pequena",
    "DATA_SOLITLIST": "%1 %2 fazer %3 %4 com delimitador %5",
    "DATA_SOLITLIST_TYPE_LT": "Lista a partir do texto",
    "DATA_SOLITLIST_TYPE_TL": "Texto a partir da lista",
    "DATA_LENLIST": "%1 %2 %3 está vazio?",
    "DATA_LENGTHLIST": "Comprimento de %1 %2 %3",
    "DATA_CREATELIST": "%1 %2 criar lista %3",
    "DATA_CLEARLIST": "%1 %2 limpar lista %3",
    "ML5MODALBUTTON": "Abrir Interface Visual da Open AI",
    "COLLAPSE_BLOCK": "Contrair",
    "EXPAND_BLOCK": "Expandir",
    "COLLAPSE_ALL": "Contrair todos os blocos",
    "EXPAND_ALL": "Expandir todos os blocos",
    "pyTimer": "Temporizador",
    "pyList": "Lista",
    "pyDictionary": "Dicionário",
    "pySet": "Conjunto",
    "mpyTuple": "Tupla",
    "pythonBase_printAndInput": "Entrada e Saída",
    "pythonBase_mainSetUp": "%1 %2 início do programa principal em Python",
    "pythonBase_print": "%1 %2 imprimir %3",
    "pythonBase_input": "%1 %2 obter a entrada, o prompt é %3",
    "pythonBase_file": "Arquivo",
    "pythonBase_file_open": "%1 %2 abrir arquivo %5 no formato %3 no modo %4 e retornar %6",
    "pythonBase_file_open_b": "%1 %2 abrir arquivo %4 no modo %3 e retornar %5",
    "pythonBase_file_open_read": "Ler",
    "pythonBase_file_open_write": "Escrever",
    "pythonBase_file_open_add": "Adicionar",
    "pythonBase_file_open_read_bit": "Leitura binária",
    "pythonBase_file_open_write_bit": "Escrita binária",
    "pythonBase_file_open_add_bit": "Adição binária",
    "pythonBase_file_close": "%1 %2 %3 fechar",
    "pythonBase_file_read": "%1 %2 %3 ler %4",
    "pythonBase_file_read_all": "Arquivo inteiro",
    "pythonBase_file_read_line": "Uma linha",
    "pythonBase_file_read_lines": "Todas as linhas",
    "pythonBase_file_write": "%1 %2 %3 escrever %4",
    "pythonBase_time": "Tempo do sistema",
    "pythonBase_get_time": "%1 %2 obter o tempo do sistema %3",
    "pythonBase_get_time_by_year": "Ano",
    "pythonBase_get_time_by_month": "Mês",
    "pythonBase_get_time_by_day": "Dia",
    "pythonBase_get_time_by_hour": "Hora",
    "pythonBase_get_time_by_minute": "Minuto",
    "pythonBase_get_time_by_second": "Segundo",
    "pythonBase_get_time_by_day_of_week": "Dia da semana",
    "pythonBase_get_time_by_day_of_year": "Dia do ano",
    "pythonBase_get_time_format": "%1 %2 obter o formato do tempo do sistema %3",
    "pythonBase_get_time_format_by_hour_minute_second": "Hora:minuto:segundo",
    "pythonBase_get_time_format_by_hour_minute": "Hora:minuto",
    "pythonBase_get_time_format_by_year_month_day": "Ano/mês/dia",
    "pythonBase_get_time_format_by_year_month": "Ano/mês",
    "pythonBase_get_time_format_by_year_month_day_": "Ano-mês-dia",
    "pythonBase_get_time_format_by_year_month_day_hour_minute_second": "Ano/mês/dia hora:minuto:segundo",
    "pythonBase_get_timestamp": "%1 %2 obter o carimbo de data e hora do sistema",
    "pythonBase_get_time_format_custom": "%1 %2 obter o formato do tempo do sistema (%3)",
    "pyTimer_setEvent": "%1 %2 configurar temporizador %3 %4 ciclo %5 milissegundos",
    "pyTimer_repeated": "Execução repetida",
    "pyTimer_delayed": "Execução adiada",
    "pyTimer_conditionEvent": "%1 %2 configurar temporizador %3: quando %4",
    "pyTimer_getCount": "%1 %2 valor contado do temporizador %3",
    "pyTimer_clearEvent": "%1 %2 limpar temporizador/evento %3",
    "pyDictionary_init": "%1 %2 inicializar dicionário %3",
    "pyDictionary_getValue": "%1 %2 dicionário %3 chave %4 valor",
    "pyDictionary_setValue": "O valor da chave %1 %2 no dicionário %3 chave %4 é definido como %5",
    "pyDictionary_deleteKey": "%1 %2 dicionário %3 excluir chave %4",
    "pyDictionary_clear": "%1 %2 limpar dicionário %3",
    "pyDictionary_isInclude": "%1 %2 dicionário %3 contém a chave %4?",
    "pyDictionary_getLength": "%1 %2 comprimento do dicionário %3",
    "pyDictionary_list": "%1 %2 dicionário %3 %4 lista",
    "pyDictionary_TypeIndex_A": "Chave",
    "pyDictionary_TypeIndex_B": "Valor",
    "pyList_join": "%1 %2 lista %3 usa o separador %4 para combinar texto",
    "pyList_split": "%1 %2 texto %3 usa o separador %4 para fazer uma lista",
    "pyList_init": "%1 %2 inicialização de lista %3",
    "pyList_clear": "%1 %2 limpar lista %3",
    "pyList_getLength": "%1 %2 comprimento da lista %3",
    "pyList_isEmpty": "%1 %2 lista %3 está vazia?",
    "pyList_getValue": "%1 %2 lista %3 índice %4 valor",
    "pyList_getValueRange": "%1 %2 retorna a lista %3 e pega %4 %5 itens para %6 %7 itens",
    "pyList_insert": "%1 %2 lista %3 inserir %5 no índice %4",
    "pyList_setValue": "%1 %2 lista %3 definir índice %4 para valor %5",
    "pyList_delete": "%1 %2 lista %3 excluir índice %4 valor",
    "pyList_append": "%1 %2 lista %3 adiciona %4 ao final",
    "pyList_extend": "%1 %2 lista %3 anexar lista %4",
    "pyList_findIndex": "%1 %2 lista %3 encontrar índice de %4",
    "pyList_sort": "%1 %2 lista %3 ordenada por %4 como %5",
    "pyList_SortModeIndex_A": "Ordem crescente",
    "pyList_SortModeIndex_B": "Ordem decrescente",
    "pyList_SortTypeIndex_A": "Número",
    "pyList_SortTypeIndex_B": "Letra",
    "pyList_SortTypeIndex_C": "Letras, ignorar maiúsculas e minúsculas",
    "pyList_has": "%1 %2 lista %3 possui %4?",
    "pySet_init": "%1 %2 inicializar conjunto %3",
    "pySet_initEmpty": "%1 %2 inicializar conjunto vazio",
    "pySet_update": "%1 %2 conjunto %3 atualizado para ser %5 com conjunto %4",
    "pySet_TypeIndex_A": "Interseção",
    "pySet_TypeIndex_B": "União",
    "pySet_TypeIndex_C": "Diferença",
    "pySet_remove": "%1 %2 conjunto %3 remover %4",
    "pySet_clear": "%1 %2 limpar conjunto %3",
    "pySet_copy": "%1 %2 copiar conjunto %3",
    "pySet_has": "%1 %2 conjunto %3 possui %4",
    "pySet_addValue": "%1 %2 conjunto %3 adicionar %4",
    "pySet_isSubsetSuperset": "%1 %2 conjunto %3 é %5 do conjunto %4?",
    "pySet_SubsetSupersetType_A": "Subconjunto",
    "pySet_SubsetSupersetType_B": "Superset",
    "pySet_intersectionUnionSetDifference": "%1 %2 leva %3 conjunto %4 conjunto %5",
    "pySet_length": "%1 %2 conjunto %3 comprimento",
    "pySet_pop": "%1 %2 itens aleatórios retornados e removidos do conjunto %3",
    "pyTuple_init": "%1 %2 inicializou tupla %3",
    "pyTuple_minMaxLength": "%1 %2 tupla %3 %4",
    "pyTuple_TypeIndex_A": "Valor Mínimo",
    "pyTuple_TypeIndex_B": "Valor Máximo",
    "pyTuple_TypeIndex_C": "Comprimento",
    "pyTuple_isInclude": "%1 %2 tupla %3 contém %4?",
    "pyTuple_getValueRange": "%1 %2 retorna a tupla %3 pegando %4 %5 itens para %6 %7 itens",
    "pyTuple_IndexType_A": "#",
    "pyTuple_IndexType_B": "contagem regressiva #",
    "pyTuple_listToTulpe": "%1 %2 lista %3 para tupla",
    "pyTuple_getValue": "%1 %2 tupla %3 índice %4 valor",
    "pyTuple_tupleToList": "%1 %2 tupla %3 transformada em uma lista",
    "text_decode_encode": "decodificar ou codificar",
    "coder": "%1 %2 %3 texto %4 %5",
    "decode": "decodificar",
    "encode": "codificar",
    "mathop_specially": "%1 %2 converter %3 em %4"
  },
  "ro": {
    "CONTROL_FOREVER": "la infinit",
    "CONTROL_REPEAT": "repetă %1",
    "CONTROL_IF": "dacă %1 atunci",
    "CONTROL_ELSE": "altfel",
    "CONTROL_STOP": "stop",
    "CONTROL_STOP_ALL": "totul",
    "CONTROL_STOP_THIS": "acest script",
    "CONTROL_STOP_OTHER": "alte scripturi pentru personaj",
    "CONTROL_WAIT": "așteaptă %1 secunde",
    "CONTROL_WAITUNTIL": "așteaptă până când %1",
    "CONTROL_REPEATUNTIL": "repetă până când %1",
    "CONTROL_WHILE": "cât timp %1",
    "CONTROL_FOREACH": "pentru fiecare %1 în %2",
    "CONTROL_STARTASCLONE": "când încep ca și clonă",
    "CONTROL_CREATECLONEOF": "creează o clonă a %1",
    "CONTROL_CREATECLONEOF_MYSELF": "eu",
    "CONTROL_DELETETHISCLONE": "șterge această clonă",
    "CONTROL_COUNTER": "contor",
    "CONTROL_INCRCOUNTER": "incrementează contor",
    "CONTROL_CLEARCOUNTER": "resetează contor",
    "CONTROL_ALLATONCE": "integral",
    "DATA_SETVARIABLETO": "setează %1 la %2",
    "DATA_CHANGEVARIABLEBY": "schimbă %1 cu %2",
    "DATA_SHOWVARIABLE": "arată variabila %1",
    "DATA_HIDEVARIABLE": "ascunde variabila %1",
    "DATA_ADDTOLIST": "adaugă %1 la %2",
    "DATA_DELETEOFLIST": "șterge %1 din %2",
    "DATA_DELETEALLOFLIST": "șterge tot din %1",
    "DATA_INSERTATLIST": "inserează %1 la %2 din %3",
    "DATA_REPLACEITEMOFLIST": "înlocuiește item %1 din %2 cu %3",
    "DATA_ITEMOFLIST": "element %1 din %2",
    "DATA_ITEMNUMOFLIST": "nr. elementului %1 în %2",
    "DATA_LENGTHOFLIST": "lungimea lui %1",
    "DATA_LISTCONTAINSITEM": "%1 conține %2?",
    "DATA_SHOWLIST": "arată lista %1",
    "DATA_HIDELIST": "ascunde lista %1",
    "DATA_INDEX_ALL": "totul",
    "DATA_INDEX_LAST": "ultim",
    "DATA_INDEX_RANDOM": "aleator",
    "EVENT_WHENFLAGCLICKED": "când se dă click pe %1",
    "EVENT_WHENTHISSPRITECLICKED": "când se dă click pe acest personaj",
    "EVENT_WHENSTAGECLICKED": "când se dă clic pe scenă",
    "EVENT_WHENTOUCHINGOBJECT": "când personajul atinge %1",
    "EVENT_WHENBROADCASTRECEIVED": "când primesc %1",
    "EVENT_WHENBACKDROPSWITCHESTO": "când decorul se schimbă la %1",
    "EVENT_WHENGREATERTHAN": "când %1>%2",
    "EVENT_WHENGREATERTHAN_TIMER": "cronometru",
    "EVENT_WHENGREATERTHAN_LOUDNESS": "intensitatea sunetului",
    "EVENT_BROADCAST": "difuzează %1",
    "EVENT_BROADCASTANDWAIT": "difuzează %1 și așteaptă",
    "EVENT_WHENKEYPRESSED": "când tasta %1 este apăsată",
    "EVENT_WHENKEYPRESSED_SPACE": "spațiu",
    "EVENT_WHENKEYPRESSED_LEFT": "săgeată stânga",
    "EVENT_WHENKEYPRESSED_RIGHT": "săgeată dreapta",
    "EVENT_WHENKEYPRESSED_DOWN": "săgeată jos",
    "EVENT_WHENKEYPRESSED_UP": "săgeată în sus",
    "EVENT_WHENKEYPRESSED_ANY": "oricare",
    "LOOKS_SAYFORSECS": "spune %1 pentru %2 secunde",
    "LOOKS_SAY": "spune %1",
    "LOOKS_HELLO": "Salut!",
    "LOOKS_THINKFORSECS": "gândește %1 pentru %2 secunde ",
    "LOOKS_THINK": "gândește %1",
    "LOOKS_HMM": "Hmm...",
    "LOOKS_SHOW": "arată",
    "LOOKS_HIDE": "ascunde",
    "LOOKS_HIDEALLSPRITES": "ascunde toate personajele",
    "LOOKS_EFFECT_COLOR": "culoare",
    "LOOKS_EFFECT_FISHEYE": "ochi de pește",
    "LOOKS_EFFECT_WHIRL": "vârtej",
    "LOOKS_EFFECT_PIXELATE": "pixelare",
    "LOOKS_EFFECT_MOSAIC": "mozaic",
    "LOOKS_EFFECT_BRIGHTNESS": "strălucire",
    "LOOKS_EFFECT_GHOST": "fantomă",
    "LOOKS_CHANGEEFFECTBY": "modifică efectul %1 cu %2",
    "LOOKS_SETEFFECTTO": "setează efectul %1 la %2",
    "LOOKS_CLEARGRAPHICEFFECTS": "anulează efectele grafice",
    "LOOKS_CHANGESIZEBY": "modifică mărimea cu %1",
    "LOOKS_SETSIZETO": "setează mărimea la %1 %",
    "LOOKS_SIZE": "mărime",
    "LOOKS_CHANGESTRETCHBY": "schimbă lărgimea cu %1",
    "LOOKS_SETSTRETCHTO": "setează lărgimea %1",
    "LOOKS_SWITCHCOSTUMETO": "schimbă costumul la %1",
    "LOOKS_NEXTCOSTUME": "costumul următor",
    "LOOKS_SWITCHBACKDROPTO": "schimbă decorul la %1",
    "LOOKS_GOTOFRONTBACK": "mergi la stratul %1",
    "LOOKS_GOTOFRONTBACK_FRONT": "în față",
    "LOOKS_GOTOFRONTBACK_BACK": "înapoi",
    "LOOKS_GOFORWARDBACKWARDLAYERS": "mergi %1 %2 straturi",
    "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "înainte",
    "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "înapoi",
    "LOOKS_BACKDROPNUMBERNAME": "decor %1",
    "LOOKS_COSTUMENUMBERNAME": "costumul %1",
    "LOOKS_NUMBERNAME_NUMBER": "număr",
    "LOOKS_NUMBERNAME_NAME": "nume",
    "LOOKS_SWITCHBACKDROPTOANDWAIT": "schimbă decorul la %1 și așteaptă",
    "LOOKS_NEXTBACKDROP_BLOCK": "decorul următor",
    "LOOKS_NEXTBACKDROP": "decorul următor",
    "LOOKS_PREVIOUSBACKDROP": "decorul anterior",
    "LOOKS_RANDOMBACKDROP": "decor aleatoriu",
    "MOTION_MOVESTEPS": "mergi %1 pași",
    "MOTION_TURNLEFT": "rotește-te %1 %2 grade",
    "MOTION_TURNRIGHT": "rotește-te %1 %2 grade",
    "MOTION_POINTINDIRECTION": "orientează-te în direcția %1",
    "MOTION_POINTTOWARDS": "orientează-te spre %1",
    "MOTION_POINTTOWARDS_POINTER": "cursorul mouse-ului",
    "MOTION_POINTTOWARDS_RANDOM": "direcție aleatoare",
    "MOTION_GOTO": "mergi la %1",
    "MOTION_GOTO_POINTER": "cursorul mouse-ului",
    "MOTION_GOTO_RANDOM": "poziție aleatoare",
    "MOTION_GOTOXY": "mergi la x %1 y: %2",
    "MOTION_GLIDESECSTOXY": "glisează în %1 secunde la x: %2 y: %3",
    "MOTION_GLIDETO": "glisează în %1 secunde la %2 ",
    "MOTION_GLIDETO_POINTER": "cursorul mouse-ului",
    "MOTION_GLIDETO_RANDOM": "poziție aleatoare",
    "MOTION_CHANGEXBY": "modifică x cu %1",
    "MOTION_SETX": "setează x la %1",
    "MOTION_CHANGEYBY": "modifică y cu %1",
    "MOTION_SETY": "setează y la %1",
    "MOTION_IFONEDGEBOUNCE": "dacă atinge marginea, ricoșează",
    "MOTION_SETROTATIONSTYLE": "setează stilul de rotație %1",
    "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "stânga-dreapta",
    "MOTION_SETROTATIONSTYLE_DONTROTATE": "nu te roti",
    "MOTION_SETROTATIONSTYLE_ALLAROUND": "de jur împrejur",
    "MOTION_XPOSITION": "poziția x",
    "MOTION_YPOSITION": "poziția y",
    "MOTION_DIRECTION": "direcția",
    "MOTION_SCROLLRIGHT": "derulează la dreapta %1",
    "MOTION_SCROLLUP": "derulează în sus %1",
    "MOTION_ALIGNSCENE": "aliniază scena %1",
    "MOTION_ALIGNSCENE_BOTTOMLEFT": "stânga-jos",
    "MOTION_ALIGNSCENE_BOTTOMRIGHT": "dreapta-jos",
    "MOTION_ALIGNSCENE_MIDDLE": "în centru",
    "MOTION_ALIGNSCENE_TOPLEFT": "stânga-sus",
    "MOTION_ALIGNSCENE_TOPRIGHT": "dreapta-sus",
    "MOTION_XSCROLL": "derulare x",
    "MOTION_YSCROLL": "derulare y",
    "MOTION_STAGE_SELECTED": "Scena selectată: fără blocuri de mișcare",
    "OPERATORS_ADD": "%1 + %2",
    "OPERATORS_SUBTRACT": "%1  - %2",
    "OPERATORS_MULTIPLY": "%1 * %2",
    "OPERATORS_DIVIDE": "%1 / %2",
    "OPERATORS_RANDOM": "alege aleator între %1 și %2",
    "OPERATORS_GT": "%1 > %2",
    "OPERATORS_GT_EQUALS": "",
    "OPERATORS_LT": "%1 < %2",
    "OPERATORS_LT_EQUALS": "",
    "OPERATORS_EQUALS": "%1  = %2",
    "OPERATORS_AND": "%1 și %2",
    "OPERATORS_OR": "%1 sau %2",
    "OPERATORS_NOT": "nu %1",
    "OPERATORS_JOIN": "alătură %1 %2",
    "OPERATORS_JOIN_APPLE": "măr",
    "OPERATORS_JOIN_BANANA": "banană",
    "OPERATORS_LETTEROF": "litera %1 din %2",
    "OPERATORS_LETTEROF_APPLE": "m",
    "OPERATORS_LENGTH": "lungimea lui %1",
    "OPERATORS_CONTAINS": "%1 conține %2?",
    "OPERATORS_MOD": "%1 mod %2",
    "OPERATORS_ROUND": "rotunjește %1",
    "OPERATORS_MATHOP": "%1 din %2",
    "OPERATORS_MATHOP_ABS": "modul",
    "OPERATORS_MATHOP_FLOOR": "partea întreagă",
    "OPERATORS_MATHOP_CEILING": "rotunjire prin adaos",
    "OPERATORS_MATHOP_SQRT": "radical",
    "OPERATORS_MATHOP_SIN": "sin",
    "OPERATORS_MATHOP_COS": "cos",
    "OPERATORS_MATHOP_TAN": "tan",
    "OPERATORS_MATHOP_ASIN": "asin",
    "OPERATORS_MATHOP_ACOS": "acos",
    "OPERATORS_MATHOP_ATAN": "atan",
    "OPERATORS_MATHOP_LN": "ln",
    "OPERATORS_MATHOP_LOG": "log",
    "OPERATORS_MATHOP_EEXP": "e ^",
    "OPERATORS_MATHOP_10EXP": "10 ^",
    "PROCEDURES_DEFINITION": "definește %1",
    "SENSING_TOUCHINGOBJECT": "atinge %1?",
    "SENSING_TOUCHINGOBJECT_POINTER": "cursorul mouse-ului",
    "SENSING_TOUCHINGOBJECT_EDGE": "marginea",
    "SENSING_TOUCHINGCOLOR": "atinge culoarea %1?",
    "SENSING_COLORISTOUCHINGCOLOR": "culoarea %1 atinge %2?",
    "SENSING_DISTANCETO": "distanța până la %1",
    "SENSING_DISTANCETO_POINTER": "cursorul mouse-ului",
    "SENSING_ASKANDWAIT": "întreabă %1 și așteaptă",
    "SENSING_ASK_TEXT": "Cum te numești?",
    "SENSING_ANSWER": "răspuns",
    "SENSING_KEYPRESSED": "tasta %1 apăsată?",
    "SENSING_MOUSEDOWN": "butonul mouse-ului e apăsat?",
    "SENSING_MOUSEX": "mouse x",
    "SENSING_MOUSEY": "mouse y",
    "SENSING_SETDRAGMODE": "setează mod tragere %1",
    "SENSING_SETDRAGMODE_DRAGGABLE": "activat",
    "SENSING_SETDRAGMODE_NOTDRAGGABLE": "dezactivat",
    "SENSING_LOUDNESS": "intensitatea sunetului",
    "SENSING_LOUD": "tare?",
    "SENSING_TIMER": "cronometru",
    "SENSING_RESETTIMER": "resetează cronometru",
    "SENSING_OF": "%1 din %2",
    "SENSING_OF_XPOSITION": "poziția x",
    "SENSING_OF_YPOSITION": "poziția y",
    "SENSING_OF_DIRECTION": "direcția",
    "SENSING_OF_COSTUMENUMBER": "nr. costum",
    "SENSING_OF_COSTUMENAME": "denumire costum",
    "SENSING_OF_SIZE": "mărime",
    "SENSING_OF_VOLUME": "volum",
    "SENSING_OF_BACKDROPNUMBER": "nr. decor",
    "SENSING_OF_BACKDROPNAME": "denumire decor",
    "SENSING_OF_STAGE": "Scenă",
    "SENSING_CURRENT": "%1 curent(ă)",
    "SENSING_CURRENT_YEAR": "anul",
    "SENSING_CURRENT_MONTH": "luna",
    "SENSING_CURRENT_DATE": "data",
    "SENSING_CURRENT_DAYOFWEEK": "ziua săptămânii",
    "SENSING_CURRENT_HOUR": "ora",
    "SENSING_CURRENT_MINUTE": "minutul",
    "SENSING_CURRENT_SECOND": "secunda",
    "SENSING_DAYSSINCE2000": "zile din 2000",
    "SENSING_USERNAME": "nume utilizator",
    "SENSING_USERID": "id utilizator",
    "SOUND_PLAY": "pornește sunetul %1",
    "SOUND_PLAYUNTILDONE": "redă sunetul %1 până la final",
    "SOUND_STOPALLSOUNDS": "oprește toate sunetele",
    "SOUND_SETEFFECTO": "setează efectul %1 la %2",
    "SOUND_CHANGEEFFECTBY": "modifică efectul %1 cu %2",
    "SOUND_CLEAREFFECTS": "șterge efectele audio",
    "SOUND_EFFECTS_PITCH": "înălțimea sunetului",
    "SOUND_EFFECTS_PAN": "deplasare la stânga/dreapta",
    "SOUND_CHANGEVOLUMEBY": "modifică volumul cu %1",
    "SOUND_SETVOLUMETO": "setează volumul la %1%",
    "SOUND_VOLUME": "volum",
    "SOUND_RECORD": "se înregistrează...",
    "CATEGORY_MOTION": "Mișcare",
    "CATEGORY_LOOKS": "Aspect",
    "CATEGORY_SOUND": "Sunet",
    "CATEGORY_EVENTS": "Evenimente",
    "CATEGORY_CONTROL": "Control",
    "CATEGORY_SENSING": "Detectare",
    "CATEGORY_OPERATORS": "Operatori",
    "CATEGORY_VARIABLES": "Variabile",
    "CATEGORY_MYBLOCKS": "Blocurile mele",
    "CATEGORY_LISTS": "",
    "CATEGORY_TUPLE": "",
    "CATEGORY_DICTIONARYS": "",
    "CATEGORY_NUMBERS": "",
    "CATEGORY_TEXT": "",
    "DUPLICATE": "Duplică",
    "DELETE": "Șterge",
    "ADD_COMMENT": "Adaugă comentariu",
    "REMOVE_COMMENT": "Șterge comentariul",
    "DELETE_BLOCK": "Șterge blocul",
    "DELETE_X_BLOCKS": "Șterge %1 blocuri",
    "DELETE_ALL_BLOCKS": "Șterg toate %1 blocuri?",
    "CLEAN_UP": "Șterge blocurile",
    "HELP": "Ajutor",
    "UNDO": "Anulează ultima modificare",
    "REDO": "Refă acțiunea anulată",
    "EDIT_PROCEDURE": "Editează",
    "SHOW_PROCEDURE_DEFINITION": "Mergi la definiție",
    "WORKSPACE_COMMENT_DEFAULT_TEXT": "Spune ceva ...",
    "COLOUR_HUE_LABEL": "Culoare",
    "COLOUR_SATURATION_LABEL": "Saturație",
    "COLOUR_BRIGHTNESS_LABEL": "Strălucire",
    "CHANGE_VALUE_TITLE": "Modifică valoarea:",
    "RENAME_VARIABLE": "Redenumește variabila",
    "RENAME_VARIABLE_STRING": "",
    "RENAME_VARIABLE_NUMBER": "",
    "RENAME_VARIABLE_LIST": "",
    "RENAME_VARIABLE_TITLE": "Redenumește peste tot variabila \"%1\" la:",
    "RENAME_VARIABLE_MODAL_TITLE": "Redenumește variabila",
    "NEW_VARIABLE": "Creează o variabilă",
    "NEW_VARIABLE_TITLE": "Numele variabilei:",
    "VARIABLE_MODAL_TITLE": "Variabilă nouă",
    "VARIABLE_ALREADY_EXISTS": "O variabilă denumită \"%1\" există deja.",
    "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "O variabilă cu numele \"%1\" există deja pentru o altă variabilă de tip \"%2\".",
    "DELETE_VARIABLE_CONFIRMATION": "Șterge %1 utilizări ale variabilei \"%2\"?",
    "CANNOT_DELETE_VARIABLE_PROCEDURE": "Variabila cu numele \"%1\" nu poate fi ștearsă deoarece este parte a definiției funcției \"%2\". ",
    "DELETE_VARIABLE": "Șterge variabila cu numele \"%1\" .",
    "DELETE_VARIABLE_STRING": "",
    "DELETE_VARIABLE_NUMBER": "",
    "DELETE_VARIABLE_LIST": "",
    "NEW_PROCEDURE": "Creează un bloc",
    "PROCEDURE_ALREADY_EXISTS": "O procedură cu numele \"%1\" există deja.",
    "PROCEDURE_DEFAULT_NAME": "numele blocului",
    "NEW_LIST": "Creează o listă",
    "NEW_LIST_TITLE": "Noul nume al listei:",
    "LIST_MODAL_TITLE": "Listă nouă",
    "LIST_ALREADY_EXISTS": "O listă cu numele \"%1\" există deja.",
    "RENAME_LIST_TITLE": "Redenumește peste tot lista \"%1\" la:",
    "RENAME_LIST_MODAL_TITLE": "Redenumește lista",
    "DEFAULT_LIST_ITEM": "element",
    "DELETE_LIST": "Șterge lista  \"%1\" ",
    "RENAME_LIST": "Redenumește lista",
    "NEW_BROADCAST_MESSAGE": "Mesaj nou",
    "NEW_BROADCAST_MESSAGE_TITLE": "Numele mesajului",
    "BROADCAST_MODAL_TITLE": "Mesaj nou",
    "DEFAULT_BROADCAST_MESSAGE_NAME": "mesaj1",
    "DELETE_PROCEDURE": "",
    "OK": "",
    "PROMPT": "",
    "STOP": "",
    "NEW_STRING": "",
    "NEW_NUMBER_VARIABLE": "",
    "WIFI": "",
    "USERNAME": "",
    "PWD": "",
    "IOT_SERVICE": "",
    "IOT_ID": "",
    "IOT_PWD": "",
    "IOT_TOPIC": "",
    "MORE_TOPIC": "",
    "OBLOQ_HTTP": "",
    "OBLOQ_IP": "",
    "OBLOQ_PORT": "",
    "ADD_TOPIC": "",
    "DELETE_TOPIC": "",
    "IOT_SERVER": "",
    "CHINA": "",
    "GLOBAL": "",
    "LOW_C": "",
    "LOW_C$": "",
    "LOW_D": "",
    "LOW_D$": "",
    "LOW_E": "",
    "LOW_F": "",
    "LOW_F$": "",
    "LOW_G": "",
    "LOW_G$": "",
    "LOW_A": "",
    "LOW_A$": "",
    "LOW_B": "",
    "MIDDLE_C": "",
    "MIDDLE_C$": "",
    "MIDDLE_D": "",
    "MIDDLE_D$": "",
    "MIDDLE_E": "",
    "MIDDLE_F": "",
    "MIDDLE_F$": "",
    "MIDDLE_G": "",
    "MIDDLE_G$": "",
    "MIDDLE_A": "",
    "MIDDLE_A$": "",
    "MIDDLE_B": "",
    "HIGH_C": "",
    "HIGH_C$": "",
    "HIGH_D": "",
    "HIGH_D$": "",
    "HIGH_E": "",
    "HIGH_F": "",
    "HIGH_F$": "",
    "HIGH_G": "",
    "HIGH_G$": "",
    "HIGH_A": "",
    "HIGH_A$": "",
    "HIGH_B": "",
    "OPERATORS_STRINGTONUMBER": "",
    "INTEGER": "",
    "DECIMAL": "",
    "OPERATORS_NUMTOASCII": "",
    "OPERATORS_STRINGTOASCII": "",
    "OPERATORS_NUMTOSTRING": "",
    "OPERATORS_MAP": "",
    "OPERATORS_CONSTRAIN": "",
    "STEPS_PER_TURN": "",
    "ROTATE_SPEED": "",
    "NEW_AI": "",
    "ASK_AND_PAINT": "",
    "GET_IDENTIFIED_NUM": "",
    "READ_NUM_AND_SAY": "",
    "IMAGE_ADDR": "",
    "IMAGE_PREVIEW": "",
    "IMAGE_OPEN": "",
    "IMAGE_SIZE": "",
    "IMAGE_WIDTH": "",
    "IMAGE_HEIGHT": "",
    "VARIABLE": "",
    "VARIABLE_LIST": "",
    "SET_STRING_VARIABLE": "",
    "STRING_START_WITH": "",
    "DICTIONARY_HEIGHT_FIRST": "",
    "DICTIONARY_HEIGHT_TWO": "",
    "DICTIONARY_HEIGHT_THREE": "",
    "DICTIONARY_HEIGHT_FOUR": "",
    "DATA_MPITEMOFLIST": "",
    "DATA_MPINTERCEPTITEM": "",
    "DATA_MPINSERTATLIST": "",
    "DATA_MPSETVALUEATLIST": "",
    "DATA_MPINSERTATLIST_INSERT": "",
    "DATA_MPINSERTATLIST_CHANGE": "",
    "DATA_MPINSERTATLIST_FOUR": "",
    "DATA_MPINSERTATLIST_FIVE": "",
    "DATA_MPINSERTATLIST_SIX": "",
    "DATA_MPINSERTATLIST_SEVEN": "",
    "DATA_MPINSERTATLIST_EIGHT": "",
    "DATA_MPINSERTATLIST_EIGHT_FALSE": "",
    "DATA_MPINSERTATLIST_EIGHT_TRUE": "",
    "DATA_MPINSERTATLIST_EIGHT_REVERSE": "",
    "OPERATORS_RANDOMA": "",
    "OPERATORS_MODA": "",
    "OPERATORS_ROUNDA": "",
    "OPERATORS_MATHOPA": "",
    "OPERATORS_MATHOP_ABSA": "",
    "OPERATORS_MATHOP_FLOORA": "",
    "OPERATORS_MATHOP_CEILINGA": "",
    "OPERATORS_MATHOP_SQRTA": "",
    "OPERATORS_MATHOP_FIVE": "",
    "OPERATORS_MATHOP_SIX": "",
    "OPERATORS_MATHOP_SEVEN": "",
    "OPERATORS_MATHOP_LENGHT": "",
    "OPERATORS_MATHOP_MAXMIN": "",
    "OPERATORS_MATHOP_MAXMIN_MAX": "",
    "OPERATORS_MATHOP_MAXMIN_MIN": "",
    "OPERATORS_TEXTS_ONE": "",
    "OPERATORS_TEXTS_TWO": "",
    "OPERATORS_TEXTS_THREE": "",
    "OPERATORS_TEXTS_FOUR": "",
    "OPERATORS_TEXTS_FOUR_NEW": "",
    "OPERATORS_TEXTS_NEW": "",
    "OPERATORS_TEXTS_NEW_OP": "",
    "OPERATORS_TEXTS_FOUR_ONE": "",
    "OPERATORS_TEXTS_FOUR_TWO": "",
    "OPERATORS_TEXTS_FOUR_THREE": "",
    "OPERATORS_TEXTS_FOUR_FOUR": "",
    "OPERATORS_TEXTS_FOUR_F": "",
    "OPERATORS_TEXTS_FOUR_L": "",
    "OPERATORS_STRINGTONUMBERA": "",
    "OPERATORS_NUMTOASCIIA": "",
    "OPERATORS_STRINGTOASCIIA": "",
    "OPERATORS_NUMTOSTRINGA": "",
    "NEW_MC": "",
    "RENAME_MCNUMBER_TITLE": "",
    "RENAME_MCNUMBER_MODAL_TITLE": "",
    "RENAME_VARIABLE_MCNUMBER": "",
    "DELETE_VARIABLE_MCNUMBER": "",
    "SET_MC_VARIABLE": "",
    "TUPLE_ONE": "",
    "TUPLE_TWO": "",
    "ALIYUN": "",
    "ONENET": "",
    "EASYIOT": "",
    "SHANGHAI": "",
    "QINGDAO": "",
    "BEIJING": "",
    "ZHANGJIAKOU": "",
    "HUHEHAOTE": "",
    "HANGZHOU": "",
    "SHENZHEN": "",
    "HONGKONG": "",
    "SINGAPORE": "",
    "SYDNEY": "",
    "KUALALUMPUR": "",
    "JAKARTA": "",
    "MUMBAI": "",
    "TOKYO": "",
    "SILICONVALLEY": "",
    "VIRGINIA": "",
    "FRANKFURT": "",
    "LONDON": "",
    "DUBAI": "",
    "IOT_PLATFORM": "",
    "PARAMS": "",
    "SERVER_ATTR": "",
    "PRODUCTID": "",
    "DEVICEID": "",
    "TINYDB_SECRET": "",
    "TINYDB_USER": "",
    "APIADDR": "",
    "SOFTWARE_SERIAL": "",
    "HARDWARE_SERIAL": "",
    "HARDWARE_SERIAL1": "",
    "HARDWARE_SERIAL2": "",
    "HARDWARE_SERIAL3": ""
  },
  "ru": {
    "CONTROL_FOREVER": "повторять всегда",
    "CONTROL_REPEAT": "повторить %1 раз",
    "CONTROL_IF": "если %1 , то",
    "CONTROL_ELSE": "иначе",
    "CONTROL_STOP": "стоп",
    "CONTROL_STOP_ALL": "все",
    "CONTROL_STOP_THIS": "этот скрипт",
    "CONTROL_STOP_OTHER": "другие скрипты спрайта",
    "CONTROL_WAIT": "ждать %1 секунд",
    "CONTROL_WAITUNTIL": "ждать до %1",
    "CONTROL_REPEATUNTIL": "повторять пока не %1",
    "CONTROL_WHILE": "пока %1",
    "CONTROL_FOREACH": "для каждого %1 в %2",
    "CONTROL_STARTASCLONE": "когда я начинаю как клон",
    "CONTROL_CREATECLONEOF": "создать клон %1",
    "CONTROL_CREATECLONEOF_MYSELF": "самого себя",
    "CONTROL_DELETETHISCLONE": "удалить клон",
    "CONTROL_COUNTER": "счётчик",
    "CONTROL_INCRCOUNTER": "увеличить счётчик",
    "CONTROL_CLEARCOUNTER": "очистить счётчик",
    "CONTROL_ALLATONCE": "все сразу",
    "DATA_SETVARIABLETO": "задать %1 значение %2",
    "DATA_CHANGEVARIABLEBY": "изменить %1 на %2",
    "DATA_SHOWVARIABLE": "показать переменную %1",
    "DATA_HIDEVARIABLE": "скрыть переменную %1",
    "DATA_ADDTOLIST": "добавить %1 к %2",
    "DATA_DELETEOFLIST": "удалить %1 из %2",
    "DATA_DELETEALLOFLIST": "удалить все из %1",
    "DATA_INSERTATLIST": "вставить %1 в %3 из %2",
    "DATA_REPLACEITEMOFLIST": "заменить элемент %1 в %2 на %3",
    "DATA_ITEMOFLIST": "элемент %1 в %2",
    "DATA_ITEMNUMOFLIST": "элемент # из %1 в %2",
    "DATA_LENGTHOFLIST": "длина списка %1",
    "DATA_LISTCONTAINSITEM": "%1 содержит %2?",
    "DATA_SHOWLIST": "показать список %1",
    "DATA_HIDELIST": "скрыть список %1",
    "DATA_INDEX_ALL": "все",
    "DATA_INDEX_LAST": "последний",
    "DATA_INDEX_RANDOM": "случайный",
    "EVENT_WHENFLAGCLICKED": "когда %1 нажат",
    "EVENT_WHENTHISSPRITECLICKED": "когда спрайт нажат",
    "EVENT_WHENSTAGECLICKED": "когда кликнут по сцене",
    "EVENT_WHENTOUCHINGOBJECT": "когда этот спрайт касается %1",
    "EVENT_WHENBROADCASTRECEIVED": "когда я получу %1",
    "EVENT_WHENBACKDROPSWITCHESTO": "когда фон сменился на %1",
    "EVENT_WHENGREATERTHAN": "когда %1 > %2",
    "EVENT_WHENGREATERTHAN_TIMER": "таймер",
    "EVENT_WHENGREATERTHAN_LOUDNESS": "громкость",
    "EVENT_BROADCAST": "передать %1",
    "EVENT_BROADCASTANDWAIT": "передать %1 и ждать до конца",
    "EVENT_WHENKEYPRESSED": "когда клавиша %1 нажата",
    "EVENT_WHENKEYPRESSED_SPACE": "пробел",
    "EVENT_WHENKEYPRESSED_LEFT": "стрелка влево",
    "EVENT_WHENKEYPRESSED_RIGHT": "стрелка вправо",
    "EVENT_WHENKEYPRESSED_DOWN": "стрелка вниз",
    "EVENT_WHENKEYPRESSED_UP": "стрелка вверх",
    "EVENT_WHENKEYPRESSED_ANY": "любая",
    "LOOKS_SAYFORSECS": "говорить %1 %2 секунд",
    "LOOKS_SAY": "сказать %1",
    "LOOKS_HELLO": "Привет!",
    "LOOKS_THINKFORSECS": "думать %1 %2 секунд",
    "LOOKS_THINK": "думать %1",
    "LOOKS_HMM": "М-м-м...",
    "LOOKS_SHOW": "показаться",
    "LOOKS_HIDE": "спрятаться",
    "LOOKS_HIDEALLSPRITES": "спрятать все спрайты",
    "LOOKS_EFFECT_COLOR": "цвет",
    "LOOKS_EFFECT_FISHEYE": "рыбий глаз",
    "LOOKS_EFFECT_WHIRL": "завихрение",
    "LOOKS_EFFECT_PIXELATE": "укрупнение пикселей",
    "LOOKS_EFFECT_MOSAIC": "мозаика",
    "LOOKS_EFFECT_BRIGHTNESS": "яркость",
    "LOOKS_EFFECT_GHOST": "прозрачность",
    "LOOKS_CHANGEEFFECTBY": "изменить эффект %1 на %2",
    "LOOKS_SETEFFECTTO": "установить эффект %1 %2",
    "LOOKS_CLEARGRAPHICEFFECTS": "убрать графические эффекты",
    "LOOKS_CHANGESIZEBY": "изменить размер на %1 %",
    "LOOKS_SETSIZETO": "установить размер %1 %",
    "LOOKS_SIZE": "размер",
    "LOOKS_CHANGESTRETCHBY": "изменить натяжение на %1",
    "LOOKS_SETSTRETCHTO": "установить натяжение %1 %",
    "LOOKS_SWITCHCOSTUMETO": "изменить костюм на %1",
    "LOOKS_NEXTCOSTUME": "следующий костюм",
    "LOOKS_SWITCHBACKDROPTO": "переключить фон на %1",
    "LOOKS_GOTOFRONTBACK": "перейти на %1 слой",
    "LOOKS_GOTOFRONTBACK_FRONT": "передний",
    "LOOKS_GOTOFRONTBACK_BACK": "задний",
    "LOOKS_GOFORWARDBACKWARDLAYERS": "перейти %1 на %2 слоя",
    "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "вперёд",
    "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "назад",
    "LOOKS_BACKDROPNUMBERNAME": "фон %1",
    "LOOKS_COSTUMENUMBERNAME": "костюм %1",
    "LOOKS_NUMBERNAME_NUMBER": "номер",
    "LOOKS_NUMBERNAME_NAME": "имя",
    "LOOKS_SWITCHBACKDROPTOANDWAIT": "переключить фон на %1 и ждать",
    "LOOKS_NEXTBACKDROP_BLOCK": "следующий фон",
    "LOOKS_NEXTBACKDROP": "следующий фон",
    "LOOKS_PREVIOUSBACKDROP": "предыдущий фон",
    "LOOKS_RANDOMBACKDROP": "случайный фон",
    "MOTION_MOVESTEPS": "идти %1 шагов",
    "MOTION_TURNLEFT": "повернуть %1 на %2 градусов",
    "MOTION_TURNRIGHT": "повернуть %1 на %2 градусов",
    "MOTION_POINTINDIRECTION": "повернуться в направлении %1",
    "MOTION_POINTTOWARDS": "повернуться к %1",
    "MOTION_POINTTOWARDS_POINTER": "указатель мыши",
    "MOTION_POINTTOWARDS_RANDOM": "случайно направление",
    "MOTION_GOTO": "перейти на %1",
    "MOTION_GOTO_POINTER": "указатель мыши",
    "MOTION_GOTO_RANDOM": "случайное положение",
    "MOTION_GOTOXY": "перейти в x: %1 y: %2",
    "MOTION_GLIDESECSTOXY": "плыть %1 секунд в точку x: %2 y: %3",
    "MOTION_GLIDETO": "плыть %1 секунд к %2",
    "MOTION_GLIDETO_POINTER": "указатель мыши",
    "MOTION_GLIDETO_RANDOM": "случайное положение",
    "MOTION_CHANGEXBY": "изменить x на %1",
    "MOTION_SETX": "установить x в %1",
    "MOTION_CHANGEYBY": "изменить y на %1",
    "MOTION_SETY": "установить y в %1",
    "MOTION_IFONEDGEBOUNCE": "если касается края, оттолкнуться",
    "MOTION_SETROTATIONSTYLE": "установить способ вращения %1",
    "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "влево-вправо",
    "MOTION_SETROTATIONSTYLE_DONTROTATE": "не вращать",
    "MOTION_SETROTATIONSTYLE_ALLAROUND": "кругом",
    "MOTION_XPOSITION": "положение x",
    "MOTION_YPOSITION": "положение y",
    "MOTION_DIRECTION": "направление",
    "MOTION_SCROLLRIGHT": "прокрутить вправо %1",
    "MOTION_SCROLLUP": "прокрутить вверх %1",
    "MOTION_ALIGNSCENE": "выровнять сцену %1",
    "MOTION_ALIGNSCENE_BOTTOMLEFT": "нижний левый",
    "MOTION_ALIGNSCENE_BOTTOMRIGHT": "нижний правый",
    "MOTION_ALIGNSCENE_MIDDLE": "середина",
    "MOTION_ALIGNSCENE_TOPLEFT": "верхний левый",
    "MOTION_ALIGNSCENE_TOPRIGHT": "верхний правый",
    "MOTION_XSCROLL": "x прокрутка",
    "MOTION_YSCROLL": "y прокрутка",
    "MOTION_STAGE_SELECTED": "Выбрана сцена: нет блоков движения",
    "OPERATORS_ADD": "%1 + %2",
    "OPERATORS_SUBTRACT": "%1 - %2",
    "OPERATORS_MULTIPLY": "%1 * %2",
    "OPERATORS_DIVIDE": "%1 / %2",
    "OPERATORS_RANDOM": "выдать случайное от %1 до %2",
    "OPERATORS_GT": "%1 > %2",
    "OPERATORS_GT_EQUALS": "",
    "OPERATORS_LT": "%1 < %2",
    "OPERATORS_LT_EQUALS": "",
    "OPERATORS_EQUALS": "%1 = %2",
    "OPERATORS_AND": "%1 и %2",
    "OPERATORS_OR": "%1 или %2",
    "OPERATORS_NOT": "не %1",
    "OPERATORS_JOIN": "объединить %1 %2",
    "OPERATORS_JOIN_APPLE": "яблоко",
    "OPERATORS_JOIN_BANANA": "банан",
    "OPERATORS_LETTEROF": "буква %1 в %2",
    "OPERATORS_LETTEROF_APPLE": "а",
    "OPERATORS_LENGTH": "длина %1",
    "OPERATORS_CONTAINS": "%1 содержит %2?",
    "OPERATORS_MOD": "%1 модуль %2",
    "OPERATORS_ROUND": "округлить %1",
    "OPERATORS_MATHOP": "%1 от %2",
    "OPERATORS_MATHOP_ABS": "модуль",
    "OPERATORS_MATHOP_FLOOR": "целое меньшее",
    "OPERATORS_MATHOP_CEILING": "целое большее",
    "OPERATORS_MATHOP_SQRT": "квадратный корень",
    "OPERATORS_MATHOP_SIN": "sin",
    "OPERATORS_MATHOP_COS": "cos",
    "OPERATORS_MATHOP_TAN": "tan",
    "OPERATORS_MATHOP_ASIN": "asin",
    "OPERATORS_MATHOP_ACOS": "acos",
    "OPERATORS_MATHOP_ATAN": "atan",
    "OPERATORS_MATHOP_LN": "ln",
    "OPERATORS_MATHOP_LOG": "log",
    "OPERATORS_MATHOP_EEXP": "e ^",
    "OPERATORS_MATHOP_10EXP": "10 ^",
    "PROCEDURES_DEFINITION": "определить %1",
    "SENSING_TOUCHINGOBJECT": "касается %1?",
    "SENSING_TOUCHINGOBJECT_POINTER": "указатель мыши",
    "SENSING_TOUCHINGOBJECT_EDGE": "край",
    "SENSING_TOUCHINGCOLOR": "касается цвета %1?",
    "SENSING_COLORISTOUCHINGCOLOR": "цвет %1 касается цвета %2?",
    "SENSING_DISTANCETO": "расстояние до %1",
    "SENSING_DISTANCETO_POINTER": "указатель мыши",
    "SENSING_ASKANDWAIT": "спросить %1 и ждать",
    "SENSING_ASK_TEXT": "Как тебя зовут?",
    "SENSING_ANSWER": "ответ",
    "SENSING_KEYPRESSED": "клавиша %1 нажата?",
    "SENSING_MOUSEDOWN": "мышь нажата?",
    "SENSING_MOUSEX": "x мыши",
    "SENSING_MOUSEY": "y мыши",
    "SENSING_SETDRAGMODE": "перетаскивать %1",
    "SENSING_SETDRAGMODE_DRAGGABLE": "можно",
    "SENSING_SETDRAGMODE_NOTDRAGGABLE": "нельзя",
    "SENSING_LOUDNESS": "громкость",
    "SENSING_LOUD": "громко?",
    "SENSING_TIMER": "таймер",
    "SENSING_RESETTIMER": "сбросить таймер",
    "SENSING_OF": "%1 от %2",
    "SENSING_OF_XPOSITION": "положение x",
    "SENSING_OF_YPOSITION": "положение y",
    "SENSING_OF_DIRECTION": "направление",
    "SENSING_OF_COSTUMENUMBER": "костюм #",
    "SENSING_OF_COSTUMENAME": "имя костюма",
    "SENSING_OF_SIZE": "размер",
    "SENSING_OF_VOLUME": "громкость",
    "SENSING_OF_BACKDROPNUMBER": "фон #",
    "SENSING_OF_BACKDROPNAME": "имя фона",
    "SENSING_OF_STAGE": "Сцена",
    "SENSING_CURRENT": "текущий %1",
    "SENSING_CURRENT_YEAR": "год",
    "SENSING_CURRENT_MONTH": "месяц",
    "SENSING_CURRENT_DATE": "дата",
    "SENSING_CURRENT_DAYOFWEEK": "день недели",
    "SENSING_CURRENT_HOUR": "час",
    "SENSING_CURRENT_MINUTE": "минута",
    "SENSING_CURRENT_SECOND": "секунда",
    "SENSING_DAYSSINCE2000": "дней с 2000 г",
    "SENSING_USERNAME": "имя участника",
    "SENSING_USERID": "id участника",
    "SOUND_PLAY": "включить звук %1",
    "SOUND_PLAYUNTILDONE": "играть звук %1 до конца",
    "SOUND_STOPALLSOUNDS": "остановить все звуки",
    "SOUND_SETEFFECTO": "установить эффект %1 в %2",
    "SOUND_CHANGEEFFECTBY": "изменить %1 эффект на %2",
    "SOUND_CLEAREFFECTS": "убрать звуковые эффекты",
    "SOUND_EFFECTS_PITCH": "питч",
    "SOUND_EFFECTS_PAN": "положение слева/справа",
    "SOUND_CHANGEVOLUMEBY": "изменить громкость на %1",
    "SOUND_SETVOLUMETO": "установить громкость %1%",
    "SOUND_VOLUME": "громкость звука",
    "SOUND_RECORD": "запись…",
    "CATEGORY_MOTION": "Движение",
    "CATEGORY_LOOKS": "Внешний вид",
    "CATEGORY_SOUND": "Звук",
    "CATEGORY_EVENTS": "События",
    "CATEGORY_CONTROL": "Управление",
    "CATEGORY_SENSING": "Сенсоры",
    "CATEGORY_OPERATORS": "Операторы",
    "CATEGORY_VARIABLES": "Переменные",
    "CATEGORY_MYBLOCKS": "Другие блоки",
    "CATEGORY_LISTS": "",
    "CATEGORY_TUPLE": "",
    "CATEGORY_DICTIONARYS": "",
    "CATEGORY_NUMBERS": "",
    "CATEGORY_TEXT": "",
    "DUPLICATE": "Дублировать",
    "DELETE": "Удалить",
    "ADD_COMMENT": "Добавить комментарий",
    "REMOVE_COMMENT": "Удалить комментарий",
    "DELETE_BLOCK": "Удалить блок",
    "DELETE_X_BLOCKS": "Удалить %1 блоки",
    "DELETE_ALL_BLOCKS": "Удалить все %1 блоки?",
    "CLEAN_UP": "Очистить блоки",
    "HELP": "Помощь",
    "UNDO": "Отменить",
    "REDO": "Вернуть",
    "EDIT_PROCEDURE": "Редактировать",
    "SHOW_PROCEDURE_DEFINITION": "Перейти к определению",
    "WORKSPACE_COMMENT_DEFAULT_TEXT": "Скажи что-нибудь...",
    "COLOUR_HUE_LABEL": "Цвет",
    "COLOUR_SATURATION_LABEL": "Насыщенность",
    "COLOUR_BRIGHTNESS_LABEL": "Яркость",
    "CHANGE_VALUE_TITLE": "Изменить значение:",
    "RENAME_VARIABLE": "Переименовать переменную",
    "RENAME_VARIABLE_STRING": "",
    "RENAME_VARIABLE_NUMBER": "",
    "RENAME_VARIABLE_LIST": "",
    "RENAME_VARIABLE_TITLE": "Переименовать все \"%1\" переменных в:",
    "RENAME_VARIABLE_MODAL_TITLE": "Переименовать переменную",
    "NEW_VARIABLE": "Создать переменную",
    "NEW_VARIABLE_TITLE": "Имя новой переменной:",
    "VARIABLE_MODAL_TITLE": "Новая переменная",
    "VARIABLE_ALREADY_EXISTS": "Переменная с именем \"%1\" уже существует.",
    "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "переменная с именем \"%1\" уже существует в другом типе переменных \"%2\".",
    "DELETE_VARIABLE_CONFIRMATION": "Удалить %1 использований  \"%2\" переменной?",
    "CANNOT_DELETE_VARIABLE_PROCEDURE": "Нельзя удалить переменную \"%1\", так как она является частью определения функции \"%2\"",
    "DELETE_VARIABLE": "Удалить переменную \"%1\"",
    "DELETE_VARIABLE_STRING": "",
    "DELETE_VARIABLE_NUMBER": "",
    "DELETE_VARIABLE_LIST": "",
    "NEW_PROCEDURE": "Создать блок",
    "PROCEDURE_ALREADY_EXISTS": "Процедура с именем \"%1\" уже существует.",
    "PROCEDURE_DEFAULT_NAME": "название блока",
    "NEW_LIST": "Создать список",
    "NEW_LIST_TITLE": "Имя нового списка:",
    "LIST_MODAL_TITLE": "Новый список",
    "LIST_ALREADY_EXISTS": "Список с именем \"%1\" уже существует.",
    "RENAME_LIST_TITLE": "Переименовать все \"%1\" списки в:",
    "RENAME_LIST_MODAL_TITLE": "Переименовать список",
    "DEFAULT_LIST_ITEM": "что-то",
    "DELETE_LIST": "Удалить список \"%1\"",
    "RENAME_LIST": "Переименовать список",
    "NEW_BROADCAST_MESSAGE": "Новое сообщение",
    "NEW_BROADCAST_MESSAGE_TITLE": "Новое имя сообщения:",
    "BROADCAST_MODAL_TITLE": "Новое сообщение",
    "DEFAULT_BROADCAST_MESSAGE_NAME": "сообщение1",
    "DELETE_PROCEDURE": "",
    "OK": "",
    "PROMPT": "",
    "STOP": "",
    "NEW_STRING": "",
    "NEW_NUMBER_VARIABLE": "",
    "WIFI": "",
    "USERNAME": "",
    "PWD": "",
    "IOT_SERVICE": "",
    "IOT_ID": "",
    "IOT_PWD": "",
    "IOT_TOPIC": "",
    "MORE_TOPIC": "",
    "OBLOQ_HTTP": "",
    "OBLOQ_IP": "",
    "OBLOQ_PORT": "",
    "ADD_TOPIC": "",
    "DELETE_TOPIC": "",
    "IOT_SERVER": "",
    "CHINA": "",
    "GLOBAL": "",
    "LOW_C": "",
    "LOW_C$": "",
    "LOW_D": "",
    "LOW_D$": "",
    "LOW_E": "",
    "LOW_F": "",
    "LOW_F$": "",
    "LOW_G": "",
    "LOW_G$": "",
    "LOW_A": "",
    "LOW_A$": "",
    "LOW_B": "",
    "MIDDLE_C": "",
    "MIDDLE_C$": "",
    "MIDDLE_D": "",
    "MIDDLE_D$": "",
    "MIDDLE_E": "",
    "MIDDLE_F": "",
    "MIDDLE_F$": "",
    "MIDDLE_G": "",
    "MIDDLE_G$": "",
    "MIDDLE_A": "",
    "MIDDLE_A$": "",
    "MIDDLE_B": "",
    "HIGH_C": "",
    "HIGH_C$": "",
    "HIGH_D": "",
    "HIGH_D$": "",
    "HIGH_E": "",
    "HIGH_F": "",
    "HIGH_F$": "",
    "HIGH_G": "",
    "HIGH_G$": "",
    "HIGH_A": "",
    "HIGH_A$": "",
    "HIGH_B": "",
    "OPERATORS_STRINGTONUMBER": "",
    "INTEGER": "",
    "DECIMAL": "",
    "OPERATORS_NUMTOASCII": "",
    "OPERATORS_STRINGTOASCII": "",
    "OPERATORS_NUMTOSTRING": "",
    "OPERATORS_MAP": "",
    "OPERATORS_CONSTRAIN": "",
    "STEPS_PER_TURN": "",
    "ROTATE_SPEED": "",
    "NEW_AI": "",
    "ASK_AND_PAINT": "",
    "GET_IDENTIFIED_NUM": "",
    "READ_NUM_AND_SAY": "",
    "IMAGE_ADDR": "",
    "IMAGE_PREVIEW": "",
    "IMAGE_OPEN": "",
    "IMAGE_SIZE": "",
    "IMAGE_WIDTH": "",
    "IMAGE_HEIGHT": "",
    "VARIABLE": "",
    "VARIABLE_LIST": "",
    "SET_STRING_VARIABLE": "",
    "STRING_START_WITH": "",
    "DICTIONARY_HEIGHT_FIRST": "",
    "DICTIONARY_HEIGHT_TWO": "",
    "DICTIONARY_HEIGHT_THREE": "",
    "DICTIONARY_HEIGHT_FOUR": "",
    "DATA_MPITEMOFLIST": "",
    "DATA_MPINTERCEPTITEM": "",
    "DATA_MPINSERTATLIST": "",
    "DATA_MPSETVALUEATLIST": "",
    "DATA_MPINSERTATLIST_INSERT": "",
    "DATA_MPINSERTATLIST_CHANGE": "",
    "DATA_MPINSERTATLIST_FOUR": "",
    "DATA_MPINSERTATLIST_FIVE": "",
    "DATA_MPINSERTATLIST_SIX": "",
    "DATA_MPINSERTATLIST_SEVEN": "",
    "DATA_MPINSERTATLIST_EIGHT": "",
    "DATA_MPINSERTATLIST_EIGHT_FALSE": "",
    "DATA_MPINSERTATLIST_EIGHT_TRUE": "",
    "DATA_MPINSERTATLIST_EIGHT_REVERSE": "",
    "OPERATORS_RANDOMA": "",
    "OPERATORS_MODA": "",
    "OPERATORS_ROUNDA": "",
    "OPERATORS_MATHOPA": "",
    "OPERATORS_MATHOP_ABSA": "",
    "OPERATORS_MATHOP_FLOORA": "",
    "OPERATORS_MATHOP_CEILINGA": "",
    "OPERATORS_MATHOP_SQRTA": "",
    "OPERATORS_MATHOP_FIVE": "",
    "OPERATORS_MATHOP_SIX": "",
    "OPERATORS_MATHOP_SEVEN": "",
    "OPERATORS_MATHOP_LENGHT": "",
    "OPERATORS_MATHOP_MAXMIN": "",
    "OPERATORS_MATHOP_MAXMIN_MAX": "",
    "OPERATORS_MATHOP_MAXMIN_MIN": "",
    "OPERATORS_TEXTS_ONE": "",
    "OPERATORS_TEXTS_TWO": "",
    "OPERATORS_TEXTS_THREE": "",
    "OPERATORS_TEXTS_FOUR": "",
    "OPERATORS_TEXTS_FOUR_NEW": "",
    "OPERATORS_TEXTS_NEW": "",
    "OPERATORS_TEXTS_NEW_OP": "",
    "OPERATORS_TEXTS_FOUR_ONE": "",
    "OPERATORS_TEXTS_FOUR_TWO": "",
    "OPERATORS_TEXTS_FOUR_THREE": "",
    "OPERATORS_TEXTS_FOUR_FOUR": "",
    "OPERATORS_TEXTS_FOUR_F": "",
    "OPERATORS_TEXTS_FOUR_L": "",
    "OPERATORS_STRINGTONUMBERA": "",
    "OPERATORS_NUMTOASCIIA": "",
    "OPERATORS_STRINGTOASCIIA": "",
    "OPERATORS_NUMTOSTRINGA": "",
    "NEW_MC": "",
    "RENAME_MCNUMBER_TITLE": "",
    "RENAME_MCNUMBER_MODAL_TITLE": "",
    "RENAME_VARIABLE_MCNUMBER": "",
    "DELETE_VARIABLE_MCNUMBER": "",
    "SET_MC_VARIABLE": "",
    "TUPLE_ONE": "",
    "TUPLE_TWO": "",
    "ALIYUN": "",
    "ONENET": "",
    "EASYIOT": "",
    "SHANGHAI": "",
    "QINGDAO": "",
    "BEIJING": "",
    "ZHANGJIAKOU": "",
    "HUHEHAOTE": "",
    "HANGZHOU": "",
    "SHENZHEN": "",
    "HONGKONG": "",
    "SINGAPORE": "",
    "SYDNEY": "",
    "KUALALUMPUR": "",
    "JAKARTA": "",
    "MUMBAI": "",
    "TOKYO": "",
    "SILICONVALLEY": "",
    "VIRGINIA": "",
    "FRANKFURT": "",
    "LONDON": "",
    "DUBAI": "",
    "IOT_PLATFORM": "",
    "PARAMS": "",
    "SERVER_ATTR": "",
    "PRODUCTID": "",
    "DEVICEID": "",
    "TINYDB_SECRET": "",
    "TINYDB_USER": "",
    "APIADDR": "",
    "SOFTWARE_SERIAL": "",
    "HARDWARE_SERIAL": "",
    "HARDWARE_SERIAL1": "",
    "HARDWARE_SERIAL2": "",
    "HARDWARE_SERIAL3": ""
  },
  "sr": {
    "CONTROL_FOREVER": "понављај заувек",
    "CONTROL_REPEAT": "понови %1",
    "CONTROL_IF": "ако је %1 онда",
    "CONTROL_ELSE": "у супротном",
    "CONTROL_STOP": "заустави",
    "CONTROL_STOP_ALL": "све",
    "CONTROL_STOP_THIS": "овај програм",
    "CONTROL_STOP_OTHER": "друге програме лика",
    "CONTROL_WAIT": "чекај %1 секунду",
    "CONTROL_WAITUNTIL": "чекај док не %1",
    "CONTROL_REPEATUNTIL": "понављај до %1",
    "CONTROL_WHILE": "док је %1",
    "CONTROL_FOREACH": "за сваки %1 у %2",
    "CONTROL_STARTASCLONE": "када се појавим као умножак",
    "CONTROL_CREATECLONEOF": "направи дупликат од %1",
    "CONTROL_CREATECLONEOF_MYSELF": "мене",
    "CONTROL_DELETETHISCLONE": "обриши овај умножак",
    "CONTROL_COUNTER": "бројач",
    "CONTROL_INCRCOUNTER": "увећај бројач",
    "CONTROL_CLEARCOUNTER": "обриши бројач",
    "CONTROL_ALLATONCE": "све одједном",
    "DATA_SETVARIABLETO": "нека %1 буде %2",
    "DATA_CHANGEVARIABLEBY": "промени %1 за %2",
    "DATA_SHOWVARIABLE": "прикажи променљиву %1",
    "DATA_HIDEVARIABLE": "сакриј променљиву %1",
    "DATA_ADDTOLIST": "додај %1 у %2",
    "DATA_DELETEOFLIST": "обриши %1 из %2",
    "DATA_DELETEALLOFLIST": "обриши све из %1",
    "DATA_INSERTATLIST": "убаци %1 на %2 у %3",
    "DATA_REPLACEITEMOFLIST": "замени елемент %1 у %2 са %3",
    "DATA_ITEMOFLIST": "елемент %1 из %2",
    "DATA_ITEMNUMOFLIST": "место од %1 у %2",
    "DATA_LENGTHOFLIST": "дужина %1",
    "DATA_LISTCONTAINSITEM": "да ли %1 садржи %2?",
    "DATA_SHOWLIST": "прикажи листу %1",
    "DATA_HIDELIST": "сакриј листу %1",
    "DATA_INDEX_ALL": "све",
    "DATA_INDEX_LAST": "последњи",
    "DATA_INDEX_RANDOM": "насумичан",
    "EVENT_WHENFLAGCLICKED": "када је кликнуто на %1",
    "EVENT_WHENTHISSPRITECLICKED": "када је кликнуто на овај лик",
    "EVENT_WHENSTAGECLICKED": "када је кликнуто на позорницу",
    "EVENT_WHENTOUCHINGOBJECT": "када овај лик додирне %1",
    "EVENT_WHENBROADCASTRECEIVED": "када примим %1",
    "EVENT_WHENBACKDROPSWITCHESTO": "када се позадина промени у %1",
    "EVENT_WHENGREATERTHAN": "када је %1 > %2",
    "EVENT_WHENGREATERTHAN_TIMER": "тајмер",
    "EVENT_WHENGREATERTHAN_LOUDNESS": "јачина звука",
    "EVENT_BROADCAST": "разгласи %1",
    "EVENT_BROADCASTANDWAIT": "разгласи %1 и чекај",
    "EVENT_WHENKEYPRESSED": "када је дирка %1 притиснута",
    "EVENT_WHENKEYPRESSED_SPACE": "размак",
    "EVENT_WHENKEYPRESSED_LEFT": "стрелица лево",
    "EVENT_WHENKEYPRESSED_RIGHT": "стрелица десно",
    "EVENT_WHENKEYPRESSED_DOWN": "стрелица доле",
    "EVENT_WHENKEYPRESSED_UP": "стрелица горе",
    "EVENT_WHENKEYPRESSED_ANY": "било који",
    "LOOKS_SAYFORSECS": "изговори %1 током %2 секунде",
    "LOOKS_SAY": "изговори %1",
    "LOOKS_HELLO": "Здраво!",
    "LOOKS_THINKFORSECS": "замисли %1 током %2 секунде",
    "LOOKS_THINK": "замисли %1",
    "LOOKS_HMM": "Хмм...",
    "LOOKS_SHOW": "прикажи",
    "LOOKS_HIDE": "сакриј",
    "LOOKS_HIDEALLSPRITES": "сакриј све ликове",
    "LOOKS_EFFECT_COLOR": "боја",
    "LOOKS_EFFECT_FISHEYE": "рибље око",
    "LOOKS_EFFECT_WHIRL": "вртлог",
    "LOOKS_EFFECT_PIXELATE": "позрнати",
    "LOOKS_EFFECT_MOSAIC": "мозаик",
    "LOOKS_EFFECT_BRIGHTNESS": "осветљеност",
    "LOOKS_EFFECT_GHOST": "дух",
    "LOOKS_CHANGEEFFECTBY": "промени ефекат %1 за %2",
    "LOOKS_SETEFFECTTO": "нека ефекат %1 буде %2",
    "LOOKS_CLEARGRAPHICEFFECTS": "уклони графичке ефекте",
    "LOOKS_CHANGESIZEBY": "промени величину за %1",
    "LOOKS_SETSIZETO": "нека величина буде %1 %",
    "LOOKS_SIZE": "величина",
    "LOOKS_CHANGESTRETCHBY": "промени увећање за %1",
    "LOOKS_SETSTRETCHTO": "нека увећање буде  %1 %",
    "LOOKS_SWITCHCOSTUMETO": "замени костим са %1",
    "LOOKS_NEXTCOSTUME": "следећи костим",
    "LOOKS_SWITCHBACKDROPTO": "промени позадину у %1",
    "LOOKS_GOTOFRONTBACK": "иди на %1 слој",
    "LOOKS_GOTOFRONTBACK_FRONT": "напред",
    "LOOKS_GOTOFRONTBACK_BACK": "назад",
    "LOOKS_GOFORWARDBACKWARDLAYERS": "иди %1 %2 слоја",
    "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "проследи",
    "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "уназад",
    "LOOKS_BACKDROPNUMBERNAME": "позадина %1",
    "LOOKS_COSTUMENUMBERNAME": "костим %1",
    "LOOKS_NUMBERNAME_NUMBER": "број",
    "LOOKS_NUMBERNAME_NAME": "име",
    "LOOKS_SWITCHBACKDROPTOANDWAIT": "промени позадину у %1 и чекај",
    "LOOKS_NEXTBACKDROP_BLOCK": "следећа позадина",
    "LOOKS_NEXTBACKDROP": "следећа позадина",
    "LOOKS_PREVIOUSBACKDROP": "претходна позадина",
    "LOOKS_RANDOMBACKDROP": "случајна позадина",
    "MOTION_MOVESTEPS": "иди %1 корака",
    "MOTION_TURNLEFT": "окрет %1 за %2 степени",
    "MOTION_TURNRIGHT": "окрет %1 за %2 степени",
    "MOTION_POINTINDIRECTION": "усмери се ка %1",
    "MOTION_POINTTOWARDS": "усмери се ка %1",
    "MOTION_POINTTOWARDS_POINTER": "показивач миша",
    "MOTION_POINTTOWARDS_RANDOM": "насумичан правац",
    "MOTION_GOTO": "иди до %1",
    "MOTION_GOTO_POINTER": "показивач миша",
    "MOTION_GOTO_RANDOM": "насумичан положај",
    "MOTION_GOTOXY": "иди до x: %1 y: %2",
    "MOTION_GLIDESECSTOXY": "клизи %1 секунди до x: %2 y: %3",
    "MOTION_GLIDETO": "клизи %1 секунди до %2",
    "MOTION_GLIDETO_POINTER": "показивач миша",
    "MOTION_GLIDETO_RANDOM": "случајан положај",
    "MOTION_CHANGEXBY": "промени x за %1",
    "MOTION_SETX": "нека x буде %1",
    "MOTION_CHANGEYBY": "промени y за %1",
    "MOTION_SETY": "нека y буде %1",
    "MOTION_IFONEDGEBOUNCE": "ако си на рубу, окрени се",
    "MOTION_SETROTATIONSTYLE": "подеси начин окретања %1",
    "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "лево-десно",
    "MOTION_SETROTATIONSTYLE_DONTROTATE": "без окретања",
    "MOTION_SETROTATIONSTYLE_ALLAROUND": "на све стране",
    "MOTION_XPOSITION": "место х",
    "MOTION_YPOSITION": "место у",
    "MOTION_DIRECTION": "смер",
    "MOTION_SCROLLRIGHT": "клизи у десно %1",
    "MOTION_SCROLLUP": "клизи горе  %1",
    "MOTION_ALIGNSCENE": "поравнај сцену %1",
    "MOTION_ALIGNSCENE_BOTTOMLEFT": "доле-лево",
    "MOTION_ALIGNSCENE_BOTTOMRIGHT": "доле-десно",
    "MOTION_ALIGNSCENE_MIDDLE": "средина",
    "MOTION_ALIGNSCENE_TOPLEFT": "горе-лево",
    "MOTION_ALIGNSCENE_TOPRIGHT": "горе-десно",
    "MOTION_XSCROLL": "x клизање",
    "MOTION_YSCROLL": "y клизање",
    "MOTION_STAGE_SELECTED": "Издабрана је позорница: нема блокова за кретање",
    "OPERATORS_ADD": "%1 + %2",
    "OPERATORS_SUBTRACT": "%1 - %2",
    "OPERATORS_MULTIPLY": "%1 * %2",
    "OPERATORS_DIVIDE": "%1 / %2",
    "OPERATORS_RANDOM": "случајан број од %1 до %2 ",
    "OPERATORS_GT": "%1 > %2",
    "OPERATORS_GT_EQUALS": "",
    "OPERATORS_LT": "%1 < %2",
    "OPERATORS_LT_EQUALS": "",
    "OPERATORS_EQUALS": "%1 = %2",
    "OPERATORS_AND": "%1 и %2",
    "OPERATORS_OR": "%1 или %2",
    "OPERATORS_NOT": "није %1",
    "OPERATORS_JOIN": "споји %1 и %2",
    "OPERATORS_JOIN_APPLE": "јабука",
    "OPERATORS_JOIN_BANANA": "банана",
    "OPERATORS_LETTEROF": "%1 слово од %2",
    "OPERATORS_LETTEROF_APPLE": "ј",
    "OPERATORS_LENGTH": "дужина %1",
    "OPERATORS_CONTAINS": "%1 садржи %2",
    "OPERATORS_MOD": "%1 мод %2",
    "OPERATORS_ROUND": "заокружи %1",
    "OPERATORS_MATHOP": "%1 од %2",
    "OPERATORS_MATHOP_ABS": "абс",
    "OPERATORS_MATHOP_FLOOR": "цео део",
    "OPERATORS_MATHOP_CEILING": "цео+1",
    "OPERATORS_MATHOP_SQRT": "корен",
    "OPERATORS_MATHOP_SIN": "sin",
    "OPERATORS_MATHOP_COS": "cos",
    "OPERATORS_MATHOP_TAN": "tg",
    "OPERATORS_MATHOP_ASIN": "arcsin",
    "OPERATORS_MATHOP_ACOS": "arccos",
    "OPERATORS_MATHOP_ATAN": "arctan",
    "OPERATORS_MATHOP_LN": "ln",
    "OPERATORS_MATHOP_LOG": "log",
    "OPERATORS_MATHOP_EEXP": "e ^",
    "OPERATORS_MATHOP_10EXP": "10 ^",
    "PROCEDURES_DEFINITION": "дефиниши %1",
    "SENSING_TOUCHINGOBJECT": "додирује ли %1?",
    "SENSING_TOUCHINGOBJECT_POINTER": "показивач миша",
    "SENSING_TOUCHINGOBJECT_EDGE": "ивица",
    "SENSING_TOUCHINGCOLOR": "додирује ли боју %1?",
    "SENSING_COLORISTOUCHINGCOLOR": "боја %1 додирује %2?",
    "SENSING_DISTANCETO": "удаљеност од %1",
    "SENSING_DISTANCETO_POINTER": "показивач миша",
    "SENSING_ASKANDWAIT": "питај %1 и чекај",
    "SENSING_ASK_TEXT": "Како се зовеш?",
    "SENSING_ANSWER": "одговор",
    "SENSING_KEYPRESSED": "дугме %1 је притиснуто?",
    "SENSING_MOUSEDOWN": "миш притиснут?",
    "SENSING_MOUSEX": "миш x",
    "SENSING_MOUSEY": "миш y",
    "SENSING_SETDRAGMODE": "нека начин превлачења буде %1",
    "SENSING_SETDRAGMODE_DRAGGABLE": "превлачиво",
    "SENSING_SETDRAGMODE_NOTDRAGGABLE": "није превлачиво",
    "SENSING_LOUDNESS": "јачина звука",
    "SENSING_LOUD": "гласно?",
    "SENSING_TIMER": "тајмер",
    "SENSING_RESETTIMER": "постави тајмер на 0",
    "SENSING_OF": "%1 од %2",
    "SENSING_OF_XPOSITION": "x положај",
    "SENSING_OF_YPOSITION": "y положај",
    "SENSING_OF_DIRECTION": "смер",
    "SENSING_OF_COSTUMENUMBER": "број костима",
    "SENSING_OF_COSTUMENAME": "име костима",
    "SENSING_OF_SIZE": "величина",
    "SENSING_OF_VOLUME": "јачина звука",
    "SENSING_OF_BACKDROPNUMBER": "број позадине",
    "SENSING_OF_BACKDROPNAME": "име позадине",
    "SENSING_OF_STAGE": "Позорница",
    "SENSING_CURRENT": "тренутно %1",
    "SENSING_CURRENT_YEAR": "година",
    "SENSING_CURRENT_MONTH": "месец",
    "SENSING_CURRENT_DATE": "датум",
    "SENSING_CURRENT_DAYOFWEEK": "дан у недељи",
    "SENSING_CURRENT_HOUR": "сат",
    "SENSING_CURRENT_MINUTE": "минут",
    "SENSING_CURRENT_SECOND": "секунда",
    "SENSING_DAYSSINCE2000": "дана после 2000",
    "SENSING_USERNAME": "корисничко име",
    "SENSING_USERID": "Корисников ид",
    "SOUND_PLAY": "покрени звук %1",
    "SOUND_PLAYUNTILDONE": "репродукуј звук %1 до краја",
    "SOUND_STOPALLSOUNDS": "заустави све звукове",
    "SOUND_SETEFFECTO": "нека ефекат %1 буде %2",
    "SOUND_CHANGEEFFECTBY": "промени ефекат %1 за %2",
    "SOUND_CLEAREFFECTS": "уклони звучне ефекте",
    "SOUND_EFFECTS_PITCH": "висина тона",
    "SOUND_EFFECTS_PAN": "померање лево/десно",
    "SOUND_CHANGEVOLUMEBY": "промени јачину звука за %1",
    "SOUND_SETVOLUMETO": "нека јачина звука буде %1%",
    "SOUND_VOLUME": "јачина звука",
    "SOUND_RECORD": "сними...",
    "CATEGORY_MOTION": "Кретање",
    "CATEGORY_LOOKS": "Изглед",
    "CATEGORY_SOUND": "Звук",
    "CATEGORY_EVENTS": "Догађаји",
    "CATEGORY_CONTROL": "Управљање",
    "CATEGORY_SENSING": "Осећаји",
    "CATEGORY_OPERATORS": "Оператори",
    "CATEGORY_VARIABLES": "Променљиве",
    "CATEGORY_MYBLOCKS": "Моји блокови",
    "CATEGORY_LISTS": "",
    "CATEGORY_TUPLE": "",
    "CATEGORY_DICTIONARYS": "",
    "CATEGORY_NUMBERS": "",
    "CATEGORY_TEXT": "",
    "DUPLICATE": "Умножи",
    "DELETE": "Обриши",
    "ADD_COMMENT": "Додај коментар",
    "REMOVE_COMMENT": "Уклони коментар",
    "DELETE_BLOCK": "Обриши блок",
    "DELETE_X_BLOCKS": "Обриши %1 блокова",
    "DELETE_ALL_BLOCKS": "Обрисати свих %1 блокова?",
    "CLEAN_UP": "Поспреми блокове",
    "HELP": "Помоћ",
    "UNDO": "Опозови",
    "REDO": "Понови",
    "EDIT_PROCEDURE": "Уреди",
    "SHOW_PROCEDURE_DEFINITION": "Иди до дефиниције",
    "WORKSPACE_COMMENT_DEFAULT_TEXT": "Кажи нешто...",
    "COLOUR_HUE_LABEL": "Боја",
    "COLOUR_SATURATION_LABEL": "Засићење",
    "COLOUR_BRIGHTNESS_LABEL": "Осветљеност",
    "CHANGE_VALUE_TITLE": "Промени вредност:",
    "RENAME_VARIABLE": "Преименуј променљиву",
    "RENAME_VARIABLE_STRING": "",
    "RENAME_VARIABLE_NUMBER": "",
    "RENAME_VARIABLE_LIST": "",
    "RENAME_VARIABLE_TITLE": "Преименуј свих \"%1\" променљивих у:",
    "RENAME_VARIABLE_MODAL_TITLE": "Преименуј променљиву",
    "NEW_VARIABLE": "Направи променљиву",
    "NEW_VARIABLE_TITLE": "Ново име променљиве:",
    "VARIABLE_MODAL_TITLE": "Нова променљива",
    "VARIABLE_ALREADY_EXISTS": "Име променљиве \"%1\" већ постоји.",
    "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "Име променљиве \"%1\" већ постоји код друге променљиве типа \"%2\".",
    "DELETE_VARIABLE_CONFIRMATION": "Обрисати %1 употреба променљиве \"%2\"?",
    "CANNOT_DELETE_VARIABLE_PROCEDURE": "Не могу да обришем променљиву \"%1\" зато што је она део дефиниције функције \"%2\"",
    "DELETE_VARIABLE": "Обриши променљиву \"%1\"",
    "DELETE_VARIABLE_STRING": "",
    "DELETE_VARIABLE_NUMBER": "",
    "DELETE_VARIABLE_LIST": "",
    "NEW_PROCEDURE": "Направи блок",
    "PROCEDURE_ALREADY_EXISTS": "Процедура са именом  \"%1\" већ постоји.",
    "PROCEDURE_DEFAULT_NAME": "име блока",
    "NEW_LIST": "Направи листу",
    "NEW_LIST_TITLE": "Ново име листе:",
    "LIST_MODAL_TITLE": "Нова листа",
    "LIST_ALREADY_EXISTS": "Листа са именом \"%1\" већ постоји.",
    "RENAME_LIST_TITLE": "Преименуј свих \"%1\" листи у:",
    "RENAME_LIST_MODAL_TITLE": "Преименуј листу",
    "DEFAULT_LIST_ITEM": "ствар",
    "DELETE_LIST": "Обриши листу \"%1\"",
    "RENAME_LIST": "Преименуј листу",
    "NEW_BROADCAST_MESSAGE": "Нова порука",
    "NEW_BROADCAST_MESSAGE_TITLE": "Име нове поруке:",
    "BROADCAST_MODAL_TITLE": "Нова порука",
    "DEFAULT_BROADCAST_MESSAGE_NAME": "порука1",
    "DELETE_PROCEDURE": "",
    "OK": "",
    "PROMPT": "",
    "STOP": "",
    "NEW_STRING": "",
    "NEW_NUMBER_VARIABLE": "",
    "WIFI": "",
    "USERNAME": "",
    "PWD": "",
    "IOT_SERVICE": "",
    "IOT_ID": "",
    "IOT_PWD": "",
    "IOT_TOPIC": "",
    "MORE_TOPIC": "",
    "OBLOQ_HTTP": "",
    "OBLOQ_IP": "",
    "OBLOQ_PORT": "",
    "ADD_TOPIC": "",
    "DELETE_TOPIC": "",
    "IOT_SERVER": "",
    "CHINA": "",
    "GLOBAL": "",
    "LOW_C": "",
    "LOW_C$": "",
    "LOW_D": "",
    "LOW_D$": "",
    "LOW_E": "",
    "LOW_F": "",
    "LOW_F$": "",
    "LOW_G": "",
    "LOW_G$": "",
    "LOW_A": "",
    "LOW_A$": "",
    "LOW_B": "",
    "MIDDLE_C": "",
    "MIDDLE_C$": "",
    "MIDDLE_D": "",
    "MIDDLE_D$": "",
    "MIDDLE_E": "",
    "MIDDLE_F": "",
    "MIDDLE_F$": "",
    "MIDDLE_G": "",
    "MIDDLE_G$": "",
    "MIDDLE_A": "",
    "MIDDLE_A$": "",
    "MIDDLE_B": "",
    "HIGH_C": "",
    "HIGH_C$": "",
    "HIGH_D": "",
    "HIGH_D$": "",
    "HIGH_E": "",
    "HIGH_F": "",
    "HIGH_F$": "",
    "HIGH_G": "",
    "HIGH_G$": "",
    "HIGH_A": "",
    "HIGH_A$": "",
    "HIGH_B": "",
    "OPERATORS_STRINGTONUMBER": "",
    "INTEGER": "",
    "DECIMAL": "",
    "OPERATORS_NUMTOASCII": "",
    "OPERATORS_STRINGTOASCII": "",
    "OPERATORS_NUMTOSTRING": "",
    "OPERATORS_MAP": "",
    "OPERATORS_CONSTRAIN": "",
    "STEPS_PER_TURN": "",
    "ROTATE_SPEED": "",
    "NEW_AI": "",
    "ASK_AND_PAINT": "",
    "GET_IDENTIFIED_NUM": "",
    "READ_NUM_AND_SAY": "",
    "IMAGE_ADDR": "",
    "IMAGE_PREVIEW": "",
    "IMAGE_OPEN": "",
    "IMAGE_SIZE": "",
    "IMAGE_WIDTH": "",
    "IMAGE_HEIGHT": "",
    "VARIABLE": "",
    "VARIABLE_LIST": "",
    "SET_STRING_VARIABLE": "",
    "STRING_START_WITH": "",
    "DICTIONARY_HEIGHT_FIRST": "",
    "DICTIONARY_HEIGHT_TWO": "",
    "DICTIONARY_HEIGHT_THREE": "",
    "DICTIONARY_HEIGHT_FOUR": "",
    "DATA_MPITEMOFLIST": "",
    "DATA_MPINTERCEPTITEM": "",
    "DATA_MPINSERTATLIST": "",
    "DATA_MPSETVALUEATLIST": "",
    "DATA_MPINSERTATLIST_INSERT": "",
    "DATA_MPINSERTATLIST_CHANGE": "",
    "DATA_MPINSERTATLIST_FOUR": "",
    "DATA_MPINSERTATLIST_FIVE": "",
    "DATA_MPINSERTATLIST_SIX": "",
    "DATA_MPINSERTATLIST_SEVEN": "",
    "DATA_MPINSERTATLIST_EIGHT": "",
    "DATA_MPINSERTATLIST_EIGHT_FALSE": "",
    "DATA_MPINSERTATLIST_EIGHT_TRUE": "",
    "DATA_MPINSERTATLIST_EIGHT_REVERSE": "",
    "OPERATORS_RANDOMA": "",
    "OPERATORS_MODA": "",
    "OPERATORS_ROUNDA": "",
    "OPERATORS_MATHOPA": "",
    "OPERATORS_MATHOP_ABSA": "",
    "OPERATORS_MATHOP_FLOORA": "",
    "OPERATORS_MATHOP_CEILINGA": "",
    "OPERATORS_MATHOP_SQRTA": "",
    "OPERATORS_MATHOP_FIVE": "",
    "OPERATORS_MATHOP_SIX": "",
    "OPERATORS_MATHOP_SEVEN": "",
    "OPERATORS_MATHOP_LENGHT": "",
    "OPERATORS_MATHOP_MAXMIN": "",
    "OPERATORS_MATHOP_MAXMIN_MAX": "",
    "OPERATORS_MATHOP_MAXMIN_MIN": "",
    "OPERATORS_TEXTS_ONE": "",
    "OPERATORS_TEXTS_TWO": "",
    "OPERATORS_TEXTS_THREE": "",
    "OPERATORS_TEXTS_FOUR": "",
    "OPERATORS_TEXTS_FOUR_NEW": "",
    "OPERATORS_TEXTS_NEW": "",
    "OPERATORS_TEXTS_NEW_OP": "",
    "OPERATORS_TEXTS_FOUR_ONE": "",
    "OPERATORS_TEXTS_FOUR_TWO": "",
    "OPERATORS_TEXTS_FOUR_THREE": "",
    "OPERATORS_TEXTS_FOUR_FOUR": "",
    "OPERATORS_TEXTS_FOUR_F": "",
    "OPERATORS_TEXTS_FOUR_L": "",
    "OPERATORS_STRINGTONUMBERA": "",
    "OPERATORS_NUMTOASCIIA": "",
    "OPERATORS_STRINGTOASCIIA": "",
    "OPERATORS_NUMTOSTRINGA": "",
    "NEW_MC": "",
    "RENAME_MCNUMBER_TITLE": "",
    "RENAME_MCNUMBER_MODAL_TITLE": "",
    "RENAME_VARIABLE_MCNUMBER": "",
    "DELETE_VARIABLE_MCNUMBER": "",
    "SET_MC_VARIABLE": "",
    "TUPLE_ONE": "",
    "TUPLE_TWO": "",
    "ALIYUN": "",
    "ONENET": "",
    "EASYIOT": "",
    "SHANGHAI": "",
    "QINGDAO": "",
    "BEIJING": "",
    "ZHANGJIAKOU": "",
    "HUHEHAOTE": "",
    "HANGZHOU": "",
    "SHENZHEN": "",
    "HONGKONG": "",
    "SINGAPORE": "",
    "SYDNEY": "",
    "KUALALUMPUR": "",
    "JAKARTA": "",
    "MUMBAI": "",
    "TOKYO": "",
    "SILICONVALLEY": "",
    "VIRGINIA": "",
    "FRANKFURT": "",
    "LONDON": "",
    "DUBAI": "",
    "IOT_PLATFORM": "",
    "PARAMS": "",
    "SERVER_ATTR": "",
    "PRODUCTID": "",
    "DEVICEID": "",
    "TINYDB_SECRET": "",
    "TINYDB_USER": "",
    "APIADDR": "",
    "SOFTWARE_SERIAL": "",
    "HARDWARE_SERIAL": "",
    "HARDWARE_SERIAL1": "",
    "HARDWARE_SERIAL2": "",
    "HARDWARE_SERIAL3": ""
  },
  "sk": {
    "CONTROL_FOREVER": "opakuj stále",
    "CONTROL_REPEAT": "opakuj %1",
    "CONTROL_IF": "ak %1",
    "CONTROL_ELSE": "inak",
    "CONTROL_STOP": "zastav",
    "CONTROL_STOP_ALL": "všetko",
    "CONTROL_STOP_THIS": "tento scenár",
    "CONTROL_STOP_OTHER": "moje iné scenáre",
    "CONTROL_WAIT": "čakaj %1 s",
    "CONTROL_WAITUNTIL": "čakaj až kým %1",
    "CONTROL_REPEATUNTIL": "opakuj až kým %1",
    "CONTROL_WHILE": "opakuj ak %1",
    "CONTROL_FOREACH": "pre každé %1 v %2",
    "CONTROL_STARTASCLONE": "pri štarte klonu",
    "CONTROL_CREATECLONEOF": "naklonuj %1",
    "CONTROL_CREATECLONEOF_MYSELF": "seba",
    "CONTROL_DELETETHISCLONE": "zruš tento klon",
    "CONTROL_COUNTER": "počítadlo",
    "CONTROL_INCRCOUNTER": "zvýš počítadlo",
    "CONTROL_CLEARCOUNTER": "vynuluj počítadlo",
    "CONTROL_ALLATONCE": "všetko naraz",
    "DATA_SETVARIABLETO": "nastav %1 na %2",
    "DATA_CHANGEVARIABLEBY": "zmeň %1 o %2",
    "DATA_SHOWVARIABLE": "ukáž premennú %1",
    "DATA_HIDEVARIABLE": "skry premennú %1",
    "DATA_ADDTOLIST": "pridaj %1 do %2",
    "DATA_DELETEOFLIST": "zruš %1 z %2",
    "DATA_DELETEALLOFLIST": "zruš všetko z %1",
    "DATA_INSERTATLIST": "vlož %1 na %2 v %3",
    "DATA_REPLACEITEMOFLIST": "nahraď %1 v %2 hodnotou %3",
    "DATA_ITEMOFLIST": "prvok %1 z %2",
    "DATA_ITEMNUMOFLIST": "poradie prvku %1 v %2",
    "DATA_LENGTHOFLIST": "dĺžka %1",
    "DATA_LISTCONTAINSITEM": "%1 obsahuje %2?",
    "DATA_SHOWLIST": "ukáž zoznam  %1",
    "DATA_HIDELIST": "skry zoznam  %1",
    "DATA_INDEX_ALL": "všetko",
    "DATA_INDEX_LAST": "posledný",
    "DATA_INDEX_RANDOM": "náhodný",
    "EVENT_WHENFLAGCLICKED": "pri kliknutí na %1",
    "EVENT_WHENTHISSPRITECLICKED": "pri kliknutí na mňa",
    "EVENT_WHENSTAGECLICKED": "pri kliknutí na scénu",
    "EVENT_WHENTOUCHINGOBJECT": "pri dotyku %1",
    "EVENT_WHENBROADCASTRECEIVED": "pri prijatí správy %1",
    "EVENT_WHENBACKDROPSWITCHESTO": "pri zmene pozadia na %1",
    "EVENT_WHENGREATERTHAN": "pri %1 > %2",
    "EVENT_WHENGREATERTHAN_TIMER": "časovač",
    "EVENT_WHENGREATERTHAN_LOUDNESS": "hlasitosť",
    "EVENT_BROADCAST": "vyšli správu %1",
    "EVENT_BROADCASTANDWAIT": "vyšli správu %1 a počkaj",
    "EVENT_WHENKEYPRESSED": "pri stlačení %1",
    "EVENT_WHENKEYPRESSED_SPACE": "medzerník",
    "EVENT_WHENKEYPRESSED_LEFT": "šípka vľavo",
    "EVENT_WHENKEYPRESSED_RIGHT": "šípka vpravo",
    "EVENT_WHENKEYPRESSED_DOWN": "šípka dole",
    "EVENT_WHENKEYPRESSED_UP": "šípka hore",
    "EVENT_WHENKEYPRESSED_ANY": "hociktorý",
    "LOOKS_SAYFORSECS": "bublina %1 %2 s",
    "LOOKS_SAY": "bublina %1",
    "LOOKS_HELLO": "Ahoj!",
    "LOOKS_THINKFORSECS": "myšlienka %1 %2 s",
    "LOOKS_THINK": "myšlienka %1",
    "LOOKS_HMM": "Hm...",
    "LOOKS_SHOW": "ukáž sa",
    "LOOKS_HIDE": "skry sa",
    "LOOKS_HIDEALLSPRITES": "skry všetky postavy",
    "LOOKS_EFFECT_COLOR": "farba",
    "LOOKS_EFFECT_FISHEYE": "rybie oko",
    "LOOKS_EFFECT_WHIRL": "zvírenie",
    "LOOKS_EFFECT_PIXELATE": "štvorčeky",
    "LOOKS_EFFECT_MOSAIC": "mozaika",
    "LOOKS_EFFECT_BRIGHTNESS": "jas",
    "LOOKS_EFFECT_GHOST": "duch",
    "LOOKS_CHANGEEFFECTBY": "zmeň efekt %1 o %2",
    "LOOKS_SETEFFECTTO": "nastav efekt %1 na %2",
    "LOOKS_CLEARGRAPHICEFFECTS": "zruš grafické efekty",
    "LOOKS_CHANGESIZEBY": "zmeň veľkosť o %1 %",
    "LOOKS_SETSIZETO": "nastav veľkosť na %1 %",
    "LOOKS_SIZE": "veľkosť",
    "LOOKS_CHANGESTRETCHBY": "zmeň natiahnutie o %1",
    "LOOKS_SETSTRETCHTO": "nastav natiahnutie na %1 %",
    "LOOKS_SWITCHCOSTUMETO": "zmeň kostým na %1",
    "LOOKS_NEXTCOSTUME": "ďalší kostým",
    "LOOKS_SWITCHBACKDROPTO": "zmeň pozadie na %1",
    "LOOKS_GOTOFRONTBACK": "prejdi na  %1 vrstvu",
    "LOOKS_GOTOFRONTBACK_FRONT": "vrchnú",
    "LOOKS_GOTOFRONTBACK_BACK": "spodnú",
    "LOOKS_GOFORWARDBACKWARDLAYERS": "prejdi o %2 vrstiev %1",
    "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "dopredu",
    "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "dozadu",
    "LOOKS_BACKDROPNUMBERNAME": "pozadie  %1",
    "LOOKS_COSTUMENUMBERNAME": "kostým %1",
    "LOOKS_NUMBERNAME_NUMBER": "číslo",
    "LOOKS_NUMBERNAME_NAME": "meno",
    "LOOKS_SWITCHBACKDROPTOANDWAIT": "zmeň pozadie na %1 a počkaj",
    "LOOKS_NEXTBACKDROP_BLOCK": "ďalšie pozadie",
    "LOOKS_NEXTBACKDROP": "ďalšie pozadie",
    "LOOKS_PREVIOUSBACKDROP": "predchádzajúce pozadie",
    "LOOKS_RANDOMBACKDROP": "náhodné pozadie",
    "MOTION_MOVESTEPS": "dopredu %1",
    "MOTION_TURNLEFT": "vľavo %1 %2",
    "MOTION_TURNRIGHT": "vpravo %1 %2",
    "MOTION_POINTINDIRECTION": "smerom %1",
    "MOTION_POINTTOWARDS": "smerom k %1",
    "MOTION_POINTTOWARDS_POINTER": "myš",
    "MOTION_POINTTOWARDS_RANDOM": "náhodný smer",
    "MOTION_GOTO": "skoč na %1",
    "MOTION_GOTO_POINTER": "myš",
    "MOTION_GOTO_RANDOM": "náhodná pozícia",
    "MOTION_GOTOXY": "skoč na x: %1 y: %2",
    "MOTION_GLIDESECSTOXY": "kĺž sa %1 s na x: %2 y: %3",
    "MOTION_GLIDETO": "kĺž sa %1 s na %2",
    "MOTION_GLIDETO_POINTER": "myš",
    "MOTION_GLIDETO_RANDOM": "náhodná pozícia",
    "MOTION_CHANGEXBY": "zmeň x o %1",
    "MOTION_SETX": "skoč na x: %1",
    "MOTION_CHANGEYBY": "zmeň y o %1",
    "MOTION_SETY": "skoč na y: %1",
    "MOTION_IFONEDGEBOUNCE": "ak na okraji, odraz sa",
    "MOTION_SETROTATIONSTYLE": "nastav otáčanie %1",
    "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "vľavo-vpravo",
    "MOTION_SETROTATIONSTYLE_DONTROTATE": "žiadne",
    "MOTION_SETROTATIONSTYLE_ALLAROUND": "podľa smeru",
    "MOTION_XPOSITION": "pozícia x",
    "MOTION_YPOSITION": "pozícia y",
    "MOTION_DIRECTION": "smer",
    "MOTION_SCROLLRIGHT": "posuň vpravo %1",
    "MOTION_SCROLLUP": "presuň hore %1",
    "MOTION_ALIGNSCENE": "zarovnaj scénu %1",
    "MOTION_ALIGNSCENE_BOTTOMLEFT": "vľavo dolu",
    "MOTION_ALIGNSCENE_BOTTOMRIGHT": "vpravo dolu",
    "MOTION_ALIGNSCENE_MIDDLE": "stred",
    "MOTION_ALIGNSCENE_TOPLEFT": "vľavo hore",
    "MOTION_ALIGNSCENE_TOPRIGHT": "vpravo hore",
    "MOTION_XSCROLL": "posunutie x",
    "MOTION_YSCROLL": "posunutie y",
    "MOTION_STAGE_SELECTED": "Scéna nemá bloky pre pohyb",
    "OPERATORS_ADD": "%1 + %2",
    "OPERATORS_SUBTRACT": "%1 - %2",
    "OPERATORS_MULTIPLY": "%1 * %2",
    "OPERATORS_DIVIDE": "%1 / %2",
    "OPERATORS_RANDOM": "náhodne od %1 do %2",
    "OPERATORS_GT": "%1 > %2",
    "OPERATORS_GT_EQUALS": "",
    "OPERATORS_LT": "%1 < %2",
    "OPERATORS_LT_EQUALS": "",
    "OPERATORS_EQUALS": "%1 = %2",
    "OPERATORS_AND": "%1 a %2",
    "OPERATORS_OR": "%1 alebo %2",
    "OPERATORS_NOT": "nie je %1",
    "OPERATORS_JOIN": "spoj %1 %2",
    "OPERATORS_JOIN_APPLE": "jablko",
    "OPERATORS_JOIN_BANANA": "hruška",
    "OPERATORS_LETTEROF": "znak %1 z %2",
    "OPERATORS_LETTEROF_APPLE": "a",
    "OPERATORS_LENGTH": "dĺžka %1",
    "OPERATORS_CONTAINS": "%1 obsahuje %2?",
    "OPERATORS_MOD": "zvyšok %1 / %2",
    "OPERATORS_ROUND": "zaokrúhli %1",
    "OPERATORS_MATHOP": "%1 z %2",
    "OPERATORS_MATHOP_ABS": "abs",
    "OPERATORS_MATHOP_FLOOR": "zaokrúhli nadol",
    "OPERATORS_MATHOP_CEILING": "zaokrúhli nahor",
    "OPERATORS_MATHOP_SQRT": "odmocnina",
    "OPERATORS_MATHOP_SIN": "sin",
    "OPERATORS_MATHOP_COS": "cos",
    "OPERATORS_MATHOP_TAN": "tg",
    "OPERATORS_MATHOP_ASIN": "arcsin",
    "OPERATORS_MATHOP_ACOS": "arccos",
    "OPERATORS_MATHOP_ATAN": "arctg",
    "OPERATORS_MATHOP_LN": "ln",
    "OPERATORS_MATHOP_LOG": "log",
    "OPERATORS_MATHOP_EEXP": "exp",
    "OPERATORS_MATHOP_10EXP": "10 ^",
    "PROCEDURES_DEFINITION": "blok %1",
    "SENSING_TOUCHINGOBJECT": "dotýkaš sa %1?",
    "SENSING_TOUCHINGOBJECT_POINTER": "myš",
    "SENSING_TOUCHINGOBJECT_EDGE": "okraj",
    "SENSING_TOUCHINGCOLOR": "dotýkaš sa %1?",
    "SENSING_COLORISTOUCHINGCOLOR": "%1 sa dotýkaš %2?",
    "SENSING_DISTANCETO": "vzdialenosť k %1",
    "SENSING_DISTANCETO_POINTER": "myš",
    "SENSING_ASKANDWAIT": "otázka %1",
    "SENSING_ASK_TEXT": "Ako sa voláš?",
    "SENSING_ANSWER": "odpoveď",
    "SENSING_KEYPRESSED": "stlačené %1?",
    "SENSING_MOUSEDOWN": "stlačené tlačidlo myši?",
    "SENSING_MOUSEX": "myš x",
    "SENSING_MOUSEY": "myš y",
    "SENSING_SETDRAGMODE": "hráč môže presúvať %1",
    "SENSING_SETDRAGMODE_DRAGGABLE": "áno ",
    "SENSING_SETDRAGMODE_NOTDRAGGABLE": "nie",
    "SENSING_LOUDNESS": "hlasitosť",
    "SENSING_LOUD": "hlasný?",
    "SENSING_TIMER": "časovač",
    "SENSING_RESETTIMER": "vynuluj časovač",
    "SENSING_OF": "%1 z %2",
    "SENSING_OF_XPOSITION": "pozícia x",
    "SENSING_OF_YPOSITION": "pozícia y",
    "SENSING_OF_DIRECTION": "smer",
    "SENSING_OF_COSTUMENUMBER": "číslo kostýmu",
    "SENSING_OF_COSTUMENAME": "meno kostýmu",
    "SENSING_OF_SIZE": "veľkosť",
    "SENSING_OF_VOLUME": "hlasitosť",
    "SENSING_OF_BACKDROPNUMBER": "číslo pozadia",
    "SENSING_OF_BACKDROPNAME": "meno pozadia",
    "SENSING_OF_STAGE": "Scéna",
    "SENSING_CURRENT": "teraz je %1",
    "SENSING_CURRENT_YEAR": "rok",
    "SENSING_CURRENT_MONTH": "mesiac",
    "SENSING_CURRENT_DATE": "deň v mesiaci",
    "SENSING_CURRENT_DAYOFWEEK": "deň v týždni",
    "SENSING_CURRENT_HOUR": "hod",
    "SENSING_CURRENT_MINUTE": "min",
    "SENSING_CURRENT_SECOND": "s",
    "SENSING_DAYSSINCE2000": "dní od roku 2000",
    "SENSING_USERNAME": "používateľ",
    "SENSING_USERID": "užívateľské id",
    "SOUND_PLAY": "zahraj zvuk %1",
    "SOUND_PLAYUNTILDONE": "zahraj zvuk %1 až do konca",
    "SOUND_STOPALLSOUNDS": "zastav všetky zvuky",
    "SOUND_SETEFFECTO": "nastav efekt %1 na %2",
    "SOUND_CHANGEEFFECTBY": "zmeň efekt %1 o %2",
    "SOUND_CLEAREFFECTS": "zruš zvukové efekty",
    "SOUND_EFFECTS_PITCH": "frekvencia",
    "SOUND_EFFECTS_PAN": "stereo",
    "SOUND_CHANGEVOLUMEBY": "zmeň hlasitosť o %1 %",
    "SOUND_SETVOLUMETO": "nastav hlasitosť na %1% %",
    "SOUND_VOLUME": "hlasitosť",
    "SOUND_RECORD": "nahraj...",
    "CATEGORY_MOTION": "Pohyb",
    "CATEGORY_LOOKS": "Vzhľad",
    "CATEGORY_SOUND": "Zvuk",
    "CATEGORY_EVENTS": "Udalosti",
    "CATEGORY_CONTROL": "Riadenie",
    "CATEGORY_SENSING": "Zisťovanie",
    "CATEGORY_OPERATORS": "Operácie",
    "CATEGORY_VARIABLES": "Premenné",
    "CATEGORY_MYBLOCKS": "Nové bloky",
    "CATEGORY_LISTS": "",
    "CATEGORY_TUPLE": "",
    "CATEGORY_DICTIONARYS": "",
    "CATEGORY_NUMBERS": "",
    "CATEGORY_TEXT": "",
    "DUPLICATE": "duplikuj",
    "DELETE": "zruš",
    "ADD_COMMENT": "pridaj komentár",
    "REMOVE_COMMENT": "zruš komentár",
    "DELETE_BLOCK": "zruš blok",
    "DELETE_X_BLOCKS": "zruš %1 blokov",
    "DELETE_ALL_BLOCKS": "Naozaj chceš zrušiť %1 blokov?",
    "CLEAN_UP": "uprac",
    "HELP": "pomoc",
    "UNDO": "späť",
    "REDO": "znovu",
    "EDIT_PROCEDURE": "uprav",
    "SHOW_PROCEDURE_DEFINITION": "zobraz definíciu",
    "WORKSPACE_COMMENT_DEFAULT_TEXT": "Poznámka",
    "COLOUR_HUE_LABEL": "farba",
    "COLOUR_SATURATION_LABEL": "sýtosť",
    "COLOUR_BRIGHTNESS_LABEL": "jas",
    "CHANGE_VALUE_TITLE": "Zmeň hodnotu:",
    "RENAME_VARIABLE": "premenuj",
    "RENAME_VARIABLE_STRING": "",
    "RENAME_VARIABLE_NUMBER": "",
    "RENAME_VARIABLE_LIST": "",
    "RENAME_VARIABLE_TITLE": "Premenuj všetky výskyty premennej \"%1\":",
    "RENAME_VARIABLE_MODAL_TITLE": "Premenuj premennú",
    "NEW_VARIABLE": "Nová premenná",
    "NEW_VARIABLE_TITLE": "Meno premennej:",
    "VARIABLE_MODAL_TITLE": "Nová premenná",
    "VARIABLE_ALREADY_EXISTS": "Premenná s menom \"%1\" už existuje.",
    "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "Premenná s menom \"%1\" už existuje pre inú prememnú typu \"%2\".",
    "DELETE_VARIABLE_CONFIRMATION": "Naozaj mám zrušiť %1 výskytov premennej \"%2\"?",
    "CANNOT_DELETE_VARIABLE_PROCEDURE": "Nemôžem zrušiť premennú \"%1\", pretože je súčasťou definície funkcie \"%2\".",
    "DELETE_VARIABLE": "zruš premennú \"%1\"",
    "DELETE_VARIABLE_STRING": "",
    "DELETE_VARIABLE_NUMBER": "",
    "DELETE_VARIABLE_LIST": "",
    "NEW_PROCEDURE": "Nový blok",
    "PROCEDURE_ALREADY_EXISTS": "Blok \"%1\" už existuje.",
    "PROCEDURE_DEFAULT_NAME": "meno bloku",
    "NEW_LIST": "Nový zoznam",
    "NEW_LIST_TITLE": "Meno nového zoznamu:",
    "LIST_MODAL_TITLE": "Nový zoznam",
    "LIST_ALREADY_EXISTS": "Zoznam \"%1\" už existuje.",
    "RENAME_LIST_TITLE": "Premenuj zoznam \"%1\" na:",
    "RENAME_LIST_MODAL_TITLE": "Premenuj zoznam",
    "DEFAULT_LIST_ITEM": "hodnota",
    "DELETE_LIST": "zruš zoznam \"%1\"",
    "RENAME_LIST": "premenuj zoznam",
    "NEW_BROADCAST_MESSAGE": "nová správa...",
    "NEW_BROADCAST_MESSAGE_TITLE": "Meno novej správy:",
    "BROADCAST_MODAL_TITLE": "Nová správa",
    "DEFAULT_BROADCAST_MESSAGE_NAME": "správa1",
    "DELETE_PROCEDURE": "",
    "OK": "",
    "PROMPT": "",
    "STOP": "",
    "NEW_STRING": "",
    "NEW_NUMBER_VARIABLE": "",
    "WIFI": "",
    "USERNAME": "",
    "PWD": "",
    "IOT_SERVICE": "",
    "IOT_ID": "",
    "IOT_PWD": "",
    "IOT_TOPIC": "",
    "MORE_TOPIC": "",
    "OBLOQ_HTTP": "",
    "OBLOQ_IP": "",
    "OBLOQ_PORT": "",
    "ADD_TOPIC": "",
    "DELETE_TOPIC": "",
    "IOT_SERVER": "",
    "CHINA": "",
    "GLOBAL": "",
    "LOW_C": "",
    "LOW_C$": "",
    "LOW_D": "",
    "LOW_D$": "",
    "LOW_E": "",
    "LOW_F": "",
    "LOW_F$": "",
    "LOW_G": "",
    "LOW_G$": "",
    "LOW_A": "",
    "LOW_A$": "",
    "LOW_B": "",
    "MIDDLE_C": "",
    "MIDDLE_C$": "",
    "MIDDLE_D": "",
    "MIDDLE_D$": "",
    "MIDDLE_E": "",
    "MIDDLE_F": "",
    "MIDDLE_F$": "",
    "MIDDLE_G": "",
    "MIDDLE_G$": "",
    "MIDDLE_A": "",
    "MIDDLE_A$": "",
    "MIDDLE_B": "",
    "HIGH_C": "",
    "HIGH_C$": "",
    "HIGH_D": "",
    "HIGH_D$": "",
    "HIGH_E": "",
    "HIGH_F": "",
    "HIGH_F$": "",
    "HIGH_G": "",
    "HIGH_G$": "",
    "HIGH_A": "",
    "HIGH_A$": "",
    "HIGH_B": "",
    "OPERATORS_STRINGTONUMBER": "",
    "INTEGER": "",
    "DECIMAL": "",
    "OPERATORS_NUMTOASCII": "",
    "OPERATORS_STRINGTOASCII": "",
    "OPERATORS_NUMTOSTRING": "",
    "OPERATORS_MAP": "",
    "OPERATORS_CONSTRAIN": "",
    "STEPS_PER_TURN": "",
    "ROTATE_SPEED": "",
    "NEW_AI": "",
    "ASK_AND_PAINT": "",
    "GET_IDENTIFIED_NUM": "",
    "READ_NUM_AND_SAY": "",
    "IMAGE_ADDR": "",
    "IMAGE_PREVIEW": "",
    "IMAGE_OPEN": "",
    "IMAGE_SIZE": "",
    "IMAGE_WIDTH": "",
    "IMAGE_HEIGHT": "",
    "VARIABLE": "",
    "VARIABLE_LIST": "",
    "SET_STRING_VARIABLE": "",
    "STRING_START_WITH": "",
    "DICTIONARY_HEIGHT_FIRST": "",
    "DICTIONARY_HEIGHT_TWO": "",
    "DICTIONARY_HEIGHT_THREE": "",
    "DICTIONARY_HEIGHT_FOUR": "",
    "DATA_MPITEMOFLIST": "",
    "DATA_MPINTERCEPTITEM": "",
    "DATA_MPINSERTATLIST": "",
    "DATA_MPSETVALUEATLIST": "",
    "DATA_MPINSERTATLIST_INSERT": "",
    "DATA_MPINSERTATLIST_CHANGE": "",
    "DATA_MPINSERTATLIST_FOUR": "",
    "DATA_MPINSERTATLIST_FIVE": "",
    "DATA_MPINSERTATLIST_SIX": "",
    "DATA_MPINSERTATLIST_SEVEN": "",
    "DATA_MPINSERTATLIST_EIGHT": "",
    "DATA_MPINSERTATLIST_EIGHT_FALSE": "",
    "DATA_MPINSERTATLIST_EIGHT_TRUE": "",
    "DATA_MPINSERTATLIST_EIGHT_REVERSE": "",
    "OPERATORS_RANDOMA": "",
    "OPERATORS_MODA": "",
    "OPERATORS_ROUNDA": "",
    "OPERATORS_MATHOPA": "",
    "OPERATORS_MATHOP_ABSA": "",
    "OPERATORS_MATHOP_FLOORA": "",
    "OPERATORS_MATHOP_CEILINGA": "",
    "OPERATORS_MATHOP_SQRTA": "",
    "OPERATORS_MATHOP_FIVE": "",
    "OPERATORS_MATHOP_SIX": "",
    "OPERATORS_MATHOP_SEVEN": "",
    "OPERATORS_MATHOP_LENGHT": "",
    "OPERATORS_MATHOP_MAXMIN": "",
    "OPERATORS_MATHOP_MAXMIN_MAX": "",
    "OPERATORS_MATHOP_MAXMIN_MIN": "",
    "OPERATORS_TEXTS_ONE": "",
    "OPERATORS_TEXTS_TWO": "",
    "OPERATORS_TEXTS_THREE": "",
    "OPERATORS_TEXTS_FOUR": "",
    "OPERATORS_TEXTS_FOUR_NEW": "",
    "OPERATORS_TEXTS_NEW": "",
    "OPERATORS_TEXTS_NEW_OP": "",
    "OPERATORS_TEXTS_FOUR_ONE": "",
    "OPERATORS_TEXTS_FOUR_TWO": "",
    "OPERATORS_TEXTS_FOUR_THREE": "",
    "OPERATORS_TEXTS_FOUR_FOUR": "",
    "OPERATORS_TEXTS_FOUR_F": "",
    "OPERATORS_TEXTS_FOUR_L": "",
    "OPERATORS_STRINGTONUMBERA": "",
    "OPERATORS_NUMTOASCIIA": "",
    "OPERATORS_STRINGTOASCIIA": "",
    "OPERATORS_NUMTOSTRINGA": "",
    "NEW_MC": "",
    "RENAME_MCNUMBER_TITLE": "",
    "RENAME_MCNUMBER_MODAL_TITLE": "",
    "RENAME_VARIABLE_MCNUMBER": "",
    "DELETE_VARIABLE_MCNUMBER": "",
    "SET_MC_VARIABLE": "",
    "TUPLE_ONE": "",
    "TUPLE_TWO": "",
    "ALIYUN": "",
    "ONENET": "",
    "EASYIOT": "",
    "SHANGHAI": "",
    "QINGDAO": "",
    "BEIJING": "",
    "ZHANGJIAKOU": "",
    "HUHEHAOTE": "",
    "HANGZHOU": "",
    "SHENZHEN": "",
    "HONGKONG": "",
    "SINGAPORE": "",
    "SYDNEY": "",
    "KUALALUMPUR": "",
    "JAKARTA": "",
    "MUMBAI": "",
    "TOKYO": "",
    "SILICONVALLEY": "",
    "VIRGINIA": "",
    "FRANKFURT": "",
    "LONDON": "",
    "DUBAI": "",
    "IOT_PLATFORM": "",
    "PARAMS": "",
    "SERVER_ATTR": "",
    "PRODUCTID": "",
    "DEVICEID": "",
    "TINYDB_SECRET": "",
    "TINYDB_USER": "",
    "APIADDR": "",
    "SOFTWARE_SERIAL": "",
    "HARDWARE_SERIAL": "",
    "HARDWARE_SERIAL1": "",
    "HARDWARE_SERIAL2": "",
    "HARDWARE_SERIAL3": ""
  },
  "sl": {
    "CONTROL_FOREVER": "ponavljaj",
    "CONTROL_REPEAT": "ponovi %1 krat",
    "CONTROL_IF": "če %1 potem",
    "CONTROL_ELSE": "sicer",
    "CONTROL_STOP": "ustavi",
    "CONTROL_STOP_ALL": "vse",
    "CONTROL_STOP_THIS": "te ukaze",
    "CONTROL_STOP_OTHER": "ostale ukaze za to figuro",
    "CONTROL_WAIT": "počakaj %1 sekund",
    "CONTROL_WAITUNTIL": "počakaj dokler ni %1",
    "CONTROL_REPEATUNTIL": "ponavljaj do %1",
    "CONTROL_WHILE": "dokler %1",
    "CONTROL_FOREACH": "za vsak %1 v %2",
    "CONTROL_STARTASCLONE": "ko začnem kot dvojnik",
    "CONTROL_CREATECLONEOF": "ustvari dvojnika %1",
    "CONTROL_CREATECLONEOF_MYSELF": "sebe",
    "CONTROL_DELETETHISCLONE": "zbriši tega dvojnika",
    "CONTROL_COUNTER": "števec",
    "CONTROL_INCRCOUNTER": "povečaj števec",
    "CONTROL_CLEARCOUNTER": "počisti števec",
    "CONTROL_ALLATONCE": "vse hkrati",
    "DATA_SETVARIABLETO": "nastavi %1 na %2",
    "DATA_CHANGEVARIABLEBY": "spremeni %1 za %2",
    "DATA_SHOWVARIABLE": "pokaži spremenljivko %1",
    "DATA_HIDEVARIABLE": "skrij spremenljivko %1",
    "DATA_ADDTOLIST": "dodaj %1 k %2",
    "DATA_DELETEOFLIST": "zbriši %1 v %2",
    "DATA_DELETEALLOFLIST": "izbriši vse v %1",
    "DATA_INSERTATLIST": "vstavi %1 na %2 v %3",
    "DATA_REPLACEITEMOFLIST": "zamenjaj %1 v %2 z %3",
    "DATA_ITEMOFLIST": "element %1 v %2",
    "DATA_ITEMNUMOFLIST": "predmet # od %1 v %2",
    "DATA_LENGTHOFLIST": "dolžina %1",
    "DATA_LISTCONTAINSITEM": "Ali %1 vsebuje %2?",
    "DATA_SHOWLIST": "pokaži seznam %1",
    "DATA_HIDELIST": "skrij seznam %1",
    "DATA_INDEX_ALL": "vse",
    "DATA_INDEX_LAST": "zadnji",
    "DATA_INDEX_RANDOM": "naključno",
    "EVENT_WHENFLAGCLICKED": "ko kliknemo na %1",
    "EVENT_WHENTHISSPRITECLICKED": "ko kliknemo to figuro",
    "EVENT_WHENSTAGECLICKED": "ko kliknemo na oder",
    "EVENT_WHENTOUCHINGOBJECT": "ko se ta figura dotika %1",
    "EVENT_WHENBROADCASTRECEIVED": "ko prejmem %1",
    "EVENT_WHENBACKDROPSWITCHESTO": "ko se ozadje zamenja na %1",
    "EVENT_WHENGREATERTHAN": "ko je %1 > %2",
    "EVENT_WHENGREATERTHAN_TIMER": "štoparica",
    "EVENT_WHENGREATERTHAN_LOUDNESS": "glasnost",
    "EVENT_BROADCAST": "objavi %1",
    "EVENT_BROADCASTANDWAIT": "objavi %1 in čakaj",
    "EVENT_WHENKEYPRESSED": "ko je pritisnjena tipka %1 ",
    "EVENT_WHENKEYPRESSED_SPACE": "presledek",
    "EVENT_WHENKEYPRESSED_LEFT": "puščica levo",
    "EVENT_WHENKEYPRESSED_RIGHT": "puščica desno",
    "EVENT_WHENKEYPRESSED_DOWN": "puščica dol",
    "EVENT_WHENKEYPRESSED_UP": "puščica gor",
    "EVENT_WHENKEYPRESSED_ANY": "poljubna",
    "LOOKS_SAYFORSECS": "reci %1 za %2 sekund",
    "LOOKS_SAY": "reci %1",
    "LOOKS_HELLO": "Živjo!",
    "LOOKS_THINKFORSECS": "pomisli %1 za %2 sekund",
    "LOOKS_THINK": "pomisli %1",
    "LOOKS_HMM": "Hmm...",
    "LOOKS_SHOW": "pokaži",
    "LOOKS_HIDE": "skrij",
    "LOOKS_HIDEALLSPRITES": "skrij vse figure",
    "LOOKS_EFFECT_COLOR": "barva",
    "LOOKS_EFFECT_FISHEYE": "ribje oko",
    "LOOKS_EFFECT_WHIRL": "vrtinec",
    "LOOKS_EFFECT_PIXELATE": "pikčasto",
    "LOOKS_EFFECT_MOSAIC": "mozaik",
    "LOOKS_EFFECT_BRIGHTNESS": "svetlost",
    "LOOKS_EFFECT_GHOST": "duh",
    "LOOKS_CHANGEEFFECTBY": "spremeni učinek %1 za %2",
    "LOOKS_SETEFFECTTO": "nastavi učinek %1 na %2",
    "LOOKS_CLEARGRAPHICEFFECTS": "odstrani slikovne učinke",
    "LOOKS_CHANGESIZEBY": "spremeni velikost za %1",
    "LOOKS_SETSIZETO": "nastavi velikost na %1 %",
    "LOOKS_SIZE": "velikost",
    "LOOKS_CHANGESTRETCHBY": "spremeni razteg za %1",
    "LOOKS_SETSTRETCHTO": "nastavi razteg na %1 %",
    "LOOKS_SWITCHCOSTUMETO": "zamenjaj videz na %1",
    "LOOKS_NEXTCOSTUME": "naslednji videz",
    "LOOKS_SWITCHBACKDROPTO": "zamenjaj ozadje na %1",
    "LOOKS_GOTOFRONTBACK": "pojdi na %1 plast",
    "LOOKS_GOTOFRONTBACK_FRONT": "spredaj",
    "LOOKS_GOTOFRONTBACK_BACK": "zadaj",
    "LOOKS_GOFORWARDBACKWARDLAYERS": "pojdi %1 %2 plasti",
    "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "naprej",
    "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "nazaj",
    "LOOKS_BACKDROPNUMBERNAME": "ozadje %1",
    "LOOKS_COSTUMENUMBERNAME": "videz %1",
    "LOOKS_NUMBERNAME_NUMBER": "število",
    "LOOKS_NUMBERNAME_NAME": "ime",
    "LOOKS_SWITCHBACKDROPTOANDWAIT": "zamenjaj ozadje na %1 in počakaj",
    "LOOKS_NEXTBACKDROP_BLOCK": "naslednje ozadje",
    "LOOKS_NEXTBACKDROP": "naslednje ozadje",
    "LOOKS_PREVIOUSBACKDROP": "prejšnje ozadje",
    "LOOKS_RANDOMBACKDROP": "naključno ozadje",
    "MOTION_MOVESTEPS": "pojdi %1 korakov",
    "MOTION_TURNLEFT": "obrni se za %1 %2 stopinj",
    "MOTION_TURNRIGHT": "obrni se za %1 %2 stopinj",
    "MOTION_POINTINDIRECTION": "obrni se v smer %1",
    "MOTION_POINTTOWARDS": "obrni se proti %1",
    "MOTION_POINTTOWARDS_POINTER": "kazalcu miške",
    "MOTION_POINTTOWARDS_RANDOM": "naključna smer",
    "MOTION_GOTO": "pojdi na %1",
    "MOTION_GOTO_POINTER": "kazalcu miške",
    "MOTION_GOTO_RANDOM": "naključno mesto",
    "MOTION_GOTOXY": "pojdi na x: %1 y: %2",
    "MOTION_GLIDESECSTOXY": "drsi %1 sekund do x: %2 y: %3",
    "MOTION_GLIDETO": "drsi %1 sekund do %2",
    "MOTION_GLIDETO_POINTER": "kazalca miške",
    "MOTION_GLIDETO_RANDOM": "naključnega mesta",
    "MOTION_CHANGEXBY": "spremeni x za %1",
    "MOTION_SETX": "nastavi x na %1",
    "MOTION_CHANGEYBY": "spremeni y za %1",
    "MOTION_SETY": "nastavi y na %1",
    "MOTION_IFONEDGEBOUNCE": "odbij se, če si na robu",
    "MOTION_SETROTATIONSTYLE": "Način vrtenja %1",
    "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "levo-desno",
    "MOTION_SETROTATIONSTYLE_DONTROTATE": "ne zasukaj",
    "MOTION_SETROTATIONSTYLE_ALLAROUND": "na vse strani",
    "MOTION_XPOSITION": "položaj x",
    "MOTION_YPOSITION": "položaj y",
    "MOTION_DIRECTION": "smer",
    "MOTION_SCROLLRIGHT": "premik desno %1",
    "MOTION_SCROLLUP": "premik gor%1",
    "MOTION_ALIGNSCENE": "poravnaj sceno %1",
    "MOTION_ALIGNSCENE_BOTTOMLEFT": "levo spodaj",
    "MOTION_ALIGNSCENE_BOTTOMRIGHT": "desno spodaj",
    "MOTION_ALIGNSCENE_MIDDLE": "sredina",
    "MOTION_ALIGNSCENE_TOPLEFT": "levo zgoraj",
    "MOTION_ALIGNSCENE_TOPRIGHT": "desno zgoraj",
    "MOTION_XSCROLL": "premik v smeri x",
    "MOTION_YSCROLL": "premik v smeri y",
    "MOTION_STAGE_SELECTED": "Izbran oder: ni blokov za premikanje",
    "OPERATORS_ADD": "%1 + %2",
    "OPERATORS_SUBTRACT": "%1 - %2",
    "OPERATORS_MULTIPLY": "%1 * %2",
    "OPERATORS_DIVIDE": "%1 / %2",
    "OPERATORS_RANDOM": "naključno število med %1 in %2",
    "OPERATORS_GT": "%1 > %2",
    "OPERATORS_GT_EQUALS": "",
    "OPERATORS_LT": "%1 < %2",
    "OPERATORS_LT_EQUALS": "",
    "OPERATORS_EQUALS": "%1 = %2",
    "OPERATORS_AND": "%1 in %2",
    "OPERATORS_OR": "%1 ali %2",
    "OPERATORS_NOT": "ne %1",
    "OPERATORS_JOIN": "združi %1 %2",
    "OPERATORS_JOIN_APPLE": "jabolko",
    "OPERATORS_JOIN_BANANA": "banana",
    "OPERATORS_LETTEROF": "%1 črka v %2",
    "OPERATORS_LETTEROF_APPLE": "b",
    "OPERATORS_LENGTH": "dolžina %1",
    "OPERATORS_CONTAINS": "ali %1 vsebuje %2?",
    "OPERATORS_MOD": "%1 mod %2",
    "OPERATORS_ROUND": "zaokroži %1",
    "OPERATORS_MATHOP": "%1 od %2",
    "OPERATORS_MATHOP_ABS": "absolutna vrednost",
    "OPERATORS_MATHOP_FLOOR": "zaokroženo navzdol",
    "OPERATORS_MATHOP_CEILING": "zaokroženo navzgor",
    "OPERATORS_MATHOP_SQRT": "kvadratni koren ",
    "OPERATORS_MATHOP_SIN": "sin",
    "OPERATORS_MATHOP_COS": "cos",
    "OPERATORS_MATHOP_TAN": "tg",
    "OPERATORS_MATHOP_ASIN": "arcsin",
    "OPERATORS_MATHOP_ACOS": "arccos",
    "OPERATORS_MATHOP_ATAN": "arctg",
    "OPERATORS_MATHOP_LN": "ln",
    "OPERATORS_MATHOP_LOG": "log",
    "OPERATORS_MATHOP_EEXP": "e ^",
    "OPERATORS_MATHOP_10EXP": "10 ^",
    "PROCEDURES_DEFINITION": "definiraj %1",
    "SENSING_TOUCHINGOBJECT": "se dotika %1?",
    "SENSING_TOUCHINGOBJECT_POINTER": "kazalca miške",
    "SENSING_TOUCHINGOBJECT_EDGE": "roba",
    "SENSING_TOUCHINGCOLOR": "se dotika barve %1?",
    "SENSING_COLORISTOUCHINGCOLOR": "se barva %1 dotika %2?",
    "SENSING_DISTANCETO": "razdalja do %1",
    "SENSING_DISTANCETO_POINTER": "kazalca miške",
    "SENSING_ASKANDWAIT": "vprašaj %1 in počakaj",
    "SENSING_ASK_TEXT": "Kako ti je ime?",
    "SENSING_ANSWER": "odgovor",
    "SENSING_KEYPRESSED": "je pritisnjena tipka %1?",
    "SENSING_MOUSEDOWN": "je miškin gumb pritisnjen?",
    "SENSING_MOUSEX": "miškin x",
    "SENSING_MOUSEY": "miškin y",
    "SENSING_SETDRAGMODE": "Možnost premika naj bo %1 ",
    "SENSING_SETDRAGMODE_DRAGGABLE": "možno premikati",
    "SENSING_SETDRAGMODE_NOTDRAGGABLE": "ne moremo premikati",
    "SENSING_LOUDNESS": "glasnost",
    "SENSING_LOUD": "glasno?",
    "SENSING_TIMER": "štoparica",
    "SENSING_RESETTIMER": "ponastavi štoparico",
    "SENSING_OF": "%1 od %2",
    "SENSING_OF_XPOSITION": "položaj x",
    "SENSING_OF_YPOSITION": "položaj y",
    "SENSING_OF_DIRECTION": "smer",
    "SENSING_OF_COSTUMENUMBER": "videz #",
    "SENSING_OF_COSTUMENAME": "ime videza",
    "SENSING_OF_SIZE": "velikost",
    "SENSING_OF_VOLUME": "glasnost",
    "SENSING_OF_BACKDROPNUMBER": "ozadje #",
    "SENSING_OF_BACKDROPNAME": "ime ozadja",
    "SENSING_OF_STAGE": "Oder",
    "SENSING_CURRENT": "trenutni %1",
    "SENSING_CURRENT_YEAR": "leto",
    "SENSING_CURRENT_MONTH": "mesec",
    "SENSING_CURRENT_DATE": "datum",
    "SENSING_CURRENT_DAYOFWEEK": "dan v tednu",
    "SENSING_CURRENT_HOUR": "ura",
    "SENSING_CURRENT_MINUTE": "minuta",
    "SENSING_CURRENT_SECOND": "sekunda",
    "SENSING_DAYSSINCE2000": "dni od leta 2000",
    "SENSING_USERNAME": "uporabniško ime",
    "SENSING_USERID": "uporabnikova številka",
    "SOUND_PLAY": "predvajaj zvok %1",
    "SOUND_PLAYUNTILDONE": "predvajaj zvok %1 do konca",
    "SOUND_STOPALLSOUNDS": "ustavi vse zvoke",
    "SOUND_SETEFFECTO": "nastavi učinek %1 na %2",
    "SOUND_CHANGEEFFECTBY": "spremeni učinek %1 za %2",
    "SOUND_CLEAREFFECTS": "izbriši zvočne učinke",
    "SOUND_EFFECTS_PITCH": "višina tona",
    "SOUND_EFFECTS_PAN": "premik levo/desno",
    "SOUND_CHANGEVOLUMEBY": "spremeni glasnost za %1",
    "SOUND_SETVOLUMETO": "nastavi glasnost na %1%",
    "SOUND_VOLUME": "glasnost",
    "SOUND_RECORD": "posnami...",
    "CATEGORY_MOTION": "Gibanje",
    "CATEGORY_LOOKS": "Videzi",
    "CATEGORY_SOUND": "Zvok",
    "CATEGORY_EVENTS": "Dogodki",
    "CATEGORY_CONTROL": "Krmiljenje",
    "CATEGORY_SENSING": "Zaznavanje",
    "CATEGORY_OPERATORS": "Operatorji",
    "CATEGORY_VARIABLES": "Spremenljivke",
    "CATEGORY_MYBLOCKS": "Moji bloki",
    "CATEGORY_LISTS": "",
    "CATEGORY_TUPLE": "",
    "CATEGORY_DICTIONARYS": "",
    "CATEGORY_NUMBERS": "",
    "CATEGORY_TEXT": "",
    "DUPLICATE": "Podvoji",
    "DELETE": "Izbriši",
    "ADD_COMMENT": "Dodaj komentar",
    "REMOVE_COMMENT": "Odstrani komentar",
    "DELETE_BLOCK": "Izbriši blok",
    "DELETE_X_BLOCKS": "Izbriši %1 blokov",
    "DELETE_ALL_BLOCKS": "Naj izbrišem vseh %1 blokov?",
    "CLEAN_UP": "Počisti bloke",
    "HELP": "Pomoč",
    "UNDO": "Razveljavi",
    "REDO": "Uveljavi",
    "EDIT_PROCEDURE": "Uredi",
    "SHOW_PROCEDURE_DEFINITION": "Pojdi na definicijo",
    "WORKSPACE_COMMENT_DEFAULT_TEXT": "Reci kaj ...",
    "COLOUR_HUE_LABEL": "Barva",
    "COLOUR_SATURATION_LABEL": "Nasičenost",
    "COLOUR_BRIGHTNESS_LABEL": "Svetlost",
    "CHANGE_VALUE_TITLE": "Spremeni vrednost: ",
    "RENAME_VARIABLE": "Preimenuj spremenljivko",
    "RENAME_VARIABLE_STRING": "",
    "RENAME_VARIABLE_NUMBER": "",
    "RENAME_VARIABLE_LIST": "",
    "RENAME_VARIABLE_TITLE": "Preimenuj vse spremenljivke  \"%1\" v:",
    "RENAME_VARIABLE_MODAL_TITLE": "Preimenuj spremenljivko",
    "NEW_VARIABLE": "Ustvari spremenljivko",
    "NEW_VARIABLE_TITLE": "Novo ime spremenljivke:",
    "VARIABLE_MODAL_TITLE": "Nova spremenljivka",
    "VARIABLE_ALREADY_EXISTS": "Spremenljivka z imenom \"%1\" že obstaja.",
    "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "Spremenljivka z imenom \"%1\" že obstaja in je tipa \"%2\".",
    "DELETE_VARIABLE_CONFIRMATION": "Izbrisati %1 uporab spremenljivke \"%2\"?",
    "CANNOT_DELETE_VARIABLE_PROCEDURE": "Ne morem izbrisati spremenljivke \"%1\", ker je del definicije funkcije \"%2\"",
    "DELETE_VARIABLE": "Izbriši spremenljivko \"%1\"",
    "DELETE_VARIABLE_STRING": "",
    "DELETE_VARIABLE_NUMBER": "",
    "DELETE_VARIABLE_LIST": "",
    "NEW_PROCEDURE": "Ustvari blok",
    "PROCEDURE_ALREADY_EXISTS": "Postopek z imenom \"%1\" že obstaja",
    "PROCEDURE_DEFAULT_NAME": "ime bloka",
    "NEW_LIST": "Ustvari seznam",
    "NEW_LIST_TITLE": "Novi ime seznama:",
    "LIST_MODAL_TITLE": "Nov seznam",
    "LIST_ALREADY_EXISTS": "Seznam z imenom \"%1\" že obstaja.",
    "RENAME_LIST_TITLE": "Preimenuj seznam \"%1\" v:",
    "RENAME_LIST_MODAL_TITLE": "Preimenuj seznam",
    "DEFAULT_LIST_ITEM": "stvar",
    "DELETE_LIST": "Izbriši seznam \"%1\" ",
    "RENAME_LIST": "Preimenuj seznam",
    "NEW_BROADCAST_MESSAGE": "Novo sporočilo",
    "NEW_BROADCAST_MESSAGE_TITLE": "Ime novega sporočila:",
    "BROADCAST_MODAL_TITLE": "Novo sporočilo",
    "DEFAULT_BROADCAST_MESSAGE_NAME": "sporočilo1",
    "DELETE_PROCEDURE": "",
    "OK": "",
    "PROMPT": "",
    "STOP": "",
    "NEW_STRING": "",
    "NEW_NUMBER_VARIABLE": "",
    "WIFI": "",
    "USERNAME": "",
    "PWD": "",
    "IOT_SERVICE": "",
    "IOT_ID": "",
    "IOT_PWD": "",
    "IOT_TOPIC": "",
    "MORE_TOPIC": "",
    "OBLOQ_HTTP": "",
    "OBLOQ_IP": "",
    "OBLOQ_PORT": "",
    "ADD_TOPIC": "",
    "DELETE_TOPIC": "",
    "IOT_SERVER": "",
    "CHINA": "",
    "GLOBAL": "",
    "LOW_C": "",
    "LOW_C$": "",
    "LOW_D": "",
    "LOW_D$": "",
    "LOW_E": "",
    "LOW_F": "",
    "LOW_F$": "",
    "LOW_G": "",
    "LOW_G$": "",
    "LOW_A": "",
    "LOW_A$": "",
    "LOW_B": "",
    "MIDDLE_C": "",
    "MIDDLE_C$": "",
    "MIDDLE_D": "",
    "MIDDLE_D$": "",
    "MIDDLE_E": "",
    "MIDDLE_F": "",
    "MIDDLE_F$": "",
    "MIDDLE_G": "",
    "MIDDLE_G$": "",
    "MIDDLE_A": "",
    "MIDDLE_A$": "",
    "MIDDLE_B": "",
    "HIGH_C": "",
    "HIGH_C$": "",
    "HIGH_D": "",
    "HIGH_D$": "",
    "HIGH_E": "",
    "HIGH_F": "",
    "HIGH_F$": "",
    "HIGH_G": "",
    "HIGH_G$": "",
    "HIGH_A": "",
    "HIGH_A$": "",
    "HIGH_B": "",
    "OPERATORS_STRINGTONUMBER": "",
    "INTEGER": "",
    "DECIMAL": "",
    "OPERATORS_NUMTOASCII": "",
    "OPERATORS_STRINGTOASCII": "",
    "OPERATORS_NUMTOSTRING": "",
    "OPERATORS_MAP": "",
    "OPERATORS_CONSTRAIN": "",
    "STEPS_PER_TURN": "",
    "ROTATE_SPEED": "",
    "NEW_AI": "",
    "ASK_AND_PAINT": "",
    "GET_IDENTIFIED_NUM": "",
    "READ_NUM_AND_SAY": "",
    "IMAGE_ADDR": "",
    "IMAGE_PREVIEW": "",
    "IMAGE_OPEN": "",
    "IMAGE_SIZE": "",
    "IMAGE_WIDTH": "",
    "IMAGE_HEIGHT": "",
    "VARIABLE": "",
    "VARIABLE_LIST": "",
    "SET_STRING_VARIABLE": "",
    "STRING_START_WITH": "",
    "DICTIONARY_HEIGHT_FIRST": "",
    "DICTIONARY_HEIGHT_TWO": "",
    "DICTIONARY_HEIGHT_THREE": "",
    "DICTIONARY_HEIGHT_FOUR": "",
    "DATA_MPITEMOFLIST": "",
    "DATA_MPINTERCEPTITEM": "",
    "DATA_MPINSERTATLIST": "",
    "DATA_MPSETVALUEATLIST": "",
    "DATA_MPINSERTATLIST_INSERT": "",
    "DATA_MPINSERTATLIST_CHANGE": "",
    "DATA_MPINSERTATLIST_FOUR": "",
    "DATA_MPINSERTATLIST_FIVE": "",
    "DATA_MPINSERTATLIST_SIX": "",
    "DATA_MPINSERTATLIST_SEVEN": "",
    "DATA_MPINSERTATLIST_EIGHT": "",
    "DATA_MPINSERTATLIST_EIGHT_FALSE": "",
    "DATA_MPINSERTATLIST_EIGHT_TRUE": "",
    "DATA_MPINSERTATLIST_EIGHT_REVERSE": "",
    "OPERATORS_RANDOMA": "",
    "OPERATORS_MODA": "",
    "OPERATORS_ROUNDA": "",
    "OPERATORS_MATHOPA": "",
    "OPERATORS_MATHOP_ABSA": "",
    "OPERATORS_MATHOP_FLOORA": "",
    "OPERATORS_MATHOP_CEILINGA": "",
    "OPERATORS_MATHOP_SQRTA": "",
    "OPERATORS_MATHOP_FIVE": "",
    "OPERATORS_MATHOP_SIX": "",
    "OPERATORS_MATHOP_SEVEN": "",
    "OPERATORS_MATHOP_LENGHT": "",
    "OPERATORS_MATHOP_MAXMIN": "",
    "OPERATORS_MATHOP_MAXMIN_MAX": "",
    "OPERATORS_MATHOP_MAXMIN_MIN": "",
    "OPERATORS_TEXTS_ONE": "",
    "OPERATORS_TEXTS_TWO": "",
    "OPERATORS_TEXTS_THREE": "",
    "OPERATORS_TEXTS_FOUR": "",
    "OPERATORS_TEXTS_FOUR_NEW": "",
    "OPERATORS_TEXTS_NEW": "",
    "OPERATORS_TEXTS_NEW_OP": "",
    "OPERATORS_TEXTS_FOUR_ONE": "",
    "OPERATORS_TEXTS_FOUR_TWO": "",
    "OPERATORS_TEXTS_FOUR_THREE": "",
    "OPERATORS_TEXTS_FOUR_FOUR": "",
    "OPERATORS_TEXTS_FOUR_F": "",
    "OPERATORS_TEXTS_FOUR_L": "",
    "OPERATORS_STRINGTONUMBERA": "",
    "OPERATORS_NUMTOASCIIA": "",
    "OPERATORS_STRINGTOASCIIA": "",
    "OPERATORS_NUMTOSTRINGA": "",
    "NEW_MC": "",
    "RENAME_MCNUMBER_TITLE": "",
    "RENAME_MCNUMBER_MODAL_TITLE": "",
    "RENAME_VARIABLE_MCNUMBER": "",
    "DELETE_VARIABLE_MCNUMBER": "",
    "SET_MC_VARIABLE": "",
    "TUPLE_ONE": "",
    "TUPLE_TWO": "",
    "ALIYUN": "",
    "ONENET": "",
    "EASYIOT": "",
    "SHANGHAI": "",
    "QINGDAO": "",
    "BEIJING": "",
    "ZHANGJIAKOU": "",
    "HUHEHAOTE": "",
    "HANGZHOU": "",
    "SHENZHEN": "",
    "HONGKONG": "",
    "SINGAPORE": "",
    "SYDNEY": "",
    "KUALALUMPUR": "",
    "JAKARTA": "",
    "MUMBAI": "",
    "TOKYO": "",
    "SILICONVALLEY": "",
    "VIRGINIA": "",
    "FRANKFURT": "",
    "LONDON": "",
    "DUBAI": "",
    "IOT_PLATFORM": "",
    "PARAMS": "",
    "SERVER_ATTR": "",
    "PRODUCTID": "",
    "DEVICEID": "",
    "TINYDB_SECRET": "",
    "TINYDB_USER": "",
    "APIADDR": "",
    "SOFTWARE_SERIAL": "",
    "HARDWARE_SERIAL": "",
    "HARDWARE_SERIAL1": "",
    "HARDWARE_SERIAL2": "",
    "HARDWARE_SERIAL3": ""
  },
  "fi": {
    "CONTROL_FOREVER": "ikuisesti",
    "CONTROL_REPEAT": "toista %1 kertaa",
    "CONTROL_IF": "jos %1, niin",
    "CONTROL_ELSE": "tai muuten",
    "CONTROL_STOP": "pysäytä",
    "CONTROL_STOP_ALL": "kaikki",
    "CONTROL_STOP_THIS": "tämä skripti",
    "CONTROL_STOP_OTHER": "hahmon muut skriptit",
    "CONTROL_WAIT": "odota %1 sekuntia",
    "CONTROL_WAITUNTIL": "odota kunnes %1",
    "CONTROL_REPEATUNTIL": "toista kunnes %1",
    "CONTROL_WHILE": "kun %1",
    "CONTROL_FOREACH": "jokainen %1 joukossa %2",
    "CONTROL_STARTASCLONE": "kun aloitan kloonina",
    "CONTROL_CREATECLONEOF": "luo klooni hahmosta %1",
    "CONTROL_CREATECLONEOF_MYSELF": "minä itse",
    "CONTROL_DELETETHISCLONE": "poista tämä klooni",
    "CONTROL_COUNTER": "laskuri",
    "CONTROL_INCRCOUNTER": "lisäyslaskuri",
    "CONTROL_CLEARCOUNTER": "nollaa laskuri",
    "CONTROL_ALLATONCE": "kaikki kerralla",
    "DATA_SETVARIABLETO": "aseta %1 arvoon %2",
    "DATA_CHANGEVARIABLEBY": "lisää muuttujaan %1 arvo %2",
    "DATA_SHOWVARIABLE": "näytä muuttuja %1",
    "DATA_HIDEVARIABLE": "piilota muuttuja %1",
    "DATA_ADDTOLIST": "lisää %1 listaan %2",
    "DATA_DELETEOFLIST": "poista %1 listasta %2",
    "DATA_DELETEALLOFLIST": "poista kaikki listasta %1",
    "DATA_INSERTATLIST": "lisää %1 kohtaan %2 listassa %3",
    "DATA_REPLACEITEMOFLIST": "korvaa listan %2 kohdan %1 arvo arvolla %3",
    "DATA_ITEMOFLIST": "listan %2 %1. kohde",
    "DATA_ITEMNUMOFLIST": "kohteen %1 numero listassa %2",
    "DATA_LENGTHOFLIST": "listan %1 pituus",
    "DATA_LISTCONTAINSITEM": "%1 sisältää %2?",
    "DATA_SHOWLIST": "näytä lista %1",
    "DATA_HIDELIST": "piilota lista %1",
    "DATA_INDEX_ALL": "kaikki",
    "DATA_INDEX_LAST": "viimeinen",
    "DATA_INDEX_RANDOM": "satunnainen",
    "EVENT_WHENFLAGCLICKED": "kun klikataan %1",
    "EVENT_WHENTHISSPRITECLICKED": "kun tätä hahmoa klikataan",
    "EVENT_WHENSTAGECLICKED": "kun esiintymislavaa klikataan",
    "EVENT_WHENTOUCHINGOBJECT": "kun tämä hahmo koskettaa %1",
    "EVENT_WHENBROADCASTRECEIVED": "kun vastaanotan %1",
    "EVENT_WHENBACKDROPSWITCHESTO": "kun taustaksi vaihtuu %1",
    "EVENT_WHENGREATERTHAN": "kun %1 > %2",
    "EVENT_WHENGREATERTHAN_TIMER": "ajastin",
    "EVENT_WHENGREATERTHAN_LOUDNESS": "äänentaso",
    "EVENT_BROADCAST": "lähetä %1",
    "EVENT_BROADCASTANDWAIT": "lähetä %1 ja odota",
    "EVENT_WHENKEYPRESSED": "kun painetaan %1",
    "EVENT_WHENKEYPRESSED_SPACE": "välilyönti",
    "EVENT_WHENKEYPRESSED_LEFT": "nuoli vasemmalle",
    "EVENT_WHENKEYPRESSED_RIGHT": "nuoli oikealle",
    "EVENT_WHENKEYPRESSED_DOWN": "nuoli alas",
    "EVENT_WHENKEYPRESSED_UP": "nuoli ylös",
    "EVENT_WHENKEYPRESSED_ANY": "mikä tahansa",
    "LOOKS_SAYFORSECS": "sano %1 %2 sekunnin ajan",
    "LOOKS_SAY": "sano %1",
    "LOOKS_HELLO": "Hei!",
    "LOOKS_THINKFORSECS": "ajattele %1 %2 sekunnin ajan",
    "LOOKS_THINK": "ajattele %1",
    "LOOKS_HMM": "Hmm...",
    "LOOKS_SHOW": "näytä",
    "LOOKS_HIDE": "piilota",
    "LOOKS_HIDEALLSPRITES": "piilota kaikki hahmot",
    "LOOKS_EFFECT_COLOR": "väri",
    "LOOKS_EFFECT_FISHEYE": "kalansilmä",
    "LOOKS_EFFECT_WHIRL": "pyörre",
    "LOOKS_EFFECT_PIXELATE": "pikselöi",
    "LOOKS_EFFECT_MOSAIC": "mosaiikki",
    "LOOKS_EFFECT_BRIGHTNESS": "kirkkaus",
    "LOOKS_EFFECT_GHOST": "haamu",
    "LOOKS_CHANGEEFFECTBY": "lisää %1 tehostetta arvolla %2",
    "LOOKS_SETEFFECTTO": "aseta tehoste %1 arvoon %2",
    "LOOKS_CLEARGRAPHICEFFECTS": "poista graafiset tehosteet",
    "LOOKS_CHANGESIZEBY": "kasvata kokoa arvolla %1",
    "LOOKS_SETSIZETO": "aseta koko arvoon %1 %",
    "LOOKS_SIZE": "koko",
    "LOOKS_CHANGESTRETCHBY": "lisää venytystä arvolla %1",
    "LOOKS_SETSTRETCHTO": "aseta venytys arvoon %1 %",
    "LOOKS_SWITCHCOSTUMETO": "vaihda asusteeksi %1",
    "LOOKS_NEXTCOSTUME": "seuraava asuste",
    "LOOKS_SWITCHBACKDROPTO": "vaihda taustaksi %1",
    "LOOKS_GOTOFRONTBACK": "mene %1 alalle",
    "LOOKS_GOTOFRONTBACK_FRONT": "etu",
    "LOOKS_GOTOFRONTBACK_BACK": "taka",
    "LOOKS_GOFORWARDBACKWARDLAYERS": "mene %2 tasoa %1",
    "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "eteenpäin",
    "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "taaksepäin",
    "LOOKS_BACKDROPNUMBERNAME": "tausta %1",
    "LOOKS_COSTUMENUMBERNAME": "asuste %1",
    "LOOKS_NUMBERNAME_NUMBER": "luku",
    "LOOKS_NUMBERNAME_NAME": "nimi",
    "LOOKS_SWITCHBACKDROPTOANDWAIT": "vaihda taustaksi %1 ja odota",
    "LOOKS_NEXTBACKDROP_BLOCK": "seuraava tausta",
    "LOOKS_NEXTBACKDROP": "seuraava tausta",
    "LOOKS_PREVIOUSBACKDROP": "edellinen tausta",
    "LOOKS_RANDOMBACKDROP": "satunnainen tausta",
    "MOTION_MOVESTEPS": "liiku %1 askelta",
    "MOTION_TURNLEFT": "käänny %1 %2 astetta",
    "MOTION_TURNRIGHT": "käänny %1 %2 astetta",
    "MOTION_POINTINDIRECTION": "osoita suuntaan %1",
    "MOTION_POINTTOWARDS": "osoita kohti %1",
    "MOTION_POINTTOWARDS_POINTER": "hiiren osoitin",
    "MOTION_POINTTOWARDS_RANDOM": "satunnainen suunta",
    "MOTION_GOTO": "mene sijaintiin %1",
    "MOTION_GOTO_POINTER": "hiiren osoitin",
    "MOTION_GOTO_RANDOM": "satunnainen sijainti",
    "MOTION_GOTOXY": "mene sijaintiin x: %1 y: %2",
    "MOTION_GLIDESECSTOXY": "liu'u %1 sekuntia sijaintiin x: %2 y: %3",
    "MOTION_GLIDETO": "liu'u %1 sekuntia sijaintiin %2",
    "MOTION_GLIDETO_POINTER": "hiiren osoitin",
    "MOTION_GLIDETO_RANDOM": "satunnainen sijainti",
    "MOTION_CHANGEXBY": "lisää x:n arvoon %1",
    "MOTION_SETX": "aseta x:n arvoksi %1",
    "MOTION_CHANGEYBY": "lisää y:n arvoon %1",
    "MOTION_SETY": "aseta y:n arvoksi %1",
    "MOTION_IFONEDGEBOUNCE": "pomppaa reunasta",
    "MOTION_SETROTATIONSTYLE": "aseta kiertotyyliksi %1",
    "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "vasen-oikea",
    "MOTION_SETROTATIONSTYLE_DONTROTATE": "älä kierrä",
    "MOTION_SETROTATIONSTYLE_ALLAROUND": "joka suuntaan",
    "MOTION_XPOSITION": "x-sijainti",
    "MOTION_YPOSITION": "y-sijainti",
    "MOTION_DIRECTION": "suunta",
    "MOTION_SCROLLRIGHT": "kierrä oikealle %1",
    "MOTION_SCROLLUP": "kierrä ylös %1",
    "MOTION_ALIGNSCENE": "tasaa näkymä %1",
    "MOTION_ALIGNSCENE_BOTTOMLEFT": "alavasen",
    "MOTION_ALIGNSCENE_BOTTOMRIGHT": "alaoikea",
    "MOTION_ALIGNSCENE_MIDDLE": "keskikohta",
    "MOTION_ALIGNSCENE_TOPLEFT": "ylävasen",
    "MOTION_ALIGNSCENE_TOPRIGHT": "yläoikea",
    "MOTION_XSCROLL": "x-kierto",
    "MOTION_YSCROLL": "y-kierto",
    "MOTION_STAGE_SELECTED": "Esiintymislava valittu: ei liikelohkoja",
    "OPERATORS_ADD": "%1 + %2",
    "OPERATORS_SUBTRACT": "%1 - %2",
    "OPERATORS_MULTIPLY": "%1 * %2",
    "OPERATORS_DIVIDE": "%1 / %2",
    "OPERATORS_RANDOM": "valitse satunnaisluku väliltä %1 – %2",
    "OPERATORS_GT": "%1 > %2",
    "OPERATORS_GT_EQUALS": "",
    "OPERATORS_LT": "%1 < %2",
    "OPERATORS_LT_EQUALS": "",
    "OPERATORS_EQUALS": "%1 = %2",
    "OPERATORS_AND": "%1 ja %2",
    "OPERATORS_OR": "%1 tai %2",
    "OPERATORS_NOT": "ei %1",
    "OPERATORS_JOIN": "yhdistä %1 ja %2",
    "OPERATORS_JOIN_APPLE": "omena",
    "OPERATORS_JOIN_BANANA": "banaani",
    "OPERATORS_LETTEROF": "%1. kirjain sanasta %2",
    "OPERATORS_LETTEROF_APPLE": "o",
    "OPERATORS_LENGTH": "listan %1 pituus",
    "OPERATORS_CONTAINS": "%1 sisältää %2?",
    "OPERATORS_MOD": "lukujen %1 ja %2 jakojäännös",
    "OPERATORS_ROUND": "pyöristä %1",
    "OPERATORS_MATHOP": "%1 luvusta %2",
    "OPERATORS_MATHOP_ABS": "itseisarvo",
    "OPERATORS_MATHOP_FLOOR": "pyöristä alaspäin",
    "OPERATORS_MATHOP_CEILING": "pyöristä ylöspäin",
    "OPERATORS_MATHOP_SQRT": "neliöjuuri",
    "OPERATORS_MATHOP_SIN": "sini",
    "OPERATORS_MATHOP_COS": "kosini",
    "OPERATORS_MATHOP_TAN": "tangentti",
    "OPERATORS_MATHOP_ASIN": "asin",
    "OPERATORS_MATHOP_ACOS": "acos",
    "OPERATORS_MATHOP_ATAN": "atan",
    "OPERATORS_MATHOP_LN": "luonnollinen logaritmi",
    "OPERATORS_MATHOP_LOG": "10-kantainen logaritmi",
    "OPERATORS_MATHOP_EEXP": "e ^",
    "OPERATORS_MATHOP_10EXP": "10 ^",
    "PROCEDURES_DEFINITION": "määrittele %1",
    "SENSING_TOUCHINGOBJECT": "koskettaako %1?",
    "SENSING_TOUCHINGOBJECT_POINTER": "hiiren osoitin",
    "SENSING_TOUCHINGOBJECT_EDGE": "reuna",
    "SENSING_TOUCHINGCOLOR": "koskettaako väriä %1?",
    "SENSING_COLORISTOUCHINGCOLOR": "koskeeko väri %1 väriä %2?",
    "SENSING_DISTANCETO": "etäisyys kohteeseen %1",
    "SENSING_DISTANCETO_POINTER": "hiiren osoitin",
    "SENSING_ASKANDWAIT": "kysy %1 ja odota",
    "SENSING_ASK_TEXT": "Mikä on nimesi?",
    "SENSING_ANSWER": "vastaus",
    "SENSING_KEYPRESSED": "onko näppäin %1 painettu?",
    "SENSING_MOUSEDOWN": "onko hiiren nappi painettu?",
    "SENSING_MOUSEX": "hiiren x-sijainti",
    "SENSING_MOUSEY": "hiiren y-sijainti",
    "SENSING_SETDRAGMODE": "aseta raahaustilaksi %1",
    "SENSING_SETDRAGMODE_DRAGGABLE": "raahaus sallittu",
    "SENSING_SETDRAGMODE_NOTDRAGGABLE": "raahaus ei sallittu",
    "SENSING_LOUDNESS": "äänentaso",
    "SENSING_LOUD": "kova äänentaso?",
    "SENSING_TIMER": "ajastin",
    "SENSING_RESETTIMER": "nollaa ajastin",
    "SENSING_OF": "kohteen %2 %1",
    "SENSING_OF_XPOSITION": "x-sijainti",
    "SENSING_OF_YPOSITION": "y-sijainti",
    "SENSING_OF_DIRECTION": "suunta",
    "SENSING_OF_COSTUMENUMBER": "asusteen numero",
    "SENSING_OF_COSTUMENAME": "asusteen nimi",
    "SENSING_OF_SIZE": "koko",
    "SENSING_OF_VOLUME": "äänenvoimakkuus",
    "SENSING_OF_BACKDROPNUMBER": "taustan numero",
    "SENSING_OF_BACKDROPNAME": "taustan nimi",
    "SENSING_OF_STAGE": "Esiintymislava",
    "SENSING_CURRENT": "nykyinen %1",
    "SENSING_CURRENT_YEAR": "vuosi",
    "SENSING_CURRENT_MONTH": "kuukausi",
    "SENSING_CURRENT_DATE": "päiväys",
    "SENSING_CURRENT_DAYOFWEEK": "viikonpäivä",
    "SENSING_CURRENT_HOUR": "tunti",
    "SENSING_CURRENT_MINUTE": "minuutti",
    "SENSING_CURRENT_SECOND": "sekunti",
    "SENSING_DAYSSINCE2000": "päiviä vuoden 2000 jälkeen",
    "SENSING_USERNAME": "käyttäjänimi",
    "SENSING_USERID": "käyttäjä-id",
    "SOUND_PLAY": "soita ääni %1",
    "SOUND_PLAYUNTILDONE": "soita ääni %1 loppuun",
    "SOUND_STOPALLSOUNDS": "pysäytä kaikki äänet",
    "SOUND_SETEFFECTO": "aseta tehoste %1 arvoon %2",
    "SOUND_CHANGEEFFECTBY": " lisää tehosteeseen %1 arvo %2",
    "SOUND_CLEAREFFECTS": "poista ääniefektit",
    "SOUND_EFFECTS_PITCH": "sävelkorkeus",
    "SOUND_EFFECTS_PAN": "panoroi vasen/oikea",
    "SOUND_CHANGEVOLUMEBY": "lisää äänenvoimakkuutta arvolla %1",
    "SOUND_SETVOLUMETO": "aseta äänenvoimakkuudeksi %1%",
    "SOUND_VOLUME": "äänenvoimakkuus",
    "SOUND_RECORD": "nauhoita...",
    "CATEGORY_MOTION": "Liike",
    "CATEGORY_LOOKS": "Ulkonäkö",
    "CATEGORY_SOUND": "Ääni",
    "CATEGORY_EVENTS": "Tapahtumat",
    "CATEGORY_CONTROL": "Ohjaus",
    "CATEGORY_SENSING": "Tuntoaisti",
    "CATEGORY_OPERATORS": "Toiminnot",
    "CATEGORY_VARIABLES": "Muuttujat",
    "CATEGORY_MYBLOCKS": "Lohkoni",
    "CATEGORY_LISTS": "",
    "CATEGORY_TUPLE": "",
    "CATEGORY_DICTIONARYS": "",
    "CATEGORY_NUMBERS": "",
    "CATEGORY_TEXT": "",
    "DUPLICATE": "Kopioi",
    "DELETE": "Poista",
    "ADD_COMMENT": "Lisää kommentti",
    "REMOVE_COMMENT": "Poista kommentti",
    "DELETE_BLOCK": "Poista lohko",
    "DELETE_X_BLOCKS": "Poista %1 lohkoa",
    "DELETE_ALL_BLOCKS": "Poistetaanko kaikki %1 lohkoa?",
    "CLEAN_UP": "Siivoa lohkot",
    "HELP": "Apua",
    "UNDO": "Kumoa",
    "REDO": "Tee uudelleen",
    "EDIT_PROCEDURE": "Muokkaa",
    "SHOW_PROCEDURE_DEFINITION": "Siirry määrittelyyn",
    "WORKSPACE_COMMENT_DEFAULT_TEXT": "Sano jotakin...",
    "COLOUR_HUE_LABEL": "Väri",
    "COLOUR_SATURATION_LABEL": "Värikylläisyys",
    "COLOUR_BRIGHTNESS_LABEL": "Kirkkaus",
    "CHANGE_VALUE_TITLE": "Vaihda arvo:",
    "RENAME_VARIABLE": "Nimeä uudelleen muuttuja",
    "RENAME_VARIABLE_STRING": "",
    "RENAME_VARIABLE_NUMBER": "",
    "RENAME_VARIABLE_LIST": "",
    "RENAME_VARIABLE_TITLE": "Nimeä uudelleen kaikki \"%1\" muuttujaa:",
    "RENAME_VARIABLE_MODAL_TITLE": "Nimeä uudelleen muuttuja",
    "NEW_VARIABLE": "Tee muuttuja",
    "NEW_VARIABLE_TITLE": "Uuden muuttujan nimi:",
    "VARIABLE_MODAL_TITLE": "Uusi muuttuja",
    "VARIABLE_ALREADY_EXISTS": "\"%1\"-niminen muuttuja on jo käytössä.",
    "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "\"%1\"-niminen muuttuja on jo käytössä toisella \"%2\"-tyyppisellä muuttujalla.",
    "DELETE_VARIABLE_CONFIRMATION": "Poistetaanko \"%2\"-muuttujan %1 käyttökohdetta?",
    "CANNOT_DELETE_VARIABLE_PROCEDURE": "Ei voitu poistaa \"%1\"-muuttujaa, koska se on osa \"%2\"-funktion määrittelyä",
    "DELETE_VARIABLE": "Poista \"%1\"-muuttuja",
    "DELETE_VARIABLE_STRING": "",
    "DELETE_VARIABLE_NUMBER": "",
    "DELETE_VARIABLE_LIST": "",
    "NEW_PROCEDURE": "Tee lohko",
    "PROCEDURE_ALREADY_EXISTS": "\"%1\"-niminen aliohjelma on jo käytössä.",
    "PROCEDURE_DEFAULT_NAME": "lohkon nimi",
    "NEW_LIST": "Tee lista",
    "NEW_LIST_TITLE": "Uuden listan nimi:",
    "LIST_MODAL_TITLE": "Uusi lista",
    "LIST_ALREADY_EXISTS": "\"%1\"-niminen lista on jo käytössä.",
    "RENAME_LIST_TITLE": "Nimeä uudelleen kaikki \"%1\" listaa:",
    "RENAME_LIST_MODAL_TITLE": "Nimeä uudelleen lista",
    "DEFAULT_LIST_ITEM": "asia",
    "DELETE_LIST": "Poista \"%1\"-lista",
    "RENAME_LIST": "Nimeä uudelleen lista",
    "NEW_BROADCAST_MESSAGE": "Uusi viesti",
    "NEW_BROADCAST_MESSAGE_TITLE": "Uuden viestin nimi:",
    "BROADCAST_MODAL_TITLE": "Uusi viesti",
    "DEFAULT_BROADCAST_MESSAGE_NAME": "viesti1",
    "DELETE_PROCEDURE": "",
    "OK": "",
    "PROMPT": "",
    "STOP": "",
    "NEW_STRING": "",
    "NEW_NUMBER_VARIABLE": "",
    "WIFI": "",
    "USERNAME": "",
    "PWD": "",
    "IOT_SERVICE": "",
    "IOT_ID": "",
    "IOT_PWD": "",
    "IOT_TOPIC": "",
    "MORE_TOPIC": "",
    "OBLOQ_HTTP": "",
    "OBLOQ_IP": "",
    "OBLOQ_PORT": "",
    "ADD_TOPIC": "",
    "DELETE_TOPIC": "",
    "IOT_SERVER": "",
    "CHINA": "",
    "GLOBAL": "",
    "LOW_C": "",
    "LOW_C$": "",
    "LOW_D": "",
    "LOW_D$": "",
    "LOW_E": "",
    "LOW_F": "",
    "LOW_F$": "",
    "LOW_G": "",
    "LOW_G$": "",
    "LOW_A": "",
    "LOW_A$": "",
    "LOW_B": "",
    "MIDDLE_C": "",
    "MIDDLE_C$": "",
    "MIDDLE_D": "",
    "MIDDLE_D$": "",
    "MIDDLE_E": "",
    "MIDDLE_F": "",
    "MIDDLE_F$": "",
    "MIDDLE_G": "",
    "MIDDLE_G$": "",
    "MIDDLE_A": "",
    "MIDDLE_A$": "",
    "MIDDLE_B": "",
    "HIGH_C": "",
    "HIGH_C$": "",
    "HIGH_D": "",
    "HIGH_D$": "",
    "HIGH_E": "",
    "HIGH_F": "",
    "HIGH_F$": "",
    "HIGH_G": "",
    "HIGH_G$": "",
    "HIGH_A": "",
    "HIGH_A$": "",
    "HIGH_B": "",
    "OPERATORS_STRINGTONUMBER": "",
    "INTEGER": "",
    "DECIMAL": "",
    "OPERATORS_NUMTOASCII": "",
    "OPERATORS_STRINGTOASCII": "",
    "OPERATORS_NUMTOSTRING": "",
    "OPERATORS_MAP": "",
    "OPERATORS_CONSTRAIN": "",
    "STEPS_PER_TURN": "",
    "ROTATE_SPEED": "",
    "NEW_AI": "",
    "ASK_AND_PAINT": "",
    "GET_IDENTIFIED_NUM": "",
    "READ_NUM_AND_SAY": "",
    "IMAGE_ADDR": "",
    "IMAGE_PREVIEW": "",
    "IMAGE_OPEN": "",
    "IMAGE_SIZE": "",
    "IMAGE_WIDTH": "",
    "IMAGE_HEIGHT": "",
    "VARIABLE": "",
    "VARIABLE_LIST": "",
    "SET_STRING_VARIABLE": "",
    "STRING_START_WITH": "",
    "DICTIONARY_HEIGHT_FIRST": "",
    "DICTIONARY_HEIGHT_TWO": "",
    "DICTIONARY_HEIGHT_THREE": "",
    "DICTIONARY_HEIGHT_FOUR": "",
    "DATA_MPITEMOFLIST": "",
    "DATA_MPINTERCEPTITEM": "",
    "DATA_MPINSERTATLIST": "",
    "DATA_MPSETVALUEATLIST": "",
    "DATA_MPINSERTATLIST_INSERT": "",
    "DATA_MPINSERTATLIST_CHANGE": "",
    "DATA_MPINSERTATLIST_FOUR": "",
    "DATA_MPINSERTATLIST_FIVE": "",
    "DATA_MPINSERTATLIST_SIX": "",
    "DATA_MPINSERTATLIST_SEVEN": "",
    "DATA_MPINSERTATLIST_EIGHT": "",
    "DATA_MPINSERTATLIST_EIGHT_FALSE": "",
    "DATA_MPINSERTATLIST_EIGHT_TRUE": "",
    "DATA_MPINSERTATLIST_EIGHT_REVERSE": "",
    "OPERATORS_RANDOMA": "",
    "OPERATORS_MODA": "",
    "OPERATORS_ROUNDA": "",
    "OPERATORS_MATHOPA": "",
    "OPERATORS_MATHOP_ABSA": "",
    "OPERATORS_MATHOP_FLOORA": "",
    "OPERATORS_MATHOP_CEILINGA": "",
    "OPERATORS_MATHOP_SQRTA": "",
    "OPERATORS_MATHOP_FIVE": "",
    "OPERATORS_MATHOP_SIX": "",
    "OPERATORS_MATHOP_SEVEN": "",
    "OPERATORS_MATHOP_LENGHT": "",
    "OPERATORS_MATHOP_MAXMIN": "",
    "OPERATORS_MATHOP_MAXMIN_MAX": "",
    "OPERATORS_MATHOP_MAXMIN_MIN": "",
    "OPERATORS_TEXTS_ONE": "",
    "OPERATORS_TEXTS_TWO": "",
    "OPERATORS_TEXTS_THREE": "",
    "OPERATORS_TEXTS_FOUR": "",
    "OPERATORS_TEXTS_FOUR_NEW": "",
    "OPERATORS_TEXTS_NEW": "",
    "OPERATORS_TEXTS_NEW_OP": "",
    "OPERATORS_TEXTS_FOUR_ONE": "",
    "OPERATORS_TEXTS_FOUR_TWO": "",
    "OPERATORS_TEXTS_FOUR_THREE": "",
    "OPERATORS_TEXTS_FOUR_FOUR": "",
    "OPERATORS_TEXTS_FOUR_F": "",
    "OPERATORS_TEXTS_FOUR_L": "",
    "OPERATORS_STRINGTONUMBERA": "",
    "OPERATORS_NUMTOASCIIA": "",
    "OPERATORS_STRINGTOASCIIA": "",
    "OPERATORS_NUMTOSTRINGA": "",
    "NEW_MC": "",
    "RENAME_MCNUMBER_TITLE": "",
    "RENAME_MCNUMBER_MODAL_TITLE": "",
    "RENAME_VARIABLE_MCNUMBER": "",
    "DELETE_VARIABLE_MCNUMBER": "",
    "SET_MC_VARIABLE": "",
    "TUPLE_ONE": "",
    "TUPLE_TWO": "",
    "ALIYUN": "",
    "ONENET": "",
    "EASYIOT": "",
    "SHANGHAI": "",
    "QINGDAO": "",
    "BEIJING": "",
    "ZHANGJIAKOU": "",
    "HUHEHAOTE": "",
    "HANGZHOU": "",
    "SHENZHEN": "",
    "HONGKONG": "",
    "SINGAPORE": "",
    "SYDNEY": "",
    "KUALALUMPUR": "",
    "JAKARTA": "",
    "MUMBAI": "",
    "TOKYO": "",
    "SILICONVALLEY": "",
    "VIRGINIA": "",
    "FRANKFURT": "",
    "LONDON": "",
    "DUBAI": "",
    "IOT_PLATFORM": "",
    "PARAMS": "",
    "SERVER_ATTR": "",
    "PRODUCTID": "",
    "DEVICEID": "",
    "TINYDB_SECRET": "",
    "TINYDB_USER": "",
    "APIADDR": "",
    "SOFTWARE_SERIAL": "",
    "HARDWARE_SERIAL": "",
    "HARDWARE_SERIAL1": "",
    "HARDWARE_SERIAL2": "",
    "HARDWARE_SERIAL3": ""
  },
  "sv": {
    "CONTROL_FOREVER": "för alltid",
    "CONTROL_REPEAT": "repetera %1",
    "CONTROL_IF": "om %1 då",
    "CONTROL_ELSE": "annars",
    "CONTROL_STOP": "stoppa",
    "CONTROL_STOP_ALL": "alla",
    "CONTROL_STOP_THIS": "detta skript",
    "CONTROL_STOP_OTHER": "andra skript i sprajten",
    "CONTROL_WAIT": "vänta %1 sekunder",
    "CONTROL_WAITUNTIL": "vänta tills %1",
    "CONTROL_REPEATUNTIL": "repetera tills %1",
    "CONTROL_WHILE": "medan %1",
    "CONTROL_FOREACH": "för varje %1 i %2",
    "CONTROL_STARTASCLONE": "när jag startar som klon",
    "CONTROL_CREATECLONEOF": "skapa klon av %1",
    "CONTROL_CREATECLONEOF_MYSELF": "mig själv",
    "CONTROL_DELETETHISCLONE": "radera klonen",
    "CONTROL_COUNTER": "räknare",
    "CONTROL_INCRCOUNTER": "öka räknare",
    "CONTROL_CLEARCOUNTER": "nollställ räknare",
    "CONTROL_ALLATONCE": "alla samtidig",
    "DATA_SETVARIABLETO": "sätt %1 till %2",
    "DATA_CHANGEVARIABLEBY": "ändra %1 med %2",
    "DATA_SHOWVARIABLE": "visa variabel %1",
    "DATA_HIDEVARIABLE": "göm variabel %1",
    "DATA_ADDTOLIST": "lägg till %1 i %2",
    "DATA_DELETEOFLIST": "ta bort %1 från %2",
    "DATA_DELETEALLOFLIST": "ta bort alla %1",
    "DATA_INSERTATLIST": "infoga %1 vid %2 i %3",
    "DATA_REPLACEITEMOFLIST": "ersätt posten %1 i %2 med %3",
    "DATA_ITEMOFLIST": "post %1 i %2",
    "DATA_ITEMNUMOFLIST": "post # av %1 i %2",
    "DATA_LENGTHOFLIST": "längden av %1",
    "DATA_LISTCONTAINSITEM": "%1 innehåller %2",
    "DATA_SHOWLIST": "visa listan %1",
    "DATA_HIDELIST": "göm listan %1",
    "DATA_INDEX_ALL": "alla",
    "DATA_INDEX_LAST": "sista",
    "DATA_INDEX_RANDOM": "slumpmässig",
    "EVENT_WHENFLAGCLICKED": "när %1 klickas på",
    "EVENT_WHENTHISSPRITECLICKED": "när denna sprajt klickas på",
    "EVENT_WHENSTAGECLICKED": "när scenen klickas på",
    "EVENT_WHENTOUCHINGOBJECT": "när denna sprajt rör %1",
    "EVENT_WHENBROADCASTRECEIVED": "när jag tar emot %1",
    "EVENT_WHENBACKDROPSWITCHESTO": "när bakgrunden växlar till %1",
    "EVENT_WHENGREATERTHAN": "när %1 > %2",
    "EVENT_WHENGREATERTHAN_TIMER": "timer",
    "EVENT_WHENGREATERTHAN_LOUDNESS": "ljudstyrka",
    "EVENT_BROADCAST": "skicka %1",
    "EVENT_BROADCASTANDWAIT": "skicka %1 och vänta",
    "EVENT_WHENKEYPRESSED": "när %1 tangenten trycks ned",
    "EVENT_WHENKEYPRESSED_SPACE": "mellanslag",
    "EVENT_WHENKEYPRESSED_LEFT": "vänsterpil",
    "EVENT_WHENKEYPRESSED_RIGHT": "högerpil",
    "EVENT_WHENKEYPRESSED_DOWN": "nedåtpil",
    "EVENT_WHENKEYPRESSED_UP": "uppåtpil",
    "EVENT_WHENKEYPRESSED_ANY": "någon",
    "LOOKS_SAYFORSECS": "säg %1 i %2 sekunder",
    "LOOKS_SAY": "säg %1",
    "LOOKS_HELLO": "Hej!",
    "LOOKS_THINKFORSECS": "tänk %1 i %2 sekunder",
    "LOOKS_THINK": "tänk %1",
    "LOOKS_HMM": "Hmm...",
    "LOOKS_SHOW": "visa",
    "LOOKS_HIDE": "göm",
    "LOOKS_HIDEALLSPRITES": "göm alla sprajtar",
    "LOOKS_EFFECT_COLOR": "färg ",
    "LOOKS_EFFECT_FISHEYE": "fisheye",
    "LOOKS_EFFECT_WHIRL": "virvel",
    "LOOKS_EFFECT_PIXELATE": "pixla",
    "LOOKS_EFFECT_MOSAIC": "mosaik",
    "LOOKS_EFFECT_BRIGHTNESS": "ljusstyrka",
    "LOOKS_EFFECT_GHOST": "genomskinlighet",
    "LOOKS_CHANGEEFFECTBY": "ändra %1 effekten med %2",
    "LOOKS_SETEFFECTTO": "sätt %1 effekten till %2",
    "LOOKS_CLEARGRAPHICEFFECTS": "ta bort grafisk effekt",
    "LOOKS_CHANGESIZEBY": "ändra storlek med %1",
    "LOOKS_SETSIZETO": "sätt storleken till %1 %",
    "LOOKS_SIZE": "storlek",
    "LOOKS_CHANGESTRETCHBY": "ändra töjning med %1",
    "LOOKS_SETSTRETCHTO": "sätt töjning till %1 %",
    "LOOKS_SWITCHCOSTUMETO": "ändra klädsel till %1",
    "LOOKS_NEXTCOSTUME": "nästa klädsel",
    "LOOKS_SWITCHBACKDROPTO": "växla bakgrund till %1",
    "LOOKS_GOTOFRONTBACK": "gå till %1 lagret",
    "LOOKS_GOTOFRONTBACK_FRONT": "översta",
    "LOOKS_GOTOFRONTBACK_BACK": "tillbaka",
    "LOOKS_GOFORWARDBACKWARDLAYERS": "gå %1 %2 lager",
    "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "upp",
    "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "ned",
    "LOOKS_BACKDROPNUMBERNAME": "bakgrund %1",
    "LOOKS_COSTUMENUMBERNAME": "klädsel %1",
    "LOOKS_NUMBERNAME_NUMBER": "nummer",
    "LOOKS_NUMBERNAME_NAME": "namn ",
    "LOOKS_SWITCHBACKDROPTOANDWAIT": "byt bakgrund till %1 och vänta",
    "LOOKS_NEXTBACKDROP_BLOCK": "nästa bakgrund",
    "LOOKS_NEXTBACKDROP": "nästa bakgrund",
    "LOOKS_PREVIOUSBACKDROP": "föregående bakgrund",
    "LOOKS_RANDOMBACKDROP": "slumpvis bakgrund",
    "MOTION_MOVESTEPS": "gå %1 steg",
    "MOTION_TURNLEFT": "rotera %1 %2 grader",
    "MOTION_TURNRIGHT": "rotera %1 %2 grader",
    "MOTION_POINTINDIRECTION": "peka i riktning %1",
    "MOTION_POINTTOWARDS": "peka mot %1",
    "MOTION_POINTTOWARDS_POINTER": "muspekare ",
    "MOTION_POINTTOWARDS_RANDOM": "slumpmässig riktning",
    "MOTION_GOTO": "gå till %1",
    "MOTION_GOTO_POINTER": "muspekare ",
    "MOTION_GOTO_RANDOM": "slumpmässig position",
    "MOTION_GOTOXY": "gå till x: %1 y: %2",
    "MOTION_GLIDESECSTOXY": "glid %1 sek till x: %2 y: %3",
    "MOTION_GLIDETO": "glid %1 sekunder till %2",
    "MOTION_GLIDETO_POINTER": "muspekare ",
    "MOTION_GLIDETO_RANDOM": "slumpmässig position",
    "MOTION_CHANGEXBY": "ändra x med %1",
    "MOTION_SETX": "sätt x till %1",
    "MOTION_CHANGEYBY": "ändra y med %1",
    "MOTION_SETY": "sätt y till %1",
    "MOTION_IFONEDGEBOUNCE": "om vid kanten, studsa",
    "MOTION_SETROTATIONSTYLE": "sätt rotationsstil %1",
    "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "vänster-höger",
    "MOTION_SETROTATIONSTYLE_DONTROTATE": "rotera inte",
    "MOTION_SETROTATIONSTYLE_ALLAROUND": "rotera",
    "MOTION_XPOSITION": "x position",
    "MOTION_YPOSITION": "y position",
    "MOTION_DIRECTION": "riktning",
    "MOTION_SCROLLRIGHT": "skrolla höger %1",
    "MOTION_SCROLLUP": "skrolla upp %1",
    "MOTION_ALIGNSCENE": "arrangera scen %1",
    "MOTION_ALIGNSCENE_BOTTOMLEFT": "nedre-vänster",
    "MOTION_ALIGNSCENE_BOTTOMRIGHT": "nedre-höger",
    "MOTION_ALIGNSCENE_MIDDLE": "mitten",
    "MOTION_ALIGNSCENE_TOPLEFT": "övre-vänster",
    "MOTION_ALIGNSCENE_TOPRIGHT": "övre-höger",
    "MOTION_XSCROLL": "x scroll",
    "MOTION_YSCROLL": "y scroll",
    "MOTION_STAGE_SELECTED": "Scen vald: inga rörelseblock",
    "OPERATORS_ADD": "%1 + %2",
    "OPERATORS_SUBTRACT": "%1 - %2",
    "OPERATORS_MULTIPLY": "%1 * %2",
    "OPERATORS_DIVIDE": "%1 / %2",
    "OPERATORS_RANDOM": "slumptal %1 till %2",
    "OPERATORS_GT": "%1 > %2",
    "OPERATORS_GT_EQUALS": "",
    "OPERATORS_LT": "%1 < %2",
    "OPERATORS_LT_EQUALS": "",
    "OPERATORS_EQUALS": "%1 = %2",
    "OPERATORS_AND": "%1 och %2",
    "OPERATORS_OR": "%1 eller %2",
    "OPERATORS_NOT": "inte %1",
    "OPERATORS_JOIN": "sammanfoga %1 %2",
    "OPERATORS_JOIN_APPLE": "äpple",
    "OPERATORS_JOIN_BANANA": "banan",
    "OPERATORS_LETTEROF": "bokstav %1 av %2",
    "OPERATORS_LETTEROF_APPLE": "ä",
    "OPERATORS_LENGTH": "längden av %1",
    "OPERATORS_CONTAINS": "%1 innehåller %2",
    "OPERATORS_MOD": "%1 mod %2",
    "OPERATORS_ROUND": "avrunda %1",
    "OPERATORS_MATHOP": "%1 av %2",
    "OPERATORS_MATHOP_ABS": "abs",
    "OPERATORS_MATHOP_FLOOR": "golv",
    "OPERATORS_MATHOP_CEILING": "tak",
    "OPERATORS_MATHOP_SQRT": "sqrt",
    "OPERATORS_MATHOP_SIN": "sin",
    "OPERATORS_MATHOP_COS": "cos",
    "OPERATORS_MATHOP_TAN": "tan",
    "OPERATORS_MATHOP_ASIN": "asin",
    "OPERATORS_MATHOP_ACOS": "acos",
    "OPERATORS_MATHOP_ATAN": "atan",
    "OPERATORS_MATHOP_LN": "ln",
    "OPERATORS_MATHOP_LOG": "log",
    "OPERATORS_MATHOP_EEXP": "e ^",
    "OPERATORS_MATHOP_10EXP": "10 ^",
    "PROCEDURES_DEFINITION": "definiera %1",
    "SENSING_TOUCHINGOBJECT": "rör vid %1?",
    "SENSING_TOUCHINGOBJECT_POINTER": "muspekare ",
    "SENSING_TOUCHINGOBJECT_EDGE": "kant",
    "SENSING_TOUCHINGCOLOR": "rör vid färgen %1?",
    "SENSING_COLORISTOUCHINGCOLOR": "rör färgen %1 vid %2?",
    "SENSING_DISTANCETO": "avstånd till %1",
    "SENSING_DISTANCETO_POINTER": "muspekare ",
    "SENSING_ASKANDWAIT": "fråga %1 och vänta",
    "SENSING_ASK_TEXT": "Vad heter du?",
    "SENSING_ANSWER": "svar",
    "SENSING_KEYPRESSED": "tangent %1 nedtryckt?",
    "SENSING_MOUSEDOWN": "musknappen nedtryckt?",
    "SENSING_MOUSEX": "mus x",
    "SENSING_MOUSEY": "mus y",
    "SENSING_SETDRAGMODE": "sätt dragläge %1",
    "SENSING_SETDRAGMODE_DRAGGABLE": "dragbar",
    "SENSING_SETDRAGMODE_NOTDRAGGABLE": "inte dragbar",
    "SENSING_LOUDNESS": "ljudstyrka",
    "SENSING_LOUD": "högt ljud?",
    "SENSING_TIMER": "timer",
    "SENSING_RESETTIMER": "nollställ timer",
    "SENSING_OF": "%1 av %2",
    "SENSING_OF_XPOSITION": "x position",
    "SENSING_OF_YPOSITION": "y position",
    "SENSING_OF_DIRECTION": "riktning",
    "SENSING_OF_COSTUMENUMBER": "klädselnummer",
    "SENSING_OF_COSTUMENAME": "klädselnamn",
    "SENSING_OF_SIZE": "storlek",
    "SENSING_OF_VOLUME": "volym ",
    "SENSING_OF_BACKDROPNUMBER": "bakgrundsnummer",
    "SENSING_OF_BACKDROPNAME": "bakgrundsnamn",
    "SENSING_OF_STAGE": "Scen",
    "SENSING_CURRENT": "aktuell %1",
    "SENSING_CURRENT_YEAR": "år ",
    "SENSING_CURRENT_MONTH": "månad ",
    "SENSING_CURRENT_DATE": "dag ",
    "SENSING_CURRENT_DAYOFWEEK": "veckodag ",
    "SENSING_CURRENT_HOUR": "timmar ",
    "SENSING_CURRENT_MINUTE": "minuter ",
    "SENSING_CURRENT_SECOND": "sekunder ",
    "SENSING_DAYSSINCE2000": "dagar sedan år 2000",
    "SENSING_USERNAME": "användarnamn ",
    "SENSING_USERID": "användarnamn",
    "SOUND_PLAY": "starta ljud %1",
    "SOUND_PLAYUNTILDONE": "spela ljudet %1 tills färdigt",
    "SOUND_STOPALLSOUNDS": "stoppa alla ljud",
    "SOUND_SETEFFECTO": "sätt %1 effekten till %2",
    "SOUND_CHANGEEFFECTBY": "ändra %1 effekten med %2",
    "SOUND_CLEAREFFECTS": "ta bort ljudeffekter",
    "SOUND_EFFECTS_PITCH": "tonhöjd",
    "SOUND_EFFECTS_PAN": "panorera vänster/höger",
    "SOUND_CHANGEVOLUMEBY": "ändra volymen med %1",
    "SOUND_SETVOLUMETO": "sätt volymen till %1%",
    "SOUND_VOLUME": "volym ",
    "SOUND_RECORD": "spela in...",
    "CATEGORY_MOTION": "Rörelse",
    "CATEGORY_LOOKS": "Utseende",
    "CATEGORY_SOUND": "Ljud",
    "CATEGORY_EVENTS": "Händelser",
    "CATEGORY_CONTROL": "Kontroll",
    "CATEGORY_SENSING": "Känna av",
    "CATEGORY_OPERATORS": "Operatorer",
    "CATEGORY_VARIABLES": "Variabler",
    "CATEGORY_MYBLOCKS": "Mina block",
    "CATEGORY_LISTS": "",
    "CATEGORY_TUPLE": "",
    "CATEGORY_DICTIONARYS": "",
    "CATEGORY_NUMBERS": "",
    "CATEGORY_TEXT": "",
    "DUPLICATE": "Kopiera",
    "DELETE": "Radera",
    "ADD_COMMENT": "Lägg till kommentar",
    "REMOVE_COMMENT": "Ta bort kommentar",
    "DELETE_BLOCK": "Ta bort block",
    "DELETE_X_BLOCKS": "Ta bort %1 block",
    "DELETE_ALL_BLOCKS": "Ta bort alla %1 block?",
    "CLEAN_UP": "Rensa block",
    "HELP": "Hjälp",
    "UNDO": "Ångra",
    "REDO": "Gör om",
    "EDIT_PROCEDURE": "Redigera",
    "SHOW_PROCEDURE_DEFINITION": "Gå till definition",
    "WORKSPACE_COMMENT_DEFAULT_TEXT": "Säg något...",
    "COLOUR_HUE_LABEL": "Färg",
    "COLOUR_SATURATION_LABEL": "Mättnad",
    "COLOUR_BRIGHTNESS_LABEL": "Ljusstyrka",
    "CHANGE_VALUE_TITLE": "Ändra värde:",
    "RENAME_VARIABLE": "Döp om variabeln",
    "RENAME_VARIABLE_STRING": "",
    "RENAME_VARIABLE_NUMBER": "",
    "RENAME_VARIABLE_LIST": "",
    "RENAME_VARIABLE_TITLE": "Döp om alla \"%1\" variabler till:",
    "RENAME_VARIABLE_MODAL_TITLE": "Döp om variabel",
    "NEW_VARIABLE": "Skapa en variabel",
    "NEW_VARIABLE_TITLE": "Nytt variabelnamn:",
    "VARIABLE_MODAL_TITLE": "Ny variabel",
    "VARIABLE_ALREADY_EXISTS": "En variabel \"%1\" finns redan.",
    "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "En variabel \"%1\" finns redan för en annan variabel av typ \"%2\".",
    "DELETE_VARIABLE_CONFIRMATION": "Ta bort %1 användningar av variabeln \"%2\"?",
    "CANNOT_DELETE_VARIABLE_PROCEDURE": "Kan inte ta bort variabel \"%1\" för att den är en del av definitionen av funktionen \"%2\"",
    "DELETE_VARIABLE": "Ta bort \"%1\" variabeln",
    "DELETE_VARIABLE_STRING": "",
    "DELETE_VARIABLE_NUMBER": "",
    "DELETE_VARIABLE_LIST": "",
    "NEW_PROCEDURE": "Skapa ett Block",
    "PROCEDURE_ALREADY_EXISTS": "En funktion \"%1\" finns redan.",
    "PROCEDURE_DEFAULT_NAME": "blocknamn",
    "NEW_LIST": "Skapa en lista",
    "NEW_LIST_TITLE": "Nytt listnamn:",
    "LIST_MODAL_TITLE": "Ny lista",
    "LIST_ALREADY_EXISTS": "En lista \"%1\" finns redan.",
    "RENAME_LIST_TITLE": "Döp om alla \"%1\" listor till:",
    "RENAME_LIST_MODAL_TITLE": "Döp om lista",
    "DEFAULT_LIST_ITEM": "grej",
    "DELETE_LIST": "Radera \"%1\" listan",
    "RENAME_LIST": "Döp om listan",
    "NEW_BROADCAST_MESSAGE": "Nytt meddelande",
    "NEW_BROADCAST_MESSAGE_TITLE": "Nytt namn på meddelande:",
    "BROADCAST_MODAL_TITLE": "Nytt meddelande",
    "DEFAULT_BROADCAST_MESSAGE_NAME": "meddelande1",
    "DELETE_PROCEDURE": "",
    "OK": "",
    "PROMPT": "",
    "STOP": "",
    "NEW_STRING": "",
    "NEW_NUMBER_VARIABLE": "",
    "WIFI": "",
    "USERNAME": "",
    "PWD": "",
    "IOT_SERVICE": "",
    "IOT_ID": "",
    "IOT_PWD": "",
    "IOT_TOPIC": "",
    "MORE_TOPIC": "",
    "OBLOQ_HTTP": "",
    "OBLOQ_IP": "",
    "OBLOQ_PORT": "",
    "ADD_TOPIC": "",
    "DELETE_TOPIC": "",
    "IOT_SERVER": "",
    "CHINA": "",
    "GLOBAL": "",
    "LOW_C": "",
    "LOW_C$": "",
    "LOW_D": "",
    "LOW_D$": "",
    "LOW_E": "",
    "LOW_F": "",
    "LOW_F$": "",
    "LOW_G": "",
    "LOW_G$": "",
    "LOW_A": "",
    "LOW_A$": "",
    "LOW_B": "",
    "MIDDLE_C": "",
    "MIDDLE_C$": "",
    "MIDDLE_D": "",
    "MIDDLE_D$": "",
    "MIDDLE_E": "",
    "MIDDLE_F": "",
    "MIDDLE_F$": "",
    "MIDDLE_G": "",
    "MIDDLE_G$": "",
    "MIDDLE_A": "",
    "MIDDLE_A$": "",
    "MIDDLE_B": "",
    "HIGH_C": "",
    "HIGH_C$": "",
    "HIGH_D": "",
    "HIGH_D$": "",
    "HIGH_E": "",
    "HIGH_F": "",
    "HIGH_F$": "",
    "HIGH_G": "",
    "HIGH_G$": "",
    "HIGH_A": "",
    "HIGH_A$": "",
    "HIGH_B": "",
    "OPERATORS_STRINGTONUMBER": "",
    "INTEGER": "",
    "DECIMAL": "",
    "OPERATORS_NUMTOASCII": "",
    "OPERATORS_STRINGTOASCII": "",
    "OPERATORS_NUMTOSTRING": "",
    "OPERATORS_MAP": "",
    "OPERATORS_CONSTRAIN": "",
    "STEPS_PER_TURN": "",
    "ROTATE_SPEED": "",
    "NEW_AI": "",
    "ASK_AND_PAINT": "",
    "GET_IDENTIFIED_NUM": "",
    "READ_NUM_AND_SAY": "",
    "IMAGE_ADDR": "",
    "IMAGE_PREVIEW": "",
    "IMAGE_OPEN": "",
    "IMAGE_SIZE": "",
    "IMAGE_WIDTH": "",
    "IMAGE_HEIGHT": "",
    "VARIABLE": "",
    "VARIABLE_LIST": "",
    "SET_STRING_VARIABLE": "",
    "STRING_START_WITH": "",
    "DICTIONARY_HEIGHT_FIRST": "",
    "DICTIONARY_HEIGHT_TWO": "",
    "DICTIONARY_HEIGHT_THREE": "",
    "DICTIONARY_HEIGHT_FOUR": "",
    "DATA_MPITEMOFLIST": "",
    "DATA_MPINTERCEPTITEM": "",
    "DATA_MPINSERTATLIST": "",
    "DATA_MPSETVALUEATLIST": "",
    "DATA_MPINSERTATLIST_INSERT": "",
    "DATA_MPINSERTATLIST_CHANGE": "",
    "DATA_MPINSERTATLIST_FOUR": "",
    "DATA_MPINSERTATLIST_FIVE": "",
    "DATA_MPINSERTATLIST_SIX": "",
    "DATA_MPINSERTATLIST_SEVEN": "",
    "DATA_MPINSERTATLIST_EIGHT": "",
    "DATA_MPINSERTATLIST_EIGHT_FALSE": "",
    "DATA_MPINSERTATLIST_EIGHT_TRUE": "",
    "DATA_MPINSERTATLIST_EIGHT_REVERSE": "",
    "OPERATORS_RANDOMA": "",
    "OPERATORS_MODA": "",
    "OPERATORS_ROUNDA": "",
    "OPERATORS_MATHOPA": "",
    "OPERATORS_MATHOP_ABSA": "",
    "OPERATORS_MATHOP_FLOORA": "",
    "OPERATORS_MATHOP_CEILINGA": "",
    "OPERATORS_MATHOP_SQRTA": "",
    "OPERATORS_MATHOP_FIVE": "",
    "OPERATORS_MATHOP_SIX": "",
    "OPERATORS_MATHOP_SEVEN": "",
    "OPERATORS_MATHOP_LENGHT": "",
    "OPERATORS_MATHOP_MAXMIN": "",
    "OPERATORS_MATHOP_MAXMIN_MAX": "",
    "OPERATORS_MATHOP_MAXMIN_MIN": "",
    "OPERATORS_TEXTS_ONE": "",
    "OPERATORS_TEXTS_TWO": "",
    "OPERATORS_TEXTS_THREE": "",
    "OPERATORS_TEXTS_FOUR": "",
    "OPERATORS_TEXTS_FOUR_NEW": "",
    "OPERATORS_TEXTS_NEW": "",
    "OPERATORS_TEXTS_NEW_OP": "",
    "OPERATORS_TEXTS_FOUR_ONE": "",
    "OPERATORS_TEXTS_FOUR_TWO": "",
    "OPERATORS_TEXTS_FOUR_THREE": "",
    "OPERATORS_TEXTS_FOUR_FOUR": "",
    "OPERATORS_TEXTS_FOUR_F": "",
    "OPERATORS_TEXTS_FOUR_L": "",
    "OPERATORS_STRINGTONUMBERA": "",
    "OPERATORS_NUMTOASCIIA": "",
    "OPERATORS_STRINGTOASCIIA": "",
    "OPERATORS_NUMTOSTRINGA": "",
    "NEW_MC": "",
    "RENAME_MCNUMBER_TITLE": "",
    "RENAME_MCNUMBER_MODAL_TITLE": "",
    "RENAME_VARIABLE_MCNUMBER": "",
    "DELETE_VARIABLE_MCNUMBER": "",
    "SET_MC_VARIABLE": "",
    "TUPLE_ONE": "",
    "TUPLE_TWO": "",
    "ALIYUN": "",
    "ONENET": "",
    "EASYIOT": "",
    "SHANGHAI": "",
    "QINGDAO": "",
    "BEIJING": "",
    "ZHANGJIAKOU": "",
    "HUHEHAOTE": "",
    "HANGZHOU": "",
    "SHENZHEN": "",
    "HONGKONG": "",
    "SINGAPORE": "",
    "SYDNEY": "",
    "KUALALUMPUR": "",
    "JAKARTA": "",
    "MUMBAI": "",
    "TOKYO": "",
    "SILICONVALLEY": "",
    "VIRGINIA": "",
    "FRANKFURT": "",
    "LONDON": "",
    "DUBAI": "",
    "IOT_PLATFORM": "",
    "PARAMS": "",
    "SERVER_ATTR": "",
    "PRODUCTID": "",
    "DEVICEID": "",
    "TINYDB_SECRET": "",
    "TINYDB_USER": "",
    "APIADDR": "",
    "SOFTWARE_SERIAL": "",
    "HARDWARE_SERIAL": "",
    "HARDWARE_SERIAL1": "",
    "HARDWARE_SERIAL2": "",
    "HARDWARE_SERIAL3": ""
  },
  "vi": {
    "CONTROL_FOREVER": "liên tục",
    "CONTROL_REPEAT": "lặp lại %1",
    "CONTROL_IF": "nếu %1 thì",
    "CONTROL_ELSE": "nếu không thì ",
    "CONTROL_STOP": "dừng lại",
    "CONTROL_STOP_ALL": "tất cả",
    "CONTROL_STOP_THIS": "kịch bản này",
    "CONTROL_STOP_OTHER": "các kịch bản khác cho đối tượng ",
    "CONTROL_WAIT": "đợi %1 giây",
    "CONTROL_WAITUNTIL": "đợi đến khi %1",
    "CONTROL_REPEATUNTIL": "lặp lại cho đến khi %1",
    "CONTROL_WHILE": "trong khi %1",
    "CONTROL_FOREACH": "với mỗi %1 trong %2",
    "CONTROL_STARTASCLONE": "khi tôi bắt đầu là một bản sao",
    "CONTROL_CREATECLONEOF": "tạo bản sao của %1",
    "CONTROL_CREATECLONEOF_MYSELF": "bản thân tôi",
    "CONTROL_DELETETHISCLONE": "xóa bản sao này",
    "CONTROL_COUNTER": "bộ đếm",
    "CONTROL_INCRCOUNTER": "bộ đếm tăng dần",
    "CONTROL_CLEARCOUNTER": "xóa bộ đếm",
    "CONTROL_ALLATONCE": "tất cả cùng một lúc",
    "DATA_SETVARIABLETO": "đặt %1 thành %2",
    "DATA_CHANGEVARIABLEBY": "thay đổi %1 một lượng %2",
    "DATA_SHOWVARIABLE": "hiện biến số %1",
    "DATA_HIDEVARIABLE": "ẩn biến số %1",
    "DATA_ADDTOLIST": "thêm phần tử %1 vào trong %2",
    "DATA_DELETEOFLIST": "xóa phần tử thứ %1 của %2",
    "DATA_DELETEALLOFLIST": "Xóa hết tất cả trong liệt kê %1",
    "DATA_INSERTATLIST": "thêm phần tử %1 tại vị trí %2 của %3",
    "DATA_REPLACEITEMOFLIST": "thay thế phần tử thứ %1 của danh sách %2 bằng %3",
    "DATA_ITEMOFLIST": "phần tử thứ %1 của %2",
    "DATA_ITEMNUMOFLIST": "phần tử thứ %1 trong tợp hợp %2",
    "DATA_LENGTHOFLIST": "kích thước của %1",
    "DATA_LISTCONTAINSITEM": "%1 chứa phần tử %2?",
    "DATA_SHOWLIST": "hiện danh sách %1",
    "DATA_HIDELIST": "ăn danh sách %1",
    "DATA_INDEX_ALL": "tất cả",
    "DATA_INDEX_LAST": "cuối cùng",
    "DATA_INDEX_RANDOM": "ngẫu nhiên",
    "EVENT_WHENFLAGCLICKED": "Khi bấm vào %1",
    "EVENT_WHENTHISSPRITECLICKED": "Khi bấm vào đối tượng này",
    "EVENT_WHENSTAGECLICKED": "khi bấm vào phông nền",
    "EVENT_WHENTOUCHINGOBJECT": "Khi đối tượng này chạm vào %1",
    "EVENT_WHENBROADCASTRECEIVED": "khi nhận %1",
    "EVENT_WHENBACKDROPSWITCHESTO": "khi phông nền chuyển thành %1",
    "EVENT_WHENGREATERTHAN": "khi %1 > %2",
    "EVENT_WHENGREATERTHAN_TIMER": "đồng hồ bấm giờ",
    "EVENT_WHENGREATERTHAN_LOUDNESS": "độ ồn",
    "EVENT_BROADCAST": "phát tin %1",
    "EVENT_BROADCASTANDWAIT": "phát tin %1 và đợi",
    "EVENT_WHENKEYPRESSED": "khi bấm phím %1",
    "EVENT_WHENKEYPRESSED_SPACE": "phím trắng",
    "EVENT_WHENKEYPRESSED_LEFT": "mũi tên trái",
    "EVENT_WHENKEYPRESSED_RIGHT": "mũi tên phải",
    "EVENT_WHENKEYPRESSED_DOWN": "mũi tên xuống",
    "EVENT_WHENKEYPRESSED_UP": "mũi tên lên",
    "EVENT_WHENKEYPRESSED_ANY": "bất kỳ",
    "LOOKS_SAYFORSECS": "nói %1 trong %2 giây",
    "LOOKS_SAY": "nói %1",
    "LOOKS_HELLO": "Xin chào!",
    "LOOKS_THINKFORSECS": "nghĩ %1 trong %2 giây",
    "LOOKS_THINK": "nghĩ %1",
    "LOOKS_HMM": "Hmm...",
    "LOOKS_SHOW": "hiện",
    "LOOKS_HIDE": "ẩn",
    "LOOKS_HIDEALLSPRITES": "ẩn tất cả đối tượng",
    "LOOKS_EFFECT_COLOR": "màu",
    "LOOKS_EFFECT_FISHEYE": "gương cầu",
    "LOOKS_EFFECT_WHIRL": "xoay",
    "LOOKS_EFFECT_PIXELATE": "nhòe",
    "LOOKS_EFFECT_MOSAIC": "khảm",
    "LOOKS_EFFECT_BRIGHTNESS": "độ sáng",
    "LOOKS_EFFECT_GHOST": "bóng ma",
    "LOOKS_CHANGEEFFECTBY": "thay đổi hiệu ứng %1 một lượng %2",
    "LOOKS_SETEFFECTTO": "thay đổi hiệu ứng %1 bằng %2",
    "LOOKS_CLEARGRAPHICEFFECTS": "bỏ các hiệu ứng đồ họa",
    "LOOKS_CHANGESIZEBY": "đổi kích thước một lượng %1",
    "LOOKS_SETSIZETO": "đặt kích thước thành %1 %",
    "LOOKS_SIZE": "kích thước",
    "LOOKS_CHANGESTRETCHBY": "đổi kéo căng một lượng %1",
    "LOOKS_SETSTRETCHTO": "đặt kéo căng thành %1 %",
    "LOOKS_SWITCHCOSTUMETO": "chuyển sang thiết kế %1",
    "LOOKS_NEXTCOSTUME": "trang phục kế tiếp",
    "LOOKS_SWITCHBACKDROPTO": "đổi phông nền thành %1",
    "LOOKS_GOTOFRONTBACK": "đi tới lớp %1",
    "LOOKS_GOTOFRONTBACK_FRONT": "phía trên cùng",
    "LOOKS_GOTOFRONTBACK_BACK": "phía sau cùng",
    "LOOKS_GOFORWARDBACKWARDLAYERS": "đi %1 %2 lớp",
    "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "tới",
    "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "lùi",
    "LOOKS_BACKDROPNUMBERNAME": "phông nền %1",
    "LOOKS_COSTUMENUMBERNAME": "thiết kế %1",
    "LOOKS_NUMBERNAME_NUMBER": "số",
    "LOOKS_NUMBERNAME_NAME": "tên",
    "LOOKS_SWITCHBACKDROPTOANDWAIT": "đổi phông nền thành %1 và đợi",
    "LOOKS_NEXTBACKDROP_BLOCK": "phông nền tiếp theo",
    "LOOKS_NEXTBACKDROP": "phông nền tiếp theo",
    "LOOKS_PREVIOUSBACKDROP": "phông nền trước",
    "LOOKS_RANDOMBACKDROP": "phông nền ngẫu nhiên",
    "MOTION_MOVESTEPS": "di chuyển %1 bước",
    "MOTION_TURNLEFT": "xoay %1 %2 độ",
    "MOTION_TURNRIGHT": "xoay %1 %2 độ",
    "MOTION_POINTINDIRECTION": "hướng về phía %1",
    "MOTION_POINTTOWARDS": "hướng về vị trí %1",
    "MOTION_POINTTOWARDS_POINTER": "con trỏ chuột",
    "MOTION_POINTTOWARDS_RANDOM": "random direction",
    "MOTION_GOTO": "đi tới %1",
    "MOTION_GOTO_POINTER": "con trỏ chuột",
    "MOTION_GOTO_RANDOM": "vị trí ngẫu nhiên",
    "MOTION_GOTOXY": "đi tới điểm x: %1 y: %2",
    "MOTION_GLIDESECSTOXY": "lướt trong %1 giây tới điểm x: %2 y: %3",
    "MOTION_GLIDETO": "lướt trong %1 giây tới %2",
    "MOTION_GLIDETO_POINTER": "con trỏ chuột",
    "MOTION_GLIDETO_RANDOM": "vị trí ngẫu nhiên",
    "MOTION_CHANGEXBY": "thay đổi hoành độ một lượng %1",
    "MOTION_SETX": "đặt hoàng độ bằng %1",
    "MOTION_CHANGEYBY": "thay đổi tung độ một lượng%1",
    "MOTION_SETY": "đặt tung độ bằng %1",
    "MOTION_IFONEDGEBOUNCE": "nếu tiếp xúc với cạnh, bật lại",
    "MOTION_SETROTATIONSTYLE": "đặt kiểu xoay %1",
    "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "trái - phải",
    "MOTION_SETROTATIONSTYLE_DONTROTATE": "không xoay",
    "MOTION_SETROTATIONSTYLE_ALLAROUND": "xung quanh",
    "MOTION_XPOSITION": "hoành độ",
    "MOTION_YPOSITION": "tung độ",
    "MOTION_DIRECTION": "hướng",
    "MOTION_SCROLLRIGHT": "cuộn phải %1",
    "MOTION_SCROLLUP": "cuộn lên %1",
    "MOTION_ALIGNSCENE": "căn chỉnh phân cảnh %1",
    "MOTION_ALIGNSCENE_BOTTOMLEFT": "góc trái dưới",
    "MOTION_ALIGNSCENE_BOTTOMRIGHT": "góc phải dưới",
    "MOTION_ALIGNSCENE_MIDDLE": "giữa",
    "MOTION_ALIGNSCENE_TOPLEFT": "góc trái trên",
    "MOTION_ALIGNSCENE_TOPRIGHT": "góc phải trên",
    "MOTION_XSCROLL": "cuộn x",
    "MOTION_YSCROLL": "cuộn y",
    "MOTION_STAGE_SELECTED": "Đã chọn nền: không có khối lập trình chuyển động",
    "OPERATORS_ADD": "%1 + %2",
    "OPERATORS_SUBTRACT": "%1 - %2",
    "OPERATORS_MULTIPLY": "%1 * %2",
    "OPERATORS_DIVIDE": "%1 / %2",
    "OPERATORS_RANDOM": "lấy ngẫu nhiên từ %1 đến %2",
    "OPERATORS_GT": "%1 > %2",
    "OPERATORS_GT_EQUALS": "",
    "OPERATORS_LT": "%1 < %2",
    "OPERATORS_LT_EQUALS": "",
    "OPERATORS_EQUALS": "%1 = %2",
    "OPERATORS_AND": "%1 và %2",
    "OPERATORS_OR": "%1 hoặc %2",
    "OPERATORS_NOT": "không phải %1",
    "OPERATORS_JOIN": "kết hợp %1 %2",
    "OPERATORS_JOIN_APPLE": "táo",
    "OPERATORS_JOIN_BANANA": "chuối",
    "OPERATORS_LETTEROF": "ký tự thứ %1 của chuỗi %2",
    "OPERATORS_LETTEROF_APPLE": "t",
    "OPERATORS_LENGTH": "độ dài của %1",
    "OPERATORS_CONTAINS": "%1 chứa ký tự %2?",
    "OPERATORS_MOD": "%1 chia lấy dư %2",
    "OPERATORS_ROUND": "làm tròn %1",
    "OPERATORS_MATHOP": "%1 của %2",
    "OPERATORS_MATHOP_ABS": "giá trị tuyệt đối",
    "OPERATORS_MATHOP_FLOOR": "làm tròn xuống",
    "OPERATORS_MATHOP_CEILING": "trần",
    "OPERATORS_MATHOP_SQRT": "căn bậc hai",
    "OPERATORS_MATHOP_SIN": "sin",
    "OPERATORS_MATHOP_COS": "cos",
    "OPERATORS_MATHOP_TAN": "tan",
    "OPERATORS_MATHOP_ASIN": "asin",
    "OPERATORS_MATHOP_ACOS": "acos",
    "OPERATORS_MATHOP_ATAN": "atan",
    "OPERATORS_MATHOP_LN": "trong",
    "OPERATORS_MATHOP_LOG": "log",
    "OPERATORS_MATHOP_EEXP": "e ^",
    "OPERATORS_MATHOP_10EXP": "10 ^",
    "PROCEDURES_DEFINITION": "định nghĩa %1",
    "SENSING_TOUCHINGOBJECT": "đang chạm %1?",
    "SENSING_TOUCHINGOBJECT_POINTER": "con trỏ chuột",
    "SENSING_TOUCHINGOBJECT_EDGE": "cạnh",
    "SENSING_TOUCHINGCOLOR": "đang chạm màu %1?",
    "SENSING_COLORISTOUCHINGCOLOR": "màu %1 đang chạm %2?",
    "SENSING_DISTANCETO": "khoảng cách đến %1",
    "SENSING_DISTANCETO_POINTER": "con trỏ chuột",
    "SENSING_ASKANDWAIT": "hỏi %1 và đợi",
    "SENSING_ASK_TEXT": "Tên của bạn là gì?",
    "SENSING_ANSWER": "trả lời",
    "SENSING_KEYPRESSED": "phím %1 được bấm?",
    "SENSING_MOUSEDOWN": "chuột được nhấn?",
    "SENSING_MOUSEX": "hoành độ con trỏ chuột",
    "SENSING_MOUSEY": "tung độ con trỏ chuột",
    "SENSING_SETDRAGMODE": "đặt chế độ kéo %1",
    "SENSING_SETDRAGMODE_DRAGGABLE": "kéo thả được",
    "SENSING_SETDRAGMODE_NOTDRAGGABLE": "không kéo thả được",
    "SENSING_LOUDNESS": "độ ồn",
    "SENSING_LOUD": "tiếng to?",
    "SENSING_TIMER": "đồng hồ bấm giờ",
    "SENSING_RESETTIMER": "đặt lại đống hồ bấm giờ",
    "SENSING_OF": "%1 của %2",
    "SENSING_OF_XPOSITION": "hoành độ",
    "SENSING_OF_YPOSITION": "tung độ",
    "SENSING_OF_DIRECTION": "hướng",
    "SENSING_OF_COSTUMENUMBER": "thiết kế #",
    "SENSING_OF_COSTUMENAME": "tên thiết kế",
    "SENSING_OF_SIZE": "kích thước",
    "SENSING_OF_VOLUME": "âm lượng",
    "SENSING_OF_BACKDROPNUMBER": "phông nền #",
    "SENSING_OF_BACKDROPNAME": "tên phông nền",
    "SENSING_OF_STAGE": "Sân khấu",
    "SENSING_CURRENT": "%1 hiện tại",
    "SENSING_CURRENT_YEAR": "năm",
    "SENSING_CURRENT_MONTH": "tháng",
    "SENSING_CURRENT_DATE": "ngày",
    "SENSING_CURRENT_DAYOFWEEK": "ngày trong tuần",
    "SENSING_CURRENT_HOUR": "giờ",
    "SENSING_CURRENT_MINUTE": "phút",
    "SENSING_CURRENT_SECOND": "giây",
    "SENSING_DAYSSINCE2000": "số ngày từ năm 2000",
    "SENSING_USERNAME": "tên đăng nhập",
    "SENSING_USERID": "id người dùng",
    "SOUND_PLAY": "bắt đầu âm thanh %1",
    "SOUND_PLAYUNTILDONE": "phát âm thanh %1 đến hết",
    "SOUND_STOPALLSOUNDS": "ngừng mọi âm thanh",
    "SOUND_SETEFFECTO": "đặt hiệu ứng %1 thành %2",
    "SOUND_CHANGEEFFECTBY": "thay đổi hiệu ứng %1 một lượng %2",
    "SOUND_CLEAREFFECTS": "xóa hiệu ứng âm thanh",
    "SOUND_EFFECTS_PITCH": "cao độ",
    "SOUND_EFFECTS_PAN": "pan trái/phải",
    "SOUND_CHANGEVOLUMEBY": "thay đổi âm lượng một lượng %1",
    "SOUND_SETVOLUMETO": "đặt âm lượng %1%",
    "SOUND_VOLUME": "âm lượng",
    "SOUND_RECORD": "lưu lại...",
    "CATEGORY_MOTION": "Chuyển động",
    "CATEGORY_LOOKS": "Hiển thị",
    "CATEGORY_SOUND": "Âm thanh",
    "CATEGORY_EVENTS": "Sự kiện",
    "CATEGORY_CONTROL": "Điều khiển",
    "CATEGORY_SENSING": "Cảm biến",
    "CATEGORY_OPERATORS": "Các phép toán",
    "CATEGORY_VARIABLES": "Các biến số",
    "CATEGORY_MYBLOCKS": "Khối của tôi",
    "CATEGORY_LISTS": "",
    "CATEGORY_TUPLE": "",
    "CATEGORY_DICTIONARYS": "",
    "CATEGORY_NUMBERS": "",
    "CATEGORY_TEXT": "",
    "DUPLICATE": "Nhân bản",
    "DELETE": "Xóa",
    "ADD_COMMENT": "Thêm chú thích",
    "REMOVE_COMMENT": "Xóa chú thích",
    "DELETE_BLOCK": "Xóa khối",
    "DELETE_X_BLOCKS": "Xóa %1 khối",
    "DELETE_ALL_BLOCKS": "Xóa toàn bộ %1 khối?",
    "CLEAN_UP": "Xóa hết khối",
    "HELP": "Trợ giúp",
    "UNDO": "Hoàn tác",
    "REDO": "Làm lại ",
    "EDIT_PROCEDURE": "Chỉnh sửa",
    "SHOW_PROCEDURE_DEFINITION": "Tìm định nghĩa",
    "WORKSPACE_COMMENT_DEFAULT_TEXT": "Nói gì đó...",
    "COLOUR_HUE_LABEL": "Màu sắc",
    "COLOUR_SATURATION_LABEL": "Độ bão hòa",
    "COLOUR_BRIGHTNESS_LABEL": "Độ sáng",
    "CHANGE_VALUE_TITLE": "Thay đổi giá trị:",
    "RENAME_VARIABLE": "Đặt tên cho biến",
    "RENAME_VARIABLE_STRING": "",
    "RENAME_VARIABLE_NUMBER": "",
    "RENAME_VARIABLE_LIST": "",
    "RENAME_VARIABLE_TITLE": "Đặt tên tất cả biến \"%1\" thành:",
    "RENAME_VARIABLE_MODAL_TITLE": "Đặt tên biến",
    "NEW_VARIABLE": "Tạo một biến",
    "NEW_VARIABLE_TITLE": "Tên biến mới:",
    "VARIABLE_MODAL_TITLE": "Biến mới",
    "VARIABLE_ALREADY_EXISTS": "Một biến với tên\"%1\" đã tồn tại.",
    "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "Một biến số tên là \"%1\" đã có ở trong một biến số khác tên \"%2\"",
    "DELETE_VARIABLE_CONFIRMATION": "Xóa %1 cách dùng của biến số \"%2\"",
    "CANNOT_DELETE_VARIABLE_PROCEDURE": "Không thể xóa biến số \"%1\" tại vì nó là một phần của chức vụ lập trình \"%2\"",
    "DELETE_VARIABLE": "Xóa biến \"%1\"",
    "DELETE_VARIABLE_STRING": "",
    "DELETE_VARIABLE_NUMBER": "",
    "DELETE_VARIABLE_LIST": "",
    "NEW_PROCEDURE": "Tạo một khối",
    "PROCEDURE_ALREADY_EXISTS": "Một thủ tục có tên \"%1\" đã tồn tại.",
    "PROCEDURE_DEFAULT_NAME": "Tên khối",
    "NEW_LIST": "Tạo một Danh sách",
    "NEW_LIST_TITLE": "Tên danh sách mới:",
    "LIST_MODAL_TITLE": "Danh sách mới",
    "LIST_ALREADY_EXISTS": "Một danh sách có tên \"%1\" đã tồn tại.",
    "RENAME_LIST_TITLE": "Đặt tên tất cả danh sách \"%1\" thành:",
    "RENAME_LIST_MODAL_TITLE": "Đặt tên danh sách",
    "DEFAULT_LIST_ITEM": "cụm",
    "DELETE_LIST": "Delete the \"%1\" list",
    "RENAME_LIST": "Rename list",
    "NEW_BROADCAST_MESSAGE": "Thông báo mới",
    "NEW_BROADCAST_MESSAGE_TITLE": "Tên thông báo mới:",
    "BROADCAST_MODAL_TITLE": "Tin nhắn mới",
    "DEFAULT_BROADCAST_MESSAGE_NAME": "tin nhắn 1",
    "DELETE_PROCEDURE": "",
    "OK": "",
    "PROMPT": "",
    "STOP": "",
    "NEW_STRING": "",
    "NEW_NUMBER_VARIABLE": "",
    "WIFI": "",
    "USERNAME": "",
    "PWD": "",
    "IOT_SERVICE": "",
    "IOT_ID": "",
    "IOT_PWD": "",
    "IOT_TOPIC": "",
    "MORE_TOPIC": "",
    "OBLOQ_HTTP": "",
    "OBLOQ_IP": "",
    "OBLOQ_PORT": "",
    "ADD_TOPIC": "",
    "DELETE_TOPIC": "",
    "IOT_SERVER": "",
    "CHINA": "",
    "GLOBAL": "",
    "LOW_C": "",
    "LOW_C$": "",
    "LOW_D": "",
    "LOW_D$": "",
    "LOW_E": "",
    "LOW_F": "",
    "LOW_F$": "",
    "LOW_G": "",
    "LOW_G$": "",
    "LOW_A": "",
    "LOW_A$": "",
    "LOW_B": "",
    "MIDDLE_C": "",
    "MIDDLE_C$": "",
    "MIDDLE_D": "",
    "MIDDLE_D$": "",
    "MIDDLE_E": "",
    "MIDDLE_F": "",
    "MIDDLE_F$": "",
    "MIDDLE_G": "",
    "MIDDLE_G$": "",
    "MIDDLE_A": "",
    "MIDDLE_A$": "",
    "MIDDLE_B": "",
    "HIGH_C": "",
    "HIGH_C$": "",
    "HIGH_D": "",
    "HIGH_D$": "",
    "HIGH_E": "",
    "HIGH_F": "",
    "HIGH_F$": "",
    "HIGH_G": "",
    "HIGH_G$": "",
    "HIGH_A": "",
    "HIGH_A$": "",
    "HIGH_B": "",
    "OPERATORS_STRINGTONUMBER": "",
    "INTEGER": "",
    "DECIMAL": "",
    "OPERATORS_NUMTOASCII": "",
    "OPERATORS_STRINGTOASCII": "",
    "OPERATORS_NUMTOSTRING": "",
    "OPERATORS_MAP": "",
    "OPERATORS_CONSTRAIN": "",
    "STEPS_PER_TURN": "",
    "ROTATE_SPEED": "",
    "NEW_AI": "",
    "ASK_AND_PAINT": "",
    "GET_IDENTIFIED_NUM": "",
    "READ_NUM_AND_SAY": "",
    "IMAGE_ADDR": "",
    "IMAGE_PREVIEW": "",
    "IMAGE_OPEN": "",
    "IMAGE_SIZE": "",
    "IMAGE_WIDTH": "",
    "IMAGE_HEIGHT": "",
    "VARIABLE": "",
    "VARIABLE_LIST": "",
    "SET_STRING_VARIABLE": "",
    "STRING_START_WITH": "",
    "DICTIONARY_HEIGHT_FIRST": "",
    "DICTIONARY_HEIGHT_TWO": "",
    "DICTIONARY_HEIGHT_THREE": "",
    "DICTIONARY_HEIGHT_FOUR": "",
    "DATA_MPITEMOFLIST": "",
    "DATA_MPINTERCEPTITEM": "",
    "DATA_MPINSERTATLIST": "",
    "DATA_MPSETVALUEATLIST": "",
    "DATA_MPINSERTATLIST_INSERT": "",
    "DATA_MPINSERTATLIST_CHANGE": "",
    "DATA_MPINSERTATLIST_FOUR": "",
    "DATA_MPINSERTATLIST_FIVE": "",
    "DATA_MPINSERTATLIST_SIX": "",
    "DATA_MPINSERTATLIST_SEVEN": "",
    "DATA_MPINSERTATLIST_EIGHT": "",
    "DATA_MPINSERTATLIST_EIGHT_FALSE": "",
    "DATA_MPINSERTATLIST_EIGHT_TRUE": "",
    "DATA_MPINSERTATLIST_EIGHT_REVERSE": "",
    "OPERATORS_RANDOMA": "",
    "OPERATORS_MODA": "",
    "OPERATORS_ROUNDA": "",
    "OPERATORS_MATHOPA": "",
    "OPERATORS_MATHOP_ABSA": "",
    "OPERATORS_MATHOP_FLOORA": "",
    "OPERATORS_MATHOP_CEILINGA": "",
    "OPERATORS_MATHOP_SQRTA": "",
    "OPERATORS_MATHOP_FIVE": "",
    "OPERATORS_MATHOP_SIX": "",
    "OPERATORS_MATHOP_SEVEN": "",
    "OPERATORS_MATHOP_LENGHT": "",
    "OPERATORS_MATHOP_MAXMIN": "",
    "OPERATORS_MATHOP_MAXMIN_MAX": "",
    "OPERATORS_MATHOP_MAXMIN_MIN": "",
    "OPERATORS_TEXTS_ONE": "",
    "OPERATORS_TEXTS_TWO": "",
    "OPERATORS_TEXTS_THREE": "",
    "OPERATORS_TEXTS_FOUR": "",
    "OPERATORS_TEXTS_FOUR_NEW": "",
    "OPERATORS_TEXTS_NEW": "",
    "OPERATORS_TEXTS_NEW_OP": "",
    "OPERATORS_TEXTS_FOUR_ONE": "",
    "OPERATORS_TEXTS_FOUR_TWO": "",
    "OPERATORS_TEXTS_FOUR_THREE": "",
    "OPERATORS_TEXTS_FOUR_FOUR": "",
    "OPERATORS_TEXTS_FOUR_F": "",
    "OPERATORS_TEXTS_FOUR_L": "",
    "OPERATORS_STRINGTONUMBERA": "",
    "OPERATORS_NUMTOASCIIA": "",
    "OPERATORS_STRINGTOASCIIA": "",
    "OPERATORS_NUMTOSTRINGA": "",
    "NEW_MC": "",
    "RENAME_MCNUMBER_TITLE": "",
    "RENAME_MCNUMBER_MODAL_TITLE": "",
    "RENAME_VARIABLE_MCNUMBER": "",
    "DELETE_VARIABLE_MCNUMBER": "",
    "SET_MC_VARIABLE": "",
    "TUPLE_ONE": "",
    "TUPLE_TWO": "",
    "ALIYUN": "",
    "ONENET": "",
    "EASYIOT": "",
    "SHANGHAI": "",
    "QINGDAO": "",
    "BEIJING": "",
    "ZHANGJIAKOU": "",
    "HUHEHAOTE": "",
    "HANGZHOU": "",
    "SHENZHEN": "",
    "HONGKONG": "",
    "SINGAPORE": "",
    "SYDNEY": "",
    "KUALALUMPUR": "",
    "JAKARTA": "",
    "MUMBAI": "",
    "TOKYO": "",
    "SILICONVALLEY": "",
    "VIRGINIA": "",
    "FRANKFURT": "",
    "LONDON": "",
    "DUBAI": "",
    "IOT_PLATFORM": "",
    "PARAMS": "",
    "SERVER_ATTR": "",
    "PRODUCTID": "",
    "DEVICEID": "",
    "TINYDB_SECRET": "",
    "TINYDB_USER": "",
    "APIADDR": "",
    "SOFTWARE_SERIAL": "",
    "HARDWARE_SERIAL": "",
    "HARDWARE_SERIAL1": "",
    "HARDWARE_SERIAL2": "",
    "HARDWARE_SERIAL3": ""
  },
  "tr": {
    "CONTROL_FOREVER": "sürekli tekrarla",
    "CONTROL_LOOP_BREAKCONTINUE": "%1",
    "CONTROL_LOOP_BREAKCONTINUE_BREAK": "break",
    "CONTROL_LOOP_BREAKCONTINUE_CONTINUE": "continue",
    "CONTROL_REPEAT": "%1 defa tekrarla",
    "CONTROL_IF": "eğer %1 ise",
    "CONTROL_ELSE": "değilse",
    "CONTROL_STOP": "durdur",
    "CONTROL_STOP_ALL": "tümü",
    "CONTROL_STOP_THIS": "bu dizi",
    "CONTROL_STOP_OTHER": "Kukladaki diğer diziler",
    "CONTROL_WAIT": "%1 saniye bekle",
    "CONTROL_WAIT_ESP32_MPY": "wait %1 %2",
    "CONTROL_FOR_FROM_SEQUENCE": "use %1 from sequence %2",
    "CONTROL_WAIT_ESP32_MPY_S": "second(s)",
    "CONTROL_WAIT_ESP32_MPY_MS": "millisecond(ms)",
    "CONTROL_WAIT_ESP32_MPY_US": "microsecond(us)",
    "CONTROL_WAITUNTIL": "%1 olana kadar bekle",
    "CONTROL_REPEATUNTIL": "%1 olana kadar tekrarla",
    "CONTROL_WHILE": "%1 olduğu sürece tekrarla",
    "CONTROL_FOREACH": "%2 deki her %1 için",
    "CONTROL_STARTASCLONE": "ikiz olarak başladığımda",
    "CONTROL_CREATECLONEOF": "%1'in ikizini yarat",
    "CONTROL_CREATECLONEOF_MYSELF": "kendim",
    "CONTROL_DELETETHISCLONE": "bu ikizi sil",
    "CONTROL_COUNTER": "sayaç",
    "CONTROL_INCRCOUNTER": "sayacı artır",
    "CONTROL_CLEARCOUNTER": "sayacı sıfırla",
    "CONTROL_ALLATONCE": "tümü tek seferde",
    "DATA_SETVARIABLETO": "%1 i %2 yap",
    "DATA_CHANGEVARIABLEBY": "%1 i %2 kadar değiştir",
    "DATA_SHOWVARIABLE": "%1 değişkenini göster",
    "DATA_HIDEVARIABLE": "%1 değişkenini gizle",
    "DATA_ADDTOLIST": "%1 i %2 ye ekle",
    "DATA_DELETEOFLIST": "%2 nin %1 ini sil",
    "DATA_DELETEALLOFLIST": "%1'in herşeyini sil",
    "DATA_INSERTATLIST": "%1 i %3 ün %2 pozisyonuna ekle",
    "DATA_REPLACEITEMOFLIST": "%2 öğesinin %1 öğesini %3 ile değiştir",
    "DATA_ITEMOFLIST": "%2 nin %1 öğesi",
    "DATA_ITEMNUMOFLIST": "%2 nin %1 öğesi",
    "DATA_LENGTHOFLIST": "%1'in uzunluğu",
    "DATA_LISTCONTAINSITEM": "%1 %2'yi içeriyor mu? ",
    "DATA_SHOWLIST": "%1 listesini göster",
    "DATA_HIDELIST": "%1 listesini gizle",
    "DATA_INDEX_ALL": "tümü",
    "DATA_INDEX_LAST": "son",
    "DATA_INDEX_RANDOM": "rastgele",
    "EVENT_WHENFLAGCLICKED": "%1 tıklandığında",
    "EVENT_WHENTHISSPRITECLICKED": "bu kukla tıklandığında",
    "EVENT_WHENSTAGECLICKED": "sahne tıklandığında",
    "EVENT_WHENTOUCHINGOBJECT": "bu kukla %1 e dokunduğunda",
    "EVENT_WHENBROADCASTRECEIVED": "%1 haberini aldığımda",
    "EVENT_WHENBACKDROPSWITCHESTO": "dekor %1 olarak değiştiğinde",
    "EVENT_WHENGREATERTHAN": "%1 > %2 olduğunda",
    "EVENT_WHENGREATERTHAN_TIMER": "zamanlayıcı",
    "EVENT_WHENGREATERTHAN_LOUDNESS": "ses yüksekliği",
    "EVENT_BROADCAST": "%1 haberini sal",
    "EVENT_BROADCASTANDWAIT": "%1 haberini sal ve bekle",
    "EVENT_WHENKEYPRESSED": "%1 tuşuna basılınca",
    "EVENT_WHENKEYPRESSED_SPACE": "boşluk",
    "EVENT_WHENKEYPRESSED_LEFT": "sol ok",
    "EVENT_WHENKEYPRESSED_RIGHT": "sağ ok",
    "EVENT_WHENKEYPRESSED_DOWN": "aşağı ok",
    "EVENT_WHENKEYPRESSED_UP": "yukarı ok",
    "EVENT_WHENKEYPRESSED_ANY": "herhangi",
    "LOOKS_SAYFORSECS": "%2 saniye boyunca %1 de ",
    "LOOKS_SAY": "%1 de",
    "LOOKS_HELLO": "Merhaba!",
    "LOOKS_THINKFORSECS": "%2 saniye boyunca %1 diye düşün",
    "LOOKS_THINK": "%1 diye düşün",
    "LOOKS_HMM": "Hmm...",
    "LOOKS_SHOW": "göster",
    "LOOKS_HIDE": "gizle",
    "LOOKS_HIDEALLSPRITES": "tüm kuklaları gizle",
    "LOOKS_EFFECT_COLOR": "renk",
    "LOOKS_EFFECT_FISHEYE": "balık gözü",
    "LOOKS_EFFECT_WHIRL": "fırıl fırıl döndür",
    "LOOKS_EFFECT_PIXELATE": "benekleştir",
    "LOOKS_EFFECT_MOSAIC": "mozaik",
    "LOOKS_EFFECT_BRIGHTNESS": "parlaklık",
    "LOOKS_EFFECT_GHOST": "hayalet",
    "LOOKS_CHANGEEFFECTBY": "%1 etkisini %2 değiştir",
    "LOOKS_SETEFFECTTO": "%1 etkisini %2 yap",
    "LOOKS_CLEARGRAPHICEFFECTS": "görsel etkileri temizle",
    "LOOKS_CHANGESIZEBY": "boyutu %1 birim değiştir",
    "LOOKS_SETSIZETO": "boyutu % %1 yap",
    "LOOKS_SIZE": "büyüklük",
    "LOOKS_CHANGESTRETCHBY": "esnekliği %1 kadar değiştir",
    "LOOKS_SETSTRETCHTO": "esnekliği % %1 olarak ayarla",
    "LOOKS_SWITCHCOSTUMETO": "%1 kılığına geç",
    "LOOKS_NEXTCOSTUME": "sonraki kostüm",
    "LOOKS_SWITCHBACKDROPTO": "%1 dekoruna geç",
    "LOOKS_GOTOFRONTBACK": "%1 katmanına git",
    "LOOKS_GOTOFRONTBACK_FRONT": "ön",
    "LOOKS_GOTOFRONTBACK_BACK": "arka",
    "LOOKS_GOFORWARDBACKWARDLAYERS": "%2 katman %1 git",
    "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "ileri",
    "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "geri",
    "LOOKS_BACKDROPNUMBERNAME": "dekor %1",
    "LOOKS_COSTUMENUMBERNAME": "kostüm %1",
    "LOOKS_NUMBERNAME_NUMBER": "sayı",
    "LOOKS_NUMBERNAME_NAME": "isim",
    "LOOKS_SWITCHBACKDROPTOANDWAIT": "%1 dekoruna geç ve bekle",
    "LOOKS_NEXTBACKDROP_BLOCK": "sonraki dekor",
    "LOOKS_NEXTBACKDROP": "sonraki dekor",
    "LOOKS_PREVIOUSBACKDROP": "önceki dekor",
    "LOOKS_RANDOMBACKDROP": "Rastgele dekor",
    "MOTION_MOVESTEPS": "%1 adım git",
    "MOTION_TURNLEFT": "%1 %2 derece dön",
    "MOTION_TURNRIGHT": "%1 %2 derece dön",
    "MOTION_POINTINDIRECTION": "%1 yönüne yönel",
    "MOTION_POINTTOWARDS": "%1 yönüne doğru yönel",
    "MOTION_POINTTOWARDS_POINTER": "fare-imleci",
    "MOTION_POINTTOWARDS_RANDOM": "rastgele yön",
    "MOTION_GOTO": "%1'e git",
    "MOTION_GOTO_POINTER": "fare-imleci",
    "MOTION_GOTO_RANDOM": "rastgele konuma",
    "MOTION_GOTOXY": "x: %1 y: %2 konumuna git",
    "MOTION_GLIDESECSTOXY": "%1 sn.de x: %2 y: %3'a git",
    "MOTION_GLIDETO": "%1 saniyede %2 noktasına git",
    "MOTION_GLIDETO_POINTER": "fare-imleci",
    "MOTION_GLIDETO_RANDOM": "rastgele konum",
    "MOTION_CHANGEXBY": "x konumunu %1 değiştir",
    "MOTION_SETX": "x konumunu %1 yap",
    "MOTION_CHANGEYBY": "y konumunu %1 değiştir",
    "MOTION_SETY": "y konumunu %1 yap",
    "MOTION_IFONEDGEBOUNCE": "kenara geldiyse sektir",
    "MOTION_SETROTATIONSTYLE": "dönüş stilini %1 yap",
    "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "sol-sağ",
    "MOTION_SETROTATIONSTYLE_DONTROTATE": "döndürme",
    "MOTION_SETROTATIONSTYLE_ALLAROUND": "tüm yönlere",
    "MOTION_XPOSITION": "x konumu",
    "MOTION_YPOSITION": "y konumu",
    "MOTION_DIRECTION": "yön",
    "MOTION_SCROLLRIGHT": "sağa %1 kaydır",
    "MOTION_SCROLLUP": "yukarı %1 kaydır",
    "MOTION_ALIGNSCENE": "%1 sahnesini hizala",
    "MOTION_ALIGNSCENE_BOTTOMLEFT": "sol-alt",
    "MOTION_ALIGNSCENE_BOTTOMRIGHT": "sağ-alt",
    "MOTION_ALIGNSCENE_MIDDLE": "orta",
    "MOTION_ALIGNSCENE_TOPLEFT": "sol-üst",
    "MOTION_ALIGNSCENE_TOPRIGHT": "sağ-üst",
    "MOTION_XSCROLL": "x ekseninde kaydır",
    "MOTION_YSCROLL": "y ekseninde kaydır",
    "MOTION_STAGE_SELECTED": "Sahne seçildi: Hareket blokları yok",
    "OPERATORS_ADD": "%1 + %2",
    "OPERATORS_SUBTRACT": "%1 - %2",
    "OPERATORS_MULTIPLY": "%1 * %2",
    "OPERATORS_DIVIDE": "%1 / %2",
    "OPERATORS_RANDOM": "%1 ile %2 arasında rastgele sayı seç",
    "OPERATORS_GT": "%1 > %2",
    "OPERATORS_GT_EQUALS": "%1 >= %2",
    "OPERATORS_LT": "%1 < %2",
    "OPERATORS_LT_EQUALS": "%1 <= %2",
    "OPERATORS_EQUALS": "%1 = %2",
    "OPERATORS_AND": "%1 ve %2",
    "OPERATORS_OR": "%1 veya %2",
    "OPERATORS_NOT": "%1 değil",
    "CONTROL_TRUE_FALSE": "%1",
    "CONTROL_TRUE_FALSE_TRUE": "true",
    "CONTROL_TRUE_FALSE_FALSE": "false",
    "CONTROL_NONE": "None",
    "CONTROL_RETURN": "return %1",
    "OPERATORS_VALUE_IS_TRUE": "value %1 is true?",
    "OPERATORS_VALUE_TYPE": "value %1 type",
    "CONTROL_TRY": "try",
    "CONTROL_EXCEPT": "except",
    "CONTROL_FINALLY": "finally",
    "OPERATORS_VALUE_TYPE_IS": "value %1 type is %2?",
    "OPERATORS_VALUE_TYPE_INT": "int",
    "OPERATORS_VALUE_TYPE_FLOAT": "float",
    "OPERATORS_VALUE_TYPE_BOOL": "bool",
    "OPERATORS_VALUE_TYPE_STR": "str",
    "OPERATORS_VALUE_TYPE_LIST": "list",
    "OPERATORS_VALUE_TYPE_TUPLE": "tuple",
    "OPERATORS_VALUE_TYPE_SET": "set",
    "OPERATORS_VALUE_TYPE_DICT": "dict",
    "OPERATORS_VALUE_TYPE_BYTES": "bytes",
    "OPERATORS_VALUE_TYPE_BYTEARRAY": "bytearray",
    "OPERATORS_VALUE_TYPE_COMPLEX": "complex",
    "OPERATORS_CACULATE_RESULT": "eval %1",
    "OPERATORS_JOIN": "%1 ve %2'i birleştir",
    "OPERATORS_JOIN_APPLE": "elma",
    "OPERATORS_JOIN_BANANA": "muz",
    "OPERATORS_LETTEROF": "%2 in %1. harfi",
    "OPERATORS_LETTEROF_APPLE": "bir",
    "OPERATORS_LENGTH": "%1'in uzunluğu",
    "OPERATORS_CONTAINS": "%1 %2'i içeriyor mu?",
    "OPERATORS_MOD": "%1 mod %2",
    "OPERATORS_ROUND": "%1'i yuvarla",
    "OPERATORS_MATHOP": "%2 nin %1 i",
    "OPERATORS_MATHOP_ABS": "mutlak değer",
    "OPERATORS_MATHOP_FLOOR": "aşağı yuvarla",
    "OPERATORS_MATHOP_CEILING": "yukarı yuvarla",
    "OPERATORS_MATHOP_SQRT": "karekök",
    "OPERATORS_MATHOP_SIN": "sin",
    "OPERATORS_MATHOP_COS": "cos",
    "OPERATORS_MATHOP_TAN": "tan",
    "OPERATORS_MATHOP_ASIN": "asin",
    "OPERATORS_MATHOP_ACOS": "acos",
    "OPERATORS_MATHOP_ATAN": "atan",
    "OPERATORS_MATHOP_LN": "ln",
    "OPERATORS_MATHOP_LOG": "log",
    "OPERATORS_MATHOP_EEXP": "e ^",
    "OPERATORS_MATHOP_10EXP": "10 ^",
    "PROCEDURES_DEFINITION": "%1 tanımla",
    "SENSING_TOUCHINGOBJECT": "%1 değiyor mu?",
    "SENSING_TOUCHINGOBJECT_POINTER": "fare-imlecine",
    "SENSING_TOUCHINGOBJECT_EDGE": "kenara",
    "SENSING_TOUCHINGCOLOR": "%1 rengine dokunuyor mu?",
    "SENSING_COLORISTOUCHINGCOLOR": "%1 rengi %2 rengine değiyor mu?",
    "SENSING_DISTANCETO": "%1'e mesafe",
    "SENSING_DISTANCETO_POINTER": "fare-imleci",
    "SENSING_ASKANDWAIT": "%1 diye sor ve bekle",
    "SENSING_ASK_TEXT": "Adın ne?",
    "SENSING_ANSWER": "cevap",
    "SENSING_KEYPRESSED": "%1 tuşuna basıldı mı?",
    "SENSING_MOUSEDOWN": "fareye basılı mı?",
    "SENSING_MOUSEX": "farenin x'i",
    "SENSING_MOUSEY": "farenin y'si",
    "SENSING_SETDRAGMODE": "sürükleme modunu %1 yap",
    "SENSING_SETDRAGMODE_DRAGGABLE": "sürüklenebilir",
    "SENSING_SETDRAGMODE_NOTDRAGGABLE": "sürüklenmez",
    "SENSING_LOUDNESS": "ses yüksekliği",
    "SENSING_LOUD": "yüksek ses?",
    "SENSING_TIMER": "zamanlayıcı",
    "SENSING_RESETTIMER": "zamanlayıcıyı sıfırla",
    "SENSING_OF": "%2 nin %1 i",
    "SENSING_OF_XPOSITION": "x konumu",
    "SENSING_OF_YPOSITION": "y konumu",
    "SENSING_OF_DIRECTION": "yön",
    "SENSING_OF_COSTUMENUMBER": "kostüm #",
    "SENSING_OF_COSTUMENAME": "kostüm ismi",
    "SENSING_OF_SIZE": "büyüklük",
    "SENSING_OF_VOLUME": "ses düzeyi",
    "SENSING_OF_BACKDROPNUMBER": "dekor #",
    "SENSING_OF_BACKDROPNAME": "dekorun adı",
    "SENSING_OF_STAGE": "Sahne",
    "SENSING_CURRENT": "şimdiki %1",
    "SENSING_CURRENT_YEAR": "yıl",
    "SENSING_CURRENT_MONTH": "ay",
    "SENSING_CURRENT_DATE": "tarih",
    "SENSING_CURRENT_DAYOFWEEK": "haftanın günü",
    "SENSING_CURRENT_HOUR": "saat",
    "SENSING_CURRENT_MINUTE": "dakika",
    "SENSING_CURRENT_SECOND": "saniye",
    "SENSING_DAYSSINCE2000": "2000 yılından beri geçen gün",
    "SENSING_USERNAME": "kullanıcı adı",
    "SENSING_USERID": "kullanıcı kimliği",
    "SOUND_PLAY": "%1 sesini başlat",
    "SOUND_PLAYUNTILDONE": "%1 sesini bitene kadar çal",
    "SOUND_STOPALLSOUNDS": "tüm sesleri durdur",
    "SOUND_SETEFFECTO": "%1 etkisini %2 yap",
    "SOUND_CHANGEEFFECTBY": "%1 etkisini %2 değiştir",
    "SOUND_CLEAREFFECTS": "ses etkilerini temizle",
    "SOUND_EFFECTS_PITCH": "ses perdesi",
    "SOUND_EFFECTS_PAN": "sağa-sola kaydır",
    "SOUND_CHANGEVOLUMEBY": "ses düzeyini %1 değiştir",
    "SOUND_SETVOLUMETO": "ses düzeyini %1% yap",
    "SOUND_VOLUME": "ses düzeyi",
    "SOUND_RECORD": "kaydet...",
    "CATEGORY_MOTION": "Hareket",
    "CATEGORY_LOOKS": "Görünüm",
    "CATEGORY_SOUND": "Ses",
    "CATEGORY_EVENTS": "Olaylar",
    "CATEGORY_CONTROL": "Kontrol",
    "CATEGORY_SENSING": "Algılama",
    "CATEGORY_OPERATORS": "Operatörler",
    "CATEGORY_VARIABLES": "Değişkenler",
    "CATEGORY_MYBLOCKS": "Bloklarım",
    "CATEGORY_NUMBERS": "Sayı",
    "CATEGORY_TEXT": "Metin",
    "DUPLICATE": "Kopyasını çıkart",
    "DELETE": "Sil",
    "ADD_COMMENT": "Yorum Ekle",
    "REMOVE_COMMENT": "Yorumu Sil",
    "DELETE_BLOCK": "Bloğu Sil",
    "DELETE_X_BLOCKS": "%1 Bloklarını Sil",
    "DELETE_ALL_BLOCKS": "Tüm %1 blokları silinsin mi?",
    "CLEAN_UP": "Blokları temizle",
    "HELP": "Yardım",
    "UNDO": "Geri Al",
    "REDO": "İleri Al",
    "EDIT_PROCEDURE": "Düzenle",
    "SHOW_PROCEDURE_DEFINITION": "Tanımlamaya git",
    "WORKSPACE_COMMENT_DEFAULT_TEXT": "Birşey söyle...",
    "COLOUR_HUE_LABEL": "Renk",
    "COLOUR_SATURATION_LABEL": "Doygunluk",
    "COLOUR_BRIGHTNESS_LABEL": "Parlaklık",
    "CHANGE_VALUE_TITLE": "Değeri değiştir:",
    "RENAME_VARIABLE": "Değişkeni yeniden adlandır",
    "RENAME_VARIABLE_STRING": "Metin değişken adını değiştir",
    "RENAME_VARIABLE_NUMBER": "Sayı değişken adını değiştir",
    "RENAME_VARIABLE_LIST": "Liste değişken adını değiştir",
    "RENAME_VARIABLE_TITLE": "Tüm \"%1\" değişkenlerini şununla adlandır:",
    "RENAME_VARIABLE_MODAL_TITLE": "Değişkeni Yeniden Adlandır",
    "NEW_VARIABLE": "Bir Değişken Oluştur",
    "NEW_VARIABLE_TITLE": "Yeni değişken adı:",
    "VARIABLE_MODAL_TITLE": "Yeni Değişken",
    "VARIABLE_ALREADY_EXISTS": "\"%1\" adlı değişken zaten var.",
    "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "\"%1\" adlı değişken \"%2\" tipinde zaten var.",
    "DELETE_VARIABLE_CONFIRMATION": "%2 değişkeninin %1 kullanımı silinsin mi?",
    "CANNOT_DELETE_VARIABLE_PROCEDURE": "\"%1\" değişkenini \"%2\" fonksiyonunun bir parçası olduğundan dolayı silinemez",
    "DELETE_VARIABLE": "\"%1\" değişkenini sil",
    "DELETE_VARIABLE_STRING": "「%1」metin değişkenini sil",
    "DELETE_VARIABLE_NUMBER": "「%1」 değişkenini sil",
    "DELETE_VARIABLE_LIST": "「%1」liste değişkenini sil",
    "NEW_PROCEDURE": "Bir Blok Oluştur",
    "PROCEDURE_ALREADY_EXISTS": "\"%1\" adlı prosedür zaten var.",
    "PROCEDURE_DEFAULT_NAME": "blok adı",
    "NEW_LIST": "Bir Liste Oluştur",
    "NEW_LIST_TITLE": "Yeni liste adı:",
    "LIST_MODAL_TITLE": "Yeni Liste",
    "LIST_ALREADY_EXISTS": "'%1' adlı liste mevcuttur",
    "RENAME_LIST_TITLE": "Tüm \"%1\" listesini şöyle adlandır:",
    "RENAME_LIST_MODAL_TITLE": "Listeyi  Yeniden Adlandır",
    "DEFAULT_LIST_ITEM": "şey",
    "DELETE_LIST": "\"%1\" listesini sil",
    "RENAME_LIST": "Listeyi yeniden adlandır",
    "NEW_BROADCAST_MESSAGE": "Yeni haber",
    "NEW_BROADCAST_MESSAGE_TITLE": "Yeni haber adı:",
    "BROADCAST_MODAL_TITLE": "Yeni Haber",
    "DEFAULT_BROADCAST_MESSAGE_NAME": "haber1",
    "DELETE_PROCEDURE": "Bir blok tanımını silmek için önce tüm blok kullanımlarını kaldırın.",
    "OK": "Tamam",
    "CANCEL": "Cancel",
    "PROMPT": "İleti",
    "STOP": "DURDUR",
    "NEW_STRING": "Dizi değişken yap",
    "NEW_NUMBER_VARIABLE": "Sayısal değişken yap",
    "WIFI": "Wi-Fi",
    "USERNAME": "kullanıcı adı",
    "PWD": "şifre",
    "IOT_SERVICE": "IOT Servisi",
    "IOT_ID": "iot kimliği",
    "IOT_PWD": "iot şifresi",
    "IOT_TOPIC": "Konu_0",
    "MORE_TOPIC": "Konu",
    "OBLOQ_HTTP": "HTTP",
    "OBLOQ_IP": "IP Adresi",
    "OBLOQ_PORT": "Port",
    "ADD_TOPIC": "Konu ekle, en fazla 4 tane",
    "DELETE_TOPIC": "Konu Sil",
    "IOT_SERVER": "Sunucu",
    "CHINA": "China",
    "GLOBAL": "Küresel",
    "LOW_C": "Düşük C/C3",
    "LOW_C$": "Düşük C#/C#3",
    "LOW_D": "Düşük D/D3",
    "LOW_D$": "Düşük D#/D#3",
    "LOW_E": "Düşük E/E3",
    "LOW_F": "Düşük F/F3",
    "LOW_F$": "Düşük F#/F#3",
    "LOW_G": "Düşük G/G3",
    "LOW_G$": "Düşük G#/G#3",
    "LOW_A": "Düşük A/A3",
    "LOW_A$": "Düşük A#/A#3",
    "LOW_B": "Düşük B/B3",
    "MIDDLE_C": "Orta C/C4",
    "MIDDLE_C$": "Orta C#/C#4",
    "MIDDLE_D": "Orta D/D4",
    "MIDDLE_D$": "Orta D#/D#4",
    "MIDDLE_E": "Orta E/E4",
    "MIDDLE_F": "Orta F/F4",
    "MIDDLE_F$": "Orta F#/F#4",
    "MIDDLE_G": "Orta G/G4",
    "MIDDLE_G$": "Orta G#/G#4",
    "MIDDLE_A": "Orta A/A4",
    "MIDDLE_A$": "Orta A#/A#4",
    "MIDDLE_B": "Orta B/B4",
    "HIGH_C": "Yüksek C/C5",
    "HIGH_C$": "Yüksek C#/C#5",
    "HIGH_D": "Yüksek D/D5",
    "HIGH_D$": "Yüksek D#/D#5",
    "HIGH_E": "Yüksek E/E5",
    "HIGH_F": "Yüksek F/F5",
    "HIGH_F$": "Yüksek F#/F#5",
    "HIGH_G": "Yüksek G/G5",
    "HIGH_G$": "Yüksek G#/G#5",
    "HIGH_A": "Yüksek A/A5",
    "HIGH_A$": "Yüksek A#/A#5",
    "HIGH_B": "Yüksek B/B5",
    "OPERATORS_STRINGTONUMBER": "%1 dizisini %2 çevir",
    "INTEGER": "tamsayıya",
    "DECIMAL": "ondalık sayıya",
    "OPERATORS_NUMTOASCII": "%1 sayısını ASCII ye çevir",
    "OPERATORS_STRINGTOASCII": "%1 dizisini ASCII ye çevir",
    "OPERATORS_NUMTOSTRING": "%1 sayısını diziye çevir",
    "OPERATORS_MAP": "%1 değerini [ %2 , %3 ] den [ %4 , %5 ] e eşlemle",
    "OPERATORS_CONSTRAIN": "%1 değerini (min) %2 ve (max) %3 arasına kısıtla",
    "STEPS_PER_TURN": "turda adımlar",
    "ROTATE_SPEED": "devir hızı(d/sn)",
    "NEW_AI": "AI Bloğu Yap",
    "ASK_AND_PAINT": "lütfen bir sayı çiz",
    "GET_IDENTIFIED_NUM": "sayı tanıma",
    "READ_NUM_AND_SAY": "tanınan sayıyı söyle",
    "IMAGE_ADDR": "Görüntü Adresi",
    "IMAGE_PREVIEW": "Görüntü Önizleme",
    "IMAGE_OPEN": "aç",
    "IMAGE_SIZE": "Görüntü Boyutu",
    "IMAGE_WIDTH": "En",
    "IMAGE_HEIGHT": "Yükseklik",
    "VARIABLE": "%1",
    "VARIABLE_LIST": "%1",
    "SET_STRING_VARIABLE": "%1 değişkenini %2 yap",
    "STRING_START_WITH": "%1 dizisi %2 ile başlayan",
    "OPERATORS_RANDOMA": "%1 %2  %3 ve %4 arası rasgele seç %5",
    "OPERATORS_MODA": "%1 %2 %3 mod %4",
    "OPERATORS_ROUNDA": "%1 %2 yuvarla %3",
    "OPERATORS_MATHOPA": "%1 %2 %3 %4",
    "OPERATORS_MATHOP_ABSA": "mutlak değer",
    "OPERATORS_MATHOP_FLOORA": "yukarı yuvarla",
    "OPERATORS_MATHOP_CEILINGA": "aşağı yuvarla",
    "OPERATORS_MATHOP_SQRTA": "karekök",
    "OPERATORS_MATHOP_FIVE": "%1 %2 %3 değerini %4 değiştir",
    "OPERATORS_MATHOP_SIX": "%1 %2  %3 değerini [ %4 , %5 ] den [ %6 , %7 ] ye uygula",
    "OPERATORS_MATHOP_SEVEN": "%1 %2 %3 değerini (min) %4 ile (max) %5 arasına sınırla",
    "OPERATORS_NUM_INPUT": "%1 %2 number %3",
    "OPERATORS_NUMS_CACULATE": "%1 %2 %3",
    "OPERATORS_MATHOP_SQUARE": "**",
    "OPERATORS_MATHOP_LOGIC_OR": "|",
    "OPERATORS_MATHOP_LOGIC_AND": "&",
    "OPERATORS_MATHOP_LOGIC_POWER": "^",
    "OPERATORS_MATHOP_LOGIC_LEFT": "<<",
    "OPERATORS_MATHOP_LOGIC_RIGHT": ">>",
    "OPERATORS_SPECIAL_NUM": "%1 %2 %3",
    "OPERATORS_MATHOP_PI": "π",
    "OPERATORS_MATHOP_LOGARITHM": "e",
    "OPERATORS_MATHOP_FAI": "φ",
    "OPERATORS_MATHOP_SQRT2": "sqrt(2)",
    "OPERATORS_MATHOP_SQRTHALF": "sqrt(½)",
    "OPERATORS_MATHOP_UNLIMITED": "∞",
    "OPERATORS_NUMS_TYPE": "%1 %2 %3 %4?",
    "OPERATORS_MATHOP_ISEVEN": "is even",
    "OPERATORS_MATHOP_ISODD": "is odd",
    "OPERATORS_MATHOP_ISINT": "is int",
    "OPERATORS_MATHOP_ISPOSITIVE": "is positive",
    "OPERATORS_MATHOP_ISNEGATIVE": "is negative",
    "OPERATORS_SAVE2DECIMAL": "%1 %2 %3 save %4 decimal places",
    "OPERATORS_LIST_CACULATE": "%1 %2 %3 %4",
    "OPERATORS_MATHOP_LIST_SUM": "sum of the numbers in the list",
    "OPERATORS_MATHOP_LIST_MIN": "minimum value in list",
    "OPERATORS_MATHOP_LIST_MAX": "maximum value in the list",
    "OPERATORS_MATHOP_LIST_AVERAGE": "average in list",
    "OPERATORS_MATHOP_LIST_NUM": "number of digits in the list",
    "OPERATORS_MATHOP_LIST_MODE": "list mode",
    "OPERATORS_MATHOP_LIST_STDDEVIATION": "standard deviation in the list",
    "OPERATORS_MATHOP_LIST_RANDOM": "random item of list",
    "OPERATORS_QUOTIENT_INT": "%1 %2 %3 ÷ %4 integer part of quotient",
    "OPERATORS_RANDOM_DECIMAL": "%1 %2 random decimal",
    "OPERATORS_BIN_NEGATION": "%1 %2 binary negation %3",
    "OPERATORS_INT_TO_STR": "%1 %2 decimal integer %3 transform into %4 string",
    "OPERATORS_MATHOP_INTTOSTR_BIN": "binary",
    "OPERATORS_MATHOP_INTTOSTR_OCT": "octal",
    "OPERATORS_MATHOP_INTTOSTR_HEX": "hex",
    "OPERATORS_BINSTR_TO_INT": "%1 %2 %3 string %4 transform into decimal integer",
    "OPERATORS_INT_TO_BYTE": "%1 %2 decimal integer %3 transform into %4 byte",
    "OPERATORS_MATHOP_INTTOBYTE_TWO": "2",
    "OPERATORS_MATHOP_INTTOBYTE_FOUR": "4",
    "OPERATORS_INT_TO_ASCII": "%1 %2 int %3 transform into ASCII character",
    "OPERATORS_ASCII_TO_INT": "%1 %2 ASCII character %3 to int",
    "OPERATORS_STR_TO_INT": "%1 %2 string %3 to hex string",
    "OPERATORS_HEXSTR_TO_BIN": "%1 %2 hex string %3 to bin byte",
    "OPERATORS_STR_TO_BASE64": "%1 %2 %3 encodes in base64 format returns a byte object",
    "OPERATORS_BASE64_TO_BYTE": "%1 %2 decode base64 data %3 returns a byte object",
    "OPERATORS_MATHOP_LENGHT": "uzunluk %1",
    "OPERATORS_MATHOP_MAXMIN": "%1 %2",
    "OPERATORS_MATHOP_MAXMIN_MAX": "max",
    "OPERATORS_MATHOP_MAXMIN_MIN": "min",
    "OPERATORS_TEXTS_ONE": "%1 %2  %3 ve %4 birleştir",
    "OPERATORS_TEXTS_TWO": "%1 %2  %3 dizisinin %4 harfi",
    "OPERATORS_TEXTS_THREE": "%1 %2 %3 dizisi %4 içerir mi ?",
    "OPERATORS_TEXTS_FOUR": "%1 %2 %3 dizisinin %5 %4 ile %7 %6 arası harfleri",
    "OPERATORS_TEXTS_FOUR_NEW": "%1 dizisinin %2 %3 harfinden %4 %5 harfine kadar",
    "OPERATORS_TEXTS_NEW": "%1 %2 find %3 in %4  %5 Emerging position",
    "OPERATORS_TEXTS_NEW_OP": "%2 dizisinde %3  %1 bulunduğu yer",
    "OPERATORS_TEXTS_FOUR_ONE": "soldan",
    "OPERATORS_TEXTS_FOUR_TWO": "sağdan",
    "OPERATORS_TEXTS_FOUR_THREE": "soldan",
    "OPERATORS_TEXTS_FOUR_FOUR": "sağdan",
    "OPERATORS_TEXTS_FOUR_F": "ilk",
    "OPERATORS_TEXTS_FOUR_L": "son",
    "OPERATORS_INPUTSTRING": "%1 %2 string %3",
    "OPERATORS_TRANSINTOTEXT": "%1 %2 transform into %3",
    "OPERATORS_TRANSINTOTEXT_ADD": "add",
    "OPERATORS_TRANSINTOTEXT_ADD_ITEM": "item",
    "OPERATORS_FORMATSTRING2F": "%1 %2 format string %3 % %4",
    "OPERATORS_FORMATSTRING3": "%1 %2 format string %3 format %4",
    "OPERATORS_TEXT_ESCAPE": "%1 %2 text escape character %3",
    "BAR_N": "\\n",
    "BAR_NN": "\\n\\r",
    "BAR_R": "\\r",
    "BAR_RN": "\\r\\n",
    "BAR_B": "\\b",
    "BAR_T": "\\t",
    "BAR_BAR": "\\\\",
    "OPERATORS_TEXT_NUMORLETTER": "%1 %2 %3 %4?",
    "OPERATORS_TEXT_NUMORLETTER_NUM": "is number",
    "OPERATORS_TEXT_NUMORLETTER_LETTER": "is letter",
    "OPERATORS_TEXT_LENGTH": "%1 %2 %3 length",
    "OPERATORS_TEXT_ISNONE": "%1 %2 %3 is none?",
    "OPERATORS_FROMTEXT_GETCHAR": "%1 %2 in text %3 %4",
    "OPERATORS_FROMTEXT_GETCHAR_FIRST": "first letter",
    "OPERATORS_FROMTEXT_GETCHAR_LAST": "last letter",
    "OPERATORS_FROMTEXT_GETCHAR_RANDOM": "random letter",
    "OPERATORS_TEXT_UPPLOW": "%1 %2 %3 %4",
    "OPERATORS_TEXT_UPPLOW_UPP": "uppercase",
    "OPERATORS_TEXT_UPPLOW_LOW": "lowercase",
    "OPERATORS_TEXT_SIDES_SPACE": "%1 %2 %3 %4",
    "OPERATORS_TEXT_SIDES_SPACE_BOTH": "clear spaces on both sides",
    "OPERATORS_TEXT_SIDES_SPACE_LEFT": "clear left space",
    "OPERATORS_TEXT_SIDES_SPACE_RIGHT": "clear right space",
    "OPERATORS_TEXT_STRTOBYTE": "%1 %2 turn byte %3",
    "OPERATORS_TEXT_BYTETOSTR": "%1 %2 byte %3 convert to string",
    "OPERATORS_TEXT_DICTTOJSONSTR": "%1 %2 dict %3 transform into json string",
    "OPERATORS_TEXT_PARSEJSONSTR": "%1 %2 parse json string %3 and return object",
    "OPERATORS_STRINGTONUMBERA": "%1 %2 %3 dizisini %4 e çevir",
    "OPERATORS_NUMTOASCIIA": "%1 %2 %3 sayısını ASCII harflere çevir",
    "OPERATORS_STRINGTOASCIIA": "%1 %2 %3 harfini bir ASCII değere çevir",
    "OPERATORS_NUMTOSTRINGA": "%1 %2 %3 sayısını bir diziye çevir",
    "NEW_MC": "micropython değişkeni yap",
    "RENAME_MCNUMBER_TITLE": "adı [%1]olan tüm değişkenleri yeniden adlandır:",
    "RENAME_MCNUMBER_MODAL_TITLE": "değişken adlandır",
    "RENAME_VARIABLE_MCNUMBER": "değişken adlandır",
    "DELETE_VARIABLE_MCNUMBER": "[%1] değişkenini sil",
    "SET_MC_VARIABLE": "%1 değişkenini %2 yap",
    "ALIYUN": "Aliyun",
    "ONENET": "OneNet",
    "ONENETNEW": "OneNet(New)",
    "EASYIOT": "Easy IoT",
    "SHANGHAI": "Shanghai",
    "QINGDAO": "Qingdao",
    "BEIJING": "Beijing",
    "ZHANGJIAKOU": "Zhangjiakou",
    "HUHEHAOTE": "Huhehaote",
    "HANGZHOU": "Hangzhou",
    "SHENZHEN": "Shenzhen",
    "HONGKONG": "Hongkong",
    "SINGAPORE": "Singapore",
    "SYDNEY": "Sydney",
    "KUALALUMPUR": "Luala Lumpur",
    "JAKARTA": "Jakarta",
    "MUMBAI": "Mumbai",
    "TOKYO": "Tokyo",
    "SILICONVALLEY": "Silicon Valley",
    "VIRGINIA": "Virginia",
    "FRANKFURT": "Frankfurt",
    "LONDON": "London",
    "DUBAI": "Dubai",
    "IOT_PLATFORM": "IOT Platformu",
    "PARAMS": "Parametreler",
    "SERVER_ATTR": "Sunucu Adresi",
    "PRODUCTID": "Ürün Kimliği",
    "DEVICEID": "Araç Kimliği",
    "DEVICENAME": "DeviceName",
    "TINYDB_SECRET": "parola (secret)",
    "TINYDB_USER": "kullanıcı (user)",
    "APIADDR": "API Adresi",
    "SOFTWARE_SERIAL": "Yazılım Seri Port",
    "HARDWARE_SERIAL": "Donanım Seri",
    "HARDWARE_SERIAL1": "Donanım Seri6",
    "HARDWARE_SERIAL2": "Donanım Seri7",
    "HARDWARE_SERIAL3": "Donanım Seri8",
    "CHECKTYPE_TIPS": "The data type received by the input box does not match the output type of the building block",
    "HIGHLIGHT_BLOCK": "hight light block",
    "HIGHLIGHT_ALL_CONFLICT_BLOCKS": "highlight all blocks with warnings",
    "SNAPSHOT": "Snapshot (CTRL+G)",
    "pictureaiPath": "please enter the picture path or URL",
    "pictureaiType": "account type",
    "pictureaiAccountDefault": "default account",
    "pictureaiAccountBaidu": "baidu account",
    "pictureaiwebImgAddr": "web image address",
    "MITRIXICON_EDIT": "light up",
    "MITRIXICON_ERASER": "eraser",
    "MITRIXICON_DUSTBIN": "clear",
    "MITRIXICON_REVERSE": "reverse",
    "MITRIXICON_SAVE": "save",
    "MITRIXICON_EXPORT": "export",
    "MITRIXICON_UPLOAD": "upload",
    "ROLL_MOUSE": "Rolling mouse",
    "MOUSE_ZOOM_IN_OUT": "Scroll the mouse to zoom in and out",
    "UPLOAD_CORRECT_JSON_FILE": "Please upload the json file in the correct format",
    "EXPORT_PROMPT": "There are no custom patterns to export. Please click the save button in the lower right corner to save the custom pattern before attempting to export.",
    "HEART": "Heart",
    "HEART_SMALL": "Small heart",
    "HAPPY": "Happy",
    "SMILE": "Smile",
    "SAD": "Sad",
    "CONFUSED": "Confused",
    "ANGRY": "Angry",
    "SLEEP": "Sleep",
    "SURPRISED": "Surprised",
    "SILLY": "Silly",
    "WONDERFUL": "Wonderful",
    "BORED": "Bored",
    "ROCK": "Rock",
    "ROCK_SMALL": "Small rock",
    "PAPER": "Paper",
    "PAPER_SMALL": "Small paper",
    "SCISSORS": "scissors",
    "SCISSORS_SMALL": "Small scissors",
    "DATA_SOLITLIST": "%1 %2 make %3 %4 with delimiter %5",
    "DATA_SOLITLIST_TYPE_LT": "list from text5",
    "DATA_SOLITLIST_TYPE_TL": "text from list",
    "DATA_LENLIST": "%1 %2 %3 is empty?",
    "DATA_LENGTHLIST": "%1 %2 length of %3",
    "DATA_CREATELIST": "%1 %2 create list %3",
    "DATA_CLEARLIST": "%1 %2 clear list %3"
  },
  "uk": {
    "CONTROL_FOREVER": "завжди",
    "CONTROL_REPEAT": "повторити %1",
    "CONTROL_IF": "якщо %1 то",
    "CONTROL_ELSE": "інакше",
    "CONTROL_STOP": "зупинити",
    "CONTROL_STOP_ALL": "все",
    "CONTROL_STOP_THIS": "цей скрипт",
    "CONTROL_STOP_OTHER": "інші скрипти цього спрайту",
    "CONTROL_WAIT": "чекати %1 секунд",
    "CONTROL_WAITUNTIL": "чекати поки %1",
    "CONTROL_REPEATUNTIL": "повторити до %1",
    "CONTROL_WHILE": "поки %1",
    "CONTROL_FOREACH": "для кожного %1 в %2",
    "CONTROL_STARTASCLONE": "коли я починаю як клон",
    "CONTROL_CREATECLONEOF": "створити клон з %1",
    "CONTROL_CREATECLONEOF_MYSELF": "myself",
    "CONTROL_DELETETHISCLONE": "вилучити цей клон",
    "CONTROL_COUNTER": "лічильник",
    "CONTROL_INCRCOUNTER": "збільшити лічильник",
    "CONTROL_CLEARCOUNTER": "очистити лічильник",
    "CONTROL_ALLATONCE": "всі одночасно",
    "DATA_SETVARIABLETO": "надати %1 значення %2",
    "DATA_CHANGEVARIABLEBY": "змінити %1 на %2",
    "DATA_SHOWVARIABLE": "показати змінну %1",
    "DATA_HIDEVARIABLE": "сховати змінну %1",
    "DATA_ADDTOLIST": "додати %1 до %2",
    "DATA_DELETEOFLIST": "вилучити %1 з %2",
    "DATA_DELETEALLOFLIST": "вилучити все з %1",
    "DATA_INSERTATLIST": "вставити %1 на місці %2 списку %3",
    "DATA_REPLACEITEMOFLIST": "замінити елемент %1 в %2 на %3",
    "DATA_ITEMOFLIST": "елемент %1 з %2",
    "DATA_ITEMNUMOFLIST": "елемент # %1 з %2",
    "DATA_LENGTHOFLIST": "довжина %1",
    "DATA_LISTCONTAINSITEM": "%1 містить %2?",
    "DATA_SHOWLIST": "показати список %1",
    "DATA_HIDELIST": "сховати список %1",
    "DATA_INDEX_ALL": "все",
    "DATA_INDEX_LAST": "останні",
    "DATA_INDEX_RANDOM": "випадково",
    "EVENT_WHENFLAGCLICKED": "коли %1 натиснуто",
    "EVENT_WHENTHISSPRITECLICKED": "коли спрайт натиснуто",
    "EVENT_WHENSTAGECLICKED": "коли сцену натиснуто",
    "EVENT_WHENTOUCHINGOBJECT": "коли цей спрайт торкнеться %1",
    "EVENT_WHENBROADCASTRECEIVED": "коли я отримую %1",
    "EVENT_WHENBACKDROPSWITCHESTO": "коли тло змінится на %1",
    "EVENT_WHENGREATERTHAN": "коли %1 > %2",
    "EVENT_WHENGREATERTHAN_TIMER": "таймер",
    "EVENT_WHENGREATERTHAN_LOUDNESS": "гучність",
    "EVENT_BROADCAST": "оповістити %1",
    "EVENT_BROADCASTANDWAIT": "оповістити %1 і чекати",
    "EVENT_WHENKEYPRESSED": "коли клавішу %1 натиснуто ",
    "EVENT_WHENKEYPRESSED_SPACE": "пропуск",
    "EVENT_WHENKEYPRESSED_LEFT": "стрілка ліворуч",
    "EVENT_WHENKEYPRESSED_RIGHT": "стрілка праворуч",
    "EVENT_WHENKEYPRESSED_DOWN": "стрілка вниз",
    "EVENT_WHENKEYPRESSED_UP": "стрілка вгору",
    "EVENT_WHENKEYPRESSED_ANY": "будь-яка",
    "LOOKS_SAYFORSECS": "говорити %1 %2 сек",
    "LOOKS_SAY": "говорити %1",
    "LOOKS_HELLO": "Привіт!",
    "LOOKS_THINKFORSECS": "подумати %1 %2 сек",
    "LOOKS_THINK": "подумати %1",
    "LOOKS_HMM": "Хмм...",
    "LOOKS_SHOW": "показати",
    "LOOKS_HIDE": "сховати",
    "LOOKS_HIDEALLSPRITES": "сховати усі спрайти",
    "LOOKS_EFFECT_COLOR": "колір",
    "LOOKS_EFFECT_FISHEYE": "роздуття",
    "LOOKS_EFFECT_WHIRL": "закручування",
    "LOOKS_EFFECT_PIXELATE": "пікселями",
    "LOOKS_EFFECT_MOSAIC": "мозаїка",
    "LOOKS_EFFECT_BRIGHTNESS": "яскравість",
    "LOOKS_EFFECT_GHOST": "привид",
    "LOOKS_CHANGEEFFECTBY": "змінити ефект %1 на %2",
    "LOOKS_SETEFFECTTO": "встановити ефект %1 в %2",
    "LOOKS_CLEARGRAPHICEFFECTS": "очистити графічні ефекти",
    "LOOKS_CHANGESIZEBY": "змінити розмір на %1",
    "LOOKS_SETSIZETO": "задати розмір %1",
    "LOOKS_SIZE": "розмір",
    "LOOKS_CHANGESTRETCHBY": "змінити розтяг на %1",
    "LOOKS_SETSTRETCHTO": "задати розтяг %1 %",
    "LOOKS_SWITCHCOSTUMETO": "змінити образ на %1",
    "LOOKS_NEXTCOSTUME": "наступний образ",
    "LOOKS_SWITCHBACKDROPTO": "змінити тло на %1",
    "LOOKS_GOTOFRONTBACK": "помістити на %1 план",
    "LOOKS_GOTOFRONTBACK_FRONT": "передній",
    "LOOKS_GOTOFRONTBACK_BACK": "задній",
    "LOOKS_GOFORWARDBACKWARDLAYERS": "перемістити %1 на %2 шар",
    "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "вперед",
    "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "назад",
    "LOOKS_BACKDROPNUMBERNAME": "тло %1",
    "LOOKS_COSTUMENUMBERNAME": "образ %1",
    "LOOKS_NUMBERNAME_NUMBER": "номер",
    "LOOKS_NUMBERNAME_NAME": "назва",
    "LOOKS_SWITCHBACKDROPTOANDWAIT": "змінити тло на %1 та чекати",
    "LOOKS_NEXTBACKDROP_BLOCK": "наступне тло",
    "LOOKS_NEXTBACKDROP": "наступне тло",
    "LOOKS_PREVIOUSBACKDROP": "попереднє тло",
    "LOOKS_RANDOMBACKDROP": "випадкове тло",
    "MOTION_MOVESTEPS": "перемістити на %1 кроків",
    "MOTION_TURNLEFT": "поворот %1 на %2 градусів",
    "MOTION_TURNRIGHT": "поворот %1 на %2 градусів",
    "MOTION_POINTINDIRECTION": "повернути в напрямку %1",
    "MOTION_POINTTOWARDS": "слідувати за %1",
    "MOTION_POINTTOWARDS_POINTER": "вказівник",
    "MOTION_POINTTOWARDS_RANDOM": "випадковий напрям",
    "MOTION_GOTO": "перейти до %1",
    "MOTION_GOTO_POINTER": "вказівник",
    "MOTION_GOTO_RANDOM": "випадкова позиція",
    "MOTION_GOTOXY": "перемістити в x: %1 y: %2",
    "MOTION_GLIDESECSTOXY": "ковзати %1 сек до x: %2 y: %3",
    "MOTION_GLIDETO": "ковзати %1 сек до %2",
    "MOTION_GLIDETO_POINTER": "вказівник",
    "MOTION_GLIDETO_RANDOM": "випадкова позиція",
    "MOTION_CHANGEXBY": "змінити x на %1",
    "MOTION_SETX": "задати x %1",
    "MOTION_CHANGEYBY": "змінити y на %1",
    "MOTION_SETY": "задати y %1",
    "MOTION_IFONEDGEBOUNCE": "якщо на межі, відбити",
    "MOTION_SETROTATIONSTYLE": "стиль обертання %1",
    "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "зліва-направо",
    "MOTION_SETROTATIONSTYLE_DONTROTATE": "не обертати",
    "MOTION_SETROTATIONSTYLE_ALLAROUND": "навколо",
    "MOTION_XPOSITION": "значення x",
    "MOTION_YPOSITION": "значення y",
    "MOTION_DIRECTION": "напрям",
    "MOTION_SCROLLRIGHT": "прокрутити праворуч %1",
    "MOTION_SCROLLUP": "прокрутити вгору %1",
    "MOTION_ALIGNSCENE": "вирівняти сцену %1",
    "MOTION_ALIGNSCENE_BOTTOMLEFT": "внизу-ліворуч",
    "MOTION_ALIGNSCENE_BOTTOMRIGHT": "вниз-праворуч",
    "MOTION_ALIGNSCENE_MIDDLE": "посередині",
    "MOTION_ALIGNSCENE_TOPLEFT": "зверху-ліворуч",
    "MOTION_ALIGNSCENE_TOPRIGHT": "зверху-праворуч",
    "MOTION_XSCROLL": "x прокрутка",
    "MOTION_YSCROLL": "y прокрутка",
    "MOTION_STAGE_SELECTED": "Обрано Сцену: немає блоків руху",
    "OPERATORS_ADD": "%1 + %2",
    "OPERATORS_SUBTRACT": "%1 - %2",
    "OPERATORS_MULTIPLY": "%1 * %2",
    "OPERATORS_DIVIDE": "%1 / %2",
    "OPERATORS_RANDOM": "випадкове від %1 до %2",
    "OPERATORS_GT": "%1 > %2",
    "OPERATORS_GT_EQUALS": "",
    "OPERATORS_LT": "%1 < %2",
    "OPERATORS_LT_EQUALS": "",
    "OPERATORS_EQUALS": "%1 = %2",
    "OPERATORS_AND": "%1 і %2",
    "OPERATORS_OR": "%1 або %2",
    "OPERATORS_NOT": "не %1",
    "OPERATORS_JOIN": "з'єднати %1 %2",
    "OPERATORS_JOIN_APPLE": "яблуко",
    "OPERATORS_JOIN_BANANA": "банан",
    "OPERATORS_LETTEROF": "символ %1 у %2",
    "OPERATORS_LETTEROF_APPLE": "я",
    "OPERATORS_LENGTH": "довжина %1",
    "OPERATORS_CONTAINS": "%1 містить %2?",
    "OPERATORS_MOD": "остача %1 на %2",
    "OPERATORS_ROUND": "округлити %1",
    "OPERATORS_MATHOP": "%1 з %2",
    "OPERATORS_MATHOP_ABS": "abs",
    "OPERATORS_MATHOP_FLOOR": "менше ціле",
    "OPERATORS_MATHOP_CEILING": "більше ціле",
    "OPERATORS_MATHOP_SQRT": "sqrt",
    "OPERATORS_MATHOP_SIN": "sin",
    "OPERATORS_MATHOP_COS": "cos",
    "OPERATORS_MATHOP_TAN": "tan",
    "OPERATORS_MATHOP_ASIN": "asin",
    "OPERATORS_MATHOP_ACOS": "acos",
    "OPERATORS_MATHOP_ATAN": "atan",
    "OPERATORS_MATHOP_LN": "ln",
    "OPERATORS_MATHOP_LOG": "log",
    "OPERATORS_MATHOP_EEXP": "e ^",
    "OPERATORS_MATHOP_10EXP": "10 ^",
    "PROCEDURES_DEFINITION": "визначити %1",
    "SENSING_TOUCHINGOBJECT": "торкається %1?",
    "SENSING_TOUCHINGOBJECT_POINTER": "вказівник",
    "SENSING_TOUCHINGOBJECT_EDGE": "межа",
    "SENSING_TOUCHINGCOLOR": "торкається кольору %1?",
    "SENSING_COLORISTOUCHINGCOLOR": "колір %1 торкається %2?",
    "SENSING_DISTANCETO": "відстань до %1",
    "SENSING_DISTANCETO_POINTER": "вказівник",
    "SENSING_ASKANDWAIT": "запитати %1 і чекати",
    "SENSING_ASK_TEXT": "Як вас звати?",
    "SENSING_ANSWER": "відповідь",
    "SENSING_KEYPRESSED": "клавішу %1 натиснуто?",
    "SENSING_MOUSEDOWN": "мишку натиснуто?",
    "SENSING_MOUSEX": "мишка x",
    "SENSING_MOUSEY": "мишка y",
    "SENSING_SETDRAGMODE": "перетягувати %1",
    "SENSING_SETDRAGMODE_DRAGGABLE": "дозволено",
    "SENSING_SETDRAGMODE_NOTDRAGGABLE": "не дозволено",
    "SENSING_LOUDNESS": "гучність",
    "SENSING_LOUD": "гучно?",
    "SENSING_TIMER": "таймер",
    "SENSING_RESETTIMER": "перезапустити таймер",
    "SENSING_OF": "%1 з %2",
    "SENSING_OF_XPOSITION": "значення x",
    "SENSING_OF_YPOSITION": "значення y",
    "SENSING_OF_DIRECTION": "напрям",
    "SENSING_OF_COSTUMENUMBER": "образ #",
    "SENSING_OF_COSTUMENAME": "ім’я образу",
    "SENSING_OF_SIZE": "розмір",
    "SENSING_OF_VOLUME": "гучність",
    "SENSING_OF_BACKDROPNUMBER": "тло #",
    "SENSING_OF_BACKDROPNAME": "ім’я тла",
    "SENSING_OF_STAGE": "Сцена",
    "SENSING_CURRENT": "сьогодні %1",
    "SENSING_CURRENT_YEAR": "рік",
    "SENSING_CURRENT_MONTH": "місяць",
    "SENSING_CURRENT_DATE": "дата",
    "SENSING_CURRENT_DAYOFWEEK": "день тижня",
    "SENSING_CURRENT_HOUR": "година",
    "SENSING_CURRENT_MINUTE": "хвилина ",
    "SENSING_CURRENT_SECOND": "секунда",
    "SENSING_DAYSSINCE2000": "днів після 2000",
    "SENSING_USERNAME": "користувач",
    "SENSING_USERID": "користувач ID",
    "SOUND_PLAY": "відтворити звук %1",
    "SOUND_PLAYUNTILDONE": "відтворити звук %1 до кінця",
    "SOUND_STOPALLSOUNDS": "зупинити всі звуки",
    "SOUND_SETEFFECTO": "встановити ефект %1 в %2",
    "SOUND_CHANGEEFFECTBY": "змінити ефект %1 на %2",
    "SOUND_CLEAREFFECTS": "очистити звукові ефекти",
    "SOUND_EFFECTS_PITCH": "висота",
    "SOUND_EFFECTS_PAN": "панорама",
    "SOUND_CHANGEVOLUMEBY": "змінити гучність на %1",
    "SOUND_SETVOLUMETO": "встановити гучність %1%",
    "SOUND_VOLUME": "гучність",
    "SOUND_RECORD": "запис...",
    "CATEGORY_MOTION": "Рух",
    "CATEGORY_LOOKS": "Вигляд",
    "CATEGORY_SOUND": "Звук",
    "CATEGORY_EVENTS": "Події",
    "CATEGORY_CONTROL": "Керування",
    "CATEGORY_SENSING": "Датчики",
    "CATEGORY_OPERATORS": "Оператори",
    "CATEGORY_VARIABLES": "Змінні",
    "CATEGORY_MYBLOCKS": "Мої блоки",
    "CATEGORY_LISTS": "",
    "CATEGORY_TUPLE": "",
    "CATEGORY_DICTIONARYS": "",
    "CATEGORY_NUMBERS": "",
    "CATEGORY_TEXT": "",
    "DUPLICATE": "Дублювати",
    "DELETE": "Вилучити",
    "ADD_COMMENT": "Додати коментар",
    "REMOVE_COMMENT": "Вилучити коментар",
    "DELETE_BLOCK": "Вилучити блок",
    "DELETE_X_BLOCKS": "Вилучити %1 блоки",
    "DELETE_ALL_BLOCKS": "Вилучити всі %1 блоки?",
    "CLEAN_UP": "Впорядкувати блоки",
    "HELP": "Допомога",
    "UNDO": "Скасувати",
    "REDO": "Повернути",
    "EDIT_PROCEDURE": "Правка",
    "SHOW_PROCEDURE_DEFINITION": "Перейти до визначення",
    "WORKSPACE_COMMENT_DEFAULT_TEXT": "Скажіть щось...",
    "COLOUR_HUE_LABEL": "Колір",
    "COLOUR_SATURATION_LABEL": "Насиченість",
    "COLOUR_BRIGHTNESS_LABEL": "Яскравість",
    "CHANGE_VALUE_TITLE": "Змінити значення:",
    "RENAME_VARIABLE": "Перейменувати змінну",
    "RENAME_VARIABLE_STRING": "",
    "RENAME_VARIABLE_NUMBER": "",
    "RENAME_VARIABLE_LIST": "",
    "RENAME_VARIABLE_TITLE": "Перейменувати усі \"%1\" змінні на:",
    "RENAME_VARIABLE_MODAL_TITLE": "Перейменувати змінну",
    "NEW_VARIABLE": "Створити змінну",
    "NEW_VARIABLE_TITLE": "Нове ім'я змінної:",
    "VARIABLE_MODAL_TITLE": "Нова змінна",
    "VARIABLE_ALREADY_EXISTS": "Змінна з іменем \"%1\" вже існує.",
    "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "Ім'я змінної \"%1\" вже використано для змінної з типом \"%2\".",
    "DELETE_VARIABLE_CONFIRMATION": "Вилучити %1 використання змінної \"%2\"?",
    "CANNOT_DELETE_VARIABLE_PROCEDURE": "Не можна вилучити змінну \"%1\" оскільки вона визначена у функції \"%2\"",
    "DELETE_VARIABLE": "Вилучити змінну '%1'",
    "DELETE_VARIABLE_STRING": "",
    "DELETE_VARIABLE_NUMBER": "",
    "DELETE_VARIABLE_LIST": "",
    "NEW_PROCEDURE": "Створити блок",
    "PROCEDURE_ALREADY_EXISTS": "Процедура з іменем '%1' вже існує",
    "PROCEDURE_DEFAULT_NAME": "назва блоку",
    "NEW_LIST": "Створити список",
    "NEW_LIST_TITLE": "Нове ім’я списку:",
    "LIST_MODAL_TITLE": "Новий список",
    "LIST_ALREADY_EXISTS": "Список з іменем '%1' вже існує",
    "RENAME_LIST_TITLE": "Перейменувати усі списки \"%1\" на:",
    "RENAME_LIST_MODAL_TITLE": "Перейменувати список",
    "DEFAULT_LIST_ITEM": "щось",
    "DELETE_LIST": "Вилучити список \"%1\"",
    "RENAME_LIST": "Перейменувати список",
    "NEW_BROADCAST_MESSAGE": "Нове повідомлення",
    "NEW_BROADCAST_MESSAGE_TITLE": "Нове ім’я повідомлення:",
    "BROADCAST_MODAL_TITLE": "Нове повідомлення",
    "DEFAULT_BROADCAST_MESSAGE_NAME": "повідомлення1",
    "DELETE_PROCEDURE": "",
    "OK": "",
    "PROMPT": "",
    "STOP": "",
    "NEW_STRING": "",
    "NEW_NUMBER_VARIABLE": "",
    "WIFI": "",
    "USERNAME": "",
    "PWD": "",
    "IOT_SERVICE": "",
    "IOT_ID": "",
    "IOT_PWD": "",
    "IOT_TOPIC": "",
    "MORE_TOPIC": "",
    "OBLOQ_HTTP": "",
    "OBLOQ_IP": "",
    "OBLOQ_PORT": "",
    "ADD_TOPIC": "",
    "DELETE_TOPIC": "",
    "IOT_SERVER": "",
    "CHINA": "",
    "GLOBAL": "",
    "LOW_C": "",
    "LOW_C$": "",
    "LOW_D": "",
    "LOW_D$": "",
    "LOW_E": "",
    "LOW_F": "",
    "LOW_F$": "",
    "LOW_G": "",
    "LOW_G$": "",
    "LOW_A": "",
    "LOW_A$": "",
    "LOW_B": "",
    "MIDDLE_C": "",
    "MIDDLE_C$": "",
    "MIDDLE_D": "",
    "MIDDLE_D$": "",
    "MIDDLE_E": "",
    "MIDDLE_F": "",
    "MIDDLE_F$": "",
    "MIDDLE_G": "",
    "MIDDLE_G$": "",
    "MIDDLE_A": "",
    "MIDDLE_A$": "",
    "MIDDLE_B": "",
    "HIGH_C": "",
    "HIGH_C$": "",
    "HIGH_D": "",
    "HIGH_D$": "",
    "HIGH_E": "",
    "HIGH_F": "",
    "HIGH_F$": "",
    "HIGH_G": "",
    "HIGH_G$": "",
    "HIGH_A": "",
    "HIGH_A$": "",
    "HIGH_B": "",
    "OPERATORS_STRINGTONUMBER": "",
    "INTEGER": "",
    "DECIMAL": "",
    "OPERATORS_NUMTOASCII": "",
    "OPERATORS_STRINGTOASCII": "",
    "OPERATORS_NUMTOSTRING": "",
    "OPERATORS_MAP": "",
    "OPERATORS_CONSTRAIN": "",
    "STEPS_PER_TURN": "",
    "ROTATE_SPEED": "",
    "NEW_AI": "",
    "ASK_AND_PAINT": "",
    "GET_IDENTIFIED_NUM": "",
    "READ_NUM_AND_SAY": "",
    "IMAGE_ADDR": "",
    "IMAGE_PREVIEW": "",
    "IMAGE_OPEN": "",
    "IMAGE_SIZE": "",
    "IMAGE_WIDTH": "",
    "IMAGE_HEIGHT": "",
    "VARIABLE": "",
    "VARIABLE_LIST": "",
    "SET_STRING_VARIABLE": "",
    "STRING_START_WITH": "",
    "DICTIONARY_HEIGHT_FIRST": "",
    "DICTIONARY_HEIGHT_TWO": "",
    "DICTIONARY_HEIGHT_THREE": "",
    "DICTIONARY_HEIGHT_FOUR": "",
    "DATA_MPITEMOFLIST": "",
    "DATA_MPINTERCEPTITEM": "",
    "DATA_MPINSERTATLIST": "",
    "DATA_MPSETVALUEATLIST": "",
    "DATA_MPINSERTATLIST_INSERT": "",
    "DATA_MPINSERTATLIST_CHANGE": "",
    "DATA_MPINSERTATLIST_FOUR": "",
    "DATA_MPINSERTATLIST_FIVE": "",
    "DATA_MPINSERTATLIST_SIX": "",
    "DATA_MPINSERTATLIST_SEVEN": "",
    "DATA_MPINSERTATLIST_EIGHT": "",
    "DATA_MPINSERTATLIST_EIGHT_FALSE": "",
    "DATA_MPINSERTATLIST_EIGHT_TRUE": "",
    "DATA_MPINSERTATLIST_EIGHT_REVERSE": "",
    "OPERATORS_RANDOMA": "",
    "OPERATORS_MODA": "",
    "OPERATORS_ROUNDA": "",
    "OPERATORS_MATHOPA": "",
    "OPERATORS_MATHOP_ABSA": "",
    "OPERATORS_MATHOP_FLOORA": "",
    "OPERATORS_MATHOP_CEILINGA": "",
    "OPERATORS_MATHOP_SQRTA": "",
    "OPERATORS_MATHOP_FIVE": "",
    "OPERATORS_MATHOP_SIX": "",
    "OPERATORS_MATHOP_SEVEN": "",
    "OPERATORS_MATHOP_LENGHT": "",
    "OPERATORS_MATHOP_MAXMIN": "",
    "OPERATORS_MATHOP_MAXMIN_MAX": "",
    "OPERATORS_MATHOP_MAXMIN_MIN": "",
    "OPERATORS_TEXTS_ONE": "",
    "OPERATORS_TEXTS_TWO": "",
    "OPERATORS_TEXTS_THREE": "",
    "OPERATORS_TEXTS_FOUR": "",
    "OPERATORS_TEXTS_FOUR_NEW": "",
    "OPERATORS_TEXTS_NEW": "",
    "OPERATORS_TEXTS_NEW_OP": "",
    "OPERATORS_TEXTS_FOUR_ONE": "",
    "OPERATORS_TEXTS_FOUR_TWO": "",
    "OPERATORS_TEXTS_FOUR_THREE": "",
    "OPERATORS_TEXTS_FOUR_FOUR": "",
    "OPERATORS_TEXTS_FOUR_F": "",
    "OPERATORS_TEXTS_FOUR_L": "",
    "OPERATORS_STRINGTONUMBERA": "",
    "OPERATORS_NUMTOASCIIA": "",
    "OPERATORS_STRINGTOASCIIA": "",
    "OPERATORS_NUMTOSTRINGA": "",
    "NEW_MC": "",
    "RENAME_MCNUMBER_TITLE": "",
    "RENAME_MCNUMBER_MODAL_TITLE": "",
    "RENAME_VARIABLE_MCNUMBER": "",
    "DELETE_VARIABLE_MCNUMBER": "",
    "SET_MC_VARIABLE": "",
    "TUPLE_ONE": "",
    "TUPLE_TWO": "",
    "ALIYUN": "",
    "ONENET": "",
    "EASYIOT": "",
    "SHANGHAI": "",
    "QINGDAO": "",
    "BEIJING": "",
    "ZHANGJIAKOU": "",
    "HUHEHAOTE": "",
    "HANGZHOU": "",
    "SHENZHEN": "",
    "HONGKONG": "",
    "SINGAPORE": "",
    "SYDNEY": "",
    "KUALALUMPUR": "",
    "JAKARTA": "",
    "MUMBAI": "",
    "TOKYO": "",
    "SILICONVALLEY": "",
    "VIRGINIA": "",
    "FRANKFURT": "",
    "LONDON": "",
    "DUBAI": "",
    "IOT_PLATFORM": "",
    "PARAMS": "",
    "SERVER_ATTR": "",
    "PRODUCTID": "",
    "DEVICEID": "",
    "TINYDB_SECRET": "",
    "TINYDB_USER": "",
    "APIADDR": "",
    "SOFTWARE_SERIAL": "",
    "HARDWARE_SERIAL": "",
    "HARDWARE_SERIAL1": "",
    "HARDWARE_SERIAL2": "",
    "HARDWARE_SERIAL3": ""
  },
  "zh-cn": {
    "CONTROL_FOREVER": "循环执行",
    "CONTROL_LOOP_BREAKCONTINUE": "%1",
    "CONTROL_LOOP_BREAKCONTINUE_BREAK": "中断循环",
    "CONTROL_LOOP_BREAKCONTINUE_CONTINUE": "继续下一次循环",
    "CONTROL_REPEAT": "重复执行 %1 次",
    "CONTROL_IF": "如果 %1 那么执行",
    "CONTROL_ELSE": "否则",
    "CONTROL_STOP": "停止",
    "CONTROL_STOP_ALL": "全部脚本",
    "CONTROL_STOP_THIS": "这个脚本",
    "CONTROL_STOP_OTHER": "该角色的其他脚本",
    "CONTROL_WAIT": "等待 %1 秒",
    "CONTROL_WAIT_ESP32_MPY": "等待 %1 %2",
    "CONTROL_FOR_FROM_SEQUENCE": "使用 %1 从序列 %2",
    "CONTROL_FOR_RANGE_SPACE": "使用 %1 从范围 %2 到 %3 每隔 %4",
    "CONTROL_WAIT_ESP32_MPY_S": "秒",
    "CONTROL_WAIT_ESP32_MPY_MS": "毫秒",
    "CONTROL_WAIT_ESP32_MPY_US": "微秒",
    "CONTROL_WAITUNTIL": "等待直到 %1",
    "CONTROL_REPEATUNTIL": "重复执行直到 %1",
    "CONTROL_WHILE": "当 %1 重复执行",
    "CONTROL_FOREACH": "对于 %2 中的每个 %1",
    "CONTROL_STARTASCLONE": "当作为克隆体启动时",
    "CONTROL_CREATECLONEOF": "克隆 %1",
    "CONTROL_CREATECLONEOF_MYSELF": "自己",
    "CONTROL_DELETETHISCLONE": "删除此克隆体",
    "CONTROL_COUNTER": "计数器",
    "CONTROL_INCRCOUNTER": "计数器加一",
    "CONTROL_CLEARCOUNTER": "计数器归零",
    "CONTROL_ALLATONCE": "所有脚本",
    "DATA_SETVARIABLETO": "设置 %1 的值为 %2",
    "DATA_CHANGEVARIABLEBY": "将 %1 增加 %2",
    "DATA_SHOWVARIABLE": "显示变量 %1",
    "DATA_HIDEVARIABLE": "隐藏变量 %1",
    "DATA_ADDTOLIST": "将 %1 加入 %2",
    "DATA_DELETEOFLIST": "删除 %2 的第 %1 项",
    "DATA_DELETEALLOFLIST": "删除 %1 的全部项目",
    "DATA_INSERTATLIST": "在 %3 的第 %2 项前插入 %1",
    "DATA_REPLACEITEMOFLIST": "将 %2 的第 %1 项替换为 %3",
    "DATA_ITEMOFLIST": "%2 的第 %1 项",
    "DATA_ITEMNUMOFLIST": "%2 中第一个 %1 的编号",
    "DATA_LENGTHOFLIST": "%1 的项目数",
    "DATA_LISTCONTAINSITEM": "%1 包含 %2 ?",
    "DATA_SHOWLIST": "显示列表 %1",
    "DATA_HIDELIST": "隐藏列表 %1",
    "DATA_INDEX_ALL": "全部",
    "DATA_INDEX_LAST": "末尾",
    "DATA_INDEX_RANDOM": "随机",
    "EVENT_WHENFLAGCLICKED": "当 %1 被点击",
    "EVENT_WHENTHISSPRITECLICKED": "当角色被点击",
    "EVENT_WHENSTAGECLICKED": "当舞台被点击",
    "EVENT_WHENTOUCHINGOBJECT": "当该角色碰到 %1",
    "EVENT_WHENBROADCASTRECEIVED": "当接收到 %1",
    "EVENT_WHENBACKDROPSWITCHESTO": "当背景换成 %1",
    "EVENT_WHENGREATERTHAN": "当 %1 > %2",
    "EVENT_WHENGREATERTHAN_TIMER": "计时器",
    "EVENT_WHENGREATERTHAN_LOUDNESS": "响度",
    "EVENT_BROADCAST": "广播 %1",
    "EVENT_BROADCASTANDWAIT": "广播 %1 并等待",
    "EVENT_WHENKEYPRESSED": "当按下 %1 键",
    "EVENT_WHENKEYPRESSED_SPACE": "空格",
    "EVENT_WHENKEYPRESSED_LEFT": "←",
    "EVENT_WHENKEYPRESSED_RIGHT": "→",
    "EVENT_WHENKEYPRESSED_DOWN": "↓",
    "EVENT_WHENKEYPRESSED_UP": "↑",
    "EVENT_WHENKEYPRESSED_ANY": "任意",
    "LOOKS_SAYFORSECS": "说 %1 %2 秒",
    "LOOKS_SAY": "说 %1",
    "LOOKS_HELLO": "你好！",
    "LOOKS_THINKFORSECS": "思考 %1 %2 秒",
    "LOOKS_THINK": "思考 %1",
    "LOOKS_HMM": "嗯……",
    "LOOKS_SHOW": "显示",
    "LOOKS_HIDE": "隐藏",
    "LOOKS_HIDEALLSPRITES": "隐藏所有角色",
    "LOOKS_EFFECT_COLOR": "颜色",
    "LOOKS_EFFECT_FISHEYE": "鱼眼",
    "LOOKS_EFFECT_WHIRL": "漩涡",
    "LOOKS_EFFECT_PIXELATE": "像素化",
    "LOOKS_EFFECT_MOSAIC": "马赛克",
    "LOOKS_EFFECT_BRIGHTNESS": "亮度",
    "LOOKS_EFFECT_GHOST": "虚像",
    "LOOKS_CHANGEEFFECTBY": "将 %1 特效增加 %2",
    "LOOKS_SETEFFECTTO": "将 %1 特效设定为 %2",
    "LOOKS_CLEARGRAPHICEFFECTS": "清除图形特效",
    "LOOKS_CHANGESIZEBY": "将大小增加 %1",
    "LOOKS_SETSIZETO": "将大小设为 %1",
    "LOOKS_SIZE": "大小",
    "LOOKS_CHANGESTRETCHBY": "伸缩%1",
    "LOOKS_SETSTRETCHTO": "设置伸缩为%1 %",
    "LOOKS_SWITCHCOSTUMETO": "换成 %1 造型",
    "LOOKS_NEXTCOSTUME": "下一个造型",
    "LOOKS_SWITCHBACKDROPTO": "换成 %1 背景",
    "LOOKS_GOTOFRONTBACK": "移到最 %1 ",
    "LOOKS_GOTOFRONTBACK_FRONT": "前面",
    "LOOKS_GOTOFRONTBACK_BACK": "后面",
    "LOOKS_GOFORWARDBACKWARDLAYERS": "%1 %2 层",
    "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "前移",
    "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "后移",
    "LOOKS_BACKDROPNUMBERNAME": "背景 %1",
    "LOOKS_COSTUMENUMBERNAME": "造型 %1",
    "LOOKS_NUMBERNAME_NUMBER": "编号",
    "LOOKS_NUMBERNAME_NAME": "名称",
    "LOOKS_SWITCHBACKDROPTOANDWAIT": "换成 %1 背景并等待",
    "LOOKS_NEXTBACKDROP_BLOCK": "下一个背景",
    "LOOKS_NEXTBACKDROP": "下一个背景",
    "LOOKS_PREVIOUSBACKDROP": "上一个背景",
    "LOOKS_RANDOMBACKDROP": "随机背景",
    "MOTION_MOVESTEPS": "移动 %1 步",
    "MOTION_TURNLEFT": "左转 %1 %2 度",
    "MOTION_TURNRIGHT": "右转 %1 %2 度",
    "MOTION_POINTINDIRECTION": "面向 %1 度方向",
    "MOTION_POINTTOWARDS": "面向 %1",
    "MOTION_POINTTOWARDS_POINTER": "鼠标指针",
    "MOTION_POINTTOWARDS_RANDOM": "随机方向",
    "MOTION_GOTO": "移到 %1",
    "MOTION_GOTO_POINTER": "鼠标指针",
    "MOTION_GOTO_RANDOM": "随机位置",
    "MOTION_GOTOXY": "移到 x: %1 y: %2",
    "MOTION_GLIDESECSTOXY": "在 %1 秒内滑行到 x: %2 y: %3",
    "MOTION_GLIDETO": "在 %1 秒内滑行到 %2",
    "MOTION_GLIDETO_POINTER": "鼠标指针",
    "MOTION_GLIDETO_RANDOM": "随机位置",
    "MOTION_CHANGEXBY": "将x坐标增加 %1",
    "MOTION_SETX": "将x坐标设为 %1",
    "MOTION_CHANGEYBY": "将y坐标增加 %1",
    "MOTION_SETY": "将y坐标设为 %1",
    "MOTION_IFONEDGEBOUNCE": "碰到边缘就反弹",
    "MOTION_SETROTATIONSTYLE": "将旋转方式设为 %1",
    "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "左右翻转",
    "MOTION_SETROTATIONSTYLE_DONTROTATE": "不可旋转",
    "MOTION_SETROTATIONSTYLE_ALLAROUND": "任意旋转",
    "MOTION_XPOSITION": "x 坐标",
    "MOTION_YPOSITION": "y 坐标",
    "MOTION_DIRECTION": "方向",
    "MOTION_SCROLLRIGHT": "向右滚动 %1",
    "MOTION_SCROLLUP": "向上滚动 %1",
    "MOTION_ALIGNSCENE": "和场景 %1 对齐",
    "MOTION_ALIGNSCENE_BOTTOMLEFT": "左下角",
    "MOTION_ALIGNSCENE_BOTTOMRIGHT": "右下角",
    "MOTION_ALIGNSCENE_MIDDLE": "中间",
    "MOTION_ALIGNSCENE_TOPLEFT": "左上角",
    "MOTION_ALIGNSCENE_TOPRIGHT": "右上角",
    "MOTION_XSCROLL": "x滚动位置",
    "MOTION_YSCROLL": "y滚动位置",
    "MOTION_STAGE_SELECTED": "选中了舞台：不可使用运动类积木",
    "OPERATORS_ADD": "%1 + %2",
    "OPERATORS_SUBTRACT": "%1 - %2",
    "OPERATORS_MULTIPLY": "%1 * %2",
    "OPERATORS_DIVIDE": "%1 / %2",
    "OPERATORS_RANDOM": "在 %1 和 %2 之间取随机数",
    "OPERATORS_GT": "%1 > %2",
    "OPERATORS_GT_EQUALS": "%1 >= %2",
    "OPERATORS_LT": "%1 < %2",
    "OPERATORS_LT_EQUALS": "%1 <= %2",
    "OPERATORS_EQUALS": "%1 = %2",
    "OPERATORS_AND": "%1 与 %2",
    "OPERATORS_OR": "%1 或 %2",
    "OPERATORS_NOT": "非 %1",
    "CONTROL_TRUE_FALSE": "%1",
    "CONTROL_TRUE_FALSE_TRUE": "真",
    "CONTROL_TRUE_FALSE_FALSE": "假",
    "CONTROL_NONE": "空",
    "CONTROL_RETURN": "返回 %1",
    "OPERATORS_VALUE_IS_TRUE": "%1 为真？",
    "OPERATORS_VALUE_TYPE": "%1 的类型",
    "OPERATORS_VALUE_TYPE_IS": "%1 的类型为 %2？",
    "OPERATORS_CACULATE_RESULT": "计算表达式 %1 的结果",
    "OPERATORS_VALUE_TYPE_INT": "整数(int)",
    "OPERATORS_VALUE_TYPE_FLOAT": "浮点数(float)",
    "OPERATORS_VALUE_TYPE_BOOL": "bool",
    "OPERATORS_VALUE_TYPE_STR": "str",
    "OPERATORS_VALUE_TYPE_LIST": "list",
    "OPERATORS_VALUE_TYPE_TUPLE": "tuple",
    "OPERATORS_VALUE_TYPE_SET": "set",
    "OPERATORS_VALUE_TYPE_DICT": "dict",
    "OPERATORS_VALUE_TYPE_BYTES": "bytes",
    "OPERATORS_VALUE_TYPE_BYTEARRAY": "bytearray",
    "OPERATORS_VALUE_TYPE_COMPLEX": "complex",
    "CONTROL_TRY": "try",
    "CONTROL_EXCEPT": "except",
    "CONTROL_FINALLY": "finally",
    "OPERATORS_JOIN": "合并 %1 %2",
    "OPERATORS_JOIN_APPLE": "苹果",
    "OPERATORS_JOIN_BANANA": "香蕉",
    "OPERATORS_LETTEROF": "%2 的第 %1 个字符",
    "OPERATORS_LETTEROF_APPLE": "果",
    "OPERATORS_LENGTH": "%1 的字符数",
    "OPERATORS_CONTAINS": "%1 包含 %2 ?",
    "OPERATORS_MOD": "%1 除以 %2 的余数",
    "OPERATORS_ROUND": "四舍五入 %1",
    "OPERATORS_MATHOP": "%1 %2",
    "OPERATORS_MATHOP_ABS": "绝对值",
    "OPERATORS_MATHOP_FLOOR": "向下取整",
    "OPERATORS_MATHOP_CEILING": "向上取整",
    "OPERATORS_MATHOP_SQRT": "平方根",
    "OPERATORS_MATHOP_SIN": "正弦(sin)",
    "OPERATORS_MATHOP_COS": "余弦(cos)",
    "OPERATORS_MATHOP_TAN": "正切(tan)",
    "OPERATORS_MATHOP_ASIN": " 反正弦(asin)",
    "OPERATORS_MATHOP_ACOS": " 反余弦(acos)",
    "OPERATORS_MATHOP_ATAN": "反正切(atan)",
    "OPERATORS_MATHOP_LN": "ln",
    "OPERATORS_MATHOP_LOG": "log",
    "OPERATORS_MATHOP_EEXP": "e ^",
    "OPERATORS_MATHOP_10EXP": "10 ^",
    "PROCEDURES_DEFINITION": "定义 %1",
    "SENSING_TOUCHINGOBJECT": "碰到 %1 ?",
    "SENSING_TOUCHINGOBJECT_POINTER": "鼠标指针",
    "SENSING_TOUCHINGOBJECT_EDGE": "舞台边缘",
    "SENSING_TOUCHINGCOLOR": "碰到颜色 %1 ?",
    "SENSING_COLORISTOUCHINGCOLOR": "颜色 %1 碰到 %2 ?",
    "SENSING_DISTANCETO": "到 %1 的距离",
    "SENSING_DISTANCETO_POINTER": "鼠标指针",
    "SENSING_ASKANDWAIT": "询问 %1 并等待",
    "SENSING_ASK_TEXT": "你叫什么名字？",
    "SENSING_ANSWER": "回答",
    "SENSING_KEYPRESSED": "按下 %1 键?",
    "SENSING_MOUSEDOWN": "按下鼠标?",
    "SENSING_MOUSEX": "鼠标的x坐标",
    "SENSING_MOUSEY": "鼠标的y坐标",
    "SENSING_SETDRAGMODE": "将拖动模式设为 %1",
    "SENSING_SETDRAGMODE_DRAGGABLE": "可拖动",
    "SENSING_SETDRAGMODE_NOTDRAGGABLE": "不可拖动",
    "SENSING_LOUDNESS": "响度",
    "SENSING_LOUD": "响声？",
    "SENSING_TIMER": "计时器",
    "SENSING_RESETTIMER": "计时器归零",
    "SENSING_OF": "%2 的 %1",
    "SENSING_OF_XPOSITION": "x 坐标",
    "SENSING_OF_YPOSITION": "y 坐标",
    "SENSING_OF_DIRECTION": "方向",
    "SENSING_OF_COSTUMENUMBER": "造型编号",
    "SENSING_OF_COSTUMENAME": "造型名称",
    "SENSING_OF_SIZE": "大小",
    "SENSING_OF_VOLUME": "音量",
    "SENSING_OF_BACKDROPNUMBER": "背景编号",
    "SENSING_OF_BACKDROPNAME": "背景名称",
    "SENSING_OF_STAGE": "舞台",
    "SENSING_CURRENT": "当前时间的 %1",
    "SENSING_CURRENT_YEAR": "年",
    "SENSING_CURRENT_MONTH": "月",
    "SENSING_CURRENT_DATE": "日",
    "SENSING_CURRENT_DAYOFWEEK": "星期",
    "SENSING_CURRENT_HOUR": "时",
    "SENSING_CURRENT_MINUTE": "分",
    "SENSING_CURRENT_SECOND": "秒",
    "SENSING_DAYSSINCE2000": "2000年至今的天数",
    "SENSING_USERNAME": "用户名",
    "SENSING_USERID": "用户id",
    "SOUND_PLAY": "播放声音 %1",
    "SOUND_PLAYUNTILDONE": "播放声音 %1 等待播完",
    "SOUND_STOPALLSOUNDS": "停止所有声音",
    "SOUND_SETEFFECTO": "将 %1 音效设为 %2",
    "SOUND_CHANGEEFFECTBY": "将 %1 音效增加 %2",
    "SOUND_CLEAREFFECTS": "清除音效",
    "SOUND_EFFECTS_PITCH": "音调",
    "SOUND_EFFECTS_PAN": "左右平衡",
    "SOUND_CHANGEVOLUMEBY": "将音量增加 %1",
    "SOUND_SETVOLUMETO": "将音量设为 %1%",
    "SOUND_VOLUME": "音量",
    "SOUND_RECORD": "录制…",
    "CATEGORY_MOTION": "运动",
    "CATEGORY_LOOKS": "外观",
    "CATEGORY_SOUND": "声音",
    "CATEGORY_EVENTS": "事件",
    "CATEGORY_CONTROL": "控制",
    "CATEGORY_SENSING": "侦测",
    "CATEGORY_OPERATORS": "运算符",
    "CATEGORY_VARIABLES": "变量",
    "CATEGORY_MYBLOCKS": "函数",
    "CATEGORY_NUMBERS": "数字",
    "CATEGORY_TEXT": "文本",
    "DUPLICATE": "复制",
    "DUPLICATE_CURRENT": "复制单个",
    "DELETE": "删除",
    "ADD_COMMENT": "添加注释",
    "REMOVE_COMMENT": "删除注释",
    "DELETE_BLOCK": "删除",
    "DELETE_X_BLOCKS": "删除 %1 积木",
    "DELETE_ALL_BLOCKS": "删除全部 %1 积木？",
    "CLEAN_UP": "整理积木",
    "HELP": "帮助",
    "UNDO": "撤销",
    "REDO": "重做",
    "EDIT_PROCEDURE": "编辑",
    "SHOW_PROCEDURE_DEFINITION": "查看定义",
    "WORKSPACE_COMMENT_DEFAULT_TEXT": "说些什么……",
    "COLOUR_HUE_LABEL": "颜色",
    "COLOUR_SATURATION_LABEL": "饱和度",
    "COLOUR_BRIGHTNESS_LABEL": "亮度",
    "CHANGE_VALUE_TITLE": "更改变量：",
    "RENAME_VARIABLE": "修改变量名",
    "RENAME_VARIABLE_STRING": "修改字符类型变量名",
    "RENAME_VARIABLE_NUMBER": "修改数字类型变量名",
    "RENAME_VARIABLE_LIST": "修改列表变量名",
    "RENAME_VARIABLE_TITLE": "将所有的「%1」变量名改为：",
    "RENAME_VARIABLE_MODAL_TITLE": "修改变量名",
    "NEW_VARIABLE": "新建变量",
    "NEW_VARIABLE_TITLE": "新变量名：",
    "VARIABLE_MODAL_TITLE": "新建变量",
    "VARIABLE_ALREADY_EXISTS": "已经存在名为「%1」的变量。",
    "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "已经存在一个名为「%1」的变量，其类型为「%2」。",
    "DELETE_VARIABLE_CONFIRMATION": "删除%1处「%2」变量吗？",
    "CANNOT_DELETE_VARIABLE_PROCEDURE": "无法删除变量「%1」，因为函数「%2」的定义中用到了它",
    "DELETE_VARIABLE": "删除变量「%1」",
    "DELETE_VARIABLE_STRING": "删除字符变量「%1」",
    "DELETE_VARIABLE_NUMBER": "删除数字变量「%1」",
    "DELETE_VARIABLE_LIST": "删除列表变量「%1」",
    "NEW_PROCEDURE": "自定义模块",
    "PROCEDURE_ALREADY_EXISTS": "已经存在名为「%1」的程序。",
    "PROCEDURE_DEFAULT_NAME": "积木名称",
    "NEW_LIST": "新建列表",
    "NEW_LIST_TITLE": "新的列表名：",
    "LIST_MODAL_TITLE": "新建列表",
    "LIST_ALREADY_EXISTS": "名为 「%1」 的列表已存在。",
    "RENAME_LIST_TITLE": "将所有的「%1」列表改名为：",
    "RENAME_LIST_MODAL_TITLE": "修改列表名",
    "DEFAULT_LIST_ITEM": "东西",
    "DELETE_LIST": "删除「%1」列表",
    "RENAME_LIST": "修改列表名",
    "NEW_BROADCAST_MESSAGE": "新消息",
    "NEW_BROADCAST_MESSAGE_TITLE": "新消息的名称：",
    "BROADCAST_MODAL_TITLE": "新消息",
    "DEFAULT_BROADCAST_MESSAGE_NAME": "消息1",
    "PROCEDURE_USED": "要删除自定义积木块, 首先应移除所有对它的引用",
    "OK": "确定",
    "CANCEL": "取消",
    "PROMPT": "提示",
    "STOP": "停止",
    "NEW_STRING": "新建字符串类型变量",
    "NEW_NUMBER_VARIABLE": "新建数字类型变量",
    "WIFI": "Wi-Fi",
    "USERNAME": "Wi-Fi名称",
    "PWD": "Wi-Fi密码",
    "IP": "IP地址",
    "IOT_SERVICE": "物联网平台参数",
    "IOT_ID": "Iot_id",
    "IOT_PWD": "Iot_pwd",
    "IOT_TOPIC": "Topic_0",
    "MORE_TOPIC": "Topic",
    "OBLOQ_HTTP": "HTTP配置",
    "OBLOQ_IP": "IP地址",
    "OBLOQ_PORT": "端口号",
    "WIFI_VALIDATE": "Wi-Fi密码必须是8-20位的字母或数字",
    "ADD_TOPIC": "添加Topic, 最多只能添加4个",
    "DELETE_TOPIC": "删除 Topic",
    "IOT_SERVER": "服务器",
    "CHINA": "中国",
    "GLOBAL": "全球",
    "LOW_C": "1 低 C/C3",
    "LOW_C$": "1# 低 C#/C#3",
    "LOW_D": "2 低 D/D3",
    "LOW_D$": "2# 低 D#/D#3",
    "LOW_E": "3 低 E/E3",
    "LOW_F": "4 低 F/F3",
    "LOW_F$": "4# 低 F#/F#3",
    "LOW_G": "5 低 G/G3",
    "LOW_G$": "5# 低 G#/G#3",
    "LOW_A": "6 低 A/A3",
    "LOW_A$": "6# 低 A#/A#3",
    "LOW_B": "7 低 B/B3",
    "MIDDLE_C": "1 中 C/C4",
    "MIDDLE_C$": "1# 中 C#/C#4",
    "MIDDLE_D": "2 中 D/D4",
    "MIDDLE_D$": "2# 中 D#/D#4",
    "MIDDLE_E": "3 中 E/E4",
    "MIDDLE_F": "4 中 F/F4",
    "MIDDLE_F$": "4# 中 F#/F#4",
    "MIDDLE_G": "5 中 G/G4",
    "MIDDLE_G$": "5# 中 G#/G#4",
    "MIDDLE_A": "6 中 A/A4",
    "MIDDLE_A$": "6# 中 A#/A#4",
    "MIDDLE_B": "7 中 B/B4",
    "HIGH_C": "1 高 C/C5",
    "HIGH_C$": "1# 高 C#/C#5",
    "HIGH_D": "2 高 D/D5",
    "HIGH_D$": "2# 高 D#/D#5",
    "HIGH_E": "3 高 E/E5",
    "HIGH_F": "4 高 F/F5",
    "HIGH_F$": "4# 高 F#/F#5",
    "HIGH_G": "5 高 G/G5",
    "HIGH_G$": "5# 高 G#/G#5",
    "HIGH_A": "6 高 A/A5",
    "HIGH_A$": "6# 高 A#/A#5",
    "HIGH_B": "7 高 B/B5",
    "OPERATORS_STRINGTONUMBER": "将字符串 %1 转换为 %2",
    "INTEGER": "整数",
    "DECIMAL": "小数",
    "OPERATORS_NUMTOASCII": "将数字 %1 转换为 ASCII字符串",
    "OPERATORS_STRINGTOASCII": "将字符 %1 转换为 ASCII数值",
    "OPERATORS_NUMTOSTRING": "将数字 %1 转换为字符串",
    "OPERATORS_MAP": "映射 %1 从[ %2 , %3 ] 到[ %4 , %5 ]",
    "OPERATORS_CONSTRAIN": "约束 %1 介于(最小值) %2 和(最大值) %3 之间",
    "STEPS_PER_TURN": "每转步数",
    "ROTATE_SPEED": "转速(r/min)",
    "NEW_AI": "创建AI模型",
    "ASK_AND_PAINT": "请写一个数字",
    "GET_IDENTIFIED_NUM": "数字识别",
    "READ_NUM_AND_SAY": "说出识别的数字",
    "IMAGE_ADDR": "图像地址",
    "IMAGE_PREVIEW": "图像预览",
    "IMAGE_OPEN": "打开",
    "IMAGE_SIZE": "图像尺寸",
    "IMAGE_WIDTH": "宽",
    "IMAGE_HEIGHT": "高",
    "VARIABLE": "变量 %1",
    "VARIABLE_LIST": "列表 %1",
    "SET_STRING_VARIABLE": "设置 %1 的值为 %2",
    "STRING_START_WITH": "%1 以 %2 字符串开始",
    "OPERATORS_RANDOMA": "%1 %2 在 %3 到 %4 间取随机 %5",
    "GLOBAL_MPY_VARIABLE": "全局global %1",
    "OPERATORS_MODA": "%1 %2 %3 除以 %4 的余数",
    "OPERATORS_ROUNDA": "%1 %2 四舍五入 %3",
    "OPERATORS_MATHOPA": "%1 %2 %3 %4",
    "OPERATORS_MATHOP_ABSA": "绝对值",
    "OPERATORS_MATHOP_FLOORA": "向下取整",
    "OPERATORS_MATHOP_CEILINGA": "向上取整",
    "OPERATORS_MATHOP_SQRTA": "平方根",
    "OPERATORS_MATHOP_FIVE": "%1 %2 将变量 %3 增加 %4",
    "OPERATORS_MATHOP_SIX": "%1 %2 映射 %3 从[ %4 , %5 ] 到[ %6 , %7 ]",
    "OPERATORS_MATHOP_SEVEN": "%1 %2 约束 %3 介于(最小值) %4 和(最大值) %5 之间",
    "OPERATORS_NUM_INPUT": "%1 %2 数字 %3",
    "OPERATORS_NUMS_CACULATE": "%1 %2 %3",
    "OPERATORS_MATHOP_SQUARE": "**",
    "OPERATORS_MATHOP_LOGIC_OR": "|",
    "OPERATORS_MATHOP_LOGIC_AND": "&",
    "OPERATORS_MATHOP_LOGIC_POWER": "^",
    "OPERATORS_MATHOP_LOGIC_LEFT": "<<",
    "OPERATORS_MATHOP_LOGIC_RIGHT": ">>",
    "OPERATORS_SPECIAL_NUM": "%1 %2 %3",
    "OPERATORS_MATHOP_PI": "π",
    "OPERATORS_MATHOP_LOGARITHM": "e",
    "OPERATORS_MATHOP_FAI": "φ",
    "OPERATORS_MATHOP_SQRT2": "sqrt(2)",
    "OPERATORS_MATHOP_SQRTHALF": "sqrt(½)",
    "OPERATORS_MATHOP_UNLIMITED": "∞",
    "OPERATORS_NUMS_TYPE": "%1 %2 %3 %4？",
    "OPERATORS_MATHOP_ISEVEN": "是偶数",
    "OPERATORS_MATHOP_ISODD": "是奇数",
    "OPERATORS_MATHOP_ISINT": "是整数",
    "OPERATORS_MATHOP_ISPOSITIVE": "为正",
    "OPERATORS_MATHOP_ISNEGATIVE": "为负",
    "OPERATORS_SAVE2DECIMAL": "%1 %2 %3 保留 %4 位小数",
    "OPERATORS_LIST_CACULATE": "%1 %2 %3 %4",
    "OPERATORS_MATHOP_LIST_SUM": "列表中的数的总和",
    "OPERATORS_MATHOP_LIST_MIN": "列表中的最小值",
    "OPERATORS_MATHOP_LIST_MAX": "列表中的最大值",
    "OPERATORS_MATHOP_LIST_AVERAGE": "列表中的平均数",
    "OPERATORS_MATHOP_LIST_NUM": "列表中位数",
    "OPERATORS_MATHOP_LIST_MODE": "列表模式",
    "OPERATORS_MATHOP_LIST_STDDEVIATION": "列表中的标准差",
    "OPERATORS_MATHOP_LIST_RANDOM": "列表中的随机项",
    "OPERATORS_QUOTIENT_INT": "%1 %2 %3 ÷ %4 商的整数部分",
    "OPERATORS_RANDOM_DECIMAL": "%1 %2 随机小数",
    "OPERATORS_BIN_NEGATION": "%1 %2 二进制取反 %3",
    "OPERATORS_INT_TO_STR": "%1 %2 10进制整数 %3 转化为 %4 字符串",
    "OPERATORS_MATHOP_INTTOSTR_BIN": "二进制",
    "OPERATORS_MATHOP_INTTOSTR_OCT": "八进制",
    "OPERATORS_MATHOP_INTTOSTR_HEX": "十六进制",
    "OPERATORS_BINSTR_TO_INT": "%1 %2 %3 字符串 %4 转化为10进制整数",
    "OPERATORS_INT_TO_BYTE": "%1 %2 10进制整数 %3 转化为 %4 字节",
    "OPERATORS_MATHOP_INTTOBYTE_TWO": "2",
    "OPERATORS_MATHOP_INTTOBYTE_FOUR": "4",
    "OPERATORS_INT_TO_ASCII": "%1 %2 整数 %3 转换为ASCII单字符",
    "OPERATORS_ASCII_TO_INT": "%1 %2 ASCII单字符 %3 转换为整数",
    "OPERATORS_STR_TO_INT": "%1 %2 字符串 %3 转十六进制字符串",
    "OPERATORS_HEXSTR_TO_BIN": "%1 %2 十六进制字符串 %3 转二进制字节",
    "OPERATORS_STR_TO_BASE64": "%1 %2 %3 以base64 格式编码 返回字节对象",
    "OPERATORS_BASE64_TO_BYTE": "%1 %2 解码base64数据 %3 返回字节对象",
    "OPERATORS_MATHOP_LENGHT": "长度 %1",
    "OPERATORS_MATHOP_MAXMIN": "%1 %2",
    "OPERATORS_MATHOP_MAXMIN_MAX": "最大值",
    "OPERATORS_MATHOP_MAXMIN_MIN": "最小值",
    "OPERATORS_TEXTS_ONE": "%1 %2 合并 %3 %4",
    "OPERATORS_TEXTS_TWO": "%1 %2 获取 %3 的第 %4 个字符",
    "OPERATORS_TEXTS_THREE": "%1 %2 %3 包含 %4 ?",
    "OPERATORS_TEXTS_FOUR": "%1 %2 %3 获取 %4 %5 个字符到 %6 %7个字符",
    "OPERATORS_TEXTS_FOUR_NEW": "%1 获取 %2 %3 个字符到 %4 %5个字符",
    "OPERATORS_TEXTS_NEW": "%1 %2 查找 %3 在 %4 中 %5 出现位置",
    "OPERATORS_TEXTS_NEW_OP": "查找 %1 在 %2 中 %3 出现位置",
    "OPERATORS_TEXTS_FOUR_ONE": "第",
    "OPERATORS_TEXTS_FOUR_TWO": "倒数第",
    "OPERATORS_TEXTS_FOUR_THREE": "第",
    "OPERATORS_TEXTS_FOUR_FOUR": "倒数第",
    "OPERATORS_TEXTS_FOUR_F": "首次",
    "OPERATORS_TEXTS_FOUR_L": "最后一次",
    "OPERATORS_INPUTSTRING": "%1 %2 文本 %3",
    "OPERATORS_TRANSINTOTEXT": "%1 %2 转为文本 %3",
    "OPERATORS_TRANSINTOTEXT_ADD": "加入",
    "OPERATORS_TRANSINTOTEXT_ADD_ITEM": "项",
    "OPERATORS_FORMATSTRING2F": "%1 %2 格式化字符串 %3 % %4",
    "OPERATORS_FORMATSTRING3": "%1 %2 格式化字符串 %3 format %4",
    "OPERATORS_TEXT_ESCAPE": "%1 %2 文本转义字符 %3",
    "BAR_N": "\\n",
    "BAR_NN": "\\n\\r",
    "BAR_R": "\\r",
    "BAR_RN": "\\r\\n",
    "BAR_B": "\\b",
    "BAR_T": "\\t",
    "BAR_BAR": "\\\\",
    "OPERATORS_TEXT_NUMORLETTER": "%1 %2 %3 %4？",
    "OPERATORS_TEXT_NUMORLETTER_NUM": "是数字",
    "OPERATORS_TEXT_NUMORLETTER_LETTER": "是字母",
    "OPERATORS_TEXT_LENGTH": "%1 %2 %3 的长度",
    "OPERATORS_TEXT_ISNONE": "%1 %2 %3 是空的？",
    "OPERATORS_FROMTEXT_GETCHAR": "%1 %2 从文本 %3 %4",
    "OPERATORS_FROMTEXT_GETCHAR_FIRST": "获得第一个字符",
    "OPERATORS_FROMTEXT_GETCHAR_LAST": "获得最后一个字符",
    "OPERATORS_FROMTEXT_GETCHAR_RANDOM": "获取随机的字母",
    "OPERATORS_TEXT_UPPLOW": "%1 %2 %3 %4",
    "OPERATORS_TEXT_UPPLOW_UPP": "转为大写",
    "OPERATORS_TEXT_UPPLOW_LOW": "转为小写",
    "OPERATORS_TEXT_SIDES_SPACE": "%1 %2 %3 %4",
    "OPERATORS_TEXT_SIDES_SPACE_BOTH": "清除两侧空格",
    "OPERATORS_TEXT_SIDES_SPACE_LEFT": "清除左侧空格",
    "OPERATORS_TEXT_SIDES_SPACE_RIGHT": "清除右侧空格",
    "OPERATORS_TEXT_STRTOBYTE": "%1 %2 转字节 %3",
    "OPERATORS_TEXT_BYTETOSTR": "%1 %2 字节 %3 转字符串",
    "OPERATORS_TEXT_DICTTOJSONSTR": "%1 %2 字典 %3 转json字符串",
    "OPERATORS_TEXT_PARSEJSONSTR": "%1 %2 解析json字符串 %3 返回对象",
    "OPERATORS_STRINGTONUMBERA": "%1 %2 将字符串 %3 转换为 %4",
    "OPERATORS_NUMTOASCIIA": "%1 %2 将数字 %3 转换为 ASCII字符",
    "OPERATORS_STRINGTOASCIIA": "%1 %2 将字符 %3 转换为 ASCII数值",
    "OPERATORS_NUMTOSTRINGA": "%1 %2 将数字 %3 转换为字符串",
    "NEW_MC": "新建变量",
    "RENAME_MCNUMBER_TITLE": "重命名所有变量名为 [%1] 的变量为:",
    "RENAME_MCNUMBER_MODAL_TITLE": "重命名变量",
    "RENAME_VARIABLE_MCNUMBER": "重命名变量",
    "DELETE_VARIABLE_MCNUMBER": "删除变量 [%1]",
    "SET_MC_VARIABLE": "将变量 %1 赋值为 %2",
    "ALIYUN": "阿里云",
    "ONENET": "OneNet",
    "ONENETNEW": "OneNet(新版)",
    "EASYIOT": "Easy IoT",
    "SIOT": "SIoT",
    "SHANGHAI": "上海",
    "QINGDAO": "青岛",
    "BEIJING": "北京",
    "ZHANGJIAKOU": "张家口",
    "HUHEHAOTE": "呼和浩特",
    "HANGZHOU": "杭州",
    "SHENZHEN": "深圳",
    "HONGKONG": "香港",
    "SINGAPORE": "新加坡",
    "SYDNEY": "悉尼",
    "KUALALUMPUR": "吉隆坡",
    "JAKARTA": "雅加达",
    "MUMBAI": "孟买",
    "TOKYO": "东京",
    "SILICONVALLEY": "硅谷",
    "VIRGINIA": "弗吉尼亚",
    "FRANKFURT": "法兰克福",
    "LONDON": "伦敦",
    "DUBAI": "迪拜",
    "IOT_PLATFORM": "物联网平台",
    "PARAMS": "参数",
    "SERVER_ATTR": "服务器地址",
    "PRODUCTID": "产品ID",
    "DEVICEID": "设备Id",
    "DEVICENAME": "设备名称",
    "TINYDB_SECRET": "密钥 (secret)",
    "TINYDB_USER": "用户名 (user)",
    "APIADDR": "API地址",
    "SOFTWARE_SERIAL": "软串口",
    "HARDWARE_SERIAL": "硬串口",
    "HARDWARE_SERIAL1": "硬串口1",
    "HARDWARE_SERIAL2": "硬串口2",
    "HARDWARE_SERIAL3": "硬串口3",
    "CHECKTYPE_TIPS": "输入框接收的数据类型和积木块的输出类型不匹配",
    "HIGHLIGHT_BLOCK": "高亮",
    "HIGHLIGHT_ALL_CONFLICT_BLOCKS": "高亮全部有警告的积木块",
    "SNAPSHOT": "截图（CTRL+G）",
    "pictureaiPath": "请输入图片路径或网址",
    "pictureaiType": "账户类型",
    "pictureaiAccountDefault": "默认账户",
    "pictureaiAccountBaidu": "百度",
    "pictureaiwebImgAddr": "网络图片地址",
    "MITRIXICON_EDIT": "点亮",
    "MITRIXICON_ERASER": "擦除",
    "MITRIXICON_DUSTBIN": "清空",
    "MITRIXICON_REVERSE": "反色",
    "MITRIXICON_COLOR": "选择颜色",
    "RED": "红",
    "GREEN": "绿",
    "BLUE": "蓝",
    "SEARCH_BLOCKS": "搜索积木",
    "NO_SEARCH_BLOCKS": "没有搜索积木",
    "SEARCH_DEFAULT_LABEL_THINK": "思考",
    "SEARCH_DEFAULT_LABEL_IF": "如果",
    "SEARCH_DEFAULT_LABEL_WAIT": "等待",
    "SEARCH_DEFAULT_LABEL_CONVERT": "转换",
    "SEARCH_DEFAULT_LABEL_BROADCAST": "广播",
    "MITRIXICON_SAVE": "保存",
    "MITRIXICON_EXPORT": "导出点阵图案",
    "MITRIXICON_UPLOAD": "导入点阵图案",
    "ROLL_MOUSE": "滚动鼠标滑动",
    "MOUSE_ZOOM_IN_OUT": "滚动鼠标放大缩小",
    "UPLOAD_CORRECT_JSON_FILE": "请上传正确格式的json文件",
    "EXPORT_PROMPT": "没有要导出的自定义图案。请先点击右下角的保存按钮，保存自定义的图案后，再尝试导出",
    "HEART": "心形",
    "HEART_SMALL": "心形(小)",
    "HAPPY": "高兴",
    "SMILE": "笑脸",
    "SAD": "伤心",
    "CONFUSED": "困惑",
    "ANGRY": "生气",
    "SLEEP": "睡着",
    "SURPRISED": "惊讶",
    "SILLY": "愚蠢",
    "WONDERFUL": "极妙",
    "BORED": "无聊",
    "ROCK": "石头",
    "ROCK_SMALL": "石头(小)",
    "PAPER": "布",
    "PAPER_SMALL": "布(小)",
    "SCISSORS": "剪刀",
    "SCISSORS_SMALL": "剪刀(小)",
    "DATA_SOLITLIST": "%1 %2 %3 %4 用分隔符 %5",
    "DATA_SOLITLIST_TYPE_LT": "从列表组合文本",
    "DATA_SOLITLIST_TYPE_TL": "从文本制作列表",
    "DATA_LENLIST": "%1 %2 列表 %3 是空的？",
    "DATA_LENGTHLIST": "%1 %2 列表 %3 的长度",
    "DATA_CREATELIST": "%1 %2 初始化列表 %3",
    "DATA_CLEARLIST": "%1 %2 清空列表 %3",
    "ML5MODALBUTTON": "打开AI可视化窗口",
    "COLLAPSE_BLOCK": "折叠",
    "EXPAND_BLOCK": "展开",
    "COLLAPSE_ALL": "折叠所有积木",
    "EXPAND_ALL": "展开所有积木",
    "pyTimer": "定时器",
    "pyList": "列表",
    "pyDictionary": "字典",
    "pySet": "集合",
    "mpyTuple": "元组",
    "pythonBase_printAndInput": "输入输出",
    "pythonBase_mainSetUp": "%1 %2 Python主程序开始",
    "pythonBase_print": "%1 %2 打印 %3",
    "pythonBase_input": "%1 %2 获取输入，提示语为 %3",
    "pythonBase_file": "文件功能",
    "pythonBase_file_open": "%1 %2 以 %3 格式 %4 模式打开文件 %5 并返回 %6",
    "pythonBase_file_open_b": "%1 %2 以 %3 模式打开文件 %4 并返回 %5",
    "pythonBase_file_open_read": "读取",
    "pythonBase_file_open_write": "写入",
    "pythonBase_file_open_add": "追加",
    "pythonBase_file_open_read_bit": "二进制读取",
    "pythonBase_file_open_write_bit": "二进制写入",
    "pythonBase_file_open_add_bit": "二进制追加",
    "pythonBase_file_close": "%1 %2 %3 关闭",
    "pythonBase_file_read": "%1 %2 %3 读取 %4",
    "pythonBase_file_read_all": "整个文件",
    "pythonBase_file_read_line": "一行内容",
    "pythonBase_file_read_lines": "整个文件所有行",
    "pythonBase_file_write": "%1 %2 %3 写入 %4",
    "pythonBase_time": "系统时间",
    "pythonBase_get_time": "%1 %2 获取系统时间 %3",
    "pythonBase_get_time_by_year": "年",
    "pythonBase_get_time_by_month": "月",
    "pythonBase_get_time_by_day": "日",
    "pythonBase_get_time_by_hour": "时",
    "pythonBase_get_time_by_minute": "分",
    "pythonBase_get_time_by_second": "秒",
    "pythonBase_get_time_by_day_of_week": "本周的第几天",
    "pythonBase_get_time_by_day_of_year": "今年的第几天",
    "pythonBase_get_time_format": "%1 %2 获取系统时间 %3",
    "pythonBase_get_time_format_by_hour_minute_second": "时:分:秒",
    "pythonBase_get_time_format_by_hour_minute": "时:分",
    "pythonBase_get_time_format_by_year_month_day": "年/月/日",
    "pythonBase_get_time_format_by_year_month": "年/月",
    "pythonBase_get_time_format_by_year_month_day_": "年-月-日",
    "pythonBase_get_time_format_by_year_month_day_hour_minute_second": "年/月/日 时:分:秒",
    "pythonBase_get_timestamp": "%1 %2 获取系统时间戳",
    "pythonBase_get_time_format_custom": "%1 %2 获取系统时间 格式化 (%3) ",
    "pyTimer_setEvent": "%1 %2 设置定时器 %3 %4 周期 %5 毫秒",
    "pyTimer_repeated": "重复执行",
    "pyTimer_delayed": "延时执行",
    "pyTimer_conditionEvent": "%1 %2 设置定时器 %3 :当 %4",
    "pyTimer_getCount": "%1 %2 定时器 %3 的计数值",
    "pyTimer_clearEvent": "%1 %2 清除 定时器/事件 %3",
    "pyDictionary_init": "%1 %2 初始化字典 %3",
    "pyDictionary_getValue": "%1 %2 字典 %3 键 %4 的值",
    "pyDictionary_setValue": "%1 %2 字典 %3 键 %4 的值设置为 %5",
    "pyDictionary_deleteKey": "%1 %2 字典 %3 删除键 %4",
    "pyDictionary_clear": "%1 %2 清空字典 %3",
    "pyDictionary_isInclude": "%1 %2 字典 %3 包含键 %4？",
    "pyDictionary_getLength": "%1 %2 字典 %3 长度",
    "pyDictionary_list": "%1 %2 字典 %3 %4 的列表",
    "pyDictionary_TypeIndex_A": "键",
    "pyDictionary_TypeIndex_B": "值",
    "pyList_join": "%1 %2 列表 %3 使用分隔符 %4 组合文本",
    "pyList_split": "%1 %2 文本 %3 使用分隔符 %4 制作列表",
    "pyList_init": "%1 %2 初始化列表 %3",
    "pyList_clear": "%1 %2 清空列表 %3",
    "pyList_getLength": "%1 %2 列表 %3 的长度",
    "pyList_isEmpty": "%1 %2 列表 %3 是空的？",
    "pyList_getValue": "%1 %2 列表 %3 索引 %4 的值",
    "pyList_getValueRange": "%1 %2 返回列表 %3 取 %4 %5 项到 %6 %7 项",
    "pyList_insert": "%1 %2 列表 %3 在索引 %4 处插入 %5",
    "pyList_setValue": "%1 %2 列表 %3 设置索引 %4 的值为 %5",
    "pyList_delete": "%1 %2 列表 %3 删除索引 %4 的值",
    "pyList_append": "%1 %2 列表 %3 将 %4 添加到末尾",
    "pyList_extend": "%1 %2 列表 %3 追加列表 %4",
    "pyList_findIndex": "%1 %2 列表 %3 查找 %4 的索引",
    "pyList_sort": "%1 %2 列表 %3 按 %4 排序为 %5",
    "pyList_SortModeIndex_A": "升序",
    "pyList_SortModeIndex_B": "降序",
    "pyList_SortTypeIndex_A": "数字",
    "pyList_SortTypeIndex_B": "字母",
    "pyList_SortTypeIndex_C": "字母，忽略大小写",
    "pyList_has": "%1 %2 列表 %3 是否存在 %4 ?",
    "pySet_init": "%1 %2 初始化集合 %3 ",
    "pySet_initEmpty": "%1 %2 初始化空集合",
    "pySet_update": "%1 %2 集合 %3  更新为与集合 %4 的 %5",
    "pySet_TypeIndex_A": "交集",
    "pySet_TypeIndex_B": "并集",
    "pySet_TypeIndex_C": "差集",
    "pySet_remove": "%1 %2 集合 %3 移除 %4",
    "pySet_clear": "%1 %2 清空集合 %3",
    "pySet_copy": "%1 %2 复制集合 %3",
    "pySet_has": "%1 %2 集合 %3 是否存在 %4",
    "pySet_addValue": "%1 %2 集合 %3 添加单个元素 %4",
    "pySet_updateValue": "%1 %2 集合 %3 添加可迭代对象 %4",
    "pySet_isSubsetSuperset": "%1 %2 集合 %3 为集合 %4 的 %5？",
    "pySet_SubsetSupersetType_A": "子集",
    "pySet_SubsetSupersetType_B": "超集",
    "pySet_intersectionUnionSetDifference": "%1 %2 取 %3 集合 %4 集合 %5",
    "pySet_length": "%1 %2 集合 %3 长度",
    "pySet_pop": "%1 %2 返回随机项 并从集合 %3 移除",
    "pyTuple_init": "%1 %2 初始化元组 %3",
    "pyTuple_minMaxLength": "%1 %2 元组 %3 %4",
    "pyTuple_TypeIndex_A": "最小值",
    "pyTuple_TypeIndex_B": "最大值",
    "pyTuple_TypeIndex_C": "长度",
    "pyTuple_isInclude": "%1 %2 元组 %3 包含 %4？",
    "pyTuple_getValueRange": "%1 %2 返回元组 %3 取 %4 %5 项到 %6 %7 项",
    "pyTuple_IndexType_A": "第",
    "pyTuple_IndexType_B": "倒数第",
    "pyTuple_listToTulpe": "%1 %2 列表 %3 转元组",
    "pyTuple_getValue": "%1 %2 元组 %3 索引 %4 的值",
    "pyTuple_tupleToList": "%1 %2 元组 %3 转变为列表",
    "text_decode_encode": "解码/编码",
    "coder": "%1 %2 %3 文本 %4 %5",
    "decode": "解码",
    "encode": "编码",
    "mathop_specially": "%1 %2 %3 转换为 %4",
    "CONTROLS_IF_MSG_IF": "如果",
    "CONTROLS_IF_MSG_ELSEIF": "否则如果",
    "CONTROLS_IF_MSG_ELSE": "否则",
    "CONTROLS_IF_MSG_THEN": "那么"
  },
  "zh-tw": {
    "CONTROL_FOREVER": "重複無限次",
    "CONTROL_LOOP_BREAKCONTINUE": "%1",
    "CONTROL_LOOP_BREAKCONTINUE_BREAK": "中断循环",
    "CONTROL_LOOP_BREAKCONTINUE_CONTINUE": "继续下一次循环",
    "CONTROL_REPEAT": "重複 %1 次",
    "CONTROL_IF": "如果 %1 那麼",
    "CONTROL_ELSE": "否則",
    "CONTROL_STOP": "停止",
    "CONTROL_STOP_ALL": "全部",
    "CONTROL_STOP_THIS": "這個程式",
    "CONTROL_STOP_OTHER": "這個物件的其它程式",
    "CONTROL_WAIT": "等待 %1 秒",
    "CONTROL_WAIT_ESP32_MPY": "等待 %1 %2",
    "CONTROL_FOR_FROM_SEQUENCE": "使用 %1 从序列 %2",
    "CONTROL_FOR_RANGE_SPACE": "使用 %1 从范围 %2 到 %3 每隔 %4",
    "CONTROL_WAIT_ESP32_MPY_S": "秒",
    "CONTROL_WAIT_ESP32_MPY_MS": "毫秒",
    "CONTROL_WAIT_ESP32_MPY_US": "微秒",
    "CONTROL_WAITUNTIL": "等待直到 %1",
    "CONTROL_REPEATUNTIL": "重複直到 %1",
    "CONTROL_WHILE": "當 %1",
    "CONTROL_FOREACH": "計數 %1 於 %2",
    "CONTROL_STARTASCLONE": "當分身產生",
    "CONTROL_CREATECLONEOF": "建立 %1 的分身",
    "CONTROL_CREATECLONEOF_MYSELF": "自己",
    "CONTROL_DELETETHISCLONE": "分身刪除",
    "CONTROL_COUNTER": "計數器",
    "CONTROL_INCRCOUNTER": "計數器累計",
    "CONTROL_CLEARCOUNTER": "計數器重置",
    "CONTROL_ALLATONCE": "全部一起",
    "DATA_SETVARIABLETO": "變數 %1 設為 %2",
    "DATA_CHANGEVARIABLEBY": "變數 %1 改變 %2",
    "DATA_SHOWVARIABLE": "變數 %1 顯示",
    "DATA_HIDEVARIABLE": "變數 %1 隱藏",
    "DATA_ADDTOLIST": "添加 %1 到 %2",
    "DATA_DELETEOFLIST": "刪除 %2 的第 %1 項",
    "DATA_DELETEALLOFLIST": "刪除 %1 的所有項目",
    "DATA_INSERTATLIST": "插入 %1 到 %3 的第 %2 項",
    "DATA_REPLACEITEMOFLIST": "替換 %2 的第 %1 項為 %3",
    "DATA_ITEMOFLIST": "%2 的第 %1 項",
    "DATA_ITEMNUMOFLIST": "%1 在 %2 裡的項目編號",
    "DATA_LENGTHOFLIST": "清單 %1 的長度",
    "DATA_LISTCONTAINSITEM": "清單 %1 包含 %2？",
    "DATA_SHOWLIST": "清單 %1 顯示",
    "DATA_HIDELIST": "清單 %1 隱藏",
    "DATA_INDEX_ALL": "全部",
    "DATA_INDEX_LAST": "末",
    "DATA_INDEX_RANDOM": "隨機",
    "EVENT_WHENFLAGCLICKED": "當 %1 被點擊",
    "EVENT_WHENTHISSPRITECLICKED": "當角色被點擊",
    "EVENT_WHENSTAGECLICKED": "當舞台被點擊",
    "EVENT_WHENTOUCHINGOBJECT": "當角色碰到 %1",
    "EVENT_WHENBROADCASTRECEIVED": "當收到訊息 %1",
    "EVENT_WHENBACKDROPSWITCHESTO": "當背景換成 %1",
    "EVENT_WHENGREATERTHAN": "當 %1 > %2",
    "EVENT_WHENGREATERTHAN_TIMER": "計時器",
    "EVENT_WHENGREATERTHAN_LOUDNESS": "聲音響度",
    "EVENT_BROADCAST": "廣播訊息 %1",
    "EVENT_BROADCASTANDWAIT": "廣播訊息 %1 並等待",
    "EVENT_WHENKEYPRESSED": "當 %1 鍵被按下",
    "EVENT_WHENKEYPRESSED_SPACE": "空白",
    "EVENT_WHENKEYPRESSED_LEFT": "向左",
    "EVENT_WHENKEYPRESSED_RIGHT": "向右",
    "EVENT_WHENKEYPRESSED_DOWN": "向下",
    "EVENT_WHENKEYPRESSED_UP": "向上",
    "EVENT_WHENKEYPRESSED_ANY": "任何",
    "LOOKS_SAYFORSECS": "說出 %1 持續 %2 秒",
    "LOOKS_SAY": "說出 %1",
    "LOOKS_HELLO": "Hello!",
    "LOOKS_THINKFORSECS": "想著 %1 持續 %2 秒",
    "LOOKS_THINK": "想著 %1",
    "LOOKS_HMM": "Hmm...",
    "LOOKS_SHOW": "顯示",
    "LOOKS_HIDE": "隱藏",
    "LOOKS_HIDEALLSPRITES": "隱藏所有角色",
    "LOOKS_EFFECT_COLOR": "顏色",
    "LOOKS_EFFECT_FISHEYE": "魚眼",
    "LOOKS_EFFECT_WHIRL": "漩渦",
    "LOOKS_EFFECT_PIXELATE": "像素化",
    "LOOKS_EFFECT_MOSAIC": "馬賽克",
    "LOOKS_EFFECT_BRIGHTNESS": "亮度",
    "LOOKS_EFFECT_GHOST": "幻影",
    "LOOKS_CHANGEEFFECTBY": "圖像效果 %1 改變 %2",
    "LOOKS_SETEFFECTTO": "圖像效果 %1 設為 %2",
    "LOOKS_CLEARGRAPHICEFFECTS": "圖像效果清除",
    "LOOKS_CHANGESIZEBY": "尺寸改變 %1",
    "LOOKS_SETSIZETO": "尺寸設為 %1 %",
    "LOOKS_SIZE": "尺寸",
    "LOOKS_CHANGESTRETCHBY": "伸縮改變 %1",
    "LOOKS_SETSTRETCHTO": "伸縮設為 %1 %",
    "LOOKS_SWITCHCOSTUMETO": "造型換成 %1",
    "LOOKS_NEXTCOSTUME": "造型換成下一個",
    "LOOKS_SWITCHBACKDROPTO": "背景換成 %1",
    "LOOKS_GOTOFRONTBACK": "圖層移到 %1 層",
    "LOOKS_GOTOFRONTBACK_FRONT": "最上",
    "LOOKS_GOTOFRONTBACK_BACK": "最下",
    "LOOKS_GOFORWARDBACKWARDLAYERS": "圖層 %1 移 %2 層",
    "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "上",
    "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "下",
    "LOOKS_BACKDROPNUMBERNAME": "背景 %1",
    "LOOKS_COSTUMENUMBERNAME": "造型 %1",
    "LOOKS_NUMBERNAME_NUMBER": "編號",
    "LOOKS_NUMBERNAME_NAME": "名稱",
    "LOOKS_SWITCHBACKDROPTOANDWAIT": "背景換成 %1 並等待",
    "LOOKS_NEXTBACKDROP_BLOCK": "背景換成下一個",
    "LOOKS_NEXTBACKDROP": "下一個背景",
    "LOOKS_PREVIOUSBACKDROP": "上一個背景",
    "LOOKS_RANDOMBACKDROP": "任一個背景",
    "MOTION_MOVESTEPS": "移動 %1 點",
    "MOTION_TURNLEFT": "左轉 %1 %2 度",
    "MOTION_TURNRIGHT": "右轉 %1 %2 度",
    "MOTION_POINTINDIRECTION": "面朝 %1 度",
    "MOTION_POINTTOWARDS": "面朝 %1 向",
    "MOTION_POINTTOWARDS_POINTER": "鼠標",
    "MOTION_POINTTOWARDS_RANDOM": "隨機",
    "MOTION_GOTO": "定位到 %1 位置",
    "MOTION_GOTO_POINTER": "鼠標",
    "MOTION_GOTO_RANDOM": "隨機",
    "MOTION_GOTOXY": "定位到 x:%1 y:%2",
    "MOTION_GLIDESECSTOXY": "滑行 %1 秒到 x:%2 y:%3",
    "MOTION_GLIDETO": "滑行 %1 秒到 %2 位置",
    "MOTION_GLIDETO_POINTER": "鼠標",
    "MOTION_GLIDETO_RANDOM": "隨機",
    "MOTION_CHANGEXBY": "x 改變 %1",
    "MOTION_SETX": "x 設為 %1",
    "MOTION_CHANGEYBY": "y 改變 %1",
    "MOTION_SETY": "y 設為 %1",
    "MOTION_IFONEDGEBOUNCE": "碰到邊緣就反彈",
    "MOTION_SETROTATIONSTYLE": "迴轉方式設為 %1",
    "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "左-右",
    "MOTION_SETROTATIONSTYLE_DONTROTATE": "不旋轉",
    "MOTION_SETROTATIONSTYLE_ALLAROUND": "不設限",
    "MOTION_XPOSITION": "x 座標",
    "MOTION_YPOSITION": "y 座標",
    "MOTION_DIRECTION": "方向",
    "MOTION_SCROLLRIGHT": "滾動向右 %1",
    "MOTION_SCROLLUP": "滾動向上 %1",
    "MOTION_ALIGNSCENE": "場景 %1 對齊",
    "MOTION_ALIGNSCENE_BOTTOMLEFT": "左下",
    "MOTION_ALIGNSCENE_BOTTOMRIGHT": "右下",
    "MOTION_ALIGNSCENE_MIDDLE": "中間",
    "MOTION_ALIGNSCENE_TOPLEFT": "左上",
    "MOTION_ALIGNSCENE_TOPRIGHT": "右上",
    "MOTION_XSCROLL": "x 捲軸",
    "MOTION_YSCROLL": "y 捲軸",
    "MOTION_STAGE_SELECTED": "目前選擇的物件是「舞台」：無可用的動作積木",
    "OPERATORS_ADD": "%1 + %2",
    "OPERATORS_SUBTRACT": "%1 - %2",
    "OPERATORS_MULTIPLY": "%1 * %2",
    "OPERATORS_DIVIDE": "%1 / %2",
    "OPERATORS_RANDOM": "隨機取數 %1 到 %2",
    "OPERATORS_GT": "%1 > %2",
    "OPERATORS_GT_EQUALS": "%1 >= %2",
    "OPERATORS_LT": "%1 < %2",
    "OPERATORS_LT_EQUALS": "%1 <= %2",
    "OPERATORS_EQUALS": "%1 = %2",
    "OPERATORS_AND": "%1 且 %2",
    "OPERATORS_OR": "%1 或 %2",
    "OPERATORS_NOT": "%1 不成立",
    "CONTROL_TRUE_FALSE": "%1",
    "CONTROL_TRUE_FALSE_TRUE": "真",
    "CONTROL_TRUE_FALSE_FALSE": "假",
    "CONTROL_NONE": "空",
    "CONTROL_RETURN": "返回 %1",
    "OPERATORS_VALUE_IS_TRUE": "%1 为真？",
    "OPERATORS_VALUE_TYPE": "%1 的类型",
    "OPERATORS_VALUE_TYPE_IS": "%1 的类型为 %2？",
    "OPERATORS_CACULATE_RESULT": "计算表达式 %1 的结果",
    "OPERATORS_VALUE_TYPE_INT": "int",
    "OPERATORS_VALUE_TYPE_FLOAT": "float",
    "OPERATORS_VALUE_TYPE_BOOL": "bool",
    "OPERATORS_VALUE_TYPE_STR": "str",
    "OPERATORS_VALUE_TYPE_LIST": "list",
    "OPERATORS_VALUE_TYPE_TUPLE": "tuple",
    "OPERATORS_VALUE_TYPE_SET": "set",
    "OPERATORS_VALUE_TYPE_DICT": "dict",
    "OPERATORS_VALUE_TYPE_BYTES": "bytes",
    "OPERATORS_VALUE_TYPE_BYTEARRAY": "bytearray",
    "OPERATORS_VALUE_TYPE_COMPLEX": "complex",
    "CONTROL_TRY": "try",
    "CONTROL_EXCEPT": "except",
    "CONTROL_FINALLY": "finally",
    "OPERATORS_JOIN": "字串組合 %1 %2",
    "OPERATORS_JOIN_APPLE": "apple",
    "OPERATORS_JOIN_BANANA": "banana",
    "OPERATORS_LETTEROF": "字串 %2 的第 %1 字",
    "OPERATORS_LETTEROF_APPLE": "a",
    "OPERATORS_LENGTH": "字串 %1 的長度",
    "OPERATORS_CONTAINS": "字串 %1 包含 %2？",
    "OPERATORS_MOD": "%1 除以 %2 的餘數",
    "OPERATORS_ROUND": "四捨五入數值 %1",
    "OPERATORS_MATHOP": "%1 數值 %2",
    "OPERATORS_MATHOP_ABS": "絕對值",
    "OPERATORS_MATHOP_FLOOR": "無條件捨去",
    "OPERATORS_MATHOP_CEILING": "無條件進位",
    "OPERATORS_MATHOP_SQRT": "平方根",
    "OPERATORS_MATHOP_SIN": "sin",
    "OPERATORS_MATHOP_COS": "cos",
    "OPERATORS_MATHOP_TAN": "tan",
    "OPERATORS_MATHOP_ASIN": "asin",
    "OPERATORS_MATHOP_ACOS": "acos",
    "OPERATORS_MATHOP_ATAN": "atan",
    "OPERATORS_MATHOP_LN": "ln",
    "OPERATORS_MATHOP_LOG": "log",
    "OPERATORS_MATHOP_EEXP": "e ^",
    "OPERATORS_MATHOP_10EXP": "10 ^",
    "PROCEDURES_DEFINITION": "定義 %1",
    "SENSING_TOUCHINGOBJECT": "碰到 %1？",
    "SENSING_TOUCHINGOBJECT_POINTER": "鼠標",
    "SENSING_TOUCHINGOBJECT_EDGE": "邊緣",
    "SENSING_TOUCHINGCOLOR": "碰到顏色 %1？",
    "SENSING_COLORISTOUCHINGCOLOR": "顏色 %1 碰到 顏色 %2？",
    "SENSING_DISTANCETO": "與 %1 的間距",
    "SENSING_DISTANCETO_POINTER": "鼠標",
    "SENSING_ASKANDWAIT": "詢問 %1 並等待",
    "SENSING_ASK_TEXT": "你的名字是？",
    "SENSING_ANSWER": "詢問的答案",
    "SENSING_KEYPRESSED": "%1 鍵被按下？",
    "SENSING_MOUSEDOWN": "滑鼠鍵被按下？",
    "SENSING_MOUSEX": "鼠標的 x",
    "SENSING_MOUSEY": "鼠標的 y",
    "SENSING_SETDRAGMODE": "拖曳方式設為 %1",
    "SENSING_SETDRAGMODE_DRAGGABLE": "可拖曳",
    "SENSING_SETDRAGMODE_NOTDRAGGABLE": "不可拖曳",
    "SENSING_LOUDNESS": "聲音響度",
    "SENSING_LOUD": "大聲？",
    "SENSING_TIMER": "計時器",
    "SENSING_RESETTIMER": "計時器重置",
    "SENSING_OF": "%2 的 %1",
    "SENSING_OF_XPOSITION": "x 座標",
    "SENSING_OF_YPOSITION": "y 座標",
    "SENSING_OF_DIRECTION": "方向",
    "SENSING_OF_COSTUMENUMBER": "造型編號",
    "SENSING_OF_COSTUMENAME": "造型名稱",
    "SENSING_OF_SIZE": "尺寸",
    "SENSING_OF_VOLUME": "音量",
    "SENSING_OF_BACKDROPNUMBER": "背景編號",
    "SENSING_OF_BACKDROPNAME": "背景名稱",
    "SENSING_OF_STAGE": "舞台",
    "SENSING_CURRENT": "目前時間的 %1",
    "SENSING_CURRENT_YEAR": "年",
    "SENSING_CURRENT_MONTH": "月",
    "SENSING_CURRENT_DATE": "日",
    "SENSING_CURRENT_DAYOFWEEK": "週",
    "SENSING_CURRENT_HOUR": "時",
    "SENSING_CURRENT_MINUTE": "分",
    "SENSING_CURRENT_SECOND": "秒",
    "SENSING_DAYSSINCE2000": "2000年迄今日數",
    "SENSING_USERNAME": "用戶名稱",
    "SENSING_USERID": "用戶 ID",
    "SOUND_PLAY": "播放音效 %1",
    "SOUND_PLAYUNTILDONE": "播放音效 %1 直到結束",
    "SOUND_STOPALLSOUNDS": "停播所有音效",
    "SOUND_SETEFFECTO": "聲音效果 %1 設為 %2",
    "SOUND_CHANGEEFFECTBY": "聲音效果 %1 改變 %2",
    "SOUND_CLEAREFFECTS": "聲音效果清除",
    "SOUND_EFFECTS_PITCH": "音高",
    "SOUND_EFFECTS_PAN": "聲道左/右",
    "SOUND_CHANGEVOLUMEBY": "音量改變 %1",
    "SOUND_SETVOLUMETO": "音量設為 %1%",
    "SOUND_VOLUME": "音量",
    "SOUND_RECORD": "錄音…",
    "CATEGORY_MOTION": "動作",
    "CATEGORY_LOOKS": "外觀",
    "CATEGORY_SOUND": "音效",
    "CATEGORY_EVENTS": "事件",
    "CATEGORY_CONTROL": "控制",
    "CATEGORY_SENSING": "偵測",
    "CATEGORY_OPERATORS": "運算",
    "CATEGORY_VARIABLES": "變數",
    "CATEGORY_MYBLOCKS": "函式積木",
    "CATEGORY_NUMBERS": "數值",
    "CATEGORY_TEXT": "文本",
    "DUPLICATE": "複製",
    "DUPLICATE_CURRENT": "复制单个",
    "DELETE": "刪除",
    "ADD_COMMENT": "添加註解",
    "REMOVE_COMMENT": "移除註解",
    "DELETE_BLOCK": "刪除積木",
    "DELETE_X_BLOCKS": "刪除 %1 個積木",
    "DELETE_ALL_BLOCKS": "要刪除全部（%1 個）積木？",
    "CLEAN_UP": "整理積木",
    "HELP": "幫助",
    "UNDO": "復原",
    "REDO": "重做",
    "EDIT_PROCEDURE": "編輯",
    "SHOW_PROCEDURE_DEFINITION": "移至定義",
    "WORKSPACE_COMMENT_DEFAULT_TEXT": "說些什麼…",
    "COLOUR_HUE_LABEL": "顏色",
    "COLOUR_SATURATION_LABEL": "彩度",
    "COLOUR_BRIGHTNESS_LABEL": "亮度",
    "CHANGE_VALUE_TITLE": "改成：",
    "RENAME_VARIABLE": "重新命名變數",
    "RENAME_VARIABLE_STRING": "修改字符類型變量名",
    "RENAME_VARIABLE_NUMBER": "修改數字類型變量名",
    "RENAME_VARIABLE_LIST": "修改列表變量名",
    "RENAME_VARIABLE_TITLE": "將變數 %1  重新命名為：",
    "RENAME_VARIABLE_MODAL_TITLE": "重新命名變數",
    "NEW_VARIABLE": "建立一個變數",
    "NEW_VARIABLE_TITLE": "新變數的名稱",
    "VARIABLE_MODAL_TITLE": "新的變數",
    "VARIABLE_ALREADY_EXISTS": "變數名稱「%1」已經被使用。",
    "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "變數名稱「%1」已經被使用在 %2 型別了。",
    "DELETE_VARIABLE_CONFIRMATION": "刪除正在使用的變數 %2 的 %1 個地方？",
    "CANNOT_DELETE_VARIABLE_PROCEDURE": "無法刪除變數 %1，因為它是函式 %2 定義中的一部分。",
    "DELETE_VARIABLE": "刪除變數 %1",
    "DELETE_VARIABLE_STRING": "刪除字符變量「%1」",
    "DELETE_VARIABLE_NUMBER": "刪除數字變量「%1」",
    "DELETE_VARIABLE_LIST": "刪除列表變量「%1」",
    "NEW_PROCEDURE": "建立一個積木",
    "PROCEDURE_ALREADY_EXISTS": "程序名稱「%1」已經被使用。",
    "PROCEDURE_DEFAULT_NAME": "積木名稱",
    "NEW_LIST": "建立一個清單",
    "NEW_LIST_TITLE": "新清單的名稱",
    "LIST_MODAL_TITLE": "新的清單",
    "LIST_ALREADY_EXISTS": "清單名稱「%1」已經被使用。",
    "RENAME_LIST_TITLE": "將清單 %1 重新命名為：",
    "RENAME_LIST_MODAL_TITLE": "重新命名清單",
    "DEFAULT_LIST_ITEM": "thing",
    "DELETE_LIST": "刪除清單 %1",
    "RENAME_LIST": "重新命名清單",
    "NEW_BROADCAST_MESSAGE": "新的訊息",
    "NEW_BROADCAST_MESSAGE_TITLE": "新訊息的名稱",
    "BROADCAST_MODAL_TITLE": "新的訊息",
    "DEFAULT_BROADCAST_MESSAGE_NAME": "message1",
    "DELETE_PROCEDURE": "要刪除自定義積木塊, 首先應移除所有對它的引用",
    "OK": "確定",
    "CANCEL": "取消",
    "PROMPT": "提示",
    "STOP": "停止",
    "NEW_STRING": "新建字符類型變量",
    "NEW_NUMBER_VARIABLE": "新建數字類型變量",
    "WIFI": "無線上網",
    "USERNAME": "wifi名稱",
    "PWD": "wifi密碼",
    "IP": "IP地址",
    "IOT_SERVICE": "物聯網平台參數",
    "IOT_ID": "Iot_id",
    "IOT_PWD": "Iot_pwd",
    "IOT_TOPIC": "Topic_0",
    "MORE_TOPIC": "Topic",
    "OBLOQ_HTTP": "HTTP配置",
    "OBLOQ_IP": "IP地址",
    "OBLOQ_PORT": "端口號",
    "WIFI_VALIDATE": "Wi-Fi密碼必須是8-20位的字母或數字",
    "ADD_TOPIC": "添加Topic, 最多只能添加4個",
    "DELETE_TOPIC": "刪除 Topic",
    "IOT_SERVER": "服務器",
    "CHINA": "中國",
    "GLOBAL": "全球",
    "LOW_C": "1 低 C/C3",
    "LOW_C$": "1# 低 C#/C#3",
    "LOW_D": "2 低 D/D3",
    "LOW_D$": "2# 低 D#/D#3",
    "LOW_E": "3 低 E/E3",
    "LOW_F": "4 低 F/F3",
    "LOW_F$": "4# 低 F#/F#3",
    "LOW_G": "5 低 G/G3",
    "LOW_G$": "5# 低 G#/G#3",
    "LOW_A": "6 低 A/A3",
    "LOW_A$": "6# 低 A#/A#3",
    "LOW_B": "7 低 B/B3",
    "MIDDLE_C": "1 中 C/C4",
    "MIDDLE_C$": "1# 中 C#/C#4",
    "MIDDLE_D": "2 中 D/D4",
    "MIDDLE_D$": "2# 中 D#/D#4",
    "MIDDLE_E": "3 中 E/E4",
    "MIDDLE_F": "4 中 F/F4",
    "MIDDLE_F$": "4# 中 F#/F#4",
    "MIDDLE_G": "5 中 G/G4",
    "MIDDLE_G$": "5# 中 G#/G#4",
    "MIDDLE_A": "6 中 A/A4",
    "MIDDLE_A$": "6# 中 A#/A#4",
    "MIDDLE_B": "7 中 B/B4",
    "HIGH_C": "1 高 C/C5",
    "HIGH_C$": "1# 高 C#/C#5",
    "HIGH_D": "2 高 D/D5",
    "HIGH_D$": "2# 高 D#/D#5",
    "HIGH_E": "3 高 E/E5",
    "HIGH_F": "4 高 F/F5",
    "HIGH_F$": "4# 高 F#/F#5",
    "HIGH_G": "5 高 G/G5",
    "HIGH_G$": "5# 高 G#/G#5",
    "HIGH_A": "6 高 A/A5",
    "HIGH_A$": "6# 高 A#/A#5",
    "HIGH_B": "7 高 B/B5",
    "OPERATORS_STRINGTONUMBER": "將字符串 %1 轉換為 %2",
    "INTEGER": "整数",
    "DECIMAL": "小数",
    "OPERATORS_NUMTOASCII": "將數字 %1 轉換為 ASCII字符",
    "OPERATORS_STRINGTOASCII": "將字符 %1 轉換為 ASCII數值",
    "OPERATORS_NUMTOSTRING": "將數字 %1 轉換為字符串",
    "OPERATORS_MAP": "映射 %1 從[ %2 , %3 ] 到[ %4 , %5 ]",
    "OPERATORS_CONSTRAIN": "約束 %1 介於(最小值) %2 和(最大值) %3 之間",
    "STEPS_PER_TURN": "每轉步數",
    "ROTATE_SPEED": "轉速(r/min)",
    "NEW_AI": "創建AI模型",
    "ASK_AND_PAINT": "請寫一個數字",
    "GET_IDENTIFIED_NUM": "數字識別",
    "READ_NUM_AND_SAY": "說出識別的數字",
    "IMAGE_ADDR": "圖像地址",
    "IMAGE_PREVIEW": "圖像預覽",
    "IMAGE_OPEN": "打開",
    "IMAGE_SIZE": "圖像尺寸",
    "IMAGE_WIDTH": "寬",
    "IMAGE_HEIGHT": "高",
    "VARIABLE": "變量 %1",
    "VARIABLE_LIST": "列表 %1",
    "SET_STRING_VARIABLE": "設置 %1 的值為 %2",
    "STRING_START_WITH": "%1 以 %2 字符串開始",
    "OPERATORS_RANDOMA": "%1 %2 在 %3 到 %4 間取隨機 %5",
    "GLOBAL_MPY_VARIABLE": "global %1",
    "OPERATORS_MODA": "%1 %2 %3 除以 %4 的餘數",
    "OPERATORS_ROUNDA": "%1 %2 四捨五入 %3",
    "OPERATORS_MATHOPA": "%1 %2 %3 %4",
    "OPERATORS_MATHOP_ABSA": "絕對值",
    "OPERATORS_MATHOP_FLOORA": "改進ToSei",
    "OPERATORS_MATHOP_CEILINGA": "向下取整",
    "OPERATORS_MATHOP_SQRTA": "平方根",
    "OPERATORS_MATHOP_FIVE": "%1 %2 將變量 %3 增加 %4",
    "OPERATORS_MATHOP_SIX": "%1 %2 映射 %3 從[ %4 , %5 ] 到[ %6 , %7 ]",
    "OPERATORS_MATHOP_SEVEN": "%1 %2 約束 %3 介於(最小值) %4 和(最大值) %5 之間",
    "OPERATORS_NUM_INPUT": "%1 %2 数字 %3",
    "OPERATORS_NUMS_CACULATE": "%1 %2 %3",
    "OPERATORS_MATHOP_SQUARE": "**",
    "OPERATORS_MATHOP_LOGIC_OR": "|",
    "OPERATORS_MATHOP_LOGIC_AND": "&",
    "OPERATORS_MATHOP_LOGIC_POWER": "^",
    "OPERATORS_MATHOP_LOGIC_LEFT": "<<",
    "OPERATORS_MATHOP_LOGIC_RIGHT": ">>",
    "OPERATORS_SPECIAL_NUM": "%1 %2 %3",
    "OPERATORS_MATHOP_PI": "π",
    "OPERATORS_MATHOP_LOGARITHM": "e",
    "OPERATORS_MATHOP_FAI": "φ",
    "OPERATORS_MATHOP_SQRT2": "sqrt(2)",
    "OPERATORS_MATHOP_SQRTHALF": "sqrt(½)",
    "OPERATORS_MATHOP_UNLIMITED": "∞",
    "OPERATORS_NUMS_TYPE": "%1 %2 %3 %4？",
    "OPERATORS_MATHOP_ISEVEN": "是偶数",
    "OPERATORS_MATHOP_ISODD": "是奇数",
    "OPERATORS_MATHOP_ISINT": "是整数",
    "OPERATORS_MATHOP_ISPOSITIVE": "为正",
    "OPERATORS_MATHOP_ISNEGATIVE": "为负",
    "OPERATORS_SAVE2DECIMAL": "%1 %2 %3 保留 %4 位小数",
    "OPERATORS_LIST_CACULATE": "%1 %2 %3 %4",
    "OPERATORS_MATHOP_LIST_SUM": "列表中的数的总和",
    "OPERATORS_MATHOP_LIST_MIN": "列表中的最小值",
    "OPERATORS_MATHOP_LIST_MAX": "列表中的最大值",
    "OPERATORS_MATHOP_LIST_AVERAGE": "列表中的平均数",
    "OPERATORS_MATHOP_LIST_NUM": "列表中位数",
    "OPERATORS_MATHOP_LIST_MODE": "列表模式",
    "OPERATORS_MATHOP_LIST_STDDEVIATION": "列表中的标准差",
    "OPERATORS_MATHOP_LIST_RANDOM": "列表中的随机项",
    "OPERATORS_QUOTIENT_INT": "%1 %2 %3 ÷ %4 商的整数部分",
    "OPERATORS_RANDOM_DECIMAL": "%1 %2 随机小数",
    "OPERATORS_BIN_NEGATION": "%1 %2 二进制取反 %3",
    "OPERATORS_INT_TO_STR": "%1 %2 10进制整数 %3 转化为 %4 字符串",
    "OPERATORS_MATHOP_INTTOSTR_BIN": "二进制",
    "OPERATORS_MATHOP_INTTOSTR_OCT": "八进制",
    "OPERATORS_MATHOP_INTTOSTR_HEX": "十六进制",
    "OPERATORS_BINSTR_TO_INT": "%1 %2 %3 字符串 %4 转化为10进制整数",
    "OPERATORS_INT_TO_BYTE": "%1 %2 10进制整数 %3 转化为 %4 字节",
    "OPERATORS_MATHOP_INTTOBYTE_TWO": "2",
    "OPERATORS_MATHOP_INTTOBYTE_FOUR": "4",
    "OPERATORS_INT_TO_ASCII": "%1 %2 整数 %3 转换为ASCII单字符",
    "OPERATORS_ASCII_TO_INT": "%1 %2 ASCII单字符 %3 转换为整数",
    "OPERATORS_STR_TO_INT": "%1 %2 字符串 %3 转十六进制字符串",
    "OPERATORS_HEXSTR_TO_BIN": "%1 %2 十六进制字符串 %3 转二进制字节",
    "OPERATORS_STR_TO_BASE64": "%1 %2 %3 以base64 格式编码 返回字节对象",
    "OPERATORS_BASE64_TO_BYTE": "%1 %2 解码base64数据 %3 返回字节对象",
    "OPERATORS_MATHOP_LENGHT": "長度 %1",
    "OPERATORS_MATHOP_MAXMIN": "%1 %2",
    "OPERATORS_MATHOP_MAXMIN_MAX": "最大值",
    "OPERATORS_MATHOP_MAXMIN_MIN": "最小值",
    "OPERATORS_TEXTS_ONE": "%1 %2 合併 %3 %4",
    "OPERATORS_TEXTS_TWO": "%1 %2 獲取 %3 的第 %4 個字符",
    "OPERATORS_TEXTS_THREE": "%1 %2 %3 包含 %4 ?",
    "OPERATORS_TEXTS_FOUR": "%1 %2 %3 獲取 %4 %5 個字符到 %6 %7個字符",
    "OPERATORS_TEXTS_FOUR_NEW": "%1 獲取 %2 %3 個字符到 %4 %5個字符",
    "OPERATORS_TEXTS_NEW": "%1 %2 查找 %3 在 %4 中 %5 出現位置",
    "OPERATORS_TEXTS_NEW_OP": "查找 %1 在 %2 中 %3 出現位置",
    "OPERATORS_TEXTS_FOUR_ONE": "第",
    "OPERATORS_TEXTS_FOUR_TWO": "倒數第",
    "OPERATORS_TEXTS_FOUR_THREE": "第",
    "OPERATORS_TEXTS_FOUR_FOUR": "倒數第",
    "OPERATORS_TEXTS_FOUR_F": "首次",
    "OPERATORS_TEXTS_FOUR_L": "最後一次",
    "OPERATORS_INPUTSTRING": "%1 %2 文本 %3",
    "OPERATORS_TRANSINTOTEXT": "%1 %2 转为文本 %3",
    "OPERATORS_TRANSINTOTEXT_ADD": "加入",
    "OPERATORS_TRANSINTOTEXT_ADD_ITEM": "项",
    "OPERATORS_FORMATSTRING2F": "%1 %2 格式化字符串 %3 % %4",
    "OPERATORS_FORMATSTRING3": "%1 %2 格式化字符串 %3 format %4",
    "OPERATORS_TEXT_ESCAPE": "%1 %2 文本转义字符 %3",
    "BAR_N": "\\n",
    "BAR_NN": "\\n\\r",
    "BAR_R": "\\r",
    "BAR_RN": "\\r\\n",
    "BAR_B": "\\b",
    "BAR_T": "\\t",
    "BAR_BAR": "\\\\",
    "OPERATORS_TEXT_NUMORLETTER": "%1 %2 %3 %4？",
    "OPERATORS_TEXT_NUMORLETTER_NUM": "是数字",
    "OPERATORS_TEXT_NUMORLETTER_LETTER": "是字母",
    "OPERATORS_TEXT_LENGTH": "%1 %2 %3 的长度",
    "OPERATORS_TEXT_ISNONE": "%1 %2 %3 是空的？",
    "OPERATORS_FROMTEXT_GETCHAR": "%1 %2 从文本 %3 %4",
    "OPERATORS_FROMTEXT_GETCHAR_FIRST": "获得第一个字符",
    "OPERATORS_FROMTEXT_GETCHAR_LAST": "获得最后一个字符",
    "OPERATORS_FROMTEXT_GETCHAR_RANDOM": "获取随机的字母",
    "OPERATORS_TEXT_UPPLOW": "%1 %2 %3 %4",
    "OPERATORS_TEXT_UPPLOW_UPP": "转为大写",
    "OPERATORS_TEXT_UPPLOW_LOW": "转为小写",
    "OPERATORS_TEXT_SIDES_SPACE": "%1 %2 %3 %4",
    "OPERATORS_TEXT_SIDES_SPACE_BOTH": "清除两侧空格",
    "OPERATORS_TEXT_SIDES_SPACE_LEFT": "清除左侧空格",
    "OPERATORS_TEXT_SIDES_SPACE_RIGHT": "清除右侧空格",
    "OPERATORS_TEXT_STRTOBYTE": "%1 %2 转字节 %3",
    "OPERATORS_TEXT_BYTETOSTR": "%1 %2 字节 %3 转字符串",
    "OPERATORS_TEXT_DICTTOJSONSTR": "%1 %2 字典 %3 转json字符串",
    "OPERATORS_TEXT_PARSEJSONSTR": "%1 %2 解析json字符串 %3 返回对象",
    "OPERATORS_STRINGTONUMBERA": "%1 %2 將字符串 %3 轉換為 %4",
    "OPERATORS_NUMTOASCIIA": "%1 %2 將數字 %3 轉換為 ASCII字符",
    "OPERATORS_STRINGTOASCIIA": "%1 %2 將字符 %3 轉換為 ASCII數值",
    "OPERATORS_NUMTOSTRINGA": "%1 %2 將數字 %3 轉換為字符串",
    "NEW_MC": "新建變量",
    "RENAME_MCNUMBER_TITLE": "重命名所有變量名為 [%1] 的變量為:",
    "RENAME_MCNUMBER_MODAL_TITLE": "重命名變量",
    "RENAME_VARIABLE_MCNUMBER": "重命名變量",
    "DELETE_VARIABLE_MCNUMBER": "刪除變量 [%1]",
    "SET_MC_VARIABLE": "將變量 %1 賦值為 %2",
    "ALIYUN": "阿里雲",
    "ONENET": "OneNet",
    "ONENETNEW": "OneNet(新版)",
    "EASYIOT": "易IoT",
    "SIOT": "SIoT",
    "SHANGHAI": "上海",
    "QINGDAO": "青島",
    "BEIJING": "北京",
    "ZHANGJIAKOU": "張家口",
    "HUHEHAOTE": "呼和浩特",
    "HANGZHOU": "杭州",
    "SHENZHEN": "深圳",
    "HONGKONG": "香港",
    "SINGAPORE": "新加坡",
    "SYDNEY": "悉尼",
    "KUALALUMPUR": "吉隆坡",
    "JAKARTA": "雅加達",
    "MUMBAI": "孟買",
    "TOKYO": "東京",
    "SILICONVALLEY": "矽谷",
    "VIRGINIA": "弗吉尼亞",
    "FRANKFURT": "法蘭克福",
    "LONDON": "倫敦",
    "DUBAI": "迪拜",
    "IOT_PLATFORM": "物聯網平台",
    "PARAMS": "參數",
    "SERVER_ATTR": "服務器地址",
    "PRODUCTID": "產品ID",
    "DEVICEID": "設備Id",
    "DEVICENAME": "设备名称",
    "TINYDB_SECRET": "密鑰 (secret)",
    "TINYDB_USER": "用戶名 (user)",
    "APIADDR": "API地址",
    "SOFTWARE_SERIAL": "軟串口",
    "HARDWARE_SERIAL": "硬串口",
    "HARDWARE_SERIAL1": "硬串口1",
    "HARDWARE_SERIAL2": "硬串口2",
    "HARDWARE_SERIAL3": "硬串口3",
    "CHECKTYPE_TIPS": "輸入框接收的數據類型和積木塊的輸出類型不匹配",
    "HIGHLIGHT_BLOCK": "高亮",
    "HIGHLIGHT_ALL_CONFLICT_BLOCKS": "高亮全部有警告的積木塊",
    "SNAPSHOT": "截圖（CTRL+G）",
    "pictureaiPath": "請輸入圖片路徑或網址",
    "pictureaiType": "賬戶類型",
    "pictureaiAccountDefault": "默認賬戶",
    "pictureaiAccountBaidu": "百度",
    "pictureaiwebImgAddr": "網絡圖片地址",
    "MITRIXICON_EDIT": "点亮",
    "MITRIXICON_ERASER": "擦除",
    "MITRIXICON_DUSTBIN": "清空",
    "MITRIXICON_REVERSE": "反色",
    "MITRIXICON_COLOR": "选择颜色",
    "RED": "红",
    "GREEN": "绿",
    "BLUE": "蓝",
    "SEARCH_BLOCKS": "搜索积木",
    "NO_SEARCH_BLOCKS": "没有搜索积木",
    "SEARCH_DEFAULT_LABEL_THINK": "思考",
    "SEARCH_DEFAULT_LABEL_IF": "如果",
    "SEARCH_DEFAULT_LABEL_WAIT": "等待",
    "SEARCH_DEFAULT_LABEL_CONVERT": "转换",
    "SEARCH_DEFAULT_LABEL_BROADCAST": "广播",
    "MITRIXICON_SAVE": "保存",
    "MITRIXICON_EXPORT": "导出点阵图案",
    "MITRIXICON_UPLOAD": "导入点阵图案",
    "ROLL_MOUSE": "滚动鼠标滑动",
    "MOUSE_ZOOM_IN_OUT": "滚动鼠标放大缩小",
    "UPLOAD_CORRECT_JSON_FILE": "请上传正确格式的json文件",
    "EXPORT_PROMPT": "没有要导出的自定义图案。请先点击右下角的保存按钮，保存自定义的图案后，再尝试导出",
    "HEART": "心形",
    "HEART_SMALL": "心形(小)",
    "HAPPY": "高兴",
    "SMILE": "笑脸",
    "SAD": "伤心",
    "CONFUSED": "困惑",
    "ANGRY": "生气",
    "SLEEP": "睡着",
    "SURPRISED": "惊讶",
    "SILLY": "愚蠢",
    "WONDERFUL": "极妙",
    "BORED": "无聊",
    "ROCK": "石头",
    "ROCK_SMALL": "石头(小)",
    "PAPER": "布",
    "PAPER_SMALL": "布(小)",
    "SCISSORS": "剪刀",
    "SCISSORS_SMALL": "剪刀(小)",
    "DATA_SOLITLIST": "%1 %2 %3 %4 用分隔符 %5",
    "DATA_SOLITLIST_TYPE_LT": "从列表组合文本",
    "DATA_SOLITLIST_TYPE_TL": "从文本制作列表",
    "DATA_LENLIST": "%1 %2 列表 %3 是空的？",
    "DATA_LENGTHLIST": "%1 %2 列表 %3 的长度",
    "DATA_CREATELIST": "%1 %2 初始化列表 %3",
    "DATA_CLEARLIST": "%1 %2 清空列表 %3",
    "ML5MODALBUTTON": "打开AI可视化窗口",
    "COLLAPSE_BLOCK": "折叠",
    "EXPAND_BLOCK": "展开",
    "COLLAPSE_ALL": "折叠所有积木",
    "EXPAND_ALL": "展开所有积木",
    "pyTimer": "定时器",
    "pyList": "列表",
    "pyDictionary": "字典",
    "pySet": "集合",
    "mpyTuple": "元组",
    "pythonBase_printAndInput": "输入输出",
    "pythonBase_mainSetUp": "%1 %2 Python主程序开始",
    "pythonBase_print": "%1 %2 打印 %3",
    "pythonBase_input": "%1 %2 获取输入，提示语为 %3",
    "pythonBase_file": "文件功能",
    "pythonBase_file_open": "%1 %2 以 %3 格式 %4 模式打开文件 %5 并返回 %6",
    "pythonBase_file_open_b": "%1 %2 以 %3 模式打开文件 %4 并返回 %5",
    "pythonBase_file_open_read": "读取",
    "pythonBase_file_open_write": "写入",
    "pythonBase_file_open_add": "追加",
    "pythonBase_file_open_read_bit": "二进制读取",
    "pythonBase_file_open_write_bit": "二进制写入",
    "pythonBase_file_open_add_bit": "二进制追加",
    "pythonBase_file_close": "%1 %2 %3 关闭",
    "pythonBase_file_read": "%1 %2 %3 读取 %4",
    "pythonBase_file_read_all": "整个文件",
    "pythonBase_file_read_line": "一行内容",
    "pythonBase_file_read_lines": "整个文件所有行",
    "pythonBase_file_write": "%1 %2 %3 写入 %4",
    "pythonBase_time": "系统时间",
    "pythonBase_get_time": "%1 %2 获取系统时间 %3",
    "pythonBase_get_time_by_year": "年",
    "pythonBase_get_time_by_month": "月",
    "pythonBase_get_time_by_day": "日",
    "pythonBase_get_time_by_hour": "时",
    "pythonBase_get_time_by_minute": "分",
    "pythonBase_get_time_by_second": "秒",
    "pythonBase_get_time_by_day_of_week": "本周的第几天",
    "pythonBase_get_time_by_day_of_year": "今年的第几天",
    "pythonBase_get_time_format": "%1 %2 获取系统时间 %3",
    "pythonBase_get_time_format_by_hour_minute_second": "时:分:秒",
    "pythonBase_get_time_format_by_hour_minute": "时:分",
    "pythonBase_get_time_format_by_year_month_day": "年/月/日",
    "pythonBase_get_time_format_by_year_month": "年/月",
    "pythonBase_get_time_format_by_year_month_day_": "年-月-日",
    "pythonBase_get_time_format_by_year_month_day_hour_minute_second": "年/月/日 时:分:秒",
    "pythonBase_get_timestamp": "%1 %2 获取系统时间戳",
    "pythonBase_get_time_format_custom": "%1 %2 获取系统时间 格式化 (%3) ",
    "pyTimer_setEvent": "%1 %2 设置定时器 %3 %4 周期 %5 毫秒",
    "pyTimer_repeated": "重复执行",
    "pyTimer_delayed": "延时执行",
    "pyTimer_conditionEvent": "%1 %2 设置定时器 %3 :当 %4",
    "pyTimer_getCount": "%1 %2 定时器 %3 的计数值",
    "pyTimer_clearEvent": "%1 %2 清除 定时器/事件 %3",
    "pyDictionary_init": "%1 %2 初始化字典 %3",
    "pyDictionary_getValue": "%1 %2 字典 %3 键 %4 的值",
    "pyDictionary_setValue": "%1 %2 字典 %3 键 %4 的值设置为 %5",
    "pyDictionary_deleteKey": "%1 %2 字典 %3 删除键 %4",
    "pyDictionary_clear": "%1 %2 清空字典 %3",
    "pyDictionary_isInclude": "%1 %2 字典 %3 包含键 %4？",
    "pyDictionary_getLength": "%1 %2 字典 %3 长度",
    "pyDictionary_list": "%1 %2 字典 %3 %4 的列表",
    "pyDictionary_TypeIndex_A": "键",
    "pyDictionary_TypeIndex_B": "值",
    "pyList_join": "%1 %2 列表 %3 使用分隔符 %4 组合文本",
    "pyList_split": "%1 %2 文本 %3 使用分隔符 %4 制作列表",
    "pyList_init": "%1 %2 初始化列表 %3",
    "pyList_clear": "%1 %2 清空列表 %3",
    "pyList_getLength": "%1 %2 列表 %3 的长度",
    "pyList_isEmpty": "%1 %2 列表 %3 是空的？",
    "pyList_getValue": "%1 %2 列表 %3 索引 %4 的值",
    "pyList_getValueRange": "%1 %2 返回列表 %3 取 %4 %5 项到 %6 %7 项",
    "pyList_insert": "%1 %2 列表 %3 在索引 %4 处插入 %5",
    "pyList_setValue": "%1 %2 列表 %3 设置索引 %4 的值为 %5",
    "pyList_delete": "%1 %2 列表 %3 删除索引 %4 的值",
    "pyList_append": "%1 %2 列表 %3 将 %4 添加到末尾",
    "pyList_extend": "%1 %2 列表 %3 追加列表 %4",
    "pyList_findIndex": "%1 %2 列表 %3 查找 %4 的索引",
    "pyList_sort": "%1 %2 列表 %3 按 %4 排序为 %5",
    "pyList_SortModeIndex_A": "升序",
    "pyList_SortModeIndex_B": "降序",
    "pyList_SortTypeIndex_A": "数字",
    "pyList_SortTypeIndex_B": "字母",
    "pyList_SortTypeIndex_C": "字母，忽略大小写",
    "pyList_has": "%1 %2 列表 %3 是否存在 %4 ?",
    "pySet_init": "%1 %2 初始化集合 %3 ",
    "pySet_initEmpty": "%1 %2 初始化空集合",
    "pySet_update": "%1 %2 集合 %3  更新为与集合 %4 的 %5",
    "pySet_TypeIndex_A": "交集",
    "pySet_TypeIndex_B": "并集",
    "pySet_TypeIndex_C": "差集",
    "pySet_remove": "%1 %2 集合 %3 移除 %4",
    "pySet_clear": "%1 %2 清空集合 %3",
    "pySet_copy": "%1 %2 复制集合 %3",
    "pySet_has": "%1 %2 集合 %3 是否存在 %4",
    "pySet_addValue": "%1 %2 集合 %3 添加单个元素 %4",
    "pySet_updateValue": "%1 %2 集合 %3 添加可迭代对象 %4",
    "pySet_isSubsetSuperset": "%1 %2 集合 %3 为集合 %4 的 %5？",
    "pySet_SubsetSupersetType_A": "子集",
    "pySet_SubsetSupersetType_B": "超集",
    "pySet_intersectionUnionSetDifference": "%1 %2 取 %3 集合 %4 集合 %5",
    "pySet_length": "%1 %2 集合 %3 长度",
    "pySet_pop": "%1 %2 返回随机项 并从集合 %3 移除",
    "pyTuple_init": "%1 %2 初始化元组 %3",
    "pyTuple_minMaxLength": "%1 %2 元组 %3 %4",
    "pyTuple_TypeIndex_A": "最小值",
    "pyTuple_TypeIndex_B": "最大值",
    "pyTuple_TypeIndex_C": "长度",
    "pyTuple_isInclude": "%1 %2 元组 %3 包含 %4？",
    "pyTuple_getValueRange": "%1 %2 返回元组 %3 取 %4 %5 项到 %6 %7 项",
    "pyTuple_IndexType_A": "第",
    "pyTuple_IndexType_B": "倒数第",
    "pyTuple_listToTulpe": "%1 %2 列表 %3 转元组",
    "pyTuple_getValue": "%1 %2 元组 %3 索引 %4 的值",
    "pyTuple_tupleToList": "%1 %2 元组 %3 转变为列表",
    "text_decode_encode": "解码/编码",
    "coder": "%1 %2 %3 文本 %4 %5",
    "decode": "解码",
    "encode": "编码",
    "mathop_specially": "%1 %2 %3 转换为 %4"
  },
  "mn": {
    "CONTROL_FOREVER": "ᠳᠠᠪᠲᠠᠨ ᠠᠵᠢᠯᠯᠠᠭᠤᠯᠬᠤ",
    "CONTROL_LOOP_BREAKCONTINUE": "%1",
    "CONTROL_LOOP_BREAKCONTINUE_BREAK": "中断循环",
    "CONTROL_LOOP_BREAKCONTINUE_CONTINUE": "继续下一次循环",
    "CONTROL_REPEAT": "%1ᠤᠳᠠᠭ᠎ᠠ ᠳᠠᠪᠲᠠᠨ ᠠᠵᠢᠯᠯᠠᠭᠤᠯᠬᠤ",
    "CONTROL_IF": "ᠬᠡᠷᠪᠡ %1  ᠪᠣᠯ",
    "CONTROL_ELSE": " ᠡᠰᠡᠬᠦᠯ᠎ᠡ",
    "CONTROL_STOP": " ᠵᠣᠭᠰᠣᠭᠠᠬᠤ",
    "CONTROL_STOP_ALL": " ᠪᠦᠭᠦᠳᠡ ᠶᠢ ᠵᠣᠭᠰᠣᠭᠠᠬᠤ",
    "CONTROL_STOP_THIS": " ᠲᠤᠰ ᠪᠢᠴᠢᠯᠭᠡ ",
    "CONTROL_STOP_OTHER": " ᠲᠤᠰ ᠳᠦᠷᠢ ᠶᠢᠨ ᠪᠤᠰᠤᠳ  ᠪᠢᠴᠢᠯᠭᠡ ",
    "CONTROL_WAIT": "%1  ᠰᠧᠬᠦ᠋ᠨᠳ᠋ ᠬᠦᠯᠢᠶᠡᠨ᠎ᠡ",
    "CONTROL_WAIT_ESP32_MPY": "等待 %1 %2",
    "CONTROL_FOR_FROM_SEQUENCE": "use %1 from sequence %2",
    "CONTROL_FOR_RANGE_SPACE": "使用 %1 从范围 %2 到 %3 每隔 %4",
    "CONTROL_WAIT_ESP32_MPY_S": "秒",
    "CONTROL_WAIT_ESP32_MPY_MS": "毫秒",
    "CONTROL_WAIT_ESP32_MPY_US": "微秒",
    "CONTROL_WAITUNTIL": "%1 ᠰᠧᠬᠦ᠋ᠨᠳ᠋ ᠬᠦᠷᠲᠡᠯ᠎ᠡ ᠬᠦᠯᠢᠶᠡᠨ᠎ᠡ",
    "CONTROL_REPEATUNTIL": "%1 ᠬᠦᠷᠲᠡᠯ᠎ᠡ ᠳᠠᠪᠲᠠᠨ ᠠᠵᠢᠯᠯᠠᠭᠤᠯᠬᠤ",
    "CONTROL_WHILE": "ᠬᠡᠷᠪᠡ %1  ᠪᠣᠯ ᠳᠠᠪᠲᠠᠨ ᠳᠠᠪᠲᠠᠨ ᠠᠵᠢᠯᠯᠠᠭᠤᠯᠬᠤ",
    "CONTROL_FOREACH": "%2  ᠶᠢᠨ %1 ᠨᠢᠭᠡᠪᠦᠷᠢ ᠳᠦ",
    "CONTROL_STARTASCLONE": " ᠺᠤᠯᠦᠨ ᠪᠡᠶ᠎ᠡ ᠪᠣᠯᠭᠠᠨ ᠠᠵᠢᠯᠯᠠᠭᠤᠯᠬᠤ ᠦᠶᠡᠰ",
    "CONTROL_CREATECLONEOF": " ᠬᠠᠭᠤᠯᠪᠤᠷᠢᠳᠠᠬᠤ %1",
    "CONTROL_CREATECLONEOF_MYSELF": " ᠥᠪᠡᠷᠲᠡᠭᠡᠨ",
    "CONTROL_DELETETHISCLONE": " ᠲᠤᠰ ᠬᠠᠭᠤᠯᠪᠤᠷᠢ ᠪᠡᠶ᠎ᠡ ᠶᠢ ᠬᠠᠰᠤᠬᠤ",
    "CONTROL_COUNTER": " ᠲᠣᠭᠠᠯᠠᠭᠤᠷ",
    "CONTROL_INCRCOUNTER": " ᠨᠡᠮᠡᠬᠦ ᠲᠣᠭᠠᠯᠠᠭᠤᠷ",
    "CONTROL_CLEARCOUNTER": " ᠲᠣᠭᠠᠯᠠᠭᠤᠷ  ᠢ ᠠᠷᠢᠯᠭᠠᠬᠤ",
    "CONTROL_ALLATONCE": " ᠪᠦᠬᠦ ᠪᠢᠴᠢᠯᠭᠡ",
    "DATA_SETVARIABLETO": "%1 ᠶᠢᠨ ᠬᠡᠮᠵᠢᠭᠳᠡᠯ ᠢ %2 ᠪᠣᠯᠭᠠᠨ ᠲᠣᠬᠢᠷᠠᠭᠤᠯᠬᠤ",
    "DATA_CHANGEVARIABLEBY": "%1 ᠶᠢ  %2 ᠪᠣᠯᠭᠠᠨ ᠨᠡᠮᠡᠭᠳᠡᠭᠦᠯᠬᠦ",
    "DATA_SHOWVARIABLE": "ᠬᠤᠪᠢᠰᠬᠤᠨ %1 ᠶᠢ ᠢᠯᠡᠷᠡᠭᠦᠯᠬᠦ",
    "DATA_HIDEVARIABLE": "ᠬᠤᠪᠢᠰᠬᠤᠨ%1  ᠶᠢ ᠨᠢᠭᠤᠬᠤ",
    "DATA_ADDTOLIST": "%1  ᠶᠢ %2 ᠲᠤ ᠨᠡᠮᠡᠬᠦ",
    "DATA_DELETEOFLIST": "%2 ᠳᠡᠬᠢ %1  ᠶᠢ ᠬᠠᠰᠤᠬᠤ",
    "DATA_DELETEALLOFLIST": "%1 ᠳᠠᠬᠢ ᠪᠦᠭᠦᠳᠡ ᠲᠥᠰᠥᠯ ᠢ ᠬᠠᠰᠤᠬᠤ",
    "DATA_INSERTATLIST": "%3  ᠶᠢᠨ %2  ᠤᠨ ᠡᠮᠦᠨ᠎ᠡ %1  ᠶᠢ ᠬᠠᠪᠴᠢᠭᠤᠯᠬᠤ",
    "DATA_REPLACEITEMOFLIST": "%2 ᠤᠨ %1 ᠳ᠋ᠦ᠋ᠭᠡᠷ ᠢ %3 ᠪᠣᠯᠭᠠᠨ ᠰᠣᠯᠢᠬᠤ",
    "DATA_ITEMOFLIST": "%2 ᠤᠨ %1 ᠳ᠋ᠦ᠋ᠭᠡᠷ",
    "DATA_ITEMNUMOFLIST": "%2 ᠦᠨ %1 ᠳ᠋ᠦ᠋ᠭᠡᠷ ᠦᠨ ᠨᠣᠮᠧᠷ",
    "DATA_LENGTHOFLIST": "%1 ᠤᠨ ᠲᠥᠰᠥᠯ ᠦᠨ ᠲᠣᠭ᠎ᠠ",
    "DATA_LISTCONTAINSITEM": "%1 ᠨᠢ %2 ᠲᠤ ᠬᠠᠷᠢᠶᠠᠯᠠᠭᠳᠠᠬᠤ ᠦᠦ?",
    "DATA_SHOWLIST": "ᠵᠢᠭᠰᠠᠭᠠᠯᠲᠠ %1 ᠶᠢ ᠢᠯᠡᠷᠡᠭᠦᠯᠬᠦ",
    "DATA_HIDELIST": "ᠵᠢᠭᠰᠠᠭᠠᠯᠲᠠ %1 ᠶᠢ ᠨᠢᠭᠤᠬᠤ",
    "DATA_INDEX_ALL": " ᠪᠦᠭᠦᠳᠡ",
    "DATA_INDEX_LAST": " ᠠᠳᠠᠭ",
    "DATA_INDEX_RANDOM": " ᠲᠠᠰᠢᠷᠠᠮ",
    "EVENT_WHENFLAGCLICKED": "%1 ᠳᠣᠶ᠋ᠢᠭᠳᠠᠬᠤ ᠦᠶᠡᠰ",
    "EVENT_WHENTHISSPRITECLICKED": " ᠳᠦᠷᠢ ᠳᠣᠶ᠋ᠢᠭᠳᠠᠬᠤ ᠦᠶᠡᠰ",
    "EVENT_WHENSTAGECLICKED": " ᠲᠠᠢᠰᠠ ᠳᠣᠶ᠋ᠢᠭᠳᠠᠬᠤ ᠦᠶᠡᠰ",
    "EVENT_WHENTOUCHINGOBJECT": "ᠲᠤᠰ ᠳᠦᠷᠢ %1 ᠲᠠᠢ ᠤᠴᠠᠷᠠᠬᠤ ᠦᠶᠡᠰ",
    "EVENT_WHENBROADCASTRECEIVED": "%1 ᠶᠢ ᠬᠦᠯᠢᠶᠡᠨ ᠠᠪᠬᠤ ᠦᠶᠡᠰ",
    "EVENT_WHENBACKDROPSWITCHESTO": "ᠠᠷᠤ ᠦᠵᠡᠭᠳᠡᠯ ᠢ %1 ᠪᠣᠯᠭᠠᠨ ᠰᠣᠯᠢᠬᠤ ᠦᠶᠡᠰ",
    "EVENT_WHENGREATERTHAN": "%1 > %2 ᠦᠶᠡᠰ",
    "EVENT_WHENGREATERTHAN_TIMER": " ᠴᠠᠭ ᠲᠣᠭᠠᠯᠠᠭᠤᠷ",
    "EVENT_WHENGREATERTHAN_LOUDNESS": " ᠳᠤᠤᠭᠠᠷᠴᠠ",
    "EVENT_BROADCAST": "%1ᠨᠡᠪᠲᠡᠷᠡᠭᠦᠯᠭᠡ",
    "EVENT_BROADCASTANDWAIT": "%1 ᠨᠡᠪᠲᠡᠷᠡᠭᠦᠯᠬᠦ  ᠮᠥᠷᠲᠡᠭᠡᠨ ᠬᠦᠯᠢᠶᠡᠬᠦ",
    "EVENT_WHENKEYPRESSED": "%1  ᠳᠠᠷᠤᠪᠴᠢ ᠶᠢ ᠳᠣᠶ᠋ᠢᠬᠤ ᠦᠶᠡᠰ",
    "EVENT_WHENKEYPRESSED_SPACE": "ᠵᠠᠢ",
    "EVENT_WHENKEYPRESSED_LEFT": "←",
    "EVENT_WHENKEYPRESSED_RIGHT": "→",
    "EVENT_WHENKEYPRESSED_DOWN": "↓",
    "EVENT_WHENKEYPRESSED_UP": "↑",
    "EVENT_WHENKEYPRESSED_ANY": " ᠳᠤᠷ᠎ᠠ ᠶᠢᠨ",
    "LOOKS_SAYFORSECS": "%1   ᠢ %2  ᠰᠸ᠋ᠺᠦᠢ᠌ᠨ᠋ᠲ ᠶᠠᠷᠢᠬᠤ",
    "LOOKS_SAY": "%1   ᠢ ᠬᠡᠯᠡᠬᠦ",
    "LOOKS_HELLO": " ᠰᠠᠢᠨ ᠤᠤ",
    "LOOKS_THINKFORSECS": "%1   ᠢ %2 ᠰᠸ᠋ᠺᠦᠢ᠌ᠨ᠋ᠲ ᠪᠣᠳᠣᠯᠬᠢᠯᠠᠬᠤ",
    "LOOKS_THINK": "%1 ᠪᠣᠳᠣᠯᠬᠢᠯᠠᠬᠤ",
    "LOOKS_HMM": " ᠬᠡᠩ...",
    "LOOKS_SHOW": " ᠢᠯᠡᠷᠡᠭᠦᠯᠬᠦ",
    "LOOKS_HIDE": " ᠳᠠᠯᠳᠠᠯᠠᠬᠤ",
    "LOOKS_HIDEALLSPRITES": " ᠪᠦᠬᠦ ᠳᠦᠷᠢ ᠶᠢ ᠳᠠᠯᠳᠠᠯᠠᠬᠤ",
    "LOOKS_EFFECT_COLOR": " ᠥᠩᠭᠡ",
    "LOOKS_EFFECT_FISHEYE": " ᠵᠢᠭᠠᠰᠤᠨ ᠤ ᠨᠢᠳᠦ",
    "LOOKS_EFFECT_WHIRL": " ᠡᠷᠭᠢᠭᠦᠯᠭᠡ",
    "LOOKS_EFFECT_PIXELATE": " ᠫᠢᠺᠰᠸ᠋ᠯᠵᠢᠭᠦᠯᠦᠯᠲᠡ",
    "LOOKS_EFFECT_MOSAIC": " ᠴᠣᠬᠤᠷ",
    "LOOKS_EFFECT_BRIGHTNESS": " ᠭᠡᠷᠡᠯᠳᠦᠴᠡ ᠶᠢᠨ ᠬᠡᠮᠵᠢᠶ᠎ᠡ",
    "LOOKS_EFFECT_GHOST": " ᠬᠠᠭᠤᠷᠮᠠᠭ ᠳᠦᠷᠢ",
    "LOOKS_CHANGEEFFECTBY": "%1 ᠵᠠᠰᠠᠪᠤᠷᠢ ᠶᠢ %2  ᠨᠡᠮᠡᠭᠳᠡᠭᠦᠯᠬᠦ",
    "LOOKS_SETEFFECTTO": "%1 ᠵᠠᠰᠠᠪᠤᠷᠢ ᠶᠢ%2 ᠪᠣᠯᠭᠠᠨ ᠳᠣᠬᠢᠷᠠᠭᠤᠯᠬᠤ",
    "LOOKS_CLEARGRAPHICEFFECTS": " ᠵᠢᠷᠤᠭ ᠤᠨ ᠵᠠᠰᠠᠪᠤᠷᠢ ᠶᠢ ᠤᠰᠠᠳᠬᠠᠬᠤ",
    "LOOKS_CHANGESIZEBY": "ᠶᠡᠬᠡ ᠪᠠᠭ᠎ᠠ  ᠶᠢ%1 ᠨᠡᠮᠡᠭᠳᠡᠭᠦᠯᠬᠦ",
    "LOOKS_SETSIZETO": "ᠶᠡᠬᠡ ᠪᠠᠭ᠎ᠠ ᠶᠢ %1 % ᠪᠣᠯᠭᠠᠬᠤ",
    "LOOKS_SIZE": " ᠶᠡᠬᠡ ᠪᠠᠭ᠎ᠠ",
    "LOOKS_CHANGESTRETCHBY": "%1 ᠠᠪᠴᠢᠭᠤᠯᠬᠤ",
    "LOOKS_SETSTRETCHTO": "ᠠᠪᠴᠢᠭᠤᠯᠬᠤ ᠶᠢ%1 % ᠪᠣᠯᠭᠠᠨ ᠳᠣᠬᠢᠷᠠᠭᠤᠯᠬᠤ",
    "LOOKS_SWITCHCOSTUMETO": "%1 ᠵᠠᠭᠪᠤᠷ ᠢ ᠰᠣᠯᠢᠬᠤ",
    "LOOKS_NEXTCOSTUME": " ᠳᠠᠷᠠᠭᠠᠬᠢ ᠵᠠᠭᠪᠤᠷ",
    "LOOKS_SWITCHBACKDROPTO": "ᠠᠷᠤ ᠦᠵᠡᠭᠳᠡᠯ%1 ᠢ ᠰᠣᠯᠢᠬᠤ",
    "LOOKS_GOTOFRONTBACK": "ᠬᠠᠮᠤᠭ%1  ᠳᠤ ᠰᠢᠯᠵᠢᠬᠦ",
    "LOOKS_GOTOFRONTBACK_FRONT": " ᠡᠮᠦᠨ᠎ᠡ ᠲᠠᠯ᠎ᠠ",
    "LOOKS_GOTOFRONTBACK_BACK": " ᠠᠷᠤ ᠲᠠᠯ᠎ᠠ",
    "LOOKS_GOFORWARDBACKWARDLAYERS": "%1 %2  ᠳᠠᠪᠬᠤᠷᠭ᠎ᠠ ᠳᠤ ᠬᠦᠷᠬᠦ",
    "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": " ᠡᠮᠦᠨᠡᠰᠢ ᠰᠢᠯᠵᠢᠬᠦ",
    "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": " ᠬᠣᠢᠰᠢ ᠰᠢᠯᠵᠢᠬᠦ",
    "LOOKS_BACKDROPNUMBERNAME": "ᠠᠷᠤ ᠦᠵᠡᠭᠳᠡᠯ %1",
    "LOOKS_COSTUMENUMBERNAME": "ᠵᠠᠭᠪᠤᠷ %1",
    "LOOKS_NUMBERNAME_NUMBER": " ᠨᠣᠮᠧᠷ",
    "LOOKS_NUMBERNAME_NAME": " ᠨᠡᠷ᠎ᠡ",
    "LOOKS_SWITCHBACKDROPTOANDWAIT": "%1 ᠦᠵᠡᠭᠳᠡᠯ ᠢ ᠰᠣᠯᠢᠭᠠᠳ ᠬᠦᠯᠢᠶᠡᠬᠦ",
    "LOOKS_NEXTBACKDROP_BLOCK": " ᠳᠠᠷᠠᠭᠠᠬᠢ ᠦᠵᠡᠭᠳᠡᠯ",
    "LOOKS_NEXTBACKDROP": " ᠳᠠᠷᠠᠭᠠᠬᠢ ᠦᠵᠡᠭᠳᠡᠯ",
    "LOOKS_PREVIOUSBACKDROP": " ᠡᠮᠦᠨᠡᠬᠢ ᠦᠵᠡᠭᠳᠡᠯ",
    "LOOKS_RANDOMBACKDROP": " ᠲᠠᠰᠢᠷᠠᠮ ᠤᠨ ᠠᠷᠤ ᠦᠵᠡᠭᠳᠡᠯ",
    "MOTION_MOVESTEPS": "%1 ᠠᠯᠬᠤᠮ ᠰᠢᠯᠵᠢᠬᠦ",
    "MOTION_TURNLEFT": "ᠵᠡᠭᠦᠨᠰᠢ ᠪᠡᠨ %1 %2  ᠭᠷᠠᠳᠦᠢ᠌ᠰ ᠡᠷᠭᠢᠭᠦᠯᠬᠦ",
    "MOTION_TURNRIGHT": "ᠪᠠᠷᠠᠭᠤᠨᠰᠢ  %1 %2  ᠭᠷᠠᠳᠦᠢ᠌ᠰ ᠡᠷᠭᠢᠭᠦᠯᠬᠦ",
    "MOTION_POINTINDIRECTION": "%1 ᠵᠦᠭ ᠲᠦ ᠬᠠᠨᠳᠤᠬᠤ",
    "MOTION_POINTTOWARDS": "%1  ᠤᠷᠤᠭᠤ ᠬᠠᠨᠳᠤᠬᠤ",
    "MOTION_POINTTOWARDS_POINTER": "ᠮᠠᠦᠢ᠌ᠰ ᠤᠨ ᠵᠢᠭᠠᠯᠲᠠ",
    "MOTION_POINTTOWARDS_RANDOM": " ᠲᠠᠰᠢᠷᠠᠮ ᠤᠨ ᠴᠢᠭᠯᠡᠯ",
    "MOTION_GOTO": "%1  ᠳᠦ ᠰᠢᠯᠵᠢᠭᠦᠯᠬᠦ",
    "MOTION_GOTO_POINTER": "ᠮᠠᠦᠢ᠌ᠰ ᠤᠨ ᠵᠢᠭᠠᠯᠲᠠ",
    "MOTION_GOTO_RANDOM": "ᠲᠠᠰᠢᠷᠠᠮ ᠤᠨ ᠪᠠᠢᠷᠢᠰᠢᠯ",
    "MOTION_GOTOXY": "x: %1 y: %2 ᠳᠤ ᠰᠢᠯᠵᠢᠬᠦ",
    "MOTION_GLIDESECSTOXY": "%1 ᠰᠸ᠋ᠺᠦᠢ᠌ᠨ᠋ᠲ ᠲᠤ  x: %2 y: %3 ᠬᠦᠷᠲᠡᠯ᠎ᠡ ᠭᠤᠯᠭᠤᠬᠤ",
    "MOTION_GLIDETO": "%1 ᠰᠸ᠋ᠺᠦᠢ᠌ᠨ᠋ᠲ ᠲᠤ  %2 ᠬᠦᠷᠲᠡᠯ᠎ᠡ ᠭᠤᠯᠭᠤᠬᠤ",
    "MOTION_GLIDETO_POINTER": " ᠮᠠᠦᠢ᠌ᠰ ᠤᠨ ᠵᠢᠭᠠᠯᠲᠠ",
    "MOTION_GLIDETO_RANDOM": " ᠲᠠᠰᠢᠷᠠᠮ ᠤᠨ ᠪᠠᠢᠷᠢᠰᠢᠯ",
    "MOTION_CHANGEXBY": "x ᠪᠠᠢᠷᠢᠰᠢᠯ ᠢ%1ᠨᠡᠮᠡᠭᠳᠡᠭᠦᠯᠬᠦ",
    "MOTION_SETX": "x ᠪᠠᠢᠷᠢᠰᠢᠯ ᠢ%1 ᠪᠣᠯᠭᠠᠨ ᠳᠣᠬᠢᠷᠠᠭᠤᠯᠬᠤ",
    "MOTION_CHANGEYBY": "y ᠪᠠᠢᠷᠢᠰᠢᠯ ᠢ%1ᠨᠡᠮᠡᠭᠳᠡᠭᠦᠯᠬᠦ",
    "MOTION_SETY": "y ᠪᠠᠢᠷᠢᠰᠢᠯ ᠢ%1 ᠪᠣᠯᠭᠠᠨ ᠳᠣᠬᠢᠷᠠᠭᠤᠯᠬᠤ",
    "MOTION_IFONEDGEBOUNCE": " ᠬᠥᠪᠡᠭᠡᠨ ᠳᠦ ᠮᠥᠷᠭᠥᠯᠳᠦᠪᠡᠯ ᠬᠠᠷᠢᠬᠤ",
    "MOTION_SETROTATIONSTYLE": "ᠡᠷᠭᠢᠯᠳᠦᠬᠦ ᠬᠡᠯᠪᠡᠷᠢ ᠶᠢ%1 ᠪᠣᠯᠭᠠᠨ ᠳᠣᠬᠢᠷᠠᠭᠤᠯᠬᠤ",
    "MOTION_SETROTATIONSTYLE_LEFTRIGHT": " ᠵᠡᠭᠦᠨᠰᠢ ᠪᠠᠷᠠᠭᠤᠨᠰᠢ ᠪᠠᠨ ᠬᠦᠷᠪᠡᠭᠦᠯᠬᠦ",
    "MOTION_SETROTATIONSTYLE_DONTROTATE": "ᠡᠷᠭᠢᠭᠦᠯᠵᠦ ᠪᠣᠯᠬᠤ ᠦᠭᠡᠢ",
    "MOTION_SETROTATIONSTYLE_ALLAROUND": " ᠳᠤᠷ᠎ᠠ ᠶᠢᠨ ᠥᠨᠴᠥᠭ ᠢᠶᠠᠷ ᠡᠷᠭᠢᠭᠦᠯᠬᠦ",
    "MOTION_XPOSITION": "x  ᠪᠠᠢᠷᠢᠰᠢᠯ",
    "MOTION_YPOSITION": "y  ᠪᠠᠢᠷᠢᠰᠢᠯ",
    "MOTION_DIRECTION": " ᠴᠢᠭᠯᠡᠯ",
    "MOTION_SCROLLRIGHT": "%1 ᠪᠠᠷᠠᠭᠤᠨᠰᠢ ᠪᠠᠨ ᠥᠩᠬᠥᠷᠢᠳᠡᠭᠦᠯᠬᠦ",
    "MOTION_SCROLLUP": "%1 ᠳᠡᠭᠡᠭᠰᠢ ᠪᠡᠨ ᠥᠩᠬᠥᠷᠢᠳᠡᠭᠦᠯᠬᠦ",
    "MOTION_ALIGNSCENE": "ᠦᠵᠡᠭᠳᠡᠯ%1 ᠲᠠᠢ ᠰᠠᠴᠠᠭᠤᠯᠠᠬᠤ",
    "MOTION_ALIGNSCENE_BOTTOMLEFT": " ᠵᠡᠭᠦᠨ ᠳᠣᠣᠷ᠎ᠠ ᠥᠨᠴᠥᠭ",
    "MOTION_ALIGNSCENE_BOTTOMRIGHT": " ᠪᠠᠷᠠᠭᠤᠨ ᠳᠣᠣᠷ᠎ᠠ ᠥᠨᠴᠥᠭ",
    "MOTION_ALIGNSCENE_MIDDLE": " ᠳᠤᠮᠳᠠ",
    "MOTION_ALIGNSCENE_TOPLEFT": " ᠵᠡᠭᠦᠨ ᠳᠡᠭᠡᠷ᠎ᠡ ᠥᠨᠴᠥᠭ",
    "MOTION_ALIGNSCENE_TOPRIGHT": " ᠪᠠᠷᠠᠭᠤᠨ ᠳᠡᠭᠡᠷ᠎ᠡ ᠥᠨᠴᠥᠭ",
    "MOTION_XSCROLL": "x  ᠥᠩᠬᠦᠷᠢᠭᠦᠯ",
    "MOTION_YSCROLL": "y ᠥᠩᠬᠦᠷᠢᠭᠦᠯ",
    "MOTION_STAGE_SELECTED": " ᠲᠠᠢᠰᠠ ᠰᠣᠩᠭᠣᠭᠳᠠᠭᠰᠠᠨ:  ᠬᠥᠳᠡᠯᠭᠡᠭᠡᠨ ᠦ ᠲᠥᠷᠥᠯ  ᠦᠨ ᠵᠡᠷᠭᠡᠯᠡᠬᠦ ᠮᠣᠳᠣ ᠶᠢ ᠬᠡᠷᠡᠭᠯᠡᠵᠦ ᠪᠣᠯᠬᠤ ᠦᠭᠡᠢ",
    "OPERATORS_ADD": "%1 + %2",
    "OPERATORS_SUBTRACT": "%1 - %2",
    "OPERATORS_MULTIPLY": "%1 * %2",
    "OPERATORS_DIVIDE": "%1 / %2",
    "OPERATORS_RANDOM": "%1  ᠪᠣᠯᠣᠨ %2  ᠤᠨ ᠬᠣᠭᠣᠷᠣᠨᠳᠣᠬᠢ ᠲᠠᠰᠢᠷᠠᠮ  ᠤᠨ ᠲᠣᠭ᠎ᠠ ᠶᠢ ᠰᠣᠩᠭᠣᠬᠤ",
    "OPERATORS_GT": "%1 > %2",
    "OPERATORS_GT_EQUALS": "%1 >= %2",
    "OPERATORS_LT": "%1 < %2",
    "OPERATORS_LT_EQUALS": "%1 <= %2",
    "OPERATORS_EQUALS": "%1 = %2",
    "OPERATORS_AND": "%1  ᠪᠣᠯᠣᠨ %2",
    "OPERATORS_OR": "%1 ᠪᠤᠶᠤ %2",
    "OPERATORS_NOT": "%1 ᠪᠢᠰᠢ",
    "CONTROL_TRUE_FALSE": "%1",
    "CONTROL_TRUE_FALSE_TRUE": "真",
    "CONTROL_TRUE_FALSE_FALSE": "假",
    "CONTROL_NONE": "空",
    "CONTROL_RETURN": "返回 %1",
    "OPERATORS_VALUE_IS_TRUE": "值 %1 为真？",
    "OPERATORS_VALUE_TYPE": "值 %1 的类型",
    "OPERATORS_VALUE_TYPE_IS": "值 %1 的类型为 %2？",
    "OPERATORS_CACULATE_RESULT": "计算表达式 %1 的结果",
    "OPERATORS_VALUE_TYPE_INT": "int",
    "OPERATORS_VALUE_TYPE_FLOAT": "float",
    "OPERATORS_VALUE_TYPE_BOOL": "bool",
    "OPERATORS_VALUE_TYPE_STR": "str",
    "OPERATORS_VALUE_TYPE_LIST": "list",
    "OPERATORS_VALUE_TYPE_TUPLE": "tuple",
    "OPERATORS_VALUE_TYPE_SET": "set",
    "OPERATORS_VALUE_TYPE_DICT": "dict",
    "OPERATORS_VALUE_TYPE_BYTES": "bytes",
    "OPERATORS_VALUE_TYPE_BYTEARRAY": "bytearray",
    "OPERATORS_VALUE_TYPE_COMPLEX": "complex",
    "CONTROL_TRY": "try",
    "CONTROL_EXCEPT": "except",
    "CONTROL_FINALLY": "finally",
    "OPERATORS_JOIN": "%1 %2 ᠢ ᠨᠡᠢᠯᠡᠭᠦᠯᠬᠦ",
    "OPERATORS_JOIN_APPLE": " ᠠᠯᠮᠤᠷᠠᠳ",
    "OPERATORS_JOIN_BANANA": " ᠪᠠᠨᠠᠨᠠ",
    "OPERATORS_LETTEROF": "%2 ᠶᠢᠨ%1 ᠳ᠋ᠦ᠋ᠭᠡᠷ ᠦᠰᠦᠭ ᠲᠡᠮᠳᠡᠭᠲᠦ",
    "OPERATORS_LETTEROF_APPLE": "a",
    "OPERATORS_LENGTH": "%1 ᠶᠢᠨ ᠲᠡᠮᠳᠡᠭᠲᠦ ᠶᠢᠨ ᠲᠣᠭ᠎ᠠ",
    "OPERATORS_CONTAINS": "%1  ᠳᠤ %2 ᠬᠠᠷᠢᠶᠠᠯᠠᠭᠳᠠᠬᠤ ᠦᠦ?",
    "OPERATORS_MOD": "%1 ᠶᠢ %2 ᠪᠡᠷ ᠬᠤᠪᠢᠶᠠᠭᠰᠠᠨ ᠦᠯᠡᠳᠡᠪᠦᠷᠢ ᠲᠣᠭ᠎ᠠ",
    "OPERATORS_ROUND": "ᠳᠥᠷᠪᠡ ᠶᠢ ᠬᠠᠶᠠᠵᠤ ᠲᠠᠪᠤ ᠶᠢ ᠳᠡᠪᠰᠢᠭᠦᠯᠬᠦ %1",
    "OPERATORS_MATHOP": "%1 of %2",
    "OPERATORS_MATHOP_ABS": " ᠬᠠᠷᠢᠴᠠᠯᠠᠰᠢᠭᠦᠢ ᠬᠡᠮᠵᠢᠭᠳᠡᠯ",
    "OPERATORS_MATHOP_FLOOR": " ᠳᠤᠭᠤᠭᠰᠢ ᠪᠦᠬᠦᠯᠢ ᠲᠣᠭᠠ ᠠᠪᠬᠤ",
    "OPERATORS_MATHOP_CEILING": " ᠳᠡᠭᠡᠭᠰᠢ ᠪᠦᠬᠦᠯᠢ ᠲᠣᠭᠠ ᠠᠪᠬᠤ",
    "OPERATORS_MATHOP_SQRT": " ᠺᠦᠸᠠᠳ᠋ ᠢᠵᠠᠭᠤᠷ",
    "OPERATORS_MATHOP_SIN": "sin",
    "OPERATORS_MATHOP_COS": "cos",
    "OPERATORS_MATHOP_TAN": "tan",
    "OPERATORS_MATHOP_ASIN": "asin",
    "OPERATORS_MATHOP_ACOS": "acos",
    "OPERATORS_MATHOP_ATAN": "atan",
    "OPERATORS_MATHOP_LN": "ln",
    "OPERATORS_MATHOP_LOG": "log",
    "OPERATORS_MATHOP_EEXP": "e ^",
    "OPERATORS_MATHOP_10EXP": "10 ^",
    "PROCEDURES_DEFINITION": "ᠲᠣᠮᠢᠶ᠎ᠠ %1",
    "SENSING_TOUCHINGOBJECT": "%1 ᠲᠤᠯᠭᠠᠷᠠᠭᠰᠠᠨ ᠦᠦ?",
    "SENSING_TOUCHINGOBJECT_POINTER": "ᠮᠠᠦᠢ᠌ᠰ  ᠤᠨ ᠵᠢᠭᠠᠯᠲᠠ",
    "SENSING_TOUCHINGOBJECT_EDGE": " ᠲᠠᠢᠰᠠᠨ ᠤ ᠬᠥᠪᠡᠭᠡ",
    "SENSING_TOUCHINGCOLOR": "%1 ᠥᠩᠭᠡ  ᠳᠤ ᠬᠠᠷᠭᠤᠬᠤ ᠦᠦ?",
    "SENSING_COLORISTOUCHINGCOLOR": "%1 ᠥᠩᠭᠡ %2 ᠲᠤ ᠬᠠᠷᠭᠤᠬᠤ ᠦᠦ?",
    "SENSING_DISTANCETO": "%1 ᠳᠤ ᠬᠦᠷᠬᠦ ᠵᠠᠢ",
    "SENSING_DISTANCETO_POINTER": "ᠮᠠᠦᠢ᠌ᠰ  ᠤᠨ ᠵᠢᠭᠠᠯᠲᠠ",
    "SENSING_ASKANDWAIT": "%1  ᠠᠰᠠᠭᠤᠬᠤ ᠪᠣᠯᠣᠨ ᠬᠦᠯᠢᠶᠡᠬᠦ",
    "SENSING_ASK_TEXT": " ᠲᠠᠨ ᠤ ᠨᠡᠷ᠎ᠡ ᠶᠢ ᠬᠡᠨ ᠭᠡᠳᠡᠭ ᠪᠤᠢ?",
    "SENSING_ANSWER": " ᠬᠠᠷᠢᠭᠤᠯᠬᠤ",
    "SENSING_KEYPRESSED": "%1  ᠳᠠᠷᠤᠪᠴᠢ ᠶᠢ ᠳᠠᠷᠤᠬᠤ ᠦᠦ?",
    "SENSING_MOUSEDOWN": "ᠮᠠᠦᠢ᠌ᠰ  ᠢ ᠳᠣᠶ᠋ᠢᠬᠤ ᠦᠦ?",
    "SENSING_MOUSEX": "ᠮᠠᠦᠢ᠌ᠰ ᠤᠨ x ᠪᠠᠢᠷᠢᠰᠢᠯ",
    "SENSING_MOUSEY": " ᠮᠠᠦᠢ᠌ᠰ ᠤᠨ y ᠪᠠᠢᠷᠢᠰᠢᠯ",
    "SENSING_SETDRAGMODE": "ᠴᠢᠷᠬᠦ ᠵᠠᠭᠪᠤᠷ ᠢ %1 ᠪᠣᠯᠭᠠᠨ ᠲᠣᠬᠢᠷᠠᠭᠤᠯᠬᠤ",
    "SENSING_SETDRAGMODE_DRAGGABLE": " ᠴᠢᠷᠴᠦ ᠪᠣᠯᠬᠤ",
    "SENSING_SETDRAGMODE_NOTDRAGGABLE": " ᠴᠢᠷᠴᠦ ᠪᠣᠯᠬᠤ ᠦᠭᠡᠢ",
    "SENSING_LOUDNESS": "ᠳᠤᠤᠭᠠᠷᠴᠠ",
    "SENSING_LOUD": " ᠳᠠᠭᠤ?",
    "SENSING_TIMER": " ᠴᠠᠭ ᠲᠣᠭᠠᠯᠠᠭᠤᠷ",
    "SENSING_RESETTIMER": " ᠴᠠᠭ ᠲᠣᠭᠠᠯᠠᠭᠤᠷ ᠢ ᠲᠢᠭ ᠪᠣᠯᠭᠠᠬᠤ",
    "SENSING_OF": "%1  ᠶᠢᠨ %2",
    "SENSING_OF_XPOSITION": "x  ᠪᠠᠢᠷᠢᠰᠢᠯ",
    "SENSING_OF_YPOSITION": "y ᠪᠠᠢᠷᠢᠰᠢᠯ",
    "SENSING_OF_DIRECTION": " ᠴᠢᠭᠯᠡᠯ",
    "SENSING_OF_COSTUMENUMBER": " ᠵᠠᠭᠪᠤᠷ ᠤᠨ ᠳ᠋ᠤᠭᠠᠷ#",
    "SENSING_OF_COSTUMENAME": " ᠵᠠᠭᠪᠤᠷ ᠤᠨ ᠨᠡᠷᠡᠢᠳᠦᠯ",
    "SENSING_OF_SIZE": " ᠶᠡᠬᠡ ᠪᠠᠭ᠎ᠠ",
    "SENSING_OF_VOLUME": " ᠳᠤᠤᠭᠠᠷᠴᠠ",
    "SENSING_OF_BACKDROPNUMBER": " ᠠᠷᠤ ᠦᠵᠡᠭᠳᠡᠯ ᠦᠨ ᠨᠣᠮᠧᠷ #",
    "SENSING_OF_BACKDROPNAME": " ᠠᠷᠤ ᠦᠵᠡᠭᠳᠡᠯ ᠦᠨ ᠨᠡᠷ᠎ᠡ",
    "SENSING_OF_STAGE": " ᠲᠠᠢᠰᠠ",
    "SENSING_CURRENT": "ᠣᠳᠣᠬᠠᠨ ᠤ %1",
    "SENSING_CURRENT_YEAR": " ᠵᠢᠯ",
    "SENSING_CURRENT_MONTH": " ᠰᠠᠷ᠎ᠠ",
    "SENSING_CURRENT_DATE": " ᠡᠳᠦᠷ",
    "SENSING_CURRENT_DAYOFWEEK": " ᠭᠠᠷᠠᠭ",
    "SENSING_CURRENT_HOUR": " ᠴᠠᠭ",
    "SENSING_CURRENT_MINUTE": " ᠮᠢᠨᠦ᠋ᠲ",
    "SENSING_CURRENT_SECOND": " ᠰᠸ᠋ᠺᠦᠢ᠌ᠨ᠋ᠲ",
    "SENSING_DAYSSINCE2000": "2000 ᠣᠨ ᠠᠴᠠ ᠥᠨᠥᠳᠥᠷ ᠬᠦᠷᠲᠡᠯᠡᠬᠢ ᠡᠳᠦᠷ ᠦᠨ ᠲᠣᠭ᠎ᠠ",
    "SENSING_USERNAME": " ᠬᠡᠷᠡᠭᠯᠡᠭᠴᠢ ᠶᠢᠨ ᠨᠡᠷ᠎ᠡ",
    "SENSING_USERID": " ᠬᠡᠷᠡᠭᠯᠡᠭᠴᠢ  ᠶᠢᠨ ᠨᠣᠮᠸᠷ",
    "SOUND_PLAY": "%1 ᠳᠠᠭᠤ ᠨᠡᠪᠲᠡᠷᠡᠭᠦᠯᠬᠦ",
    "SOUND_PLAYUNTILDONE": "%1  ᠶᠢᠨ ᠳᠠᠭᠤ ᠶᠢ ᠨᠡᠪᠲᠡᠷᠡᠭᠦᠯᠵᠦ ᠳᠠᠭᠤᠰᠬᠤ ᠶᠢ ᠬᠦᠯᠢᠶᠡᠬᠦ",
    "SOUND_STOPALLSOUNDS": " ᠪᠦᠭᠦᠳᠡ ᠳᠠᠭᠤ ᠶᠢ ᠵᠣᠭᠰᠣᠭᠠᠬᠤ",
    "SOUND_SETEFFECTO": "%1 ᠶᠢᠨ  ᠳᠠᠭᠤᠨ ᠨᠥᠯᠥᠭᠡ ᠶᠢ %2 ᠪᠣᠯᠭᠠᠨ ᠲᠣᠬᠢᠷᠠᠭᠤᠯᠬᠤ",
    "SOUND_CHANGEEFFECTBY": "%1  ᠳᠠᠭᠤᠨ ᠨᠥᠯᠦᠭᠡ %2  ᠬᠦᠷᠲᠡᠯᠡ ᠨᠡᠮᠡᠭᠳᠡᠭᠦᠯᠬᠦ",
    "SOUND_CLEAREFFECTS": " ᠳᠠᠭᠤᠨ ᠨᠥᠯᠦᠭᠡ  ᠶᠢ ᠤᠰᠠᠳᠭᠠᠬᠤ",
    "SOUND_EFFECTS_PITCH": " ᠠᠶᠠᠯᠭᠤ",
    "SOUND_EFFECTS_PAN": " ᠵᠡᠭᠦᠨ ᠪᠠᠷᠠᠭᠤᠨ ᠢ ᠲᠡᠩᠴᠡᠭᠦᠷᠢᠰᠢᠭᠦᠯᠬᠦ",
    "SOUND_CHANGEVOLUMEBY": "ᠳᠤᠤᠭᠠᠷᠴᠠ ᠶᠢ %1 ᠪᠣᠯᠭᠠᠨ ᠨᠡᠮᠡᠭᠳᠡᠭᠦᠯᠬᠦ",
    "SOUND_SETVOLUMETO": "ᠳᠤᠤᠭᠠᠷᠴᠠ ᠶᠢ %1 ᠪᠣᠯᠭᠠᠨ ᠳᠣᠬᠢᠷᠠᠭᠤᠯᠬᠤ",
    "SOUND_VOLUME": " ᠳᠤᠤᠭᠠᠷᠴᠠ",
    "SOUND_RECORD": " ᠰᠢᠩᠭᠡᠭᠡᠨ ᠦᠢᠯᠡᠳᠬᠦ...",
    "CATEGORY_MOTION": " ᠬᠥᠳᠡᠯᠭᠡᠭᠡᠨ",
    "CATEGORY_LOOKS": " ᠭᠠᠳᠠᠷ ᠦᠵᠡᠮᠵᠢ",
    "CATEGORY_SOUND": " ᠳᠠᠭᠤ",
    "CATEGORY_EVENTS": " ᠬᠡᠷᠡᠭ ᠤᠴᠢᠷ",
    "CATEGORY_CONTROL": " ᠡᠵᠡᠮᠰᠢᠬᠦ",
    "CATEGORY_SENSING": " ᠲᠠᠩᠨᠠᠬᠤ",
    "CATEGORY_OPERATORS": " ᠪᠣᠳᠣᠬᠤ",
    "CATEGORY_VARIABLES": " ᠬᠤᠪᠢᠰᠤᠭᠴᠢ ᠬᠡᠮᠵᠢᠭᠳᠡᠭᠦᠨ",
    "CATEGORY_MYBLOCKS": " ᠹᠦᠩᠺᠼ",
    "CATEGORY_NUMBERS": "ᠲᠣᠭ᠎ᠠ ᠬᠡᠮᠵᠢᠭᠳᠡᠯ",
    "CATEGORY_TEXT": " ᠲᠧᠺᠰᠲ",
    "DUPLICATE": " ᠪᠠᠭᠤᠯᠭᠠᠬᠤ",
    "DUPLICATE_CURRENT": "复制单个",
    "DELETE": " ᠬᠠᠰᠤᠬᠤ",
    "ADD_COMMENT": " ᠵᠡᠭᠦᠯᠲᠡ ᠶᠢ ᠨᠡᠮᠡᠬᠦ",
    "REMOVE_COMMENT": " ᠵᠡᠭᠦᠯᠲᠡ ᠶᠢ ᠬᠠᠰᠤᠬᠤ",
    "DELETE_BLOCK": " ᠬᠠᠰᠤᠬᠤ",
    "DELETE_X_BLOCKS": "%1 ᠶᠢᠨ ᠵᠡᠷᠭᠡᠯᠡᠬᠦ ᠮᠣᠳᠣ ᠶᠢ ᠬᠠᠰᠤᠬᠤ",
    "DELETE_ALL_BLOCKS": "%1 ᠶᠢᠨ ᠪᠦᠭᠦᠳᠡ ᠵᠡᠷᠭᠡᠯᠡᠬᠦ ᠮᠣᠳᠣ ᠶᠢ ᠬᠠᠰᠤᠬᠤ ᠦᠦ?",
    "CLEAN_UP": " ᠵᠡᠷᠭᠡᠯᠡᠬᠦ ᠮᠣᠳᠣ ᠶᠢ ᠡᠮᠬᠢᠯᠡᠬᠦ",
    "HELP": " ᠬᠠᠪᠰᠤᠷᠬᠤ",
    "UNDO": " ᠤᠰᠠᠳᠬᠠᠬᠤ",
    "REDO": " ᠳᠠᠬᠢᠨ ᠬᠢᠬᠦ",
    "EDIT_PROCEDURE": " ᠨᠠᠢᠷᠠᠭᠤᠯᠬᠤ",
    "SHOW_PROCEDURE_DEFINITION": " ᠲᠣᠮᠢᠶ᠎ᠠ ᠶᠢ ᠪᠠᠢᠴᠠᠭᠠᠨ ᠦᠵᠡᠬᠦ",
    "WORKSPACE_COMMENT_DEFAULT_TEXT": " ᠶᠠᠭᠤ ᠬᠡᠯᠡᠬᠦ...",
    "COLOUR_HUE_LABEL": " ᠥᠩᠭᠡ",
    "COLOUR_SATURATION_LABEL": " ᠬᠠᠨᠤᠯᠲᠠ",
    "COLOUR_BRIGHTNESS_LABEL": " ᠭᠡᠷᠡᠯᠳᠦᠴᠡ",
    "CHANGE_VALUE_TITLE": " ᠬᠤᠪᠢᠰᠬᠤᠨ ᠢ ᠬᠤᠪᠢᠷᠠᠭᠠᠬᠤ",
    "RENAME_VARIABLE": " ᠬᠤᠪᠢᠰᠬᠤᠨ ᠤ ᠨᠡᠷ᠎ᠡ ᠶᠢ ᠥᠭᠡᠷᠡᠴᠢᠯᠡᠬᠦ",
    "RENAME_VARIABLE_STRING": " ᠦᠰᠦᠭ ᠲᠡᠮᠳᠡᠭᠲᠦ ᠬᠤᠪᠢᠰᠬᠤᠨ ᠤ ᠨᠡᠷ᠎ᠡ ᠶᠢ ᠥᠭᠡᠷᠡᠴᠢᠯᠡᠬᠦ",
    "RENAME_VARIABLE_NUMBER": " ᠲᠣᠭ᠎ᠠ ᠲᠡᠮᠳᠡᠭᠲᠦ ᠬᠤᠪᠢᠰᠬᠤᠨ ᠤ ᠨᠡᠷ᠎ᠡ ᠶᠢ ᠥᠭᠡᠷᠡᠴᠢᠯᠡᠬᠦ",
    "RENAME_VARIABLE_LIST": " ᠵᠢᠭᠰᠠᠭᠠᠯᠲᠠ ᠬᠤᠪᠢᠰᠬᠤᠨ ᠤ ᠨᠡᠷ᠎ᠡ ᠶᠢ ᠥᠭᠡᠷᠡᠴᠢᠯᠡᠬᠦ ",
    "RENAME_VARIABLE_TITLE": "ᠪᠦᠬᠦᠢᠯᠡ ᠬᠤᠪᠢᠰᠬᠤᠨ [%1] ᠨᠡᠷ᠎ᠡ ᠶᠢ ᠥᠭᠡᠷᠡᠴᠢᠯᠡᠭᠡᠳ:",
    "RENAME_VARIABLE_MODAL_TITLE": "ᠬᠤᠪᠢᠰᠬᠤᠨ ᠤ ᠨᠡᠷ᠎ᠡ ᠶᠢ ᠥᠭᠡᠷᠡᠴᠢᠯᠡᠬᠦ",
    "NEW_VARIABLE": " ᠰᠢᠨ᠎ᠡ ᠬᠤᠪᠢᠰᠬᠤᠨ ᠢ ᠡᠭᠦᠰᠭᠡᠬᠦ",
    "NEW_VARIABLE_TITLE": " ᠰᠢᠨ᠎ᠡ ᠬᠤᠪᠢᠰᠬᠤᠨ ᠤ ᠨᠡᠷ᠎ᠡ:",
    "VARIABLE_MODAL_TITLE": "ᠰᠢᠨ᠎ᠡ ᠬᠤᠪᠢᠰᠬᠤᠨ ᠢ ᠡᠭᠦᠰᠭᠡᠬᠦ",
    "VARIABLE_ALREADY_EXISTS": "ᠨᠢᠭᠡᠨᠲᠡ [%1] ᠨᠡᠷ᠎ᠡ ᠲᠠᠢ ᠬᠤᠪᠢᠰᠬᠤᠨ ᠣᠷᠣᠰᠢᠵᠤ ᠪᠠᠢᠨ᠎ᠠ",
    "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "ᠨᠢᠭᠡᠨᠲᠡ [%1] ᠨᠡᠷ᠎ᠡ ᠲᠠᠢ ᠬᠤᠪᠢᠰᠬᠤᠨ ᠣᠷᠣᠰᠢᠵᠤ ᠪᠠᠢᠨ᠎ᠠ  ᠲᠡᠭᠦᠨ ᠦ ᠬᠡᠯᠪᠡᠷᠢ ᠨᠢ[%2].",
    "DELETE_VARIABLE_CONFIRMATION": "%1 ᠳᠡᠬᠢ  ᠬᠤᠪᠢᠰᠬᠤᠨ [%2]  ᠢ ᠤᠰᠠᠳᠬᠠᠬᠤ ᠦᠦ?",
    "CANNOT_DELETE_VARIABLE_PROCEDURE": "ᠬᠤᠪᠢᠰᠬᠤᠨ [%1]  ᠶᠢ ᠬᠠᠰᠤᠵᠤ ᠳᠡᠢᠯᠬᠦ ᠦᠭᠡᠢ ᠪᠠᠢᠨ᠎ᠠ᠂ ᠤᠴᠢᠷ ᠨᠢ  ᠹᠦᠩᠺᠼ [%2] ᠳᠡᠬᠢ ᠲᠣᠮᠢᠶᠠᠨ ᠳᠤ ᠬᠡᠷᠡᠭᠯᠡᠭᠳᠡᠭᠰᠡᠨ ᠪᠠᠢᠨ᠎ᠠ",
    "DELETE_VARIABLE": "ᠬᠤᠪᠢᠰᠬᠤᠨ [%1]  ᠶᠢ ᠤᠰᠠᠳᠬᠠᠬᠤ",
    "DELETE_VARIABLE_STRING": "ᠦᠰᠦᠭ ᠲᠡᠮᠳᠡᠭᠲᠦ ᠬᠤᠪᠢᠰᠬᠤᠨ[%1]  ᠶᠢ ᠤᠰᠠᠳᠬᠠᠬᠤ",
    "DELETE_VARIABLE_NUMBER": "ᠲᠣᠭ᠎ᠠ ᠲᠡᠮᠳᠡᠭᠲᠦ ᠬᠤᠪᠢᠰᠬᠤᠨ[%1]  ᠶᠢ ᠤᠰᠠᠳᠬᠠᠬᠤ",
    "DELETE_VARIABLE_LIST": "ᠵᠢᠭᠰᠠᠭᠠᠯᠲᠠ ᠬᠤᠪᠢᠰᠬᠤᠨ[%1]  ᠶᠢ ᠤᠰᠠᠳᠬᠠᠬᠤ",
    "NEW_PROCEDURE": " ᠰᠢᠨ᠎ᠡ ᠬᠡᠰᠡᠭᠲᠦ ᠪᠠᠢᠭᠤᠯᠬᠤ",
    "PROCEDURE_ALREADY_EXISTS": "ᠨᠢᠭᠡᠨᠲᠡ [%1] ᠨᠡᠷ᠎ᠡ ᠲᠠᠢ  ᠫᠷᠦᠭᠷᠠᠮ ᠣᠷᠣᠰᠢᠵᠤ ᠪᠠᠢᠨ᠎ᠠ",
    "PROCEDURE_DEFAULT_NAME": " ᠵᠡᠷᠭᠡᠯᠡᠬᠦ ᠮᠣᠳᠣᠨ ᠤ ᠨᠡᠷ᠎ᠡ",
    "NEW_LIST": " ᠵᠢᠭᠰᠠᠭᠠᠯᠲᠠ ᠶᠢ ᠡᠭᠦᠰᠭᠡᠬᠦ",
    "NEW_LIST_TITLE": " ᠰᠢᠨ᠎ᠡ ᠵᠢᠭᠰᠠᠭᠠᠯᠲᠠ ᠶᠢᠨ ᠨᠡᠷ᠎ᠡ: ",
    "LIST_MODAL_TITLE": "ᠵᠢᠭᠰᠠᠭᠠᠯᠲᠠ ᠶᠢ ᠡᠭᠦᠰᠭᠡᠬᠦ",
    "LIST_ALREADY_EXISTS": "[%1] ᠨᠡᠷ᠎ᠡ ᠲᠠᠢ ᠵᠢᠭᠰᠠᠭᠠᠯᠲᠠ ᠨᠢᠭᠡᠨᠲᠡ ᠣᠷᠣᠰᠢᠵᠤ ᠪᠠᠢᠨ᠎ᠠ",
    "RENAME_LIST_TITLE": "ᠪᠦᠭᠦᠳᠡ[%1]  ᠶᠢᠨ ᠵᠢᠭᠰᠠᠭᠠᠯᠲᠠ  ᠶᠢᠨ ᠨᠡᠷ᠎ᠡ ᠶᠢ ᠥᠭᠡᠷᠡᠴᠢᠯᠡᠭᠡᠳ:",
    "RENAME_LIST_MODAL_TITLE": " ᠵᠢᠭᠰᠠᠭᠠᠯᠲᠠ ᠶᠢᠨ ᠨᠡᠷ᠎ᠡ ᠶᠢ ᠥᠭᠡᠷᠡᠴᠢᠯᠡᠬᠦ",
    "DEFAULT_LIST_ITEM": " ᠶᠠᠭᠤᠮ᠎ᠠ",
    "DELETE_LIST": "[%1]  ᠵᠢᠭᠰᠠᠭᠠᠯᠲᠠ ᠶᠢ ᠤᠰᠠᠳᠬᠠᠬᠤ",
    "RENAME_LIST": "ᠵᠢᠭᠰᠠᠭᠠᠯᠲᠠ ᠶᠢᠨ ᠨᠡᠷ᠎ᠡ ᠶᠢ ᠥᠭᠡᠷᠡᠴᠢᠯᠡᠬᠦ",
    "NEW_BROADCAST_MESSAGE": " ᠰᠢᠨ᠎ᠡ ᠮᠡᠳᠡᠭᠡ",
    "NEW_BROADCAST_MESSAGE_TITLE": " ᠰᠢᠨ᠎ᠡ ᠮᠡᠳᠡᠭᠡᠨ ᠦ ᠨᠡᠷ᠎ᠡ:",
    "BROADCAST_MODAL_TITLE": " ᠰᠢᠨ᠎ᠡ ᠮᠡᠳᠡᠭᠡ",
    "DEFAULT_BROADCAST_MESSAGE_NAME": "ᠮᠡᠳᠡᠭᠡ1",
    "DELETE_PROCEDURE": "ᠵᠡᠷᠭᠡᠯᠡᠬᠦ ᠮᠣᠳᠣ ᠶᠢ ᠤᠰᠠᠳᠬᠠᠬᠤ ᠳᠤ ᠤᠷᠢᠳᠠᠪᠠᠷ ᠲᠡᠭᠦᠨ ᠲᠠᠢ ᠬᠠᠷᠠᠭᠠᠯᠵᠠᠭᠰᠠᠳ ᠡᠰᠢᠯᠡᠯᠲᠡ ᠶᠢ ᠬᠠᠰᠤᠬᠤ ᠬᠡᠷᠡᠭᠲᠡᠢ",
    "OK": "ᠲᠡᠭᠡ",
    "CANCEL": "Cancel",
    "PROMPT": " ᠰᠠᠨᠠᠭᠤᠯᠤᠮᠵᠢ",
    "STOP": " ᠵᠣᠭᠰᠣᠭᠠᠬᠤ",
    "NEW_STRING": " ᠦᠰᠦᠭ ᠲᠡᠮᠳᠡᠭᠲᠦ ᠬᠡᠯᠪᠡᠷᠢ ᠶᠢᠨ ᠬᠤᠪᠢᠰᠬᠤᠨ ᠢ ᠪᠠᠢᠭᠤᠯᠬᠤ...",
    "NEW_NUMBER_VARIABLE": " ᠲᠣᠭᠠᠨ ᠤ ᠬᠡᠯᠪᠡᠷᠢ ᠶᠢᠨ ᠬᠣᠪᠢᠰᠬᠤᠨ ᠢ ᠪᠠᠢᠭᠤᠯᠬᠤ",
    "WIFI": "Wi-Fi",
    "USERNAME": "Wi-Fi ᠨᠡᠷ᠎ᠡ",
    "PWD": " ᠨᠢᠭᠤᠴᠠ  ᠨᠣᠮᠸᠷ",
    "IP": "IP地址",
    "IOT_SERVICE": "IOT ᠦᠢᠯᠡᠴᠢᠯᠡᠭᠦᠷ",
    "IOT_ID": "iot id",
    "IOT_PWD": "iot pwd",
    "IOT_TOPIC": " ᠰᠡᠳᠦᠪ_0",
    "MORE_TOPIC": " ᠰᠡᠳᠦᠪ",
    "OBLOQ_HTTP": "HTTP",
    "OBLOQ_IP": "IP  ᠬᠠᠶᠢᠭ",
    "OBLOQ_PORT": " ᠵᠠᠯᠭᠠᠭᠤᠷ",
    "WIFI_VALIDATE": "Wi-Fi密码必须是8-20位的字母或数字",
    "ADD_TOPIC": " ᠰᠡᠳᠦᠪ ᠨᠡᠮᠡᠬᠦ,  ᠬᠠᠮᠤᠭ ᠣᠯᠠᠨ ᠳᠤ ᠪᠠᠨ ᠵᠥᠪᠬᠡᠨ 4 ᠶᠢ ᠨᠡᠮᠡᠵᠦ ᠪᠣᠯᠣᠨ᠎ᠠ",
    "DELETE_TOPIC": "  ᠰᠡᠳᠦᠪ ᠢ ᠤᠰᠠᠳᠬᠠᠬᠤ",
    "IOT_SERVER": " ᠦᠢᠯᠡᠴᠢᠯᠡᠭᠦᠷ",
    "CHINA": " ᠳᠤᠮᠳᠠᠳᠤ ᠤᠯᠤᠰ",
    "GLOBAL": " ᠪᠦᠬᠦ ᠳᠡᠯᠡᠬᠡᠢ",
    "LOW_C": " ᠳᠣᠣᠷ᠎ᠠ C/C3",
    "LOW_C$": " ᠳᠣᠣᠷ᠎ᠠ C#/C#3",
    "LOW_D": " ᠳᠣᠣᠷ᠎ᠠ D/D3",
    "LOW_D$": " ᠳᠣᠣᠷ᠎ᠠ D#/D#3",
    "LOW_E": " ᠳᠣᠣᠷ᠎ᠠ E/E3",
    "LOW_F": " ᠳᠣᠣᠷ᠎ᠠ F/F3",
    "LOW_F$": " ᠳᠣᠣᠷ᠎ᠠ F#/F#3",
    "LOW_G": " ᠳᠣᠣᠷ᠎ᠠ G/G3",
    "LOW_G$": " ᠳᠣᠣᠷ᠎ᠠ G#/G#3",
    "LOW_A": " ᠳᠣᠣᠷ᠎ᠠ A/A3",
    "LOW_A$": " ᠳᠣᠣᠷ᠎ᠠ A#/A#3",
    "LOW_B": " ᠳᠣᠣᠷ᠎ᠠ B/B3",
    "MIDDLE_C": " ᠳᠤᠮᠳᠠ C/C4",
    "MIDDLE_C$": " ᠳᠤᠮᠳᠠ C#/C#4",
    "MIDDLE_D": " ᠳᠤᠮᠳᠠ D/D4",
    "MIDDLE_D$": " ᠳᠤᠮᠳᠠ D#/D#4",
    "MIDDLE_E": " ᠳᠤᠮᠳᠠ E/E4",
    "MIDDLE_F": " ᠳᠤᠮᠳᠠ F/F4",
    "MIDDLE_F$": " ᠳᠤᠮᠳᠠ F#/F#4",
    "MIDDLE_G": " ᠳᠤᠮᠳᠠ G/G4",
    "MIDDLE_G$": " ᠳᠤᠮᠳᠠ G#/G#4",
    "MIDDLE_A": " ᠳᠤᠮᠳᠠ A/A4",
    "MIDDLE_A$": " ᠳᠤᠮᠳᠠ A#/A#4",
    "MIDDLE_B": " ᠳᠤᠮᠳᠠ B/B4",
    "HIGH_C": " ᠥᠨᠳᠦᠷ C/C5",
    "HIGH_C$": " ᠥᠨᠳᠦᠷ C#/C#5",
    "HIGH_D": " ᠥᠨᠳᠦᠷ D/D5",
    "HIGH_D$": " ᠥᠨᠳᠦᠷ D#/D#5",
    "HIGH_E": " ᠥᠨᠳᠦᠷ E/E5",
    "HIGH_F": " ᠥᠨᠳᠦᠷ F/F5",
    "HIGH_F$": " ᠥᠨᠳᠦᠷ F#/F#5",
    "HIGH_G": " ᠥᠨᠳᠦᠷ G/G5",
    "HIGH_G$": " ᠥᠨᠳᠦᠷ G#/G#5",
    "HIGH_A": " ᠥᠨᠳᠦᠷ A/A5",
    "HIGH_A$": " ᠥᠨᠳᠦᠷ A#/A#5",
    "HIGH_B": " ᠥᠨᠳᠦᠷ B/B5",
    "OPERATORS_STRINGTONUMBER": "ᠦᠰᠦᠭ ᠲᠡᠮᠳᠡᠭᠲᠦ %1  ᠶᠢ %2 ᠪᠣᠯᠭᠠᠬᠤ",
    "INTEGER": " ᠪᠦᠬᠦᠯᠢ ᠲᠣᠭ᠎ᠠ",
    "DECIMAL": " ᠪᠤᠲᠠᠷᠬᠠᠢ ᠲᠣᠭ᠎ᠠ",
    "OPERATORS_NUMTOASCII": "ᠲᠣᠭ᠎ᠠ %1 ᠶᠢ ASCII ᠺᠣᠳ᠋ ᠪᠣᠯᠭᠠᠬᠤ",
    "OPERATORS_STRINGTOASCII": "ᠦᠰᠦᠭ ᠲᠡᠮᠳᠡᠭᠲᠦ %1 ᠶᠢ ASCII ᠺᠣᠳ᠋ ᠪᠣᠯᠭᠠᠬᠤ",
    "OPERATORS_NUMTOSTRING": "ᠲᠣᠭ᠎ᠠ %1  ᠶᠢ ᠦᠰᠦᠭ ᠲᠡᠮᠳᠡᠭᠲᠦ ᠪᠣᠯᠭᠠᠬᠤ",
    "OPERATORS_MAP": "%1   ᠶᠢ[ %2 , %3 ]  ᠡᠴᠡ[ %4 , %5 ] ᠲᠤ ᠲᠤᠰᠬᠠᠬᠤ",
    "OPERATORS_CONSTRAIN": "%1  ᠪᠣᠯᠣᠨ %2  ( ᠬᠠᠮᠤᠭ ᠪᠠᠭ᠎ᠠ ᠬᠡᠮᠵᠢᠭᠳᠡᠯ) ᠤᠨ ᠬᠣᠭᠣᠷᠣᠨᠳᠣ%3 ( ᠬᠠᠮᠤᠭ ᠶᠡᠬᠡ ᠬᠡᠮᠵᠢᠭᠳᠡᠯ) ᠬᠢᠵᠠᠭᠠᠷᠯᠠᠬᠤ",
    "STEPS_PER_TURN": " ᠡᠷᠭᠢᠯᠳᠦᠬᠦ ᠪᠦᠷᠢ ᠶᠢᠨ ᠠᠯᠬᠤᠮ ᠤᠨ ᠲᠣᠭ᠎ᠠ",
    "ROTATE_SPEED": " ᠡᠷᠭᠢᠯᠳᠦᠬᠦ ᠬᠤᠷᠳᠤᠴᠠ(r/s)",
    "NEW_AI": " ᠣᠶᠤᠨᠴᠢᠷ ᠵᠠᠭᠪᠤᠷ ᠢ ᠪᠠᠢᠭᠤᠯᠬᠤ",
    "ASK_AND_PAINT": " ᠨᠢᠭᠡ ᠲᠣᠭ᠎ᠠ ᠶᠢ ᠪᠢᠴᠢᠭᠡᠷᠡᠢ",
    "GET_IDENTIFIED_NUM": " ᠲᠣᠭ᠎ᠠ ᠲᠠᠨᠢᠬᠤ",
    "READ_NUM_AND_SAY": " ᠲᠠᠨᠢᠭᠰᠠᠨ ᠲᠣᠭ᠎ᠠ ᠶᠢ ᠶᠠᠷᠢᠭᠤᠯᠬᠤ",
    "IMAGE_ADDR": " ᠵᠢᠷᠤᠭ ᠤᠨ ᠬᠠᠶ᠋ᠢᠭ",
    "IMAGE_PREVIEW": " ᠵᠢᠷᠤᠭ ᠢ ᠦᠵᠡᠬᠦ",
    "IMAGE_OPEN": " ᠨᠡᠭᠡᠭᠡᠬᠦ",
    "IMAGE_SIZE": " ᠵᠢᠷᠤᠭ ᠤᠨ ᠬᠡᠮᠵᠢᠶ᠎ᠡ",
    "IMAGE_WIDTH": " ᠥᠷᠭᠡᠨ",
    "IMAGE_HEIGHT": " ᠥᠨᠳᠥᠷ",
    "VARIABLE": "ᠬᠤᠪᠢᠰᠬᠤᠨ%1",
    "VARIABLE_LIST": "ᠵᠢᠭᠰᠠᠭᠠᠯᠲᠠ%1",
    "SET_STRING_VARIABLE": "%1 ᠶᠢᠨ ᠬᠡᠮᠵᠢᠶ᠎ᠡ ᠶᠢ %2 ᠪᠣᠯᠭᠠᠨ ᠲᠣᠬᠢᠷᠠᠭᠤᠯᠬᠤ",
    "STRING_START_WITH": "%1  ᠢ %2 ᠦᠰᠦᠭ ᠲᠡᠮᠳᠡᠭᠲᠦ ᠡᠴᠡ ᠡᠬᠢᠯᠡᠬᠦ",
    "OPERATORS_RANDOMA": "%1 %2 %3 ᠠᠴᠠ %4 ᠶᠢᠨ ᠬᠣᠭᠣᠷᠣᠨᠳᠤ ᠲᠠᠰᠢᠷᠠᠮ ᠤᠨ ᠲᠣᠭ᠎ᠠ ᠠᠪᠬᠤ %5",
    "GLOBAL_MPY_VARIABLE": "全局global %1",
    "OPERATORS_MODA": "%1 %2 %3 ᠶᠢ%4 ᠪᠡᠷ ᠬᠤᠪᠢᠶᠠᠭᠰᠠᠨ ᠦᠯᠡᠳᠡᠪᠦᠷᠢ ᠲᠣᠭ᠎ᠠ",
    "OPERATORS_ROUNDA": "%1 %2  ᠳᠥᠷᠪᠡ ᠶᠢ ᠬᠠᠶᠠᠵᠤ ᠲᠠᠪᠤ ᠶᠢ ᠳᠠᠪᠰᠢᠭᠤᠯᠤᠭᠠᠳ %3",
    "OPERATORS_MATHOPA": "%1 %2 %3 %4",
    "OPERATORS_MATHOP_ABSA": " ᠬᠠᠷᠢᠴᠠᠯᠠᠰᠢᠭᠦᠢ ᠬᠡᠮᠵᠢᠭᠳᠡᠯ",
    "OPERATORS_MATHOP_FLOORA": " ᠳᠡᠭᠡᠭᠰᠢ ᠪᠦᠬᠦᠯᠢ ᠲᠣᠭᠠ ᠠᠪᠬᠤ",
    "OPERATORS_MATHOP_CEILINGA": " ᠳᠣᠭᠤᠭᠰᠢ ᠪᠦᠬᠦᠯᠢ ᠲᠣᠭᠠ ᠠᠪᠬᠤ",
    "OPERATORS_MATHOP_SQRTA": " ᠺᠦᠸᠠᠳ᠋ ᠢᠵᠠᠭᠤᠷ",
    "OPERATORS_MATHOP_FIVE": "%1 %2  ᠬᠤᠪᠢᠰᠬᠤᠨ%3   ᠶᠢ ᠨᠡᠮᠡᠭᠳᠡᠭᠦᠯᠦᠭᠡᠳ %4 ᠪᠣᠯᠭᠠᠬᠤ",
    "OPERATORS_MATHOP_SIX": "%1 %2  %3  ᠠᠴᠠ[ %4 , %5 ]  ᠳᠤ[ %6 , %7 ] ᠲᠤᠰᠬᠠᠬᠤ",
    "OPERATORS_MATHOP_SEVEN": "%1 %2 %3 ( ᠬᠠᠮᠤᠭ ᠪᠠᠭ᠎ᠠ ᠬᠡᠮᠵᠢᠭᠳᠡᠯ ) %4  ᠪᠠ( ᠬᠠᠮᠤᠭ ᠶᠡᠬᠡ ᠬᠡᠮᠵᠢᠭᠳᠡᠯ ) %5 ᠶᠢᠨ ᠬᠣᠭᠣᠷᠣᠨᠳᠣ ᠲᠤᠰᠬᠠᠬᠤ",
    "OPERATORS_NUM_INPUT": "%1 %2 数字 %3",
    "OPERATORS_NUMS_CACULATE": "%1 %2 %3",
    "OPERATORS_MATHOP_SQUARE": "**",
    "OPERATORS_MATHOP_LOGIC_OR": "|",
    "OPERATORS_MATHOP_LOGIC_AND": "&",
    "OPERATORS_MATHOP_LOGIC_POWER": "^",
    "OPERATORS_MATHOP_LOGIC_LEFT": "<<",
    "OPERATORS_MATHOP_LOGIC_RIGHT": ">>",
    "OPERATORS_SPECIAL_NUM": "%1 %2 %3",
    "OPERATORS_MATHOP_PI": "π",
    "OPERATORS_MATHOP_LOGARITHM": "e",
    "OPERATORS_MATHOP_FAI": "φ",
    "OPERATORS_MATHOP_SQRT2": "sqrt(2)",
    "OPERATORS_MATHOP_SQRTHALF": "sqrt(½)",
    "OPERATORS_MATHOP_UNLIMITED": "∞",
    "OPERATORS_NUMS_TYPE": "%1 %2 %3 %4？",
    "OPERATORS_MATHOP_ISEVEN": "是偶数",
    "OPERATORS_MATHOP_ISODD": "是奇数",
    "OPERATORS_MATHOP_ISINT": "是整数",
    "OPERATORS_MATHOP_ISPOSITIVE": "为正",
    "OPERATORS_MATHOP_ISNEGATIVE": "为负",
    "OPERATORS_SAVE2DECIMAL": "%1 %2 %3 保留 %4 位小数",
    "OPERATORS_LIST_CACULATE": "%1 %2 %3 %4",
    "OPERATORS_MATHOP_LIST_SUM": "列表中的数的总和",
    "OPERATORS_MATHOP_LIST_MIN": "列表中的最小值",
    "OPERATORS_MATHOP_LIST_MAX": "列表中的最大值",
    "OPERATORS_MATHOP_LIST_AVERAGE": "列表中的平均数",
    "OPERATORS_MATHOP_LIST_NUM": "列表中位数",
    "OPERATORS_MATHOP_LIST_MODE": "列表模式",
    "OPERATORS_MATHOP_LIST_STDDEVIATION": "列表中的标准差",
    "OPERATORS_MATHOP_LIST_RANDOM": "列表中的随机项",
    "OPERATORS_QUOTIENT_INT": "%1 %2 %3 ÷ %4 商的整数部分",
    "OPERATORS_RANDOM_DECIMAL": "%1 %2 随机小数",
    "OPERATORS_BIN_NEGATION": "%1 %2 二进制取反 %3",
    "OPERATORS_INT_TO_STR": "%1 %2 10进制整数 %3 转化为 %4 字符串",
    "OPERATORS_MATHOP_INTTOSTR_BIN": "二进制",
    "OPERATORS_MATHOP_INTTOSTR_OCT": "八进制",
    "OPERATORS_MATHOP_INTTOSTR_HEX": "十六进制",
    "OPERATORS_BINSTR_TO_INT": "%1 %2 %3 字符串 %4 转化为10进制整数",
    "OPERATORS_INT_TO_BYTE": "%1 %2 10进制整数 %3 转化为 %4 字节",
    "OPERATORS_MATHOP_INTTOBYTE_TWO": "2",
    "OPERATORS_MATHOP_INTTOBYTE_FOUR": "4",
    "OPERATORS_INT_TO_ASCII": "%1 %2 整数 %3 转换为ASCII单字符",
    "OPERATORS_ASCII_TO_INT": "%1 %2 ASCII单字符 %3 转换为整数",
    "OPERATORS_STR_TO_INT": "%1 %2 字符串 %3 转十六进制字符串",
    "OPERATORS_HEXSTR_TO_BIN": "%1 %2 十六进制字符串 %3 转二进制字节",
    "OPERATORS_STR_TO_BASE64": "%1 %2 %3 以base64 格式编码 返回字节对象",
    "OPERATORS_BASE64_TO_BYTE": "%1 %2 解码base64数据 %3 返回字节对象",
    "OPERATORS_MATHOP_LENGHT": "%1 ᠶᠢᠨ ᠤᠷᠲᠤᠴᠠ",
    "OPERATORS_MATHOP_MAXMIN": "%1 %2",
    "OPERATORS_MATHOP_MAXMIN_MAX": " ᠬᠠᠮᠤᠭ ᠶᠡᠬᠡ ᠬᠡᠮᠵᠢᠭᠳᠡᠯ",
    "OPERATORS_MATHOP_MAXMIN_MIN": " ᠬᠠᠮᠤᠭ ᠪᠠᠭ᠎ᠠ ᠬᠡᠮᠵᠢᠭᠳᠡᠯ",
    "OPERATORS_TEXTS_ONE": "%1 %2 ᠢ  %3 %4 ᠲᠠᠢ ᠨᠡᠢᠯᠡᠭᠦᠯᠬᠦ",
    "OPERATORS_TEXTS_TWO": "%1 %2  %3 ᠶᠢᠨ %4 ᠳ᠋ᠦ᠋ᠭᠡᠷ ᠲᠡᠮᠳᠡᠭᠲᠦ ᠶᠢ ᠠᠪᠬᠤ",
    "OPERATORS_TEXTS_THREE": "%1 %2 %3  ᠳᠣᠲᠣᠷ᠎ᠠ %4 ᠢ ᠪᠠᠭᠲᠠᠭᠠᠬᠤ ᠤᠤ ?",
    "OPERATORS_TEXTS_FOUR": "%1 %2 %3 %4 %5  ᠲᠡᠮᠳᠡᠭᠲᠦ %6 %7  ᠲᠡᠮᠲᠡᠭᠲᠦ ᠶᠢ ᠣᠯᠬᠤ",
    "OPERATORS_TEXTS_FOUR_NEW": "%1 ᠨᠢ %2 %3 ᠦᠰᠦᠭ ᠢ ᠠᠪᠤᠭᠠᠳ᠂ %4 %5 ᠦᠰᠦᠭ ᠬᠦᠷᠭᠡᠬᠦ",
    "OPERATORS_TEXTS_NEW": "%1 %2 ᠨᠢ %3  ᠶᠢᠨ %4  ᠳᠡᠬᠢ %5  ᠳᠤ ᠢᠯᠡᠷᠡᠭᠰᠡᠨ ᠪᠠᠢᠷᠢ ᠶᠢ ᠬᠠᠢᠬᠤ",
    "OPERATORS_TEXTS_NEW_OP": "%1  ᠶᠢᠨ  %2 ᠳᠡᠬᠢ  %3  ᠳᠤ ᠢᠯᠡᠷᠡᠭᠰᠡᠨ ᠪᠠᠢᠷᠢ ᠶᠢ ᠬᠠᠢᠬᠤ",
    "OPERATORS_TEXTS_FOUR_ONE": " ᠡᠮᠦᠨ᠎ᠡ",
    "OPERATORS_TEXTS_FOUR_TWO": " ᠠᠳᠠᠭ ᠠᠴᠠ ᠨᠢ ᠲᠣᠭᠠᠯᠠᠬᠤ",
    "OPERATORS_TEXTS_FOUR_THREE": " ᠳᠡᠰ ᠲᠦ ᠣᠷᠣᠬᠤ",
    "OPERATORS_TEXTS_FOUR_FOUR": " ᠠᠳᠠᠭ ᠠᠴᠠ ᠬᠡᠳᠦ ᠶᠢ ᠲᠣᠭᠯᠠᠬᠤ",
    "OPERATORS_TEXTS_FOUR_F": "ᠠᠩᠬ᠎ᠠ",
    "OPERATORS_TEXTS_FOUR_L": "ᠡᠴᠦᠰ ᠦᠨ",
    "OPERATORS_INPUTSTRING": "%1 %2 文本 %3",
    "OPERATORS_TRANSINTOTEXT": "%1 %2 转为文本 %3",
    "OPERATORS_TRANSINTOTEXT_ADD": "加入",
    "OPERATORS_TRANSINTOTEXT_ADD_ITEM": "项",
    "OPERATORS_FORMATSTRING2F": "%1 %2 格式化字符串 %3 % %4",
    "OPERATORS_FORMATSTRING3": "%1 %2 格式化字符串 %3 format %4",
    "OPERATORS_TEXT_ESCAPE": "%1 %2 文本转义字符 %3",
    "BAR_N": "\\n",
    "BAR_NN": "\\n\\r",
    "BAR_R": "\\r",
    "BAR_RN": "\\r\\n",
    "BAR_B": "\\b",
    "BAR_T": "\\t",
    "BAR_BAR": "\\\\",
    "OPERATORS_TEXT_NUMORLETTER": "%1 %2 %3 %4？",
    "OPERATORS_TEXT_NUMORLETTER_NUM": "是数字",
    "OPERATORS_TEXT_NUMORLETTER_LETTER": "是字母",
    "OPERATORS_TEXT_LENGTH": "%1 %2 %3 的长度",
    "OPERATORS_TEXT_ISNONE": "%1 %2 %3 是空的？",
    "OPERATORS_FROMTEXT_GETCHAR": "%1 %2 从文本 %3 %4",
    "OPERATORS_FROMTEXT_GETCHAR_FIRST": "获得第一个字符",
    "OPERATORS_FROMTEXT_GETCHAR_LAST": "获得最后一个字符",
    "OPERATORS_FROMTEXT_GETCHAR_RANDOM": "获取随机的字母",
    "OPERATORS_TEXT_UPPLOW": "%1 %2 %3 %4",
    "OPERATORS_TEXT_UPPLOW_UPP": "转为大写",
    "OPERATORS_TEXT_UPPLOW_LOW": "转为小写",
    "OPERATORS_TEXT_SIDES_SPACE": "%1 %2 %3 %4",
    "OPERATORS_TEXT_SIDES_SPACE_BOTH": "清除两侧空格",
    "OPERATORS_TEXT_SIDES_SPACE_LEFT": "清除左侧空格",
    "OPERATORS_TEXT_SIDES_SPACE_RIGHT": "清除右侧空格",
    "OPERATORS_TEXT_STRTOBYTE": "%1 %2 转字节 %3",
    "OPERATORS_TEXT_BYTETOSTR": "%1 %2 字节 %3 转字符串",
    "OPERATORS_TEXT_DICTTOJSONSTR": "%1 %2 字典 %3 转json字符串",
    "OPERATORS_TEXT_PARSEJSONSTR": "%1 %2 解析json字符串 %3 返回对象",
    "OPERATORS_STRINGTONUMBERA": "%1 %2 ᠦᠰᠦᠭ ᠲᠡᠮᠳᠡᠭᠲᠦ %3 ᠶᠢ %4  ᠪᠣᠯᠭᠠᠬᠤ",
    "OPERATORS_NUMTOASCIIA": "%1 %2  ᠲᠣᠭ᠎ᠠ ᠳᠡᠮᠲᠡᠭᠲᠦ %3  ᠶᠢ ASCII ᠪᠣᠯᠭᠠᠨ ᠥᠭᠡᠷᠡᠴᠢᠯᠡᠬᠦ",
    "OPERATORS_STRINGTOASCIIA": "%1 %2  ᠦᠰᠦᠭ ᠲᠡᠮᠲᠡᠭᠲᠦ %3 ᠶᠢ ASCII ᠬᠡᠮᠵᠢᠭᠳᠡᠯ ᠪᠣᠯᠭᠠᠨ ᠥᠭᠡᠷᠡᠴᠢᠯᠡᠬᠦ",
    "OPERATORS_NUMTOSTRINGA": "%1 %2  ᠲᠣᠭ᠎ᠠ ᠲᠡᠮᠳᠡᠭᠲᠦ ᠶᠢ %3  ᠦᠰᠦᠭ ᠲᠡᠮᠳᠡᠭᠲᠦ ᠪᠣᠯᠭᠠᠨ ᠥᠭᠡᠷᠡᠴᠢᠯᠡᠬᠦ",
    "NEW_MC": " ᠰᠢᠨ᠎ᠡ ᠬᠤᠪᠢᠰᠬᠤᠨ ᠢ ᠪᠠᠢᠭᠤᠯᠬᠤ",
    "RENAME_MCNUMBER_TITLE": "ᠪᠦᠭᠦᠳᠡ  [%1]  ᠨᠡᠷ᠎ᠡ ᠲᠠᠢ ᠬᠤᠪᠢᠰᠬᠤᠨ ᠢ ᠳᠠᠬᠢᠨ ᠨᠡᠷᠡᠯᠡᠭᠡᠳ:",
    "RENAME_MCNUMBER_MODAL_TITLE": " ᠬᠤᠪᠢᠰᠬᠤᠨ ᠢ ᠳᠠᠬᠢᠨ ᠨᠡᠷᠡᠯᠡᠬᠦ",
    "RENAME_VARIABLE_MCNUMBER": " ᠬᠤᠪᠢᠰᠬᠤᠨ ᠢ ᠳᠠᠬᠢᠨ ᠨᠡᠷᠡᠯᠡᠬᠦ",
    "DELETE_VARIABLE_MCNUMBER": "[%1] ᠬᠤᠪᠢᠰᠬᠤᠨ ᠢ ᠤᠰᠠᠳᠬᠠᠬᠤ",
    "SET_MC_VARIABLE": "ᠬᠤᠪᠢᠰᠬᠤᠨ %1  ᠳᠤ  %2 ᠤᠨ ᠬᠡᠮᠵᠢᠭᠳᠡᠯ ᠢ ᠥᠭᠬᠦ",
    "ALIYUN": " ᠠ ᠯᠢ ᠡᠭᠦᠯᠡᠨᠴᠢᠷ",
    "ONENET": "OneNet",
    "ONENETNEW": "OneNet(新版)",
    "EASYIOT": "Easy IoT",
    "SIOT": "SIoT",
    "SHANGHAI": " ᠱᠠᠩᠬᠠᠢ",
    "QINGDAO": " ᠴᠢᠩᠳᠣᠣ",
    "BEIJING": " ᠪᠡᠭᠡᠵᠢᠩ",
    "ZHANGJIAKOU": " ᠴᠢᠭᠤᠯᠭᠠᠲᠤ ᠬᠠᠭᠠᠯᠭ᠎ᠠ",
    "HUHEHAOTE": " ᠬᠥᠬᠡᠬᠣᠲᠠ",
    "HANGZHOU": " ᠬᠠᠩᠵᠸᠦ",
    "SHENZHEN": " ᠱᠸ᠋ᠨ᠋ᠵᠸ᠋ᠨ",
    "HONGKONG": " ᠰᠢᠶᠠᠩᠭᠠᠩ",
    "SINGAPORE": " ᠰᠢᠩᠭᠠᠫᠤᠷ",
    "SYDNEY": " ᠰᠢᠳ᠋ᠨᠢ",
    "KUALALUMPUR": " ᠺᠦᠢᠸᠠᠯᠠ ᠯᠦᠮᠫᠦ᠋ᠷ",
    "JAKARTA": " ᠵᠠᠺᠠᠷᠲ᠋ᠠ",
    "MUMBAI": " ᠪᠤᠮᠪᠸᠢ",
    "TOKYO": " ᠲᠣᠣᠺᠢᠤ᠋",
    "SILICONVALLEY": " ᠴᠠᠬᠢᠭᠤᠷ  ᠤᠨ ᠬᠥᠨᠳᠡᠢ",
    "VIRGINIA": " ᠸᠢᠷᠭᠢᠨᠢᠶ᠎ᠠ ᠵᠸᠤ",
    "FRANKFURT": " ᠹᠷᠠᠩᠹᠦᠷᠲ",
    "LONDON": " ᠯᠤᠨᠳᠤᠨ",
    "DUBAI": " ᠳ᠋ᠦᠪᠡᠢ ᠬᠣᠲᠠ",
    "IOT_PLATFORM": " ᠪᠣᠳᠠᠰ ᠤᠨ ᠬᠣᠯᠪᠣᠭᠠᠨ ᠤ ᠳᠠᠪᠴᠠᠩ",
    "PARAMS": " ᠫᠠᠷᠠᠮᠸ᠋ᠲᠷ",
    "SERVER_ATTR": "ᠦᠢᠯᠡᠴᠢᠯᠡᠭᠦᠷ ᠮᠠᠰᠢᠨ ᠤ ᠬᠠᠶ᠋ᠢᠭ",
    "PRODUCTID": "ᠲᠠᠸᠠᠷ ᠤᠨ ID",
    "DEVICEID": "ᠲᠥᠬᠦᠭᠡᠷᠦᠮᠵᠢ ᠶᠢᠨ ID",
    "DEVICENAME": "设备名称",
    "TINYDB_SECRET": "ᠨᠢᠭᠤᠴᠠ ᠲᠦᠯᠬᠢᠭᠦᠷ(secret)",
    "TINYDB_USER": "ᠬᠡᠷᠡᠭᠯᠡᠭᠴᠢ ᠶᠢᠨ ᠨᠡᠷ᠎ᠡ (user)",
    "APIADDR": "API ᠬᠠᠶᠢᠭ",
    "SOFTWARE_SERIAL": "ᠵᠥᠭᠡᠯᠡᠨ ᠤᠭᠰᠠᠷᠠᠭ᠎ᠠ ᠵᠠᠯᠭᠠᠭᠤᠷ",
    "HARDWARE_SERIAL": "ᠬᠠᠲᠠᠭᠤ ᠤᠭᠰᠠᠷᠠᠭ᠎ᠠ ᠵᠠᠯᠭᠠᠭᠤᠷ",
    "HARDWARE_SERIAL1": "ᠬᠠᠲᠠᠭᠤ ᠤᠭᠰᠠᠷᠠᠭ᠎ᠠ ᠵᠠᠯᠭᠠᠭᠤᠷ 1",
    "HARDWARE_SERIAL2": "ᠬᠠᠲᠠᠭᠤ ᠤᠭᠰᠠᠷᠠᠭ᠎ᠠ ᠵᠠᠯᠭᠠᠭᠤᠷ 2",
    "HARDWARE_SERIAL3": "ᠬᠠᠲᠠᠭᠤ ᠤᠭᠰᠠᠷᠠᠭ᠎ᠠ ᠵᠠᠯᠭᠠᠭᠤᠷ 3",
    "CHECKTYPE_TIPS": "输入框接收的数据类型和积木块的输出类型不匹配",
    "HIGHLIGHT_BLOCK": "高亮",
    "HIGHLIGHT_ALL_CONFLICT_BLOCKS": "高亮全部有警告的积木块",
    "SNAPSHOT": "截图（CTRL+G）",
    "pictureaiPath": "请输入图片路径或网址",
    "pictureaiType": "账户类型",
    "pictureaiAccountDefault": "默认账户",
    "pictureaiAccountBaidu": "百度",
    "pictureaiwebImgAddr": "网络图片地址",
    "MITRIXICON_EDIT": "点亮",
    "MITRIXICON_ERASER": "擦除",
    "MITRIXICON_DUSTBIN": "清空",
    "MITRIXICON_REVERSE": "反色",
    "MITRIXICON_COLOR": "选择颜色",
    "RED": "红",
    "GREEN": "绿",
    "BLUE": "蓝",
    "SEARCH_BLOCKS": "搜索积木",
    "NO_SEARCH_BLOCKS": "没有搜索积木",
    "SEARCH_DEFAULT_LABEL_THINK": "思考",
    "SEARCH_DEFAULT_LABEL_IF": "如果",
    "SEARCH_DEFAULT_LABEL_WAIT": "等待",
    "SEARCH_DEFAULT_LABEL_CONVERT": "转换",
    "SEARCH_DEFAULT_LABEL_BROADCAST": "广播",
    "MITRIXICON_SAVE": "保存",
    "MITRIXICON_EXPORT": "导出点阵图案",
    "MITRIXICON_UPLOAD": "导入点阵图案",
    "ROLL_MOUSE": "滚动鼠标滑动",
    "MOUSE_ZOOM_IN_OUT": "滚动鼠标放大缩小",
    "UPLOAD_CORRECT_JSON_FILE": "请上传正确格式的json文件",
    "EXPORT_PROMPT": "没有要导出的自定义图案。请先点击右下角的保存按钮，保存自定义的图案后，再尝试导出",
    "HEART": "心形",
    "HEART_SMALL": "心形(小)",
    "HAPPY": "高兴",
    "SMILE": "笑脸",
    "SAD": "伤心",
    "CONFUSED": "困惑",
    "ANGRY": "生气",
    "SLEEP": "睡着",
    "SURPRISED": "惊讶",
    "SILLY": "愚蠢",
    "WONDERFUL": "极妙",
    "BORED": "无聊",
    "ROCK": "石头",
    "ROCK_SMALL": "石头(小)",
    "PAPER": "布",
    "PAPER_SMALL": "布(小)",
    "SCISSORS": "剪刀",
    "SCISSORS_SMALL": "剪刀(小)",
    "DATA_SOLITLIST": "%1 %2 %3 %4 用分隔符 %5",
    "DATA_SOLITLIST_TYPE_LT": "从列表组合文本",
    "DATA_SOLITLIST_TYPE_TL": "从文本制作列表",
    "DATA_LENLIST": "%1 %2 列表 %3 是空的？",
    "DATA_LENGTHLIST": "%1 %2 列表 %3 的长度",
    "DATA_CREATELIST": "%1 %2 初始化列表 %3",
    "DATA_CLEARLIST": "%1 %2 清空列表 %3",
    "ML5MODALBUTTON": "打开AI可视化窗口",
    "COLLAPSE_BLOCK": "折叠",
    "EXPAND_BLOCK": "展开",
    "COLLAPSE_ALL": "折叠所有积木",
    "EXPAND_ALL": "展开所有积木",
    "pyTimer": "定时器",
    "pyList": "列表",
    "pyDictionary": "字典",
    "pySet": "集合",
    "mpyTuple": "元组",
    "pythonBase_printAndInput": "输入输出",
    "pythonBase_mainSetUp": "%1 %2 Python主程序开始",
    "pythonBase_print": "%1 %2 打印 %3",
    "pythonBase_input": "%1 %2 获取输入，提示语为 %3",
    "pythonBase_file": "文件功能",
    "pythonBase_file_open": "%1 %2 以 %3 格式 %4 模式打开文件 %5 并返回 %6",
    "pythonBase_file_open_b": "%1 %2 以 %3 模式打开文件 %4 并返回 %5",
    "pythonBase_file_open_read": "读取",
    "pythonBase_file_open_write": "写入",
    "pythonBase_file_open_add": "追加",
    "pythonBase_file_open_read_bit": "二进制读取",
    "pythonBase_file_open_write_bit": "二进制写入",
    "pythonBase_file_open_add_bit": "二进制追加",
    "pythonBase_file_close": "%1 %2 %3 关闭",
    "pythonBase_file_read": "%1 %2 %3 读取 %4",
    "pythonBase_file_read_all": "整个文件",
    "pythonBase_file_read_line": "一行内容",
    "pythonBase_file_read_lines": "整个文件所有行",
    "pythonBase_file_write": "%1 %2 %3 写入 %4",
    "pythonBase_time": "系统时间",
    "pythonBase_get_time": "%1 %2 获取系统时间 %3",
    "pythonBase_get_time_by_year": "年",
    "pythonBase_get_time_by_month": "月",
    "pythonBase_get_time_by_day": "日",
    "pythonBase_get_time_by_hour": "时",
    "pythonBase_get_time_by_minute": "分",
    "pythonBase_get_time_by_second": "秒",
    "pythonBase_get_time_by_day_of_week": "本周的第几天",
    "pythonBase_get_time_by_day_of_year": "今年的第几天",
    "pythonBase_get_time_format": "%1 %2 获取系统时间 %3",
    "pythonBase_get_time_format_by_hour_minute_second": "时:分:秒",
    "pythonBase_get_time_format_by_hour_minute": "时:分",
    "pythonBase_get_time_format_by_year_month_day": "年/月/日",
    "pythonBase_get_time_format_by_year_month": "年/月",
    "pythonBase_get_time_format_by_year_month_day_": "年-月-日",
    "pythonBase_get_time_format_by_year_month_day_hour_minute_second": "年/月/日 时:分:秒",
    "pythonBase_get_timestamp": "%1 %2 获取系统时间戳",
    "pythonBase_get_time_format_custom": "%1 %2 获取系统时间 格式化 (%3) ",
    "pyTimer_setEvent": "%1 %2 设置定时器 %3 %4 周期 %5 毫秒",
    "pyTimer_repeated": "重复执行",
    "pyTimer_delayed": "延时执行",
    "pyTimer_conditionEvent": "%1 %2 设置定时器 %3 :当 %4",
    "pyTimer_getCount": "%1 %2 定时器 %3 的计数值",
    "pyTimer_clearEvent": "%1 %2 清除 定时器/事件 %3",
    "pyDictionary_init": "%1 %2 初始化字典 %3",
    "pyDictionary_getValue": "%1 %2 字典 %3 键 %4 的值",
    "pyDictionary_setValue": "%1 %2 字典 %3 键 %4 的值设置为 %5",
    "pyDictionary_deleteKey": "%1 %2 字典 %3 删除键 %4",
    "pyDictionary_clear": "%1 %2 清空字典 %3",
    "pyDictionary_isInclude": "%1 %2 字典 %3 包含键 %4？",
    "pyDictionary_getLength": "%1 %2 字典 %3 长度",
    "pyDictionary_list": "%1 %2 字典 %3 %4 的列表",
    "pyDictionary_TypeIndex_A": "键",
    "pyDictionary_TypeIndex_B": "值",
    "pyList_join": "%1 %2 列表 %3 使用分隔符 %4 组合文本",
    "pyList_split": "%1 %2 文本 %3 使用分隔符 %4 制作列表",
    "pyList_init": "%1 %2 初始化列表 %3",
    "pyList_clear": "%1 %2 清空列表 %3",
    "pyList_getLength": "%1 %2 列表 %3 的长度",
    "pyList_isEmpty": "%1 %2 列表 %3 是空的？",
    "pyList_getValue": "%1 %2 列表 %3 索引 %4 的值",
    "pyList_getValueRange": "%1 %2 返回列表 %3 取 %4 %5 项到 %6 %7 项",
    "pyList_insert": "%1 %2 列表 %3 在索引 %4 处插入 %5",
    "pyList_setValue": "%1 %2 列表 %3 设置索引 %4 的值为 %5",
    "pyList_delete": "%1 %2 列表 %3 删除索引 %4 的值",
    "pyList_append": "%1 %2 列表 %3 将 %4 添加到末尾",
    "pyList_extend": "%1 %2 列表 %3 追加列表 %4",
    "pyList_findIndex": "%1 %2 列表 %3 查找 %4 的索引",
    "pyList_sort": "%1 %2 列表 %3 按 %4 排序为 %5",
    "pyList_SortModeIndex_A": "升序",
    "pyList_SortModeIndex_B": "降序",
    "pyList_SortTypeIndex_A": "数字",
    "pyList_SortTypeIndex_B": "字母",
    "pyList_SortTypeIndex_C": "字母，忽略大小写",
    "pyList_has": "%1 %2 列表 %3 是否存在 %4 ?",
    "pySet_init": "%1 %2 初始化集合 %3 ",
    "pySet_initEmpty": "%1 %2 初始化空集合",
    "pySet_update": "%1 %2 集合 %3  更新为与集合 %4 的 %5",
    "pySet_TypeIndex_A": "交集",
    "pySet_TypeIndex_B": "并集",
    "pySet_TypeIndex_C": "差集",
    "pySet_remove": "%1 %2 集合 %3 移除 %4",
    "pySet_clear": "%1 %2 清空集合 %3",
    "pySet_copy": "%1 %2 复制集合 %3",
    "pySet_has": "%1 %2 集合 %3 是否存在 %4",
    "pySet_addValue": "%1 %2 集合 %3 添加单个元素 %4",
    "pySet_updateValue": "%1 %2 集合 %3 添加可迭代对象 %4",
    "pySet_isSubsetSuperset": "%1 %2 集合 %3 为集合 %4 的 %5？",
    "pySet_SubsetSupersetType_A": "子集",
    "pySet_SubsetSupersetType_B": "超集",
    "pySet_intersectionUnionSetDifference": "%1 %2 取 %3 集合 %4 集合 %5",
    "pySet_length": "%1 %2 集合 %3 长度",
    "pySet_pop": "%1 %2 返回随机项 并从集合 %3 移除",
    "pyTuple_init": "%1 %2 初始化元组 %3",
    "pyTuple_minMaxLength": "%1 %2 元组 %3 %4",
    "pyTuple_TypeIndex_A": "最小值",
    "pyTuple_TypeIndex_B": "最大值",
    "pyTuple_TypeIndex_C": "长度",
    "pyTuple_isInclude": "%1 %2 元组 %3 包含 %4？",
    "pyTuple_getValueRange": "%1 %2 返回元组 %3 取 %4 %5 项到 %6 %7 项",
    "pyTuple_IndexType_A": "第",
    "pyTuple_IndexType_B": "倒数第",
    "pyTuple_listToTulpe": "%1 %2 列表 %3 转元组",
    "pyTuple_getValue": "%1 %2 元组 %3 索引 %4 的值",
    "pyTuple_tupleToList": "%1 %2 元组 %3 转变为列表",
    "text_decode_encode": "解码/编码",
    "coder": "%1 %2 %3 文本 %4 %5",
    "decode": "解码",
    "encode": "编码",
    "mathop_specially": "%1 %2 %3 转换为 %4"
  },
  "khm": {
    "CONTROL_FOREVER": "ធ្វើរហូត",
    "CONTROL_LOOP_BREAKCONTINUE": "%1",
    "CONTROL_LOOP_BREAKCONTINUE_BREAK": "break",
    "CONTROL_LOOP_BREAKCONTINUE_CONTINUE": "continue",
    "CONTROL_REPEAT": "ធ្វើដដែលៗចំនួន %1 ដង",
    "CONTROL_IF": "ប្រសិនបើ %1 ធ្វើ",
    "CONTROL_ELSE": "បើមិនអញ្ចឹងទេ",
    "CONTROL_STOP": "បញ្ឈប់",
    "CONTROL_STOP_ALL": "ទាំងអស់",
    "CONTROL_STOP_THIS": "ស្ក្រីបនេះ",
    "CONTROL_STOP_OTHER": "ស្ក្រីបផ្សេងទៀតរបស់តួអង្គ",
    "CONTROL_WAIT": "រង់ចាំ %1 វិនាទី",
    "CONTROL_WAIT_ESP32_MPY": "wait %1 %2",
    "CONTROL_FOR_FROM_SEQUENCE": "use %1 from sequence %2",
    "CONTROL_WAIT_ESP32_MPY_S": "second(s)",
    "CONTROL_WAIT_ESP32_MPY_MS": "millisecond(ms)",
    "CONTROL_WAIT_ESP32_MPY_US": "microsecond(us)",
    "CONTROL_WAITUNTIL": "រង់ចាំរហូតដល់ %1",
    "CONTROL_REPEATUNTIL": "ធ្វើដដែលៗរហូតដល់ %1",
    "CONTROL_WHILE": "នៅពេលដែល %1",
    "CONTROL_FOREACH": "សម្រាប់ធាតុ %1 ដែលនៅក្នុង %2",
    "CONTROL_STARTASCLONE": "ពេលខ្ញុំចាប់ផ្តើមជារូបចម្លង",
    "CONTROL_CREATECLONEOF": "ចម្លងរូបរាងនៃ %1",
    "CONTROL_CREATECLONEOF_MYSELF": "ខ្លួនឯង",
    "CONTROL_DELETETHISCLONE": "លុបរូបចម្លងនេះ",
    "CONTROL_COUNTER": "ប្រដាប់រាប់",
    "CONTROL_INCRCOUNTER": "ប្រដាប់រាប់ រាប់កើន",
    "CONTROL_CLEARCOUNTER": "កំណត់ប្រដាប់រាប់ឡើងវិញ",
    "CONTROL_ALLATONCE": "ទាំងអស់ក្នុងពេលតែមួយ",
    "DATA_SETVARIABLETO": "ដាក់ %1 ទៅជា %2",
    "DATA_CHANGEVARIABLEBY": "ប្តូរ %1 ចំនួន %2",
    "DATA_SHOWVARIABLE": "បង្ហាញអថេរ %1",
    "DATA_HIDEVARIABLE": "លាក់អថេរ %1",
    "DATA_ADDTOLIST": "បន្ថែម %1 ទៅក្នុង %2",
    "DATA_DELETEOFLIST": "លុបធាតុ %1 ចេញពី %2",
    "DATA_DELETEALLOFLIST": "លុបទាំងអស់ចេញពី %1",
    "DATA_INSERTATLIST": "បញ្ជូល %1 នៅលំដាប់ %2 នៃ %3",
    "DATA_REPLACEITEMOFLIST": "ជំនួសធាតុ %1 នៃ %2 ដោយ %3",
    "DATA_ITEMOFLIST": "ធាតុ %1 នៃ %2",
    "DATA_ITEMNUMOFLIST": "លេខធាតុទី %1 នៃ %2",
    "DATA_LENGTHOFLIST": "ប្រវែងនៃ %1",
    "DATA_LISTCONTAINSITEM": "%1 មានធាតុ %2?",
    "DATA_SHOWLIST": "បង្ហាញបញ្ជី %1",
    "DATA_HIDELIST": "លាក់បញ្ជី %1",
    "DATA_INDEX_ALL": "ទាំងអស់",
    "DATA_INDEX_LAST": "ចុងក្រោយបំផុត",
    "DATA_INDEX_RANDOM": "ចៃដន្យ",
    "EVENT_WHENFLAGCLICKED": "ពេលចុច %1",
    "EVENT_WHENTHISSPRITECLICKED": "ពេលចុចតួអង្គ",
    "EVENT_WHENSTAGECLICKED": "ពេលចុចឆាក",
    "EVENT_WHENTOUCHINGOBJECT": "ពេលតួអង្គប៉ះ %1",
    "EVENT_WHENBROADCASTRECEIVED": "ពេលខ្ញុំទទួលបាន %1",
    "EVENT_WHENBACKDROPSWITCHESTO": "ពេលផ្ទាំងខាងក្រោយប្តូរទៅជា %1",
    "EVENT_WHENGREATERTHAN": "ពេល %1 > %2",
    "EVENT_WHENGREATERTHAN_TIMER": "ឧបករណ៍កំណត់ពេលវេលា",
    "EVENT_WHENGREATERTHAN_LOUDNESS": "កម្រិតឮ​",
    "EVENT_BROADCAST": "បញ្ជូនសារ %1",
    "EVENT_BROADCASTANDWAIT": "បញ្ជូនសារ %1 ហើយរង់ចាំ",
    "EVENT_WHENKEYPRESSED": "ពេលចុច %1",
    "EVENT_WHENKEYPRESSED_SPACE": "ដកឃ្លា",
    "EVENT_WHENKEYPRESSED_LEFT": "← ព្រួញខាងឆ្វេង",
    "EVENT_WHENKEYPRESSED_RIGHT": "→ ព្រួញខាងស្តាំ",
    "EVENT_WHENKEYPRESSED_DOWN": "↓ ព្រួញខាងក្រោម",
    "EVENT_WHENKEYPRESSED_UP": "↑ ព្រួញខាងលើ",
    "EVENT_WHENKEYPRESSED_ANY": "ណាមួយ",
    "LOOKS_SAYFORSECS": "និយាយ %1 រយៈពេល %2 វិនាទី",
    "LOOKS_SAY": "និយាយ %1",
    "LOOKS_HELLO": "សួស្តី!",
    "LOOKS_THINKFORSECS": "គិត %1 រយៈពេល %2 វិនាទី",
    "LOOKS_THINK": "គិត %1",
    "LOOKS_HMM": "អឺ...",
    "LOOKS_SHOW": "បង្ហាញ",
    "LOOKS_HIDE": "លាក់",
    "LOOKS_HIDEALLSPRITES": "លាក់តួអង្គទាំងអស់",
    "LOOKS_EFFECT_COLOR": "ពណ៌",
    "LOOKS_EFFECT_FISHEYE": "ភ្នែកត្រី",
    "LOOKS_EFFECT_WHIRL": "ខ្យល់កួច",
    "LOOKS_EFFECT_PIXELATE": "បំបែកជាភីកសែល",
    "LOOKS_EFFECT_MOSAIC": "សិល្បៈនៃការផ្គុំ",
    "LOOKS_EFFECT_BRIGHTNESS": "កម្រិតពន្លឺ",
    "LOOKS_EFFECT_GHOST": "ខ្មោច",
    "LOOKS_CHANGEEFFECTBY": "ប្តូរបែបផែន %1 ចំនួន %2",
    "LOOKS_SETEFFECTTO": "ដាក់បែបផែន %1 ត្រឹម %2",
    "LOOKS_CLEARGRAPHICEFFECTS": "លុបបែបផែនក្រាហ្វិច",
    "LOOKS_CHANGESIZEBY": "ប្តូរទំហំចំនួន %1",
    "LOOKS_SETSIZETO": "ដាក់ទំហំត្រឹម %1 %",
    "LOOKS_SIZE": "ទំហំ",
    "LOOKS_CHANGESTRETCHBY": "ប្តូរភាពយឺត %1",
    "LOOKS_SETSTRETCHTO": "ដាក់ភាពយឺតត្រឹម %1 %",
    "LOOKS_SWITCHCOSTUMETO": "ប្តូររូបរាងទៅជា %1",
    "LOOKS_NEXTCOSTUME": "រូបរាងបន្ទាប់",
    "LOOKS_SWITCHBACKDROPTO": "ប្តូរផ្ទាំងខាងក្រោយទៅជា %1",
    "LOOKS_GOTOFRONTBACK": "ទៅស្រទាប់ %1",
    "LOOKS_GOTOFRONTBACK_FRONT": "មុខ",
    "LOOKS_GOTOFRONTBACK_BACK": "ក្រោយ",
    "LOOKS_GOFORWARDBACKWARDLAYERS": "ទៅ %1 %2 ស្រទាប់",
    "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "ខាងមុខ",
    "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "ខាងក្រោយ",
    "LOOKS_BACKDROPNUMBERNAME": "ផ្ទាំងខាងក្រោយ %1",
    "LOOKS_COSTUMENUMBERNAME": "រូបរាង %1",
    "LOOKS_NUMBERNAME_NUMBER": "លេខ",
    "LOOKS_NUMBERNAME_NAME": "ឈ្មោះ",
    "LOOKS_SWITCHBACKDROPTOANDWAIT": "ប្តូរផ្ទាំងខាងក្រោយទៅជា %1 ហើយរង់ចាំ",
    "LOOKS_NEXTBACKDROP_BLOCK": "ផ្ទាំងខាងក្រោយបន្ទាប់",
    "LOOKS_NEXTBACKDROP": "ផ្ទាំងខាងក្រោយបន្ទាប់",
    "LOOKS_PREVIOUSBACKDROP": "ផ្ទាំងខាងក្រោយពីមុន",
    "LOOKS_RANDOMBACKDROP": "ផ្ទាំងខាងក្រោយចៃដន្យ",
    "MOTION_MOVESTEPS": "ផ្លាស់ទី %1 ជំហាន",
    "MOTION_TURNLEFT": "បង្វិល %1 %2 ដឺក្រេ",
    "MOTION_TURNRIGHT": "បង្វិល %1 %2 ដឺក្រេ",
    "MOTION_POINTINDIRECTION": "ចង្អុលទៅទិសដៅ %1",
    "MOTION_POINTTOWARDS": "ចង្អុលទៅ %1",
    "MOTION_POINTTOWARDS_POINTER": "ព្រួញម៉ៅស៍",
    "MOTION_POINTTOWARDS_RANDOM": "ទិសដៅចៃដន្យ",
    "MOTION_GOTO": "ទៅកាន់ %1",
    "MOTION_GOTO_POINTER": "ព្រួញម៉ៅស៍",
    "MOTION_GOTO_RANDOM": "ទីតាំងចៃដន្យ",
    "MOTION_GOTOXY": "ទៅកាន់ x: %1 y: %2",
    "MOTION_GLIDESECSTOXY": "រំកិល %1 វិនាទី ទៅ x: %2 y: %3",
    "MOTION_GLIDETO": "រំកិល %1 វិនាទី ទៅ %2",
    "MOTION_GLIDETO_POINTER": "ព្រួញម៉ៅស៍",
    "MOTION_GLIDETO_RANDOM": "ទីតាំងចៃដន្យ",
    "MOTION_CHANGEXBY": "ប្តូរ x ចំនួន %1",
    "MOTION_SETX": "ដាក់ x ត្រឹម %1",
    "MOTION_CHANGEYBY": "ប្តូរ y ចំនួន %1",
    "MOTION_SETY": "ដាក់ y ត្រឹម %1",
    "MOTION_IFONEDGEBOUNCE": "បើនៅគែមឆាក, ខ្ទាតចេញមកវិញ",
    "MOTION_SETROTATIONSTYLE": "ដាក់ស្តាយបង្វិល %1",
    "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "ឆ្វេង-ស្តាំ",
    "MOTION_SETROTATIONSTYLE_DONTROTATE": "កុំបង្វិល",
    "MOTION_SETROTATIONSTYLE_ALLAROUND": "ពេញមួយជុំ",
    "MOTION_XPOSITION": "ទីតាំង x",
    "MOTION_YPOSITION": "ទីតាំង y",
    "MOTION_DIRECTION": "ទិសដៅ",
    "MOTION_SCROLLRIGHT": "មូរទៅខាងស្តាំ %1",
    "MOTION_SCROLLUP": "មូរឡើងលើ %1",
    "MOTION_ALIGNSCENE": "តំរឹមជាមួយឆាក %1",
    "MOTION_ALIGNSCENE_BOTTOMLEFT": "ខាងក្រោម-ឆ្វេង",
    "MOTION_ALIGNSCENE_BOTTOMRIGHT": "ខាងក្រោម-ស្តាំ",
    "MOTION_ALIGNSCENE_MIDDLE": "កណ្តាល",
    "MOTION_ALIGNSCENE_TOPLEFT": "ខាងលើ-ឆ្វេង",
    "MOTION_ALIGNSCENE_TOPRIGHT": "ខាងលើ-ស្តាំ",
    "MOTION_XSCROLL": "មូរតាម x",
    "MOTION_YSCROLL": "មូរតាម y",
    "MOTION_STAGE_SELECTED": "ឆាកដែលអ្នកជ្រើសរើស មិនមាន ប្លុកចលនាទេ",
    "OPERATORS_ADD": "%1 + %2",
    "OPERATORS_SUBTRACT": "%1 - %2",
    "OPERATORS_MULTIPLY": "%1 * %2",
    "OPERATORS_DIVIDE": "%1 / %2",
    "OPERATORS_RANDOM": "យកតម្លៃចៃដន្យពី %1 ទៅ %2",
    "OPERATORS_GT": "%1 > %2",
    "OPERATORS_GT_EQUALS": "%1 >= %2",
    "OPERATORS_LT": "%1 < %2",
    "OPERATORS_LT_EQUALS": "%1 <= %2",
    "OPERATORS_EQUALS": "%1 = %2",
    "OPERATORS_AND": "%1 និង %2",
    "OPERATORS_OR": "%1 ឬ %2",
    "OPERATORS_NOT": "មិនមែន %1",
    "CONTROL_TRUE_FALSE": "%1",
    "CONTROL_TRUE_FALSE_TRUE": "true",
    "CONTROL_TRUE_FALSE_FALSE": "false",
    "CONTROL_NONE": "None",
    "CONTROL_RETURN": "return %1",
    "OPERATORS_VALUE_IS_TRUE": "value %1 is true?",
    "OPERATORS_VALUE_TYPE": "value %1 type",
    "CONTROL_TRY": "try",
    "CONTROL_EXCEPT": "except",
    "CONTROL_FINALLY": "finally",
    "OPERATORS_VALUE_TYPE_IS": "value %1 type is %2?",
    "OPERATORS_VALUE_TYPE_INT": "int",
    "OPERATORS_VALUE_TYPE_FLOAT": "float",
    "OPERATORS_VALUE_TYPE_BOOL": "bool",
    "OPERATORS_VALUE_TYPE_STR": "str",
    "OPERATORS_VALUE_TYPE_LIST": "list",
    "OPERATORS_VALUE_TYPE_TUPLE": "tuple",
    "OPERATORS_VALUE_TYPE_SET": "set",
    "OPERATORS_VALUE_TYPE_DICT": "dict",
    "OPERATORS_VALUE_TYPE_BYTES": "bytes",
    "OPERATORS_VALUE_TYPE_BYTEARRAY": "bytearray",
    "OPERATORS_VALUE_TYPE_COMPLEX": "complex",
    "OPERATORS_CACULATE_RESULT": "eval %1",
    "OPERATORS_JOIN": "ភ្ជាប់ %1 %2",
    "OPERATORS_JOIN_APPLE": "ប៉ោម",
    "OPERATORS_JOIN_BANANA": "ចេក",
    "OPERATORS_LETTEROF": "តួអក្សរទី %1 នៃ %2",
    "OPERATORS_LETTEROF_APPLE": "ប",
    "OPERATORS_LENGTH": "ប្រវែងនៃ %1",
    "OPERATORS_CONTAINS": "%1 មាន %2?",
    "OPERATORS_MOD": "សំណល់នៃ %1 ចែកនឹង %2",
    "OPERATORS_ROUND": "បង្គត់ %1",
    "OPERATORS_MATHOP": "%1 នៃ %2",
    "OPERATORS_MATHOP_ABS": "តម្លៃដាច់ខាត",
    "OPERATORS_MATHOP_FLOOR": "បង្គត់ចុះ",
    "OPERATORS_MATHOP_CEILING": "បង្គត់ឡើង",
    "OPERATORS_MATHOP_SQRT": "ឫសការ៉េ",
    "OPERATORS_MATHOP_SIN": "sin",
    "OPERATORS_MATHOP_COS": "cos",
    "OPERATORS_MATHOP_TAN": "tan",
    "OPERATORS_MATHOP_ASIN": "asin",
    "OPERATORS_MATHOP_ACOS": "acos",
    "OPERATORS_MATHOP_ATAN": "atan",
    "OPERATORS_MATHOP_LN": "ln",
    "OPERATORS_MATHOP_LOG": "log",
    "OPERATORS_MATHOP_EEXP": "e ^",
    "OPERATORS_MATHOP_10EXP": "10 ^",
    "PROCEDURES_DEFINITION": "កំណត់ន័យ %1",
    "SENSING_TOUCHINGOBJECT": "ប៉ះ %1?",
    "SENSING_TOUCHINGOBJECT_POINTER": "ព្រួញម៉ៅស៍",
    "SENSING_TOUCHINGOBJECT_EDGE": "គែម",
    "SENSING_TOUCHINGCOLOR": "ប៉ះពណ៌ %1?",
    "SENSING_COLORISTOUCHINGCOLOR": "ពណ៌ %1 ប៉ះ %2?",
    "SENSING_DISTANCETO": "ចំងាយពី %1",
    "SENSING_DISTANCETO_POINTER": "ព្រួញម៉ៅស៍",
    "SENSING_ASKANDWAIT": "សួរ %1 ហើយរង់ចាំ",
    "SENSING_ASK_TEXT": "តើអ្នកឈ្មោះអ្វី?",
    "SENSING_ANSWER": "ចម្លើយ",
    "SENSING_KEYPRESSED": "គ្រាប់ចុច %1 ត្រូវបានចុច?",
    "SENSING_MOUSEDOWN": "ម៉ៅស៍ចុះ?",
    "SENSING_MOUSEX": "ទីតាំងព្រួញម៉ៅស៍ x",
    "SENSING_MOUSEY": "ទីតាំងព្រួញម៉ៅស៍ y",
    "SENSING_SETDRAGMODE": "ដាក់ម៉ូតអូស %1",
    "SENSING_SETDRAGMODE_DRAGGABLE": "អាចអូសបាន",
    "SENSING_SETDRAGMODE_NOTDRAGGABLE": "មិនអាចអូសបាន",
    "SENSING_LOUDNESS": "កម្រិតឮ​",
    "SENSING_LOUD": "ឮ​ខ្លាំង?",
    "SENSING_TIMER": "ឧបករណ៍រាប់ពេលវេលា (វិនាទី)",
    "SENSING_RESETTIMER": "កំណត់ឧបករណ៍រាប់ពេលវេលាសាជាថ្មី​ (0)",
    "SENSING_OF": "%1 នៃ %2",
    "SENSING_OF_XPOSITION": "ទីតាំង x",
    "SENSING_OF_YPOSITION": "ទីតាំង y",
    "SENSING_OF_DIRECTION": "ទិសដៅ",
    "SENSING_OF_COSTUMENUMBER": "រូបរាង #",
    "SENSING_OF_COSTUMENAME": "ឈ្មោះរូបរាង",
    "SENSING_OF_SIZE": "ទំហំ",
    "SENSING_OF_VOLUME": "កម្រិតសម្លេង",
    "SENSING_OF_BACKDROPNUMBER": "ផ្ទាំងខាងក្រោយ #",
    "SENSING_OF_BACKDROPNAME": "ឈ្មោះផ្ទាំងខាងក្រោយ",
    "SENSING_OF_STAGE": "ឆាក",
    "SENSING_CURRENT": "បច្ចុប្បន្ន %1",
    "SENSING_CURRENT_YEAR": "ឆ្នាំ",
    "SENSING_CURRENT_MONTH": "ខែ",
    "SENSING_CURRENT_DATE": "ថ្ងៃទី",
    "SENSING_CURRENT_DAYOFWEEK": "ថ្ងៃប្រចាំសប្តាហ៍",
    "SENSING_CURRENT_HOUR": "ម៉ោង",
    "SENSING_CURRENT_MINUTE": "នាទី",
    "SENSING_CURRENT_SECOND": "វិនាទី",
    "SENSING_DAYSSINCE2000": "ចំនួនថ្ងៃចាប់ពីឆ្នាំ 2000",
    "SENSING_USERNAME": "ឈ្មោះអ្នកប្រើប្រាស់",
    "SENSING_USERID": "លេខសម្គាល់អ្នកប្រើប្រាស់",
    "SOUND_PLAY": "ផ្តើមសម្លេង %1",
    "SOUND_PLAYUNTILDONE": "លេងសម្លេង %1 រហូតដល់ចប់",
    "SOUND_STOPALLSOUNDS": "បញ្ឈប់សម្លេងទាំងអស់",
    "SOUND_SETEFFECTO": "ដាក់បែបផែន %1 ត្រឹម %2",
    "SOUND_CHANGEEFFECTBY": "ប្តូរបែបផែន %1 ចំនូន %2",
    "SOUND_CLEAREFFECTS": "លុបបែបផែនសម្លេង",
    "SOUND_EFFECTS_PITCH": "កម្រិតខ្ពស់ទាប",
    "SOUND_EFFECTS_PAN": "កម្រិតឆ្វេងស្តាំ",
    "SOUND_CHANGEVOLUMEBY": "ប្តូរកម្រិតសម្លេងចំនួន %1",
    "SOUND_SETVOLUMETO": "ដាក់កម្រិតសម្លេងត្រឹម %1%",
    "SOUND_VOLUME": "កម្រិតសម្លេង",
    "SOUND_RECORD": "ថត...",
    "CATEGORY_MOTION": "ចលនា",
    "CATEGORY_LOOKS": "រូបរាង",
    "CATEGORY_SOUND": "សម្លេង",
    "CATEGORY_EVENTS": "ព្រឹត្តិការណ៍",
    "CATEGORY_CONTROL": "គ្រប់គ្រង",
    "CATEGORY_SENSING": "ញ្ញាណ",
    "CATEGORY_OPERATORS": "ប្រមាណវិធី",
    "CATEGORY_VARIABLES": "អថេរ",
    "CATEGORY_MYBLOCKS": "ប្លុកខ្ញុំ",
    "CATEGORY_NUMBERS": "លេខ",
    "CATEGORY_TEXT": "អត្ថបទ",
    "DUPLICATE": "ស្ទួនគ្នា",
    "DELETE": "លុបចោល",
    "ADD_COMMENT": "បន្ថែមមតិយោបល់",
    "REMOVE_COMMENT": "ដកចេញមតិយោបល់",
    "DELETE_BLOCK": "លុបប្លុកចោល",
    "DELETE_X_BLOCKS": "លុបប្លុក %1",
    "DELETE_ALL_BLOCKS": "លុបប្លុកទាំងអស់ %1?",
    "CLEAN_UP": "សម្អាតប្លុកទាំងអស់ចេញ",
    "HELP": "ជំនួយ",
    "UNDO": "មិនធ្វើវិញ",
    "REDO": "ធ្វើឡើងវិញ",
    "EDIT_PROCEDURE": "កែសម្រួល",
    "SHOW_PROCEDURE_DEFINITION": "ទៅមើលនិយមន័យ",
    "WORKSPACE_COMMENT_DEFAULT_TEXT": "និយាយខ្លះទៅ...",
    "COLOUR_HUE_LABEL": "ពណ៌",
    "COLOUR_SATURATION_LABEL": "កម្រិតដិត",
    "COLOUR_BRIGHTNESS_LABEL": "កម្រិតពន្លឺ",
    "CHANGE_VALUE_TITLE": "ប្តូរតម្លៃ:",
    "RENAME_VARIABLE": "កែឈ្មោះអថេរ",
    "RENAME_VARIABLE_STRING": "កែឈ្មោះអថេរអក្សរ",
    "RENAME_VARIABLE_NUMBER": "កែឈ្មោះអថេរលេខ",
    "RENAME_VARIABLE_LIST": "កែឈ្មោះអថេរបញ្ជី",
    "RENAME_VARIABLE_TITLE": "កែឈ្មោះអថេរទាំងអស់ [%1] ទៅជា:",
    "RENAME_VARIABLE_MODAL_TITLE": "កែឈ្មោះអថេរ",
    "NEW_VARIABLE": "បង្កើតអថេរ",
    "NEW_VARIABLE_TITLE": "ឈ្មោះអថេរថ្មី:",
    "VARIABLE_MODAL_TITLE": "អថេរថ្មី",
    "VARIABLE_ALREADY_EXISTS": "អថេរឈ្មោះ [%1] មានរួចហើយ.",
    "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "អថេរឈ្មោះ [%1] មានរួចហើយសម្រាប់ប្រភេទអថេរផ្សេងប្រភេទ [%2].",
    "DELETE_VARIABLE_CONFIRMATION": "លុបចោល %1 ពីការប្រើប្រាស់អថេរ [%2] ?",
    "CANNOT_DELETE_VARIABLE_PROCEDURE": "មិនអាចលុបអថេរ [%1] ដោយហេតុថា អថេរនេះជាផ្នែកមួយដែលកំនត់ន័យអនុគមន៍ [%2]",
    "DELETE_VARIABLE": "លុបអថេរ「%1」",
    "DELETE_VARIABLE_STRING": "លុបអថេរអក្សរ「%1」",
    "DELETE_VARIABLE_NUMBER": "លុបអថេរលេខ「%1」",
    "DELETE_VARIABLE_LIST": "លុបអថេរបញ្ជី「%1」",
    "NEW_PROCEDURE": "បង្កើតប្លុក",
    "PROCEDURE_ALREADY_EXISTS": "ឈ្មោះនិតិវិធី [%1] មានរួចហើយ.",
    "PROCEDURE_DEFAULT_NAME": "ឈ្មោះប្លុក",
    "NEW_LIST": "បង្កើតបញ្ជី",
    "NEW_LIST_TITLE": "ឈ្មោះបញ្ជីថ្មី:",
    "LIST_MODAL_TITLE": "បញ្ជីថ្មី",
    "LIST_ALREADY_EXISTS": "ឈ្មោះបញ្ជី [%1] មានរួចហើយ.",
    "RENAME_LIST_TITLE": "កែឈ្មោះបញ្ជីទាំងអស់ [%1] ទៅជា:",
    "RENAME_LIST_MODAL_TITLE": "កែឈ្មោះបញ្ជី",
    "DEFAULT_LIST_ITEM": "រឿងរ៉ាវ",
    "DELETE_LIST": "លុបបញ្ជី [%1]",
    "RENAME_LIST": "កែឈ្មោះបញ្ជី",
    "NEW_BROADCAST_MESSAGE": "សារថ្មី",
    "NEW_BROADCAST_MESSAGE_TITLE": "ឈ្មោះសារថ្មី:",
    "BROADCAST_MODAL_TITLE": "សារថ្មី",
    "DEFAULT_BROADCAST_MESSAGE_NAME": "សារ 1",
    "DELETE_PROCEDURE": "ដើម្បីលុប កំណត់ន័យប្លុក,​ ជាដំបូងត្រូវលុបប្លុកដែលបានប្រើរួចឱ្យអស់ជាមុនសិន",
    "OK": "អូខេ",
    "CANCEL": "បោះបង់",
    "PROMPT": "ដាស់តឿន",
    "STOP": "បញ្ឈប់",
    "NEW_STRING": "បង្កើតអថេរអក្សរ",
    "NEW_NUMBER_VARIABLE": "បង្កើតអថេរលេខ",
    "WIFI": "Wi-Fi",
    "USERNAME": "អ្នកប្រើប្រាស់",
    "PWD": "ពាក្យសម្ងាត់",
    "IOT_SERVICE": "សេវា IOT",
    "IOT_ID": "លេខសម្គាល់ iot",
    "IOT_PWD": "លេខសម្ងាត់ iot",
    "IOT_TOPIC": "ប្រធានបទ_0",
    "MORE_TOPIC": "ប្រធានបទ",
    "OBLOQ_HTTP": "HTTP",
    "OBLOQ_IP": "អាសយដ្ឋាន IP",
    "OBLOQ_PORT": "ច្រក",
    "ADD_TOPIC": "បន្ថែមប្រធានបទ, អាចបន្ថែមដល់ត្រឹម 4",
    "DELETE_TOPIC": "លុបប្រធានបទ",
    "IOT_SERVER": "ម៉ាស៊ីនមេ",
    "CHINA": "ចិន",
    "GLOBAL": "សកល",
    "LOW_C": "តុងទាប C/C3",
    "LOW_C$": "តុងទាប C#/C#3",
    "LOW_D": "តុងទាប D/D3",
    "LOW_D$": "តុងទាប D#/D#3",
    "LOW_E": "តុងទាប E/E3",
    "LOW_F": "តុងទាប F/F3",
    "LOW_F$": "តុងទាប F#/F#3",
    "LOW_G": "តុងទាប G/G3",
    "LOW_G$": "តុងទាប G#/G#3",
    "LOW_A": "តុងទាប A/A3",
    "LOW_A$": "តុងទាប A#/A#3",
    "LOW_B": "តុងទាប B/B3",
    "MIDDLE_C": "តុងកណ្តាល C/C4",
    "MIDDLE_C$": "តុងកណ្តាល C#/C#4",
    "MIDDLE_D": "តុងកណ្តាល D/D4",
    "MIDDLE_D$": "តុងកណ្តាល D#/D#4",
    "MIDDLE_E": "តុងកណ្តាល E/E4",
    "MIDDLE_F": "តុងកណ្តាល F/F4",
    "MIDDLE_F$": "តុងកណ្តាល F#/F#4",
    "MIDDLE_G": "តុងកណ្តាល G/G4",
    "MIDDLE_G$": "តុងកណ្តាល G#/G#4",
    "MIDDLE_A": "តុងកណ្តាល A/A4",
    "MIDDLE_A$": "តុងកណ្តាល A#/A#4",
    "MIDDLE_B": "តុងកណ្តាល B/B4",
    "HIGH_C": "តុងខ្ពស់ C/C5",
    "HIGH_C$": "តុងខ្ពស់ C#/C#5",
    "HIGH_D": "តុងខ្ពស់ D/D5",
    "HIGH_D$": "តុងខ្ពស់ D#/D#5",
    "HIGH_E": "តុងខ្ពស់ E/E5",
    "HIGH_F": "តុងខ្ពស់ F/F5",
    "HIGH_F$": "តុងខ្ពស់ F#/F#5",
    "HIGH_G": "តុងខ្ពស់ G/G5",
    "HIGH_G$": "តុងខ្ពស់ G#/G#5",
    "HIGH_A": "តុងខ្ពស់ A/A5",
    "HIGH_A$": "តុងខ្ពស់ A#/A#5",
    "HIGH_B": "តុងខ្ពស់ B/B5",
    "OPERATORS_STRINGTONUMBER": "បំលែងអក្សរ %1 ទៅជា %2",
    "INTEGER": "លេខគត់",
    "DECIMAL": "លេខទសភាគ",
    "OPERATORS_NUMTOASCII": "បំលែងលេខ %1 ជាព្យញ្ជនៈ ASCII",
    "OPERATORS_STRINGTOASCII": "បំលែងអក្សរ %1 ជាព្យញ្ជនៈ ASCII",
    "OPERATORS_NUMTOSTRING": "បំលែងលេខ %1 ជាអក្សរ",
    "OPERATORS_MAP": "ផ្គូផ្គងលេខ %1 នៅចន្លោះពី [ %2 , %3 ] ឱ្យទៅក្នុងចន្លោះពី [ %4 , %5 ]",
    "OPERATORS_CONSTRAIN": "កំហិតលេខ %1 ឱ្យនៅចន្លោះ(តូចបំផុត) %2 និង(ធំបំផុត) %3",
    "STEPS_PER_TURN": "ជំហានក្នុងមួយជុំ",
    "ROTATE_SPEED": "ល្បឿនបង្វិល (ជុំ/វិនាទី)",
    "NEW_AI": "បង្កើតប្លុក AI",
    "ASK_AND_PAINT": "សូមគូសលេខ",
    "GET_IDENTIFIED_NUM": "ការទទួលស្គាល់លេខ",
    "READ_NUM_AND_SAY": "និយាយពីលេខដែលបានទទួលស្គាល់",
    "IMAGE_ADDR": "អាសយដ្ឋាន រូបភាព",
    "IMAGE_PREVIEW": "រូបភាពមើលមុន",
    "IMAGE_OPEN": "បើក",
    "IMAGE_SIZE": "ទំហំរូបភាព",
    "IMAGE_WIDTH": "ទទឹង",
    "IMAGE_HEIGHT": "កំពស់",
    "VARIABLE": "%1",
    "VARIABLE_LIST": "%1",
    "SET_STRING_VARIABLE": "ដាក់ %1 ទៅជា %2",
    "STRING_START_WITH": "%1 ចាប់ផ្តើមជាមួយ %2",
    "OPERATORS_RANDOMA": "%1 %2 យកតម្លៃចៃដន្យពី %3 ទៅ %4 %5",
    "OPERATORS_MODA": "%1 %2 សំណល់នៃ %3 ចែកនឹង %4",
    "OPERATORS_ROUNDA": "%1 %2 បង្គត់ %3",
    "OPERATORS_MATHOPA": "%1 %2 %3 %4",
    "OPERATORS_MATHOP_ABSA": "តម្លៃដាច់ខាត",
    "OPERATORS_MATHOP_FLOORA": "បង្គត់ឡើង",
    "OPERATORS_MATHOP_CEILINGA": "បង្គត់ចុះ",
    "OPERATORS_MATHOP_SQRTA": "ឫសការ៉េ",
    "OPERATORS_MATHOP_FIVE": "%1 %2 ប្តូរ %3 ចំនួន %4",
    "OPERATORS_MATHOP_SIX": "%1 %2 ផ្គូផ្គងលេខ %3 នៅចន្លោះពី [ %4 , %5 ] ឱ្យទៅក្នុងចន្លោះពី [ %6 , %7 ]",
    "OPERATORS_MATHOP_SEVEN": "%1 %2 កំហិតលេខ %3 ឱ្យនៅចន្លោះ(តូចបំផុត) %4 និង(ធំបំផុត) %5",
    "OPERATORS_NUM_INPUT": "%1 %2 number %3",
    "OPERATORS_NUMS_CACULATE": "%1 %2 %3",
    "OPERATORS_MATHOP_SQUARE": "**",
    "OPERATORS_MATHOP_LOGIC_OR": "|",
    "OPERATORS_MATHOP_LOGIC_AND": "&",
    "OPERATORS_MATHOP_LOGIC_POWER": "^",
    "OPERATORS_MATHOP_LOGIC_LEFT": "<<",
    "OPERATORS_MATHOP_LOGIC_RIGHT": ">>",
    "OPERATORS_SPECIAL_NUM": "%1 %2 %3",
    "OPERATORS_MATHOP_PI": "π",
    "OPERATORS_MATHOP_LOGARITHM": "e",
    "OPERATORS_MATHOP_FAI": "φ",
    "OPERATORS_MATHOP_SQRT2": "sqrt(2)",
    "OPERATORS_MATHOP_SQRTHALF": "sqrt(½)",
    "OPERATORS_MATHOP_UNLIMITED": "∞",
    "OPERATORS_NUMS_TYPE": "%1 %2 %3 %4?",
    "OPERATORS_MATHOP_ISEVEN": "is even",
    "OPERATORS_MATHOP_ISODD": "is odd",
    "OPERATORS_MATHOP_ISINT": "is int",
    "OPERATORS_MATHOP_ISPOSITIVE": "is positive",
    "OPERATORS_MATHOP_ISNEGATIVE": "is negative",
    "OPERATORS_SAVE2DECIMAL": "%1 %2 %3 save %4 decimal places",
    "OPERATORS_LIST_CACULATE": "%1 %2 %3 %4",
    "OPERATORS_MATHOP_LIST_SUM": "sum of the numbers in the list",
    "OPERATORS_MATHOP_LIST_MIN": "minimum value in list",
    "OPERATORS_MATHOP_LIST_MAX": "maximum value in the list",
    "OPERATORS_MATHOP_LIST_AVERAGE": "average in list",
    "OPERATORS_MATHOP_LIST_NUM": "number of digits in the list",
    "OPERATORS_MATHOP_LIST_MODE": "list mode",
    "OPERATORS_MATHOP_LIST_STDDEVIATION": "standard deviation in the list",
    "OPERATORS_MATHOP_LIST_RANDOM": "random item of list",
    "OPERATORS_QUOTIENT_INT": "%1 %2 %3 ÷ %4 integer part of quotient",
    "OPERATORS_RANDOM_DECIMAL": "%1 %2 random decimal",
    "OPERATORS_BIN_NEGATION": "%1 %2 binary negation %3",
    "OPERATORS_INT_TO_STR": "%1 %2 decimal integer %3 transform into %4 string",
    "OPERATORS_MATHOP_INTTOSTR_BIN": "binary",
    "OPERATORS_MATHOP_INTTOSTR_OCT": "octal",
    "OPERATORS_MATHOP_INTTOSTR_HEX": "hex",
    "OPERATORS_BINSTR_TO_INT": "%1 %2 %3 string %4 transform into decimal integer",
    "OPERATORS_INT_TO_BYTE": "%1 %2 decimal integer %3 transform into %4 byte",
    "OPERATORS_MATHOP_INTTOBYTE_TWO": "2",
    "OPERATORS_MATHOP_INTTOBYTE_FOUR": "4",
    "OPERATORS_INT_TO_ASCII": "%1 %2 int %3 transform into ASCII character",
    "OPERATORS_ASCII_TO_INT": "%1 %2 ASCII character %3 to int",
    "OPERATORS_STR_TO_INT": "%1 %2 string %3 to hex string",
    "OPERATORS_HEXSTR_TO_BIN": "%1 %2 hex string %3 to bin byte",
    "OPERATORS_STR_TO_BASE64": "%1 %2 %3 encodes in base64 format returns a byte object",
    "OPERATORS_BASE64_TO_BYTE": "%1 %2 decode base64 data %3 returns a byte object",
    "OPERATORS_MATHOP_LENGHT": "ប្រវែង %1",
    "OPERATORS_MATHOP_MAXMIN": "%1 %2",
    "OPERATORS_MATHOP_MAXMIN_MAX": "អតិបរិមា",
    "OPERATORS_MATHOP_MAXMIN_MIN": "អប្បបរិមា",
    "OPERATORS_TEXTS_ONE": "%1 %2 ភ្ជាប់ %3 %4",
    "OPERATORS_TEXTS_TWO": "%1 %2 តួអក្សរទី %4 នៃ %3",
    "OPERATORS_TEXTS_THREE": "%1 %2 %3 មាន %4 ?",
    "OPERATORS_TEXTS_FOUR": "%1 %2 តួអក្សរពី %5 %4 ដល់ %7 %6 នៃ %3",
    "OPERATORS_TEXTS_FOUR_NEW": "%1 ទាញយក ព្យញ្ជនៈទី %2 %3 ដល់ ព្យញ្ជនៈទី %4 %5",
    "OPERATORS_TEXTS_NEW": "%1 %2 ស្វែងរក %3 នៅក្នុង %4  %5 ទីតាំងរកឃើញ",
    "OPERATORS_TEXTS_NEW_OP": "ស្វែងរកកន្លែង ដែល %1 ឃើញមានក្នុង %2 %3",
    "OPERATORS_TEXTS_FOUR_ONE": "ពីឆ្វេង",
    "OPERATORS_TEXTS_FOUR_TWO": "ពីស្តាំ",
    "OPERATORS_TEXTS_FOUR_THREE": "ពីឆ្វេង",
    "OPERATORS_TEXTS_FOUR_FOUR": "ពីស្តាំ",
    "OPERATORS_TEXTS_FOUR_F": "ពីដើមទី",
    "OPERATORS_TEXTS_FOUR_L": "ពីចុងបំផុត",
    "OPERATORS_INPUTSTRING": "%1 %2 string %3",
    "OPERATORS_TRANSINTOTEXT": "%1 %2 transform into %3",
    "OPERATORS_TRANSINTOTEXT_ADD": "add",
    "OPERATORS_TRANSINTOTEXT_ADD_ITEM": "item",
    "OPERATORS_FORMATSTRING2F": "%1 %2 format string %3 % %4",
    "OPERATORS_FORMATSTRING3": "%1 %2 format string %3 format %4",
    "OPERATORS_TEXT_ESCAPE": "%1 %2 text escape character %3",
    "BAR_N": "\\n",
    "BAR_NN": "\\n\\r",
    "BAR_R": "\\r",
    "BAR_RN": "\\r\\n",
    "BAR_B": "\\b",
    "BAR_T": "\\t",
    "BAR_BAR": "\\\\",
    "OPERATORS_TEXT_NUMORLETTER": "%1 %2 %3 %4?",
    "OPERATORS_TEXT_NUMORLETTER_NUM": "is number",
    "OPERATORS_TEXT_NUMORLETTER_LETTER": "is letter",
    "OPERATORS_TEXT_LENGTH": "%1 %2 %3 length",
    "OPERATORS_TEXT_ISNONE": "%1 %2 %3 is none?",
    "OPERATORS_FROMTEXT_GETCHAR": "%1 %2 in text %3 %4",
    "OPERATORS_FROMTEXT_GETCHAR_FIRST": "first letter",
    "OPERATORS_FROMTEXT_GETCHAR_LAST": "last letter",
    "OPERATORS_FROMTEXT_GETCHAR_RANDOM": "random letter",
    "OPERATORS_TEXT_UPPLOW": "%1 %2 %3 %4",
    "OPERATORS_TEXT_UPPLOW_UPP": "uppercase",
    "OPERATORS_TEXT_UPPLOW_LOW": "lowercase",
    "OPERATORS_TEXT_SIDES_SPACE": "%1 %2 %3 %4",
    "OPERATORS_TEXT_SIDES_SPACE_BOTH": "clear spaces on both sides",
    "OPERATORS_TEXT_SIDES_SPACE_LEFT": "clear left space",
    "OPERATORS_TEXT_SIDES_SPACE_RIGHT": "clear right space",
    "OPERATORS_TEXT_STRTOBYTE": "%1 %2 turn byte %3",
    "OPERATORS_TEXT_BYTETOSTR": "%1 %2 byte %3 convert to string",
    "OPERATORS_TEXT_DICTTOJSONSTR": "%1 %2 dict %3 transform into json string",
    "OPERATORS_TEXT_PARSEJSONSTR": "%1 %2 parse json string %3 and return object",
    "OPERATORS_STRINGTONUMBERA": "%1 %2 បំលែងអក្សរ %3 ជា %4",
    "OPERATORS_NUMTOASCIIA": "%1 %2 បំលែងលេខ %3 ជាព្យញ្ជនៈ ASCII",
    "OPERATORS_STRINGTOASCIIA": "%1 %2 បំលែងព្យញ្ជនៈ %3 ជាតម្លៃព្យញ្ជនៈ ASCII",
    "OPERATORS_NUMTOSTRINGA": "%1 %2 បំលែងលេខ %3 ជាអក្សរ",
    "NEW_MC": "បង្កើតអថេរសម្រាប់ micropython",
    "RENAME_MCNUMBER_TITLE": "កែឈ្មោះអថេរទាំងអស់ ដែល ឈ្មោះនោះមាន [%1] ទៅជា:",
    "RENAME_MCNUMBER_MODAL_TITLE": "កែឈ្មោះអថេរ",
    "RENAME_VARIABLE_MCNUMBER": "កែឈ្មោះអថេរ",
    "DELETE_VARIABLE_MCNUMBER": "លុបអថេរ [%1]",
    "SET_MC_VARIABLE": "ដាក់ %1 ទៅជា %2",
    "ALIYUN": "Aliyun",
    "ONENET": "OneNet",
    "ONENETNEW": "OneNet(New)",
    "EASYIOT": "Easy IoT",
    "SHANGHAI": "ទីក្រុងសៀងហៃ (Shanghai)",
    "QINGDAO": "ទីក្រុង ឈីងតាវ (Qingdao)",
    "BEIJING": "ទីក្រុង ប៉េកាំង (Beijing)",
    "ZHANGJIAKOU": "ទីក្រុង ចាងចៀក (Zhangjiakou)",
    "HUHEHAOTE": "Huhehaote",
    "HANGZHOU": "ទីក្រុង ហាំងចូវ (Hangzhou)",
    "SHENZHEN": "ទីក្រុងសិនជិន (Shenzhen)",
    "HONGKONG": "ទីក្រុងហុងកុង (HongKong)",
    "SINGAPORE": "ប្រទេសសិង្ហបុរី (Singapore)",
    "SYDNEY": "ទីក្រុងស៊ីដនី (Sydney)",
    "KUALALUMPUR": "ទីក្រុងគូឡាឡាំពួ (Kuala Lumpur)",
    "JAKARTA": "ទីក្រុងចាការតា (Jakarta)",
    "MUMBAI": "ទីក្រុងបុមបៃ (Mumbai)",
    "TOKYO": "ទីក្រុងតូក្យូ (Tokyo)",
    "SILICONVALLEY": "Silicon Valley",
    "VIRGINIA": "ទីក្រុងវឺជីនៀ (Virginia)",
    "FRANKFURT": "ទីក្រុងហ្វ្រែងហ្វើត (Frankfurt)",
    "LONDON": "ទីក្រុងឡុង (London)",
    "DUBAI": "ទីក្រុងឌូបៃ (Dubai)",
    "IOT_PLATFORM": "ទម្រប្រព័ន្ធ IoT",
    "PARAMS": "ប៉ារ៉ាម៉ែត្រ",
    "SERVER_ATTR": "អាសយដ្ឋានម៉ាស៊ីនមេ",
    "PRODUCTID": "លេខសម្គាល់ផលិតផល",
    "DEVICEID": "លេខសម្គាល់ឧបករណ៍",
    "DEVICENAME": "DeviceName",
    "TINYDB_SECRET": "លេខសម្ងាត់កូនបន្ទាយទិន្នន័យ",
    "TINYDB_USER": "អ្នកប្រើប្រាស់កូនបន្ទាយទិន្នន័យ",
    "APIADDR": "អាសយដ្ឋាន API",
    "SOFTWARE_SERIAL": "ច្រកសេរ៊ីប្រើកម្មវិធី",
    "HARDWARE_SERIAL": "ច្រកសេរ៊ីប្រើឧបករណ៍",
    "HARDWARE_SERIAL1": "ច្រកសេរ៊ីប្រើឧបករណ៍ 1",
    "HARDWARE_SERIAL2": "ច្រកសេរ៊ីប្រើឧបករណ៍ 2",
    "HARDWARE_SERIAL3": "ច្រកសេរ៊ីប្រើឧបករណ៍ 3",
    "CHECKTYPE_TIPS": "ប្រភេទទិន្នន័យដែលទទួលបានពី ប្រអប់បញ្ចូល ខុសពីប្រភេទទីន្នន័យដែលបានកំណត់ពេលបង្កើតប្លុក",
    "HIGHLIGHT_BLOCK": "រំលេចប្លុក",
    "HIGHLIGHT_ALL_CONFLICT_BLOCKS": "រំលេចសញ្ញាព្រមានទៅគ្រប់ប្លុក",
    "SNAPSHOT": "ថតរូប (CTRL+G)",
    "pictureaiPath": "សូមបញ្ចូលកន្លែងទុករូបភាព ឬ URL",
    "pictureaiType": "ប្រភេទគណនី",
    "pictureaiAccountDefault": "គណនីលំនាំដើម",
    "pictureaiAccountBaidu": "គណនី Baidu",
    "pictureaiwebImgAddr": "អាសយដ្ឋានរូបភាពនៃគេហទំព័រ",
    "MITRIXICON_EDIT": "បន្ថែមពន្លឺ",
    "MITRIXICON_ERASER": "ជ័រលុប",
    "MITRIXICON_DUSTBIN": "លុប",
    "MITRIXICON_REVERSE": "បញ្ច្រាស់",
    "MITRIXICON_SAVE": "រក្សាទុក",
    "MITRIXICON_EXPORT": "នាំចេញ",
    "MITRIXICON_UPLOAD": "ផ្ញើរចេញ",
    "ROLL_MOUSE": "ម៉ៅស៍រមៀល",
    "MOUSE_ZOOM_IN_OUT": "អូសម៉ៅស៍កណ្តាលដើម្បីពង្រីកឬបង្រួម",
    "UPLOAD_CORRECT_JSON_FILE": "សូមផ្ញើរឯកសារប្រភេទ Json ដែលត្រឹមត្រូវតាមទម្រង់ដែលបានកំណត់",
    "EXPORT_PROMPT": "មិនមានលំនាំផ្ទាល់ខ្លួនណាមួយសម្រាប់នាំចេញទេ។ សូមចុចលើប៉ូតុង រក្សាទុក ដែលស្ថិតនៅ ជ្រុងខាងស្តាំ​-ក្រោម ដើម្បីរក្សាទុកលំនាំផ្ទាល់ខ្លួនមុននឹងធ្វើការនាំចេញ",
    "HEART": "Heart",
    "HEART_SMALL": "Small heart",
    "HAPPY": "Happy",
    "SMILE": "Smile",
    "SAD": "Sad",
    "CONFUSED": "Confused",
    "ANGRY": "Angry",
    "SLEEP": "Sleep",
    "SURPRISED": "Surprised",
    "SILLY": "Silly",
    "WONDERFUL": "Wonderful",
    "BORED": "Bored",
    "ROCK": "Rock",
    "ROCK_SMALL": "Small rock",
    "PAPER": "Paper",
    "PAPER_SMALL": "Small paper",
    "SCISSORS": "scissors",
    "SCISSORS_SMALL": "Small scissors",
    "DATA_SOLITLIST": "%1 %2 make %3 %4 with delimiter %5",
    "DATA_SOLITLIST_TYPE_LT": "list from text5",
    "DATA_SOLITLIST_TYPE_TL": "text from list",
    "DATA_LENLIST": "%1 %2 %3 is empty?",
    "DATA_LENGTHLIST": "%1 %2 length of %3",
    "DATA_CREATELIST": "%1 %2 create list %3",
    "DATA_CLEARLIST": "%1 %2 clear list %3"
  }
};
