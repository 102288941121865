import styles from "./LoginModal.module.scss"
import logo from 'asset/image/mindplus-logo.svg'
import CloseButton from "component/close-button/close-button"
import classNames from "classnames"
import { Button, Checkbox, ConfigProvider, Input, Popover } from "antd"
import { useState } from "react"
import { defineMessages, useIntl } from "react-intl"
import eyeClose from "./eye-close.svg";
import { IS_WEB_PLATFORM } from "config/config"
import { useLogin } from "service/user/useUser"

const messages = defineMessages({
    phoneNumber: {
        defaultMessage: '手机号/邮箱',
        description: 'Mobile Number',
        id: 'gui.account.dialog.phoneNumber'
    },
    password: {
        defaultMessage: 'Password',
        description: '',
        id: 'gui.account.dialog.password'
    },
    passwordEmpty: {
        defaultMessage: '密码不能为空',
        description: '',
        id: 'gui.account.dialog.passwordEmpty'
    }
})

interface Props {
    onClose: Function
}
interface ErrMsg {
    username?: string
    password?: string
}

export const LoginModal = (props: Props) => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [isSave, setIsSave] = useState(false);
    const [errMsg, setErrMsg] = useState<ErrMsg>({});
    const intl = useIntl()
    const login = useLogin();

    const handleUserInput = (e) => {
        e.preventDefault();
        setUsername(e.target.value)
    }
    const handlePasswordInput = (e) => {
        e.preventDefault();
        setPassword(e.target.value)
    }
    const onForgotPasswd = () => {
        // "https://auth.dfrobot.com.cn/resetPassword.html"
        if (IS_WEB_PLATFORM){
            window.open("https://auth.dfrobot.com.cn/resetPassword.html")
        } else {
            // todo: 离线版通过electron打开默认浏览器

        }

    }

    const handleAutoLogin = (e) => {
        setIsSave(e.target.checked);
    }

    const onSubmit = () => {
        const errMsg_: ErrMsg = {}
        // 输入校验
        if (!username) {
            errMsg_.username = "用户名不能为空"
        } else if (username.length < 3) {
            errMsg_.username = "用户名至少3个字符"
        } else if (username.length > 30) {
            errMsg_.username = "用户名最多30个字符"
        } else if (!/^[\w\-\_]+$/.exec(username)) {
            errMsg_.username = "用户名只能包含字母、数字、_ 和 -"
        }
        if (!password) {
            errMsg_.password = "密码不能为空"
        }
        if (Object.keys(errMsg_).length > 0) {
            setErrMsg(errMsg_)
        } else {
            // 登录
            login(username, password, isSave).then(() => {
                // 登录成功, 关闭弹窗
            })
        }

    }
    return (
        <ConfigProvider
            theme={{
                token: {
                    colorBgElevated: "#c40",
                },
            }}
        >
            <div className={styles.loginWrapper}>
                <div
                    className={styles.loginContent}
                >
                    <form autoComplete="off" className={styles.login}>
                        <img className={styles.logo} src={logo} />
                        <Popover open={!!errMsg.username} placement="right" content={<span className={styles.errMsg}>{errMsg.username}</span>}>
                            <div
                                className={styles.inputWrapper}
                            >
                                <Input
                                    id="username"
                                    name="username"
                                    type="text"
                                    autoCapitalize="off"
                                    autoCorrect="off"
                                    value={username}
                                    variant="borderless"
                                    maxLength={30}
                                    autoComplete="off"
                                    onChange={handleUserInput}
                                    placeholder={"手机号/邮箱"} 
                                />
                            </div>
                        </Popover>
                        <Popover open={!!errMsg.password} placement="right" content={<span className={styles.errMsg}>{errMsg.password}</span>}>
                            <Input.Password
                                id="passwd"
                                name="passwd"
                                type="text"
                                autoCapitalize="off"
                                autoCorrect="off"
                                autoComplete="off"
                                value={password}
                                maxLength={20}
                                variant="borderless"
                                onChange={handlePasswordInput}
                                onKeyUp={(e)=>{
                                    if (e.key === "Enter") onSubmit()
                                }}
                                className={styles.inputWrapper}
                                placeholder={"密码"}
                            />
                        </Popover>
                        <div className={styles.checkboxLabel}>
                            <Checkbox
                                id="saveStatus"
                                className={styles.autoLogin}
                                checked={isSave}
                                onChange={handleAutoLogin}
                                // onClick={props.onInputChange}
                            >
                                自动登录
                            </Checkbox>
                            <span
                                className={styles.foundPasswd}
                                onClick={onForgotPasswd}
                            >
                                忘记密码
                            </span>
                        </div>
                        <div className={styles.loginSubmit}
                        >
                            <Button
                                type="primary"
                                size="large"
                                // disabled={}
                                onClick={onSubmit}
                                // loading={props.loading}
                            >
                            登录
                            </Button>
                        </div>
                    </form>
                    <div className={styles.closeButton}>
                        <CloseButton
                            // size={CloseButton.SIZE_LARGE}
                            onClick={() => props.onClose()}
                        />    
                    </div>
                </div>
            </div>
        </ConfigProvider>
    )
}