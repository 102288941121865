import { useTags } from "./util/useTags";
import { useLibrariesData } from "./util/useLibrariesData";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useClose } from "./util/useClose";
import { useFilterData } from "./util/useFilterData";
import { Button, Input, Modal, Pagination, Tabs } from "antd";
import styles from "./ExtensionLibraries.module.scss";
import { SearchOutlined } from "@ant-design/icons";
import { ExtensionLibrariesItem } from "../extension-libraries/extension-libraries-item/ExtensionLibrariesItem";
import { useSelector } from "react-redux";
import { selectAssetLibStatus } from "redux/asset-lib/assetLibStatusSlice";
import BackIcon from "./icon--back.svg";

const COUNT_PER_PAGE = 3 * 6;

export const ExtensionLibraries = () => {
  const { extensionLibVisible } = useSelector(selectAssetLibStatus);
  const [libType, setLibType] = useState<"extension" | "device" | "module">(
    "extension"
  );
  const [searchText, setSearchText] = useState("");
  const isDevice = libType === "device";
  // tag数据
  const tags = useTags(libType);
  // list数据
  const librariesData = useLibrariesData(libType);
  const [currentPage, setCurrentPage] = useState(1);
  // 选择的tag
  const [selectedTag, setSelectedTag] = useState("all");
  const intl = useIntl();

  const onClose = useClose(extensionLibVisible);

  // 标签和搜索过滤的数据
  const filterData = useFilterData(selectedTag, searchText, librariesData);
  // 这一页的数据
  const offset = (currentPage - 1) * COUNT_PER_PAGE;
  // const dataOfCurrentPage = filterData.slice(offset, offset + COUNT_PER_PAGE);
  const dataOfCurrentPage = filterData;
  useEffect(() => {
    if (extensionLibVisible) setLibType("extension");
  }, [extensionLibVisible]);

  // 切换设备/扩展/模块库时，清空选择的标签
  useEffect(() => {
    setSelectedTag("all");
  }, [libType]);

  const onTagButtonClick = (key: string) => {
    setSelectedTag(key);
  };

  const handleSearchTextChange = (e) => {
    setSearchText(e.target.value);
  }

  return (
    <div className={styles.extensionLibraries}>
      <div className={styles.header}>
        <div onClick={onClose} className={styles.closeButton}>
          <img src={BackIcon} />
          <span>返回</span>
        </div>
        <div className={styles.tabs}>
          <div
            className={
              libType === "extension" ? styles.tabSelected : styles.tab
            }
            onClick={() => setLibType("extension")}
          >
            舞台
          </div>
          <div
            className={libType === "device" ? styles.tabSelected : styles.tab}
            onClick={() => setLibType("device")}
          >
            设备
          </div>
          <div
            className={libType === "module" ? styles.tabSelected : styles.tab}
            onClick={() => setLibType("module")}
          >
            小模块
          </div>
        </div>
        <div className={styles.search}>
          <Input
            className={styles.searchInput}
            prefix={<SearchOutlined />}
            placeholder={"搜索"}
            bordered={false}
            value={searchText}
            onChange={handleSearchTextChange}
          />
        </div>
      </div>
      <div className={styles.tags}>
        {tags.length > 0 && (
          <Button
            shape="round"
            type={selectedTag === "all" ? "primary" : "default"}
            onClick={() => onTagButtonClick("all")}
          >
            全部
          </Button>
        )}
        {tags.map((item) => (
          <Button
            shape="round"
            type={selectedTag === item.tag ? "primary" : "default"}
            onClick={() => onTagButtonClick(item.tag)}
          >
            {intl.formatMessage(item.intlLabel)}
          </Button>
        ))}
      </div>
      <div className={styles.content}>
        {filterData.map((item) => (
          <ExtensionLibrariesItem
            libType={libType}
            key={item[0].id}
            items={item}
          />
        ))}
      </div>
      {/* <div className={styles.footer}>
                <Pagination showSizeChanger={false} hideOnSinglePage current={currentPage} pageSize={COUNT_PER_PAGE}
                            onChange={(page) => setCurrentPage(page)} total={filterData.length}/>
            </div> */}
    </div>
  );
};
