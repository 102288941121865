import { COMMAND_WS_URL, TRANSMIT_WS_URL } from "config/config";

type LinkWsType = "Command" | "Serialport" | "child_process"| "UDP" | "MQTT"; // todo: BLE

class LinkWebSocket {
    _type: LinkWsType
    _onOpen?: ()=>void
    _onClose?: ()=>void
    _onError?: ()=>void
    _handleMessage?: (message: JSON)=>void
    _ws?: WebSocket
    constructor (type: LinkWsType) {
        this._type = type;
    }

    open () {
        switch (this._type) {
        case 'Command':
            this._ws = new WebSocket(COMMAND_WS_URL);
            break;
        case 'Serialport':
            this._ws = new WebSocket(TRANSMIT_WS_URL, "Serialport");
            break;
        case 'child_process':
            this._ws = new WebSocket(TRANSMIT_WS_URL, "child_process");
            break;
        case 'UDP':
            this._ws = new WebSocket(TRANSMIT_WS_URL, "UDP");
            break;
          case 'MQTT':
            this._ws = new WebSocket(TRANSMIT_WS_URL, "MQTT");
            break;
        default:
            throw new Error(`Unknown Link Type: ${this._type}`);
        }

        if (this._onOpen && this._onClose && this._onError && this._handleMessage) {
            this._ws.onopen = this._onOpen;
            this._ws.onclose = this._onClose;
            this._ws.onerror = this._onError;
        } else {
            throw new Error('Must set open, close, message and error handlers before calling open');
        }

        this._ws.onmessage = this._onMessage.bind(this);
    }

    close () {
        if (!this._ws) return;
        this._ws.close();
        this._ws = undefined;
    }

    sendMessage (message) {
        if (!this._ws) return;
        const messageText = JSON.stringify(message);
        this._ws.send(messageText);
    }

    setOnOpen (fn) {
        this._onOpen = fn;
    }

    setOnClose (fn) {
        this._onClose = fn;
    }

    setOnError (fn) {
        this._onError = fn;
    }

    setHandleMessage (fn) {
        this._handleMessage = fn;
    }

    isOpen () {
        return this._ws && this._ws.readyState === this._ws.OPEN;
    }

    _onMessage (e) {
        if (!this._handleMessage) return;
        const json = JSON.parse(e.data);
        this._handleMessage(json);
    }
}

export default LinkWebSocket;
