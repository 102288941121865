
import { Button } from "antd"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { closeLinkModal, openLinkDisconnect, openLinkError, selectLinkModal } from "redux/link-modal/linkModalSlice"
import { commandWs } from "service/link-adapter/websocket/commandWs"
import styles from "./LinkModal.module.scss"

export const LinkModal = () => {
    const {type, linkModal} = useSelector(selectLinkModal)
    const dispatch = useDispatch()

    // 如果ws已经连接, 返回null
    if (commandWs.isOpen()) {
        return null;
    }

    const cancel = () => {
        dispatch(closeLinkModal())
    }
    const connectLink = () => {
        commandWs.open();
        cancel();
    }

    const startLink = () => {
        cancel();
    }

    // 连接提示
    if (type === "connect") {
        return (
            <div className={styles.LinkModal}>
                <div className={styles.textWrapper}>Mind-Link未连接!</div>
                <div className={styles.ButtonWrapper}>
                    <Button shape="round" onClick={cancel}>取消</Button>
                    <Button shape="round" type="primary" onClick={connectLink}>连接</Button>
                </div>
            </div>
        )
    }

    // 启动提示
    if (type === "start") {
        return (
            <div className={styles.LinkModal}>
                <div className={styles.textWrapper}>Mind-Link未启动!</div>
                <div className={styles.ButtonWrapper}>
                    <Button shape="round">取消</Button>
                    <Button shape="round" type="primary" onClick={startLink}><a href={"mindplus://"}>启动Mind-Link</a></Button>
                </div>
            </div>
        )
    }

    // 断开连接提示
    if (type === "disconnect") {
        return (
            <div className={styles.LinkModal}>
                <div className={styles.textWrapper}>与Mind-Link断开连接!</div>
                <div className={styles.ButtonWrapper}>
                    <Button shape="round" onClick={cancel}>取消</Button>
                    <Button shape="round" type="primary" onClick={connectLink}>重新连接</Button>
                </div>
            </div>
        )
    }

    // 连接失败提示
    if (type === "error") {
        return (
            <div className={styles.LinkModal}>
                <div className={styles.textWrapper}>连接失败! 请确认Mind-Link是否启动.</div>
                <div className={styles.ButtonWrapper}>
                    <Button shape="round">取消</Button>
                    <Button shape="round" type="primary" onClick={connectLink}>重新连接</Button>
                    <Button shape="round" type="primary" onClick={startLink}><a href={"mindplus://"}>启动Mind-Link</a></Button>
                </div>
            </div>
        )
    }

    return null;
}