import { RightOutlined } from '@ant-design/icons';
import React, { useState, useRef, useEffect, ReactNode } from 'react';
import styles from './DFDropdown.module.scss';
import DownArrowIcon from "asset/image/down_arrow_icon.svg"

export interface DFDropdownItem {
  label: string;
  key: string | number;
  disabled?: boolean;
  icon?: ReactNode;
  children?: DFDropdownItem[]; // Support for sub-menu items
}

export interface DFDropdownProps {
  position?: "right" | "left"
  trigger?: 'click' | 'hover' | 'contextMenu';
  items: DFDropdownItem[];
  onItemClick?: (item: DFDropdownItem) => void;
  children?: ReactNode;
  width?: string
  showArrow?: boolean
}

const DFDropdown: React.FC<DFDropdownProps> = ({
  children,
  position = 'left',
  trigger = 'click',
  items,
  onItemClick,
  width,
  showArrow = true
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [activeSubMenus, setActiveSubMenus] = useState<string[]>([]);
  const [mouseEnterItem, setMouseEnterItem] = useState<boolean>(false);

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsOpen(false);
      setActiveSubMenus([]);
    }
  };

  const handleItemClick = (item: DFDropdownItem) => {
    if (onItemClick && !item.disabled) {
      onItemClick(item);
    }
    setIsOpen(false);
    setActiveSubMenus([]);
  };

  const handleCloseDropdown = () => {
  };

  const handleSubMenuEnter = (level: number, subMenuKey: string) => {
    setActiveSubMenus((prevActiveSubMenus) => {
      const updatedSubMenus = [...prevActiveSubMenus];
      updatedSubMenus[level] = subMenuKey;
      updatedSubMenus.length = level + 1 //将多余的展开收起
      return updatedSubMenus;
    });
  };

  useEffect(() => {
    if (trigger === 'click' || trigger === 'hover') {
      window.addEventListener('click', handleClickOutside);
    }
    return () => {
      if (trigger === 'click' || trigger === 'hover') {
        window.removeEventListener('click', handleClickOutside);
      }
    };
  }, [trigger]);

  const handleTriggerClick = () => {
    if (trigger === 'click') {
      setIsOpen(!isOpen);
      setActiveSubMenus([]);
    }
  };

  const handleTriggerHover = () => {
    if (trigger === 'hover') {
      setIsOpen(true);
    }
  };

  const handleTriggerContextMenu = (event: React.MouseEvent) => {
    event.preventDefault();
    if (trigger === 'contextMenu') {
      setIsOpen(true);
    }
  };

  const renderItems = (
    level: number,
    items: DFDropdownItem[] | undefined,
    parentKey: string,
    onItemClick: (item: DFDropdownItem) => void,
  ) => {
    if (!items) {
      return null;
    }
    const handleMouseEnter = (item: DFDropdownItem) => {
      if (trigger === 'hover') {
        if (item.children) {
          handleSubMenuEnter(level, parentKey + item.key);
        } else {
          //收起上次展开的
          handleSubMenuEnter(level, '');
        }
      }
    };

    const handleClick = (item: DFDropdownItem, e: React.MouseEvent) => {
      //阻止冒泡,避免触发clickout
      e.stopPropagation();
      if (!item.disabled) {
        if (trigger === 'click') {
          if (item.children) {
            handleSubMenuEnter(level, parentKey + item.key);
          } else {
            onItemClick(item);
          }
        } else {
          onItemClick(item);
        }
      }

    };

    const handleMouseLeaveUL = (e) => {
      e.stopPropagation();
      if (trigger === 'hover') {
        setMouseEnterItem(false);
        setIsOpen(false)
      }
    }
    const handleMouseEnterUL = (e) => {
      e.stopPropagation();
      if (trigger === 'hover') {
        setMouseEnterItem(true);
      }
    }

    return (
      <ul className={`${styles.items}  ${position === "right" ? styles.right : styles.left}`} onMouseLeave={handleMouseLeaveUL} onMouseEnter={handleMouseEnterUL}>
        {items.map((item) => (
          <li
            key={item.key}
            className={`${styles.item} ${item.children ? styles.hasChildren : ''} ${item.disabled ? styles.disabled : ''}`}
            onMouseEnter={() => handleMouseEnter(item)}
            onClick={(e) => {
              e.stopPropagation()
              handleClick(item, e)
            }}

          >
            {item.icon && <span className={styles.icon}>{item.icon}</span>}
            {item.label}
            {item.children && activeSubMenus[level] === parentKey + item.key && (
              <div className={styles.subMenu}>
                {renderItems(level + 1, item.children, parentKey + item.key, onItemClick)}
              </div>
            )}
          </li>
        ))}
      </ul>
    );
  };

  return (
    <>
      <div
        className={styles.dropdownWrapper}
        ref={dropdownRef}
        onClick={handleTriggerClick}
        onMouseEnter={handleTriggerHover}
        onMouseLeave={handleCloseDropdown}
        onContextMenu={handleTriggerContextMenu}
        style={{ width: width }}
      >
        {children}
        {showArrow && <img src={DownArrowIcon} className={styles.arrow} alt=''  />}
      </div>
      {isOpen && renderItems(0, items, '', handleItemClick)
      }
    </>
  );
};

export default DFDropdown;
