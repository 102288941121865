import ScratchBlocks from 'scratch-blocks';
import {vm} from 'lib/scratch-vm'
import VMScratchBlocks from './blocks';

export default ScratchBlocks;

export const CustomScratchBlocks = VMScratchBlocks(vm);





