// 标签和搜索过滤的数据
export const useFilterData = (selectedTag, searchText,data) => {
  let filterData;
  if (selectedTag === "all") {
    filterData = data;
  } else {
    filterData = data.filter((item) => {
      if (item.find((i) => i.feature && i.feature.includes(selectedTag)))
        return true;
    });
  }
  // todo: 搜索
  if (searchText) {
    filterData = filterData.filter((item) => {
      return item.find((i) => (i.name["zh-cn"] && i.name["zh-cn"].includes(searchText)) || (i.name["en"] && i.name["en"].includes(searchText)));
    });
  }
  
  // 排序 放置已加载->支持设备->不支持设备
  const loaded = filterData.filter((item) => item.find((i) => i.isLoaded));
  const support = filterData.filter((item) =>
    item.find((i) => i.isSupportCurrentDevice && !i.isLoaded)
  );
  const notSupport = filterData.filter(
    (item) => item.find((i) => !i.isSupportCurrentDevice && !i.isLoaded)
  );
  return loaded.concat(support).concat(notSupport)
};
