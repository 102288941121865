import React from 'react';
import styles from './MenuBar.module.scss'
import { Project } from "./project/Project";
import logo from 'asset/image/mindplus-logo.svg'
import { Editing } from "./editing/Eidting";
import { Lesson } from "./lesson/Lesson";
import { SelectSerial } from "./select-serial/SelectSerial";
import { FeedBack } from "./feedback/FeedBack";
import { Settings } from "./settings/Settings";
import { ProjectTitle } from "./project-title/ProjectTitle";
import { Account } from './account/Account';
import SaveStatus from './save-status/save-status';
import { useSelector } from 'react-redux';
import { selectProject } from 'redux/project/projectSlice';
import { useSaveProject } from 'service/project/useProject';
import Divider from 'component/divider/divider';

const MenuBar = () => {
  const { saveStatus, projectChanged, canSave } = useSelector(selectProject)
  const saveProject = useSaveProject();
  const onSaveProject = () => {
    saveProject();
  }
  return (
    <div className={styles.container}>
      <div className={styles.left}>
        <div className={styles.title}>
          <img src={logo} />
        </div>
        <div className={styles.menuItem}><Project /></div>
        <div className={styles.menuItem}><Lesson /></div>
        <div className={styles.menuItem}><Editing /></div>
        <Divider className={styles.divider} />
        <SelectSerial />
        <Divider className={styles.divider} />
        <ProjectTitle />
      </div>
      <div className={styles.right}>
        {canSave && <SaveStatus
          saveStatus
          projectChanged
          onClickSave={onSaveProject}
        />}
        {/* <FeedBack /> */}
        <Account />
        <Settings />
      </div>
    </div>
  );
};

export default MenuBar;
