import {CloseOutlined} from '@ant-design/icons';
import React, {useState, useEffect, useMemo, useRef, CSSProperties} from 'react';
import ReactDOM from 'react-dom';
import styles from './DFModal.module.scss';
import DFButton from "../df-button/DFButton";

interface DFModalProps {
    open: boolean;
    onClose: () => void;
    centered?: boolean;
    cancelText?: string;
    okText?: string;
    title?: string | null;
    width?: number | string;
    maskClosable?: boolean
    closable?: boolean
    keyboard?: boolean;
    onCancel?: () => void;
    onOk?: () => void;
    children?: React.ReactNode;
    footer?: React.ReactNode;
    header?: React.ReactNode;
    closeIcon?: React.ReactNode;
    contentStyle?:CSSProperties
}

const DFModal: React.FC<DFModalProps> = ({
                                             open,
                                             onClose,
                                             centered = false,
                                             closeIcon,
                                             cancelText = '取消',
                                             okText = '确定',
                                             title,
                                             children,
                                             footer,
                                             header,
                                             keyboard = true,
                                             onCancel,
                                             onOk,
                                             width,
                                             maskClosable = false,
                                             closable = true,
                                              contentStyle
                                         }) => {
    const [modalVisible, setModalVisible] = useState(open);
    const modalContentRef = useRef<HTMLDivElement | null>(null)
    useEffect(() => {
        setModalVisible(open);
    }, [open]);

    const closeModal = () => {
        setModalVisible(false);
        onClose();
    };
    const handleMaskClick = (event) => {
        if (maskClosable) {
            if (modalContentRef.current && !modalContentRef.current.contains(event.target as Node)) {
                setModalVisible(false);
                onClose()
            }
        }
    }

    const handleKeyDown = (event: KeyboardEvent) => {
        if (keyboard && event.key === 'Escape') {
            closeModal();
        }
    };

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [keyboard]);

    const contentWidth = useMemo(() => {
        if (width) {
            if (typeof width !== 'number') {
                return width
            } else {
                return width + 'px'
            }
        }
        return '416px'
    }, [width])
    const modalContent = (
        <div className={`${styles.modal} `}>
            <div className={styles.mask}></div>
            <div className={`${styles.modalWrapper} ${centered ? styles.centered : ''}`} onClick={handleMaskClick}>
                <div className={styles.modalContent} style={{width: contentWidth,...contentStyle}} ref={modalContentRef}>
                    {
                        header === undefined && <>
                            {title && <div className={styles.title}>{title}</div>}
                        </>
                    }
                    {closable && (
                        <button className={styles.closeButton} onClick={closeModal}>
                            {closeIcon ? closeIcon : <CloseOutlined/>}
                        </button>
                    )}
                    {header}
                    <div className={styles.content}>{children}</div>
                    {footer === undefined && (
                        <div className={styles.footer}>
                            <DFButton shape={"square"} style={{marginRight: '12px'}} onClick={onCancel || closeModal}>
                                {cancelText}
                            </DFButton>
                            <DFButton shape={"square"} type={"primary"} onClick={onOk || closeModal}>
                                {okText}
                            </DFButton>
                        </div>
                    )}
                    {footer}
                </div>
            </div>
        </div>
    );

    return modalVisible
        ? ReactDOM.createPortal(modalContent, document.body)
        : null;
};

export default DFModal;
