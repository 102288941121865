import { FC, useEffect, useRef, useState } from "react";
import ScratchBlocks from "lib/scratch-blocks";
import { Modal } from "component/modal/index";
import Box from "component/box/box";
import styles from "./FunctionPrompt.module.scss";
import booleanInputIcon from "./icon--boolean-input.svg";
import textInputIcon from "./icon--text-input.svg";
import labelIcon from "./icon--label.svg";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";

interface FunctionModalProps {
  onPromptClosed: () => void;
  isRtl?: boolean;
}

const FunctionPrompt: FC<FunctionModalProps> = ({ onPromptClosed, isRtl }) => {
  const intl = useIntl();
  const [isOpen, setIsOpen] = useState(false);
  const [wrap, setWrap] = useState(false);
  const workspaceRef = useRef<any | null>(null);
  const mutatorRef = useRef<Element | null>();
  const mutationRootRef = useRef<any>();
  const callbackRef = useRef<Function | null>();
  const rtlOffsetRef = useRef(0);

  useEffect(() => {
    // 绑定创建函数时的回调
    ScratchBlocks.Procedures.externalProcedureDefCallback = (
      data: Element,
      callback: any
    ) => {
      mutatorRef.current = data;
      callbackRef.current = callback;
      setIsOpen(true);
    };

    return () => {
      ScratchBlocks.Procedures.externalProcedureDefCallback = null;
      workspaceRef.current?.dispose();
    };
  }, []);

  const onOpen = (blocks: HTMLDivElement) => {
    if (!blocks) return;
    // inject时toolbox参数为空就会使用默认toolbox, 所以先清除默认toolbox
    const old = ScratchBlocks.Blocks.defaultToolbox;
    ScratchBlocks.Blocks.defaultToolbox = null;
    // 创建自定义block的工作区
    workspaceRef.current = ScratchBlocks.inject("custome-procedure", {
      // toolbox: '',
      comments: false,
      scrollbars: true,
      media: "/static/blocks-media/",
      zoom: {
        controls: false,
        wheel: false,
        startScale: 0.9,
      },
    });
    ScratchBlocks.Blocks.defaultToolbox = old;

    // 在工作区内添加一个声明block
    mutationRootRef.current = workspaceRef.current.newBlock(
      "procedures_declaration"
    );
    // 不可移动
    mutationRootRef.current.setMovable(false);
    // 不可删除
    mutationRootRef.current.setDeletable(false);
    // 没有右键菜单
    mutationRootRef.current.contextMenu = false;
    // 设置mutate数据
    if (mutationRootRef.current.domToMutation)
      mutationRootRef.current.domToMutation(mutatorRef.current!);
    // 显示
    mutationRootRef.current.initSvg();
    mutationRootRef.current.render();

    workspaceRef.current.addChangeListener(() => {
      mutationRootRef.current.onChangeFn();
      // Keep the block centered on the workspace
      const metrics = workspaceRef.current.getMetrics();
      const { x, y } = mutationRootRef.current.getRelativeToSurfaceXY();
      const dy =
        metrics.viewHeight / 2 - mutationRootRef.current.height / 2 - y;
      let dx;
      if (isRtl) {
        // // TODO: https://github.com/LLK/scratch-gui/issues/2838
        // This is temporary until we can figure out what's going on width
        // block positioning on the workspace for RTL.
        // Workspace is always origin top-left, with x increasing to the right
        // Calculate initial starting offset and save it, every other move
        // has to take the original offset into account.
        // Calculate a new left postion based on new width
        // Convert current x position into LTR (mirror) x position (uses original offset)
        // Use the difference between ltrX and mirrorX as the amount to move
        const ltrX =
          metrics.viewWidth / 2 - mutationRootRef.current.width / 2 + 25;
        const mirrorX = x - (x - rtlOffsetRef.current) * 2;
        if (mirrorX === ltrX) {
          return;
        }
        dx = mirrorX - ltrX;
        const midPoint = metrics.viewWidth / 2;
        if (x === 0) {
          // if it's the first time positioning, it should always move right
          if (mutationRootRef.current.width < midPoint) {
            dx = ltrX;
          } else if (mutationRootRef.current.width < metrics.viewWidth) {
            dx =
              midPoint -
              (metrics.viewWidth - mutationRootRef.current.width) / 2;
          } else {
            dx = midPoint + (mutationRootRef.current.width - metrics.viewWidth);
          }
          mutationRootRef.current.moveBy(dx, dy);
          rtlOffsetRef.current =
            mutationRootRef.current.getRelativeToSurfaceXY().x;
          return;
        }
        if (mutationRootRef.current.width > metrics.viewWidth) {
          dx = dx + mutationRootRef.current.width - metrics.viewWidth;
        }
      } else {
        dx = metrics.viewWidth / 2 - mutationRootRef.current.width / 2 - x;
        // If the procedure declaration is wider than the view width,
        // keep the right-hand side of the procedure in view.
        if (mutationRootRef.current.width > metrics.viewWidth) {
          dx = metrics.viewWidth - mutationRootRef.current.width - x;
        }
      }
      mutationRootRef.current.moveBy(dx, dy);
    });

    setTimeout(() => {
      mutationRootRef.current.focusLastEditor_();
    });
  };

  const closeModal = () => {
    setIsOpen(false);
    workspaceRef.current?.dispose();
    workspaceRef.current = null;
    callbackRef.current = null;
    mutationRootRef.current = null;
    mutatorRef.current = null;
  };

  const handleOk = () => {
    if (mutationRootRef.current)
      callbackRef.current?.(mutationRootRef.current.mutationToDom(true));
    // 当数据改变, 弹窗关闭时, 刷新workspace
    onPromptClosed();
    closeModal();
  };
  const handleToggleWrap = () => {
    if (mutationRootRef.current) {
      const newWarp = mutationRootRef.current.getWarp();
      mutationRootRef.current.setWarp(newWarp);
      setWrap(newWarp);
    }
  };

  return (
    <>
      {isOpen && (
        <CustomProcedures
          intl={intl}
          componentRef={onOpen}
          wrap={wrap}
          onAddBoolean={() => mutationRootRef.current?.addBooleanExternal()}
          onAddLabel={() => mutationRootRef.current?.addLabelExternal()}
          onAddTextNumber={() =>
            mutationRootRef.current?.addStringNumberExternal()
          }
          onCancel={closeModal}
          onOk={handleOk}
          onToggleWarp={handleToggleWrap}
        />
      )}
    </>
  );
};

const messages = defineMessages({
  myblockModalTitle: {
    defaultMessage: "Make a Block",
    description: "Title for the modal where you create a custom block.",
    id: "gui.extension.makeBlock",
  },
});
const CustomProcedures = (props) => (
  <Modal
    className={styles.modalContent}
    contentLabel={props.intl.formatMessage(messages.myblockModalTitle)}
    onRequestClose={props.onCancel}
  >
    {/* <Box className={styles.workspace} componentRef={props.componentRef} /> */}
    <div
      className={styles.workspace}
      ref={props.componentRef}
      id="custome-procedure"
    />
    <Box className={styles.body}>
      <div className={styles.optionsRow}>
        <div
          className={styles.optionCard}
          role="button"
          onClick={props.onAddTextNumber}
        >
          <img className={styles.optionIcon} src={textInputIcon} alt="" />
          <div className={styles.optionTitle}>
            <FormattedMessage
              defaultMessage="Add an input"
              description="Label for button to add a number/text input"
              id="gui.customProcedures.addAnInputNumberText"
            />
          </div>
          <div className={styles.optionDescription}>
            <FormattedMessage
              defaultMessage="number or text"
              description="Description of the number/text input type"
              id="gui.customProcedures.numberTextType"
            />
          </div>
        </div>
        <div
          className={styles.optionCard}
          role="button"
          onClick={props.onAddBoolean}
        >
          <img className={styles.optionIcon} src={booleanInputIcon} alt="" />
          <div className={styles.optionTitle}>
            <FormattedMessage
              defaultMessage="Add an input"
              description="Label for button to add a boolean input"
              id="gui.customProcedures.addAnInputBoolean"
            />
          </div>
          <div className={styles.optionDescription}>
            <FormattedMessage
              defaultMessage="boolean"
              description="Description of the boolean input type"
              id="gui.customProcedures.booleanType"
            />
          </div>
        </div>
        <div
          className={styles.optionCard}
          role="button"
          onClick={props.onAddLabel}
        >
          <img className={styles.optionIcon} src={labelIcon} alt="" />
          <div className={styles.optionTitle}>
            <FormattedMessage
              defaultMessage="Add a label"
              description="Label for button to add a label"
              id="gui.customProcedures.addALabel"
            />
          </div>
        </div>
      </div>
      <div className={styles.checkboxRow}>
        <label>
          <input
            checked={props.warp}
            type="checkbox"
            onChange={props.onToggleWarp}
          />
          <FormattedMessage
            defaultMessage="Run without screen refresh"
            description="Label for checkbox to run without screen refresh"
            id="gui.customProcedures.runWithoutScreenRefresh"
          />
        </label>
      </div>
      <Box className={styles.buttonRow}>
        <button className={styles.cancelButton} onClick={props.onCancel}>
          <FormattedMessage
            defaultMessage="Cancel"
            description="Label for button to cancel custom procedure edits"
            id="gui.customProcedures.cancel"
          />
        </button>
        <button className={styles.okButton} onClick={props.onOk}>
          <FormattedMessage
            defaultMessage="OK"
            description="Label for button to save new custom procedure"
            id="gui.customProcedures.ok"
          />
        </button>
      </Box>
    </Box>
  </Modal>
);

export default FunctionPrompt;
