import { useCallback, useEffect } from "react";
import makeToolboxXML from 'lib/make-toolbox-xml';
import extensionData from "lib/extension-lib";
import { useSelector } from "react-redux";
import { vm } from 'lib/scratch-vm'
import { getToolboxXML } from "./getToolbox";
import { defineBlocks, undefineBlocks } from "./defineBlocks";
import ScratchBlocks from "lib/scratch-blocks";
import { selectIntl } from "redux/intl/intlSlice";

// 默认的toolbox
const defaultToolbox = makeToolboxXML(true);

let toolboxUpdateTimeout: NodeJS.Timeout | undefined;
let toolboxUpdateQueue: any[] = [];
let toolboxCurr: string = defaultToolbox;

/**
* @Description: toolbox更新相关（1.监听[扩展被加载]2.scratch-blocks/scratch-vm翻译，更改后刷新toolbox）
* @author LiuSheng
* @date 2023/5/5
*/
export const useToolboxUpdate = (workspaceRef: any) => {
  const { locale, messages } = useSelector(selectIntl);
  // 同步刷新toolbox
  const updateToolbox = useCallback((toolboxXml: string) => {
    toolboxUpdateTimeout = undefined;
    const workspace = workspaceRef.current;
    if (!workspace) return;
    // 当toolbox内容和上次一样时, 阻止刷新
    const selectedItem = workspace.toolbox_.getSelectedItem();
    // 获取上次选中的 categoryId
    const selectedItemId = selectedItem ? selectedItem.id_ : null;
    if (toolboxXml === toolboxCurr) {
      return;
    }
    // const offset = workspace.toolbox_.getCategoryScrollOffset();
    workspace.updateToolbox(toolboxXml);
    // 记录此次刷新的toolbox
    toolboxCurr = toolboxXml;
    // 重新选中 
    workspace.toolbox_.setSelectedItemById(selectedItemId);

    // In order to catch any changes that mutate the toolbox during "normal runtime"
    // (variable changes/etc), re-enable toolbox refresh.
    // Using the setter function will rerender the entire toolbox which we just rendered.
    workspace.toolboxRefreshEnabled_ = true;

    // const currentCategoryPos = workspace.toolbox_.getCategoryPositionById(categoryId);
    // const currentCategoryLen = workspace.toolbox_.getCategoryLengthById(categoryId);
    // if (offset < currentCategoryLen) {
    //     workspace.toolbox_.setFlyoutScrollPos(currentCategoryPos + offset);
    // } else {
    //     workspace.toolbox_.setFlyoutScrollPos(currentCategoryPos);
    // }

    const queue = toolboxUpdateQueue;
    toolboxUpdateQueue = [];
    queue.forEach(fn => fn());
  }, [])

  // 异步刷新toolbox
  const requestToolboxUpdate = useCallback(
    (toolboxXml?: string | null) => {
      //初始化时设置locale,但此时targets还未加载, 所以使用默认的toolbox
      if (!vm.runtime.getTargetForStage()) {
        toolboxXml = defaultToolbox;
      } else {
        if (!toolboxXml) toolboxXml = getToolboxXML(vm);
        if (!toolboxXml) return;
      }
      clearTimeout(toolboxUpdateTimeout);
      toolboxUpdateTimeout = setTimeout(() => {
        updateToolbox(toolboxXml!);
      }, 0);
    },
    [updateToolbox]
  );

  // 等待更新结束执行回调
  const withToolboxUpdates = useCallback((fn) => {
    // if there is a queued toolbox update, we need to wait
    if (toolboxUpdateTimeout) {
      toolboxUpdateQueue.push(fn);
    } else {
      fn();
    }
  }, [])

  //设置toolbox选中的category
  const handleCategorySelected = useCallback((categoryId) => {
    // todo:
    // const extension = extensionData.find(ext => ext.deviceId === categoryId);
    // if (extension && extension.launchPeripheralConnectionFlow) {
    //     // this.handleConnectionModalStart(categoryId);
    //     // todo: 连接外设
    // }
    // 等待toolbox渲染之后, 滚动到选择的扩展位置
    withToolboxUpdates(() => {
      // workspaceRef.current.toolbox_.setSelectedCategoryById(categoryId);
    });
  }, [withToolboxUpdates])

  // 监听自定义事件[扩展被选中]
  useEffect(() => {
    const handleExtensionSelected = (extensionId) => {
      handleCategorySelected(extensionId);
    }
    vm.on('EXTENSION_SELECTED', handleExtensionSelected)
    return () => {
      vm.removeListener('EXTENSION_SELECTED', handleExtensionSelected);
    }
  }, [handleCategorySelected])

  // 监听[扩展被加载]
  useEffect(() => {
    // 当扩展被加载
    const handleExtensionAdded = (categoryInfo) => {
      // 声明block
      defineBlocks(categoryInfo);
      // 刷新toolbox
      const toolboxXML = getToolboxXML(vm);
      if (toolboxXML) {
        requestToolboxUpdate(toolboxXML);
      }
    }
    const handleExtensionRemoved = (extensionId) => {
      // 清除block声明
      undefineBlocks(extensionId);
      // 刷新toolbox
      const toolboxXML = getToolboxXML(vm);
      if (toolboxXML) {
        requestToolboxUpdate(toolboxXML);
      }
    }
    vm.on('EXTENSION_ADDED', handleExtensionAdded);
    vm.on('EXTENSION_REMOVED', handleExtensionRemoved); // 扩展被移除
    vm.on('BLOCKSINFO_UPDATE', handleExtensionAdded);
    return () => {
      vm.removeListener('EXTENSION_ADDED', handleExtensionAdded);
      vm.removeListener('EXTENSION_REMOVED', handleExtensionRemoved);
      vm.removeListener('BLOCKSINFO_UPDATE', handleExtensionAdded);
    }
  }, [requestToolboxUpdate])


  // scratch-blocks/scratch-vm的翻译
  useEffect(() => {
    // todo: 在scratch-blocks中新增的翻译需要考虑如何与scratch-l10n统一, 方便导出翻译资源
    // 如果要将翻译全部放在scratch-l10n中, 那么要在软件初始化之前, 将翻译资源绑定到scratch-blocks中, scratch-blocks的翻译切换只传递locale变量
    ScratchBlocks.ScratchMsgs.setLocale(locale);
    vm.setLocale(locale, messages).then(() => {
      workspaceRef.current.getFlyout().setRecyclingEnabled(false);
      // 刷新workspace
      vm.refreshWorkspace();
      requestToolboxUpdate();
      withToolboxUpdates(() => {
        workspaceRef.current.getFlyout().setRecyclingEnabled(true);
      });
    });
  }, [
    locale,
    messages,
    requestToolboxUpdate,
    withToolboxUpdates,
    workspaceRef,
  ]);

    useEffect(() => {
        const workspace = workspaceRef.current;
        const onTargetsUpdate = () => {
            if (vm.editingTarget && workspace.getFlyout()) {
                ['glide', 'move', 'set'].forEach(prefix => {
                    updateToolboxBlockValue(`${prefix}x`, Math.round(vm.editingTarget.x).toString());
                    updateToolboxBlockValue(`${prefix}y`, Math.round(vm.editingTarget.y).toString());
                });
            }
        }
        const updateToolboxBlockValue = (id, value) => {
            withToolboxUpdates(() => {
                const block = workspace
                    .getFlyout()
                    .getWorkspace()
                    .getBlockById(id);
                if (block) {
                    block.inputList[0].fieldRow[0].setValue(value);
                }
            });
        }
        vm.addListener('targetsUpdate', onTargetsUpdate);
        return () => {
            vm.removeListener('targetsUpdate', onTargetsUpdate);
        }
    }, [])

    return {
        requestToolboxUpdate,
        updateToolbox,
        withToolboxUpdates,
        handleCategorySelected
    }
}
