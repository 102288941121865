// @flow
import { defineMessages, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import {
  selectStageSize,
  setLargeStage,
  setSmallStage,
} from "redux/stage-size/stageSizeSlice";
import DFIcon from "../../../../component/df-icon/DFIcon";
import styles from "./StageControl.module.scss";
type Props = {};
const messages = defineMessages({
  largeStageSizeMessage: {
    defaultMessage: 'Stage Size Toggle - Large',
    description: 'Button to change stage size to large',
    id: 'gui.gui.stageSizeLarge'
  },
  smallStageSizeMessage: {
    defaultMessage: 'Stage Size Toggle - Small',
    description: 'Button to change stage size to small',
    id: 'gui.gui.stageSizeSmall'
  },
});
export const StageControl = (props: Props) => {
  const { stageSize } = useSelector(selectStageSize);
  const dispatch = useDispatch();
  const intl = useIntl();

  const onSmallClick = () => {
    if (stageSize !== "small") dispatch(setSmallStage());
  };

  const onLargeClick = () => {
    if (stageSize !== "large") dispatch(setLargeStage());
  };
  return (
    <div className={styles.container}>
      <div
        className={stageSize === "large" || stageSize=== 'largeConstrained' ? styles.leftActive : styles.left}
        onClick={onLargeClick}
        title={intl.formatMessage(messages.largeStageSizeMessage)}
      >
        <DFIcon iconName={"icon--large-stage"} />
      </div>
      <div
        className={stageSize === "small" ? styles.rightActive : styles.right}
        onClick={onSmallClick}
        title={intl.formatMessage(messages.smallStageSizeMessage)}
      >
        <DFIcon iconName={"icon--small-stage"} />
      </div>
    </div>
  );
};
