import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import Box from "component/box/box.jsx";
import Label from "component/forms/label.jsx";
import Input from "component/forms/input.jsx";
import BufferedInputHOC from "component/forms/buffered-input-hoc.jsx";
import DirectionPicker from "component/direction-picker/direction-picker.jsx";

import { injectIntl, defineMessages, FormattedMessage } from "react-intl";

import { STAGE_DISPLAY_SIZES } from "lib/layout-constants.js";
import { isWideLocale } from "lib/locale-utils.js";

import styles from "./sprite-info.module.scss";

import xIcon from "./icon--x.svg";
import yIcon from "./icon--y.svg";

import DFIcon from "../../df-icon/DFIcon";

const BufferedInput = BufferedInputHOC(Input);

const messages = defineMessages({
  spritePlaceholder: {
    id: "gui.SpriteInfo.spritePlaceholder",
    defaultMessage: "Name",
    description: "Placeholder text for sprite name",
  },
});

class SpriteInfo extends React.Component {
  shouldComponentUpdate(nextProps) {
    return (
      this.props.rotationStyle !== nextProps.rotationStyle ||
      this.props.disabled !== nextProps.disabled ||
      this.props.name !== nextProps.name ||
      this.props.stageSize !== nextProps.stageSize ||
      this.props.visible !== nextProps.visible ||
      // Only update these if rounded value has changed
      Math.round(this.props.direction) !== Math.round(nextProps.direction) ||
      Math.round(this.props.size) !== Math.round(nextProps.size) ||
      Math.round(this.props.x) !== Math.round(nextProps.x) ||
      Math.round(this.props.y) !== Math.round(nextProps.y)
    );
  }
  render() {
    const { stageSize } = this.props;

    const sprite = (
      <FormattedMessage
        defaultMessage="Sprite"
        description="Sprite info label"
        id="gui.sprite.sprite"
      />
    );
    const showLabel = (
      <FormattedMessage
        defaultMessage="Show"
        description="Sprite info show label"
        id="gui.SpriteInfo.show"
      />
    );
    const sizeLabel = (
      <FormattedMessage
        defaultMessage="Size"
        description="Sprite info size label"
        id="gui.SpriteInfo.size"
      />
    );

    const labelAbove = isWideLocale(this.props.intl.locale);

    const spriteNameInput = (
      <BufferedInput
        className={classNames(styles.spriteInput, {
          [styles.columnInput]: labelAbove,
        })}
        disabled={this.props.disabled}
        placeholder={this.props.intl.formatMessage(messages.spritePlaceholder)}
        tabIndex="0"
        type="text"
        value={this.props.disabled ? "" : this.props.name}
        onSubmit={this.props.onChangeName}
      />
    );

    const xPosition = (
      <div className={styles.group}>
        {stageSize === STAGE_DISPLAY_SIZES.large ? (
          <div className={styles.iconWrapper}>
            <img
              aria-hidden="true"
              className={classNames(styles.xIcon, styles.icon)}
              src={xIcon}
              alt=""
            />
          </div>
        ) : null}
        <Label text="x">
          <BufferedInput
            small
            disabled={this.props.disabled}
            placeholder="x"
            tabIndex="0"
            type="text"
            value={this.props.disabled ? "" : Math.round(this.props.x)}
            onSubmit={this.props.onChangeX}
          />
        </Label>
      </div>
    );

    const yPosition = (
      <div className={styles.group}>
        {stageSize === STAGE_DISPLAY_SIZES.large ? (
          <div className={styles.iconWrapper}>
            <img
              aria-hidden="true"
              className={classNames(styles.yIcon, styles.icon)}
              src={yIcon}
            />
          </div>
        ) : null}
        <Label text="y">
          <BufferedInput
            small
            disabled={this.props.disabled}
            placeholder="y"
            tabIndex="0"
            type="text"
            value={this.props.disabled ? "" : Math.round(this.props.y)}
            onSubmit={this.props.onChangeY}
          />
        </Label>
      </div>
    );

    if (stageSize === STAGE_DISPLAY_SIZES.small) {
      return (
        <Box className={styles.spriteInfo}>
          <div className={classNames(styles.row, styles.rowPrimary)}>
            <div className={styles.group}>{spriteNameInput}</div>
          </div>
          <div className={classNames(styles.row, styles.rowSecondary)}>
            {xPosition}
            {yPosition}
          </div>
        </Box>
      );
    }

    return (
      <Box className={styles.spriteInfo}>
        <div className={classNames(styles.row, styles.rowPrimary)}>
          <div className={styles.group}>
            <Label above={labelAbove} text={sprite}>
              {spriteNameInput}
            </Label>
          </div>
          {xPosition}
          {yPosition}
        </div>
        <div className={classNames(styles.row, styles.rowSecondary)}>
          <div className={labelAbove ? styles.column : styles.group}>
            {stageSize === STAGE_DISPLAY_SIZES.large ? (
              <Label secondary text={showLabel} />
            ) : null}
            <div className={styles.radioWrapper}>
              <div
                className={classNames(
                  styles.radio,
                  styles.radioFirst,
                  styles.iconWrapper,
                  {
                    [styles.isActive]:
                      this.props.visible && !this.props.disabled,
                    [styles.isDisabled]: this.props.disabled,
                  }
                )}
                tabIndex="0"
                onClick={this.props.onClickVisible}
                onKeyPress={this.props.onPressVisible}
              >
                <div>
                  <DFIcon iconName={"icon--show"} />
                </div>
              </div>
              <div
                className={classNames(
                  styles.radio,
                  styles.radioLast,
                  styles.iconWrapper,
                  {
                    [styles.isActive]:
                      !this.props.visible && !this.props.disabled,
                    [styles.isDisabled]: this.props.disabled,
                  }
                )}
                tabIndex="0"
                onClick={this.props.onClickNotVisible}
                onKeyPress={this.props.onPressNotVisible}
              >
                <div>
                  <DFIcon iconName={"icon--hide"} />
                </div>
              </div>
            </div>
          </div>
          <div className={classNames(styles.group, styles.largerInput)}>
            <Label secondary above={labelAbove} text={sizeLabel}>
              <BufferedInput
                small
                disabled={this.props.disabled}
                label={sizeLabel}
                tabIndex="0"
                type="text"
                value={this.props.disabled ? "" : Math.round(this.props.size)}
                onSubmit={this.props.onChangeSize}
              />
            </Label>
          </div>
          <div className={classNames(styles.group, styles.largerInput)}>
            <DirectionPicker
              direction={Math.round(this.props.direction)}
              disabled={this.props.disabled}
              labelAbove={labelAbove}
              rotationStyle={this.props.rotationStyle}
              onChangeDirection={this.props.onChangeDirection}
              onChangeRotationStyle={this.props.onChangeRotationStyle}
            />
          </div>
        </div>
      </Box>
    );
  }
}

SpriteInfo.propTypes = {
  direction: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  name: PropTypes.string,
  onChangeDirection: PropTypes.func,
  onChangeName: PropTypes.func,
  onChangeRotationStyle: PropTypes.func,
  onChangeSize: PropTypes.func,
  onChangeX: PropTypes.func,
  onChangeY: PropTypes.func,
  onClickNotVisible: PropTypes.func,
  onClickVisible: PropTypes.func,
  onPressNotVisible: PropTypes.func,
  onPressVisible: PropTypes.func,
  rotationStyle: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  stageSize: PropTypes.oneOf(Object.keys(STAGE_DISPLAY_SIZES)).isRequired,
  visible: PropTypes.bool,
  x: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  y: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default injectIntl(SpriteInfo);
