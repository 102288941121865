import { vm } from "lib/scratch-vm";
import { DeviceTarget } from "./deviceTarget";

class ConnectScratch {
    deviceTarget: DeviceTarget
    device: any
    // onlineConnectStatus: 0|1|2|3 = 0 // 未连接/连接中/连接成功/连接失败
    // onlineConnectStatusCallback: Function|null = null;
    deviceObj: any = null // extension对象(getInfo)
    constructor (deviceTarget) {
        this.deviceTarget = deviceTarget;
    }

    isConnected() {
        return this.device && this.device.isConnected && this.device.isConnected()
    }
    
    // 实时模式连接
    async _connect(port) {
        if (!this.deviceObj) this.deviceObj = vm.getExtensionObj(this.deviceTarget.config.deviceIdWithVersion);
        // 判断有无device对象
        if (!this.deviceObj || !this.deviceObj.getDevice) return Promise.reject('设备中未定义getDevice方法');
        const device = this.deviceObj.getDevice();
        if (!device) return Promise.reject('getDevice 返回为空');
        if (!device.connect) return Promise.reject('device中没有定义connect方法');
        this.device = device;
        return device.connect(port)
            .then((board) => {
                // 如果board为空, 连接失败, 烧录之后再次握手
                if (board) return board;
                // 烧录固件
                return this.deviceTarget.burnFirmware(port, "online")
                    .then(() => {
                        console.log('上传固件成功')
                        return new Promise(resolve => {
                            // 烧录完成后, 等待2s再连接
                            setTimeout(resolve, 2000);
                        })
                    })
                    .then(() => {
                        // 再次连接
                        return device.connect(port);
                    })
            }/*, (err) => {
              return Promise.reject(err)
            }*/)
            .then(board => {
                board.on('close', () => {
                    // this.setOnlineConnectStatus(0); // 状态设置为未连接
                    console.log('连接断开')
                })
            })
    }

    connect(port) {
        // this.setOnlineConnectStatus(1); // 状态设置为正在连接
        return this._connect(port)
            .then(() => {
                // this.setOnlineConnectStatus(2); // 状态设置为连接成功
            })
            .catch((err) => {
                // this.setOnlineConnectStatus(3); // 状态设置为连接失败
                return Promise.reject(err)
            })
    }

    // 断开连接
    disconnect() {
        if (!this.device) return Promise.resolve();
        if (!this.device.disconnect) return Promise.reject('device中没有定义disconnect方法');
        // this.setOnlineConnectStatus(0); // 状态设置为未连接
        // 停止运行
        vm.runtime.stopAll();
        return this.device.disconnect();
    }

    // // 设置连接状态
    // setOnlineConnectStatus(status) {
    //     this.onlineConnectStatus = status;
    //     if (this.onlineConnectStatusCallback) {
    //         this.onlineConnectStatusCallback(status);
    //     }
    // }

    // // 获取连接状态
    // getOnlineConnectStatus() {
    //     return this.onlineConnectStatus;
    // }

    // // 设置状态回调
    // onOnlineConnectStatus(callback) {
    //     this.onlineConnectStatusCallback = callback;
    //     // 监听回调挂载时, 刷新一次状态
    //     if (callback) callback(this.getOnlineConnectStatus());
    // }
}

export default ConnectScratch
