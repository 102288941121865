import DFDropdown from "component/df-dropdown/DFDropdown";
import { IS_SCRATCH_MODE } from "config/config";
import { useEffect, useRef, useState } from 'react';
import { defineMessages, useIntl } from "react-intl";
import Serialport from 'service/link-adapter/serialport';
import { installSerialPortDriver, openDM } from 'service/link-adapter/util';
import { commandWs } from 'service/link-adapter/websocket/commandWs';
import message from "../../../component/df-message/Message";
import { vm } from "../../../lib/scratch-vm";
import MenuBarStyles from "../MenuBar.module.scss";
import styles from "./SelectSerial.module.scss";
import dfrobotAlert from 'component/df-alert/DFAlert'

interface SerialInfo {
  [portPath: string]: string // serialNumber
}

export const SelectSerial = () => {
  const intl = useIntl()
  const [serials, setSerials] = useState<{ key: string, label: string }[]>([])
  const [currentSerial, setCurrentSerial] = useState('')
  const [isWsConnected, setIsWsConnected] = useState(commandWs.isOpen())
  const [isLinking, setIsLinking] = useState(false)
  const isLinkingRef = useRef(false)
  const serialInfo = useRef<SerialInfo>({}); // 存储serialNumber

  let menuItems: any = []

  if (commandWs.isOpen()) {
    menuItems.push({
      key: "disconnect",
      label: intl.formatMessage(messages.disconnectDevice),
      disabled: !currentSerial,
    });
  }

  useEffect(() => {
    isLinkingRef.current = isLinking
  }, [isLinking])

  menuItems = menuItems.concat(serials)
  menuItems.push({ key: 'openDM', label: intl.formatMessage(messages.openDM) })
  useEffect(() => {
    let interval: any = null;
    const serialList = () => {
      if (interval) clearInterval(interval);
      interval = setInterval(() => {
        // calliope...等主板在烧录固件之后, boot重启, 串口会断开, 所以连接时先停止扫描串口, 保证串口显示的正常
        if (!commandWs.isOpen() || vm.runtime.isStopScanSerial) return;
        Serialport.list()
          .then((ports: any) => {
            // 重置serialInfo
            serialInfo.current = {}
            ports = ports.filter((item: any) => item.pnpId.indexOf('USB') === 0 || item.pnpId.indexOf('FTDIBUS') == 0);
            ports = ports.map((port: any) => {
              let name = '';
              if (port.pnpId.indexOf('VID_1A86&PID_7523') !== -1) {
                name = 'CH340';
              } else if ((port.pnpId.indexOf('VID_2341&PID_0043') !== -1) || (port.pnpId.indexOf('VID_3343&PID_0043') !== -1)) {
                name = 'Uno';
              } else if (port.pnpId.indexOf('VID_0D28&PID_0204') !== -1) {
                if (port.serialNumber.indexOf('9904') === 0 || port.serialNumber.indexOf('9905') === 0 || port.serialNumber.indexOf('9906') === 0) {
                  name = 'Microbit V2';
                } else {
                  name = 'Microbit';
                }
              } else if (port.pnpId.indexOf('VID_2341&PID_8036') !== -1 || port.pnpId.indexOf('VID_3343&PID_8036') !== -1 || port.pnpId.indexOf('VID_3343&PID_8035') !== -1) {
                name = 'Leonardo';
              } else if (port.pnpId.indexOf('VID_2341&PID_0036') !== -1 || port.pnpId.indexOf('VID_3343&PID_0036') !== -1 || port.pnpId.indexOf('VID_3343&PID_0035') !== -1) {
                name = 'Leonardo-bootloader';
              } else if (port.pnpId.indexOf('VID_0403+PID_6001') !== -1) {
                name = 'FT232';
              } else if (port.pnpId.indexOf('VID_10C4&PID_EA60') !== -1) {
                name = 'CP210x';
              } else if (port.pnpId.indexOf('VID_1A86&PID_55D4') !== -1) {
                name = 'CH9102';
              } else if ((port.pnpId.indexOf('VID_2341&PID_0042') !== -1) || (port.pnpId.indexOf('VID_3343&PID_0042') !== -1)) {
                name = 'Mega2560';
              } else if (port.pnpId.indexOf('VID_0403+PID_6010') !== -1) {
                if (port.pnpId.indexOf("A\\") !== -1) {
                  name = 'Maixduino';
                } else if (port.pnpId.indexOf("B\\") !== -1) {
                  name = `ESP32(${intl.formatMessage(messages.networkInterfaceCard)})`;
                }
              } else if (port.pnpId.indexOf('VID_1366&PID_1025') !== -1 || port.pnpId.indexOf('VID_1366&PID_1015') !== -1) {
                name = 'Calliope';
              } else if (port.pnpId.indexOf('VID_1A86&PID_7522') !== -1) {
                name = 'CH340K';
              } else if ((port.pnpId.indexOf('VID_2E8A&PID_000A') !== -1) || (port.pnpId.indexOf('VID_2E8A&PID_0005') !== -1) || (port.pnpId.indexOf('VID_2E8A&PID_00C0') !== -1)) {
                name = 'Pico';
              }

              // 保存serialNumber
              serialInfo.current[port.path] = port.serialNumber;
              return {
                key: port.path,
                label: `${port.path}-${name}`
              }
            })
            setSerials(ports)
          })
      }, 1000)
    }

    const onWsClose = () => {
      setSerials([]);
      setCurrentSerial("");
      setIsWsConnected(false)
    }

    const onWsError = () => {
      message.error("连接link失败!")
      setIsWsConnected(false)
    }

    const onWsOpen = () => {
      setIsWsConnected(true)
      serialList()
    }
    serialList()
    commandWs.on("open", onWsOpen)
    commandWs.on("close", onWsClose)
    commandWs.on("error", onWsError)

    // runtime上挂载获取serialNumber的方法
    vm.runtime.getSerialNumberByPort = (port: string) => {
      return serialInfo.current[port];
    }
    return () => {
      // commandWs.removeListener("open", serialList)
      if (interval) clearInterval(interval);
      commandWs.removeListener("close", onWsClose)
      commandWs.removeListener("error", onWsError)
      commandWs.removeListener("open", onWsOpen)
      vm.runtime.getSerialNumberByPort = null;
    }
  }, [intl])
  useEffect(() => {
    // 当前选择的串口断开连接了
    if (currentSerial) {
      const ret = serials.some(item => item.key === currentSerial)
      if (!ret) {
        // 断开连接
        vm.runtime.deviceManager.changePort("")
        // 刷新显示
        setCurrentSerial("")
      }
    }
  }, [serials])

  // ws断开连接之后, 清空串口选择和列表
  useEffect(() => {
    if (!isWsConnected) {
      setCurrentSerial('')
      setSerials([])
    }
  }, [isWsConnected])

  const onSearchWs = () => {
    if (commandWs.isOpen()) {
      setIsWsConnected(true)
      return
    }
    commandWs.open();
  }
  const onToggleConnect = (key: string) => {
    // 点击的是同一个串口
    if (currentSerial == key) return
    // 设置连接状态
    setIsLinking(true)
    // 停止扫描串口(上传时, 也需要停止)
    vm.runtime.isStopScanSerial = true;
    setCurrentSerial(key);
    vm.runtime.deviceManager.changePort(key)
      .then(() => {
        if (IS_SCRATCH_MODE) message.info(`连接设备成功!`)
      })
      .catch((err) => {
        if (IS_SCRATCH_MODE) {
          setCurrentSerial('');
          if (err === '未加载设备') {
            dfrobotAlert(intl.formatMessage(messages.prompt), intl.formatMessage(messages.notSelectDevice), { timeout: 3000 });
          } else {
            message.error("设备连接失败:"+err.toString(), 5000)
          }
        }
      })
      .finally(() => {
        setIsLinking(false)
        vm.runtime.isStopScanSerial = false;
      })
  }

  // 打开设备管理器
  const handleOpenDM = () => {
    openDM()
  }

  const handleInstallDriver = () => {
    installSerialPortDriver()
  }
  const onDropdownClick = ({ key }) => {
    switch (key) {
      case 'openDM':
        handleOpenDM()
        break
      case 'disconnect':
        setCurrentSerial('');
        vm.runtime.deviceManager.changePort('');
        break;
      default:
        onToggleConnect(key)
    }
  }

  // ws 未连接
  if (!isWsConnected) {
    return (
      <div className={styles.WsConnectButton} onClick={onSearchWs}>
        连接Link
      </div>
    )
  }
  return (
    <>
      {
        !isLinking ? (
          <div className={MenuBarStyles.menuItem} >
            <DFDropdown trigger={'click'} items={menuItems} onItemClick={onDropdownClick}
              width={'100'}>
              <div className={MenuBarStyles.dropdownContent}>
                <span >{currentSerial || intl.formatMessage(messages.connectDevice)}</span>
              </div>
            </DFDropdown>
          </div >

        ) : (
          <div className={styles.linking}>设备连接中
            <span className={styles.linkingDot}>.</span>
            <span className={styles.linkingDot}>.</span>
            <span className={styles.linkingDot}>.</span>
          </div>
        )

      }
    </>
  );
};


const messages = defineMessages({
  connectDevice: {
    "id": 'gui.gui.connectDevice',
    "defaultMessage": "Connect device",
    "description": "top Menu Connect device"
  },
  disconnectDevice: {
    "id": 'gui.gui.disconnectDevice',
    "defaultMessage": "Disconnect device",
    "description": "top Menu Disconnect device"
  },
  installSerialportDriver: {
    "id": 'gui.gui.installSerialportDriver',
    "defaultMessage": "一键安装串口驱动",
    "description": "top Menu Unconnected SerialPort"
  },
  openDM: {
    "id": 'gui.gui.openDM',
    "defaultMessage": "Open Device Manager",
    "description": "top Menu Open Device Manager"
  },
  restoreSetting: {
    "id": 'gui.gui.restoreSetting',
    "defaultMessage": "Restore device initial settings",
    "description": "top Menu restore setting"
  },
  errMsg: {
    id: 'gui.progress.errMsg',
    defaultMessage: 'Upload error, you can see error message in the console at the lower right corner',
    descriptions: 'msg for progress-bar err message'
  },
  uploadSuccess: {
    id: 'gui.progress.uploadSuccess',
    defaultMessage: 'upload success',
    description: 'text for upload success'
  },
  searchWebsocketAgain: {
    id: 'gui.gui.searchWebsocketAgain',
    defaultMessage: 'Search Mind+ Link again',
    description: 'text for Mind+ Link'
  },
  websocketConnected: {
    id: 'gui.gui.websocketConnected',
    defaultMessage: 'Mind+ Link connected',
    description: 'text for Mind+ Link'
  },
  searching: {
    id: 'gui.gui.searching',
    defaultMessage: 'Searching',
    description: 'text for Mind+ Link'
  },
  networkInterfaceCard: {
    id: 'gui.progress.networkInterfaceCard',
    defaultMessage: 'NIC',
    description: 'network interface card'
  },
  prompt: {
    id: "gui.dialog.prompt",
    default: "Note"
  },
  notSelectDevice: {
    id: "gui.dialog.notSelectDevice",
    default: "No device is selected, please click on 'Extension' in the lower left corner of the software,</br>click to select the device you want to connect to"
  }
});
