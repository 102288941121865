import {FC} from "react";
import 'asset/iconfont/iconfont.js'
import styles from './DFIcon.module.scss'

type Props = {
    iconName: string
    useThemeColor?: boolean
    color?: string
    size?:number
};
const DfIcon: FC<Props> = ({iconName, useThemeColor = false, color,size}) => {
    const colorStyle = color && {color: color}
    const sizeStyle = size && {fontSize: size}
    return (
        <svg className={styles.container} style={{...colorStyle,...sizeStyle}} aria-hidden="true">
            <use xlinkHref={`#icon-${iconName}`}/>
        </svg>
    );
};

export default DfIcon
