import React from 'react';
import styles from './ExtensionButton.module.scss';
import {useDispatch} from "react-redux";
import {openExtensionLib} from "redux/asset-lib/assetLibStatusSlice";
import DFButton from "../df-button/DFButton";

const ExtensionButton = ({visibility}: {visibility: boolean}) => {
    const dispatch = useDispatch();

    // 打开扩展库
    const openExtensionLib_ = () => {
        dispatch(openExtensionLib())
    }

    return (
        <DFButton type={"primary"} style={{visibility: visibility?'visible':'hidden'}} className={styles.container} onClick={openExtensionLib_}>
            扩展
        </DFButton>
    );
};

export default ExtensionButton;
